import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import i18n from './../../i18n';
import { assignDepartmentPayload,saveStatusPayload, assignEmployeePayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import Config from './../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import ToastComponent from './../ToastComponent';

export default class AssignToComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requesttypeid: '',
            requestType: '',
            isLoading: false,
            employeeId: 0,
            departmentId: 0,
            addedComent: '',
            value: '',
            title: '',
            pathname: '',
            employeeArray: [],
            departmentArray: [],
            
        }
    }

    componentDidMount = () => {
      
        let requesttypeid = '';
        if (this.props.requesttypeid) {
            requesttypeid = this.props.requesttypeid;
        }
        let tempEmployeeArray = [];
        if (Config.getEmployeeData()) {
            tempEmployeeArray = Config.getEmployeeData();
        }
        let tempDepartmentArray = [];
        if (Config.getDepartmentData()) {
            tempDepartmentArray = Config.getDepartmentData();
        }
        this.setState({
            requesttypeid: requesttypeid
        });
        
        //this.setState({title:this.props.title});
        this.setState({ departmentArray: tempDepartmentArray});
   
        this.setState({employeeArray: tempEmployeeArray});
         
    }

    callAssignToDep = () => {
debugger;
        // if (this.state.value !== '') {
        let obj = {};
        obj.requestid = this.props.id;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.notes = this.state.addedComent;

        if (this.props.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.requesttypeid === Config.getExtendReviewObjectiveId() 
            || this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId() 
            || this.props.requesttypeid === Config.getOpenComplainsStageId() || this.props.requesttypeid === Config.getAmendOnSharedGovermentId() 
            || this.props.requesttypeid === Config.getJoinAndImplementId()  || this.props.requesttypeid === Config.getReportAndStaticId() 
            || this.props.requesttypeid === Config.getOtherRequest()) {
            if (this.props.title === 'assigntodatadep') {
               
                obj.departmentid =Config.getDataDepartmentId();
            }
            else 
            {
                if (this.state.departmentId) {
                    obj.departmentid = parseInt(this.state.departmentId);
                } else {
                    obj.departmentid = this.props.assigneddepartmentid;
                }
            }
         } else {
                // select sepcificdepart from list
                if(this.props.requesttypeid ===874)
                {
                    obj.departmentid =131
                }  
              else if (this.state.departmentId) {
                    obj.departmentid = parseInt(this.state.departmentId);
                } else {
                    obj.departmentid = this.props.assigneddepartmentid;
                }
            }
        // } else {
        //     obj.departmentid = parseInt('0');
        // }
        
        obj.statusid = this.props.currentStatusId;
        obj.reasonid = this.props.currentStatusReasonId;
        obj.requestnumber = this.props.currentRequestNumber;
        // call save status update api
        let payload = assignDepartmentPayload(obj);
        let endPoint = 'AssignDepartment'
        let url = Config.getUrl(endPoint);
        
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                debugger;
                this.setState({
                    isLoading: false
                }, () => {
                  
                    if (res.Status) {
                        //if (this.props.isviewdetails == 1) { }
                        //else {
                            this.props.toggle(true);
                        //}
                    } else {
                        // alert(res.Message);
                      
                        //if (this.props.isviewdetails == 1) { }
                        //else {
                            this.props.toggle(false);
                        //}
                        ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoassigndepartment);
                    }
                })
            })
        })
    }

  
    callAssignToEmp = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        let tempUserId = getDataFromLocalStorage.id;

        let obj = {};
        obj.requestid = this.props.id;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.notes = this.state.addedComent;
        // obj.employeeid = parseInt(this.state.value);
        // obj.employeeid = parseInt('0');
        if (this.props.title === 'assigntospecificemp') {
            obj.employeeid = tempUserId;
        } else if (this.props.title === 'assigntoemp') {
            if (this.state.employeeId) {
                obj.employeeid = parseInt(this.state.employeeId);
            } else {
                obj.employeeid = 0;
            }
        }
        obj.statusid = this.props.currentStatusId;
        obj.reasonid = this.props.currentStatusReasonId;
        obj.requestnumber = this.props.currentRequestNumber;

        // call save status update api
        let payload = assignEmployeePayload(obj);
        let endPoint = 'AssignEmployee'
        let url = Config.getUrl(endPoint);
        
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                debugger;
                this.setState({
                    isLoading: false
                }, () => {
                  
                    if (res.Status) {
                        //if (this.props.isviewdetails == 1) { this.props.MGM() }
                        //else {
                            this.props.toggle(true);
                       // }
                    } else {
                        // alert(res.Message);
                        
                        //if (this.props.isviewdetails == 1) { }
                        //else {
                            this.props.toggle(false);
                       // }
                        ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoassignemployee);
                    }
                })
            })
        })
        // }
    }

    submitHandler = (event) => {
       
        event.preventDefault();
        event.target.className += " was-validated";
        if (this.props.title === 'assigntodep' || this.props.title === 'assigntodatadep' || this.props.title === 'backtomanager') {
            this.callAssignToDep();
        } else if (this.props.title === 'assigntospecificdep') {
            if (this.state.departmentId) {
                this.callAssignToDep();
            }
        } else if (this.props.title === 'assigntoemp') {
            this.callAssignToEmp();
        } else if (this.props.title === 'assigntospecificemp') {
            this.callAssignToEmp();
        }
       
    }
    

    render() {
       
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }
       
        let title = this.props.title;
        var flag = false;
        if (title === 'assigntodep' || title==='assigntospecificdep') {
            flag = true;
        }
      
        return (
         
            <MDBContainer style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate>
                         <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>
                            {
                            this.props.title === 'assigntoemp' && this.state.employeeArray && this.state.employeeArray.length > 0 ?
                                <MDBCol style={{ width: '100%', padding: 0 }}>
                                    <span style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].assignToEmp}</span>
                                    <br />
                                    <select
                                        className="browser-default custom-select" id="sources-funding"
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                        value={this.state.employeeId}
                                        onChange={(event) => {
                                            this.setState({ employeeId: event.target.value })
                                        }}>
                                        <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].select}-</option>
                                        {
                                            this.state.employeeArray ? this.state.employeeArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}
                                                </option>
                                            }) : null
                                        }
                                    </select>
                                </MDBCol>
                                :
                                null

                        }

{
                            this.props.title === 'assigntoemp' && this.state.employeeArray && this.state.employeeArray.length > 0 ?
                                <br />
                                :
                                null
                        }

{
                            
                            // 
                            flag===true  && this.props.requesttypeid !== 874?
                                <MDBCol style={{ width: '100%', padding: 0 }}>
                                 <span style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].assignToDep}</span>

                                 <br />
                                    <select
                                        required
                                        className="browser-default custom-select" id="sources-funding"
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                        value={this.state.departmentId}
                                        onChange={(event) => {
                                            this.setState({ departmentId: event.target.value })
                                        }}>
                                        <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].select}-</option>
                                        {
                                            this.state.departmentArray ? this.state.departmentArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}
                                                </option>
                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                </MDBCol>
                                :
                                null

                        }
                        {
                            this.props.title === 'assigntospecificdep' && this.state.departmentArray && this.state.departmentArray.length > 0 ?
                                <br />
                                :
                                null
                        }
                       

                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                            <MDBCol md={12} style={{ display: 'flex', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start' }}>
                                <div style={{  display: 'flex', flexDirection: 'column' }} className="form-group">
                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                    </label>
                                    <textarea
                                        className="form-control" id="exampleFormControlTextarea2" rows="2" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        // required
                                        onChange={(event) => {
                                            this.setState({
                                                addedComent: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }} >
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].SCI.submit}
                            </MDBBtn>
                            <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
                        </MDBRow>
</LoadingOverlay>
                </form>
            </MDBContainer>
        );
    }
}