import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import Config from "../../utils/Config";
import "./../../css/style.css";
import CKEditor from "react-ckeditor-component";
import i18n from "../../i18n";
import StepsComponent from "../StepsComponent";
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');

const currentMonthlyBonusOptions = [
    { value: 'Technical Allowance', label: 'Technical Allowance' },
    { value: 'Danger Allowance', label: 'Danger Allowance' },
    { value: 'Special Allowance', label: 'Special Allowance' },
    { value: 'Parking Allowance', label: 'Parking Allowance' },
    { value: 'Mobile Allowance', label: 'Mobile Allowance' },
    { value: 'Cost Of Living Allowance', label: 'Cost Of Living Allowance' },
    { value: 'UAE National Allowance', label: 'UAE National Allowance' },
    { value: 'Laptop Allowance', label: 'Laptop Allowance' },
    { value: 'Transportation Allowance', label: 'Transportation Allowance' },
    { value: 'Furniture Allowance', label: 'Furniture Allowance' },
    { value: 'Qualification Allowance', label: 'Qualification Allowance' },
    { value: 'Remote Area Allowance', label: 'Remote Area Allowance' },
    { value: 'General Allowance', label: 'General Allowance' },
    { value: 'Service Allowance', label: 'Service Allowance' },
    { value: 'Commity Membership Allowance', label: 'Commity Membership Allowance' },
    { value: 'Entertainment Allowance', label: 'Entertainment Allowance' },
    { value: 'Training Allowance', label: 'Training Allowance' },
    { value: 'Acting Allowance', label: 'Acting Allowance' },
    { value: 'Administrative Allowance', label: 'Administrative Allowance' },
    { value: 'Duty Allowance', label: 'Duty Allowance' },
    { value: 'Fuel Allowance', label: 'Fuel Allowance' }
];

const currentAnnualBenefitOptions = [
    { value: 'Ticket Allowance', label: 'Ticket Allowance' },
    { value: 'Educational Allowance', label: 'Educational Allowance' },
    { value: 'Club Membership Allowance', label: 'Club Membership Allowance' },
    { value: 'Furniture Allowance', label: 'Furniture Allowance' },
    { value: 'Car Allowance', label: 'Car Allowance' },
    { value: 'Special Allowance', label: 'Special Allowance' },
];

const currentMonthlyBonusOptions_AR = [
    { value: 'علاوة فنية', label: 'علاوة فنية' },
    { value: 'علاوة خطر', label: 'علاوة خطر' },
    { value: 'علاوة خاصة', label: 'علاوة خاصة' },
    { value: 'علاوة مواقف', label: 'علاوة مواقف' },
    { value: 'علاوة هاتف', label: 'علاوة هاتف' },
    { value: 'علاوة غلاء معيشة', label: 'علاوة غلاء معيشة' },
    { value: 'علاوة مواطن', label: 'علاوة مواطن' },
    { value: 'علاوة حاسب آلي', label: 'علاوة حاسب آلي' },
    { value: 'علاوة مواصلات', label: 'علاوة مواصلات' },
    { value: 'علاوة أثاث', label: 'علاوة أثاث' },
    { value: 'علاوة مؤهل', label: 'علاوة مؤهل' },
    { value: 'علاوة المناطق النائية', label: 'علاوة المناطق النائية' },
    { value: 'علاوة عامة', label: 'علاوة عامة' },
    { value: 'علاوة خدمات', label: 'علاوة خدمات' },
    { value: 'علاوة عضوية اللجان', label: 'علاوة عضوية اللجان' },
    { value: 'علاوة ترفيه', label: 'علاوة ترفيه' },
    { value: 'علاوة تدريب', label: 'علاوة تدريب' },
    { value: 'علاوة تكليف', label: 'علاوة تكليف' },
    { value: 'علاوة إدارية', label: 'علاوة إدارية' },
    { value: 'علاوة مناوبات', label: 'علاوة مناوبات' },
    { value: 'علاوة وقود', label: 'علاوة وقود' }
];

const currentAnnualBenefitOptions_AR = [
    { value: 'بدل تذاكر السفر', label: 'بدل تذاكر السفر' },
    { value: 'بدل تعليم أبناء', label: 'بدل تعليم أبناء' },
    { value: 'بدل الاشتراك في النوادي', label: 'بدل الاشتراك في النوادي' },
    { value: 'بدل أثاث', label: 'بدل أثاث' },
    { value: 'بدل سيارة', label: 'بدل سيارة' },
    { value: 'بدل خاص', label: 'بدل خاص' },
];

export default class AmendSalariesScaleStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: false,
            statusid: '',
            proposedScale: this.props.sendData.proposedScale,
            principlesUsedToModifyScale: this.props.sendData.principlesUsedToModifyScale,
            justificationOfEditTheScale: this.props.sendData.justificationOfEditTheScale,
            proposedBasicSalary: this.props.sendData.proposedBasicSalary,
            currentBasicSalary: this.props.sendData.currentBasicSalary,
            proposedAnnualBenefits: this.props.sendData.proposedAnnualBenefits,
            currentAnnualBenefits: this.props.sendData.currentAnnualBenefits,
            proposedFinancialEffect: this.props.sendData.proposedFinancialEffect,
            currentFinancialEffect: this.props.sendData.currentFinancialEffect,
            selectedOption: this.props.sendData.selectedOption,
            sourcesFunding: this.props.sendData.sourcesFunding,
            approvedBudgetsOfPart: this.props.sendData.approvedBudgetsOfPart,
            otherBonusTableData: this.props.sendData.otherBonusTableData,
            otherBenefitTableData: this.props.sendData.otherBenefitTableData,
            proposedSalariesScale: this.props.sendData.proposedSalariesScale,
            currentSalariesScale: this.props.sendData.currentSalariesScale,
            avarageForProposedSalariesScale: this.props.sendData.avarageForProposedSalariesScale,
            avarageForCurrentSalariesScale: this.props.sendData.avarageForCurrentSalariesScale,
            EffectOfApplyingOnProposed: this.props.sendData.EffectOfApplyingOnProposed,
            EffectOfApplyingOnCurrent: this.props.sendData.EffectOfApplyingOnCurrent,
            justificationOfEditTheScaleErr: '',
            principlesUsedToModifyScaleErr: '',
            salariesCompensationFile: "",
            approvedScaleFile: "",
            proposedScaleFile: "",
            selectedOptionErr: '',
            reqDetails: "",
            currentBasicSalaryErr: '',
            proposedBasicSalaryErr: '',
            proposedAnnualBenefitsErr: '',
            currentAnnualBenefitsErr: '',
            currentSalariesScaleErr: '',
            proposedSalariesScaleErr: '',
            EffectOfApplyingOnCurrentErr: '',
            EffectOfApplyingOnProposedErr: '',
            approvedBudgetsOfPartErr: "",
            sourcesFundingLabel: this.props.sendData.sourcesFundingLabel
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            
            let tempOtherBonusTableData = [], tempOtherBenefitTableData = [];
            let tempCurrentBasicSalary = null;
            let tempProposedBasicSalary = null;
            let tempCurrentAnnualBenefits = null;
            let tempProposedAnnualBenefits = null;
            if (editObj.amendSalaryScaleMatrix && editObj.amendSalaryScaleMatrix.length > 0) {
                tempCurrentBasicSalary = editObj.amendSalaryScaleMatrix[0].currentmonthlybonus;
                tempProposedBasicSalary = editObj.amendSalaryScaleMatrix[0].proposedmonthlybonus;
                tempCurrentAnnualBenefits = editObj.amendSalaryScaleMatrix[0].currentannualbenefits;
                tempProposedAnnualBenefits = editObj.amendSalaryScaleMatrix[0].proposedannualbenefits;
                tempOtherBonusTableData = editObj.amendSalaryScaleMatrix[0].otherbonus;
                tempOtherBenefitTableData = editObj.amendSalaryScaleMatrix[0].otherbenefits;
            } else {
                // tempCurrentBasicSalary = null;
                // tempProposedBasicSalary = null;
                // tempCurrentAnnualBenefits = null;
                // tempProposedAnnualBenefits = null;
                tempOtherBonusTableData = [{
                    othercurrentmonthlybonus: '',
                    otherproposedmonthlybonus: ''
                }];
                tempOtherBenefitTableData = [{
                    othercurrentannualbenefits: '',
                    otherproposedannualbenefits: '',
                }]
            }
            
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                justificationOfEditTheScale: editObj.justification,
                principlesUsedToModifyScale: editObj.principle,
                sourcesFunding: editObj.fundingid,
                selectedOption: parseInt(editObj.isallocated) === 1 ? "Yes" : "No",
                approvedBudgetsOfPart: editObj.budgetapprovalamount,
                // currentBasicSalary: editObj.currentbasicsalary,
                // proposedBasicSalary: editObj.proposedbasicsalary,
                // currentAnnualBenefits: editObj.currentannualbenefits,
                // proposedAnnualBenefits: editObj.proposedannualbenefits,
                currentFinancialEffect: editObj.currentfinancialeffect,
                proposedFinancialEffect: editObj.proposedfinancialeffect,
                currentSalariesScale: editObj.currentscalechallenge,
                proposedSalariesScale: editObj.proposedscalechallenge,
                avarageForCurrentSalariesScale: editObj.currentincreaeaverage,
                avarageForProposedSalariesScale: editObj.currentdecreaseaverage,
                EffectOfApplyingOnCurrent: editObj.currentapplyeffect,
                EffectOfApplyingOnProposed: editObj.proposedappleffect,
                currentBasicSalary: tempCurrentBasicSalary,
                proposedBasicSalary: tempProposedBasicSalary,
                currentAnnualBenefits: tempCurrentAnnualBenefits,
                proposedAnnualBenefits: tempProposedAnnualBenefits,
                // otherBonusTableData: editObj.amendSalaryScaleMatrix.otherbonus,
                // otherBenefitTableData: editObj.amendSalaryScaleMatrix.otherbenefits,
                otherBonusTableData: tempOtherBonusTableData,
                otherBenefitTableData: tempOtherBenefitTableData,
            })
        }

    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

        let selectedOptionErr = '', currentBasicSalaryErr = '', proposedBasicSalaryErr = '', currentAnnualBenefitsErr = '', proposedAnnualBenefitsErr = '', tempJustificationOfEditTheScaleErr = "", tempPrinciplesUsedToModifyScaleErr = "", currentSalariesScaleErr = '', proposedSalariesScaleErr = '', EffectOfApplyingOnCurrentErr = '', EffectOfApplyingOnProposedErr = '', approvedBudgetsOfPartErr = '';

        if (!this.state.approvedBudgetsOfPart) {
            approvedBudgetsOfPartErr = "thisFieldIsRequired";
        }
        if (!this.state.selectedOption) {
            selectedOptionErr = 'thisFieldIsRequired';
        }
        if (!this.state.currentBasicSalary) {
            currentBasicSalaryErr = 'thisFieldIsRequired';
        }
        if (!this.state.proposedBasicSalary) {
            proposedBasicSalaryErr = 'thisFieldIsRequired';
        }
        if (!this.state.currentAnnualBenefits) {
            currentAnnualBenefitsErr = 'thisFieldIsRequired';
        }
        if (!this.state.proposedAnnualBenefits) {
            proposedAnnualBenefitsErr = 'thisFieldIsRequired';
        }

        if (this.state.currentSalariesScale && this.state.currentSalariesScale.trim()) {
            let val = striptags(this.state.currentSalariesScale.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                currentSalariesScaleErr = 'thisFieldisRequired';
            }
        } else {
            currentSalariesScaleErr = 'thisFieldisRequired';
        }

        if (this.state.proposedSalariesScale && this.state.proposedSalariesScale.trim()) {
            let val = striptags(this.state.proposedSalariesScale.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                proposedSalariesScaleErr = 'thisFieldisRequired';
            }
        } else {
            proposedSalariesScaleErr = 'thisFieldisRequired';
        }

        if (this.state.EffectOfApplyingOnCurrent && this.state.EffectOfApplyingOnCurrent.trim()) {
            let val = striptags(this.state.EffectOfApplyingOnCurrent.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                EffectOfApplyingOnCurrentErr = 'thisFieldisRequired';
            }
        } else {
            EffectOfApplyingOnCurrentErr = 'thisFieldisRequired';
        }

        if (this.state.EffectOfApplyingOnProposed && this.state.EffectOfApplyingOnProposed.trim()) {
            let val = striptags(this.state.EffectOfApplyingOnProposed.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                EffectOfApplyingOnProposedErr = 'thisFieldisRequired';
            }
        } else {
            EffectOfApplyingOnProposedErr = 'thisFieldisRequired';
        }

        if (this.state.justificationOfEditTheScale && this.state.justificationOfEditTheScale.trim()) {
            let val = striptags(this.state.justificationOfEditTheScale.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempJustificationOfEditTheScaleErr = 'thisFieldisRequired';
            }
        } else {
            tempJustificationOfEditTheScaleErr = 'thisFieldisRequired';
        }

        if (this.state.principlesUsedToModifyScale && this.state.principlesUsedToModifyScale.trim()) {
            let val = striptags(this.state.principlesUsedToModifyScale.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempPrinciplesUsedToModifyScaleErr = 'thisFieldisRequired';
            }
        } else {
            tempPrinciplesUsedToModifyScaleErr = 'thisFieldisRequired';
        }

        this.setState({
            selectedOptionErr: selectedOptionErr,
            currentBasicSalaryErr: currentBasicSalaryErr,
            proposedBasicSalaryErr: proposedBasicSalaryErr,
            currentAnnualBenefitsErr: currentAnnualBenefitsErr,
            proposedAnnualBenefitsErr: proposedAnnualBenefitsErr,
            currentSalariesScaleErr: currentSalariesScaleErr,
            proposedSalariesScaleErr: proposedSalariesScaleErr,
            EffectOfApplyingOnCurrentErr: EffectOfApplyingOnCurrentErr,
            EffectOfApplyingOnProposedErr: EffectOfApplyingOnProposedErr,
            justificationOfEditTheScaleErr: tempJustificationOfEditTheScaleErr,
            principlesUsedToModifyScaleErr: tempPrinciplesUsedToModifyScaleErr,
            approvedBudgetsOfPartErr: approvedBudgetsOfPartErr
        }, () => {
            if (this.state.selectedOption === 'No') {
                if (!selectedOptionErr && !currentBasicSalaryErr && !proposedBasicSalaryErr &&
                    !currentAnnualBenefitsErr && !proposedAnnualBenefitsErr && !currentSalariesScaleErr
                    && !proposedSalariesScaleErr && !EffectOfApplyingOnCurrentErr && !EffectOfApplyingOnProposedErr
                    && !tempJustificationOfEditTheScaleErr && !tempPrinciplesUsedToModifyScaleErr) {
                    if (
                        striptags(this.state.currentSalariesScale) &&
                        striptags(this.state.proposedSalariesScale) &&
                        striptags(this.state.EffectOfApplyingOnCurrent) &&
                        striptags(this.state.EffectOfApplyingOnProposed) &&
                        striptags(this.state.justificationOfEditTheScale) &&
                        striptags(this.state.principlesUsedToModifyScale) &&
                        this.state.proposedBasicSalary &&
                        this.state.currentBasicSalary &&
                        this.state.proposedAnnualBenefits &&
                        this.state.currentAnnualBenefits &&
                        this.state.proposedFinancialEffect &&
                        this.state.currentFinancialEffect &&
                        this.state.selectedOption &&
                        this.state.sourcesFunding &&
                        this.state.avarageForProposedSalariesScale &&
                        this.state.avarageForCurrentSalariesScale
                    ) {
                        this.props.getData.stepOneData({
                            // requestId: this.state.requestId,
                            // isEdit: this.state.isEdit,
                            principlesUsedToModifyScale: this.state.principlesUsedToModifyScale,
                            justificationOfEditTheScale: this.state.justificationOfEditTheScale,
                            proposedBasicSalary: this.state.proposedBasicSalary,
                            currentBasicSalary: this.state.currentBasicSalary,
                            proposedAnnualBenefits: this.state.proposedAnnualBenefits,
                            currentAnnualBenefits: this.state.currentAnnualBenefits,
                            proposedFinancialEffect: this.state.proposedFinancialEffect,
                            currentFinancialEffect: this.state.currentFinancialEffect,
                            selectedOption: this.state.selectedOption,
                            sourcesFunding: this.state.sourcesFunding,
                            approvedBudgetsOfPart: this.state.approvedBudgetsOfPart,
                            proposedSalariesScale: this.state.proposedSalariesScale,
                            currentSalariesScale: this.state.currentSalariesScale,
                            avarageForProposedSalariesScale: this.state.avarageForProposedSalariesScale,
                            EffectOfApplyingOnProposed: this.state.EffectOfApplyingOnProposed,
                            EffectOfApplyingOnCurrent: this.state.EffectOfApplyingOnCurrent,
                            avarageForCurrentSalariesScale: this.state.avarageForCurrentSalariesScale,
                            otherBonusTableData: this.state.otherBonusTableData,
                            otherBenefitTableData: this.state.otherBenefitTableData,
                            sourcesFundingLabel: this.state.sourcesFundingLabel
                        });
                    }
                }
            } else if (this.state.selectedOption === 'Yes') {

                if (!selectedOptionErr && !currentBasicSalaryErr && !proposedBasicSalaryErr &&
                    !currentAnnualBenefitsErr && !proposedAnnualBenefitsErr && !currentSalariesScaleErr
                    && !proposedSalariesScaleErr && !EffectOfApplyingOnCurrentErr && !EffectOfApplyingOnProposedErr
                    && !tempJustificationOfEditTheScaleErr && !tempPrinciplesUsedToModifyScaleErr &&
                    !approvedBudgetsOfPartErr
                ) {
                    if (
                        striptags(this.state.currentSalariesScale) &&
                        striptags(this.state.proposedSalariesScale) &&
                        striptags(this.state.EffectOfApplyingOnCurrent) &&
                        striptags(this.state.EffectOfApplyingOnProposed) &&
                        striptags(this.state.justificationOfEditTheScale) &&
                        striptags(this.state.principlesUsedToModifyScale) &&
                        this.state.proposedBasicSalary &&
                        this.state.currentBasicSalary &&
                        this.state.proposedAnnualBenefits &&
                        this.state.currentAnnualBenefits &&
                        this.state.proposedFinancialEffect &&
                        this.state.currentFinancialEffect &&
                        this.state.selectedOption &&
                        this.state.sourcesFunding &&
                        this.state.avarageForProposedSalariesScale &&
                        this.state.avarageForCurrentSalariesScale
                    ) {
                        this.props.getData.stepOneData({
                            // requestId: this.state.requestId,
                            // isEdit: this.state.isEdit,
                            principlesUsedToModifyScale: this.state.principlesUsedToModifyScale,
                            justificationOfEditTheScale: this.state.justificationOfEditTheScale,
                            proposedBasicSalary: this.state.proposedBasicSalary,
                            currentBasicSalary: this.state.currentBasicSalary,
                            proposedAnnualBenefits: this.state.proposedAnnualBenefits,
                            currentAnnualBenefits: this.state.currentAnnualBenefits,
                            proposedFinancialEffect: this.state.proposedFinancialEffect,
                            currentFinancialEffect: this.state.currentFinancialEffect,
                            selectedOption: this.state.selectedOption,
                            sourcesFunding: this.state.sourcesFunding,
                            approvedBudgetsOfPart: this.state.approvedBudgetsOfPart,
                            proposedSalariesScale: this.state.proposedSalariesScale,
                            currentSalariesScale: this.state.currentSalariesScale,
                            avarageForProposedSalariesScale: this.state.avarageForProposedSalariesScale,
                            EffectOfApplyingOnProposed: this.state.EffectOfApplyingOnProposed,
                            EffectOfApplyingOnCurrent: this.state.EffectOfApplyingOnCurrent,
                            avarageForCurrentSalariesScale: this.state.avarageForCurrentSalariesScale,
                            otherBonusTableData: this.state.otherBonusTableData,
                            otherBenefitTableData: this.state.otherBenefitTableData,
                            sourcesFundingLabel: this.state.sourcesFundingLabel
                        });
                    }
                }
            }

        });
    };

    saveAsDraft = () => {
        
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            saveFrom: 'step1',
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            principlesUsedToModifyScale: this.state.principlesUsedToModifyScale,
            justificationOfEditTheScale: this.state.justificationOfEditTheScale,
            proposedBasicSalary: this.state.proposedBasicSalary,
            currentBasicSalary: this.state.currentBasicSalary,
            proposedAnnualBenefits: this.state.proposedAnnualBenefits,
            currentAnnualBenefits: this.state.currentAnnualBenefits,
            proposedFinancialEffect: this.state.proposedFinancialEffect,
            currentFinancialEffect: this.state.currentFinancialEffect,
            selectedOption: this.state.selectedOption,
            sourcesFunding: this.state.sourcesFunding,
            approvedBudgetsOfPart: this.state.approvedBudgetsOfPart,
            proposedSalariesScale: this.state.proposedSalariesScale,
            currentSalariesScale: this.state.currentSalariesScale,
            avarageForProposedSalariesScale: this.state.avarageForProposedSalariesScale,
            EffectOfApplyingOnProposed: this.state.EffectOfApplyingOnProposed,
            EffectOfApplyingOnCurrent: this.state.EffectOfApplyingOnCurrent,
            avarageForCurrentSalariesScale: this.state.avarageForCurrentSalariesScale,
            otherBonusTableData: this.state.otherBonusTableData,
            otherBenefitTableData: this.state.otherBenefitTableData
        });
    };

    clearForm = () => {
        this.setState({
            principlesUsedToModifyScale: '',
            justificationOfEditTheScale: '',
            proposedBasicSalary: '',
            currentBasicSalary: '',
            proposedAnnualBenefits: '',
            currentAnnualBenefits: '',
            proposedFinancialEffect: '',
            currentFinancialEffect: '',
            selectedOption: '',
            sourcesFunding: '',
            approvedBudgetsOfPart: '',
            proposedSalariesScale: '',
            currentSalariesScale: '',
            avarageForProposedSalariesScale: '',
            EffectOfApplyingOnProposed: '',
            EffectOfApplyingOnCurrent: '',
            avarageForCurrentSalariesScale: '',
            // otherBonusTableData: [],
            // otherBenefitTableData: []
        })
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    handleOptionChange = e => {
      
        this.setState({
            selectedOption: e.target.value,
            selectedOptionErr: ''
        });
    };

    onCurrentSalariesScaleChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ currentSalariesScale: newContent, currentSalariesScaleErr: '' });
        } else {
            this.setState({ currentSalariesScaleErr: 'ckeditorCountErr' });
        }
    }

    onProposedSalariesScaleChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ proposedSalariesScale: newContent, proposedSalariesScaleErr: '' });
        } else {
            this.setState({ proposedSalariesScaleErr: 'ckeditorCountErr' });
        }
    }

    onEffectOfApplyingOnCurrentChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ EffectOfApplyingOnCurrent: newContent, EffectOfApplyingOnCurrentErr: '' });
        } else {
            this.setState({ EffectOfApplyingOnCurrentErr: 'ckeditorCountErr' });
        }
    }

    onEffectOfApplyingOnProposedChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ EffectOfApplyingOnProposed: newContent, EffectOfApplyingOnProposedErr: '' });
        } else {
            this.setState({ EffectOfApplyingOnProposedErr: 'ckeditorCountErr' });
        }
    }

    onjustificationOfEditTheScaleChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ justificationOfEditTheScale: newContent, justificationOfEditTheScaleErr: '' });
        } else {
            this.setState({ justificationOfEditTheScaleErr: 'ckeditorCountErr' });
        }
    }

    onPrinciplesUsedToModifyScaleChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ principlesUsedToModifyScale: newContent, principlesUsedToModifyScaleErr: '' });
        } else {
            this.setState({ principlesUsedToModifyScaleErr: 'ckeditorCountErr' });
        }
    }

    addOtherBonusNameView = () => {
        let obj = {};
        obj.othercurrentmonthlybonus = "";
        obj.otherproposedmonthlybonus = "";
        this.setState({
            otherBonusTableData: this.state.otherBonusTableData.concat(obj),
            view: this.state.view + 1
        });
    }

    deleteOtherBonusNameView = (indexVal) => {
        var array = this.state.otherBonusTableData;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({ otherBonusTableData: array });
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({ otherBonusTableData: array });
                    }
                }
            }
        }
    }

    addOtherBenefitNameView = () => {
        let obj = {};
        obj.othercurrentannualbenefits = "";
        obj.otherproposedannualbenefits = "";
        this.setState({
            otherBenefitTableData: this.state.otherBenefitTableData.concat(obj),
            view: this.state.view + 1
        });
    }

    deleteOtherBenefitNameView = (indexVal) => {
        var array = this.state.otherBenefitTableData;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({ otherBenefitTableData: array });
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({ otherBenefitTableData: array });
                    }
                }
            }
        }
    }

    handleApprovedBudgetOnChange = (value) => {
        if (value) {
            
            this.setState({ approvedBudgetsOfPart: value.formattedValue, approvedBudgetsOfPartErr: "" });
        }
    }

    render() {
        const { currentBasicSalary } = this.state;
        const { proposedBasicSalary } = this.state;
        const { currentAnnualBenefits } = this.state;
        const { proposedAnnualBenefits } = this.state;

        let element1 = this.state.otherBonusTableData && this.state.otherBonusTableData.map((item, index) => {
            return (
                <tr>
                    <td>
                        {
                            index === 0
                                ?
                                <MDBBtn className="primary plusBtn" onClick={() => this.addOtherBonusNameView()}>+</MDBBtn>
                                :
                                <MDBBtn className="primary deleteBtn" onClick={() => this.deleteOtherBonusNameView(index)}>-</MDBBtn>
                        }
                    </td>
                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].other_bonus_name}
                    </td>

                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={item.othercurrentmonthlybonus}
                                onChange={(e) => {
                                    let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
                                    if (regex.test(e.target.value)) {
                                        item.othercurrentmonthlybonus = e.target.value;
                                        this.setState({ othercurrentmonthlybonus: e.target.value });
                                        this.setState({ othercurrentmonthlybonus: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>

                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].other_bonus_name}
                    </td>

                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={item.otherproposedmonthlybonus}
                                onChange={(e) => {
                                    let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
                                    if (regex.test(e.target.value)) {
                                        item.otherproposedmonthlybonus = e.target.value;
                                        this.setState({ otherproposedmonthlybonus: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>
                </tr>
            )
        });

        let element2 = this.state.otherBenefitTableData && this.state.otherBenefitTableData.map((item, index) => {
            return (
                <tr>
                    <td>
                        {
                            index === 0
                                ?
                                <MDBBtn className="primary plusBtn" onClick={() => this.addOtherBenefitNameView()}>+</MDBBtn>
                                :
                                <MDBBtn className="primary deleteBtn" onClick={() => this.deleteOtherBenefitNameView(index)}>-</MDBBtn>
                        }
                    </td>
                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].other_benefit_name}
                    </td>

                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={item.othercurrentannualbenefits}
                                onChange={(e) => {
                                    let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
                                    if (regex.test(e.target.value)) {
                                        item.othercurrentannualbenefits = e.target.value;
                                        this.setState({ othercurrentannualbenefits: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>

                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].other_benefit_name}
                    </td>

                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={item.otherproposedannualbenefits}
                                onChange={(e) => {
                                    let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
                                    if (regex.test(e.target.value)) {
                                        item.otherproposedannualbenefits = e.target.value;
                                        this.setState({ otherproposedannualbenefits: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>
                </tr>
            )
        });

        return (
            <form
                style={{ marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2",]}
                    currentIndex={1}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow className="requiredcondition" style={{ textAlign: this.props.isArabic ? 'right' : 'justify' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <p>{i18n[this.getLocale()].requiredcondition}</p>
                            <p>{i18n[this.getLocale()].sources_funding_for_proposed_increases}</p>
                            <p>{i18n[this.getLocale()].sources_funding_for_proposed_increases_one}</p>
                            <p>{i18n[this.getLocale()].sources_funding_for_proposed_increases_two}</p>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ marginTop: "10px", display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div
                            style={
                                this.props.isArabic
                                    ? { marginBottom: "10px" }
                                    : { marginBottom: "0px" }
                            }>
                            <label
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row",
                                    width: "100%"
                                }}>
                                {
                                    i18n[this.props.isArabic ? 'ar' : 'en']
                                        .sources_funding_for_proposed_increases
                                }
                                <span className="requireStar">*</span>
                            </label>

                            <div class="form-group">
                                <select
                                    class="custom-select browser-default"
                                    required
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    onChange={event => {
                                        let value = event.target.value;
                                        let dropDownLabel = '';
                                        if (parseInt(value) === 1) {
                                            dropDownLabel = i18n[this.props.isArabic ? 'ar' : 'en'].self
                                        } else {
                                            dropDownLabel = i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.goverment
                                        }
                                        this.setState({
                                            sourcesFunding: event.target.value,
                                            sourcesFundingLabel: dropDownLabel
                                        });
                                    }}
                                    value={this.state.sourcesFunding}>
                                    <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}-</option>
                                    <option value='1'>{i18n[this.props.isArabic ? 'ar' : 'en'].self}</option>
                                    <option value='2'>{i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.goverment}</option>
                                </select>

                                <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>

                            </div>
                        </div>

                        <br />
                    </MDBCol>

                    <MDBCol
                        xs="6" sm="6" md="6" lg="6" xl="6"
                        style={
                            this.props.isArabic ?
                                { display: "flex", flexDirection: "column", alignItems: "flex-end" }
                                : null
                        }>
                        <div className="form-group">
                            <label htmlFor="necessary_financial_allocations" style={{ direction: !this.props.isArabic ? 'ltr' : 'rtl' }}>{i18n[this.getLocale()].necessary_financial_allocations}<span className="requireStar">*</span></label>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOption === "Yes"}
                                    class="custom-control-input"
                                    id="customControlValidation2"
                                    onChange={this.handleOptionChange}
                                    name="radio-stacked"
                                    required
                                />

                                <label
                                    class="custom-control-label"
                                    for="customControlValidation2"
                                >
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation3"
                                    name="radio-stacked"
                                    checked={this.state.selectedOption === "No"}
                                    onChange={this.handleOptionChange}
                                    required
                                />

                                <label
                                    class="custom-control-label"
                                    for="customControlValidation3"
                                >
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>
                            </div>

                            <br />

                            <div>
                                {this.state.selectedOptionErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>

                    </MDBCol>
                </MDBRow>

                {
                    this.state.selectedOption === "Yes" ?
                        <MDBRow
                            style={{
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row"
                            }}
                        >
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"></MDBCol>

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label
                                        htmlFor="proposed-financial-effect"
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row-reverse"
                                                : "row",
                                            width: "100%"
                                        }}
                                    >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].approvedBudgetsOfPart}
                                        <span className="requireStar">*</span>
                                    </label>

                                    {/* <input
                                        type="text"
                                        className="form-control"
                                        id="proposed-financial-effect"
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row-reverse"
                                                : "row",
                                            direction: this.props.isArabic ? "rtl" : "ltr"
                                        }}
                                        onChange={event => {
                                            let regex = /^[0-9]{0,15}$/;
                                            if (regex.test(event.target.value)) {
                                                if (event.target.value !== '0') {
                                                    this.setState({
                                                        approvedBudgetsOfPart: event.target.value
                                                    });
                                                }
                                            }
                                        }}
                                        required
                                        value={this.state.approvedBudgetsOfPart}
                                        name="currentFinancialEffect"
                                    />

                                    <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div> */}

                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.approvedBudgetsOfPartErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                        <CurrencyFormat
                                            thousandSeparator={true}
                                            value={this.state.approvedBudgetsOfPart}
                                            onValueChange={(value) => { this.handleApprovedBudgetOnChange(value) }}
                                            display={"text"}
                                            maxLength={10}
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                        />

                                    </div>
                                    {this.state.approvedBudgetsOfPartErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        : null
                }

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <label htmlFor="formGroupExampleInput" style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].salaryScaleSummery}</label>
                        <br />
                        <MDBTable bordered style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}>
                            <MDBTableBody>
                                <tr>
                                    <td width="2%">&nbsp;</td>
                                    <td
                                        width="22%"
                                        style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}> {i18n[this.props.isArabic ? 'ar' : 'en'].current_basic_salary}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td width="22%">
                                        <div className="form-group">
                                            <div style={{ border: this.state.currentBasicSalaryErr !== 'thisFieldIsRequired' ? '' : '1px solid red', borderRadius: '5px' }}>
                                                <Select
                                                    value={currentBasicSalary}
                                                    options={this.props.isArabic ? currentMonthlyBonusOptions_AR : currentMonthlyBonusOptions}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                                    }}
                                                    onChange={(currentBasicSalary) => {
                                                        if (currentBasicSalary) {
                                                            if (currentBasicSalary.length > 0) {
                                                                this.setState({
                                                                    currentBasicSalary: currentBasicSalary,
                                                                    currentBasicSalaryErr: ''
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    currentBasicSalary: null,
                                                                    currentBasicSalaryErr: 'thisFieldIsRequired'
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                currentBasicSalary: null,
                                                                currentBasicSalaryErr: 'thisFieldIsRequired'
                                                            });
                                                        }
                                                        
                                                    }}
                                                    required
                                                    placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                                />
                                            </div>

                                            <div>
                                                {
                                                    this.state.currentBasicSalaryErr === 'thisFieldIsRequired' ?
                                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td width="22%"
                                        style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].proposed_basic_salary}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td width="22%">
                                        <div className="form-group">
                                            <div style={{ border: this.state.proposedBasicSalaryErr !== 'thisFieldIsRequired' ? '' : '1px solid red', borderRadius: '5px' }}>
                                                <Select
                                                    value={proposedBasicSalary}
                                                    options={this.props.isArabic ? currentMonthlyBonusOptions_AR : currentMonthlyBonusOptions}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                                    }}
                                                    onChange={(proposedBasicSalary) => {
                                                        if (proposedBasicSalary) {
                                                            if (proposedBasicSalary.length > 0) {
                                                                this.setState({
                                                                    proposedBasicSalary: proposedBasicSalary,
                                                                    proposedBasicSalaryErr: ''
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    proposedBasicSalary: null,
                                                                    proposedBasicSalaryErr: 'thisFieldIsRequired'
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                proposedBasicSalary: null,
                                                                proposedBasicSalaryErr: 'thisFieldIsRequired'
                                                            });
                                                        }

                                                    }}
                                                    required
                                                    placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                                />
                                            </div>

                                            <div>
                                                {this.state.proposedBasicSalaryErr === 'thisFieldIsRequired' ?
                                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                    </div>
                                                    :
                                                    null}
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                {/* Start Other Annual Bonuses */}
                                {element1}
                                {/* End Other Annual Bonuses */}
                                <tr>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].current_annual_benefits}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <div style={{ border: this.state.currentAnnualBenefitsErr !== 'thisFieldIsRequired' ? '' : '1px solid red', borderRadius: '5px' }}>
                                                <Select
                                                    value={currentAnnualBenefits}
                                                    options={this.props.isArabic ? currentAnnualBenefitOptions_AR : currentAnnualBenefitOptions}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                                    }}
                                                    onChange={(currentAnnualBenefits) => {
                                                        if (currentAnnualBenefits) {
                                                            if (currentAnnualBenefits.length > 0) {
                                                                this.setState({
                                                                    currentAnnualBenefits: currentAnnualBenefits,
                                                                    currentAnnualBenefitsErr: ''
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    currentAnnualBenefits: null,
                                                                    currentAnnualBenefitsErr: 'thisFieldIsRequired'
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                currentAnnualBenefits: null,
                                                                currentAnnualBenefitsErr: 'thisFieldIsRequired'
                                                            });
                                                        }
                                                    }}
                                                    required
                                                    placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                                />
                                            </div>

                                            <div>
                                                {this.state.currentAnnualBenefitsErr === 'thisFieldIsRequired' ?
                                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                    </div>
                                                    :
                                                    null}
                                            </div>

                                        </div>
                                    </td>

                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].proposed_annual_benefits}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <div style={{ border: this.state.proposedAnnualBenefitsErr !== 'thisFieldIsRequired' ? '' : '1px solid red', borderRadius: '5px' }}>
                                                <Select
                                                    value={proposedAnnualBenefits}
                                                    options={this.props.isArabic ? currentAnnualBenefitOptions_AR : currentAnnualBenefitOptions}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                                    }}
                                                    onChange={(proposedAnnualBenefits) => {
                                                        if (proposedAnnualBenefits) {
                                                            if (proposedAnnualBenefits.length > 0) {
                                                                this.setState({
                                                                    proposedAnnualBenefits: proposedAnnualBenefits,
                                                                    proposedAnnualBenefitsErr: ''
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    proposedAnnualBenefits: null,
                                                                    proposedAnnualBenefitsErr: 'thisFieldIsRequired'
                                                                });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                proposedAnnualBenefits: null,
                                                                proposedAnnualBenefitsErr: 'thisFieldIsRequired'
                                                            });
                                                        }

                                                    }}
                                                    required
                                                    placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                                />
                                            </div>

                                            <div>
                                                {this.state.proposedAnnualBenefitsErr === 'thisFieldIsRequired' ?
                                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                    </div>
                                                    :
                                                    null}
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                {/* Start Other Benefit Name */}
                                {element2}
                                {/* End Other Benefit Name */}
                                <tr>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].current_financial_effect}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="noOfDays"
                                                name="noOfDays"
                                                value={this.state.currentFinancialEffect}
                                                required
                                                onChange={(e) => {
                                                    if (e.target.value.trim() != '') {
                                                        //let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
                                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9\b. ]{0,50}$/;
                                                        if (regex.test(e.target.value)) {
                                                            this.setState({ currentFinancialEffect: e.target.value });
                                                        }
                                                    } else {
                                                        this.setState({ currentFinancialEffect: '' })
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].proposed_financial_effect}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id="basicSalaryTb"
                                                name="basicSalaryTb"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                value={this.state.proposedFinancialEffect}
                                                required
                                                onChange={(e) => {
                                                    if (e.target.value.trim() != '') {
                                                        // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
                                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9\b. ]{0,50}$/;
                                                        if (regex.test(e.target.value)) {
                                                            this.setState({ proposedFinancialEffect: e.target.value });
                                                        }
                                                    } else {
                                                        this.setState({ proposedFinancialEffect: '' });
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                </tr>

                                {/* <tr>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].Challenges_of_current_salaries}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.currentSalariesScale}
                                                    required
                                                    onChange={(e) => {
                                                        let regex = /^[\u0621-\u064AA-Za-z0-9]{0,50}$/;
                                                        if (regex.test(e.target.value)) {
                                                            this.setState({ currentSalariesScale: e.target.value });
                                                        }
                                                    }}
                                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                                />

                                                <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                </div>

                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].challenges_of_proposed_salaries}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.proposedSalariesScale}
                                                required
                                                onChange={(e) => {
                                                    let regex = /^[\u0621-\u064AA-Za-z0-9]{0,50}$/;
                                                    if (regex.test(e.target.value)) {
                                                        this.setState({ proposedSalariesScale: e.target.value });
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                </tr> */}

                                <tr>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].avarage_increase_for_current_salaries}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="month"
                                                name="month"
                                                value={this.state.avarageForCurrentSalariesScale}
                                                required
                                                onChange={(e) => {
                                                    let regex = /^[0-9]{0,15}$/;
                                                    if (regex.test(e.target.value)) {
                                                        this.setState({ avarageForCurrentSalariesScale: e.target.value });
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].avarage_increase_for_proposed_salaries}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id="totalSalaryDollar"
                                                name="totalSalaryDollar"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                value={this.state.avarageForProposedSalariesScale}
                                                required
                                                onChange={(e) => {
                                                    let regex = /^[0-9]{0,15}$/;
                                                    if (regex.test(e.target.value)) {
                                                        this.setState({ avarageForProposedSalariesScale: e.target.value });
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                </tr>

                                {/* <tr>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].proposed_scale_on_current}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="noOfDays"
                                                name="noOfDays"
                                                value={this.state.EffectOfApplyingOnCurrent}
                                                required
                                                onChange={(e) => {
                                                    if (e.target.value.trim() != '') {
                                                        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
                                                        if (regex.test(e.target.value)) {
                                                            this.setState({ EffectOfApplyingOnCurrent: e.target.value });
                                                        }
                                                    } else {
                                                        this.setState({ EffectOfApplyingOnCurrent: '' });
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                    <td style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].proposed_scale_on_proposed}
                                        <span className="requireStar">*</span>
                                    </td>
                                    <td>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id="basicSalaryTb"
                                                name="basicSalaryTb"
                                                className="form-control"
                                                aria-label="Amount (to the nearest dollar)"
                                                value={this.state.EffectOfApplyingOnProposed}
                                                required
                                                onChange={(e) => {
                                                    if (e.target.value.trim() != '') {
                                                        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
                                                        if (regex.test(e.target.value)) {
                                                            this.setState({ EffectOfApplyingOnProposed: e.target.value });
                                                        }
                                                    } else {
                                                        this.setState({ EffectOfApplyingOnProposed: '' });
                                                    }
                                                }}
                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                            />

                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>

                                        </div>
                                    </td>
                                </tr> */}

                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label
                                for="currentSalariesScale"
                                style={{
                                    display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.getLocale()].Challenges_of_current_salaries}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.currentSalariesScaleErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.currentSalariesScale}
                                            events={{
                                                "change": this.onCurrentSalariesScaleChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.currentSalariesScale}
                                            events={{
                                                "change": this.onCurrentSalariesScaleChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.currentSalariesScaleErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label
                                for="proposedSalariesScale"
                                style={{
                                    display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.getLocale()].challenges_of_proposed_salaries}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.proposedSalariesScaleErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.proposedSalariesScale}
                                            events={{
                                                "change": this.onProposedSalariesScaleChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.proposedSalariesScale}
                                            events={{
                                                "change": this.onProposedSalariesScaleChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.proposedSalariesScaleErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label
                                for="EffectOfApplyingOnCurrent"
                                style={{
                                    display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.getLocale()].proposed_scale_on_current}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.EffectOfApplyingOnCurrentErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.EffectOfApplyingOnCurrent}
                                            events={{
                                                "change": this.onEffectOfApplyingOnCurrentChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.EffectOfApplyingOnCurrent}
                                            events={{
                                                "change": this.onEffectOfApplyingOnCurrentChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.EffectOfApplyingOnCurrentErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label
                                for="EffectOfApplyingOnProposed"
                                style={{
                                    display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.getLocale()].proposed_scale_on_proposed}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.EffectOfApplyingOnProposedErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.EffectOfApplyingOnProposed}
                                            events={{
                                                "change": this.onEffectOfApplyingOnProposedChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.EffectOfApplyingOnProposed}
                                            events={{
                                                "change": this.onEffectOfApplyingOnProposedChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.EffectOfApplyingOnProposedErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label
                                for="justificationofeditthescale"
                                style={{
                                    display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.getLocale()].justification_of_edit_the_scale}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.justificationOfEditTheScaleErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.justificationOfEditTheScale}
                                            events={{
                                                "blur": this.onBlurEmailEditor,
                                                "afterPaste": this.afterPasteEmailEditor,
                                                "change": this.onjustificationOfEditTheScaleChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.justificationOfEditTheScale}
                                            events={{
                                                "blur": this.onBlurEmailEditor,
                                                "afterPaste": this.afterPasteEmailEditor,
                                                "change": this.onjustificationOfEditTheScaleChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.justificationOfEditTheScaleErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label
                                for="principlesusedtomodifythescale"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}
                            >
                                {i18n[this.getLocale()].principles_used_to_modify_the_scale}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.principlesUsedToModifyScaleErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">

                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.principlesUsedToModifyScale}
                                            events={{
                                                "blur": this.onBlurEmailEditor,
                                                "afterPaste": this.afterPasteEmailEditor,
                                                "change": this.onPrinciplesUsedToModifyScaleChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.principlesUsedToModifyScale}
                                            events={{
                                                "blur": this.onBlurEmailEditor,
                                                "afterPaste": this.afterPasteEmailEditor,
                                                "change": this.onPrinciplesUsedToModifyScaleChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.principlesUsedToModifyScaleErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    className="formRow" style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                    <MDBBtn type="submit" color="primary" className="submitBtn"> {i18n[this.props.isArabic ? "ar" : "en"].next}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>
            </form>
        );
    }
}