import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdbreact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Particles from 'react-particles-js';
import i18n from './../i18n';
import { changePasswordPayload } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import Config from './../utils/Config';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay'
import Switch from "react-switch";

export default class ConfirmPassword extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            checked: localStorage.getItem("Lang") === "ar" ? true : false,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            password: '',
            confirmPassword: '',
            passwordErr: '',
            confirmPwdErr: '',
            PasswordPolicyErr:''
        }
    }

    handlpassChange = (event) => {
        this.setState({ password: event.target.value, passwordErr: '',PasswordPolicyErr:'' }, () => {
        
        });
    };

    handlconfirmPassChange = (event) => {
        this.setState({ confirmPassword: event.target.value, confirmPwdErr: '',PasswordPolicyErr:'' }, () => {
        
        });
    };

    validateEmail = email => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        return regexp.test(email);
    };

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    alertSubmitForError = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.push('/loginScreen')
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        if (this.state.password === '') {
            this.setState({ passwordErr: i18n[this.getLocale()].thisFieldIsRequired })
        } else if (this.state.confirmPassword === '') {
            this.setState({ confirmPwdErr: i18n[this.getLocale()].thisFieldIsRequired })
        } else if (this.state.password !== this.state.confirmPassword) {
            this.setState({ confirmPwdErr: i18n[this.getLocale()].passwrdErr })
        
        }
        else if(this.state.password.length<8)
        {
            this.setState({ PasswordPolicyErr: i18n[this.getLocale()].PasswordPolicy })
        }
        else if((/^(((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[!@#$%^*])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^*]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^*]))|((?=.*[0-9])(?=.*[a-z])(?=.*[!@#$%^*])))(?=.{8,})/).test(this.state.password)==false)
        {
            
            this.setState({ PasswordPolicyErr: i18n[this.getLocale()].PasswordPolicy })
            
        }
         else {
            // call the password reset api 
            let obj = {};
            obj.newpassword = this.state.password;
            obj.confirmpassword = this.state.confirmPassword;
            // call save status update api
            let payload = changePasswordPayload(obj);
            let endPoint = 'changepassword'
            let url = Config.getUrl(endPoint);
            

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                    this.setState({
                        isLoading: false
                    }, () => {
                        if (res.Status) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].message,
                                messageAlert: i18n[this.getLocale()].password_change
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].failed_to_change_password
                            }, () => {
                                this.alertSubmitForError();
                            })
                        }
                    })
                })
            })
        }
    };

    handleSwitch = checked => {
        if (this.state.checked === true) {
            this.setState({ isArabic: false });
        } else {
            this.setState({ isArabic: true });
        }
        this.setState({ checked });
    };

    render() {

        const logo = require('../assets/images/logo_bg.png');
        return (

            <MDBContainer fluid style={{ overflow: 'hidden' }}>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <MDBRow className="header" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md="8"></MDBCol>
                        <MDBCol md="4" style={{ textAlign: this.state.isArabic ? 'right' : 'left', paddingRight: this.state.isArabic ? '3%' : 0, paddingLeft: this.state.isArabic ? 0 : '3%', }}>
                            <img src={logo} alt="" className="logo"></img>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="loginBoxContainer" md="12" style={{ backgroundColor: '#6a99ad', height: '100vh', width: '100vw' }}>
                        {/* <MDBCol>
                            <Particles style={{ zIndex: 1 }} params={{ "particles": { "number": { "value": 50 }, "size": { "value": 3 } }, "interactivity": { "events": { "onhover": { "enable": true, "mode": "repulse" } } } }} />
                        </MDBCol> */}

                        <MDBCol md="12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '30%', margin: '0 auto', zIndex: 20 }}>
                            {/* <MDBCard style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30vw', height: '60vh' }}> */}
                            {/* <MDBCardBody> */}
                            <form
                                style={{ marginTop: 20 }}
                                className="needs-validation"
                                onSubmit={this.submitHandler}
                                noValidate >

                                <MDBRow>
                                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                                            <labe style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'ge_ss_text_Regular' }}> {i18n[this.getLocale()].resetPass}</labe>
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="formRow">
                                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div className="form-group">
                                            <label style={{ fontFamily: 'ge_ss_text_Regular', display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                                {i18n[this.getLocale()].password}
                                                <span style={{ flexDirection: this.state.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                                            </label>

                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                maxLength={50}
                                                minLength={8}
                                                name="password"
                                                style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                                value={this.state.password}
                                                onChange={this.handlpassChange}
                                                required
                                            />
                                            {this.state.passwordErr !== '' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
                                                    {this.state.passwordErr}
                                                </div>
                                                :
                                                null}
                                        </div>

                                    </MDBCol>

                                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div className="form-group">
                                            <label style={{ fontFamily: 'ge_ss_text_Regular', display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                                {i18n[this.getLocale()].confirmPass}
                                                <span style={{ flexDirection: this.state.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                                            </label>

                                            <input
                                                type="password"
                                                className="form-control"
                                                id="confirmPwd"
                                                maxLength={50}
                                                minLength={8}
                                                name="confirmPwd"
                                                style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                                value={this.state.confirmPassword}
                                                onChange={this.handlconfirmPassChange}
                                                required
                                            />

                                            {this.state.confirmPwdErr !== '' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
                                                    {this.state.confirmPwdErr}
                                                </div>
                                                :
                                                null}

                                        </div>
                                    </MDBCol>
                                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className="form-group">
                                    {this.state.PasswordPolicyErr !== '' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
                                                    {this.state.PasswordPolicyErr}
                                                </div>
                                                :
                                                null}
                                                </div>
                                        </MDBCol>
                                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                    {this.state.PasswordPolicyErr == '' ?
                                                <div  style={{ width: '100%', display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
                                                    {this.state.PasswordPolicyErr}
                                                </div>
                                                :
                                                null}
                                        </MDBCol>

                                </MDBRow>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "20px",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                    <label
                                        style={{
                                            paddingRight: "10px",
                                            paddingLeft: "10px",
                                            fontFamily: "ge_ss_text_Regular",
                                            fontSize: "14px",
                                            color: "#999999"
                                        }}>
                                        {i18n[this.getLocale()].english}
                                    </label>

                                    <Switch
                                        onChange={this.handleSwitch}
                                        checked={this.state.checked}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        height={20}
                                        onColor={"#6a99ad"}
                                        offColor={"#6a99ad"}
                                    />

                                    <label
                                        style={{
                                            paddingRight: "10px",
                                            paddingLeft: "10px",
                                            fontFamily: "ge_ss_text_Regular",
                                            fontSize: "14px",
                                            color: "#999999"
                                        }}
                                    >
                                        {i18n[this.getLocale()].arabic}
                                    </label>
                                </div>

                                <MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <MDBBtn type='submit' color="primary" style={{ borderRadius: 5, fontFamily: 'ge_ss_text_Regular' }} >
                                        {i18n[this.getLocale()].submit}</MDBBtn>
                                </MDBRow>
                            </form>

                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                        </MDBCol>
                    </MDBRow>

                </LoadingOverlay>

            </MDBContainer>
        )
    }
}