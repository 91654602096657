import React from "react";
import ReactExport from "react-data-export";
import i18n from './../i18n';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class DownloadExcel extends React.Component {
    render() {
        return (
            <ExcelFile
                filename={i18n[this.props.isArabic ? 'ar' : 'en'].statushistory}
                element={<a href="#"><img
                    style={{
                        float: this.props.isArabic ? 'left' : 'right', height: '50px', width: '50px'
                    }}
                    alt="" src={require('./../assets/images/BlueDownloads.png')}
                /></a>}>
                <ExcelSheet data={this.props.historyArray} name="Employees">
                    <ExcelColumn label="Updated By" value="updatedby" />
                    <ExcelColumn label="Updated On" value="updatedon" />
                    <ExcelColumn label="Status" value="status" />
                    <ExcelColumn label="Status reason" value="statusreason" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}