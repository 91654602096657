
import React, { Component } from 'react';
import ApiRequest from '../../services/ApiRequest';
import Config from '../../utils/Config';
import {
    getFunctionBlockPayload, saveFunctionalDataPayload,
    deleteFunctionalDataPayload, downloadAttachment
} from '../../utils/Payload';
import Locali18n from './Locali18n';
import ToastComponent from './../ToastComponent';

import i18n from '../../i18n';

import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';

import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import './../../css/react-confirm-alert.css';
import SortableTree, { toggleExpandedForAll, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { relativeTimeThreshold } from 'moment';

var _ = require('lodash');
const striptags = require('striptags');

let info = {};

export default class OrganizationStructureFunctions extends Component {

    constructor(props) {

        super(props);
        this.state = {
            reload: true,
            edit: 0,
            positiondetails: false,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,

            canvieworganizationsturcturetree: 1,
            isLoading: false,
            searchString: '',
            searchFocusIndex: 0,
            searchFoundCount: null,
            treeData: [],
            viewData: [],
            rowInfo: null,

            modal: false,
            modalOuterAddNode: '',
            modalAddNode: '',
            modalPreview: '',

            modalUpdateNode: '',
            modalPeview: '',
            modalTitle: '',
            modalContent: '',
            parentNodeValue: '',
            nodeType: '',
            englishName: '',
            arabicName: '',
            typeArray: [],

            positionsModal: false,
            positionsBasicModal: false,
            FTEModal: false,
            UnitDetailsModal: false,
            titleAlert: '',
            messageAlert: '',
            positiontypeid: '',
            treetype: '0',
            branch: 0,
            level: 0,
            loginData: '',
            canvieworgrequest: 0,
            canvieworgrequesttree: 0,
            canviewtree: 0,
            entitySelectedValue: 0,

        };


    }


    getcolor=(rowinfo)=>
    {

        
        if(rowinfo.node.draft === 1)
             {        
              return "btn btn-danger btn-sm";
        }
        else
         {
          if(rowinfo.node.typeid === 0 )
              return "btn btn-primary btn-sm Ripple-parent gridActionBtn";
         else
              return "btn btn-warning btn-sm";

         }
    
    }

    createDesign = () => {

        this.setState({
            isLoading: true
        }, () => 
        this.upLoadDesign());

    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    DownloadDesign = () => {
        
        let obj = {}
        obj.filename = this.state.loginData.EntityID + '.jpg';
        obj.requesttype = 0;
        obj.requestid = this.state.loginData.EntityID;


        let payload = downloadAttachment(obj);
        let endPoint = 'DownloadAttachment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        
                        let fileData = res.data;
                        let filecontent = fileData.filecontent;
                        let contenttype = fileData.contenttype;
                        let blob = this.b64toBlob(filecontent, contenttype);
                        
                        if (navigator.msSaveBlob) {

                            window.navigator.msSaveBlob(blob, obj.requestid);
                        } else {
                            let a = window.document.createElement('a');

                            a.href = URL.createObjectURL(blob);
                            //a.href = `data:${contenttype};base64,${filecontent}`;
                            a.download = obj.requestid;
                            a.text = 'download';
                            // Append anchor to body.
                            document.body.appendChild(a);
                            a.click();
                            // Remove anchor from body
                            document.body.removeChild(a);
                            this.setState({ isLoading: false });
                        }
                    } else {
                        //alert(res.Message);
                        // console.log(res.Message);
                        // i18n[this.getLocale()].fileName
                        let tempFileNotFoundMessage = i18n[this.getLocale()].filenotfound
                        if (res.Message === 'filenotfound') {
                            ToastComponent.notifyInfo(tempFileNotFoundMessage);
                        }
                        this.setState({ isLoading: false });
                    }
                })
            })
        })
    }
    upLoadDesign = () => {
        
        console.log(this.state);
        let payload = {};

        // payload.filename = this.state.loginData.EntityNameAr;
        payload.filename = this.state.loginData.EntityID + '.jpg';
        payload.entityid = this.state.loginData.EntityID;
        payload.lang = 1;
      payload.entityname = this.state.loginData.EntityNameAr

        let endPoint = 'GetFinalDesign'  
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {

            ApiRequest.sendRequestForPost(url, payload, res => {
              this.DownloadDesign()
                
            })
        })

    }



    handleChangeForEntity = (event) => {
        this.setState({ entitySelectedValue: event.target.value }
            , () => this.getAllFunctionDataPreview(0));
        Config.setCurrentEntity(event.target.value);

    };

    getAllFunctionDataPreview = (positionId) => {


        let payload = getFunctionBlockPayload();
        payload.positionid = positionId;
        payload.EntityID = this.state.entitySelectedValue;
        this.ttype ? payload.typeid = this.ttype : payload.typeid = 0;

        let endPoint = 'GetTreeNodes'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {

            ApiRequest.sendRequestForPost(url, payload, res => {

                this.setState({
                    isLoading: false
                }, () => {

                    if (res.Status) {


                        let expanded = true;
                        this.setState(({

                            treeData: toggleExpandedForAll({

                                treeData: res.data,
                                expanded,
                            })
                        }), () => {

                        })
                    } else {

                    }
                })
            })
        })


    }

    ReloadTreePreview = (e) => {

        this.ttype = e.target.value;
        this.setState({
            treetype: e.target.value
        });

        this.reload(this.state.entitySelectedValue);
    }

    ReloadTree = (e) => {

        this.ttype = e.target.value;
        this.setState({
            treetype: e.target.value
        });
        Config.setCurrentTreeView(e.target.value);
        this.reload(0);
    }

    reload = (value) => {
        
        if (value === 0)
            this.getAllFunctionData(0);
        else {
            this.setState({ entitySelectedValue: value });
            this.getAllFunctionDataPreview(0);
        }


      
        let tempTypeArray = [];
        if (Config.getFunctionalBlockTypeData()) {
            tempTypeArray = Config.getFunctionalBlockTypeData();
        }
        this.setState({
            typeArray: tempTypeArray
        })
    }

    getAllFunctionData = (positionId) => {


        let payload = getFunctionBlockPayload();
        payload.positionid = positionId;
        this.ttype ? payload.typeid = this.ttype : payload.typeid = 0;

        let endPoint = 'GetTreeNodes'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {

            ApiRequest.sendRequestForPost(url, payload, res => {

                this.setState({
                    isLoading: false
                }, () => {

                    if (res.Status) {


                        let expanded = true;
                        this.setState(({

                            treeData: toggleExpandedForAll({

                                treeData: res.data,
                                expanded,
                            })
                        }), () => {

                        })
                    } else {

                    }
                })
            })
        })

    }


    getnodetypes = () => {


        let payload = getFunctionBlockPayload();
        let endPoint = 'GetUnitesTypes'
        let url = Config.getUrl(endPoint);
        ApiRequest.sendRequestForPost(url, payload, res => {
            if (res.Status) {

                this.setState({ typeArray: res.data });
            }
            else {
                return;
            }

        });

    }

    getUnitesTypes = () => {
        let payload = {};
        let endPoint = 'GetUnitesTypes'
        let url = Config.getUrl(endPoint);
        ApiRequest.sendRequestForPost(url, payload, res => {
            let tempSystemListArray = [];
            if (res.Status) {
                let resSystemListData = res.data;
                if (resSystemListData && resSystemListData.length > 0) {
                    for (let index = 0; index < resSystemListData.length; index++) {
                        tempSystemListArray.push(resSystemListData[index]);
                    }
                    this.setState({
                        systemListArray: tempSystemListArray
                    }, () => {
                        Config.setSystemList(this.state.systemListArray);
                    })
                }
            } else {
                // TODO
                Config.setSystemList(tempSystemListArray);
            }
        })
    }
    HandleCheckBoxBranch = () => {

        info.node.branch === 0 ? this.setState({ branch: 1 }) : this.setState({ branch: 0 });
        info.node.branch === 0 ? info.node.branch = 1 : info.node.branch = 0;

    }
    updateUnittype = (e) => {

        this.setState({ nodeType: e.target.value });
        info.node.unittype = e.target.value;

    }
    showpositionsModal = (rowInfo, status, details) => {
        
        this.setState({ rowInfo: rowInfo, edit: status, positiondetails: details });
        rowInfo.node.edit = status;
        this.setState({
            positionsModal: !this.state.positionsModal,
            parentNodeValue: rowInfo.node.title,
            englishName: '',
            arabicName: '',
            rowInfo: rowInfo

        });
    }
 showpositionsBasicModal = (rowInfo, status, details) => {
        
        this.setState({ rowInfo: rowInfo, edit: status, positiondetails: details });
        rowInfo.node.edit = status;
        this.setState({
            positionsBasicModal: !this.state.positionsBasicModal,
            parentNodeValue: rowInfo.node.title,
            englishName: '',
            arabicName: '',
            rowInfo: rowInfo

        });

    }
    closepositionsModal = () => {

        this.setState({
            positionsModal: !this.state.positionsModal,

        });
    }
    closepositionsBasicModal = () => {

        this.setState({
            positionsBasicModal: !this.state.positionsBasicModal,

        });

        let adminroles=[];
        Config.setOrganizationStructurAdminRoles(adminroles);
        let organizationalroles=[];
        Config.setOrganizationStructurOrganizationalRoles(organizationalroles);
        let functionalroles=[];
        Config.setOrganizationStructurFunctionalRoles(functionalroles);
    }
    showUnitDetailsModal = (rowInfo) => {

        this.setState({
            UnitDetailsModal: !this.state.UnitDetailsModal,
            rowInfo: rowInfo
        });
    }
    closeUnitDetailsModal = () => {

        this.setState({
            UnitDetailsModal: !this.state.UnitDetailsModal,

        });
    }
    showFTEModal = (rowInfo) => {
        this.setState({
            FTEModal: !this.state.FTEModal,
            parentNodeValue: rowInfo.node.title,
            englishName: '',
            arabicName: '',
            rowInfo: rowInfo
        });

    }
    closeFTEModal = (rowInfo) => {
        this.setState({
            FTEModal: !this.state.FTEModal,

        });

    }

    loadPositions = (rowInfo) => {
        this.getAllFunctionData(rowInfo.node.id);

    }



    



    handleTreeOnChange = treeData => {
        this.setState({ treeData });
    };

    handleSearchOnChange = e => {
        this.setState({
            searchString: e.target.value,
        });
    };

    selectPrevMatch = () => {
        const { searchFocusIndex, searchFoundCount } = this.state;

        this.setState({
            searchFocusIndex:
                searchFocusIndex !== null
                    ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                    : searchFoundCount - 1,
        });
    };

    selectNextMatch = () => {
        const { searchFocusIndex, searchFoundCount } = this.state;

        this.setState({
            searchFocusIndex:
                searchFocusIndex !== null
                    ? (searchFocusIndex + 1) % searchFoundCount
                    : 0,
        });
    };

    toggleNodeExpansion = expanded => {
        this.setState(prevState => ({
            treeData: toggleExpandedForAll({
                treeData: prevState.treeData,
                expanded,
            }),
        }));
    };

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }


    toggleModalPositionsPopup = () => {
        this.setState({
            positionsModal: !this.state.positionsModal
        });
    }


    handleEnglishName = (event) => {
        // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
        // if (regex.test(event.target.value)) {
        this.setState({ englishName: event.target.value });
        info.node.nameen = event.target.value;
        //  }
    };

    handleArabicName = (event) => {
        // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
        //if (regex.test(event.target.value)) {
        this.setState({ arabicName: event.target.value });
        info.node.namear = event.target.value;
        // }
    };

    showAddNodePopup = (rowInfo) => {

        this.setState({ rowInfo: rowInfo });
        info = rowInfo;

        this.setState({
            modalAddNode: !this.state.modalAddNode,
            parentNodeValue: rowInfo.node.title,
            englishName: '',
            arabicName: '',


        });

    }


    Preview = (rowInfo) => {

        this.setState({ rowInfo: rowInfo });
        info = rowInfo;

        this.setState({
            modalPeview: !this.state.modalPeview,

        });

    }

    closeAddNodePopup = () => {

        this.setState({
            modalAddNode: false,

        });

    }

    closePreview = () => {

        this.setState({
            modalPeview: false,

        });

    }

    addNode = (event) => {

        
        event.preventDefault();
        event.target.className += " was-validated";

        //let { path } = this.info;



        if (info.node.Parentid !== '' && info.node.typeid !== '' &&
            info.node.nameen !== '' && info.node.namear !== '') {



            // save function data
            let obj = {};
            obj.parenttypeid = 0;
            obj.nameEN = info.node.nameen;
            obj.nameAR = info.node.namear;
            obj.typeId = 0;
            obj.unittype = info.node.unittype;
            obj.branch = info.node.branch;
            obj.level = this.state.level;


            if (info.edit === 1) {
                obj.Id = info.node.id;
                obj.parentId = info.node.Parentid;

            }
            else {
                obj.Id = 0;
                obj.parentId = info.node.id;

            }
            let payload = saveFunctionalDataPayload(obj);

            let endPoint = 'SaveUnit';

            let url = Config.getUrl(endPoint);



            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                    this.setState({
                        isLoading: false
                    }, () => {
                        if (res.Status) {

                            this.props.reload(0);
                            ToastComponent.notifyInfo(
                                info.node.id === '0' ? Locali18n[this.getLocale()].nodeadded
                                    : Locali18n[this.getLocale()].nodeadded
                            );

                        } else {
                            ToastComponent.notifyInfo(
                                info.node.id === '0' ? Locali18n[this.getLocale()].failed
                                    : Locali18n[this.getLocale()].failed
                            );
                        }
                    })

                })
            })

        }
    }

    deleteFunctionalDataBlock = (rowInfo) => {
        
        let obj = {};
        let endPoint = '';
        obj.id = rowInfo.node.id;

        let payload = deleteFunctionalDataPayload(obj);

        rowInfo.node.typeid === 0 ? endPoint = 'DeleteUnit' : endPoint = 'DeletePosition'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        this.reload(0);
                        ToastComponent.notifyInfo(
                           
                                Locali18n[this.getLocale()].nodedeleted)
                        }
                        else
                        {
                            this.props.reload(0);
                            ToastComponent.notifyInfo(
                               Locali18n[this.getLocale()].failed)
                        }
                })
            })
        })
    }


    removeNode = (rowInfo) => {
        let { node, treeIndex, path } = rowInfo;
       
                        // call delete API
                        let tempChilderArray = rowInfo.node.children;

                        if (tempChilderArray.length == 0) {
                            this.deleteFunctionalDataBlock(rowInfo);
                         

                        }
                        

                  
        };
        
    

    showUpdateNodePopup = (rowInfo) => {
        this.setState({
            modalAddNode: !this.state.modalAddNode,
            englishName: rowInfo.node.title,
            arabicName: rowInfo.node.arabicTitle,
            rowInfo: rowInfo

        });

    }
    closeUpdateNodePopup = () => {
        this.setState({
            modalUpdateNode: !this.state.modalUpdateNode,

        });

    }





    updateLevel = (e) => {

        this.setState({ level: e.target.value });
        info.node.level = e.target.value;

    }

    alertSubmitForSuccess = () => {

        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.message,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.reload()
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForFail = () => {


        confirmAlert({
            title: Locali18n[this.getLocale()].failed,
            message: Locali18n[this.getLocale()].processfailed,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.getAllFunctionData(true)
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    updateTreeData = (treeData) => {
        this.setState({ treeData });
    }


    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    handleViewTreeNavigation = (route) => {
        localStorage.setItem('currentPath', 'hierarchyContainer');
        this.setState({
            pathName: 'hierarchyContainer'
        }, () => {
            this.props.history.replace('/hierarchyContainer');

        })
    }

   }

