import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
// import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from './../components/Header';
import SideBar from './../components/SideBar';
import AmendSalariesScaleStep1 from './../components/AmendSalaryScaleSteps/StepOne';
import AmendSalariesScaleStep2 from './../components/AmendSalaryScaleSteps/StepTwo';
import Config from './../utils/Config';
import i18n from './../i18n';
import Footer from './../components/Footer';
import SubHeaderStrip from './../components/SubHeaderStrip';
import ApiRequest from './../services/ApiRequest';
import { saveAmendSalaryScalePayload, uploadDocument, saveAmendSalaryScaleMatrixPayload } from './../utils/Payload';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';

export default class AmendSalaryScreen extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            requestId: '',
            isEdit: false,
            statusid: '',
            formType: '',
            canviewbonusrequest: 0,
            canviewamendscalerequest: 0,
            canviewamendsalaryrequest: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            salariesCompensationPolicy: '',
            approvedScaleOfGrades: '',
            principlesUsedToModifyScale: '',
            justificationOfEditTheScale: '',
            tableOfTheAlignment: '',
            proposedScale: '',
            illustrationsOfCurrent: '',
            illustrationsOfCurrentShortName: '',
            comparativeStudy: '',
            necessaryFinancialAllocations: '',
            sourcesFunding: "",
            detailsOfAnnualCost: '',
            //copyOfTheDecision: '',
            currentBasicSalary: null,
            proposedBasicSalary: null,
            currentAnnualBenefits: null,
            proposedAnnualBenefits: null,
            proposedFinancialEffect: '',
            currentFinancialEffect: '',
            proposedSalariesScale: '',
            currentSalariesScale: '',
            avarageForProposedSalariesScale: '',
            avarageForCurrentSalariesScale: '',
            EffectOfApplyingOnProposed: '',
            EffectOfApplyingOnCurrent: '',
            entityChairman: '',
            hraStudy: '',
            employeeStudyMaker: '',
            selectedOption: '',
            isSideBarMinimized: false,
            approvedBudgetsOfPart: '',
            // otherBonusTableData: [{
            //     otherBonusName1: '',
            //     otherBonusName2: ''
            // }],
            // otherBenefitTableData: [{
            //     otherBenefitName1: '',
            //     otherBenefitName2: '',
            // }],
            otherBonusTableData: [{
                othercurrentmonthlybonus: '',
                otherproposedmonthlybonus: ''
            }],
            otherBenefitTableData: [{
                othercurrentannualbenefits: '',
                otherproposedannualbenefits: '',
            }],
            sourcesFundingLabel: ''
        }
    };

    componentDidMount = () => {
        
        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewbonusrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                    canviewamendscalerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                    canviewamendsalaryrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest
                }, () => {
                })
            })
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    componentWillMount() {
        let previewFlag = Config.getIsPreviewFlag();
        let previewCKObj = Config.getPreviewckEditorArray();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();

        let approvedScaleOfGrades = '',
            salariesCompensationPolicy = '',
            tableOfTheAlignment = '',
            proposedScale = '',
            entityChairman = '',
            illustrationsOfCurrent = '',
            comparativeStudy = '',
            detailsOfAnnualCost = '';

        if (previewFlag) {

            let
                proposedBasicSalaryArr = [],
                currentBasicSalaryArr = [],
                proposedAnnualBenefitsArr = [],
                proposedBasicSalaryObj = [],
                currentAnnualBenefitsArr = [],
                currentBasicSalaryObj = [],
                proposedAnnualBenefitsObj = [],
                currentAnnualBenefitsObj = [];

            if (previewObj.table.proposedBascSalaryfunding) {
                proposedBasicSalaryObj = previewObj.table.proposedBascSalaryfunding.split(",");
                if (proposedBasicSalaryObj.length) {
                    for (let index = 0; index < proposedBasicSalaryObj.length; index++) {
                        proposedBasicSalaryArr.push({ value: proposedBasicSalaryObj[index], label: proposedBasicSalaryObj[index] })
                    }
                }
            }

            if (previewObj.table.currentBasicSalarymonthly) {
                currentBasicSalaryObj = previewObj.table.currentBasicSalarymonthly.split(",");
                if (currentBasicSalaryObj.length) {
                    for (let index = 0; index < currentBasicSalaryObj.length; index++) {
                        currentBasicSalaryArr.push({ value: currentBasicSalaryObj[index], label: currentBasicSalaryObj[index] })
                    }
                }
            }

            if (previewObj.table.proposedAnnualBenefits) {
                proposedAnnualBenefitsObj = previewObj.table.proposedAnnualBenefits.split(",");
                if (proposedAnnualBenefitsObj.length) {
                    for (let index = 0; index < proposedAnnualBenefitsObj.length; index++) {
                        proposedAnnualBenefitsArr.push({ value: proposedAnnualBenefitsObj[index], label: proposedAnnualBenefitsObj[index] })
                    }
                }
            }

            if (previewObj.table.currentAnnualBenefits) {
                currentAnnualBenefitsObj = previewObj.table.currentAnnualBenefits.split(",");
                if (currentAnnualBenefitsObj.length) {
                    for (let index = 0; index < currentAnnualBenefitsObj.length; index++) {
                        currentAnnualBenefitsArr.push({ value: currentAnnualBenefitsObj[index], label: currentAnnualBenefitsObj[index] })
                    }
                }
            }
          
            for (let index = 0; index < previewAttachmentArray.length; index++) {
                if (previewAttachmentArray[index].label === 'approvedScaleOfGrades') {
                    approvedScaleOfGrades = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'salariesCompensationPolicy') {
                    salariesCompensationPolicy = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'tableOfTheAlignment') {
                    tableOfTheAlignment = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'proposedScale') {
                    proposedScale = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'entityChairman') {
                    entityChairman = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'illustrationsOfCurrent') {
                    illustrationsOfCurrent = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'comparativeStudy') {
                    comparativeStudy = previewAttachmentArray[index].fileName;

                } else if (previewAttachmentArray[index].label === 'detailsOfAnnualCost') {
                    detailsOfAnnualCost = previewAttachmentArray[index].fileName;
                }
            }

            let principlesUsedToModifyScale = '',
                justificationOfEditTheScale = '',
                proposedSalariesScale = '',
                currentSalariesScale = '',
                EffectOfApplyingOnProposed = '',
                EffectOfApplyingOnCurrent = '',
                selectedOptionFinancial = '';

            for (let index = 0; index < previewCKObj.length; index++) {
                if (previewCKObj[index].label === 'principlesUsedToModifyScale') {
                    principlesUsedToModifyScale = previewCKObj[index].value;
                } else if (previewCKObj[index].label === 'justificationOfEditTheScale') {
                    justificationOfEditTheScale = previewCKObj[index].value;
                } else if (previewCKObj[index].label === 'proposedSalariesScale') {
                    proposedSalariesScale = previewCKObj[index].value;
                } else if (previewCKObj[index].label === 'currentSalariesScale') {
                    currentSalariesScale = previewCKObj[index].value;
                } else if (previewCKObj[index].label === 'EffectOfApplyingOnProposed') {
                    EffectOfApplyingOnProposed = previewCKObj[index].value;
                } else if (previewCKObj[index].label === 'EffectOfApplyingOnCurrent') {
                    EffectOfApplyingOnCurrent = previewCKObj[index].value;
                } else if (previewCKObj[index].label === 'selectedOptionFinancial') {
                    selectedOptionFinancial = previewCKObj[index].value;
                }
            }

            if (selectedOptionFinancial) {
                if (selectedOptionFinancial === i18n["ar"].yes || selectedOptionFinancial === i18n["en"].yes) {
                    selectedOptionFinancial = "Yes";
                }
                else if (selectedOptionFinancial === i18n["ar"].no || selectedOptionFinancial === i18n["en"].no) {
                    selectedOptionFinancial = "No";
                }
            }

            this.setState({
                step1: false,
                step2: true,
                proposedBasicSalary: proposedBasicSalaryArr,
                currentBasicSalary: currentBasicSalaryArr,
                proposedAnnualBenefits: proposedAnnualBenefitsArr,
                currentAnnualBenefits: currentAnnualBenefitsArr,
                proposedFinancialEffect: previewObj.table.proposedFinancialEffect,
                currentFinancialEffect: previewObj.table.currentFinancialEffect,
                selectedOption: selectedOptionFinancial,
                sourcesFunding: previewObj.sourcesFunding,
                sourcesFundingLabel: previewObj.sourcesFundingLabel,
                approvedBudgetsOfPart: previewObj.approvedBudgetsOfPart,
                otherBonusTableData: previewObj.table.otherBonusTableData,
                otherBenefitTableData: previewObj.table.otherBenefitTableData,
                avarageForProposedSalariesScale: previewObj.table.avarageForProposedSalariesScale,
                avarageForCurrentSalariesScale: previewObj.table.avarageForCurrentSalariesScale,
                approvedScaleOfGrades: approvedScaleOfGrades,
                salariesCompensationPolicy: salariesCompensationPolicy,
                tableOfTheAlignment: tableOfTheAlignment,
                proposedScale: proposedScale,
                entityChairman: entityChairman,
                illustrationsOfCurrent: illustrationsOfCurrent,
                illustrationsOfCurrentShortName: previewObj.illustrationsOfCurrentShortName,

                comparativeStudy: comparativeStudy,
                detailsOfAnnualCost: detailsOfAnnualCost,

                principlesUsedToModifyScale: principlesUsedToModifyScale,
                justificationOfEditTheScale: justificationOfEditTheScale,
                proposedSalariesScale: proposedSalariesScale,
                currentSalariesScale: currentSalariesScale,
                EffectOfApplyingOnProposed: EffectOfApplyingOnProposed,
                EffectOfApplyingOnCurrent: EffectOfApplyingOnCurrent
            });
        }
    }

    uploadFile = (i, totalCount, res, multifileCount) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};
            let isLastFileInMultiUpload = false;

            if (i === 0) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile1();
                obj.filename = this.state.salariesCompensationPolicy;
                obj.filetype = Config.getAmendSalaryScaleSalariesAndConpansationAttachmentId();
                tempBase64 = fileObj;
            }
            else if (i === 1) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile2();
                obj.filename = this.state.approvedScaleOfGrades;
                obj.filetype = Config.getAmendSalaryScaleApprovedScaleAndGradeAttachmentId();
                tempBase64 = fileObj;
            }
            else if (i === 2) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile3();
                obj.filename = this.state.proposedScale;
                obj.filetype = Config.getAmendSalaryScalePropsedScaleAttachmentId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile4();
                obj.filename = this.state.tableOfTheAlignment;
                obj.filetype = Config.getAmendSalaryScaleAlignmentTableGradeAttachmentId();
                tempBase64 = fileObj;
            }
            else if (i === 4) {
                // get base 64 for attachement
                let fileObjArray = Config.getbase64ofAmendSalaryScreenfile5();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }
                // let filenamesArray = this.state.illustrationsOfCurrent.split(",");
                // obj.filename = filenamesArray[multifileCount];
                if (this.state.illustrationsOfCurrent !== null) {
                    let filenamesArray = this.state.illustrationsOfCurrent.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                obj.filetype = Config.getAmendSalaryScaleCurrentAndProposedSalaryLineAttachmentId();
                tempBase64 = fileObj;
            }
            else if (i === 5) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile6();
                obj.filename = this.state.comparativeStudy;
                obj.filetype = Config.getAmendSalaryScaleStudyWithSimilarEntityAttachmentId();
                tempBase64 = fileObj;
            }
            else if (i === 6) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile7();
                obj.filename = this.state.detailsOfAnnualCost;
                obj.filetype = Config.getAmendSalaryScaleAnnualCostAttachmentId();
                tempBase64 = fileObj;
            }
            // else if (i === 7) {
            //     // get base 64 for attachement
            //     fileObj = Config.getbase64ofAmendSalaryScreenfile8();
            //     obj.filename = this.state.copyOfTheDecision;
            //     obj.filetype = Config.getAmendSalaryScaleAdminAuthIsRegisteredAttachmentId();
            //     tempBase64 = fileObj;
            // }
            else if (i === 7) {
                // get base 64 for attachement
                fileObj = Config.getbase64ofAmendSalaryScreenfile9();
                obj.filename = this.state.entityChairman;
                obj.filetype = Config.getAmendSalaryScaleChairmanApproveAttachmentId();
                tempBase64 = fileObj;
            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (res && res.data && res.data.id) {
                    reqId = res.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.fileContent = tempBase64.split(',')[1];
                // below commented code will use insted of include method
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    this.setState({
                        isLoading: true
                    }, () => {
                        if (resForEntityFIleRequestFile.Status) {
                            if (i === 4) {
                                if (isLastFileInMultiUpload) {
                                    this.uploadFile(i + 1, totalCount, res, multifileCount + 1);
                                } else {
                                    this.uploadFile(i, totalCount, res, multifileCount + 1);
                                }
                            } else {
                                this.uploadFile(i + 1, totalCount, res, 0);
                            }
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                if (this.state.formType === "saveAsDraft") {
                                    this.setState({
                                        titleAlert: i18n[this.getLocale()].success,
                                        messageAlert: i18n[this.getLocale()].save_as_Draft
                                    }, () => {
                                        this.alertSubmitForSuccess();
                                    })
                                } else {
                                    this.setState({
                                        titleAlert: i18n[this.getLocale()].error,
                                        messageAlert: i18n[this.getLocale()].attachment_error
                                    }, () => {
                                        this.alertSubmit();
                                    })
                                }
                            })
                        }
                    })
                })
            } else {
                this.uploadFile(i + 1, totalCount, res, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    }

    stepOneData = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            //from step 1
            setConfigObj.justification = obj.justificationOfEditTheScale;
            setConfigObj.principle = obj.principlesUsedToModifyScale;
            setConfigObj.fundingid = obj.sourcesFunding;
            setConfigObj.isallocated = obj.selectedOption === "Yes" ? 1 : 0;
            setConfigObj.budgetapprovalamount = obj.approvedBudgetsOfPart;
            setConfigObj.currentfinancialeffect = obj.currentFinancialEffect;
            setConfigObj.proposedfinancialeffect = obj.proposedFinancialEffect;
            setConfigObj.currentscalechallenge = obj.currentSalariesScale;
            setConfigObj.proposedscalechallenge = obj.proposedSalariesScale;
            setConfigObj.currentincreaeaverage = obj.avarageForCurrentSalariesScale;
            setConfigObj.currentdecreaseaverage = obj.avarageForProposedSalariesScale;
            setConfigObj.currentapplyeffect = obj.EffectOfApplyingOnCurrent;
            setConfigObj.proposedappleffect = obj.EffectOfApplyingOnProposed;

            let scaleMatrixArray = [{
                currentmonthlybonus: obj.currentBasicSalary,
                proposedmonthlybonus: obj.proposedBasicSalary,
                currentannualbenefits: obj.currentAnnualBenefits,
                proposedannualbenefits: obj.proposedAnnualBenefits,
                otherbonus: obj.otherBonusTableData,
                otherbenefits: obj.otherBenefitTableData
            }];

            setConfigObj.amendSalaryScaleMatrix = scaleMatrixArray;
            Config.setEditObj(setConfigObj);
        }

        this.setState({
            step1: false, step2: true,
            principlesUsedToModifyScale: obj.principlesUsedToModifyScale,
            justificationOfEditTheScale: obj.justificationOfEditTheScale,
            selectedOption: obj.selectedOption,
            sourcesFunding: obj.sourcesFunding,
            proposedBasicSalary: obj.proposedBasicSalary,
            currentBasicSalary: obj.currentBasicSalary,
            proposedAnnualBenefits: obj.proposedAnnualBenefits,
            currentAnnualBenefits: obj.currentAnnualBenefits,
            proposedFinancialEffect: obj.proposedFinancialEffect,
            currentFinancialEffect: obj.currentFinancialEffect,
            approvedBudgetsOfPart: obj.approvedBudgetsOfPart,
            proposedSalariesScale: obj.proposedSalariesScale,
            currentSalariesScale: obj.currentSalariesScale,
            avarageForProposedSalariesScale: obj.avarageForProposedSalariesScale,
            avarageForCurrentSalariesScale: obj.avarageForCurrentSalariesScale,
            EffectOfApplyingOnProposed: obj.EffectOfApplyingOnProposed,
            EffectOfApplyingOnCurrent: obj.EffectOfApplyingOnCurrent,
            otherBonusTableData: obj.otherBonusTableData,
            otherBenefitTableData: obj.otherBenefitTableData,
            sourcesFundingLabel: obj.sourcesFundingLabel
        })
    }

    submitApiCall = () => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let obj = {};
                obj.requestId = this.state.requestId;
                obj.isEdit = this.state.isEdit;
                obj.justification = this.state.justificationOfEditTheScale;
                obj.principle = this.state.principlesUsedToModifyScale;
                obj.fundingid = this.state.sourcesFunding;
                // obj.fundingid = this.state.sourcesFunding === i18n[this.props.isArabic ? 'ar' : 'en'].self ? 1 : 2;
                // obj.isallocated = this.state.necessaryFinancialAllocations === 'No' ? "0" : "1";
                obj.isallocated = this.state.selectedOption === 'No' ? "0" : "1";
                // obj.budgetapprovalamount = this.state.approvedBudgetsOfPart;
                // obj.currentbasicsalary = this.state.currentBasicSalary;
                // obj.proposedbasicsalary = this.state.proposedBasicSalary;
                // obj.currentannualbenefits = this.state.currentAnnualBenefits;
                // obj.proposedannualbenefits = this.state.proposedAnnualBenefits;
                // obj.currentfinancialeffect = this.state.currentFinancialEffect;
                // obj.proposedfinancialeffect = this.state.EffectOfApplyingOnProposed;
                // obj.currentscalechallenge = this.state.currentSalariesScale;
                // obj.proposedscalechallenge = this.state.proposedSalariesScale;
                // obj.currentincreaeaverage = this.state.avarageForCurrentSalariesScale;
                // obj.currentdecreaseaverage = this.state.avarageForCurrentSalariesScale;
                // obj.currentapplyeffect = this.state.currentFinancialEffect;
                // obj.proposedappleffect = this.state.proposedFinancialEffect;
                obj.currentfinancialeffect = this.state.currentFinancialEffect;
                obj.proposedfinancialeffect = this.state.proposedFinancialEffect;
                obj.currentscalechallenge = this.state.currentSalariesScale;
                obj.proposedscalechallenge = this.state.proposedSalariesScale;
                obj.currentincreaeaverage = this.state.avarageForCurrentSalariesScale;
                obj.currentdecreaseaverage = this.state.avarageForProposedSalariesScale;
                obj.currentapplyeffect = this.state.EffectOfApplyingOnCurrent;
                obj.proposedappleffect = this.state.EffectOfApplyingOnProposed;
                if (this.state.formType === "saveAsDraft") {
                    obj.draft = 1;
                } else {
                    obj.draft = 0;
                }
                if (this.state.selectedOption === 'No') {
                    obj.budgetapprovalamount = 0;
                } else {
                    obj.budgetapprovalamount = this.state.approvedBudgetsOfPart;
                }

                let payload = saveAmendSalaryScalePayload(obj);
                let endPoint = 'SaveAmmendSalaryScale'
                let url = Config.getUrl(endPoint);

                
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resForAmmendSalaryScale => {

                        if (resForAmmendSalaryScale.Status) {
                            // save amend salary scale matrix
                            // table data for bonus and benifits
                            let amendSalaryScaleMatrixObj = {};
                            amendSalaryScaleMatrixObj.refid = resForAmmendSalaryScale.data.id;
                            amendSalaryScaleMatrixObj.currentmonthlybonus = this.state.currentBasicSalary;
                            amendSalaryScaleMatrixObj.proposedmonthlybonus = this.state.proposedBasicSalary;
                            amendSalaryScaleMatrixObj.currentannualbenefits = this.state.currentAnnualBenefits;
                            amendSalaryScaleMatrixObj.proposedannualbenefits = this.state.proposedAnnualBenefits;
                            amendSalaryScaleMatrixObj.otherbonus = this.state.otherBonusTableData;
                            amendSalaryScaleMatrixObj.otherbenefits = this.state.otherBenefitTableData;

                            let payloadForAmendSalaryScaleMatrix = saveAmendSalaryScaleMatrixPayload(amendSalaryScaleMatrixObj);
                            let endPoint = 'SaveAmmendSalaryScaleMatrix'
                            let urlForAmendSalaryScaleMatrix = Config.getUrl(endPoint);

                            ApiRequest.sendRequestForPost(urlForAmendSalaryScaleMatrix, payloadForAmendSalaryScaleMatrix, resForAmendSalaryScaleMatrix => {

                            })

                            let totalFile = 8;
                            this.uploadFile(0, totalFile, resForAmmendSalaryScale, 0);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            })
                        }
                    })
                })
            })
        }
    }

    stepTwoData = (obj) => {
        if (obj.saveFrom === 'step1') {
            this.setState({
                requestId: obj.requestId,
                isEdit: obj.isEdit,
                formType: obj.formType,
                principlesUsedToModifyScale: obj.principlesUsedToModifyScale,
                justificationOfEditTheScale: obj.justificationOfEditTheScale,
                selectedOption: obj.selectedOption,
                sourcesFunding: obj.sourcesFunding,
                proposedBasicSalary: obj.proposedBasicSalary,
                currentBasicSalary: obj.currentBasicSalary,
                proposedAnnualBenefits: obj.proposedAnnualBenefits,
                currentAnnualBenefits: obj.currentAnnualBenefits,
                proposedFinancialEffect: obj.proposedFinancialEffect,
                currentFinancialEffect: obj.currentFinancialEffect,
                approvedBudgetsOfPart: obj.approvedBudgetsOfPart,
                proposedSalariesScale: obj.proposedSalariesScale,
                currentSalariesScale: obj.currentSalariesScale,
                avarageForProposedSalariesScale: obj.avarageForProposedSalariesScale,
                avarageForCurrentSalariesScale: obj.avarageForCurrentSalariesScale,
                EffectOfApplyingOnProposed: obj.EffectOfApplyingOnProposed,
                EffectOfApplyingOnCurrent: obj.EffectOfApplyingOnCurrent,
                otherBonusTableData: obj.otherBonusTableData,
                otherBenefitTableData: obj.otherBenefitTableData
            }, () => {
                this.submitApiCall();
            })
        } else {
            this.setState({
                requestId: obj.requestId,
                isEdit: obj.isEdit,
                formType: obj.formType,
                approvedScaleOfGrades: obj.approvedScaleOfGrades,
                salariesCompensationPolicy: obj.salariesCompensationPolicy,
                tableOfTheAlignment: obj.tableOfTheAlignment,
                proposedScale: obj.proposedScale,
                entityChairman: obj.entityChairman,
                illustrationsOfCurrent: obj.illustrationsOfCurrent,
                illustrationsOfCurrentShortName: obj.illustrationsOfCurrentShortName,
                comparativeStudy: obj.comparativeStudy,
                detailsOfAnnualCost: obj.detailsOfAnnualCost,
            }, () => {
                this.submitApiCall();
            })
        }
    }

    stepTwoDataPrev = (obj) => {
        this.setState({
            step1: true, step2: false,
            approvedScaleOfGrades: obj.approvedScaleOfGrades,
            salariesCompensationPolicy: obj.salariesCompensationPolicy,
            tableOfTheAlignment: obj.tableOfTheAlignment,
            proposedScale: obj.proposedScale,
            entityChairman: obj.entityChairman,
            illustrationsOfCurrent: obj.illustrationsOfCurrent,
            illustrationsOfCurrentShortName: obj.illustrationsOfCurrentShortName,
            comparativeStudy: obj.comparativeStudy,
            detailsOfAnnualCost: obj.detailsOfAnnualCost,
            //copyOfTheDecision: obj.copyOfTheDecision,
        })
    }

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
    };

    getPrev = {
        stepTwoDataPrev: this.stepTwoDataPrev,
    };

    navigate = () => {
        this.setState({
            step2: true, step1: false
        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    handlePreViewNavigation = (obj) => {

        let prevObj = {};
        let
            proposedBasicSalaryObj = '',
            currentBasicSalaryObj = '',
            proposedAnnualBenefitsObj = '',
            currentAnnualBenefitsObj = '';

        if (this.state.proposedBasicSalary.length) {
            for (let index = 0; index < this.state.proposedBasicSalary.length; index++) {
                proposedBasicSalaryObj = proposedBasicSalaryObj + '' + this.state.proposedBasicSalary[index].value;
                if (index < (this.state.proposedBasicSalary.length - 1)) {
                    proposedBasicSalaryObj = proposedBasicSalaryObj + ',';
                }
            }
        }
        if (this.state.currentBasicSalary.length) {
            for (let index = 0; index < this.state.currentBasicSalary.length; index++) {
                currentBasicSalaryObj = currentBasicSalaryObj + '' + this.state.currentBasicSalary[index].value;
                if (index < (this.state.currentBasicSalary.length - 1)) {
                    currentBasicSalaryObj = currentBasicSalaryObj + ',';
                }
            }
        }
        if (this.state.proposedAnnualBenefits.length) {
            for (let index = 0; index < this.state.proposedAnnualBenefits.length; index++) {
                proposedAnnualBenefitsObj = proposedAnnualBenefitsObj + '' + this.state.proposedAnnualBenefits[index].value;
                if (index < (this.state.proposedAnnualBenefits.length - 1)) {
                    proposedAnnualBenefitsObj = proposedAnnualBenefitsObj + ',';
                }
            }
        }
        if (this.state.currentAnnualBenefits.length) {
            for (let index = 0; index < this.state.currentAnnualBenefits.length; index++) {
                currentAnnualBenefitsObj = currentAnnualBenefitsObj + '' + this.state.currentAnnualBenefits[index].value;
                if (index < (this.state.currentAnnualBenefits.length - 1)) {
                    currentAnnualBenefitsObj = currentAnnualBenefitsObj + ',';
                }
            }
        }

        prevObj.sourcesFunding = this.state.sourcesFunding;

        let selectedOptionFinancial = '';
        // if (this.state.selectedOptionFinancial === "Yes") {
        //     // prevObj.selectedOptionfinancial = i18n[this.state.isArabic ? "ar" : "en"].yes
        //     selectedOptionFinancial = i18n[this.state.isArabic ? "ar" : "en"].yes;
        // }
        // else {
        //     // prevObj.selectedOptionfinancial = i18n[this.state.isArabic ? "ar" : "en"].no
        //     selectedOptionFinancial = i18n[this.state.isArabic ? "ar" : "en"].no;
        // }

        

        if (this.state.selectedOption === "Yes") {
            selectedOptionFinancial = i18n[this.state.isArabic ? "ar" : "en"].yes;
        }
        else {
            selectedOptionFinancial = i18n[this.state.isArabic ? "ar" : "en"].no;
        }

        prevObj.sourcesFundingLabel = this.state.sourcesFundingLabel;

        if (this.state.selectedOption === 'Yes') {
            prevObj.approvedBudgetsOfPart = this.state.approvedBudgetsOfPart;
        }

        prevObj.table = {
            currentBasicSalarymonthly: currentBasicSalaryObj,
            proposedBascSalaryfunding: proposedBasicSalaryObj,
            otherBonusTableData: this.state.otherBonusTableData,
            currentAnnualBenefits: currentAnnualBenefitsObj,
            proposedAnnualBenefits: proposedAnnualBenefitsObj,
            otherBenefitTableData: this.state.otherBenefitTableData,
            currentFinancialEffect: this.state.currentFinancialEffect,
            proposedFinancialEffect: this.state.proposedFinancialEffect,
            avarageForCurrentSalariesScale: this.state.avarageForCurrentSalariesScale,
            avarageForProposedSalariesScale: this.state.avarageForProposedSalariesScale
        }

        prevObj.illustrationsOfCurrentShortName = obj.illustrationsOfCurrentShortName;

        let attachmentArr = [
            {
                label: 'approvedScaleOfGrades',
                fileName: obj.approvedScaleOfGrades,
                fileObj: Config.getbase64ofAmendSalaryScreenfile2()
            },
            {
                label: 'salariesCompensationPolicy',
                fileName: obj.salariesCompensationPolicy,
                fileObj: Config.getbase64ofAmendSalaryScreenfile1()
            },
            {
                label: 'tableOfTheAlignment',
                fileName: obj.tableOfTheAlignment,
                fileObj: Config.getbase64ofAmendSalaryScreenfile4()
            },
            {
                label: 'proposedScale',
                fileName: obj.proposedScale,
                fileObj: Config.getbase64ofAmendSalaryScreenfile3()
            },
            {
                label: 'entityChairman',
                fileName: obj.entityChairman,
                fileObj: Config.getbase64ofAmendSalaryScreenfile9()
            },
            {
                label: 'illustrationsOfCurrent',
                fileName: obj.illustrationsOfCurrent,
                fileObj: Config.getbase64ofAmendSalaryScreenfile5()
            },
            {
                label: 'comparativeStudy',
                fileName: obj.comparativeStudy,
                fileObj: Config.getbase64ofAmendSalaryScreenfile6()
            },
            {
                label: 'detailsOfAnnualCost',
                fileName: obj.detailsOfAnnualCost,
                fileObj: Config.getbase64ofAmendSalaryScreenfile7()
            }
        ];

        let ckEditorArr = [
            {
                label: 'selectedOptionFinancial',
                value: selectedOptionFinancial,
            },
            {
                label: 'proposedSalariesScale',
                value: this.state.proposedSalariesScale,
            },
            {
                label: 'currentSalariesScale',
                value: this.state.currentSalariesScale,
            },
            {
                label: 'EffectOfApplyingOnProposed',
                value: this.state.EffectOfApplyingOnProposed,
            },
            {
                label: 'EffectOfApplyingOnCurrent',
                value: this.state.EffectOfApplyingOnCurrent,
            },
            {
                label: 'principlesUsedToModifyScale',
                value: this.state.principlesUsedToModifyScale,
            },
            {
                label: 'justificationOfEditTheScale',
                value: this.state.justificationOfEditTheScale,
            }
        ];

        localStorage.setItem("currentPath", "amendSalaryScreen");
        this.props.history.replace("/preview", { data: prevObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    render() {
        const sideBarData = Config.getServiceSideBar('amendSalaryScreen', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid >
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'amendSalaryScreen' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].amendSalariesScale}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            {
                                this.state.step1 ?
                                    <AmendSalariesScaleStep1 isArabic={this.state.isArabic}
                                        sendData={{
                                            salariesCompensationPolicy: this.state.salariesCompensationPolicy,
                                            principlesUsedToModifyScale: this.state.principlesUsedToModifyScale,
                                            justificationOfEditTheScale: this.state.justificationOfEditTheScale,
                                            tableOfTheAlignment: this.state.tableOfTheAlignment,
                                            proposedScale: this.state.proposedScale,
                                            proposedBasicSalary: this.state.proposedBasicSalary,
                                            currentBasicSalary: this.state.currentBasicSalary,
                                            proposedAnnualBenefits: this.state.proposedAnnualBenefits,
                                            currentAnnualBenefits: this.state.currentAnnualBenefits,
                                            proposedFinancialEffect: this.state.proposedFinancialEffect,
                                            currentFinancialEffect: this.state.currentFinancialEffect,
                                            selectedOption: this.state.selectedOption,
                                            sourcesFunding: this.state.sourcesFunding,
                                            approvedBudgetsOfPart: this.state.approvedBudgetsOfPart,
                                            proposedSalariesScale: this.state.proposedSalariesScale,
                                            currentSalariesScale: this.state.currentSalariesScale,
                                            avarageForProposedSalariesScale: this.state.avarageForProposedSalariesScale,
                                            avarageForCurrentSalariesScale: this.state.avarageForCurrentSalariesScale,
                                            EffectOfApplyingOnProposed: this.state.EffectOfApplyingOnProposed,
                                            EffectOfApplyingOnCurrent: this.state.EffectOfApplyingOnCurrent,
                                            otherBonusTableData: this.state.otherBonusTableData,
                                            otherBenefitTableData: this.state.otherBenefitTableData,
                                            sourcesFundingLabel: this.state.sourcesFundingLabel
                                        }} getData={this.getData} getPrev={this.getPrev}
                                        saveAsDraft={this.stepTwoData}
                                    />
                                    :
                                    null
                            }
                            {
                                this.state.step2 ?
                                    <AmendSalariesScaleStep2 isArabic={this.state.isArabic}
                                        sendData={{
                                            approvedScaleOfGrades: this.state.approvedScaleOfGrades,
                                            salariesCompensationPolicy: this.state.salariesCompensationPolicy,
                                            tableOfTheAlignment: this.state.tableOfTheAlignment,
                                            proposedScale: this.state.proposedScale,
                                            entityChairman: this.state.entityChairman,
                                            hraStudy: this.state.hraStudy,
                                            illustrationsOfCurrent: this.state.illustrationsOfCurrent,
                                            illustrationsOfCurrentShortName: this.state.illustrationsOfCurrentShortName,

                                            comparativeStudy: this.state.comparativeStudy,
                                            detailsOfAnnualCost: this.state.detailsOfAnnualCost,
                                        }} getData={this.getData} getPrev={this.getPrev}
                                        previewPage={this.handlePreViewNavigation}
                                    />
                                    :
                                    null
                            }
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewbonusrequest={this.state.canviewbonusrequest}
                            canviewamendscalerequest={this.state.canviewamendscalerequest}
                            canviewamendsalaryrequest={this.state.canviewamendsalaryrequest}
                            // height={'250vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}