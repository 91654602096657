import React,{ Component } from 'react'
import ApiRequest from '../../services/ApiRequest';
export default class ExternalSurvey extends Component {
    constructor() {
        super();
        this.state = {
            isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
        }
    }

     componentDidMount=()=>
    {
    }
    
    render() 
    {
        return(<div><h2>External</h2></div> );
    
    

    }

}