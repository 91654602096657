import React, { Component } from "react";
import {
    MDBBtn,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBRow, MDBCol
} from "mdbreact";
import i18n from "../../i18n";
import Config from './../../utils/Config';

const actions = {
    VIEW: "view",
    EDIT: "edit",
    UPDATE_STATUS: "update_status",
    COMMENT: "comment",
    DELETE: "delete",
    ASSIGNTOSECTOR: "assigntosector",
    ASSIGNTODEP: "assigntodep",
    BACKTOMANAGER: "backtomanager",
    ASSIGNTOSPECIFICDEP: "assigntospecificdep",
    ASSIGNTODATADEP: "assigntodatadep",
    ASSIGNTOEMP: "assigntoemp",
    ASSIGNTOSPECIFICEMP: "assigntospecificemp",
    ASSIGNTOGMOFFICE: "assigntogmoffice",
    ASSIGNTOSTRATEGICOFFICE: "assigntostrategicoffice",
    ASSIGNBACKTOENTITY: "assignbacktoentity",
    ASSIGNTOENTITY: "assigntoentity",
    ADDSTUDY: "addStudy",
    ADDREPLY: "addReply",
    FOLLOW: "follow",
    VIEWDETAILS: 'viewdetails',
    OpenCreationStage: "OpenCreationStage",
    CloseRequest: 'CloseRequest'
};

class ViewDetailsAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUserId: '',
            pathName: '',
            loginData: '',
            accountTypeId: '',
            requestStatus: '',
            requestStatusReason: '',
            canView: 0,
            canEdit: 0,
            canUpdateStatus: 0,
            canAddComment: 0,
            canAssignDept: 0,
            canAssignEmployee: 0,
            canProvideFeedback: 0,
            canAssignSector: 0,
            canAddHraStudy: 0,
            canViewHraStudy: 0,
            canAssignGm: 0,
            canAssignBackToEntity: 0,
            casAssignBackToDataDepartment: 0,
            canCloseRequest: 0,
            screen: localStorage.getItem("currentPath"),
            ORG_Path: "OrganizationStructure",
            userdepartmentid: 0
        }
    };

    componentWillReceiveProps = (props) => {

        this.permissionData();
    }

    componentDidMount = () => {

        this.permissionData();

    }

    checkPermissionForInboxFuncationality = (pathName) => {
        
        pathName='inprogress';
        let loginData = Config.getLocalStorageLoginData();
        debugger;
        if (this.props && this.props.requesttypeid) {
            this.setState({ canCloseRequest: loginData.permissionType.canCloseRequest });

            this.setState({ userdepartmentid: loginData.userdepartmentid })
            if (this.props.requesttypeid === Config.getSciRequestTypeId()) {

                this.setState({ canAssignDept: loginData.permissionType.canassignscidepartment });
                this.setState({ canAssignEmployee: loginData.permissionType.canasignsciemployee });
                this.setState({ currentUserId: loginData.id });
                this.setState({ requestStatus: this.props.ActionData.statusid });
                this.setState({ requestStatusReason: this.props.ActionData.statusreasonid });
                this.setState({ pathName: pathName });
                this.setState({ accountTypeId: loginData.AccountypeID });
                this.setState({ canAssignBackToEntity: loginData.permissionType.canassignscibacktoentity });
                this.setState({ canView: loginData.permissionType.canreviewscirequest });
                this.setState({ canEdit: loginData.permissionType.caneditscirequest });
                this.setState({ canUpdateStatus: loginData.permissionType.canupdatescistatus });
                this.setState({ canAddComment: loginData.permissionType.canaddscicomment });
                this.setState({ canAssignGm: loginData.permissionType.canassignscigm });


                this.setState({ canProvideFeedback: loginData.permissionType.canprovidescifeedback });
                this.setState({ canAssignSector: loginData.permissionType.canassignscisector });
                this.setState({ canAddHraStudy: 0 });


            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignbonusbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewbonusrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditbonusrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatebonusstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddbonuscomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignbonusgm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignbounsdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignbounsemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidebonusstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivebonusstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignbonussector
                })
            } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignbonusbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewbonusrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditbonusrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatebonusstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddbonuscomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignbonusgm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignbounsdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignbounsemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidebonusstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivebonusstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignbonussector
                })
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignamendscalebacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewamendscalerequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditamendscalerequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateamendscalestatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddamendscalecomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignamendscalegm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignamendscaledepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignamendscaleemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideamendscalestudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveamendscalestudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignamendscalesector
                })
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignamendsalarybacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewamendsalaryrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditamendsalaryrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateamendsalarystatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddamendsalarycomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignamendsalarygm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignamendsalarydepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignamendsalaryemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideamendsalarystudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveamendsalarystudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignamendsalarysector
                    //canAssignSector:1
                })
            }

            else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId() || this.props.requesttypeid === 10925 || this.props.requesttypeid === 10926) {

                this.setState({
                    currentUserId: loginData && loginData.id
                });
                this.setState({ requestStatus: this.props.ActionData.statusid });
                this.setState({ requestStatusReason: this.props.ActionData.statusreasonid });
                this.setState({ pathName: pathName });
                this.setState({ accountTypeId: loginData && loginData.AccountypeID });
                this.setState({ canAssignBackToEntity: loginData.permissionType.canassignpatientbacktoentity });
                this.setState({ canView: loginData && loginData.permissionType && loginData.permissionType.canviewpatientrequest });
                this.setState({ canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditpatientrequest });
                this.setState({ canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatepatientstatus });
                this.setState({ canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddpatientcomments });
                this.setState({ canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignscigm });
                this.setState({ canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignpatientdepartment });
                this.setState({ canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignpatientemployee });
                this.setState({ canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidepatientstudy });
                this.setState({ canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivepatientstudy });
                this.setState({
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignpatientsector
                });
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignhrbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewreviewhrrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditreviewhrrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatereviewhrstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddreviewhrcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignhrgm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignhrdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignhremployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidehrstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivehrstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignhrsector
                })
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignhousingbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewadhousingrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditadhousingrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateadhousingstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddadhousingcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignhousinggm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignhousingdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignhousingemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidehousingstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivehousingstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignhousingsector
                })
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewsecondmentrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditsecondmentrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatesecondmentstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddsecondmentcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentgm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmetnemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidesecondmentstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivesecondmentstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentsector
                })
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {

                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignretirementbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewretirementrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditretirementrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateretirementstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddretirementcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignretirementgm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignretirementdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignretirementemployees,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideretirementstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveretirementstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignretirementsector
                })
            } else if (this.props.requesttypeid === Config.getExtendCreateObjectiveId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewextendcreaterequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditextendcreaterequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateextendcreatestatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddextendcreatecomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignextendcreaterequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignextendcreatedepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignextendcreateemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideextendcreatestudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveextendcreatestudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignextendcreatesector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignextendcreatebacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignextendcreatebacktoentity
                })
            } else if (this.props.requesttypeid === Config.getExtendReviewObjectiveId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewextendreviewrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditextendreviewrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateextendreviewstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddextendreviewcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignextendreviewrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignextendreviewdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignextendreviewemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideextendreviewstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveextendreviewstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignextendreviewsector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignextendreviewbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignextendreviewbacktoentity
                })
            } else if (this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewextendannualrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditextendannualrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateextendannualstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddextendannualcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignextendannualrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignextendannualdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignextendannualemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideextendannualstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveextendannualstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignextendannualsector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignextendannualbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignextendannualbacktoentity
                })
            } else if (this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewemployeeperformancerequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditemployeeperformancerequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateemployeeperformancestatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddemployeeperformancecomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignemployeeperformancerequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignemployeeperformancedepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignemployeeperformanceemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideemployeeperformancestudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveemployeeperformancestudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignemployeeperformancesector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignemployeeperformancebacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignemployeeperformancebacktoentity
                })
            } else if (this.props.requesttypeid === Config.getJoinAndImplementId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewjoinimplementrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditjoinimplementrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatejoinimplementstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddjoinimplementcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignjoinimplementrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignjoinimplementdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignjoinimplementemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidejoinimplementstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivejoinimplementstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignjoinimplementsector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignjoinimplementbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignjoinimplementbacktoentity
                })
            } else if (this.props.requesttypeid === Config.getOpenComplainsStageId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewopencomplainsrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditopencomplainsrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateopencomplainsstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddopencomplainscomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignopencomplainsrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignopencomplainsdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignopencomplainsemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideopencomplainsstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveopencomplainsstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignopencomplainssector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignopencomplainsbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignopencomplainsbacktoentity,
                }, () => {

                })
            } else if (this.props.requesttypeid === Config.getAmendOnSharedGovermentId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewamendsystemsrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditamendsystemsrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateamendsystemsstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddamendsystemscomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignamendsystemsrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignamendsystemsdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignamendsystemsemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideamendsystemsstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveamendsystemsstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignamendsystemssector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignamendsystemsbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignamendsystemsbacktoentity
                })
            } else if (this.props.requesttypeid === Config.getReportAndStaticId()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewreportsstaticsrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditreportsstaticsrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatereportsstaticsstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddreportsstaticscomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignreportsstaticsrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignreportsstaticsdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignreportsstaticsemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidereportsstaticsstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivereportsstaticsstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignreportsstaticssector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignreportsstaticsbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignreportsstaticsbacktoentity
                })
            } else if (this.props.requesttypeid === Config.getOtherRequest()) {
                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewotherrequestsrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditotherrequestsrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdateotherrequestsstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddotherrequestscomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignotherrequesttogmsrequesttogm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignotherrequestsdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignotherrequestsemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovideotherrequestsstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceiveotherrequestsstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignotherrequestssector,
                    casAssignBackToDataDepartment: loginData && loginData.permissionType && loginData.permissionType.canassignotherrequestsbacktodepartment,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignotherrequestsbacktoentity
                })
            }

            // organization structure
            else if (this.props.requesttypeid === Config.getORGRequestTypeId()) {

                this.setState({
                    currentUserId: loginData && loginData.id,
                    requestStatus: this.props.ActionData.statusid,
                    requestStatusReason: this.props.ActionData.statusreasonid,
                    pathName: pathName,
                    accountTypeId: loginData && loginData.AccountypeID,
                    canAssignBackToEntity: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentbacktoentity,
                    canView: loginData && loginData.permissionType && loginData.permissionType.canviewsecondmentrequest,
                    canEdit: loginData && loginData.permissionType && loginData.permissionType.caneditsecondmentrequest,
                    canUpdateStatus: loginData && loginData.permissionType && loginData.permissionType.canupdatesecondmentstatus,
                    canAddComment: loginData && loginData.permissionType && loginData.permissionType.canaddsecondmentcomments,
                    canAssignGm: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentgm,
                    canAssignDept: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentdepartment,
                    canAssignEmployee: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmetnemployee,
                    canAddHraStudy: loginData && loginData.permissionType && loginData.permissionType.canprovidesecondmentstudy,
                    canViewHraStudy: loginData && loginData.permissionType && loginData.permissionType.canreceivesecondmentstudy,
                    canAssignSector: loginData && loginData.permissionType && loginData.permissionType.canassignsecondmentsector
                })
            }
        }
    }


    permissionData = () => {

        let loginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: loginData
        }, () => {

            debugger;
            let pathName = '';
            if (this.props && this.props.location && this.props.location.state) {
                pathName = this.props.location.state.pathname
            } else {
                pathName = localStorage.getItem('currentPath');
            }
            //
            if (pathName) {
                if(pathName==='viewContainer')
                {
                    this.checkPermissionForInboxFuncationality('inprogress');
                }
                else if (pathName === 'currentUserTasks') {
                    
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'inprogress') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'pending') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'completed') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'cancelled') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'onhold') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'SCIRequest') {
                    ;
                    this.setState({ canAssignDept: this.state.loginData.permissionType.canassignscidepartment });
                    this.setState({ canAssignEmployee: this.state.loginData.permissionType.canasignsciemployee });
                    this.setState({ currentUserId: this.state.loginData.id });
                    // this.setState( {requestStatus: this.props.viewactionsdata.statusid});
                    // this.setState( {requestStatusReason: this.props.viewactionsdata.statusreasonid});
                    this.setState({ pathName: pathName });
                    this.setState({ accountTypeId: this.state.loginData.AccountypeID });
                    this.setState({ canAssignBackToEntity: this.state.loginData.permissionType.canassignscibacktoentity });
                    this.setState({ canView: this.state.loginData.permissionType.canreviewscirequest });
                    this.setState({ canEdit: this.state.loginData.permissionType.caneditscirequest });
                    this.setState({ canUpdateStatus: this.state.loginData.permissionType.canupdatescistatus });
                    this.setState({ canAddComment: this.state.loginData.permissionType.canaddscicomment });
                    this.setState({ canAssignGm: this.state.loginData.permissionType.canassignscigm });


                    this.setState({ canProvideFeedback: this.state.loginData.permissionType.canprovidescifeedback });
                    this.setState({ canAssignSector: this.state.loginData.permissionType.canassignscisector });
                    this.setState({ canAddHraStudy: 0 });

                } else if (pathName === 'informationRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewirrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditirrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateirstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddircomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirdepartment,
                        canAssignEmployee: 0,
                        canProvideFeedback: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideirfeedback,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirsector,
                        canAddHraStudy: 0
                    })
                } else if (pathName === 'bonusRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditbonusrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatebonusstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddbonuscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidebonusstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivebonusstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonussector
                    })
                } else if (pathName === 'amendSalaryScreen') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalebacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendscalerequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendscalestatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendscalecomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalegm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscaledepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscaleemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendscalestudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendscalestudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalesector
                    })
                } else if (pathName === 'amendEmployeeSalary') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarybacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendsalaryrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendsalarystatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendsalarycomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarygm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarydepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalaryemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendsalarystudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendsalarystudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarysector

                    })
                } else if (pathName === 'reviewHRPolicies') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditreviewhrrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatereviewhrstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddreviewhrcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhremployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidehrstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivehrstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrsector
                    })
                } else if (pathName === 'secondmentForFederalAgency') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditsecondmentrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatesecondmentstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddsecondmentcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmetnemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidesecondmentstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivesecondmentstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentsector
                    })
                } else if (pathName === 'exceptionADHousing') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditadhousingrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateadhousingstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddadhousingcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousinggm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidehousingstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivehousingstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingsector
                    })
                } else if (pathName === 'exemptionFromAccompanyPatient' || pathName === 'SickLeave' || pathName === 'Promotion') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditpatientrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatepatientstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddpatientcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignscigm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidepatientstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivepatientstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientsector
                    })
                } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditretirementrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateretirementstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddretirementcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementemployees,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideretirementstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveretirementstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementsector
                    })
                } else if (pathName === 'extendCreateObjectives') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendcreaterequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendcreaterequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendcreatestatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendcreatecomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreaterequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatedepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreateemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendcreatestudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendcreatestudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatesector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatebacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatebacktoentity
                    })
                } else if (pathName === 'extendReviewObjectives') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendreviewrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendreviewrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendreviewstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendreviewcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendreviewstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendreviewstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewsector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewbacktoentity
                    })
                } else if (pathName === 'extendAnnualPerformance') {



                    this.setState({

                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.ActionData.statusid,
                        requestStatusReason: this.props.ActionData.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendannualrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendannualrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendannualstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendannualcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendannualstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendannualstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualsector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualbacktoentity
                    }, () => {


                    })
                } else if (pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewemployeeperformancerequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditemployeeperformancerequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateemployeeperformancestatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddemployeeperformancecomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancerequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancedepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformanceemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideemployeeperformancestudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveemployeeperformancestudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancesector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancebacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancebacktoentity
                    })
                } else if (pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewjoinimplementrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditjoinimplementrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatejoinimplementstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddjoinimplementcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidejoinimplementstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivejoinimplementstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementsector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementbacktoentity
                    })
                } else if (pathName === 'openComplainsStage') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewopencomplainsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditopencomplainsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateopencomplainsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddopencomplainscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideopencomplainsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveopencomplainsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsbacktoentity,
                    })
                } else if (pathName === 'amendsSharedGovernmentHRManagement') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsystemsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendsystemsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendsystemsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendsystemscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendsystemsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendsystemsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsbacktoentity
                    })
                } else if (pathName === 'reportsAndStaticRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreportsstaticsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditreportsstaticsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatereportsstaticsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddreportsstaticscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidereportsstaticsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivereportsstaticsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsbacktoentity
                    })
                } else if (pathName === 'otherRequests') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.viewactionsdata.statusid,
                        requestStatusReason: this.props.viewactionsdata.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewotherrequestsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditotherrequestsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateotherrequestsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddotherrequestscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequesttogmsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideotherrequestsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveotherrequestsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsbacktoentity
                    })
                } else if (pathName === 'entity') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'contact') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'lookUp') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'mailTemplate') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'role') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                }
            }
        })
    }

    getLocale = () => {
        let value = localStorage.getItem("Lang") === "ar" ? true : false;
        if (value) {
            return "ar";
        } else {
            return "en";
        }
    };

    getActionbuttonName = () => {

        return i18n[this.getLocale()].statusUpdate;
    }

    getActionButtonNameForGmAndStrategic = () => {

        let buttonname = i18n[this.getLocale()].assignToGmOffice;
        if (this.props.viewactionsdata.requesttypeid === Config.getSciRequestTypeId() || this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest') {
            buttonname = i18n[this.getLocale()].assignToStrategicOffice;
        }
        return buttonname;
    }

    getActionButtonNameForAssignToEntity = () => {

        let buttonname = i18n[this.getLocale()].assignBackToEntity;
        if (this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') {
            buttonname = i18n[this.getLocale()].assignToEntity;
        }
        return buttonname;
    }

    render() {

        debugger;

        return (

            <MDBCol md="12" sm="12" style={{
                padding: '0px',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around"
            }}>

                <MDBRow>

                    {
                        this.props.Attachments ?
                            <MDBBtn
                                className="gridActionBtn gridActionBtAttch"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(actions.VIEW, this.props.index);
                                }}
                            >
                                {
                                    i18n[this.getLocale()].attached_files
                                }
                            </MDBBtn> : ''
                    }

                    {
                        this.state.canView ?
                            <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(actions.VIEW, this.props.index);
                                }}
                            >
                                {
                                    i18n[this.getLocale()].view
                                }
                            </MDBBtn> :
                            <span></span>
                    }









                    {
                        this.state.canView ?
                            <br />
                            :
                            <spna></spna>
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId()
                            ?
                           null
                            :
                            this.state.canUpdateStatus && (this.state.requestStatusReason === Config.getReasonForExecutiveOffice()
                                || this.state.requestStatusReason === Config.getReasonForExecutiveOffice() || this.state.accountTypeId === Config.getAccountTypeGMOffice()) ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.UPDATE_STATUS, this.props.index);
                                    }}>
                                    {
                                        this.getActionbuttonName()
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canUpdateStatus ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :

                            this.state.canAssignBackToEntity && (((this.props.viewactionsdata.requesttypeid !== Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid !== Config.getExtendReviewObjectiveId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid !== Config.getAddAndEditEmployeePerformanceEvaluationId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid !== Config.getOpenComplainsStageId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid !== Config.getReportAndStaticId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getOtherRequest()) && this.state.accountTypeId !== Config.getAccountTypeDataDeptManager()) || ((this.props.viewactionsdata.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid === Config.getExtendReviewObjectiveId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid === Config.getOpenComplainsStageId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid === Config.getReportAndStaticId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getOtherRequest()) && this.state.accountTypeId === Config.getAccountTypeDataDeptManager()) ||
                                (this.props.viewactionsdata.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId() || this.props.requesttypeid === 10925 || this.props.requesttypeid === 10926 || this.props.viewactionsdata.requesttypeid === Config.getSickLeaveTypeId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getPromotionTypeId ||
                                    this.props.viewactionsdata.requesttypeid === Config.getHrReviewPolicyRequestTypeId() ||
                                    this.props.viewactionsdata.requesttypeid === Config.getExceptionADHousingRequestTypeId() ||
                                    this.props.viewactionsdata.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId() ||
                                    this.props.viewactionsdata.requesttypeid === Config.getRetirmentRequestTypeId() ||
                                    this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) && (this.state.accountTypeId === Config.getAccountResearchSector() || this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager()))

                                && this.props.viewactionsdata.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNBACKTOENTITY, this.props.index);
                                    }}>
                                    {
                                        this.getActionButtonNameForAssignToEntity()
                                    }
                                    {/* {i18n[this.getLocale()].assignBackToEntity} */}
                                </MDBBtn>
                                : null
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (((this.props.viewactionsdata.requesttypeid !== Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid !== Config.getExtendReviewObjectiveId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid !== Config.getAddAndEditEmployeePerformanceEvaluationId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid !== Config.getOpenComplainsStageId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid !== Config.getReportAndStaticId()
                                || this.props.viewactionsdata.requesttypeid !== Config.getOtherRequest()) && this.state.accountTypeId !== Config.getAccountTypeDataDeptManager()) || ((this.props.viewactionsdata.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid === Config.getExtendReviewObjectiveId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid === Config.getOpenComplainsStageId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid === Config.getReportAndStaticId()
                                    || this.props.viewactionsdata.requesttypeid === Config.getOtherRequest()) && this.state.accountTypeId === Config.getAccountTypeDataDeptManager())) && this.props.viewactionsdata.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') && this.state.requestStatusReason === Config.getReasonForStrategicAssignedId()
                                && this.state.accountTypeId === Config.getAccountTypeSPOfiice() ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOENTITY, this.props.index);
                                    }}>
                                    {
                                        this.getActionButtonNameForAssignToEntity()
                                    }
                                    {/* {i18n[this.getLocale()].assignBackToEntity} */}
                                </MDBBtn>
                                : null
                    }
                    {

                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') && this.state.requestStatusReason === Config.getReasonForStrategicAssignedId()
                                && this.state.accountTypeId === Config.getAccountTypeSPOfiice() ?
                                <br />
                                :
                                null
                    }

                    {
                        (this.props.viewactionsdata.requesttypeid === Config.getSciRequestTypeId() || this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'SCIRequest' || this.state.pathName === 'informationRequest') ?
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||

                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ?
                                null
                                :
                                this.state.canAssignSector ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            ;
                                            this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].assignToSector}
                                    </MDBBtn>
                                    :
                                    null
                            :
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getDraftId() ||

                                this.state.requestStatus === Config.getStatusOnHoldId()
                                ?
                                null
                                :
                                ((this.state.canAssignSector && this.props.viewactionsdata.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                    this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            ;
                                            this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].assignToSector}
                                    </MDBBtn>
                                    :
                                    this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ?
                                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies() || this.state.accountTypeId === Config.getAccountTypePolicesSectionMager() ||
                                            this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) ||
                                            this.state.accountTypeId === Config.getAccountTypeSectorManager()
                                            ?
                                            null
                                            :
                                            ((this.state.canAssignSector && this.props.viewactionsdata.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                                this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                                <MDBBtn
                                                    className="gridActionBtn"
                                                    color="primary"
                                                    style={{ color: "var(--btnColor)" }}
                                                    size="sm"
                                                    onClick={() => {
                                                        ;
                                                        this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                                    }}
                                                >
                                                    {i18n[this.getLocale()].assignToSector}
                                                </MDBBtn>
                                                :
                                                null
                                        : null

                    }

                    {
                        this.props.viewactionsdata.requesttypeid === Config.getSciRequestTypeId() || this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'SCIRequest' || this.state.pathName === 'informationRequest' ?
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ?
                                null
                                :
                                this.state.canAssignSector ?
                                    <br />
                                    :
                                    null
                            :
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||

                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId()
                                ?
                                null
                                :
                                ((this.state.canAssignSector && this.props.viewactionsdata.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                    this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                    < br />
                                    :
                                    this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ?
                                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies() || this.state.accountTypeId === Config.getAccountTypePolicesSectionMager() ||
                                            this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) ||
                                            this.state.accountTypeId === Config.getAccountTypeSectorManager()
                                            ?
                                            null
                                            :
                                            ((this.state.canAssignSector && this.props.viewactionsdata.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                                this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                                < br />
                                                :
                                                null
                                        : null

                    }

                    {



                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                            null
                            :
                            this.state.canAssignGm && this.props.viewactionsdata.requesttype != Config.getSCILEgalSerbviceId() ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(
                                            this.props.viewactionsdata.requesttypeid === Config.getSciRequestTypeId() || this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest'
                                                ? actions.ASSIGNTOSTRATEGICOFFICE : actions.ASSIGNTOGMOFFICE,
                                            this.props.index);
                                    }}>
                                    {
                                        this.getActionButtonNameForGmAndStrategic()
                                    }
                                    {/* i18n[this.getLocale()].assignToGmOffice */}
                                </MDBBtn>
                                :
                                null

                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                            // this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                            null :
                            this.state.canAssignGm ?
                                <br />
                                :
                                null
                    }

                    {
                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies() || this.state.accountTypeId === Config.getAccountTypePolicesSectionMager() ||
                            this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && (this.props.viewactionsdata.assignedemployeeid === 0) && this.props.viewactionsdata.assigned === 1 ?
                            <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(actions.ASSIGNTOSPECIFICEMP, this.props.index);
                                }}
                            >
                                {i18n[this.getLocale()].loackrequest}
                            </MDBBtn>
                            :
                            null
                    }

                    {
                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies() || this.state.accountTypeId === Config.getAccountTypePolicesSectionMager() ||
                            this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && (this.props.viewactionsdata.assignedemployeeid === 0) && this.props.viewactionsdata.assigned === 1 ?
                            <br />
                            :
                            null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIEmployeeassignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAssignEmployee ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOEMP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToEmp}
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    {

                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIEmployeeassignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAssignEmployee && this.props.viewactionsdata.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {

                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason == Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                            null
                            :
                            this.state.canAssignDept ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {

                                        this.props.manageAction(actions.ASSIGNTODEP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToDep}
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                            null
                            :
                            this.state.canAssignDept && this.props.viewactionsdata.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {
                        (this.props.viewactionsdata.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.viewactionsdata.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.viewactionsdata.requesttypeid === Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.viewactionsdata.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid === Config.getReportAndStaticId()
                            || this.props.viewactionsdata.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() || this.state.requestStatusReason === Config.getReasonForStudyProvidedId()) && this.props.viewactionsdata.assigneddepartmentid !== Config.getDataDepartmentId()) && this.props.viewactionsdata.assigned === 1 ?
                                this.state.casAssignBackToDataDepartment ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            ;
                                            this.props.manageAction(actions.ASSIGNTODATADEP, this.props.index);
                                        }}>
                                        {
                                            (((this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() || this.state.requestStatusReason === Config.getReasonForStudyProvidedId()) && this.props.viewactionsdata.assigneddepartmentid !== Config.getDataDepartmentId()) && this.props.viewactionsdata.assigned === 1)
                                                ?
                                                i18n[this.getLocale()].sendToDataDep
                                                :
                                                i18n[this.getLocale()].assignToDataDep
                                        }
                                        {/* {i18n[this.getLocale()].assignToDataDep} */}
                                    </MDBBtn>
                                    :
                                    null
                                :
                                null
                            :
                            null
                    }
                    {
                        (this.props.viewactionsdata.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.viewactionsdata.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.viewactionsdata.requesttypeid === Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.viewactionsdata.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid === Config.getReportAndStaticId()
                            || this.props.viewactionsdata.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() || this.state.requestStatusReason === Config.getReasonForStudyProvidedId()) && this.props.viewactionsdata.assigneddepartmentid !== Config.getDataDepartmentId() && this.state.casAssignBackToDataDepartment) && this.props.viewactionsdata.assigned === 1 ?
                                <br />
                                :
                                null
                            :
                            null
                    }

                    {
                        (this.props.viewactionsdata.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.viewactionsdata.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.viewactionsdata.requesttypeid === Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.viewactionsdata.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid === Config.getReportAndStaticId()
                            || this.props.viewactionsdata.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForStudyProvidedId() || this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId()) && (this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager() || this.state.accountTypeId === Config.getAccountPolicesector() || this.state.accountTypeId === Config.getAccountResearchSector() || this.state.accountTypeId === Config.getAccountTypeSalariesDeptManager() || this.state.accountTypeId === Config.getAccountTypeDataDeptManager() || this.state.accountTypeId === Config.getAccountTypeOrganizationDeptManager() || this.state.accountTypeId === Config.getAccountTypeHRMSDeptManager())) && this.props.viewactionsdata.assigned === 1 && this.state.accountTypeId === Config.getAccountTypeDataDeptManager() ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOSPECIFICDEP, this.props.index);
                                    }}>
                                    {i18n[this.getLocale()].assignToSpecificDep}
                                </MDBBtn>
                                :
                                null
                            :
                            null
                    }

                    {
                        (this.props.viewactionsdata.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.viewactionsdata.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.viewactionsdata.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.viewactionsdata.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.viewactionsdata.requesttypeid === Config.getJoinAndImplementId() || this.props.viewactionsdata.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.viewactionsdata.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.viewactionsdata.requesttypeid === Config.getReportAndStaticId()
                            || this.props.viewactionsdata.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForStudyProvidedId() | this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId()) && (this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager() || this.state.accountTypeId === Config.getAccountPolicesector() || this.state.accountTypeId === Config.getAccountResearchSector() || this.state.accountTypeId === Config.getAccountTypeSalariesDeptManager() || this.state.accountTypeId === Config.getAccountTypeDataDeptManager() || this.state.accountTypeId === Config.getAccountTypeOrganizationDeptManager() || this.state.accountTypeId === Config.getAccountTypeHRMSDeptManager())) && this.props.viewactionsdata.assigned === 1 && this.state.accountTypeId === Config.getAccountTypeDataDeptManager() ?
                                <br />
                                :
                                null
                            :
                            null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.props.viewactionsdata.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.viewactionsdata.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.BACKTOMANAGER, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].backToManager}
                                </MDBBtn>
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.props.viewactionsdata.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.viewactionsdata.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAddHraStudy ?
                                this.props.viewactionsdata.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.viewactionsdata.assigned === 1 ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDSTUDY, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].addStudy}
                                    </MDBBtn>
                                    :
                                    null
                                :
                                null

                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAddHraStudy ?
                                this.props.viewactionsdata.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.viewactionsdata.assigned === 1 ?
                                    <br />
                                    :
                                    null
                                :
                                null
                    }
                    {
                        this.props.viewactionsdata.requesttypeid === Config.getSciRequestTypeId() || this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' ?
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getReasonAcceptedId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                // this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonInformationFeedbackProvidedId()
                                ?
                                // this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() && (this.props.viewactionsdata.requesttypeid !== Config.getInformationRequestTypeId() && this.props.viewactionsdata.assigned === 1) ?
                                this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDREPLY, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].addReply}
                                    </MDBBtn>
                                    :
                                    null
                                :
                                // this.state.canProvideFeedback && (this.props.viewactionsdata.requesttypeid !== Config.getInformationRequestTypeId() && this.props.viewactionsdata.assigned === 1) ?
                                this.state.canProvideFeedback ?
                                    < MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDREPLY, this.props.index);
                                        }}>
                                        {i18n[this.getLocale()].addReply}
                                    </MDBBtn>
                                    :
                                    null
                            :
                            null
                    }

                    {
                        this.props.viewactionsdata.requesttypeid === Config.getSciRequestTypeId() || this.props.viewactionsdata.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' ?
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getReasonAcceptedId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                // this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonInformationFeedbackProvidedId()
                                ?
                                // this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() && (this.props.viewactionsdata.requesttypeid !== Config.getInformationRequestTypeId() && this.props.viewactionsdata.assigned === 1) ?
                                this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ?
                                    <br />
                                    :
                                    null
                                :
                                // this.state.canProvideFeedback && (this.props.viewactionsdata.requesttypeid !== Config.getInformationRequestTypeId() && this.props.viewactionsdata.assigned === 1) ?
                                this.state.canProvideFeedback ?
                                    <br />
                                    :
                                    null
                            :
                            null
                    }


                    {
                        this.state.canAddComment ?
                            <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(actions.COMMENT, this.props.index);
                                }}
                            >
                                {
                                    i18n[this.getLocale()].comment
                                }
                            </MDBBtn>
                            :
                            <span></span>
                    }

                    {
                        this.state.canCloseRequest && this.props.viewactionsdata.assigneddepartmentid == this.state.userdepartmentid ?

                            <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {

                                    this.props.manageAction(actions.CloseRequest, this.props.index);
                                }}
                            >
                                {
                                    i18n[this.getLocale()].canCloseRequest
                                }
                            </MDBBtn>

                            : ''
                    }


                    {/* <MDBBtn
            className="gridActionBtn"
            color="primary"
            style={{ color: "var(--btnColor)" }}
            size="sm"
            onClick={() => {
                this.props.manageAction(actions.FOLLOW, this.props.index);
            }}>
            {i18n[this.getLocale()].follow}
        </MDBBtn> */}
                </MDBRow>
            </MDBCol>

        );
    }
}

export default ViewDetailsAction;
