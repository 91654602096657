import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import StepOne from '../components/SecondmentForAFederalAgencySteps/StepOne';
import StepTwo from '../components/SecondmentForAFederalAgencySteps/StepTwo';
import SubHeaderStrip from './../components/SubHeaderStrip';
import i18n from './../i18n';
import Config from './../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import Footer from './../components/Footer';
import { saveSecondmentRequestPayload, uploadDocument } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
let moment = require('moment');

export default class SecondementForFederalAgency extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            requestId: '',
            isEdit: false,
            statusid: '',
            formType: "",
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            isNextScreenActive: false,
            stepNumber: 1,
            employeeName: '',
            entityName: '',
            jobName: '',
            employeeNumber: '',
            basicSalary: '',
            totalSalary: '',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            //step 2
            retirementCont: '',
            reqForBrowEntity: '',
            reqForBrowEntityFileObj: null,
            reqByCompEnty: '',
            reqForCompEntityFileObj: null,
            jobDescFileName: '',
            jobDescFileNameFileObj: null,
            //step 3
            approvalEmpFileName: '',
            approvalEmpFileNameFileObj: "",
            copyOfHRCommitteFileName: '',
            copyOfHRCommFileObj: "",
            copyOfLastYearEffiReportFileName: '',
            copyOfLastYearEffiFileObj: "",
            emiratesIdFileName: '',
            emiratesIdFileObj: "",
            passportCopyFileName: '',
            passportCopyFileObj: "",
            // famliyBookFileName: '',
            // familyBookFileObj: "",
            //step 4
            hraEmpStudyFileName: '',
            hraStudySelectedValue: '',
            hraEmpStudyFileObj: null,
            isSideBarMinimized: false,
            reqForCompEntity: '',
            fromDatePeriodOfSecndMent: null,
            toDatePeriodOfSecndMent: null,
            entityRespForSal: 'Goverment',
            unifiedNumber: '',
            // familyNumber: '',
            emiratesID: '',
            empPassNoticePeriod: false,
            lastYearPerformanceEfficiency: false,
            employeeAgreement: false,
            empShouldPassProbationPeriodLabel: "",
            lastYearPerfDegMoreThanWeekLabel: "",
            employeeAgreementLabel: "",
            OtherAttach: '',
            OtherAttachFileObj:"",
            reasonForGovPaySalary:'',
            BorrowEntityID:'',
            CurrentJobDesc:'',
            CurrentJobDescFileObj:"",
            entitySelectedValue:"",
        }
    }

    componentWillMount = () => {
        let previewFlag = Config.getIsPreviewFlag();
        let previewObj = Config.getPreviewObj();
        let previewArray = Config.getPreviewAttachmentArray();

        //For Preview Form
        if (previewFlag) {

            let empPassNoticePeriodValue = false,
                lastYearPerfDegMoreThanWeekValue = false,
                employeeAgreementValue = false,
                approvalEmpFileName = "",
                reqForBrowsEntity = "",
                copyOfHrComitte = "",
                jobDesFile = "",
                copyOfLastYearEffiReportFileName = "",
                emiratesIdFileName = "",
                passportCopyFileName = "",
                reqByCompEnty = ""
                ,OtherAttach="",CurrentJobDesc="",entitySelectedValue="";


            if (previewObj.empShouldPassProbationPeriodLabel) {
                if (previewObj.empShouldPassProbationPeriodLabel === i18n["en"].yes || previewObj.empShouldPassProbationPeriodLabel === i18n["ar"].yes) {
                    empPassNoticePeriodValue = true;
                } else {
                    empPassNoticePeriodValue = false;
                }
            }

            if (previewObj.lastYearPerfDegMoreThanWeekLabel) {
                if (previewObj.lastYearPerfDegMoreThanWeekLabel === i18n["en"].yes || previewObj.lastYearPerfDegMoreThanWeekLabel === i18n["ar"].yes) {
                    lastYearPerfDegMoreThanWeekValue = true;
                } else {
                    lastYearPerfDegMoreThanWeekValue = false;
                }
            }

            if (previewObj.employeeAgreementLabel) {
                if (previewObj.employeeAgreementLabel === i18n["en"].yes || previewObj.employeeAgreementLabel === i18n["ar"].yes) {
                    employeeAgreementValue = true;
                }
                else {
                    employeeAgreementValue = false;
                }
            }

            let langValueFromPreviewForentityRespSal = "";
            if (previewObj.entityRespForSal === i18n["en"].secondement_For_Federal_Agency_Step.goverment || previewObj.entityRespForSal === i18n["ar"].secondement_For_Federal_Agency_Step.goverment) {

                langValueFromPreviewForentityRespSal = "Goverment";
            }
            else if (previewObj.entityRespForSal === i18n["en"].secondement_For_Federal_Agency_Step.competent || i18n["ar"].secondement_For_Federal_Agency_Step.competent) {
                langValueFromPreviewForentityRespSal = "Competent";
            }

            for (let index = 0; index < previewArray.length; index++) {
                if (previewArray[index].label === 'CurrentJobDesc') {
                    CurrentJobDesc = previewArray[index].fileName;
                }
                if (previewArray[index].label === 'approvalEmpFileName') {
                    approvalEmpFileName = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'reqForBrowEntity') {
                    reqForBrowsEntity = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'copyOfHRCommitteFileName') {
                    copyOfHrComitte = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'jobDescFileName') {
                    jobDesFile = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'copyOfLastYearEffiReportFileName') {
                    copyOfLastYearEffiReportFileName = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'emiratesIdFileName') {
                    emiratesIdFileName = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'passportCopyFileName') {
                    passportCopyFileName = previewArray[index].fileName;
                }
                else if (previewArray[index].label === 'reqByCompEnty') {
                    reqByCompEnty = previewArray[index].fileName;
                }
                else if(previewArray[index].label === 'OtherAttach')
                {
                    OtherAttach = previewArray[index].fileName;

                }
            }

            this.setState({
                requestId: previewObj.requestId,
                isEdit: previewObj.isEdit,
                entityName: previewObj.entityName,
                employeeName: previewObj.employeeName,
                jobName: previewObj.jobName,
                employeeNumber: previewObj.employeeNumber,
                totalSalary: previewObj.totalSalary,
                basicSalary: previewObj.basicSalary,
                retirementCont: previewObj.retirementCont,
                fromDatePeriodOfSecndMent: previewObj.fromDatePeriodOfSecndMent ? new Date(previewObj.fromDatePeriodOfSecndMent) : null,
                toDatePeriodOfSecndMent: previewObj.toDatePeriodOfSecndMent ? new Date(previewObj.toDatePeriodOfSecndMent) : null,
                entityRespForSal: langValueFromPreviewForentityRespSal,
                unifiedNumber: previewObj.unifiedNumber,
                reasonForGovPaySalary:previewObj.tempreasonForGovPaySalary,
                BorrowEntityID:previewObj.tempBorrowEntityID,
               
                emiratesID: previewObj.emiratesID,
                empPassNoticePeriod: empPassNoticePeriodValue,
                empShouldPassProbationPeriodLabel: previewObj.empShouldPassProbationPeriodLabel,
                lastYearPerfDegMoreThanWeekLabel: previewObj.lastYearPerfDegMoreThanWeekLabel,
                employeeAgreementLabel: previewObj.employeeAgreementLabel,
                lastYearPerformanceEfficiency: lastYearPerfDegMoreThanWeekValue,
                employeeAgreement: employeeAgreementValue,
                approvalEmpFileName: approvalEmpFileName,
                reqForBrowEntity: reqForBrowsEntity,
                copyOfHRCommitteFileName: copyOfHrComitte,
                jobDescFileName: jobDesFile,
                copyOfLastYearEffiReportFileName: copyOfLastYearEffiReportFileName,
                emiratesIdFileName: emiratesIdFileName,
                passportCopyFileName: passportCopyFileName,
                // famliyBookFileName: this.state.famliyBookFileName,
                reqByCompEnty: reqByCompEnty,
                OtherAttach:OtherAttach,
                CurrentJobDesc:CurrentJobDesc,
            });
        }
    }

    componentDidMount = () => {

        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                    // TODO
                })
            })
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    uploadFile = (i, totalCount, resSecondmentForFederalAgency) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};

            if (i === 0) {
                fileObj = Config.getBase64ForSecondment_reqForBrowEntity();
                obj.filename = this.state.reqForBrowEntity;
                obj.filetype = Config.getBorrowingEntityId();
                tempBase64 = fileObj;
            } 
            // else if (i === 1) {
            //     fileObj = Config.getBase64ForSecondment_reqForCompEntity();
            //     obj.filename = this.state.reqByCompEnty;
            //     obj.filetype = Config.getCompetentEntityId();
            //     tempBase64 = fileObj;
            // } 
            else if (i === 1) {
                fileObj = Config.getBase64ForSecondment_copyOfHRCommitteFileName();
                if (fileObj) {
                    obj.filename = this.state.copyOfHRCommitteFileName;
                    obj.filetype = Config.getHrCommitteId();
                    tempBase64 = fileObj;
                } else {
                    tempBase64 = '';
                }
            } else if (i === 2) {
                fileObj = Config.getBase64ForSecondment_copyOfLastYearEffiReportFileName();
                obj.filename = this.state.copyOfLastYearEffiReportFileName;
                obj.filetype = Config.getLastYearEfficiencyReportId();
                tempBase64 = fileObj;
            } else if (i === 3) {
                fileObj = Config.getBase64ForSecondment_emiratesIdFileName();
                obj.filename = this.state.emiratesIdFileName;
                obj.filetype = Config.getEmaritesId();
                tempBase64 = fileObj;
            } else if (i === 4) {
                fileObj = Config.getBase64ForSecondment_passportCopyFileName();
                obj.filename = this.state.passportCopyFileName;
                obj.filetype = Config.getPassportCopyId();
                tempBase64 = fileObj;
            }
            // else if (i === 6) {
            //     fileObj = Config.getBase64ForSecondment_famliyBookFileName();
            //     obj.filename = this.state.famliyBookFileName;
            //     obj.filetype = Config.getFamilyBookId();
            //     tempBase64 = fileObj;
            // } 
            else if (i === 5) {
                fileObj = Config.getBase64ForSecondment_approvalEmpFileName();
                if (fileObj) {
                    obj.filename = this.state.approvalEmpFileName;
                    obj.filetype = Config.getApprovalEmployeeId();
                    tempBase64 = fileObj;
                } else {
                    tempBase64 = '';
                }
            } else if (i === 6) {
                fileObj = Config.getBase64ForSecondment_jobDescFileName();
                obj.filename = this.state.jobDescFileName;
                obj.filetype = Config.getJobDescriptionId();
                tempBase64 = fileObj;
            }
            else if (i === 7) {
                fileObj = Config.getBase64ForOtherAttach();
                obj.filename = this.state.OtherAttach;
                obj.filetype = Config.getRETOtherAttachId();
                tempBase64 = fileObj;
            }
            else if (i === 8) {
                fileObj = Config.getBase64ForSecondment_CurrentJobDesc();
                obj.filename = this.state.CurrentJobDesc;
                obj.filetype = Config.getFederalCurrentJobDescId();
                tempBase64 = fileObj;
            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resSecondmentForFederalAgency && resSecondmentForFederalAgency.data && resSecondmentForFederalAgency.data.id) {
                    reqId = resSecondmentForFederalAgency.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resSecondmentForFederalAgency.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';

                let url = Config.getUrl(endPoint);
                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        this.uploadFile(i + 1, totalCount, resSecondmentForFederalAgency);
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        });
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resSecondmentForFederalAgency);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            });
        }
    }

    submitApiCall = () => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let requestObj = {}
                requestObj.secondmententityname = this.state.entityName;
                requestObj.employeenumber = this.state.employeeNumber;
                requestObj.jobname = this.state.jobName;
                requestObj.employeename = this.state.employeeName;
                requestObj.basicsalary = this.state.basicSalary;
                requestObj.totalsalary = this.state.totalSalary;
                requestObj.retirementcontribution = this.state.retirementCont;

                let tempDateOne = '';
                if (this.state.fromDatePeriodOfSecndMent !== '' && this.state.fromDatePeriodOfSecndMent !== null) {
                    tempDateOne = new Date(this.state.toDatePeriodOfSecndMent);
                    let dayOne = tempDateOne.getDate();
                    let monthOne = tempDateOne.getMonth() + 1;
                    let yearOne = tempDateOne.getFullYear();
                    let finalDateOne = yearOne + '-' + monthOne + '-' + dayOne;
                    requestObj.secondmentfrom = finalDateOne;
                } else {
                    requestObj.secondmentfrom = tempDateOne;
                }

                let tempDateTwo = ''
                if (this.state.toDatePeriodOfSecndMent !== '' && this.state.toDatePeriodOfSecndMent !== null) {
                    tempDateTwo = new Date(this.state.toDatePeriodOfSecndMent);
                    let dayTwo = tempDateTwo.getDate();
                    let monthTwo = tempDateTwo.getMonth() + 1;
                    let yearTwo = tempDateTwo.getFullYear();

                    let finalDateTwo = yearTwo + '-' + monthTwo + '-' + dayTwo;
                    requestObj.secondmentto = finalDateTwo;
                } else {
                    requestObj.secondmentto = tempDateTwo;
                }

                requestObj.unifiedNumber = this.state.unifiedNumber;
                // requestObj.familyNumber = this.state.familyNumber;
                requestObj.familyNumber = 1;
                requestObj.emiratesid = this.state.emiratesID;
                requestObj.empPassNoticePeriod = this.state.empPassNoticePeriod ? 1 : 0;
                requestObj.lastYearPerformanceEfficiency = this.state.lastYearPerformanceEfficiency ? 1 : 0;
                requestObj.employeeAgreement = this.state.employeeAgreement ? 1 : 0;
                // requestObj.entityRespForSal = this.state.entityRespForSal;
                requestObj.reqByCompEnty = this.state.reqByCompEnty;
                requestObj.salaryownerid = this.state.entityRespForSal === i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.goverment ? 1 : 2;

                requestObj.isEdit = this.state.isEdit;
                requestObj.requestId = this.state.requestId;

              
                if (this.state.formType === "saveAsDraft") {
                    // this.state.dataObject.status = 'saveAsDraft';
                    requestObj.draft = 1;
                } else {
                    // this.state.dataObject.status = 'submit';
                    requestObj.draft = 0;
                }

                requestObj.reasonForGovPaySalary= this.state.reasonForGovPaySalary;
                requestObj.BorrowEntityID=this.state.BorrowEntityID;
                let payload = saveSecondmentRequestPayload(requestObj);
                
                let endPoint = 'SaveFederalSecondment';
                let url = Config.getUrl(endPoint);
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resSecondmentForFederalAgency => {
                        if (resSecondmentForFederalAgency.Status) {
                            let totalCount = 9;
                            this.uploadFile(0, totalCount, resSecondmentForFederalAgency);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            })
                        }
                    });
                });
            })
        }
    }

    stepTwoData = (obj) => {
        if (obj.saveFrom === "Step1") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                jobName: obj.jobName,
                employeeName: obj.employeeName,
                employeeNumber: obj.employeeNumber,
                entityName: obj.entityName,
                totalSalary: obj.totalSalary,
                basicSalary: obj.basicSalary,
                retirementCont: obj.retirementCont,
                // hraStudySelectedValue: obj.hraEmpStudyMakerSelectedValue,
                entityRespForSal: obj.entityRespForSal,
                fromDatePeriodOfSecndMent: obj.fromDatePeriodOfSecndMent,
                toDatePeriodOfSecndMent: obj.toDatePeriodOfSecndMent,
                unifiedNumber: obj.unifiedNumber,
                reasonForGovPaySalary:obj.reasonForGovPaySalary,
                BorrowEntityID:obj.BorrowEntityID,
                emiratesID: obj.emiratesID,
                empPassNoticePeriod: obj.empPassNoticePeriod,
                lastYearPerformanceEfficiency: obj.lastYearPerformanceEfficiency,
                employeeAgreement: obj.employeeAgreement,
                empShouldPassProbationPeriodLabel: obj.empShouldPassProbationPeriodLabel,
                lastYearPerfDegMoreThanWeekLabel: obj.lastYearPerfDegMoreThanWeekLabel,
                employeeAgreementLabel: obj.employeeAgreementLabel
            }, () => {
                this.submitApiCall();
            });
        } else {
            this.setState({
                // step1: true, step2: false,
                requestId: obj.requestId,
                isEdit: obj.isEdit,
                formType: obj.formType,
                approvalEmpFileName: obj.approvalEmpFileName,
                reqForBrowEntity: obj.reqForBrowEntity,
                copyOfHRCommitteFileName: obj.copyOfHRCommitteFileName,
                jobDescFileName: obj.jobDescFileName,
                copyOfLastYearEffiReportFileName: obj.copyOfLastYearEffiReportFileName,
                emiratesIdFileName: obj.emiratesIdFileName,
                passportCopyFileName: obj.passportCopyFileName,
                // famliyBookFileName: obj.famliyBookFileName,
                // reqByCompEnty: obj.reqByCompEnty,
                OtherAttach:obj.OtherAttach,
                CurrentJobDesc:obj.CurrentJobDesc
            }, () => {
                this.submitApiCall();
            });
        }
    }

    stepOneData = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            setConfigObj.secondmententityname = obj.entityName;
            setConfigObj.employeename = obj.employeeName;
            setConfigObj.jobname = obj.jobName;
            setConfigObj.employeenumber = obj.employeeNumber;
            setConfigObj.basicsalary = obj.basicSalary;
            setConfigObj.totalsalary = obj.totalSalary;
            setConfigObj.unifiednumber = obj.unifiedNumber;
            setConfigObj.reasonForGovPaySalary=obj.reasonForGovPaySalary;
            setConfigObj.BorrowEntityID=obj.BorrowEntityID;
            setConfigObj.emiratesid = obj.emiratesID;
            setConfigObj.passtestperiod = obj.empPassNoticePeriod === true ? '1' : '0';
            setConfigObj.performancedegree = obj.lastYearPerformanceEfficiency === true ? '1' : '0';
            setConfigObj.employeeagreement = obj.employeeAgreement === true ? '1' : '0';
            setConfigObj.retirementcontribution = obj.retirementCont;
            setConfigObj.secondmentfrom = obj.fromDatePeriodOfSecndMent;
            setConfigObj.secondmentto = obj.toDatePeriodOfSecndMent;
            setConfigObj.salaryownerid = obj.entityRespForSal === "Goverment" ? 1 : 2;

            Config.setEditObj(setConfigObj);
        }
        this.setState({
            step1: false,
            step2: true,
            jobName: obj.jobName,
            employeeName: obj.employeeName,
            employeeNumber: obj.employeeNumber,
            entityName: obj.entityName,
            totalSalary: obj.totalSalary,
            basicSalary: obj.basicSalary,
            retirementCont: obj.retirementCont,
            // hraStudySelectedValue: obj.hraEmpStudyMakerSelectedValue,
            entityRespForSal: obj.entityRespForSal,
            fromDatePeriodOfSecndMent: obj.fromDatePeriodOfSecndMent,
            toDatePeriodOfSecndMent: obj.toDatePeriodOfSecndMent,
            unifiedNumber: obj.unifiedNumber,
            reasonForGovPaySalary:obj.reasonForGovPaySalary,
            BorrowEntityID:obj.BorrowEntityID,
            emiratesID: obj.emiratesID,
            empPassNoticePeriod: obj.empPassNoticePeriod,
            lastYearPerformanceEfficiency: obj.lastYearPerformanceEfficiency,
            employeeAgreement: obj.employeeAgreement,
            empShouldPassProbationPeriodLabel: obj.empShouldPassProbationPeriodLabel,
            lastYearPerfDegMoreThanWeekLabel: obj.lastYearPerfDegMoreThanWeekLabel,
            employeeAgreementLabel: obj.employeeAgreementLabel
            // familyNumber: obj.familyNumber,
            //empPassNoticePeriod: obj.empPassNoticePeriod,
            // lastYearPerformanceEfficiency: obj.lastYearPerformanceEfficiency,
            //employeeAgreement: obj.employeeAgreement
        });
    }

    stepTwoDataPrev = (obj) => {
        this.setState({
            step1: true, step2: false,
            approvalEmpFileName: obj.approvalEmpFileName,
            reqForBrowEntity: obj.reqForBrowEntity,
            copyOfHRCommitteFileName: obj.copyOfHRCommitteFileName,
            jobDescFileName: obj.jobDescFileName,
            copyOfLastYearEffiReportFileName: obj.copyOfLastYearEffiReportFileName,
            emiratesIdFileName: obj.emiratesIdFileName,
            passportCopyFileName: obj.passportCopyFileName,
            //famliyBookFileName: obj.famliyBookFileName,
            // reqByCompEnty: obj.reqByCompEnty,
            OtherAttach:obj.OtherAttach,
            CurrentJobDesc:obj.CurrentJobDesc,
        });
    }

    getPrev = {
        stepTwoDataPrev: this.stepTwoDataPrev,
    };

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
    };

    navigate = () => {
        this.setState({
            step2: false, step1: false
        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    handlePreViewNavigation = (obj) => {
       
        localStorage.setItem('currentPath', 'secondmentForFederalAgency');
let tempentityarr = Config.getEntityData().filter(x=> x.id ==parseInt(this.state.BorrowEntityID))[0];

        let previewObj = {};
        previewObj.jobName = this.state.jobName;
        previewObj.employeeName = this.state.employeeName;
        previewObj.employeeNumber = this.state.employeeNumber;
        previewObj.entityName = this.state.entityName;
        previewObj.totalSalary = this.state.totalSalary;
        previewObj.basicSalary = this.state.basicSalary;
        previewObj.retirementCont = this.state.retirementCont;
        previewObj.fromDatePeriodOfSecndMent = this.state.fromDatePeriodOfSecndMent ? moment(this.state.fromDatePeriodOfSecndMent).format('L').toString() : "";
        previewObj.toDatePeriodOfSecndMent = this.state.toDatePeriodOfSecndMent ? moment(this.state.toDatePeriodOfSecndMent).format('L').toString() : "";
        previewObj.unifiedNumber = this.state.unifiedNumber;
        previewObj.tempreasonForGovPaySalary=this.state.reasonForGovPaySalary;
        previewObj.BorrowEntityID=this.state.isArabic?tempentityarr.arabicName:tempentityarr.englishName;   
        previewObj.tempBorrowEntityID=this.state.BorrowEntityID;

        previewObj.emiratesID = this.state.emiratesID;
        previewObj.empShouldPassProbationPeriodLabel = this.state.empPassNoticePeriod ? i18n[this.state.isArabic ? "ar" : "en"].yes : i18n[this.state.isArabic ? "ar" : "en"].no;
        previewObj.lastYearPerfDegMoreThanWeekLabel = this.state.lastYearPerformanceEfficiency ? i18n[this.state.isArabic ? "ar" : "en"].yes : i18n[this.state.isArabic ? "ar" : "en"].no;
        previewObj.employeeAgreementLabel = this.state.employeeAgreement ? i18n[this.state.isArabic ? "ar" : "en"].yes : i18n[this.state.isArabic ? "ar" : "en"].no;

        let langValueForEntityRespForSal = "";
        if (this.state.entityRespForSal === "Goverment") {
            langValueForEntityRespForSal = i18n[this.state.isArabic ? "ar" : "en"].secondement_For_Federal_Agency_Step.goverment;

        }
        else if (this.state.entityRespForSal === "Competent") {
            langValueForEntityRespForSal = i18n[this.state.isArabic ? "ar" : "en"].secondement_For_Federal_Agency_Step.competent;

        }

        previewObj.entityRespForSal = langValueForEntityRespForSal;

        let previewAttachMentArray = [
            {
                "label": "CurrentJobDesc",
                "fileName": obj.CurrentJobDesc,
                "fileObj": Config.getBase64ForSecondment_CurrentJobDesc()
            },
            {
                "label": "approvalEmpFileName",
                "fileName": obj.approvalEmpFileName,
                "fileObj": Config.getBase64ForSecondment_approvalEmpFileName()
            },
            {
                "label": "reqForBrowEntity",
                "fileName": obj.reqForBrowEntity,
                "fileObj": Config.getBase64ForSecondment_reqForBrowEntity()
            },
            {
                "label": "copyOfHRCommitteFileName",
                "fileName": obj.copyOfHRCommitteFileName,
                "fileObj": Config.getBase64ForSecondment_copyOfHRCommitteFileName()
            },
            {
                "label": "jobDescFileName",
                "fileName": obj.jobDescFileName,
                "fileObj": Config.getBase64ForSecondment_jobDescFileName()
            },
            {
                "label": "copyOfLastYearEffiReportFileName",
                "fileName": obj.copyOfLastYearEffiReportFileName,
                "fileObj": Config.getBase64ForSecondment_copyOfLastYearEffiReportFileName()
            },
            {
                "label": "emiratesIdFileName",
                "fileName": obj.emiratesIdFileName,
                "fileObj": Config.getBase64ForSecondment_emiratesIdFileName()
            },
            {
                "label": "passportCopyFileName",
                "fileName": obj.passportCopyFileName,
                "fileObj": Config.getBase64ForSecondment_passportCopyFileName()
            },
            // {
            //     "label": "reqByCompEnty",
            //     "fileName": obj.reqByCompEnty,
            //     "fileObj": Config.getBase64ForSecondment_reqForCompEntity()
            // },
            {
                "label": 'OtherAttach',
                "fileName": obj.OtherAttach,
                "fileObj": Config.getBase64ForOtherAttach(),
            }];
            let ckEditorArr= [
                
                    {
                        label: 'reasonForGovPaySalary',
                        value: this.state.reasonForGovPaySalary,
                    },  
               
            ];
        this.setState({
            pathName: 'secondmentForFederalAgency'
        }, () => {
            this.props.history.replace('/preview',
                { data: previewObj, attachmentArr: previewAttachMentArray, ckEditorArr:ckEditorArr });
        });

    }

    render() {
        const sideBarData = Config.getServiceSideBar('secondmentForFederalAgency', this.state.isArabic, this, 0);
        return (

            <MDBContainer fluid style={{ height: '100vh' }}>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                    <MDBRow
                        className="formWhiteContainer"
                        style={{
                            //  width: '100vw',
                            flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                        }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'secondmentForFederalAgency' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].secondmentForFederal}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            {
                                this.state.step1 ?
                                    <StepOne
                                        isArabic={this.state.isArabic}
                                        sendData={{
                                            jobName: this.state.jobName,
                                            employeeName: this.state.employeeName,
                                            entityName: this.state.entityName,
                                            employeeNumber: this.state.employeeNumber,
                                            basicSalary: this.state.basicSalary,
                                            totalSalary: this.state.totalSalary,
                                            retirementCont: this.state.retirementCont,
                                            entityRespForSal: this.state.entityRespForSal,
                                            fromDatePeriodOfSecndMent: this.state.fromDatePeriodOfSecndMent,
                                            toDatePeriodOfSecndMent: this.state.toDatePeriodOfSecndMent,
                                            hraStudySelectedValue: this.state.hraStudySelectedValue,
                                            unifiedNumber: this.state.unifiedNumber,
                                            reasonForGovPaySalary:this.state.reasonForGovPaySalary,
                                            BorrowEntityID:this.state.BorrowEntityID,
                                            // familyNumber: this.state.familyNumber,
                                            emiratesID: this.state.emiratesID,
                                            empPassNoticePeriod: this.state.empPassNoticePeriod,
                                            lastYearPerformanceEfficiency: this.state.lastYearPerformanceEfficiency,
                                            employeeAgreement: this.state.employeeAgreement,
                                            empShouldPassProbationPeriodLabel: this.state.empShouldPassProbationPeriodLabel,
                                            lastYearPerfDegMoreThanWeekLabel: this.state.lastYearPerfDegMoreThanWeekLabel,
                                            employeeAgreementLabel: this.state.employeeAgreementLabel
                                        }}
                                        getData={this.getData}
                                        saveAsDraft={this.stepTwoData}

                                    />
                                    :
                                    null
                            }
                            {
                                this.state.step2 ?
                                    <StepTwo
                                        isArabic={this.state.isArabic}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        saveAsDraft={this.stepTwoData}
                                        previewPage={this.handlePreViewNavigation}
                                        sendData={{
                                            //Step 1
                                            jobName: this.state.jobName,
                                            employeeName: this.state.employeeName,
                                            entityName: this.state.entityName,
                                            employeeNumber: this.state.employeeNumber,
                                            basicSalary: this.state.basicSalary,
                                            totalSalary: this.state.totalSalary,
                                            retirementCont: this.state.retirementCont,
                                            entityRespForSal: this.state.entityRespForSal,
                                            fromDatePeriodOfSecndMent: this.state.fromDatePeriodOfSecndMent,
                                            toDatePeriodOfSecndMent: this.state.toDatePeriodOfSecndMent,
                                            hraStudySelectedValue: this.state.hraStudySelectedValue,
                                            unifiedNumber: this.state.unifiedNumber,
                                            reasonForGovPaySalary:this.state.reasonForGovPaySalary,
                                            BorrowEntityID:this.state.BorrowEntityID,
                                            // familyNumber: this.state.familyNumber,
                                            emiratesID: this.state.emiratesID,
                                            empPassNoticePeriod: this.state.empPassNoticePeriod,
                                            lastYearPerformanceEfficiency: this.state.lastYearPerformanceEfficiency,
                                            employeeAgreement: this.state.employeeAgreement,
                                            empShouldPassProbationPeriodLabel: this.state.empShouldPassProbationPeriodLabel,
                                            lastYearPerfDegMoreThanWeekLabel: this.state.lastYearPerfDegMoreThanWeekLabel,
                                            employeeAgreementLabel: this.state.employeeAgreementLabel,

                                            //Step 2
                                            reqForBrowEntity: this.state.reqForBrowEntity,
                                            jobDescFileName: this.state.jobDescFileName,
                                            approvalEmpFileName: this.state.approvalEmpFileName,
                                            copyOfHRCommitteFileName: this.state.copyOfHRCommitteFileName,
                                            copyOfLastYearEffiReportFileName: this.state.copyOfLastYearEffiReportFileName,
                                            emiratesIdFileName: this.state.emiratesIdFileName,
                                            passportCopyFileName: this.state.passportCopyFileName,
                                            famliyBookFileName: this.state.famliyBookFileName,
                                            reqByCompEnty: this.state.reqByCompEnty,
                                            OtherAttach:this.state.OtherAttach,
                                            CurrentJobDesc:this.state.CurrentJobDesc
                                        }}
                                        next={this.navigate} getData={this.getData} getPrev={this.getPrev} /> : null}

                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}