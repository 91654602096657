import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import './../../css/style.css';
import i18n from './../../i18n';
import StepsComponent from "./../StepsComponent";
import Config from '../../utils/Config';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from '../../utils/Validator';
import CurrencyFormat from 'react-currency-format';
const striptags = require('striptags');

export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: false,
            statusid: '',
            entityLogo: this.props.sendData.entityLogo,
            entityName: this.props.sendData.entityName,
            cordinatorName: this.props.sendData.cordinatorName,
            jobName: this.props.sendData.jobName,
            email: this.props.sendData.email,
            contactNumber: this.props.sendData.contactNumber,
            contactNumberMobile: this.props.sendData.contactNumberMobile,
            cordinatorName2: this.props.sendData.cordinatorName2,
            jobName2: this.props.sendData.jobName2,
            email2: this.props.sendData.email2,
            contactNumber2: this.props.sendData.contactNumber2,
            contactNumberMobile2: this.props.sendData.contactNumberMobile2,
            haveBudget: this.props.sendData.haveBudget,
            reasonsToPayBonuses: this.props.sendData.reasonsToPayBonuses,
            entityAchivementsForYear: this.props.sendData.entityAchivementsForYear,
            enterTheBudget: this.props.sendData.enterTheBudget,
            enterTheYear1: this.props.sendData.enterTheYear1,
            percentageOne: this.props.sendData.percentageOne,
            percentageTwo: this.props.sendData.percentageTwo,
            yearOne: this.props.sendData.yearOne,
            yearTwo: this.props.sendData.yearTwo,
            entityLogoFileObj: null,
            emailErr: '',
            email2Err: '',
            yearOneErr: '',
            yearTwoErr: '',
            enterTheYear1Err: '',
            phoneNumberErro: '',
            reasonsToPayBonusesErr: '',
            entityAchivementsForYearErr: '',
            haveBudgetErr: '',
            contactNumberErr: '',
            contactNumberMobileErr: '',
            contactNumber2Err: '',
            contactNumberMobile2Err: '',
            enterTheBudgetErr: ""
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                entityName: editObj.entityname,
                cordinatorName: editObj.coordinatorname,
                jobName: editObj.jobname,
                contactNumber: editObj.contactnumber,
                contactNumberMobile: editObj.phonenumber,
                email: editObj.email,
                cordinatorName2: editObj.subcoordinatorname,
                jobName2: editObj.subjobname,
                contactNumber2: editObj.subcontactnumber,
                contactNumberMobile2: editObj.subphonenumber,
                email2: editObj.subemail,
                haveBudget: editObj.isbudgeted === 1 ? "Yes" : "No",
                reasonsToPayBonuses: editObj.bonusreason,
                entityAchivementsForYear: editObj.yearachievment,
                enterTheBudget: editObj.budget,
                enterTheYear1: editObj.budgetyear,
                percentageOne: editObj.emiratizationpercentage,
                percentageTwo: editObj.emiratizationpercentage2,
                yearOne: editObj.year1,
                yearTwo: editObj.year2,
            }, () => {
                // TODO
            });
        } else {
            let loginData = Config.getLocalStorageLoginData();
            this.setState({
                loginData: loginData
            }, () => {
                if (loginData) {
                    let tempEntityName = this.props.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn
                    this.setState({
                        entityName: tempEntityName
                    })
                }
            })
        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

       

        let emailErr = '', email2Err = '', enterTheYear1Err = '', yearOneErr = '', yearTwoErr = '', contactNumberErr = '', contactNumberMobileErr = '', contactNumber2Err = '', contactNumberMobile2Err = '', entityAchivementsForYearErr = '', reasonsToPayBonusesErr = '', haveBudgetErr = '', enterTheBudgetErr = "";

       

        if (!this.state.enterTheBudget) {
            enterTheBudgetErr = "thisFieldIsRequired";
        }
        
        //email 
        if (this.state.email) {
            if (!Validator.validateEmail(this.state.email)) {
                emailErr = 'invalidEmail';
            } else {
                emailErr = '';
            }
        }
        else {
            emailErr = 'thisFieldIsRequired';
        }

        //email2
        if (this.state.email2) {
            if (!Validator.validateEmail(this.state.email2)) {
                email2Err = 'invalidEmail';
            } else {
                email2Err = '';
            }
        }
        else {
            email2Err = '';
        }

        // Enter the year1
        if (this.state.enterTheYear1) {
            if (!Validator.validateYear(this.state.enterTheYear1)) {
                enterTheYear1Err = 'invalidYear';
            } else {
                enterTheYear1Err = '';
            }
        } else {
            enterTheYear1Err = 'thisFieldIsRequired';
        }

        //yearOne
        if (this.state.yearOne) {
            if (!Validator.validateYear(this.state.yearOne)) {
                yearOneErr = 'invalidYear';
            } else {
                yearOneErr = '';
            }
        } else {
            yearOneErr = 'thisFieldIsRequired';
        }

        // yearTwo
        if (this.state.yearTwo) {
            if (!Validator.validateYear(this.state.yearTwo)) {
                yearTwoErr = 'invalidYear';
            } else {
                yearTwoErr = '';
            }
        } else {
            yearTwoErr = 'thisFieldIsRequired';
        }

        //contactNumber
        if (this.state.contactNumber) {
            if (!Validator.validateContactNumber(this.state.contactNumber)) {
                contactNumberErr = 'invalidContactNumber';
            } else {
                contactNumberErr = '';
            }
        } else {
            contactNumberErr = 'thisFieldIsRequired';
        }

        //contactNumber mobile
        if (this.state.contactNumberMobile) {
            if (!Validator.validateContactNumber(this.state.contactNumberMobile)) {
                contactNumberMobileErr = 'invalidMobileNumber';
            } else {
                contactNumberMobileErr = '';
            }
        } else {
            contactNumberMobileErr = 'thisFieldIsRequired';
        }

        //contactNumber2
        if (this.state.contactNumber2) {
            if (!Validator.validateContactNumber(this.state.contactNumber2)) {
                contactNumber2Err = 'invalidContactNumber';
            } else {
                contactNumber2Err = '';
            }
        } else {
            contactNumber2Err = '';
        }

        //contactNumber mobile2
        if (this.state.contactNumberMobile2) {
            if (!Validator.validateContactNumber(this.state.contactNumberMobile2)) {
                contactNumberMobile2Err = 'invalidMobileNumber';
            } else {
                contactNumberMobile2Err = '';
            }
        } else {
            contactNumberMobile2Err = '';
        }

        if (this.state.reasonsToPayBonuses && this.state.reasonsToPayBonuses.trim()) {
            let val = striptags(this.state.reasonsToPayBonuses.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                reasonsToPayBonusesErr = 'thisFieldisRequired';
            }
        } else {
            reasonsToPayBonusesErr = 'thisFieldisRequired';
        }

        if (this.state.entityAchivementsForYear && this.state.entityAchivementsForYear.trim()) {
            let val = striptags(this.state.entityAchivementsForYear.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                entityAchivementsForYearErr = 'thisFieldisRequired';
            }
        } else {
            entityAchivementsForYearErr = 'thisFieldisRequired';
        }

        if (!this.state.haveBudget) {
            haveBudgetErr = 'thisFieldIsRequired';
        }

        this.setState({
            emailErr: emailErr,
            email2Err: email2Err,
            enterTheYear1Err: enterTheYear1Err,
            yearOneErr: yearOneErr,
            yearTwoErr: yearTwoErr,
            contactNumberErr: contactNumberErr,
            contactNumberMobileErr: contactNumberMobileErr,
            contactNumber2Err: contactNumber2Err,
            contactNumberMobile2Err: contactNumberMobile2Err,
            entityAchivementsForYearErr: entityAchivementsForYearErr,
            reasonsToPayBonusesErr: reasonsToPayBonusesErr,
            haveBudgetErr: haveBudgetErr,
            enterTheBudgetErr: enterTheBudgetErr
        }, () => {
            if (!emailErr &&
                !email2Err &&
                !enterTheYear1Err &&
                !yearOneErr &&
                !yearTwoErr &&
                !contactNumberErr &&
                !contactNumberMobileErr &&
                !contactNumber2Err &&
                !contactNumberMobile2Err &&
                !entityAchivementsForYearErr &&
                !reasonsToPayBonusesErr &&
                !haveBudgetErr &&
                !enterTheBudgetErr) {

              
                //haveBudget = Yes

               

                if (this.state.haveBudget === 'Yes') {

                    if (
                        this.state.percentageTwo &&
                        this.state.percentageOne &&
                        this.state.entityName &&
                        this.state.enterTheBudget &&
                        this.state.cordinatorName &&
                        this.state.jobName &&
                        this.state.haveBudget &&
                        striptags(this.state.reasonsToPayBonuses) &&
                        striptags(this.state.entityAchivementsForYear)
                    ) {

                        this.props.getData.stepOneData({
                            isEdit: this.state.isEdit,
                            requestId: this.state.requestId,
                            entityName: this.state.entityName,
                            cordinatorName: this.state.cordinatorName,
                            jobName: this.state.jobName,
                            contactNumber: this.state.contactNumber,
                            contactNumberMobile: this.state.contactNumberMobile,
                            email: this.state.email,
                            cordinatorName2: this.state.cordinatorName2,
                            jobName2: this.state.jobName2,
                            contactNumber2: this.state.contactNumber2,
                            contactNumberMobile2: this.state.contactNumberMobile2,
                            email2: this.state.email2,
                            haveBudget: this.state.haveBudget,
                            reasonsToPayBonuses: this.state.reasonsToPayBonuses,
                            entityAchivementsForYear: this.state.entityAchivementsForYear,
                            enterTheBudget: this.state.enterTheBudget,
                            enterTheYear1: this.state.enterTheYear1,
                            percentageOne: this.state.percentageOne,
                            percentageTwo: this.state.percentageTwo,
                            yearOne: this.state.yearOne,
                            yearTwo: this.state.yearTwo,
                        });
                    }

                } else {
                    
                    if (this.state.percentageTwo && 
                        this.state.percentageOne && 
                        this.state.entityName && 
                        this.state.cordinatorName && 
                        this.state.jobName && 
                        this.state.email && 
                        this.state.haveBudget && 
                        this.state.reasonsToPayBonuses && 
                        this.state.entityAchivementsForYear) {
                        
                        this.props.getData.stepOneData({
                            isEdit: this.state.isEdit,
                            requestId: this.state.requestId,
                            entityName: this.state.entityName,
                            cordinatorName: this.state.cordinatorName,
                            jobName: this.state.jobName,
                            contactNumber: this.state.contactNumber,
                            contactNumberMobile: this.state.contactNumberMobile,
                            email: this.state.email,
                            cordinatorName2: this.state.cordinatorName2,
                            jobName2: this.state.jobName2,
                            contactNumber2: this.state.contactNumber2,
                            contactNumberMobile2: this.state.contactNumberMobile2,
                            email2: this.state.email2,
                            haveBudget: this.state.haveBudget,
                            reasonsToPayBonuses: this.state.reasonsToPayBonuses,
                            entityAchivementsForYear: this.state.entityAchivementsForYear,
                            enterTheBudget: this.state.enterTheBudget,
                            enterTheYear1: this.state.enterTheYear1,
                            percentageOne: this.state.percentageOne,
                            percentageTwo: this.state.percentageTwo,
                            yearOne: this.state.yearOne,
                            yearTwo: this.state.yearTwo,
                        });
                    }
                }

            }

        });

    };

    clearForm = () => {
        this.setState({
            cordinatorName: '',
            jobName: '',
            contactNumber: '',
            contactNumberMobile: '',
            email: '',
            cordinatorName2: '',
            jobName2: '',
            contactNumber2: '',
            contactNumberMobile2: '',
            email2: '',
            haveBudget: '',
            reasonsToPayBonuses: '',
            entityAchivementsForYear: '',
            enterTheBudget: '',
            enterTheYear1: '',
            percentageOne: '',
            percentageTwo: '',
            yearOne: '',
            yearTwo: ''
        });
    }

    haveBudgetOptionChange = (e) => {
        if (e.target.value) {
            this.setState({
                haveBudget: e.target.value,
                haveBudgetErr: ''
            }, () => {
                if (this.state.haveBudget === "No") {
                    // this.setState({ enterTheBudget: '' });
                }
            });
        } else {
            this.setState({ haveBudgetErr: '' });
        }

    };

    entityLogoOnChange = e => {
        if (e.target.files.length > 0) {
            this.setState({ entityLogo: e.target.files[0].name });
            let tempFile = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(tempFile);
            
            reader.onloadend = () => {
                this.setState({
                    entityLogoFileObj: reader.result,
                }, () => {
                    Config.setBase64ForBonusRequest_EntityLogoFileChange(this.state.entityLogoFileObj);
                });

            }
        };
    }

    validateYearLength = (year) => {
        if (year.length === 4) {
            return true
        }
        else {
            return false
        }
    }

    handlejobNameChange = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
            if (regex.test(event.target.value) && event.target.value.length < 50) {
                this.setState({ jobName: event.target.value });
            }
        } else {
            this.setState({ jobName: '' });
        }
    };

    handlejobName2Change = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
            if (regex.test(event.target.value) && event.target.value.length < 50) {
                this.setState({ jobName2: event.target.value });
            }
        } else {
            this.setState({ jobName2: '' });
        }
    };

    handleEntityNameChange = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
            if (regex.test(event.target.value) && event.target.value.length < 50) {
                this.setState({ entityName: event.target.value });
            }
        } else {
            this.setState({ entityName: '' });
        }
    };

    onChangecordinatorName = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
            if (regex.test(event.target.value) && event.target.value.length < 50) {
                this.setState({ cordinatorName: event.target.value });
            }
        } else {
            this.setState({ cordinatorName: '' });
        }
    };

    onChangecordinatorName2 = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
            if (regex.test(event.target.value) && event.target.value.length < 50) {
                this.setState({ cordinatorName2: event.target.value });
            }
        } else {
            this.setState({ cordinatorName2: '' });
        }
    };

    handleEmail = (event) => {
        if (event.target.value && event.target.value.trim()) {
            if (event.target.value && event.target.value.length < 50) {
                this.setState({ email: event.target.value, emailErr: '' });
            } else {
                this.setState({ emailErr: '' });
            }
        } else {
            this.setState({ email: '' });
        }
    };

    handleEmail2 = (event) => {
        if (event.target.value && event.target.value.trim()) {
            if (event.target.value && event.target.value.length < 50) {
                this.setState({ email2: event.target.value, email2Err: '' });
            } else {
                this.setState({ email2Err: '' });
            }
        } else {
            this.setState({ email2: '', email2Err: 'thisFieldIsRequired' });
        }
    };

    handleContactNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value) {
            this.setState({ contactNumber: event.target.value }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ contactNumberErr: '' });
                } else {
                    this.setState({ contactNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ contactNumber: '', contactNumberErr: 'thisFieldIsRequired' });
        }

    };

    handleContactNumberMobileChange = (event) => {
        let contactNumberMobile = event.target.value;
        if (event.target.value) {
            this.setState({ contactNumberMobile: event.target.value }, () => {
                if (contactNumberMobile.length === 11 || contactNumberMobile.length === 12) {
                    this.setState({ contactNumberMobileErr: '' });
                } else {
                    this.setState({ contactNumberMobileErr: 'invalidMobileNumber' });
                }
            });
        } else {
            this.setState({ contactNumberMobile: '', contactNumberMobileErr: 'thisFieldIsRequired' });
        }
    };

    handleContactNumber2Change = (event) => {
        let contactNumber2 = event.target.value;
        if (event.target.value) {
            this.setState({ contactNumber2: event.target.value }, () => {
                if (contactNumber2.length === 11 || contactNumber2.length === 12) {
                    this.setState({ contactNumber2Err: '' });
                } else {
                    this.setState({ contactNumber2Err: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ contactNumber2: '', contactNumber2Err: '' });
        }
    };

    handleContactNumberMobile2Change = (event) => {
        let contactNumberMobile2 = event.target.value;
        if (event.target.value) {
            this.setState({ contactNumberMobile2: event.target.value }, () => {
                if (contactNumberMobile2.length === 11 || contactNumberMobile2.length === 12) {
                    this.setState({ contactNumberMobile2Err: '' });
                } else {
                    this.setState({ contactNumberMobile2Err: 'invalidMobileNumber' });
                }
            });
        } else {
            this.setState({ contactNumberMobile2: '', contactNumber2Err: '' });
        }
    };

    handleYearChange = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ enterTheYear1: event.target.value, enterTheYear1Err: '' });
            }
        } else {
            this.setState({ enterTheYear1: '', enterTheYear1Err: 'thisFieldIsRequired' });
        }
    };

    onEntityAchivementChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                entityAchivementsForYearErr: "",
                entityAchivementsForYear: newContent
            })
        } else {
            this.setState({
                entityAchivementsForYearErr: "ckeditorCountErr"
            })
        }

    }

    onReasonToPayBonousChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                reasonsToPayBonusesErr: "",
                reasonsToPayBonuses: newContent
            })
        } else {
            this.setState({
                reasonsToPayBonusesErr: "ckeditorCountErr"
            })
        }
    }

    changeYearOne = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ yearOne: event.target.value, yearOneErr: '' });
            }

        } else {
            this.setState({ yearOne: '', yearOneErr: 'thisFieldIsRequired' });
        }
    };

    changeYearTwo = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ yearTwo: event.target.value, yearTwoErr: '' });
            }
        } else {
            this.setState({ yearTwo: '', yearTwoErr: 'thisFieldIsRequired' });

        }
    };

    changePercentageOne = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,3}$/;
            if (regex.test(event.target.value)) {
                if (event.target.value <= 100) {
                    this.setState({ percentageOne: event.target.value });
                }
            }
        } else {
            this.setState({ percentageOne: '' });
        }
    };

    changePercentageTwo = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,3}$/;
            if (regex.test(event.target.value)) {
                if (event.target.value <= 100) {
                    this.setState({ percentageTwo: event.target.value });
                }
            }
        } else {
            this.setState({ percentageTwo: '' });
        }
    };

    saveAsDraft = () => {
        
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            saveFrom: 'step1',
            entityName: this.state.entityName,
            cordinatorName: this.state.cordinatorName,
            jobName: this.state.jobName,
            contactNumber: this.state.contactNumber,
            contactNumberMobile: this.state.contactNumberMobile,
            email: this.state.email,
            cordinatorName2: this.state.cordinatorName2,
            jobName2: this.state.jobName2,
            contactNumber2: this.state.contactNumber2,
            contactNumberMobile2: this.state.contactNumberMobile2,
            email2: this.state.email2,
            haveBudget: this.state.haveBudget,
            reasonsToPayBonuses: this.state.reasonsToPayBonuses,
            entityAchivementsForYear: this.state.entityAchivementsForYear,
            enterTheBudget: this.state.enterTheBudget,
            enterTheYear1: this.state.enterTheYear1,
            percentageOne: this.state.percentageOne,
            percentageTwo: this.state.percentageTwo,
            yearOne: this.state.yearOne,
            yearTwo: this.state.yearTwo,
            isEdit: this.state.isEdit,
            requestId: this.state.requestId
        });
    };

    onChangebudget = (value) => {
        if (value) {
            
            this.setState({ enterTheBudget: value.formattedValue, enterTheBudgetErr: "" });
        }
    };

    render() {

        return (

            <form
                style={{ marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2", "Step3"]}
                    currentIndex={1}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                {/* required condition commented */}
                {/* <MDBRow className="requiredcondition" style={{ textAlign: this.props.isArabic ? 'right' : 'justify' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <p>{i18n[this.getLocale()].requiredcondition}</p>
                            <p>{i18n[this.getLocale()].bonusrequestcondition}</p>
                        </div>
                    </MDBCol>
                </MDBRow> */}

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formEntityName" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].entityName}<span className="requireStar">*</span></label>
                            <input
                                disabled={true}
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="entityName" name="entityName" onChange={this.handleEntityNameChange} required value={this.state.entityName} />
                            <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formCordinatorName" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].firstcordinatorName}<span className="requireStar">*</span></label>
                            <input style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="cordinatorName" name="cordinatorName" onChange={this.onChangecordinatorName} required value={this.state.cordinatorName} />
                            <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formEmail" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].firstemail}<span className="requireStar">*</span></label>
                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr" }}
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleEmail}
                                required
                                value={this.state.email}
                            />

                            {
                                this.state.emailErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.emailErr === 'invalidEmail' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmail}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formContactNumber" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}> {i18n[this.getLocale()].firstcontactNumber}<span className="requireStar">*</span></label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                className="form-control"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            {
                                this.state.contactNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.contactNumberErr == 'invalidContactNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formJobName" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].firstjobName}<span className="requireStar">*</span></label>
                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="jobName" name="jobName" onChange={this.handlejobNameChange} required value={this.state.jobName} />
                            <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}> {i18n[this.getLocale()].firstcontactNumberMobile}<span className="requireStar">*</span></label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumberMobile}
                                onChange={this.handleContactNumberMobileChange}
                                className="form-control"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            {
                                this.state.contactNumberMobileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.contactNumberMobileErr === 'invalidMobileNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidMobileNumber}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div className="form-group">
                                        <label htmlFor="formCordinatorName" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].firstcordinatorName}<span className="requireStar">*</span></label>
                                        <input minLength='0'
                                            maxLength='50' style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="cordinatorName" name="cordinatorName" onChange={this.onChangecordinatorName} required value={this.state.cordinatorName} />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </div>
                                </MDBCol> */}
                </MDBRow>

                {/* Repeat above entries */}
                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div className="form-group">
                                        <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].secondjobName}</label>
                                        <input
                                            minLength='0'
                                            maxLength='50' style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="jobName2" name="jobName2" onChange={this.handlejobName2Change} value={this.state.jobName2} />

                                    </div>
                                </MDBCol> */}
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].secondcordinatorName}</label>
                            <input style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="cordinatorName2" name="cordinatorName2" onChange={this.onChangecordinatorName2} value={this.state.cordinatorName2} />
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">
                            <label htmlFor="formEmail" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].secondemail}</label>
                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                type="text"
                                className="form-control"
                                id="email2"
                                name="email2"
                                onChange={this.handleEmail2}
                                value={this.state.email2}
                            />

                            {
                                this.state.email2Err === 'invalidEmail' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmail}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}> {i18n[this.getLocale()].secondcontactNumber}</label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber2}
                                onChange={this.handleContactNumber2Change}
                                className="form-control"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            {
                                this.state.contactNumber2Err === 'invalidContactNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].secondjobName}</label>
                            <input style={{ direction: this.props.isArabic ? "rtl" : "ltr", }} type="text" className="form-control" id="jobName2" name="jobName2" onChange={this.handlejobName2Change} value={this.state.jobName2} />

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}> {i18n[this.getLocale()].secondcontactNumberMobile}</label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumberMobile2}
                                onChange={this.handleContactNumberMobile2Change}
                                className="form-control"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            {
                                this.state.contactNumberMobile2Err === 'invalidMobileNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidMobileNumber}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>

                </MDBRow>

                {/* End repeat entries */}

                {/* <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label htmlFor="formHavebudget"
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].havebudget}
                                <span className="requireStar">*</span>
                            </label>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    name="haveBudget"
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    type="radio"
                                    value="Yes"
                                    id="haveBudgetYes"
                                    className="custom-control-input"
                                    required checked={this.state.haveBudget === 'Yes'}
                                    onChange={this.haveBudgetOptionChange} />
                                <label className="custom-control-label" htmlFor="haveBudgetYes">
                                    {i18n[this.getLocale()].yes}
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    name="haveBudget"
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    type="radio"
                                    required
                                    value="No"
                                    disabled
                                    id="haveBudgetNo"
                                    className="custom-control-input"
                                    checked={this.state.haveBudget === 'No'}
                                    onChange={this.haveBudgetOptionChange} />
                                <label className="custom-control-label" htmlFor="haveBudgetNo">{i18n[this.getLocale()].no}</label>
                            </div>
                        </div>

                        {
                            this.state.haveBudgetErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }
                    </MDBCol>
                </MDBRow> */}

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formEnterTheBudget" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].enterTheBudget}<span className="requireStar">*</span></label>
                            {/* <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="enterTheBudget" name="enterTheBudget" onChange={this.onChangebudget} required value={this.state.enterTheBudget} />
                                <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div> */}
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.enterTheBudgetErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.enterTheBudget}
                                    onValueChange={(value) => { this.onChangebudget(value) }}
                                    display={"text"}
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    maxLength={10}
                                />
                            </div>
                            {this.state.enterTheBudgetErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formEnterTheYear" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].enterTheYear}<span className="requireStar">*</span></label>
                            <input
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="enterTheYear1"
                                name="enterTheYear1"
                                onChange={this.handleYearChange}
                                required
                                value={this.state.enterTheYear1}
                            />

                            {
                                this.state.enterTheYear1Err === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.enterTheYear1Err === 'invalidYear' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidYear}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>


                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <label htmlFor="formPercentageOfEmariatisation" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].percentageOfEmariatisation}<span className="requireStar">*</span></label>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">

                            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                {/* <MDBCol xs="1" sm="1" md="1" lg="1" xl="1" style={{ 'padding': '0px' }}>
                                </MDBCol> */}

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <label htmlFor="formPercentageOne" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].enterPercentage}<span className="requireStar">*</span></label>

                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                            type="text"
                                            className="form-control"
                                            id="percentageOne"
                                            name="percentageOne"
                                            onChange={this.changePercentageOne}
                                            required
                                            value={this.state.percentageOne} />

                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </div>
                                </MDBCol>


                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <label htmlFor="formYearOne" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].enterYear}<span className="requireStar">*</span></label>

                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text"
                                            className="form-control"
                                            id="yearOne"
                                            name="yearOne"
                                            onChange={this.changeYearOne}
                                            required
                                            value={this.state.yearOne}
                                        />

                                        {
                                            this.state.yearOneErr === 'thisFieldIsRequired' ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            this.state.yearOneErr === 'invalidYear' ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.getLocale()].invalidYear}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </MDBCol>

                                {/* <MDBCol xs="1" sm="1" md="1" lg="1" xl="1" style={{ 'padding': '0px' }}></MDBCol> */}

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <label htmlFor="formPercentageTwo" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].enterPercentage}<span className="requireStar">*</span></label>

                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                            type="text"
                                            className="form-control"
                                            id="percentageTwo"
                                            name="percentageTwo"
                                            onChange={this.changePercentageTwo}
                                            required
                                            value={this.state.percentageTwo} />

                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <label htmlFor="formYearTwo" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].enterYear}<span className="requireStar">*</span></label>

                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text"
                                            className="form-control"
                                            id="yearTwo"
                                            name="yearTwo"
                                            onChange={this.changeYearTwo}
                                            required
                                            value={this.state.yearTwo}
                                        />

                                        {
                                            this.state.yearTwoErr === 'thisFieldIsRequired' ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            this.state.yearTwoErr === 'invalidYear' ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.getLocale()].invalidYear}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="formEntityAchivementsForYear" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].entityAchivementsForYear}<span className="requireStar">*</span></label>

                                <div
                                    style={{ border: this.state.entityAchivementsForYearErr === 'thisFieldisRequired' ? '1px solid red' : '' }}
                                    className="ckEditorStyle">
                                    {
                                        this.props.isArabic ?
                                            null :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.entityAchivementsForYear}
                                                events={{
                                                    "change": this.onEntityAchivementChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.entityAchivementsForYear}
                                                events={{
                                                    "change": this.onEntityAchivementChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.entityAchivementsForYearErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="formReasonsToPayBonuses"
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                >
                                    {i18n[this.getLocale()].reasonsToPayBonuses}
                                    <span className="requireStar">*</span>
                                </label>

                                <div
                                    style={{
                                        border: this.state.reasonsToPayBonusesErr === 'thisFieldisRequired' ? '1px solid red' : ''
                                    }}
                                    className="ckEditorStyle">
                                    {
                                        this.props.isArabic ?
                                            null
                                            :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.reasonsToPayBonuses}
                                                events={{
                                                    "change": this.onReasonToPayBonousChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.reasonsToPayBonuses}
                                                events={{
                                                    "change": this.onReasonToPayBonousChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.reasonsToPayBonusesErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn type='submit' className="submitBtn" color="primary">{i18n[this.getLocale()].next}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>
            </form>
        );
    }
}