import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard } from "mdbreact";
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import ApiRequest from '../../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay'
import { saveSciRequestPayload, uploadDocument } from '../../utils/Payload';
import { confirmAlert } from 'react-confirm-alert';
import '../../css/react-confirm-alert.css';
import SubstitutionplanFormcom from '../../components/Substitutionplan/SubstitutionplanForm';
import Config from '../../utils/Config';

export default class SubstitutionplanForm extends Component {
    constructor() {
        super();
        this.state = {
            isArabic:true
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }
    render() {
      
        const sideBarData = Config.getServiceSideBar('Substitutionplan', this.state.isArabic, this, 0);
      return (
        <MDBContainer fluid>
                     <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip  goBack={() => { this.props.history.replace('/SubstitutionplanForm', { pathname: 'Substitutionplan' }) }} isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].Substitutionplan.Subject} />
                                </MDBCol>
                            </MDBRow>
                          
                            <div style={{ paddingTop: "10px" }}>
                              <SubstitutionplanFormcom history={this.props.history}      isArabic={this.state.isArabic}/> 
                            </div>

                            <div style={{paddingTop:100}}></div>
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            height={'140vh'}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>  
            </MDBContainer>
     );
    }
}