import React, { Component } from 'react'
import i18n from "../i18n";

export default class PreviewItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    render() {
        return (
            <div
                className="grid-item"
                style={{
                    display: 'flex',
                    flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                    borderBottom: this.props.item.key !== 'approvedBudgetsOfPart' && 
                    this.props.item.key !== 'sourcesFundingLabel' ? '1px solid rgb(221, 221, 221)' : ''
                }}>
                {/* {this.props.item.key} */}

                <div className="grid-key"
                    style={{
                        marginLeft: this.props.isArabic ? 10 : 0, marginRight: this.props.isArabic ? 0 : 10,
                        direction: this.props.isArabic ? 'rtl' : 'ltr',
                        textAlign: this.props.isArabic ? 'right' : 'left',
                    }}>
                        
                    {i18n[this.getLocale()].viewDetails[this.props.item.key]} :
                </div>

                <div
                    className="grid-value"
                    style={{
                        textAlign: this.props.isArabic ? 'right' : 'left',
                        direction: this.props.isArabic ? 'rtl' : 'ltr'
                    }}>
                    {this.props.item.value}

                </div>

            </div>
        )
    }
}