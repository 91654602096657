import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody,MDBModal, MDBModalBody, MDBModalHeader,MDBContainer,MDBCard } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment,saveEntityInfo } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import LoadingOverlay from 'react-loading-overlay';

const striptags = require('striptags');



export default class Step3 extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            FocalPointNameErr:'1',
FoaclPointTitleErr:'1',
FocalPointPhoneErr:'1',
FocalPointEmailErr:'1',
FocalPointMobileErr:'1',

            isArabic:true,
            showDefneationsState:false,
            isValid:true,
errormessage:'',
            isChrimanSelected:'',
            MembersArray:[],
            entityArray:[],
            formalrequestletterArray: [],
            formalrequestletterErr: "",
            formalrequestletter: this.props.sendData.formalrequestletter,
            formalrequestletterFileObj: "",
            formalrequestletterUrl:'',
            requesterentityname:'',
requesterentitynameErr:'',
requesterName:'',
requesterNameErr:'',
requesterphoneNumber:'',
requesterphoneNumberErr:'',
rquesterEmail:'',
rquesterEmailErr:'',
MemberJobTitleErr:'',
EmployeesTotalNumber:'0',
UAEEmployeesTotalNumber:'0',
NonEmployeesTotalNumber:'0',
NNUAEHAvePDP:'0',
NNNonUAEHAvePDP:'0',
NNEIncluded:'0',
NNNONUAEIncluded:'0',
NNEHave:'0',
NNonUAEHave:'0',
NNUAEIncluded:'0',
            isEdit:this.props.isEdit,
            requestId:this.props.requestId,
            memberTitleId:'',
   poistionsTypeArray: [
                {
                    id: "1",
                    EnglishTitle: "Chairman",
                    ArabicTitle: "رئيساً"
                },
                {
                    id: "2",
                    EnglishTitle: "Member",
                    ArabicTitle: "عضواً"
                }
            ],
        }
    }

    handlecancle = () => {
        let pathname = 'ActionPlansListinternal';
        this.props.history.replace(pathname, {
            pathname: pathname
        })
    }
    checkValidationAddNewMember()
    {
    
        let errormessage = '';
        let isvalida = true;
        let tempobj = this.state.MembersArray[this.state.MembersArray.length-1];
        if (this.state.FocalPointNameErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointNameErr;
            isvalida = false;
        }
        if (this.state.FoaclPointTitleErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FoaclPointTitleErr;
            isvalida = false;
        }
        if (this.state.FocalPointPhoneErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointPhoneErr;
            isvalida = false;
        }
        else if (!Validator.validateContactNumber(tempobj.FocalPointPhone)) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.focalpointinvalidPhoneNumber;
            isvalida = false;
        }
    
        if (this.state.FocalPointEmailErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointEmailErr;
            isvalida = false;
        }
     
        else if (!Validator.validateEmail(tempobj.FocalPointEmail)) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.focalpointinvalidEmail;
            isvalida = false;
        }
        
    
        if (this.state.FocalPointMobileErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointMobileErr;
            isvalida = false;
        }
        else if (!Validator.validateContactNumber(tempobj.FocalPointMobile)) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.focalpointinvalidMobile;
            isvalida = false;
        }
    
        this.setState({
            errormessage: errormessage,
            isValid: isvalida,
        });
        return errormessage;
    }
    checkValidationEntity()
    {
       
        let errormessage = '';
        let isvalida=true;
        if(this.state.requesterName=='')
        {
            errormessage  =  errormessage+i18n[this.getLocale()].ActionPlansErrorMessage.requesterNameErr;
            isvalida=false;
        }
        if(this.state.requesterphoneNumber=='')
        {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.requesterphoneNumber;
            isvalida=false;
        }
        else
        {
            if (!Validator.validateContactNumber(this.state.requesterphoneNumber))
            {
                errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.invalidPhoneNumber;
                isvalida=false;
            }

        }
        if(this.state.rquesterEmail=='')
        {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.rquesterEmail;
            isvalida=false;
        }
        else{
            if(!Validator.validateEmail(this.state.rquesterEmail))
            {
                errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.invalidEmail;
                isvalida=false;
            }
        }
        if(this.state.EmployeesTotalNumber=='' || this.state.EmployeesTotalNumber=='0')
        {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.EmployeesTotalNumber;
            isvalida=false;
        }
       //MGM
       if(this.state.UAEEmployeesTotalNumber==' ' ||this.state.UAEEmployeesTotalNumber==0 )
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.UAEEmployeesTotalNumber;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.UAEEmployeesTotalNumber) >parseInt( this.state.EmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.UAEEmployeesTotalNumberErr;
            isvalida=false;

           }
       }
       if(this.state.NonEmployeesTotalNumber=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NonEmployeesTotalNumber;
           isvalida=false;
       }
       else
       {
           let x =parseInt ( this.state.NonEmployeesTotalNumber)  +parseInt( this.state.UAEEmployeesTotalNumber);
        
           if(x > this.state.EmployeesTotalNumber || x < this.state.EmployeesTotalNumber)
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NonEmployeesTotalNumberErr;
            isvalida=false;

           }

       }
       if(this.state.NNUAEHAvePDP=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNUAEHAvePDP;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.NNUAEHAvePDP) > parseInt(this.state.UAEEmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNUAEHAvePDPErr;
            isvalida=false;

           }
       }
       
       if(this.state.NNNonUAEHAvePDP=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNNonUAEHAvePDP;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.NNNonUAEHAvePDP) > parseInt(this.state.NonEmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNNonUAEHAvePDPErr;
            isvalida=false;

           }
       }
       
       if(this.state.NNUAEIncluded=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNUAEIncluded;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.NNUAEIncluded) > parseInt(this.state.UAEEmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNUAEIncludedErr;
            isvalida=false;

           }
       }
    
       if(this.state.NNNONUAEIncluded=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNNONUAEIncluded;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.NNNONUAEIncluded) > parseInt(this.state.NonEmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNNONUAEIncludedErr;
            isvalida=false;

           }
       }
       if(this.state.NNEHave=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNEHave;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.NNEHave) > parseInt(this.state.UAEEmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNEHaveErr;
            isvalida=false;

           }
       }
       if(this.state.NNonUAEHave=='')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNonUAEHave;
           isvalida=false;
       }
       else
       {
           if(parseInt(this.state.NNonUAEHave) > parseInt(this.state.NonEmployeesTotalNumber))
           {
            errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NNonUAEHaveErr;
            isvalida=false;

           }
       }

       if(this.state.FocalPointNameErr=='1')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointNameErr;
           isvalida=false;
       }
       if(this.state.FoaclPointTitleErr=='1')
       {
           errormessage  =  errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.FoaclPointTitleErr;
           isvalida=false;
       }
       if (this.state.FocalPointPhoneErr == '1') {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointPhoneErr;
        isvalida = false;
    }
    if (this.state.FocalPointPhoneErr == '2') {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.focalpointinvalidPhoneNumber;
        isvalida = false;
    }

    if (this.state.FocalPointEmailErr == '1') {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointEmailErr;
        isvalida = false;
    }
 
    if (this.state.FocalPointEmailErr == '2') {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.focalpointinvalidEmail;
        isvalida = false;
    }
    

    if (this.state.FocalPointMobileErr == '1') {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.FocalPointMobileErr;
        isvalida = false;
    }
    if (this.state.FocalPointMobileErr == '2') {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.focalpointinvalidMobile;
        isvalida = false;
    }

        this.setState({
            errormessage: errormessage,
            isValid:isvalida,
        });
      return errormessage;
    }
    handleEmployeesTotalNumber = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeesTotalNumber: event.target.value, EmployeesTotalNumberErr: '' });
            }
        } else {
            this.setState({ EmployeesTotalNumber: '', EmployeesTotalNumberErr: 'thisFieldIsRequired' });
        }
    };
    handleUAEEmployeesTotalNumber = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ UAEEmployeesTotalNumber: event.target.value, UAEEmployeesTotalNumberErr: '' });
            }
        } else {
            this.setState({ UAEEmployeesTotalNumber: '', UAEEmployeesTotalNumberErr: 'thisFieldIsRequired' });
        }
    };

    handleNonEmployeesTotalNumber = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NonEmployeesTotalNumber: event.target.value, NonEmployeesTotalNumberErr: '' });
            }
        } else {
            this.setState({ NonEmployeesTotalNumber: '', NonEmployeesTotalNumberErr: 'thisFieldIsRequired' });
        }
    };
    handleNNUAEHAvePDP = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NNUAEHAvePDP: event.target.value, NNUAEHAvePDPErr: '' });
            }
        } else {
            this.setState({ NNUAEHAvePDP: '', NNUAEHAvePDPErr: 'thisFieldIsRequired' });
        }
    };
    handleNNNonUAEHAvePDP = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NNNonUAEHAvePDP: event.target.value, NNNonUAEHAvePDPErr: '' });
            }
        } else {
            this.setState({ NNNonUAEHAvePDP: '', NNNonUAEHAvePDPErr: 'thisFieldIsRequired' });
        }
    };
    handleNNUAEIncluded = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NNUAEIncluded: event.target.value, NNUAEIncludedErr: '' });
            }
        } else {
            this.setState({ NNUAEIncluded: '', NNUAEIncludedErr: 'thisFieldIsRequired' });
        }
    };
    handleNNNONUAEIncluded = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NNNONUAEIncluded: event.target.value, NNNONUAEIncludedErr: '' });
            }
        } else {
            this.setState({ NNNONUAEIncluded: '', NNNONUAEIncludedErr: 'thisFieldIsRequired' });
        }
    };

    handleNNEHave = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NNEHave: event.target.value, NNEHaveErr: '' });
            }
        } else {
            this.setState({ NNEHave: '', NNEHaveErr: 'thisFieldIsRequired' });
        }
    };
    handleNNonUAEHave = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ NNonUAEHave: event.target.value, NNonUAEHaveErr: '' });
            }
        } else {
            this.setState({ NNonUAEHave: '', NNonUAEHaveErr: 'thisFieldIsRequired' });
        }
    };

    FormalrequestletterOnChange = e => {
        let inpFileName = e.target.files[0].name;
        let fileSize = e.target.files[0].size;
        let fileExt = inpFileName.split('.').pop().toUpperCase();
        if (e.target.files.length > 0) {
          
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ formalrequestletter: '', formalrequestletterErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ formalrequestletter: inpFileName, formalrequestletterErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                formalrequestletterFileObj: reader.result
                            }, () => {
                                Config.setBase64ForDisciplinaryboardformalrequestletter(this.state.formalrequestletterFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ formalrequestletterFile: '',formalrequestletterFileErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            RequestType: this.state.RequestType,
            employeeName: this.state.employeeName,
            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
            EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
           
        });
    };

    handlerequestername = (event) => {
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ requesterName: event.target.value });
            }
        } else {
            this.setState({ requesterName: '' });
        }
    };

    handleRquesterEmail = (event) => {
        debugger;
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ rquesterEmail: event.target.value, rquesterEmailErr: '' });
            }
            else {
                this.setState({ rquesterEmail: "", rquesterEmailErr: "thisFieldIsRequired" });
            }
        } else {
            this.setState({ rquesterEmail: "", rquesterEmailErr: "thisFieldIsRequired" });
        }
    };

    handleRequesterPhoneNumber = (event) => {
        let requesterphoneNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ requesterphoneNumber: event.target.value }, () => {
                // if (!Validator.validateContactNumber(requesterphoneNumber))
                {
                    this.setState({ requesterphoneNumberErr: '' });
                }
                //  else {
                //     this.setState({ requesterphoneNumberErr: 'invalidContactNumber' });
                // }
            });
        } else {
            this.setState({ requesterphoneNumber: '', requesterphoneNumberErr: 'thisFieldIsRequired' });
        }
    };

  
 

    goToPrevious = () => {
        this.props.getData.stepThreePrev({
            
        });
    };
    componentDidMount = () => {
        let temp = [];
        let obj = {};
        temp.push(obj);
     
        this.setState({MembersArray:temp});
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
debugger;
this.setState({entityArray:tempEntityArray,MembernameErr:'1',
jobdegreeErr:'1',
EntityErr:'1',
MembertitleErr:'1'});
let reqid= 0;
let x = Config.getActionPlanID();;
if(x>reqid)
{
    reqid=x;
}
this.callGetActionPlanLookUpForNext();
//this.callGetActionPlanLookUp(reqid);

let payload = {};
//payload.Upcoming = 1;

payload.lang = this.state.isArabic ? 1 : 0;
let getDataFromLocalStorage = Config.getLocalStorageLoginData();

payload.EntityID = getDataFromLocalStorage.EntityID;
payload.id=reqid;
let endPoint = 'GetActionPlanById';

let url = Config.getUrl(endPoint);
// this.toggleLoadingSpinner(true);
ApiRequest.sendRequestForPost(url, payload, res => {
    // this.toggleLoadingSpinner(false);
    
    if (res.Status) {

        let mainArray = {};
        let actualRowArray = [];
        let resArray = res.data;
        let intialdetail = {};//resArray.data.IntivateDetailsOutput;
       // alert(resArray.EmployeesTotalNumber);
       let tempArray = [];
        let tempobj ={};
        let focalpoint = resArray.ActionPlanFocalPointOutput;
        if(focalpoint.length>0)
        {
        for(let i=0;i<focalpoint.length;i++)
        {
         let   item=focalpoint[i];
         tempobj ={};
            tempobj.FocalPointName=item.Name;
            tempobj.FoaclPointTitle=item.Title;
            tempobj.FocalPointPhone=item.OfficeNumber;
            tempobj.FocalPointMobile=item.MobileNumber;
            tempobj.FocalPointEmail=item.Email;
           
            tempArray.push(tempobj);
        }
        this.setState(
            {
                FocalPointNameErr:'',
FoaclPointTitleErr:'',
FocalPointPhoneErr:'',
FocalPointEmailErr:'',
FocalPointMobileErr:'',
            }
        );
    }
        else
        {
            tempArray.push(tempobj);
        }
        
      

       

        this.setState({
            MembersArray:tempArray,
            EmployeesTotalNumber:resArray.EmployeesTotalNumber,
UAEEmployeesTotalNumber:resArray.NationalEmployees,
NonEmployeesTotalNumber:resArray.NonNationalEmployees,
NNUAEHAvePDP:resArray.NationalEmployeesHDP,
NNNonUAEHAvePDP:resArray.NonNationalEmployeesHDP,
NNUAEIncluded:resArray.NationalEmployeesIncluded,
NNNONUAEIncluded:resArray.NonNationalEmployeesIncluded,
NNEHave:resArray.NationalEmployeesHTP,
NNonUAEHave:resArray.NonNationalEmployeesHTP,
        }, () => {
            
            if (editFlag) {
                this.setState({
                    
                    employeeName:editObj.employeename,
                    EmployeeInfoEmployeeNumber:editObj.employeenumber,
                    EmployeeInfoJobTitle:editObj.jobtitle,
                    EmployeeInfoJobDegree:editObj.grade,
                    EmployeeInfoEmirateID:editObj.emiratesid,
                    EmployeeInfoEmployeeUID:editObj.unifiednumber,
                    EmployeeInfoBasicSalary:editObj.basicsalary,
                    EmployeeInfoTotalSalary:editObj.totalsalary,
    EmployeeInfoRetirementcontributions: editObj.retirementcontribution,
    RequestType:editObj.requesttype,
    requestId:editObj.requestId
                });
            }
            else
            {
                let loginData = Config.getLocalStorageLoginData();
                let tempEntityName = this.props.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
                let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
                this.setState({requesterentityname:tempEntityName,
                    requesterName:tempEmployeeName,
                    requesterphoneNumber:loginData.ContactNumber,
                    rquesterEmail:loginData.Email,});
    
            }
        });
        //alert(resArray);
        // this.setState({ data:resArray,
        //     IntivateDetailsOutput:,
        //     ProjectsOutput:resArray.ProjectsOutput,
        //     IntivateFocalPointOutput:

        // }
        // );
        
        // if (resArray && resArray.length > 0) {
        //     alert(resArray[0].MainTheme.length);
        //     this.setState({ MainThemeArray: resArray[0].MainTheme });
           
        // } else {
        //     // send empty array to list page
        //     this.setState({
        //         data: [],
        //         popUpRowArray: [],
        //     }, () => {
        //        // this.updateListData(this.state.data, this.state.popUpRowArray);
        //     })
        // }
    } else {
        // TODO
    }
})
       
    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
        this.setState({isArabic:this.props.isArabic});
    }
    handleEmployeeInfoEmirateID = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ EmployeeInfoEmirateID: event.target.value }, () => {
                // if (!Validator.validateEmiratesId(emiratesIDStepOne))
                 {
                    this.setState({ EmployeeInfoEmirateIDErr: '' });
                }
                //  else {
                //     this.setState({ EmployeeInfoEmirateIDErr: 'invalidEmiratesId' });
                // }
            });
        }
        else {
            this.setState({ EmployeeInfoEmirateID: '', EmployeeInfoEmirateIDErr: 'thisFieldisRequired' });
        }
    }


    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    showDefneations= () => { 
    
        this.setState({
            showDefneationsState: true,
        
        },()=>
        {
            
        }
        );
      
    }
    
    addnewmember = () => { 
        
        let isvalida = this.checkValidationAddNewMember();
     
        if(isvalida==='')
        {
         
        let obj = {};
        obj.WorkShopLocation='';
        obj.WorkShopDate='';
        obj.WorkShopfrom='';
        obj.WorkShopto='';

        if (this.state.MembersArray.length < 40) {
            this.setState({
                MembersArray: this.state.MembersArray.concat(obj),
                view: this.state.view + 1,FocalPointNameErr:'1',
                FoaclPointTitleErr:'1',
                FocalPointPhoneErr:'1',
                FocalPointEmailErr:'1',
                FocalPointMobileErr:'1',
            });
        }
    }
    else{
        this.setState({
            titleAlert: i18n[this.getLocale()].error,
            messageAlert: isvalida
        }, () => {
            this.alertSubmit();
        })

    }

    }
    toggle = (reloadFlag) => {
        
        debugger;
        this.setState({
            modal: !this.state.modal,
            showDefneationsState:!this.state.showDefneationsState
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState,
                  
                }, () => {
                    alert(this.state.showDefneationsState);
                    this.current.componentDidMount();
                });
            }
        });
    }
    removemember = (indexVal) => {
        var array = this.state.MembersArray;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        MembersArray: array,
                        FocalPointNameErr:'',
FoaclPointTitleErr:'',
FocalPointPhoneErr:'',
FocalPointEmailErr:'',
FocalPointMobileErr:'',
                    });

                    //ReCalculate total no of days & total salary to be payed
                 
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            MembersArray: array
                        });
                     
                    }
                }
            }
        }

    }

    SaveMembersArray = (id, tempTableData) => {
       
        debugger;
        //let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        let newTempTableData = [];
        tempTableData.forEach((item, index) => {

            let SaveWorkShopDatesObject = {};
         debugger;
            SaveWorkShopDatesObject.ActionPlanID=id;
            SaveWorkShopDatesObject.FocalPointName=item.FocalPointName;
            SaveWorkShopDatesObject.FoaclPointTitle=item.FoaclPointTitle;
            SaveWorkShopDatesObject.FocalPointPhone=item.FocalPointPhone;
            SaveWorkShopDatesObject.FocalPointMobile=item.FocalPointMobile;
            SaveWorkShopDatesObject.FocalPointEmail=item.FocalPointEmail;
            newTempTableData.push(SaveWorkShopDatesObject);
        })
        return newTempTableData;
    }
//MGMEdit
    callGetActionPlanLookUp = (id) => {
        let payload = {};
        //payload.Upcoming = 1;
    
        payload.lang = this.state.isArabic ? 1 : 0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        payload.EntityID = getDataFromLocalStorage.EntityID;
        payload.id=id;
        let endPoint = 'GetActionPlanById';
        
        let url = Config.getUrl(endPoint);
        // this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            // this.toggleLoadingSpinner(false);
            if (res.Status) {
    
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let intialdetail = resArray.IntivateDetailsOutput;
                let focalpoint = resArray.ActionPlanFocalPointOutput;
                this.setState({
                    EmployeesTotalNumber:intialdetail.EmployeesTotalNumber,
UAEEmployeesTotalNumber:intialdetail.NationalEmployees,
NonEmployeesTotalNumber:intialdetail.NonNationalEmployees,
NNUAEHAvePDP:intialdetail.NationalEmployeesHDP,
NNNonUAEHAvePDP:intialdetail.NonNationalEmployeesHDP,
NNUAEIncluded:intialdetail.NationalEmployeesIncluded,
NNNONUAEIncluded:intialdetail.NonNationalEmployeesIncluded,
NNEHave:intialdetail.NationalEmployeesHTP,
NNonUAEHave:intialdetail.NonNationalEmployeesHTP,
                });
                //alert(resArray);
                // this.setState({ data:resArray,
                //     IntivateDetailsOutput:,
                //     ProjectsOutput:resArray.ProjectsOutput,
                //     IntivateFocalPointOutput:

                // }
                // );
                
                // if (resArray && resArray.length > 0) {
                //     alert(resArray[0].MainTheme.length);
                //     this.setState({ MainThemeArray: resArray[0].MainTheme });
                   
                // } else {
                //     // send empty array to list page
                //     this.setState({
                //         data: [],
                //         popUpRowArray: [],
                //     }, () => {
                //        // this.updateListData(this.state.data, this.state.popUpRowArray);
                //     })
                // }
            } else {
                // TODO
            }
        })
    }
    
    callGetActionPlanLookUpForNext = () => {
        let payload = {};
        //payload.Upcoming = 1;
    
        payload.lang = this.state.isArabic ? 1 : 0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        payload.EntityID = getDataFromLocalStorage.EntityID;
        let endPoint = 'GetActionPlanLookUps'
        let url = Config.getUrl(endPoint);
        // this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            // this.toggleLoadingSpinner(false);
            if (res.Status) {
                let resArray = res.data;
                Config.SetMainPracticeArray(resArray.MainPractice);
            }
            else {
                // TODO
            }
        })
    }
    submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let requesterNameErr = '',
            rquesterEmailErr = '', memberslengtherror = '',
            requesterphoneNumberErr = '', formalrequestletterErr = ''
            ;
    
        debugger;
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
       let isvalida= this.checkValidationEntity();
       
    
        if (tempEditObj && isvalida==='') {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                debugger;
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();
    
                let resposeObj = {};
    
                //resposeObj.isEdit = this.state.isEdit;
                //resposeObj.requestId = this.state.requestId;
                // resposeObj.RequestType= this.state.RequestType;
                resposeObj.requesterName = this.state.requesterName;
                resposeObj.rquesterEmail = this.state.rquesterEmail;
                resposeObj.requesterphoneNumber = this.state.requesterphoneNumber;
                resposeObj.UAEEmployeesTotalNumber = this.state.UAEEmployeesTotalNumber;
                resposeObj.NonEmployeesTotalNumber = this.state.NonEmployeesTotalNumber;
                resposeObj.NNUAEHAvePDP = this.state.NNUAEHAvePDP;
                resposeObj.NNNonUAEHAvePDP = this.state.NNNonUAEHAvePDP;
                resposeObj.NNUAEIncluded = this.state.NNUAEIncluded;
                resposeObj.NNNONUAEIncluded = this.state.NNNONUAEIncluded;
                resposeObj.NNEHave = this.state.NNEHave;
                resposeObj.NNonUAEHave = this.state.NNonUAEHave;
                resposeObj.EmployeesTotalNumber = this.state.EmployeesTotalNumber;
              //  resposeObj.MembersArray = this.state.MembersArray;
    
    
    
                debugger;
                let payload = saveEntityInfo(resposeObj);
                let endPoint = 'saveActionPlansEntityInfo'
                let url = Config.getUrl(endPoint);
    
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                        if (resAcompanyPatient.Status) {
                            let tempTableData = this.state.MembersArray;
    
                            let payloadForMembersArray = this.SaveMembersArray(resAcompanyPatient.data.id, tempTableData);
                            let endPoint = 'SaveActionPlansFocalPoints'
                            let urlForDisciplinaryMembers = Config.getUrl(endPoint);
    
                            ApiRequest.sendRequestForPost(urlForDisciplinaryMembers, payloadForMembersArray, resForSalaryMatrix => {
                                if (resForSalaryMatrix.Status) {
    
                                    let pathname = 'ActionPlansInitiatives';
                                    this.props.history.replace(pathname, {
                                        pathname: pathname
                                    });
    
                                }
                                else {
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].error,
                                            messageAlert: i18n[this.getLocale()].error_message
                                        }, () => {
                                            this.alertSubmit();
                                        })
                                    });
                                }
                                
                            })
                            debugger;
    
    
    
                        }
                        else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            });
                        }
                    })
                })
            })
        }
        else{
            this.setState({
                titleAlert: i18n[this.getLocale()].error,
                messageAlert: isvalida
            }, () => {
                this.alertSubmit();
            })

        }
    }
    
    
    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };

    handleChangeForEntity = (event,index) => {
        debugger;
        let entityname = event.target.value;
        let errcode='',EntityErr='';
        event.target.parentNode.className += " was-validated";
        if(entityname=="")
        {
            errcode='thisFieldIsRequired';
            EntityErr='1';
        }
        if(this.state.memberTitleId==1 || this.state.memberTitleId=='1')
        {
            let requesterEntityID = Config.getLocalStorageLoginData().EntityID;
        if(event.target.value==requesterEntityID)
        {
            EntityErr='2'; 
        }
        }
        let tempobj = this.state.MembersArray;
        tempobj[index].entity=entityname;
        tempobj[index].entityErr=errcode;
        this.setState({MembersArray:tempobj,EntityErr:EntityErr});
    
    
    };

  

    handleMemberName= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].FocalPointName=event.target.value;
        tempobj[index].FocalPointNameErr='';
        
            this.setState({
                MembersArray:tempobj,
                MembernameErr:'',
                FocalPointNameErr:'',
            });
            }
        } else {
            
        
            tempobj[index].FocalPointName=event.target.value;
            tempobj[index].FocalPointNameErr='thisFieldIsRequired';
             this.setState({ FocalPointNameErr: '1',MembersArray:tempobj });
        }
    }

    handleFoaclPointTitle= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].FoaclPointTitle=event.target.value;
        tempobj[index].FoaclPointTitleErr='';
        
            this.setState({
                MembersArray:tempobj,
                FoaclPointTitleErr:'',
            });
            }
        } else {
            
        
            tempobj[index].FoaclPointTitle=event.target.value;
            tempobj[index].FoaclPointTitleErr='thisFieldIsRequired';
             this.setState({ FoaclPointTitleErr: '1',MembersArray:tempobj });
        }
    }

    handleFocalPointPhone= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            // if (!Validator.validateContactNumber(event.target.value))
             {
                
        
        tempobj[index].FocalPointPhone=event.target.value;
        tempobj[index].FocalPointPhoneErr='';

       
        
            this.setState({
                MembersArray:tempobj,
                FocalPointPhoneErr:'',
            });
            }
            // else
            // {
            //     this.setState({ FocalPointPhoneErr: '2',MembersArray:tempobj });
            // }
        } 
       
        else {
            
        
            tempobj[index].FocalPointPhone='';
            tempobj[index].FocalPointPhoneErr='thisFieldIsRequired';
             this.setState({ FocalPointPhoneErr: '1',MembersArray:tempobj });
        }
    }

 
    handleFocalPointEmail= (event,index) => {

        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            if (event.target.value.length <= 50) {
            
                //    if (!Validator.validateEmail
                //     (event.target.value))
        {
        tempobj[index].FocalPointEmail=event.target.value;
        tempobj[index].FocalPointEmailErr='';
        
            this.setState({
                MembersArray:tempobj,
                FocalPointEmailErr:'',
            });
        }
        // else
        // {
        //     this.setState({
               
        //         FocalPointEmailErr:'2',
        //     });
        // }
            }
            else
            {
                tempobj[index].FocalPointEmail='';
                tempobj[index].FocalPointEmailErr='';
        
                this.setState({
                    MembersArray:tempobj,
                    FocalPointEmailErr:'2',
                });

            }
        }
         else {
            
        
            tempobj[index].FocalPointEmail='';
            tempobj[index].FocalPointEmailErr='thisFieldIsRequired';
             this.setState({ FocalPointEmailErr: '1',MembersArray:tempobj });
        }
    }
    handleFocalPointMobile= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            // if (regex.test(event.target.value)) 
            // if (!Validator.validateContactNumber(event.target.value))
            // 
            {
                
        
        tempobj[index].FocalPointMobile=event.target.value;
        tempobj[index].FocalPointMobileErr='';
        
            this.setState({
                MembersArray:tempobj,
                FocalPointMobileErr:'',
                
            });
            }
            // else
            // {
            //     this.setState({ FocalPointMobileErr: '2',MembersArray:tempobj });  
            // }

        } 
        else {
            
        
            tempobj[index].FocalPointMobile=event.target.value;
            tempobj[index].FocalPointMobileErr='thisFieldIsRequired';
             this.setState({ FocalPointMobileErr: '1',MembersArray:tempobj });
        }
    }
   /* Member Title* */
   handleMemberTitle = (event, index) => {
    let dropDownLabel = '';
    let chrimaneerror = '0';
    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.MembersArray;
    if (this.state.poistionsTypeArray && this.state.poistionsTypeArray.length > 0) {
        for (let index = 0; index < this.state.poistionsTypeArray.length; index++) {
            if (parseInt(event.target.value) === parseInt(this.state.poistionsTypeArray[index].id)) {
                dropDownLabel = this.props.isArabic ?
                    this.state.poistionsTypeArray[index].ArabicTitle :
                    this.state.poistionsTypeArray[index].EnglishTitle;
            }
        }
    }
    if (event.target.value > 0 && dropDownLabel != '') {

        debugger;
        //if (event.target.value.trim() != '')

        {
            // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;



            tempobj[index].title = dropDownLabel;
            tempobj[index].titlevalue = event.target.value;
            tempobj[index].titleErr = '';
            let isChrimanSelected = '';
            if ((event.target.value == 1 || event.target.value == '1') && (this.state.isChrimanSelected == '')) {
                this.setState({

                    isChrimanSelected: '0',
                });
            }
            else if (event.target.value=='1') {


                chrimaneerror = '1';
            }

            if (chrimaneerror == '1') {
                this.setState({
                    MembersArray: tempobj,
                    MembertitleErr: '2',
                    memberTitleId: event.target.value,


                });

            }
            else {
                this.setState({
                    MembersArray: tempobj,
                    MembertitleErr: '',
                    memberTitleId: event.target.value
                });
            }
        }

    }
    else {
        tempobj[index].title = '';
        tempobj[index].titlevalue = event.target.value;
        tempobj[index].titleErr = 'thisFieldIsRequired';
        this.setState({
            MembertitleErr: '1', MembersArray: tempobj,
            memberTitleId: event.target.value
        })
    }
}
   /** */

    handleMemberJobDegree= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].jobdegree=event.target.value;
        tempobj[index].jobdegreeErr='';
        
            this.setState({
                MembersArray:tempobj,
                jobdegreeErr:'',
            });
            }
        } else {
            
        
            tempobj[index].jobdegree=event.target.value;
            tempobj[index].jobdegreeErr='thisFieldIsRequired';
             this.setState({ jobdegreeErr: '1',MembersArray:tempobj });
        }
            }

    render() {
      
        let element = this.state.MembersArray.map((item, index) => {
            return (<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.FocalPointName}<span className="requireStar">*</span></label>
                        
                                <input
                                type="text"   className="form-control"
                                 
                                    value={item.FocalPointName}
                                 onChange={(value) => { this.handleMemberName(value,index) }}
                                //    required
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                           
                            {
                                item.membernameErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.FoaclPointTitle}<span className="requireStar">*</span></label>
                            <input
                                type="text"   className="form-control"
                                 
                                    value={item.FoaclPointTitle}
                                onChange={(value) => { this.handleFoaclPointTitle(value,index) }}
                                //    required
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                /> {/* <input
                                type="text"
                                value={item.title}   className="form-control"
                                    onChange={(value) => { this.handleMemberTitle(value,index) }}
                                  
                                  
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                /> */}
                            
                            {
                                item.titleErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
        
        
                </MDBRow>
        
                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.FocalPointPhone}<span className="requireStar">*</span></label>
                            
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={item.FocalPointPhone}
                             
                                onChange={(value) => { this.handleFocalPointPhone(value,index) }}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            
                            {
                                item.jobdegreeErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
        
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.FocalPointMobile}<span className="requireStar">*</span></label>
                            
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={item.FocalPointMobile}
                            
                               
                                onChange={(value) => { this.handleFocalPointMobile(value,index) }}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            
                            {
                                item.jobdegreeErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
                    
            
                </MDBRow>
        
        <MDBRow>           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.FocalPointEmail}<span className="requireStar">*</span></label>
                            <input
                                type="text"   className="form-control"
                                 
                                    value={item.FocalPointEmail}
                                onChange={(value) => { this.handleFocalPointEmail(value,index) }}
                                //    required
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                /> {/* <input
                                type="text"
                                value={item.title}   className="form-control"
                                    onChange={(value) => { this.handleMemberTitle(value,index) }}
                                  
                                  
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                /> */}
                            
                            {
                                item.titleErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
        
        
                </MDBRow>
                {index===this.state.MembersArray.length-1 ? null :
      <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" >
        <div className="seperator"></div>
        </MDBCol>
       </MDBRow>
        }

{index===this.state.MembersArray.length-1 ? 
            <MDBRow style={{ margin: '5px 34%' }}>
                    <MDBBtn onClick={() => this.addnewmember()} className="gridActionBtAttch whitetext">
                        {i18n[this.props.isArabic ? "ar" : "en"].ActionPlans.addnewmember}
                    </MDBBtn>
                    <MDBBtn onClick={() => this.removemember
                        (index)} className="gridUploadAttch whitetext">
                        {i18n[this.props.isArabic ? "ar" : "en"].ActionPlans.removemember}
                    </MDBBtn>
                </MDBRow>
        
        :null}

        
            </div>)
        })
        return (
            // <React.Fragment>
            <MDBContainer style={{ height: '100%' }} fluid>
            <LoadingOverlay>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={1}
                    isArabic={!this.state.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
         
                <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].Disciplinaryboard.requesterinfo} </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  
  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>

  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.entityname}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.requesterentityname}
                                onChange={this.handlerequesterentityname}
                                type="text"
                                id="requesterEntity"
                                className="form-control"
                                name="requesterEntity"
                                disabled={true}
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                             {
                                this.state.requesterentitynameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.requestername}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.requesterName}
                                onChange={this.handlerequestername}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.requesterNameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>
                
               
</MDBRow>

<MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.phoneNumber}<span className="requireStar">*</span></label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.requesterphoneNumber}
                                onChange={this.handleRequesterPhoneNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                               {
                                this.state.requesterphoneNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.requesterphoneNumberErr == 'invalidContactNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.email}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.rquesterEmail}
                                onChange={this.handleRquesterEmail}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {this.state.rquesterEmailErr == "thisFieldIsRequired" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                            {this.state.rquesterEmailErr == "invalidEmail" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidEmail}
                                </div>
                                :
                                null}

                        </div>
                    </MDBCol>

               
</MDBRow>

    
<MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.EmployeesTotalNumber}
                            <span className="requireStar">*</span>
                        </label>
 
                            <input
                                value={this.state.EmployeesTotalNumber}
                                 onChange={this.handleEmployeesTotalNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />   
                        </div>
                        <br />
                    </MDBCol>

            
</MDBRow>
    
<MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.UAEEmployeesTotalNumber}
                            <span className="requireStar">*</span>
                        </label>
 
                            <input
                                value={this.state.UAEEmployeesTotalNumber}
                                 onChange={this.handleUAEEmployeesTotalNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />   
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
             <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
             <label
                 style={{
                     width: "100%",
                     display: "flex",
                   flexDirection:'row'
                 }}
                 htmlFor="formGroupExampleInput">
                  {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NonEmployeesTotalNumber}
                 <span className="requireStar">*</span>
             </label>

                 <input
                     value={this.state.NonEmployeesTotalNumber}
                      onChange={this.handleNonEmployeesTotalNumber}
                     type="text"
                     id="employeeName"
                     className="form-control"
                     name="empJob"
                    //  required
                     //maxlength="50"
                     style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                 />   
             </div>
             <br />
         </MDBCol>

</MDBRow>



<MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NNUAEHAvePDP}
                            <span className="requireStar">*</span>
                            <span className="infoIcon" onClick={this.showDefneations}></span>
                        </label>
 
                            <input
                                value={this.state.NNUAEHAvePDP}
                                 onChange={this.handleNNUAEHAvePDP}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />   
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
             <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
             <label
                 style={{
                     width: "100%",
                     display: "flex",
                   flexDirection:'row'
                 }}
                 htmlFor="formGroupExampleInput">
                  {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NNNonUAEHAvePDP}
                 <span className="requireStar">*</span>
                 <span className="infoIcon" onClick={this.showDefneations}></span>
             </label>

                 <input
                     value={this.state.NNNonUAEHAvePDP}
                      onChange={this.handleNNNonUAEHAvePDP}
                     type="text"
                     id="employeeName"
                     className="form-control"
                     name="empJob"
                    //  required
                     //maxlength="50"
                     style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                 />   
             </div>
             <br />
         </MDBCol>

</MDBRow>
    


<MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NNUAEIncluded}
                            <span className="requireStar">*</span>
                        </label>
 
                            <input
                                value={this.state.NNUAEIncluded}
                                 onChange={this.handleNNUAEIncluded}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />   
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
             <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
             <label
                 style={{
                     width: "100%",
                     display: "flex",
                   flexDirection:'row'
                 }}
                 htmlFor="formGroupExampleInput">
                  {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NNNONUAEIncluded}
                 <span className="requireStar">*</span>
             </label>

                 <input
                     value={this.state.NNNONUAEIncluded}
                      onChange={this.handleNNNONUAEIncluded}
                     type="text"
                     id="employeeName"
                     className="form-control"
                     name="empJob"
                    //  required
                     //maxlength="50"
                     style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                 />   
             </div>
             <br />
         </MDBCol>

</MDBRow>
    

<MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NNEHave}
                            <span className="requireStar">*</span>
                        </label>
 
                            <input
                                value={this.state.NNEHave}
                                onChange={this.handleNNEHave}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />   
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             
             <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
             <label
                 style={{
                     width: "100%",
                     display: "flex",
                   flexDirection:'row'
                 }}
                 htmlFor="formGroupExampleInput">
                  {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.NNonUAEHave}
                 <span className="requireStar">*</span>
             </label>

                 <input
                     value={this.state.NNonUAEHave}
                      onChange={this.handleNNonUAEHave}
                     type="text"
                     id="employeeName"
                     className="form-control"
                     name="empJob"
                    //  required
                     //maxlength="50"
                     style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                 />   
             </div>
             <br />
         </MDBCol>

</MDBRow>
    


      </div>
      </div>

      <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].ActionPlans.FocalPoint} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
          {element}
     </div>
          
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
             <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
             {/* <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn> */}
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].next}
                           </MDBBtn>
                           <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlecancle}>
                               {i18n[this.getLocale()].cancle}
                           </MDBBtn>
       
                           {/* {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    } */}
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                           </MDBCol>
                       </MDBRow>
       
      
               
                       </form>   
                       
                       <MDBModal
                       className={this.props.isArabic ? 'comment_ar viewDetailModal' : 'viewDetailModal'}
                       isOpen={this.state.showDefneationsState==true}
                       toggle={this.toggle}
                       size={
                           
                                "fluid"
                               
                       } position="bottom">

                       <MDBModalHeader
                           style={{
                               padding: '0.5rem 1rem',
                               flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                               textAlign: this.props.isArabic ? "right" : "left"
                           }}
                           toggle={this.toggle}>{i18n[this.getLocale()].ActionPlans.Defniations}
                       </MDBModalHeader>

                       <MDBModalBody>
                       <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="padding15px">
                        <div className="form-group">
                        {this.props.isArabic?
                        <ol className="DisciplinaryboardIntrolist Aralign">
  <li>خطة التطوير الشخصية : هي عملية إعداد خطة عمل للموظف مبنية على وعي وقيم أخلاقية، محددة الأهداف والمدة الزمنية - قصيرة وطويلة المدى- وتهدف إلى مساعدة الموظف لتحقيق هذه الأهداف وتحسين أدائه الوظيفي في الوظيفة الحالية، وإعداده للوظيفة المستقبلية، ويجب أن تتضمن المحاور التالي:                               <span>  <br/>1. كفاءات / مهارات سلوكية و فنية                                                         <br/>2. المعارف المستهدفة / المطلوبة                                                        <br/>3. البرامج التدريبية والتطويرية                                                                 <br/>4. التطوير الوظيفي- الوظائف المستقبلية                                                <br/> 5.المدة الزمنية (تاريخ البدء - تاريخ الإنتهاء)</span></li>

<li>الكفاءات السلوكية : هي مجموعة من السمات والصفات الشخصية التي قد يمتلكها الموظف / يحتاج لتطويرها والتي تساعد في تحديد مدى إنجازه في أداء المهام الوظيفية للوظيفة التي يشغلها أو الوظيفة المستقبلية، كذلك يمكن تحدد نقاط القوة السلوكية للموظف للنجاح في المستقبل.</li>
<li>الكفاءات الفنية  : هي التعليم والمعارف والمهارات اللازمة التي يمتلكها الموظف لأداء فعال في وظيفة معينة أو مجموعة من الوظائف داخل المؤسسة.</li>

                        </ol>
                        :
                        <ol className="DisciplinaryboardIntrolist">
                          <li>Personal Development Plan (PDP) : is the process of creating an action plan based on awareness, values, reflection, goal-setting and planning for personal development within the context of a career, education, relationship or for self-improvement, also  to help employees reach short and long-term career goals, as well as improve current job performance</li>



                        
<li>PDP should Include 

                               <span>  <br/>1. Competencies both (Technical & Behavioral) <br/>2. Education                                                       <br/>3. Training<br/>4. Career Steps<br/> 5. Clear Timeline</span></li>


<li>Behavioral competency  : Attributes and personality traits a person might have, such as knowledge and skill set, leadership skills which can help determine how successful he/she will be at the job they are holding / applying for. It can help define employee's behavioral strengths for the future success in the workplace</li>


<li>Technical competency : Describe the application of knowledge and skills needed to perform effectively in a specific job or group of jobs within the organization. These types of competencies are closely aligned with the knowledge and skills or “know-how” needed for successful performance.</li>

                        </ol>
    }
                          <br />
                           
                            {/* {
                                this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].budgetAvailabilityError}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>

                        </MDBRow>


                       </MDBModalBody>
                   </MDBModal>

</LoadingOverlay></MDBContainer>
    //   </React.Fragment>
              
        );
    }
}