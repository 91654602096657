import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import i18n from './../../i18n';
import { assginGmPayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import Config from './../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import ToastComponent from './../ToastComponent';

export default class AssignToGm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            comment: '',
            requestType: '',
            pathName: ''
        }
    }

    componentDidMount = () => {
        // TODO
    }

    // componentDidMount = () => {
    //     let pathName = localStorage.getItem('currentPath');
    //     if (pathName) {
    //         if (pathName === 'SCIRequest') {
    //             this.setState({
    //                 requestType: Config.getSciRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'informationRequest') {
    //             this.setState({
    //                 requestType: Config.getInformationRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'bonusRequest') {
    //             this.setState({
    //                 requestType: Config.getBonusRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'amendSalaryScreen') {
    //             this.setState({
    //                 requestType: Config.getAmendSalaryScaleRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'amendEmployeeSalary') {
    //             this.setState({
    //                 requestType: Config.getAmendEmployeeSalaryRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'exemptionFromAccompanyPatient') {
    //             this.setState({
    //                 requestType: Config.getExceptionAcompanypatientRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'reviewHRPolicies') {
    //             this.setState({
    //                 requestType: Config.getHrReviewPolicyRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'exceptionADHousing') {
    //             this.setState({
    //                 requestType: Config.getExceptionADHousingRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'secondmentForFederalAgency') {
    //             this.setState({
    //                 requestType: Config.getSecondmentForFederalAgencyRequestTypeId(),
    //                 pathName: pathName
    //             })
    //         } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
    //             this.setState({
    //                 requestType: Config.getRetirmentRequestTypeId(),
    //                 pathname: pathName
    //             })
    //         }
    //     }
    // }

    submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let obj = {};
        obj.requestid = this.props.id;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.notes = this.state.comment;
        obj.statusid = this.props.currentStatusId;
        obj.reasonid = this.props.currentStatusReasonId;
        obj.requestnumber = this.props.currentRequestNumber;
        let endPoint = 'AssignGm';
        let payload = assginGmPayload(obj);
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
               
                this.setState({
                    isLoading: false
                }, () => {
                  
                    if (res.Status) {
                        this.props.toggle(true);
                    } else {
                        // alert(res.Message);
                        
                        this.props.toggle(false);
                        ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoassigngmoffice);
                    }
                })
            })
        })
    };

    render() {
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBContainer style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>

                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate>

                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>

                        {/* <MDBCol style={{ width: '100%', padding: 0 }}>

                            <span style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].select_sector}</span>

                            <br />

                            <select
                                className="browser-default custom-select" id="sources-funding"
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                value={this.state.assignValueToSector}
                                onChange={(event) => {
                                    this.setState({
                                        assignValueToSector: event.target.value,
                                        statusUpdateInfoError: ''
                                    }, () => {
                                       
                                    })
                                }}
                                required>

                                <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].select}-</option>
                                {
                                    this.state.sectorArray ? this.state.sectorArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}
                                        </option>

                                    }) : null
                                }

                            </select>

                            {
                                this.state.value === '' ?
                                    <div className="redText" style={{ color: 'red', fontSize: '12px', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : null
                            }

                        </MDBCol> */}

                        {/* <br /> */}

                        {/* <MDBCol style={{ width: '100%', padding: 0 }}>

                            <div className="form-group">

                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].target_date}<span className="requireStar">*</span></label>
                                <div style={{ width: '100%', display: 'flex', flexDirection: "column" }}>

                                    <DatePicker
                                        className="form-control"
                                        todayButton={"Today"}
                                        minDate={new Date()}
                                        style={{ width: '100%' }}
                                        selected={this.state.selectedDate}
                                        onChange={this.handleSelectedDate}
                                        required
                                    />

                                    <div>
                                        {
                                            this.state.dateReplyError !== '' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                    {this.state.dateReplyError}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                </div>

                            </div>

                        </MDBCol> */}

                        {/* <br /> */}

                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>

                            <MDBCol md={12} style={{ display: 'flex', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start' }}>

                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className="form-group">

                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                    </label>

                                    <textarea
                                        className="form-control" id="exampleFormControlTextarea2" rows="2" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        // required
                                        onChange={(event) => {
                                            this.setState({
                                                comment: event.target.value
                                            })
                                        }}
                                    />


                                    {

                                    }

                                </div>

                            </MDBCol>

                        </MDBRow>

                        <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }} >
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].SCI.submit}
                            </MDBBtn>
                            <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
                        </MDBRow>

                    </LoadingOverlay>

                </form>

            </MDBContainer>
        );
    }
}

