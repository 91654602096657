import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import LoadingOverlay from 'react-loading-overlay';
import ApiRequest from './../../services/ApiRequest';
import Config from './../../utils/Config';
import { saveStatusPayload, getDependentResonPayload } from './../../utils/Payload';
import i18n from '../../i18n';

class StatusUpdateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pathName: '',
            disableSelectStatusDropDownForInformation: false,
            requestType: '',
            isLoading: false,
            addedComent: '',
            filter: 6,
            selectedStatus: '',
            selectedReason: '',
            statusUpdateInfoObj: {},
            statusUpdateInfoError: "",
            reasonArray: [],
            statusArray: [],
            reasonArrayForSCI: [],
            reasonArrayForInformation: [],
            reasonArrayForAllOtherServices: [],
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    componentDidMount = () => {
        
        // get request type value according to which request is called
        let pathName = localStorage.getItem('currentPath');
        if (pathName) {
            if (this.props.requesttypeid === Config.getSciRequestTypeId()) {
                this.setState({
                    filter: 6,
                    pathName: pathName,
                    requestType: Config.getSciRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.setState({
                    filter: 8,
                    pathName: pathName,
                    disableSelectStatusDropDownForInformation: true,
                    requestType: Config.getInformationRequestTypeId()
                }, () => {
                    this.getReasonAccordingToStatus();
                })
            } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getBonusRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getAmendSalaryScaleRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getAmendEmployeeSalaryRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getHrReviewPolicyRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getExceptionADHousingRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getSecondmentForFederalAgencyRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getExceptionAcompanypatientRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getRetirmentRequestTypeId()
                })
            } else if (this.props.requesttypeid === Config.getExtendCreateObjectiveId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getExtendCreateObjectiveId()
                })
            } else if (this.props.requesttypeid === Config.getExtendReviewObjectiveId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getExtendReviewObjectiveId()
                })
            } else if (this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getExtendAnnualPerformaceEvaluationId()
                })
            } else if (this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getAddAndEditEmployeePerformanceEvaluationId()
                })
            } else if (this.props.requesttypeid === Config.getJoinAndImplementId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getJoinAndImplementId()
                })
            } else if (this.props.requesttypeid === Config.getOpenComplainsStageId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getOpenComplainsStageId()
                })
            } else if (this.props.requesttypeid === Config.getAmendOnSharedGovermentId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getAmendOnSharedGovermentId()
                })
            } else if (this.props.requesttypeid === Config.getReportAndStaticId()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getReportAndStaticId()
                })
            } else if (this.props.requesttypeid === Config.getOtherRequest()) {
                this.setState({
                    filter: 9,
                    pathName: pathName,
                    requestType: Config.getOtherRequest()
                })
            }
        }

        let tempStatus = [];
        if (Config.getStatusData()) {
            tempStatus = Config.getStatusData();
        }
      

        // remove the current status from status array
        let tempStatusArray = [];
        if (tempStatus) {
            
            tempStatus.forEach((item, index) => {
                if (item.id === Config.getStatusCompletedId() || item.id === Config.getStatusWaitingForExecutiveOffice()) {
                    tempStatusArray.push(item);
                }
            })
        }

        this.setState({
            statusArray: tempStatusArray,
        });
    }

    statusUpdateInfo = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        if (this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') {
            // if (this.state.addedComent !== '' && this.state.selectedReason !== '') {
            this.callSaveStatus();
            // } else {
            // this.setState({ statusUpdateInfoError: 'All fields are mandatory' })
            // }
        } else {
            if (this.state.addedComent !== '' && this.state.selectedStatus !== '' && this.state.selectedReason !== '') {
                this.callSaveStatus();
            } else {
                this.setState({ statusUpdateInfoError: 'All fields are mandatory' })
            }
        }

    };

    changeStatusHandle = (event) => {
        this.setState({
            selectedStatus: event.target.value, statusUpdateInfoError: ''
        }, () => {
            this.getReasonAccordingToStatus();
        })
    }

    getReasonAccordingToStatus = () => {
        let obj = {};
        obj.filter = this.state.filter;
        if (this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') {
            obj.parentid = this.props.currentStatusId;
        } else {
            obj.parentid = this.state.selectedStatus;
        }
        let payload = getDependentResonPayload(obj);
        let endPoint = 'GetDependentMaster'
        let url = Config.getUrl(endPoint);
        
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let tempReason = res.data;
                        let tempReasonArray = [];
                        // remove status reason from current array
                        if (tempReason && tempReason.length > 0) {
                            tempReason.forEach((item, index) => {
                                tempReasonArray.push(item);
                            })
                        }
                        this.setState({
                            reasonArray: tempReasonArray
                        })
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    callSaveStatus = () => {
        let obj = {};
        obj.requestid = this.props.id;
        obj.requestnumber = this.props.currentRequestNumber;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        if (this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') {
            if (this.props.currentStatusReasonId === Config.getReasonInformationInitialsubmissionId()) {
                obj.statusid = Config.getStatusInProgressId();
            } else if (this.props.currentStatusReasonId === Config.getReasonForDepartmentalApprovalId()) {
                obj.statusid = Config.getStatusInProgressId();
            } else if (this.props.currentStatusReasonId === Config.getReasonForSectorApprovalId()) {
                obj.statusid = Config.getStatusInProgressId();
            } else if (this.props.currentStatusReasonId === Config.getReasonForGMApprovalId()) {
                obj.statusid = Config.getStatusCompletedId();
            } else {
                obj.statusid = Config.getStatusInProgressId();
            }
        }
        else {
            obj.statusid = parseInt(this.state.selectedStatus);
           
            
        }

        obj.reasonid = parseInt(this.state.selectedReason);
        // obj.reasonid = Config.getReasonAcceptedId();
        obj.comment = this.state.addedComent;

        let payload = saveStatusPayload(obj);
        let endPoint = 'SaveStatus'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        this.props.toggle(true);
                    } else {
                        this.props.toggle(false);
                    
                    }
                })
            })
        })
    }

    render() {
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (

            <MDBContainer style={{ display: 'flex: ', width: '100%', }} >
                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.statusUpdateInfo}
                    noValidate>
                    
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>

                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ display: 'flex', justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className="form-group" >
                                    <div style={{ alignSelf: this.props.isArabic ? 'flex-start' : 'flex-end' }}>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            {i18n[this.getLocale()].current_status}:
                                             {this.props.cureentStatus}
                                        </label>
                                    </div>
                                    {
                                        this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' ?
                                            null
                                            :
                                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                                {i18n[this.getLocale()].select_status}
                                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>

                                            </label>
                                    }
                                    {
                                        this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' ?
                                            null
                                            :
                                            <select
                                                className="browser-default custom-select" id="sources-funding"
                                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                                placeholder={"Select Status"}
                                                value={this.state.selectedStatus}
                                                onChange={(event) => {
                                                    this.changeStatusHandle(event);
                                                }}
                                                required>
                                                <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].select}-</option>
                                                {
                                                    this.state.statusArray ? this.state.statusArray.map((item, index) => {
                                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}
                                                        </option>
                                                    }) : null
                                                }
                                            </select>
                                    }
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <br />

                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                            <MDBCol md={12} style={{ display: 'flex', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className="form-group" >
                                    <div style={{ alignSelf: this.props.isArabic ? 'flex-start' : 'flex-end' }}>
                                        <label style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                            {i18n[this.getLocale()].current_reason}:
                                             {this.props.currentStatusReason}
                                        </label>
                                    </div>
                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                        {i18n[this.getLocale()].select_reason}
                                        <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>

                                    </label>
                                    <select className="browser-default custom-select" id="sources-funding"
                                        placeholder={"Select Reason"}
                                        value={this.state.selectedReason}
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                        required
                                        onChange={(event) => {
                                            this.setState({ selectedReason: event.target.value, statusUpdateInfoError: '' })
                                        }} >
                                        <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].select}-</option>
                                        {
                                            this.state.reasonArray ? this.state.reasonArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <br />

                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                            <MDBCol md={12} style={{ display: 'flex', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className="form-group">
                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                        {i18n[this.getLocale()].add_comment}
                                        <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>
                                            *
                                        </span>
                                    </label>
                                    <textarea
                                        className="form-control" id="exampleFormControlTextarea2" rows="2" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.getLocale()].add_comment}
                                        required
                                        onChange={(event) => {
                                            this.setState({ addedComent: event.target.value })
                                        }
                                        }
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow md="12" >  <hr style={{ width: '100vw' }} /> </MDBRow>

                        <MDBRow style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }}>
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.getLocale()].update_status.Update_Status}
                            </MDBBtn>
                            <MDBBtn size="md" color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}>
                                {i18n[this.getLocale()].update_status.Close}
                            </MDBBtn>
                        </MDBRow>

                    </LoadingOverlay>

                </form>

            </MDBContainer>
        );
    }
};

export default StatusUpdateComponent;