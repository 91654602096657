import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../../components/Footer';
import { saveSecondmentRequestPayload, uploadDocument } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
 import WorkShopCreate from '../../components/ActionPlans/Step3';
 
let moment = require('moment');
export default class ActionPlansEntity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            WorkShopPres:'',
            WorkShopPresFileObj:null,
           
        }
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
    componentWillMount = () => {
        localStorage.setItem('currentPath', 'WorkShopAdd');
    }

    componentDidMount=()=>
    {
        let loginData = Config.getLocalStorageLoginData();
        
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }

    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    create = () => {
        let pathname = localStorage.getItem('WorkShopCreate');
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    render() {
        const sideBarData = Config.getServiceSideBar('ActionPlans', this.state.isArabic, this, 0);
        return(
        <MDBContainer fluid style={{ height: '100vh' }}>
        <LoadingOverlay
                active={this.state.isLoading}
                spinner={true}>
                <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                <MDBRow
                    className="formWhiteContainer"
                    style={{
                        //  width: '100vw',
                        flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                    }}>
                    <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                        <MDBRow>
                            <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                <SubHeaderStrip
                                    goBack={() => {
                                        Config.setIsEditFlag(false);
                                        this.props.history.replace('/ActionPlansListinternal') ;
                                    }}
                                    enableCreate={false}
                                    create={this.create}
                                    isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.subject}
                                />
                                <div style={{ paddingTop: "10px" }}>
                                    <WorkShopCreate history={this.props.history} isArabic={this.state.isArabic} sendData={{WorkShopPres:this.state.WorkShopPres}}/>
                                </div>
                                <div style={{clear:"both",height:"100px"}}/>
                                  <Footer isArabic={this.state.isArabic} />

                            </MDBCol>
                             </MDBRow>
                        </MDBCol>
                        <SideBar
                            canViewSCI={true}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                      </MDBRow>
   </LoadingOverlay>
   </MDBContainer>);
    }
}