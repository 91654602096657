import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle, MDBDropdown } from "mdbreact";
import i18n from './../i18n';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay';

import Footer from './../components/Footer';
import Header from './../components/Header';
import SideBar from './../components/SideBar';
import SubHeaderStrip from './../components/SubHeaderStrip';
const striptags = require('striptags');
export default class Faqs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isArabic: true,
            faqsData: ''
        }
    }

    componentDidMount = () => {
        if (localStorage.getItem('Lang') === 'ar') {
            this.setState({
                isArabic: true
            }, () => {
                this.callFaqs();
            })
        } else {
            this.setState({
                isArabic: false
            }, () => {
                this.callFaqs();
            })
        }
    }

    callFaqs = () => {
        let payload = '';
        let endPoint = 'getfaq';
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let data = res.data;
                   
                        this.setState({
                            faqsData: data
                        }, () => {
                   
                        })
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    getLangName = () => {
        return this.state.isArabic ? 'العربية' : 'English'
    }

    getFlagImage = (lan) => {
        let lanFlag = 'ar';
        if (localStorage.getItem('Lang') !== '') {
            lanFlag = localStorage.getItem('Lang');
        }
        if (this.state.isArabic) {
            if (lan === lanFlag) {
                return <img src={require("./../assets/images/flags/ae.png")} alt="" />
            }
            else {
                return <img src={require("./../assets/images/flags/us.png")} alt="" />
            }
        } else if (this.state.isArabic) {
            return <img src={require("./../assets/images/flags/ae.png")} alt="" />
        } else {
            return <img src={require("./../assets/images/flags/us.png")} alt="" />
        }
    }

    onClickAboutHra = () => {
        this.props.history.push('/aboutEservices');
    }

    onClickHome = () => {
        this.props.history.push('/');
    }

    clickOnLogo = () => {
        return Config.getHraLink();
    }
    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }
    render() {

        const logo = require('../assets/images/logo_bg.png');
        const home = require('../assets/images/home_icon_black.png');

        let element = '';
        if (this.state.faqsData) {
            element = this.state.faqsData.map((item, index) => {
                return (
                    <div>
                        <MDBRow className="faqBgImage" style={{ right: this.state.isArabic ? '30px' : '' }}>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <img alt="" src={require('./../assets/images/icons/sidebar_new_icon/faqIcon.png')} />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="topSpaceFaq">
                                &nbsp;
                        </MDBCol>
                        </MDBRow>

                        <MDBRow className="faqContent" style={{ borderRight: this.state.isArabic ? '' : '9px solid #97b8c6', borderLeft: this.state.isArabic ? '9px solid #97b8c6' : '' }}>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="faqBox">
                                <MDBRow className="faqRow" style={{ display : 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', textAlign: this.state.isArabic ? 'right' : 'justify' }}>
                                    <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
                                        <img alt="" src={require('./../assets/images/icons/sidebar_new_icon/question_icon.png')} />
                                    </MDBCol>

                                    <MDBCol xs="11" sm="11" md="11" lg="11" xl="11" className="noPad">
                                        <div className="questionTxt">
                                            {this.state.isArabic ? item.QuestionAR : item.QuestionEN}
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow className="faqRow" style={{ display : 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', textAlign: this.state.isArabic ? 'right' : 'justify' }}>
                                    <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
                                        <img alt="" src={require('./../assets/images/icons/sidebar_new_icon/answer_icon.png')} />
                                    </MDBCol>

                                    <MDBCol xs="11" sm="11" md="11" lg="11" xl="11" className="noPad">
                                        <div className="ansTxt"  dangerouslySetInnerHTML ={{__html: this.state.isArabic ?
                                                    striptags(item.AnswerAR) 
                                                    :
                                                    striptags(item.AnswerEN)}}>
                                           
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </div>
                )
            })
        } else {

        }
        const sideBarData = Config.getServiceSideBar('FAQ', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

<Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

<MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
    <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
        <MDBRow>
            <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                <SubHeaderStrip
                    goBack={() => {
                        Config.setIsEditFlag(false);
                        this.props.history.replace('/listpage', { pathname: 'extendReviewObjectives' })
                    }}
                    isArabic={this.state.isArabic} title={i18n[this.getLocale()].FAQ}
                />
            </MDBCol>
        </MDBRow>

                    {element}
                    <MDBRow style={{ height: '10vh' }}> 
                    <Footer isArabic={this.state.isArabic} />
                    </MDBRow>
                        </MDBCol>

                        <SideBar
                            // data services
                            canviewextendcreaterequest={this.state.canviewextendcreaterequest}
                            canviewextendreviewrequest={this.state.canviewextendreviewrequest}
                            canviewextendannualrequest={this.state.canviewextendannualrequest}
                            canviewemployeeperformancerequest={this.state.canviewemployeeperformancerequest}
                            canviewjoinimplementrequest={this.state.canviewjoinimplementrequest}
                            canviewopencomplainsrequest={this.state.canviewopencomplainsrequest}
                            canviewamendsystemsrequest={this.state.canviewamendsystemsrequest}
                            canviewreportsstaticsrequest={this.state.canviewreportsstaticsrequest}
                            canviewotherrequestsrequest={this.state.canviewotherrequestsrequest}
                            // end of data services
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>
                </LoadingOverlay>

            </MDBContainer>
        );
    }
}