
    import React, { Component } from 'react';

	import {MDBBtn, MDBContainer, MDBCol, MDBRow, MDBTableHead, MDBTable, MDBTableBody } from 'mdbreact';
    
   import ToastComponent from '../ToastComponent';

    import i18n from '../../i18n';
    import Locali18n from './Locali18n';
    import  RequestFunctions from './RequestFunctions';
    import './../../css/style.css';
    import "react-datepicker/dist/react-datepicker.css";
    import Config from '../../utils/Config';
    import LoadingOverlay from 'react-loading-overlay';
    import { confirmAlert } from 'react-confirm-alert';
    import './../../css/react-confirm-alert.css';
    import ApiRequest from '../../services/ApiRequest';
    import { uploadDocument ,OrganizationStrucutreRequestPayload} from '../../utils/Payload';
    import DatePicker from "react-datepicker";
    import InputMask from 'react-input-mask';
    import CKEditorComponent from '../CKEditorComponent';
    import DownloadComponent from "../DownloadComponent";
    import PostionsFunctions from './PostionsFunctions';
    import ViewRecordComponent from '../../components/PopOverSubComponents/ViewRecordComponent';
	import { saveStatusPayload, assginGmPayload, getRequestByIdPayload, downloadAttachment } from './../../utils/Payload';

   import OrganizationStructureFunctions from './OrganizationStructureFunctions';
import { TableRow } from '@material-ui/core';


export default class Preview  extends Component {

    constructor(props)
    {
        super(props);
       this.state={
		   info : this.props.info,
		   Attachments: [],
		   isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
		   modal: false,
		   selectedIndex: 0,
		   userEditedTableData: {},
		   viewObject: {},
		   comment: '',
		   statusHistory: [],     
		   data: {},
		   popUpRowArray: [],
		   
       }
       
       
	}
	componentWillMount=()=>{
		this.callRequestById();
	}
	callRequestById = () => {
        debugger;
        //let id = requestId;
		let payload = getRequestByIdPayload(901);
		payload.id=this.state.info.node.id;
        let url = Config.getUrl("GetPosition");

        ApiRequest.sendRequestForPost(url, payload, res => {
            
        
                    
                    if (res.Status) {
                        
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.requestdate = res.data.requestdate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
							editObj.attachments = res.data.attachments;
							this.setState({attachments : res.data.attachments});
                            // set obj
                            Config.setEditObj(editObj);
                    

                            this.props.history.push('/OrganizationStructure', {
                                pathname: 'OrganizationStructure',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });

                    
					}
                     else {
                                             
                    }
                })
         
    }

	getLocale = () => {
		if (this.state.isArabic) {
			return 'ar';
		}
		else {
			return 'en';
		}
	}

exportToExcel = () => {
	let pathname = localStorage.getItem('currentPath');
	
	return this.state.data.rows;
}

saveFile = (item) => {
       
	let obj = {}
	obj.filename = item.aliasfilename;
	// obj.requesttype = this.state.requestType;
	obj.requesttype = 901;
	obj.requestid = this.props.info.node.id;

	let payload = downloadAttachment(obj);
	let endPoint = 'DownloadAttachment'
	let url = Config.getUrl(endPoint);

	this.setState({
		isLoading: true
	}, () => {
		ApiRequest.sendRequestForPost(url, payload, res => {
			this.setState({
				isLoading: false
			}, () => {
				if (res.Status) {
					debugger;
					let fileData = res.data;
					let filecontent = fileData.filecontent;
					let contenttype = fileData.contenttype;
					let blob = this.b64toBlob(filecontent, contenttype);
					debugger;
					if (navigator.msSaveBlob) {
					  
						window.navigator.msSaveBlob(blob, item.filename);
					} else {
						let a = window.document.createElement('a');

						a.href= URL.createObjectURL(blob);
						//a.href = `data:${contenttype};base64,${filecontent}`;
						a.download = item.filename;
						a.text='download';
						// Append anchor to body.
						document.body.appendChild(a);
						a.click();
						// Remove anchor from body
						document.body.removeChild(a);
					}
				} else {
					//alert(res.Message);
				
					// i18n[this.props.isArabic ? 'ar' : 'en'].fileName
					let tempFileNotFoundMessage = i18n[this.props.isArabic ? 'ar' : 'en'].filenotfound
					if (res.Message === 'filenotfound') {
						ToastComponent.notifyInfo(tempFileNotFoundMessage);
					}
				}
			})
		})
	})
}


       render()
       {
		   
           return (
            <MDBContainer style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <form
                style={{ marginTop: 20 }}
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate>

					{
					this.props.info.node.typeid === 1 ?
					<MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>

			       <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
				   <div>
					<div>
				
				<br></br>
					<div align="center">
						<table dir="rtl" border="1" cellspacing="5" cellpadding="5" width="0">
							<tbody>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<br clear="all"/>
										<p align="center" dir="RTL">
											<strong>
												&#1575;&#1604;&#1608;&#1589;&#1601;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="108" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1587;&#1605;&#1609;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="207" >
									<strong>
										<p align="center" dir="RTL">
										{ this.getLocale() =='ar' ? this.props.info.node.title : 
	                                          this.props.info.node.title}
										</p>
										</strong>
									</td>
									<td width="87" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1585;&#1602;&#1605;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;
											</strong>
											<strong></strong>
										</p>
									</td>
									<td width="225">
										<p align="center" dir="RTL">
									
										</p>
									</td>
								</tr>
								<tr>
									<td width="108" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1602;&#1591;&#1575;&#1593;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="207">
										<p align="center" dir="RTL">
										{ this.getLocale() =='ar' ? this.props.info.node.dmar : 
	                                          this.props.info.node.dmar}
										</p>
									</td>
									<td width="87" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1573;&#1583;&#1575;&#1585;&#1577;
											</strong>
											<strong>:</strong>
										</p>
									</td>
									<td width="225">
										<p align="center" dir="RTL">
										{ this.getLocale() =='ar' ? this.props.info.node.namear : 
	                                          this.props.info.node.namear}
										</p>
									</td>
								</tr>
								<tr>
									<td width="108" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1602;&#1587;&#1605;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="207">
										<p align="center" dir="RTL">
											-
										</p>
									</td>
									<td width="87" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1603;&#1578;&#1576;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="225">
										<p align="center" dir="RTL">
											-
										</p>
									</td>
								</tr>
								<tr>
									<td width="108" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;
												&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="207">
										<p align="center" dir="RTL">
											xxxx
										</p>
									</td>
									<td width="87" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1583;&#1585;&#1580;&#1577;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;&#1577;
											</strong>
											<strong>:</strong>
										</p>
									</td>
									<td width="225">
										<p align="center" dir="RTL">
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="108" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1601;&#1574;&#1577;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;&#1577;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="207">
										<p align="center" dir="RTL">
											-
										</p>
									</td>
									<td width="87" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1593;&#1575;&#1574;&#1604;&#1577;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;&#1577;
											</strong>
											<strong>:</strong>
											<strong></strong>
										</p>
									</td>
									<td width="225">
										<p align="center" dir="RTL">
											-
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<p align="center" dir="RTL">
											<strong>
												&#1575;&#1604;&#1607;&#1583;&#1601;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4">
										<p align="center" dir="RTL">
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<p align="center" dir="RTL">
											<strong>
												&#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
											</strong>
											<strong>/ </strong>
											<strong>
												&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1569;
												&#1575;&#1604;&#1575;&#1587;&#1578;&#1585;&#1575;&#1578;&#1610;&#1580;&#1610;&#1608;&#1606;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="315" colspan="2" class="previewhead">
										<p align="center" dir="RTL">
											<strong>
												&#1583;&#1575;&#1582;&#1604;&#1610;&#1611;&#1575;
											</strong>
											<strong></strong>
										</p>
									</td>
									<td width="312" colspan="2" class="previewhead">
										<p align="center" dir="RTL">
											<strong>
												&#1582;&#1575;&#1585;&#1580;&#1610;&#1611;&#1575;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="315" colspan="2">
										<p dir="RTL">
											<strong>&#167; </strong>
											Xxxx
										</p>
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxxz
										</p>
									</td>
									<td width="312" colspan="2">
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<p align="center" dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1575;&#1578;
												&#1575;&#1604;&#1585;&#1574;&#1610;&#1587;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1575;&#1578;
												&#1575;&#1604;&#1573;&#1583;&#1575;&#1585;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4">
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<br clear="all"/>
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1575;&#1578;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4">
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1575;&#1578;
												&#1575;&#1604;&#1605;&#1572;&#1587;&#1587;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4">
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<br clear="all"/>
										<p align="center" dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;
												&#1575;&#1604;&#1608;&#1592;&#1610;&#1601;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1572;&#1607;&#1604;&#1575;&#1578;
												&#1575;&#1604;&#1571;&#1603;&#1575;&#1583;&#1610;&#1605;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4">
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
										<p dir="RTL">
											<strong>&#167; </strong>
											xxxx
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" valign="top" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1582;&#1576;&#1585;&#1577;
												&#1575;&#1604;&#1605;&#1607;&#1606;&#1610;&#1577;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="627" colspan="4" >
										<p dir="RTL">
											&#1582;&#1576;&#1585;&#1577; &#1601;&#1610; xxx
											&#1571;&#1608; &#1571;&#1610;
											&#1605;&#1580;&#1575;&#1604; &#1570;&#1582;&#1585;
											&#1584;&#1610; &#1589;&#1604;&#1577;
											&#1578;&#1586;&#1610;&#1583; &#1593;&#1606;:
										</p>
										<p dir="RTL">
											&#167; xxxx
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				
					<div a align="right">
					<p dir="RTL">
						<strong>- </strong>
						<strong>&#1571;&#1593;&#1583;&#1607;&#1575;</strong>
						<strong>:</strong>
						<strong></strong>
					</p>
					</div>
					<div  align="center">
						<table dir="rtl" border="1" cellspacing="5" cellpadding="5" width="0">
							<tbody>
								<tr>
									<td width="144" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1575;&#1587;&#1605;
											</strong>
											<strong></strong>
										</p>
									</td>
									<td width="144" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1605;&#1606;&#1589;&#1576;
											</strong>
											<strong></strong>
										</p>
									</td>
									<td width="144" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1578;&#1608;&#1602;&#1610;&#1593;
											</strong>
											<strong></strong>
										</p>
									</td>
									<td width="144" class="previewhead">
										<p dir="RTL">
											<strong>
												&#1575;&#1604;&#1578;&#1575;&#1585;&#1610;&#1582;
											</strong>
											<strong></strong>
										</p>
									</td>
								</tr>
								<tr>
                        <td width="144" height="30">&nbsp;</td>
                        <td width="144" height="30">&nbsp;</td>
                        <td width="144" height="30">&nbsp;</td>
                        <td width="144" height="30">&nbsp;</td>
                         </tr>
							</tbody>
						</table>
					</div>
					<div  align="right">
					<p dir="RTL">
						<strong>- </strong>
						<strong>
							&#1589;&#1575;&#1583;&#1602; &#1593;&#1604;&#1610;&#1607;&#1575;
						</strong>
						<strong>:</strong>
						<strong></strong>
					</p>
				</div>
				<br clear="all"/>
				<div  align="center">
					<table dir="rtl" border="1" cellspacing="5" cellpadding="5" width="0">
						<tbody>
							<tr>
								<td width="144" class="previewhead">
									<p dir="RTL">
										<strong>&#1575;&#1604;&#1575;&#1587;&#1605;</strong>
										<strong></strong>
									</p>
								</td>
								<td width="144" class="previewhead">
									<p dir="RTL">
										<strong>
											&#1575;&#1604;&#1605;&#1606;&#1589;&#1576;
										</strong>
										<strong></strong>
									</p>
								</td>
								<td width="144" class="previewhead">
									<p dir="RTL">
										<strong>
											&#1575;&#1604;&#1578;&#1608;&#1602;&#1610;&#1593;
										</strong>
										<strong></strong>
									</p>
								</td>
								<td width="144" class="previewhead">
									<p dir="RTL">
										<strong>
											&#1575;&#1604;&#1578;&#1575;&#1585;&#1610;&#1582;
										</strong>
										<strong></strong>
									</p>
								</td>
							</tr>
								<tr>
                        <td width="144" height="30">&nbsp;</td>
                        <td width="144" height="30">&nbsp;</td>
                        <td width="144" height="30">&nbsp;</td>
                        <td width="144" height="30">&nbsp;</td>
                         </tr>
						</tbody>
					</table>
				</div>
				
				
				</div>
				</div>
				   </MDBCol>
				    </MDBRow>
					
					:
					<MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>

					<MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
					<div align="center">
                    <h3 align="center">وحدة هيكل  تنظيمي</h3>
						 <br></br>
						 <div align="center">
						 <table dir="RTL"  className="previewtable">
							<tr dir="RTL">	<td  className="previewhead" dir="RTL">النوع</td>
							<td className="previewtext" dir="RTL">
								 { this.getLocale() =='ar' ? this.props.info.node.typear : this.props.info.node.typeen}</td>
							
								

							</tr>
							<tr  dir="RTL"><td className="previewhead" dir="RTL">الاسم</td>
							<td className="previewtext" dir="RTL">
								{ this.getLocale() =='ar' ? this.props.info.node.title : this.props.info.node.title}</td>
								
								

							</tr>
							
							<tr  dir="RTL">					
							<td className="previewhead" dir="RTL">التسلسل</td>
							<td className="previewtext" dir="RTL">
							{this.props.info.node.branch == 1 ? 'فرعي' : 'رئيسى'}
								 </td>

							</tr>
							<tr  dir="RTL"> <td className="previewhead" dir="RTL">المستوى</td>
							<td className="previewtext" dir="RTL">{this.props.info.node.level}</td>
							
							</tr>
						 </table>
					 </div>
                      </div>
					 </MDBCol>
                      </MDBRow>
			      	}

                <br></br> 
				<br></br>

				<MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>

				<MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <MDBTable striped responsive hover style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                  
                                    <MDBTableBody>
                                        {
                                            this.state.attachments ? this.state.attachments.map((item, index) => {
                                                return (<tr style={{ textAlign: "center" }}>
                                                   
                                                    <td>
                                                        {item.filetype}
                                                    </td>
                                                    
                                                    <td>
                                                        <img
                                                            onClick={() => this.saveFile(item)}
                                                            style={{
                                                                height: "auto", display: "inline-block", valign: "middle",
                                                                verticalAlign: "middle", width: "auto", maxWidth: "20px", maxHeight: "20px", marginLeft: '10px', cursor: "pointer"
                                                            }}
                                                            alt=""
                                                            src={require('./../../assets/images/BlueDownloads.png')}
                                                            className="img-fluid" />
                                                    </td>
                                                </tr>)
                                            }) : ''
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
				</MDBRow>
		
			    <MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
			    <MDBCol xs="3" sm="3" md="3" lg="3" xl="3"></MDBCol>

		      <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">	
			 <MDBBtn style={{ borderRadius: '5px' }} className="btn btn-primary btn-md"
             onClick={this.props.hide} >{Locali18n[this.state.isArabic ? 'ar' : 'en'].orgStructure.close}</MDBBtn>
         </MDBCol>
		 <MDBCol xs="3" sm="3" md="3" lg="3" xl="3"></MDBCol>
		 </MDBRow>
             </form>
         </MDBContainer>
           )
        }
                           

    }