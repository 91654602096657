import React, { Component } from 'react'
import i18n from "../i18n";

export default class PreviewItem extends Component {

    constructor(props) {
        super(props);
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    downloadAttachment = (fileObj, fileName) => {
        if (navigator.msSaveBlob) {
            let blob = this.b64toBlobForPreview(fileObj);
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            let a = window.document.createElement('a');
            a.href = fileObj;
            a.download = fileName;

            // Append anchor to body.
            document.body.appendChild(a);
            a.click();

            // Remove anchor from body
            // document.body.removeChild(a);
        }

    }

    b64toBlobForPreview = (b64Data, sliceSize = 512) => {

        let contentType = b64Data.split(';base64,');
        let contentType1 = contentType[0].split('data:');
        let contentType2 = contentType1[1].split('/');
      

        const byteCharacters = atob(contentType[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType1[1] });
        return blob;
    }

    render() {
        return (
            <div className="" style={{
                width: '100%',
                display: 'flex',
                flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                borderBottom: '1px solid rgb(221, 221, 221)'
            }}>

                <div className="grid-label" style={{
                    marginLeft: this.props.isArabic ? 10 : 0,
                    marginRight: this.props.isArabic ? 0 : 10,
                    direction: this.props.isArabic ? 'rtl' : 'ltr',
                    textAlign: this.props.isArabic ? 'right' : 'left',
                }}>
                   
                    {i18n[this.getLocale()].viewDetails[this.props.item.label]} :
                </div>
                <div className="grid-attachment"
                    style={{
                        direction: this.props.isArabic ? 'rtl' : 'ltr',
                        cursor: 'pointer'
                    }}>
                    {
                        this.props.item.fileName && this.props.item.fileObj ?
                            <img
                                onClick={() => {
                                    this.downloadAttachment(this.props.item.fileObj, this.props.item.fileName)
                                }}
                                style={{
                                    float: this.props.isArabic ? 'left' : 'right',
                                    width: '40px',
                                    height: '35px',
                                    paddingLeft: '10px',
                                }}
                                alt="" src={require('./../assets/images/BlueDownloads.png')}
                            />
                            : null
                    }

                </div>
            
                <div
                    className="grid-fileName"
                    style={{
                        direction: this.props.isArabic ? 'rtl' : 'ltr',
                        textAlign: this.props.isArabic ? 'right' : 'left',
                    }}>
                    {this.props.item.fileName ? this.props.item.fileName : ''}
                </div>

           </div>
        )
    }
}