
import React, { Component } from 'react';

import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import './../../css/react-confirm-alert.css';
import i18n from '../../i18n';

import ApiRequest from '../../services/ApiRequest';

import Locali18n from './Locali18n';
 import ToastComponent from './../ToastComponent';
 
 import {uploadDocument, getFunctionBlockPayload } from '../../utils/Payload';


const striptags = require('striptags');
let moment = require('moment');

    


 export class PostionsFunctions extends Component {

    constructor(props) {
       
        super(props);
        this.state = {
            parentid:'',
            isEdit: '0',
            Positionedit:false,
           
            id:'0',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            // form variables 
            FTETotal:0,
            position : '',
            subtotal : 0,
            positiontypeid:'0',
            positiongoals: '',
            organizationalRelations: '',
            internalRelations: '',
            externalRelations: '',
            knowledgeAndLevel: 0,
            educationLevel: '',
            experience: '',

            reportto:'',
            requestDate: new Date(),
           
	          
            // attachements
            functionalLoadFileName: '',
            jobEvaluationFileName: '', 
            functionalLoadFileref: '',
            jobEvaluationFileref: '',
           
            functionalLoadFileObjArray: [],
            jobEvaluationFileObjArray: [],
          
            PositionTypesArray:[],
            EducationLevelArray:[],
            organizationalRoles: [],
            functionalRoles:[],
            adminRoles: [],
            positionsTypesArray:[],
            positionsCategoriesArray:[],
            FTE:[],
            position:[],
            subGradesArray:[],
            gradesArray:[],

            jobEvaluationFileErr:'',
            functionalLoadFileErr:'',
            positionGoalsErr: '',
            organizationalRelationsErr: '',
            internalRelationsErr: '',
            externalRelationsErr: '',
            knowledgeAndLevelErr: '',
            educationLevelErr: '',
            experienceErr: '',
            adminRolesErr: '',
            organizationalRolesErr: '',
            functionalRolesErr: '',
            grade:'',
            subgrade:'',
            numberOfPositions:'',
            gradeErr:'',
            subgradeErr:'',
            numberOfPositionsErr:'',

           
             reporttoErr:'',
             categoryidErr:'',
          
            adminRolesModal:false,
            functionalRolesModal:false,
            organizationalRolesModal:false,
            collapseid: "1",
            nameen:'',
            namear:'',
            nameenErr:'',
            namearErr:'',
            title:'',
            draft:0,
            allroles:'0',
            categoryid:'0',
            entityid:0,
            userid:0,
            lang:0,
            dmy:1,
            dailyhours:1,
            formalvacation:12,
            monthlydays:22,
            yearlyvacation:30,
            monthlydaysErr:'',
            yearlyvacationErr:'',
           dailyhoursErr:''
          
        };  
        
    };

    handledmy=(e)=>{

        
          //  let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
         //   if (regex.test(e.target.value)) {
                this.setState({dmy : e.target.value });
               
          //  }
          //  else if (e.target.value === '')
         //    this.setState({dmyErr:'This Field Required'});
          //   else
          //   this.setState({dmyErr:'Invalid Formate'})
    
     
      

    }

    handledailyhours=(e)=>{

        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
        if (regex.test(e.target.value)) {
            this.setState({dailyhours : parseInt(e.target.value) ?  e.target.value : ''});
           
        }
        else if (e.target.value === '')
         this.setState({dailyhoursErr:'This Field Required'});
         else
         this.setState({dailyhoursErr:'Invalid Formate'})


    }

    handleyearlyvacation=(e)=>{


        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
        if (regex.test(e.target.value)) {
            this.setState({yearlyvacation : parseInt(e.target.value) ?  e.target.value : ''});
           
        }
        else if (e.target.value === '')
         this.setState({yearlyvacationErr:'This Field Required'});
         else
         this.setState({yearlyvacationErr:'Invalid Formate'})


    }
    handlemonthlydays=(e)=>{

        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
        if (regex.test(e.target.value)) {
            this.setState({monthlydays : parseInt(e.target.value) ?  e.target.value : ''});
           
        }
        else if (e.target.value === '')
         this.setState({monthlydaysErr:'This Field Required'});
         else
         this.setState({monthlydaysErr:'Invalid Formate'})

     

    }


updateUnittype=(e)=>{
   
    this.setState({ nodeType: e.target.value });
    //info.node.unittype = e.target.value ;

}
getLocale = () => {
    if (this.state.isArabic) {
        return 'ar';
    }
    else {
        return 'en';
    }
}


   submitFTE=()=>{

    if(this.state.FTE)
    {

    }

}

  handleFTEgradeOnchange=(e,item,index)=>{
      
      this.state.FTE[index].grade = e.target.value;
      this.state.FTE[index].subtotal = this.state.FTE[index].grade * this.state.FTE[index].frequency;

      this.setState({FTETotal:0});
       
      let grand=0;
      for(let i=0 ; i<this.state.FTE.length ; i++)
      {   
          let sum = this.state.FTE[i].grade * this.state.FTE[i].frequency;
           grand = grand +  sum ;
              
                  
       }

 this.setState({FTETotal:grand});

}

load=(data)=>{
    this.setState({FTETotal:0});
       
    let grand=0;
    for(let i=0 ; i<data.length ; i++)
    {   
        let sum = data[i].grade * data[i].frequency;
         grand = grand +  sum ;
            
                
     }

this.setState({FTETotal:grand});
}

  handleFTEfrequencyOnchange=(e,item,index)=>{

    this.state.FTE[index].frequency = e.target.value;
      this.state.FTE[index].subtotal = this.state.FTE[index].frequency * this.state.FTE[index].grade;

      this.setState({FTETotal:0});
       
      let grand=0;
      for(let i=0 ; i<this.state.FTE.length ; i++)
      {   
          let sum = this.state.FTE[i].grade * this.state.FTE[i].frequency;
           grand = grand +  sum ;
              
                  
       }

 this.setState({FTETotal:grand});

}

   handleFTESubOnchange=(e,index)=>{
       
    this.setState({
        subtotal: parseInt(this.state.subtotal) + parseInt(e.target.value)
    });

   
}

  handleAdminRoleOnchange=(e,index)=>{
    
    let adminRoles =Config.getOrganizationStructurAdminRoles();
        
    adminRoles[index].desc = e.target.value;
    this.setState({ adminRoles});
     Config.setOrganizationStructurAdminRoles(adminRoles);


    
  }
  

    handleOrganizationalRoleOnchange=(e,index)=>{

        let orgainzationalRoles =Config.getOrganizationStructurOrganizationalRoles();
        
       
        orgainzationalRoles[index].desc = e.target.value;
        this.setState({ orgainzationalRoles});
         Config.setOrganizationStructurOrganizationalRoles(orgainzationalRoles);
       
      }

      handleFunctionalRoleOnchange=(e,index)=>{


        let functionalRoles =Config.getOrganizationStructurFunctionalRoles();
        
        functionalRoles[index].desc = e.target.value;
        this.setState({ functionalRoles});
         Config.setOrganizationStructurFunctionalRoles(functionalRoles);

         
      }

    handleEnglishName = (event) => {
        // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
        // if (regex.test(event.target.value)) {
             this.setState({ nameen: event.target.value });
            
       //  }
     };
     
     handleArabicName = (event) => {
        // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
         //if (regex.test(event.target.value)) {
             this.setState({ namear: event.target.value });
            
        // }
     };
    
  


   
    handleNumberOfPositions=(e) => {
        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
        if (regex.test(e.target.value)) {
            this.setState({numberOfPositions : parseInt(e.target.value) ?  e.target.value : ''});
           
        }
        else if (e.target.value === '')
               this.setState({numberOfPositionsErr:'This Field Required'});
         else
                this.setState({numberOfPositionsErr:'Invalid Formate'})

    };

    handleGrade=(e) => {
        
        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
        if (regex.test(e.target.value)) {
            this.setState({grade : e.target.value});
            this.setState({gradeErr:''});

            this.getsubgrades(e.target.value);
           
        }
        else if (e.target.value === '')
         this.setState({gradeErr:'This Field Required'});
         else
         this.setState({gradeErr:'Invalid Formate'})

    };
    handleSubGrade=(e) => {
        
        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
        if (regex.test(e.target.value)) {
            this.setState({subgrade : e.target.value});
            this.setState({subgradeErr:''});
        }
        else if (e.target.value === '')
         this.setState({subgradeErr:'This Field Required'});
         else
         this.setState({subgradeErr:'Invalid Formate'})

    };

    handlePositionGoals = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                positionGoalsErr: "",
                positiongoals: newContent
            })
        }
        else {
            this.setState({
                positionGoalsErr: "ckeditorCountErr"
            })
        }
    };
    updatEducationLevel=(e)=>{
  
        
        this.setState({ educationLevel: e.target.value });
        this.setState({ educationLevelErr:''});

       // info.node.knowledgeAndLevel = e.target.value ;
    
    }
    handleOrganizationalRelations = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                organizationalRelationsErr: "",
                organizationalRelations: newContent
            })
        }
        else {
            this.setState({
                organizationalRelationsErr: "ckeditorCountErr"
            })
        }
    };
    handleInternalRelations = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                internalRelationsErr: "",
                internalRelations: newContent
            })
        }
        else {
            this.setState({
                internalRelationsErr: "ckeditorCountErr"
            })
        }
    }; 

    handlExternalRelations = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                externalRelationsErr: "",
                externalRelations: newContent
            })
        }
        else {
            this.setState({
                externalRelationsErr: "ckeditorCountErr"
            })
        }
    }; 

  
    handleKnowledgeAndLevel = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                knowledgeAndLevelErr: "",
                knowledgeAndLevel: newContent
            })
        }
        else {
            this.setState({
                knowledgeAndLevelErr: "ckeditorCountErr"
            })
        }
    };
    handleEducationLevel = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                educationLevelErr: "",
                educationLevel: newContent
            })
        }
        else {
            this.setState({
                educationLevelErr: "ckeditorCountErr"
            })
        }
    };
    handleExperience = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                experienceErr: "",
                experience: newContent
            })
        }
        else {
            this.setState({
                experienceErr: "ckeditorCountErr"
            })
        }
    };

    //----------
 setdraft=(value)=>{
    this.setState({draft:value});
 }
   
    
    functionalLoadFileOnChange = (e) => {
        let TempArray = [];
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({
                    functionalLoadFileName: '', functionalLoadFileErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileObj = e.target.files[0];
                    
                    this.setState({
                        
                        functionalLoadFileName: e.target.files[0].name, functionalLoadFileErr: ''
                    }, () => {
                      
                        let tempFile = fileObj;
                        let reader = new FileReader();
                      
                        reader.onloadend = () => {
                            TempArray.push(reader.result);
                            this.setState({
                                functionalLoadFileObjArray: TempArray
                            }, () => {
                              
                                Config.setBase64ForOrganizationStructurfunctionalLoad(this.state.functionalLoadFileObjArray);
                            }); 
                                         
                        }
                           
                        reader.readAsDataURL(tempFile);
                    });
    
                } else {
                    e.target.value = '';
                    this.setState({
                        functionalLoadFileName: '',
                        functionalLoadFileErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            this.setState({ functionalLoadFileName: '', functionalLoadFileErr: 'thisFieldIsRequired' });
        }
    };
    
     Validate=()=> {
    
        
        let state = true;
          
    
        if( this.state.positiongoals==='' )
        {this.setState({positionGoalsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.positionGoalsErr !=='')
        state=false;   
        if( this.state.organizationalRelations==='' )
        {this.setState({organizationalRelationsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.organizationalRelationsErr !=='')
        state=false; 
        if( this.state.internalRelations==='' )
        {this.setState({internalRelationsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.internalRelationsErr !=='')
        state=false; 
        if( this.state.externalRelations==='' )
        {this.setState({externalRelationsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.externalRelationsErr !=='')
        state=false; 
        if( this.state.knowledgeAndLevel==='' )
        {this.setState({knowledgeAndLevelErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.knowledgeAndLevelErr !=='')
        state=false; 
        if( this.state.educationLevel==='' )
        {this.setState({educationLevelErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.educationLevelErr !=='')
        state=false; 
        if( this.state.experience==='' )
        {this.setState({experienceErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.experienceErr !=='')
        state=false; 
       
    
       // if( this.state.jobEvaluationFileObjArray.length === 0 )
       // {this.setState({jobEvaluationFileErr:'thisFieldIsRequired'}); state=false;}
       // if( this.state.jobEvaluationFileErr!=='')
       // state=false;
        
       // if( this.state.functionalLoadFileObjArray.length===0 )
      //  {this.setState({functionalLoadFileErr:'thisFieldIsRequired'}); state=false;}
       // if( this.state.functionalLoadFileErr !=='')
      //state=false; 
    
      

    
        if(Config.getOrganizationStructurAdminRoles().length === 0 ||
        Config.getOrganizationStructurFunctionalRoles().length === 0
            || Config.getOrganizationStructurOrganizationalRoles().length === 0)
            {
                this.setState({allroles : '1'});
                state=false;
            }

       return state;
    };
    // Admin Roles

    addAdminRole = () => {
        let adminRoles=[];
       if(Config.getOrganizationStructurAdminRoles().length > 0 && 
        this.state.adminRoles.length==0)
        {
            adminRoles = Config.getOrganizationStructurAdminRoles();
        }
       else
         adminRoles = [...this.state.adminRoles];

        let obj ={id:'',desc:''};
        adminRoles.push(obj);
        this.setState({adminRoles});
        Config.setOrganizationStructurAdminRoles(adminRoles);

    };

    deleteAdminRole = (index) => {
        let adminRoles =[];
         adminRoles = [...this.state.adminRoles];
        adminRoles.splice(index,1);
        this.setState({adminRoles});
        Config.setOrganizationStructurAdminRoles(adminRoles);
    };

// Orgainzational Roles

    addOrgainzationalRole = () => {
        let organizationalRoles=[];
        if(Config.getOrganizationStructurOrganizationalRoles().length > 0 && 
        this.state.organizationalRoles.length==0)
        {
            organizationalRoles = Config.getOrganizationStructurOrganizationalRoles();
        }
        else
         organizationalRoles = [...this.state.organizationalRoles];

    let obj ={id:'',desc:''};
    organizationalRoles.push(obj);
    this.setState({organizationalRoles});
    Config.setOrganizationStructurOrganizationalRoles(organizationalRoles);
};

    deleteOrgainzationalRole = (index) => {
        let organizationalRoles =[];
     organizationalRoles = [...this.state.organizationalRoles];
    organizationalRoles.splice(index,1);
    this.setState({organizationalRoles});
    Config.setOrganizationStructurOrganizationalRoles(organizationalRoles);
};

// Functional Roles

addFunctionalRole = () => {

    let functionalRoles=[];
    if(Config.getOrganizationStructurFunctionalRoles().length > 0 && 
    this.state.functionalRoles.length==0)
    {
        functionalRoles = Config.getOrganizationStructurFunctionalRoles();
    }
    else
     functionalRoles = [...this.state.functionalRoles];

    let obj ={id:'',desc:''};
    functionalRoles.push(obj);
    this.setState({functionalRoles});
    Config.setOrganizationStructurFunctionalRoles(functionalRoles);
};

deleteFunctionalRole = (index) => {
    let functionalRoles = [];
     functionalRoles = [...this.state.functionalRoles];
    functionalRoles.splice(index,1);
    this.setState({functionalRoles});
    Config.setOrganizationStructurFunctionalRoles(functionalRoles);
};

showFunctionalModal = () => {
    this.setState({
        functionalRolesModal: !this.state.functionalRolesModal,
        parentNodeValue: '',
        englishName: '',
        arabicName: '',
        adminRolesErr:'',
    });
}
showOrganizationalModal = () => {
    this.setState({
        organizationalRolesModal: !this.state.organizationalRolesModal,
        parentNodeValue: '',
        englishName: '',
        arabicName: '',
        adminRolesErr:'',
    });
}
showAdminModal = () => {
    this.setState({
        adminRolesModal: !this.state.adminRolesModal,
        parentNodeValue: '',
        englishName: '',
        arabicName: '',
        adminRolesErr:'',
       
    });


}

updatePositionreportto=(e)=>{
   
    this.setState({ reportto : e.target.value });
    this.setState({ reporttoErr : ''});
}
updatePositioncategory=(e)=>{
   
    this.setState({ categoryid : e.target.value});
    this.setState({ categoryidErr : ''});
              
     

}

FunctionalLoadFileOnChange = (e) => {
    let TempArray = [];
    if (e.target.files.length > 0) {
        let inpFileName = e.target.files[0].name;
        let fileSize = e.target.files[0].size;
        let fileExt = inpFileName.split('.').pop().toUpperCase();
        let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
        if (allowedExtensions.indexOf(fileExt) <= -1) {
            this.setState({
                functionalLoadFileName: '', functionalLoadFileErr: 'invalidFileType'
            });
        }
        else {
            if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                let fileObj = e.target.files[0];
                this.setState({
                    
                    functionalLoadFileName: e.target.files[0].name, functionalLoadFileErr: ''
                }, () => {
                   

                    let tempFile = fileObj;
                    let reader = new FileReader();
                  
                    reader.onloadend = () => {
                        TempArray.push(reader.result);
                        this.setState({
                            functionalLoadFileObjArray: TempArray
                        }, () => {
                           
                            Config.setBase64ForOrganizationStructurFunctionalLoad(this.state.functionalLoadFileObjArray);
                        }); 
                                     
                    }
                    
                    reader.readAsDataURL(tempFile);
                });

            } else {
                e.target.value = '';
                this.setState({
                    functionalLoadFileName: '',
                    functionalLoadFileErr: 'invalidFileSize'
                });
            }
        }
    } else {
        this.setState({ functionalLoadFileName: '', functionalLoadFileErr: 'thisFieldIsRequired' });
    }
    
    };

  JobEvaluationFileOnChange = (e) => {
    let TempArray = [];
    if (e.target.files.length > 0) {
        let inpFileName = e.target.files[0].name;
        let fileSize = e.target.files[0].size;
        let fileExt = inpFileName.split('.').pop().toUpperCase();
        let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
        if (allowedExtensions.indexOf(fileExt) <= -1) {
            this.setState({
                jobEvaluationFileName: '', jobEvaluationFileErr: 'invalidFileType'
            });
        }
        else {
            if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                let fileObj = e.target.files[0];
                
                this.setState({
                    
                    jobEvaluationFileName: e.target.files[0].name, jobEvaluationFileErr: ''
                }, () => {
                   

                    let tempFile = fileObj;
                    let reader = new FileReader();
                  
                    reader.onloadend = () => {
                        TempArray.push(reader.result);
                        this.setState({
                            jobEvaluationFileObjArray: TempArray
                        }, () => {
                           
                            Config.setBase64ForOrganizationStructurJobEvaluation(this.state.jobEvaluationFileObjArray);
                        }); 
                                     
                    }
                    
                    reader.readAsDataURL(tempFile);
                });

            } else {
                e.target.value = '';
                this.setState({
                    jobEvaluationFileName: '',
                    jobEvaluationFileErr: 'invalidFileSize'
                });
            }
        }
    } else {
        this.setState({ jobEvaluationFileName: '', jobEvaluationFileErr: 'thisFieldIsRequired' });
    }
    
    };

    checkSavingDraft=()=>{
   
        
      let state=true;
        if( Config.getOrganizationStructurAdminRoles().length === 0  ||
             Config.getOrganizationStructurFunctionalRoles().length === 0 
            || Config.getOrganizationStructurOrganizationalRoles().length === 0 )
        {
           this.setState({adminRolesErr:'thisFieldIsRequired'}); 
            state=false;
       }
       if(this.state.categoryid === ''  || this.state.categoryid === null || this.state.categoryid==='0')
       {
           this.setState({categoryidErr:'thisFieldIsRequired'}); 
           state=false;
           
       }
        if(this.state.numberOfPositions === '')
        {
            this.setState({numberOfPositionsErr:'thisFieldIsRequired'});
             state=false;
            
        }
       if(this.state.educationLevel === ''  || this.state.educationLevel === null)
        {
            this.setState({educationLevelErr:'thisFieldIsRequired'}); 
            state=false;
            
        }

     
        if(this.state.reportto === ''  || this.state.reportto === null || this.state.reportto===0)
        {
            this.setState({reporttoErr:'thisFieldIsRequired'}); 
            state=false;
            
        }
        if(this.state.grade === ''  || this.state.grade === null)
        {
            this.setState({gradeErr:'thisFieldIsRequired'}); 
            state=false;
            
        }
        if(this.state.subgrade === ''  || this.state.subgrade === null)
        {
            this.setState({subgradeErr:'thisFieldIsRequired'}); 
            state=false;
            
        }

       return state;
    }

  addPosition = (event) => {
 
   event.preventDefault();
   event.target.className += " was-validated";
   let p = this.props;
 
   console.log("categoryidErr" ,this.state.categoryid);

       let obj = {};

       if (event.target.id === "2") {

        if(this.checkSavingDraft())
             obj.draft = 1;
          else 
              return;
      
        }
        else if (event.target.id === "1") {
            obj.draft = 1;
        }
     else if (event.target.id === "0") {
        if(!this.Validate())
            return;
        else
        obj.draft = 0;
    }

       let getDataFromLocalStorage = Config.getLocalStorageLoginData();
       if (getDataFromLocalStorage) {
           obj.entityid = getDataFromLocalStorage.EntityID;
           obj.Userid = getDataFromLocalStorage.id;
       }

       obj.reportto = this.state.reportto ? this.state.reportto : 0;
       obj.categoryid = this.state.categoryid ? this.state.categoryid : 0;
       obj.nameen = this.state.nameen;
       obj.namear = this.state.namear;
       obj.typeid = 1;
       obj.positiontypeid = this.state.positiontypeid? this.state.positiontypeid : 0;
       
       obj.grade = this.state.grade;
       obj.number = this.state.numberOfPositions;
      
       obj.organizationalrelations = this.state.organizationalRelations;
       obj.internalrelations = this.state.internalRelations;
       obj.externalrelations = this.state.externalRelations;
       obj.objectives = this.state.positiongoals;

       obj.knowledgeandlevel = this.state.knowledgeAndLevel;
       obj.experience = this.state.experience;
       obj.qualifications = this.state.educationLevel; 


       obj.dailyhours = this.state.dailyhours;
       obj.monthlydays = this.state.monthlydays;
       obj.yearlyvacationdays = this.state.yearlyvacation; 
       obj.dmy = this.state.dmy; 
       obj.subgrade = this.state.subgrade; 


       
       obj.adminroles = Config.getOrganizationStructurAdminRoles();
       obj.functionalroles =Config.getOrganizationStructurFunctionalRoles();
       obj.organizationalroles = Config.getOrganizationStructurOrganizationalRoles();

      

       if(this.props.edit===1){
          obj.id =this.props.info.node.id;
          obj.parentId = this.props.info.node.Parentid;
          obj.unitid = this.props.info.node.Parentid;
          obj.parenttypeid=this.props.info.node.parenttypeid;

       }
       
       if(this.props.edit===0) {
         obj.id =0;
         obj.parentId = this.props.info.node.id;
         obj.unitid = this.props.info.node.id;
         obj.parenttypeid=this.props.info.node.typeid;


       }

       let payload = obj;

       let  endPoint = 'SavePosition';
  
       let url = Config.getUrl(endPoint);

       this.setState({
           isLoading: true
       }, () => {
           ApiRequest.sendRequestForPost(url, payload, res => {
               this.setState({
                   isLoading: false
               }
               , () => {
                   if (res.Status) {

                        let totalFile = 2;
                       this.uploadFile(0, totalFile, res, 0);

                           let adminroles=[];
                           Config.setOrganizationStructurAdminRoles(adminroles);
                           let organizationalroles=[];
                           Config.setOrganizationStructurOrganizationalRoles(organizationalroles);
                           let functionalroles=[];
                           Config.setOrganizationStructurFunctionalRoles(functionalroles);

                      // if(event.target.id ===2)
                           this.props.reload(0);
                    
                    ToastComponent.notifyInfo(
                        obj.id ===0 ?   Locali18n[this.getLocale()].positionadded
                        : Locali18n[this.getLocale()].positionupdated
                           ); 

                   

                   }
                    else {
                       this.setState({
                           isLoading: false
                       })
                      
                       ToastComponent.notifyInfo(res.Message);
                   
                   }
                   
               })
           })
       })

       this.props.hide();
};

  saveFTE = (event) => {

   event.preventDefault();
   event.target.className += " was-validated";

  

      let payload = this.state.FTE;
      
       let  endPoint = 'saveFTE';
  
       let url = Config.getUrl(endPoint);

       this.setState({
           isLoading: true
       }, () => {
           ApiRequest.sendRequestForPost(url, payload, res => {
               this.setState({
                   isLoading: false
               }
               , () => {
                   if (res.Status) {
                    
                       ToastComponent.notifyInfo( Locali18n[this.props.isArabic ? 'ar' : 'en'].fteUpdated);
                       
                   }
                    else {
                       this.setState({
                           isLoading: false
                       })
                      
                       ToastComponent.notifyInfo(res.Message);
                   
                   }
                   
               })
           })
       })

     
};

uploadFile = (i, totalCount, res, multifileCount) => {
  
   if (i < totalCount) {
       let fileObj = null;
       let tempBase64 = {};
       let isLastFileInMultiUpload = false;
       let obj = {};
       if (i === 0) {
           // get base 64 for attachement
           fileObj = Config.getBase64ForOrganizationStructurFunctionalLoad();
           obj.filename = this.state.functionalLoadFileName;
           obj.filetype = Config.getbase64OrganizationStructurFunctionalLoadId();
           tempBase64 = fileObj;
       } else if (i === 1) {
           // get base 64 for attachement
           fileObj = Config.getBase64ForOrganizationStructurJobEvaluation();
           obj.filename = this.state.jobEvaluationFileName;
           obj.filetype = Config.getbase64OrganizationStructurJobEvaluationId();
           tempBase64 = fileObj;
       } 
     
      
       if (obj.filename && tempBase64) {
           let reqId = 0;
           if (res && res.data && res.data) {
               reqId = res.data;
               obj.requestid = reqId;
           } else {
               obj.requestid = reqId;
           }
        
           if (tempBase64[0].includes(",")) {
               obj.fileContent = tempBase64[0].split(',')[1];
           } else {
               obj.fileContent = tempBase64[0];
           }
          
           obj.position = 1;
           let payload = uploadDocument(obj);
           let endPoint = 'UploadAttachment';
           let url = Config.getUrl(endPoint);
          
           ApiRequest.sendRequestForPost(url, payload, resForOtherAttachmentFile => {
              
               if (resForOtherAttachmentFile.Status) {
                   if (i === 0) {
                       if (isLastFileInMultiUpload) {
                           this.uploadFile(i + 1, totalCount, res, multifileCount + 1);
                       } else {
                           this.uploadFile(i + 1, totalCount, res, multifileCount + 1);
                       }
                   } else {
                       this.uploadFile(i + 1, totalCount, res, 0);
                   }
               }
              
               
           })
       } else {
           this.uploadFile(i + 1, totalCount, res, 0);
       }
   } 
};


getgrades=()=>{

   
    let payload = getFunctionBlockPayload();
    let endPoint = 'GetGrades'
    let url = Config.getUrl(endPoint);
    ApiRequest.sendRequestForPost(url, payload, res => {
        let tempSystemListArray = [];
        if (res.Status) {
            
            let resSystemListData = res.data;
            if (resSystemListData && resSystemListData.length > 0) {
                for (let index = 0; index < resSystemListData.length; index++) {
                    tempSystemListArray.push(resSystemListData[index]);
                }
                this.setState({
                   gradesArray: tempSystemListArray
                })
                //this.pt = tempSystemListArray;
                //console.log( 'pt :' + this.pt);
            }
        } 
    })

};

getsubgrades=(gradeid)=>{

   
   // let payload = getFunctionBlockPayload();
   let payload ={};
   if(gradeid===0)
      payload.GradeId=this.state.grade; 
      else
       payload.GradeId=gradeid;

    let endPoint = 'GetSubGrades'
    let url = Config.getUrl(endPoint);
    ApiRequest.sendRequestForPost(url, payload, res => {
        let tempSystemListArray = [];
        if (res.Status) {
            
            let resSystemListData = res.data;
            if (resSystemListData && resSystemListData.length > 0) {
                for (let index = 0; index < resSystemListData.length; index++) {
                    tempSystemListArray.push(resSystemListData[index]);
                }
                this.setState({
                   subGradesArray: tempSystemListArray
                })
                //this.pt = tempSystemListArray;
                //console.log( 'pt :' + this.pt);
            }
        } 
    })

};

getpositionscategories=()=>{

   
    let payload = getFunctionBlockPayload();
    let endPoint = 'GetPositionsCategories'
    let url = Config.getUrl(endPoint);
    ApiRequest.sendRequestForPost(url, payload, res => {
        let tempSystemListArray = [];
        if (res.Status) {
            
            let resSystemListData = res.data;
            if (resSystemListData && resSystemListData.length > 0) {
                for (let index = 0; index < resSystemListData.length; index++) {
                    tempSystemListArray.push(resSystemListData[index]);
                }
                this.setState({
                    positionsCategoriesArray: tempSystemListArray
                })
               // this.pt = tempSystemListArray;
            }
        } 
    })

};

geteducationlevels=()=>{

    
    let payload = getFunctionBlockPayload();
    let endPoint = 'GetEducationLevels'
    let url = Config.getUrl(endPoint);
    ApiRequest.sendRequestForPost(url, payload, res => {
        let tempSystemListArray = [];
        if (res.Status) {
            
            let resSystemListData = res.data;
            if (resSystemListData && resSystemListData.length > 0) {
                for (let index = 0; index < resSystemListData.length; index++) {
                    tempSystemListArray.push(resSystemListData[index]);
                }
                this.setState({
                    EducationLevelArray: tempSystemListArray
                })
                //this.pt = tempSystemListArray;
                //console.log( 'pt :' + this.pt);
            }
        } 
    })

};

  getpositionsTypes = () => {
    let payload = getFunctionBlockPayload();
  
    let endPoint = 'GetPositionsTypes'
    let url = Config.getUrl(endPoint);
    ApiRequest.sendRequestForPost(url, payload, res => {
        let tempSystemListArray = [];
        if (res.Status) {
            let resSystemListData = res.data;
            if (resSystemListData && resSystemListData.length > 0) {
                for (let index = 0; index < resSystemListData.length; index++) {
                    tempSystemListArray.push(resSystemListData[index]);
                }
                this.setState({
                    positionsTypesArray: tempSystemListArray
                })
                //this.p = tempSystemListArray;
              //  console.log( 'p :' + this.p);

            }
        } 
    })
}
 
getFTE=(positionid)=>{

   let obj = {};
   obj.positionid = positionid;

   let payload = obj;

   let  endPoint = 'GetFTE';

   let url = Config.getUrl(endPoint);

  
  ApiRequest.sendRequestForPost(url, payload, res => {
          
               if (res.Status) {

                   this.setState({FTE:res.data});
                   this.load(res.data);
                   console.log(res.data);
 
               }
                else {
                  
                   ToastComponent.notifyInfo(Locali18n[this.props.isArabic ? 'ar' : 'en'].nodata);
               
               }
               
           })
}


}
export default PostionsFunctions



