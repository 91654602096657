import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody,MDBModal, MDBModalBody, MDBModalHeader,MDBContainer,MDBCard } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment,saveActionPlansInitiatives } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import CurrencyFormat from 'react-currency-format';
import {  uploadDocument } from '../../utils/Payload';
import DatePicker from "react-datepicker";
import LoadingOverlay from 'react-loading-overlay';

const striptags = require('striptags');




export default class Step4 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentindex:'0',
            InitiativeOtherDescrption:'',
            isArabic:localStorage.getItem('Lang') === 'ar' ? true : false,
            CanEdit:true,
            showDefneationsState:false,
            showdeffortheme:true,
            showdefforPractice:false,
            MainthemeDef:'',
MainPracticeDef:'',
            InitiativeFileArray: [],
            InitiativeFileErr: "",
 InitiativeFile:'',
 InitiativeProjectsTasksBudgetErr:'',
            InitiativeFileFileObj: "",
           InitiativeFileUrl:'',
            isChrimanSelected:'',
            MembersArray:[],
            entityArray:[],
            formalrequestletterArray: [],
            disablejustddl:false,
            InitiativeDelayJustificationTxt:'',
            formalrequestletterErr: "",
            formalrequestletter: this.props.sendData.formalrequestletter,
            formalrequestletterFileObj: "",
            formalrequestletterUrl:'',
            requesterentityname:'',
requesterentitynameErr:'',
requesterName:'',
requesterNameErr:'',
requesterphoneNumber:'',
requesterphoneNumberErr:'',
rquesterEmail:'',
rquesterEmailErr:'',
MemberJobTitleErr:'',
ProjectAttachErr:'',
ProjectAttachFileObj:'',
            isEdit:this.props.isEdit,
            requestId:this.props.requestId,
            memberTitleId:'',
           
            InitiativeBudgetErr:'',
            InitiativeProjectsTasksStatusArray:
[

],
InitiativeStatus:'',
InitiativeStatusLabel:'',
            MainThemeArray:[],
                MainTheme:'',
                MainThemeLabel:'',
                MainPracticeArrayTemp:[],
                MainPracticeArray:[],
                MainPractice:'',
                MainPracticeLabel:'',
   poistionsTypeArray: [],
   InitiativeProjectsJustificationsArray:[],
   InitiativeStatusArray:[],
   InitiativeStatusJustificationArray:[],
   
   InitiativeName:'',
   InitiativeBudget:'0',
   Nameofresponsible:'',
   Roleofresponsible:'',
   InitiativeStatus:'',
   InitiativeFile:'',
   ExpectedOutcomes:'',
   ActualOutcomes:'',	
   ProjectsBuget:0
        }
    }

    ProjectAttachOnChange = (e,index) => {
        debugger;
index = this.state.currentindex;

        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let tempobj = this.state.MembersArray;
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                tempobj[index].ProjectAttach = '';
                tempobj[index].ProjectAttachErr = 'invalidFileType';
    
            
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ 
                        // ProjectAttach:inpFileName,
                         ProjectAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            tempobj[index].ProjectAttach=inpFileName;
                            tempobj[index].ProjectAttachFileObj = reader.result;
                            tempobj[index].ProjectAttachErr = '';
    
                            this.setState({
                                ProjectAttachFileObj: reader.result
                            }, () => {
                                // Config.setBase64ForDisciplinaryboardProjectAttach(this.state.ProjectAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    tempobj[index].ProjectAttachFile = '';
                        tempobj[index].ProjectAttachFileErr = 'invalidFileSize';
                    this.setState({ ProjectAttachFile: '', ProjectAttachFileErr: 'invalidFileSize' });
                }
            }
    
            this.setState({
                MembersArray: tempobj,
                ProjectAttachErr: tempobj[index].ProjectAttachFileErr
            });
        }
        //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };
    EditCollapse (index)
    {
        let tempobj = this.state.MembersArray;
        tempobj[index].collapse = true;
        
        this.setState({ MembersArray: tempobj,currentindex:index });
    }
    CloseCollapse (index)
    {
        let tempobj = this.state.MembersArray;
        tempobj[index].collapse = false;
        
        this.setState({ MembersArray: tempobj });
    }
    showDefneationsMainTheme= () => { 
    
        this.setState({
            showDefneationsState: true,
            showdeffortheme:true,
        showdefforPractice:false
        },()=>
        {
            
        }
        );
      
    }
    showDefneationsMainPractice= () => { 
    
        this.setState({
            showDefneationsState: true,
            showdeffortheme:false,
            showdefforPractice:true,
        },()=>
        {
            
        }
        );
      
    }
    showMertricDesctiption= () => { 
    
        this.setState({
            showDefneationsState: true,
            showdeffortheme:false,
            showdefforPractice:false,
        },()=>
        {
            
        }
        );
      
    }
    toggle = (reloadFlag) => {
        
        debugger;
        this.setState({
            modal: !this.state.modal,
            showDefneationsState:!this.state.showDefneationsState
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState,
                  
                }, () => {
                  
                    this.current.componentDidMount();
                });
            }
        });
    }

    InitiativeFileOnChange = e => {
        if (e.target.files.length > 0) {
            for(let i=0;i<e.target.files.length;i++)
        {

        
            let inpFileName = e.target.files[i].name;
            let fileSize = e.target.files[i].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ InitiativeFile: '', InitiativeFileErr: 'invalidFileType' });
                return;
            } else {
                let fileTargetVarible = e.target.files[i];
                if (fileSize > 0 && fileSize < Config.getWorkshopMaxFileSize()) {
                    let tmepinifile =this.state.InitiativeFile? this.state.InitiativeFile+' / '+e.target.files[i].name:e.target.files[i].name;
                    this.setState({ InitiativeFile: tmepinifile , InitiativeFileErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                InitiativeFileFileObj: reader.result
                            }, () => {
                                Config.setBase64ForInitiativeFile(this.state.InitiativeFileFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ InitiativeFile: '', InitiativeFileErr: 'invalidFileSize' });
                }
            }
        }
    }
    };
//*********** Backup for single file upload  ******/
    // InitiativeFileOnChange = e => {
    //     if (e.target.files.length > 0) {
    //         let inpFileName = e.target.files[0].name;
    //         let fileSize = e.target.files[0].size;
    //         let fileExt = inpFileName.split('.').pop().toUpperCase();
    //         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
    //         if (allowedExtensions.indexOf(fileExt) <= -1) {
    //             e.target.value = '';
    //             this.setState({ InitiativeFile: '', InitiativeFileErr: 'invalidFileType' });
    //         } else {
    //             let fileTargetVarible = e.target.files[0];
    //             if (fileSize > 0 && fileSize < Config.getWorkshopMaxFileSize()) {
    //                 this.setState({ InitiativeFile: e.target.files[0].name, InitiativeFileErr: '' }, () => {
    //                     let tempFile = fileTargetVarible;
    //                     let reader = new FileReader();
    //                     reader.onloadend = () => {
    //                         this.setState({
    //                             InitiativeFileFileObj: reader.result
    //                         }, () => {
    //                             Config.setBase64ForInitiativeFile(this.state.InitiativeFileFileObj);
    //                         })
    //                     };
    //                     reader.readAsDataURL(tempFile);
    //                 });
    //             }
    //             else {
    //                 e.target.value = '';
    //                 this.setState({ InitiativeFile: '', InitiativeFileErr: 'invalidFileSize' });
    //             }
    //         }
    //     }
    // };
    // InitiativeFileOnChange1 = e => {
    //     if (e.target.files.length > 0) {
    //         let inpFileName = e.target.files[0].name;
    //         let fileSize = e.target.files[0].size;
    //         let fileExt = inpFileName.split('.').pop().toUpperCase();
    //         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
    //         if (allowedExtensions.indexOf(fileExt) <= -1) {
    //             e.target.value = '';
    //             this.setState({ InitiativeFile: '', InitiativeFileErr: 'invalidFileType' });
    //         } else {
    //             let fileTargetVarible = e.target.files[0];
    //             if (fileSize > 0 && fileSize < Config.getWorkshopMaxFileSize()) {
    //                 this.setState({ InitiativeFile: e.target.files[0].name, InitiativeFileErr: '' }, () => {
    //                     let tempFile = fileTargetVarible;
    //                     let reader = new FileReader();
    //                     reader.onloadend = () => {
    //                         this.setState({
    //                             InitiativeFileFileObj: reader.result
    //                         }, () => {
    //                             Config.setBase64ForInitiativeFile(this.state.InitiativeFileFileObj);
    //                         })
    //                     };
    //                     reader.readAsDataURL(tempFile);
    //                 });
    //             }
    //             else {
    //                 e.target.value = '';
    //                 this.setState({ InitiativeFile: '', InitiativeFileErr: 'invalidFileSize' });
    //             }
    //         }
    //     }
    // };

    SaveMembersArray = (id, tempTableData) => {
       
        debugger;
        //let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        let newTempTableData = [];
        tempTableData.forEach((item, index) => {

            let SaveWorkShopDatesObject = {};
         debugger;
         SaveWorkShopDatesObject.ID=item.ProjectID;
            SaveWorkShopDatesObject.InitiativeID=id;
            SaveWorkShopDatesObject.IntialStartDate=item.IntialStartDate;
            SaveWorkShopDatesObject.IntialEndDate=item.IntialEndDate;
            SaveWorkShopDatesObject.Metric=item.Metric;
            SaveWorkShopDatesObject.ActualCloseTime=item.ActualCloseTime;
            SaveWorkShopDatesObject.InitiativeProjectsTasksBudget=item.InitiativeProjectsTasksBudget;
            // SaveWorkShopDatesObject.ExpectedOutcomes=item.ExpectedOutcomes;
            // SaveWorkShopDatesObject.ActualOutcomes=item.ActualOutcomes;
            SaveWorkShopDatesObject.ProjectStatus=item.ProjectStatus;
            SaveWorkShopDatesObject. ProjectDelayJustification=item.ProjectDelayJustification;
            SaveWorkShopDatesObject.ProjectOtherDescrption=item.ProjectOtherDescrption;
            SaveWorkShopDatesObject.ProjectStatusInitiativeDelayJustificationTxt=item.ProjectStatusInitiativeDelayJustificationTxt;
            SaveWorkShopDatesObject.Title=item.Title;
            SaveWorkShopDatesObject.MainPractice=item.MainPractice;
            SaveWorkShopDatesObject.MainTheme=item.MainTheme;
            SaveWorkShopDatesObject.ProjectAttach=item.ProjectAttach;
            SaveWorkShopDatesObject.ProjectAttachFileObj=item.ProjectAttachFileObj;
            
            newTempTableData.push(SaveWorkShopDatesObject);
        })
        return newTempTableData;
    }
    checkValidationAddNewMember()
    {
        let errormessage = '';
        let isvalida = true;
        let item = this.state.MembersArray[this.state.MembersArray.length-1];
        let diff = new Date(item.IntialEndDate) - new Date(item.IntialStartDate);

        if (diff < 1) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.DateValidation;
            isvalida = false;
         
        }
        if(!item.InitiativeProjectsTasksBudget && item.InitiativeProjectsTasksBudget!=0) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectBudgetEmptyErr;
            isvalida = false;
         
        }
        if (!item.IntialStartDate) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.StartDateErr;
            isvalida = false;
         
        }
        if (!item.IntialEndDate) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.EndDateErr;
            isvalida = false;
         
        }
        if (!item.Title) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.PrjectTitleErr;
            isvalida = false;
         
        }

        if (!item.Metric) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.MetricErr;
            isvalida = false;
         
        }

        if (!item.ProjectStatus) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectStatusEmptyErr;
            isvalida = false;
         
        }
        if(!item.disableall)
    {
        if (   item.ProjectStatus == 1   ||    item.ProjectStatus == 5 || item.ProjectStatus == 6 || item.ProjectStatus == 8 || item.ProjectStatus == 4) 
        {
           if(!item.ProjectAttach||!item.ProjectAttachFileObj) 
           {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectAttachErr;
            isvalida = false;
           }
        }
    }
        //Project Status Reason DD
        if (item.ProjectStatus > 2 && item.ProjectStatus != 9) {
            if (!item.ProjectDelayJustification  || item.ProjectDelayJustification == 0) {
                errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectReasonErr;
                isvalida = false;
             
            }

            if (item.ProjectStatus == 8 || item.ProjectDelayJustification==5) {
                if (!item.ProjectOtherDescrption) {
                    errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectOtherReasonDescriptionErr;
                    isvalida = false;
                 
                }
            }
            if (item.ProjectStatus == 5 || item.ProjectStatus == 6 || item.ProjectStatus == 8 || item.ProjectStatus == 4) {
                if (!item.ProjectStatusInitiativeDelayJustificationTxt) {
                    errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectReasonTextErr;
                    isvalida = false;
                 
                }
            }
            let diff1 = new Date() - new Date(item.IntialStartDate);

            if (diff1 > 90) {
                errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.PrjectStatusisIntitaionErr;
                isvalida = false;
             
            }
        }
        if(parseInt(item.InitiativeProjectsTasksBudget)>parseInt(this.state.InitiativeBudget))
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectBudgetError + parseInt(item.InitiativeProjectsTasksBudget) + " " + parseInt(this.state.InitiativeBudget);
            isvalida = false;
        }
       
        this.setState({
            errormessage: errormessage,
            isValid: isvalida,
        });
        return errormessage;
    }
    checkValidationEntity()
    {
       
        let errormessage = '';
        let isvalida=true;
        if(this.state.InitiativeName=='')
        {
            errormessage  =  errormessage+i18n[this.getLocale()].ActionPlansErrorMessage.InitiativesNameErr;
            isvalida=false;
        }
if(this.state.InitiativeBudget=='')
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.InitiativeBudgetErr;
            isvalida=false;
        }
if(this.state.Nameofresponsible=='')
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.NameofresponsibleErr;
            isvalida=false;
        }
if(this.state.Roleofresponsible=='')
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.RoleofresponsibleErr;
            isvalida=false;
        }
if(this.state.InitiativeStatus=='')
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.InitiativeStatusErr;
            isvalida=false;
        }
if(this.state.InitiativeFile=='')
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.SupportDocumentsErr;
            isvalida=false;
        }
if(this.state.ExpectedOutcomes=='')
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.ExpectedOutcomesErr;
            isvalida=false;
        }
        if(this.state.InitiativeStatus==7)
        {
            errormessage  =   errormessage+' \n -  '+i18n[this.getLocale()].ActionPlansErrorMessage.InitiativeStatusisIntitaionErr;
            isvalida=false;  
        }
        let projecterror=false;
     let projectitle='';
        for(let i=0;i<this.state.MembersArray.length;i++)
        {
        
            if(!projecterror){
              
            let item = this.state.MembersArray[i];
        let diff =new Date(item.IntialEndDate)-new Date(item.IntialStartDate);
    
        if (diff<1) {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.DateValidation;
            isvalida = false;
            projecterror=true;
        }
        if(!item.InitiativeProjectsTasksBudget && item.InitiativeProjectsTasksBudget!=0)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectBudgetEmptyErr;  
            isvalida = false;
           
            projecterror=true; 
        }
        if(!item.IntialStartDate)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.StartDateErr;  
            isvalida = false;
            projecterror=true; 
        }
        if(!item.IntialEndDate)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.EndDateErr;  
            isvalida = false;
            projecterror=true; 
        }
        if(!item.Title)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.PrjectTitleErr;  
            isvalida = false;
            projecterror=true; 
        }

        else

        {
            projectitle=item.Title;
        }

        if(!item.Metric)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.MetricErr;  
            isvalida = false;
            projecterror=true; 
        }

        if(!item.ProjectStatus||item.ProjectStatus==0)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectStatusEmptyErr;  
            isvalida = false;
            projecterror=true; 
        }
        debugger;
        if(item.ProjectStatus==9)
        {
        let diff1 =new Date()-new Date(item.IntialStartDate);

        if(diff1>90)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.PrjectStatusisIntitaionErr;  
            isvalida = false;
            projecterror=true; 
        }
    }

    if(item.ProjectStatus==2)
    {
    let diff1 =new Date()-new Date(item.IntialEndDate);

    if(diff1>90)
    {
        errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.PrjectStatusisIntitaionErr;  
        isvalida = false;
        projecterror=true; 
    }
}
if(!item.disableall)
{
if (   item.ProjectStatus == 1   ||    item.ProjectStatus == 5 || item.ProjectStatus == 6 || item.ProjectStatus == 8 || item.ProjectStatus == 4) 
        {
           if(!item.ProjectAttach||!item.ProjectAttachFileObj) 
           {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectAttachErr;
            isvalida = false;
            projecterror=true; 
           }
        }
    }
    
//Project Status Reason DD
if(item.ProjectStatus > 2 && item.ProjectStatus != 9)
{
        if(item.ProjectDelayJustification==''||item.ProjectDelayJustification==0)
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectReasonErr;  
            isvalida = false;
            projecterror=true; 
        }

        if(item.ProjectStatus == 8 || item.ProjectDelayJustification==5)
        {
            if(!item.ProjectOtherDescrption)
            {
                errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectOtherReasonDescriptionErr;  
                isvalida = false;
                projecterror=true; 
            }
        }
        if(item.ProjectStatus == 5 || item.ProjectStatus == 6 || item.ProjectStatus == 8 || item.ProjectStatus == 4)
        {
            if(!item.ProjectStatusInitiativeDelayJustificationTxt)
            {
                errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectReasonTextErr;  
                isvalida = false;
                projecterror=true; 
            }
        }
     
    }
       
       
       

        }
    }
        if(parseInt(this.state.ProjectsBuget)>parseInt(this.state.InitiativeBudget))
        {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].ActionPlansErrorMessage.ProjectBudgetError;
            isvalida = false;
        }
    

// if(isvalida==false)
// {
//     errormessage=projectitle+errormessage;
// }
        this.setState({
            errormessage: errormessage,
            isValid:isvalida,
        });
      return errormessage;
    }


    submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let requesterNameErr = '',
            rquesterEmailErr = '', memberslengtherror = '',
            requesterphoneNumberErr = '', formalrequestletterErr = ''
            ;
    
        debugger;
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        let isvalida= this.checkValidationEntity();
        if (tempEditObj  && isvalida==='') {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                debugger;
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();
    
                let resposeObj = {};
    
                //resposeObj.isEdit = this.state.isEdit;
                //resposeObj.requestId = this.state.requestId;
                // resposeObj.RequestType= this.state.RequestType;
                resposeObj.ID=Config.getActionPlanID();
             
                resposeObj.Nameofresponsible = this.state.Nameofresponsible;
                resposeObj.Roleofresponsible = this.state.Roleofresponsible;
                resposeObj.InitiativeName = this.state.InitiativeName;
                resposeObj.InitiativeBudget = this.state.InitiativeBudget;

               resposeObj.ActualOutcomes = this.state.ActualOutcomes;
               resposeObj.ExpectedOutcomes = this.state.ExpectedOutcomes;
               resposeObj.InitiativesStatus = this.state.InitiativeStatus;
               resposeObj.InitiativeOtherDescrption=this.state.InitiativeOtherDescrption;
               resposeObj.InitiativeDelayJustificationTxt=this.state.InitiativeDelayJustificationTxt;
               resposeObj.InitiativeDelayJustification=this.state.InitiativeDelayJustification;
                //  resposeObj.MembersArray = this.state.MembersArray;
    
    
    
                debugger;
                let payload = saveActionPlansInitiatives(resposeObj);
                let endPoint = 'saveActionPlansInitiatives'
                let url = Config.getUrl(endPoint);
    
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                       if (resAcompanyPatient.Status) {
                        let tempTableData = this.state.MembersArray;
    
                            let payloadForMembersArray = this.SaveMembersArray(resAcompanyPatient.data.id, tempTableData);
                            let endPoint = 'SaveActionPlansProjectTasks'
                            let urlForDisciplinaryMembers = Config.getUrl(endPoint);
    
                            ApiRequest.sendRequestForPost(urlForDisciplinaryMembers, payloadForMembersArray, resForSalaryMatrix => {
                                if (resForSalaryMatrix.Status) {

                                    this.uploadFile(0, 1, resAcompanyPatient, 0);

                                   
    
                                }
                                else {
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].error,
                                            messageAlert: i18n[this.getLocale()].error_message
                                        }, () => {
                                            this.alertSubmit();
                                        })
                                    });
                                }
                                
                            })
                            debugger;
    
    
    
    
                       }
                       else {
                           this.setState({
                               isLoading: false
                           }, () => {
                               this.setState({
                                   titleAlert: i18n[this.getLocale()].error,
                                   messageAlert: i18n[this.getLocale()].error_message
                               }, () => {
                                   this.alertSubmit();
                               })
                           });
                       }
                    }
                    )
                })
            })
        }
        else{
            this.setState({
                titleAlert: i18n[this.getLocale()].error,
                messageAlert: isvalida
            }, () => {
                this.alertSubmit();
            })

        }
    }
    
    callGetActionPlanLookUp = () => {
        let payload = {};
        //payload.Upcoming = 1;
    
        payload.lang = this.state.isArabic ? 1 : 0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        payload.EntityID = getDataFromLocalStorage.EntityID;
        let endPoint = 'GetActionPlanLookUps'
        let url = Config.getUrl(endPoint);
        // this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            // this.toggleLoadingSpinner(false);
            if (res.Status) {
    
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                //alert(resArray);
                this.setState({ MainThemeArray: resArray.MainTheme
                ,MainPracticeArray:resArray.MainPractice
                ,MainPracticeArrayTemp:resArray.MainPractice
                ,InitiativeProjectsTasksStatusArray:resArray.ProjectStatus
                ,InitiativeProjectsJustificationsArray:resArray.Justifications,
                InitiativeStatusArray:resArray.InitiativesStatus,
                InitiativeStatusJustificationArray:resArray.InitiativeStatusJustificationArray
                },()=>{ setTimeout(function(){ console.log('') }, 90000000);});
                
                // if (resArray && resArray.length > 0) {
                //     alert(resArray[0].MainTheme.length);
                //     this.setState({ MainThemeArray: resArray[0].MainTheme });
                   
                // } else {
                //     // send empty array to list page
                //     this.setState({
                //         data: [],
                //         popUpRowArray: [],
                //     }, () => {
                //        // this.updateListData(this.state.data, this.state.popUpRowArray);
                //     })
                // }
            } else {
                // TODO
            }
        })
    }

    FormalrequestletterOnChange = e => {
        let inpFileName = e.target.files[0].name;
        let fileSize = e.target.files[0].size;
        let fileExt = inpFileName.split('.').pop().toUpperCase();
        if (e.target.files.length > 0) {
          
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ formalrequestletter: '', formalrequestletterErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ formalrequestletter: inpFileName, formalrequestletterErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                formalrequestletterFileObj: reader.result
                            }, () => {
                                Config.setBase64ForDisciplinaryboardformalrequestletter(this.state.formalrequestletterFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ formalrequestletterFile: '',formalrequestletterFileErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            RequestType: this.state.RequestType,
            employeeName: this.state.employeeName,
            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
            handleInitiativeBudget: this.state.handleInitiativeBudget,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
           
        });
    };

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ requesterName: event.target.value });
            }
        } else {
            this.setState({ requesterName: '' });
        }
    };

    handleRquesterEmail = (event) => {
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ rquesterEmail: event.target.value, rquesterEmailErr: '' });
            }
            else {
                this.setState({ rquesterEmail: "", rquesterEmailErr: "thisFieldIsRequired" });
            }
        } else {
            this.setState({ rquesterEmail: "", rquesterEmailErr: "thisFieldIsRequired" });
        }
    };

    handleRequesterPhoneNumber = (event) => {
        let requesterphoneNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ requesterphoneNumber: event.target.value }, () => {
                if (!Validator.validateContactNumber(requesterphoneNumber))
                {
                    this.setState({ requesterphoneNumberErr: '' });
                } else {
                    this.setState({ requesterphoneNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ requesterphoneNumber: '', requesterphoneNumberErr: 'thisFieldIsRequired' });
        }
    };

  
 

    goToPrevious = () => {
        this.props.getData.stepThreePrev({
            
        });
    };

    uploadFile = (i, totalCount, resAcompanyPatient, multifileCount) => {
        debugger;
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let isLastFileInMultiUpload = false;
            let obj = {};
    
            if (i === 0) {
                fileObj = Config.getBase64ForInitiativeFile();
                obj.filename = this.state.InitiativeFile;
                obj.filetype = Config.getInitiativeFileId();
                tempBase64 = fileObj;
            }
              
            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 10962;
                if (resAcompanyPatient && resAcompanyPatient.data && resAcompanyPatient.data.id) {
                    reqId = resAcompanyPatient.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resAcompanyPatient.data.id;;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                payload.requestType= Config.getActionPlanId();
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);
    
                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        // if (i === 1) {
                        //     if (isLastFileInMultiUpload) {
                        //         this.uploadFile(i + 1, totalCount, resAcompanyPatient, multifileCount + 1);
                        //     } else {
                        //         this.uploadFile(i, totalCount, resAcompanyPatient, multifileCount + 1);
                        //     }
                        // } else 
                        {
                            this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].ActionPlans.sucess
                            }, () => {
                                this.alertSubmitForSuccess();
                              
                                // this.props.history.replace(pathname, {
                                //     pathname: pathname
                                // });
                            })
                          
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].ActionPlans.sucess
                        }, () => {
                            this.alertSubmitForSuccess();
                          
                            // this.props.history.replace(pathname, {
                            //     pathname: pathname
                            // });
                        })
                      
                    }
                }
            })
        }
    }
   
    componentDidMount = () => {

          this.callGetActionPlanLookUp();
                
let reqid= 0;
let CanEdit =true;
let x = Config.getActionPlanID();;
if(x>reqid)
{

    reqid=x;
    CanEdit=false;
}
        let temp = [];
        let obj = {};
        obj.InitiativeProjectsTasksBudget=0;
         obj.collapse=true; 
        obj.CanEdit=CanEdit;
        obj.AllDisabled=false;
        temp.push(obj);
        this.setState({MembersArray:temp});
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

this.setState({entityArray:tempEntityArray,MembernameErr:'1',
jobdegreeErr:'1',
EntityErr:'1',
MembertitleErr:'1'});



let payload = {};
payload.lang = this.state.isArabic ? 1 : 0;
let getDataFromLocalStorage = Config.getLocalStorageLoginData();

payload.EntityID = getDataFromLocalStorage.EntityID;
payload.id=reqid;
let endPoint = 'GetActionPlanById';
let url = Config.getUrl(endPoint);

ApiRequest.sendRequestForPost(url, payload, res => {
    // this.toggleLoadingSpinner(false);
   debugger;
    if (res.Status) {
       
        let mainArray = {};
        let actualRowArray = [];
        let resArray = res.data;
        let intialdetail = resArray.IntivateDetailsOutput;
      
   
       let tempArray = [];
        let tempobj ={};
        let Projects = resArray.ProjectsOutput;
 if(Projects.length>0)
    {
        for (let i = 0; i < Projects.length; i++) {
            tempobj={};
            let item = Projects[i];
            
            let disableall=false;
            if(item.ProjectsTasksStatusID==1 || item.ProjectsTasksStatusID==5)
            {
                disableall=true;
            }
            tempobj.disableall=disableall;
            tempobj.CanEdit=CanEdit;
            tempobj.Title=item.Title;
           // tempobj.InitiativeID=id;
           tempobj.ProjectID=item.ProjectID;
           debugger;
          
          if(item.ActualStartTime!=null)
          {
            
            tempobj.IntialStartDate=item.DTActualStartTime;
          }
          else
          {
            tempobj.IntialStartDate=item.DTIntialStartDate;
          }
          if(item.ActualCloseTime!=null)
          {
            tempobj.IntialEndDate=item.DTActualCloseTime;
          }
          else
          {
          tempobj.IntialEndDate=item.DTIntialEndDate;
          }
         

            tempobj.Metric=item.Metric;
            tempobj.ActualCloseTime=item.ActualCloseTime;
            tempobj.InitiativeProjectsTasksBudget=item.InitiativeProjectsTasksBudget;
            // tempobj.ExpectedOutcomes=item.ExpectedOutcomes;
            // tempobj.ActualOutcomes=item.ActualOutcomes;
            tempobj.ProjectStatus=item.ProjectsTasksStatusID;
            tempobj.ProjectStatus=item.ProjectsTasksStatusID;
            tempobj.ProjectOtherDescrption=item.ProjectOtherDescrption;
            tempobj.ProjectStatusInitiativeDelayJustificationTxt=item.ProjectStatusInitiativeDelayJustificationTxt;
            tempobj.ProjectDelayJustification=item.ProjectDelayJustification;
           
            tempobj.collapse=false;
            let tempmainprac = []
           let tempMainPracticeArray=[];
           if(this.state.MainPracticeArrayTemp.length>0)
        {
            tempMainPracticeArray=this.state.MainPracticeArrayTemp;
        }
        else
        {
            
            
            
            tempMainPracticeArray=Config.GetMainPracticeArray();
          
            
        }
            for(let i=0;i<tempMainPracticeArray.length;i++)
         {
         let tempitem = tempMainPracticeArray[i];
         
         if(tempitem.MainThemeID==item.MainThemeID)
         {
             tempmainprac.push(tempitem);
         }
         }
         tempobj.MainPracticeArray=tempmainprac;
            tempobj.MainPractice=item.MainPracticeID;
            tempobj.MainTheme=item.MainThemeID;
            tempArray.push(tempobj);
        }

    }
    else
    {
       tempobj.InitiativeProjectsTasksBudget=0;
       
        tempobj.CanEdit=CanEdit;
        tempArray.push(tempobj);
    }

        this.setState({
            CanEdit:CanEdit,
         MembersArray:tempArray,
            InitiativeName:intialdetail.Title,
            InitiativeBudget:intialdetail.Budget,
            Nameofresponsible:intialdetail.Nameofresponsible,
            Roleofresponsible:intialdetail.RoleofResponsible,
            ExpectedOutcomes:intialdetail.ExpectedOutcomes,
            InitiativeStatus:intialdetail.InitiativesStatusID,
        }, () => {
            
            setTimeout(() => {  console.log("1"); }, 10000);

            if (editFlag) {
                this.setState({
                    
                    employeeName:editObj.employeename,
                    EmployeeInfoEmployeeNumber:editObj.employeenumber,
                    EmployeeInfoJobTitle:editObj.jobtitle,
                    EmployeeInfoJobDegree:editObj.grade,
                    EmployeeInfoEmirateID:editObj.emiratesid,
                    EmployeeInfoEmployeeUID:editObj.unifiednumber,
                    EmployeeInfoBasicSalary:editObj.basicsalary,
                    EmployeeInfoTotalSalary:editObj.totalsalary,
    EmployeeInfoRetirementcontributions: editObj.retirementcontribution,
    RequestType:editObj.requesttype,
    requestId:editObj.requestId
                });
            }
            else
            {
                let loginData = Config.getLocalStorageLoginData();
                let tempEntityName = this.props.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
                let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
                this.setState({requesterentityname:tempEntityName,
                    requesterName:tempEmployeeName,
                    requesterphoneNumber:loginData.ContactNumber,
                    rquesterEmail:loginData.Email,});
    
            }
        });
        //alert(resArray);
        // this.setState({ data:resArray,
        //     IntivateDetailsOutput:,
        //     ProjectsOutput:resArray.ProjectsOutput,
        //     IntivateFocalPointOutput:

        // }
        // );
        
        // if (resArray && resArray.length > 0) {
        //     alert(resArray[0].MainTheme.length);
        //     this.setState({ MainThemeArray: resArray[0].MainTheme });
           
        // } else {
        //     // send empty array to list page
        //     this.setState({
        //         data: [],
        //         popUpRowArray: [],
        //     }, () => {
        //        // this.updateListData(this.state.data, this.state.popUpRowArray);
        //     })
        // }
    } else {
        // TODO
    }
})
    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    handleEmployeeInfoEmirateID = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ EmployeeInfoEmirateID: event.target.value }, () => {
                if (!Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ EmployeeInfoEmirateIDErr: '' });
                } else {
                    this.setState({ EmployeeInfoEmirateIDErr: 'invalidEmiratesId' });
                }
            });
        }
        else {
            this.setState({ EmployeeInfoEmirateID: '', EmployeeInfoEmirateIDErr: 'thisFieldisRequired' });
        }
    }


    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    
    addnewmember = () => { 
        
        let isvalida = this.checkValidationAddNewMember();
     
        if(isvalida==='')
        {
        let obj = {};
        obj.collapse=true;
        obj.CanEdit=true;
        obj.WorkShopDate='';
        obj.WorkShopfrom='';
        obj.WorkShopto='';

        if (this.state.MembersArray.length < 40) {
            this.setState({
                MembersArray: this.state.MembersArray.concat(obj),
                view: this.state.view + 1,MembernameErr:'1',
                jobdegreeErr:'1',
                EntityErr:'1',
                MembertitleErr:'1',
                MemberJobTitleErr:'1'
            });
        }
    }
    else

    {
        this.setState({
            titleAlert: i18n[this.getLocale()].error,
            messageAlert: isvalida
        }, () => {
            this.alertSubmit();
        })
       
    }

    }

    removemember = (indexVal) => {
        var array = this.state.MembersArray;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        MembersArray: array
                    });

                    //ReCalculate total no of days & total salary to be payed
                 
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            MembersArray: array
                        });
                     
                    }
                }
            }
        }

    }

  
    
    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };

    handleChangeForEntity = (event,index) => {
        debugger;
        let entityname = event.target.value;
        let errcode='',EntityErr='';
        event.target.parentNode.className += " was-validated";
        if(entityname=="")
        {
            errcode='thisFieldIsRequired';
            EntityErr='1';
        }
        if(this.state.memberTitleId==1 || this.state.memberTitleId=='1')
        {
            let requesterEntityID = Config.getLocalStorageLoginData().EntityID;
        if(event.target.value==requesterEntityID)
        {
            EntityErr='2'; 
        }
        }
        let tempobj = this.state.MembersArray;
        tempobj[index].entity=entityname;
        tempobj[index].entityErr=errcode;
        this.setState({MembersArray:tempobj,EntityErr:EntityErr});
    
    
    };

  

    handleMemberName= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].membername=event.target.value;
        tempobj[index].membernameErr='';
        
            this.setState({
                MembersArray:tempobj,
                MembernameErr:'',
            });
            }
        } else {
            
        
            tempobj[index].membername=event.target.value;
            tempobj[index].membernameErr='thisFieldIsRequired';
             this.setState({ MembernameErr: '1',MembersArray:tempobj });
        }
    }

    handleMemberMemberJobTitle= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].MemberJobTitle=event.target.value;
        tempobj[index].MemberJobTitleErr='';
        
            this.setState({
                MembersArray:tempobj,
                MemberJobTitleErr:'',
            });
            }
        } else {
            
        
            tempobj[index].MemberJobTitle=event.target.value;
            tempobj[index].MemberJobTitleErr='thisFieldIsRequired';
             this.setState({ MemberJobTitleErr: '1',MembersArray:tempobj });
        }
    }

    handleInitiativeName = (event) => {
        if (event.target.value.trim() != '') {
            // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            // if (regex.test(event.target.value)) 
            {
                this.setState({ InitiativeName: event.target.value });
            }
        }
        //  else {
        //     this.setState({ InitiativeName: '' });
        // }
    }

    // handleInitiativeBudget = (event) => {
    //     if (event.target.value.trim() != '') {
    //     //    let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
    //         // if (regex.test(event.target.value)) 
    //         {
    //             this.setState({ InitiativeBudget: event.target.value });
    //         }
    //     } 
    //     // else {
    //     //     this.setState({ InitiativeBudget: '' });
    //     // }
    // }

    handleInitiativeOtherDescrption = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ InitiativeOtherDescrption: event.target.value });
            }
        } else {
            this.setState({ InitiativeOtherDescrption: '' });
        }
    }

    handleRoleofresponsible = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ Roleofresponsible: event.target.value });
            }
        } else {
            this.setState({ Roleofresponsible: '' });
        }
    }


    handleNameofresponsible = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ Nameofresponsible: event.target.value });
            }
        } else {
            this.setState({ Nameofresponsible: '' });
        }
    }
   /* Member Title* */
   handleMainTheme = (event,index) => {
    let dropDownLabel = '';
    let entityname = event.target.value;
    let tempobj = this.state.MembersArray;
    tempobj[index].MainTheme = entityname;
    let MainthemeDef='';
    

    this.setState({ MainTheme: event.target.value }, () => {
     
        if (this.state.MainThemeArray.length > 0) {
            for (let index = 0; index < this.state.MainThemeArray.length; index++) {
                
                
                if (parseInt(entityname) === this.state.MainThemeArray[index].ID) {
                    dropDownLabel = this.state.isArabic ? this.state.MainThemeArray[index].ArabicTitle : this.state.MainThemeArray[index].EnglishTitle;
                    MainthemeDef= this.state.isArabic ? this.state.MainThemeArray[index].ArabicMeaning : this.state.MainThemeArray[index].EnglishMeaning;
                }
            }
        }

        let tempmainprac = []
        let ithemeid= parseInt(this.state.MainTheme);
        for(let i=0;i<this.state.MainPracticeArrayTemp.length;i++)
{
let tempitem = this.state.MainPracticeArrayTemp[i];

if(tempitem.MainThemeID==this.state.MainTheme)
{
    tempmainprac.push(tempitem);
}
}
tempobj[index].MainPracticeArray=tempmainprac;

        this.setState({
            MainThemeLabel: dropDownLabel,
            MainPracticeArray:tempmainprac,
            MembersArray: tempobj,
            MainthemeDef:MainthemeDef
        });
    })
}


handleTitle = (event, index) => {
    debugger;
    let entityname = event.target.value;
    let errcode = '', TitleErr = '';
    
    
    event.target.parentNode.className += " was-validated";
    if (entityname == "") {
        errcode = 'thisFieldIsRequired';
        TitleErr = '1';
    }
   
    let tempobj = this.state.MembersArray;
    tempobj[index].Title = entityname;
    tempobj[index].TitleErr = errcode;
    this.setState({ MembersArray: tempobj, TitleErr: TitleErr });


}

handleProjectOtherDescrption = (event, index) => {
    debugger;
    let entityname = event.target.value;
    let errcode = '', TitleErr = '';
    
    
    event.target.parentNode.className += " was-validated";
    if (entityname == "") {
        errcode = 'thisFieldIsRequired';
        TitleErr = '1';
    }
   
    let tempobj = this.state.MembersArray;
    tempobj[index].ProjectOtherDescrption = entityname;
    tempobj[index].ProjectOtherDescrptionErr = errcode;
    this.setState({ MembersArray: tempobj, ProjectOtherDescrptionErr: TitleErr });


}

handleInitiativeProjectBudget = (value, index) => {
    debugger;
    let entityname =value.formattedValue;
    let errcode = '', TitleErr = '';
    let totalBudget = parseInt(this.state.ProjectsBuget);
    totalBudget=totalBudget+parseInt(entityname);
    //event.target.parentNode.className += " was-validated";
    if (!entityname) {
        errcode = 'thisFieldIsRequired';
        TitleErr = '1';
    }
   
    let tempobj = this.state.MembersArray;
    tempobj[index].InitiativeProjectsTasksBudget  = entityname;
    tempobj[index].InitiativeProjectsTasksBudgetErr = errcode;
    this.setState({
        ProjectsBuget:totalBudget, MembersArray: tempobj, InitiativeProjectsTasksBudgetErr: TitleErr });


}


handleIntialStartDate = (date, index) => {
    
    if(date!='' && date !='01/01/2000'  && date!=null)
    {
        try
        {
            
    let tempobj = this.state.MembersArray;
    tempobj[index].strDate=date.toString();
    tempobj[index].IntialStartDate=date;
    
        this.setState({
       MembersArray:tempobj
        });
    }
    catch{}
    }
    else
    {
        let tempobj = this.state.MembersArray;
        tempobj[index].strDate='';
        tempobj[index].IntialStartDate='';
        this.setState({
            MembersArray:tempobj
             });
    }


}


handleIntialEndDate = (date, index) => {

    if(date!='' && date !='01/01/2000'  && date!=null)
    {
    
        try
        {

    let tempobj = this.state.MembersArray;
    
    tempobj[index].IntialEndDate=date;
    
        this.setState({
       MembersArray:tempobj
        });
   
    }
    catch{}
    }
    else
    {
        let tempobj = this.state.MembersArray;
        
        tempobj[index].IntialEndDate='';
        this.setState({
            MembersArray:tempobj
             });
    }
   

}



handleMetric = (evt) => {
    var newContent = evt.editor.getData();
    let newContent1 = striptags(newContent);

    if (newContent1.length <= Config.getMaxCKEditorLimit()) {
        let index = this.state.currentindex;
      
        let tempobj = this.state.MembersArray;
        tempobj[index].Metric = newContent;
        //tempobj[index].MetricErr = errcode;
        this.setState({ MembersArray: tempobj
        
         },()=>
        {})
        ;

        this.setState({
            MetricErr: "",
            Metric: newContent,

        });
    } else {
        this.setState({
            MetricErr: "ckeditorCountErr"
        });
    }
}

handleExpectedOutcomes = (evt) => {
    var newContent = evt.editor.getData();
    let newContent1 = striptags(newContent);

    if (newContent1.length <= Config.getMaxCKEditorLimit()) {
        // let index = this.state.MembersArray.length - 1;
        // let tempobj = this.state.MembersArray;
        // tempobj[index].ExpectedOutcomes = newContent;
        //tempobj[index].MetricErr = errcode;
        // this.setState({ MembersArray: tempobj
        //      , ExpectedOutcomes: newContent
        //  });

        this.setState({
            ExpectedOutcomesErr: "",
            ExpectedOutcomes: newContent
        });
    } else {
        this.setState({
            ExpectedOutcomesErr: "ckeditorCountErr"
        });
    }
}

handlecancle = () => {
    let pathname = 'ActionPlansListinternal';
    this.props.history.replace(pathname, {
        pathname: pathname
    })
}

alertSubmitForSuccess = () => {
    let pathname = 'ActionPlansListinternal';
    confirmAlert({
        title: this.state.titleAlert,
        message: this.state.messageAlert,
        buttons: [
            {
                label: i18n[this.getLocale()].ok,
                onClick: () => this.props.history.replace(pathname, {
                    pathname: pathname
                })
            }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
    });
    setTimeout(() => {
        document.getElementById('alertOkButton').focus()
    }, 100);
};

handleActualOutcomes = (evt) => {
    var newContent = evt.editor.getData();
    let newContent1 = striptags(newContent);

    if (newContent1.length <= Config.getMaxCKEditorLimit()) {
        // let index = this.state.MembersArray.length - 1;
        // let tempobj = this.state.MembersArray;
        // tempobj[index].ActualOutcomes = newContent;
        // //tempobj[index].MetricErr = errcode;
        // this.setState({ MembersArray: tempobj
        //     // , IntialEndDateErr: IntialEndDateErr 
        // });
        this.setState({
            ActualOutcomesErr: "",
            ActualOutcomes: newContent
        });
    } else {
        this.setState({
            ExpectedOutcomesErr: "ckeditorCountErr"
        });
    }
}

handleInitiativeDelayJustificationTxt = (evt) => {
    var newContent = evt.editor.getData();
    let newContent1 = striptags(newContent);

    if (newContent1.length <= Config.getMaxCKEditorLimit()) {
        // let index = this.state.MembersArray.length - 1;
        // let tempobj = this.state.MembersArray;
        // tempobj[index].ActualOutcomes = newContent;
        // //tempobj[index].MetricErr = errcode;
        // this.setState({ MembersArray: tempobj
        //     // , IntialEndDateErr: IntialEndDateErr 
        // });
        this.setState({
            InitiativeDelayJustificationTxtErr: "",
            InitiativeDelayJustificationTxt: newContent
        });
    } else {
        this.setState({
            ExpectedOutcomesErr: "ckeditorCountErr"
        });
    }
}

handleProjectStatusInitiativeDelayJustificationTxt = (evt) => {
    var newContent = evt.editor.getData();
    let newContent1 = striptags(newContent);

    if (newContent1.length <= Config.getMaxCKEditorLimit()) {
        let index = this.state.currentindex;
        let tempobj = this.state.MembersArray;
        tempobj[index].ProjectStatusInitiativeDelayJustificationTxt = newContent;
        //tempobj[index].MetricErr = errcode;
        this.setState({ MembersArray: tempobj
        
         },()=>
        {})
        ;

        this.setState({
            MetricErr: "",
            Metric: newContent,

        });
    } else {
        this.setState({
            MetricErr: "ckeditorCountErr"
        });
    }
}



handleInitiativeProjectsTasksStatus = (event,index) => {
    let dropDownLabel = '';
    let entityname = event.target.value;
    let tempobj = this.state.MembersArray;
    tempobj[index].ProjectStatus = entityname;
    if(entityname==8)
    {
    tempobj[index].ProjectDelayJustification=5;
    tempobj[index].disablejust=true;
    }
    else{
        tempobj[index].ProjectDelayJustification=0;
        tempobj[index].disablejust=false;
    }
   // tempobj[index].entityErr = errcode;
    this.setState({ MembersArray: tempobj,
        //  EntityErr: EntityErr,
         InitiativeProjectsTasksStatus:entityname }, () => {
     
       
       if (this.state.InitiativeProjectsTasksStatusArray.length > 0) {
            for (let index = 0; index < this.state.InitiativeProjectsTasksStatusArray.length; index++) {
                if (parseInt(this.state.MainPractice) === this.state.InitiativeProjectsTasksStatusArray[index].id) {
                    dropDownLabel = this.state.isArabic ? this.state.InitiativeProjectsTasksStatusArray[index].ArabicTitle : this.state.InitiativeProjectsTasksStatusArray[index].EnglishTitle;
                }
            }
        }


        this.setState({
            InitiativeProjectsTasksStatusLabel: dropDownLabel,
           
        });
    })
}

handleProjectDelayJustification = (event,index) => {
    let dropDownLabel = '';
    let entityname = event.target.value;
    let tempobj = this.state.MembersArray;
    tempobj[index].ProjectDelayJustification = entityname;
   // tempobj[index].entityErr = errcode;
    this.setState({ MembersArray: tempobj,
        //  EntityErr: EntityErr,
         InitiativeProjectsTasksStatus:entityname }, () => {
     
       
       if (this.state.InitiativeProjectsTasksStatusArray.length > 0) {
            for (let index = 0; index < this.state.InitiativeProjectsTasksStatusArray.length; index++) {
                if (parseInt(this.state.MainPractice) === this.state.InitiativeProjectsTasksStatusArray[index].id) {
                    dropDownLabel = this.state.isArabic ? this.state.InitiativeProjectsTasksStatusArray[index].ArabicTitle : this.state.InitiativeProjectsTasksStatusArray[index].EnglishTitle;
                }
            }
        }


        this.setState({
            InitiativeProjectsTasksStatusLabel: dropDownLabel,
           
        });
    })
}

handleInitiativesStatus = (event) => {
    let dropDownLabel = '';
    let entityname = event.target.value;
    // let tempobj = this.state.MembersArray;
    // tempobj[index].InitiativeStatus = entityname;
   // tempobj[index].entityErr = errcode;
   let InitiativeDelayJustification=0;
   let disablejustddl=false;
   if(entityname==8)
   {
    InitiativeDelayJustification=5;
    disablejustddl=true;
   }
    this.setState({ 
        //  EntityErr: EntityErr,
        InitiativeStatus:entityname,
        InitiativeDelayJustification:InitiativeDelayJustification,disablejustddl:disablejustddl }, () => {
     
       
       if (this.state.InitiativeStatusArray.length > 0) {
            for (let index = 0; index < this.state.InitiativeStatusArray.length; index++) {
                if (parseInt(this.state.MainPractice) === this.state.InitiativeStatusArray[index].id) {
                    dropDownLabel = this.state.isArabic ? this.state.InitiativeStatusArray[index].ArabicTitle : this.state.InitiativeStatusArray[index].EnglishTitle;
                }
            }
        }


        this.setState({
            InitiativeStatusLabel: dropDownLabel,
           
        });
    })
}

handleInitiativeDelayJustification = (event) => {
    let dropDownLabel = '';
    let entityname = event.target.value;
    // let tempobj = this.state.MembersArray;
    // tempobj[index].InitiativeStatus = entityname;
   // tempobj[index].entityErr = errcode;
    this.setState({ 
        //  EntityErr: EntityErr,
        InitiativeDelayJustification:entityname }, () => {
     
       
    //    if (this.state.InitiativeStatusArray.length > 0) {
    //         for (let index = 0; index < this.state.InitiativeStatusArray.length; index++) {
    //             if (parseInt(this.state.MainPractice) === this.state.InitiativeStatusArray[index].id) {
    //                 dropDownLabel = this.state.isArabic ? this.state.InitiativeStatusArray[index].ArabicTitle : this.state.InitiativeStatusArray[index].EnglishTitle;
    //             }
    //         }
    //     }


    //     this.setState({
    //         InitiativeStatusLabel: dropDownLabel,
           
    //     });
    })
}

handleMainPractice = (event,index) => {
    let dropDownLabel = '';
    let entityname = event.target.value;
    let tempobj = this.state.MembersArray;
    tempobj[index].MainPractice = entityname;
    let MainPracticeDef='';
    

    this.setState({ MainPractice: event.target.value,MembersArray: tempobj }, () => {
     
        if (this.state.MainPracticeArray.length > 0) {
            for (let index = 0; index < this.state.MainPracticeArray.length; index++) {
                if (parseInt(this.state.MainPractice) === this.state.MainPracticeArray[index].ID) {
                    dropDownLabel = this.state.isArabic ? this.state.MainPracticeArray[index].ArabicTitle : this.state.MainPracticeArray[index].EnglishTitle;
                    MainPracticeDef= this.state.isArabic ? this.state.MainPracticeArray[index].ArabicMeaning : this.state.MainPracticeArray[index].EnglishMeaning;
                }
            }
        }

        this.setState({
            MainPracticeDef:MainPracticeDef,
            MainPracticeLabel: dropDownLabel
        });
    })
}
   /** */
   handleInitiativeBudget= (value) => {
    if (value) {
        this.setState({ InitiativeBudget: value.formattedValue, InitiativeBudgetErr: "" });
    }
};
    handleMemberJobDegree= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].jobdegree=event.target.value;
        tempobj[index].jobdegreeErr='';
        
            this.setState({
                MembersArray:tempobj,
                jobdegreeErr:'',
            });
            }
        } else {
            
        
            tempobj[index].jobdegree=event.target.value;
            tempobj[index].jobdegreeErr='thisFieldIsRequired';
             this.setState({ jobdegreeErr: '1',MembersArray:tempobj });
        }
            }

    render() {
      
        let element = this.state.MembersArray.map((item, index) => {
         debugger;
            return (<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                
                {item.collapse ?
                    <div>
                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].ActionPlans.InitiativeKeyProjectsTasksTitle}<span className="requireStar">*</span></label>
                           
                                    <input
                                        type="text" className="form-control"
        
                                        disabled={item.disableall==true?true:item.CanEdit==true?false:true}
                                        value={item.Title}
                                        onChange={(value) => { this.handleTitle(value, index) }}
        
                                        style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                    />
        
                                    {
                                        item.membernameErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}
                                </div>
                            </MDBCol>
        
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeProjectsTasksBudget}<span className="requireStar">*</span></label>
                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.InitiativeProjectsTasksBudgetErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>    <CurrencyFormat
               disabled={item.disableall==true?true:false}
                                        thousandSeparator={false}
                                        value={item.InitiativeProjectsTasksBudget}
                                        onValueChange={(value) => { this.handleInitiativeProjectBudget(value, index) }}
                                        display={"text"}
                                        maxLength={10}
                                        style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                    />
                                    </div>
                                    {
                                        this.state.requesterentitynameErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}
                                </div>
                            </MDBCol>
        
        
                        </MDBRow>
        
                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.MainTheme}<span className="requireStar">*</span>  {item.MainTheme > 0 ? <span className="infoIcon" onClick={this.showDefneationsMainTheme}></span> : null}
                                    </label>
        
                                    <select
                                 disabled={item.disableall==true?true:item.CanEdit==true?false:true}
                                        style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                        id="sources-funding"
                                        onChange={(value) => { this.handleMainTheme(value, index) }}
                                        value={item.MainTheme}
                                        required>
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
        
                                            this.state.MainThemeArray ? this.state.MainThemeArray.map((item, index) => {
                                                return <option value={item.ID}>{this.state.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                            }) : null
                                        }
                                    </select>
        
        
        
                                </div>
                            </MDBCol>
        
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.MainPractice}<span className="requireStar">*</span>
                                        {item.MainPractice > 0 ?
                                            <span className="infoIcon" onClick={this.showDefneationsMainPractice}></span>
                                            : null}
                                    </label> 
                                    <select
                                               disabled={item.disableall==true?true:item.CanEdit==true?false:true}
                                        style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                        id="sources-funding"
                                        onChange={(value) => { this.handleMainPractice(value, index) }}
                                        value={item.MainPractice}
                                        required>
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
        
                                            item.MainPracticeArray ? item.MainPracticeArray.map((item, index) => {
                                                return <option value={item.ID}>{this.state.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                            }) : null
                                        }
                                    </select>
        
        
        
                                </div>
                            </MDBCol>
        
        
                        </MDBRow>
        
                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeStartDate}<span className="requireStar">*</span></label>
                                    <DatePicker
                                           disabled={item.disableall==true?true:false}
                                        className="form-control"
                                        todayButton={"Today"}
                                        selected={item.IntialStartDate}
                                        minDate="12/01/2019"
                                        onBlur={date => (item.IntialStartDate)}
                                        onChange={(date) => this.handleIntialStartDate(date, index)}
                                        // ref={el => this.onDatepickerRef1(el)}
                                        customInput={<input />}
                                        dateFormat="MM/dd/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                    //showTimeInput
                                    //required
        
                                    />
                                    {/* <input
                                        type="text"   className="form-control"
                                         
                                            value={item.IntialStartDate}
                                        onChange={(value) => { this.handleIntialStartDate(value,index) }}
                                           
                                            style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                        /> */}
        
                                    {
                                        item.membernameErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}
                                </div>
                            </MDBCol>
        
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeEndDate}<span className="requireStar">*</span></label>
                                    <DatePicker
                                        className="form-control"
                                        todayButton={"Today"}
                                        selected={item.IntialEndDate}
                                        minDate="12/01/2019"
                                        onBlur={date => (item.IntialEndDate)}
                                        onChange={(date) => this.handleIntialEndDate(date, index)}
                                        // ref={el => this.onDatepickerRef1(el)}
                                        customInput={<input />}
                                        dateFormat="MM/dd/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        disabled={item.disableall==true?true:false}
                                    //showTimeInput
                                    //required
        
                                    />
                                    {/* <input
                                        type="text"   className="form-control"
                                         
                                            value={item.IntialEndDate}
                                        onChange={(value) => { this.handleIntialEndDate(value,index) }}
                                           
                                            style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                        /> */}
        
                                    {
                                        item.membernameErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}
                                </div>
                            </MDBCol>
        
        
                        </MDBRow>
        
                        <MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <div className="form-group">
                                    <label style={{ display: 'flex', flexDirection: 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                        {i18n[this.getLocale()].ActionPlans.Metric}
                                        <span className="requireStar">*</span>
                                        <span className="infoIcon" onClick={this.showMertricDesctiption}></span>
                                    </label>
        
                                    <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                        {
                                            this.state.isArabic ?
                                                null
                                                :
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={item.Metric}
                                                    events={{
                                                        "change": this.handleMetric
                                                    }}
                                                    config={{ language: "en", height: '10vh',readOnly:item.disableall==true?true:false }}
                                                />
                                        }
        
                                        {
                                            this.state.isArabic ?
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={item.Metric}
                                                    events={{
                                                        "change": this.handleMetric
                                                    }}
                                                    config={{ language: "ar", height: '10vh',readOnly:item.disableall==true?true:false }}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                    <p style={{ display: "flex", flexDirection: this.state.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                        {i18n[this.getLocale()].allowCkEditorLimit}
                                    </p>
                                    {
                                        this.state.reqDetailsErr === 'thisFieldisRequired' ?
                                            <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.reqDetailsErr === 'ckeditorCountErr' ?
                                            <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].ckeditorCountErr}
                                            </div>
                                            :
                                            null
                                    }
        
        
                                </div>
                            </MDBCol>
                        </MDBRow>
        
        
        
                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeProjectsTasksStatus}<span className="requireStar">*</span></label>
                                    <select
                                        style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                        id="sources-funding"
                                        disabled={item.disableall==true?true:false}
                                        onChange={(value) => { this.handleInitiativeProjectsTasksStatus(value, index) }}
                                        value={item.ProjectStatus}
                                    >
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
        
                                            this.state.InitiativeProjectsTasksStatusArray ? this.state.InitiativeProjectsTasksStatusArray.map((item, index) => {
                                                return <option value={item.ID}>{this.state.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                            }) : null
                                        }
                                    </select>
                                    {/* <input
                                        type="text"
                                        value={item.title}   className="form-control"
                                            onChange={(value) => { this.handleMemberTitle(value,index) }}
                                          
                                          
                                            style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                        /> */}
        
        
                                </div>
                            </MDBCol>
        
                            {
                                item.ProjectStatus > 2 && item.ProjectStatus != 9 ?
                                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeDelayJustification + this.state.InitiativeStatusLabel}<span className="requireStar">*</span></label>
                                            <select
                                                disabled={item.disableall==true?true:item.disablejust==true?true:false}
                                                style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                                id="sources-funding"
                                                onChange={(value) => { this.handleProjectDelayJustification(value, index) }}
                                                value={item.ProjectDelayJustification}
                                            >
                                                <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                                {
        
                                                    this.state.InitiativeStatusJustificationArray ? this.state.InitiativeStatusJustificationArray.map((item, index) => {
                                                        return <option value={item.ID}>{this.state.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                                    }) : null
                                                }
                                            </select>
        
        
        
                                        </div>
                                    </MDBCol>
                                    : null
        
                            }
        
        
                        </MDBRow>
        
                        {item.ProjectStatus == 8 || item.ProjectDelayJustification==5?
                            <MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
                                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                        <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">
                                            {i18n[this.getLocale()].ActionPlans.OtherDescrption}
                                            <span className="requireStar">*</span>
                                        </label>
                                        <input
                                            value={item.ProjectOtherDescrption}
                                            disabled={item.disableall==true?true:false}
                                            onChange={(value) => { this.handleProjectOtherDescrption(value, index) }}
                                            type="text"
                                            id="requesterEntity"
                                            className="form-control"
                                            name="requesterEntity"
        
                                            required
                                            //maxlength="50"
                                            style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                        />
                                        {
                                            this.state.OtherDescrptionErr == 'thisFieldIsRequired' ?
                                                <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                    {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                </div>
                                                : ''}
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            : null}
        
                        {
                            item.ProjectStatus == 5 || item.ProjectStatus == 6 || item.ProjectStatus == 8 || item.ProjectStatus == 4 ?
                                <MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
                                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div className="form-group">
                                            <label style={{ display: 'flex', flexDirection: 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                                {i18n[this.getLocale()].ActionPlans.InitiativeDelayJustificationTxt}
                                                <span className="requireStar">*</span>
                                            </label>
        
                                            <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                                {
                                                    this.state.isArabic ?
                                                        null
                                                        :
                                                        <CKEditor
                                                        
                                                            activeClass="p10"
                                                            content={item.ProjectStatusInitiativeDelayJustificationTxt}
                                                            events={{
                                                                "change": this.handleProjectStatusInitiativeDelayJustificationTxt
                                                            }}
                                                            config={{ language: "en", height: '10vh',readOnly:item.disableall==true?true:false }}
                                                        />
                                                }
        
                                                {
                                                    this.state.isArabic ?
                                                        <CKEditor
                                                            activeClass="p10"
                                                            content={item.ProjectStatusInitiativeDelayJustificationTxt}
                                                            events={{
                                                                "change": this.handleProjectStatusInitiativeDelayJustificationTxt
                                                            }}
                                                            config={{ language: "ar", height: '10vh',readOnly:item.disableall==true?true:false }}
                                                        />
                                                        :
                                                        null
                                                }
                                            </div>
                                            <p style={{ display: "flex", flexDirection: this.state.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                                {i18n[this.getLocale()].allowCkEditorLimit}
                                            </p>
                                            {
                                                this.state.reqDetailsErr === 'thisFieldisRequired' ?
                                                    <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.reqDetailsErr === 'ckeditorCountErr' ?
                                                    <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.getLocale()].ckeditorCountErr}
                                                    </div>
                                                    :
                                                    null
                                            }
        
        
                                        </div>
                                    </MDBCol>
                                </MDBRow> : null
                        }
        
                        {
                      item.ProjectStatus == 1   ||    item.ProjectStatus == 5 || item.ProjectStatus == 6 || item.ProjectStatus == 8 || item.ProjectStatus == 4 ?
                                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                        <div class={this.state.isArabic ? "form-group txtAr" : "form-group txtEn"} >
        
                                            <label
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: 'row'
                                                }}
                                                htmlFor="formGroupExampleInput">
                                                {i18n[this.getLocale()].ActionPlans.InitiativeSupportDocuments}
        
                                                <span className="requireStar">*</span>
                                            </label>
        
                                            <div className="input-group">
                                                <div className="custom-file-inp">
                                                    <input
                                                    disabled={item.disableall==true?true:false}
                                                    // multiple
                                                    
                                                        id="ProjectAttach"
                                                        type="file"
                                                        name="ProjectAttach"
                                                        onChange={event => this.ProjectAttachOnChange(event,index)}
                                                    />
                                                    <label
                                                        htmlFor="ProjectAttach"
                                                        className={this.state.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                                    >
                                                        {i18n[this.state.isArabic ? "ar" : "en"].choose_file}
                                                    </label>
                                                    <label htmlFor="ProjectAttach" className="fileName" style={{ textAlign: this.state.isArabic ? "right" : '' }}>
                                                        <span>{item.ProjectAttach}</span>
                                                    </label>
                                                </div>
                                                {
                                                    item.ProjectAttachErr === 'thisFieldIsRequired' ?
                                                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.ProjectAttachErr === 'invalidFileType' ?
                                                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                            {i18n[this.getLocale()].invalidFileType}
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.ProjectAttachErr === 'invalidFileSize' ?
                                                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                                            {i18n[this.getLocale()].invalidFileSizeWorkshop}
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <p style={{ color: '#000', display: "flex", flexDirection: !this.state.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                                    {i18n[this.state.isArabic ? "ar" : "en"].acceptedFormatsAllWorkshop}
                                                </p>
                                                {
                                                    (item.ProjectAttachArray &&
                                                        item.ProjectAttachArray.length > 0)
                                                        ? <DownloadComponent filesObj={item.ProjectAttachArray} />
                                                        : <span></span>
                                                }
                                            </div>
                                        </div>
                                    </MDBCol>
        
                                </MDBRow>
                                : null
                        }
        
                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" >
                                {item.collapse ?
                                    <MDBBtn onClick={() => this.CloseCollapse(index)} class="btn btn-primary Ripple-parent submitBtn CloseCollapsebtn">
                                        {i18n[this.state.isArabic ? "ar" : "en"].ActionPlans.collapse}
                                    </MDBBtn>
                                    : null
                                }
                                {index === this.state.MembersArray.length - 1 ? null :
                                    <div className="seperator"></div>}
                            </MDBCol>
                        </MDBRow>
        
                    </div>
                    :
                    <div class="divCollapsebtn">
        
                        <MDBRow >
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <MDBBtn onClick={() => this.EditCollapse(index)} class="btn btn-primary Ripple-parent submitBtn EditCollapsebtn">
                                    {i18n[this.state.isArabic ? "ar" : "en"].ActionPlans.Expand}
                                </MDBBtn><h4 class="EditCollapseH5">{item.Title}</h4>
                            </MDBCol>
                        </MDBRow>
                        <div class="divCollapsebtnspace">
                        </div>
                    </div>
                }
        
                {index === this.state.MembersArray.length - 1 ?
                    <MDBRow style={{ margin: '5px 34%' }}>
                        <MDBBtn onClick={() => this.addnewmember()} className="gridActionBtAttch whitetext">
                            {i18n[this.state.isArabic ? "ar" : "en"].ActionPlans.addProject}
                        </MDBBtn>
                        <MDBBtn onClick={() => this.removemember
                            (index)} className="gridUploadAttch whitetext">
                            {i18n[this.state.isArabic ? "ar" : "en"].ActionPlans.removProject}
                        </MDBBtn>
                    </MDBRow>
        
                    : null}
        
            </div>)
        })
        return (
            // <React.Fragment>
            <MDBContainer style={{ height: '100%' }} fluid>
            <LoadingOverlay      active={this.state.isLoading}
                    spinner={true}>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.state.isArabic? 'rtl':'ltr',textAlign:this.state.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={2}
                    isArabic={!this.state.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
         
                <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].ActionPlans.Initiatives} </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  
 
  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>

  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeName}<span className="requireStar">*</span></label>
                            <input
                            disabled=  {!this.state.CanEdit}
                                value={this.state.InitiativeName}
                                onChange={this.handleInitiativeName}
                                type="text"
                                id="requesterEntity"
                                className="form-control"
                                name="requesterEntity"
                            
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />
                             {
                                this.state.InitiativeNameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeBudget}<span className="requireStar">*</span></label>
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.InitiativeBudgetErr !== ''  ? '1px solid #dc3545' : '1px solid #ced4da' }}>    <CurrencyFormat
                    
                                    thousandSeparator={false}
                                            value={this.state.InitiativeBudget}
                                            onValueChange={(value) => { this.handleInitiativeBudget(value) }}   
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                />
                                </div>
                             {
                                this.state.requesterentitynameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
             </MDBCol>
                
               
</MDBRow>

      <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>

<MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                          <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.Nameofresponsible}<span className="requireStar">*</span></label>
                          <input
                     
                               value={this.state.Nameofresponsible}
                               onChange={this.handleNameofresponsible}
                              type="text"
                              id="requesterEntity"
                              className="form-control"
                              name="requesterEntity"
                          
                              required
                              //maxlength="50"
                              style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                          />
                           {
                              this.state.requesterentitynameErr == 'thisFieldIsRequired' ?
                          <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                              {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                          </div>
                           :'' }
                      </div>
                  </MDBCol>

          <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
          <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                          <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.Roleofresponsible}<span className="requireStar">*</span></label>
                    <input
                
                               value={this.state.Roleofresponsible}
                               onChange={this.handleRoleofresponsible}
                              type="text"
                              id="requesterEntity"
                              className="form-control"
                              name="requesterEntity"
                          
                              required
                              //maxlength="50"
                              style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                          />
                          
                           {
                              this.state.requesterentitynameErr == 'thisFieldIsRequired' ?
                          <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                              {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                          </div>
                           :'' }
                      </div>
           </MDBCol>
              
             
</MDBRow>

  
<MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
        <div className="form-group">
            <label style={{ display: 'flex', flexDirection: 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                {i18n[this.getLocale()].ActionPlans.ExpectedOutcomes}
                <span className="requireStar">*</span>
            </label>

            <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                {
                    this.state.isArabic ?
                        null
                        :
                        <CKEditor
                        readOnly =  {!this.state.CanEdit}
                            activeClass="p10"
                            content={this.state.ExpectedOutcomes}
                            events={{
                                "change": this.handleExpectedOutcomes
                            }}
                            config={{ language: "en", height: '10vh' }}
                        />
                }

                {
                    this.state.isArabic ?
                        <CKEditor
                        
                        readOnly =  {!this.state.CanEdit}
                            activeClass="p10"
                            content={this.state.ExpectedOutcomes}
                            events={{
                                "change": this.handleExpectedOutcomes
                            }}
                                config={{ language: "ar", height: '10vh' }}
                        />
                        :
                        null
                }
            </div>
            <p style={{ display: "flex", flexDirection: this.state.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                {i18n[this.getLocale()].allowCkEditorLimit}
            </p>
            {
                this.state.reqDetailsErr === 'thisFieldisRequired' ?
                    <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                        {i18n[this.getLocale()].thisFieldIsRequired}
                    </div>
                    :
                    null
            }
            {
                this.state.reqDetailsErr === 'ckeditorCountErr' ?
                    <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                        {i18n[this.getLocale()].ckeditorCountErr}
                    </div>
                    :
                    null
            }


        </div>
    </MDBCol>
</MDBRow>

  
<MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeStatus}<span className="requireStar">*</span></label>
            <select
                style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                id="sources-funding"
                onChange={(value) => { this.handleInitiativesStatus(value) }}
                value={this.state.InitiativeStatus}
            >
                <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                {

                    this.state.InitiativeStatusArray ? this.state.InitiativeStatusArray.map((item, index) => {
                        return <option value={item.ID}>{this.state.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                    }) : null
                }
            </select>
            {/* <input
                                type="text"
                                value={item.title}   className="form-control"
                                    onChange={(value) => { this.handleMemberTitle(value,index) }}
                                  
                                  
                                    style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                /> */}


        </div>
    </MDBCol>
    {
        this.state.InitiativeStatus >2 &&  this.state.InitiativeStatus !=7  ?
        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.InitiativeDelayJustification+this.state.InitiativeStatusLabel}<span className="requireStar">*</span></label>
            <select
            disabled ={this.state.disablejustddl}
                style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                id="sources-funding"
                onChange={(value) => { this.handleInitiativeDelayJustification(value) }}
                value={this.state.InitiativeDelayJustification}
            >
                <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                {

                    this.state.InitiativeStatusJustificationArray ? this.state.InitiativeStatusJustificationArray.map((item, index) => {
                        return <option value={item.ID}>{this.state.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                    }) : null
                }
            </select>
          


        </div>
    </MDBCol>
        :null

    }
</MDBRow>
{this.state.InitiativeStatus==8 ? 
    <MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
        <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">
                    {i18n[this.getLocale()].ActionPlans.OtherDescrption}
                    <span className="requireStar">*</span>
                </label>
                <input
            value={this.state.InitiativeOtherDescrption}
            onChange={this.handleInitiativeOtherDescrption}
            type="text"
            id="requesterEntity"
            className="form-control"
            name="requesterEntity"

            required
            //maxlength="50"
            style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
        />
        {
            this.state.OtherDescrptionErr == 'thisFieldIsRequired' ?
                <div className="invalid-feedback" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                    {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
                : ''}
                </div>
                </MDBCol>
                </MDBRow>
:null}
{
         this.state.InitiativeStatus ==3 ||  this.state.InitiativeStatus ==5  ||this.state.InitiativeStatus ==6 ||  this.state.InitiativeStatus ==8 || this.state.InitiativeStatus ==4  ?
<MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
            <div className="form-group">
                <label style={{ display: 'flex', flexDirection: 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                    {i18n[this.getLocale()].ActionPlans.InitiativeDelayJustificationTxt}
                    <span className="requireStar">*</span>
                </label>

                <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                    {
                        this.state.isArabic ?
                            null
                            :
                            <CKEditor
                                activeClass="p10"
                                content={this.state.InitiativeDelayJustificationTxt}
                                events={{
                                    "change": this.handleInitiativeDelayJustificationTxt
                                }}
                                config={{ language: "en", height: '10vh' }}
                            />
                    }

                    {
                        this.state.isArabic ?
                            <CKEditor
                                activeClass="p10"
                                content={this.state.InitiativeDelayJustificationTxt}
                                events={{
                                    "change": this.handleInitiativeDelayJustificationTxt
                                }}
                                    config={{ language: "ar", height: '10vh'}}
                            />
                            :
                            null
                    }
                </div>
                <p style={{ display: "flex", flexDirection: this.state.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                    {i18n[this.getLocale()].allowCkEditorLimit}
                </p>
                {
                    this.state.reqDetailsErr === 'thisFieldisRequired' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                        :
                        null
                }
                {
                    this.state.reqDetailsErr === 'ckeditorCountErr' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].ckeditorCountErr}
                        </div>
                        :
                        null
                }


            </div>
        </MDBCol>
    </MDBRow> :null
    }
{
        this.state.InitiativeStatus ==1 ||  this.state.InitiativeStatus ==3 ||  this.state.InitiativeStatus ==5  ||this.state.InitiativeStatus ==6 ||  this.state.InitiativeStatus ==8 || this.state.InitiativeStatus ==4  ?
<MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
            <div className="form-group">
                <label style={{ display: 'flex', flexDirection: 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                    {i18n[this.getLocale()].ActionPlans.ActualOutcomes}
                    <span className="requireStar">*</span>
                </label>

                <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                    {
                        this.state.isArabic ?
                            null
                            :
                            <CKEditor
                                activeClass="p10"
                                content={this.state.ActualOutcomes}
                                events={{
                                    "change": this.handleActualOutcomes
                                }}
                                config={{ language: "en", height: '10vh' }}
                            />
                    }

                    {
                        this.state.isArabic ?
                            <CKEditor
                                activeClass="p10"
                                content={this.state.ActualOutcomes}
                                events={{
                                    "change": this.handleActualOutcomes
                                }}
                                    config={{ language: "ar", height: '10vh'}}
                            />
                            :
                            null
                    }
                </div>
                <p style={{ display: "flex", flexDirection: this.state.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                    {i18n[this.getLocale()].allowCkEditorLimit}
                </p>
                {
                    this.state.reqDetailsErr === 'thisFieldisRequired' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                        :
                        null
                }
                {
                    this.state.reqDetailsErr === 'ckeditorCountErr' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].ckeditorCountErr}
                        </div>
                        :
                        null
                }


            </div>
        </MDBCol>
    </MDBRow> :null
    }

    <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
        <div class={this.state.isArabic ? "form-group txtAr":"form-group txtEn"} >

            <label
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: 'row'
                }}
                htmlFor="formGroupExampleInput">
                {i18n[this.getLocale()].ActionPlans.InitiativeSupportDocuments}

                <span className="requireStar">*</span>
            </label>

            <div className="input-group">
                <div className="custom-file-inp">
                    <input
                        id="InitiativeFile"
                        type="file"
                        // multiple
                        name="InitiativeFile"
                        onChange={event => this.InitiativeFileOnChange(event)}
                    />
                    <label
                        htmlFor="InitiativeFile"
                        className={this.state.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                    >
                        {i18n[this.state.isArabic ? "ar" : "en"].choose_file}
                    </label>
                    <label htmlFor="InitiativeFile" className="fileName" style={{ textAlign: this.state.isArabic ? "right" : '' }}>
                        <span>{this.state.InitiativeFile}</span>
                    </label>
                </div>
                {
                    this.state.InitiativeFileErr === 'thisFieldIsRequired' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                        :
                        null
                }
                {
                    this.state.InitiativeFileErr === 'invalidFileType' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].invalidFileType}
                        </div>
                        :
                        null
                }
                {
                    this.state.InitiativeFileErr === 'invalidFileSize' ?
                        <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.getLocale()].invalidFileSizeWorkshop}
                        </div>
                        :
                        null
                }
                <p style={{ color: '#000', display: "flex", flexDirection: !this.state.isArabic ? "row-reverse" : "row", width: '100%' }}>
                    {i18n[this.state.isArabic ? "ar" : "en"].acceptedFormatsAllWorkshop}
                </p>
                {
                    (this.state.InitiativeFileArray &&
                        this.state.InitiativeFileArray.length > 0)
                        ? <DownloadComponent filesObj={this.state.InitiativeFileArray} />
                        : <span></span>
                }
            </div>
        </div>
    </MDBCol>

</MDBRow>

      </div>
      </div>

      <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].ActionPlans.InitiativeKeyProjectsTasks} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
          {element}
     </div>
          
      <div className="clearfix"></div>
      <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
             <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">    
           
             {/* <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.state.isArabic ? "ar" : "en"].previous}
                    </MDBBtn> */}
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].submit}
                           </MDBBtn>
                           <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlecancle}>
                               {i18n[this.getLocale()].cancle}
                           </MDBBtn>
       
                           {/* {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    } */}
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                 </MDBCol>
                       </MDBRow>
       
      
               
                       </form>    
                       <MDBModal
                       className={this.state.isArabic ? 'comment_ar viewDetailModal' : 'viewDetailModal'}
                       isOpen={this.state.showDefneationsState==true}
                       toggle={this.toggle}
                       size={
                           
                                "fluid"
                               
                       } position="bottom">

                       <MDBModalHeader
                           style={{
                               padding: '0.5rem 1rem',
                               flexDirection: 'row',
                               textAlign: this.state.isArabic ? "right" : "left"
                           }}
                           toggle={this.toggle}>{this.state.showdeffortheme ? this.state.MainThemeLabel:this.state.showdefforPractice? this.state.MainPracticeLabel :i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.Metric 
                           }
                       </MDBModalHeader>

                       <MDBModalBody>
                       <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="padding15px">
                        <div className="form-group">
                        {this.state.isArabic?
                        <ul className="DisciplinaryboardIntrolist Aralign">
  <li>{this.state.showdeffortheme ? this.state.MainthemeDef:this.state.showdefforPractice? this.state.MainPracticeDef :i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.MertricDesctiption }</li>

                        </ul>
                        :
                        <ul className="DisciplinaryboardIntrolist ">
                         <li>{this.state.showdeffortheme ? this.state.MainthemeDef:this.state.showdefforPractice? this.state.MainPracticeDef :i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.MertricDesctiption }</li>
                      
                                              </ul>
                }
                          <br />
                           
                            {/* {
                                this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                    <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].budgetAvailabilityError}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>

                        </MDBRow>


                       </MDBModalBody>
                   </MDBModal>

</LoadingOverlay></MDBContainer>
    //   </React.Fragment>
              
        );
    }
}