import React from 'react';
import i18n from './../i18n';

const Footer = (props) => {
    return (
        <>
      
        <div style={{ display: 'flex', justifyContent: 'center ' }} className="footerText">
        {/* <div style={{ display: 'flex', justifyContent: 'center ',paddingLeft:'50px',paddingRight:'50px' }} > <a style={{ color: 'blue' }} href="/faqs" className="footerSupportTxt">{i18n[props.isArabic ? 'ar' : 'en'].FAQ}
        </a></div> */}
            {i18n[props.isArabic ? 'ar' : 'en'].footerText}
            <div style={{ width: '20px' }}></div>
            {/* <div style={{ display: 'flex', direction: props.isArabic ? 'rtl' : 'ltr' }}>
                <div>
                    {i18n[props.isArabic ? 'ar' : 'en'].writeusat}
                </div>
                <div style={{ width: '5px' }}></div>
                <a style={{ color: 'blue' }} href={"mailto:" + i18n[props.isArabic ? 'ar' : 'en'].supportemail} className="footerSupportTxt">{i18n[props.isArabic ? 'ar' : 'en'].supportemail}
                </a>
                
               
              
               
            </div> */}
        
        </div>
  </>
    );
};

export default Footer;