import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import StepsComponent from "../StepsComponent";
import Config from "../../utils/Config";
import i18n from "../../i18n";
import "./../../css/style.css";
import DownloadComponent from "../DownloadComponent";



export default class PromotionUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            RequestType: this.props.sendData.RequestType,
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getPromotionTypeId(),
            EmployeeCVArray: [],
            EmployeeCVErr: "",
            EmployeeCV:this.props.sendData.EmployeeCV,
            EmployeeCVFileObj: "",
            editAttachmentArray:[],


EmployeePhotoArray: [],
EmployeePhotoErr: "",
EmployeePhoto:this.props.sendData.EmployeePhoto,
EmployeePhotoFileObj: "",


positionofthenominatedpositionArray: [],
positionofthenominatedpositionErr: "",
positionofthenominatedposition:this.props.sendData.positionofthenominatedposition,
positionofthenominatedpositionFileObj: "",



jobdescriptionArray: [],
jobdescriptionErr: "",
jobdescription: this.props.sendData.jobdescription,
jobdescriptionFileObj: "",


HumanResourcesCommitteeArray: [],
HumanResourcesCommitteeErr: "",
HumanResourcesCommittee: this.props.sendData.HumanResourcesCommittee,
HumanResourcesCommitteeFileObj: "",


EmployeeNominationFormArray: [],
EmployeeNominationFormErr: "",
EmployeeNominationForm:this.props.sendData.EmployeeNominationForm,
EmployeeNominationFormFileObj: "",


EducationalQualificationsArray: [],
EducationalQualificationsErr: "",
EducationalQualifications:this.props.sendData.EducationalQualifications,
EducationalQualificationsFileObj: "",


ExperienceCertificatesArray: [],
ExperienceCertificatesErr: "",
ExperienceCertificates:this.props.sendData.ExperienceCertificates,
ExperienceCertificatesFileObj: "",


competencyreportsArray: [],
competencyreportsErr: "",
competencyreports:this.props.sendData.competencyreports,
competencyreportsFileObj: "",


passportArray: [],
passportErr: "",
passport: this.props.sendData.passport,
passportFileObj: "",


SummaryofregistrationforcitizensArray: [],
SummaryofregistrationforcitizensErr: "",
Summaryofregistrationforcitizens: this.props.sendData.Summaryofregistrationforcitizens,
SummaryofregistrationforcitizensFileObj: "",


IDcardArray: [],
IDcardErr: "",
IDcard: this.props.sendData.IDcard,
IDcardFileObj: "",


CIDArray: [],
CIDErr: "",
CID: this.props.sendData.CID,
CIDFileObj: "",


MedicalfitnesscheckupArray: [],
MedicalfitnesscheckupErr: "",
Medicalfitnesscheckup: this.props.sendData.Medicalfitnesscheckup,
MedicalfitnesscheckupFileObj: "",


AnotheremployeesselfstatementArray: [],
AnotheremployeesselfstatementErr: "",
Anotheremployeesselfstatement: this.props.sendData.Anotheremployeesselfstatement,
AnotheremployeesselfstatementFileObj: "",
EmployeeCVUrl: '',
EmployeePhotoUrl: '',
positionofthenominatedpositionUrl: '',
jobdescriptionUrl: '',     
HumanResourcesCommitteeUrl: '',        
EmployeeNominationFormUrl: '',
EducationalQualificationsUrl: '',
ExperienceCertificatesUrl: '',
competencyreportsUrl: '',
passportUrl: '',
SummaryofregistrationforcitizensUrl: '',
Url: '',cardUrl: '',
CUrl: '',Url: '',
MedicalfitnesscheckupUrl: '',
AnotheremployeesselfstatementUrl: '',
            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            isSubmit: false,
           
           
        };
    }
   
    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
            this.setState({
                RequestType: editObj.RequestType,
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempEmployeeCVArray= [],
                tempEmployeePhotoArray=  [],
                temppositionofthenominatedpositionArray= [],
                tempjobdescriptionArray= [],
                tempHumanResourcesCommitteeArray= [],
                tempEmployeeNominationFormArray= [],
                tempEducationalQualificationsArray= [],
                tempExperienceCertificatesArray= [],
                tempcompetencyreportsArray= [],
                temppassportArray= [],
                tempSummaryofregistrationforcitizensArray= [],
                tempIDcardArray= [],
                tempCIDArray= [],
                tempMedicalfitnesscheckupArray= [],
                tempAnotheremployeesselfstatementArray= [];
                
                
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getPromotionEmployeeCVId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempEmployeeCVArray.push(temp);
                                this.setState({
                                    //passportCopy: item.filename,
                                    EmployeeCVUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPromotionEmployeePhotoId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempEmployeePhotoArray.push(temp);
                                this.setState({
                                    //medicalReport: item.filename,
                                    EmployeePhotoUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPromotionpositionofthenominatedpositionId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;
                                temppositionofthenominatedpositionArray.push(temp);
                                this.setState({
                                    //letterFromEmbassy: item.filename,
                                    positionofthenominatedpositionUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPromotionjobdescriptionId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;
                                tempjobdescriptionArray.push(temp);
                                this.setState({
                                    //copyOftheDigest: item.filename,
                                    jobdescriptionUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPromotionHumanResourcesCommitteeId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempHumanResourcesCommitteeArray.push(temp);
                                this.setState({
                                    //copyOfHumanResoourcesCommittee: item.filename,
                                    HumanResourcesCommitteeUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPromotionEmployeeNominationFormId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;
                                tempEmployeeNominationFormArray.push(temp);
                                this.setState({
                                    //documentationOfFinancialObligations: item.filename,
                                    EmployeeNominationFormUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPromotionEducationalQualificationsId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempEducationalQualificationsArray.push(temp);
                                this.setState({
                                    //officialLetter: item.filename,
                                    EducationalQualificationsUrl: item.aliasfilename
                                })
                            } 
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionExperienceCertificatesId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempExperienceCertificatesArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    ExperienceCertificatesUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotioncompetencyreportsId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempcompetencyreportsArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    competencyreportsUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionpassportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                temppassportArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    passportUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionSummaryofregistrationforcitizensId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempSummaryofregistrationforcitizensArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    SummaryofregistrationforcitizensUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionIDcardId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempIDcardArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    IDcardUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionCIDId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempCIDArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    CIDUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionMedicalfitnesscheckupId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempMedicalfitnesscheckupArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    MedicalfitnesscheckupUrl:item.aliasfilename
                                })
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getPromotionAnotheremployeesselfstatementId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempAnotheremployeesselfstatementArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    AnotheremployeesselfstatementUrl:item.aliasfilename
                                })
                            }
                        })
                    }
                }

                this.setState({
                    EmployeeCVArray:tempEmployeeCVArray,
                    EmployeePhotoArray:tempEmployeePhotoArray,
                    positionofthenominatedpositionArray:temppositionofthenominatedpositionArray,
                    jobdescriptionArray:tempjobdescriptionArray,
                    HumanResourcesCommitteeArray:tempHumanResourcesCommitteeArray,            
                    EmployeeNominationFormArray:tempEmployeeNominationFormArray,
                    EducationalQualificationsArray:tempEducationalQualificationsArray,        ExperienceCertificatesArray:tempExperienceCertificatesArray,
                    competencyreportsArray:tempcompetencyreportsArray,      
                    passportArray:temppassportArray,
                    SummaryofregistrationforcitizensArray:tempSummaryofregistrationforcitizensArray,          
                    IDcardArray:tempIDcardArray,                
                    CIDArray:tempCIDArray,
                    MedicalfitnesscheckupArray:tempMedicalfitnesscheckupArray,               
                    AnotheremployeesselfstatementArray:tempAnotheremployeesselfstatementArray, 
                });

            });

        }

    };

    goToPrevious = () => {
        this.props.getData.stepTwoPrev({
            EmployeeCV: this.state.EmployeeCV,
            EmployeePhoto: this.state.EmployeePhoto,
            positionofthenominatedposition: this.state.positionofthenominatedposition,
            jobdescription: this.state.jobdescription,
            HumanResourcesCommittee: this.state.HumanResourcesCommittee,
            EmployeeNominationForm: this.state.EmployeeNominationForm,
            ExperienceCertificates: this.state.ExperienceCertificates,
            competencyreports: this.state.competencyreports,
            passport: this.state.passport,
            Summaryofregistrationforcitizens: this.state.Summaryofregistrationforcitizens,
            IDcard: this.state.IDcard,
            CID: this.state.CID,
            EducationalQualifications: this.state.EducationalQualifications,
            Medicalfitnesscheckup: this.state.Medicalfitnesscheckup,
            Anotheremployeesselfstatement: this.state.Anotheremployeesselfstatement,
            
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };


    checkValidations = () => {
        let    EmployeeCVErr= "",
        EmployeePhotoErr= "",
        positionofthenominatedpositionErr= "",
        jobdescriptionErr= "",
        HumanResourcesCommitteeErr= "",
        EmployeeNominationFormErr= "",
        EducationalQualificationsErr= "",
        ExperienceCertificatesErr= "",
        competencyreportsErr= "",
        passportErr= "",
     //   SummaryofregistrationforcitizensErr= "",
        IDcardErr= "",
        CIDErr= "",
        MedicalfitnesscheckupErr= "",
        AnotheremployeesselfstatementErr= "";
        if (this.state.EmployeeCVArray && this.state.EmployeeCVArray.length > 0) {
            EmployeeCVErr = "";
        } else {
            if (!this.state.EmployeeCV) {
                EmployeeCVErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.EmployeePhotoArray && this.state.EmployeePhotoArray.length > 0) {
            EmployeePhotoErr = "";
        } else {
            if (!this.state.EmployeePhoto) {
                EmployeePhotoErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.positionofthenominatedpositionArray && this.state.positionofthenominatedpositionArray.length > 0) {
            positionofthenominatedpositionErr = "";
        } else {
            if (!this.state.positionofthenominatedposition) {
                positionofthenominatedpositionErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.jobdescriptionArray && this.state.jobdescriptionArray.length > 0) {
            jobdescriptionErr = "";
        } else {
            if (!this.state.jobdescription) {
                jobdescriptionErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.HumanResourcesCommitteeArray && this.state.HumanResourcesCommitteeArray.length > 0) {
            HumanResourcesCommitteeErr = "";
        } else {
            if (!this.state.HumanResourcesCommittee) {
                HumanResourcesCommitteeErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.EmployeeNominationFormArray && this.state.EmployeeNominationFormArray.length > 0) {
            EmployeeNominationFormErr = "";
        } else {
            if (!this.state.EmployeeNominationForm) {
                EmployeeNominationFormErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.EducationalQualificationsArray && this.state.EducationalQualificationsArray.length > 0) {
            EducationalQualificationsErr = "";
        } else {
            if (!this.state.EducationalQualifications) {
                EducationalQualificationsErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.ExperienceCertificatesArray && this.state.ExperienceCertificatesArray.length > 0) {
            ExperienceCertificatesErr = "";
        } else {
            if (!this.state.ExperienceCertificates&& this.state.RequestType==='1') {
                ExperienceCertificatesErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.competencyreportsArray && this.state.competencyreportsArray.length > 0) {
            competencyreportsErr = "";
        } else {
            if (!this.state.competencyreports && this.state.RequestType==='1') {
                competencyreportsErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.passportArray && this.state.passportArray.length > 0) {
            passportErr = "";
        } else {
            if (!this.state.passport && this.state.RequestType==='2') {
                passportErr = 'thisFieldIsRequired';
            }
        }
        //  if (this.state.SummaryofregistrationforcitizensArray && this.state.SummaryofregistrationforcitizensArray.length > 0) {
        //     SummaryofregistrationforcitizensErr = "";
        // } else {
        //     if (!this.state.Summaryofregistrationforcitizens) {
        //         SummaryofregistrationforcitizensErr = 'thisFieldIsRequired';
        //     }
        // }
         if (this.state.IDcardArray && this.state.IDcardArray.length > 0) {
            IDcardErr = "";
        } else {
            if (!this.state.IDcard && this.state.RequestType==='2') {
                IDcardErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.CIDArray && this.state.CIDArray.length > 0) {
            CIDErr = "";
        } else {
            if (!this.state.CID && this.state.RequestType==='2') {
                CIDErr = 'thisFieldIsRequired';
            }
        }
         if (this.state.MedicalfitnesscheckupArray && this.state.MedicalfitnesscheckupArray.length > 0) {
            MedicalfitnesscheckupErr = "";
        } else {
            if (!this.state.Medicalfitnesscheckup && this.state.RequestType==='2') {
                MedicalfitnesscheckupErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.AnotheremployeesselfstatementArray && this.state.AnotheremployeesselfstatementArray.length > 0) {
            AnotheremployeesselfstatementErr = "";
        } else {
            if (!this.state.Anotheremployeesselfstatement && this.state.RequestType==='1') {
                AnotheremployeesselfstatementErr = 'thisFieldIsRequired';
            }
        }

        this.setState({
            EmployeeCVErr: EmployeeCVErr,
            EmployeePhotoErr: EmployeePhotoErr,
            positionofthenominatedpositionErr: positionofthenominatedpositionErr,
            jobdescriptionErr: jobdescriptionErr,
            HumanResourcesCommitteeErr: HumanResourcesCommitteeErr,
            EmployeeNominationFormErr: EmployeeNominationFormErr,
            EducationalQualificationsErr: EducationalQualificationsErr,
            ExperienceCertificatesErr:ExperienceCertificatesErr,
            competencyreportsErr: competencyreportsErr,
            passportErr: passportErr,
        
            IDcardErr: IDcardErr,
            CIDErr: CIDErr,
            MedicalfitnesscheckupErr: MedicalfitnesscheckupErr,
            AnotheremployeesselfstatementErr: AnotheremployeesselfstatementErr,
        }, () => {
        
            if (!EmployeeCVErr && !EmployeePhotoErr && !positionofthenominatedpositionErr && !jobdescriptionErr && !HumanResourcesCommitteeErr && !EmployeeNominationFormErr && !EducationalQualificationsErr && !competencyreportsErr && !passportErr && !IDcardErr && !CIDErr && !MedicalfitnesscheckupErr && !AnotheremployeesselfstatementErr&&!ExperienceCertificatesErr) {

        this.props.getData.stepTwoData({
           
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: this.state.formType,
            EmployeeCV: this.state.EmployeeCV,
            EmployeePhoto: this.state.EmployeePhoto,
            positionofthenominatedposition: this.state.positionofthenominatedposition,
            jobdescription: this.state.jobdescription,
            HumanResourcesCommittee: this.state.HumanResourcesCommittee,
            EmployeeNominationForm: this.state.EmployeeNominationForm,
            ExperienceCertificates: this.state.ExperienceCertificates,
            competencyreports: this.state.competencyreports,
            passport: this.state.passport,
            Summaryofregistrationforcitizens: this.state.Summaryofregistrationforcitizens,
            IDcard: this.state.IDcard,
            CID: this.state.CID,
            EducationalQualifications: this.state.EducationalQualifications,
            Medicalfitnesscheckup: this.state.Medicalfitnesscheckup,
            Anotheremployeesselfstatement: this.state.Anotheremployeesselfstatement,
        });
    }
});


    }

  

   

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        //check for edit flag, if edit false => attachment are not compulsary
        this.checkValidations();

    };

    clearForm = () => {
        this.setState({
            officialLetter: '',
            emiratedID: '',
            passportCopy: '',
            medicalReport: '',
            letterFromEmbassy: '',
            copyOftheDigest: '',
            copyOfHumanResoourcesCommittee: '',
            documentationOfFinancialObligations: ''
        });
    }

    EmployeeCVOnChange = e => {
       
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ EmployeeCV: '', EmployeeCVErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ EmployeeCV: e.target.files[0].name, EmployeeCVErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                EmployeeCVFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionEmployeeCV(this.state.EmployeeCVFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ EmployeeCV: '', EmployeeCVErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };
    EmployeePhotoOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ EmployeePhoto: '', EmployeePhotoErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ EmployeePhoto: e.target.files[0].name, EmployeePhotoErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                EmployeePhotoFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionEmployeePhoto(this.state.EmployeePhotoFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ EmployeePhoto: '', EmployeePhotoErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EmployeePhoto: '', EmployeePhotoErr: 'thisFieldIsRequired' });
        //}
    };

    positionofthenominatedpositionOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ positionofthenominatedposition: '', positionofthenominatedpositionErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ positionofthenominatedposition: e.target.files[0].name, positionofthenominatedpositionErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                positionofthenominatedpositionFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionpositionofthenominatedposition(this.state.positionofthenominatedpositionFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ positionofthenominatedposition: '', positionofthenominatedpositionErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ positionofthenominatedposition: '', positionofthenominatedpositionErr: 'thisFieldIsRequired' });
        //}
    };

    jobdescriptionOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ jobdescription: '', jobdescriptionErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ jobdescription: e.target.files[0].name, jobdescriptionErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                jobdescriptionFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionjobdescription(this.state.jobdescriptionFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ jobdescription: '', jobdescriptionErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ jobdescription: '', jobdescriptionErr: 'thisFieldIsRequired' });
        //}
    };

    HumanResourcesCommitteeOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ HumanResourcesCommittee: '', HumanResourcesCommitteeErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ HumanResourcesCommittee: e.target.files[0].name, HumanResourcesCommitteeErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                HumanResourcesCommitteeFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionHumanResourcesCommittee(this.state.HumanResourcesCommitteeFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ HumanResourcesCommittee: '', HumanResourcesCommitteeErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ HumanResourcesCommittee: '', HumanResourcesCommitteeErr: 'thisFieldIsRequired' });
        //}
    };

    EmployeeNominationFormOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ EmployeeNominationForm: '', EmployeeNominationFormErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ EmployeeNominationForm: e.target.files[0].name, EmployeeNominationFormErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                EmployeeNominationFormFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionEmployeeNominationForm(this.state.EmployeeNominationFormFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ EmployeeNominationForm: '', EmployeeNominationFormErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeNominationForm: '', EmployeeNominationFormErr: 'thisFieldIsRequired' });
        //}
    };

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            EmployeeCV: this.state.EmployeeCV,
            EmployeePhoto: this.state.EmployeePhoto,
            positionofthenominatedposition: this.state.positionofthenominatedposition,
            jobdescription: this.state.jobdescription,
            HumanResourcesCommittee: this.state.HumanResourcesCommittee,
            EmployeeNominationForm: this.state.EmployeeNominationForm,
            EducationalQualifications: this.state.EducationalQualifications,
            ExperienceCertificates: this.state.ExperienceCertificates,
            competencyreports: this.state.competencyreports,
            passport: this.state.passport,
            Summaryofregistrationforcitizens: this.state.Summaryofregistrationforcitizens,
            IDcard: this.state.IDcard,
            CID: this.state.CID,
            Medicalfitnesscheckup: this.state.Medicalfitnesscheckup,
            Anotheremployeesselfstatement: this.state.Anotheremployeesselfstatement,
        });
    }

    EducationalQualificationsOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ EducationalQualifications: '', EducationalQualificationsErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ EducationalQualifications: e.target.files[0].name, EducationalQualificationsErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                EducationalQualificationsFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionEducationalQualifications(this.state.EducationalQualificationsFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ EducationalQualifications: '', EducationalQualificationsErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EducationalQualifications: '', EducationalQualificationsErr: 'thisFieldIsRequired' });
        //}
    };

    ExperienceCertificatesOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ ExperienceCertificates: '', ExperienceCertificatesErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ ExperienceCertificates: e.target.files[0].name, ExperienceCertificatesErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                ExperienceCertificatesFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionExperienceCertificates(this.state.ExperienceCertificatesFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ ExperienceCertificates: '', ExperienceCertificatesErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ ExperienceCertificates: '', ExperienceCertificatesErr: 'thisFieldIsRequired' });
        //}
    };

    competencyreportsOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ competencyreports: '', competencyreportsErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ competencyreports: e.target.files[0].name, competencyreportsErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                competencyreportsFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotioncompetencyreports(this.state.competencyreportsFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ competencyreports: '', competencyreportsErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ competencyreports: '', competencyreportsErr: 'thisFieldIsRequired' });
        //}
    };

    passportOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ passport: '', passportErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ passport: e.target.files[0].name, passportErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                passportFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionpassport(this.state.passportFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ passport: '', passportErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ passport: '', passportErr: 'thisFieldIsRequired' });
        //}
    };

    SummaryofregistrationforcitizensOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Summaryofregistrationforcitizens: '', SummaryofregistrationforcitizensErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Summaryofregistrationforcitizens: e.target.files[0].name, SummaryofregistrationforcitizensErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                SummaryofregistrationforcitizensFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionSummaryofregistrationforcitizens(this.state.SummaryofregistrationforcitizensFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Summaryofregistrationforcitizens: '', SummaryofregistrationforcitizensErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ Summaryofregistrationforcitizens: '', SummaryofregistrationforcitizensErr: 'thisFieldIsRequired' });
        //}
    };

    IDcardOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ IDcard: '', IDcardErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ IDcard: e.target.files[0].name, IDcardErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                IDcardFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionIDcard(this.state.IDcardFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ IDcard: '', IDcardErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ IDcard: '', IDcardErr: 'thisFieldIsRequired' });
        //}
    };

    CIDOnChange = e => {
        debugger;
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ CID: '', CIDErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ CID: e.target.files[0].name, CIDErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                CIDFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionCID(this.state.CIDFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ CID: '', CIDErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ CID: '', CIDErr: 'thisFieldIsRequired' });
        //}
    };

    MedicalfitnesscheckupOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Medicalfitnesscheckup: '', MedicalfitnesscheckupErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Medicalfitnesscheckup: e.target.files[0].name, MedicalfitnesscheckupErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                MedicalfitnesscheckupFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionMedicalfitnesscheckup(this.state.MedicalfitnesscheckupFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Medicalfitnesscheckup: '', MedicalfitnesscheckupErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ Medicalfitnesscheckup: '', MedicalfitnesscheckupErr: 'thisFieldIsRequired' });
        //}
    };

    AnotheremployeesselfstatementOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Anotheremployeesselfstatement: '', AnotheremployeesselfstatementErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Anotheremployeesselfstatement: e.target.files[0].name, AnotheremployeesselfstatementErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                AnotheremployeesselfstatementFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPromotionAnotheremployeesselfstatement(this.state.AnotheremployeesselfstatementFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Anotheremployeesselfstatement: '', AnotheremployeesselfstatementErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ Anotheremployeesselfstatement: '', AnotheremployeesselfstatementErr: 'thisFieldIsRequired' });
        //}
    };



    saveAsDraft = () => {
       
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            EmployeeCV:this.state.EmployeeCV,
            EmployeePhoto:this.state.EmployeePhoto,
            positionofthenominatedposition:this.state.positionofthenominatedposition,
            jobdescription:this.state.jobdescription,
            HumanResourcesCommittee:this.state.HumanResourcesCommittee,
            EmployeeNominationForm:this.state.EmployeeNominationForm,
            EducationalQualifications:this.state.EducationalQualifications,
            ExperienceCertificates:this.state.ExperienceCertificates,
            competencyreports:this.state.competencyreports,
            passport:this.state.passport,
            Summaryofregistrationforcitizens:this.state.Summaryofregistrationforcitizens,
            IDcard:this.state.IDcard,
            CID:this.state.CID,
            Medicalfitnesscheckup:this.state.Medicalfitnesscheckup,
            Anotheremployeesselfstatement:this.state.Anotheremployeesselfstatement,
        });
    };

    render() {

        return (
            <form
                style={{ paddingTop: '20', paddingBottom: '200px',direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.EmployeeCV}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeeCVFile"
                                    type="file"
                                    name="EmployeeCV"
                                    onChange={event => this.EmployeeCVOnChange(event)}
                                />
                                <label
                                    htmlFor="EmployeeCVFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeeCVFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EmployeeCV}</span>
                                </label>
                            </div>
                            {
                                this.state.EmployeeCVErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeCVErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeCVErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EmployeeCVArray &&
                                    this.state.EmployeeCVArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EmployeeCVArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.EmployeePhoto}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeePhotoFile"
                                    type="file"
                                    name="EmployeePhoto"
                                    onChange={event => this.EmployeePhotoOnChange(event)}
                                />
                                <label
                                    htmlFor="EmployeePhotoFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeePhotoFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EmployeePhoto}</span>
                                </label>
                            </div>
                            {
                                this.state.EmployeePhotoErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeePhotoErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeePhotoErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EmployeePhotoArray &&
                                    this.state.EmployeePhotoArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EmployeePhotoArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.positionofthenominatedposition}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="positionofthenominatedpositionFile"
                                    type="file"
                                    name="positionofthenominatedposition"
                                    onChange={event => this.positionofthenominatedpositionOnChange(event)}
                                />
                                <label
                                    htmlFor="positionofthenominatedpositionFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="positionofthenominatedpositionFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.positionofthenominatedposition}</span>
                                </label>
                            </div>
                            {
                                this.state.positionofthenominatedpositionErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.positionofthenominatedpositionErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.positionofthenominatedpositionErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.positionofthenominatedpositionArray &&
                                    this.state.positionofthenominatedpositionArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.positionofthenominatedpositionArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.jobdescription}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="jobdescriptionFile"
                                    type="file"
                                    name="jobdescription"
                                    onChange={event => this.jobdescriptionOnChange(event)}
                                />
                                <label
                                    htmlFor="jobdescriptionFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="jobdescriptionFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.jobdescription}</span>
                                </label>
                            </div>
                            {
                                this.state.jobdescriptionErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.jobdescriptionErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.jobdescriptionErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.jobdescriptionArray &&
                                    this.state.jobdescriptionArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.jobdescriptionArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.HumanResourcesCommittee}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="HumanResourcesCommitteeFile"
                                    type="file"
                                    name="HumanResourcesCommittee"
                                    onChange={event => this.HumanResourcesCommitteeOnChange(event)}
                                />
                                <label
                                    htmlFor="HumanResourcesCommitteeFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="HumanResourcesCommitteeFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.HumanResourcesCommittee}</span>
                                </label>
                            </div>
                            {
                                this.state.HumanResourcesCommitteeErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.HumanResourcesCommitteeErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.HumanResourcesCommitteeErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.HumanResourcesCommitteeArray &&
                                    this.state.HumanResourcesCommitteeArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.HumanResourcesCommitteeArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.EmployeeNominationForm}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeeNominationFormFile"
                                    type="file"
                                    name="EmployeeNominationForm"
                                    onChange={event => this.EmployeeNominationFormOnChange(event)}
                                />
                                <label
                                    htmlFor="EmployeeNominationFormFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeeNominationFormFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EmployeeNominationForm}</span>
                                </label>
                            </div>
                            {
                                this.state.EmployeeNominationFormErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeNominationFormErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeNominationFormErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EmployeeNominationFormArray &&
                                    this.state.EmployeeNominationFormArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EmployeeNominationFormArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.EducationalQualifications}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EducationalQualificationsFile"
                                    type="file"
                                    name="EducationalQualifications"
                                    onChange={event => this.EducationalQualificationsOnChange(event)}
                                />
                                <label
                                    htmlFor="EducationalQualificationsFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EducationalQualificationsFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EducationalQualifications}</span>
                                </label>
                            </div>
                            {
                                this.state.EducationalQualificationsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EducationalQualificationsErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EducationalQualificationsErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EducationalQualificationsArray &&
                                    this.state.EducationalQualificationsArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EducationalQualificationsArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.ExperienceCertificates}
                             { this.state.RequestType==='1'?
                             <span className="requireStar">*</span> :null}
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="ExperienceCertificatesFile"
                                    type="file"
                                    name="ExperienceCertificates"
                                    onChange={event => this.ExperienceCertificatesOnChange(event)}
                                />
                                <label
                                    htmlFor="ExperienceCertificatesFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="ExperienceCertificatesFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.ExperienceCertificates}</span>
                                </label>
                            </div>
                            {
                                this.state.ExperienceCertificatesErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.ExperienceCertificatesErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.ExperienceCertificatesErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.ExperienceCertificatesArray &&
                                    this.state.ExperienceCertificatesArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.ExperienceCertificatesArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>
{this.state.RequestType==='1'?
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.competencyreports}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="competencyreportsFile"
                                    type="file"
                                    name="competencyreports"
                                    onChange={event => this.competencyreportsOnChange(event)}
                                />
                                <label
                                    htmlFor="competencyreportsFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="competencyreportsFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.competencyreports}</span>
                                </label>
                            </div>
                            {
                                this.state.competencyreportsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.competencyreportsErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.competencyreportsErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.competencyreportsArray &&
                                    this.state.competencyreportsArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.competencyreportsArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.Anotheremployeesselfstatement}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="AnotheremployeesselfstatementFile"
                                    type="file"
                                    name="Anotheremployeesselfstatement"
                                    onChange={event => this.AnotheremployeesselfstatementOnChange(event)}
                                />
                                <label
                                    htmlFor="AnotheremployeesselfstatementFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="AnotheremployeesselfstatementFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.Anotheremployeesselfstatement}</span>
                                </label>
                            </div>
                            {
                                this.state.AnotheremployeesselfstatementErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.AnotheremployeesselfstatementErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.AnotheremployeesselfstatementErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.AnotheremployeesselfstatementArray &&
                                    this.state.AnotheremployeesselfstatementArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.AnotheremployeesselfstatementArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>
              
                <br />
                </MDBRow>
:null}
{this.state.RequestType==='2'?
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.Summaryofregistrationforcitizens}
                            {/* <span className="requireStar">*</span> */}
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="SummaryofregistrationforcitizensFile"
                                    type="file"
                                    name="Summaryofregistrationforcitizens"
                                    onChange={event => this.SummaryofregistrationforcitizensOnChange(event)}
                                />
                                <label
                                    htmlFor="SummaryofregistrationforcitizensFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="Summaryofregistrationforcitizens" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.Summaryofregistrationforcitizens}</span>
                                </label>
                            </div>
                            {
                                this.state.SummaryofregistrationforcitizensErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SummaryofregistrationforcitizensErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SummaryofregistrationforcitizensErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.SummaryofregistrationforcitizensArray &&
                                    this.state.SummaryofregistrationforcitizensArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.SummaryofregistrationforcitizensArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.IDcard}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="IDcardFile"
                                    type="file"
                                    name="IDcard"
                                    onChange={event => this.IDcardOnChange(event)}
                                />
                                <label
                                    htmlFor="IDcardFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="IDcardFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.IDcard}</span>
                                </label>
                            </div>
                            {
                                this.state.IDcardErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.IDcardErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.IDcardErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.IDcardArray &&
                                    this.state.IDcardArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.IDcardArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

               <br />
                </MDBRow>
:null}{this.state.RequestType==='2'?
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.CID}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="CIDFile"
                                    type="file"
                                    name="CIDCopy"
                                    onChange={event => this.CIDOnChange(event)}
                                />
                                <label
                                    htmlFor="CIDFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="CIDFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.CID}</span>
                                </label>
                            </div>
                            {
                                this.state.CIDErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.CIDErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.CIDErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.CIDArray &&
                                    this.state.CIDArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.CIDArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.Medicalfitnesscheckup}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="MedicalfitnesscheckupFile"
                                    type="file"
                                    name="Medicalfitnesscheckup"
                                    onChange={event => this.MedicalfitnesscheckupOnChange(event)}
                                />
                                <label
                                    htmlFor="MedicalfitnesscheckupFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="MedicalfitnesscheckupFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.Medicalfitnesscheckup}</span>
                                </label>
                            </div>
                            {
                                this.state.MedicalfitnesscheckupErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.MedicalfitnesscheckupErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.MedicalfitnesscheckupErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.MedicalfitnesscheckupArray &&
                                    this.state.MedicalfitnesscheckupArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.MedicalfitnesscheckupArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

               <br />
                </MDBRow>

:null}{this.state.RequestType==='2'?             <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>

<MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.passport}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportFile"
                                    type="file"
                                    name="passport"
                                    onChange={event => this.passportOnChange(event)}
                                />
                                <label
                                    htmlFor="passportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passport}</span>
                                </label>
                            </div>
                            {
                                this.state.passportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportArray &&
                                    this.state.passportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                   
                
               <br />
                </MDBRow>
:null}
       
                          
                <MDBRow style={{ display: "flex", flexDirection: "row" }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        // (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        //     null
                        //     :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type="submit" color="primary">{i18n[this.props.isArabic ? "ar" : "en"].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }
}
