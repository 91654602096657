import React, { Component } from "react";
import {
    MDBBtn,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBRow
} from "mdbreact";
import i18n from "../i18n";
import Config from './../utils/Config';

const actions = {
    Reopen:"Reopen",
    Underapproval:"Underapproval",
    Notification:"Notification",
    VIEW: "view",
    EDIT: "edit",
    UPDATE_STATUS: "update_status",
    COMMENT: "comment",
    DELETE: "delete",
    ASSIGNTOSECTOR: "assigntosector",
    ASSIGNTODEP: "assigntodep",
    BACKTOMANAGER: "backtomanager",
    ASSIGNTOSPECIFICDEP: "assigntospecificdep",
    ASSIGNTODATADEP: "assigntodatadep",
    ASSIGNTOEMP: "assigntoemp",
    ASSIGNTOSPECIFICEMP: "assigntospecificemp",
    ASSIGNTOGMOFFICE: "assigntogmoffice",
    ASSIGNTOSTRATEGICOFFICE: "assigntostrategicoffice",
    ASSIGNBACKTOENTITY: "assignbacktoentity",
    ASSIGNTOENTITY: "assigntoentity",
    ADDSTUDY: "addStudy",
    ADDREPLY: "addReply",
    FOLLOW: "follow",
    VIEWDETAILS: 'viewdetails',
    OpenCreationStage:"OpenCreationStage",
    CloseRequest:'CloseRequest',
    EditComment:'EditComment'
};

class PopOverComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
            EditComment:'1',
            currentUserId: '',
            pathName: '',
            loginData: '',
            accountTypeId: '',
            requestStatus: '',
            requestStatusReason: '',
            canView: 0,
            canEdit: 0,
            canUpdateStatus: 0,
            canAddComment: 0,
            canAssignDept: 0,
            canAssignEmployee: 0,
            canProvideFeedback: 0,
            canAssignSector: 0,
            canAddHraStudy: 0,
            canViewHraStudy: 0,
            canAssignGm: 0,
            canAssignBackToEntity: 0,
            casAssignBackToDataDepartment: 0,
            canCloseRequest:0,
            
            screen: localStorage.getItem("currentPath"),
            ORG_Path : "OrganizationStructure",
            userdepartmentid:0
        }
    };
    
    validateaddcomment = ()=> 
    { 
      
        if (this.props.data.requesttypeid == Config.getSciRequestTypeId() || this.props.data.requesttypeid===10969)
        {
           
return 1;
        }
        let validateaddcomment=1;
        let statusid=this.props.data.statusid;
        let statusreasonid = this.props.data.statusreasonid;
        switch(statusid)
        {
           case 164:
               {
                validateaddcomment=0;
                break;
               }
               case 23:
                    {
                     validateaddcomment=0;
                     break;
                    }
                 
        }
        if(validateaddcomment=1)
        {
            switch(statusreasonid)
            {
               case 815:
                   {
                    validateaddcomment=0;
                    break;
                   }
                   case 54:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 56:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 555:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 157:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 158:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 159:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 160:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 810:
                        {
                         validateaddcomment=0;
                         break;
                        }
                        case 897:
                        {
                         validateaddcomment=0;
                         break;
                        }
                     
            }

        }
        return validateaddcomment;
    }
    componentWillReceiveProps = (props) => {
        this.permissionData();
    }

    componentDidMount = () => {
       
        this.permissionData();
       
    }

    checkPermissionForInboxFuncationality = (pathName) => {
     debugger;
        if (this.props.data && this.props.data.requesttypeid) {
            
            this.setState({canCloseRequest:this.state.loginData.permissionType.canCloseRequest|| this.state.loginData.isadmin===1});
           
            this.setState({userdepartmentid:this.state.loginData.userdepartmentid})
              if(this.state.loginData.isadmin===1) 
            {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    canProvideFeedback:1,
                    canAddHraStudy:1,
                    accountTypeId:1,
                    canView:1,
                    canAddComment:1,
                    canEdit: 1,
                    canAssignDept:1,
                    canAssignEmployee:1,
                    canAssignGm:1,
                    canCloseRequest:1
                   
                });
            }
           else if (this.props.data.requesttypeid === Config.getDisciplinaryboardTypeId()) 
                {
                    let depem= this.state.loginData.id==10341?1:0;
                    
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        canProvideFeedback:depem,
                        canAddHraStudy:depem,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView:(this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest) || this.state.loginData.id==16 || this.state.loginData.id==10340 || this.state.loginData.id==10341,
                        canAddComment:( this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddpatientcomments)|| this.state.loginData.id==16|| this.state.loginData.id==10340 || this.state.loginData.id==10341,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditpatientrequest,
                        canAssignDept:this.state.loginData.id==16,
                        canAssignEmployee:this.state.loginData.id==10340,
                        canAssignGm:this.state.loginData.id==10340,
                        canCloseRequest:this.state.loginData.id==16
                       
                    });
                }
            else if (this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid ===10969) {
               
                this.setState( {canAssignDept :  this.state.loginData.permissionType.canassignscidepartment});
                this.setState( {canAssignEmployee: this.state.loginData.permissionType.canasignsciemployee});
                this.setState( { currentUserId:  this.state.loginData.id});
                 this.setState( {requestStatus: this.props.data.statusid});
                 this.setState( {requestStatusReason: this.props.data.statusreasonid});
                this.setState( {pathName: pathName});
                this.setState( {accountTypeId:  this.state.loginData.AccountypeID});
                this.setState( {canAssignBackToEntity:  this.state.loginData.permissionType.canassignscibacktoentity});
                this.setState( {canView:  this.state.loginData.permissionType.canreviewscirequest});
                this.setState( {canEdit:  this.state.loginData.permissionType.caneditscirequest});
                this.setState( {canUpdateStatus: this.state.loginData.permissionType.canupdatescistatus});
                this.setState( {canAddComment:  this.state.loginData.permissionType.canaddscicomment});
                this.setState( {canAssignGm:  this.state.loginData.permissionType.canassignscigm});
                                            
              
                this.setState( {canProvideFeedback:  this.state.loginData.permissionType.canprovidescifeedback});
                this.setState( {canAssignSector: this.state.loginData.permissionType.canassignscisector});
                this.setState( {canAddHraStudy: 0});
               
               
        } else if (this.props.data.requesttypeid === Config.getInformationRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditbonusrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatebonusstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddbonuscomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusgm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidebonusstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivebonusstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonussector
                })
            } else if (this.props.data.requesttypeid === Config.getBonusRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditbonusrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatebonusstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddbonuscomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusgm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidebonusstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivebonusstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonussector
                })
            } else if (this.props.data.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalebacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendscalerequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendscalestatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendscalecomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalegm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscaledepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscaleemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendscalestudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendscalestudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalesector
                })
            } else if (this.props.data.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarybacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendsalaryrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendsalarystatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendsalarycomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarygm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarydepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalaryemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendsalarystudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendsalarystudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarysector
                    //canAssignSector:1
                })
            } 
            
            else if (this.props.data.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId() ||this.props.data.requesttypeid === 10925||this.props.data.requesttypeid === 10926 ) {
              
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id});
                   this.setState({  requestStatus: this.props.data.statusid});
                   this.setState({  requestStatusReason: this.props.data.statusreasonid});
                   this.setState({  pathName: pathName});
                    this.setState({ accountTypeId: this.state.loginData && this.state.loginData.AccountypeID});
                  this.setState({   canAssignBackToEntity: this.state.loginData.permissionType.canassignpatientbacktoentity});
                  this.setState({   canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest});
                   this.setState({  canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditpatientrequest});
                   this.setState({  canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatepatientstatus});
                   this.setState({  canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddpatientcomments});
                     this.setState({canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignscigm});
                   this.setState({  canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientdepartment});
                  this.setState({   canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientemployee});
                    this.setState({ canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidepatientstudy});
                   this.setState({  canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivepatientstudy});
                  this.setState({   canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientsector
                });
            } else if (this.props.data.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditreviewhrrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatereviewhrstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddreviewhrcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrgm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhremployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidehrstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivehrstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrsector
                })
            } else if (this.props.data.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditadhousingrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateadhousingstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddadhousingcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousinggm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidehousingstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivehousingstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingsector
                })
            } else if (this.props.data.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditsecondmentrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatesecondmentstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddsecondmentcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentgm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmetnemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidesecondmentstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivesecondmentstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentsector
                })
            } else if (this.props.data.requesttypeid === Config.getRetirmentRequestTypeId()) {
                
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditretirementrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateretirementstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddretirementcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementgm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementemployees,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideretirementstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveretirementstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementsector
                })
            } else if (this.props.data.requesttypeid === Config.getExtendCreateObjectiveId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendcreaterequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendcreaterequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendcreatestatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendcreatecomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreaterequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatedepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreateemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendcreatestudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendcreatestudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatesector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatebacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatebacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendreviewrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendreviewrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendreviewstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendreviewcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendreviewstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendreviewstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewsector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewbacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendannualrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendannualrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendannualstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendannualcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendannualstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendannualstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualsector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualbacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewemployeeperformancerequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditemployeeperformancerequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateemployeeperformancestatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddemployeeperformancecomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancerequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancedepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformanceemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideemployeeperformancestudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveemployeeperformancestudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancesector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancebacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancebacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getJoinAndImplementId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewjoinimplementrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditjoinimplementrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatejoinimplementstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddjoinimplementcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidejoinimplementstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivejoinimplementstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementsector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementbacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getOpenComplainsStageId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewopencomplainsrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditopencomplainsrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateopencomplainsstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddopencomplainscomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideopencomplainsstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveopencomplainsstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainssector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsbacktoentity,
                }, () => {
                  
                })
            } else if (this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsystemsrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendsystemsrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendsystemsstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendsystemscomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendsystemsstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendsystemsstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemssector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsbacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getReportAndStaticId()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreportsstaticsrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditreportsstaticsrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatereportsstaticsstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddreportsstaticscomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidereportsstaticsstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivereportsstaticsstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticssector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsbacktoentity
                })
            } else if (this.props.data.requesttypeid === Config.getOtherRequest()) {
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewotherrequestsrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditotherrequestsrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateotherrequestsstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddotherrequestscomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequesttogmsrequesttogm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideotherrequestsstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveotherrequestsstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestssector,
                    casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsbacktodepartment,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsbacktoentity
                })
            }

            // organization structure
            else if (this.props.data.requesttypeid === Config.getORGRequestTypeId()) {
                
                this.setState({
                    currentUserId: this.state.loginData && this.state.loginData.id,
                    requestStatus: this.props.data.statusid,
                    requestStatusReason: this.props.data.statusreasonid,
                    pathName: pathName,
                    accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                    canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentbacktoentity,
                    canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditsecondmentrequest,
                    canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatesecondmentstatus,
                    canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddsecondmentcomments,
                    canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentgm,
                    canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentdepartment,
                    canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmetnemployee,
                    canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidesecondmentstudy,
                    canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivesecondmentstudy,
                    canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentsector
              })
            } 
        }
    }

    permissionData = () => {

       debugger;
        let loginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: loginData
        }, () => {
            let pathName = '';
            if (this.props && this.props.location && this.props.location.state) {
                pathName = this.props.location.state.pathname
            } else {
                pathName = localStorage.getItem('currentPath');
            }
            //
            this.setState({canCloseRequest:this.state.loginData.permissionType.canCloseRequest});
            if (pathName) {
                if (pathName === 'currentUserTasks') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'inprogress' || pathName==='search') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'pending') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } 
                else if (pathName === 'Underapproval') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } 
                else if (pathName === 'completed') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'cancelled') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'onhold') {
                    this.checkPermissionForInboxFuncationality(pathName);
                } else if (pathName === 'SCIRequest' || pathName === 'StrategicPlanningforworkforceEnquire') {
                  ;
                    this.setState( {canAssignDept :  this.state.loginData.permissionType.canassignscidepartment});
                    this.setState( {canAssignEmployee: this.state.loginData.permissionType.canasignsciemployee});
                    this.setState( { currentUserId:  this.state.loginData.id});
                    this.setState( {requestStatus: this.props.data.statusid});
                    this.setState( {requestStatusReason: this.props.data.statusreasonid});
                    this.setState( {pathName: pathName});
                    this.setState( {accountTypeId:  this.state.loginData.AccountypeID});
                    this.setState( {canAssignBackToEntity:  this.state.loginData.permissionType.canassignscibacktoentity});
                    this.setState( {canView:  this.state.loginData.permissionType.canreviewscirequest});
                    this.setState( {canEdit:  this.state.loginData.permissionType.caneditscirequest});
                    this.setState( {canUpdateStatus: this.state.loginData.permissionType.canupdatescistatus});
                    this.setState( {canAddComment:  this.state.loginData.permissionType.canaddscicomment});
                    this.setState( {canAssignGm:  this.state.loginData.permissionType.canassignscigm});
                                                
                  
                    this.setState( {canProvideFeedback:  this.state.loginData.permissionType.canprovidescifeedback});
                    this.setState( {canAssignSector: this.state.loginData.permissionType.canassignscisector});
                    this.setState( {canAddHraStudy: 0});
                                     
                } else if (pathName === 'informationRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewirrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditirrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateirstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddircomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirdepartment,
                        canAssignEmployee: 0,
                        canProvideFeedback: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideirfeedback,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignirsector,
                        canAddHraStudy: 0
                    })
                } else if (pathName === 'bonusRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditbonusrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatebonusstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddbonuscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonusgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbounsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidebonusstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivebonusstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignbonussector
                    })
                } else if (pathName === 'amendSalaryScreen') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalebacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendscalerequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendscalestatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendscalecomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalegm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscaledepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscaleemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendscalestudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendscalestudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendscalesector
                    })
                } else if (pathName === 'amendEmployeeSalary') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarybacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendsalaryrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendsalarystatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendsalarycomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarygm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarydepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalaryemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendsalarystudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendsalarystudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsalarysector
                      
                    })
                } else if (pathName === 'reviewHRPolicies') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditreviewhrrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatereviewhrstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddreviewhrcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhremployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidehrstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivehrstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhrsector
                    })
                } else if (pathName === 'secondmentForFederalAgency') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditsecondmentrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatesecondmentstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddsecondmentcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmetnemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidesecondmentstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivesecondmentstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignsecondmentsector
                    })
                } else if (pathName === 'exceptionADHousing') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditadhousingrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateadhousingstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddadhousingcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousinggm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidehousingstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivehousingstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignhousingsector
                    })
                } 
                else if (pathName=='Disciplinaryboard')
                {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView:(this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest) || this.state.loginData.id==16,
                        canAddComment:( this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddpatientcomments)|| this.state.loginData.id==16,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditpatientrequest,

                    });
                }
                else if (pathName === 'exemptionFromAccompanyPatient'||pathName === 'SickLeave'||pathName === 'Promotion') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditpatientrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatepatientstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddpatientcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignscigm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidepatientstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivepatientstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignpatientsector
                    })
                } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementbacktoentity,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditretirementrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateretirementstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddretirementcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementgm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementemployees,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideretirementstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveretirementstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignretirementsector
                    })
                } else if (pathName === 'extendCreateObjectives') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendcreaterequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendcreaterequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendcreatestatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendcreatecomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreaterequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatedepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreateemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendcreatestudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendcreatestudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatesector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatebacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendcreatebacktoentity
                    })
                } else if (pathName === 'extendReviewObjectives') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendreviewrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendreviewrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendreviewstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendreviewcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendreviewstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendreviewstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewsector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendreviewbacktoentity
                    })
                } else if (pathName === 'extendAnnualPerformance') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendannualrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditextendannualrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateextendannualstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddextendannualcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideextendannualstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveextendannualstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualsector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignextendannualbacktoentity
                    })
                } else if (pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewemployeeperformancerequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditemployeeperformancerequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateemployeeperformancestatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddemployeeperformancecomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancerequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancedepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformanceemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideemployeeperformancestudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveemployeeperformancestudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancesector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancebacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignemployeeperformancebacktoentity
                    })
                } else if (pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewjoinimplementrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditjoinimplementrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatejoinimplementstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddjoinimplementcomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidejoinimplementstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivejoinimplementstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementsector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignjoinimplementbacktoentity
                    })
                } else if (pathName === 'openComplainsStage') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewopencomplainsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditopencomplainsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateopencomplainsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddopencomplainscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideopencomplainsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveopencomplainsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignopencomplainsbacktoentity,
                    })
                } else if (pathName === 'amendsSharedGovernmentHRManagement') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsystemsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditamendsystemsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateamendsystemsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddamendsystemscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideamendsystemsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveamendsystemsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignamendsystemsbacktoentity
                    })
                } else if (pathName === 'reportsAndStaticRequest') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreportsstaticsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditreportsstaticsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdatereportsstaticsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddreportsstaticscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovidereportsstaticsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceivereportsstaticsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignreportsstaticsbacktoentity
                    })
                } else if (pathName === 'otherRequests') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        requestStatus: this.props.data.statusid,
                        requestStatusReason: this.props.data.statusreasonid,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewotherrequestsrequest,
                        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditotherrequestsrequest,
                        canUpdateStatus: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canupdateotherrequestsstatus,
                        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddotherrequestscomments,
                        canAssignGm: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequesttogmsrequesttogm,
                        canAssignDept: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsdepartment,
                        canAssignEmployee: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsemployee,
                        canAddHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canprovideotherrequestsstudy,
                        canViewHraStudy: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreceiveotherrequestsstudy,
                        canAssignSector: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestssector,
                        casAssignBackToDataDepartment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsbacktodepartment,
                        canAssignBackToEntity: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canassignotherrequestsbacktoentity
                    })
                } else if (pathName === 'entity') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'contact') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'lookUp') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'mailTemplate') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                } else if (pathName === 'role') {
                    this.setState({
                        currentUserId: this.state.loginData && this.state.loginData.id,
                        pathName: pathName,
                        accountTypeId: this.state.loginData && this.state.loginData.AccountypeID,
                        canView: 0,
                        canEdit: 1,
                    })
                    
                }
            }
        })
    }

    getLocale = () => {
        let value = localStorage.getItem("Lang") === "ar" ? true : false;
        if (value) {
            return "ar";
        } else {
            return "en";
        }
    };

    getActionbuttonName = () => {
     
        return i18n[this.getLocale()].statusUpdate;
    }

    getActionButtonNameForGmAndStrategic = () => {
     
        let buttonname = i18n[this.getLocale()].assignToGmOffice;
        if (this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire') {
            buttonname = i18n[this.getLocale()].assignToStrategicOffice;
        }
        return buttonname;
    }

    getActionButtonNameForAssignToEntity = () => {
      
        let buttonname = i18n[this.getLocale()].assignBackToEntity;
        if (this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') {
            buttonname = i18n[this.getLocale()].assignToEntity;
        }
        return buttonname;
    }

    render() {
        
     debugger;
        return (
            
            <MDBPopover
                component="button" size="sm" placement={this.props.y > 555 ? "top" : "bottom"}
                popoverBody={i18n[this.getLocale()].actions} className="btn btn-primary btn-sm Ripple-parent gridActionBtn">
                <MDBPopoverHeader>
                    {" "}
                    {i18n[this.getLocale()].select_action}
                </MDBPopoverHeader>
                <MDBPopoverBody
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}>

                    <MDBRow>
                        {
                            this.state.requestStatus === Config.getStatusInProgressId()||this.state.requestStatus === 23 ||
                            this.state.requestStatus === 31 ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getReasonAcceptedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                                null
                                :
                                this.state.canEdit ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.EDIT, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].edit}
                                    </MDBBtn>
                                    :
                                    <span></span>
                        }
                        {
                            this.state.canView ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.VIEW, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].view
                                    }
                                </MDBBtn> :
                                <span></span>
                        }

                    </MDBRow>

               
                    <MDBRow>
                        {
                            
                                this.state.requestStatus === Config.getStatusCompletedId() &&
                                !this.state.loginData.internalcontact && this.props.data.canreopenrequest ?
                              
                                
                                this.state.canEdit ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.Reopen, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].Reopen}
                                    </MDBBtn>
                                    :
                                    <span></span> : null
                        }
                       

                    </MDBRow>

                    {
                        this.state.canEdit || this.state.canView ?
                            <br />
                            :
                            <span></span>
                    }

                    <MDBRow>
                        {
                            this.state.canView ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                  
                                        this.props.manageAction(actions.VIEWDETAILS, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].viewDetail
                                    }
                                </MDBBtn> :
                                <span></span>
                        }

                    </MDBRow>

                    {
                        this.state.canView ?
                            <br />
                            :
                            <span></span>
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId()
                            ?
                            null
                            :
                            this.state.canUpdateStatus && (this.state.requestStatusReason === Config.getReasonForExecutiveOffice()
                                || this.state.requestStatusReason === Config.getReasonForExecutiveOffice() || this.state.accountTypeId === Config.getAccountTypeGMOffice()) ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.UPDATE_STATUS, this.props.index);
                                    }}>
                                    {
                                        this.getActionbuttonName()
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canUpdateStatus ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (((this.props.data.requesttypeid !== Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid !== Config.getExtendReviewObjectiveId()
                                || this.props.data.requesttypeid !== Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid !== Config.getAddAndEditEmployeePerformanceEvaluationId()
                                || this.props.data.requesttypeid !== Config.getJoinAndImplementId() || this.props.data.requesttypeid !== Config.getOpenComplainsStageId()
                                || this.props.data.requesttypeid !== Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid !== Config.getReportAndStaticId()
                                || this.props.data.requesttypeid !== Config.getOtherRequest()) && this.state.accountTypeId !== Config.getAccountTypeDataDeptManager()) || ((this.props.data.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()
                                    || this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                                    || this.props.data.requesttypeid === Config.getJoinAndImplementId() || this.props.data.requesttypeid === Config.getOpenComplainsStageId()
                                    || this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid === Config.getReportAndStaticId()
                                    || this.props.data.requesttypeid === Config.getOtherRequest()) && this.state.accountTypeId === Config.getAccountTypeDataDeptManager())) && this.props.data.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNBACKTOENTITY, this.props.index);
                                    }}>
                                    {
                                        this.getActionButtonNameForAssignToEntity()
                                    }
                                    {/* {i18n[this.getLocale()].assignBackToEntity} */}
                                </MDBBtn>
                                : null
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (((this.props.data.requesttypeid !== Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid !== Config.getExtendReviewObjectiveId()
                                || this.props.data.requesttypeid !== Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid !== Config.getAddAndEditEmployeePerformanceEvaluationId()
                                || this.props.data.requesttypeid !== Config.getJoinAndImplementId() || this.props.data.requesttypeid !== Config.getOpenComplainsStageId()
                                || this.props.data.requesttypeid !== Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid !== Config.getReportAndStaticId()
                                || this.props.data.requesttypeid !== Config.getOtherRequest()) && this.state.accountTypeId !== Config.getAccountTypeDataDeptManager()) || ((this.props.data.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()
                                    || this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                                    || this.props.data.requesttypeid === Config.getJoinAndImplementId() || this.props.data.requesttypeid === Config.getOpenComplainsStageId()
                                    || this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid === Config.getReportAndStaticId()
                                    || this.props.data.requesttypeid === Config.getOtherRequest()) && this.state.accountTypeId === Config.getAccountTypeDataDeptManager())) && this.props.data.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (((this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') && this.state.requestStatusReason === Config.getReasonForStrategicAssignedId()
                                && this.state.accountTypeId === Config.getAccountTypeSPOfiice()) || ((this.props.data.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId() ||this.props.data.requesttypeid === 10925||this.props.data.requesttypeid === 10926  ||
                                    this.props.data.requesttypeid === Config.getHrReviewPolicyRequestTypeId() ||
                                    this.props.data.requesttypeid === Config.getExceptionADHousingRequestTypeId() ||
                                    this.props.data.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId() ||
                                    this.props.data.requesttypeid === Config.getRetirmentRequestTypeId() ||
                                this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) && (this.state.accountTypeId === Config.getAccountResearchSector() || this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager())))
?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOENTITY, this.props.index);
                                    }}>
                                    {
                                        this.getActionButtonNameForAssignToEntity()
                                    }
                                    {/* {i18n[this.getLocale()].assignBackToEntity} */}
                                </MDBBtn>
                                : null
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            // this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId()
                            ?
                            null
                            :
                            this.state.canAssignBackToEntity && (this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') && this.state.requestStatusReason === Config.getReasonForStrategicAssignedId()
                                && this.state.accountTypeId === Config.getAccountTypeSPOfiice() ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.loginData.isadmin===1 &&  this.state.requestStatus != Config.getStatusCompletedId() ?
                            <MDBBtn
                            className="gridActionBtn"
                            color="primary"
                            style={{ color: "var(--btnColor)" }}
                            size="sm"
                            onClick={() => {
                                ;
                                this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                            }}
                        >
                            {i18n[this.getLocale()].assignToSector}
                        </MDBBtn>
                            :
                        (this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire' || this.state.pathName === 'informationRequest') ?

                                
                                this.state.requestStatusReason != Config.getReasonForSectorAssignedId() && 
                                this.state.requestStatusReason != Config.getReasonForStrategicAssignedId()
                                && 
                                this.state.requestStatusReason != Config.getReasonSCIDepartmentAssignedId()
                                 
                                 ?
                              null
                                :
                                this.state.canAssignSector  ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            ;
                                            this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].assignToSector}
                                    </MDBBtn>
                                    :
                                    null
                            :
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                               
                                this.state.requestStatus === Config.getStatusOnHoldId()
                                ?
                                null
                                :
                                ((this.state.canAssignSector && this.props.data.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                    this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            ;
                                            this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].assignToSector}
                                    </MDBBtn>
                                    :
                                    this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ?
                                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies()||this.state.accountTypeId === Config.getAccountTypePolicesSectionMager()||
                                        this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) ||
                                            this.state.accountTypeId === Config.getAccountTypeSectorManager()
                                            ?
                                            null
                                            :
                                            ((this.state.canAssignSector && this.props.data.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                                this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                                <MDBBtn
                                                    className="gridActionBtn"
                                                    color="primary"
                                                    style={{ color: "var(--btnColor)" }}
                                                    size="sm"
                                                    onClick={() => {
                                                        ;
                                                        this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                                    }}
                                                >
                                                    {i18n[this.getLocale()].assignToSector}
                                                </MDBBtn>
                                                :
                                                null
                                        : null

                    }

                    {
                        this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire' || this.state.pathName === 'informationRequest' ?
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ?
                                null
                                :
                                this.state.canAssignSector ?
                                    <br />
                                    :
                                    null
                            :
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                              
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId()
                                ?
                                null
                                :
                                ((this.state.canAssignSector && this.props.data.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                    this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                    < br />
                                    :
                                    this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ?
                                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies()||this.state.accountTypeId === Config.getAccountTypePolicesSectionMager()||this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager()||
                                        this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) ||
                                            this.state.accountTypeId === Config.getAccountTypeSectorManager()
                                            ?
                                            null
                                            :
                                            ((this.state.canAssignSector && this.props.data.assigned === 1) || (this.state.canAssignSector && this.state.accountTypeId === Config.getAccountTypeGMOffice() &&
                                                this.state.requestStatusReason === Config.getReasonForGmAssignedId())) ?
                                                < br />
                                                :
                                                null
                                        : null

                    }

                    {
                        
                        
                        
                            this.state.requestStatus === Config.getStatusCompletedId()   ||
                            this.state.requestStatus === Config.getStatusCanceledId()   ||
                            this.state.requestStatus === Config.getStatusOnHoldId()  ||
                            this.state.requestStatus === Config.getDraftId()  ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId()  ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId()  ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId()  ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId()   ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId()  ||
                            this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId()   ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId()   ?
                            this.state.loginData.id==10340 &&(this.state.requestStatusReason ==152 || this.state.requestStatusReason == 155)
                                ? <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(
                                        this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire'
                                            ? actions.ASSIGNTOSTRATEGICOFFICE : actions.ASSIGNTOGMOFFICE,
                                        this.props.index);
                                }}> {
                                    this.getActionButtonNameForGmAndStrategic()
                                  }
                                  {/* i18n[this.getLocale()].assignToGmOffice */}
                              </MDBBtn>:
                                null
                            :
                            this.state.canAssignGm  && this.props.data.requesttype != Config.getSCILEgalSerbviceId() ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(
                                            this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire'
                                                ? actions.ASSIGNTOSTRATEGICOFFICE : actions.ASSIGNTOGMOFFICE,
                                            this.props.index);
                                    }}>
                                    {
                                      this.getActionButtonNameForGmAndStrategic()
                                    }
                                    {/* i18n[this.getLocale()].assignToGmOffice */}
                                </MDBBtn>
                                :
this.state.loginData.id==10340 &&(this.state.requestStatusReason ==152 || this.state.requestStatusReason == 155)
                                ? <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(
                                        this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire'
                                            ? actions.ASSIGNTOSTRATEGICOFFICE : actions.ASSIGNTOGMOFFICE,
                                        this.props.index);
                                }}> {
                                    this.getActionButtonNameForGmAndStrategic()
                                  }
                                  {/* i18n[this.getLocale()].assignToGmOffice */}
                              </MDBBtn>:
                                null
                                
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                            // this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                            null :
                            this.state.canAssignGm ?
                                <br />
                                :
                                null
                    }

                    {
                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies()||this.state.accountTypeId === Config.getAccountTypePolicesSectionMager()||
                        this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && (this.props.data.assignedemployeeid === 0) && this.props.data.assigned === 1 ?
                            <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(actions.ASSIGNTOSPECIFICEMP, this.props.index);
                                }}
                            >
                                {i18n[this.getLocale()].loackrequest}
                            </MDBBtn>
                            :
                            null
                    }

                    {
                        (this.state.accountTypeId === Config.getAccountTypeEmployeeForPolicies()||this.state.accountTypeId === Config.getAccountTypePolicesSectionMager()||
                        this.state.accountTypeId === Config.getAccountTypeResearchSectionManager() || this.state.accountTypeId === Config.getAccountTypeEmployeeForSalaries() || this.state.accountTypeId === Config.getAccountTypeEmployeeForData() || this.state.accountTypeId === Config.getAccountTypeEmployeeForHRMS() || this.state.accountTypeId === Config.getAccountTypeEmployeeForOrgnisation()) && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && (this.props.data.assignedemployeeid === 0) && this.props.data.assigned === 1 ?
                            <br />
                            :
                            null
                    }

                    {
                        
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            
                            this.state.requestStatusReason === Config.getReasonSCIEmployeeassignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            // this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAssignEmployee ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOEMP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToEmp}
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    {
                        
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonSCIEmployeeassignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAssignEmployee && this.props.data.assigned === 1 ?
                                <br />
                                :
                                null
                    }
 
 {this.state.loginData.isadmin===1 &&  this.state.requestStatus != Config.getStatusCompletedId() ?
   <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                       
                                        this.props.manageAction(actions.ASSIGNTODEP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToDep}
                                </MDBBtn>: 
           
                      
                      this.state.requestStatusReason != Config.getReasonForSectorAssignedId() && 
                      this.state.requestStatusReason != Config.getReasonForStrategicAssignedId() && this.state.requestStatusReason != Config.getReasonForDepartmentAssignedId() && this.state.requestStatusReason != Config.getReasonSCIDepartmentAssignedId()?

                     this.props.data.requesttypeid === Config.getDisciplinaryboardTypeId() && this.state.loginData.id==16 && this.state.canAssignDept ?
                     
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                       
                                        this.props.manageAction(actions.ASSIGNTODEP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToDep}
                                </MDBBtn>
                                
                     :
                           null
                            :
                            this.state.canAssignDept && this.props.data.requesttypeid!=10969 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                       
                                        this.props.manageAction(actions.ASSIGNTODEP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToDep}
                                </MDBBtn>
                                :
                                <span></span>
                                
                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonSCIDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ||
                            this.state.requestStatusReason === Config.getReasonForAssignToEntityId() ?
                            null
                            :
                            this.state.canAssignDept && this.props.data.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {
                        (this.props.data.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.data.requesttypeid === Config.getJoinAndImplementId() || this.props.data.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid === Config.getReportAndStaticId()
                            || this.props.data.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() || this.state.requestStatusReason === Config.getReasonForStudyProvidedId()) && this.props.data.assigneddepartmentid !== Config.getDataDepartmentId()) && this.props.data.assigned === 1 ?
                                this.state.casAssignBackToDataDepartment ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            ;
                                            this.props.manageAction(actions.ASSIGNTODATADEP, this.props.index);
                                        }}>
                                        {
                                            (((this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() || this.state.requestStatusReason === Config.getReasonForStudyProvidedId()) && this.props.data.assigneddepartmentid !== Config.getDataDepartmentId()) && this.props.data.assigned === 1)
                                                ?
                                                i18n[this.getLocale()].sendToDataDep
                                                :
                                                i18n[this.getLocale()].assignToDataDep
                                        }
                                        {/* {i18n[this.getLocale()].assignToDataDep} */}
                                    </MDBBtn>
                                    :
                                    null
                                :
                                null
                            :
                            null
                    }
                    {
                        (this.props.data.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.data.requesttypeid === Config.getJoinAndImplementId() || this.props.data.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid === Config.getReportAndStaticId()
                            || this.props.data.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() || this.state.requestStatusReason === Config.getReasonForStudyProvidedId()) && this.props.data.assigneddepartmentid !== Config.getDataDepartmentId() && this.state.casAssignBackToDataDepartment) && this.props.data.assigned === 1 ?
                                <br />
                                :
                                null
                            :
                            null
                    }

                    {
                        (this.props.data.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.data.requesttypeid === Config.getJoinAndImplementId() || this.props.data.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid === Config.getReportAndStaticId()
                            || this.props.data.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForStudyProvidedId() || this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId()) && (this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager() || this.state.accountTypeId === Config.getAccountPolicesector() || this.state.accountTypeId === Config.getAccountResearchSector() ||this.state.accountTypeId === Config.getAccountTypeSalariesDeptManager() || this.state.accountTypeId === Config.getAccountTypeDataDeptManager() || this.state.accountTypeId === Config.getAccountTypeOrganizationDeptManager() || this.state.accountTypeId === Config.getAccountTypeHRMSDeptManager())) && this.props.data.assigned === 1 && this.state.accountTypeId === Config.getAccountTypeDataDeptManager() ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOSPECIFICDEP, this.props.index);
                                    }}>
                                    {i18n[this.getLocale()].assignToSpecificDep}
                                </MDBBtn>
                                :
                                null
                            :
                            null
                    }

                    {
                        (this.props.data.requesttypeid === Config.getExtendCreateObjectiveId() || this.props.data.requesttypeid === Config.getExtendReviewObjectiveId()
                            || this.props.data.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId() || this.props.data.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()
                            || this.props.data.requesttypeid === Config.getJoinAndImplementId() || this.props.data.requesttypeid === Config.getOpenComplainsStageId()
                            || this.props.data.requesttypeid === Config.getAmendOnSharedGovermentId() || this.props.data.requesttypeid === Config.getReportAndStaticId()
                            || this.props.data.requesttypeid === Config.getOtherRequest())
                            ?
                            ((this.state.requestStatusReason === Config.getReasonForStudyProvidedId() | this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId()) && (this.state.accountTypeId === Config.getAccountTypePoliciesDeptManager() || this.state.accountTypeId === Config.getAccountPolicesector() || this.state.accountTypeId === Config.getAccountResearchSector() ||this.state.accountTypeId === Config.getAccountTypeSalariesDeptManager() || this.state.accountTypeId === Config.getAccountTypeDataDeptManager() || this.state.accountTypeId === Config.getAccountTypeOrganizationDeptManager() || this.state.accountTypeId === Config.getAccountTypeHRMSDeptManager())) && this.props.data.assigned === 1 && this.state.accountTypeId === Config.getAccountTypeDataDeptManager() ?
                                <br />
                                :
                                null
                            :
                            null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.props.data.assignedemployeeid === this.state.currentUserId && 
                            this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.data.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.BACKTOMANAGER, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].backToManager}
                                </MDBBtn>
                                :
                                this.state.loginData.id==10341 && this.state.requestStatusReason==153 ?
                                <MDBBtn
                                className="gridActionBtn"
                                color="primary"
                                style={{ color: "var(--btnColor)" }}
                                size="sm"
                                onClick={() => {
                                    this.props.manageAction(actions.BACKTOMANAGER, this.props.index);
                                }}
                            >
                                {i18n[this.getLocale()].backToManager}
                            </MDBBtn>  :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.props.data.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.data.assigned === 1 ?
                                <br />
                                :
                                null
                    }

                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCanceledId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            (this.state.requestStatusReason === Config.getReasonForSectorAssignedId() &&  this.state.accountTypeId != Config.getAccountTypeCultureandTalentManagementSectorManager()  ) ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                      null
                            :
                               this.state.canAddHraStudy ?
                      this.state.accountTypeId === Config.getAccountTypeCultureandTalentManagementSectorManager()     ||       (this.props.data.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.data.assigned === 1) ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDSTUDY, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].addStudy}
                                    </MDBBtn>
                                    :
                                    null
                                :
                                null

                    }
                    {
                        this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                            this.state.requestStatus === Config.getStatusOnHoldId() ||
                            this.state.requestStatus === Config.getDraftId() ||
                            this.state.requestStatusReason === Config.getReasonForStudyProvidedId() ||
                            this.state.requestStatusReason === Config.getReasonForGmAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForDepartmentAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                            this.state.requestStatusReason === Config.getReasonForReturnToEntityId() ?
                            null
                            :
                            this.state.canAddHraStudy ?
                                this.props.data.assignedemployeeid === this.state.currentUserId && this.state.requestStatusReason === Config.getReasonForEmployeeAssignedId() && this.props.data.assigned === 1 ?
                                    <br />
                                    :
                                    null
                                :
                                null
                    }
                    {
                        this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === 10969 || this.props.data.requesttypeid === Config.getDisciplinaryboardTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire' || this.state.pathName === 'disciplinaryboard'  ?
                        this.state.accountTypeId === 10958 ||
                        this.state.accountTypeId === Config.getAccountTypeCultureandTalentManagementSectorManager() ||
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getReasonAcceptedId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                // this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonInformationFeedbackProvidedId()
                                ?
                                // this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() && (this.props.data.requesttypeid !== Config.getInformationRequestTypeId() && this.props.data.assigned === 1) ?
                                this.state.accountTypeId === 10958 || this.state.accountTypeId === 903 ||
                                this.state.accountTypeId === Config.getAccountTypeCultureandTalentManagementSectorManager() ||
                                this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDREPLY, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].addReply}
                                    </MDBBtn>
                                    :
                                null
                                :
                                // this.state.canProvideFeedback && (this.props.data.requesttypeid !== Config.getInformationRequestTypeId() && this.props.data.assigned === 1) ?
                                this.state.canProvideFeedback ?
                                    < MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDREPLY, this.props.index);
                                        }}>
                                        {i18n[this.getLocale()].addReply}
                                    </MDBBtn>
                                    :
                                    null
                            :
                            null
                    }

                    {
                        this.props.data.requesttypeid === Config.getSciRequestTypeId() || this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest' || this.state.pathName === 'StrategicPlanningforworkforceEnquire' ?
                            this.state.requestStatus === Config.getStatusCompletedId() ||
                                this.state.requestStatus === Config.getStatusCanceledId() ||
                                this.state.requestStatus === Config.getReasonAcceptedId() ||
                                this.state.requestStatus === Config.getStatusOnHoldId() ||
                                this.state.requestStatus === Config.getDraftId() ||
                                this.state.requestStatusReason === Config.getReasonForStrategicAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCIFeedbackProvidedId() ||
                                this.state.requestStatusReason === Config.getReasonForSectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ||
                                // this.state.requestStatusReason === Config.getReasonSCISectorAssignedId() ||
                                this.state.requestStatusReason === Config.getReasonInformationFeedbackProvidedId()
                                ?
                                // this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() && (this.props.data.requesttypeid !== Config.getInformationRequestTypeId() && this.props.data.assigned === 1) ?
                                this.state.accountTypeId === Config.getAccountTypeSPOfiice() && this.state.requestStatusReason === Config.getReasonSCISectorFeedbackProvidedId() ?
                                    <br />
                                    :
                                    null
                                :
                                // this.state.canProvideFeedback && (this.props.data.requesttypeid !== Config.getInformationRequestTypeId() && this.props.data.assigned === 1) ?
                                this.state.canProvideFeedback ?
                                    <br />
                                    :
                                    null
                            :
                            null
                    }

                    <MDBRow>
                        {
                            this.state.canAddComment && this.validateaddcomment()==1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.COMMENT, this.props.index);
                                    }}
                                >
                                    { 
                                        i18n[this.getLocale()].comment
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                        }
                    </MDBRow>
                    {this.state.loginData.isadmin===1 && ( localStorage.getItem('currentPath')!=="entity" &&
 localStorage.getItem('currentPath')!=="lookUp" &&
 localStorage.getItem('currentPath')!=="role" &&
 localStorage.getItem('currentPath')!=="contact" &&
 localStorage.getItem('currentPath')!=="mailTemplate") ? 
                          <MDBRow>
                          <MDBBtn
                                                     className="gridActionBtn"
                                                     color="primary"
                                                     style={{ color: "var(--btnColor)" }}
                                                     size="sm"
                                                     onClick={() => {
                                                   
                                                         this.props.manageAction(actions.Underapproval, this.props.index);
                                                     }}
                                                 >
                                                     {
                                                         i18n[this.getLocale()].Underapproval
                                                     }
                                                 </MDBBtn>
                         </MDBRow>:''
                    }
                    {
                        this.state.loginData.isadmin===1 &&( localStorage.getItem('currentPath')!=="entity" &&
                        localStorage.getItem('currentPath')!=="lookUp" &&
                        localStorage.getItem('currentPath')!=="role" &&
                        localStorage.getItem('currentPath')!=="contact" &&
                        localStorage.getItem('currentPath')!=="mailTemplate") ? 
                        <MDBRow>
                        <MDBBtn
                                                   className="gridActionBtn"
                                                   color="primary"
                                                   style={{ color: "var(--btnColor)" }}
                                                   size="sm"
                                                   onClick={() => {
                                                 
                                                       this.props.manageAction(actions.CloseRequest, this.props.index);
                                                   }}
                                               >
                                                   {
                                                       i18n[this.getLocale()].canCloseRequest
                                                   }
                                               </MDBBtn>
                       </MDBRow> :
    this.state.canCloseRequest && this.props.data.assigneddepartmentid==this.state.userdepartmentid ? 
    <MDBRow>
         <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                  
                                        this.props.manageAction(actions.CloseRequest, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].canCloseRequest
                                    }
                                </MDBBtn>
        </MDBRow>
    :(this.props.data.requesttypeid === Config.getDisciplinaryboardTypeId() && this.state.loginData.id==16) ?

    <MDBRow>
            <MDBBtn
                                       className="gridActionBtn"
                                       color="primary"
                                       style={{ color: "var(--btnColor)" }}
                                       size="sm"
                                       onClick={() => {
                                     
                                           this.props.manageAction(actions.CloseRequest, this.props.index);
                                       }}
                                   >
                                       {
                                           i18n[this.getLocale()].canCloseRequest
                                       }
                                   </MDBBtn>
           </MDBRow>
   :''
}

{this.state.loginData.isadmin===1 && ( localStorage.getItem('currentPath')!=="entity" &&
 localStorage.getItem('currentPath')!=="lookUp" &&
 localStorage.getItem('currentPath')!=="role" &&
 localStorage.getItem('currentPath')!=="contact" &&
 localStorage.getItem('currentPath')!=="mailTemplate") ? 
                          <MDBRow>
                          <MDBBtn
                                                     className="gridActionBtn"
                                                     color="primary"
                                                     style={{ color: "var(--btnColor)" }}
                                                     size="sm"
                                                     onClick={() => {
                                                //    alert(actions.Notification);
                                                         this.props.manageAction(actions.Notification, this.props.index);
                                                     }}
                                                 >
                                                     {
                                                         i18n[this.getLocale()].Notification
                                                     }
                                                 </MDBBtn>
                         </MDBRow>:''
                    }
{
    this.props.data.CanEditComment > 0 && this.props.data.assignedemployeeid ==this.state.loginData.id ? 
    <MDBRow>
         <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                  
                                        this.props.manageAction(actions.EditComment, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].editcomment
                                    }
                                </MDBBtn>
        </MDBRow>
    :''
}
           

                    {/* <MDBBtn
                        className="gridActionBtn"
                        color="primary"
                        style={{ color: "var(--btnColor)" }}
                        size="sm"
                        onClick={() => {
                            this.props.manageAction(actions.FOLLOW, this.props.index);
                        }}>
                        {i18n[this.getLocale()].follow}
                    </MDBBtn> */}
                </MDBPopoverBody>
            </MDBPopover >
        );
    }
}

export default PopOverComponent;
