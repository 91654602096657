import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import i18n from './../i18n';
import Config from './../utils/Config';
import Header from './../components/Header';
import LoadingOverlay from 'react-loading-overlay';

export default class HelpScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isSideBarMinimized: false,
            userEnglishManual: [
                { englishName: ` ${i18n['en'].sci}`, arabicName: ` ${i18n['ar'].sci}` },
                { englishName: ` ${i18n['en'].infoRequest}`, arabicName: ` ${i18n['ar'].infoRequest}` },
                { englishName: ` ${i18n['en'].exceptio_from_company_patient}`, arabicName: ` ${i18n['ar'].exceptio_from_company_patient}` },
                { englishName: ` ${i18n['en'].reviewHrPolices}`, arabicName: ` ${i18n['ar'].reviewHrPolices}` },
                { englishName: ` ${i18n['en'].exemptionFromADHousPolicy}`, arabicName: ` ${i18n['ar'].exemptionFromADHousPolicy}` },
                { englishName: ` ${i18n['en'].secndmentForFedralAgency}`, arabicName: ` ${i18n['ar'].secndmentForFedralAgency}` },
                { englishName: ` ${i18n['en'].exceptionOfSteerRetirementRequest}`, arabicName: ` ${i18n['ar'].exceptionOfSteerRetirementRequest}` },
                { englishName: ` ${i18n['en'].bonusRequest}`, arabicName: ` ${i18n['ar'].bonusRequest}` },
                { englishName: ` ${i18n['en'].amendSalariesScale}`, arabicName: ` ${i18n['ar'].amendSalariesScale}` },
                { englishName: ` ${i18n['en'].amendEmployeeSalary}`, arabicName: ` ${i18n['ar'].amendEmployeeSalary}` },
            ],
            userArabicManual: [
                { englishName: ` ${i18n['en'].sci}`, arabicName: ` ${i18n['ar'].sci}` },
                { englishName: ` ${i18n['en'].infoRequest}`, arabicName: ` ${i18n['ar'].infoRequest}` },
                { englishName: ` ${i18n['en'].exceptio_from_company_patient}`, arabicName: ` ${i18n['ar'].exceptio_from_company_patient}` },
                { englishName: ` ${i18n['en'].reviewHrPolices}`, arabicName: ` ${i18n['ar'].reviewHrPolices}` },
                { englishName: ` ${i18n['en'].exemptionFromADHousPolicy}`, arabicName: ` ${i18n['ar'].exemptionFromADHousPolicy}` },
                { englishName: ` ${i18n['en'].secndmentForFedralAgency}`, arabicName: ` ${i18n['ar'].secndmentForFedralAgency}` },
                { englishName: ` ${i18n['en'].exceptionOfSteerRetirementRequest}`, arabicName: ` ${i18n['ar'].exceptionOfSteerRetirementRequest}` },
                { englishName: ` ${i18n['en'].bonusRequest}`, arabicName: ` ${i18n['ar'].bonusRequest}` },
                { englishName: ` ${i18n['en'].amendSalariesScale}`, arabicName: ` ${i18n['ar'].amendSalariesScale}` },
                { englishName: ` ${i18n['en'].amendEmployeeSalary}`, arabicName: ` ${i18n['ar'].amendEmployeeSalary}` }
            ],
            AdminEnglishManual: [
                { englishName: ` ${i18n['en'].contact}`, arabicName: ` ${i18n['ar'].contact}` },
                { englishName: ` ${i18n['en'].entity}`, arabicName: ` ${i18n['ar'].entity}` },
                { englishName: ` ${i18n['en'].lookUp}`, arabicName: ` ${i18n['ar'].lookUp}` },
                { englishName: ` ${i18n['en'].mailTemplate}`, arabicName: ` ${i18n['ar'].mailTemplate}` },
                { englishName: ` ${i18n['en'].role}`, arabicName: ` ${i18n['ar'].role}` }
            ],
            AdminArabicManual: [
                { englishName: ` ${i18n['en'].contact}`, arabicName: ` ${i18n['ar'].contact}` },
                { englishName: ` ${i18n['en'].entity}`, arabicName: ` ${i18n['ar'].entity}` },
                { englishName: ` ${i18n['en'].lookUp}`, arabicName: ` ${i18n['ar'].lookUp}` },
                { englishName: ` ${i18n['en'].mailTemplate}`, arabicName: ` ${i18n['ar'].mailTemplate}` },
                { englishName: ` ${i18n['en'].role}`, arabicName: ` ${i18n['ar'].role}` }
            ],
            onClickManual1: true,
            onClickManual2: false,
            onClickManual3: false,
            onClickManual4: false
        }
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    getLangName = () => {
        return this.state.isArabic ? 'العربية' : 'English'
    }

    getFlagImage = (lan) => {
        let lanFlag = 'ar';
        if (localStorage.getItem('Lang') !== '') {
            lanFlag = localStorage.getItem('Lang');
        }

        if (this.state.isArabic) {
            if (lan === lanFlag) {
                return <img src={require("./../assets/images/flags/ae.png")} alt="" />
            }
            else {
                return <img src={require("./../assets/images/flags/us.png")} alt="" />
            }
        } else if (this.state.isArabic) {
            return <img src={require("./../assets/images/flags/ae.png")} alt="" />
        } else {
            return <img src={require("./../assets/images/flags/us.png")} alt="" />
        }
    }

    downloadAll = () => {

    }

    onClickEServicesClick = () => {
        this.props.history.push('/faqs');
    }

    onClickHome = () => {
        this.props.history.push('/landing');
    }

    clickOnLogo = () => {
        return Config.getHraLink();
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });

    }

    downloadUserEnDoc = ({item}) => {

    }

    downloadUserArDoc = ({ item }) => {

    }

    downloadAdminEnDoc = ({ item }) => {

    }
    
    downloadAdminArDoc = ({ item }) => {

    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    render() {
        return (
            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} />
                    <MDBRow className="formWhiteContainer" className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <span className="helpHeadings" style={{ direction: this.state.isArabic ? 'rtl' : 'ltr', width: '100%', display: 'flex' }}>
                                    <h6 style={{ color: '#5f6061' }}>{i18n[this.getLocale()].userManual} </h6>
                                </span>
                                <div style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', paddingTop: '2vh' }}>
                                    <span style={{ display: 'flex', direction: this.state.isArabic ? 'rtl' : 'ltr', width: '16%', borderBottom: this.state.onClickManual1 ? '3px solid #40505f' : '0px solid #40505f', paddingBottom: '1vh' }}>
                                        <a onClick={() => { this.setState({ onClickManual1: true, onClickManual2: false, onClickManual3: false, onClickManual4: false, }) }}>
                                            <h6 style={{ color: this.state.onClickManual1 ? '#987bc9' : '#9a9a9a' }}><b>{i18n[this.getLocale()].userManualEnglish} </b></h6>
                                        </a>
                                    </span>
                                    <span style={{ width: '4%' }} />
                                    <span style={{ display: 'flex', direction: this.state.isArabic ? 'rtl' : 'ltr', width: '15%', borderBottom: this.state.onClickManual2 ? '3px solid #40505f' : '0px solid #40505f', paddingBottom: '1vh' }}>
                                        <a onClick={() => { this.setState({ onClickManual1: false, onClickManual2: true, onClickManual3: false, onClickManual4: false, }) }}><h6 style={{ color: this.state.onClickManual2 ? '#987bc9' : '#9a9a9a' }}><b>{i18n[this.getLocale()].userManualArabic} </b></h6> </a>
                                    </span>
                                    <span style={{ width: '5%' }} />
                                    <span style={{ display: 'flex', direction: this.state.isArabic ? 'rtl' : 'ltr', width: '22%', borderBottom: this.state.onClickManual3 ? '3px solid #40505f' : '0px solid #40505f', paddingBottom: '1vh' }}>
                                        <a onClick={() => { this.setState({ onClickManual1: false, onClickManual2: false, onClickManual3: true, onClickManual4: false, }) }}>
                                            <h6 style={{ color: this.state.onClickManual3 ? '#987bc9' : '#9a9a9a' }}> <b>{i18n[this.getLocale()].userManualforAdminEnglish} </b></h6> </a>
                                    </span>
                                    <span style={{ width: '8%' }} />
                                    <span style={{ display: 'flex', direction: this.state.isArabic ? 'rtl' : 'ltr', width: '22%', borderBottom: this.state.onClickManual4 ? '3px solid #40505f' : '0px solid #40505f', paddingBottom: '1vh' }}>
                                        <a onClick={() => { this.setState({ onClickManual1: false, onClickManual2: false, onClickManual3: false, onClickManual4: true, }) }}>
                                            <h6 style={{ color: this.state.onClickManual4 ? '#987bc9' : '#9a9a9a' }}><b>{i18n[this.getLocale()].userManualforAdminArabic} </b></h6> </a>
                                    </span>
                                    <span style={{ width: '8%' }} />
                                </div>
                                <div style={{ width: '100%', display: 'flex', direction: this.state.isArabic ? 'rtl' : 'ltr', borderBottom: '0.5px solid #dfdfdf', padding: '3vh', flexDirection: 'row-reverse' }}>
                                    <span style={{ flexDirection: 'row-reverse' }} onClick={this.downloadAll} >

                                        <a style={{ display: 'flex' }} onClick={this.downloadAll}>
                                            <p className='text'>{i18n[this.getLocale()].downloadAll}</p>
                                            <img style={{ marginLeft: this.state.isArabic ? 0 : '5px', marginRight: this.state.isArabic ? '5px' : 0, maxHeight: '25px', maxWidth: '25px' }}
                                                src={require("./../assets/images/DOWNLOAD_ALL.png")} alt="" />
                                        </a>
                                    </span>
                                </div>
                                <div className="aboutDetail">
                                <div class="ExternalClass27018CB2F3B04281A8AC839151FD09B3\">
                                {
                                    this.state.onClickManual1 ?
                                        this.state.userEnglishManual.map((item, index) => {
                                            return (
                                                <div style={{display:'flex',flexDirection:this.state.isArabic ?'row-reverse' :'row',width:'100%',borderBottom:'0.5px solid #dfdfdf',}}>  
                                                    <span style={{display:'flex',direction:this.state.isArabic ?'rtl' :'ltr',width:'50%'}}>
                                                    <p className='text' style={{fontSize:'16px',color:'#9a9a9a'}}> {this.state.isArabic ? item.arabicName : item.englishName}</p>  
                                                    </span>
                                                    <span style={{width:'45%'}}>
                                                    </span>   
                                                    <span style={{width:'5%'}}> 
                                                    <a onClick={()=>this.downloadUserEnDoc(item)}> 
                                                    <img onClick={()=>this.downloadUserEnDoc(item)} 
                                                        style={{marginLeft:this.state.isArabic ?0:'5px',marginRight:this.state.isArabic ?'5px':0,maxHeight:'25px',maxWidth:'25px'}} 
                                                        src={require("./../assets/images/download_one.png")} alt="" />
                                                    </a>
                                                    </span>
                                                </div>
                                            )
                                        })
                                        : 
                                        this.state.onClickManual2 ?
                                            this.state.userArabicManual.map((item, index) => {
                                                return (
                                                    <div style={{display:'flex',flexDirection:this.state.isArabic ?'row-reverse' :'row',width:'100%',borderBottom:'0.5px solid #dfdfdf'}}>  
                                                        <span style={{display:'flex',direction:this.state.isArabic ?'rtl' :'ltr',width:'50%'}}>
                                                        <p className='text' style={{fontSize:'16px',color:'#9a9a9a'}}> {this.state.isArabic ? item.arabicName : item.englishName}</p>   
                                                        </span>
                                                        <span style={{width:'45%'}}>
                                                        </span>   
                                                        <span style={{width:'5%'}}>
                                                            <a onClick={()=>this.downloadUserArDoc(item)}>
                                                            <img onClick={()=>this.downloadUserArDoc(item)} 
                                                                style={{marginLeft:this.state.isArabic ?0:'5px',marginRight:this.state.isArabic ?'5px':0,maxHeight:'25px',maxWidth:'25px'}} 
                                                            src={require("./../assets/images/download_one.png")} alt="" /></a>
                                                        </span>
                                                    </div>
                                                )                       
                                        })
                                        : this.state.onClickManual3 ?
                                            this.state.AdminEnglishManual.map((item, index) => {
                                                return (
                                                    <div style={{display:'flex',flexDirection:this.state.isArabic ?'row-reverse' :'row',width:'100%',borderBottom:'0.5px solid #dfdfdf'}}>  
                                                        <span style={{display:'flex',direction:this.state.isArabic ?'rtl' :'ltr',width:'50%'}}>
                                                        <p className='text' style={{fontSize:'16px',color:'#9a9a9a'}}> {this.state.isArabic ? item.arabicName : item.englishName}</p>    
                                                        </span>
                                                        <span style={{width:'45%'}}>
                                                        </span>   
                                                        <span style={{width:'5%'}}>
                                                            <a onClick={()=>this.downloadAdminEnDoc(item)} >
                                                                <img onClick={()=>this.downloadAdminEnDoc(item)} 
                                                                style={{marginLeft:this.state.isArabic ?0:'5px',marginRight:this.state.isArabic ?'5px':0,maxHeight:'25px',maxWidth:'25px'}} 
                                                                src={require("./../assets/images/download_one.png")} alt="" /></a>
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        : this.state.onClickManual4 ?
                                            this.state.AdminArabicManual.map((item, index) => {
                                                return (
                                                    <div style={{display:'flex',flexDirection:this.state.isArabic ?'row-reverse' :'row',        width:'100%',borderBottom:'0.5px solid #dfdfdf'}}>  
                                                        <span style={{display:'flex',direction:this.state.isArabic ?'rtl' :'ltr',width:'50%'}}>
                                                            <p className='text' style={{fontSize:'16px',color:'#9a9a9a'}}> {this.state.isArabic ? item.arabicName : item.englishName}</p>  
                                                        </span>
                                                        <span style={{width:'45%'}}>
                                                        </span>   
                                                        <span style={{width:'5%'}}>
                                                            <a onClick={()=>this.downloadAdminArDoc(item)}> 
                                                            <img style={{marginLeft:this.state.isArabic ?0:'5px',maxHeight:'25px',               marginRight:this.state.isArabic ?'5px':0,maxWidth:'25px'}} 
                                                                src={require("./../assets/images/download_one.png")} alt="" />
                                                            </a>
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        : null
                                }
                                </div>
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </LoadingOverlay>
        </MDBContainer>
    );
    }
}