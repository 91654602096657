import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import { assginGmPayload } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import ToastComponent from '../ToastComponent';
import DatePicker from "react-datepicker";
import { TrainRounded } from '@material-ui/icons';

export default class ExtendDateReplayComponent extends Component {

    constructor(props) {
        super(props)
        this.state =
        {
            isLoading: false,
            comment: '',
            requestType: '',
            pathName: '',
            id: '',
            decisionerr: '',
            notes: '',
            decisionar: ["موافقة", "رفض"],
            decisionen: ["Accept", "ٌRefuse"],
            decisioncurrent: [],
            decision: 0,
           
            fromDate:'',
            todate:'',
            reason:''
        }
    }

    componentDidMount = () => {

        console.log("data : ", this.props.data);
        this.Load();
        this.props.isArabic ? this.setState({ decisioncurrent: this.state.decisionar }) :
            this.setState({ decisioncurrent: this.state.decisionen });

    }


    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    Load = () => {
        let obj = {};
        let endPoint = 'GetinformationrequestbyID';
        let payload = assginGmPayload(obj);
        payload.id = this.props.id;
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {

                this.setState({
                    isLoading: false
                }, () => {

                    if (res.Status) {
                        // this.props.toggle(true);
                        console.log("res : ", res);
                        debugger;
                         this.setState({ fromDate: res.data.datettoreply })
                        this.setState({ toDate: res.data.extendeddate })
                        this.setState({ reason: res.data.extenddatereason })


                    } else {

                        // this.props.toggle(false);
                        ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoloadextend);
                    }
                })
            })
        })

    }
    submitHandler = (event) => {
        event.preventDefault();
        let result = true;
debugger;
        if (this.state.comment === '' && this.state.decision === "1") {
            this.setState({ notes: "thisFieldIsRequired" });
            result = false;
        }
        if (this.state.decision === '') {
            this.setState({ decisionerr: "thisFieldIsRequired" });
            result = false;
        }

        if (result) {
            event.target.className += " was-validated";
            let payload = {};
            payload.requestid = this.props.id;
            // obj.requesttype = this.state.requestType;
            // obj.requesttype = this.props.requesttypeid;
            payload.comment = this.state.comment;
            payload.decision = this.state.decision;

            let endPoint = 'ExtendInfoDateReplay';

            let userid = Config.getLocalStorageLoginData().id;
            payload.userid = userid;
            debugger;
            let url = Config.getUrl(endPoint);

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                    debugger;
                    this.setState({
                        isLoading: false
                    }, () => {

                        if (res) {
                            ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].savedsuccessfully);

                            this.props.toggle(true);
                        } else {

                            this.props.toggle(false);
                            ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoextend);
                        }
                    })
                })
            })
        }
        else
            return;

    };

    changecomment = (value) => {

        this.setState({ comment: value });
        this.setState({ notes: '' });

    }

    handledecision = (value) => {
        this.setState({ decision: value, decisionerr: '' });
       if(value === '0')  
          this.setState({ notes: "" });
    }
    render() {
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBContainer style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>

                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                //noValidate
                >

                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>

                        <MDBRow className="formRow">

                            <MDBCol md="12">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].exxtendatereason}
                                        
                                    </label>
                                </div>
                            </MDBCol>
                            <MDBCol md="12">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{this.state.reason}
                                        
                                    </label>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="formRow">




                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].toDate}
                                    </label>

                                    <div style={{ width: '100%' }}
                                        className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.lastTimeToReplyErr ? "datePickerBorderColorErr" : ""}`}>

                                        <label htmlFor="formGroupExampleInput"
                                            style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}
                                        >
                                            {this.state.toDate}
                                        </label>
                                    </div>

                                </div>
                            </MDBCol>
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].fromDate}
                                    </label>

                                    <div style={{ width: '100%' }}
                                        className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.lastTimeToReplyErr ? "datePickerBorderColorErr" : ""}`}>

                                        <label htmlFor="formGroupExampleInput"
                                            style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}
                                        >
                                            {this.state.fromDate}
                                        </label>
                                    </div>

                                </div>
                            </MDBCol>



                        </MDBRow>

                        <MDBRow className="formRow">
                        <MDBCol md="6"></MDBCol>
                            <MDBCol md="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].decision}
                                        {/* <span className="requireStar">*</span> */}
                                    </label>

                                    <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                        className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.lastTimeToReplyErr ? "datePickerBorderColorErr" : ""}`}>
                                        <select
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                            className="browser-default custom-select"
                                            id="sources-funding"
                                            onChange={(e) => this.handledecision(e.target.value)}
                                            required
                                            // name="typeOfRequest"
                                            value={this.state.decision}
                                        >
                                            {


                                                this.state.decisioncurrent.map((item, index) =>
                                                    <option key={index} value={index}>{item}</option>
                                                )
                                            }
                                        </select>
                                    </div>

                                </div>
                                {
                                    this.state.lastTimeToReplyErr === "thisFieldIsRequired" ?

                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                            </MDBCol>

                        </MDBRow>
                        <MDBRow className="formRow">
                            <MDBCol md="12"  >
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        <span className="requireStar">*</span>
                                    </label>

                                    
                                    <textarea
                                        className="form-control" id="exampleFormControlTextarea2" rows="2" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                            //required
                                            onChange={(event) => this.changecomment(event.target.value)}
                                        />
                                  

                                </div>
                                {
                                    this.state.notes === "thisFieldIsRequired" ?

                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                            </MDBCol>

                        </MDBRow>

                        <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }} >
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].submit}
                            </MDBBtn>
                            <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
                        </MDBRow>

                    </LoadingOverlay>

                </form>

            </MDBContainer>
        );
    }
}

