import React, { Component } from 'react';

import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';

const striptags = require('striptags');
let moment = require('moment');

export class RequestFunctions extends Component{

// Admin Roles

addAdminRole = () => {
    let obj = {};
    obj.value = "";
  
    this.setState({
        AdminRoles: this.state.AdminRoles.concat(obj),
        view: this.state.view + 1
    });
}

deleteAdminRole = (index) => {
    var array = this.state.AdminRoles;
    var index = array.indexOf(index);
    if (array && array.length > 1) {
        if (index === -1) {
            if (array && array.length > 1) {
                array.splice(index, 1);
                this.setState({ AdminRoles: array });
            } else {
                array.splice(index, 1);
                if (index > 0) {
                    this.setState({ AdminRoles: array });
                }
            }
        }
    }
}

// Orgainzational Roles

addOrgainzationalRole = () => {
    let obj = {};
    obj.value = "";
  
    this.setState({
        OrgainzationalRole: this.state.OrgainzationalRole.concat(obj),
        view: this.state.view + 1
    });
}

deleteOrgainzationalRole = (index) => {
    var array = this.state.OrgainzationalRole;
    var index = array.indexOf(index);
    if (array && array.length > 1) {
        if (index === -1) {
            if (array && array.length > 1) {
                array.splice(index, 1);
                this.setState({ OrgainzationalRole: array });
            } else {
                array.splice(index, 1);
                if (index > 0) {
                    this.setState({ OrgainzationalRole: array });
                }
            }
        }
    }
}

// Functional Roles

addFunctionallRole = () => {
    let obj = {};
    obj.value = "";
  
    this.setState({
        FunctionalRole: this.state.FunctionalRole.concat(obj),
        view: this.state.view + 1
    });
}

deleteFunctionalRole = (index) => {
    var array = this.state.FunctionalRole;
    var index = array.indexOf(index);
    if (array && array.length > 1) {
        if (index === -1) {
            if (array && array.length > 1) {
                array.splice(index, 1);
                this.setState({ FunctionalRole: array });
            } else {
                array.splice(index, 1);
                if (index > 0) {
                    this.setState({ FunctionalRole: array });
                }
            }
        }
    }
}

getRequestInfo=(requestid)=>{

    
    let obj={};
    obj.id=requestid;
    let payload = obj;
    let endpoint = 'GetOrganizationStructurebyID';
    let url = Config.getUrl(endpoint);

     ApiRequest.sendRequestForPost(url, payload, res => {
          
     if (res.Status) {
    
      let info = res.data; 
       debugger;
       this.setState({
       nameen : res.data.nameen,
       namear :  res.data.namear,
       typeid : 1,
       employeeName:res.data.employeename,
       contactNumber:res.data.contactnumber,
       requestDetails:res.data.requestdetails,
       requestdate :res.data.requestdate,
       //entityName : res.data.entityname,
       email : res.data.email,

       requestreasonfilename:res.data.requestreasonfilename,
       entityformalrequestfilename:res.data.entityformalrequestfilename,
       entityestablishmentLawfilename:res.data.entityestablishmentLawfilename,
       otherattachmentfilename:res.data.otherattachmentfilename,


       requestreasonfileref:res.data.requestreasonfileref,
       entityformalrequestfileref:res.data.entityformalrequestfileref,
       entityestablishmentLawfileref:res.data.entityestablishmentLawfileref,
       otherattachmentfileref:res.data.otherattachmentfileref


    }); 

    let TasksAndSpecificationsTemplatefilearray = {};
    let OrganizationStrucutreOperationalTemplatefilearray = {};
    let CurrentOrganizationStructurefilearray = {};
    let SuggestedOrganizationStructurefilearray = {};

    TasksAndSpecificationsTemplatefilearray.url = res.data.TasksAndSpecificationsTemplatefileref;
    TasksAndSpecificationsTemplatefilearray.requestId = res.data.id;
    TasksAndSpecificationsTemplatefilearray.fileName = res.data.TasksAndSpecificationsTemplatefilename;

    
     OrganizationStrucutreOperationalTemplatefilearray.url = res.data.OrganizationStrucutreOperationalTemplatefileref;
     OrganizationStrucutreOperationalTemplatefilearray.requestId = res.data.id; 
     OrganizationStrucutreOperationalTemplatefilearray.fileName = res.data.OrganizationStrucutreOperationalTemplatefilename;

     CurrentOrganizationStructurefilearray.url = res.data.CurrentOrganizationStructurefileref;
     CurrentOrganizationStructurefilearray.requestId = res.data.id; 
     CurrentOrganizationStructurefilearray.fileName = res.data.CurrentOrganizationStructurefilename;

     SuggestedOrganizationStructurefilearray.url = res.data.SuggestedOrganizationStructurefileref;
     SuggestedOrganizationStructurefilearray.requestId = res.data.id; 
     SuggestedOrganizationStructurefilearray.fileName = res.data.SuggestedOrganizationStructurefilename;

     let reasonfilearray = {};
     reasonfilearray.url = res.data.requestreasonfileref;
     reasonfilearray.requestId = res.data.id;
     reasonfilearray.fileName = res.data.requestreasonfilename;

      let formalfilearray = {};
      formalfilearray.url = res.data.entityformalrequestfileref;
      formalfilearray.requestId = res.data.id; 
      formalfilearray.fileName = res.data.entityformalrequestfilename;

      let Lawfilearray = {};
      Lawfilearray.url = res.data.entityestablishmentLawfileref;
      Lawfilearray.requestId = res.data.id; 
      Lawfilearray.fileName = res.data.entityestablishmentLawfilename;

      let otherfilearray = {};
      otherfilearray.url = res.data.otherattachmentfileref;
      otherfilearray.requestId = res.data.id; 
      otherfilearray.fileName = res.data.otherattachmentfilename;


    this.state.RequestReasonFileObjArray.push(reasonfilearray);
    this.state.EntityFormalRequestFileObjArray.push(formalfilearray);

    this.state.EntityEstablishmentLawFileObjArray.push(Lawfilearray);
    this.state.otherAttachmentFileObjArray.push(otherfilearray);


  

    this.state.TasksAndSpecificationsTemplateObjArray.push(TasksAndSpecificationsTemplatefilearray);
    this.state.OrganizationStrucutreOperationalTemplateFileObjArray.push(OrganizationStrucutreOperationalTemplatefilearray);

    this.state.CurrentOrganizationStructureFileObjArray.push(CurrentOrganizationStructurefilearray);
    this.state.SuggestedOrganizationStructureFileObjArray.push(SuggestedOrganizationStructurefilearray);

    }


  });

};


}
export default RequestFunctions;