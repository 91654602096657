import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class SickLeaveForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
        isEdit: '',
        statusid: '',
        isNextScreenActive: false,
        isfirstPageValidate: false,
        isNextClick: false,
        isArabic: true,
        isSubmit: false,
        totalDays: this.props.sendData.totalDays,
        myTemp: this.props.sendData.myTemp,
        dateOfStartingWork: this.props.sendData.dateOfStartingWork,
            
        totalAmountExempted: this.props.sendData.totalAmountExempted,
        HowToCalculate: this.props.sendData.HowToCalculate,
        dateOfSalaryTermination: this.props.sendData.dateOfSalaryTermination,
        violation: this.props.sendData.violation,
        violationErr: '',
        dateOfStartingWorkErr: '',
        explain: this.props.sendData.explain,
        administrativeProcedures: this.props.sendData.tableData,
        view: this.props.sendData.view,
        status: [],
        tempTableData: [],
        isStatusTerminated: [],
       
        explainErr: '',
        howToCalculateErr: '',
        totalAmtExmErr: "",
        intervalsErr: '',
        salaryStatusArr: [
            {
                id: 1,
                EnglishName: 'Continuous',
                ArabicName: 'مستمر'
            },
            {
                id: 2,
                EnglishName: 'Stop',
                ArabicName: 'متوقف'
            }
        ],
        statusArr: [
            {
                id: 2,
                EnglishName: 'Paid',
                ArabicName: 'مدفوع'
            },
            {
                id: 1,
                EnglishName: 'Terminated',
                ArabicName: 'منتهي'
            }
        ],
        monthArray: [
            {
                id: 1,
                EnglishName: 'January',
                ArabicName: 'يناير'
            },
            {
                id: 2,
                EnglishName: 'February',
                ArabicName: 'فبراير'
            },
            {
                id: 3,
                EnglishName: 'March',
                ArabicName: 'مارس'
            },
            {
                id: 4,
                EnglishName: 'April',
                ArabicName: 'ابريل'
            },
            {
                id: 5,
                EnglishName: 'May',
                ArabicName: 'مايو'
            },
            {
                id: 6,
                EnglishName: 'June',
                ArabicName: 'يونيو'
            },
            {
                id: 7,
                EnglishName: 'July',
                ArabicName: 'يوليو'
            },
            {
                id: 8,
                EnglishName: 'August',
                ArabicName: 'اغسطس'
            },
            {
                id: 9,
                EnglishName: 'September',
                ArabicName: 'سبتمبر'
            },
            {
                id: 10,
                EnglishName: 'October',
                ArabicName: 'اكتوبر'
            },
            {
                id: 11,
                EnglishName: 'November',
                ArabicName: 'نوفمبر'
            },
            {
                id: 12,
                EnglishName: 'December',
                ArabicName: 'ديسمبر'
            }
        ],
        basicSalaryTable: this.props.sendData.basicSalaryTable,
        year: "",
        month: "1",
        fromDay: "",
        toDay: "",
        salaryStatus: "Continuous",
        subTotal: "",
        noOfDays: "",
        subTotalTotal: this.props.sendData.subTotalTotal,
        totalNoOfDays: this.props.sendData.totalNoOfDays,
        totalSalaryWithSubscription: this.props.sendData.totalSalaryWithSubscription,
        retirementSalary: this.props.sendData.retirementSalary,
        tswsrsSalary: this.props.sendData.tswsrsSalary,
        yearErr: "",
        monthErr: "",
        fromDayErr: "",
        toDayErr: "",
        subTotalErr: "",
        noOfDaysErr: "",
        basicSalErr: "",
        retirementSalaryErr: "",
            requestId: '',
            isEdit: '',
            statusid: '',
            steps: this.props.steps,
            dateBeforFiveYear: '',
            dateAfterOneYear: '',
            dateOfSalaryTerminationErr: "",
            dateOfDirectWorkErr: "",
            officialLetdateOfSalaryTerminationterFileObj: null,
            officialLetterErr: "",
            officialLetter: this.props.sendData.officialLetter,
            empJob: this.props.sendData.empJob,
            headlinesOfRequest: this.props.sendData.headlinesOfRequest,
            employeeName: this.props.sendData.employeeName,
            grade: this.props.sendData.grade,
            basicSalary: this.props.sendData.basicSalary,
            unifiedNumber: this.props.sendData.unifiedNumber,
            totalSalary: this.props.sendData.totalSalary,
            retirementContributions: this.props.sendData.retirementContributions,
            durationOfAccompanyingLeave: this.props.sendData.durationOfAccompanyingLeave,
            dateOfSalaryTermination: this.props.sendData.dateOfSalaryTermination,
            dateOfDirectWork: this.props.sendData.dateOfDirectWork,
            requiredToPayBudget: this.props.sendData.requiredToPayBudget,
            selectedOption2: this.props.sendData.selectedOption2,
            selectedOption2Err: '',
            selectedOptionErr: '',
            budgetYear: this.props.sendData.budgetYear,
            budgetAvailabilityYear: this.props.sendData.budgetAvailabilityYear,
            budgetValue: this.props.sendData.budgetValue,
            annualBalanceOfVacations: this.props.sendData.annualBalanceOfVacations,
            justificationsEmployee: this.props.sendData.justificationsEmployee,
            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            budgetYearErr: '',
            budgetAvailabilityYearErr: '',
            SalaryCointiunousOption:'',
            justificationsEmployeeErr: '',
            emiratesIDStepOne: this.props.sendData.emiratesIDStepOne,
            emiratesIDStepOneErr: '',
            exemptionSelectedValue: this.props.sendData.exemptionSelectedValue,
           // requestType: this.props.sendData.requestType,
            Resonforfirstandsecond:'',
    
          

            totalSalaryErr: "",
            basicSalErr: "",
            retirmentContErr: "",
            budgetValueErr: "",
            //requestTypeLabel: this.props.sendData.requestTypeLabel,
            // Degreeofrelationshiplabel: this.props.sendData.Degreeofrelationshiplabel,
            // Degreeofrelationship:this.props.sendData.Degreeofrelationship,
            // DegreeofrelationshipErr:"",
        }
        this.handleDateOfSalaryTermination = this.handleDateOfSalaryTermination.bind(this);
        this.handleDateOfDirectWork = this.handleDateOfDirectWork.bind(this);
        this.handleDateFrom1 = this.handleDateFrom1.bind(this);
    this.handleDateTo1 = this.handleDateTo1.bind(this);

    this.handleDateFrom2 = this.handleDateFrom2.bind(this);
    this.handleDateTo2 = this.handleDateTo2.bind(this);

    this.handleDateFrom3 = this.handleDateFrom3.bind(this);
    this.handleDateTo3 = this.handleDateTo3.bind(this);
    }

    handleAmountExempted = (value) => {
        if (value) {
          
            this.setState({ totalAmountExempted: value.formattedValue, totalAmtExmErr: "" });
        }
    };

    handletotalSalaryWithSubscriptionTable = (value) => {
        if (value) {
            this.setState({
                totalSalaryWithSubscription: value.value,
                totalSalaryWithSubscriptionErr: ""
            }, () => {
                this.calculateTswsRsSalary();
            });

        }
    };

    handleretirementSalaryTable = (value) => {
        if (value) {
            this.setState({
                retirementSalary: value.value,
                retirementSalaryErr: ""
            }, () => this.calculateTswsRsSalary());
        }
    };

    handletswsrsSalaryTable = (value) => {
        if (value) {
         
            this.setState({ tswsrsSalary: value.value, tswsrsSalaryErr: "" });
        }
    };

    handleHowToCalculate = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                howToCalculateErr: '',
                HowToCalculate: newContent
            })
        } else{
            this.setState({
                howToCalculateErr: "ckeditorCountErr"
            });
        }
    }

    handleExplainOnChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                explainErr: "",
                explain: newContent
            })
        }
        else {
            this.setState({
                explainErr: "ckeditorCountErr"
            })
        }
    }

    handleBasicSalaryTable = (value) => {
        if (value) {
          
            this.setState({ basicSalaryTable: value.value, basicSalErr: "" });
        }

    }

    
    handleDateFrom1 = (item, date) => {
        if (date) {
            item.fromDate1 = date;
            item.toDate1IsDisabled = false;
            this.calculateNoOfDays(item);
        } else {
            item.toDate1IsDisabled = true;
        }
     
    };

    handleDateTo1 = (item, date) => {
        if (item.fromDate1) {
            item.toDate1 = date;
            this.calculateNoOfDays(item);
        }
    }

    handleDateFrom2 = (item, date) => {
        this.setState({ toDate2IsDisabled: false }, () => {
            item.fromDate2 = date;
            this.calculateNoOfDays(item);
        })
    };

    handleDateTo2 = (item, date) => {
        if (item.fromDate2) {
            item.toDate2 = date;
            this.calculateNoOfDays(item);
        }
        else {
            this.setState({ toDate2IsDisabled: true })
        }

    }

    handleDateFrom3 = (item, date) => {
        this.setState({ toDate3IsDisabled: false }, () => {
            item.fromDate3 = date;
            this.calculateNoOfDays(item);
        })

    };

    handleDateTo3 = (item, date) => {
        if (item.fromDate3) {
            item.toDate3 = date;
            this.calculateNoOfDays(item);
        }
        else {
            this.setState({ toDate3IsDisabled: true })
        }
    }

    calculateNoOfDays = (item) => {

        let dateCal1 = 0, dateCal2 = 0, dateCal3 = 0;
        //Calculate from date1-3 & todate 1-3, then assign to numOfDays
        if (item.fromDate1 !== null && item.toDate1 !== null) {
            dateCal1 = Math.abs((item.fromDate1 - item.toDate1) / (1000 * 60 * 60 * 24));
        }
        if (item.fromDate2 !== null && item.toDate2 !== null) {
            dateCal2 = Math.abs((item.fromDate2 - item.toDate2) / (1000 * 60 * 60 * 24));
        }
        if (item.fromDate3 !== null && item.toDate3 !== null) {
            dateCal3 = Math.abs((item.fromDate3 - item.toDate3) / (1000 * 60 * 60 * 24));
        }

        item.numOfDays = parseInt(dateCal1) + parseInt(dateCal2) + parseInt(dateCal3);

        //ReCalculate total no of days & total salary to be payed
        let array = this.state.administrativeProcedures;
        let tempTotalNoOfDays = 0;

        for (let i = 0; i < array.length; i++) {
            if (array[i].numOfDays > 0) {
                tempTotalNoOfDays = parseFloat(array[i].numOfDays + tempTotalNoOfDays);
            }

        }

        this.setState({
            totalDays: tempTotalNoOfDays,
            administrativeProcedures: this.state.administrativeProcedures
        });

    };

    addView = () => {

        let obj = {};
        obj.year = "";
        obj.month = "1";
        obj.fromDay = "";
        obj.toDay = "";
        obj.salaryStatus = "Continuous";
        obj.subTotal = "";
        obj.noOfDays = "";
        obj.subTotalTotal = "";
        obj.totalNoOfDays = "";
        obj.ref = this.state.view;
        obj.yearErr = "";
        obj.monthErr = "";
        obj.fromDayErr = "";
        obj.toDayErr = "";
        obj.subTotalErr = "";
        obj.noOfDaysErr = "";

        if (this.state.administrativeProcedures.length < 40) {
            this.setState({
                administrativeProcedures: this.state.administrativeProcedures.concat(obj),
                view: this.state.view + 1
            });
        }

    }

    deleteView = (indexVal) => {
        var array = this.state.administrativeProcedures;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        administrativeProcedures: array
                    });

                    //ReCalculate total no of days & total salary to be payed
                    let tempTotalNoOfDays = 0;
                    let tempSubTotalTotal = 0;

                    for (let i = 0; i < array.length; i++) {
                   
                        if (array[i].noOfDays > 0) {
                            tempTotalNoOfDays = parseInt(array[i].noOfDays) + tempTotalNoOfDays;
                        }
                        if (array[i].subTotal > 0) {
                            tempSubTotalTotal = parseFloat(parseFloat(array[i].subTotal) + tempSubTotalTotal);
                        }
                    }
                    this.setState({
                        totalNoOfDays: tempTotalNoOfDays,
                        subTotalTotal: tempSubTotalTotal
                    });
                    // this.setState({
                    //     totalDays: tempTotalNoOfDays,
                    //     myTemp: tempSubTotalTotal
                    // });
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            administrativeProcedures: array
                        });
                        //ReCalculate total no of days & total salary to be payed
                        let tempTotalNoOfDays = 0;
                        let tempSubTotalTotal = 0;

                        for (let i = 0; i < array.length; i++) {
                           
                            if (array[i].numOfDays > 0) {
                                tempTotalNoOfDays = parseFloat(array[i].numOfDays + tempTotalNoOfDays);
                            }
                            if (array[i].subTotal > 0) {
                                tempSubTotalTotal = parseFloat(parseFloat(array[i].subTotal) + tempSubTotalTotal);
                            }
                        }
                        this.setState({
                            totalNoOfDays: tempTotalNoOfDays,
                            subTotalTotal: tempSubTotalTotal
                        });
                    }
                }
            }
        }

    }

    handleViolationChange = (e) => {
        debugger;
        this.setState({
            violation: e.target.value,
            violationErr: ''
        });
    };

    handleOnCHangeYear = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ year: event.target.value, yearOneErr: '' });
            }
        } else {
            this.setState({ year: '', yearOneErr: 'thisFieldIsRequired' });
        }
    };

    daysInMonth = (month, year) => {
      
        //return new Date(year, month, 0).getDate();
        return moment(year + "-" + month, "YYYY-MM").daysInMonth();
    }

    calculateTswsRsSalary = () => {
        debugger;

        let temptotalSalaryWithSubscription =  '0';
        if (this.state.totalSalaryWithSubscription) {
            temptotalSalaryWithSubscription = this.state.totalSalaryWithSubscription;
        } else {
            temptotalSalaryWithSubscription =  '0';
        }

        let tempretirementSalary = '0';
        if (this.state.retirementSalary) {
            tempretirementSalary = this.state.retirementSalary;
        } else {
            tempretirementSalary = '0';
        }

        let temp = parseFloat(temptotalSalaryWithSubscription.replace(',','')) + parseFloat(tempretirementSalary.replace(',',''));
      

        if (temp ===  '0') {
            temp = '';
        }

        this.setState({
            tswsrsSalary: temp
        }, () => {
            // this.recalculateSubTotalAndNoOfDaysAfterChangeTotalSalary();
        });
    }

    changeNoOfDays = (fromDay, toDay, item) => {
        if (fromDay && toDay) {
            if (fromDay <= toDay) {
                let temp = toDay - fromDay;
                item.noOfDays = temp;
                this.setState({ noOfDays: temp });
            }
        }
    }

    //When edit Recalculate Subtotal & NoOfDays
    recalculateSubTotalAndNoOfDaysWhenEdit = () => {
        //Here we have to calculate total no of days & Total of sub-total
        let tempNoOfDays = 0;
        let tempsubTotal = 0;
        let tempTableData = this.state.tempTableData;

        if (tempTableData) {
            for (let i = 0; i < tempTableData.length; i++) {
             

                if (tempTableData[i].noOfDays) {
                    tempNoOfDays = tempNoOfDays + parseInt(tempTableData[i].noOfDays);
                }
                if (tempTableData[i].subtotal) {
                    tempsubTotal = tempsubTotal + parseFloat(tempTableData[i].subtotal);
                } else {
                    if (tempTableData[i].subTotal) {
                        tempsubTotal = tempsubTotal + parseFloat(tempTableData[i].subTotal);
                    }
                }
                if (i == tempTableData.length - 1) {
                    this.setState({
                        totalNoOfDays: tempNoOfDays,
                        subTotalTotal: tempsubTotal.toFixed(2)
                    });
                }
            }
        }
    }

    recalculateSubTotalAndNoOfDays = (item, daysInMonth) => {
        let temp = item.toDay - item.fromDay;
        if (temp > 0) {
            item.noOfDays = temp + 1;
        } else {
            item.noOfDays = temp;
        }

        this.setState({
            noOfDays: temp
        }, () => {

            //Here we have to calculate total no of days
            let tempNoOfDays = 0;
            let tempsubTotal = 0;
            for (let i = 0; i < this.state.administrativeProcedures.length; i++) {
                if (this.state.administrativeProcedures[i].noOfDays != "") {
                    tempNoOfDays = tempNoOfDays + parseInt(this.state.administrativeProcedures[i].noOfDays);
                }
                if (this.state.administrativeProcedures[i].subTotal != "") {
                    tempsubTotal = tempsubTotal + parseFloat(this.state.administrativeProcedures[i].subTotal);
                }
                if (i == this.state.administrativeProcedures.length - 1) {
                    this.setState({
                        totalNoOfDays: tempNoOfDays,
                        subTotalTotal: tempsubTotal.toFixed(2)
                    });
                }
            }

        })

    }

    recalculateSubTotalAndNoOfDaysAfterChangeTotalSalary = () => {
        //Here we have to calculate total no of days
        let tempNoOfDays = 0;
        let temp = [];

        this.state.administrativeProcedures.forEach((item, index) => {
            let obj = {};
            obj.year = item.year;
            obj.month = item.month;
            obj.fromDay = item.fromDay;
            obj.toDay = item.toDay;
            obj.salaryStatus = item.salaryStatus;
            obj.subTotal = item.subTotal;

            obj.noOfDays = item.noOfDays;
            if (item.noOfDays != "") {
                tempNoOfDays = tempNoOfDays + parseInt(item.noOfDays);
            }
            // if (item.subTotal != "") {
            //     tempsubTotal = tempsubTotal + parseFloat(obj.subTotal);
            // }
            if (index == this.state.administrativeProcedures.length - 1) {
                this.setState({
                    totalNoOfDays: tempNoOfDays,
                    // subTotalTotal: tempsubTotal.toFixed(2)
                });
            }
            temp.push(obj);

        });

        this.setState({
            administrativeProcedures: temp
        }, () => {
            
        });

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        debugger;
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let day = currentDate.getDate();
        let dateBeforFiveYear = new Date(year - 5, month, day);
        let dateAfterOneYear = new Date(year + 1, month, day);
        this.setState({
            dateBeforFiveYear: dateBeforFiveYear,
            dateAfterOneYear: dateAfterOneYear
        })
    
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
    
        if (editFlag) {
            debugger;
            let tempDateOne = '', tempDateTwo = '';
            if (editObj.dateofsalarytermination) {
                tempDateOne = new Date(editObj.dateofsalarytermination);
            } else {
                tempDateOne = null;
            }
    
    
    
            if (editObj.dateofwork) {
                tempDateTwo = new Date(editObj.dateofwork);
            } else {
                tempDateTwo = null;
            }
    
            //For Request Type
            let requestTypeLabel = '';
           /* if (this.state.requestTypeArray && this.state.requestTypeArray.length > 0) {
                for (let index = 0; index < this.state.requestTypeArray.length; index++) {
                    if (parseInt(editObj.requesttype) === parseInt(this.state.requestTypeArray[index].id)) {
                        requestTypeLabel = this.props.isArabic ?
                            this.state.requestTypeArray[index].ArabicTitle :
                            this.state.requestTypeArray[index].EnglishTitle;
                    }
                }
            }*/
           
    
            let tempsalariedpaid = 'Yes';
            if (tempDateOne != null) {
                tempsalariedpaid = 'No';
            }
            this.setState({
                totalDays: editObj.worktotaldays,
                myTemp: editObj.worktotalsalarytobepaid,
                dateOfStartingWork: new Date(`${editObj.dateofstarting}`),
             
             
              
                HowToCalculate: editObj.howtocalculaterule,
                violation: editObj.isviolation === 1 ? 'Yes' : 'No',
                explain: editObj.violationdetail,
                basicSalaryTable: editObj.basicsalary2 != '' ? editObj.basicsalary2 : this.state.basicSalaryTable,
                totalSalaryWithSubscription: editObj.totalsalarywithsubscription != '' ? editObj.totalsalarywithsubscription : this.state.totalSalaryWithSubscription,
                retirementSalary: editObj.retirement != '' ? editObj.retirement : this.state.retirementSalary,
                tswsrsSalary: editObj.totalsalary2 != '' ? editObj.totalsalary2 : this.state.tswsrsSalary,
                tempTableData: editObj.salarymatrix,
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                empJob: editObj.jobtitle,
                employeeName: editObj.employeename,
                headlinesOfRequest: editObj.requestheadline,
                grade: editObj.grade,
                basicSalary: editObj.basicsalary,
                unifiedNumber: editObj.unifiednumber,
                totalSalary: editObj.totalsalary,
                retirementContributions: editObj.retirementcontribution,
                durationOfAccompanyingLeave: editObj.durationofleave,
    
                dateOfSalaryTermination: tempDateOne,
                dateOfDirectWork: tempDateTwo,
                requiredToPayBudget: editObj.isrequriedtopaybybudget === 1 ? "Yes" : "No",
                selectedOption2: editObj.isrequriedtopaybybudget === 0 ? "Yes" : "No",
                budgetYear: editObj.budgetyear,
                budgetAvailabilityYear: editObj.budgetavailableyear,
                budgetValue: editObj.budgetvalue,
                annualBalanceOfVacations: editObj.annualleavebalance,
                justificationsEmployee: editObj.justification,
                emiratesIDStepOne: editObj.emiratesid,
                //exemptionSelectedValue: editObj.exemptionSelectedValue,
                requestTypeLabel: requestTypeLabel,
                //Degreeofrelationshiplabel: Degreeofrelationshiplabel,
                //requestType: editObj.requesttype,
                //Degreeofrelationship: editObj.Degreeofrelationship,
                SalaryCointiunousOption: tempsalariedpaid
            }, () => {
              
            
                let temp = [];
                if (this.state.tempTableData && this.state.tempTableData.length > 0) {
                    this.state.tempTableData.forEach((item, index) => {
                        let obj = {};
                        obj.year = item.year;
                      
                        obj.salaryStatus = item.salaryStatus;
                        obj.noOfDays = item.noOfDays;
                        obj.month = item.month;
                        // obj.subTotalTotal = item.totalSalary;
    
                        if (item.subtotal) {
                            obj.subTotal = item.subtotal;
                        } else if (item.subTotal) {
                            obj.subTotal = item.subTotal;
                        }
    
                        if (item.from) {
                            obj.fromDay = item.from;
                        } else if (item.fromDay) {
                            obj.fromDay = item.fromDay;
                        }
    
                        if (item.to) {
                            obj.toDay = item.to;
                        } else if (item.toDay) {
                            obj.toDay = item.toDay;
                        }
    
                        temp.push(obj);
                        this.recalculateSubTotalAndNoOfDaysWhenEdit(item);
                    });
    
                    this.setState({
                        administrativeProcedures: temp
                    }, () => {
    
                    });
                }
                // set flag
                //Config.setIsEditFlag(false);
            });
        }
    }
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    };
    
    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }
    
    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    submitHandler = (event) => {
    debugger;
    event.preventDefault();
    event.target.className += " was-validated";

    let dateOfSalaryTerminationErr = '', dateOfDirectWorkErr = '', budgetYearErr = '', budgetAvailabilityYearErr = '', selectedOption2Err = '', selectedOptionErr = '', justificationsEmployeeErr = '', salaryErr = '', emiratesIDStepOneErr = '',
        totalSalaryErr = "", basicSalErr = "", retirmentContErr = "",
         budgetValueErr = "";

    /*  if (this.state.dateOfSalaryTermination === null) {
          dateOfSalaryTerminationErr = 'thisFieldIsRequired';
      } else {
          if (this.state.dateOfDirectWork === null) {
              dateOfDirectWorkErr = 'thisFieldIsRequired';
          }
      }*/


    if (!this.state.totalSalary) {
        totalSalaryErr = 'thisFieldIsRequired';
    }

    if (!this.state.basicSalary) {
        basicSalErr = 'thisFieldIsRequired';
    }

    if (!this.state.retirementContributions) {
        retirmentContErr = 'thisFieldIsRequired';
    }

    // if (this.state.budgetValue === '') {
    //     budgetValueErr = 'thisFieldIsRequired';
    // }

    if (!this.state.selectedOption2) {
        selectedOption2Err = 'thisFieldIsRequired';
    }
    // else if (this.state.selectedOption2 === 'No') {
    //     selectedOption2Err = 'budgetAvailabilityError';
    // }
    // if (!this.state.Degreeofrelationship && this.state.Degreeofrelationship != '') {
    //     DegreeofrelationshipErr = 'thisFieldIsRequired';
    // }

    if (this.state.selectedOption2 === 'Yes') {
        if (this.state.budgetAvailabilityYear) {
            if (!Validator.validateYear(this.state.budgetAvailabilityYear)) {
                budgetAvailabilityYearErr = 'invalidYear';
            }
        } else {
            budgetAvailabilityYearErr = 'thisFieldIsRequired';
        }
    } else {
        if (!this.state.requiredToPayBudget) {
            selectedOptionErr = 'thisFieldIsRequired';
        } else {
            if (this.state.requiredToPayBudget === 'Yes') {
                if (this.state.budgetYear) {
                    if (!Validator.validateYear(this.state.budgetYear)) {
                        budgetYearErr = 'invalidYear';
                    }
                } else {
                    budgetYearErr = 'thisFieldIsRequired';
                }

                if (!this.state.budgetValue) {
                    budgetValueErr = 'thisFieldIsRequired';
                }
            }
        }
    }

    // if (this.state.selectedOption2 === 'No') {
    //     if (this.state.budgetYear !== '') {
    //         if (!Validator.validateYear(this.state.budgetYear)) {
    //             budgetYearErr = 'invalidYear';
    //         }
    //     } else {
    //         budgetYearErr = 'thisFieldIsRequired';
    //     }
    // }

    if (this.state.justificationsEmployee && this.state.justificationsEmployee.trim()) {
        let val = striptags(this.state.justificationsEmployee.trim().replace(/&nbsp;/g, ""));
        if (!val.trim()) {
            justificationsEmployeeErr = 'thisFieldIsRequired';
        }
    } else {
        justificationsEmployeeErr = 'thisFieldIsRequired';
    }

    if (this.state.basicSalary && this.state.totalSalary) {
        let basicSalAfterReplace = this.state.basicSalary.replace(/,/g, "");
        let totalSalAfterReplace = this.state.totalSalary.replace(/,/g, "");
        if (parseFloat(basicSalAfterReplace) > parseFloat(totalSalAfterReplace)) {
            salaryErr = 'salaryErr';
        }
    }

    if (this.state.retirementContributions && this.state.totalSalary) {
        let retirementContributionsAfterReplace = this.state.retirementContributions.replace(/,/g, "");
        let totalSalAfterReplace = this.state.totalSalary.replace(/,/g, "");
        if (parseFloat(retirementContributionsAfterReplace) > parseFloat(totalSalAfterReplace)) {
            retirmentContErr = 'retirmentContErr';
        }
    }

    if (this.state.emiratesIDStepOne) {
        //if (!Validator.validOrInvalidEmiratesId(this.state.emiratesIDStepOne)) {
        if (!Validator.validateEmiratesId(this.state.emiratesIDStepOne)) {
            emiratesIDStepOneErr = "invalidEmiratesId";
        }
    }
    else {
        emiratesIDStepOneErr = "thisFieldisRequired"
    }

    this.setState({
        dateOfSalaryTerminationErr: dateOfSalaryTerminationErr,
        dateOfDirectWorkErr: dateOfDirectWorkErr,
        budgetYearErr: budgetYearErr,
        budgetAvailabilityYearErr: budgetAvailabilityYearErr,
        selectedOption2Err: selectedOption2Err,
        selectedOptionErr: selectedOptionErr,
        justificationsEmployeeErr: justificationsEmployeeErr,
        salaryErr: salaryErr,
        emiratesIDStepOneErr: emiratesIDStepOneErr,
        totalSalaryErr: totalSalaryErr,
        basicSalErr: basicSalErr,
        retirmentContErr: retirmentContErr,
        budgetValueErr: budgetValueErr,
        // DegreeofrelationshipErr
    }, () => {

        debugger;
        if (!dateOfSalaryTerminationErr && !dateOfDirectWorkErr && !budgetYearErr && !budgetAvailabilityYearErr && !selectedOption2Err && !selectedOptionErr && !justificationsEmployeeErr && !salaryErr && !emiratesIDStepOneErr && !totalSalaryErr && !basicSalErr && !retirmentContErr && !budgetValueErr) {
            debugger;

            //If Budget Availability is Yes, then Enter the Budget's year compulsary
            if (this.state.selectedOption2 === 'Yes') {
                if (
                    this.state.empJob &&
                   
                    this.state.employeeName &&
                    this.state.grade &&
                    this.state.basicSalary &&
                    this.state.unifiedNumber &&
                    this.state.totalSalary &&
                    this.state.retirementContributions &&
                    this.state.durationOfAccompanyingLeave &&
                    // this.state.dateOfSalaryTermination &&
                    this.state.selectedOption2 &&
                    striptags(this.state.justificationsEmployee) &&
                    this.state.emiratesIDStepOne
                    //  && this.state.Degreeofrelationship
                ) {
                    this.setState({
                        isNextClick: true,
                        isfirstPageValidate: true
                    }, () => {
                        debugger;
                        this.props.stepOneData({
                           
                            empJob: this.state.empJob,
                            headlinesOfRequest: this.state.headlinesOfRequest,
                            employeeName: this.state.employeeName,
                            grade: this.state.grade,
                            basicSalary: this.state.basicSalary,
                            unifiedNumber: this.state.unifiedNumber,
                            totalSalary: this.state.totalSalary,
                            retirementContributions: this.state.retirementContributions,
                            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                            dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                            dateOfDirectWork: this.state.dateOfDirectWork,
                            requiredToPayBudget: this.state.requiredToPayBudget,
                            selectedOption2: this.state.selectedOption2,
                            budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                            budgetYear: this.state.budgetYear,
                            budgetValue: this.state.budgetValue,
                            // budgetYear: '',
                            // budgetValue: '',
                            annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                            justificationsEmployee: this.state.justificationsEmployee,
                            emiratesIDStepOne: this.state.emiratesIDStepOne,
                            exemptionSelectedValue: this.state.exemptionSelectedValue,
                            myTemp: this.state.myTemp,
                            HowToCalculate: this.state.HowToCalculate,
                            violation: this.state.violation,
                            explain: this.state.explain,
                            tableData: this.state.administrativeProcedures,
                            basicSalaryTable: this.state.basicSalaryTable,
                            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
                            retirementSalary: this.state.retirementSalary,
                            tswsrsSalary: this.state.tswsrsSalary,
                            subTotalTotal: this.state.subTotalTotal,
                            totalNoOfDays: this.state.totalNoOfDays
                           // requestTypeLabel: this.state.requestTypeLabel                            , 
                            // Degreeofrelationship: this.state.Degreeofrelationship
                        });
                    });
                }
            } else {
                debugger;
                //If Budget Availability is No & Required to pay additional budget is Yes, then Enter the Year & Budget's value compulsary 
                if (this.state.selectedOption2 === 'No' && this.state.selectedOption === 'Yes') {
                    debugger;
                    if (/*this.state.requestType &&*/ this.state.empJob && this.state.employeeName && this.state.grade && this.state.basicSalary && this.state.unifiedNumber && this.state.totalSalary && this.state.retirementContributions && this.state.durationOfAccompanyingLeave && this.state.dateOfSalaryTermination && this.state.requiredToPayBudget && this.state.selectedOption2 && striptags(this.state.justificationsEmployee) && this.state.budgetYear && this.state.budgetValue && this.state.emiratesIDStepOne) {
                        debugger;
                        this.setState({ isNextClick: true, isfirstPageValidate: true }, () => {
                            this.props.stepOneData({
                                //requestType: this.state.requestType,
                                empJob: this.state.empJob,
                                headlinesOfRequest: this.state.headlinesOfRequest,
                                employeeName: this.state.employeeName,
                                grade: this.state.grade,
                                basicSalary: this.state.basicSalary,
                                unifiedNumber: this.state.unifiedNumber,
                                totalSalary: this.state.totalSalary,
                                retirementContributions: this.state.retirementContributions,
                                durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                dateOfDirectWork: this.state.dateOfDirectWork,
                                requiredToPayBudget: this.state.requiredToPayBudget,
                                selectedOption2: this.state.selectedOption2,
                                budgetYear: this.state.budgetYear,
                                budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                budgetValue: this.state.budgetValue,
                                emiratesIDStepOne: this.state.emiratesIDStepOne,
                                annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                justificationsEmployee: this.state.justificationsEmployee,
                                myTemp: this.state.myTemp,
                                HowToCalculate: this.state.HowToCalculate,
                                violation: this.state.violation,
                                explain: this.state.explain,
                                tableData: this.state.administrativeProcedures,
                                basicSalaryTable: this.state.basicSalaryTable,
                                totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
                                retirementSalary: this.state.retirementSalary,
                                tswsrsSalary: this.state.tswsrsSalary,
                                subTotalTotal: this.state.subTotalTotal,
                                totalNoOfDays: this.state.totalNoOfDays
                                //requestTypeLabel: this.state.requestTypeLabel                                , 
                                // Degreeofrelationship: this.state.Degreeofrelationship

                            });
                        });
                    }
                } else {
                    debugger;
                    if ( this.state.empJob && this.state.employeeName && this.state.grade && this.state.basicSalary && this.state.unifiedNumber && this.state.totalSalary && this.state.retirementContributions && this.state.durationOfAccompanyingLeave && this.state.dateOfSalaryTermination && this.state.requiredToPayBudget && this.state.selectedOption2 && this.state.justificationsEmployee) {
                        debugger;
                        this.setState({ isNextClick: true, isfirstPageValidate: true }, () => {
                            debugger;
                            this.props.stepOneData({
                               
                                empJob: this.state.empJob,
                                headlinesOfRequest: this.state.headlinesOfRequest,
                                employeeName: this.state.employeeName,
                                grade: this.state.grade,
                                basicSalary: this.state.basicSalary,
                                unifiedNumber: this.state.unifiedNumber,
                                totalSalary: this.state.totalSalary,
                                retirementContributions: this.state.retirementContributions,
                                durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                dateOfDirectWork: this.state.dateOfDirectWork,
                                requiredToPayBudget: this.state.requiredToPayBudget,
                                selectedOption2: this.state.selectedOption2,
                                budgetYear: this.state.budgetYear,
                                budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                budgetValue: this.state.budgetValue,
                                annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                justificationsEmployee: this.state.justificationsEmployee,
                                emiratesIDStepOne: this.state.emiratesIDStepOne,
                                exemptionSelectedValue: this.state.exemptionSelectedValue,
                                myTemp: this.state.myTemp,
                                HowToCalculate: this.state.HowToCalculate,
                                violation: this.state.violation,
                                explain: this.state.explain,
                                tableData: this.state.administrativeProcedures,
                                basicSalaryTable: this.state.basicSalaryTable,
                                totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
                                retirementSalary: this.state.retirementSalary,
                                tswsrsSalary: this.state.tswsrsSalary,
                                subTotalTotal: this.state.subTotalTotal,
                                totalNoOfDays: this.state.totalNoOfDays
                              //  requestTypeLabel: this.state.requestTypeLabel
                                 /*Degreeofrelationship: this.state.Degreeofrelationship*/
                            });
                        });
                    }
                }
            }
        }
    });
};
changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
};

onChangehandler = (value) => {
    this.setState({
        isNextClick: value
    });
}

handleEmpJob = (event) => {
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
        if (regex.test(event.target.value)) {
            this.setState({ empJob: event.target.value });
        }
    } else {
        this.setState({ empJob: '' });
    }
};

handleHeadlinesOfRequest = (event) => {
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
        if (regex.test(event.target.value)) {
            this.setState({ headlinesOfRequest: event.target.value });
        }
    } else {
        this.setState({ headlinesOfRequest: '' });
    }
};

handleEmiratesIDChange = (event) => {
    let emiratesIDStepOne = event.target.value;
    if (event.target.value !== '') {
        this.setState({ emiratesIDStepOne: event.target.value }, () => {
            if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                this.setState({ emiratesIDStepOneErr: '' });
            } else {
                this.setState({ emiratesIDStepOneErr: 'invalidEmiratesId' });
            }
        });
    }
    else {
        this.setState({ emiratesIDStepOne: '', emiratesIDStepOneErr: 'thisFieldisRequired' });
    }
}

handleEmployeeName = (event) => {
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
        if (regex.test(event.target.value)) {
            this.setState({ employeeName: event.target.value });
        }
    } else {
        this.setState({ employeeName: '' });
    }
};

handleGrade = (event) => {
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ grade: event.target.value });
        }
    } else {
        this.setState({ grade: '' });
    }
};

handleBasicSalary = (value) => {
    if (value) {
        this.setState({ basicSalary: value.formattedValue, basicSalErr: "" });
    }
};

handleUnifiedNumber = (event) => {
    if (event.target.value) {
        if (Validator.validateUnifiedNumber(event.target.value)) {
            this.setState({ unifiedNumber: event.target.value });
        }
    } else {
        this.setState({ unifiedNumber: "" })
    }
};

handleTotalSalary = (value) => {
    if (value) {
        this.setState({ totalSalary: value.formattedValue, totalSalaryErr: "" });
    }
};

handleRetirementContrib = (value) => {
    if (value) {
        this.setState({ retirementContributions: value.formattedValue, retirmentContErr: "" });
    }
};

handleDurationOfAccompanyingLeave = (event) => {
    let regex = /^[0-9\b]{0,15}$/;
    if (regex.test(event.target.value)) {
        if (event.target.value < 366) {
            this.setState({ durationOfAccompanyingLeave: event.target.value });
        }
    }
};

handleDateOfSalaryTermination(date) {
    if (date) {
        var d = new Date(date);
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var tempDate = new Date(year, month, day + 1);

        this.setState({
            dateOfSalaryTermination: date,
            dateOfSalaryTerminationErr: '',
            //dateOfDirectWork: tempDate,
            dateOfDirectWorkErr: ''
        });
    }
}

handleDateOfDirectWork(date) {
    this.setState({
        dateOfDirectWork: date,
        dateOfDirectWorkErr: ''
    });
}

handleOptionChange = (e) => {
    this.setState({
        requiredToPayBudget: e.target.value,
        selectedOptionErr: ''
    });
};

handleSalayOptionChange = (e) => {
    this.setState({
        SalaryCointiunousOption: e.target.value,
        SalaryCointiunousOptionErr: ''
    });
};
findRoutes()
{
    
}
handleOptionChange2 = (e) => {
    
 
    this.setState({
        selectedOption2: e.target.value,
        selectedOption2Err: ''
    });

    if(this.state.selectedOption2=="Yes")
    {
        this.setState({
            budgetYear: '',
            budgetValue: '',
            requiredToPayBudget:"No"
        }, () => {
        })
   
    }
    else if (this.state.selectedOption2=="No"){
       
        this.setState({
            budgetAvailabilityYear:''}, () => {
            })
    }
};

handleBudgetYear = (event) => {
    if (event.target.value) {
        let regex = /^[0-9]{0,4}$/;
        if (regex.test(event.target.value)) {
            this.setState({ budgetYear: event.target.value, budgetYearErr: '' });
        }
        this.setState({ budgetYearErr: '' });
    } else {
        this.setState({ budgetYear: '', budgetYearErr: 'thisFieldIsRequired' });
    }
};

validateBudgetYear = (budgetYear) => {
    let budgetYearLen = budgetYear.length;
    if (budgetYearLen === 4) {
        return true;
    } else {
        return false;
    }
}

handleBudgetAvailabilityYear = (event) => {
    let regex = /^[0-9\b]{0,4}$/;
    if (regex.test(event.target.value)) {
        this.setState({ budgetAvailabilityYear: event.target.value, budgetAvailabilityYearErr: '' });
    }
};

handleBudgetValue = (value) => {
    if (value) {
        
        this.setState({ budgetValue: value.formattedValue, budgetValueErr: "" });
    }
};

handleAnnualBalanceOfVacations = (event) => {
    let regex = /^[0-9]{0,2}$/;
    if (event.target.value) {
        if (event.target.value < 91) {
            if (regex.test(event.target.value)) {
                this.setState({ annualBalanceOfVacations: event.target.value });
            }
        }
    } else {
        this.setState({ annualBalanceOfVacations: '' });
    }
};

handleYear = (event) => {
    let regex = /^[0-9\b]{0,4}$/;
    if (regex.test(event.target.value)) {
        this.setState({ year: event.target.value });
    }
};

handleSalaryStatus = (event) => {
    let regex = /^[\u0621-\u064AA-Za-z ]*$/;
    if (regex.test(event.target.value)) {
        this.setState({ salaryStatus: event.target.value });
    }
};

handleTotalSalaryDollar = (event) => {
    let regex = /^[0-9\b.]{0,15}$/;
    if (regex.test(event.target.value)) {
        this.setState({ totalSalaryDollar: event.target.value });
    }
};

handleNoOfDays = (event) => {
    let regex = /^[0-9\b]{0,50}$/;
    if (regex.test(event.target.value)) {
        this.setState({ noOfDays: event.target.value });
    }
};

handleTotalDays = (event) => {
    let regex = /^[0-9\b]{0,50}$/;
    if (regex.test(event.target.value)) {
        this.setState({ totalDays: event.target.value });
    }
};

handleTotalSalaryPayed = (event) => {
    let regex = /^[0-9\b.]{0,15}$/;
    if (regex.test(event.target.value)) {
        this.setState({ totalSalaryPayed: event.target.value });
    }
};

justificationsEmployeeOnChange = (evt) => {
    var newContent = evt.editor.getData();
    let newContent1 = striptags(newContent);

    if (newContent1.length <= Config.getMaxCKEditorLimit()) {
        this.setState({
            justificationsEmployeeErr: "",
            justificationsEmployee: newContent
        })
    }
    else {
        this.setState({
            justificationsEmployeeErr: "ckeditorCountErr"
        })
    }

};

handleChangeForExemptionType = (event) => {
    this.setState({ exemptionSelectedValue: event.target.value });
};

handleChangeForRequestType = (event) => {
    let dropDownLabel = '';
    /*if (this.state.requestTypeArray && this.state.requestTypeArray.length > 0) {
        for (let index = 0; index < this.state.requestTypeArray.length; index++) {
            if (parseInt(event.target.value) === parseInt(this.state.requestTypeArray[index].id)) {
                dropDownLabel = this.props.isArabic ?
                    this.state.requestTypeArray[index].ArabicTitle :
                    this.state.requestTypeArray[index].EnglishTitle;
            }
        }
    }*/
    this.setState({
        requestType: event.target.value,
        requestTypeLabel: dropDownLabel
    }, () => {
      
    });

};

    handleChangeForDegreeofrelationship = (event) => {
        let dropDownLabel = '';
        if (this.state.DegreeofrelationshipArray && this.state.DegreeofrelationshipArray.length > 0) {
            for (let index = 0; index < this.state.DegreeofrelationshipArray.length; index++) {
                if (parseInt(event.target.value) === parseInt(this.state.DegreeofrelationshipArray[index].id)) {
                    dropDownLabel = this.props.isArabic ?
                        this.state.DegreeofrelationshipArray[index].ArabicTitle :
                        this.state.rDegreeofrelationshipArray[index].EnglishTitle;
                }
            }
        }

    this.setState({
        Degreeofrelationship: event.target.value,
        DegreeofrelationshipLabel: dropDownLabel
    }, () => {
     
    });

};

onDatepickerRef(el) {
    if (el && el.input) {
        el.input.readOnly = true;
        el.input.classList.add('white_bg');
    }
}

onDatepickerRef1(el) {
    if (el && el.input) {
        // if (this.state.dateOfSalaryTermination === null) {
        //     el.input.disabled = true;
        //     el.input.readOnly = false;
        // } else {
        //     el.input.disabled = false;
        //     el.input.readOnly = true;
        //     el.input.classList.add('white_bg');
        // }

        if (el.input.type === "blur") {
            el.input.target.style.backgroundColor = "white";
        }
    }
}
saveAsDraft = () => {


    this.props.saveAsDraft({
        isEdit: this.state.isEdit,
        requestId: this.state.requestId,
        formType: 'saveAsDraft',
        saveFrom: "Step1",
       
        empJob: this.state.empJob,
        //headlinesOfRequest: this.state.headlinesOfRequest,
        employeeName: this.state.employeeName,
        grade: this.state.grade,
        basicSalary: this.state.basicSalary,
        unifiedNumber: this.state.unifiedNumber,
        totalSalary: this.state.totalSalary,
        retirementContributions: this.state.retirementContributions,
        durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
        dateOfSalaryTermination: this.state.dateOfSalaryTermination,
        dateOfDirectWork: this.state.dateOfDirectWork,
        requiredToPayBudget: this.state.requiredToPayBudget,
        selectedOption2: this.state.selectedOption2,
        budgetAvailabilityYear: this.state.budgetAvailabilityYear,
        budgetYear: this.state.budgetYear,
        budgetValue: this.state.budgetValue,
        annualBalanceOfVacations: this.state.annualBalanceOfVacations,
        justificationsEmployee: this.state.justificationsEmployee,
        emiratesIDStepOne: this.state.emiratesIDStepOne,
        exemptionSelectedValue: this.state.exemptionSelectedValue
        ,

        myTemp: this.state.myTemp,
        HowToCalculate: this.state.HowToCalculate,
        violation: this.state.violation,
        explain: this.state.explain,
        tableData: this.state.administrativeProcedures,
        basicSalaryTable: this.state.basicSalaryTable,
        totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
        retirementSalary: this.state.retirementSalary,
        tswsrsSalary: this.state.tswsrsSalary,
        subTotalTotal: this.state.subTotalTotal,
        totalNoOfDays: this.state.totalNoOfDays
        //Degreeofrelationship: this.state.Degreeofrelationship
    });
};
    render() {
        let element = this.state.administrativeProcedures.map((item, index) => {
            return (
                <tr>
                    <td>
                        {
                            index > 0 ?
                                <div className="deleteBox">
                                    <MDBBtn className="primary deleteBtn" onClick={() => this.deleteView(index)}>-</MDBBtn>
                                </div>
                                : ''
                        }
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "year"}
                                name="year"
                                value={item.year}
                                required
                                onChange={(e) => {
                                    let regex = /^[0-9\b]{0,4}$/;
                                    if (e.target.value) {
                                        if (regex.test(e.target.value)) {
                                            item.year = e.target.value;
                                            this.setState({ year: e.target.value });
                                        }
                                    } else {
                                        item.year = '';
                                        this.setState({ year: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    let year = e.target.value;
                                    if (year !== "" && parseInt(year) > 0) {
                                        if (!Validator.validateYear(year)) {
                                            item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].yearValidation;
                                            this.setState({
                                                yearErr: i18n[this.props.isArabic ? 'ar' : 'en'].yearValidation
                                            });
                                        } else {
                                            item.year = e.target.value;
                                            item.yearErr = "";
                                            item.fromDayErr = "";

                                            item.fromDay = '';
                                            item.toDay = '';

                                            this.setState({
                                                year: e.target.value,
                                                yearErr: "",

                                                fromDay: '',
                                                toDay: '',
                                                fromDayErr: ""
                                            });
                                        }
                                    } else {
                                        item.yearErr = "";
                                        item.year = '';
                                        this.setState({
                                            year: "",
                                            yearErr: ''
                                        });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: 100 }}
                            />
                            <div className='textRed'>{item.yearErr}</div>
                        </div>
                    </td>
                    <td style={{ width: '15%' }}>
                        <div className="form-group">
                            <select
                                className="browser-default custom-select"
                                id={item.ref + "month"}
                                style={{ width: '125px', fontSize: '13px' }}
                                value={item.month}
                                onChange={(e) => {
                                    item.month = e.target.value;
                                    item.fromDay = '';
                                    item.toDay = '';
                                    item.noOfDays = '';
                                    this.setState({
                                        month: e.target.value,
                                        fromDay: '',
                                        toDay: '',
                                        noOfDays: '',
                                        fromDayErr: ""
                                    }, () => {
                                        this.recalculateSubTotalAndNoOfDays(item);
                                    });
                                }}
                                name='month'>
                                {
                                    this.state.monthArray.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "fromDay"}
                                name="fromDay"
                                value={item.fromDay}
                                required
                                onChange={(e) => {
                                    if (!item.year) {
                                        item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst;
                                        this.setState({ yearErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst });
                                        return;
                                    }

                                    if (e.target.value != "") {
                                        let regex = /^[0-9]{0,2}$/;
                                        let fromDay = parseInt(e.target.value);
                                        let toDay = item.toDay;
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();

                                        if (fromDay > 0 && fromDay <= daysInMonth) {
                                            if (toDay > 0) {
                                                if (fromDay <= toDay ) {
                                                    item.fromDay = fromDay;
                                                    this.recalculateSubTotalAndNoOfDays(item, daysInMonth);
                                                    item.fromDayErr = '';
                                                    this.setState({ fromDay: fromDay, fromDayErr: '' });
                                                } else {
                                                    //show error fromDay value should be less than ToDay
                                                    item.fromDay = '';
                                                    item.fromDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                                    this.setState({
                                                        fromDay: '',
                                                        fromDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                                    });
                                                }
                                            } else {
                                                if (regex.test(e.target.value)) {
                                                    item.fromDay = fromDay;
                                                    item.fromDayErr = '';
                                                    this.setState({ fromDay: fromDay, fromDayErr: '' });
                                                }
                                            }
                                        } else {
                                            item.fromDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                            this.setState({
                                                fromDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                            });
                                        }
                                    } else {
                                        this.recalculateSubTotalAndNoOfDays(item, 0);
                                        item.fromDay = '';
                                        this.setState({ fromDay: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (e.target.value != "") {
                                        let fromDay = parseInt(e.target.value);
                                        let toDay = item.toDay;
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();

                                        if (fromDay > 0 && fromDay <= daysInMonth) {
                                            if (toDay > 0 && fromDay < toDay) {
                                                item.fromDayErr = '';
                                                this.setState({ fromDayErr: '' });
                                            }
                                        }
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: '70px' }}
                            />
                            <div className='textRed'>{item.fromDayErr}</div>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "toDay"}
                                name="toDay"
                                required
                                value={item.toDay}
                                onChange={(e) => {
                                    if (!item.year) {
                                        item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst;
                                        item.toDay = '';
                                        this.setState({ toDay: '' });
                                        return;
                                    }

                                    if (e.target.value !== "") {
                                        let regex = /^[0-9]{0,2}$/;
                                        let toDay = parseInt(e.target.value);
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                        if (toDay > 0 && toDay <= daysInMonth) {
                                            if (regex.test(e.target.value)) {
                                                item.toDay = toDay;
                                                item.toDayErr = '';
                                                this.setState({ toDay: toDay, toDayErr: '' });
                                            }
                                        } else {
                                            item.toDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                            this.setState({
                                                toDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                            });
                                        }
                                    } else {
                                        item.toDay = '';
                                        this.setState({ toDay: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (item.yearErr === '') {
                                        if (item.toDay != "") {
                                            let toDay = item.toDay;
                                            item.toDay = toDay;
                                            let fromDay = item.fromDay;
                                            if (fromDay > 0) {
                                                if (fromDay <= toDay) {
                                                    let temp = toDay - fromDay;
                                                    item.noOfDays = temp;
                                                    this.setState({ noOfDays: temp });
                                                    let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                                    this.recalculateSubTotalAndNoOfDays(item, daysInMonth);

                                                    item.toDayErr = '';
                                                    this.setState({ toDayErr: '' });

                                                } else {
                                                    //show error formDay value should be less than ToDay
                                                    item.toDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                                    this.setState({
                                                        toDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                                    });
                                                }

                                            } else {
                                                item.toDay = e.target.value;
                                                item.toDayErr = '';
                                                this.setState({ toDay: e.target.value, toDayErr: '' });
                                            }
                                        }
                                    } else {
                                        item.toDay = e.target.value;
                                        this.setState({ toDay: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: '70px' }}
                            />
                            <div className='textRed'>{item.toDayErr}</div>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <select
                                className="browser-default custom-select"
                                id={item.ref + "salaryStatus"}
                                name="salaryStatus"
                                value={item.salaryStatus}
                                onChange={(e) => {
                                    item.salaryStatus = e.target.value;
                                    this.setState({ salaryStatus: e.target.value });
                                }}
                                style={{ width: 'auto', fontSize: '13px' }}>
                                {
                                    this.state.salaryStatusArr ? this.state.salaryStatusArr.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName}</option>
                                    }) : null
                                }
                            </select>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                pattern="^[1-9]\d*$"
                                required
                                id={item.ref + "subTotal"}
                                name="subTotal"
                                onChange={(e) => {
                                    let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                    let regex = /^[0-9\b.]{0,15}$/;
                                    let splitArr = e.target.value.split('.');
                                   
                                    if (regex.test(e.target.value) && splitArr.length <= 2) {
                                        item.subTotal = e.target.value;
                                        this.setState({ subTotal: e.target.value }, () => {
                                            this.recalculateSubTotalAndNoOfDays(item, daysInMonth);
                                        });
                                    }
                                }}
                                value={item.subTotal}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', overflowX: 'scroll' }}
                            />
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "noOfDays"}
                                name="noOfDays"
                                readOnly
                                value={item.noOfDays}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>
                </tr>
            )
        })
       
        return (
           
            // <React.Fragment>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
            <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={1}
                    isArabic={!this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
           
              <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].Common.EmployeeInfoSubject} </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  
  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.employeeName}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.employeeName}
                                onChange={this.handleEmployeeName}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

             {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmployeeNumber}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.EmployeeInfoEmployeeNumber}
                                onChange={this.handleEmployeeInfoEmployeeNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                             {
                                this.state.EmployeeInfoEmployeeNumberErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol> */}

               
</MDBRow>

<MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoJobTitle}<span className="requireStar">*</span></label>
                            <input
                                  value={this.state.empJob}
                                  onChange={this.handleEmpJob}
                                type="text"
                                id="empJob"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.empJobErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoJobDegree}<span className="requireStar">*</span></label>
                            <input
                              onChange={this.handleGrade}
                              value={this.state.grade}
                                type="text"
                                id="grade"
                                className="form-control"
                                name="grade"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.gradeErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

               
</MDBRow>
<MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmirateID}<span className="requireStar">*</span></label>
                            <InputMask
                                   mask="999-9999-9999999-9"
                                   maskChar={null}
                                   value={this.state.emiratesIDStepOne}
                                onChange={this.handleEmiratesIDChange}
                                
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.emiratesIDStepOneErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeInfoEmirateIDErr === 'invalidEmiratesId' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmiratesID}
                                    </div>
                                    :
                                    null
                            }
                            
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmployeeUID}<span className="requireStar">*</span></label>
                            <input
                                onChange={this.handleUnifiedNumber}
                                value={this.state.unifiedNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.unifiedNumberErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

               
</MDBRow>
    

      </div>
      </div>

      <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].Common.SalaryInfo} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
        <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.basicSalary}<span className="requireStar">*</span></label>
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.basicSalErr !== '' || this.state.salaryErr ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.basicSalary}
                                    onValueChange={(value) => { this.handleBasicSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                            {
                                this.state.basicSalErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            {
                                this.state.salaryErr === 'salaryErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].salaryErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.retirementContribution}<span className="requireStar">*</span></label>                           
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.retirmentContErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.retirementContributions}
                                    onValueChange={(value) => { this.handleRetirementContrib(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                            {
                                this.state.retirmentContErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.retirmentContErr === 'retirmentContErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].RetirementContributionsErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>


      
                     
      </MDBRow>
      
      <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
              
      <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalSalary}<span className="requireStar">*</span></label>
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.totalSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.totalSalary}
                                    onValueChange={(value) => { this.handleTotalSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                            {/* <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div> */}
                            {this.state.totalSalaryErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                     
      </MDBRow>
      
      
            </div></div>


            <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].SickLeave.LeaveSubject} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
                  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.YearlyLeaveBalance}<span className="requireStar">*</span></label>
                                  <input
                                    id="annualBalanceOfVacations"
                                    name="annualBalanceOfVacations"
                                    value={this.state.annualBalanceOfVacations}
                                    onChange={this.handleAnnualBalanceOfVacations}
                                      type="text"
                                    
                                      className="form-control"
                                    
                                      required
                                      //maxlength="50"
                                      style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                  />
                                  <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                      {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                  </div>
                              </div>
                          </MDBCol>
                          <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.LeavePeriod}<span className="requireStar">*</span></label>
                                  <input
                                     
                                      type="text"
                                      
                                      className="form-control"
                                      id="durationOfAccompanyingLeave"
                                      name="durationOfAccompanyingLeave"
                                      onChange={this.handleDurationOfAccompanyingLeave}
                                      value={this.state.durationOfAccompanyingLeave}
                                      required
                                      //maxlength="50"
                                      style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                  />
                                  <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                      {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                  </div>
                              </div>
                          </MDBCol>
      
                     
      </MDBRow>
      
      <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.BacktoWorkDate}<span className="requireStar">*</span></label>
                                  <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    selected={this.state.dateOfDirectWork}
                                    minDate={this.state.dateOfDirectWork}
                                    onChange={this.handleDateOfDirectWork}
                                    ref={el => this.onDatepickerRef1(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required
                                   
                                />

                              
                                  <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                      {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                  </div>
                              </div>
                          </MDBCol>
                      
                     
      </MDBRow>
      
      
            </div></div>
      


            <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].SickLeave.LeveSalryDetails} </a>
        </li>
      </ul>
     

      <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">


        <MDBRow className="formRow" style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystoped}<span className="requireStar">*</span></label>
                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                        <input
                            type="radio"
                            value="Yes"
                            checked={this.state.SalaryCointiunousOption === 'Yes'}
                            class="custom-control-input"
                            id="customControlValidation21"
                            onChange={this.handleSalayOptionChange}
                            name="radio-stackedsalary"
                            required />
                        <label class="custom-control-label" for="customControlValidation21">
                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystopedgoing}
                        </label>
                    </div>
                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                        <input
                            type="radio"
                            value="No"
                            class="custom-control-input"
                            id="customControlValidation31"
                            name="radio-stackedsalary"
                            checked={this.state.SalaryCointiunousOption === 'No'}
                            onChange={this.handleSalayOptionChange}
                            required />
                        <label class="custom-control-label" for="customControlValidation31">
                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystopedstoped}
                        </label>
                    </div><br />
                    {this.state.SalaryStatusErr == 'thisFieldIsRequired' ?
                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                        </div>
                        :
                        null}
                </div>
            </MDBCol>
            {this.state.SalaryCointiunousOption==='No' ?
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.dateOfSalaryTermination}
                            {/* <span className="requireStar">*</span> */}
                        </label>
                        <div
                            // style={{ width: '100%', display: 'flex', flexDirection:'row' }}
                            style={{ width: '100%', display: 'flex', flexDirection: 'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                            className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.DateoSalaryStopErr ? "datePickerBorderColorErr" : ""}`}>
                        <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    minDate={this.state.dateBeforFiveYear}
                                    maxDate={this.state.dateAfterOneYear}
                                    selected={this.state.dateOfSalaryTermination}
                                    onChange={this.handleDateOfSalaryTermination}
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                   // required
                                />
                        </div>
                        {/* {this.state.DateoSalaryStopErr !== '' ?
                                      <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:'row' }}>
                                          {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                      </div>
                                      :
                                      null} */}
                    </div>
                </MDBCol> : ''}

        </MDBRow>



        <MDBRow className="formRow" style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                    <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetAvailability}<span className="requireStar">*</span></label>
                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                        <input
                            type="radio"
                            value="Yes"
                            checked={this.state.selectedOption2 === 'Yes'}
                            class="custom-control-input"
                            id="customControlValidation4"
                            onChange={this.handleOptionChange2}
                            name="radio-stacked-2"
                            required />
                        <label class="custom-control-label" for="customControlValidation4">
                            {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                        </label>
                    </div>
                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                    <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.selectedOption2 === 'No'}
                                    onChange={this.handleOptionChange2}
                                    required />
                        <label class="custom-control-label" for="customControlValidation5">
                            {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                        </label>
                    </div>
                    <br />
                    {
                        this.state.selectedOption2Err == 'thisFieldIsRequired' ?
                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                            :
                            null
                    }
                    {/* {
                                      this.state.BudgetAviableErr === 'budgetAvailabilityError' ?
                                          <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                              {i18n[this.getLocale()].budgetAvailabilityError}
                                          </div>
                                          :
                                          null
                                  } */}
                </div>
            </MDBCol>
            {
                this.state.selectedOption2 === "Yes" ?
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetYear}
                                <span className="requireStar">*</span>
                            </label>

                            <input
                                        type="text"
                                        className="form-control"
                                        id="budgetAvailabilityYear"
                                        name="budgetAvailabilityYear"
                                        value={this.state.budgetAvailabilityYear}
                                        onChange={this.handleBudgetAvailabilityYear}
                                        style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        required
                                    />

                            {
                                this.state.budgetAvailabilityYearErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.budgetAvailabilityYearErr === 'invalidYear' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {i18n[this.getLocale()].invalidYear}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    :
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ padding: '30px' }}>
                    </MDBCol>
            }

        </MDBRow>
        {
          this.state.selectedOption2 === "No" ?

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.requiredToPayBudget}<span className="requireStar">*</span></label>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                            <input
                                            type="radio"
                                            value="Yes"
                                            checked={this.state.requiredToPayBudget === 'Yes'}
                                            class="custom-control-input"
                                            id="customControlValidation2"
                                            onChange={this.handleOptionChange}
                                            name="radio-stacked"
                                            required />
                                <label class="custom-control-label" for="customControlValidation2">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                            <input
                                            type="radio"
                                            value="No"
                                            class="custom-control-input"
                                            id="customControlValidation3"
                                            name="radio-stacked"
                                            checked={this.state.requiredToPayBudget === 'No'}
                                            onChange={this.handleOptionChange}
                                            required />
                                <label class="custom-control-label" for="customControlValidation3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>
                            </div><br />
                            {this.state.selectedOptionErr == 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                </MDBRow>
                :
                ''
        }

        <br />
        {
             (this.state.requiredToPayBudget === "Yes" && this.state.selectedOption2 !== "Yes") ?
                // (this.state.BudgetAviable === '1') ?

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SCI.enterTheYear}
                                <span className="requireStar">*</span>
                            </label>
                            <input
                                        type="text"
                                        className="form-control"
                                        id="budgetYear"
                                        name="budgetYear"
                                        value={this.state.budgetYear}
                                        onChange={this.handleBudgetYear}
                                        style={{
                                            direction: this.props.isArabic ? "rtl" : 'ltr',
                                            border: this.state.budgetYearErr !== '' ? '1px solid #dc3545' : '',
                                            backgroundImage: this.state.budgetYearErr !== '' ? 'none' : '',
                                        }}
                                    // required
                                    />

                            {
                                this.state.budgetYearErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.budgetYearErr == 'invalidYear' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidYear}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetValue}
                                <span className="requireStar">*</span>
                            </label>


                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.budgetValueErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                            <CurrencyFormat
                                            thousandSeparator={true}
                                            value={this.state.budgetValue}
                                            onValueChange={(value) => { this.handleBudgetValue(value) }}
                                            display={"text"}
                                            maxLength={10}
                                            style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        />
                            </div>

                            {this.state.budgetValueErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                </MDBRow>

                :
                null
        }

        <MDBRow className="formRow" style={{ display: 'flex', flexDirection: 'row' }}>

        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div  className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection:  'row' }} htmlFor="exampleFormControlTextarea3">{ i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.justificationsEmployee}<span className="requireStar">*</span></label>

                                <div style={{ border: this.state.justificationsEmployeeErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} className="ckEditorStyle">

                                    {
                                        this.props.isArabic ?
                                            null :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.justificationsEmployee}
                                                events={{
                                                    "change": this.justificationsEmployeeOnChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }

                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.justificationsEmployee}
                                                events={{
                                                    "change": this.justificationsEmployeeOnChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.justificationsEmployeeErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                

        </MDBRow>


        <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
            <a class="nav-link active" href="#">{i18n[this.getLocale()].SickLeave.DescripeIssue} </a>
        </li>
    </ul>

  



        <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

            <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: this.props.isArabic ? 'flex-start' : 'flex-end', }}>

                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className="form-group">
                        {/* <label style={{ width: '100%', display: 'flex', flexDirection: 'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.violationLabel}<span className="requireStar">*</span></label> */}

                        <label htmlFor="formGroupExampleInput" style={{ direction: !this.props.isArabic ? 'ltr' : 'rtl', textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.getLocale()].ExemptionFromAccompanyPatient.violationLabel}<span className="requireStar">*</span></label>

                        <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                value="Yes"
                                checked={this.state.violation === 'Yes'}
                                class="custom-control-input"
                                id="customControlValidation2s1"
                                onChange={this.handleViolationChange}
                                name="radio-stackeds1"
                                required />
                            <label class="custom-control-label" for="customControlValidation2s1">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                            </label>
                        </div>
                        <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                value="No"
                                class="custom-control-input"
                                id="customControlValidation3s1"
                                name="radio-stackeds1"
                                checked={this.state.violation === 'No'}
                                onChange={this.handleViolationChange}
                                required />
                            <label class="custom-control-label" for="customControlValidation3s1">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                            </label>


                        </div> <br />
                        {this.state.violationErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                            :
                            null}
                    </div>
                </MDBCol>


                {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"></MDBCol> */}

            </MDBRow>

            {
                this.state.violation === 'Yes' ?
                    <MDBRow style={{ display: 'flex', flexDirection: 'row'}}>
                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: 'row'}} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.explain}<span className="requireStar">*</span></label>
                                <div style={{ border: this.state.explainErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                    {
                                        this.props.isArabic ?
                                            null
                                            :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.explain}
                                                events={{
                                                    "change": this.handleExplainOnChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.explain}
                                                events={{
                                                    "change": this.handleExplainOnChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.explainErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </MDBCol>
                    </MDBRow>
                    :
                    ''
            }

            <MDBRow style={{ display: 'flex', flexDirection: 'row'}}>
                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                    <label htmlFor="formGroupExampleInput" style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.irregularitiesTable}</label>
                    <MDBTable bordered style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}>
                        <MDBTableBody>
                            <div>
                                <tr>
                                    <td width="15%"><div className="tableHeading" style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.employeeSalaryInformation}</div></td>
                                    <td>
                                        <div>
                                            <tr>
                                                <td width="20%">
                                                    <lable style={{ display: 'flex' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.basicSalaryTb}</lable>

                                                    <div
                                                        className=" currencyFormatInputCSS currencyFormat"
                                                        style={{
                                                            direction: this.props.isArabic ? 'rtl' : 'ltr',
                                                            border: this.state.basicSalErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                                        }}>
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            value={this.state.basicSalaryTable}
                                                            onValueChange={(value) => { this.handleBasicSalaryTable(value) }}
                                                            display={"text"}
                                                            maxLength={10}
                                                        />
                                                    </div>

                                                    {
                                                        this.state.basicSalErr === 'basicSalErr' ?
                                                            <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].salaryErr}
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </td>
                                                <td width="30%">
                                                    <lable style={{ display: 'flex' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalSalaryWithoutSubscription}</lable>
                                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                                        <CurrencyFormat
                                                            thousandSeparator={true}
                                                            value={this.state.totalSalaryWithSubscription}
                                                            onValueChange={(value) => { this.handletotalSalaryWithSubscriptionTable(value) }}
                                                            display={"text"}
                                                            maxLength={10}
                                                        />
                                                    </div>
                                                </td>
                                                <td width="20%">
                                                    <lable style={{ display: 'flex' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.retirementSalary}</lable>
                                                    <div className="form-group">

                                                        <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', border: this.state.retirementSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                                            <CurrencyFormat
                                                                thousandSeparator={true}
                                                                value={this.state.retirementSalary}
                                                                onValueChange={(value) => { this.handleretirementSalaryTable(value) }}
                                                                display={"text"}
                                                                maxLength={10}
                                                            />

                                                        </div>

                                                        {
                                                            this.state.retirementSalaryErr === 'retirementSalaryErr' ?
                                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                                    {i18n[this.getLocale()].monthlyContributionsErr}
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                </td>
                                                <td width="20%">
                                                    <lable style={{ display: 'flex' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.total}</lable>
                                                    <div className="form-group">
                                                        <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                                            <CurrencyFormat
                                                                thousandSeparator={true}
                                                                value={this.state.tswsrsSalary}
                                                                onValueChange={(value) => { this.handletswsrsSalaryTable(value) }}
                                                                display={"text"}
                                                                maxLength={10}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <div className="tableHeading"
                                            style={{
                                                textAlign: this.props.isArabic ? "right" : '',
                                                width: 'auto'
                                            }}
                                        >
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.intervals}
                                        </div>

                                        <div className="deleteBox">
                                            <MDBBtn className="primary deleteBtn" onClick={() => this.addView()} >+</MDBBtn>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <table>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.year} <span class="requireStar">*</span> </th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.month} <span class="requireStar">*</span> </th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.from} <span class="requireStar">*</span> </th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.to} <span class="requireStar">*</span></th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salaryStatus} <span class="requireStar">*</span></th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.subTotal} <span class="requireStar">*</span></th>
                                                    <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.noOfDays} </th>
                                                </tr>

                                                {element}

                                                <tr>
                                                    <td colSpan={6} style={{ border: 'none' }}>
                                                        {
                                                            this.state.intervalsErr ?
                                                                <div className="textRed"
                                                                    style={{
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        textAlign: this.props.isArabic ? 'right' : 'left',
                                                                        flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                                                                        direction: 'ltr'
                                                                    }}
                                                                >
                                                                    {i18n[this.props.isArabic ? 'ar' : 'en'].allFieldsAreMandatory}
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </td>

                                                    <td>
                                                        {/* <lable>Amount Should be Paid</lable> */}
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="subTotalTotal"
                                                                name="subTotalTotal"
                                                                readOnly
                                                                value={this.state.subTotalTotal}
                                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', overflowX: 'auto' }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {/* <lable>Total Days</lable> */}
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="daysTotal"
                                                                name="daysTotal"
                                                                disabled
                                                                value={this.state.totalNoOfDays}
                                                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>

                            </div>

                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </MDBRow>

            <MDBRow style={{ display: 'flex', flexDirection: 'row'}}>
                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className="form-group">
                        <label style={{ width: '100%', display: 'flex', flexDirection: 'row'}} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.HowToCalculate}<span className="requireStar">*</span></label>

                        <div style={{ border: this.state.howToCalculateErr === 'thisFieldisRequired' ? '1px solid red' : '' }}>
                            {
                                this.props.isArabic ?
                                    null :
                                    <CKEditor
                                        activeClass="p10"
                                        content={this.state.HowToCalculate}
                                        events={{
                                            "change": this.handleHowToCalculate
                                        }}
                                        config={{ language: "en", height: '10vh' }}
                                    />
                            }
                            {
                                this.props.isArabic ?
                                    <CKEditor
                                        activeClass="p10"
                                        content={this.state.HowToCalculate}
                                        events={{
                                            "change": this.handleHowToCalculate
                                        }}
                                        config={{ language: "ar", height: '10vh' }}
                                    />
                                    :
                                    null
                            }
                        </div>
                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                            {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                        </p>
                        {
                            this.state.howToCalculateErr === 'thisFieldisRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }
                    </div>
                </MDBCol>
            </MDBRow>


        </div></div>




    </div></div>
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].next_btn}
                           </MDBBtn>
       
                           {
                           
                               (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                                   null
                                   :
                                   <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                       {i18n[this.getLocale()].saveAsDraft}
                                   </MDBBtn>
                           }
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                       </MDBRow>
       
      
               
                       </form>    

    //   </React.Fragment>
              
        );
    }
}