import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import './../../css/style.css';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import CKEditor from "react-ckeditor-component";

const striptags = require('striptags');

export default class Stepone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getHrReviewPolicyRequestTypeId(),
            isArabic: this.props.isArabic,
            isfileName: false,
            reasonsOfRequest: this.props.sendData.reasonsOfRequest,
            entityFormalRequest: this.props.sendData.entityFormalRequest,
            entityFormalRequestFile: this.props.sendData.entityFormalRequestFile,
            entityFormalRequestUrl: '',
            standardComparisons: this.props.sendData.standardComparisons,
            standardComparisonsFile: this.props.sendData.standardComparisonsFile,
            standardComparisonsUrl: '',
            studies: this.props.sendData.studies,
            studiesShortName: this.props.sendData.studiesShortName,
            studiesFile: this.props.sendData.studiesFile,
            studiesUrl: '',
            entityFormalRequestArray: [],
            standardComparisonsArray: [],
            studiesArray: [],
            editAttachmentArray: [],
            entityFormalRequestErr: '',
            standardComparisonsErr: '',
            studiesErr: '',
            reasonsOfRequestErr: '',
            studiesFileObjArray: [],
            entityFormalRequestFileObj: "",
            standardComparisonsFileObj: "",
            OtherAttachArray: [],
            OtherAttachErr: "",
            OtherAttach: this.props.sendData.OtherAttach,
            OtherAttachFileObj: ""
        }
    }

    componentDidMount = () => {
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
        
        let previewFlag = Config.getIsPreviewFlag();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();
        let previewckEditorArr = Config.getPreviewckEditorArray();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
                reasonsOfRequest: editObj.requestreason,
            }, () => {
                let tempEntityFormalRequestArray = [];
                let tempStandardComparisonsArray = [];
                let tempStudiesArray = [];
                let tempOtherAttachArray = [];
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getHrReviewFormalRequestId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                if (this.state.requestId) {
                                    temp.requestId = this.state.requestId;
                                } else {
                                    temp.requestId = editObj.requestId
                                }
                                tempEntityFormalRequestArray.push(temp);
                            } else if (item.filetypeid === Config.getHrReviewStandardComparisonId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                if (this.state.requestId) {
                                    temp.requestId = this.state.requestId;
                                } else {
                                    temp.requestId = editObj.requestId
                                }
                                tempStandardComparisonsArray.push(temp);
                            } else if (item.filetypeid === Config.getHrReviewStudiesId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                if (this.state.requestId) {
                                    temp.requestId = this.state.requestId;
                                } else {
                                    temp.requestId = editObj.requestId
                                }
                                tempStudiesArray.push(temp);
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETOtherAttachId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempOtherAttachArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }
                        })
                        this.setState({
                            entityFormalRequestArray: tempEntityFormalRequestArray,
                            standardComparisonsArray: tempStandardComparisonsArray,
                            studiesArray: tempStudiesArray,
                            OtherAttachArray:tempOtherAttachArray
                        }, () => {
                         
                        })
                    }
                }
            })
        }

        //For Preview Form
        if (previewFlag) {
            this.setState({
                requestId: previewObj.requestId,
                isEdit: previewObj.isEdit,
                studiesShortName: previewObj.studiesShortName
            }, () => {
                //For attachments
                let entityFormalRequest = '', standardComparisons = '', studies = '',OtherAttach='';
                if (previewAttachmentArray.length > 0) {
                    for (let index = 0; index < previewAttachmentArray.length; index++) {
                        if (previewAttachmentArray[index].label === 'entityFormalRequest') {
                            entityFormalRequest = previewAttachmentArray[index].fileName;
                        }
                        if (previewAttachmentArray[index].label === 'standardComparisons') {
                            standardComparisons = previewAttachmentArray[index].fileName;
                        }
                        if (previewAttachmentArray[index].label === 'studies') {
                            studies = previewAttachmentArray[index].fileName;
                        }
                        if (previewAttachmentArray[index].label === 'OtherAttach') {
                            OtherAttach = previewAttachmentArray[index].fileName;
                        }
                    }
                }

                //For CK Editor
                let reasonsOfRequest = '';
                if (previewckEditorArr) {
                    if (previewckEditorArr.length > 0) {
                        for (let j = 0; j < previewckEditorArr.length; j++) {
                            if (previewckEditorArr[j].label === 'reasonsOfRequest') {
                                reasonsOfRequest = previewckEditorArr[j].value;
                            }
                        }
                    }
                }
                this.setState({
                    reasonsOfRequest: reasonsOfRequest,
                    entityFormalRequest: entityFormalRequest,
                    standardComparisons: standardComparisons,
                    studies: studies,
                    OtherAttach
                });
            });
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    handleReasonsOfRequest = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                reasonsOfRequestErr: "",
                reasonsOfRequest: newContent
            });
        }else{
            this.setState({
                reasonsOfRequestErr: "ckeditorCountErr"
            });
        }
    }

    entityFormalRequestOnChange = (e) => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;

            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ entityFormalRequest: '', entityFormalRequestErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ entityFormalRequest: e.target.files[0].name, entityFormalRequestErr: '' });
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.onloadend = () => {
                        this.setState({
                            entityFormalRequestFileObj: reader.result,
                        }, () => {
                            Config.setBase64ForReviewHRPoliciesOne(this.state.entityFormalRequestFileObj);
                        });
                    };
                    reader.readAsDataURL(file);
                }
                else {
                    e.target.value = '';
                    this.setState({ entityFormalRequest: '', entityFormalRequestErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ entityFormalRequest: '', entityFormalRequestErr: 'thisFieldIsRequired' });
        }
    };

    standardComparisonsOnChange = (e) => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ standardComparisonsErr: 'invalidFileType', standardComparisons: '' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ standardComparisonsErr: '', standardComparisons: inpFileName });
                    e.preventDefault();
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.onloadend = () => {
                        this.setState({
                            standardComparisonsFileObj: reader.result,
                        }, () => {
                            Config.setBase64ForReviewHRPoliciesTwo(this.state.standardComparisonsFileObj);
                        });
                    };
                    reader.readAsDataURL(file);
                }
                else {
                    e.target.value = '';
                    this.setState({ standardComparisons: '', standardComparisonsErr: 'invalidFileSize' }, () => {
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ standardComparisonsErr: 'thisFieldIsRequired', standardComparisons: '' });
        }
    };

    studiesOnChange = (e) => {
        let fileNames = "";
        let fileSupportArray = [];

        if (e.target.files.length > 0) {

            if (e.target.files.length <= Config.getMaxFileCount()) {

                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({ studiesErr: 'invalidFileType', studies: '', studiesShortName: '', });
                        break;
                    }
                    else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;

                            let studiesShortName = fileNames.substring(0, 50);

                            let reader = new FileReader();
                            let tempFile = e.target.files[m];
                            reader.onloadend = () => {
                                fileSupportArray.push(reader.result);
                                this.setState({
                                    studiesFileObjArray: fileSupportArray,
                                }, () => {
                                    Config.setBase64ForReviewHRPoliciesThree(this.state.studiesFileObjArray);
                                });
                            };
                            reader.readAsDataURL(tempFile);
                            this.setState({ studies: fileNames, studiesShortName: studiesShortName, studiesErr: '' });
                        } else {
                            e.target.value = '';
                            this.setState({ studies: '', studiesShortName: '', studiesErr: 'invalidFileSize' });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({ studies: "", studiesShortName: '', studiesErr: 'fileLimitErr' });
            }
        }
        else {
            e.target.value = '';
            this.setState({ studiesErr: 'thisFieldIsRequired', studies: '', studiesShortName: '' });
        }
    };

    checkValidations = () => {

        let entityFormalRequestErr = '',
            standardComparisonsErr = '',
            studiesErr = '',
            reasonsOfRequestErr = '';

        if (this.state.entityFormalRequestArray && this.state.entityFormalRequestArray.length > 0) {
            entityFormalRequestErr = "";
        } else {
            if (!this.state.entityFormalRequest) {
                entityFormalRequestErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.standardComparisonsArray && this.state.standardComparisonsArray.length > 0) {
            standardComparisonsErr = "";
        } else {
            if (!this.state.standardComparisons) {
                standardComparisonsErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.studiesArray && this.state.studiesArray.length > 0) {
            studiesErr = "";
        } else {
            if (!this.state.studies) {
                studiesErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.reasonsOfRequest) {
            let val = striptags(this.state.reasonsOfRequest.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                reasonsOfRequestErr = 'thisFieldIsRequired';
            } else {
                reasonsOfRequestErr = '';
            }
        } else {
            reasonsOfRequestErr = 'thisFieldIsRequired';
        }

        this.setState({
            entityFormalRequestErr: entityFormalRequestErr,
            standardComparisonsErr: standardComparisonsErr,
            studiesErr: studiesErr,
            reasonsOfRequestErr: reasonsOfRequestErr,
        });
        if (!entityFormalRequestErr &&
            !standardComparisonsErr &&
            !studiesErr &&
            !reasonsOfRequestErr
        ) {
          
            
            var obj = {
                requestId: this.state.requestId,
                isEdit: this.state.isEdit,
                reasonsOfRequest: this.state.reasonsOfRequest,
                entityFormalRequest: this.state.entityFormalRequest,
                entityFormalRequestFileObj: this.state.entityFormalRequestFileObj,
                standardComparisons: this.state.standardComparisons,
                standardComparisonsFileObj: this.state.standardComparisonsFileObj,
                studies: this.state.studies,
                studiesFileObjArray: this.state.studiesFileObjArray,
                OtherAttach:this.state.OtherAttach,
                OtherAttachFileObj:this.state.OtherAttachFileObj,
            }
            this.props.setDataObject(obj);
            // }
        }
    }

    OtherAttachOnChange = e => {

        debugger;
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ OtherAttach: e.target.files[0].name, OtherAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                OtherAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForOtherAttach(this.state.OtherAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ OtherAttach: '', OtherAttachErr: 'thisFieldIsRequired' });
        //}
    };

    withoutValidation = () => {
        var obj = {
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            reasonsOfRequest: this.state.reasonsOfRequest,
            entityFormalRequest: this.state.entityFormalRequest,
            entityFormalRequestFileObj: this.state.entityFormalRequestFileObj,
            standardComparisons: this.state.standardComparisons,
            standardComparisonsFileObj: this.state.standardComparisonsFileObj,
            studies: this.state.studies,
            studiesFileObjArray: this.state.studiesFileObjArray,
            OtherAttachFileObj:this.state.OtherAttachFileObj,
            OtherAttach:this.state.OtherAttach
        }
        this.props.setDataObject(obj);
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
       
        if (this.state.isEdit) {
            this.checkValidations();
        } else {
            this.checkValidations();
        }
    };

    handlePreViewNavigation = () => {
        let ckEditorArr = [
            {
                label: 'reasonsOfRequest',
                value: this.state.reasonsOfRequest,
            },
        ];

        let dataObj = {
            studiesShortName : this.state.studiesShortName
        };
        let attachmentArr = [
            {
                label: 'entityFormalRequest',
                fileName: this.state.entityFormalRequest,
                fileObj: Config.getBase64ForReviewHRPoliciesOne(),
            },
            {
                label: 'standardComparisons',
                fileName: this.state.standardComparisons,
                fileObj: Config.getBase64ForReviewHRPoliciesTwo(),
            },
            {
                label: 'studies',
                fileName: this.state.studies,
                fileObj: Config.getBase64ForReviewHRPoliciesThree(),
            },
            {
                label: 'OtherAttach',
                fileName: this.state.OtherAttach,
                fileObj: Config.getBase64ForOtherAttach(),
            },
           
        ];

        this.props.previewPage(dataObj, attachmentArr, ckEditorArr);
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            reasonsOfRequest: this.state.reasonsOfRequest,
            entityFormalRequest: this.state.entityFormalRequest,
            standardComparisons: this.state.standardComparisons,
            studies: this.state.studies,
            OtherAttach:this.state.OtherAttach
        });
    };

    render() {
        return (
            <form
                style={{ paddingTop: '20px', marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow className="formRow">
                    <MDBCol xs="12" sm="12" md="12" lg="2" xl="12" >
                        <div className="form-group " >
                            <label htmlFor="exampleFormControlTextarea2" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}> {i18n[this.props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.ReasonsOfRequest}<span style={{ color: 'red' }}> *</span></label>
                            <div style={{ border: this.state.reasonsOfRequestErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.reasonsOfRequest}
                                            events={{
                                                "change": this.handleReasonsOfRequest
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />

                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.reasonsOfRequest}
                                            events={{
                                                "change": this.handleReasonsOfRequest
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.reasonsOfRequestErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow">
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="Financialcost" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.standard_comparisons}  <span style={{ color: 'red' }}> *</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="standardComparisonsFile"
                                        type="file"
                                        name="standardComparisons"
                                        onChange={(event) => this.standardComparisonsOnChange(event)}
                                    />
                                    <label
                                        htmlFor="standardComparisonsFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row"
                                                : "row-reverse"
                                        }}
                                    >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="standardComparisonsFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        {this.state.standardComparisons}
                                    </label>
                                </div>
                                {
                                    this.state.standardComparisonsErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.standardComparisonsErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.standardComparisonsErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.standardComparisonsArray &&
                                    this.state.standardComparisonsArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.standardComparisonsArray} />
                                    : <span></span>

                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group"  >
                            <label htmlFor="Attachedfilesforsupportrequest" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} >{i18n[this.props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.studies} <span style={{ color: 'red' }}> *</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="studiesFile"
                                        type="file"
                                        name="studies"
                                        multiple
                                        onChange={(event) => this.studiesOnChange(event)}
                                    />
                                    <label
                                        htmlFor="studiesFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row"
                                                : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="studiesFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        {/* <span>{this.state.studies}</span> */}
                                        <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.studiesShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.studiesShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.studies}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.studiesErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.studiesErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.studiesErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.studiesErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                            </p>
                            {
                                (this.state.studiesArray &&
                                    this.state.studiesArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.studiesArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow className="formRow">
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.OtherAttach}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="OtherAttachFile"
                                        type="file"
                                        name="OtherAttach"
                                        onChange={(event) => this.OtherAttachOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="OtherAttachFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="OtherAttachFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.OtherAttach}</span></label>
                                </div>

                                {
                                    this.state.OtherAttachErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.OtherAttachArray &&
                                    this.state.OtherAttachArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.OtherAttachArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>


                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="Entityformalrequest" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} >{i18n[this.props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.Entity_formal_request} <span style={{ color: 'red' }}> *</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="entityFormalRequestFile"
                                        type="file"
                                        name="entityFormalRequest"
                                        onChange={(event) => this.entityFormalRequestOnChange(event)}
                                    />
                                    <label
                                        htmlFor="entityFormalRequestFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row"
                                                : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="entityFormalRequestFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        {this.state.entityFormalRequest}
                                    </label>
                                </div>
                                {
                                    this.state.entityFormalRequestErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.entityFormalRequestErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.entityFormalRequestErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <br />
                            </div>
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.entityFormalRequestArray  &&
                                    this.state.entityFormalRequestArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.entityFormalRequestArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>
              
              

                </MDBRow>
               

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn type='submit' color="primary" className="submitBtn">{i18n[this.props.isArabic ? 'ar' : 'en'].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }
}