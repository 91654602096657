import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import { components } from 'react-select';
import { Multiselect } from 'multiselect-react-dropdown';
import { confirmAlert } from 'react-confirm-alert';
import doc from './../../assets/نموذج خطة الاحلال الوظيفي.xlsx';
import { saveSubstitutionPlanPayload, uploadDocument } from '../../utils/Payload';
const striptags = require('striptags');

export default class SubstitutionplanFormcom extends Component 

{
    constructor (props)
    {
        super(props);
        this.state=
        {
            selectedQuarter:0,
            selectedQuarterErr:'',
          
            officalLetterErr: '',
            actualOfficialLetter: '',
            officialLetter:'',

            Files:[]
        }

    }
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }
 componentDidMount = () => {
  

 }





 submitHandler = event => {
 debugger;
    event.preventDefault();
    event.target.className += " was-validated";
     let Quartererror='',officalLetterErr='';
  
     if(!this.state.selectedQuarter || this.state.selectedQuarter===0 || this.state.selectedQuarter==='0')
{
    Quartererror="thisFieldIsRequired";
}


if(this.state.Files.length===0)
{
    officalLetterErr="thisFieldIsRequired";

}


this.setState({
    selectedQuarterErr:Quartererror,
    officalLetterErr:officalLetterErr,

    
},()=>{})

   

if (
   
    !officalLetterErr &&
    ! Quartererror
 
)
{
    let dataObject = {}
    dataObject.Quarter=this.state.selectedQuarter;
    
  let tempfiles=[];
  tempfiles = this.state.Files;
    dataObject.filename=tempfiles[0].filename;
    dataObject.filecontent=tempfiles[0].filecontent;
    // dataObject.filecontent=this.state.actualOfficialLetter;
    let payload = saveSubstitutionPlanPayload(dataObject);
    
    debugger;
    let endPoint = 'SaveSubstitutionplan'
    let url = Config.getUrl(endPoint);
    this.setState({
        isLoading: true
    }, () => {
        ApiRequest.sendRequestForPost(url, payload, res => {
            if (res.Status) {
              

                this.setState({
                    isLoading: false,
                    titleAlert: i18n[this.getLocale()].success,
                    messageAlert: i18n[this.getLocale()].Substitutionplan.sucess
                }, () => {
                    this.alertSubmitForSuccess();
                })
            }
        }
        )

 }
);
}
 }
 handleChangeForQuarter = (e) => {
let selectedvalue = parseInt(e.target.value);
if(selectedvalue > 0)
{
    this.setState({selectedQuarter:selectedvalue,selectedQuarterErr:''});
}
else
{
    this.setState({selectedQuarter:selectedvalue,selectedQuarterErr:'thisFieldisRequired'});
}

 }

 officialLetterOnChange = e => {
     let tempfiles = [];    debugger;
    if (e.target.files.length > 0) {
        for(let i=0; i<e.target.files.length;i++)
        {
            let element=e.target.files[i];
        
        
            let inpFileName = element.name;
            let fileSize = element.size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['XLS', 'XLSX'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    officialLetter: '',
                    officalLetterErr: 'invalidFileType'
                });
                return;
            }
           
           
           
            else {
                this.setState({officalLetterErr:''});
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let tempobj = {};
                    let fileObj = element;
                    tempobj.filename=element.name;
                    let reader = new FileReader();
                    let file = fileObj;
                    reader.onloadend = () => {
                        tempobj.filecontent=reader.result;
                    };
                    reader.readAsDataURL(file);
                    tempfiles.push(tempobj);
                }
                else {
                    e.target.value = '';
                    this.setState({
                        officialLetter: '',
                        officalLetterErr: 'invalidFileSize'
                    });
                    return;
                }  
            }
            

        }
      
this.setState({
    Files:tempfiles
});
        // else {
        //     if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
        //         let fileObj = e.target.files[0];
        //         this.setState({
        //             officialLetter: e.target.files[0].name,
        //             officalLetterErr: ''
        //         }, () => {
        //             let reader = new FileReader();
        //             let file = fileObj;
        //             reader.onloadend = () => {
        //                 this.setState({
        //                     actualOfficialLetter: reader.result,
        //                 }, () => {
        //                     Config.setBase64ForSciOfficalLetter(this.state.actualOfficialLetter);
        //                 });
        //             };
        //             reader.readAsDataURL(file);
        //         });

        //     } else {
        //         e.target.value = '';
        //         this.setState({
        //             officialLetter: '',
        //             officalLetterErr: 'invalidFileSize'
        //         });
        //     }
        // }

    } else {
        e.target.value = '';
        this.setState({ officialLetter: '' });
    }

};
alertSubmitForSuccess = () => {
    debugger;
    //return (<RequestSurvey isArabic={this.state.isArabic} y={this.props.y} />);
     confirmAlert({
         title: this.state.titleAlert,
         message: this.state.messageAlert,
         buttons: [
             {
                 label: i18n[this.getLocale()].ok,
                 onClick: () =>   this.props.history.replace('/substitutionplan', {
                    pathname: '/substitutionplan'
                })
             }
         ],
         closeOnEscape: false,
         closeOnClickOutside: false,
         childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
     });
     setTimeout(() => {
         document.getElementById('alertOkButton').focus()
     }, 100);
};
 render() {

    return (

        <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate>

            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].Substitutionplan.Quarter }
                            <span className="requireStar">*</span>
                        </label>
                        <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select"
                                id="sources-funding"
                                onChange={this.handleChangeForQuarter}
                                value={this.state.selectedQuarter}
                                name='QuarterSelectedValue'
                                required>

                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                <option value="1">{i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.q1}</option>
                                <option value="2">{i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.q2}</option>
                                <option value="3">{i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.q3}</option>
                                <option value="4">{i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.q4}</option>

                            </select>
                       
{this.state.selectedQuarterErr  ?
                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                         :null}
                    </div>
                </MDBCol>

         
            </MDBRow>
         


            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
          <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
             <a href={doc} target="_blank">
                        <div className="flagclass" style={{ display: 'flex', 
                        flexDirection: this.props.isArabic  ? 'row-reverse' : 'row', alignItems: this.props.isArabic  ?'right':'left',
                         justifyContent: this.props.isArabic  ?'right':'left' }} caret gradient="var(--primaryColor)">
                          <span  style={{backgroundColor: '#09243e',  borderRadius: 50,
                             margin: '10px',color:'#ffffff'}}>
                                 <span style={{margin:'15px'}}>
                              {i18n[this.props.isArabic ? 'ar' : 'en'].    Substitutionplan.Template}
                              </span>
                              </span>
                           
                        </div>
                   </a>
             </MDBCol>
         </MDBRow>


            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
         <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
              <div className="form-group">
                  <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="Attachedfilesforsupportrequest">{i18n[this.getLocale()].SCI.attachedFiles}</label>

                  <div className="input-group">
                      <div className="custom-file-inp">
                          <input required
                              id="file"
                              type="file"
                              name="officialLetter"
                              onChange={(event) => this.officialLetterOnChange(event)}
                          />
                          <label htmlFor="file" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].SCI.chooseFile}</label>
                          <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="file" className="fileName">
                       
                              {this.state.Files.length>0
                              ?this.state.Files.map((item, index) => {
                                return (
                                    item.filename
                                )
                              }
                              )
                              :''
                              }
                              {/* <span>{this.state.officialLetter}</span> */}
                          </label>
                      </div>
                      {
                          this.state.officalLetterErr === 'thisFieldIsRequired' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                              </div>
                              :
                              null
                      }
                      {
                          this.state.officalLetterErr === 'invalidFileType' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                              </div>
                              :
                              null
                      }
                      {
                          this.state.officalLetterErr === 'invalidFileSize' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                              </div>
                              :
                              null
                      }

                  </div>
                  <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                      {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormats_xlsx}
                  </p>
                  {
                      (this.state.sciAttachmentArray &&
                          this.state.sciAttachmentArray.length > 0)
                          ? <DownloadComponent filesObj={this.state.sciAttachmentArray} />
                          : <span></span>

                  }
              </div>
          </MDBCol>

      </MDBRow>

            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                {/* {
                    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        null
                        :
                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                            {i18n[this.getLocale()].preview}
                        </MDBBtn>
                } */}
                {/* {
                    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        null
                        :
                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                            {i18n[this.getLocale()].saveAsDraft}
                        </MDBBtn>
                } */}
                <MDBBtn className="submitBtn" type='submit' color="primary">
                    {i18n[this.getLocale()].submit}
                </MDBBtn>
            </MDBRow>
        </form>
    );
}
}

