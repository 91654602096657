import React,{ Component } from 'react';
import {    MDBCardBody, MDBCard,MDBContainer, MDBRow, MDBCol,MDBBtn,MDBModalBody,MDBModalHeader,MDBTableBody,MDBTableHead,MDBModal,MDBTable, MDBTableFoot } from "mdbreact";
import Config from '../../utils/Config';
import { confirmAlert } from 'react-confirm-alert';
import { Route , withRouter} from 'react-router-dom';
import ApiRequest from '../../services/ApiRequest';
import i18n from '../../i18n';
import _ from "lodash";
export default class InternalSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            dataRow:[],
            selectedOption:"",
            answers:[]
        }
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    handleChangeForText =(e,Questionid) => 
    {
if(this.state.answers.length>0)
{
    debugger;
    let tempanswers =this.state.answers;
    let tempobj = {};
    debugger;
    tempobj.QuestionID=Questionid;
    tempobj.Value= e.target.value;
    try{
  let index =  tempanswers.findIndex((e)=>e.Questionid===Questionid);
  if(index>-1)
  {
tempanswers.splice(index,1);
  }
    }
    catch{}
    
    tempanswers.push(tempobj);
    this.setState({answers:tempanswers});
   
    
}
else
{
    let tempanswers = [];
    let tempobj = {};
    tempobj.QuestionID=Questionid;
    tempobj.Value= e.target.value;
    tempanswers.push(tempobj);
    this.setState({answers:tempanswers});
}
    }
    handleChangeForOptions =(e,Questionid,questionindex,optionindex,FillInChopice,optionslength) => 
    {
   
if(FillInChopice && optionslength===optionindex)
{
    let tempquestions = this.state.dataRow;
    tempquestions[questionindex].showmore=true;
    this.setState({dataRow:tempquestions});
    return;
}

else if(this.state.answers.length>0)
{
    let tempquestions = this.state.dataRow;
    tempquestions[questionindex].showmore=false;
   
    
    debugger;
    let tempanswers =this.state.answers;
    let tempobj = {};
    debugger;
    tempobj.QuestionID=Questionid;
    tempobj.Value= e.target.value;
    try{
  let index =  tempanswers.findIndex((e)=>e.Questionid===Questionid);
  if(index>-1)
  {
tempanswers.splice(index,1);
  }
    }
    catch{}
    
    tempanswers.push(tempobj);
    this.setState({answers:tempanswers,dataRow:tempquestions});
   
    
}
else
{
    let tempanswers = [];
    let tempobj = {};
    tempobj.QuestionID=Questionid;
    tempobj.Value= e.target.value;
    tempanswers.push(tempobj);
    this.setState({answers:tempanswers});
}
    }

    handleChangeForSelect =(e,Questionid,questionindex,lastoption,FillInChopice) => 
    {
      
if(e.target.value===lastoption)
{
    let tempquestions = this.state.dataRow;
    tempquestions[questionindex].showmore=true;
    this.setState({dataRow:tempquestions});
    return;
}

else if(this.state.answers.length>0)
{
    debugger;
    let tempquestions = this.state.dataRow;
    tempquestions[questionindex].showmore=false;

    let tempanswers =this.state.answers;
    let tempobj = {};
    debugger;
    tempobj.QuestionID=Questionid;
    tempobj.Value= e.target.value;
    try{
  let index =  tempanswers.findIndex((e)=>e.Questionid===Questionid);
  if(index>-1)
  {
tempanswers.splice(index,1);
  }
    }
    catch{}
    
    tempanswers.push(tempobj);
    this.setState({answers:tempanswers,dataRow:tempquestions});
   
    
}
else
{
    let tempanswers = [];
    let tempobj = {};
    tempobj.QuestionID=Questionid;
    tempobj.Value= e.target.value;
    tempanswers.push(tempobj);
    this.setState({answers:tempanswers});
}
    }
    handleOptionChange = (e) => {
       
this.setState({selectedOption:e.target.value});
    }
     componentDidMount=()=>
    {
        let endPoint = 'GetAllQuestions'
        let url = Config.getUrl(endPoint);
        
        let payload = {};
    //    this.toggleLoadingSpinner(true);
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    payload.isInternal  = getDataFromLocalStorage.internalcontact;
    
    payload.userid=getDataFromLocalStorage.id;
       ApiRequest.sendRequestForPost(url, payload, res => {
        // this.toggleLoadingSpinner(false);
        debugger;
            
        if (res.Status) {
            let resArray = res.data;
            debugger;
            let tempanswers =[]; 
for(let i=0;i<resArray.length;i++)
{
if(resArray[i].DefaultValue)
{
    debugger;
    let tepitemanswer={}
    tepitemanswer.QuestionID=resArray[i].id;
    tepitemanswer.Value=resArray[i].DefaultValue;
    tempanswers.push(tepitemanswer);
}
}
            this.setState({
                dataRow: resArray,
                answers:tempanswers
            })
        }
       })
    }

    
    submitHandler = event => 
    {
        event.preventDefault();
        event.target.className += " was-validated";
        let endPoint = 'SaveSurveyAnswers'
        let url = Config.getUrl(endPoint);
        
        let payload = {};
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        payload.isInternal  = getDataFromLocalStorage.internalcontact;
        payload.UserName =getDataFromLocalStorage.NameAr;
        payload.UserId =  getDataFromLocalStorage.id;
        payload.QuestionAnswers= this.state.answers;
         payload.EntityName = getDataFromLocalStorage.EntityNameAr;
        let tempData = this.state.dataRow;
        let errorflag = false;

for(let i=0;i<tempData.length;i++)
{
    let index =  payload.QuestionAnswers.findIndex((e)=>e.QuestionID=== tempData[i].id);
     debugger;
    if(index<0)
    {
        tempData[i].isInvaid=true;
        errorflag=true;
    }
}
       
       if(errorflag)
       {
           this.setState({dataRow:tempData});
           
       }
        else
        {
            ApiRequest.sendRequestForPost(url, payload, res => {
                if (res.Status) {
                    this.props.alertSubmitForSuccess();
                }
            })
        }
     
 
    }
    render() 
    {
        
        return(
        
            this.state.dataRow.length>0 ?
            <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate>
<MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
<span style={{paddingRight:"15px",textAlign:"right",float:'right',direction:"rtl"}}>من منطلق حرص هيئة الموارد البشرية على تحسين نوعية وجودة الخدمات المقدمة لكافة الجهات الحكومية فإننا نطرح هذا الاستبيان لاستطلاع آرائكم وقياس مستوى الرضا عن الخدمات الإلكترونية المقدمة في نظام (HRA HUB) للجهات الحكومية خلال العام 2020، وذلك للاستفادة من مخرجات استطلاع الرأي في عمليات التطوير والتحسين على خدمات الهيئة.
 شاكرين لكم مساهمتكم وتعاونكم في تعبئة الحقول التالية، علما أنه سيتم التعامل مع بياناتكم بسرية تامة ولأغراض الدراسة فقط.

</span>
                    </MDBCol>
                    </MDBRow>

               {this.state.dataRow.map((item, index) => {
                return(
                    <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px', direction: this.state.isArabic ? "rtl" : 'ltr',paddingLeft:"15px",paddingRight:"15px",marginLeft:"15px",marginright:"15px" }} >
 <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{paddingLeft:"15px",paddingRight:"15px"}}>
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{item.Title}<span className="requireStar">*</span>   
                            </label>

                    
                {item.Type===1 ?   <input style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                type="text"
                                className="form-control"
                                id="req_subject"
                                maxLength={255}
                                name="req_subject"
                                onChange={(value) => { this.handleChangeForText(value, item.id) }}
                              Value={item.DefaultValue}
                                required
                            />
 :  item.Type===2 ?      
 <div style={{ float: this.state.isArabic ? '' : 'left', width: '100%', paddingLeft: "10px" }} className="custom-control custom-radio ">
     {item.Options.map((itema,indexa)=>{
         return(
            <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px', direction: this.state.isArabic ? "rtl" : 'ltr',paddingLeft:"15px",paddingRight:"15px",marginLeft:"15px",marginright:"15px" }} >
            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" style={{paddingLeft:"15px",paddingRight:"15px"}}>
           
      <div>      <input id={'Opt'+index+indexa}   name={item.Title} type="radio" className=""       onChange={(value) => { this.handleChangeForOptions(value, item.id,index,indexa,item.FillInChopice,item.Options.length-1) }}     style={{ float: this.state.isArabic ? 'right':''}} value={itema} required/>        <label className="" htmlFor="defaultInline1" style={{ float: this.state.isArabic ? 'right' : '', paddingRight:"15px",paddingLeft:"15px" }}>{itema}</label></div>
      
                 </MDBCol></MDBRow>
         )
     })}
 {item.showmore? <input style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? "rtl" : 'ltr', borderRadius: '5px' ,marginTop:"10px",borderColor:"red",borderWidth:"2px"}}
                                type="text"
                                className="form-control"
                                id="req_subject"
                                maxLength={255}
                                name="req_subject"
                                onChange={(value) => { this.handleChangeForText(value, item.id) }}
                              Value={item.DefaultValue}
                                required
                            />:null}
     </div> : 
     <div style={{ float: this.state.isArabic ? '' : 'left', width: '100%', paddingLeft: "10px" }} className="custom-control custom-radio ">
                        
                            <select  style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding"       onChange={(value) => { this.handleChangeForSelect(value, item.id,index,item.Options[item.Options.length-1],item.FillInChopice) }}
                                        //value={}
                                        required>
                                        <option value="0">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
     {item.Options.map((itema,indexa)=>{
         return(
            <option value={itema}>
{itema}
                </option>
       )
     })}
 </select>
  {item.showmore? <input style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? "rtl" : 'ltr', borderRadius: '5px',marginTop:"10px",borderColor:"red",borderWidth:"2px" }}
  type="text" maxLength={255}
  className="form-control"
  id="req_subject"

  name="req_subject"
  onChange={(value) => { this.handleChangeForText(value, item.id) }}
Value={item.DefaultValue}
  required
/>:null}
</div>
}
{item.isInvaid ?    <div className="textRed" style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div> :null}
             </div>   </MDBCol>
                </MDBRow>
            
                )
               
      
        })}
       <MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row',textAlign:"center" }}>
                 <MDBBtn style={{margin:"auto"}} className="submitBtn" type='submit' color="primary">
                        {i18n[this.getLocale()].submit}
                    </MDBBtn>
                     </MDBRow>
                      </form>
        :null);
    
    

    }

}