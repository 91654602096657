/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBRow } from "mdbreact";

export default class StepsComponent extends Component {

    constructor(props) {
        super(props);
    }

    getLength = () => {
        switch (this.props.steps.length) {
            case 2: return '50%';
            case 3: return '32%';
            case 4: return '22%';
            case 5: return '14%';
            case 6: return '10%';
            default: break;
        }
    }

    render() {
        return (
            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '7vh', padding: '1%', marginBottom: 1 }}>

                {this.props.steps.map((item, index) => {
                    var iconColor = '', lineColor = '', iconClass = '', num, showEditImg = false;
                    if (index === this.props.currentIndex - 1) {
                        lineColor = '#eeeeee';
                        iconClass = 'fas fa-pencil-alt pencilIcon';
                        showEditImg = true;
                    } else if (index < this.props.currentIndex) {
                        lineColor = '#00BCD4';
                        iconColor = '#00BCD4';
                        iconClass = 'fas fa-check-circle';
                    } else {
                        lineColor = '#eeeeee';
                        iconColor = '#eeeeee';
                        iconClass = 'iconNumber';
                        num = index + 1;
                    }

                    return (
                        <span style={{ width: index < this.props.steps.length - 1 ? this.getLength() : 'auto', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'center' }}>

                            {
                                showEditImg
                                    ?
                                    <span className="pencilIcon" style={{ width: "70px" }}>
                                        <img src={require("./../assets/images/edit_one.png")} alt="pencil-icon" />
                                    </span>
                                    :
                                    <i className={iconClass} style={{
                                        fontSize: 40,
                                        color: iconColor
                                    }}>
                                        {num}
                                    </i>
                            }
                            {index < this.props.steps.length - 1 ? <div style={{ width: '100%', height: 1, backgroundColor: lineColor, alignSelf: 'center' }}></div> : ''}
                        </span>)
                })}
            </MDBRow>
        );
    }
}