import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import "./../../css/style.css";
import Config from "./../../utils/Config";
import i18n from "./../../i18n";
import DownloadComponent from "./../DownloadComponent";
import StepsComponent from "./../../components/StepsComponent";

export default class AmendSalariesScaleStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getAmendSalaryScaleRequestTypeId(),
            approvedScaleOfGrades: this.props.sendData.approvedScaleOfGrades,
            approvedScaleOfGradesUrl: '',
            salariesCompensationPolicy: this.props.sendData.salariesCompensationPolicy,
            salariesCompensationPolicyUrl: '',
            tableOfTheAlignment: this.props.sendData.tableOfTheAlignment,
            tableOfTheAlignmentUrl: '',
            proposedScale: this.props.sendData.proposedScale,
            proposedScaleUrl: '',
            approvedGradesOfScaleAndSalFileObj: "",
            salariesAndCompensationPolicyFIleObj: "",
            tableAlignmentOfGradesFIleObj: "",
            proposedScaleFileObj: "",
            approvedScaleOfGradesErr: "",
            salariesCompensationErr: "",
            tableOfTheAlignmentErr: "",
            proposedScaleErr: "",
            illustrationsOfCurrentErr: "",
            comparativeStudyErr: "",
            //copyOfTheDecisionErr: "",
            detailsOfAnnualCostErr: "",
            entityChairmanErr: '',
            annualCostFileObj: "",
            comparitiveStudyFIleObj: "",
            entityChairmenFileObj: "",
            //approvedGradesOfScaleAndSalFileObj0: "",
            entityChairman: this.props.sendData.entityChairman,
            entityChairmanUrl: '',
            illustrationsOfCurrent: this.props.sendData.illustrationsOfCurrent,
            illustrationsOfCurrentShortName: this.props.sendData.illustrationsOfCurrentShortName,
            illustrationsOfCurrentUrl: '',
            comparativeStudy: this.props.sendData.comparativeStudy,
            comparativeStudyUrl: '',
            detailsOfAnnualCost: this.props.sendData.detailsOfAnnualCost,
            detailsOfAnnualCostUrl: '',
            //copyOfTheDecision: this.props.sendData.copyOfTheDecision,
            //copyOfTheDecisionUrl: '',
            editAttachmentArray: [],
            approvedScaleAndSalariesArray: [],
            salAndCompensastionArray: [],
            proposedScaleArray: [],
            tableOfAlignmentArray: [],
            comparativeStudyArray: [],
            illustrationOfCurrentArray: [],
            copyOfDecisionArray: [],
            detailsOfAnnualCostArray: [],
            entityChairmanApproveArray: [],
            illUstrationOfSalLinesFileArray: []
        };
    }

    componentDidMount = () => {

        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments
            }, () => {
                let tempApprovedScaleAndSalariesArray = [];
                let tempSalAndCompensastionArray = [];
                let tempProposedScaleArray = [];
                let tempTableOfAlignmentArray = [];
                let tempComparativeStudyArray = [];
                let tempIllustrationOfCurrentArray = [];
                let tempCopyOfDecisionArray = [];
                let tempDetailsOfAnnualCostArray = [];
                let tempEntityChairmanApproveArray = [];

                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                  
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getAmendSalaryScaleApprovedScaleAndGradeAttachmentId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempApprovedScaleAndSalariesArray.push(temp);

                                this.setState({
                                    //approvedScaleOfGrades: item.filename,
                                    approvedScaleOfGradesUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getAmendSalaryScaleSalariesAndConpansationAttachmentId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempSalAndCompensastionArray.push(temp);

                                this.setState({
                                    //salariesCompensationPolicy: item.filename,
                                    salariesCompensationPolicyUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScalePropsedScaleAttachmentId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempProposedScaleArray.push(temp);

                                this.setState({
                                    //proposedScale: item.filename,
                                    proposedScaleUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScaleAlignmentTableGradeAttachmentId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempTableOfAlignmentArray.push(temp);

                                this.setState({
                                    //tableOfTheAlignment: item.filename,
                                    tableOfTheAlignmentUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScaleStudyWithSimilarEntityAttachmentId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempComparativeStudyArray.push(temp);

                                this.setState({
                                    //comparativeStudy: item.filename,
                                    comparativeStudyUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScaleCurrentAndProposedSalaryLineAttachmentId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempIllustrationOfCurrentArray.push(temp);

                                this.setState({
                                    //illustrationsOfCurrent: item.filename,
                                    illustrationsOfCurrentUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScaleAdminAuthIsRegisteredAttachmentId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempCopyOfDecisionArray.push(temp);

                                this.setState({
                                    //copyOfTheDecision: item.filename,
                                    //copyOfTheDecisionUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScaleAnnualCostAttachmentId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempDetailsOfAnnualCostArray.push(temp);

                                this.setState({
                                    //detailsOfAnnualCost: item.filename,
                                    detailsOfAnnualCostUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAmendSalaryScaleChairmanApproveAttachmentId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEntityChairmanApproveArray.push(temp);

                                this.setState({
                                    //entityChairman: item.filename,
                                    entityChairmanUrl: item.aliasfilename
                                })

                            }
                        })

                        this.setState({
                            approvedScaleAndSalariesArray: tempApprovedScaleAndSalariesArray,
                            salAndCompensastionArray: tempSalAndCompensastionArray,
                            proposedScaleArray: tempProposedScaleArray,
                            tableOfAlignmentArray: tempTableOfAlignmentArray,
                            comparativeStudyArray: tempComparativeStudyArray,
                            illustrationOfCurrentArray: tempIllustrationOfCurrentArray,
                            copyOfDecisionArray: tempCopyOfDecisionArray,
                            detailsOfAnnualCostArray: tempDetailsOfAnnualCostArray,
                            entityChairmanApproveArray: tempEntityChairmanApproveArray,
                        })
                    }
                }

            });

        }

    }

    checkValidations = () => {
        let
            approvedScaleOfGradesErr = '',
            salariesCompensationErr = '',
            tableOfTheAlignmentErr = '',
            proposedScaleErr = '',
            illustrationsOfCurrentErr = '',
            entityChairmanErr = '',
            detailsOfAnnualCostErr = '',
            comparativeStudyErr = '';

        if (this.state.approvedScaleAndSalariesArray && this.state.approvedScaleAndSalariesArray.length > 0) {
            approvedScaleOfGradesErr = "";
        } else {
            if (!this.state.approvedScaleOfGrades) {
                approvedScaleOfGradesErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.salAndCompensastionArray && this.state.salAndCompensastionArray.length > 0) {
            salariesCompensationErr = "";
        } else {
            if (!this.state.salariesCompensationPolicy) {
                salariesCompensationErr = 'thisFieldIsRequired';
            }
        }

        if (!this.state.tableOfTheAlignment) {
            tableOfTheAlignmentErr = '';
        }

        if (this.state.proposedScaleArray && this.state.proposedScaleArray.length > 0) {
            proposedScaleErr = "";
        } else {
            if (!this.state.proposedScale) {
                proposedScaleErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.illustrationOfCurrentArray && this.state.illustrationOfCurrentArray.length > 0) {
            illustrationsOfCurrentErr = "";
        } else {
            if (!this.state.illustrationsOfCurrent) {
                illustrationsOfCurrentErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.entityChairmanApproveArray && this.state.entityChairmanApproveArray.length > 0) {
            entityChairmanErr = "";
        } else {
            if (!this.state.entityChairman) {
                entityChairmanErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.detailsOfAnnualCostArray && this.state.detailsOfAnnualCostArray.length > 0) {
            detailsOfAnnualCostErr = "";
        } else {
            if (!this.state.detailsOfAnnualCost) {
                detailsOfAnnualCostErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.comparativeStudyArray && this.state.comparativeStudyArray.length > 0) {
            comparativeStudyErr = "";
        } else {
            if (!this.state.comparativeStudy) {
                comparativeStudyErr = 'thisFieldIsRequired';
            }
        }

        this.setState({
            approvedScaleOfGradesErr: approvedScaleOfGradesErr,
            salariesCompensationErr: salariesCompensationErr,
            tableOfTheAlignmentErr: tableOfTheAlignmentErr,
            proposedScaleErr: proposedScaleErr,
            illustrationsOfCurrentErr: illustrationsOfCurrentErr,
            entityChairmanErr: entityChairmanErr,
            detailsOfAnnualCostErr: detailsOfAnnualCostErr,
            //copyOfTheDecisionErr: copyOfTheDecisionErr,
            comparativeStudyErr: comparativeStudyErr
        }, () => {

            if (
                !approvedScaleOfGradesErr &&
                !salariesCompensationErr &&
                !tableOfTheAlignmentErr &&
                !proposedScaleErr &&
                !illustrationsOfCurrentErr &&
                !entityChairmanErr &&
                !detailsOfAnnualCostErr &&
                //copyOfTheDecisionErr === "" &&
                !comparativeStudyErr
            ) {
                this.props.getData.stepTwoData({
                    requestId: this.state.requestId,
                    isEdit: this.state.isEdit,
                    approvedScaleOfGrades: this.state.approvedScaleOfGrades,
                    salariesCompensationPolicy: this.state.salariesCompensationPolicy,
                    tableOfTheAlignment: this.state.tableOfTheAlignment,
                    proposedScale: this.state.proposedScale,
                    entityChairman: this.state.entityChairman,
                    illustrationsOfCurrent: this.state.illustrationsOfCurrent,
                    comparativeStudy: this.state.comparativeStudy,
                    detailsOfAnnualCost: this.state.detailsOfAnnualCost,
                    //copyOfTheDecision: this.state.copyOfTheDecision,
                });
            }
        });
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
    };

    approvedScaleOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ approvedScaleOfGrades: '', approvedScaleOfGradesErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    this.setState({
                        approvedScaleOfGrades: e.target.files[0].name,
                        approvedScaleOfGradesErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                approvedGradesOfScaleAndSalFileObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile2(this.state.approvedGradesOfScaleAndSalFileObj);
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ approvedScaleOfGrades: '', approvedScaleOfGradesErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ approvedScaleOfGrades: '', approvedScaleOfGradesErr: 'thisFieldIsRequired' });
        }
    };

    salariesCompensationOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ salariesCompensationPolicy: '', salariesCompensationErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        salariesCompensationPolicy: e.target.files[0].name,
                        salariesCompensationErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                salariesAndCompensationPolicyFIleObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile1(this.state.salariesAndCompensationPolicyFIleObj);
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ salariesCompensationPolicy: '', salariesCompensationErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ salariesCompensationPolicy: '', salariesCompensationErr: 'thisFieldIsRequired' });
        }
    };

    tableOfTheAlignmentOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ tableOfTheAlignment: '', tableOfTheAlignmentErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        tableOfTheAlignment: e.target.files[0].name,
                        tableOfTheAlignmentErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                tableAlignmentOfGradesFIleObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile4(this.state.tableAlignmentOfGradesFIleObj);
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ tableOfTheAlignment: '', tableOfTheAlignmentErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ tableOfTheAlignment: '', tableOfTheAlignmentErr: 'thisFieldIsRequired' });
        }
    };

    proposedScaleOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ proposedScale: '', proposedScaleErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        proposedScale: e.target.files[0].name,
                        proposedScaleErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                proposedScaleFileObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile3(this.state.proposedScaleFileObj);
                             
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ proposedScale: '', proposedScaleErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ proposedScale: '', proposedScaleErr: 'thisFieldIsRequired' });
        }
    };

    illustrationsOfCurrentOnChange = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                e.preventDefault();
                let fileNames = "";
                let fileSupportArray = [];

                for (let m = 0; m < e.target.files.length; m++) {
                    let inpFileName = e.target.files[m].name;
                    let fileSize = e.target.files[m].size;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            illustrationsOfCurrent: '',
                            illustrationsOfCurrentShortName: '',
                            illustrationsOfCurrentErr: 'invalidFileType'
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let fileObj = e.target.files[m];
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let illustrationsOfCurrentShortName = fileNames.substring(0, 50);

                            this.setState({
                                illustrationsOfCurrent: fileNames,
                                illustrationsOfCurrentShortName: illustrationsOfCurrentShortName,
                                illustrationsOfCurrentErr: ''
                            }, () => {
                                let tempFile = fileObj;
                                let reader = new FileReader();
                                reader.readAsDataURL(tempFile);
                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({
                                        illUstrationOfSalLinesFileArray: fileSupportArray
                                    }, () => {
                                        Config.setbase64ofAmendSalaryScreenfile5(this.state.illUstrationOfSalLinesFileArray);
                                    })
                                }
                            });
                        }
                        else {
                            e.target.value = '';
                            this.setState({
                                illustrationsOfCurrent: '',
                                illustrationsOfCurrentShortName: '',
                                illustrationsOfCurrentErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({ 
                    illustrationsOfCurrent: '', 
                    illustrationsOfCurrentShortName: '', 
                    illustrationsOfCurrentErr: 'fileLimitErr' 
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({ 
                illustrationsOfCurrent: '', 
                illustrationsOfCurrentShortName: '', 
                illustrationsOfCurrentErr: 'thisFieldIsRequired' 
            });
        }
    }

    comparativeStudyOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ comparativeStudy: '', comparativeStudyErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        comparativeStudy: e.target.files[0].name,
                        comparativeStudyErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                comparitiveStudyFIleObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile6(this.state.comparitiveStudyFIleObj);
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ comparativeStudy: '', comparativeStudyErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ comparativeStudy: '', comparativeStudyErr: 'thisFieldIsRequired' });
        }
    };

    annualCostFileOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ detailsOfAnnualCost: '', detailsOfAnnualCostErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    this.setState({
                        detailsOfAnnualCost: e.target.files[0].name,
                        detailsOfAnnualCostErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                annualCostFileObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile7(this.state.annualCostFileObj);
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ detailsOfAnnualCost: '', detailsOfAnnualCostErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ detailsOfAnnualCost: '', detailsOfAnnualCostErr: 'thisFieldIsRequired' });
        }

    };

    entityChairmanFileOnChange = e => {
        if (e.target.files.length > 0) {
            e.preventDefault();
            
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ entityChairman: '', entityChairmanErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        entityChairman: e.target.files[0].name,
                        entityChairmanErr: ""
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                entityChairmenFileObj: reader.result
                            }, () => {
                                Config.setbase64ofAmendSalaryScreenfile9(this.state.entityChairmenFileObj);
                            }
                            );
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ entityChairman: '', entityChairmanErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ entityChairman: '', entityChairmanErr: "thisFieldIsRequired" });
        }

    };

    saveAsDraft = () => {
        this.props.getData.stepTwoData({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            approvedScaleOfGrades: this.state.approvedScaleOfGrades,
            salariesCompensationPolicy: this.state.salariesCompensationPolicy,
            tableOfTheAlignment: this.state.tableOfTheAlignment,
            proposedScale: this.state.proposedScale,
            entityChairman: this.state.entityChairman,
            illustrationsOfCurrent: this.state.illustrationsOfCurrent,
            illustrationsOfCurrentShortName: this.state.illustrationsOfCurrentShortName,
            comparativeStudy: this.state.comparativeStudy,
            detailsOfAnnualCost: this.state.detailsOfAnnualCost
        });
    };

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            approvedScaleOfGrades: this.state.approvedScaleOfGrades,
            salariesCompensationPolicy: this.state.salariesCompensationPolicy,
            tableOfTheAlignment: this.state.tableOfTheAlignment,
            proposedScale: this.state.proposedScale,
            entityChairman: this.state.entityChairman,
            illustrationsOfCurrent: this.state.illustrationsOfCurrent,
            illustrationsOfCurrentShortName: this.state.illustrationsOfCurrentShortName,
            comparativeStudy: this.state.comparativeStudy,
            detailsOfAnnualCost: this.state.detailsOfAnnualCost
        });
    }

    goback = () => {
        this.props.getPrev.stepTwoDataPrev({
            approvedScaleOfGrades: this.state.approvedScaleOfGrades,
            salariesCompensationPolicy: this.state.salariesCompensationPolicy,
            tableOfTheAlignment: this.state.tableOfTheAlignment,
            proposedScale: this.state.proposedScale,
            entityChairman: this.state.entityChairman,
            illustrationsOfCurrent: this.state.illustrationsOfCurrent,
            illustrationsOfCurrentShortName: this.state.illustrationsOfCurrentShortName,
            comparativeStudy: this.state.comparativeStudy,
            detailsOfAnnualCost: this.state.detailsOfAnnualCost,
            //copyOfTheDecision: this.state.copyOfTheDecision,
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    render() {
        return (
            <form
                style={{ marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>
                
                <StepsComponent
                    steps={["Step1", "Step2",]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <br />

                <MDBRow
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="approvedScaleOfGrades" style={{
                                display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                width: "100%"
                            }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].approved_scale_of_grades_salaries}
                                <span style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", color: "red" }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="approvedScaleFile"
                                        type="file"
                                        name="approvedScaleOfGrades"
                                        onChange={event => this.approvedScaleOnChange(event)}
                                    />
                                    <label
                                        htmlFor="approvedScaleFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                    >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>

                                    <label htmlFor="approvedScaleFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.approvedScaleOfGrades}</span>
                                    </label>
                                </div>
                                {
                                    this.state.approvedScaleOfGradesErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.approvedScaleOfGradesErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.approvedScaleOfGradesErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.approvedScaleAndSalariesArray &&
                                        this.state.approvedScaleAndSalariesArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.approvedScaleAndSalariesArray} />
                                        : <span></span>
                                }
                            </div>

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="salariesCompensationPolicy"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].salaries_compensation_policy}
                                <span style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", color: "red" }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="salariesCompensationFile"
                                        type="file"
                                        name="salariesCompensationPolicy"
                                        onChange={event => this.salariesCompensationOnChange(event)}
                                    />
                                    <label
                                        htmlFor="salariesCompensationFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                    >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="salariesCompensationFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}

                                    >
                                        <span>{this.state.salariesCompensationPolicy}</span>
                                    </label>
                                </div>
                                {
                                    this.state.salariesCompensationErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.salariesCompensationErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.salariesCompensationErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.salAndCompensastionArray &&
                                        this.state.salAndCompensastionArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.salAndCompensastionArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="proposedScale"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].proposed_scale}
                                <span style={{ color: "red", display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="proposedScaleFile"
                                        type="file"
                                        name="proposedScale"
                                        onChange={event => this.proposedScaleOnChange(event)}
                                    />
                                    <label
                                        htmlFor="proposedScaleFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>

                                    <label htmlFor="proposedScaleFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        <span>{this.state.proposedScale}</span>
                                    </label>
                                </div>
                                {
                                    this.state.proposedScaleErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.proposedScaleErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.proposedScaleErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.proposedScaleArray &&
                                        this.state.proposedScaleArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.proposedScaleArray} />
                                        : <span></span>
                                }

                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="tableOfTheAlignment"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].table_of_the_alignment_of_the_grades}
                                {/* <span style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", color: "red" }}>
                                    *
                                </span> */}
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="tableOfTheAlignmentFile"
                                        type="file"
                                        name="tableOfTheAlignment"
                                        onChange={event => this.tableOfTheAlignmentOnChange(event)}
                                        required
                                    />
                                    <label
                                        htmlFor="tableOfTheAlignmentFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                    >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>

                                    <label htmlFor="tableOfTheAlignmentFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.tableOfTheAlignment}</span>
                                    </label>
                                </div>
                                {/* {
                                    this.state.tableOfTheAlignmentErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                } */}
                                {
                                    this.state.tableOfTheAlignmentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.tableOfTheAlignmentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }

                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.tableOfAlignmentArray &&
                                        this.state.tableOfAlignmentArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.tableOfAlignmentArray} />
                                        : <span></span>
                                }
                            </div>

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>
                    <MDBCol
                        xs="6"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        style={this.props.isArabic ? { display: "flex", flexDirection: "column", justifyContent: "flex-end" } : null}>
                        <div className="form-group">
                            <label
                                htmlFor="comparativeStudy"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row",
                                    width: "100%"
                                }}>
                                {
                                    i18n[this.props.isArabic ? "ar" : "en"]
                                        .comparative_study_with_similar_entities
                                }

                                <span style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", color: "red" }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="comparativeStudyFile"
                                        type="file"
                                        name="comparativeStudy"
                                        onChange={event =>
                                            this.comparativeStudyOnChange(event)
                                        }
                                    />
                                    <label
                                        htmlFor="comparativeStudyFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"} >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>

                                    <label
                                        htmlFor="comparativeStudyFile"
                                        className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.comparativeStudy}</span>
                                    </label>
                                </div>
                                {
                                    this.state.comparativeStudyErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.comparativeStudyErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.comparativeStudyErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.comparativeStudyArray &&
                                        this.state.comparativeStudyArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.comparativeStudyArray} />
                                        : <span></span>
                                }
                            </div>

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="illustrationsOfCurrent"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row",
                                    width: "100%"
                                }}>
                                {
                                    i18n[this.props.isArabic ? "ar" : "en"]
                                        .illustrations_current_and_proposed_salary_lines
                                }
                                <span style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", color: "red" }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="illustrationsOfCurrentFile"
                                        type="file"
                                        name="illustrationsOfCurrent"
                                        onChange={event => this.illustrationsOfCurrentOnChange(event)}
                                        multiple
                                    />
                                    <label
                                        htmlFor="illustrationsOfCurrentFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>

                                    <label
                                        htmlFor="illustrationsOfCurrentFile"
                                        className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        {/* <span>{this.state.illustrationsOfCurrent}</span> */}

                                        <span className="attachmentName">{this.state.illustrationsOfCurrentShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.illustrationsOfCurrentShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.illustrationsOfCurrent}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.illustrationsOfCurrentErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.illustrationsOfCurrentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.illustrationsOfCurrentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.illustrationsOfCurrentErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", justifyContent: this.props.isArabic ? "flex-start" : "flex-end", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                                </p>
                                {
                                    (this.state.illustrationOfCurrentArray &&
                                        this.state.illustrationOfCurrentArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.illustrationOfCurrentArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="entityChairman"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].entity_chairman_approve}
                                <span style={{
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                    color: "red"
                                }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="entityChairmanFile"
                                        type="file"
                                        name="entityChairman"
                                        onChange={event =>
                                            this.entityChairmanFileOnChange(event)
                                        }
                                    />
                                    <label htmlFor="entityChairmanFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label htmlFor="entityChairmanFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        <span>{this.state.entityChairman}</span>
                                    </label>
                                </div>
                                {
                                    this.state.entityChairmanErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.entityChairmanErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.entityChairmanErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.entityChairmanApproveArray &&
                                        this.state.entityChairmanApproveArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.entityChairmanApproveArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="detailsOfAnnualCost"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row",
                                    width: "100%"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].details_of_annual_cost}
                                <span style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", color: "red" }}>
                                    *
                                </span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="annualCostFile"
                                        type="file"
                                        name="detailsOfAnnualCost"
                                        onChange={event => this.annualCostFileOnChange(event)}
                                    />
                                    <label
                                        htmlFor="annualCostFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                    >
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>

                                    <label htmlFor="annualCostFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.detailsOfAnnualCost}</span>
                                    </label>
                                </div>
                                {
                                    this.state.detailsOfAnnualCostErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.detailsOfAnnualCostErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.detailsOfAnnualCostErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.detailsOfAnnualCostArray &&
                                        this.state.detailsOfAnnualCostArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.detailsOfAnnualCostArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ flexDirection: this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                    <MDBBtn onClick={this.goback} color="gray" className="previousAndClearBtn">{i18n[this.getLocale()].previous}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn type="submit" color="primary" className="submitBtn">{i18n[this.props.isArabic ? "ar" : "en"].submit}</MDBBtn>
                </MDBRow>

            </form>
        );
    }
}
