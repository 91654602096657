import React, { Component } from "react";
import { MDBCol,MDBRow, MDBBtn, MDBTableHead, MDBTable, MDBTableBody, MDBCard, MDBCardBody, MDBFormInline, MDBContainer } from 'mdbreact';
import i18n from "./../../i18n";
import {getAllCommentsForRequest, saveCommentPayload, updateStudyorFeedbackPayload, uploadDocument, saveStatusPayload } from "./../../utils/Payload";
import ApiRequest from "./../../services/ApiRequest";
import Config from "./../../utils/Config";
import LoadingOverlay from "react-loading-overlay";
import ToastComponent from './../ToastComponent';

export default class AssignToComponent extends Component {

    constructor(props) {
        super(props);
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        
        this.state = {
            Files:[],
            attachedTwoErr: '',
            statusUpdateInfoError: "",
            isLoading: false,
            fileName: "",
            actualAttachment: "",
            comment: "",
            fileType: '', commentsArray: [], 
            checkedItems: new Map(),userid:getDataFromLocalStorage.id,canCloseRequest:getDataFromLocalStorage.permissionType.canCloseRequest,closerequest:0,
            iseditcomment:this.props.iseditcomment,
            Commentfilename:"",

            
        }
        this.HandelcheckBox = this.HandelcheckBox.bind(this);
        this.handelcanCloseRequest=this.handelcanCloseRequest.bind(this);
        
    }
    GetComment =()=>{

        let id = this.props.id;
        let payload ={};
        payload.requestid=id;
        payload.requestType=this.props.requesttypeid;
        payload.userid=Config.getLocalStorageLoginData().id;
         let endPoint = 'GetCommentbyID'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                     
                       
                    
                         
                            this.setState({
                                comment:  res.data.comment,
                                Commentfilename:res.data.Commentfilename,
                            }, () => {
                                // TODO
                            })
                       
                    } else {
                        // TODO
                    }
                })
            })
        })
    
    }

    uploadAttachmentForEditcomment = () => {
        debugger;
        let res = false;

        for(let i=0;i<this.state.Files.length;i++)
        {
        let attachment =this.state.Files[i] ;
        if (attachment !== '') {
            let objAttachment = {};
            objAttachment.requestid = this.props.id;
            objAttachment.filetype = this.state.fileType;
            objAttachment.filename = this.state.fileName;
            objAttachment.requesttype  = this.props.requesttypeid;
            let tempBase64 = attachment;
            objAttachment.fileContent = tempBase64.split(',')[1];
            let payloadForAttachment = uploadDocument(objAttachment);
            let endPointForAttachment = 'UploadAttachmentForEditcomment'
            let urlForAttachment = Config.getUrl(endPointForAttachment);
            
            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(urlForAttachment, payloadForAttachment, resForAttachment => {
                    if (resForAttachment.Status) {
               if(i===this.state.Files.length-1)   
               {
                if (res === true) {


                    if (this.props.requesttypeid != 4) {
                        this.callSaveStatus();
                    }
                    else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.toggle(true);
                        })
                    }
                                        } 
                                        else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.toggle(false);
                        })
                    }
               }
                }
            }
                )
            })
        } else {
            // TODO
        }
    }

  
    }

    updateEditComment = () => {
        debugger;
      
        let obj = {}
        obj.id = this.props.id;;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.notes = this.state.comment;
        obj.isEditComment=this.state.iseditcomment;
        let payload = saveCommentPayload(obj);
        let endPoint = 'SaveComment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        debugger;
                        let attachment = this.state.Files;
                        this.setState({
                            isLoading: true
                        }, () => {
                            if (attachment && attachment.length>0) {
                                this.uploadAttachmentForEditcomment();
                            } else {
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    this.props.toggle(true);
                                })
                            }
                        })
                       
                    } else {
                        // TODO
                        this.props.toggle(false);
                        ToastComponent.notifyErr(i18n[this.props.isArabic ? 'ar' : 'en'].failedtosavecomment);
                    }
                })
            })
        }
        )
    }
    componentDidMount = () => {
        if(this.state.iseditcomment !=0 && this.props.title != "addReply"&&this.props.title != "CloseRequest" && this.props.title != "addStudy")
        {
       
             this.GetComment();}
        // let pathName = '';
        if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
            this.setState({
                fileType: Config.getInformationRequestFeedbackAttachmentId(),
            });
        } else if (this.props.requesttypeid === Config.getSciRequestTypeId()) {
            this.setState({
                fileType: Config.getSciReplyAttachmentId(),
            });
        } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForBonusId(),
            })
        } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForAmendSalaryScaleId(),
            })
        } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForEmployeeSalID(),
            })
        } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForHrReviewId(),
            })
        } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForAdHousingId(),
            })
        } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForSecondmentId(),
            })
        } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()||this.props.requesttypeid === Config.getPromotionTypeId()||this.props.requesttypeid === Config.getSickLeaveTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForPatientId(),
            })
        } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForPatientId(),
            })
        } else if (this.props.requesttypeid === Config.getExtendCreateObjectiveId()) {
            this.setState({
                fileType: Config.getHraStudyForExtendCreate(),
            })
        } else if (this.props.requesttypeid === Config.getExtendReviewObjectiveId()) {
            this.setState({
                fileType: Config.getHraStudyForExtendReview(),
            })
        } else if (this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
            this.setState({
                fileType: Config.getHraStudyForExtendAnnualPerformance(),
            })
        } else if (this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
            this.setState({
                fileType: Config.getHraStudyForAddAndEditPerformance(),
            })
        } else if (this.props.requesttypeid === Config.getJoinAndImplementId()) {
            this.setState({
                fileType: Config.getHraStudyForJoinAndImplement(),
            })
        } else if (this.props.requesttypeid === Config.getOpenComplainsStageId()) {
            this.setState({
                fileType: Config.getHraStudyForOpenGrievvance(),
            })
        } else if (this.props.requesttypeid === Config.getAmendOnSharedGovermentId()) {
            this.setState({
                fileType: Config.getHraStudyForAmendOnSharedGoverment(),
            })
        } else if (this.props.requesttypeid === Config.getReportAndStaticId()) {
            this.setState({
                fileType: Config.getHraStudyForReportAndStatic(),
            })
        } else if (this.props.requesttypeid === Config.getOtherRequest()) {
            this.setState({
                fileType: Config.getHraStudyForOtherRequest(),
            }) 
        }
        // organization structure
            else if (this.props.requesttypeid === Config.getORGRequestTypeId()) {
            this.setState({
                fileType: Config.getHraStudyForOrganizationStructureRequest(),
            })
        }
    }; 

    handelcanCloseRequest()
    {
     
        this.state.closerequest==1 ? this.setState({closerequest:0}):this.setState({closerequest:1});
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    getValue = () => {
        if (this.state.fileName !== "") {
            this.props.toggle(false);
        }
    };

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        // if (this.state.comment !== "") {
        //   if (this.props.title === "addReply") {
        //     this.callAddReply();
        //   }
        // } else {
        //   if (this.props.title === "addStudy") {
        //     this.uploadAttachmentForAddStudy();
        //   }
        // }
        debugger;
        if(this.state.iseditcomment !=0 && this.props.title != "addReply"&&this.props.title != "CloseRequest" && this.props.title != "addStudy")
        {
                this.updateEditComment();
        }
        else if (this.props.title === "addReply"||this.props.title === "CloseRequest" || this.props.title === "addStudy" ) {
           
            //if (this.state.comment === '' || this.state.fileName === '') {
                if (this.state.comment === '' ) {
                this.setState({
                    //attachedTwoErr: i18n[this.getLocale()].thisFieldIsRequired
                    //attachedTwoErr: 'thisFieldIsRequired'
                })
            } else {
               //
                this.callAddReply();
            }
        } 
        else if (this.props.title === "addStudy" ) {
            if (this.state.fileName === '') {
                this.setState({
                    // attachedTwoErr: i18n[this.getLocale()].thisFieldIsRequired
                    attachedTwoErr: 'thisFieldIsRequired'
                })
            } else {
                this.uploadAttachmentForAddStudy();
            }
        }
    };
    officialLetterOnChange = e => {
        let tempfiles = [];    debugger;
       if (e.target.files.length > 0) {
           for(let i=0; i<e.target.files.length;i++)
           {
               let element=e.target.files[i];
           
           
               let inpFileName = element.name;
               let fileSize = element.size;
               let fileExt = inpFileName.split('.').pop().toUpperCase();
               let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
               if (allowedExtensions.indexOf(fileExt) <= -1) {
                   e.target.value = '';
                   this.setState({
                       officialLetter: '',
                       officalLetterErr: 'invalidFileType'
                   });
                   return;
               }
               else {
                   debugger;
                   if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                       let tempobj = {};
                       let fileObj = element;
                       tempobj.filename=element.name;
                       let reader = new FileReader();
                       let file = fileObj;
                       reader.onloadend = () => {
                           tempobj.filecontent=reader.result;
                       };
                       reader.readAsDataURL(file);
                       tempfiles.push(tempobj);
                   }
                   else {
                       e.target.value = '';
                       this.setState({
                           officialLetter: '',
                           officalLetterErr: 'invalidFileSize'
                       });
                       return;
                   }  
               }
               
   
           }
         
   this.setState({
       Files:tempfiles
   });
           // else {
           //     if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
           //         let fileObj = e.target.files[0];
           //         this.setState({
           //             officialLetter: e.target.files[0].name,
           //             officalLetterErr: ''
           //         }, () => {
           //             let reader = new FileReader();
           //             let file = fileObj;
           //             reader.onloadend = () => {
           //                 this.setState({
           //                     actualOfficialLetter: reader.result,
           //                 }, () => {
           //                     Config.setBase64ForSciOfficalLetter(this.state.actualOfficialLetter);
           //                 });
           //             };
           //             reader.readAsDataURL(file);
           //         });
   
           //     } else {
           //         e.target.value = '';
           //         this.setState({
           //             officialLetter: '',
           //             officalLetterErr: 'invalidFileSize'
           //         });
           //     }
           // }
   
       } else {
           e.target.value = '';
           this.setState({ officialLetter: '' });
       }
   
   };
    // officialLetterOnChange = (e) => {
    //     if (e.target.files.length > 0) {
    //         let inpFileName = e.target.files[0].name;
    //         let fileExt = inpFileName.split('.').pop().toUpperCase();
    //         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX'];
    //         if (allowedExtensions.indexOf(fileExt) <= -1) {
    //             this.setState({
    //                 fileName: '',
    //                 //attachedTwoErr: i18n[this.getLocale()].invalidFileType
    //                 attachedTwoErr: 'invalidFileType'
    //             });
    //         } else {
    //             this.setState({ fileName: e.target.files[0].name, attachedTwoErr: '' });
    //             let tempFile = e.target.files[0];
    //             let reader = new FileReader();
    //             reader.readAsDataURL(tempFile);
    //             reader.onloadend = () => {
    //                 this.setState({
    //                     actualAttachment: reader.result
    //                 }, () => {
    //                     Config.setBase64ForInformatinRequestForReply(this.state.actualAttachment);
    //                 });
    //             }
    //         }
    //     } else {
    //         this.setState({ fileName: '', attachedTwoErr: 'thisFieldIsRequired' });
    //     }
    // };

    // uploadAttachment = (attachment) => {
    //     let objAttachment = {};
    //     objAttachment.requestid = this.props.id;
    //     objAttachment.requesttype  = this.props.requesttypeid;
    //     alert('fff');
    //     debugger;
    //     if(objAttachment.requesttype==10953)
    //     {
    //         objAttachment.filetype = 10959;
    //     }
    //     else
    // {
    //     objAttachment.filetype = this.state.fileType;
    // }

    //     objAttachment.filename = this.state.fileName;
        
    //     let tempBase64 = attachment;
    //     objAttachment.fileContent = tempBase64.split(',')[1];
    //     // call upload attachment API
    //     let payloadForAttachment = uploadDocument(objAttachment);
    //     let endPointForAttachment = 'UploadAttachment'
    //     let urlForAttachment = Config.getUrl(endPointForAttachment);
        
    //     ApiRequest.sendRequestForPost(urlForAttachment, payloadForAttachment, resForAttachment => {
    //         if (resForAttachment.Status) {
    //             this.updateComment();
    //             // this.props.toggle(true);
    //         } else {
    //             this.setState({
    //                 isLoading: false
    //             }, () => {
    //                 this.props.toggle(false);
    //             })
    //         }
    //     })
    // }

    callSaveStatus = () => {
        let obj = {};
        obj.requestid = this.props.id;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.statusid = Config.getStatusInProgressId();
        obj.reasonid = Config.getReasonForStudyProvidedId();
        obj.comment = '';
        let payload = saveStatusPayload(obj);
       
        let endPoint = 'SaveStatus'
        let url = Config.getUrl(endPoint);
        if(this.props.title==="CloseRequest")
        {
            payload.closerequest=1;
        }
        else{
            payload.closerequest=0;
        }
        
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: false
            }, () => {
                if (res.Status) {
                    this.props.toggle(true);
                } else {
                    this.props.toggle(false);
                }
            })
        })
    }

    updateComment = () => {
        let obj = {}
        obj.id = this.props.id;;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.notes = this.state.comment;
        let payload = saveCommentPayload(obj);
        let endPoint = 'SaveComment'
        let url = Config.getUrl(endPoint);
       
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: false
            }, () => {
                if (res.Status) {
                    // TODO
                    this.props.toggle(true);
                } else {
                    // TODO
                    this.props.toggle(false);
                }
            })
        })
    }

    uploadAttachmentForAddStudy = () => {
        debugger;
        for(let i=0;i<this.state.Files.length;i++)
        {
        let attachment =this.state.Files[i] ;
       // let attachment = Config.getBase64ForInformatinRequestForReply();
        if (attachment !== '') {
            let objAttachment = {};
            objAttachment.requestid = this.props.id;
            objAttachment.requesttype  = this.props.requesttypeid;
            
            if(objAttachment.requesttype==10953)
        {
            objAttachment.filetype = 10959;
        }
        else
    {
        objAttachment.filetype = this.state.fileType;
    }
    debugger;
            objAttachment.filename = attachment.filename;
            
            let tempBase64 = attachment.filecontent;
            objAttachment.fileContent = tempBase64.split(',')[1];
            let payloadForAttachment = uploadDocument(objAttachment);
            debugger;
            let endPointForAttachment = 'UploadAttachment'
            let urlForAttachment = Config.getUrl(endPointForAttachment);
            
            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(urlForAttachment, payloadForAttachment, resForAttachment => {
                   if(i===this.state.Files.length-1)
                   {
                    if (resForAttachment.Status) {
                        if( objAttachment.requesttype  !=4)
                        {
                        this.callSaveStatus();
                        }
                        else
                        {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.props.toggle(true);
                            })
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.toggle(false);
                        })
                    }
                   }
                })
            })
        } else {
            // TODO
        }
    }
    }


    
  

    callAddReply = () => {
        
        let obj = {};
        obj.requestid = this.props.id;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.study = this.state.comment;
        obj.CheckedComments = '';
       
        var iterator1 =  this.state.commentsArray[Symbol.iterator]();
        for (let item of iterator1) {
            if(item.isPublished)
            {
            if(obj.CheckedComments!='')
            {
                obj.CheckedComments=obj.CheckedComments+','+item.id;
            }
            else
{
    obj.CheckedComments=item.id;
}
            }
        }

       


        // call save status update api
        let payload = 
        updateStudyorFeedbackPayload(obj);
        if(this.props.title==="CloseRequest")
        {
            payload.closerequest=1;
        }
        else{
            payload.closerequest=0;
        }
       
        
        let endPoint = "UpdateStudyorFeedback";
        let url = Config.getUrl(endPoint);
        
        let attachment = this.state.Files;
        //alert(attachment);
        
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                
               
                if (res.Status) {
                    //this.props.toggle(true);
                     if (attachment && attachment.length>0 ) {
                         this.uploadAttachmentForAddStudy();
                     } else {
                         this.setState({
                             isLoading: false
                         }, () => {
                             this.props.toggle(true);
                         })
                     }
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.toggle(false);
                    })
                }
            });
        })
    };

    callRequestById = () => {
        /*let id = this.props.id;
        let requesttype = this.props.requesttypeid;*/
        let obj = {};
        obj.requestid = this.props.id;
        obj.requesttype  = this.props.requesttypeid;
        let payload = getAllCommentsForRequest(obj);

       let endPoint = 'GetallComment';
        let url = Config.getUrl(endPoint);
      
            ApiRequest.sendRequestForPost(url, payload, res => {
               
                    if (res.Status) {
                        let tempCommentsArray = [];
                        let commentDataFromRes = res.data;
                        if (commentDataFromRes && commentDataFromRes.length > 0) {
                           
                            for (let index = 0; index < commentDataFromRes.length; index++) {
                                let tempCommentObj = {};
                                let comment = commentDataFromRes[index].notes;
                                let commentedby = commentDataFromRes[index].commentedby;
                                let commentedon = commentDataFromRes[index].commentedon;
                                if (comment !== "") {
                                    
                                    tempCommentObj.comment = comment
                                    tempCommentObj.commentedby = commentedby
                                    tempCommentObj.commentedon = commentedon;
                                    tempCommentObj.isPublished = commentDataFromRes[index].isPublished;
                                   
                                    tempCommentObj.id = commentDataFromRes[index].id;
                                   
                                    if(tempCommentObj.isPublished==1)
                                    {
                                        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(tempCommentObj.id,tempCommentObj.id) }));
                                    }
                                   
                                    
                                    tempCommentsArray.push(tempCommentObj);
                                }
                               // tempCommentsArray.push(tempCommentObj);
                            }
                           // alert(tempCommentsArray[0].comment);
                         
                           
                           if(this.state.commentsArray=='')
                           {
                               
                            this.setState({
                                commentsArray: tempCommentsArray
                            })
                        }
                        }
                    } else {
                        // TODOa
                    }
                })
           
       
    };

     checkunche= (prevchecked,currentitem)=>{
            //{id,name,ischecjed}
        let existingcheckes= prevchecked;
        let newchecks=[];
        //lo


        //Checked will be set at allst

        this.setState({},()=>{

        });
       
    }


    HandelcheckBox(e) {
       
        const item = e.target.name;
        const isChecked = e.target.checked;
        let tempCommentsArray = [];
        var iterator1 =  this.state.commentsArray[Symbol.iterator]();
       
        for (let citem of iterator1) {
            let tempCommentObj = {};
            if(citem.id==item)
            {
                tempCommentObj.comment = citem.comment
                                    tempCommentObj.commentedby = citem.commentedby
                                    tempCommentObj.commentedon = citem.commentedon;
                                    tempCommentObj.isPublished = isChecked;
                                   
                                    tempCommentObj.id = citem.id;
          
            }
            else
            {
                tempCommentObj.comment = citem.comment
                tempCommentObj.commentedby = citem.commentedby
                tempCommentObj.commentedon = citem.commentedon;
                tempCommentObj.isPublished = citem.isPublished;
               
                tempCommentObj.id = citem.id;

            }
            tempCommentsArray.push(tempCommentObj);

        }
      
        this.setState({
            commentsArray: tempCommentsArray
        })
       

    }

    // un hide below funcation for comment 
    populateComments = () => {
       
        //alert( this.state.commentsArray);
        if( this.state.commentsArray.length<1)
        {
            
        this.callRequestById();
        }
        //this.state.commentsArray[0]="abc";
        return (
            <div>
                <MDBRow style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    <MDBCol md="12">
                        <MDBTable striped responsive hover style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                            <MDBTableHead>
                            <tr style={{ textAlign: "center" }}>
                                    
                                       
                                    <th></th>
                                <th>
                                   {i18n[this.props.isArabic ? 'ar' : 'en'].CreatedBy }
                                </th>
                                  <th>
                                  {i18n[this.props.isArabic ? 'ar' : 'en'].Comment }
                               </th>
                               
                          
                        
                    </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                    this.state.commentsArray ? this.state.commentsArray.map((item, index) => {
                                        return (<tr style={{ textAlign: "center" }}>
                                            <td>

                                            <input type="checkbox" value={item.id} checked={item.isPublished} key={item.id} name={item.id}  onChange={this.HandelcheckBox} />
                                            </td>
                                            <td>
                                                {item.commentedby}
                                            </td>
                                            <td>
                                                {item.comment}
                                            </td>
                                            
                                        </tr>)
                                    }) : ''
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
                <br />
            </div>)
        }
    


    render() {
        
        return (
            <MDBContainer
                style={{ justifyContent: this.props.isArabic ? "row-reverse" : "row" }}
            >
                <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                // action='next'
                >
                    <LoadingOverlay active={this.state.isLoading} spinner={true}>
                        <MDBCol
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            style={{ width: "100%", alignItems: "center" }}
                            
                        >
                         {this.state.iseditcomment !=0|| this.props.title === "addReply"|| this.props.title === "addStudy" ||this.props.title === "CloseRequest" ? (
                                <MDBCol
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    style={{ height: "20vh" }}
                                >
                                    <div className="form-group">
                                        <label
                                            htmlFor="Entityformalrequest"
                                            style={{
                                                display: "flex",
                                                flexDirection: this.props.isArabic
                                                    ? "row-reverse"
                                                    : "row"
                                            }}
                                        >
                                            {this.props.title === "addReply" || this.props.title === "addStudy" ||this.props.title === "CloseRequest" 
                                                ? i18n[this.props.isArabic ? "ar" : "en"].addReply
                                                : i18n[this.props.isArabic ? "ar" : "en"].addStudy}
                                            <span style={{ color: "red" }}> *</span>
                                        </label>
                                        <div className="input-group">
                                            <div
                                                style={{ width: "100%", height: "12vh" }}
                                                className="custom-file-inp"
                                            >
                                                <textarea
                                                    className="form-control rounded-0"
                                                    //   maxLength="250"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: this.props.isArabic
                                                            ? "row-reverse"
                                                            : "row",
                                                        height: "20vh",
                                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                                        borderRadius: "5px"
                                                    }}
                                                    value={this.state.comment}
                                                    onChange={event => {
                                                        this.setState({
                                                            comment: event.target.value,
                                                            statusUpdateInfoError: ""
                                                        });
                                                    }}
                                                    id="exampleFormControlTextarea2"
                                                    placeholder={
                                                        i18n[this.props.isArabic ? "ar" : "en"].add_comment
                                                    }
                                                    rows="3"
                                                    required
                                                />
                                            </div>

                                            {this.state.statusUpdateInfoError !== "" ? (
                                                <div
                                                    className="textRed"
                                                    style={{
                                                        textAlign: this.props.isArabic ? "right" : "left",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {
                                                        i18n[this.props.isArabic ? "ar" : "en"]
                                                            .thisFieldIsRequired
                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </MDBCol>
                            ) : null}

<MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
<div className="form-group">
      
<div  style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                        }}>
      {this.state.Commentfilename ?
                                              <img onClick={() => this.saveFile()} style={{
                                                  height: "auto", display: "inline-block", valign: "middle",
                                                  verticalAlign: "middle", width: "auto", maxWidth: "40px", maxHeight: "40px", marginLeft: '10px', cursor: "pointer"
                                              }} alt="" src={require('./../../assets/images/BlueDownloads.png')}
                                                  className="img-fluid" /> 
                                              : ''}
                                            
  {this.state.Commentfilename ? '   '+ this.state.Commentfilename:""}
  
  </div>

                                        
                                              

</div>   </MDBCol>
                               <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <div className="form-group">
                                    <label
                                        htmlFor="Entityformalrequest"
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                        }}
                                    >
                                        {i18n[this.props.isArabic ? "ar" : "en"].attahment}{" "}
                                         {/* <span style={{ color: "red" }}> *</span> */}
                                    </label>
                                    <div className="input-group">
                                        <div style={{ width: "100%" }} className="custom-file-inp">
                                            <input
                                                id="file"
                                                type="file"
                                                multiple
                                                name="officialLetter"
                                                //accept="application/pdf"
                                                onChange={event => this.officialLetterOnChange(event)}
                                            />
                                            <label
                                                htmlFor="file"
                                                className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: this.props.isArabic
                                                        ? "row"
                                                        : "row-reverse"
                                                }}
                                            >
                                                {i18n[this.props.isArabic ? "ar" : "en"].chooseFile}
                                            </label>
                                            {/* <label
                        htmlFor="file"
                        className="fileName"
                        style={{
                          display: "flex",
                          flexDirection: this.props.isArabic
                            ? "row"
                            : "row-reverse",
                          width: "100%"
                        }}
                      >
                        {this.state.fileName}
                      </label> */}
                                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="file" className="fileName">
                                                <span>  {this.state.Files.length>0
                              ?this.state.Files.map((item, index) => {
                                return (
                                    "( "+item.filename+" ) "
                                )
                              }
                              )
                              :''
                              }</span>
                                            </label>
                                        </div>

                                        {
                                            this.state.attachedTwoErr === 'invalidFileType' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                    {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            this.state.attachedTwoErr === 'thisFieldIsRequired' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                    {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                                                </div>
                                                :
                                                null
                                        }

                                        <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", width: '100%' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormats_pdf_doc_xlxs}
                                        </p>
                                    </div>
                                </div>
                            </MDBCol>



                            {
                            
                            Config.getLocalStorageLoginData().RoleID==18
                            ||Config.getLocalStorageLoginData().RoleID==28
                            ||Config.getLocalStorageLoginData().RoleID==29
                            ||Config.getLocalStorageLoginData().RoleID==33
                            ||Config.getLocalStorageLoginData().RoleID==53
                            ||Config.getLocalStorageLoginData().RoleID==65
                            ||Config.getLocalStorageLoginData().RoleID==64 
                            ||Config.getLocalStorageLoginData().RoleID==67
                            &&
                             this.props.requesttypeid == Config.getSCILEgalSerbviceId  || this.state.userid == 66 && this.props.requesttypeid == Config.getSCILEgalSerbviceId 
                            ?
                             <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <div className="form-group">
                                     <label
                                       htmlFor="Entityformalrequest"
                                        style={{
                                             display: "flex",
                                             flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                         }}
                                    >
                                         {i18n[this.props.isArabic ? "ar" : "en"].SelectComment}{" "}
                                        
                                    </label>
                                 </div>
                                <div>
                                     <div style={{ width: "100%",overflowY: 'auto' }}>
                                        {this.populateComments()}
                                    </div>
                                </div>
                                 </MDBCol>:''
                       
                    } 
                    </MDBCol>

                        <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }}>
                            <MDBBtn
                                type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {" "}
                                {i18n[this.props.isArabic ? "ar" : "en"].postComment}{" "}
                            </MDBBtn>

                            <MDBBtn
                                onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}>
                                {" "}
                                {i18n[this.props.isArabic ? "ar" : "en"].cancle}{" "}
                            </MDBBtn>
                        </MDBRow>

                        {/* <MDBRow
              style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }}>
              <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].SCI.submit}
              </MDBBtn>
              <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
            </MDBRow> */}
                    </LoadingOverlay>
                </form>
            </MDBContainer>
        );
    }
}
