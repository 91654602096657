import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import StepsComponent from './../StepsComponent';
import i18n from './../../i18n';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Config from './../../utils/Config';
import CKEditor from "react-ckeditor-component";
import Validator from './../../utils/Validator';
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');

export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: '',
            statusid: '',
            steps: this.props.steps,
            dateBeforFiveYear: '',
            dateAfterOneYear: '',
            dateOfSalaryTerminationErr: "",
            dateOfDirectWorkErr: "",
            officialLetdateOfSalaryTerminationterFileObj: null,
            officialLetterErr: "",
            officialLetter: this.props.sendData.officialLetter,
            empJob: this.props.sendData.empJob,
            headlinesOfRequest: this.props.sendData.headlinesOfRequest,
            employeeName: this.props.sendData.employeeName,
            grade: this.props.sendData.grade,
            basicSalary: this.props.sendData.basicSalary,
            unifiedNumber: this.props.sendData.unifiedNumber,
            totalSalary: this.props.sendData.totalSalary,
            retirementContributions: this.props.sendData.retirementContributions,
            durationOfAccompanyingLeave: this.props.sendData.durationOfAccompanyingLeave,
            dateOfSalaryTermination: this.props.sendData.dateOfSalaryTermination,
            dateOfDirectWork: this.props.sendData.dateOfDirectWork,
            requiredToPayBudget: this.props.sendData.requiredToPayBudget,
            selectedOption2: this.props.sendData.selectedOption2,
            selectedOption2Err: '',
            selectedOptionErr: '',
            budgetYear: this.props.sendData.budgetYear,
            budgetAvailabilityYear: this.props.sendData.budgetAvailabilityYear,
            budgetValue: this.props.sendData.budgetValue,
            annualBalanceOfVacations: this.props.sendData.annualBalanceOfVacations,
            justificationsEmployee: this.props.sendData.justificationsEmployee,
            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            budgetYearErr: '',
            budgetAvailabilityYearErr: '',
            SalaryCointiunousOption:'',
            justificationsEmployeeErr: '',
            emiratesIDStepOne: this.props.sendData.emiratesIDStepOne,
            emiratesIDStepOneErr: '',
            exemptionSelectedValue: this.props.sendData.exemptionSelectedValue,
            requestType: this.props.sendData.requestType,
            Resonforfirstandsecond:'',
            requestTypeArray: [
                {
                    id: "1",
                    EnglishTitle: "Exception from relative degree",
                    ArabicTitle: "الإستثناء من درجه القرابه"
                },
                {
                    id: "2",
                    EnglishTitle: "Exception from allowed legal interval for patient accompany",
                    ArabicTitle: "الإستثناء من المده القانونية المسموح بها لإجازه مرافق مريض"
                }
            ],
            DegreeofrelationshipArray:[
                {
                    id:"1",
                    EnglishTitle:"First Degree",
                    ArabicTitle:"الدرجة الأولى",
            },
            {
                id:"2",
                EnglishTitle:"In-laws first class",
                ArabicTitle:"الأصهار من الدرجة الأولى",
        },
        {
            id:"3",
            EnglishTitle:"Second degree",
            ArabicTitle:"الدرجة الثانية",
    },
    {
        id:"4",
        EnglishTitle:"Third degree",
        ArabicTitle:"الدرجة الثالثة",
},
{
    id:"5",
    EnglishTitle:"Fourth degree",
    ArabicTitle:"الدرجة الرابعة",
},
            ],

            totalSalaryErr: "",
            basicSalErr: "",
            retirmentContErr: "",
            budgetValueErr: "",
            requestTypeLabel: this.props.sendData.requestTypeLabel,
            Degreeofrelationshiplabel: this.props.sendData.Degreeofrelationshiplabel,
            Degreeofrelationship:this.props.sendData.Degreeofrelationship,
            DegreeofrelationshipErr:"",
        }
        this.handleDateOfSalaryTermination = this.handleDateOfSalaryTermination.bind(this);
        this.handleDateOfDirectWork = this.handleDateOfDirectWork.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
debugger;
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let day = currentDate.getDate();
        let dateBeforFiveYear = new Date(year - 5, month, day);
        let dateAfterOneYear = new Date(year + 1, month, day);
        this.setState({
            dateBeforFiveYear: dateBeforFiveYear,
            dateAfterOneYear: dateAfterOneYear
        })

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            let tempDateOne = '', tempDateTwo = '';
            if (editObj.dateofsalarytermination) {
                tempDateOne = new Date(editObj.dateofsalarytermination);
            } else {
                tempDateOne = null;
            }

         

            if (editObj.dateofwork) {
                tempDateTwo = new Date(editObj.dateofwork);
            } else {
                tempDateTwo = null;
            }

            //For Request Type
            let requestTypeLabel = '';
            if (this.state.requestTypeArray && this.state.requestTypeArray.length > 0) {
                for (let index = 0; index < this.state.requestTypeArray.length; index++) {
                    if (parseInt(editObj.requesttype) === parseInt(this.state.requestTypeArray[index].id)) {
                        requestTypeLabel = this.props.isArabic ?
                            this.state.requestTypeArray[index].ArabicTitle :
                            this.state.requestTypeArray[index].EnglishTitle;
                    }
                }
            }
let  Degreeofrelationshiplabel='';
            if(this.state.DegreeofrelationshipArray && this.state.DegreeofrelationshipArray.length>0)
            {
                for(let index=0;index<this.state.DegreeofrelationshipArray.length;index++)
                {
                    if(parseInt(editObj.Degreeofrelationship)=== parseInt(this.state.DegreeofrelationshipArray[index].id))
                    {
                        Degreeofrelationshiplabel= this.props.isArabic ?
                        this.state.DegreeofrelationshipArray[index].ArabicTitle :
                        this.state.DegreeofrelationshipArray[index].EnglishTitle;
                    }
                }
            }
           
            let tempsalariedpaid='Yes';
            if(tempDateOne!=null)
            {
                tempsalariedpaid='No';
            }
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                empJob: editObj.jobtitle,
                employeeName: editObj.employeename,
                headlinesOfRequest: editObj.requestheadline,
                grade: editObj.grade,
                basicSalary: editObj.basicsalary,
                unifiedNumber: editObj.unifiednumber,
                totalSalary: editObj.totalsalary,
                retirementContributions: editObj.retirementcontribution,
                durationOfAccompanyingLeave: editObj.durationofleave,
                
                dateOfSalaryTermination: tempDateOne,
                dateOfDirectWork: tempDateTwo,
                requiredToPayBudget: editObj.isrequriedtopaybybudget === 1 ? "Yes" : "No",
                selectedOption2: editObj.isrequriedtopaybybudget === 0 ? "Yes" : "No",
                budgetYear: editObj.budgetyear,
                budgetAvailabilityYear: editObj.budgetavailableyear,
                budgetValue: editObj.budgetvalue,
                annualBalanceOfVacations: editObj.annualleavebalance,
                justificationsEmployee: editObj.justification,
                emiratesIDStepOne: editObj.emiratesid,
                //exemptionSelectedValue: editObj.exemptionSelectedValue,
                requestTypeLabel: requestTypeLabel,
                Degreeofrelationshiplabel:Degreeofrelationshiplabel,
                requestType: editObj.requesttype,
                Degreeofrelationship:editObj.Degreeofrelationship,
                SalaryCointiunousOption:tempsalariedpaid
            });
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    };

    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    submitHandler = (event) => {
debugger;
        event.preventDefault();
        event.target.className += " was-validated";

        let dateOfSalaryTerminationErr = '', dateOfDirectWorkErr = '', budgetYearErr = '', budgetAvailabilityYearErr = '', selectedOption2Err = '', selectedOptionErr = '', justificationsEmployeeErr = '', salaryErr = '', emiratesIDStepOneErr = '',
            totalSalaryErr = "", basicSalErr = "", retirmentContErr = "", budgetValueErr = "",DegreeofrelationshipErr="";

      /*  if (this.state.dateOfSalaryTermination === null) {
            dateOfSalaryTerminationErr = 'thisFieldIsRequired';
        } else {
            if (this.state.dateOfDirectWork === null) {
                dateOfDirectWorkErr = 'thisFieldIsRequired';
            }
        }*/


        if (!this.state.totalSalary) {
            totalSalaryErr = 'thisFieldIsRequired';
        }

        if (!this.state.basicSalary) {
            basicSalErr = 'thisFieldIsRequired';
        }

        if (!this.state.retirementContributions) {
            retirmentContErr = 'thisFieldIsRequired';
        }

        // if (this.state.budgetValue === '') {
        //     budgetValueErr = 'thisFieldIsRequired';
        // }

        if (!this.state.selectedOption2) {
            selectedOption2Err = 'thisFieldIsRequired';
        }
        // else if (this.state.selectedOption2 === 'No') {
        //     selectedOption2Err = 'budgetAvailabilityError';
        // }
        if(!this.state.Degreeofrelationship && this.state.Degreeofrelationship!='')
        {
            DegreeofrelationshipErr='thisFieldIsRequired';
        }

        if (this.state.selectedOption2 === 'Yes') {
            if (this.state.budgetAvailabilityYear) {
                if (!Validator.validateYear(this.state.budgetAvailabilityYear)) {
                    budgetAvailabilityYearErr = 'invalidYear';
                }
            } else {
                budgetAvailabilityYearErr = 'thisFieldIsRequired';
            }
        } else {
            if (!this.state.requiredToPayBudget) {
                selectedOptionErr = 'thisFieldIsRequired';
            } else {
                if (this.state.requiredToPayBudget === 'Yes') {
                    if (this.state.budgetYear) {
                        if (!Validator.validateYear(this.state.budgetYear)) {
                            budgetYearErr = 'invalidYear';
                        }
                    } else {
                        budgetYearErr = 'thisFieldIsRequired';
                    }

                    if (!this.state.budgetValue) {
                        budgetValueErr = 'thisFieldIsRequired';
                    }
                }
            }
        }

        // if (this.state.selectedOption2 === 'No') {
        //     if (this.state.budgetYear !== '') {
        //         if (!Validator.validateYear(this.state.budgetYear)) {
        //             budgetYearErr = 'invalidYear';
        //         }
        //     } else {
        //         budgetYearErr = 'thisFieldIsRequired';
        //     }
        // }

        if (this.state.justificationsEmployee && this.state.justificationsEmployee.trim()) {
            let val = striptags(this.state.justificationsEmployee.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                justificationsEmployeeErr = 'thisFieldIsRequired';
            }
        } else {
            justificationsEmployeeErr = 'thisFieldIsRequired';
        }

        if (this.state.basicSalary && this.state.totalSalary) {
            let basicSalAfterReplace = this.state.basicSalary.replace(/,/g, "");
            let totalSalAfterReplace = this.state.totalSalary.replace(/,/g, "");
            if (parseFloat(basicSalAfterReplace) > parseFloat(totalSalAfterReplace)) {
                salaryErr = 'salaryErr';
            }
        }

        if (this.state.retirementContributions && this.state.totalSalary) {
            let retirementContributionsAfterReplace = this.state.retirementContributions.replace(/,/g, "");
            let totalSalAfterReplace = this.state.totalSalary.replace(/,/g, "");
            if (parseFloat(retirementContributionsAfterReplace) > parseFloat(totalSalAfterReplace)) {
                retirmentContErr = 'retirmentContErr';
            }
        }

        if (this.state.emiratesIDStepOne) {
            //if (!Validator.validOrInvalidEmiratesId(this.state.emiratesIDStepOne)) {
            if (!Validator.validateEmiratesId(this.state.emiratesIDStepOne)) {
                emiratesIDStepOneErr = "invalidEmiratesId";
            }
        }
        else {
            emiratesIDStepOneErr = "thisFieldisRequired"
        }

        this.setState({
            dateOfSalaryTerminationErr: dateOfSalaryTerminationErr,
            dateOfDirectWorkErr: dateOfDirectWorkErr,
            budgetYearErr: budgetYearErr,
            budgetAvailabilityYearErr: budgetAvailabilityYearErr,
            selectedOption2Err: selectedOption2Err,
            selectedOptionErr: selectedOptionErr,
            justificationsEmployeeErr: justificationsEmployeeErr,
            salaryErr: salaryErr,
            emiratesIDStepOneErr: emiratesIDStepOneErr,
            totalSalaryErr: totalSalaryErr,
            basicSalErr: basicSalErr,
            retirmentContErr: retirmentContErr,
            budgetValueErr: budgetValueErr,
            DegreeofrelationshipErr
        }, () => {
            

            if (!dateOfSalaryTerminationErr && !dateOfDirectWorkErr && !budgetYearErr && !budgetAvailabilityYearErr && !selectedOption2Err && !selectedOptionErr && !justificationsEmployeeErr && !salaryErr && !emiratesIDStepOneErr && !totalSalaryErr && !basicSalErr && !retirmentContErr && !budgetValueErr) {
                

                //If Budget Availability is Yes, then Enter the Budget's year compulsary
                if (this.state.selectedOption2 === 'Yes') {
                    if (this.state.requestType &&
                        this.state.empJob &&
                        this.state.headlinesOfRequest &&
                        this.state.employeeName &&
                        this.state.grade &&
                        this.state.basicSalary &&
                        this.state.unifiedNumber &&
                        this.state.totalSalary &&
                        this.state.retirementContributions &&
                        this.state.durationOfAccompanyingLeave &&
                       // this.state.dateOfSalaryTermination &&
                        this.state.selectedOption2 &&
                        striptags(this.state.justificationsEmployee) &&
                        this.state.emiratesIDStepOne && this.state.Degreeofrelationship
                    ) {
                        this.setState({
                            isNextClick: true,
                            isfirstPageValidate: true
                        }, () => {
                            debugger;
                            this.props.getData.stepOneData({
                                requestType: this.state.requestType,
                                empJob: this.state.empJob,
                                headlinesOfRequest: this.state.headlinesOfRequest,
                                employeeName: this.state.employeeName,
                                grade: this.state.grade,
                                basicSalary: this.state.basicSalary,
                                unifiedNumber: this.state.unifiedNumber,
                                totalSalary: this.state.totalSalary,
                                retirementContributions: this.state.retirementContributions,
                                durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                dateOfDirectWork: this.state.dateOfDirectWork,
                                requiredToPayBudget: this.state.requiredToPayBudget,
                                selectedOption2: this.state.selectedOption2,
                                budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                budgetYear: this.state.budgetYear,
                                budgetValue: this.state.budgetValue,
                                // budgetYear: '',
                                // budgetValue: '',
                                annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                justificationsEmployee: this.state.justificationsEmployee,
                                emiratesIDStepOne: this.state.emiratesIDStepOne,
                                exemptionSelectedValue: this.state.exemptionSelectedValue,
                                requestTypeLabel: this.state.requestTypeLabel
                                ,Degreeofrelationship:this.state.Degreeofrelationship
                            });
                        });
                    }
                } else {
                   
                    //If Budget Availability is No & Required to pay additional budget is Yes, then Enter the Year & Budget's value compulsary 
                    if (this.state.selectedOption2 === 'No' && this.state.selectedOption === 'Yes') {
                    
                        if (this.state.requestType && this.state.empJob && this.state.headlinesOfRequest && this.state.employeeName && this.state.grade && this.state.basicSalary && this.state.unifiedNumber && this.state.totalSalary && this.state.retirementContributions && this.state.durationOfAccompanyingLeave && this.state.dateOfSalaryTermination && this.state.requiredToPayBudget && this.state.selectedOption2 && striptags(this.state.justificationsEmployee) && this.state.budgetYear && this.state.budgetValue && this.state.emiratesIDStepOne) {
                            this.setState({ isNextClick: true, isfirstPageValidate: true }, () => {
                                debugger;
                                this.props.getData.stepOneData({
                                    requestType: this.state.requestType,
                                    empJob: this.state.empJob,
                                    headlinesOfRequest: this.state.headlinesOfRequest,
                                    employeeName: this.state.employeeName,
                                    grade: this.state.grade,
                                    basicSalary: this.state.basicSalary,
                                    unifiedNumber: this.state.unifiedNumber,
                                    totalSalary: this.state.totalSalary,
                                    retirementContributions: this.state.retirementContributions,
                                    durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                    dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                    dateOfDirectWork: this.state.dateOfDirectWork,
                                    requiredToPayBudget: this.state.requiredToPayBudget,
                                    selectedOption2: this.state.selectedOption2,
                                    budgetYear: this.state.budgetYear,
                                    budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                    budgetValue: this.state.budgetValue,
                                    emiratesIDStepOne: this.state.emiratesIDStepOne,
                                    annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                    justificationsEmployee: this.state.justificationsEmployee,
                                    requestTypeLabel: this.state.requestTypeLabel
                                    ,Degreeofrelationship:this.state.Degreeofrelationship

                                });
                            });
                        }
                    } else {
                     
                        if (this.state.requestType && this.state.empJob && this.state.headlinesOfRequest && this.state.employeeName && this.state.grade && this.state.basicSalary && this.state.unifiedNumber && this.state.totalSalary && this.state.retirementContributions && this.state.durationOfAccompanyingLeave && this.state.dateOfSalaryTermination && this.state.requiredToPayBudget && this.state.selectedOption2 && this.state.justificationsEmployee) {
                            this.setState({ isNextClick: true, isfirstPageValidate: true }, () => {
                                debugger;
                                this.props.getData.stepOneData({
                                    requestType: this.state.requestType,
                                    empJob: this.state.empJob,
                                    headlinesOfRequest: this.state.headlinesOfRequest,
                                    employeeName: this.state.employeeName,
                                    grade: this.state.grade,
                                    basicSalary: this.state.basicSalary,
                                    unifiedNumber: this.state.unifiedNumber,
                                    totalSalary: this.state.totalSalary,
                                    retirementContributions: this.state.retirementContributions,
                                    durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                    dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                    dateOfDirectWork: this.state.dateOfDirectWork,
                                    requiredToPayBudget: this.state.requiredToPayBudget,
                                    selectedOption2: this.state.selectedOption2,
                                    budgetYear: this.state.budgetYear,
                                    budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                    budgetValue: this.state.budgetValue,
                                    annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                    justificationsEmployee: this.state.justificationsEmployee,
                                    emiratesIDStepOne: this.state.emiratesIDStepOne,
                                    exemptionSelectedValue: this.state.exemptionSelectedValue,
                                    requestTypeLabel: this.state.requestTypeLabel
                                    ,Degreeofrelationship:this.state.Degreeofrelationship
                                });
                            });
                        }
                    }
                }
            }
        });
    };

    clearForm = () => {
        this.setState({
            requestType: '',
            empJob: '',
            headlinesOfRequest: '',
            employeeName: '',
            grade: '',
            basicSalary: '',
            unifiedNumber: '',
            totalSalary: '',
            retirementContributions: '',
            durationOfAccompanyingLeave: '',
            dateOfSalaryTermination: '',
            dateOfDirectWork: '',
            selectedOption: '',
            selectedOption2: '',
            budgetYear: '',
            budgetAvailabilityYear: '',
            budgetValue: '',
            annualBalanceOfVacations: '',
            justificationsEmployee: '',
            emiratesIDStepOne: '',
            exemptionSelectedValue: ''
        });
    }

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangehandler = (value) => {
        this.setState({
            isNextClick: value
        });
    }

    handleEmpJob = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ empJob: event.target.value });
            }
        } else {
            this.setState({ empJob: '' });
        }
    };

    handleHeadlinesOfRequest = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ headlinesOfRequest: event.target.value });
            }
        } else {
            this.setState({ headlinesOfRequest: '' });
        }
    };

    handleEmiratesIDChange = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ emiratesIDStepOne: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ emiratesIDStepOneErr: '' });
                } else {
                    this.setState({ emiratesIDStepOneErr: 'invalidEmiratesId' });
                }
            });
        }
        else {
            this.setState({ emiratesIDStepOne: '', emiratesIDStepOneErr: 'thisFieldisRequired' });
        }
    }

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };

    handleGrade = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,15}$/;
            if (regex.test(event.target.value)) {
                this.setState({ grade: event.target.value });
            }
        } else {
            this.setState({ grade: '' });
        }
    };

    handleBasicSalary = (value) => {
        if (value) {
            this.setState({ basicSalary: value.formattedValue, basicSalErr: "" });
        }
    };

    handleUnifiedNumber = (event) => {
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ unifiedNumber: event.target.value });
            }
        } else {
            this.setState({ unifiedNumber: "" })
        }
    };

    handleTotalSalary = (value) => {
        if (value) {
            this.setState({ totalSalary: value.formattedValue, totalSalaryErr: "" });
        }
    };

    handleRetirementContrib = (value) => {
        if (value) {
            this.setState({ retirementContributions: value.formattedValue, retirmentContErr: "" });
        }
    };

    handleDurationOfAccompanyingLeave = (event) => {
        let regex = /^[0-9\b]{0,15}$/;
        if (regex.test(event.target.value)) {
            if (event.target.value < 366) {
                this.setState({ durationOfAccompanyingLeave: event.target.value });
            }
        }
    };

    handleDateOfSalaryTermination(date) {
        if (date) {
            var d = new Date(date);
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var tempDate = new Date(year, month, day + 1);

            this.setState({
                dateOfSalaryTermination: date,
                dateOfSalaryTerminationErr: '',
                //dateOfDirectWork: tempDate,
                dateOfDirectWorkErr: ''
            });
        }
    }

    handleDateOfDirectWork(date) {
        this.setState({
            dateOfDirectWork: date,
            dateOfDirectWorkErr: ''
        });
    }

    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    findRoutes()
    {
        
    }
    handleOptionChange2 = (e) => {
        
     
        this.setState({
            selectedOption2: e.target.value,
            selectedOption2Err: ''
        });
  
        if(this.state.selectedOption2=="Yes")
        {
            this.setState({
                budgetYear: '',
                budgetValue: '',
                requiredToPayBudget:"No"
            }, () => {
            })
       
        }
        else if (this.state.selectedOption2=="No"){
           
            this.setState({
                budgetAvailabilityYear:''}, () => {
                })
        }
    };

    handleBudgetYear = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ budgetYear: event.target.value, budgetYearErr: '' });
            }
            this.setState({ budgetYearErr: '' });
        } else {
            this.setState({ budgetYear: '', budgetYearErr: 'thisFieldIsRequired' });
        }
    };

    validateBudgetYear = (budgetYear) => {
        let budgetYearLen = budgetYear.length;
        if (budgetYearLen === 4) {
            return true;
        } else {
            return false;
        }
    }

    handleBudgetAvailabilityYear = (event) => {
        let regex = /^[0-9\b]{0,4}$/;
        if (regex.test(event.target.value)) {
            this.setState({ budgetAvailabilityYear: event.target.value, budgetAvailabilityYearErr: '' });
        }
    };

    handleBudgetValue = (value) => {
        if (value) {
            
            this.setState({ budgetValue: value.formattedValue, budgetValueErr: "" });
        }
    };

    handleAnnualBalanceOfVacations = (event) => {
        let regex = /^[0-9]{0,2}$/;
        if (event.target.value) {
            if (event.target.value < 91) {
                if (regex.test(event.target.value)) {
                    this.setState({ annualBalanceOfVacations: event.target.value });
                }
            }
        } else {
            this.setState({ annualBalanceOfVacations: '' });
        }
    };

    handleYear = (event) => {
        let regex = /^[0-9\b]{0,4}$/;
        if (regex.test(event.target.value)) {
            this.setState({ year: event.target.value });
        }
    };

    handleSalaryStatus = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z ]*$/;
        if (regex.test(event.target.value)) {
            this.setState({ salaryStatus: event.target.value });
        }
    };

    handleTotalSalaryDollar = (event) => {
        let regex = /^[0-9\b.]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalSalaryDollar: event.target.value });
        }
    };

    handleNoOfDays = (event) => {
        let regex = /^[0-9\b]{0,50}$/;
        if (regex.test(event.target.value)) {
            this.setState({ noOfDays: event.target.value });
        }
    };

    handleTotalDays = (event) => {
        let regex = /^[0-9\b]{0,50}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalDays: event.target.value });
        }
    };

    handleTotalSalaryPayed = (event) => {
        let regex = /^[0-9\b.]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalSalaryPayed: event.target.value });
        }
    };

    justificationsEmployeeOnChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                justificationsEmployeeErr: "",
                justificationsEmployee: newContent
            })
        }
        else {
            this.setState({
                justificationsEmployeeErr: "ckeditorCountErr"
            })
        }

    };

    handleChangeForExemptionType = (event) => {
        this.setState({ exemptionSelectedValue: event.target.value });
    };

    handleChangeForRequestType = (event) => {
        let dropDownLabel = '';
        if (this.state.requestTypeArray && this.state.requestTypeArray.length > 0) {
            for (let index = 0; index < this.state.requestTypeArray.length; index++) {
                if (parseInt(event.target.value) === parseInt(this.state.requestTypeArray[index].id)) {
                    dropDownLabel = this.props.isArabic ?
                        this.state.requestTypeArray[index].ArabicTitle :
                        this.state.requestTypeArray[index].EnglishTitle;
                }
            }
        }this.setState({
            requestType: event.target.value,
            requestTypeLabel: dropDownLabel
        }, () => {
          
        });

    };

        handleChangeForDegreeofrelationship = (event) => {
            let dropDownLabel = '';
            if (this.state.DegreeofrelationshipArray && this.state.DegreeofrelationshipArray.length > 0) {
                for (let index = 0; index < this.state.DegreeofrelationshipArray.length; index++) {
                    if (parseInt(event.target.value) === parseInt(this.state.DegreeofrelationshipArray[index].id)) {
                        dropDownLabel = this.props.isArabic ?
                            this.state.DegreeofrelationshipArray[index].ArabicTitle :
                            this.state.rDegreeofrelationshipArray[index].EnglishTitle;
                    }
                }
            }

        this.setState({
            Degreeofrelationship: event.target.value,
            DegreeofrelationshipLabel: dropDownLabel
        }, () => {
         
        });

    };

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    onDatepickerRef1(el) {
        if (el && el.input) {
            // if (this.state.dateOfSalaryTermination === null) {
            //     el.input.disabled = true;
            //     el.input.readOnly = false;
            // } else {
            //     el.input.disabled = false;
            //     el.input.readOnly = true;
            //     el.input.classList.add('white_bg');
            // }

            if (el.input.type === "blur") {
                el.input.target.style.backgroundColor = "white";
            }
        }
    }

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            requestType: this.state.requestType,
            empJob: this.state.empJob,
            headlinesOfRequest: this.state.headlinesOfRequest,
            employeeName: this.state.employeeName,
            grade: this.state.grade,
            basicSalary: this.state.basicSalary,
            unifiedNumber: this.state.unifiedNumber,
            totalSalary: this.state.totalSalary,
            retirementContributions: this.state.retirementContributions,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            dateOfSalaryTermination: this.state.dateOfSalaryTermination,
            dateOfDirectWork: this.state.dateOfDirectWork,
            requiredToPayBudget: this.state.requiredToPayBudget,
            selectedOption2: this.state.selectedOption2,
            budgetAvailabilityYear: this.state.budgetAvailabilityYear,
            budgetYear: this.state.budgetYear,
            budgetValue: this.state.budgetValue,
            annualBalanceOfVacations: this.state.annualBalanceOfVacations,
            justificationsEmployee: this.state.justificationsEmployee,
            emiratesIDStepOne: this.state.emiratesIDStepOne,
            exemptionSelectedValue: this.state.exemptionSelectedValue
            ,Degreeofrelationship:this.state.Degreeofrelationship
        });
    };

    render() {

        return (

            <form
                style={{ paddingTop: '20', paddingBottom: '200px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={['Step1', 'Step2', 'Step3']}
                    currentIndex={1}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].requestType}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                id="sources-funding"
                                onChange={this.handleChangeForRequestType}
                                value={this.state.requestType}
                                required>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.requestTypeArray ? this.state.requestTypeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
            
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].ExemptionFromAccompanyPatient.Degreeofrelationship}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                               
                            </label>
                           
                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                id="Degreeofrelationship"
                                onChange={this.handleChangeForDegreeofrelationship}
                                value={this.state.Degreeofrelationship}
                                required>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.DegreeofrelationshipArray ? this.state.DegreeofrelationshipArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.headlinesOfRequest}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.headlinesOfRequest}
                                onChange={this.handleHeadlinesOfRequest}
                                type="text"
                                id="headlinesOfRequest"
                                className="form-control"
                                name="empJob"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.employeeName}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.employeeName}
                                onChange={this.handleEmployeeName}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].emiratesID}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <InputMask
                                mask="999-9999-9999999-9"
                                maskChar={null}
                                value={this.state.emiratesIDStepOne}
                                onChange={this.handleEmiratesIDChange}
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr" }}
                                className="form-control"
                                required
                            />
                            {
                                this.state.emiratesIDStepOneErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emiratesIDStepOneErr === 'invalidEmiratesId' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmiratesID}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.empJob}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.empJob}
                                onChange={this.handleEmpJob}
                                type="text"
                                id="defaultFormRegisterPasswordEx4"
                                className="form-control"
                                name="empJob"
                                required
                                maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.basicSalary}<span className="requireStar">*</span></label>
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.basicSalErr !== '' || this.state.salaryErr ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.basicSalary}
                                    onValueChange={(value) => { this.handleBasicSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                            {
                                this.state.basicSalErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            {
                                this.state.salaryErr === 'salaryErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].salaryErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalSalary}<span className="requireStar">*</span></label>
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.totalSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.totalSalary}
                                    onValueChange={(value) => { this.handleTotalSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                            {/* <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div> */}
                            {this.state.totalSalaryErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

               </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
               

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.retirementContribution}<span className="requireStar">*</span></label>                           
                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.retirmentContErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.retirementContributions}
                                    onValueChange={(value) => { this.handleRetirementContrib(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                            {
                                this.state.retirmentContErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.retirmentContErr === 'retirmentContErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].RetirementContributionsErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.grade}<span className="requireStar">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="grade"
                                name="grade"
                                onChange={this.handleGrade}
                                value={this.state.grade}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.unifiedNumber}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="unifiedNumber"
                                name="unifiedNumber"
                                onChange={this.handleUnifiedNumber}
                                value={this.state.unifiedNumber}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.annualBalanceOfVacations}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="annualBalanceOfVacations"
                                name="annualBalanceOfVacations"
                                value={this.state.annualBalanceOfVacations}
                                onChange={this.handleAnnualBalanceOfVacations}
                                style={{
                                    direction: this.props.isArabic ? "rtl" : 'ltr'
                                }}
                            />
                        </div>
                    </MDBCol>
                
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.durationOfAccompanyingLeave}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="durationOfAccompanyingLeave"
                                name="durationOfAccompanyingLeave"
                                onChange={this.handleDurationOfAccompanyingLeave}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.durationOfAccompanyingLeave}
                                required
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>


                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.dateOfDirectWork}
                            {/* <span className="requireStar">MGM</span> */}
                            </label>
                            <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} >
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    selected={this.state.dateOfDirectWork}
                                    minDate={this.state.dateOfDirectWork}
                                    onChange={this.handleDateOfDirectWork}
                                    ref={el => this.onDatepickerRef1(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    //required
                                   
                                />
                            </div>
                            {/* {this.state.dateOfDirectWorkErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null} */}
                        </div>
                    </MDBCol>


                 </MDBRow>
{/* MGM */}
                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystoped}<span className="requireStar">*</span></label>
                                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={this.state.SalaryCointiunousOption === 'Yes'}
                                            class="custom-control-input"
                                            id="customControlValidation21"
                                            onChange={this.handleSalayOptionChange}
                                            name="radio-stackedsalary"
                                            required />
                                        <label class="custom-control-label" for="customControlValidation21">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystopedgoing}
                                        </label>
                                    </div>
                                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                        <input
                                            type="radio"
                                            value="No"
                                            class="custom-control-input"
                                            id="customControlValidation31"
                                            name="radio-stackedsalary"
                                            checked={this.state.SalaryCointiunousOption === 'No'}
                                            onChange={this.handleSalayOptionChange}
                                            required />
                                        <label class="custom-control-label" for="customControlValidation31">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystopedstoped}
                                        </label>
                                    </div><br />
                                    {this.state.selectedOptionErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null}
                                </div>
                            </MDBCol>
                            {this.state.SalaryCointiunousOption==='No' ? 
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.dateOfSalaryTermination}
                            {/* <span className="requireStar">*</span> */}
                            </label>
                            <div
                                // style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }} 
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.dateOfSalaryTerminationErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    minDate={this.state.dateBeforFiveYear}
                                    maxDate={this.state.dateAfterOneYear}
                                    selected={this.state.dateOfSalaryTermination}
                                    onChange={this.handleDateOfSalaryTermination}
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                   // required
                                />
                            </div>
                            {/* {this.state.dateOfSalaryTerminationErr !== '' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null} */}
                        </div>
                    </MDBCol>:''}
                
                             </MDBRow>

          

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetAvailability}<span className="requireStar">*</span></label>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOption2 === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidation4"
                                    onChange={this.handleOptionChange2}
                                    name="radio-stacked-2"
                                    required />
                                <label class="custom-control-label" for="customControlValidation4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.selectedOption2 === 'No'}
                                    onChange={this.handleOptionChange2}
                                    required />
                                <label class="custom-control-label" for="customControlValidation5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>
                            </div>
                            <br />
                            {
                                this.state.selectedOption2Err == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {/* {
                                this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].budgetAvailabilityError}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>
                    {
                        this.state.selectedOption2 === "Yes" ?
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetYear}
                                        <span className="requireStar">*</span>
                                    </label>
                                   
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="budgetAvailabilityYear"
                                        name="budgetAvailabilityYear"
                                        value={this.state.budgetAvailabilityYear}
                                        onChange={this.handleBudgetAvailabilityYear}
                                        style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        required
                                    />

                                    {
                                        this.state.budgetAvailabilityYearErr === 'thisFieldIsRequired' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        this.state.budgetAvailabilityYearErr === 'invalidYear' ?
                                            <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                {i18n[this.getLocale()].invalidYear}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </MDBCol>
                            :
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ padding: '30px' }}>
                            </MDBCol>
                    }

                </MDBRow>
                {
                    this.state.selectedOption2 === "No" ?

                        <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.requiredToPayBudget}<span className="requireStar">*</span></label>
                                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={this.state.requiredToPayBudget === 'Yes'}
                                            class="custom-control-input"
                                            id="customControlValidation2"
                                            onChange={this.handleOptionChange}
                                            name="radio-stacked"
                                            required />
                                        <label class="custom-control-label" for="customControlValidation2">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                        </label>
                                    </div>
                                    <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                        <input
                                            type="radio"
                                            value="No"
                                            class="custom-control-input"
                                            id="customControlValidation3"
                                            name="radio-stacked"
                                            checked={this.state.requiredToPayBudget === 'No'}
                                            onChange={this.handleOptionChange}
                                            required />
                                        <label class="custom-control-label" for="customControlValidation3">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                        </label>
                                    </div><br />
                                    {this.state.selectedOptionErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null}
                                </div>
                            </MDBCol>

                        </MDBRow>
                        :
                        ''
                }

                <br />
                {
                    (this.state.requiredToPayBudget === "Yes" && this.state.selectedOption2 !== "Yes") ?
                        // (this.state.selectedOption2 === "Yes") ?

                        <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SCI.enterTheYear}
                                        <span className="requireStar">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="budgetYear"
                                        name="budgetYear"
                                        value={this.state.budgetYear}
                                        onChange={this.handleBudgetYear}
                                        style={{
                                            direction: this.props.isArabic ? "rtl" : 'ltr',
                                            border: this.state.budgetYearErr !== '' ? '1px solid #dc3545' : '',
                                            backgroundImage: this.state.budgetYearErr !== '' ? 'none' : '',
                                        }}
                                    // required
                                    />

                                    {
                                        this.state.budgetYearErr === 'thisFieldIsRequired' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.budgetYearErr == 'invalidYear' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].invalidYear}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </MDBCol>

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetValue}
                                        <span className="requireStar">*</span>
                                    </label>
                                   

                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.budgetValueErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                        <CurrencyFormat
                                            thousandSeparator={true}
                                            value={this.state.budgetValue}
                                            onValueChange={(value) => { this.handleBudgetValue(value) }}
                                            display={"text"}
                                            maxLength={10}
                                            style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        />
                                    </div>

                                    {this.state.budgetValueErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null}
                                </div>
                            </MDBCol>

                        </MDBRow>

                        :
                        null
                }

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{this.state.requestType? this.state.requestType ==2 ? i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.justificationsEmployee: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.Resonforfirstandsecond:i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.justificationsEmployee}<span className="requireStar">*</span></label>

                                <div style={{ border: this.state.justificationsEmployeeErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} className="ckEditorStyle">

                                    {
                                        this.props.isArabic ?
                                            null :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.justificationsEmployee}
                                                events={{
                                                    "change": this.justificationsEmployeeOnChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }

                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.justificationsEmployee}
                                                events={{
                                                    "change": this.justificationsEmployeeOnChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.justificationsEmployeeErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                
             


                <div className="clearfix"></div>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn className="submitBtn" type='submit' color="primary">
                        {i18n[this.props.isArabic ? 'ar' : 'en'].next_btn}
                    </MDBBtn>

                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }

                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>

            </form>

        );
    }
}