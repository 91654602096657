import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import SubHeaderStrip from '../components/SubHeaderStrip';
import Footer from '../components/Footer';
import i18n from '../i18n';
import ApiRequest from '../services/ApiRequest';
import Config from '../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import EmployeesReportsForm from '../components/Reports/EmployeesReportsForm';

export default class EmployeesReports extends Component {
    constructor() {
        super();
        // disableBrowserBackButton();
        this.state = {
            isLoading: false,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
           
            loginData: ''
        }
    }
    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }
    render() {
        const sideBarData = Config.getServiceSideBar('EmployeesReports', this.state.isArabic, this, 0);
        debugger;
        
        return (

            <MDBContainer fluid>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/landing', { pathname: '' }) }} isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].canViewEmployeeReport} />
                                </MDBCol>
                            </MDBRow>
                            <div style={{ paddingTop: "10px" }}>
                                <EmployeesReportsForm isArabic={this.state.isArabic}/>
                                </div>
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            height={'140vh'}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}