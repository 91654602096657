import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol,MDBBtn } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import Step1 from '../components/Disciplinaryboard/Step1';
import Step2 from '../components/Disciplinaryboard/Step2';
import Step3 from '../components/Disciplinaryboard/Step3';
import { saveDisciplinaryboard, uploadDocument } from './../utils/Payload';
import Upload from '../components/Common/Upload';
import StepTwo from '../components/SecondmentForAFederalAgencySteps/StepTwo';
import SubHeaderStrip from '../components/SubHeaderStrip';
import i18n from '../i18n';
import Config from '../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import Footer from '../components/Footer';

import ApiRequest from '../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
let moment = require('moment');

export default class Disciplinaryboard extends Component {
    constructor(props) {
      
        super(props);
        this.state = {
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            step1: true,
            step2: false,
            step3: false,
            
            isEdit: '',
            requestId: '',
            RequestType: '',
            requesterName:'',
            
rquesterEmail:'',
requesterphoneNumber:'',
formalrequestletter:'',
MembersArray:[],
EmployeesArray:[],

           
        }
    }
    SaveEmployeesArray = (id, tempTableData) => {
       
        debugger;
        //let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        let newTempTableData = [];
        tempTableData.forEach((item, index) => {

            let SaveWorkShopDatesObject = {};
            SaveWorkShopDatesObject.EmployeePhoneNumber=item.EmployeePhoneNumber;
            SaveWorkShopDatesObject.EmployeeJobTitle=item.EmployeeJobTitle;
            SaveWorkShopDatesObject.EmployeeJobDegree=item.EmployeeJobDegree;
            SaveWorkShopDatesObject.EmployeeName=item.employeeName;
            SaveWorkShopDatesObject.Reason=item.EmployeeReason;
            SaveWorkShopDatesObject.DisciplinaryboardID=id;
            SaveWorkShopDatesObject.EmployeeViloation=item.EmployeeViloation;
            SaveWorkShopDatesObject.EmployeeViloationFileObj=item.EmployeeViloationFileObj;
            SaveWorkShopDatesObject.Employeeinvestigation=item.Employeeinvestigation;
            SaveWorkShopDatesObject.EmployeeinvestigationFileObj=item.EmployeeinvestigationFileObj;
            SaveWorkShopDatesObject.userid=item.userid;
            SaveWorkShopDatesObject.entityid=item.entityid;
            SaveWorkShopDatesObject.isinverstagiondone=item.isinverstagiondone;
            newTempTableData.push(SaveWorkShopDatesObject);
        })
        return newTempTableData;
    }
    SaveMembersArray = (id, tempTableData) => {
       
        debugger;
        //let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        let newTempTableData = [];
        tempTableData.forEach((item, index) => {

            let SaveWorkShopDatesObject = {};
            SaveWorkShopDatesObject.MemberEntityID=item.entity;
            SaveWorkShopDatesObject.MemberJobDegree=item.jobdegree;
            SaveWorkShopDatesObject.MemberName=item.membername;
            SaveWorkShopDatesObject.DisciplinaryboardID=id;
            SaveWorkShopDatesObject.MemberBoardTitle=item.title;
            SaveWorkShopDatesObject.MemberJobTitle=item.MemberJobTitle;
            newTempTableData.push(SaveWorkShopDatesObject);
        })
        return newTempTableData;
    }
    

    submitApiCall = () => {
       

        debugger;
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                debugger;
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let resposeObj = {};
                if (this.state.formType === "saveAsDraft") {
                    resposeObj.draft = 1;
                } else {
                    resposeObj.draft = 0;
                }
                resposeObj.isEdit = this.state.isEdit;
                resposeObj.requestId = this.state.requestId;
                resposeObj.RequestType= this.state.RequestType;
                resposeObj.requesterName= this.state.requesterName;
                resposeObj.rquesterEmail= this.state.rquesterEmail;
                resposeObj.requesterphoneNumber=this.state.requesterphoneNumber;
              
              
                
                debugger;
                let payload = saveDisciplinaryboard(resposeObj);
                let endPoint = 'SaveDisciplinaryboard'
                let url = Config.getUrl(endPoint);

                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                        if (resAcompanyPatient.Status) {
                            if(this.state.MembersArray.length>0)
                            {
                            let tempTableData = this.state.MembersArray;
                  
                            let payloadForMembersArray = this.SaveMembersArray(resAcompanyPatient.data.id, tempTableData);
                            let endPoint = 'SaveDisciplinaryMembers'
                            let urlForDisciplinaryMembers = Config.getUrl(endPoint);

                            ApiRequest.sendRequestForPost(urlForDisciplinaryMembers, payloadForMembersArray, resForSalaryMatrix => {
                                if (resForSalaryMatrix.Status) {
                                    
                                    let totalFile = 1;
                                    let EmployeesArray = this.state.EmployeesArray;
                                    if( resposeObj.RequestType==2)
                                    {
                                        let payloadForEmployeesArray = this.SaveEmployeesArray(resAcompanyPatient.data.id, EmployeesArray);
                                        let EmployeesendPoint = 'SaveDisciplinaryEmployees'
                                        let urlForDisciplinaryEmployees = Config.getUrl(EmployeesendPoint);
                                        ApiRequest.sendRequestForPost(urlForDisciplinaryEmployees, payloadForEmployeesArray, resForEmployeeArrays => {
                                            if(resForEmployeeArrays.Status)
                                            {
                                                this.uploadFile(0, totalFile, 
                                                    resAcompanyPatient, 0);

                                            }
                                        })
                                    }
                                    else
                                    {

                                    this.uploadFile(0, totalFile, 
                                        resAcompanyPatient, 0);
                                    }
                                }
                                else
                                {
                                    if (this.state.formType === "saveAsDraft") {
                                        this.setState({
                                            isLoading: false,
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].save_as_Draft
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    } else {
                                        this.setState({
                                            isLoading: false,
                                            titleAlert: i18n[this.getLocale()].error,
                                            messageAlert: i18n[this.getLocale()].error_message
                                        }, () => {
                                            this.alertSubmit();
                                        })
                                    }  
                                }
                            })
        debugger;
                        }
                        else
                        {
                            let totalFile = 1;
                            let EmployeesArray = this.state.EmployeesArray;
                            if (resposeObj.RequestType == 2) {
                                let payloadForEmployeesArray = this.SaveEmployeesArray(resAcompanyPatient.data.id, EmployeesArray);
                                let EmployeesendPoint = 'SaveDisciplinaryEmployees'
                                let urlForDisciplinaryEmployees = Config.getUrl(EmployeesendPoint);
                                ApiRequest.sendRequestForPost(urlForDisciplinaryEmployees, payloadForEmployeesArray, resForEmployeeArrays => {
                                    if (resForEmployeeArrays.Status) {
                                        this.uploadFile(0, totalFile,
                                            resAcompanyPatient, 0);
                    
                                    }
                                    else {
                                        this.setState({
                                            isLoading: false,
                                            titleAlert: i18n[this.getLocale()].error,
                                            messageAlert: i18n[this.getLocale()].error_message
                                        }, () => {
                                            this.alertSubmit();
                                        })
                                    }
                                
                                    
                                })
                            }
                            else {
                    
                                this.uploadFile(0, totalFile,
                                    resAcompanyPatient, 0);
                            }
                        }
                         
                          
                         
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            });
                        }
                    })
                })
            })
        }
    }


    stepTwoData = (obj) => {
       
        if (obj.saveFrom === "Step2") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                RequestType: obj.RequestType,
                EmployeesArray:obj.EmployeesArray,
            }, () => {
            //    this.submitApiCall();
            })
        } 
        else {
            // this.setState({
            //     isEdit: obj.isEdit,
            //     requestId: obj.requestId,
            //     formType: obj.formType,
            //     EmployeeCV: obj.EmployeeCV,
            //     EmployeePhoto: obj.EmployeePhoto,
            //     positionofthenominatedposition: obj.positionofthenominatedposition,
            //     jobdescription: obj.jobdescription,
            //     HumanResourcesCommittee: obj.HumanResourcesCommittee,
            //     EmployeeNominationForm: obj.EmployeeNominationForm,
            //     ExperienceCertificates: obj.ExperienceCertificates,
            //     competencyreports: obj.competencyreports,
            //     passport: obj.passport,
            //     Summaryofregistrationforcitizens: obj.Summaryofregistrationforcitizens,
            //     IDcard: obj.IDcard,
            //     CID: obj.CID,
            //     EducationalQualifications: obj.EducationalQualifications,
            //     Medicalfitnesscheckup: obj.Medicalfitnesscheckup,
            //     Anotheremployeesselfstatement: obj.Anotheremployeesselfstatement,
            // }, () => {
            //     this.submitApiCall();
            // });
        }
        this.setState({
            step1: false, step2: false,step3: true,
        });
        this.nextButtonPressed(3);
    }

    stepThreeData = (obj) => {
        
       debugger;
        if (obj.saveFrom === "Step2") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                RequestType: obj.requestType,
                EmployeesArray:obj.EmployeesArray,
            }, () => {
                this.submitApiCall();
            })
        } 
        else {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                requesterName:obj.requesterName,
rquesterEmail:obj.rquesterEmail,
requesterphoneNumber:obj.requesterphoneNumber,
formalrequestletter:obj.formalrequestletter,
MembersArray:obj.MembersArray,

            }, () => {
                this.submitApiCall();
            });
        }
        this.setState({
            step1: false, step2: false,step3: true,
        });
        this.nextButtonPressed(3);
    }
    saveAsDraft = (obj) => {
     
        debugger;
        if (obj.saveFrom === "Step2") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                RequestType:obj.RequestType,
                EmployeesArray:obj.EmployeesArray,
                formType:"saveAsDraft"
            }, () => {
                this.submitApiCall();
            })
        } else  {
            
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                // requestType: obj.requestType,
                // RequestType: obj.RequestType,
                EmployeeCV: obj.EmployeeCV,
    EmployeePhoto: obj.EmployeePhoto,
        positionofthenominatedposition: obj.positionofthenominatedposition,
            jobdescription: obj.jobdescription,
                HumanResourcesCommittee: obj.HumanResourcesCommittee,
                    EmployeeNominationForm: obj.EmployeeNominationForm,
                        ExperienceCertificates: obj.ExperienceCertificates,
                            competencyreports: obj.competencyreports,
                                passport: obj.passport,
                                    Summaryofregistrationforcitizens: obj.Summaryofregistrationforcitizens,
                                        IDcard: obj.IDcard,
                                            CID: obj.CID,
                                            EducationalQualifications: obj.EducationalQualifications,
                                                    Medicalfitnesscheckup: obj.Medicalfitnesscheckup,
                                                        Anotheremployeesselfstatement: obj.Anotheremployeesselfstatement,
            }, () => {
                this.submitApiCall();
            })
        }
        // else {
        //     this.setState({
        //         isEdit: obj.isEdit,
        //         requestId: obj.requestId,
        //         formType: obj.formType,
        //         officialLetter: obj.officialLetter,
        //         emiratedID: obj.emiratedID,
        //         OtherAttach:obj.OtherAttach,
        //         passportCopy: obj.passportCopy,
        //         medicalReport: obj.medicalReport,
        //         medicalReportFIleObj: obj.medicalReportFIleObj,

        //         letterFromEmbassy: obj.letterFromEmbassy,
        //         copyOftheDigest: obj.copyOftheDigest,
        //         copyOfHumanResoourcesCommittee: obj.copyOfHumanResoourcesCommittee,
        //         documentationOfFinancialObligations: obj.documentationOfFinancialObligations,
        //         documentationOfFinancialObligationsShortName: obj.documentationOfFinancialObligationsShortName,
        //         //file code
        //         officialLetterFromHeadOfEntityFileObj: obj.officialLetterFromHeadOfEntityFileObj,
        //         passportCopyFIleObj: obj.passportCopyFIleObj,
              
        //         letterFromEmbassyFileObj: obj.letterFromEmbassyFileObj,
        //         copyOfDigestFileObj: obj.copyOfDigestFileObj,
        //         copyOfHumanResoourcesCommitteeFile: obj.copyOfHumanResoourcesCommitteeFile,
        //         documentationOfFinObligationsFileObjArray: obj.documentationOfFinObligationsFileObjArray,
        //         emiratesIDFileObj: obj.emiratesIDFileObj
        //     }, () => {
        //         this.submitApiCall();
        //     });
        // }

    }
    stepThreePrev =(obj)=>
    {
        this.setState({
            step1: false, step2: true,step3:false,
            isEdit: obj.isEdit,
            requestId: obj.requestId,
       
            requesterName:obj.requesterName,
            rquesterEmail: obj.rquesterEmail,
            requesterphoneNumber: obj.requesterphoneNumber,
            formalrequestletter:obj.formalrequestletter,
            MembersArray:obj.MembersArray,
        });
    }
    stepTwoPrev = (obj) => {
      
       
        this.setState({
            step1: true, step2: false,step3:false,
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            formType: obj.formType,
            RequestType: obj.RequestType,
            EmployeesArray:obj.EmployeesArray,
        });
    }
    
    stepOneData = (obj) => {
      

        this.setState({
            
            step1: false, step2: true,step3:false,
         
        }, () => {
            this.nextButtonPressed(2);
        });
    }

    nextButtonPressed = (isNextScreenActive) => {
        this.setState({ isNextScreenActive: isNextScreenActive });
    }

    handlePreViewNavigation = (obj) => {
        let editObj = Config.getEditObj();
        localStorage.setItem('currentPath', 'Disciplinaryboard');
        let previewObj = {};
        let tempreqtype='',tempreqtypeid='';
        tempreqtypeid=this.state.RequestType;
        if(tempreqtypeid=== '1')
        {
            tempreqtype= this.state.isArabic ?   'ترقية':'Promotion';
        }
        else
        {
            tempreqtype= this.state.isArabic ?   'تعيين':'Hiring';
        }
        previewObj.isEdit = editObj.isEdit;
        previewObj.requestId = editObj.requestId;
        // previewObj.isEdit = this.state.isEdit;
        // previewObj.requestId = this.state.requestId;
        previewObj.requestType = this.state.requestType;
        previewObj.RequestType = tempreqtype;
        previewObj.employeeName = this.state.employeeName;
        previewObj.EmployeeInfoEmployeeNumber = this.state.EmployeeInfoEmployeeNumber;
        previewObj.EmployeeInfoJobTitle = this.state.EmployeeInfoJobTitle;
        previewObj.EmployeeInfoJobDegree = this.state.EmployeeInfoJobDegree;
        previewObj.EmployeeInfoEmirateID = this.state.EmployeeInfoEmirateID;
        previewObj.EmployeeInfoEmployeeUID = this.state.EmployeeInfoEmployeeUID;
        previewObj.EmployeeInfoTotalSalary = this.state.EmployeeInfoTotalSalary;
        previewObj.EmployeeInfoBasicSalary = this.state.EmployeeInfoBasicSalary;
       
        previewObj.EmployeeInfoRetirementcontributions = this.state.EmployeeInfoRetirementcontributions;
    
        let ckEditorArr = [
            {
                label: "PromotionRequestreason",
                value: previewObj.PromotionRequestreason,
              
            },
          
        ];
    
      
    
        let previewAttachMentArray =
            [
                {
                    "label": "EmployeeCV",
                    "fileName": obj.EmployeeCV,
                    "fileObj": Config.getBase64ForPromotionEmployeeCV()
                },
                {
                    "label": "EmployeePhoto",
                    "fileName": obj.EmployeePhoto,
                    "fileObj": Config.getBase64ForPromotionEmployeePhoto()
                },
                {
                    "label": "positionofthenominatedposition",
                    "fileName": obj.positionofthenominatedposition,
                    "fileObj": Config.getBase64ForPromotionpositionofthenominatedposition()
                },
                {
                    "label": "jobdescription",
                    "fileName": obj.jobdescription,
                    "fileObj": Config.getBase64ForPromotionjobdescription()
                },
                {
                    "label": "HumanResourcesCommittee",
                    "fileName": obj.HumanResourcesCommittee,
                    "fileObj": Config.getBase64ForPromotionHumanResourcesCommittee()
                },
                {
                    "label": "EmployeeNominationForm",
                    "fileName": obj.EmployeeNominationForm,
                    "fileObj": Config.getBase64ForPromotionEmployeeNominationForm()
    
                },
                {
                    "label": "EducationalQualifications",
                    "fileName": obj.EducationalQualifications,
                    "fileObj": Config.getBase64ForPromotionEducationalQualifications()
                },
                {
                    "label": "ExperienceCertificates",
                    "fileName": obj.ExperienceCertificates,
                    "fileObj": Config.getBase64ForPromotionExperienceCertificates()
                },
                {
                    "label": "competencyreports",
                    "fileName": obj.competencyreports,
                    "fileObj": Config.getBase64ForPromotioncompetencyreports()
                },
                {
                    "label": "Summaryofregistrationforcitizens",
                    "fileName": obj.Summaryofregistrationforcitizens,
                    "fileObj": Config.getBase64ForPromotionSummaryofregistrationforcitizens()
                },
                {
                    "label": "passport",
                    "fileName": obj.passport,
                    "fileObj": Config.getBase64ForPromotionpassport()
                },
               
                {
                    "label": "IDcard",
                    "fileName": obj.IDcard,
                    "fileObj": Config.getBase64ForPromotionIDcard()
                },
                
                {
                    "label": "CID",
                    "fileName": obj.CID,
                    "fileObj": Config.getBase64ForPromotionCID()
                },
                {
                    "label": "Medicalfitnesscheckup",
                    "fileName": obj.Medicalfitnesscheckup,
                    "fileObj": Config.getBase64ForPromotionMedicalfitnesscheckup()
                },
                
                {
                    "label": "Anotheremployeesselfstatement",
                    "fileName": obj.Anotheremployeesselfstatement,
                    "fileObj": Config.getBase64ForPromotionAnotheremployeesselfstatement()
                }
            ];
    
        this.props.history.replace('/preview', { data: previewObj, attachmentArr: previewAttachMentArray, ckEditorArr: ckEditorArr });
    }
    uploadFile = (i, totalCount, resAcompanyPatient, multifileCount) => {
        
        debugger;
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let isLastFileInMultiUpload = false;
            let obj = {};
    
            if (i === 0) {
                fileObj = Config.getBase64ForDisciplinaryboardformalrequestletter();
                obj.filename = this.state.formalrequestletter;
                obj.filetype = Config.getDisciplinaryboardformalrequestletterID();
                tempBase64 = fileObj;
            }
            
         
         
    
            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 10953;
                if (resAcompanyPatient && resAcompanyPatient.data && resAcompanyPatient.data.id) {
                    reqId = resAcompanyPatient.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resAcompanyPatient.data.id;;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);
    
                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        // if (i === 1) {
                        //     if (isLastFileInMultiUpload) {
                        //         this.uploadFile(i + 1, totalCount, resAcompanyPatient, multifileCount + 1);
                        //     } else {
                        //         this.uploadFile(i, totalCount, resAcompanyPatient, multifileCount + 1);
                        //     }
                        // } else 
                        {
                            this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    }
    
    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }
    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
        stepThreeData: this.stepThreeData,
       
        stepTwoPrev: this.stepTwoPrev,
        stepThreePrev:this.stepThreePrev,
       
    };
    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
    
    componentWillMount = () => {

        let previewFlag = Config.getIsPreviewFlag();
        let previewckEditorArr = Config.getPreviewckEditorArray();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();
    
        if (previewFlag) {
    
            let EmployeeCV= '',
                EmployeePhoto= '',
                positionofthenominatedposition= '',
                jobdescription= '',
                HumanResourcesCommittee= '',
                EmployeeNominationForm= '',
                EducationalQualifications= '',
                ExperienceCertificates= '',
                competencyreports= '',
                passport= '',
                Summaryofregistrationforcitizens= '',
                IDcard= '',
                CID= '',
                Medicalfitnesscheckup= '',
                Anotheremployeesselfstatement= '',
                PromotionRequestreason = '';
    
           
     
    
            if (previewckEditorArr) {
                if (previewckEditorArr.length > 0) {
                    for (let j = 0; j < previewckEditorArr.length; j++) {
                        if (previewckEditorArr[j].label === 'PromotionRequestreason') {
                            PromotionRequestreason = previewckEditorArr[j].value;
                        }
                    }
                }
            }
    
        
            if (previewAttachmentArray.length > 0) {
                for (let index = 0; index < previewAttachmentArray.length; index++) {
                    if (previewAttachmentArray[index].label === 'EmployeeCV') {
                     
                        EmployeeCV = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'EmployeePhoto') {
                        EmployeePhoto = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'positionofthenominatedposition') {
                        positionofthenominatedposition = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'Anotheremployeesselfstatement') {
                        Anotheremployeesselfstatement = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'Medicalfitnesscheckup') {
                        Medicalfitnesscheckup = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'CID') {
                        CID = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'IDcard') {
                        IDcard = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'Summaryofregistrationforcitizens') {
                        Summaryofregistrationforcitizens = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'passport') {
                        passport = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'ExperienceCertificates') {
                        ExperienceCertificates = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'competencyreports') {
                        competencyreports = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'EducationalQualifications') {
                        EducationalQualifications = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'EmployeeNominationForm') {
                        EmployeeNominationForm = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'jobdescription') {
                        jobdescription = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'HumanResourcesCommittee') {
                        HumanResourcesCommittee = previewAttachmentArray[index].fileName;
                    }
            
                }
            }
    
            this.setState({
                step1: false,
                step2: true,
                RequestType : previewObj.RequestType,
                employeeName : previewObj.employeeName,
                EmployeeInfoEmployeeNumber : previewObj.EmployeeInfoEmployeeNumber,
                EmployeeInfoJobTitle : previewObj.EmployeeInfoJobTitle,
                EmployeeInfoJobDegree : previewObj.EmployeeInfoJobDegree,
                EmployeeInfoEmirateID : previewObj.EmployeeInfoEmirateID,
                EmployeeInfoEmployeeUID : previewObj.EmployeeInfoEmployeeUID,
                EmployeeInfoTotalSalary : previewObj.EmployeeInfoTotalSalary,
                EmployeeInfoBasicSalary : previewObj.EmployeeInfoBasicSalary,
                durationOfAccompanyingLeave : previewObj.durationOfAccompanyingLeave,
                EmployeeInfoRetirementcontributions : previewObj.EmployeeInfoRetirementcontributions,
                EmployeeCV:EmployeeCV,
                EmployeePhoto:EmployeePhoto,
                positionofthenominatedposition:positionofthenominatedposition,
                jobdescription:jobdescription,
                HumanResourcesCommittee:HumanResourcesCommittee,
                EmployeeNominationForm:EmployeeNominationForm,
                 EducationalQualifications:EducationalQualifications,
                                        ExperienceCertificates:ExperienceCertificates,
                                            competencyreports:competencyreports,
                                                passport:passport,
                                                    Summaryofregistrationforcitizens:Summaryofregistrationforcitizens,
                                                        IDcard:IDcard,
                                                            CID:CID,
                                                                Medicalfitnesscheckup:Medicalfitnesscheckup,
                                                                    Anotheremployeesselfstatement:Anotheremployeesselfstatement,
            });
         
          
        }
    }

    componentDidMount=()=>
    {
        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: true,
                step3:false
            
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
    }

    
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    render() {
        const sideBarData = Config.getServiceSideBar('Disciplinaryboard', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid style={{ height: '100vh' }}>
            <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                    <MDBRow
                        className="formWhiteContainer"
                        style={{
                            //  width: '100vw',
                            flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                        }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'Disciplinaryboard' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].Disciplinaryboard.subject}
                                    />
                                </MDBCol>
                            </MDBRow>

                            
                      
                          {/* <Upload  isArabic={this.state.isArabic}/> */}
                      
                          {
                                this.state.step1 ?
<Step1    isArabic={this.state.isArabic}  saveAsDraft={this.saveAsDraft}
   sendData={{
    isEdit: this.state.isEdit,
requestId: this.state.requestId,
        
RequestType: this.state.RequestType,
employeeName: this.state.employeeName,
EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
}}
getData={this.getData}
/>
:null}

  {
                                this.state.step2 ?
                                <Step2    isArabic={this.state.isArabic} 
                                getData={this.getData}
                                sendData={{
                                    isEdit: this.state.isEdit,
                                    requestId: this.state.requestId,
                                    RequestType:this.state.RequestType,
                                    EmployeesArray:this.state.EmployeesArray
                                }}
                                saveAsDraft={this.saveAsDraft}   previewPage={this.handlePreViewNavigation}/>
                                :null}

{
                                this.state.step3 ?
                                <Step3    isArabic={this.state.isArabic} 
                                getData={this.getData}
                                sendData={{
                                    isEdit: this.state.isEdit,
                                    requestId: this.state.requestId,
                                    
       
                                    requesterName:this.state.requesterName,
                                    rquesterEmail: this.state.rquesterEmail,
                                    requesterphoneNumber: this.state.requesterphoneNumber,
                                    formalrequestletter:this.state.formalrequestletter,
                                    MembersArray:this.state.MembersArray,
                                }}
                                saveAsDraft={this.saveAsDraft}   previewPage={this.handlePreViewNavigation}/>
                                :null}
                        
                        
<div style={{clear:"both",height:"100px"}}/>
                          <Footer isArabic={this.state.isArabic} />
                            </MDBCol>
                            
                            <SideBar
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
        </MDBRow>
        </LoadingOverlay>
</MDBContainer>
        );
    }
}