import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import InputMask from 'react-input-mask';
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadComponent from "../DownloadComponent";
import CKEditorComponent from '../CKEditorComponent';
import Validator from "../../utils/Validator";
import Select from 'react-select';

const striptags = require('striptags');
const englishArray = [];
const arebicArray = [];

export default class StepOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: '',
            statusid: '',
            statusreasonid: '',
            // form variables
            entityName: this.props.sendData.entityName,
            requestTitle: this.props.sendData.requestTitle,
            requestDetails: this.props.sendData.requestDetails,
            requestDate: this.props.sendData.requestDate,
            systemType: this.props.sendData.systemType,
            systemTypeLable: this.props.sendData.systemTypeLable,
            employeeName: this.props.sendData.employeeName,
            jobDesc: this.props.sendData.jobDesc,
            contactNumber: this.props.sendData.contactNumber,
            email: this.props.sendData.email,
            systemTypeArray: [],
            // attachment
            otherAttachment: this.props.sendData.otherAttachment,
            otherAttachmentShortName: this.props.sendData.otherAttachmentShortName,
            otherAttachmentFileObjArray: [],
            // error variables
            requestDateErr: '',
            emailErr: '',
            contactNumberErr: '',
            systemTypeErr: '',
            jobDescErr: "",
            reqDetailsErr: "",

            // edit attachment array
            editAttachmentArray: [],
            otherAttachmentArray: []
        }
        this.handleRequestDate = this.handleRequestDate.bind(this);
    }

    componentDidMount = () => {
        let tempSystemArray = []
        if (Config.getSystemList()) {
            tempSystemArray = Config.getSystemList();
        }
        this.setState({
            systemTypeArray: tempSystemArray
        }, () => {
            let loginData = Config.getLocalStorageLoginData();
            let getloginDataReceivedFirstTime = Config.getloginDataReceivedFirstTime();
           
            if (loginData && !getloginDataReceivedFirstTime) {
                // get email, contact and , entity id and entity name from login
                let tempEntityName = this.props.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
                let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
                this.setState({
                    employeeName: tempEmployeeName,
                    entityName: tempEntityName,
                    jobDesc: loginData && loginData.JobTitle,
                    contactNumber: loginData && loginData.ContactNumber,
                    email: loginData && loginData.Email
                }, () => {
                    Config.setLoginDataReceivedFirstTime(true);
                })
            }

            if (this.state.systemTypeArray.length && this.state.systemTypeArray) {
                if (this.state.systemTypeArray.length !== englishArray.length) {
                    for (let index = 0; index < this.state.systemTypeArray.length; index++) {
                        englishArray.push({
                            value: this.state.systemTypeArray[index].englishtitle.toString(),
                            label: this.state.systemTypeArray[index].englishtitle.toString()
                        });
                        arebicArray.push({
                            value: this.state.systemTypeArray[index].arabictitle.toString(),
                            label: this.state.systemTypeArray[index].arabictitle.toString()
                        });
                    }
                }
            }


            let editFlag = Config.getIsEditFlag();
            let editObj = Config.getEditObj();
            if (editFlag) {
                this.setState({
                    requestId: editObj.requestId,
                    isEdit: editFlag,
                    requesttypeid: editObj.requesttypeid,
                    statusid: editObj.statusid,
                    statusreasonid: editObj.statusreasonid,
                    requestTitle: editObj.requesttitle,
                    email: editObj.email,
                    requestDate: editObj.requestdate ? new Date(editObj.requestdate) : null,
                    employeeName: editObj.employeename,
                    contactNumber: editObj.contactnumber,
                    requestDetails: editObj.requestdetails,
                    jobDesc: editObj.jobtitle,
                    systemType: editObj.systemlist,
                    editAttachmentArray: editObj.attachments
                }, () => {
                    let tempOtherAttachmentArray = [];
                    if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                        let tempAttactmentArray = [...this.state.editAttachmentArray];
                        if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                            tempAttactmentArray.forEach((item, index) => {
                                if (item.filetypeid === Config.getOtherAttachmentsAmendEntitySystems()) {
                                    let temp = {}
                                    temp.url = item.aliasfilename;
                                    temp.fileName = item.filename;
                                    if (this.state.requestId) {
                                        temp.requestId = this.state.requestId;
                                    } else {
                                        temp.requestId = editObj.requestId
                                    }
                                    tempOtherAttachmentArray.push(temp);
                                }
                            })
                            this.setState({
                                otherAttachmentArray: tempOtherAttachmentArray
                            })
                        }
                    }
                });
            }
        })
    }

    submitHandler = (event) => {

        event.preventDefault();
        event.target.className += " was-validated";

        if (!this.state.requestDate) {
            this.setState({ requestDate: null, requestDateErr: i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired });
        }

        let tempContactNumberErr = "";
        if (this.state.contactNumber) {
            if (!Validator.validateContactNumber(this.state.contactNumber)) {
                tempContactNumberErr = "invalidContactNumber";
            }
        }
        else {
            tempContactNumberErr = "thisFieldIsRequired";
        }

        let tempJobDescErr = "";
        if (this.state.jobDesc && this.state.jobDesc.trim()) {
            let val = striptags(this.state.jobDesc.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                tempJobDescErr = 'thisFieldIsRequired';
            }
        } else {
            tempJobDescErr = 'thisFieldIsRequired';
        }

        let systemTypeErr = '';
        if (this.state.systemType) {
            if (this.state.systemType.length == 0) {
                systemTypeErr = 'thisFieldIsRequired';
            }
        } else {
            systemTypeErr = 'thisFieldIsRequired';
        }

        let tempReqDetailsErr = "";
        if (this.state.requestDetails && this.state.requestDetails.trim()) {
            let val = striptags(this.state.requestDetails.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                tempReqDetailsErr = 'thisFieldIsRequired';

            }
        } else {
            tempReqDetailsErr = 'thisFieldIsRequired';
        }

        let tempEmailErr = "";
        if (this.state.email) {
            if (!Validator.validateEmail(this.state.email)) {
                tempEmailErr = "invalidEmail";
            }
        }
        else {
            tempEmailErr = "thisFieldIsRequired";
        }

        this.setState({
            jobDescErr: tempJobDescErr,
            systemTypeErr: systemTypeErr,
            reqDetailsErr: tempReqDetailsErr,
            contactNumberErr: tempContactNumberErr,
            emailErr: tempEmailErr
        }, () => {
            if (!this.state.jobDescErr &&
                !this.state.systemTypeErr &&
                !this.state.reqDetailsErr &&
                !this.state.emailErr &&
                !this.state.contactNumberErr &&
                !this.state.requestDateErr
            ) {
                if (this.state.entityName &&
                    this.state.requestTitle &&
                    this.state.requestDetails &&
                    this.state.requestDate &&
                    this.state.systemType &&
                    this.state.employeeName &&
                    this.state.jobDesc &&
                    !this.state.contactNumberErr &&
                    this.state.email
                ) {
                    this.props.getData.stepOneData({
                        requestId: this.state.requestId,
                        isEdit: this.state.isEdit,
                        entityName: this.state.entityName,
                        requestTitle: this.state.requestTitle,
                        requestDetails: this.state.requestDetails,
                        requestDate: this.state.requestDate,
                        systemType: this.state.systemType,
                        employeeName: this.state.employeeName,
                        jobDesc: this.state.jobDesc,
                        contactNumber: this.state.contactNumber,
                        email: this.state.email,
                        // attchment names
                        otherAttachment: this.state.otherAttachment,
                        otherAttachmentShortName: this.state.otherAttachmentShortName,
                    });
                }
            }
        });
    };

    handleEntityName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ entityName: event.target.value }, () => {
                });
            }
        }
        else {
            this.setState({ entityName: "" }, () => {
            });
        }
    };

    handleRequestTitle = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ requestTitle: event.target.value });
            }
        }
        else {
            this.setState({ requestTitle: "" });
        }
    };

    handleRequestDetails = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                requestDetails: newContent,
                reqDetailsErr: ""
            })
        }
        else {
            this.setState({ reqDetailsErr: "ckeditorCountErr" })
        }
    };

    handleRequestDate(date) {
        if (date != null) {
            this.setState({
                requestDate: date,
                requestDateErr: ''
            });
        }
        else {
            this.setState({ requestDate: null, requestDateErr: "thisFieldIsRequired" })
        }
    }

    handleChangeForSystemTypeDropdown = (event) => {
        this.setState({ systemType: event, systemTypeErr: '' });
    };

    handleEmployeeName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        }
        else {
            this.setState({ employeeName: "" });
        }
    };

    handleJobDesc = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim()) {
            if (regex.test(event.target.value)) {
                this.setState({
                    jobDescErr: "",
                    jobDesc: event.target.value
                })
            }
        }
        else {
            this.setState({
                jobDesc: ""
            })
        }
    }

    handleEmailChange = (event) => {
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ email: event.target.value, emailErr: '' });
            }
        } else {
            this.setState({ email: "", emailErr: "thisFieldIsRequired" });
        }
    };

    handleContactNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ contactNumber: event.target.value }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ contactNumberErr: '' });
                } else {
                    this.setState({ contactNumberErr: "invalidContactNumber" });
                }
            });
        } else {
            this.setState({ contactNumber: '', contactNumberErr: "thisFieldIsRequired" });
        }

    };

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestTitle: this.state.requestTitle,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            systemType: this.state.systemType,
            employeeName: this.state.employeeName,
            jobDesc: this.state.jobDesc,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            systemTypeLable: this.state.systemTypeLable,
            // attchment names
            otherAttachment: this.state.otherAttachment,
            otherAttachmentShortName: this.state.otherAttachmentShortName,
        });
    };
    
    onRequestDatepickerRef(el) {
        if (el && el.input) {
            if (this.state.requestDate !== null) {
                el.input.disabled = true;
                el.input.readOnly = false;
                el.input.classList.remove('white_bg');
            } else {
                el.input.disabled = false;
                el.input.readOnly = true;
                el.input.classList.add('white_bg');
            }
            if (el.input.type === "blur") {
                el.input.target.style.backgroundColor = "white";
            }
        }
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestTitle: this.state.requestTitle,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            systemType: this.state.systemType,
            employeeName: this.state.employeeName,
            jobDesc: this.state.jobDesc,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            systemTypeLable: this.state.systemTypeLable,
            otherAttachment: this.state.otherAttachment,
            otherAttachmentShortName: this.state.otherAttachmentShortName,
        });
    }

    otherAttachmentOnChnage = (e) => {
        let fileNames = "";
        let fileOtherAttachmnetArray = [];

        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            otherAttachment: '',
                            otherAttachmentShortName: '',
                            otherAttchmentErr: 'invalidFileType'
                        });
                        break;
                    }
                    else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let otherAttachmentShortName = fileNames.substring(0, 50);

                            let reader = new FileReader();
                            let tempFile = e.target.files[m];
                            reader.onloadend = () => {
                                fileOtherAttachmnetArray.push(reader.result);
                                this.setState({
                                    otherAttachmentFileObjArray: fileOtherAttachmnetArray,
                                }, () => {
                                    Config.setbase64OtherAttachmentForAmendOnShared(this.state.otherAttachmentFileObjArray);
                                });
                            };
                            reader.readAsDataURL(tempFile);
                            this.setState({
                                otherAttachment: fileNames,
                                otherAttachmentShortName: otherAttachmentShortName,
                                otherAttchmentErr: ''
                            });
                        } else {
                            e.target.value = '';
                            this.setState({
                                otherAttachment: '',
                                otherAttachmentShortName: '',
                                otherAttchmentErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    otherAttachment: "",
                    otherAttachmentShortName: "",
                    otherAttchmentErr: 'fileLimitErr'
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({
                otherAttachment: "",
                otherAttachmentShortName: "",
                otherAttchmentErr: ''
            });
        }
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.entityName}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.entityName}
                                onChange={this.handleEntityName}
                                type="text"
                                id="entityName"
                                className="form-control"
                                name="entityName"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestTitle}<span className="requireStar">*</span></label>

                            <input
                                value={this.state.requestTitle}
                                onChange={this.handleRequestTitle}
                                type="text"
                                id="defaultFormRegisterPasswordEx4"
                                className="form-control"
                                name="requestTitle"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>

                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">

                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.email}<span className="requireStar">*</span></label>

                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleEmailChange}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.email}
                                required
                            />

                            {this.state.emailErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                : null}

                            {this.state.emailErr === 'invalidEmail' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidEmail}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestDate}<span className="requireStar">*</span></label>

                            <div
                                style={{
                                    width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                }}
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.requestDateErr ? "datePickerBorderColorErr" : ""}`}>

                                <DatePicker
                                    disabled={true}
                                    minDate={new Date()}
                                    className="form-control"
                                    todayButton={"Today"}
                                    style={{ width: '100%' }}
                                    selected={this.state.requestDate}
                                    onChange={this.handleRequestDate}
                                    ref={el => this.onRequestDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required />

                            </div>

                            {this.state.requestDateErr !== '' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}

                        </div>

                    </MDBCol>

                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.contactNumber}<span className="requireStar">*</span></label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="form-control"
                                required
                            />

                            {this.state.contactNumberErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div> : null}

                            {this.state.contactNumberErr === 'invalidContactNumber' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidContactNumber}
                                </div>
                                :
                                null}

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.employeeName}<span className="requireStar">*</span></label>

                            <input
                                type="text"
                                className="form-control"
                                id="employeeName"
                                name="employeeName"
                                onChange={this.handleEmployeeName}
                                value={this.state.employeeName}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>

                </MDBRow>
                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.jobDesc}<span className="requireStar">*</span></label>

                            <input
                                type="text"
                                className="form-control"
                                id="jobDesc"
                                name="jobDesc"
                                onChange={this.handleJobDesc}
                                value={this.state.jobDesc}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>


                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"
                        style={{
                            direction: this.props.isArabic ? 'rtl' : 'ltr',
                            textAlign: this.props.isArabic ? 'right' : 'left'
                        }}>

                        <div className="form-group">
                            <label style={{ display: 'flex', width: '100%' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.systemType}<span className="requireStar">*</span></label>

                            <div style={{ width: '100%', display: 'flex', borderRadius: '.25rem', padding: '1px', border: this.state.systemTypeErr === 'thisFieldIsRequired' ? '1px solid red' : '', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                                <Select
                                    options={this.props.isArabic ? arebicArray : englishArray}
                                    style={{
                                        display: 'flex',
                                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        textAlign: this.props.isArabic ? "right" : "left",
                                    }}
                                    placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                    onChange={this.handleChangeForSystemTypeDropdown}
                                    value={this.state.systemType}
                                    required
                                    isMulti
                                    closeMenuOnSelect={false}
                                />
                            </div>

                            {this.state.systemTypeErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'unset' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group"  >
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} >{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.otherattachemnt}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="otherFile"
                                        type="file"
                                        name="others"
                                        multiple
                                        onChange={(event) => this.otherAttachmentOnChnage(event)}
                                    />
                                    <label
                                        htmlFor="otherFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic ? "row" : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="otherFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        {/* {this.state.otherAttachment} */}

                                        <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.otherAttachmentShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.otherAttachmentShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.otherAttachment}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.otherAttchmentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                            </p>
                            {
                                (this.state.otherAttachmentArray &&
                                    this.state.otherAttachmentArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.otherAttachmentArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">

                        <div className="form-group">

                            <label htmlFor="formGroupExampleInput" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestDetails}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>

                            </label>

                            <div style={{ border: this.state.reqDetailsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >

                                <CKEditorComponent
                                    isArabic={this.props.isArabic}
                                    content={this.state.requestDetails}
                                    onChange={this.handleRequestDetails}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                        </div>
                        {
                            this.state.reqDetailsErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    {
                        ((!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) && !(this.state.statusreasonid === Config.getReasonForReturnToEntityId())) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.props.isArabic ? "ar" : "en"].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.props.isArabic ? "ar" : "en"].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn type="submit" color="primary" className="submitBtn" >
                        {i18n[this.props.isArabic ? "ar" : "en"].submit}
                    </MDBBtn>
                </MDBRow>
            </form>
        );
    }
}