import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import Collapsible from 'react-collapsible';
import "../../css/main.css";
import Config from '../../utils/Config';

export default class RoleForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            radio: 0,
            englishTitleValue: '',
            arabicTitle: '',
            isNextScreenActive: false,
            isadmin: 0,
            canCreateWorkShop: 0,
            canBookWorkShop: 0,
            canViewDisciplinaryboard:0,
            canCreateDisciplinaryboard: 0,
            canEditDisciplinaryboard: 0,
            canAssignDisciplinaryboardToDGoffice: 0,
            canAssignDisciplinaryboardtoEmployee: 0,
            canAddReplayDisciplinaryboard: 0,
            canViewOHI: 0,
            
            // SCI
            canassignscigm: 0,
            cansubmitscirequest: 0,
            canreviewscirequest: 0,
            caneditscirequest: 0,
            canupdatescistatus: 0,
            canaddscicomment: 0,
            canassignscidepartment: 0,
            canasignsciemployee: 0,
            canprovidescifeedback: 0,
            canviewscifeedback: 0,
            canassignscisector: 0,
            canshowscicomments: 0,
            canassignscibacktoentity: 0,

             // organization structure 
              canassignorggm: 0,
              cansubmitorgrequest: 0,
              canvieworgrequest: 0,
              caneditorgrequest: 0,
              canupdateorgstatus: 0,
              canaddorgcomment: 0,
              canassignorgdepartment: 0,
              canassignorgemployee: 0,
              canprovideorgfeedback: 0,
              canvieworgfeedback: 0,
              canassignorgsector: 0,
              canshoworgcomments: 0,
              canassignorgbacktoentity: 0,
              canviewtree:0,

            // Information
            canassignirgm: 0,
            cansubmitirrequest: 0,
            canviewirrequest: 0,
            caneditirrequest: 0,
            canupdateirstatus: 0,
            canaddircomments: 0,
            canassignirdepartment: 0,
            canAddInfoReqFeedback: 0,
            canAddInfoFeedback: 0,
            canviewInfofeedback: 0,
            canshowircomments: 0,
            canassignirsector: 0,
            canreceiveirfeedback: 0,
            canprovideirfeedback: 0,
            canassignirbacktoentity: 0,

            // Patient
            canassignpatientgm: 0,
            cansubmitpatientrequest: 0,
            canviewpatientrequest: 0,
            caneditpatientrequest: 0,
            canupdatepatientstatus: 0,
            canaddpatientcomments: 0,
            canshowpatientcomments: 0,
            canassignpatientdepartment: 0,
            canassignpatientemployee: 0,
            canassignpatientsector: 0,
            canprovidepatientstudy: 0,
            canreceivepatientstudy: 0,
            canassignpatientbacktoentity: 0,

            // HR Policy
            canassignhrgm: 0,
            cansubmitreviewhrrequest: 0,
            canviewreviewhrrequest: 0,
            caneditreviewhrrequest: 0,
            canupdatereviewhrstatus: 0,
            canaddreviewhrcomments: 0,
            canshowreviewhrcomments: 0,
            canassignhrsector: 0,
            canassignhrdepartment: 0,
            canreceivehrstudy: 0,
            canprovidehrstudy: 0,
            canassignhremployee: 0,
            canassignhrbacktoentity: 0,

            // AD Housing
            canassignhousinggm: 0,
            cansubmitadhousingrequest: 0,
            canviewadhousingrequest: 0,
            caneditadhousingrequest: 0,
            canupdateadhousingstatus: 0,
            canaddadhousingcomments: 0,
            canassignhousingdepartment: 0,
            canassignhousingemployee: 0,
            canassignhousingsector: 0,
            canprovidehousingstudy: 0,
            canreceivehousingstudy: 0,
            canshowadhousingcomments: 0,
            canassignhousingbacktoentity: 0,

            // Secondment
            canassignsecondmentgm: 0,
            cansubmitsecondmentrequest: 0,
            canviewsecondmentrequest: 0,
            caneditsecondmentrequest: 0,
            canupdatesecondmentstatus: 0,
            canaddsecondmentcomments: 0,
            canassignsecondmentdepartment: 0,
            canassignsecondmetnemployee: 0,
            canassignsecondmentsector: 0,
            canprovidesecondmentstudy: 0,
            canreceivesecondmentstudy: 0,
            canshowsecondmentcomments: 0,
            canassignsecondmentbacktoentity: 0,

            // Bonus
            canassignbonusgm: 0,
            cansubmitbonusrequest: 0,
            canviewbonusrequest: 0,
            caneditbonusrequest: 0,
            canupdatebonusstatus: 0,
            canaddbonuscomments: 0,
            canassignBonusReqdepartment: 0,
            canasignBonusReqemployee: 0,
            canprovideBonusReqfeedback: 0,
            canviewBonusReqfeedback: 0,
            canshowbonuscomments: 0,
            canassignbounsdepartment: 0,
            canassignbounsemployee: 0,
            canassignbonussector: 0,
            canprovidebonusstudy: 0,
            canreceivebonusstudy: 0,
            canassignbonusbacktoentity: 0,

            // Amend scale
            canassignamendscalegm: 0,
            cansubmitamendscalerequest: 0,
            canviewamendscalerequest: 0,
            caneditamendscalerequest: 0,
            canupdateamendscalestatus: 0,
            canaddamendscalecomments: 0,
            canassignAmendSalScaledepartment: 0,
            canasignAmendSalScaleemployee: 0,
            canprovideAmendSalScalefeedback: 0,
            canviewAmendSalScalefeedback: 0,
            canshowamendscalecomments: 0,
            canassignamendscaledepartment: 0,
            canassignamendscaleemployee: 0,
            canassignamendscalesector: 0,
            canprovideamendscalestudy: 0,
            canreceiveamendscalestudy: 0,
            canassignamendscalebacktoentity: 0,

            // Amend Salary
            canassignamendsalarygm: 0,
            cansubmitamendsalaryrequest: 0,
            canviewamendsalaryrequest: 0,
            caneditamendsalaryrequest: 0,
            canupdateamendsalarystatus: 0,
            canaddamendsalarycomments: 0,
            canassignamendsalarydepartment: 0,
            canassignamendsalaryemployee: 0,
            canassignamendsalarysector: 0,
            canshowamendsalarycomments: 0,
            canprovideamendsalarystudy: 0,
            canreceiveamendsalarystudy: 0,
            canassignamendsalarybacktoentity: 0,

            // Retirment
            canassignretirementgm: 0,
            cansubmitretirementrequest: 0,
            canviewretirementrequest: 0,
            caneditretirementrequest: 0,
            canupdateretirementstatus: 0,
            canaddretirementcomments: 0,
            canshowretirementcomments: 0,
            canassignretirementdepartment: 0,
            canassignretirementemployees: 0,
            canassignretirementsector: 0,
            canprovideretirementstudy: 0,
            canreceiveretirementstudy: 0,
            canassignretirementbacktoentity: 0,

            //extend create objectives
            cansubmitextendcreaterequest: 0,
            canviewextendcreaterequest: 0,
            caneditextendcreaterequest: 0,
            canupdateextendcreatestatus: 0,
            canshowextendcreatecomments: 0,
            canaddextendcreatecomments: 0,
            canprovideextendcreatestudy: 0,
            canreceiveextendcreatestudy: 0,
            canassignextendcreatesector: 0,
            canassignextendcreatedepartment: 0,
            canassignextendcreateemployee: 0,
            canassignextendcreatebacktodepartment: 0,
            canassignextendcreatebacktoentity: 0,
            canassignextendcreaterequesttogm: 0,

            //extend review objectives
            cansubmitextendreviewrequest: 0,
            canviewextendreviewrequest: 0,
            caneditextendreviewrequest: 0,
            canupdateextendreviewstatus: 0,
            canshowextendreviewcomments: 0,
            canaddextendreviewcomments: 0,
            canprovideextendreviewstudy: 0,
            canreceiveextendreviewstudy: 0,
            canassignextendreviewsector: 0,
            canassignextendreviewdepartment: 0,
            canassignextendreviewemployee: 0,
            canassignextendreviewbacktodepartment: 0,
            canassignextendreviewbacktoentity: 0,
            canassignextendreviewrequesttogm: 0,

            //extend annual performance
            cansubmitextendannualrequest: 0,
            canviewextendannualrequest: 0,
            caneditextendannualrequest: 0,
            canupdateextendannualstatus: 0,
            canshowextendannualcomments: 0,
            canaddextendannualcomments: 0,
            canprovideextendannualstudy: 0,
            canreceiveextendannualstudy: 0,
            canassignextendannualsector: 0,
            canassignextendannualdepartment: 0,
            canassignextendannualemployee: 0,
            canassignextendannualbacktodepartment: 0,
            canassignextendannualbacktoentity: 0,
            canassignextendannualrequesttogm: 0,

            // add and edit employee perfpmance
            cansubmitemployeeperformancerequest: 0,
            canviewemployeeperformancerequest: 0,
            caneditemployeeperformancerequest: 0,
            canupdateemployeeperformancestatus: 0,
            canshowemployeeperformancecomments: 0,
            canaddemployeeperformancecomments: 0,
            canprovideemployeeperformancestudy: 0,
            canreceiveemployeeperformancestudy: 0,
            canassignemployeeperformancesector: 0,
            canassignemployeeperformancedepartment: 0,
            canassignemployeeperformanceemployee: 0,
            canassignemployeeperformancebacktodepartment: 0,
            canassignemployeeperformancebacktoentity: 0,
            canassignemployeeperformancerequesttogm: 0,

            //join and implement 
            cansubmitjoinimplementrequest: 0,
            canviewjoinimplementrequest: 0,
            caneditjoinimplementrequest: 0,
            canupdatejoinimplementstatus: 0,
            canshowjoinimplementcomments: 0,
            canaddjoinimplementcomments: 0,
            canprovidejoinimplementstudy: 0,
            canreceivejoinimplementstudy: 0,
            canassignjoinimplementsector: 0,
            canassignjoinimplementdepartment: 0,
            canassignjoinimplementemployee: 0,
            canassignjoinimplementbacktodepartment: 0,
            canassignjoinimplementbacktoentity: 0,
            canassignjoinimplementrequesttogm: 0,

            // open complains
            cansubmitopencomplainsrequest: 0,
            canviewopencomplainsrequest: 0,
            caneditopencomplainsrequest: 0,
            canupdateopencomplainsstatus: 0,
            canshowopencomplainscomments: 0,
            canaddopencomplainscomments: 0,
            canprovideopencomplainsstudy: 0,
            canreceiveopencomplainsstudy: 0,
            canassignopencomplainssector: 0,
            canassignopencomplainsdepartment: 0,
            canassignopencomplainsemployee: 0,
            canassignopencomplainsbacktodepartment: 0,
            canassignopencomplainsbacktoentity: 0,
            canassignopencomplainsrequesttogm: 0,

            //Amend system
            cansubmitamendsystemsrequest: 0,
            canviewamendsystemsrequest: 0,
            caneditamendsystemsrequest: 0,
            canupdateamendsystemsstatus: 0,
            canshowamendsystemscomments: 0,
            canaddamendsystemscomments: 0,
            canprovideamendsystemsstudy: 0,
            canreceiveamendsystemsstudy: 0,
            canassignamendsystemssector: 0,
            canassignamendsystemsdepartment: 0,
            canassignamendsystemsemployee: 0,
            canassignamendsystemsbacktodepartment: 0,
            canassignamendsystemsbacktoentity: 0,
            canassignamendsystemsrequesttogm: 0,

            //Report and static 
            cansubmitreportsstaticsrequest: 0,
            canviewreportsstaticsrequest: 0,
            caneditreportsstaticsrequest: 0,
            canupdatereportsstaticsstatus: 0,
            canshowreportsstaticscomments: 0,
            canaddreportsstaticscomments: 0,
            canprovidereportsstaticsstudy: 0,
            canreceivereportsstaticsstudy: 0,
            canassignreportsstaticssector: 0,
            canassignreportsstaticsdepartment: 0,
            canassignreportsstaticsemployee: 0,
            canassignreportsstaticsbacktodepartment: 0,
            canassignreportsstaticsbacktoentity: 0,
            canassignreportsstaticsrequesttogm: 0,

            //Other request
            cansubmitotherrequestsrequest: 0,
            canviewotherrequestsrequest: 0,
            caneditotherrequestsrequest: 0,
            canupdateotherrequestsstatus: 0,
            canshowotherrequestscomments: 0,
            canaddotherrequestscomments: 0,
            canprovideotherrequestsstudy: 0,
            canreceiveotherrequestsstudy: 0,
            canassignotherrequestssector: 0,
            canassignotherrequestsdepartment: 0,
            canassignotherrequestsemployee: 0,
            canassignotherrequestsbacktodepartment: 0,
            canassignotherrequestsbacktoentity: 0,
            canassignotherrequesttogmsrequesttogm: 0,

            // report and close request
            canviewreport:0,
            canCloseRequest:0,
            canViewEmployeeReport:0,
            canGenerateAllreports:0,
            englishName: '',
            arabicName: '',
            shortName: '',
            employeeNumber: '',
            isMotherCompany: '',
            isArabic: true,
            sector: '',
            industry: '',
            logoFile: null,
            clientDescription: '',
            city: [],
            isActive: false,
            reasonToDeative: '',
            phoneNumber: '',
            isEdit: false,
            requestId: '',
            titleArray: [
                {
                    'id': '1',
                    'EnglishTitle': 'Gov',
                    'ArabicTitle': 'الحكومة الإلكترونية'
                },
                {
                    'id': '2',
                    'EnglishTitle': 'Semi Gov',
                    'ArabicTitle': 'نصف حكومي'
                },
                {
                    'id': '3',
                    'EnglishTitle': 'Fedral',
                    'ArabicTitle': 'فدرالي'
                },
                {
                    'id': '4',
                    'EnglishTitle': 'Local',
                    'ArabicTitle': 'محلي'
                },
            ],
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
        
        if (editFlag) {
            
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,

                englishTitleValue: editObj.titleSelectedValue,
                arabicTitle: editObj.arabicTitle,

                isadmin: editObj.isadmin,
                canviewreport:editObj.canviewreport,
                canCloseRequest:editObj.canCloseRequest,
                canViewEmployeeReport:editObj.canViewEmployeeReport,
                canGenerateAllreports:editObj.canGenerateAllreports,

                canCreateWorkShop:editObj.canCreateWorkShop,
canBookWorkShop:editObj.canBookWorkShop,
canViewDisciplinaryboard:editObj.canViewDisciplinaryboard,
canCreateDisciplinaryboard:editObj.canCreateDisciplinaryboard,
canEditDisciplinaryboard:editObj.canEditDisciplinaryboard,
canAssignDisciplinaryboardToDGoffice:editObj.canAssignDisciplinaryboardToDGoffice,
canAssignDisciplinaryboardtoEmployee:editObj.canAssignDisciplinaryboardtoEmployee,
canAddReplayDisciplinaryboard:editObj.canAddReplayDisciplinaryboard,
canViewOHI:editObj.canViewOHI,
                // SCI
                canassignscigm: editObj.canassignscigm,
                cansubmitscirequest: editObj.cansubmitscirequest,
                canreviewscirequest: editObj.canreviewscirequest,
                caneditscirequest: editObj.caneditscirequest,
                canupdatescistatus: editObj.canupdatescistatus,
                canaddscicomment: editObj.canaddscicomment,
                canassignscidepartment: editObj.canassignscidepartment,
                canasignsciemployee: editObj.canasignsciemployee,
                canprovidescifeedback: editObj.canprovidescifeedback,
                canviewscifeedback: editObj.canviewscifeedback,
                canassignscisector: editObj.canassignscisector,
                canshowscicomments: editObj.canshowscicomments,
                canassignscibacktoentity: editObj.canassignscibacktoentity,

                 // organization structure 
                
                 canassignorggm: editObj.canassignorggm,
                 cansubmitorgrequest: editObj.cansubmitorgrequest,
                 canvieworgrequest: editObj.canvieworgrequest,
                 canopencreationstage: editObj.canopencreationstage,

                 caneditorgrequest: editObj.caneditorgrequest,
                 canupdateorgstatus: editObj.canupdateorgstatus,
                 canaddorgcomment: editObj.canaddorgcomment,
                 canassignorgdepartment: editObj.canassignorgdepartment,
                 canasignorgemployee: editObj.canasignorgemployee,
                 canprovideorgfeedback: editObj.canprovideorgfeedback,
                 canvieworgfeedback: editObj.canvieworgfeedback,
                 canassignorgsector: editObj.canassignorgsector,
                 canshoworgcomments: editObj.canshoworgcomments,
                 canassignorgbacktoentity: editObj.canassignorgbacktoentity,
                 canviewtree: editObj.canviewtree,


                // Infomration
                canassignirgm: editObj.canassignirgm,
                cansubmitirrequest: editObj.cansubmitirrequest,
                canviewirrequest: editObj.canviewirrequest,
                caneditirrequest: editObj.caneditirrequest,
                canupdateirstatus: editObj.canupdateirstatus,
                canaddircomments: editObj.canaddircomments,
                canassignirdepartment: editObj.canassignirdepartment,
                canshowircomments: editObj.canshowircomments,
                canassignirsector: editObj.canassignirsector,
                canreceiveirfeedback: editObj.canreceiveirfeedback,
                canprovideirfeedback: editObj.canprovideirfeedback,
                canassignirbacktoentity: editObj.canassignirbacktoentity,

                // Patient
                canassignpatientgm: editObj.canassignpatientgm,
                cansubmitpatientrequest: editObj.cansubmitpatientrequest,
                canviewpatientrequest: editObj.canviewpatientrequest,
                caneditpatientrequest: editObj.caneditpatientrequest,
                canupdatepatientstatus: editObj.canupdatepatientstatus,
                canaddpatientcomments: editObj.canaddpatientcomments,
                canshowpatientcomments: editObj.canshowpatientcomments,
                canassignpatientdepartment: editObj.canassignpatientdepartment,
                canassignpatientemployee: editObj.canassignpatientemployee,
                canassignpatientsector: editObj.canassignpatientsector,
                canprovidepatientstudy: editObj.canprovidepatientstudy,
                canreceivepatientstudy: editObj.canreceivepatientstudy,
                canassignpatientbacktoentity: editObj.canassignpatientbacktoentity,

                // HR 
                canassignhrgm: editObj.canassignhrgm,
                cansubmitreviewhrrequest: editObj.cansubmitreviewhrrequest,
                canviewreviewhrrequest: editObj.canviewreviewhrrequest,
                caneditreviewhrrequest: editObj.caneditreviewhrrequest,
                canupdatereviewhrstatus: editObj.canupdatereviewhrstatus,
                canaddreviewhrcomments: editObj.canaddreviewhrcomments,
                canshowreviewhrcomments: editObj.canshowreviewhrcomments,
                canassignhrdepartment: editObj.canassignhrdepartment,
                canassignhrsector: editObj.canassignhrsector,
                canassignhremployee: editObj.canassignhremployee,
                canprovidehrstudy: editObj.canprovidehrstudy,
                canreceivehrstudy: editObj.canreceivehrstudy,
                canassignhrbacktoentity: editObj.canassignhrbacktoentity,

                // AD housing
                canassignhousinggm: editObj.canassignhousinggm,
                cansubmitadhousingrequest: editObj.cansubmitadhousingrequest,
                canviewadhousingrequest: editObj.canviewadhousingrequest,
                caneditadhousingrequest: editObj.caneditadhousingrequest,
                canupdateadhousingstatus: editObj.canupdateadhousingstatus,
                canaddadhousingcomments: editObj.canaddadhousingcomments,
                canshowadhousingcomments: editObj.canshowadhousingcomments,
                canassignhousingdepartment: editObj.canassignhousingdepartment,
                canassignhousingemployee: editObj.canassignhousingemployee,
                canassignhousingsector: editObj.canassignhousingsector,
                canprovidehousingstudy: editObj.canprovidehousingstudy,
                canreceivehousingstudy: editObj.canreceivehousingstudy,
                canassignhousingbacktoentity: editObj.canassignhousingbacktoentity,

                // Secondment
                canassignsecondmentgm: editObj.canassignsecondmentgm,
                cansubmitsecondmentrequest: editObj.cansubmitsecondmentrequest,
                canviewsecondmentrequest: editObj.canviewsecondmentrequest,
                caneditsecondmentrequest: editObj.caneditsecondmentrequest,
                canupdatesecondmentstatus: editObj.canupdatesecondmentstatus,
                canaddsecondmentcomments: editObj.canaddsecondmentcomments,
                canassignsecondmentdepartment: editObj.canassignsecondmentdepartment,
                canassignsecondmetnemployee: editObj.canassignsecondmetnemployee,
                canassignsecondmentsector: editObj.canassignsecondmentsector,
                canprovidesecondmentstudy: editObj.canprovidesecondmentstudy,
                canreceivesecondmentstudy: editObj.canreceivesecondmentstudy,
                canshowsecondmentcomments: editObj.canshowsecondmentcomments,
                canassignsecondmentbacktoentity: editObj.canassignsecondmentbacktoentity,

                // Bonus
                canassignbonusgm: editObj.canassignbonusgm,
                cansubmitbonusrequest: editObj.cansubmitbonusrequest,
                canviewbonusrequest: editObj.canviewbonusrequest,
                caneditbonusrequest: editObj.caneditbonusrequest,
                canupdatebonusstatus: editObj.canupdatebonusstatus,
                canaddbonuscomments: editObj.canaddbonuscomments,
                canshowbonuscomments: editObj.canshowbonuscomments,
                canassignbounsdepartment: editObj.canassignbounsdepartment,
                canassignbounsemployee: editObj.canassignbounsemployee,
                canassignbonussector: editObj.canassignbonussector,
                canprovidebonusstudy: editObj.canprovidebonusstudy,
                canreceivebonusstudy: editObj.canreceivebonusstudy,
                canassignbonusbacktoentity: editObj.canassignbonusbacktoentity,

                // Amend Salary scale
                canassignamendscalegm: editObj.canassignamendscalegm,
                cansubmitamendscalerequest: editObj.cansubmitamendscalerequest,
                canviewamendscalerequest: editObj.canviewamendscalerequest,
                caneditamendscalerequest: editObj.caneditamendscalerequest,
                canupdateamendscalestatus: editObj.canupdateamendscalestatus,
                canaddamendscalecomments: editObj.canaddamendscalecomments,
                canshowamendscalecomments: editObj.canshowamendscalecomments,
                canassignamendscaledepartment: editObj.canassignamendscaledepartment,
                canassignamendscaleemployee: editObj.canassignamendscaleemployee,
                canassignamendscalesector: editObj.canassignamendscalesector,
                canprovideamendscalestudy: editObj.canprovideamendscalestudy,
                canreceiveamendscalestudy: editObj.canreceiveamendscalestudy,
                canassignamendscalebacktoentity: editObj.canassignamendscalebacktoentity,

                // Amend employee
                canassignamendsalarygm: editObj.canassignamendsalarygm,
                cansubmitamendsalaryrequest: editObj.cansubmitamendsalaryrequest,
                canviewamendsalaryrequest: editObj.canviewamendsalaryrequest,
                caneditamendsalaryrequest: editObj.caneditamendsalaryrequest,
                canupdateamendsalarystatus: editObj.canupdateamendsalarystatus,
                canaddamendsalarycomments: editObj.canaddamendsalarycomments,
                canshowamendsalarycomments: editObj.canshowamendsalarycomments,
                canassignamendsalarydepartment: editObj.canassignamendsalarydepartment,
                canassignamendsalaryemployee: editObj.canassignamendsalaryemployee,
                canassignamendsalarysector: editObj.canassignamendsalarysector,
                canprovideamendsalarystudy: editObj.canprovideamendsalarystudy,
                canreceiveamendsalarystudy: editObj.canreceiveamendsalarystudy,
                canassignamendsalarybacktoentity: editObj.canassignamendsalarybacktoentity,

                // Retirment
                canassignretirementgm: editObj.canassignretirementgm,
                cansubmitretirementrequest: editObj.cansubmitretirementrequest,
                canviewretirementrequest: editObj.canviewretirementrequest,
                caneditretirementrequest: editObj.caneditretirementrequest,
                canupdateretirementstatus: editObj.canupdateretirementstatus,
                canaddretirementcomments: editObj.canaddretirementcomments,
                canshowretirementcomments: editObj.canshowretirementcomments,
                canassignretirementdepartment: editObj.canassignretirementdepartment,
                canassignretirementemployees: editObj.canassignretirementemployees,
                canassignretirementsector: editObj.canassignretirementsector,
                canprovideretirementstudy: editObj.canprovideretirementstudy,
                canreceiveretirementstudy: editObj.canreceiveretirementstudy,
                canassignretirementbacktoentity: editObj.canassignretirementbacktoentity,

                // extend create objective
                cansubmitextendcreaterequest: editObj.cansubmitextendcreaterequest,
                canviewextendcreaterequest: editObj.canviewextendcreaterequest,
                caneditextendcreaterequest: editObj.caneditextendcreaterequest,
                canupdateextendcreatestatus: editObj.canupdateextendcreatestatus,
                canshowextendcreatecomments: editObj.canshowextendcreatecomments,
                canaddextendcreatecomments: editObj.canaddextendcreatecomments,
                canprovideextendcreatestudy: editObj.canprovideextendcreatestudy,
                canreceiveextendcreatestudy: editObj.canreceiveextendcreatestudy,
                canassignextendcreatesector: editObj.canassignextendcreatesector,
                canassignextendcreatedepartment: editObj.canassignextendcreatedepartment,
                canassignextendcreateemployee: editObj.canassignextendcreateemployee,
                canassignextendcreatebacktodepartment: editObj.canassignextendcreatebacktodepartment,
                canassignextendcreatebacktoentity: editObj.canassignextendcreatebacktoentity,
                canassignextendcreaterequesttogm: editObj.canassignextendcreaterequesttogm,

                // exten review objective
                cansubmitextendreviewrequest: editObj.cansubmitextendreviewrequest,
                canviewextendreviewrequest: editObj.canviewextendreviewrequest,
                caneditextendreviewrequest: editObj.caneditextendreviewrequest,
                canupdateextendreviewstatus: editObj.canupdateextendreviewstatus,
                canshowextendreviewcomments: editObj.canshowextendreviewcomments,
                canaddextendreviewcomments: editObj.canaddextendreviewcomments,
                canprovideextendreviewstudy: editObj.canprovideextendreviewstudy,
                canreceiveextendreviewstudy: editObj.canreceiveextendreviewstudy,
                canassignextendreviewsector: editObj.canassignextendreviewsector,
                canassignextendreviewdepartment: editObj.canassignextendreviewdepartment,
                canassignextendreviewemployee: editObj.canassignextendreviewemployee,
                canassignextendreviewbacktodepartment: editObj.canassignextendreviewbacktodepartment,
                canassignextendreviewbacktoentity: editObj.canassignextendreviewbacktoentity,
                canassignextendreviewrequesttogm: editObj.canassignextendreviewrequesttogm,

                // extend annual
                cansubmitextendannualrequest: editObj.cansubmitextendannualrequest,
                canviewextendannualrequest: editObj.canviewextendannualrequest,
                caneditextendannualrequest: editObj.caneditextendannualrequest,
                canupdateextendannualstatus: editObj.canupdateextendannualstatus,
                canshowextendannualcomments: editObj.canshowextendannualcomments,
                canaddextendannualcomments: editObj.canaddextendannualcomments,
                canprovideextendannualstudy: editObj.canprovideextendannualstudy,
                canreceiveextendannualstudy: editObj.canreceiveextendannualstudy,
                canassignextendannualsector: editObj.canassignextendannualsector,
                canassignextendannualdepartment: editObj.canassignextendannualdepartment,
                canassignextendannualemployee: editObj.canassignextendannualemployee,
                canassignextendannualbacktodepartment: editObj.canassignextendannualbacktodepartment,
                canassignextendannualbacktoentity: editObj.canassignextendannualbacktoentity,
                canassignextendannualrequesttogm: editObj.canassignextendannualrequesttogm,

                // add and edit employee performance
                cansubmitemployeeperformancerequest: editObj.cansubmitemployeeperformancerequest,
                canviewemployeeperformancerequest: editObj.canviewemployeeperformancerequest,
                caneditemployeeperformancerequest: editObj.caneditemployeeperformancerequest,
                canupdateemployeeperformancestatus: editObj.canupdateemployeeperformancestatus,
                canshowemployeeperformancecomments: editObj.canshowemployeeperformancecomments,
                canaddemployeeperformancecomments: editObj.canaddemployeeperformancecomments,
                canprovideemployeeperformancestudy: editObj.canprovideemployeeperformancestudy,
                canreceiveemployeeperformancestudy: editObj.canreceiveemployeeperformancestudy,
                canassignemployeeperformancesector: editObj.canassignemployeeperformancesector,
                canassignemployeeperformancedepartment: editObj.canassignemployeeperformancedepartment,
                canassignemployeeperformanceemployee: editObj.canassignemployeeperformanceemployee,
                canassignemployeeperformancebacktodepartment: editObj.canassignemployeeperformancebacktodepartment,
                canassignemployeeperformancebacktoentity: editObj.canassignemployeeperformancebacktoentity,
                canassignemployeeperformancerequesttogm: editObj.canassignemployeeperformancerequesttogm,

                // join and implement
                cansubmitjoinimplementrequest: editObj.cansubmitjoinimplementrequest,
                canviewjoinimplementrequest: editObj.canviewjoinimplementrequest,
                caneditjoinimplementrequest: editObj.caneditjoinimplementrequest,
                canupdatejoinimplementstatus: editObj.canupdatejoinimplementstatus,
                canshowjoinimplementcomments: editObj.canshowjoinimplementcomments,
                canaddjoinimplementcomments: editObj.canaddjoinimplementcomments,
                canprovidejoinimplementstudy: editObj.canprovidejoinimplementstudy,
                canreceivejoinimplementstudy: editObj.canreceivejoinimplementstudy,
                canassignjoinimplementsector: editObj.canassignjoinimplementsector,
                canassignjoinimplementdepartment: editObj.canassignjoinimplementdepartment,
                canassignjoinimplementemployee: editObj.canassignjoinimplementemployee,
                canassignjoinimplementbacktodepartment: editObj.canassignjoinimplementbacktodepartment,
                canassignjoinimplementbacktoentity: editObj.canassignjoinimplementbacktoentity,
                canassignjoinimplementrequesttogm: editObj.canassignjoinimplementrequesttogm,

                // open 
                cansubmitopencomplainsrequest: editObj.cansubmitopencomplainsrequest,
                canviewopencomplainsrequest: editObj.canviewopencomplainsrequest,
                caneditopencomplainsrequest: editObj.caneditopencomplainsrequest,
                canupdateopencomplainsstatus: editObj.canupdateopencomplainsstatus,
                canshowopencomplainscomments: editObj.canshowopencomplainscomments,
                canaddopencomplainscomments: editObj.canaddopencomplainscomments,
                canprovideopencomplainsstudy: editObj.canprovideopencomplainsstudy,
                canreceiveopencomplainsstudy: editObj.canreceiveopencomplainsstudy,
                canassignopencomplainssector: editObj.canassignopencomplainssector,
                canassignopencomplainsdepartment: editObj.canassignopencomplainsdepartment,
                canassignopencomplainsemployee: editObj.canassignopencomplainsemployee,
                canassignopencomplainsbacktodepartment: editObj.canassignopencomplainsbacktodepartment,
                canassignopencomplainsbacktoentity: editObj.canassignopencomplainsbacktoentity,
                canassignopencomplainsrequesttogm: editObj.canassignopencomplainsrequesttogm,

                // amend systemcansubmitamendsystemsrequest
                cansubmitamendsystemsrequest: editObj.cansubmitamendsystemsrequest,
                canviewamendsystemsrequest: editObj.canviewamendsystemsrequest,
                caneditamendsystemsrequest: editObj.caneditamendsystemsrequest,
                canupdateamendsystemsstatus: editObj.canupdateamendsystemsstatus,
                canshowamendsystemscomments: editObj.canshowamendsystemscomments,
                canaddamendsystemscomments: editObj.canaddamendsystemscomments,
                canprovideamendsystemsstudy: editObj.canprovideamendsystemsstudy,
                canreceiveamendsystemsstudy: editObj.canreceiveamendsystemsstudy,
                canassignamendsystemssector: editObj.canassignamendsystemssector,
                canassignamendsystemsdepartment: editObj.canassignamendsystemsdepartment,
                canassignamendsystemsemployee: editObj.canassignamendsystemsemployee,
                canassignamendsystemsbacktodepartment: editObj.canassignamendsystemsbacktodepartment,
                canassignamendsystemsbacktoentity: editObj.canassignamendsystemsbacktoentity,
                canassignamendsystemsrequesttogm: editObj.canassignamendsystemsrequesttogm,

                // report and static
                cansubmitreportsstaticsrequest: editObj.cansubmitreportsstaticsrequest,
                canviewreportsstaticsrequest: editObj.canviewreportsstaticsrequest,
                caneditreportsstaticsrequest: editObj.caneditreportsstaticsrequest,
                canupdatereportsstaticsstatus: editObj.canupdatereportsstaticsstatus,
                canshowreportsstaticscomments: editObj.canshowreportsstaticscomments,
                canaddreportsstaticscomments: editObj.canaddreportsstaticscomments,
                canprovidereportsstaticsstudy: editObj.canprovidereportsstaticsstudy,
                canreceivereportsstaticsstudy: editObj.canreceivereportsstaticsstudy,
                canassignreportsstaticssector: editObj.canassignreportsstaticssector,
                canassignreportsstaticsdepartment: editObj.canassignreportsstaticsdepartment,
                canassignreportsstaticsemployee: editObj.canassignreportsstaticsemployee,
                canassignreportsstaticsbacktodepartment: editObj.canassignreportsstaticsbacktodepartment,
                canassignreportsstaticsbacktoentity: editObj.canassignreportsstaticsbacktoentity,
                canassignreportsstaticsrequesttogm: editObj.canassignreportsstaticsrequesttogm,

                // other request 
                cansubmitotherrequestsrequest: editObj.cansubmitotherrequestsrequest,
                canviewotherrequestsrequest: editObj.canviewotherrequestsrequest,
                caneditotherrequestsrequest: editObj.caneditotherrequestsrequest,
                canupdateotherrequestsstatus: editObj.canupdateotherrequestsstatus,
                canshowotherrequestscomments: editObj.canshowotherrequestscomments,
                canaddotherrequestscomments: editObj.canaddotherrequestscomments,
                canprovideotherrequestsstudy: editObj.canprovideotherrequestsstudy,
                canreceiveotherrequestsstudy: editObj.canreceiveotherrequestsstudy,
                canassignotherrequestssector: editObj.canassignotherrequestssector,
                canassignotherrequestsdepartment: editObj.canassignotherrequestsdepartment,
                canassignotherrequestsemployee: editObj.canassignotherrequestsemployee,
                canassignotherrequestsbacktodepartment: editObj.canassignotherrequestsbacktodepartment,
                canassignotherrequestsbacktoentity: editObj.canassignotherrequestsbacktoentity,
                canassignotherrequesttogmsrequesttogm: editObj.canassignotherrequesttogmsrequesttogm

            }, () => {
                Config.setIsEditFlag(false);
            })
        }

    }

    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    submitHandler = event => {

        event.preventDefault();
        event.target.className += " was-validated";

        let roleData = {};

        let adminPermissionData = {};
        let reportPermissionData={};
        let closerequestData={};

  let WorkShopData={};
  let DisciplinaryboardData={};
  let OHIData={};
        
        let canViewEmployeeReportData={};
        let canGenerateAllreportsData={};
        let sciRequestPermissionData = {};

        // organization structure 
        let orgRequestPermissionData = {};

        let infoRequestPermissionData = {};
        let exmAccompanyPatientPermissionData = {};
        let reviewHrPolicyPermissionData = {};
        let exmADHousingPermissionData = {};
        let secndmentForFedralAgencyPermissionData = {};
        let bonusRequestPermissionData = {};
        let amendSalaryScalePermissionData = {};
        let amendEmpSalaryPermissionData = {};
        let retirementPermissionData = {};

        //data services
        let extendCreateObjectivesData = {
            cansubmitextendcreaterequest: 0,
            canviewextendcreaterequest: 0,
            caneditextendcreaterequest: 0,
            canupdateextendcreatestatus: 0,
            canshowextendcreatecomments: 0,
            canaddextendcreatecomments: 0,
            canprovideextendcreatestudy: 0,
            canreceiveextendcreatestudy: 0,
            canassignextendcreatesector: 0,
            canassignextendcreatedepartment: 0,
            canassignextendcreateemployee: 0,
            canassignextendcreatebacktodepartment: 0,
            canassignextendcreatebacktoentity: 0,
            canassignextendcreaterequesttogm: 0
        };

        let extendReviewObjectivesData = {
            cansubmitextendreviewrequest: 0,
            canviewextendreviewrequest: 0,
            caneditextendreviewrequest: 0,
            canupdateextendreviewstatus: 0,
            canshowextendreviewcomments: 0,
            canaddextendreviewcomments: 0,
            canprovideextendreviewstudy: 0,
            canreceiveextendreviewstudy: 0,
            canassignextendreviewsector: 0,
            canassignextendreviewdepartment: 0,
            canassignextendreviewemployee: 0,
            canassignextendreviewbacktodepartment: 0,
            canassignextendreviewbacktoentity: 0,
            canassignextendreviewrequesttogm: 0
        };
        let extendAnnualObjectivesData = {
            cansubmitextendannualrequest: 0,
            canviewextendannualrequest: 0,
            caneditextendannualrequest: 0,
            canupdateextendannualstatus: 0,
            canshowextendannualcomments: 0,
            canaddextendannualcomments: 0,
            canprovideextendannualstudy: 0,
            canreceiveextendannualstudy: 0,
            canassignextendannualsector: 0,
            canassignextendannualdepartment: 0,
            canassignextendannualemployee: 0,
            canassignextendannualbacktodepartment: 0,
            canassignextendannualbacktoentity: 0,
            canassignextendannualrequesttogm: 0
        };

        let addOrEditEmpPerfData = {
            cansubmitemployeeperformancerequest: 0,
            canviewemployeeperformancerequest: 0,
            caneditemployeeperformancerequest: 0,
            canupdateemployeeperformancestatus: 0,
            canshowemployeeperformancecomments: 0,
            canaddemployeeperformancecomments: 0,
            canprovideemployeeperformancestudy: 0,
            canreceiveemployeeperformancestudy: 0,
            canassignemployeeperformancesector: 0,
            canassignemployeeperformancedepartment: 0,
            canassignemployeeperformanceemployee: 0,
            canassignemployeeperformancebacktodepartment: 0,
            canassignemployeeperformancebacktoentity: 0,
            canassignemployeeperformancerequesttogm: 0
        };

        let joinAndImplementData = {
            cansubmitjoinimplementrequest: 0,
            canviewjoinimplementrequest: 0,
            caneditjoinimplementrequest: 0,
            canupdatejoinimplementstatus: 0,
            canshowjoinimplementcomments: 0,
            canaddjoinimplementcomments: 0,
            canprovidejoinimplementstudy: 0,
            canreceivejoinimplementstudy: 0,
            canassignjoinimplementsector: 0,
            canassignjoinimplementdepartment: 0,
            canassignjoinimplementemployee: 0,
            canassignjoinimplementbacktodepartment: 0,
            canassignjoinimplementbacktoentity: 0,
            canassignjoinimplementrequesttogm: 0
        };

        let openComplainsData = {
            cansubmitopencomplainsrequest: 0,
            canviewopencomplainsrequest: 0,
            caneditopencomplainsrequest: 0,
            canupdateopencomplainsstatus: 0,
            canshowopencomplainscomments: 0,
            canaddopencomplainscomments: 0,
            canprovideopencomplainsstudy: 0,
            canreceiveopencomplainsstudy: 0,
            canassignopencomplainssector: 0,
            canassignopencomplainsdepartment: 0,
            canassignopencomplainsemployee: 0,
            canassignopencomplainsbacktodepartment: 0,
            canassignopencomplainsbacktoentity: 0,
            canassignopencomplainsrequesttogm: 0
        }

        let amendSystemData = {
            cansubmitamendsystemsrequest: 0,
            canviewamendsystemsrequest: 0,
            caneditamendsystemsrequest: 0,
            canupdateamendsystemsstatus: 0,
            canshowamendsystemscomments: 0,
            canaddamendsystemscomments: 0,
            canprovideamendsystemsstudy: 0,
            canreceiveamendsystemsstudy: 0,
            canassignamendsystemssector: 0,
            canassignamendsystemsdepartment: 0,
            canassignamendsystemsemployee: 0,
            canassignamendsystemsbacktodepartment: 0,
            canassignamendsystemsbacktoentity: 0,
            canassignamendsystemsrequesttogm: 0
        }

        let reportStatisticData = {
            cansubmitreportsstaticsrequest: 0,
            canviewreportsstaticsrequest: 0,
            caneditreportsstaticsrequest: 0,
            canupdatereportsstaticsstatus: 0,
            canshowreportsstaticscomments: 0,
            canaddreportsstaticscomments: 0,
            canprovidereportsstaticsstudy: 0,
            canreceivereportsstaticsstudy: 0,
            canassignreportsstaticssector: 0,
            canassignreportsstaticsdepartment: 0,
            canassignreportsstaticsemployee: 0,
            canassignreportsstaticsbacktodepartment: 0,
            canassignreportsstaticsbacktoentity: 0,
            canassignreportsstaticsrequesttogm: 0
        }

        let otherRequestData = {
            cansubmitotherrequestsrequest: 0,
            canviewotherrequestsrequest: 0,
            caneditotherrequestsrequest: 0,
            canupdateotherrequestsstatus: 0,
            canshowotherrequestscomments: 0,
            canaddotherrequestscomments: 0,
            canprovideotherrequestsstudy: 0,
            canreceiveotherrequestsstudy: 0,
            canassignotherrequestssector: 0,
            canassignotherrequestsdepartment: 0,
            canassignotherrequestsemployee: 0,
            canassignotherrequestsbacktodepartment: 0,
            canassignotherrequestsbacktoentity: 0,
            canassignotherrequesttogmsrequesttogm: 0
        }

        if (this.state.englishTitleValue !== '' && this.state.arabicTitle !== '') {

            if (this.state.isadmin) {
                adminPermissionData.isadmin = 1;
            } else {
                adminPermissionData.isadmin = 0;
            }

            roleData.adminPermissionData = adminPermissionData;
if(this.state.canviewreport)
{
    reportPermissionData.canviewreport=1;
}
else
{
    reportPermissionData.canviewreport=0;
}
roleData.reportPermissionData=reportPermissionData;

if(this.state.canCreateWorkShop)
{
WorkShopData.canCreateWorkShop=1;
}
else{
    WorkShopData.canCreateWorkShop=0;
}
if(this.state.canBookWorkShop)
{
WorkShopData.canBookWorkShop=1;
}
else{
    WorkShopData.canBookWorkShop=0;
    
}
roleData.WorkShopData=WorkShopData;

if (this.state.canViewDisciplinaryboard) {
    DisciplinaryboardData.canViewDisciplinaryboard = 1;
}
else {
    DisciplinaryboardData.canViewDisciplinaryboard = 0;
}

if (this.state.canCreateDisciplinaryboard) {
    DisciplinaryboardData.canCreateDisciplinaryboard = 1;
}
else {
    DisciplinaryboardData.canCreateDisciplinaryboard = 0;
}

if (this.state.canEditDisciplinaryboard) {
    DisciplinaryboardData.canEditDisciplinaryboard = 1;
}
else {
    DisciplinaryboardData.canEditDisciplinaryboard = 0;
}

if (this.state.canAssignDisciplinaryboardToDGoffice) {
    DisciplinaryboardData.canAssignDisciplinaryboardToDGoffice = 1;
}
else {
    DisciplinaryboardData.canAssignDisciplinaryboardToDGoffice = 0;
}

if (this.state.canAssignDisciplinaryboardtoEmployee) {
    DisciplinaryboardData.canAssignDisciplinaryboardtoEmployee = 1;
}
else {
    DisciplinaryboardData.canAssignDisciplinaryboardtoEmployee = 0;
}

if (this.state.canAddReplayDisciplinaryboard) {
    DisciplinaryboardData.canAddReplayDisciplinaryboard = 1;
}
else {
    DisciplinaryboardData.canAddReplayDisciplinaryboard = 0;
}


roleData.DisciplinaryboardData=DisciplinaryboardData;

if(this.state.canViewOHI)
{
OHIData.canViewOHI=1;
}
else{
    OHIData.canViewOHI=0;
}

roleData.OHIData=OHIData;



if(this.state.canCloseRequest)
        {
            closerequestData.canCloseRequest=1;
        }
        else
        {
            closerequestData.canCloseRequest=0;
        }
     
roleData.closerequestData=closerequestData;
   if(this.state.canViewEmployeeReport)
    {
        canViewEmployeeReportData.canViewEmployeeReport=1;
    }
    else
    {
        canViewEmployeeReportData.canViewEmployeeReport=0;
    }

roleData.canViewEmployeeReportData=canViewEmployeeReportData;

if(this.state.canGenerateAllreports)
    {
        canGenerateAllreportsData.canGenerateAllreports=1;
    }
    else
    {
        canGenerateAllreportsData.canGenerateAllreports=0;
    }

roleData.canGenerateAllreportsData=canGenerateAllreportsData;
            //cansubmitscirequest
            if (this.state.cansubmitscirequest) {
                sciRequestPermissionData.cansubmitscirequest = 1
            }
            else {
                sciRequestPermissionData.cansubmitscirequest = 0
            }

            //canreviewscirequest
            if (this.state.canreviewscirequest) {
                sciRequestPermissionData.canreviewscirequest = 1
            }
            else {
                sciRequestPermissionData.canreviewscirequest = 0

            }

            // caneditscirequest
            if (this.state.caneditscirequest) {
                sciRequestPermissionData.caneditscirequest = 1
            }
            else {
                sciRequestPermissionData.caneditscirequest = 0
            }

            // canupdatescistatus
            if (this.state.canupdatescistatus) {
                sciRequestPermissionData.canupdatescistatus = 1
            }
            else {
                sciRequestPermissionData.canupdatescistatus = 0
            }

            // canaddscicomment
            if (this.state.canaddscicomment) {
                sciRequestPermissionData.canaddscicomment = 1
            }
            else {
                sciRequestPermissionData.canaddscicomment = 0
            }

            // canassignscidepartment
            if (this.state.canassignscidepartment) {
                sciRequestPermissionData.canassignscidepartment = 1
            }
            else {
                sciRequestPermissionData.canassignscidepartment = 0
            }

            if (this.state.canassignscibacktoentity) {
                sciRequestPermissionData.canassignscibacktoentity = 1
            }
            else {
                sciRequestPermissionData.canassignscibacktoentity = 0
            }

            // canasignsciemployee
            if (this.state.canasignsciemployee) {
                sciRequestPermissionData.canasignsciemployee = 1
            }
            else {
                sciRequestPermissionData.canasignsciemployee = 0
            }

            if (this.state.canassignscigm) {
                sciRequestPermissionData.canassignscigm = 1
            }
            else {
                sciRequestPermissionData.canassignscigm = 0
            }

            // canprovidescifeedback
            if (this.state.canprovidescifeedback) {
                sciRequestPermissionData.canprovidescifeedback = 1
            }
            else {
                sciRequestPermissionData.canprovidescifeedback = 0
            }

            // canviewscifeedback
            if (this.state.canviewscifeedback) {
                sciRequestPermissionData.canviewscifeedback = 1
            }
            else {
                sciRequestPermissionData.canviewscifeedback = 0
            }

            // canassignscisector
            if (this.state.canassignscisector) {
                sciRequestPermissionData.canassignscisector = 1
            }
            else {
                sciRequestPermissionData.canassignscisector = 0
            }

            //canshowscicomments
            if (this.state.canshowscicomments) {
                sciRequestPermissionData.canshowscicomments = 1
            }
            else {
                sciRequestPermissionData.canshowscicomments = 0
            }

            roleData.sciRequestPermissionData = sciRequestPermissionData;

            // organization structure 

             //cansubmitorgrequest
             if (this.state.cansubmitorgrequest) {
                orgRequestPermissionData.cansubmitorgrequest = 1
            }
            else {
                orgRequestPermissionData.cansubmitorgrequest = 0
            }
         

            if (this.state.canopencreationstage) {
                orgRequestPermissionData.canopencreationstage = 1
            }
            else {
                orgRequestPermissionData.canopencreationstage = 0
            }
            //canvieworgrequest
            if (this.state.canvieworgrequest) {
                orgRequestPermissionData.canvieworgrequest = 1
            }
            else {
                orgRequestPermissionData.canvieworgrequest = 0

            }
            if (this.state.canviewtree) {
                orgRequestPermissionData.canviewtree = 1
            }
            else {
                orgRequestPermissionData.canviewtree = 0

            }
            // caneditorgrequest
            if (this.state.caneditorgrequest) {
                orgRequestPermissionData.caneditorgrequest = 1
            }
            else {
                orgRequestPermissionData.caneditorgrequest = 0
            }

            // canupdateorgstatus
            if (this.state.canupdateorgstatus) {
                orgRequestPermissionData.canupdateorgstatus = 1
            }
            else {
                orgRequestPermissionData.canupdateorgstatus = 0
            }

            // canaddorgcomment
            if (this.state.canaddorgcomment) {
                orgRequestPermissionData.canaddorgcomment = 1
            }
            else {
                orgRequestPermissionData.canaddorgcomment = 0
            }

            // canassignorgdepartment
            if (this.state.canassignorgdepartment) {
                orgRequestPermissionData.canassignorgdepartment = 1
            }
            else {
                orgRequestPermissionData.canassignorgdepartment = 0
            }

            if (this.state.canassignorgbacktoentity) {
                orgRequestPermissionData.canassignorgbacktoentity = 1
            }
            else {
                orgRequestPermissionData.canassignorgbacktoentity = 0
            }

            // canasignorgemployee
            if (this.state.canasignorgemployee) {
                orgRequestPermissionData.canasignorgemployee = 1
            }
            else {
                orgRequestPermissionData.canasignorgemployee = 0
            }

            if (this.state.canassignorggm) {
                orgRequestPermissionData.canassignorggm = 1
            }
            else {
                orgRequestPermissionData.canassignorggm = 0
            }

            // canprovideorgfeedback
            if (this.state.canprovideorgfeedback) {
                orgRequestPermissionData.canprovideorgfeedback = 1
            }
            else {
                orgRequestPermissionData.canprovideorgfeedback = 0
            }

            // canvieworgfeedback
            if (this.state.canvieworgfeedback) {
                orgRequestPermissionData.canvieworgfeedback = 1
            }
            else {
                orgRequestPermissionData.canvieworgfeedback = 0
            }

            // canassignorgsector
            if (this.state.canassignorgsector) {
                orgRequestPermissionData.canassignorgsector = 1
            }
            else {
                orgRequestPermissionData.canassignorgsector = 0
            }

            //canshoworgcomments
            if (this.state.canshoworgcomments) {
                orgRequestPermissionData.canshoworgcomments = 1
            }
            else {
                orgRequestPermissionData.canshoworgcomments = 0
            }


            roleData.orgRequestPermissionData = orgRequestPermissionData;



            // cansubmitirrequest
            if (this.state.cansubmitirrequest) {
                infoRequestPermissionData.cansubmitirrequest = 1
            }
            else {
                infoRequestPermissionData.cansubmitirrequest = 0
            }

            // canviewirrequest
            if (this.state.canviewirrequest) {
                infoRequestPermissionData.canviewirrequest = 1
            }
            else {
                infoRequestPermissionData.canviewirrequest = 0
            }

            // canshowircomments
            if (this.state.canshowircomments) {
                infoRequestPermissionData.canshowircomments = 1
            }
            else {
                infoRequestPermissionData.canshowircomments = 0
            }

            // caneditirrequest
            if (this.state.caneditirrequest) {
                infoRequestPermissionData.caneditirrequest = 1
            }
            else {
                infoRequestPermissionData.caneditirrequest = 0
            }

            // canupdateirstatus
            if (this.state.canupdateirstatus) {
                infoRequestPermissionData.canupdateirstatus = 1
            }
            else {
                infoRequestPermissionData.canupdateirstatus = 0
            }

            // canaddircomments
            if (this.state.canaddircomments) {
                infoRequestPermissionData.canaddircomments = 1
            }
            else {
                infoRequestPermissionData.canaddircomments = 0
            }

            // canassignirdepartment
            if (this.state.canassignirdepartment) {
                infoRequestPermissionData.canassignirdepartment = 1
            }
            else {
                infoRequestPermissionData.canassignirdepartment = 0
            }

            if (this.state.canassignirbacktoentity) {
                infoRequestPermissionData.canassignirbacktoentity = 1
            }
            else {
                infoRequestPermissionData.canassignirbacktoentity = 0
            }

            if (this.state.canassignirgm) {
                infoRequestPermissionData.canassignirgm = 1
            }
            else {
                infoRequestPermissionData.canassignirgm = 0
            }

            // canAddInfoReqFeedback
            if (this.state.canAddInfoReqFeedback) {
                infoRequestPermissionData.canAddInfoReqFeedback = 1
            }
            else {
                infoRequestPermissionData.canAddInfoReqFeedback = 0
            }

            //canassignirsector
            if (this.state.canassignirsector) {
                infoRequestPermissionData.canassignirsector = 1
            }
            else {
                infoRequestPermissionData.canassignirsector = 0
            }

            //canreceiveirfeedback 
            if (this.state.canreceiveirfeedback) {
                infoRequestPermissionData.canreceiveirfeedback = 1
            }
            else {
                infoRequestPermissionData.canreceiveirfeedback = 0
            }

            // canprovideirfeedback
            if (this.state.canprovideirfeedback) {
                infoRequestPermissionData.canprovideirfeedback = 1
            }
            else {
                infoRequestPermissionData.canprovideirfeedback = 0
            }

            roleData.infoRequestPermissionData = infoRequestPermissionData;

            // cansubmitpatientrequest
            if (this.state.cansubmitpatientrequest) {
                exmAccompanyPatientPermissionData.cansubmitpatientrequest = 1
            }
            else {
                exmAccompanyPatientPermissionData.cansubmitpatientrequest = 0
            }

            // canviewpatientrequest
            if (this.state.canviewpatientrequest) {
                exmAccompanyPatientPermissionData.canviewpatientrequest = 1
            }
            else {
                exmAccompanyPatientPermissionData.canviewpatientrequest = 0
            }

            // caneditpatientrequest
            if (this.state.caneditpatientrequest) {
                exmAccompanyPatientPermissionData.caneditpatientrequest = 1
            }
            else {
                exmAccompanyPatientPermissionData.caneditpatientrequest = 0
            }

            // canupdatepatientstatus
            if (this.state.canupdatepatientstatus) {
                exmAccompanyPatientPermissionData.canupdatepatientstatus = 1
            }
            else {
                exmAccompanyPatientPermissionData.canupdatepatientstatus = 0
            }

            // canshowpatientcomments
            if (this.state.canshowpatientcomments) {
                exmAccompanyPatientPermissionData.canshowpatientcomments = 1
            }
            else {
                exmAccompanyPatientPermissionData.canshowpatientcomments = 0
            }

            // canaddpatientcomments
            if (this.state.canaddpatientcomments) {
                exmAccompanyPatientPermissionData.canaddpatientcomments = 1
            }
            else {
                exmAccompanyPatientPermissionData.canaddpatientcomments = 0
            }

            // canassignpatientdepartment
            if (this.state.canassignpatientdepartment) {
                exmAccompanyPatientPermissionData.canassignpatientdepartment = 1
            }
            else {
                exmAccompanyPatientPermissionData.canassignpatientdepartment = 0
            }

            if (this.state.canassignpatientgm) {
                exmAccompanyPatientPermissionData.canassignpatientgm = 1
            }
            else {
                exmAccompanyPatientPermissionData.canassignpatientgm = 0
            }

            if (this.state.canassignpatientbacktoentity) {
                exmAccompanyPatientPermissionData.canassignpatientbacktoentity = 1
            }
            else {
                exmAccompanyPatientPermissionData.canassignpatientbacktoentity = 0
            }

            // canassignpatientemployee
            if (this.state.canassignpatientemployee) {
                exmAccompanyPatientPermissionData.canassignpatientemployee = 1
            }
            else {
                exmAccompanyPatientPermissionData.canassignpatientemployee = 0
            }

            if (this.state.canassignpatientsector) {
                exmAccompanyPatientPermissionData.canassignpatientsector = 1
            }
            else {
                exmAccompanyPatientPermissionData.canassignpatientsector = 0
            }

            // canprovidepatientstudy
            if (this.state.canprovidepatientstudy) {
                exmAccompanyPatientPermissionData.canprovidepatientstudy = 1
            }
            else {
                exmAccompanyPatientPermissionData.canprovidepatientstudy = 0
            }

            // canreceivepatientstudy
            if (this.state.canreceivepatientstudy) {
                exmAccompanyPatientPermissionData.canreceivepatientstudy = 1
            }
            else {
                exmAccompanyPatientPermissionData.canreceivepatientstudy = 0
            }


            roleData.exmAccompanyPatientPermissionData = exmAccompanyPatientPermissionData;

            // cansubmitreviewhrrequest
            if (this.state.cansubmitreviewhrrequest) {
                reviewHrPolicyPermissionData.cansubmitreviewhrrequest = 1
            }
            else {
                reviewHrPolicyPermissionData.cansubmitreviewhrrequest = 0
            }

            // canviewreviewhrrequest
            if (this.state.canviewreviewhrrequest) {
                reviewHrPolicyPermissionData.canviewreviewhrrequest = 1
            }
            else {
                reviewHrPolicyPermissionData.canviewreviewhrrequest = 0
            }

            // caneditreviewhrrequest
            if (this.state.caneditreviewhrrequest) {
                reviewHrPolicyPermissionData.caneditreviewhrrequest = 1
            }
            else {
                reviewHrPolicyPermissionData.caneditreviewhrrequest = 0
            }

            // canupdatereviewhrstatus
            if (this.state.canupdatereviewhrstatus) {
                reviewHrPolicyPermissionData.canupdatereviewhrstatus = 1
            }
            else {
                reviewHrPolicyPermissionData.canupdatereviewhrstatus = 0
            }

            // canaddreviewhrcomments
            if (this.state.canaddreviewhrcomments) {
                reviewHrPolicyPermissionData.canaddreviewhrcomments = 1
            }
            else {
                reviewHrPolicyPermissionData.canaddreviewhrcomments = 0
            }

            // canshowreviewhrcomments
            if (this.state.canshowreviewhrcomments) {
                reviewHrPolicyPermissionData.canshowreviewhrcomments = 1
            }
            else {
                reviewHrPolicyPermissionData.canshowreviewhrcomments = 0
            }

            // canassignhrsector
            if (this.state.canassignhrsector) {
                reviewHrPolicyPermissionData.canassignhrsector = 1
            }
            else {
                reviewHrPolicyPermissionData.canassignhrsector = 0
            }

            // canassignhrdepartment
            if (this.state.canassignhrdepartment) {
                reviewHrPolicyPermissionData.canassignhrdepartment = 1
            }
            else {
                reviewHrPolicyPermissionData.canassignhrdepartment = 0
            }

            if (this.state.canassignhrgm) {
                reviewHrPolicyPermissionData.canassignhrgm = 1
            }
            else {
                reviewHrPolicyPermissionData.canassignhrgm = 0
            }

            if (this.state.canassignhrbacktoentity) {
                reviewHrPolicyPermissionData.canassignhrbacktoentity = 1
            }
            else {
                reviewHrPolicyPermissionData.canassignhrbacktoentity = 0
            }

            // canreceivehrstudy
            if (this.state.canreceivehrstudy) {
                reviewHrPolicyPermissionData.canreceivehrstudy = 1
            }
            else {
                reviewHrPolicyPermissionData.canreceivehrstudy = 0
            }

            // canprovidehrstudy
            if (this.state.canprovidehrstudy) {
                reviewHrPolicyPermissionData.canprovidehrstudy = 1
            }
            else {
                reviewHrPolicyPermissionData.canprovidehrstudy = 0
            }

            // canassignhremployee
            if (this.state.canassignhremployee) {
                reviewHrPolicyPermissionData.canassignhremployee = 1
            }
            else {
                reviewHrPolicyPermissionData.canassignhremployee = 0
            }

            roleData.reviewHrPolicyPermissionData = reviewHrPolicyPermissionData;

            // exmption ad housing 
            //cansubmitadhousingrequest
            if (this.state.cansubmitadhousingrequest) {
                exmADHousingPermissionData.cansubmitadhousingrequest = 1
            }
            else {
                exmADHousingPermissionData.cansubmitadhousingrequest = 0
            }

            //canviewadhousingrequest
            if (this.state.canviewadhousingrequest) {
                exmADHousingPermissionData.canviewadhousingrequest = 1
            }
            else {
                exmADHousingPermissionData.canviewadhousingrequest = 0
            }

            //caneditadhousingrequest
            if (this.state.caneditadhousingrequest) {
                exmADHousingPermissionData.caneditadhousingrequest = 1
            }
            else {
                exmADHousingPermissionData.caneditadhousingrequest = 0
            }

            //canupdateadhousingstatus
            if (this.state.canupdateadhousingstatus) {
                exmADHousingPermissionData.canupdateadhousingstatus = 1
            }
            else {
                exmADHousingPermissionData.canupdateadhousingstatus = 0
            }

            //canaddadhousingcomments
            if (this.state.canaddadhousingcomments) {
                exmADHousingPermissionData.canaddadhousingcomments = 1
            }
            else {
                exmADHousingPermissionData.canaddadhousingcomments = 0
            }

            //canshowadhousingcomments
            if (this.state.canshowadhousingcomments) {
                exmADHousingPermissionData.canshowadhousingcomments = 1
            }
            else {
                exmADHousingPermissionData.canshowadhousingcomments = 0
            }


            //canassignhousingdepartment
            if (this.state.canassignhousingdepartment) {
                exmADHousingPermissionData.canassignhousingdepartment = 1
            }
            else {
                exmADHousingPermissionData.canassignhousingdepartment = 0
            }

            if (this.state.canassignhousinggm) {
                exmADHousingPermissionData.canassignhousinggm = 1
            }
            else {
                exmADHousingPermissionData.canassignhousinggm = 0
            }

            if (this.state.canassignhousingbacktoentity) {
                exmADHousingPermissionData.canassignhousingbacktoentity = 1
            }
            else {
                exmADHousingPermissionData.canassignhousingbacktoentity = 0
            }

            //canassignhousingemployee
            if (this.state.canassignhousingemployee) {
                exmADHousingPermissionData.canassignhousingemployee = 1
            }
            else {
                exmADHousingPermissionData.canassignhousingemployee = 0
            }

            if (this.state.canassignhousingsector) {
                exmADHousingPermissionData.canassignhousingsector = 1
            }
            else {
                exmADHousingPermissionData.canassignhousingsector = 0
            }

            //canprovidehousingstudy
            if (this.state.canprovidehousingstudy) {
                exmADHousingPermissionData.canprovidehousingstudy = 1
            }
            else {
                exmADHousingPermissionData.canprovidehousingstudy = 0
            }

            //canreceivehousingstudy
            if (this.state.canreceivehousingstudy) {
                exmADHousingPermissionData.canreceivehousingstudy = 1
            }
            else {
                exmADHousingPermissionData.canreceivehousingstudy = 0
            }

            roleData.exmADHousingPermissionData = exmADHousingPermissionData;

            //cansubmitsecondmentrequest
            if (this.state.cansubmitsecondmentrequest) {
                secndmentForFedralAgencyPermissionData.cansubmitsecondmentrequest = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.cansubmitsecondmentrequest = 0
            }

            //canviewsecondmentrequest
            if (this.state.canviewsecondmentrequest) {
                secndmentForFedralAgencyPermissionData.canviewsecondmentrequest = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canviewsecondmentrequest = 0
            }

            //caneditsecondmentrequest
            if (this.state.caneditsecondmentrequest) {
                secndmentForFedralAgencyPermissionData.caneditsecondmentrequest = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.caneditsecondmentrequest = 0
            }

            //canupdatesecondmentstatus
            if (this.state.canupdatesecondmentstatus) {
                secndmentForFedralAgencyPermissionData.canupdatesecondmentstatus = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canupdatesecondmentstatus = 0
            }

            //canaddsecondmentcomments
            if (this.state.canaddsecondmentcomments) {
                secndmentForFedralAgencyPermissionData.canaddsecondmentcomments = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canaddsecondmentcomments = 0
            }

            //canshowsecondmentcomments
            if (this.state.canshowsecondmentcomments) {
                secndmentForFedralAgencyPermissionData.canshowsecondmentcomments = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canshowsecondmentcomments = 0
            }

            //canassignsecondmentdepartment
            if (this.state.canassignsecondmentdepartment) {
                secndmentForFedralAgencyPermissionData.canassignsecondmentdepartment = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canassignsecondmentdepartment = 0
            }

            if (this.state.canassignsecondmentgm) {
                secndmentForFedralAgencyPermissionData.canassignsecondmentgm = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canassignsecondmentgm = 0
            }

            if (this.state.canassignsecondmentbacktoentity) {
                secndmentForFedralAgencyPermissionData.canassignsecondmentbacktoentity = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canassignsecondmentbacktoentity = 0
            }

            //canassignsecondmetnemployee
            if (this.state.canassignsecondmetnemployee) {
                secndmentForFedralAgencyPermissionData.canassignsecondmetnemployee = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canassignsecondmetnemployee = 0
            }

            if (this.state.canassignsecondmentsector) {
                secndmentForFedralAgencyPermissionData.canassignsecondmentsector = 1;
            }
            else {
                secndmentForFedralAgencyPermissionData.canassignsecondmentsector = 0;
            }

            //canprovidesecondmentstudy
            if (this.state.canprovidesecondmentstudy) {
                secndmentForFedralAgencyPermissionData.canprovidesecondmentstudy = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canprovidesecondmentstudy = 0
            }

            //canreceivesecondmentstudy
            if (this.state.canreceivesecondmentstudy) {
                secndmentForFedralAgencyPermissionData.canreceivesecondmentstudy = 1
            }
            else {
                secndmentForFedralAgencyPermissionData.canreceivesecondmentstudy = 0
            }


            roleData.secndmentForFedralAgencyPermissionData = secndmentForFedralAgencyPermissionData;

            //bonus request..........
            //cansubmitbonusrequest
            if (this.state.cansubmitbonusrequest) {
                bonusRequestPermissionData.cansubmitbonusrequest = 1
            }
            else {
                bonusRequestPermissionData.cansubmitbonusrequest = 0
            }

            //canviewbonusrequest
            if (this.state.canviewbonusrequest) {
                bonusRequestPermissionData.canviewbonusrequest = 1
            }
            else {
                bonusRequestPermissionData.canviewbonusrequest = 0
            }

            //caneditbonusrequest
            if (this.state.caneditbonusrequest) {
                bonusRequestPermissionData.caneditbonusrequest = 1
            }
            else {
                bonusRequestPermissionData.caneditbonusrequest = 0
            }

            //canupdatebonusstatus
            if (this.state.canupdatebonusstatus) {
                bonusRequestPermissionData.canupdatebonusstatus = 1
            }
            else {
                bonusRequestPermissionData.canupdatebonusstatus = 0
            }

            //canaddbonuscomments
            if (this.state.canaddbonuscomments) {
                bonusRequestPermissionData.canaddbonuscomments = 1
            }
            else {
                bonusRequestPermissionData.canaddbonuscomments = 0
            }

            //canshowbonuscomments
            if (this.state.canshowbonuscomments) {
                bonusRequestPermissionData.canshowbonuscomments = 1
            }
            else {
                bonusRequestPermissionData.canshowbonuscomments = 0
            }

            //canassignbounsdepartment
            if (this.state.canassignbounsdepartment) {
                bonusRequestPermissionData.canassignbounsdepartment = 1
            }
            else {
                bonusRequestPermissionData.canassignbounsdepartment = 0
            }

            if (this.state.canassignbonusgm) {
                bonusRequestPermissionData.canassignbonusgm = 1
            }
            else {
                bonusRequestPermissionData.canassignbonusgm = 0
            }

            if (this.state.canassignbonusbacktoentity) {
                bonusRequestPermissionData.canassignbonusbacktoentity = 1
            }
            else {
                bonusRequestPermissionData.canassignbonusbacktoentity = 0
            }

            //canassignbounsemployee
            if (this.state.canassignbounsemployee) {
                bonusRequestPermissionData.canassignbounsemployee = 1
            }
            else {
                bonusRequestPermissionData.canassignbounsemployee = 0
            }

            if (this.state.canassignbonussector) {
                bonusRequestPermissionData.canassignbonussector = 1;
            }
            else {
                bonusRequestPermissionData.canassignbonussector = 0;
            }

            //canprovidebonusstudy
            if (this.state.canprovidebonusstudy) {
                bonusRequestPermissionData.canprovidebonusstudy = 1
            }
            else {
                bonusRequestPermissionData.canprovidebonusstudy = 0
            }

            //canreceivebonusstudy
            if (this.state.canreceivebonusstudy) {
                bonusRequestPermissionData.canreceivebonusstudy = 1
            }
            else {
                bonusRequestPermissionData.canreceivebonusstudy = 0
            }


            roleData.bonusRequestPermissionData = bonusRequestPermissionData;

            // amend salary scale permission
            //cansubmitamendscalerequest
            if (this.state.cansubmitamendscalerequest) {
                amendSalaryScalePermissionData.cansubmitamendscalerequest = 1
            }
            else {
                amendSalaryScalePermissionData.cansubmitamendscalerequest = 0
            }

            //canviewamendscalerequest
            if (this.state.canviewamendscalerequest) {
                amendSalaryScalePermissionData.canviewamendscalerequest = 1
            }
            else {
                amendSalaryScalePermissionData.canviewamendscalerequest = 0
            }

            //caneditamendscalerequest
            if (this.state.caneditamendscalerequest) {
                amendSalaryScalePermissionData.caneditamendscalerequest = 1
            }
            else {
                amendSalaryScalePermissionData.caneditamendscalerequest = 0
            }

            //canupdateamendscalestatus
            if (this.state.canupdateamendscalestatus) {
                amendSalaryScalePermissionData.canupdateamendscalestatus = 1
            }
            else {
                amendSalaryScalePermissionData.canupdateamendscalestatus = 0
            }

            //canaddamendscalecomments
            if (this.state.canaddamendscalecomments) {
                amendSalaryScalePermissionData.canaddamendscalecomments = 1
            }
            else {
                amendSalaryScalePermissionData.canaddamendscalecomments = 0
            }

            //canshowamendscalecomments
            if (this.state.canshowamendscalecomments) {
                amendSalaryScalePermissionData.canshowamendscalecomments = 1
            }
            else {
                amendSalaryScalePermissionData.canshowamendscalecomments = 0
            }

            //canassignamendscaledepartment
            if (this.state.canassignamendscaledepartment) {
                amendSalaryScalePermissionData.canassignamendscaledepartment = 1
            }
            else {
                amendSalaryScalePermissionData.canassignamendscaledepartment = 0
            }

            if (this.state.canassignamendscalegm) {
                amendSalaryScalePermissionData.canassignamendscalegm = 1
            }
            else {
                amendSalaryScalePermissionData.canassignamendscalegm = 0
            }

            if (this.state.canassignamendscalebacktoentity) {
                amendSalaryScalePermissionData.canassignamendscalebacktoentity = 1
            }
            else {
                amendSalaryScalePermissionData.canassignamendscalebacktoentity = 0
            }

            //canassignamendscaleemployee
            if (this.state.canassignamendscaleemployee) {
                amendSalaryScalePermissionData.canassignamendscaleemployee = 1
            }
            else {
                amendSalaryScalePermissionData.canassignamendscaleemployee = 0
            }

            if (this.state.canassignamendscalesector) {
                amendSalaryScalePermissionData.canassignamendscalesector = 1;
            }
            else {
                amendSalaryScalePermissionData.canassignamendscalesector = 0;
            }

            //canprovideamendscalestudy
            if (this.state.canprovideamendscalestudy) {
                amendSalaryScalePermissionData.canprovideamendscalestudy = 1
            }
            else {
                amendSalaryScalePermissionData.canprovideamendscalestudy = 0
            }

            //canreceiveamendscalestudy
            if (this.state.canreceiveamendscalestudy) {
                amendSalaryScalePermissionData.canreceiveamendscalestudy = 1
            }
            else {
                amendSalaryScalePermissionData.canreceiveamendscalestudy = 0
            }


            roleData.amendSalaryScalePermissionData = amendSalaryScalePermissionData;

            //cansubmitamendsalaryrequest
            if (this.state.cansubmitamendsalaryrequest) {
                amendEmpSalaryPermissionData.cansubmitamendsalaryrequest = 1
            }
            else {
                amendEmpSalaryPermissionData.cansubmitamendsalaryrequest = 0
            }

            //canviewamendsalaryrequest
            if (this.state.canviewamendsalaryrequest) {
                amendEmpSalaryPermissionData.canviewamendsalaryrequest = 1
            }
            else {
                amendEmpSalaryPermissionData.canviewamendsalaryrequest = 0
            }

            //caneditamendsalaryrequest
            if (this.state.caneditamendsalaryrequest) {
                amendEmpSalaryPermissionData.caneditamendsalaryrequest = 1
            }
            else {
                amendEmpSalaryPermissionData.caneditamendsalaryrequest = 0
            }

            //canupdateamendsalarystatus
            if (this.state.canupdateamendsalarystatus) {
                amendEmpSalaryPermissionData.canupdateamendsalarystatus = 1
            }
            else {
                amendEmpSalaryPermissionData.canupdateamendsalarystatus = 0
            }

            //canaddamendsalarycomments
            if (this.state.canaddamendsalarycomments) {
                amendEmpSalaryPermissionData.canaddamendsalarycomments = 1
            }
            else {
                amendEmpSalaryPermissionData.canaddamendsalarycomments = 0
            }

            //canshowamendsalarycomments
            if (this.state.canshowamendsalarycomments) {
                amendEmpSalaryPermissionData.canshowamendsalarycomments = 1
            }
            else {
                amendEmpSalaryPermissionData.canshowamendsalarycomments = 0
            }

            //canassignamendsalarydepartment
            if (this.state.canassignamendsalarydepartment) {
                amendEmpSalaryPermissionData.canassignamendsalarydepartment = 1
            }
            else {
                amendEmpSalaryPermissionData.canassignamendsalarydepartment = 0
            }

            if (this.state.canassignamendsalarygm) {
                amendEmpSalaryPermissionData.canassignamendsalarygm = 1
            }
            else {
                amendEmpSalaryPermissionData.canassignamendsalarygm = 0
            }

            if (this.state.canassignamendsalarybacktoentity) {
                amendEmpSalaryPermissionData.canassignamendsalarybacktoentity = 1
            }
            else {
                amendEmpSalaryPermissionData.canassignamendsalarybacktoentity = 0
            }

            //canassignamendsalaryemployee
            if (this.state.canassignamendsalaryemployee) {
                amendEmpSalaryPermissionData.canassignamendsalaryemployee = 1
            }
            else {
                amendEmpSalaryPermissionData.canassignamendsalaryemployee = 0
            }

            if (this.state.canassignamendsalarysector) {
                amendEmpSalaryPermissionData.canassignamendsalarysector = 1;
            }
            else {
                amendEmpSalaryPermissionData.canassignamendsalarysector = 0;
            }

            //canprovideamendsalarystudy
            if (this.state.canprovideamendsalarystudy) {
                amendEmpSalaryPermissionData.canprovideamendsalarystudy = 1
            }
            else {
                amendEmpSalaryPermissionData.canprovideamendsalarystudy = 0
            }

            //canreceiveamendsalarystudy
            if (this.state.canreceiveamendsalarystudy) {
                amendEmpSalaryPermissionData.canreceiveamendsalarystudy = 1
            }
            else {
                amendEmpSalaryPermissionData.canreceiveamendsalarystudy = 0
            }

            roleData.amendEmpSalaryPermissionData = amendEmpSalaryPermissionData;

            //cansubmitretirementrequest
            if (this.state.cansubmitretirementrequest) {
                retirementPermissionData.cansubmitretirementrequest = 1
            }
            else {
                retirementPermissionData.cansubmitretirementrequest = 0
            }

            //canviewretirementrequest
            if (this.state.canviewretirementrequest) {
                retirementPermissionData.canviewretirementrequest = 1
            }
            else {
                retirementPermissionData.canviewretirementrequest = 0
            }

            //caneditretirementrequest
            if (this.state.caneditretirementrequest) {
                retirementPermissionData.caneditretirementrequest = 1
            }
            else {
                retirementPermissionData.caneditretirementrequest = 0
            }

            //canupdateretirementstatus
            if (this.state.canupdateretirementstatus) {
                retirementPermissionData.canupdateretirementstatus = 1
            }
            else {
                retirementPermissionData.canupdateretirementstatus = 0
            }

            //canaddretirementcomments
            if (this.state.canaddretirementcomments) {
                retirementPermissionData.canaddretirementcomments = 1
            }
            else {
                retirementPermissionData.canaddretirementcomments = 0
            }

            //canshowretirementcomments
            if (this.state.canshowretirementcomments) {
                retirementPermissionData.canshowretirementcomments = 1
            }
            else {
                retirementPermissionData.canshowretirementcomments = 0
            }

            //canassignretirementdepartment
            if (this.state.canassignretirementdepartment) {
                retirementPermissionData.canassignretirementdepartment = 1
            }
            else {
                retirementPermissionData.canassignretirementdepartment = 0
            }

            if (this.state.canassignretirementgm) {
                retirementPermissionData.canassignretirementgm = 1
            }
            else {
                retirementPermissionData.canassignretirementgm = 0
            }

            if (this.state.canassignretirementbacktoentity) {
                retirementPermissionData.canassignretirementbacktoentity = 1
            }
            else {
                retirementPermissionData.canassignretirementbacktoentity = 0
            }

            //canassignretirementemployees
            if (this.state.canassignretirementemployees) {
                retirementPermissionData.canassignretirementemployees = 1
            }
            else {
                retirementPermissionData.canassignretirementemployees = 0
            }

            if (this.state.canassignretirementsector) {
                retirementPermissionData.canassignretirementsector = 1;
            }
            else {
                retirementPermissionData.canassignretirementsector = 0;
            }

            //canprovideretirementstudy
            if (this.state.canprovideretirementstudy) {
                retirementPermissionData.canprovideretirementstudy = 1
            }
            else {
                retirementPermissionData.canprovideretirementstudy = 0
            }

            //canreceiveretirementstudy
            if (this.state.canreceiveretirementstudy) {
                retirementPermissionData.canreceiveretirementstudy = 1
            }
            else {
                retirementPermissionData.canreceiveretirementstudy = 0
            }

            roleData.retirementPermissionData = retirementPermissionData;

            //....extend create objectives...
            //cansubmitextendcreaterequest
            if (this.state.cansubmitextendcreaterequest) {
                extendCreateObjectivesData.cansubmitextendcreaterequest = 1
            }

            //canviewextendcreaterequest
            if (this.state.canviewextendcreaterequest) {
                extendCreateObjectivesData.canviewextendcreaterequest = 1
            }

            //caneditextendcreaterequest
            if (this.state.caneditextendcreaterequest) {
                extendCreateObjectivesData.caneditextendcreaterequest = 1
            }

            //canupdateextendcreatestatus
            if (this.state.canupdateextendcreatestatus) {
                extendCreateObjectivesData.canupdateextendcreatestatus = 1
            }

            //canshowextendcreatecomments
            if (this.state.canshowextendcreatecomments) {
                extendCreateObjectivesData.canshowextendcreatecomments = 1
            }

            //canaddextendcreatecomments
            if (this.state.canaddextendcreatecomments) {
                extendCreateObjectivesData.canaddextendcreatecomments = 1
            }

            //canprovideextendcreatestudy
            if (this.state.canprovideextendcreatestudy) {
                extendCreateObjectivesData.canprovideextendcreatestudy = 1
            }

            //canreceiveextendcreatestudy
            if (this.state.canreceiveextendcreatestudy) {
                extendCreateObjectivesData.canreceiveextendcreatestudy = 1
            }

            //canassignextendcreatesector
            if (this.state.canassignextendcreatesector) {
                extendCreateObjectivesData.canassignextendcreatesector = 1
            }

            //canassignextendcreatedepartment
            if (this.state.canassignextendcreatedepartment) {
                extendCreateObjectivesData.canassignextendcreatedepartment = 1
            }

            //canassignextendcreateemployee
            if (this.state.canassignextendcreateemployee) {
                extendCreateObjectivesData.canassignextendcreateemployee = 1
            }

            //canassignextendcreatebacktodepartment
            if (this.state.canassignextendcreatebacktodepartment) {
                extendCreateObjectivesData.canassignextendcreatebacktodepartment = 1
            }

            //canassignextendcreatebacktoentity
            if (this.state.canassignextendcreatebacktoentity) {
                extendCreateObjectivesData.canassignextendcreatebacktoentity = 1
            }

            //canassignextendcreaterequesttogm
            if (this.state.canassignextendcreaterequesttogm) {
                extendCreateObjectivesData.canassignextendcreaterequesttogm = 1
            }

            roleData.extendCreateObjectivesData = extendCreateObjectivesData;
           

            //....extend review objectives...
            //cansubmitextendreviewrequest
            if (this.state.cansubmitextendreviewrequest) {
                extendReviewObjectivesData.cansubmitextendreviewrequest = 1
            }

            //canviewextendreviewrequest
            if (this.state.canviewextendreviewrequest) {
                extendReviewObjectivesData.canviewextendreviewrequest = 1
            }

            //caneditextendreviewrequest
            if (this.state.caneditextendreviewrequest) {
                extendReviewObjectivesData.caneditextendreviewrequest = 1
            }

            //canupdateextendreviewstatus
            if (this.state.canupdateextendreviewstatus) {
                extendReviewObjectivesData.canupdateextendreviewstatus = 1
            }

            //canshowextendreviewcomments
            if (this.state.canshowextendreviewcomments) {
                extendReviewObjectivesData.canshowextendreviewcomments = 1
            }

            //canaddextendreviewcomments
            if (this.state.canaddextendreviewcomments) {
                extendReviewObjectivesData.canaddextendreviewcomments = 1
            }

            //canprovideextendreviewstudy
            if (this.state.canprovideextendreviewstudy) {
                extendReviewObjectivesData.canprovideextendreviewstudy = 1
            }

            //canreceiveextendreviewstudy
            if (this.state.canreceiveextendreviewstudy) {
                extendReviewObjectivesData.canreceiveextendreviewstudy = 1
            }

            //canassignextendreviewsector
            if (this.state.canassignextendreviewsector) {
                extendReviewObjectivesData.canassignextendreviewsector = 1
            }

            //canassignextendreviewdepartment
            if (this.state.canassignextendreviewdepartment) {
                extendReviewObjectivesData.canassignextendreviewdepartment = 1
            }

            //canassignextendreviewemployee
            if (this.state.canassignextendreviewemployee) {
                extendReviewObjectivesData.canassignextendreviewemployee = 1
            }
            else {
                extendReviewObjectivesData.canassignextendreviewemployee = 0
            }

            //canassignextendreviewbacktodepartment
            if (this.state.canassignextendreviewbacktodepartment) {
                extendReviewObjectivesData.canassignextendreviewbacktodepartment = 1
            }

            //canassignextendreviewbacktoentity
            if (this.state.canassignextendreviewbacktoentity) {
                extendReviewObjectivesData.canassignextendreviewbacktoentity = 1
            }

            //canassignextendreviewrequesttogm
            if (this.state.canassignextendreviewrequesttogm) {
                extendReviewObjectivesData.canassignextendreviewrequesttogm = 1
            }

            roleData.extendReviewObjectivesData = extendReviewObjectivesData;
          

            //extend annual perf
            //cansubmitextendannualrequest
            if (this.state.cansubmitextendannualrequest) {
                extendAnnualObjectivesData.cansubmitextendannualrequest = 1
            }

            //canviewextendannualrequest
            if (this.state.canviewextendannualrequest) {
                extendAnnualObjectivesData.canviewextendannualrequest = 1
            }

            //caneditextendannualrequest
            if (this.state.caneditextendannualrequest) {
                extendAnnualObjectivesData.caneditextendannualrequest = 1
            }

            //canupdateextendannualstatus
            if (this.state.canupdateextendannualstatus) {
                extendAnnualObjectivesData.canupdateextendannualstatus = 1
            }

            //canshowextendannualcomments
            if (this.state.canshowextendannualcomments) {
                extendAnnualObjectivesData.canshowextendannualcomments = 1
            }

            //canaddextendannualcomments
            if (this.state.canaddextendannualcomments) {
                extendAnnualObjectivesData.canaddextendannualcomments = 1
            }

            //canprovideextendannualstudy
            if (this.state.canprovideextendannualstudy) {
                extendAnnualObjectivesData.canprovideextendannualstudy = 1
            }

            //canreceiveextendannualstudy
            if (this.state.canreceiveextendannualstudy) {
                extendAnnualObjectivesData.canreceiveextendannualstudy = 1
            }

            //canassignextendannualsector
            if (this.state.canassignextendannualsector) {
                extendAnnualObjectivesData.canassignextendannualsector = 1
            }

            //canassignextendannualdepartment
            if (this.state.canassignextendannualdepartment) {
                extendAnnualObjectivesData.canassignextendannualdepartment = 1
            }

            //canassignextendannualemployee
            if (this.state.canassignextendannualemployee) {
                extendAnnualObjectivesData.canassignextendannualemployee = 1
            }

            //canassignextendannualbacktodepartment
            if (this.state.canassignextendannualbacktodepartment) {
                extendAnnualObjectivesData.canassignextendannualbacktodepartment = 1
            }

            //canassignextendannualbacktoentity
            if (this.state.canassignextendannualbacktoentity) {
                extendAnnualObjectivesData.canassignextendannualbacktoentity = 1
            }

            //canassignextendannualrequesttogm
            if (this.state.canassignextendannualrequesttogm) {
                extendAnnualObjectivesData.canassignextendannualrequesttogm = 1
            }

            roleData.extendAnnualObjectivesData = extendAnnualObjectivesData;
            

            //Add/Edit EMp Perf
            //cansubmitemployeeperformancerequest
            if (this.state.cansubmitemployeeperformancerequest) {
                addOrEditEmpPerfData.cansubmitemployeeperformancerequest = 1
            }

            //canviewemployeeperformancerequest
            if (this.state.canviewemployeeperformancerequest) {
                addOrEditEmpPerfData.canviewemployeeperformancerequest = 1
            }

            //caneditemployeeperformancerequest
            if (this.state.caneditemployeeperformancerequest) {
                addOrEditEmpPerfData.caneditemployeeperformancerequest = 1
            }

            //canupdateemployeeperformancestatus
            if (this.state.canupdateemployeeperformancestatus) {
                addOrEditEmpPerfData.canupdateemployeeperformancestatus = 1
            }

            //canshowemployeeperformancecomments
            if (this.state.canshowemployeeperformancecomments) {
                addOrEditEmpPerfData.canshowemployeeperformancecomments = 1
            }

            //canaddemployeeperformancecomments
            if (this.state.canaddemployeeperformancecomments) {
                addOrEditEmpPerfData.canaddemployeeperformancecomments = 1
            }

            //canprovideemployeeperformancestudy
            if (this.state.canprovideemployeeperformancestudy) {
                addOrEditEmpPerfData.canprovideemployeeperformancestudy = 1
            }

            //canreceiveemployeeperformancestudy
            if (this.state.canreceiveemployeeperformancestudy) {
                addOrEditEmpPerfData.canreceiveemployeeperformancestudy = 1
            }

            //canassignemployeeperformancesector
            if (this.state.canassignemployeeperformancesector) {
                addOrEditEmpPerfData.canassignemployeeperformancesector = 1
            }

            //canassignemployeeperformancedepartment
            if (this.state.canassignemployeeperformancedepartment) {
                addOrEditEmpPerfData.canassignemployeeperformancedepartment = 1
            }

            //canassignemployeeperformanceemployee
            if (this.state.canassignemployeeperformanceemployee) {
                addOrEditEmpPerfData.canassignemployeeperformanceemployee = 1
            }

            //canassignemployeeperformancebacktodepartment
            if (this.state.canassignemployeeperformancebacktodepartment) {
                addOrEditEmpPerfData.canassignemployeeperformancebacktodepartment = 1
            }

            //canassignemployeeperformancebacktoentity
            if (this.state.canassignemployeeperformancebacktoentity) {
                addOrEditEmpPerfData.canassignemployeeperformancebacktoentity = 1
            }

            //canassignemployeeperformancerequesttogm
            if (this.state.canassignemployeeperformancerequesttogm) {
                addOrEditEmpPerfData.canassignemployeeperformancerequesttogm = 1
            }

            roleData.addOrEditEmpPerfData = addOrEditEmpPerfData;
            

            //....join and implement...
            //cansubmitjoinimplementrequest
            if (this.state.cansubmitjoinimplementrequest) {
                joinAndImplementData.cansubmitjoinimplementrequest = 1
            }

            //canviewjoinimplementrequest
            if (this.state.canviewjoinimplementrequest) {
                joinAndImplementData.canviewjoinimplementrequest = 1
            }

            //caneditjoinimplementrequest
            if (this.state.caneditjoinimplementrequest) {
                joinAndImplementData.caneditjoinimplementrequest = 1
            }

            //canupdatejoinimplementstatus
            if (this.state.canupdatejoinimplementstatus) {
                joinAndImplementData.canupdatejoinimplementstatus = 1
            }

            //canshowjoinimplementcomments
            if (this.state.canshowjoinimplementcomments) {
                joinAndImplementData.canshowjoinimplementcomments = 1
            }

            //canaddjoinimplementcomments
            if (this.state.canaddjoinimplementcomments) {
                joinAndImplementData.canaddjoinimplementcomments = 1
            }

            //canprovidejoinimplementstudy
            if (this.state.canprovidejoinimplementstudy) {
                joinAndImplementData.canprovidejoinimplementstudy = 1
            }

            //canreceivejoinimplementstudy
            if (this.state.canreceivejoinimplementstudy) {
                joinAndImplementData.canreceivejoinimplementstudy = 1
            }

            //canassignjoinimplementsector
            if (this.state.canassignjoinimplementsector) {
                joinAndImplementData.canassignjoinimplementsector = 1
            }

            //canassignjoinimplementdepartment
            if (this.state.canassignjoinimplementdepartment) {
                joinAndImplementData.canassignjoinimplementdepartment = 1
            }

            //canassignjoinimplementemployee
            if (this.state.canassignjoinimplementemployee) {
                joinAndImplementData.canassignjoinimplementemployee = 1
            }

            //canassignjoinimplementbacktodepartment
            if (this.state.canassignjoinimplementbacktodepartment) {
                joinAndImplementData.canassignjoinimplementbacktodepartment = 1
            }

            //canassignjoinimplementbacktoentity
            if (this.state.canassignjoinimplementbacktoentity) {
                joinAndImplementData.canassignjoinimplementbacktoentity = 1
            }

            //canassignjoinimplementbacktoentity
            if (this.state.canassignjoinimplementrequesttogm) {
                joinAndImplementData.canassignjoinimplementrequesttogm = 1
            }

            roleData.joinAndImplementData = joinAndImplementData;
            

            //open complains data
            if (this.state.cansubmitopencomplainsrequest) {
                openComplainsData.cansubmitopencomplainsrequest = 1
            }

            if (this.state.canviewopencomplainsrequest) {
                openComplainsData.canviewopencomplainsrequest = 1
            }

            if (this.state.caneditopencomplainsrequest) {
                openComplainsData.caneditopencomplainsrequest = 1
            }

            if (this.state.canupdateopencomplainsstatus) {
                openComplainsData.canupdateopencomplainsstatus = 1
            }

            if (this.state.canshowopencomplainscomments) {
                openComplainsData.canshowopencomplainscomments = 1
            }

            if (this.state.canaddopencomplainscomments) {
                openComplainsData.canaddopencomplainscomments = 1
            }

            if (this.state.canprovideopencomplainsstudy) {
                openComplainsData.canprovideopencomplainsstudy = 1
            }

            if (this.state.canreceiveopencomplainsstudy) {
                openComplainsData.canreceiveopencomplainsstudy = 1
            }

            if (this.state.canassignopencomplainssector) {
                openComplainsData.canassignopencomplainssector = 1
            }

            if (this.state.canassignopencomplainsdepartment) {
                openComplainsData.canassignopencomplainsdepartment = 1
            }

            if (this.state.canassignopencomplainsemployee) {
                openComplainsData.canassignopencomplainsemployee = 1
            }

            if (this.state.canassignopencomplainsbacktodepartment) {
                openComplainsData.canassignopencomplainsbacktodepartment = 1
            }

            if (this.state.canassignopencomplainsbacktoentity) {
                openComplainsData.canassignopencomplainsbacktoentity = 1
            }

            if (this.state.canassignopencomplainsrequesttogm) {
                openComplainsData.canassignopencomplainsrequesttogm = 1
            }

            roleData.openComplainsData = openComplainsData;
          

            //amend systems
            if (this.state.cansubmitamendsystemsrequest) {
                amendSystemData.cansubmitamendsystemsrequest = 1
            }

            if (this.state.canviewamendsystemsrequest) {
                amendSystemData.canviewamendsystemsrequest = 1
            }

            if (this.state.caneditamendsystemsrequest) {
                amendSystemData.caneditamendsystemsrequest = 1
            }

            if (this.state.canupdateamendsystemsstatus) {
                amendSystemData.canupdateamendsystemsstatus = 1
            }

            if (this.state.canshowamendsystemscomments) {
                amendSystemData.canshowamendsystemscomments = 1
            }

            if (this.state.canaddamendsystemscomments) {
                amendSystemData.canaddamendsystemscomments = 1
            }

            if (this.state.canprovideamendsystemsstudy) {
                amendSystemData.canprovideamendsystemsstudy = 1
            }

            if (this.state.canreceiveamendsystemsstudy) {
                amendSystemData.canreceiveamendsystemsstudy = 1
            }

            if (this.state.canassignamendsystemssector) {
                amendSystemData.canassignamendsystemssector = 1
            }

            if (this.state.canassignamendsystemsdepartment) {
                amendSystemData.canassignamendsystemsdepartment = 1
            }

            if (this.state.canassignamendsystemsemployee) {
                amendSystemData.canassignamendsystemsemployee = 1
            }

            if (this.state.canassignamendsystemsbacktodepartment) {
                amendSystemData.canassignamendsystemsbacktodepartment = 1
            }

            if (this.state.canassignamendsystemsbacktoentity) {
                amendSystemData.canassignamendsystemsbacktoentity = 1
            }

            if (this.state.canassignamendsystemsrequesttogm) {
                amendSystemData.canassignamendsystemsrequesttogm = 1
            }

            roleData.amendSystemData = amendSystemData;
            

            //Report and static 
            if (this.state.cansubmitreportsstaticsrequest) {
                reportStatisticData.cansubmitreportsstaticsrequest = 1
            }

            if (this.state.canviewreportsstaticsrequest) {
                reportStatisticData.canviewreportsstaticsrequest = 1
            }

            if (this.state.caneditreportsstaticsrequest) {
                reportStatisticData.caneditreportsstaticsrequest = 1
            }

            if (this.state.canupdatereportsstaticsstatus) {
                reportStatisticData.canupdatereportsstaticsstatus = 1
            }

            if (this.state.canshowreportsstaticscomments) {
                reportStatisticData.canshowreportsstaticscomments = 1
            }

            if (this.state.canaddreportsstaticscomments) {
                reportStatisticData.canaddreportsstaticscomments = 1
            }

            if (this.state.canprovidereportsstaticsstudy) {
                reportStatisticData.canprovidereportsstaticsstudy = 1
            }

            if (this.state.canreceivereportsstaticsstudy) {
                reportStatisticData.canreceivereportsstaticsstudy = 1
            }

            if (this.state.canassignreportsstaticssector) {
                reportStatisticData.canassignreportsstaticssector = 1
            }

            if (this.state.canassignreportsstaticsdepartment) {
                reportStatisticData.canassignreportsstaticsdepartment = 1
            }

            if (this.state.canassignreportsstaticsemployee) {
                reportStatisticData.canassignreportsstaticsemployee = 1
            }

            if (this.state.canassignreportsstaticsbacktodepartment) {
                reportStatisticData.canassignreportsstaticsbacktodepartment = 1
            }

            if (this.state.canassignreportsstaticsbacktoentity) {
                reportStatisticData.canassignreportsstaticsbacktoentity = 1
            }

            if (this.state.canassignreportsstaticsrequesttogm) {
                reportStatisticData.canassignreportsstaticsrequesttogm = 1
            }

            roleData.reportStatisticData = reportStatisticData;
            

            //Other request
            if (this.state.cansubmitotherrequestsrequest) {
                otherRequestData.cansubmitotherrequestsrequest = 1
            }

            if (this.state.canviewotherrequestsrequest) {
                otherRequestData.canviewotherrequestsrequest = 1
            }

            if (this.state.caneditotherrequestsrequest) {
                otherRequestData.caneditotherrequestsrequest = 1
            }

            if (this.state.canupdateotherrequestsstatus) {
                otherRequestData.canupdateotherrequestsstatus = 1
            }

            if (this.state.canshowotherrequestscomments) {
                otherRequestData.canshowotherrequestscomments = 1
            }

            if (this.state.canaddotherrequestscomments) {
                otherRequestData.canaddotherrequestscomments = 1
            }

            if (this.state.canprovideotherrequestsstudy) {
                otherRequestData.canprovideotherrequestsstudy = 1
            }

            if (this.state.canreceiveotherrequestsstudy) {
                otherRequestData.canreceiveotherrequestsstudy = 1
            }

            if (this.state.canassignotherrequestssector) {
                otherRequestData.canassignotherrequestssector = 1
            }

            if (this.state.canassignotherrequestsdepartment) {
                otherRequestData.canassignotherrequestsdepartment = 1
            }

            if (this.state.canassignotherrequestsemployee) {
                otherRequestData.canassignotherrequestsemployee = 1
            }

            if (this.state.canassignotherrequestsbacktodepartment) {
                otherRequestData.canassignotherrequestsbacktodepartment = 1
            }

            if (this.state.canassignotherrequestsbacktoentity) {
                otherRequestData.canassignotherrequestsbacktoentity = 1
            }

            if (this.state.canassignotherrequesttogmsrequesttogm) {
                otherRequestData.canassignotherrequesttogmsrequesttogm = 1
            }

            roleData.otherRequestData = otherRequestData;
           

            roleData.isEdit = this.state.isEdit;
            roleData.requestId = this.state.requestId;
            roleData.titleSelectedValue = this.state.englishTitleValue;
            roleData.arabicTitle = this.state.arabicTitle;

            
            
            this.props.onSubmitData(roleData);
        }
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleIsAdmin = () => {
        this.setState({ isadmin: !this.state.isadmin });
    }

    handleCheckCanViewReport = () => {
        this.setState({ canviewreport: !this.state.canviewreport });
    }

    handleCheckcanCloseRequest =()=>{
        this.setState({canCloseRequest:!this.state.canCloseRequest});
    }
    
    handleCheckcanCreateWorkShop=()=>{
        this.setState({canCreateWorkShop:!this.state.canCreateWorkShop});}
        handleCheckcanBookWorkShop=()=>{this.setState({canBookWorkShop:!this.state.canBookWorkShop});}
        handleCheckcanViewDisciplinaryboard=()=>{this.setState({canViewDisciplinaryboard:!this.state.canViewDisciplinaryboard});}
        handleCheckcanCreateDisciplinaryboard=()=>{this.setState({canCreateDisciplinaryboard:!this.state.canCreateDisciplinaryboard});}
        handleCheckcanEditDisciplinaryboard=()=>{this.setState({canEditDisciplinaryboard:!this.state.canEditDisciplinaryboard});}
        handleCheckcanAssignDisciplinaryboardToDGoffice=()=>{this.setState({canAssignDisciplinaryboardToDGoffice:!this.state.canAssignDisciplinaryboardToDGoffice});}
        handleCheckcanAssignDisciplinaryboardtoEmployee=()=>{this.setState({canAssignDisciplinaryboardtoEmployee:!this.state.canAssignDisciplinaryboardtoEmployee});}
        handleCheckcanAddReplayDisciplinaryboard=()=>{this.setState({canAddReplayDisciplinaryboard:!this.state.canAddReplayDisciplinaryboard});}
        handleCheckcanViewOHI=()=>{this.setState({canViewOHI:!this.state.canViewOHI});}

    handleCheckcanViewEmployeeRepor=()=>{
        this.setState({canViewEmployeeReport:!this.state.canViewEmployeeReport});
    }
    handelChekcanGenerateAllreports=()=>{
this.setState({canGenerateAllreports:!this.state.canGenerateAllreports});
    }

    handleCheckCanSubmitSciReq = () => {
        this.setState({ cansubmitscirequest: !this.state.cansubmitscirequest });
    }

    handleCheckCanReviewSciReq = () => {
        this.setState({ canreviewscirequest: !this.state.canreviewscirequest });
    }

    handleCheckCanEditSciReq = () => {
        this.setState({ caneditscirequest: !this.state.caneditscirequest });
    }

    handleCheckCanUpdateSciStatus = () => {
        this.setState({ canupdatescistatus: !this.state.canupdatescistatus });
    }

    handleCheckCanAddSciComment = () => {
        this.setState({ canaddscicomment: !this.state.canaddscicomment });
    }

    handleCheckCanAssignSciDepartment = () => {
        this.setState({ canassignscidepartment: !this.state.canassignscidepartment });
    }

    handleCheckForCanAssignSciGm = () => {
        this.setState({ canassignscigm: !this.state.canassignscigm });
    }

    handleCheckForCanAssignSciBackToEntity = () => {
        this.setState({ canassignscibacktoentity: !this.state.canassignscibacktoentity });
    }

    handleCheckCanAssignSciDepartment = () => {
        this.setState({ canassignscidepartment: !this.state.canassignscidepartment });
    }

    handleCheckCanAssignSciEmployee = () => {
        this.setState({ canasignsciemployee: !this.state.canasignsciemployee });
    }

    handleCheckCanProvideSciFeedback = () => {
        this.setState({ canprovidescifeedback: !this.state.canprovidescifeedback });
    }

    handleCheckCanViewSciFeedback = () => {
        this.setState({ canviewscifeedback: !this.state.canviewscifeedback });
    }

    handleCheckCanAssignSCISector = () => {
        this.setState({ canassignscisector: !this.state.canassignscisector });
    }

    // organization structure 
    handleCheckCanSubmitorgReq = () => {
        this.setState({ cansubmitorgrequest: !this.state.cansubmitorgrequest });
    }
    handleCheckCanViewtree = () => {
        this.setState({ canviewtree: !this.state.canviewtree });
    }
    handleCheckCanOpenCreationStage = () => {
        this.setState({ canopencreationstage: !this.state.canopencreationstage });
    }

    handleCheckCanVieworgReq = () => {
        this.setState({ canvieworgrequest: !this.state.canvieworgrequest });
    }

    handleCheckCanEditorgReq = () => {
        this.setState({ caneditorgrequest: !this.state.caneditorgrequest });
    }

    handleCheckCanUpdateorgStatus = () => {
        this.setState({ canupdateorgstatus: !this.state.canupdateorgstatus });
    }

    handleCheckCanAddorgComment = () => {
        this.setState({ canaddorgcomment: !this.state.canaddorgcomment });
    }

    handleCheckCanAssignorgDepartment = () => {
        this.setState({ canassignorgdepartment: !this.state.canassignorgdepartment });
    }

    handleCheckForCanAssignorgGm = () => {
        this.setState({ canassignorggm: !this.state.canassignorggm });
    }

    handleCheckForCanAssignorgBackToEntity = () => {
        this.setState({ canassignorgbacktoentity: !this.state.canassignorgbacktoentity });
    }

    handleCheckCanAssignorgDepartment = () => {
        this.setState({ canassignorgdepartment: !this.state.canassignorgdepartment });
    }

    handleCheckCanAssignorgEmployee = () => {
        this.setState({ canasignorgemployee: !this.state.canasignorgemployee });
    }

    handleCheckCanProvideorgFeedback = () => {
        this.setState({ canprovideorgfeedback: !this.state.canprovideorgfeedback });
    }

    handleCheckCanVieworgFeedback = () => {
        this.setState({ canvieworgfeedback: !this.state.canvieworgfeedback });
    }

    handleCheckCanAssignorgSector = () => {
        this.setState({ canassignorgsector: !this.state.canassignorgsector });
    }

    handleCheckForCanShoworgComments = () => {
        this.setState({ canshoworgcomments: !this.state.canshoworgcomments });
    }


    handleCheckCanSubmitInfoReq = () => {
        this.setState({ cansubmitirrequest: !this.state.cansubmitirrequest });
    }

    handleCheckCanViewInfoReq = () => {
        this.setState({ canviewirrequest: !this.state.canviewirrequest });
    }

    handleCheckCanEditInfoReq = () => {
        this.setState({ caneditirrequest: !this.state.caneditirrequest });
    }

    handleCheckCanUpdateInfoStatus = () => {
        this.setState({ canupdateirstatus: !this.state.canupdateirstatus });
    }

    handleCheckCanAddInfoComment = () => {
        this.setState({ canaddircomments: !this.state.canaddircomments });
    }

    handleCheckCanAssignInfoDepartment = () => {
        this.setState({ canassignirdepartment: !this.state.canassignirdepartment });
    }

    handleCheckCanAssignIrGm = () => {
        this.setState({ canassignirgm: !this.state.canassignirgm });
    }

    handleCheckCanAssignIrBackToEntity = () => {
        this.setState({ canassignirbacktoentity: !this.state.canassignirbacktoentity });
    }

    handleCheckCanAddInfoReqFeedback = () => {
        this.setState({ canAddInfoReqFeedback: !this.state.canAddInfoReqFeedback });
    }

    handleCheckcanAddInfoFeedback = () => {
        this.setState({ canAddInfoFeedback: !this.state.canAddInfoFeedback });
    }

    handleCheckCanViewInfoFeedback = () => {
        this.setState({ canviewInfofeedback: !this.state.canviewInfofeedback });
    }

    handleCheckCanShowInfoReqComment = () => {
        this.setState({ canshowircomments: !this.state.canshowircomments });
    }

    handleCheckCanProvideInfoReqFeedback = () => {
        this.setState({ canprovideirfeedback: !this.state.canprovideirfeedback });
    }

    handleCheckForCanShowSCIComment = () => {
        this.setState({ canshowscicomments: !this.state.canshowscicomments });
    }

    handleCheckForCanReciveInfoReqFeedback = () => {
        this.setState({ canreceiveirfeedback: !this.state.canreceiveirfeedback });
    }

    handleCheckCanAssignInfoReqSector = () => {
        this.setState({ canassignirsector: !this.state.canassignirsector });
    }

    //Exemption from Acompany Patient
    handleCheckCanSubmitExmAccPatientReq = () => {
        this.setState({ cansubmitpatientrequest: !this.state.cansubmitpatientrequest });
    }

    handleCheckCanReviewExmAccPatientReq = () => {
        this.setState({ canviewpatientrequest: !this.state.canviewpatientrequest });
    }

    handleCheckCanEditExmAccPatientReq = () => {
        this.setState({ caneditpatientrequest: !this.state.caneditpatientrequest });
    }

    handleCheckCanUpdateExmAccPatientStatus = () => {
        this.setState({ canupdatepatientstatus: !this.state.canupdatepatientstatus });
    }

    handleCheckCanAddExmAccPatientComment = () => {
        this.setState({ canaddpatientcomments: !this.state.canaddpatientcomments });
    }

    handleCheckCanShowExmAccPatientComment = () => {
        this.setState({ canshowpatientcomments: !this.state.canshowpatientcomments });
    }

    //new
    handleCheckCanAssignResearchDepartForPatient = () => {
        this.setState({ canassignpatientdepartment: !this.state.canassignpatientdepartment });
    }

    handleCheckCanAssignGmPatient = () => {
        this.setState({ canassignpatientgm: !this.state.canassignpatientgm });
    }

    handleCheckCanAssignBackToEntityPatient = () => {
        this.setState({ canassignpatientbacktoentity: !this.state.canassignpatientbacktoentity });
    }

    handleCheckCanAssignResearchEmpForPatient = () => {
        this.setState({ canassignpatientemployee: !this.state.canassignpatientemployee });
    }

    handleCheckCanAssignReserchSectorForPatient = () => {
        this.setState({ canassignpatientsector: !this.state.canassignpatientsector })
    }

    handleCheckCanProvideResearchStudyForPatient = () => {
        this.setState({ canprovidepatientstudy: !this.state.canprovidepatientstudy });
    }

    handleCheckCanReceivesResearchStudyForPatient = () => {
        this.setState({ canreceivepatientstudy: !this.state.canreceivepatientstudy });
    }

    //Review hr polices
    handleCheckCanSubmitHrPolicyReq = () => {
        this.setState({ cansubmitreviewhrrequest: !this.state.cansubmitreviewhrrequest });
    }

    handleCheckCanViewHrPolicyReq = () => {
        this.setState({ canviewreviewhrrequest: !this.state.canviewreviewhrrequest });
    }

    handleCheckCanEditHrPolicyReq = () => {
        this.setState({ caneditreviewhrrequest: !this.state.caneditreviewhrrequest });
    }

    handleCheckCanUpdateHrPolicyStatus = () => {
        this.setState({ canupdatereviewhrstatus: !this.state.canupdatereviewhrstatus });
    }

    handleCheckCanAddHrPolicyComment = () => {
        this.setState({ canaddreviewhrcomments: !this.state.canaddreviewhrcomments });
    }

    handleCheckCanShowHrPoliciesComment = () => {
        this.setState({ canshowreviewhrcomments: !this.state.canshowreviewhrcomments });
    }

    //new
    handleCheckCanAssignPoliciesSector = () => {
        this.setState({ canassignhrsector: !this.state.canassignhrsector });
    }

    handleCheckCanAssignPoliciesDept = () => {
        this.setState({ canassignhrdepartment: !this.state.canassignhrdepartment });
    }

    handleCheckcanassignhrgm = () => {
        this.setState({ canassignhrgm: !this.state.canassignhrgm });
    }

    handleCheckcanassignhrBackToEntity = () => {
        this.setState({ canassignhrbacktoentity: !this.state.canassignhrbacktoentity });
    }

    handleCheckCanAssignPoliciesEmp = () => {
        this.setState({ canassignhremployee: !this.state.canassignhremployee });
    }

    handleCheckCanProvidePoliciesStudy = () => {
        this.setState({ canprovidehrstudy: !this.state.canprovidehrstudy });
    }

    handleCheckcanreceivehrstudy = () => {
        this.setState({ canreceivehrstudy: !this.state.canreceivehrstudy });
    }

    //Exemption from ad housing policy
    handleCheckCanSubmitExmADHousingPolicyReq = () => {
        this.setState({ cansubmitadhousingrequest: !this.state.cansubmitadhousingrequest });
    }

    handleCheckCanViewExmADHousingPolicyReq = () => {
        this.setState({ canviewadhousingrequest: !this.state.canviewadhousingrequest });
    }

    handleCheckCanEditExmADHousingPolicyReq = () => {
        this.setState({ caneditadhousingrequest: !this.state.caneditadhousingrequest });
    }

    handleCheckCanUpdateExmADHousingPolicyStatus = () => {
        this.setState({ canupdateadhousingstatus: !this.state.canupdateadhousingstatus });
    }

    handleCheckCanAddExmADHousingPolicyComment = () => {
        this.setState({ canaddadhousingcomments: !this.state.canaddadhousingcomments });
    }

    handleCheckCanShowAdHousingComment = () => {
        this.setState({ canshowadhousingcomments: !this.state.canshowadhousingcomments });
    }

    //new
    handleCheckCanAssignResearchDepartForAdHousing = () => {
        this.setState({ canassignhousingdepartment: !this.state.canassignhousingdepartment });
    }

    handleCheckCanAssignGmForAdHousing = () => {
        this.setState({ canassignhousinggm: !this.state.canassignhousinggm });
    }

    handleCheckCanAssignBackToEntityForAdHousing = () => {
        this.setState({ canassignhousingbacktoentity: !this.state.canassignhousingbacktoentity });
    }

    handleCheckCanAssignResearchEmpForAdHousing = () => {
        this.setState({ canassignhousingemployee: !this.state.canassignhousingemployee });
    }

    handleCheckCanAssignReserachSectorForAdHousing = () => {
        this.setState({ canassignhousingsector: !this.state.canassignhousingsector });
    }

    handleCheckCanProvideResearchStudyForAdHousing = () => {
        this.setState({ canprovidehousingstudy: !this.state.canprovidehousingstudy });
    }

    handleCheckCanReceivesResearchStudyForAdHousing = () => {
        this.setState({ canreceivehousingstudy: !this.state.canreceivehousingstudy });
    }

    //Secondement for fedral agency
    handleCheckCanSubmitSecndmentForFrdrlAgencyReq = () => {
        this.setState({ cansubmitsecondmentrequest: !this.state.cansubmitsecondmentrequest });
    }

    handleCheckCanViewSecndmentForFrdrlAgencyReq = () => {
        this.setState({ canviewsecondmentrequest: !this.state.canviewsecondmentrequest });
    }

    handleCheckCanEditSecndmentForFrdrlAgencyReq = () => {
        this.setState({ caneditsecondmentrequest: !this.state.caneditsecondmentrequest });
    }

    handleCheckCanUpdateSecndmentForFrdrlAgencyStatus = () => {
        this.setState({ canupdatesecondmentstatus: !this.state.canupdatesecondmentstatus });
    }

    handleCheckCanAddSecndmentForFrdrlAgencyComment = () => {
        this.setState({ canaddsecondmentcomments: !this.state.canaddsecondmentcomments });
    }

    handleCheckCanShowSecondementComment = () => {
        this.setState({ canshowsecondmentcomments: !this.state.canshowsecondmentcomments });
    }

    handleCheckCanAssignResearchDepartForSecondement = () => {
        this.setState({ canassignsecondmentdepartment: !this.state.canassignsecondmentdepartment });
    }

    handleCheckCanAssignGmSecondement = () => {
        this.setState({ canassignsecondmentgm: !this.state.canassignsecondmentgm });
    }

    handleCheckCanAssignBackToEntitySecondement = () => {
        this.setState({ canassignsecondmentbacktoentity: !this.state.canassignsecondmentbacktoentity });
    }

    handleCheckCanAssignResearchEmpForSecondement = () => {
        this.setState({ canassignsecondmetnemployee: !this.state.canassignsecondmetnemployee });
    }

    handleCheckCanAssignResearchSecotrForSecondement = () => {
        this.setState({ canassignsecondmentsector: !this.state.canassignsecondmentsector });
    }

    handleCheckCanProvideResearchStudyForSecondement = () => {
        this.setState({ canprovidesecondmentstudy: !this.state.canprovidesecondmentstudy });
    }

    handleCheckCanReceivesResearchStudyForSecondement = () => {
        this.setState({ canreceivesecondmentstudy: !this.state.canreceivesecondmentstudy });
    }

    //bonus request 
    handleCheckCanSubmitBonusRequestReq = () => {
        this.setState({ cansubmitbonusrequest: !this.state.cansubmitbonusrequest });
    }

    handleCheckCanViewBonusRequestReq = () => {
        this.setState({ canviewbonusrequest: !this.state.canviewbonusrequest });
    }

    handleCheckCanEditBonusRequestReq = () => {
        this.setState({ caneditbonusrequest: !this.state.caneditbonusrequest });
    }

    handleCheckCanShowBonusRequestReqComment = () => {
        this.setState({ canshowbonuscomments: !this.state.canshowbonuscomments });
    }

    handleCheckCanUpdateBonusRequestStatus = () => {
        this.setState({ canupdatebonusstatus: !this.state.canupdatebonusstatus });
    }

    handleCheckCanAddBonusRequestComment = () => {
        this.setState({ canaddbonuscomments: !this.state.canaddbonuscomments });
    }

    handleCheckCanViewBonusRequestFeedback = () => {
        this.setState({ canviewBonusReqfeedback: !this.state.canviewBonusReqfeedback });
    }

    handleCheckCanAssignSalariesDepartForBR = () => {
        this.setState({ canassignbounsdepartment: !this.state.canassignbounsdepartment });
    }

    handleCheckCanAssignGmForBR = () => {
        this.setState({ canassignbonusgm: !this.state.canassignbonusgm });
    }

    handleCheckCanAssignBackToEntityForBR = () => {
        this.setState({ canassignbonusbacktoentity: !this.state.canassignbonusbacktoentity });
    }

    handleCheckCanAssignSalariesEmpForBR = () => {
        this.setState({ canassignbounsemployee: !this.state.canassignbounsemployee });
    }

    handleCheckCanAssignSalariesSeactorForBR = () => {
        this.setState({ canassignbonussector: !this.state.canassignbonussector })
    }

    handleCheckCanProvideSalariesForBR = () => {
        this.setState({ canprovidebonusstudy: !this.state.canprovidebonusstudy });
    }

    handleCheckCanReceivesSalStudyForBR = () => {
        this.setState({ canreceivebonusstudy: !this.state.canreceivebonusstudy });
    }

    //amend salariy scale 
    handleCheckCanSubmitAmendSalaryScaleReq = () => {
        this.setState({ cansubmitamendscalerequest: !this.state.cansubmitamendscalerequest });
    }

    handleCheckCanViewAmendSalaryScaleReq = () => {
        this.setState({ canviewamendscalerequest: !this.state.canviewamendscalerequest });
    }

    handleCheckCanEditAmendSalaryScaleReq = () => {
        this.setState({ caneditamendscalerequest: !this.state.caneditamendscalerequest });
    }

    handleCheckCanUpdateAmendSalaryScaleStatus = () => {
        this.setState({ canupdateamendscalestatus: !this.state.canupdateamendscalestatus });
    }

    handleCheckCanAddAmendSalaryScaleComment = () => {
        this.setState({ canaddamendscalecomments: !this.state.canaddamendscalecomments });
    }

    handleCheckCanAssignAmendSalaryScaleDepartment = () => {
        this.setState({ canassignAmendSalScaledepartment: !this.state.canassignAmendSalScaledepartment });
    }

    handleCheckCanAssignAmendSalaryScaleEmployee = () => {
        this.setState({ canasignAmendSalScaleemployee: !this.state.canasignAmendSalScaleemployee });
    }

    handleCheckCanProvideAmendSalaryScaleFeedback = () => {
        this.setState({ canprovideAmendSalScalefeedback: !this.state.canprovideAmendSalScalefeedback });
    }

    handleCheckCanViewAmendSalaryScaleFeedback = () => {
        this.setState({ canviewAmendSalScalefeedback: !this.state.canviewAmendSalScalefeedback });
    }

    handleCheckCanShowAmendSalScaleComment = () => {
        this.setState({ canshowamendscalecomments: !this.state.canshowamendscalecomments });
    }

    handleCheckCanAssignSalariesDepartForAmendSalScale = () => {
        this.setState({ canassignamendscaledepartment: !this.state.canassignamendscaledepartment });
    }

    handleCheckCanAssignGmForAmendSalScale = () => {
        this.setState({ canassignamendscalegm: !this.state.canassignamendscalegm });
    }

    handleCheckCanAssignBackToEntityForAmendSalScale = () => {
        this.setState({ canassignamendscalebacktoentity: !this.state.canassignamendscalebacktoentity });
    }

    handleCheckCanAssignSalariesEmpForAmendSalScale = () => {
        this.setState({ canassignamendscaleemployee: !this.state.canassignamendscaleemployee });
    }

    handleCheckCanAssignSalariesSectorForAmendSalScale = () => {
        this.setState({ canassignamendscalesector: !this.state.canassignamendscalesector });
    }

    handleCheckCanProvideSalariesForAmendSalScale = () => {
        this.setState({ canprovideamendscalestudy: !this.state.canprovideamendscalestudy });
    }

    handleCheckCanReceivesSalStudyForAmendSalScale = () => {
        this.setState({ canreceiveamendscalestudy: !this.state.canreceiveamendscalestudy });
    }

    //amend employee  salary 
    handleCheckCanSubmitAmendEmpSalaryReq = () => {
        this.setState({ cansubmitamendsalaryrequest: !this.state.cansubmitamendsalaryrequest });
    }

    handleCheckCanViewAmendEmpSalaryReq = () => {
        this.setState({ canviewamendsalaryrequest: !this.state.canviewamendsalaryrequest });
    }

    handleCheckCanEditAmendEmpSalaryReq = () => {
        this.setState({ caneditamendsalaryrequest: !this.state.caneditamendsalaryrequest });
    }

    handleCheckCanUpdateAmendEmpSalaryStatus = () => {
        this.setState({ canupdateamendsalarystatus: !this.state.canupdateamendsalarystatus });
    }

    handleCheckCanAddAmendEmpSalaryComment = () => {
        this.setState({ canaddamendsalarycomments: !this.state.canaddamendsalarycomments });
    }

    handleCheckCanShowAmendSalaryComment = () => {
        this.setState({ canshowamendsalarycomments: !this.state.canshowamendsalarycomments });
    }

    handleCheckCanAssignSalariesDepartForAmendEmpSal = () => {
        this.setState({ canassignamendsalarydepartment: !this.state.canassignamendsalarydepartment });
    }

    handleCheckCanAssignGmForAmendEmpSal = () => {
        this.setState({ canassignamendsalarygm: !this.state.canassignamendsalarygm });
    }

    handleCheckCanAssignBackToEntityForAmendEmpSal = () => {
        this.setState({ canassignamendsalarybacktoentity: !this.state.canassignamendsalarybacktoentity });
    }

    handleCheckCanAssignSalariesEmpForAmendEmpSal = () => {
        this.setState({ canassignamendsalaryemployee: !this.state.canassignamendsalaryemployee });
    }

    handleCheckCanAssignSalariesSectorForAmendEmpSal = () => {
        this.setState({ canassignamendsalarysector: !this.state.canassignamendsalarysector })
    }

    handleCheckCanProvideSalariesForAmendEmpSal = () => {
        this.setState({ canprovideamendsalarystudy: !this.state.canprovideamendsalarystudy });
    }

    handleCheckCanReceivesSalStudyForAmendEmpSal = () => {
        this.setState({ canreceiveamendsalarystudy: !this.state.canreceiveamendsalarystudy });
    }

    //retirement 
    handleCheckCanSubmitRetirementReq = () => {
        this.setState({ cansubmitretirementrequest: !this.state.cansubmitretirementrequest });
    }

    handleCheckCanViewRetirementReq = () => {
        this.setState({ canviewretirementrequest: !this.state.canviewretirementrequest });
    }

    handleCheckCanEditRetirementReq = () => {
        this.setState({ caneditretirementrequest: !this.state.caneditretirementrequest });
    }

    handleCheckCanUpdateRetirementStatus = () => {
        this.setState({ canupdateretirementstatus: !this.state.canupdateretirementstatus });
    }

    handleCheckCanAddRetirementComment = () => {
        this.setState({ canaddretirementcomments: !this.state.canaddretirementcomments });
    }

    handleCheckCanShowRetirementComment = () => {
        this.setState({ canshowretirementcomments: !this.state.canshowretirementcomments });
    }

    handleCheckCanAssignResearchDepartForRetirement = () => {
        this.setState({ canassignretirementdepartment: !this.state.canassignretirementdepartment });
    }

    handleCheckCanAssignGmForRetirment = () => {
        this.setState({ canassignretirementgm: !this.state.canassignretirementgm });
    }

    handleCheckCanAssignBackToEntityForRetirment = () => {
        this.setState({ canassignretirementbacktoentity: !this.state.canassignretirementbacktoentity });
    }

    handleCheckCanAssignResearchEmpForRetirement = () => {
        this.setState({ canassignretirementemployees: !this.state.canassignretirementemployees });
    }

    handleCheckCanAssignReserchSectorForRetirment = () => {
        this.setState({ canassignretirementsector: !this.state.canassignretirementsector })
    }

    handleCheckCanProvideResearchStudyForRetirement = () => {
        this.setState({ canprovideretirementstudy: !this.state.canprovideretirementstudy });
    }

    handleCheckCanReceivesResearchStudyForRetirement = () => {
        this.setState({ canreceiveretirementstudy: !this.state.canreceiveretirementstudy });
    }

    //extend create request
    handleCheckCanSubmitExtendCreateRequest = () => {
        this.setState({ cansubmitextendcreaterequest: !this.state.cansubmitextendcreaterequest });
    }

    handleCheckCanViewExtendCreateRequest = () => {
        this.setState({ canviewextendcreaterequest: !this.state.canviewextendcreaterequest });
    }

    handleCheckCanEditExtendCreateRequest = () => {
        this.setState({ caneditextendcreaterequest: !this.state.caneditextendcreaterequest });
    }

    handleCheckCanUpdateExtendCreateStatus = () => {
        this.setState({ canupdateextendcreatestatus: !this.state.canupdateextendcreatestatus });
    }

    handleShowExtendCreateComments = () => {
        this.setState({ canshowextendcreatecomments: !this.state.canshowextendcreatecomments });
    }

    handleAddExtendCreateComments = () => {
        this.setState({ canaddextendcreatecomments: !this.state.canaddextendcreatecomments });
    }

    handleProvideExtendCreateStudy = () => {
        this.setState({ canprovideextendcreatestudy: !this.state.canprovideextendcreatestudy });
    }

    handleReceiveExtendCreateStudy = () => {
        this.setState({ canreceiveextendcreatestudy: !this.state.canreceiveextendcreatestudy });
    }

    handleAssignExtendCreateSector = () => {
        this.setState({ canassignextendcreatesector: !this.state.canassignextendcreatesector });
    }

    handleAssignExtendCreateDepartment = () => {
        this.setState({ canassignextendcreatedepartment: !this.state.canassignextendcreatedepartment });
    }

    handleAssignExtendCreateEmployee = () => {
        this.setState({ canassignextendcreateemployee: !this.state.canassignextendcreateemployee });
    }

    handleAssignExtendCreateBackToDepartment = () => {
        this.setState({ canassignextendcreatebacktodepartment: !this.state.canassignextendcreatebacktodepartment });
    }

    handleAssignExtendCreateBackToEntity = () => {
        this.setState({ canassignextendcreatebacktoentity: !this.state.canassignextendcreatebacktoentity });
    }

    handleAssignExtendCreateGm = () => {
        this.setState({ canassignextendcreaterequesttogm: !this.state.canassignextendcreaterequesttogm });
    }

    //extend Review request
    handleCheckCanSubmitExtendReviewRequest = () => {
        this.setState({ cansubmitextendreviewrequest: !this.state.cansubmitextendreviewrequest });
    }

    handleCheckCanViewExtendReviewRequest = () => {
        this.setState({ canviewextendreviewrequest: !this.state.canviewextendreviewrequest });
    }

    handleCheckCanEditExtendReviewRequest = () => {
        this.setState({ caneditextendreviewrequest: !this.state.caneditextendreviewrequest });
    }

    handleCheckCanUpdateExtendReviewStatus = () => {
        this.setState({ canupdateextendreviewstatus: !this.state.canupdateextendreviewstatus });
    }

    handleShowExtendReviewComments = () => {
        this.setState({ canshowextendreviewcomments: !this.state.canshowextendreviewcomments });
    }

    handleAddExtendReviewComments = () => {
        this.setState({ canaddextendreviewcomments: !this.state.canaddextendreviewcomments });
    }

    handleProvideExtendReviewStudy = () => {
        this.setState({ canprovideextendreviewstudy: !this.state.canprovideextendreviewstudy });
    }

    handleReceiveExtendReviewStudy = () => {
        this.setState({ canreceiveextendreviewstudy: !this.state.canreceiveextendreviewstudy });
    }

    handleAssignExtendReviewSector = () => {
        this.setState({ canassignextendreviewsector: !this.state.canassignextendreviewsector });
    }

    handleAssignExtendReviewDepartment = () => {
        this.setState({ canassignextendreviewdepartment: !this.state.canassignextendreviewdepartment });
    }

    handleAssignExtendReviewEmployee = () => {
        this.setState({ canassignextendreviewemployee: !this.state.canassignextendreviewemployee });
    }

    handleAssignExtendReviewBackToDepartment = () => {
        this.setState({ canassignextendreviewbacktodepartment: !this.state.canassignextendreviewbacktodepartment });
    }

    handleAssignExtendReviewBackToEntity = () => {
        this.setState({ canassignextendreviewbacktoentity: !this.state.canassignextendreviewbacktoentity });
    }

    handleAssignExtendReviewGm = () => {
        this.setState({ canassignextendreviewrequesttogm: !this.state.canassignextendreviewrequesttogm });
    }

    //extend annual 
    handleCheckCanSubmitExtendAnnualRequest = () => {
        this.setState({ cansubmitextendannualrequest: !this.state.cansubmitextendannualrequest });
    }

    handleCheckCanViewExtendAnnualRequest = () => {
        this.setState({ canviewextendannualrequest: !this.state.canviewextendannualrequest });
    }

    handleCheckCanEditExtendAnnualRequest = () => {
        this.setState({ caneditextendannualrequest: !this.state.caneditextendannualrequest });
    }

    handleCheckCanUpdateExtendAnnualStatus = () => {
        this.setState({ canupdateextendannualstatus: !this.state.canupdateextendannualstatus });
    }

    handleShowExtendAnnualComments = () => {
        this.setState({ canshowextendannualcomments: !this.state.canshowextendannualcomments });
    }

    handleAddExtendAnnualComments = () => {
        this.setState({ canaddextendannualcomments: !this.state.canaddextendannualcomments });
    }

    handleProvideExtendAnnualStudy = () => {
        this.setState({ canprovideextendannualstudy: !this.state.canprovideextendannualstudy });
    }

    handleReceiveExtendAnnualStudy = () => {
        this.setState({ canreceiveextendannualstudy: !this.state.canreceiveextendannualstudy });
    }

    handleAssignExtendAnnualSector = () => {
        this.setState({ canassignextendannualsector: !this.state.canassignextendannualsector });
    }

    handleAssignExtendAnnualDepartment = () => {
        this.setState({ canassignextendannualdepartment: !this.state.canassignextendannualdepartment });
    }

    handleAssignExtendAnnualEmployee = () => {
        this.setState({ canassignextendannualemployee: !this.state.canassignextendannualemployee });
    }

    handleAssignExtendAnnualBackToDepartment = () => {
        this.setState({ canassignextendannualbacktodepartment: !this.state.canassignextendannualbacktodepartment });
    }

    handleAssignExtendAnnualBackToEntity = () => {
        this.setState({ canassignextendannualbacktoentity: !this.state.canassignextendannualbacktoentity });
    }

    handleAssignExtendAnnualGm = () => {
        this.setState({ canassignextendannualrequesttogm: !this.state.canassignextendannualrequesttogm });
    }

    //add or edit employee perf
    handleCheckCanSubmitEmployeePerfRequest = () => {
        this.setState({ cansubmitemployeeperformancerequest: !this.state.cansubmitemployeeperformancerequest });
    }

    handleCheckCanViewEmpPerfRequest = () => {
        this.setState({ canviewemployeeperformancerequest: !this.state.canviewemployeeperformancerequest });
    }

    handleCheckCanEditEmpPerfRequest = () => {
        this.setState({ caneditemployeeperformancerequest: !this.state.caneditemployeeperformancerequest });
    }

    handleCheckCanUpdateEmpPerfStatus = () => {
        this.setState({ canupdateemployeeperformancestatus: !this.state.canupdateemployeeperformancestatus });
    }

    handleShowEmpPerfComments = () => {
        this.setState({ canshowemployeeperformancecomments: !this.state.canshowemployeeperformancecomments });
    }

    handleAddEmpPerfComments = () => {
        this.setState({ canaddemployeeperformancecomments: !this.state.canaddemployeeperformancecomments });
    }

    handleProvideEmpPerfStudy = () => {
        this.setState({ canprovideemployeeperformancestudy: !this.state.canprovideemployeeperformancestudy });
    }

    handleReceiveEmpPerfStudy = () => {
        this.setState({ canreceiveemployeeperformancestudy: !this.state.canreceiveemployeeperformancestudy });
    }

    handleAssignEmpPerfSector = () => {
        this.setState({ canassignemployeeperformancesector: !this.state.canassignemployeeperformancesector });
    }

    handleAssignEmpPerfDepartment = () => {
        this.setState({ canassignemployeeperformancedepartment: !this.state.canassignemployeeperformancedepartment });
    }

    handleAssignEmpPerfEmployee = () => {
        this.setState({ canassignemployeeperformanceemployee: !this.state.canassignemployeeperformanceemployee });
    }

    handleAssignEmpPerfBackToDepartment = () => {
        this.setState({ canassignemployeeperformancebacktodepartment: !this.state.canassignemployeeperformancebacktodepartment });
    }

    handleAssignEmpPerfBackToEntity = () => {
        this.setState({ canassignemployeeperformancebacktoentity: !this.state.canassignemployeeperformancebacktoentity });
    }

    handleAssignEmpPerfGm = () => {
        this.setState({ canassignemployeeperformancerequesttogm: !this.state.canassignemployeeperformancerequesttogm });
    }

    //join and implement
    handleCheckCanSubmitJoinImpleRequest = () => {
        this.setState({ cansubmitjoinimplementrequest: !this.state.cansubmitjoinimplementrequest });
    }

    handleCheckCanViewJoinImpleRequest = () => {
        this.setState({ canviewjoinimplementrequest: !this.state.canviewjoinimplementrequest });
    }

    handleCheckCanEditJoinImpleRequest = () => {
        this.setState({ caneditjoinimplementrequest: !this.state.caneditjoinimplementrequest });
    }

    handleCheckCanUpdateJoinImpleStatus = () => {
        this.setState({ canupdatejoinimplementstatus: !this.state.canupdatejoinimplementstatus });
    }

    handleShowJoinImpleComments = () => {
        this.setState({ canshowjoinimplementcomments: !this.state.canshowjoinimplementcomments });
    }

    handleAddJoinImpleComments = () => {
        this.setState({ canaddjoinimplementcomments: !this.state.canaddjoinimplementcomments });
    }

    handleProvideJoinImpleStudy = () => {
        this.setState({ canprovidejoinimplementstudy: !this.state.canprovidejoinimplementstudy });
    }

    handleReceiveJoinImpleStudy = () => {
        this.setState({ canreceivejoinimplementstudy: !this.state.canreceivejoinimplementstudy });
    }

    handleAssignJoinImpleSector = () => {
        this.setState({ canassignjoinimplementsector: !this.state.canassignjoinimplementsector });
    }

    handleAssignJoinImpleDepartment = () => {
        this.setState({ canassignjoinimplementdepartment: !this.state.canassignjoinimplementdepartment });
    }

    handleAssignJoinImpleEmployee = () => {
        this.setState({ canassignjoinimplementemployee: !this.state.canassignjoinimplementemployee });
    }

    handleAssignJoinImpleBackToDepartment = () => {
        this.setState({ canassignjoinimplementbacktodepartment: !this.state.canassignjoinimplementbacktodepartment });
    }

    handleAssignJoinImpleBackToEntity = () => {
        this.setState({ canassignjoinimplementbacktoentity: !this.state.canassignjoinimplementbacktoentity });
    }

    handleAssignJoinImpleGm = () => {
        this.setState({ canassignjoinimplementrequesttogm: !this.state.canassignjoinimplementrequesttogm });
    }

    //open complains
    handleCheckCanSubmitOpenComplainRequest = () => {
        this.setState({ cansubmitopencomplainsrequest: !this.state.cansubmitopencomplainsrequest });
    }

    handleCheckCanViewOpenComplainRequest = () => {
        this.setState({ canviewopencomplainsrequest: !this.state.canviewopencomplainsrequest });
    }

    handleCheckCanEditOpenComplainRequest = () => {
        this.setState({ caneditopencomplainsrequest: !this.state.caneditopencomplainsrequest });
    }

    handleCheckCanUpdateOpenComplainRequest = () => {
        this.setState({ canupdateopencomplainsstatus: !this.state.canupdateopencomplainsstatus });
    }
    handleCheckCanShowOpenComplainRequest = () => {
        this.setState({ canshowopencomplainscomments: !this.state.canshowopencomplainscomments });
    }

    handleCheckCanAddOpenComplainRequest = () => {
        this.setState({ canaddopencomplainscomments: !this.state.canaddopencomplainscomments });
    }

    handleCheckCanProvideOpenComplainRequest = () => {
        this.setState({ canprovideopencomplainsstudy: !this.state.canprovideopencomplainsstudy });
    }

    handleCheckCanReceiveOpenComplainStudy = () => {
        this.setState({ canreceiveopencomplainsstudy: !this.state.canreceiveopencomplainsstudy });
    }

    handleCheckCanAssignOpenComplainSector = () => {
        this.setState({ canassignopencomplainssector: !this.state.canassignopencomplainssector });
    }

    handleCheckCanAssignOpenComplainDepartment = () => {
        this.setState({ canassignopencomplainsdepartment: !this.state.canassignopencomplainsdepartment });
    }

    handleCheckCanAssignOpenComplainEmployee = () => {
        this.setState({ canassignopencomplainsemployee: !this.state.canassignopencomplainsemployee });
    }

    handleCheckCanAssignOpenComplainBackToDepartment = () => {
        this.setState({ canassignopencomplainsbacktodepartment: !this.state.canassignopencomplainsbacktodepartment })
    }

    handleCheckCanAssignOpenComplainsBackToEntity = () => {
        this.setState({ canassignopencomplainsbacktoentity: !this.state.canassignopencomplainsbacktoentity });
    }

    handleCheckCanAssignOpenComplainsGm = () => {
        this.setState({ canassignopencomplainsrequesttogm: !this.state.canassignopencomplainsrequesttogm });
    }

    //amend system
    handleCheckCanSubmitAmendSystemRequest = () => {
        this.setState({ cansubmitamendsystemsrequest: !this.state.cansubmitamendsystemsrequest });
    }

    handleCheckCanViewAmendSystemRequest = () => {
        this.setState({ canviewamendsystemsrequest: !this.state.canviewamendsystemsrequest });
    }

    handleCheckCanEditAmendSystemRequest = () => {
        this.setState({ caneditamendsystemsrequest: !this.state.caneditamendsystemsrequest });
    }

    handleCheckCanUpdateAmendSystemRequest = () => {
        this.setState({ canupdateamendsystemsstatus: !this.state.canupdateamendsystemsstatus });
    }

    handleCheckCanShowAmendSystemRequest = () => {
        this.setState({ canshowamendsystemscomments: !this.state.canshowamendsystemscomments });
    }

    handleCheckCanAddAmendSystemRequest = () => {
        this.setState({ canaddamendsystemscomments: !this.state.canaddamendsystemscomments });
    }

    handleCheckCanProvideAmendSystemRequest = () => {
        this.setState({ canprovideamendsystemsstudy: !this.state.canprovideamendsystemsstudy });
    }

    handleCheckCanReceiveAmendSystemStudy = () => {
        this.setState({ canreceiveamendsystemsstudy: !this.state.canreceiveamendsystemsstudy });
    }

    handleCheckCanAssignAmendSystemSector = () => {
        this.setState({ canassignamendsystemssector: !this.state.canassignamendsystemssector });
    }

    handleCheckCanAssignAmendSystemDepartment = () => {
        this.setState({ canassignamendsystemsdepartment: !this.state.canassignamendsystemsdepartment });
    }

    handleCheckCanAssignAmendSystemEmployee = () => {
        this.setState({ canassignamendsystemsemployee: !this.state.canassignamendsystemsemployee });
    }

    handleCheckCanAssignAmendSystemBackToDepartment = () => {
        this.setState({ canassignamendsystemsbacktodepartment: !this.state.canassignamendsystemsbacktodepartment })
    }

    handleCheckCanAssignAmendSystemsBackToEntity = () => {
        this.setState({ canassignamendsystemsbacktoentity: !this.state.canassignamendsystemsbacktoentity });
    }

    handleCheckCanAssignAmendSystemsGm = () => {
        this.setState({ canassignamendsystemsrequesttogm: !this.state.canassignamendsystemsrequesttogm });
    }

    //report and statistics
    //Report and static 
    handleCheckCanSubmitReportAndStaticRequest = () => {
        this.setState({ cansubmitreportsstaticsrequest: !this.state.cansubmitreportsstaticsrequest });
    }

    handleCheckCanViewReportAndStaticRequest = () => {
        this.setState({ canviewreportsstaticsrequest: !this.state.canviewreportsstaticsrequest });
    }

    handleCheckCanEditReportAndStaticRequest = () => {
        this.setState({ caneditreportsstaticsrequest: !this.state.caneditreportsstaticsrequest });
    }

    handleCheckCanUpdateReportAndStaticStatus = () => {
        this.setState({ canupdatereportsstaticsstatus: !this.state.canupdatereportsstaticsstatus });
    }

    handleShowReportAndStaticComments = () => {
        this.setState({ canshowreportsstaticscomments: !this.state.canshowreportsstaticscomments });
    }

    handleAddReportAndStaticComments = () => {
        this.setState({ canaddreportsstaticscomments: !this.state.canaddreportsstaticscomments });
    }

    handleProvideReportAndStaticStudy = () => {
        this.setState({ canprovidereportsstaticsstudy: !this.state.canprovidereportsstaticsstudy });
    }

    handleReceiveReportAndStaticStudy = () => {
        this.setState({ canreceivereportsstaticsstudy: !this.state.canreceivereportsstaticsstudy });
    }

    handleAssignReportAndStaticSector = () => {
        this.setState({ canassignreportsstaticssector: !this.state.canassignreportsstaticssector });
    }

    handleAssignReportAndStaticDepartment = () => {
        this.setState({ canassignreportsstaticsdepartment: !this.state.canassignreportsstaticsdepartment });
    }

    handleAssignReportAndStaticEmployee = () => {
        this.setState({ canassignreportsstaticsemployee: !this.state.canassignreportsstaticsemployee });
    }

    handleAssignReportAndStaticBackToDepartment = () => {
        this.setState({ canassignreportsstaticsbacktodepartment: !this.state.canassignreportsstaticsbacktodepartment });
    }

    handleAssignReportAndStaticBackToEntity = () => {
        this.setState({ canassignreportsstaticsbacktoentity: !this.state.canassignreportsstaticsbacktoentity });
    }

    handleAssignReportAndStaticGm = () => {
        this.setState({ canassignreportsstaticsrequesttogm: !this.state.canassignreportsstaticsrequesttogm });
    }

    // other request
    handleCheckCanSubmitOtherRequestRequest = () => {
        this.setState({ cansubmitotherrequestsrequest: !this.state.cansubmitotherrequestsrequest });
    }

    handleCheckCanViewOtherRequestRequest = () => {
        this.setState({ canviewotherrequestsrequest: !this.state.canviewotherrequestsrequest });
    }

    handleCheckCanEditOtherRequestRequest = () => {
        this.setState({ caneditotherrequestsrequest: !this.state.caneditotherrequestsrequest });
    }

    handleCheckCanUpdateOtherRequestStatus = () => {
        this.setState({ canupdateotherrequestsstatus: !this.state.canupdateotherrequestsstatus });
    }

    handleShowOtherRequestComments = () => {
        this.setState({ canshowotherrequestscomments: !this.state.canshowotherrequestscomments });
    }

    handleAddOtherRequestComments = () => {
        this.setState({ canaddotherrequestscomments: !this.state.canaddotherrequestscomments });
    }

    handleProvideOtherRequestStudy = () => {
        this.setState({ canprovideotherrequestsstudy: !this.state.canprovideotherrequestsstudy });
    }

    handleReceiveOtherRequestStudy = () => {
        this.setState({ canreceiveotherrequestsstudy: !this.state.canreceiveotherrequestsstudy });
    }

    handleAssignOtherRequestSector = () => {
        this.setState({ canassignotherrequestssector: !this.state.canassignotherrequestssector });
    }

    handleAssignOtherRequestDepartment = () => {
        this.setState({ canassignotherrequestsdepartment: !this.state.canassignotherrequestsdepartment });
    }

    handleAssignOtherRequestEmployee = () => {
        this.setState({ canassignotherrequestsemployee: !this.state.canassignotherrequestsemployee });
    }

    handleAssignOtherRequestBackToDepartment = () => {
        this.setState({ canassignotherrequestsbacktodepartment: !this.state.canassignotherrequestsbacktodepartment });
    }

    handleAssignOtherRequestBackToEntity = () => {
        this.setState({ canassignotherrequestsbacktoentity: !this.state.canassignotherrequestsbacktoentity });
    }

    handleAssignOtherRequestGm = () => {
        this.setState({ canassignotherrequesttogmsrequesttogm: !this.state.canassignotherrequesttogmsrequesttogm });
    }

    handleEnglishTileOnChange = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ englishTitleValue: event.target.value }, () => {
                });
            }
        }
        else {
            this.setState({ englishTitleValue: "" }, () => {
            });
        }
    }

    handleArabicTitle = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ arabicTitle: event.target.value }, () => {
                });
            }
        }
        else {
            this.setState({ arabicTitle: "" }, () => {
            });
        }
    };

    render() {

        return (

            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate >

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].EnglishTitle}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="englishTitleValue"
                                name="englishTitleValue"
                                value={this.state.englishTitleValue}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEnglishTileOnChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].ArabicTitle}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="arabicTitle"
                                name="arabicTitle"
                                value={this.state.arabicTitle}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleArabicTitle}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                </MDBRow>

                <div className={this.props.isArabic ? "collapseAr" : ""}>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                       
                        <div className="card-body">
                            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].permission}
                                        </label>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].admin} open={true} >

                            <MDBRow>

                                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleIsAdmin}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.isadmin ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].isadmin}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].report} open={true} >

<MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
        <div className="form-group">
            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <div>
                    <input type="checkbox" onChange={this.handleCheckCanViewReport}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canviewreport ? true : ''}
                    />
                </div>
                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                    {i18n[this.props.isArabic ? 'ar' : 'en'].report}
                </label>
            </div>
            </div>
            </MDBCol>
            
    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
        <div className="form-group">
            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <div>
                    <input type="checkbox" onChange={this.handleCheckcanViewEmployeeRepor}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canViewEmployeeReport ? true : ''}
                    />
                </div>
                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                    {i18n[this.props.isArabic ? 'ar' : 'en'].canViewEmployeeReport}
                </label>
            </div>
        </div>
        
    </MDBCol>

    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
        <div className="form-group">
            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <div>
                    <input type="checkbox" onChange={this.handelChekcanGenerateAllreports}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canGenerateAllreports ? true : ''}
                    />
                </div>
                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                    {i18n[this.props.isArabic ? 'ar' : 'en'].canGenerateAllreports}
                </label>
            </div>
        </div>
        
    </MDBCol>

</MDBRow>

</Collapsible> 

<Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].canCloseRequest} open={true} >

<MDBRow>

    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
        <div className="form-group">
            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <div>
                    <input type="checkbox" onChange={this.handleCheckcanCloseRequest}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canCloseRequest ? true : ''}
                    />
                </div>
                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                    {i18n[this.props.isArabic ? 'ar' : 'en'].canCloseRequest}
                </label>
            </div>
        </div>
    </MDBCol>

</MDBRow>

</Collapsible>
</MDBRow>
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
<Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.subject} open={false} >
<MDBRow>

<MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanViewOHI}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canViewOHI ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canViewOHI}
            </label>
        </div>
    </div>
</MDBCol>

</MDBRow>
</Collapsible>
                    </MDBRow>
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
<Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.subject} open={false} >
<MDBRow><MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanViewDisciplinaryboard}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canViewDisciplinaryboard ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canViewDisciplinaryboard}
            </label>
        </div>
    </div>
</MDBCol>
<MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanCreateDisciplinaryboard}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canCreateDisciplinaryboard ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canCreateDisciplinaryboard}
            </label>
        </div>
    </div>
</MDBCol>
<MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanEditDisciplinaryboard}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canEditDisciplinaryboard ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canEditDisciplinaryboard}
            </label>
        </div>
    </div>
</MDBCol>
<MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanAssignDisciplinaryboardToDGoffice}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canAssignDisciplinaryboardToDGoffice ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canAssignDisciplinaryboardToDGoffice}
            </label>
        </div>
    </div>
</MDBCol>
</MDBRow>
<MDBRow>
<MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanAssignDisciplinaryboardtoEmployee}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canAssignDisciplinaryboardtoEmployee ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canAssignDisciplinaryboardtoEmployee}
            </label>
        </div>
    </div>
</MDBCol>
<MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanAddReplayDisciplinaryboard}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canAddReplayDisciplinaryboard ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canAddReplayDisciplinaryboard}
            </label>
        </div>
    </div>
</MDBCol>

</MDBRow>
</Collapsible>
                    </MDBRow>
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
<Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.subject} open={false} >
<MDBRow>

<MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanCreateWorkShop}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canCreateWorkShop ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canCreateWorkShop}
            </label>
        </div>
    </div>
</MDBCol>

<MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
    <div className="form-group">
        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
            <div>
                <input type="checkbox" onChange={this.handleCheckcanBookWorkShop}
                    style={{ cursor: 'pointer' }}
                    checked={this.state.canBookWorkShop ? true : ''}
                />
            </div>
            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                {i18n[this.props.isArabic ? 'ar' : 'en'].canBookWorkShop}
            </label>
        </div>
    </div>
</MDBCol>

</MDBRow>
</Collapsible>
                    </MDBRow>
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].SCI_Request} open={false} >

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitSciReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitscirequest ? true : ''}
                                                />
                                            </div>

                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitscirequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReviewSciReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreviewscirequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreviewscirequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleCheckCanEditSciReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditscirequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditscirequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleCheckCanUpdateSciStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdatescistatus ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatescistatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleCheckCanAddSciComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddscicomment ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddscicomment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleCheckCanAssignSciDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignscidepartment ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignscidepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleCheckCanAssignSciEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canasignsciemployee ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canasignsciemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox" onChange={this.handleCheckCanProvideSciFeedback}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canprovidescifeedback ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidescifeedback}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox" onChange={this.handleCheckCanViewSciFeedback}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canviewscifeedback ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewscifeedback}
                                            </label>
                                        </div>

                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignSCISector}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignscisector ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignscisector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckForCanShowSCIComment}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canshowscicomments ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowscicomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckForCanAssignSciGm}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignscigm ? true : ''}
                                            /></div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignscigm}
                                            </label>
                                        </div>
                                    </div>

                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckForCanAssignSciBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignscibacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignscibacktoentity}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/*organization structure */} 
                     <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                      <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].ORG_Request} open={true} >

                     <MDBRow>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox"
                            onChange={this.handleCheckCanSubmitorgReq}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.cansubmitorgrequest ? true : ''}
                        />
                    </div>

                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canSubmitORGRequest}
                    </label>
                </div>
                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox"
                            onChange={this.handleCheckCanVieworgReq}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canvieworgrequest ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canViewORGRequest}
                    </label>
                </div>
                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox" onChange={this.handleCheckCanEditorgReq}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.caneditorgrequest ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canEditORGRequest}
                    </label>
                </div>
                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox" onChange={this.handleCheckCanUpdateorgStatus}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canupdateorgstatus ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canUpdateORGStatus}
                    </label>
                </div>
            </div>
        </MDBCol>

    </MDBRow>

    <MDBRow>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox" onChange={this.handleCheckCanAddorgComment}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canaddorgcomment ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canAddORGComment}
                    </label>
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox" onChange={this.handleCheckCanAssignorgDepartment}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canassignorgdepartment ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canAssignORGDepartment}
                    </label>
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox" onChange={this.handleCheckCanAssignorgEmployee}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canasignorgemployee ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canAssignORGEmployee}
                    </label>
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div><input type="checkbox" onChange={this.handleCheckCanProvideorgFeedback}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canprovideorgfeedback ? true : ''}
                    /></div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canProvideORGFeddback}
                    </label>
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div><input type="checkbox" onChange={this.handleCheckCanVieworgFeedback}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canvieworgfeedback ? true : ''}
                    /></div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canViewORGFeddback}
                    </label>
                </div>

                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div><input type="checkbox"
                        onChange={this.handleCheckCanAssignorgSector}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canassignorgsector ? true : ''}
                    /></div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignORGsector}
                    </label>
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div><input type="checkbox"
                        onChange={this.handleCheckForCanShoworgComments}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canshoworgcomments ? true : ''}
                    /></div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowORGcomments}
                    </label>
                </div>
            </div>
        </MDBCol>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div><input type="checkbox"
                        onChange={this.handleCheckForCanAssignorgGm}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.canassignorggm ? true : ''}
                    /></div>
                    <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignORGGM}
                    </label>
                </div>
            </div>

        </MDBCol>

    </MDBRow>

    <MDBRow>

        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox"
                            onChange={this.handleCheckForCanAssignorgBackToEntity}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canassignorgbacktoentity ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignORGBackToEntity}
                    </label>
                </div>
                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>
        <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox"
                            onChange={this.handleCheckCanOpenCreationStage}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canopencreationstage ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canOpenCreationStage}
                    </label>
                </div>
                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>

   <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
            <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <div>
                        <input type="checkbox"
                            onChange={this.handleCheckCanViewtree}
                            style={{ cursor: 'pointer' }}
                            checked={this.state.canviewtree ? true : ''}
                        />
                    </div>
                    <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewtree}
                    </label>
                </div>
                <div className="invalid-feedback">
                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                </div>
            </div>
        </MDBCol>

    </MDBRow>

</Collapsible>

</MDBRow>

                   
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].informationReq}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanSubmitInfoReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.cansubmitirrequest ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitirrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanViewInfoReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canviewirrequest ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewirrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanEditInfoReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.caneditirrequest ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditirrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanUpdateInfoStatus}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canupdateirstatus ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateirstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

</MDBRow>

                           

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignInfoDepartment}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignirdepartment ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignirdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanProvideInfoReqFeedback}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canprovideirfeedback ? true : ''}
                                            /></div>

                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideirfeedback}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignIrGm}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignirgm ? true : ''}
                                            /></div>

                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignirgm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignIrBackToEntity}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignirbacktoentity ? true : ''}
                                            /></div>

                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignirbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].exemptionFromAccompanyPatient}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanSubmitExmAccPatientReq} style={{ cursor: 'pointer' }}
                                                checked={this.state.cansubmitpatientrequest ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitpatientrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanReviewExmAccPatientReq} style={{ cursor: 'pointer' }}
                                                checked={this.state.canviewpatientrequest ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewpatientrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanEditExmAccPatientReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.caneditpatientrequest ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditpatientrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanUpdateExmAccPatientStatus} style={{ cursor: 'pointer' }}
                                                checked={this.state.canupdatepatientstatus ? true : ''}
                                            />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatepatientstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAddExmAccPatientComment} style={{ cursor: 'pointer' }}
                                                checked={this.state.canaddpatientcomments ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddpatientcomments}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanShowExmAccPatientComment} style={{ cursor: 'pointer' }}
                                                checked={this.state.canshowpatientcomments ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowpatientcomments}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignResearchDepartForPatient} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignpatientdepartment ? true : ''}
                                                /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignpatientdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignResearchEmpForPatient} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignpatientemployee ? true : ''}
                                                /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignpatientemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanProvideResearchStudyForPatient} style={{ cursor: 'pointer' }}
                                                checked={this.state.canprovidepatientstudy ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidepatientstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanReceivesResearchStudyForPatient} style={{ cursor: 'pointer' }}
                                                checked={this.state.canreceivepatientstudy ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivepatientstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignReserchSectorForPatient}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignpatientsector ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignpatientsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignGmPatient}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignpatientgm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignpatientgm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignBackToEntityPatient}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignpatientbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignpatientbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].reviewHrPolices}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitHrPolicyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitreviewhrrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitreviewhrrequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewHrPolicyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewreviewhrrequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewreviewhrrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditHrPolicyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditreviewhrrequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditreviewhrrequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateHrPolicyStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdatereviewhrstatus ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatereviewhrstatus}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAddHrPolicyComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddreviewhrcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddreviewhrcomments}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowHrPoliciesComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowreviewhrcomments ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowreviewhrcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignPoliciesSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhrsector ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhrsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignPoliciesDept}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhrdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhrdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignPoliciesEmp}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhremployee ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhremployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvidePoliciesStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovidehrstudy ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidehrstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckcanreceivehrstudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceivehrstudy ? true : ''}
                                                />
                                                </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivehrstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckcanassignhrgm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhrgm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhrgm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckcanassignhrBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhrbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhrbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].exemptionFromADHousPolicy} >

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitExmADHousingPolicyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitadhousingrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitadhousingrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewExmADHousingPolicyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewadhousingrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewadhousingrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditExmADHousingPolicyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditadhousingrequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditadhousingrequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateExmADHousingPolicyStatus} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateadhousingstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateadhousingstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAddExmADHousingPolicyComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddadhousingcomments ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddadhousingcomments}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowAdHousingComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowadhousingcomments ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowadhousingcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignResearchDepartForAdHousing}
                                                    checked={this.state.canassignhousingdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhousingdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignResearchEmpForAdHousing} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignhousingemployee ? true : ''}
                                            /></div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhousingemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvideResearchStudyForAdHousing} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovidehousingstudy ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidehousingstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReceivesResearchStudyForAdHousing}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceivehousingstudy ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivehousingstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignReserachSectorForAdHousing}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhousingsector ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhousingsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignGmForAdHousing}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhousinggm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhousinggm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignBackToEntityForAdHousing}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignhousingbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel" htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignhousingbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].secndmentForFedralAgency} >

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitSecndmentForFrdrlAgencyReq} style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitsecondmentrequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitsecondmentrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewSecndmentForFrdrlAgencyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewsecondmentrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewsecondmentrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanEditSecndmentForFrdrlAgencyReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditsecondmentrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditsecondmentrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateSecndmentForFrdrlAgencyStatus} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdatesecondmentstatus ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatesecondmentstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAddSecndmentForFrdrlAgencyComment} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddsecondmentcomments ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddsecondmentcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowSecondementComment} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowsecondmentcomments ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowsecondmentcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignResearchDepartForSecondement} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignsecondmentdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignsecondmentdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignResearchEmpForSecondement} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignsecondmetnemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignsecondmetnemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvideResearchStudyForSecondement} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovidesecondmentstudy ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidesecondmentstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReceivesResearchStudyForSecondement} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceivesecondmentstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivesecondmentstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignResearchSecotrForSecondement}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignsecondmentsector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignsecondmentsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignGmSecondement}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignsecondmentgm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignsecondmentgm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignBackToEntitySecondement}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignsecondmentbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignsecondmentbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].bonusRequest}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitBonusRequestReq} style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitbonusrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitbonusrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewBonusRequestReq} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewbonusrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewbonusrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditBonusRequestReq} style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditbonusrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditbonusrequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateBonusRequestStatus} checked={this.state.canupdatebonusstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatebonusstatus}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAddBonusRequestComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddbonuscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddbonuscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox" onChange={this.handleCheckCanShowBonusRequestReqComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowbonuscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowbonuscomments}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanAssignSalariesDepartForBR} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignbounsdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignbounsdepartment}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignSalariesEmpForBR} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignbounsemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignbounsemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvideSalariesForBR} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovidebonusstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidebonusstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanReceivesSalStudyForBR}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceivebonusstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivebonusstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignSalariesSeactorForBR}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignbonussector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignbonussector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignGmForBR}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignbonusgm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignbonusgm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignBackToEntityForBR}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignbonusbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignbonusbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].amendSalaryScale}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanSubmitAmendSalaryScaleReq} style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitamendscalerequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitamendscalerequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanViewAmendSalaryScaleReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewamendscalerequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewamendscalerequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanEditAmendSalaryScaleReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditamendscalerequest ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditamendscalerequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanUpdateAmendSalaryScaleStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateamendscalestatus ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateamendscalestatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanAddAmendSalaryScaleComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddamendscalecomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddamendscalecomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanShowAmendSalScaleComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowamendscalecomments ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowamendscalecomments}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanAssignSalariesDepartForAmendSalScale}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendscaledepartment ? true : ''}
                                                />
                                            </div>
                                            <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{
                                                display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                            }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendscaledepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignSalariesEmpForAmendSalScale} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendscaleemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendscaleemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanProvideSalariesForAmendSalScale}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideamendscalestudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideamendscalestudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReceivesSalStudyForAmendSalScale} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveamendscalestudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveamendscalestudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignSalariesSectorForAmendSalScale} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendscalesector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendscalesector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignGmForAmendSalScale} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendscalegm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendscalegm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignBackToEntityForAmendSalScale} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendscalebacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendscalebacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].amendEmpSalary} >

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    onChange={this.handleCheckCanSubmitAmendEmpSalaryReq}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitamendsalaryrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitamendsalaryrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanViewAmendEmpSalaryReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canviewamendsalaryrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewamendsalaryrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanEditAmendEmpSalaryReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.caneditamendsalaryrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditamendsalaryrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateAmendEmpSalaryStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateamendsalarystatus ? true : ''}
                                                /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateamendsalarystatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAddAmendEmpSalaryComment}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canaddamendsalarycomments ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddamendsalarycomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowAmendSalaryComment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowamendsalarycomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowamendsalarycomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignSalariesDepartForAmendEmpSal} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignamendsalarydepartment ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsalarydepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignSalariesEmpForAmendEmpSal}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignamendsalaryemployee ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsalaryemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanProvideSalariesForAmendEmpSal} style={{ cursor: 'pointer' }}
                                                checked={this.state.canprovideamendsalarystudy ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideamendsalarystudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanReceivesSalStudyForAmendEmpSal} style={{ cursor: 'pointer' }}
                                                checked={this.state.canreceiveamendsalarystudy ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveamendsalarystudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignSalariesSectorForAmendEmpSal} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignamendsalarysector ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsalarysector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignGmForAmendEmpSal} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignamendsalarygm ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsalarygm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignBackToEntityForAmendEmpSal} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignamendsalarybacktoentity ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsalarybacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].retirement} >

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanSubmitRetirementReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.cansubmitretirementrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitretirementrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanViewRetirementReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canviewretirementrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewretirementrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanEditRetirementReq}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.caneditretirementrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditretirementrequest}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanUpdateRetirementStatus}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canupdateretirementstatus ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateretirementstatus}
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAddRetirementComment}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.canaddretirementcomments ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddretirementcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowRetirementComment} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowretirementcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowretirementcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignResearchDepartForRetirement} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignretirementdepartment ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignretirementdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanAssignResearchEmpForRetirement} style={{ cursor: 'pointer' }}
                                                checked={this.state.canassignretirementemployees ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignretirementemployees}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvideResearchStudyForRetirement}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideretirementstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideretirementstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReceivesResearchStudyForRetirement} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveretirementstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveretirementstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignReserchSectorForRetirment} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignretirementsector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignretirementsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignGmForRetirment} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignretirementgm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignretirementgm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>
                                
                            </MDBRow>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignBackToEntityForRetirment} style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignretirementbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignretirementbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                        </Collapsible>
                    
                    </MDBRow>

                    {/* extend create objectives */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].extendCreateObjectives}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanSubmitExtendCreateRequest}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.cansubmitextendcreaterequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitextendcreaterequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewextendcreaterequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewExtendCreateRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewextendcreaterequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewextendcreaterequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditextendcreaterequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditExtendCreateRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditextendcreaterequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditextendcreaterequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateextendcreatestatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateExtendCreateStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateextendcreatestatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateextendcreatestatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowextendcreatecomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowExtendCreateComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowextendcreatecomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowextendcreatecomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddextendcreatecomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddExtendCreateComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddextendcreatecomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddextendcreatecomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideextendcreatestudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideExtendCreateStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideextendcreatestudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideextendcreatestudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveextendcreatestudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveExtendCreateStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveextendcreatestudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveextendcreatestudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                                
                                {/* canassignextendcreatesector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendCreateSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendcreatesector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendcreatesector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendcreatedepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendCreateDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendcreatedepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendcreatedepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendcreateemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendCreateEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendcreateemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendcreateemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendcreatebacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendCreateBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendcreatebacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendcreatebacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignextendcreatebacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendCreateBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendcreatebacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendcreatebacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendCreateGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendcreaterequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendcreaterequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/* extend review objectives */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].extendReviewObjectives}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanSubmitExtendReviewRequest}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.cansubmitextendreviewrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitextendreviewrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewextendreviewrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewExtendReviewRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewextendreviewrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewextendreviewrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditextendreviewrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditExtendReviewRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditextendreviewrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditextendreviewrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateextendreviewstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateExtendReviewStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateextendreviewstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateextendreviewstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                                {/* canshowextendreviewcomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowExtendReviewComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowextendreviewcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowextendreviewcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddextendreviewcomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddExtendReviewComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddextendreviewcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddextendreviewcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideextendreviewstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideExtendReviewStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideextendreviewstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideextendreviewstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveextendreviewstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveExtendReviewStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveextendreviewstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveextendreviewstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignextendreviewsector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendReviewSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendreviewsector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendreviewsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendreviewdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendReviewDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendreviewdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendreviewdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendreviewemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendReviewEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendreviewemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendreviewemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendreviewbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendReviewBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendreviewbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendreviewbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignextendcreatebacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendReviewBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendreviewbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendreviewbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendReviewGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendreviewrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendreviewrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/* extend annual objectives */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationInterval}>

                            <MDBRow>
                              
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div><input type="checkbox"
                                                onChange={this.handleCheckCanSubmitExtendAnnualRequest}
                                                style={{ cursor: 'pointer' }}
                                                checked={this.state.cansubmitextendannualrequest ? true : ''}
                                            /></div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitextendannualrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewExtendAnnualRequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewExtendAnnualRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewextendannualrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewextendannualrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditExtendAnnualRequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditExtendAnnualRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditextendannualrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditextendannualrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateextendannualstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateExtendAnnualStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateextendannualstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateextendannualstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowextendannualcomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowExtendAnnualComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowextendannualcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowextendannualcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddextendannualcomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddExtendAnnualComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddextendannualcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddextendannualcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideextendannualstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideExtendAnnualStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideextendannualstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideextendannualstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveextendannualstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveExtendAnnualStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveextendannualstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveextendannualstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignextendannualsector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendAnnualSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendannualsector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendannualsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendannualdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendAnnualDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendannualdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendannualdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendannualemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendAnnualEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendannualemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendannualemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignextendannualbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendAnnualBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendannualbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendannualbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignextendcreatebacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendAnnualBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendannualbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendannualbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignExtendAnnualGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignextendannualrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignextendannualrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/* add/edit emp perf */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluation}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitEmployeePerfRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitemployeeperformancerequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitemployeeperformancerequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewemployeeperformancerequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewEmpPerfRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewemployeeperformancerequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewemployeeperformancerequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditemployeeperformancerequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditEmpPerfRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditemployeeperformancerequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditemployeeperformancerequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateemployeeperformancestatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateEmpPerfStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateemployeeperformancestatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateemployeeperformancestatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowemployeeperformancecomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowEmpPerfComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowemployeeperformancecomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowemployeeperformancecomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddemployeeperformancecomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddEmpPerfComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddemployeeperformancecomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddemployeeperformancecomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideemployeeperformancestudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideEmpPerfStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideemployeeperformancestudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideemployeeperformancestudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveemployeeperformancestudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveEmpPerfStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveemployeeperformancestudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveemployeeperformancestudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignemployeeperformancesector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignEmpPerfSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignemployeeperformancesector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>

                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignemployeeperformancesector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignemployeeperformancedepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignEmpPerfDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignemployeeperformancedepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignemployeeperformancedepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignemployeeperformanceemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignEmpPerfEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignemployeeperformanceemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignemployeeperformanceemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignemployeeperformancebacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignEmpPerfBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignemployeeperformancebacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignemployeeperformancebacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignemployeeperformancebacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignEmpPerfBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignemployeeperformancebacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignemployeeperformancebacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignEmpPerfGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignemployeeperformancerequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignemployeeperformancerequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>
                            
                        </Collapsible>

                    </MDBRow>

                    {/* join and implement */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntity}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitJoinImpleRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitjoinimplementrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitjoinimplementrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewjoinimplementrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewJoinImpleRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewjoinimplementrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewjoinimplementrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditjoinimplementrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditJoinImpleRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditjoinimplementrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditjoinimplementrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdatejoinimplementstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateJoinImpleStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdatejoinimplementstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatejoinimplementstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowjoinimplementcomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowJoinImpleComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowjoinimplementcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowjoinimplementcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddjoinimplementcomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddJoinImpleComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddjoinimplementcomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddjoinimplementcomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovidejoinimplementstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideJoinImpleStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovidejoinimplementstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidejoinimplementstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceivejoinimplementstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveJoinImpleStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceivejoinimplementstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivejoinimplementstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignjoinimplementsector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignJoinImpleSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignjoinimplementsector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignjoinimplementsector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignjoinimplementdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignJoinImpleDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignjoinimplementdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignjoinimplementdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignjoinimplementemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignJoinImpleEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignjoinimplementemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignjoinimplementemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignjoinimplementbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignJoinImpleBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignjoinimplementbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignjoinimplementbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignjoinimplementbacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignJoinImpleBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignjoinimplementbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignjoinimplementbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignJoinImpleGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignjoinimplementrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignjoinimplementrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>                

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/* open Complain */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].openComplainsStage}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitopencomplainsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitopencomplainsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewopencomplainsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewopencomplainsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewopencomplainsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditopencomplainsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditopencomplainsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditopencomplainsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateopencomplainsstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateopencomplainsstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>

                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateopencomplainsstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowopencomplainscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowopencomplainscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowopencomplainscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddopencomplainscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAddOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddopencomplainscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddopencomplainscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideopencomplainsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvideOpenComplainRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideopencomplainsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideopencomplainsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveopencomplainsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReceiveOpenComplainStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveopencomplainsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveopencomplainsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignopencomplainssector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignOpenComplainSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignopencomplainssector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignopencomplainssector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignopencomplainsdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignOpenComplainDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignopencomplainsdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignopencomplainsdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignopencomplainsemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignOpenComplainEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignopencomplainsemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignopencomplainsemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignopencomplainsbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignOpenComplainBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignopencomplainsbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignopencomplainsbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignopencomplainsbacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignOpenComplainsBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignopencomplainsbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignopencomplainsbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignOpenComplainsGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignopencomplainsrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignopencomplainsrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/* amend systems */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystem}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitamendsystemsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitamendsystemsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewamendsystemsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewamendsystemsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewamendsystemsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditamendsystemsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditamendsystemsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditamendsystemsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateamendsystemsstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateamendsystemsstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateamendsystemsstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowamendsystemscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanShowAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowamendsystemscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowamendsystemscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddamendsystemscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAddAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddamendsystemscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddamendsystemscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideamendsystemsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanProvideAmendSystemRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideamendsystemsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideamendsystemsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveamendsystemsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanReceiveAmendSystemStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveamendsystemsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveamendsystemsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignamendsystemssector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignAmendSystemSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendsystemssector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsystemssector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignamendsystemsdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignAmendSystemDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendsystemsdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsystemsdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignamendsystemsemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignAmendSystemEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendsystemsemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsystemsemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignamendsystemsbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignAmendSystemBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendsystemsbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsystemsbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignamendsystemsbacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignAmendSystemsBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendsystemsbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsystemsbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanAssignAmendSystemsGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignamendsystemsrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignamendsystemsrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/*report and stats */}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequest}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitReportAndStaticRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitreportsstaticsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitreportsstaticsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewreportsstaticsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewReportAndStaticRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewreportsstaticsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewreportsstaticsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditreportsstaticsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditReportAndStaticRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditreportsstaticsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditreportsstaticsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdatereportsstaticsstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateReportAndStaticStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdatereportsstaticsstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdatereportsstaticsstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowreportsstaticscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowReportAndStaticComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowreportsstaticscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowreportsstaticscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddreportsstaticscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddReportAndStaticComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddreportsstaticscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddreportsstaticscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovidereportsstaticsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideReportAndStaticStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovidereportsstaticsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovidereportsstaticsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceivereportsstaticsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveReportAndStaticStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceivereportsstaticsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceivereportsstaticsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignreportsstaticssector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignReportAndStaticSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignreportsstaticssector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignreportsstaticssector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignreportsstaticsdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignReportAndStaticDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignreportsstaticsdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignreportsstaticsdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignreportsstaticsemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignReportAndStaticEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignreportsstaticsemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignreportsstaticsemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignreportsstaticsbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignReportAndStaticBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignreportsstaticsbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignreportsstaticsbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignreportsstaticsbacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignReportAndStaticBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignreportsstaticsbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignreportsstaticsbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignReportAndStaticGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignreportsstaticsrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignreportsstaticsrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                    {/*other req*/}
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <Collapsible trigger={i18n[this.props.isArabic ? 'ar' : 'en'].OtherRequests}>

                            <MDBRow>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanSubmitOtherRequestRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.cansubmitotherrequestsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.cansubmitotherrequestsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canviewotherrequestsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanViewOtherRequestRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canviewotherrequestsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canviewotherrequestsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* caneditotherrequestsrequest */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanEditOtherRequestRequest}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.caneditotherrequestsrequest ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.caneditotherrequestsrequest}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canupdateotherrequestsstatus */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckCanUpdateOtherRequestStatus}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canupdateotherrequestsstatus ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canupdateotherrequestsstatus}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canshowotherrequestscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleShowOtherRequestComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canshowotherrequestscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canshowotherrequestscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canaddotherrequestscomments */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAddOtherRequestComments}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canaddotherrequestscomments ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canaddotherrequestscomments}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canprovideotherrequestsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleProvideOtherRequestStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canprovideotherrequestsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canprovideotherrequestsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canreceiveotherrequestsstudy */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleReceiveOtherRequestStudy}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canreceiveotherrequestsstudy ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canreceiveotherrequestsstudy}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignotherrequestssector */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignOtherRequestSector}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignotherrequestssector ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignotherrequestssector}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignotherrequestsdepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignOtherRequestDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignotherrequestsdepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignotherrequestsdepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignotherrequestsemployee */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignOtherRequestEmployee}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignotherrequestsemployee ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignotherrequestsemployee}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                {/* canassignotherrequestsbacktodepartment */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignOtherRequestBackToDepartment}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignotherrequestsbacktodepartment ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignotherrequestsbacktodepartment}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>

                                {/* canassignotherrequestsbacktoentity */}
                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignOtherRequestBackToEntity}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignotherrequestsbacktoentity ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignotherrequestsbacktoentity}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                                    <div className="form-group">
                                        <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            <div>
                                                <input type="checkbox"
                                                    onChange={this.handleAssignOtherRequestGm}
                                                    style={{ cursor: 'pointer' }}
                                                    checked={this.state.canassignotherrequesttogmsrequesttogm ? true : ''}
                                                />
                                            </div>
                                            <label className="roleLabel"
                                                htmlFor="formGroupExampleInput" style={{
                                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : ''
                                                }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.canassignotherrequesttogmsrequesttogm}
                                            </label>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>

                            <MDBRow>
                            </MDBRow>

                        </Collapsible>

                    </MDBRow>

                </div>

                <MDBRow
                    style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: "10px" }}>
                    <MDBBtn className="submitBtn" type='submit' color="primary" style={{ borderRadius: 5 }} >{i18n[this.getLocale()].submit}</MDBBtn>
                </MDBRow>

            </form>
        );
    }
}