import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import i18n from './../../i18n';
import { assginSectorPayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import Config from './../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
//import DatePicker from "react-datepicker";
import ToastComponent from './../ToastComponent';

let moment = require('moment');

export default class AssignToSector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            sectorArray: [],
            comment: '',
            assignValueToSector: '',
            selectedDate: '',
            requestType: '',
            pathName: ''
        }
        this.handleSelectedDate = this.handleSelectedDate.bind(this);
    }

    componentDidMount = () => {
        // set date to after faive days bt default
        // lastTimeToReply: new Date(`${editObj.lastTimeToReply}`)
        let dateAfterFiveDays = moment(new Date(), "DD-MM-YYYY").add(5, 'days');
        this.setState({
            selectedDate: new Date(`${dateAfterFiveDays}`)
        })

        let tempSectorData = [];
        if (Config.getSectorData()) {
            tempSectorData = Config.getSectorData();
        }

        // let pathName = '';
        let pathName = localStorage.getItem('currentPath');
        if (pathName) {
            if (this.props.requesttypeid === Config.getSciRequestTypeId()) {
                this.setState({
                    //requestType: Config.getSciRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.setState({
                    //requestType: Config.getInformationRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                this.setState({
                    //requestType: Config.getBonusRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.setState({
                    //requestType: Config.getAmendSalaryScaleRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.setState({
                    //requestType: Config.getAmendEmployeeSalaryRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId) {
                this.setState({
                    //requestType: Config.getExceptionAcompanypatientRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.setState({
                    //requestType: Config.getHrReviewPolicyRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.setState({
                    //requestType: Config.getExceptionADHousingRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.setState({
                    //requestType: Config.getSecondmentForFederalAgencyRequestTypeId(),
                    pathName: pathName
                })
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
                this.setState({
                    //requestType: Config.getRetirmentRequestTypeId(),
                    pathName: pathName
                })
            }
            else if (this.props.requesttypeid === Config.getORGRequestTypeId()) {
                this.setState({
                    //requestType: Config.getRetirmentRequestTypeId(),
                    pathName: pathName
                })
            }
        }

        this.setState({
            
            sectorArray: tempSectorData

        })
    }

    handleSelectedDate = (date) => {
       
        let tempDate = new Date(date);
        let day = tempDate.getDate();
        let month = tempDate.getMonth();
        let year = tempDate.getFullYear();

        let finalDate = year + '-' + month + '-' + day;
       

        this.setState({
            selectedDate: date,
            dateReplyError: ""
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
       
        if (this.props.requesttypeid === Config.getSciRequestTypeId() || this.props.requesttypeid === Config.getSCILEgalSerbviceId()  || this.props.requesttypeid === Config.getSCIComplainId() || this.props.requesttypeid === Config.getSCILEgalSuggestionId()|| this.props.requesttypeid === Config.getSCIGeneralId() || this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest') {
            if (this.state.assignValueToSector !== '') {
                let tempDate = new Date();
                let day = tempDate.getDate();
                let month = tempDate.getMonth();
                let year = tempDate.getFullYear();
                let finalDate = year + '-' + month + '-' + day;

                let obj = {};
                obj.requestid = this.props.id;
                // obj.requesttype = this.state.requestType;
                obj.requesttype = this.props.requesttypeid;
                obj.notes = this.state.comment;
                obj.sectorid = parseInt(this.state.assignValueToSector);
                obj.sectorid = parseInt('0');
                obj.targetdate = finalDate;

                // call assign sector
                this.callAssignSector(obj);
            }
        } else {
            let tempDate = new Date();
            let day = tempDate.getDate();
            let month = tempDate.getMonth();
            let year = tempDate.getFullYear();
            let finalDate = year + '-' + month + '-' + day;

            let obj = {};
            obj.requestid = this.props.id;
            // obj.requesttype = this.state.requestType;
            obj.requesttype = this.props.requesttypeid;
            obj.notes = this.state.comment;
            // obj.sectorid = parseInt(this.state.assignValueToSector);
            obj.sectorid = parseInt('0');
            obj.targetdate = finalDate;

            // call assign sector
            this.callAssignSector(obj);
        }

    };


    callAssignSector = (obj) => {
        obj.statusid = this.props.currentStatusId;
        obj.reasonid = this.props.currentStatusReasonId;
        obj.requestnumber = this.props.currentRequestNumber;
        obj.sectorid = this.state.assignValueToSector;
        // call save status update api
        let payload = assginSectorPayload(obj);
        let endPoint = 'AssignSector'
        let url = Config.getUrl(endPoint);
        debugger;
        debugger;
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        this.props.toggle(true);
                    } else {
                        this.props.toggle(false);
                        ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoassignsector);
                    }
                })
            })
        })
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    render() {

        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        } else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        let title = this.props.title;
        // var flag = false;
        if (title === 'assigntodep') {
            // flag = true;
        }

        return (
            <MDBContainer style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>
                        {
                            this.props.requesttypeid === Config.getSciRequestTypeId() || this.props.requesttypeid === Config.getSCILEgalSerbviceId()  || this.props.requesttypeid === Config.getSCIComplainId() || this.props.requesttypeid === Config.getSCILEgalSuggestionId()|| this.props.requesttypeid === Config.getSCIGeneralId() || this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest'
                                ?
                                <MDBCol style={{ width: '100%', padding: 0 }}>
                                    <span style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].select_sector}</span>
                                    <br />
                                    <select
                                        className="browser-default custom-select" id="sources-funding"
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                        value={this.state.assignValueToSector}
                                        onChange={(event) => {
                                            this.setState({
                                                assignValueToSector: event.target.value,
                                                // statusUpdateInfoError: ''
                                            }, () => {
                                            
                                            })
                                        }}
                                        required>
                                        <option value="">-{i18n[this.props.isArabic ? 'ar' : 'en'].select}-</option>
                                        {
                                            this.state.sectorArray ? this.state.sectorArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}
                                                </option>

                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                </MDBCol>
                                :
                                null
                        }

                        {
                            this.props.requesttypeid === Config.getSciRequestTypeId() || this.props.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest' || this.state.pathName === 'SCIRequest'
                                ?
                                <br />
                                :
                                null
                        }

                       
                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                            <MDBCol md={12} style={{ display: 'flex', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className="form-group">
                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                    </label>
                                    <textarea
                                        className="form-control" id="exampleFormControlTextarea2" rows="2" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        // required
                                        onChange={(event) => {
                                            this.setState({
                                                comment: event.target.value
                                            })
                                        }}
                                    />
                                    {
                                    }
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }} >
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].SCI.submit}
                            </MDBBtn>
                            <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
                        </MDBRow>
                    </LoadingOverlay>
                </form>
            </MDBContainer>
        );
    }
}