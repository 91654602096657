import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBBtn } from "mdbreact";
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import i18n from '../i18n';
import SubHeaderStrip from '../components/SubHeaderStrip';
import Config from '../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import Footer from './../components/Footer';
import PreviewItem from './../components/PreviewItem';
import PreviewAttachment from './../components/PreviewAttachment';
import PreviewAttachmentMultiple from './../components/PreviewAttachmentMultiple';
import PreviewCKEditor from './../components/PreviewCKEditor';
import PreviewPatientItem from './../components/PreviewPatientItem';
import CurrencyFormat from 'react-currency-format';

var pathname;
const striptags = require('striptags');

let dataFromProps = {};
let attachmentArrProps = [];
let ckEditorArrFromProps = [];

export default class Preview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isArabic: localStorage.getItem("Lang") === "ar" ? true : false,
            previewData: {},
            previewDataNew: [],
            patientArray: [],
            previewAttachmentMultipleArr: [],
            previewSingleAttachmentArr: [],

            previewAttachmentArr: [],
            previewckEditorArr: [],

            //Patient variables
            basicSalaryTable: '',
            retirementSalary: '',
            subTotalTotal: '',
            totalAmountExempted: '',
            totalNoOfDays: '',
            totalSalary: '',
            totalSalaryWithSubscription: '',
            tswsrsSalary: '',

            lastIndex: '',
            previewDataCount: '',
            borderFlag: false
        };

        this.colorArray = [];
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        if (this.props.location && this.props.location.state && this.props.location.state.data) {
            this.setState({
                previewData: this.props.location.state.data,
                previewAttachmentArr: this.props.location.state.attachmentArr,
                previewckEditorArr: this.props.location.state.ckEditorArr
            }, () => {

              

                //Set Preview Data in Config For Edit again
                Config.setIsPreviewFlag(true);
                Config.setPreviewObj(this.state.previewData);
                Config.setPreviewAttachmentArray(this.state.previewAttachmentArr);
                Config.setPreviewckEditorArray(this.state.previewckEditorArr);

                let dataObj = this.state.previewData;
                let keys = Object.keys(dataObj);
                let temp = [];

                //Patient table variables
                let basicSalaryTable = '',
                    retirementSalary = '',
                    subTotalTotal = '',
                    totalAmountExempted = '',
                    totalNoOfDays = '',
                    totalSalary = '',
                    totalSalaryWithSubscription = '',
                    tswsrsSalary = '',
                    tableArray = [];
                    
                    debugger;
                for (let i = 0; i < keys.length; i++) {

                  
                    if (
                        keys[i] !== 'requestId' &&
                        keys[i] !== 'isEdit' &&
                        keys[i] !== 'assignedEntitySelectedValue' &&
                        keys[i] !== 'typeOfRequest' &&
                        keys[i] !== 'requestType' &&
                        keys[i] !== 'nationality' &&
                        keys[i] !== "nationalOrNot" &&
                        keys[i] !== 'typeOfContract' &&
                        keys[i] !== 'selectedOption' &&
                        keys[i] !== 'sourcesFunding' &&
                        keys[i] !== 'studiesShortName' &&
                        keys[i] !== 'previousandCurrentApprovedCompetencyReportShortName' &&
                        keys[i] !== 'illustrationsOfCurrentShortName' &&
                        keys[i] !== 'resultsOfEvaluationGeneralPerformanceShortName' &&
                        keys[i] !== 'uploadTheContractsShortName' &&
                        keys[i] !== 'supportRequestShortName' &&
                        keys[i] !== 'medicalApprovalsFromTheCometentMedicalCommitteeShortName' &&
                        keys[i] !== 'acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName' &&
                        keys[i] !== 'documentationOfFinancialObligationsShortName' &&
                        keys[i] !== 'otherAttachmentShortName' &&
                        typeof dataObj[keys[i]] !== 'object'
                    ) {

                       

                        let flag = true;

                        //For Patient table
                        if (pathname === 'exemptionFromAccompanyPatient') {
                            if (keys[i] === 'basicSalaryTable') {
                                basicSalaryTable = dataObj['basicSalaryTable'];
                                flag = false;
                            }
                            if (keys[i] === 'retirementSalary') {
                                retirementSalary = dataObj['retirementSalary'];
                                flag = false;
                            }
                            if (keys[i] === 'subTotalTotal') {
                                subTotalTotal = dataObj['subTotalTotal'];
                                flag = false;
                            }
                            if (keys[i] === 'totalAmountExempted') {
                                totalAmountExempted = dataObj['totalAmountExempted'];
                                flag = false;
                            }
                            if (keys[i] === 'totalNoOfDays') {
                                totalNoOfDays = dataObj['totalNoOfDays'];
                                flag = false;
                            }
                            if (keys[i] === 'totalSalary') {
                                totalSalary = dataObj['totalSalary'];
                                flag = false;
                            }
                            if (keys[i] === 'totalSalaryWithSubscription') {
                                totalSalaryWithSubscription = dataObj['totalSalaryWithSubscription'];
                                flag = false;
                            }
                            if (keys[i] === 'tswsrsSalary') {
                                tswsrsSalary = dataObj['tswsrsSalary'];
                                flag = false;
                            }
                           
                        }

                     
                        if (flag) {
                            let data = {
                                key: keys[i],
                                value: this.preProcessing(dataObj[keys[i]])
                            }
                            temp.push(data);
                        }

                    }

                    //Patient table array set here
                    if (pathname === 'exemptionFromAccompanyPatient' && keys[i] === 'tableData') {
                        tableArray = dataObj['tableData'];
                    }

                }

                //For attachment array
                let attachmentArray = this.state.previewAttachmentArr;
                let tempAttachmentNormalArray = [];
                let tempAttachmentArray = [];
                let tempAttachmentMultipleArray = [];

                if (attachmentArray) {
                    
                    if (attachmentArray.length > 0) {
                        for (let j = 0; j < attachmentArray.length; j++) {
                          
                            tempAttachmentArray = [];
                            if (typeof (attachmentArray[j].fileObj) === 'object') {
                                let fileNameArr = [];
                                if (attachmentArray[j].fileName) {

                                    fileNameArr = attachmentArray[j].fileName.split(',');
                          

                                    if (fileNameArr.length > 0) {
                                        for (let k = 0; k < fileNameArr.length; k++) {
                                         

                                            if (fileNameArr[k]) {
                                               
                                                let data = {
                                                    
                                                    label: attachmentArray[j].label,
                                                    fileName: fileNameArr[k].trim(),
                                                    fileObj: attachmentArray[j].fileObj[k]
                                                }
                                                tempAttachmentArray.push(data);
                                               
                                            }
                                        }
                                    }
                                }
                            } else {
                                let singleAttachment = {
                                    label: attachmentArray[j].label,
                                    fileName: attachmentArray[j].fileName,
                                    fileObj: attachmentArray[j].fileObj
                                }
                                tempAttachmentNormalArray.push(singleAttachment);
                            }
                            if (tempAttachmentArray.length > 0) {
                                tempAttachmentMultipleArray.push(tempAttachmentArray);
                            }
                        }
                    }
                }

                this.setState({
                    //Patient variables
                    patientArray: tableArray,

                    basicSalaryTable: basicSalaryTable,
                    retirementSalary: retirementSalary,
                    subTotalTotal: subTotalTotal,
                    totalAmountExempted: totalAmountExempted,
                    totalNoOfDays: totalNoOfDays,
                    totalSalary: totalSalary,
                    totalSalaryWithSubscription: totalSalaryWithSubscription,
                    tswsrsSalary: tswsrsSalary,

                    previewDataNew: temp,
                    previewSingleAttachmentArr: tempAttachmentNormalArray,
                    previewAttachmentMultipleArr: tempAttachmentMultipleArray,
                    lastIndex: temp.length - 1,
                    previewDataCount: temp.length
                });

            });
        }

    }

    preProcessing = (value) => {
        if (!value) {
            return '';
        }
        value = value.toString();
        value = value.replace(/↵/g, '');
        value = striptags(value.toString('html'));
        return value;
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    getTitle = () => {
        pathname = localStorage.getItem('currentPath');

        switch (localStorage.getItem('currentPath')) {
            case 'StrategicPlanningforworkforceEnquire':
            return i18n[this.getLocale()].StrategicPlanningforworkforce.Enquire + ' - ' + i18n[this.getLocale()].preview;
            case 'SCIRequest':
                return i18n[this.getLocale()].sci + ' - ' + i18n[this.getLocale()].preview;
            case 'informationRequest':
                return i18n[this.getLocale()].information_request + ' - ' + i18n[this.getLocale()].preview;
            case 'exemptionFromAccompanyPatient':
                return i18n[this.getLocale()].exceptio_from_company_patient + ' - ' + i18n[this.getLocale()].preview;
            case 'reviewHRPolicies':
                return i18n[this.getLocale()].reveiew_hr_policies + ' - ' + i18n[this.getLocale()].preview;
            case 'exceptionADHousing':
                return i18n[this.getLocale()].exceptionADHousing + ' - ' + i18n[this.getLocale()].preview;
            case 'secondmentForFederalAgency':
                return i18n[this.getLocale()].secndmentForFedralAgency + ' - ' + i18n[this.getLocale()].preview;
            case 'exceptionOfSteerTheAgeOfRetirementRequest':
                return i18n[this.getLocale()].exceptionOfSteerRetirementRequest + ' - ' + i18n[this.getLocale()].preview;
            case 'bonusRequest':
                return i18n[this.getLocale()].bonus_Request + ' - ' + i18n[this.getLocale()].preview;
            case 'amendSalaryScreen':
                return i18n[this.getLocale()].amend_salaries_scale + ' - ' + i18n[this.getLocale()].preview;
            case 'amendEmployeeSalary':
                return i18n[this.getLocale()].amend_employe_salary + ' - ' + i18n[this.getLocale()].preview;

            //Data Services
            case 'extendCreateObjectives':
                return i18n[this.getLocale()].extendCreateObjectives + ' - ' + i18n[this.getLocale()].preview;
            case 'extendReviewObjectives':
                return i18n[this.getLocale()].extendReviewObjectives + ' - ' + i18n[this.getLocale()].preview;
            case 'extendAnnualPerformance':
                return i18n[this.getLocale()].extendAnnualPerformanceEvaluationInterval + ' - ' + i18n[this.getLocale()].preview;
            case 'addOrEditEmployeesPerformanceEvaluation':
                return i18n[this.getLocale()].addOrEditEmployeesPerformanceEvaluation + ' - ' + i18n[this.getLocale()].preview;
            case 'joinAndImplementGovResourcesSystemForNewEntity':
                return i18n[this.getLocale()].joinAndImplementGovernmentResourcesSystemForNewEntity + ' - ' + i18n[this.getLocale()].preview;
            case 'openComplainsStage':
                return i18n[this.getLocale()].openComplainsStage + ' - ' + i18n[this.getLocale()].preview;
            case 'amendsSharedGovernmentHRManagement':
                return i18n[this.getLocale()].amendsOnSharedGovernmentHRManagementystem + ' - ' + i18n[this.getLocale()].preview;
            case 'reportsAndStaticRequest':
                return i18n[this.getLocale()].ReportsAndStaticRequest + ' - ' + i18n[this.getLocale()].preview;
            case 'otherRequests':
                return i18n[this.getLocale()].OtherRequests + ' - ' + i18n[this.getLocale()].preview;

            default:
                return '';
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    render() {

        const sideBarData = Config.getServiceSideBar('SCIRequest', this.state.isArabic, this, 0);
        return (

            <MDBContainer fluid style={{ height: '100vh' }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} />

                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            this.props.history.replace('/' + pathname, { pathname: pathname })
                                        }}
                                        isArabic={this.state.isArabic}
                                        title={this.getTitle()}
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow
                                className="outerContainer"
                                style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
                                {/* <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="innerContainer"> */}
                                {/* <div className="grid-container"> */}
                                {/* PreviewItem Key-value data */}
                                {
                                    this.state.previewDataNew ? this.state.previewDataNew.map((item, i) =>
                                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" className='viewContainerClass'>
                                            <PreviewItem
                                                isArabic={this.state.isArabic}
                                                key={i}
                                                item={item}
                                                i={i}
                                                borderFlag={this.state.borderFlag}
                                                lastIndex={this.state.lastIndex}
                                                previewDataCount={this.state.previewDataCount}
                                            />
                                        </MDBCol>)
                                        :
                                        null
                                }
                                {/* set blank div for odd no of array - for border */}
                                {/* Preview Data Length : {this.state.previewDataNew.length} */}
                                {
                                    this.state.previewDataNew.length % 2 !== 0
                                        ?
                                        <div
                                            className="grid-item"
                                            style={{
                                                display: 'flex',
                                                flexDirection: this.state.isArabic ? 'row-reverse' : 'row',
                                                borderBottom: '1px solid rgb(221, 221, 221)'
                                            }}>
                                            <div className="grid-key"
                                                style={{
                                                    marginLeft: this.state.isArabic ? 10 : 0,
                                                    marginRight: this.state.isArabic ? 0 : 10,
                                                    direction: this.state.isArabic ? 'rtl' : 'ltr'
                                                }}>
                                            </div>

                                            <div
                                                className="grid-value"
                                                style={{
                                                    textAlign: this.state.isArabic ? 'right' : 'left',
                                                    direction: this.state.isArabic ? 'rtl' : 'ltr'
                                                }}>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                {/* </div> */}
                                {/* Patient table */}
                                {
                                    pathname === 'exemptionFromAccompanyPatient'
                                        ?
                                        <div className="patientTable">
                                            <p
                                                className="viewHeading"
                                                style={{
                                                    textAlign: this.state.isArabic ? 'right' : 'left'
                                                }}
                                            >
                                                {i18n[this.getLocale()].ExemptionFromAccompanyPatient.irregularitiesTable}
                                            </p>

                                            <MDBTable
                                                bordered
                                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                            >
                                                <MDBTableBody>
                                                    <tr>
                                                        <td width="15%">
                                                            <div className="tableHeading" style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.employeeSalaryInformation}</div>
                                                        </td>
                                                        <td>
                                                            <MDBTable
                                                                bordered
                                                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                                            >
                                                                <MDBTableBody>
                                                                    <tr>
                                                                        <td align="center">
                                                                            <lable>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.basicSalaryTb}</lable>
                                                                            <div align="center">
                                                                                {this.state.basicSalaryTable}
                                                                            </div>
                                                                        </td>
                                                                        <td align="center">
                                                                            <lable>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalSalaryWithoutSubscription}</lable>
                                                                            <div align="center">
                                                                                {this.state.totalSalaryWithSubscription}
                                                                            </div>
                                                                        </td>
                                                                        <td align="center">
                                                                            <lable>
                                                                                {i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.retirementSalary}</lable>
                                                                            <div className="form-group">
                                                                                <div align="center">
                                                                                    {this.state.retirementSalary}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td align="center">
                                                                            <lable>
                                                                                {i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.total}</lable>
                                                                            <div align="center">
                                                                                {this.state.tswsrsSalary}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </MDBTableBody>
                                                            </MDBTable>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div className="tableHeading"
                                                                style={{
                                                                    textAlign: this.state.isArabic ? "right" : '',
                                                                    //width: 'auto'
                                                                }}
                                                            >
                                                                {i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.intervals}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <MDBTable
                                                                bordered
                                                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                                            >
                                                                <MDBTableBody>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.year}</th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.month}</th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.from}</th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.to}</th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salaryStatus}</th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.subTotal}</th>
                                                                        <th style={{ textAlign: this.state.isArabic ? "right" : '' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.noOfDays}</th>
                                                                    </tr>

                                                                    {
                                                                        this.state.patientArray ? this.state.patientArray.map((item, i) =>
                                                                            <PreviewPatientItem
                                                                                isArabic={this.state.isArabic}
                                                                                key={i}
                                                                                i={i}
                                                                                item={item}
                                                                                index={i}
                                                                            />
                                                                        )
                                                                            :
                                                                            null
                                                                    }

                                                                    <tr>
                                                                        <td style={{ border: 'none' }}> </td>
                                                                        <td style={{ border: 'none' }}> </td>
                                                                        <td style={{ border: 'none' }}> </td>
                                                                        <td style={{ border: 'none' }}> </td>
                                                                        <td style={{ border: 'none' }}> </td>
                                                                        <td style={{ border: 'none' }}> </td>
                                                                        <td>
                                                                            <div
                                                                                style={{ textAlign: this.state.isArabic ? "right" : '' }}
                                                                                className="form-group">
                                                                                {this.state.subTotalTotal}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                style={{ textAlign: this.state.isArabic ? "right" : '' }}
                                                                                className="form-group">
                                                                                {this.state.totalNoOfDays}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </MDBTableBody>
                                                            </MDBTable>
                                                        </td>
                                                    </tr>
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    pathname === 'bonusRequest'
                                        ?
                                        <div className="bonusTable">

                                            <MDBTable bordered style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                                className={this.state.isArabic ? "bonusTableAr" : ""}>
                                                <MDBTableBody>
                                                    <tr>
                                                        <th></th>
                                                        <th>{i18n[this.getLocale()].permanentContract}</th>
                                                        <th>{i18n[this.getLocale()].privateContract}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{i18n[this.getLocale()].locals}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table1 ?
                                                                        this.state.previewData.table1.localwithpermanent
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table1 ?
                                                                        this.state.previewData.table1.localwithprivate
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{i18n[this.getLocale()].nonLocals}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table1 ?
                                                                        this.state.previewData.table1.nonlocalwithpermanent
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table1 ?
                                                                        this.state.previewData.table1.nonlocalwithprivate
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </MDBTableBody>
                                            </MDBTable>

                                            <MDBTable bordered style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                                className={this.state.isArabic ? "bonusTableAr" : ""}>
                                                <MDBTableBody>
                                                    <tr>
                                                        <th>{i18n[this.getLocale()].annualEvaluationForAYearWhichRequestedBonus}</th>
                                                        <th>{i18n[this.getLocale()].numberOfEmployeeWhichTheyNotEqual}</th>
                                                        <th>{i18n[this.getLocale()].percentage}</th>
                                                    </tr>

                                                    <tr>
                                                        <td>{i18n[this.getLocale()].excellent}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.excellentemployees
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.excellentemployeesPercentage
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>{i18n[this.getLocale()].veryGood}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.verygoodemployees
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.verygoodemployeesPercentage
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>{i18n[this.getLocale()].good}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.goodemployees
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.goodemployeesPercentage
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>{i18n[this.getLocale()].acceptable}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.acceptableemployees
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.acceptableemployeesPercentage
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>{i18n[this.getLocale()].weak}</td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.weakemployees
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {
                                                                    this.state.previewData.table2 ?
                                                                        this.state.previewData.table2.weakemployeesPercentage
                                                                        :
                                                                        null
                                                                }
                                                            </p>
                                                        </td>
                                                    </tr>

                                                </MDBTableBody>
                                            </MDBTable>

                                        </div>
                                        :
                                        null
                                }

                                {
                                    pathname === 'amendSalaryScreen'
                                        ?
                                        <div className="patientTable">

                                            <MDBTable bordered style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }} className={this.state.isArabic ? "amendSalScaleTablePreviewAr" : ""}>
                                                <MDBTableBody>
                                                    <tr>
                                                        <td width="2%">&nbsp;</td>
                                                        <td
                                                            width="22%"
                                                            style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}> {i18n[this.state.isArabic ? 'ar' : 'en'].current_basic_salary}

                                                        </td>
                                                        <td width="22%">
                                                            <div className="form-group">
                                                                <div style={{ borderRadius: '5px' }}>
                                                                    <p>
                                                                        {
                                                                            this.state.previewData.table ? this.state.previewData.table.currentBasicSalarymonthly
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td width="22%"
                                                            style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].proposed_basic_salary}

                                                        </td>
                                                        <td width="22%">
                                                            <div className="form-group">
                                                                <div style={{ borderRadius: '5px' }}>
                                                                    <p>
                                                                        {
                                                                            this.state.previewData.table ? this.state.previewData.table.proposedBascSalaryfunding
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* Start Other Annual Bonuses */}
                                                    {
                                                        this.state.previewData.table ?
                                                            this.state.previewData.table.otherBonusTableData.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                                                                            {i18n[this.state.isArabic ? 'ar' : 'en'].other_bonus_name}
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-group">
                                                                                <p>{item.othercurrentmonthlybonus}</p>
                                                                            </div>
                                                                        </td>

                                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].other_bonus_name}
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-group">
                                                                                <p>{item.otherproposedmonthlybonus}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                    {/* End Other Annual Bonuses */}
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].current_annual_benefits}

                                                        </td>
                                                        <td>
                                                            <div className="form-group">
                                                                <div style={{ borderRadius: '5px' }}>
                                                                    <p>
                                                                        {
                                                                            this.state.previewData.table ?
                                                                                this.state.previewData.table.currentAnnualBenefits
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </td>

                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].proposed_annual_benefits}

                                                        </td>
                                                        <td>
                                                            <div className="form-group">
                                                                <div style={{ borderRadius: '5px' }}>
                                                                    <p>
                                                                        {
                                                                            this.state.previewData.table ?
                                                                                this.state.previewData.table.proposedAnnualBenefits
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* Start Other Benefit Name */}
                                                    {
                                                        this.state.previewData.table ?
                                                            this.state.previewData.table.otherBenefitTableData.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                                                                            {i18n[this.state.isArabic ? 'ar' : 'en'].other_benefit_name}
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-group">
                                                                                <p>{item.othercurrentannualbenefits}</p>
                                                                            </div>
                                                                        </td>

                                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].other_benefit_name}
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-group">
                                                                                <p>{item.otherproposedannualbenefits}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                    {/* End Other Benefit Name */}
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                                                            {i18n[this.state.isArabic ? 'ar' : 'en'].current_financial_effect}

                                                        </td>
                                                        <td>
                                                            <div className="form-group">
                                                                <div style={{ borderRadius: '5px' }}>
                                                                    <p>
                                                                        {
                                                                            this.state.previewData.table ?
                                                                                this.state.previewData.table.currentFinancialEffect
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].proposed_financial_effect}

                                                        </td>
                                                        <td>
                                                            <div className="form-group">
                                                                <div style={{ borderRadius: '5px' }}>
                                                                    <p>
                                                                        {
                                                                            this.state.previewData.table ?
                                                                                this.state.previewData.table.proposedFinancialEffect
                                                                                :
                                                                                null
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].avarage_increase_for_current_salaries}

                                                        </td>
                                                        <td>
                                                            <div style={{ borderRadius: '5px' }}>
                                                                <p>
                                                                    {
                                                                        this.state.previewData.table ?
                                                                            this.state.previewData.table.avarageForCurrentSalariesScale
                                                                            :
                                                                            null
                                                                    }
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].avarage_increase_for_proposed_salaries}

                                                        </td>
                                                        <td>
                                                            <div style={{ borderRadius: '5px' }}>
                                                                <p>
                                                                    {
                                                                        this.state.previewData.table ?
                                                                            this.state.previewData.table.avarageForProposedSalariesScale
                                                                            :
                                                                            null
                                                                    }
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </MDBTableBody>
                                            </MDBTable>

                                        </div>
                                        :
                                        null
                                }
                                {/* For CKEditor */}
                                {
                                    this.state.previewckEditorArr ? this.state.previewckEditorArr.map((item, i) =>
                                        <PreviewCKEditor
                                            isArabic={this.state.isArabic}
                                            key={i}
                                            item={item}
                                            i={i}
                                        />
                                    )
                                        :
                                        null
                                }

                                {/* For Attachment */}
                                {
                                    (
                                        this.state.previewSingleAttachmentArr &&
                                        this.state.previewSingleAttachmentArr.length > 0
                                    )
                                        ||
                                        (
                                            this.state.previewAttachmentMultipleArr &&
                                            this.state.previewAttachmentMultipleArr.length > 0
                                        )
                                        ?
                                        <p
                                            className="viewHeading"
                                            style={{
                                                textAlign: this.state.isArabic ? 'right' : 'left',
                                                width: '100%'
                                            }}
                                        >
                                            {i18n[this.getLocale()].viewComponent.attachments}
                                        </p>
                                        :
                                        null
                                }

                                {
                                    this.state.previewSingleAttachmentArr ? this.state.previewSingleAttachmentArr.map((item, i) =>
                                        <PreviewAttachment
                                            isArabic={this.state.isArabic}
                                            key={i}
                                            item={item}
                                            i={i}
                                        />
                                    )
                                        :
                                        null
                                }

                                {/* Multiple Attachment */}
                                {
                                    this.state.previewAttachmentMultipleArr ? this.state.previewAttachmentMultipleArr.map((item, i) =>
                                        <PreviewAttachmentMultiple
                                            isArabic={this.state.isArabic}
                                            key={i}
                                            i={i}
                                            item={item}
                                        />
                                    )
                                        :
                                        null
                                }

                                {/* </MDBCol> */}

                            </MDBRow>
                            <MDBRow style={{ paddingTop : '60px' }}>
                                <Footer isArabic={this.state.isArabic} />
                            </MDBRow>
                        </MDBCol>

                        <SideBar
                            // height={'300vh'} 
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic}
                        />

                    </MDBRow>

                </LoadingOverlay>

            </MDBContainer >
        )
    }
}
