import React, { Component } from 'react';
import { MDBRow, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBCol, MDBModalMDBModal, MDBModalHeader, MDBModalBody, MDBModal, MDBContainer } from "mdbreact";
import NotificationComponent from './NotificationComponent';
import { getNotificationPayload } from './../utils/Payload';
import i18n from './../i18n';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';
import doc from './../assets/HRAHUBElectronicServices.pdf';

import { white } from 'ansi-colors';

export default class Header extends Component {

    constructor(props) {
        super(props);
        let lanFlag = 'ar';
        if (localStorage.getItem('Lang') !== '') {
            lanFlag = localStorage.getItem('Lang');
        }
        this.state = {
            username: '',
            lan: lanFlag,
            notifications: [],
            notificationBadgeCount: 0,
            Ads: Config.getLocalStorageAdsData(),
        }
    }

    componentDidMount = () => {

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                username: loginData.Username
            }, () => {
                this.callNotificationList();
            })
        }
    }

    callNotificationList = () => {
        let payload = getNotificationPayload();
        let endPoint = 'GetNotificationList'
        let url = Config.getUrl(endPoint);
        ApiRequest.sendRequestForPost(url, payload, res => {
            if (res.Status) {

                let temp = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let obj = {
                            title: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            read: false
                        };
                        temp.push(obj);
                    }
                } else {
                    let temp = [{
                        title: 'No Notifications',
                        read: false
                    }]
                    this.setState({
                        notificationBadgeCount: 0,
                        notifications: temp
                    })
                    return;
                }
                this.setState({
                    notifications: temp
                }, () => {
                    let temp = 0;
                    if (this.state.notifications.length > 0) {
                        for (let i = 0; i < this.state.notifications.length; i++) {
                            if (!this.state.notifications[i].read) {
                                ++temp;
                            }
                        }
                        this.setState({
                            notificationBadgeCount: temp
                        });
                    } else {
                        this.setState({
                            notificationBadgeCount: temp
                        });
                    }
                });
            } else {

            }
        })
    }
    closeadspopup = () => {
        Config.unsetLocalStorageAds();
        let ad = false;
        this.setState({
            Ads: ad
        })
    }
    toggleAds = () => {
        this.setState({ Ads: !this.state.Ads });
    }
    toggleLanguage = (lan, langId) => {
        localStorage.setItem('Lang', lan);
        this.setState({
            lan
        }, () => {
            setTimeout(() => {
                this.props.toggleLanguage(lan);
            }, 500);
        });
    }

    getLangName = () => {
        return this.state.lan === 'ar' ? 'العربية' : 'English'
    }

    getLocale = () => {
        let lanFlag = 'ar';
        if (localStorage.getItem('Lang')) {
            lanFlag = localStorage.getItem('Lang');
        }
        return lanFlag;
    }

    getFlagImage = (lan) => {
        if (lan) {
            if (lan === 'ar') {
                return <img src={require("./../assets/images/arabic.png")} alt="" />
            }
            else {
                return <img src={require("./../assets/images/english.png")} alt="" />
            }
        } else if (this.state.lan === 'ar') {
            return <img src={require("./../assets/images/arabic.png")} alt="" />
        } else {
            return <img src={require("./../assets/images/english.png")} alt="" />
        }
    }

    render() {


        return (
            <>
                <div>
                    <MDBRow className="headerMain" style={{ flexDirection: this.state.lan === 'en' ? 'row-reverse' : 'row' }}>



                        {/* <MDBCol size="6" sm="6" md="6"></MDBCol> */}

                        <MDBCol size="5" sm="5" md="5" className="headerRightBox" style={{ zIndex: 1, direction: this.state.lan === 'ar' ? 'rtl' : 'ltr' }}>

                            {/* <span style={{display:'flex',direction:this.state.lan === 'ar' ? 'row-reverse' : 'row', cursor: 'pointer'}} onClick={this.props.goToHelpPage}>
                        {i18n[this.state.lan === 'ar' ? 'ar' : 'en'].help}
                        <img alt=""  style={{marginRight:this.state.lan === 'ar' ? '5px' : '5px',marginLeft:this.state.lan === 'ar' ? '5px' : '5px'}} src={require('./../assets/images/help_ab.png')}  />
                    </span> */}

                            {/* <NotificationComponent 
                        isArabic={this.props.isArabic}
                    /> */}


                            <a href={doc} target="_blank">
                                <div className="flagclass" style={{
                                    display: 'flex',
                                    flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', alignItems: 'center',
                                    justifyContent: 'center'
                                }} caret gradient="var(--primaryColor)">
                                    <span style={{
                                        backgroundColor: '#09243e', borderRadius: 50,
                                        height: 25,
                                        marginRight: 2, padding: '3px', color: '#ffffff'
                                    }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].helpfile}
                                    </span>

                                </div>
                            </a>

                            <MDBDropdown size="3" sm="3" md="3" className="mx-2">
                                <MDBDropdownToggle className="flagclass" style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center' }} caret gradient="var(--primaryColor)">
                                    {this.state.notificationBadgeCount ? <div style={{ backgroundColor: '#9AD4A1', borderRadius: 50, height: 20, width: 20, boxShadow: '2px 2px rgba(0, 0, 255, .2)', marginRight: 2/*, padding: '3px'*/ }}>{this.state.notificationBadgeCount}</div> : null}
                                    <img src={require("./../assets/images/notification_white.png")} alt="" />
                                </MDBDropdownToggle>

                                <MDBDropdownMenu style={{ padding: 3, maxHeight: '40vh', overflowY: 'auto' }} basic>
                                    {
                                        this.state.notifications ? this.state.notifications.map((item, i) =>
                                            <span key={i}>
                                                {!i
                                                    ?
                                                    // boxShadow: '2px 2px rgba(0, 0, 255, .2)',
                                                    //  padding: '2%' 
                                                    <div style={{ backgroundColor: 'white', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #9AD4A1' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].notifications}
                                                        <span style={{ marginLeft: 5, marginRight: 5, backgroundColor: '#9AD4A1', borderRadius: 50, height: 28, width: 28, textAlign: 'center', color: 'white', marginRight: 2, padding: '2%' }}>
                                                            {this.state.notificationBadgeCount}
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', justifyContent: 'space-between', padding: '1%', backgroundColor: item.read ? 'lightgrey' : 'white', height: 40, boxShadow: item.read ? '2px 2px #6A96B0' : '2px 2px #9AD4A1' }}
                                                    onClick={() => {
                                                        let temp = item.read ? 'already read' : 'unread';
                                                        //alert('You Clicked ' + item.title + ' which is ' + temp);
                                                    }}>
                                                    <span style={{ padding: '2px 10px' }}>
                                                        {item.title}
                                                    </span>
                                                    <span style={{ padding: '2px 10px' }}>
                                                        {item.statusreason}
                                                    </span>
                                                    {item.read ? null : <span style={{ height: 6, width: 6, dispaly: 'flex', alignSelf: 'center', justifyContent: 'flex-end', borderRadius: 6, backgroundColor: '#9AD4A1', marginRight: '10%', boxShadow: '2px 2px rgba(0, 0, 255, .2)' }}></span>}
                                                </MDBDropdownItem>
                                                <MDBDropdownItem divider />
                                            </span>
                                        )
                                            :
                                            null
                                    }
                                </MDBDropdownMenu>

                            </MDBDropdown>

                            <MDBDropdown size="3" sm="3" md="3" className="mx-2">
                                <MDBDropdownToggle className="flagclass" style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center' }} caret gradient="var(--primaryColor)">
                                    <span className="langTxt">{this.getFlagImage(localStorage.getItem('Lang'))}</span>
                                    <span className="langTxt">{this.getLangName(localStorage.getItem('Lang'))}</span>
                                </MDBDropdownToggle>

                                <MDBDropdownMenu style={{ padding: 0 }} basic>
                                    <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row' }} onClick={() => { this.toggleLanguage('ar') }}>
                                        <span style={{ padding: '2px 10px' }}>
                                            {this.getFlagImage('ar')}
                                        </span>
                                        <span style={{ textAlign: 'right' }}>
                                            {i18n[this.getLocale()].arabic}
                                        </span>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem divider />
                                    <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row' }} onClick={() => { this.toggleLanguage('en') }}>
                                        <span style={{ padding: '2px 10px' }}>
                                            {this.getFlagImage('en')}
                                        </span>
                                        <span style={{ textAlign: 'right' }}>
                                            {i18n[this.getLocale()].english}
                                        </span>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                            <MDBDropdown size="3" sm="3" md="3" className="settings">
                                <MDBDropdownToggle className="userNameTxt" style={{ flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row' }} caret gradient="var(--primaryColor)">
                                    {this.state.lan === 'ar' ?
                                        <span style={{ marginLeft: 5, marginRight: 5 }}>{this.state.username}</span>
                                        :
                                        <span style={{ marginLeft: 5, marginRight: 5 }}>{this.state.username}</span>
                                    }
                                    <img style={{ borderRadius: '50%', maxHeight: '30px', margin: 2 }} className="userdropdownImage" alt="" src={require("./../assets/images/placeholder.jpg")} />
                                </MDBDropdownToggle>

                                <MDBDropdownMenu style={{ padding: 0 }} basic>
                                    <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row' }} onClick={this.props.logout} title={i18n[this.getLocale()].logout}><img alt="" style={{ padding: '0 10px' }} src={require('./../assets/images/logout.png')} />{i18n[this.getLocale()].logout}</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                        </MDBCol>

                        <MDBCol sm={this.props.isSideBarMinimized ? "6" : "5"} md={this.props.isSideBarMinimized ? "6" : "5"} size={this.props.isSideBarMinimized ? "6" : "5"} className="headerMiddleBox" style={{ direction: !this.state.lan === 'ar' ? 'rtl' : 'ltr', flexDirection: this.state.lan === 'en' ? 'row-reverse' : 'row' }}>
                            <span className="logoBox" onClick={this.props.goToLandingPage}>
                                <img alt="" src={require('./../assets/images/HRA-logo-1-C70.png')} />
                            </span>
                        </MDBCol>

                        <MDBCol sm={this.props.isSideBarMinimized ? "1" : "2"} md={this.props.isSideBarMinimized ? "1" : "2"} size={this.props.isSideBarMinimized ? "1" : "2"} className="headerLeftBox" style={{ flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', padding: !this.props.isSideBarMinimized ? "0% 1% 0% 0.3%" : this.props.isArabic ? "0% 2% 0% 0.3%" : "0% 1% 0% 1%" }}>
                            <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                                {
                                    this.props.isSideBarMinimized
                                        ?
                                        <img onClick={this.props.toggleSideBarSize} alt="" src={require('./../assets/images/HRA-logo-6.png')} style={{ cursor: 'pointer', color: 'white', maxHeight: '70px', maxWidth: '38px' }} />
                                        :
                                        <img onClick={this.props.toggleSideBarSize} alt="" src={require('./../assets/images/logo_with_white_text.png')} style={{ cursor: 'pointer', color: 'white', width: '100%', maxHeight: '70px' }} />
                                }
                            </div>
                            <div style={{ width: '5%' }} />
                            <div style={{ width: '15%' }}>
                                <img onClick={this.props.toggleSideBarSize} alt="" src={require('./../assets/images/menu1.png')} style={{ cursor: 'pointer', color: 'white' }} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBModal
                        isOpen={false}
                        // isOpen={this.state.Ads}
                        toggle={this.toggleAds}
                        position="bottom" size="lg" >
                        <MDBModalBody style={{ backgroundColor: "#DCDCDC" }}>
                            <MDBContainer fluid style={{ overflow: "hidden" }}>
                                {
                                    this.state.Ads === 'true' ?
                                        this.props.isArabic ?
                                            <div>
                                                <MDBRow className="" style={{ flexDirection: this.state.lan === 'en' ? 'row-reverse' : 'row' }}>  <MDBCol size="12" sm="12" md="12" className="AdsTitleWhite headerRightBoxAdsPOp" style={{
                                                    direction: this.state.lan === 'ar' ? 'rtl' : 'ltr'
                                                }}>

                                                    <h3 className="Adsh1White">الموضوع : تحديثات على النظام  </h3>


                                                </MDBCol>
                                                </MDBRow>
                                                <MDBRow className="" style={{ flexDirection: this.state.lan === 'en' ? 'row-reverse' : 'row' }}>  <MDBCol size="12" sm="12" md="12" className="AdsWhite headerRightBoxAdsPOp" style={{
                                                    direction: this.state.lan === 'ar' ? 'rtl' : 'ltr'
                                                }}>
                                                    <table className="AdsTable">
                                                        <tr>
                                                            <td >
                                                                <img onClick={this.props.toggleSideBarSize} alt="" src={require('./../assets/images/LogoVertical.png')} style={{ cursor: 'pointer', color: 'white', width: '100%' }} />
                                                            </td>
                                                            <td>
                                                                <img alt="" src={require('./../assets/images/HRAHubNew.png')} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2">
                                                                <span classnnname="AdsSpan">
                                                                    <b>عزيزي مستخدم  HRA HUB،،، </b>
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    يهديكم فريق عمل HRA HUB أطيب التحيات والتمنيات لكم بدوام التوفيق والنجاح. ، فوفقا للتحديث الجديد في النظام تم تحسين التالي :-
                                                                </span>     <br /><br /><br />

                                                                <span className="boldspan">

                                                                    •	خاصية " إعادة فتح الطلب " خلال 3 أيام فقط من تاريخ استكمال الطلب ، وذلك لغاية الاستفسارات حول الرد من قبل الهيئة على طلبكم

                                                                </span><br /><br />
                                                                <span className="boldspan">
                           o	للوصول للخاصية : عند الحصول على الطلب المستكمل -> النقر على  إجراء  -> من ثم على  إعادة فتح الطلب
                                                                </span><br /><br /><br /> <span className="boldspan">ملاحظة : في حال لم يتم أخذ الأجراء بعد 3 أيام من استكمال طلب الخدمة ، سيتم اغلاق الطلب وسيتعين عليكم فتح طلب خدمة جديدة </span>
                                                                <br /><br />
                                                                <span>تحياتنا، </span>
                                                                <br />  <span>فريق عمل نظام الخدمات الإلكترونية لهيئة الموارد البشرية (HRA HUB)</span>
                                                            </td>

                                                        </tr>
                                                    </table>




                                                </MDBCol>
                                                </MDBRow>
                                            </div>
                                            :
                                            null : null}
                            </MDBContainer>
                        </MDBModalBody></MDBModal>
                </div>
               
                </>
        );
    }
}