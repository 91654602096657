import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import StepsComponent from '../StepsComponent';
import i18n from '../../i18n';
import DatePicker from "react-datepicker";
import Config from './../../utils/Config';
import Validator from './../../utils/Validator';
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';
import CKEditor from "react-ckeditor-component";
const striptags = require('striptags');
export default class SecondmentForFederalAgencyStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radio: 0,
            isNextScreenActive: false,
            dateAfterOneYear: '',
            employeeName: this.props.sendData.employeeName,
            entityName: this.props.sendData.entityName,
            jobName: this.props.sendData.jobName,
            employeeNumber: this.props.sendData.employeeNumber,
            basicSalary: this.props.sendData.basicSalary,
            totalSalary: this.props.sendData.totalSalary,
            entityRespForSal: this.props.sendData.entityRespForSal,
            fromDatePeriodOfSecndMent: this.props.sendData.fromDatePeriodOfSecndMent,
            toDatePeriodOfSecndMent: this.props.sendData.toDatePeriodOfSecndMent,
            retirementCont: this.props.sendData.retirementCont,
            unifiedNumber: this.props.sendData.unifiedNumber,
            reasonForGovPaySalary:this.props.sendData.reasonForGovPaySalary,
            emiratesID: this.props.sendData.emiratesID,
            empPassNoticePeriod: this.props.sendData.empPassNoticePeriod,
            lastYearPerformanceEfficiency: this.props.sendData.lastYearPerformanceEfficiency,
            employeeAgreement: this.props.sendData.employeeAgreement,
            empShouldPassProbationPeriodLabel: this.props.sendData.empShouldPassProbationPeriodLabel,
            lastYearPerfDegMoreThanWeekLabel: this.props.sendData.lastYearPerfDegMoreThanWeekLabel,
            employeeAgreementLabel: this.props.sendData.employeeAgreementLabel,
           
            isArabic: true,
            toDate: '',
            periodOfSendMentErr: '',
            periodOfSendMentErr_0: '',

            // familyNumber: this.props.sendData.familyNumber,
            errForEntityRespForSal: '',
            salaryErr: '',
            emiratesIDErr: '',
            empPassNoticePeriodErr: '',
            lastYearPerformanceEfficiencyErr: '',
            employeeAgreementErr: '',
            requestId: '',
            isEdit: false,
            statusid: '',
            totalSalaryErr: "",
            basicSalErr: "",
            retirmentContErr: "",
           // reasonForGovPaySalary:"",
            reasonForGovPaySalaryErr:"",
            BorrowEntityIDErr:'',
            BorrowEntityID:this.props.sendData.BorrowEntityID ? this.props.sendData.BorrowEntityID : '0',
            BorrowEntityLabel:this.props.sendData.BorrowEntityLabel,

        }
    }
    onReasonForGovPaySalary = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                reasonForGovPaySalaryErr: "",
                reasonForGovPaySalary: newContent
            });
        }else{
            this.setState({
                reasonForGovPaySalaryErr: "ckeditorCountErr"
            });
        }
    }
    componentDidMount = () => {
        window.scrollTo(0, 0);

        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let day = currentDate.getDate();
        let tempEntityValue ='';
        let dateAfterOneYear = new Date(year + 1, month, day);
        this.setState({
            dateAfterOneYear: dateAfterOneYear
        })

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {

            let tempDateOne = '', tempDateTwo = '';
            if (editObj.BorrowEntityID === 0) {
                tempEntityValue = '';
            } else {
                tempEntityValue = editObj.BorrowEntityID;
            } 
            if (editObj.secondmentfrom !== "") {
                tempDateOne = new Date(editObj.secondmentfrom);
            } else {
                tempDateOne = null;
            }
            if (editObj.secondmentto !== "") {
                tempDateTwo = new Date(editObj.secondmentto);
            } else {
                tempDateTwo = null;
            }
            
            
            this.setState({
                
                BorrowEntityID: tempEntityValue,
                isEdit: editFlag,
                requestId: editObj.requestId,
                statusid: editObj.statusid,
                entityName: editObj.secondmententityname,
                employeeName: editObj.employeename,
                jobName: editObj.jobname,
                retirementCont: editObj.retirementcontribution,
                employeeNumber: editObj.employeenumber,
                basicSalary: editObj.basicsalary,
                totalSalary: editObj.totalsalary,
                unifiedNumber: editObj.unifiednumber,
                reasonForGovPaySalary:editObj.reasonForGovPaySalary,
                // familyNumber: editObj.familynumber,
                emiratesID: editObj.emiratesid,
                empPassNoticePeriod: parseInt(editObj.passtestperiod) === 1 ? true : false,
                lastYearPerformanceEfficiency: parseInt(editObj.performancedegree) === 1 ? true : false,
                employeeAgreement: parseInt(editObj.employeeagreement) === 1 ? true : false,

                empShouldPassProbationPeriodLabel: parseInt(editObj.passtestperiod) === 1 ? 'Yes' : 'No',
                lastYearPerfDegMoreThanWeekLabel: parseInt(editObj.performancedegree) === 1 ? 'Yes' : 'No',
                employeeAgreementLabel: parseInt(editObj.employeeagreement) === 1 ? 'Yes' : 'No',
                // fromDatePeriodOfSecndMent: new Date(`${editObj.secondmentfrom}`),
                // toDatePeriodOfSecndMent: new Date(`${editObj.secondmentto}`),
                fromDatePeriodOfSecndMent: tempDateOne,
                toDatePeriodOfSecndMent: tempDateTwo,
                entityRespForSal: editObj.salaryownerid === 1 ? "Goverment" : "Competent",
                //entityRespForSal: editObj.salaryownerid === 1 ? i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.goverment : i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.competent,
            }, () => {
                // set flag
                // Config.setIsEditFlag(false);
            })
        } else {
            let loginData = Config.getLocalStorageLoginData();
            this.setState({
                loginData: loginData
            }, () => {
                if (loginData) {
                    let tempEntityName = this.props.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn
                    this.setState({
                        entityName: tempEntityName
                    })
                }
            })
        }
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }

        this.setState({
           
            entityArray: tempEntityArray,
          
        }, () => {
          
        });
    }

    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }
    handleChangeForBorrowEntityID = (event) => {
        this.setState({ BorrowEntityID: event.target.value,BorrowEntityLabel:event.target.label });
    };

    checkValidations = () => {
        let
            periodOfSendMentErr_0 = '',
            periodOfSendMentErr = '',
            errForEntityRespForSal = '',
            salaryErr = '',
            emiratesIDErr = '',
            // empPassNoticePeriodErr = '',
            // lastYearPerformanceEfficiencyErr = '',
            // employeeAgreementErr = '',
            totalSalaryErr = "",
            basicSalErr = "",
            retirmentContErr = "",reasonForGovPaySalaryErr="",BorrowEntityIDErr="";
            if(!this.state.BorrowEntityID || this.state.BorrowEntityID==='0' || this.state.BorrowEntityID===''|| this.state.BorrowEntityID === null)
            {
                BorrowEntityIDErr='thifFieldIsRequired';
            }
if(this.state.entityRespForSal==="Goverment" && !this.state.reasonForGovPaySalary)
{
    reasonForGovPaySalaryErr='thisFieldIsRequired';
}
        if (!this.state.fromDatePeriodOfSecndMent) {
            periodOfSendMentErr_0 = 'thisFieldIsRequired';
        } else {
            if (!this.state.toDatePeriodOfSecndMent) {
                periodOfSendMentErr = 'thisFieldIsRequired';
            }
        }

        if (!this.state.entityRespForSal) {
            errForEntityRespForSal = 'thisFieldIsRequired';
        }

        if (!this.state.totalSalary) {
            totalSalaryErr = 'thisFieldIsRequired';
        }

        if (!this.state.basicSalary) {
            basicSalErr = 'thisFieldIsRequired';
        }

        if (!this.state.retirementCont) {
            retirmentContErr = 'thisFieldIsRequired';
        }

        if (this.state.basicSalary &&
            this.state.totalSalary
        ) {
            let basicSalAfterReplace = this.state.basicSalary.replace(/,/g, "");
            let totalSalAfterReplace = this.state.totalSalary.replace(/,/g, "");

            if (parseFloat(basicSalAfterReplace) > parseFloat(totalSalAfterReplace)) {
                salaryErr = 'salaryErr';
            }
        }

        if (this.state.emiratesID) {
            if (!Validator.validateEmiratesId(this.state.emiratesID)) {
                emiratesIDErr = 'invalidEmiratesID';
            }
        } else {
            emiratesIDErr = 'thisFieldIsRequired';
        }

        if (this.state.retirementCont &&
            this.state.totalSalary
        ) {
            let retirementContAfterReplace = this.state.retirementCont.replace(/,/g, "");
            let totalSalAfterReplace = this.state.totalSalary.replace(/,/g, "");
            if (parseFloat(retirementContAfterReplace) > parseFloat(totalSalAfterReplace)) {
                retirmentContErr = 'retirmentContErr';
            } else {
                retirmentContErr = '';
            }
        }
        
        this.setState({
            periodOfSendMentErr_0: periodOfSendMentErr_0,
            periodOfSendMentErr: periodOfSendMentErr,
            salaryErr: salaryErr,
            errForEntityRespForSal: errForEntityRespForSal,
            emiratesIDErr: emiratesIDErr,
            totalSalaryErr: totalSalaryErr,
            basicSalErr: basicSalErr,
            retirmentContErr: retirmentContErr,
            reasonForGovPaySalaryErr,
            BorrowEntityIDErr:BorrowEntityIDErr,
        }, () => {

            if (!periodOfSendMentErr_0 &&
                !periodOfSendMentErr &&
                !salaryErr &&
                !errForEntityRespForSal &&
                !emiratesIDErr &&
                !basicSalErr &&
                !totalSalaryErr &&
                !retirmentContErr && !reasonForGovPaySalaryErr && !BorrowEntityIDErr
            ) {

                if (
                    this.state.entityName &&
                    this.state.employeeName &&
                    this.state.jobName &&
                    this.state.employeeNumber &&
                    this.state.basicSalary &&
                    this.state.totalSalary &&
                    this.state.retirementCont &&
                    this.state.fromDatePeriodOfSecndMent &&
                    this.state.toDatePeriodOfSecndMent &&
                    this.state.entityRespForSal &&
                    this.state.unifiedNumber&& this.state.BorrowEntityID) {

                    this.props.getData.stepOneData({
                        entityName: this.state.entityName,
                        employeeName: this.state.employeeName,
                        jobName: this.state.jobName,
                        employeeNumber: this.state.employeeNumber,
                        totalSalary: this.state.totalSalary,
                        basicSalary: this.state.basicSalary,
                        retirementCont: this.state.retirementCont,
                        fromDatePeriodOfSecndMent: this.state.fromDatePeriodOfSecndMent,
                        toDatePeriodOfSecndMent: this.state.toDatePeriodOfSecndMent,
                        entityRespForSal: this.state.entityRespForSal,
                        unifiedNumber: this.state.unifiedNumber,
                        reasonForGovPaySalary:this.state.reasonForGovPaySalary,
                        // familyNumber: this.state.familyNumber,
                        emiratesID: this.state.emiratesID,
                        empPassNoticePeriod: this.state.empPassNoticePeriod,
                        lastYearPerformanceEfficiency: this.state.lastYearPerformanceEfficiency,
                        employeeAgreement: this.state.employeeAgreement,
                        empShouldPassProbationPeriodLabel: this.state.empShouldPassProbationPeriodLabel,
                        lastYearPerfDegMoreThanWeekLabel: this.state.lastYearPerfDegMoreThanWeekLabel,
                        employeeAgreementLabel: this.state.employeeAgreementLabel,
                        BorrowEntityID:this.state.BorrowEntityID,
                    });
                }
            }

        });
    }

    withoutValidations = () => {
        this.props.getData.stepOneData({
            BorrowEntityID:this.state.BorrowEntityID,
            entityName: this.state.entityName,
            employeeName: this.state.employeeName,
            jobName: this.state.jobName,
            employeeNumber: this.state.employeeNumber,
            totalSalary: this.state.totalSalary,
            basicSalary: this.state.basicSalary,
            retirementCont: this.state.retirementCont,
            fromDatePeriodOfSecndMent: this.state.fromDatePeriodOfSecndMent,
            toDatePeriodOfSecndMent: this.state.toDatePeriodOfSecndMent,
            entityRespForSal: this.state.entityRespForSal,
            unifiedNumber: this.state.unifiedNumber,
            reasonForGovPaySalary:this.state.reasonForGovPaySalary,
            // familyNumber: this.state.familyNumber,
            emiratesID: this.state.emiratesID,
            empPassNoticePeriod: this.state.empPassNoticePeriod,
            lastYearPerformanceEfficiency: this.state.lastYearPerformanceEfficiency,
            employeeAgreement: this.state.employeeAgreement,
            empShouldPassProbationPeriodLabel: this.state.empShouldPassProbationPeriodLabel,
            lastYearPerfDegMoreThanWeekLabel: this.state.lastYearPerfDegMoreThanWeekLabel,
            employeeAgreementLabel: this.state.employeeAgreementLabel,
        });
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
        // if (this.state.isEdit) {
        //     this.withoutValidations();
        //     // if (this.state.statusid === Config.getDraftId()) {
        //     //     this.checkValidations();
        //     // } else {
        //     //     this.withoutValidations();
        //     // }
        // } else {
        //     this.checkValidations();
        // }
    };

    clearForm = () => {
        this.setState({
            employeeName: '',
            jobName: '',
            employeeNumber: '',
            totalSalary: '',
            basicSalary: '',
            retirementCont: '',
            fromDatePeriodOfSecndMent: null,
            toDatePeriodOfSecndMent: null,
            entityRespForSal: '',
            unifiedNumber: '',
            reasonForGovPaySalary:'',
            // familyNumber: this.state.familyNumber,
            emiratesID: '',
            empPassNoticePeriod: false,
            lastYearPerformanceEfficiency: false,
            employeeAgreement: false,
            periodOfSendMentErr_0: "",
            periodOfSendMentErr: "",
            salaryErr: "",
            errForEntityRespForSal: "",
            emiratesIDErr: "",
            empPassNoticePeriodErr: "",
            lastYearPerformanceEfficiencyErr: "",
            employeeAgreementErr: "",
            totalSalaryErr: "",
            basicSalErr: "",
            retirmentContErr: "",
            BorrowEntityID: '0', entityArray: [],
        });
    }

    handleEntityName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z ]*$/;
        if (regex.test(event.target.value)) {
            this.setState({ entityName: event.target.value });
        }
        else {
            this.setState({ entityName: "" });
        }
    };

    handleEmployeeNameChange = (event) => {

        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z ]*$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };

    handleJobNameChange = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z ]*$/;
            if (regex.test(event.target.value)) {
                this.setState({ jobName: event.target.value });
            }
        } else {
            this.setState({ jobName: '' });
        }
    };

    handleEmployeeNumberChange = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (event.target.value) {
            if (regex.test(event.target.value)) {
                this.setState({ employeeNumber: event.target.value });
            }
        } else {
            this.setState({ employeeNumber: "" });
        }
    };

    handleBasicSalaryChange = (value) => {
        if (value) {
            this.setState({ basicSalary: value.formattedValue, basicSalErr: '', salaryErr: '' });
        }
    };

    handleTotalSalaryChange = (value) => {
        if (value) {
            this.setState({ totalSalary: value.formattedValue, totalSalaryErr: "" });
        }
    };

    handleRetirementContribution = (value) => {
        if (value) {
            this.setState({ retirementCont: value.formattedValue, retirmentContErr: "" });
        }
    };

    unifiedNumberChange = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ unifiedNumber: event.target.value });
            }
        } else {
            this.setState({ unifiedNumber: "" });
        }
    };

    emiratesIDChange = (event) => {
        let emiratesID = event.target.value;
        if (event.target.value !== '') {
            this.setState({ emiratesID: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesID)) {
                    this.setState({ emiratesIDErr: '' });
                } else {
                    this.setState({ emiratesIDErr: 'invalidEmiratesID' });
                }
            });
        } else {
            this.setState({ emiratesID: '', emiratesIDErr: 'thisFieldIsRequired' });
        }
    };

    handleDateOfFromDate = (date) => {
        if (date) {
            // add +1 day
            var d = new Date(date);
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var tempDate = new Date(year, month + 3, day - 1);

            this.setState({
                fromDatePeriodOfSecndMent: date,
                periodOfSendMentErr_0: '',
                toDatePeriodOfSecndMent: tempDate
            });
        } else {
            this.setState({
                fromDatePeriodOfSecndMent: null,
                toDatePeriodOfSecndMent: null
            });
        }
      
    }

    handleDateOfToDate = (date) => {
        if (this.state.fromDatePeriodOfSecndMent > date) {
            this.setState({
                periodOfSendMentErr: i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.fromDateLessThanToDate
            });
        }
        else {
            this.setState({ periodOfSendMentErr: '', toDatePeriodOfSecndMent: date });
        }

        if (date == null) {
            this.setState({ toDate: null, toDatePeriodOfSecndMent: null })
        }
    }

    handleOptionChangeForEntityResponsibleForSal = (e) => {
        this.setState({ errForEntityRespForSal: '', entityRespForSal: e.target.value });
    };

    handleEmpPassProbationPeriod = (e) => {
        if (e.target.checked) {
            this.setState({
                empShouldPassProbationPeriodLabel: this.props.isArabic ? i18n[this.getLocale()].yes : i18n[this.getLocale()].yes,
                empPassNoticePeriod: !this.state.empPassNoticePeriod,
                empPassNoticePeriodErr: ''
            });
        } else {
            this.setState({
                empShouldPassProbationPeriodLabel: this.props.isArabic ? i18n[this.getLocale()].no : i18n[this.getLocale()].no,
                empPassNoticePeriod: !this.state.empPassNoticePeriod,
                empPassNoticePeriodErr: 'thisFieldIsRequired'
            });
        }
    }

    handleLastYearPerformanceEfficiency = (e) => {
        if (e.target.checked) {
            this.setState({

                lastYearPerfDegMoreThanWeekLabel: this.props.isArabic ? i18n[this.getLocale()].yes : i18n[this.getLocale()].yes,
                lastYearPerformanceEfficiency: !this.state.lastYearPerformanceEfficiency,
                lastYearPerformanceEfficiencyErr: ''
            });
        } else {
            this.setState({
                lastYearPerfDegMoreThanWeekLabel: this.props.isArabic ? i18n[this.getLocale()].no : i18n[this.getLocale()].no,
                lastYearPerformanceEfficiency: !this.state.lastYearPerformanceEfficiency,
                lastYearPerformanceEfficiencyErr: 'thisFieldIsRequired'
            });
        }
    }

    handleEmployeeAgreement = (e) => {
        if (e.target.checked) {
            this.setState({
                employeeAgreementLabel: this.props.isArabic ? i18n[this.getLocale()].yes : i18n[this.getLocale()].yes,
                employeeAgreement: !this.state.employeeAgreement,
                employeeAgreementErr: ''
            });
        } else {
            this.setState({
                employeeAgreementLabel: this.props.isArabic ? i18n[this.getLocale()].no : i18n[this.getLocale()].no,
                employeeAgreement: !this.state.employeeAgreement,
                employeeAgreementErr: 'thisFieldIsRequired'
            });
        }
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    onDatepickerRef1(el) {
        if (el && el.input) {
            if (this.state.fromDatePeriodOfSecndMent === null) {
                el.input.disabled = true;
                el.input.readOnly = false;
                el.input.classList.remove('white_bg');
            }
            else {
                el.input.disabled = false;
                el.input.readOnly = true;
                el.input.classList.add('white_bg');
            }

            if (el.input.type === "blur") {
                el.input.target.style.backgroundColor = "white";
            }
        }
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            entityName: this.state.entityName,
            employeeName: this.state.employeeName,
            jobName: this.state.jobName,
            employeeNumber: this.state.employeeNumber,
            totalSalary: this.state.totalSalary,
            basicSalary: this.state.basicSalary,
            retirementCont: this.state.retirementCont,
            fromDatePeriodOfSecndMent: this.state.fromDatePeriodOfSecndMent,
            toDatePeriodOfSecndMent: this.state.toDatePeriodOfSecndMent,
            entityRespForSal: this.state.entityRespForSal,
            unifiedNumber: this.state.unifiedNumber,
            reasonForGovPaySalary:this.state.reasonForGovPaySalary,
            // familyNumber: this.state.familyNumber,
            emiratesID: this.state.emiratesID,
            empPassNoticePeriod: this.state.empPassNoticePeriod,
            lastYearPerformanceEfficiency: this.state.lastYearPerformanceEfficiency,
            employeeAgreement: this.state.employeeAgreement,
            BorrowEntityID:this.state.BorrowEntityID,
            
        });
    };

    render() {
        return (
            <form
                style={{ marginTop: 20, marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate >

                <StepsComponent steps={["Step1", "Step2"]} currentIndex={1} lineColor='#eeeeee' onChange={this.onChangehandler} activeColor='#00BCD4'
                    isArabic={this.props.isArabic} nonActiveColor='#eeeeee' />

                {/* required condition commented */}
                {/* <MDBRow className="requiredcondition" style={{ textAlign: this.props.isArabic ? 'right' : 'justify' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <p>{i18n[this.getLocale()].requiredcondition}</p>
                            <p>{i18n[this.getLocale()].secondmentreqquestconditionone}</p>
                            <p>{i18n[this.getLocale()].secondmentreqquestconditiontwo}</p>
                            <p>{i18n[this.getLocale()].secondmentreqquestconditionthree}</p>
                            <p>{i18n[this.getLocale()].secondmentreqquestconditionfour}</p>
                        </div>
                    </MDBCol>
                </MDBRow> */}

                <MDBRow className="formRow">
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                        {i18n[this.getLocale()].secondement_For_Federal_Agency_Step.BorrowEntityID}
                                        <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                                    </label>
                                    <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForBorrowEntityID}
                                        value={this.state.BorrowEntityID}
                                        required>
                                        <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}</option>
                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                </div>
                            </MDBCol>
                            

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Entity_Name}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="entityName"
                                name="entityName"
                                maxlength='50'
                                value={this.state.entityName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEntityName}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                  </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group" style={{ textAlign: this.props.isArabic ? 'right' : 'left', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                            <label style={{ width: '100%', display: 'flex', }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.The_entity_which_responsible_for_salary}
                                <span className="requireStar">*</span></label>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value={"Goverment"}
                                    checked={this.state.entityRespForSal === "Goverment"}
                                    // checked={this.state.entityRespForSal === i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.goverment}
                                    class="custom-control-input"
                                    id="customControlValidation2"
                                    onChange={this.handleOptionChangeForEntityResponsibleForSal}
                                    name="radio-stacked"
                                    required />
                                <label class="custom-control-label" for="customControlValidation2">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.goverment}
                                </label>
                            </div>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value={"Competent"}
                                    class="custom-control-input"
                                    id="customControlValidation3"
                                    name="radio-stacked"
                                    checked={this.state.entityRespForSal === "Competent"}
                                    // checked={this.state.entityRespForSal === i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.competent}
                                    onChange={this.handleOptionChangeForEntityResponsibleForSal}
                                    required />
                                <label class="custom-control-label" for="customControlValidation3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.competent}
                                </label>
                            </div>
                            <br />

                            {this.state.errForEntityRespForSal === 'thisFieldIsRequired' ?

                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                  
                <MDBCol xs="6" sm="6" md="6" lg="8" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Employee_Name}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="employeeName"
                                maxlength='50'
                                name="employeeName"
                                value={this.state.employeeName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEmployeeNameChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
               
                        
                      </MDBRow>

                      <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.unifiedNumber}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="unifiedNumber"
                                name="unifiedNumber"
                                maxlength="50"
                                value={this.state.unifiedNumber}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.unifiedNumberChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div className="form-group">
                                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.familyNumber}
                                            <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="familyNumber"
                                            name="familyNumber"
                                            min={0}
                                            value={this.state.familyNumber}
                                            style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                            onChange={this.familyNumberChange}
                                            required
                                        />
                                        <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                    </div>
                                </MDBCol> */}

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].emiratesID}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>

                            <InputMask
                                mask="999-9999-9999999-9"
                                maskChar={null}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.emiratesID}
                                onChange={this.emiratesIDChange}
                                className="form-control"
                                required
                            />
                            {
                                this.state.emiratesIDErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.emiratesIDErr == 'invalidEmiratesID' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmiratesID}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Job_Name}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="jobName"
                                name="jobName"
                                maxlength="50"
                                value={this.state.jobName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleJobNameChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Employee_Number}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="employeeNumber"
                                name="employeeNumber"
                                value={this.state.employeeNumber}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEmployeeNumberChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Basic_Salary}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <div class="input-group mb-3" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                               

                                <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.basicSalErr !== '' || this.state.salaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>

                                    <CurrencyFormat
                                        thousandSeparator={true}
                                        value={this.state.basicSalary}
                                        onValueChange={(value) => { this.handleBasicSalaryChange(value) }}
                                        display={"text"}
                                        maxLength={10}
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    />

                                </div>

                                {
                                    this.state.basicSalErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.state.salaryErr === 'salaryErr' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].salaryErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Total_Salary}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <div class="input-group mb-3" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                {/* <div class="input-group-prepend" >
                                                <span class="input-group-text">$</span>
                                            </div> */}
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    id="totalSalary"
                                    name="totalSalary"
                                    min={0}
                                    style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                    value={this.state.totalSalary}
                                    onChange={this.handleTotalSalaryChange}
                                    required
                                />
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div> */}
                                <div className="currencyFormatInputCSS currencyFormat" style={{ border: this.state.totalSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>

                                    <CurrencyFormat
                                        thousandSeparator={true}
                                        value={this.state.totalSalary}
                                        onValueChange={(value) => { this.handleTotalSalaryChange(value) }}
                                        display={"text"}
                                        maxLength={10}
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    />

                                </div>

                                {this.state.totalSalaryErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Retirement_Contributions}
                                <span style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <div class="input-group mb-3" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                {/* <div class="input-group-prepend">
                                                  <span class="input-group-text">$</span>
                                                      </div> */}
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    id="retirementCont"
                                    name="retirementCont"
                                    min={0}
                                    style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                    value={this.state.retirementCont}
                                    onChange={this.handleRetirementContribution}
                                    required
                                />
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div> */}

                                <div
                                    className="currencyFormatInputCSS currencyFormat"
                                    style={{
                                        border: this.state.retirmentContErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                    }}>
                                    <CurrencyFormat
                                        thousandSeparator={true}
                                        value={this.state.retirementCont}
                                        onValueChange={(value) => { this.handleRetirementContribution(value) }}
                                        display={"text"}
                                        maxLength={10}
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    />
                                </div>

                                {
                                    this.state.retirmentContErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.state.retirmentContErr === 'retirmentContErr' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].RetirementContributionsErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group"  >
                            <label htmlFor="exampleFormControlTextarea2"
                                style={{
                                    display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                                    width: '100%'
                                }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Period_of_Secondment}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <MDBRow xs="6" sm="6" md="6" lg="6" xl="6" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div
                                        style={{
                                            width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                        }}
                                        className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.periodOfSendMentErr_0 ? "datePickerBorderColorErr" : ""}`}>
                                        <DatePicker
                                            className="form-control"
                                            todayButton={"Today"}
                                            maxDate={this.state.dateAfterOneYear}
                                            style={{ width: '100%' }}
                                            placeholderText={i18n[this.props.isArabic ? 'ar' : 'en'].fromDate}
                                            selected={this.state.fromDatePeriodOfSecndMent}
                                            onChange={this.handleDateOfFromDate}
                                            ref={el => this.onDatepickerRef(el)}
                                            customInput={<input />}
                                            dateFormat="MM/dd/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            //showTimeInput
                                            required />
                                    </div>

                                    {
                                        this.state.periodOfSendMentErr_0 === 'thisFieldIsRequired' ?
                                            <div className="textRed" style={{ width: '100%', textAlign: this.props.isArabic ? 'right' : "left" }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                    }
                                </MDBCol>
                                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }} className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar" : "react-datepicker-popper"} ${this.state.periodOfSendMentErr ? "datePickerBorderColorErr" : ""}`}>
                                        <DatePicker
                                            className="form-control"
                                            todayButton={"Today"}
                                            style={{ width: '100%' }}
                                            placeholderText={i18n[this.props.isArabic ? 'ar' : 'en'].toDate}
                                            minDate={this.state.toDatePeriodOfSecndMent}
                                            selected={this.state.toDatePeriodOfSecndMent}
                                            onChange={this.handleDateOfToDate}
                                            disabled={this.state.fromDatePeriodOfSecndMent !== null ? false : true}
                                            ref={el => this.onDatepickerRef1(el)}
                                            customInput={<input />}
                                            dateFormat="MM/dd/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                            required
                                        />
                                    </div>

                                    {this.state.periodOfSendMentErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', textAlign: this.props.isArabic ? 'right' : "left" }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null}
                                </MDBCol>

                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>

         {this.state.entityRespForSal==="Goverment"? 
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

               
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].secondement_For_Federal_Agency_Step.reasonForGovPaySalary}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.reasonForGovPaySalaryErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.reasonForGovPaySalary}
                                            events={{
                                                "change": this.onReasonForGovPaySalary
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }

                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.reasonForGovPaySalary}
                                            events={{
                                                "change": this.onReasonForGovPaySalary
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit }
                            </p>
                            {
                                this.state.reasonForGovPaySalaryErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.reasonForGovPaySalaryErr === 'ckeditorCountErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].ckeditorCountErr}
                                    </div>
                                    :
                                    null
                            }
                            

                        </div>
                    </MDBCol>
           
                </MDBRow>  :null }

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                        <div className="form-group" >
                            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                <input
                                    type="checkbox"
                                    onChange={this.handleEmpPassProbationPeriod}
                                    style={{ cursor: 'pointer', marginTop: '5px' }}
                                    checked={this.state.empPassNoticePeriod ? true : ''}
                                />
                                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.empPassNoticePeriod}
                                    {/* <span className="requireStar">*</span> */}
                                </label>
                            </div>
                            {/* {
                                this.state.empPassNoticePeriodErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            } */}

                        </div>
                    </MDBCol>

                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                        <div className="form-group">
                            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                <input
                                    type="checkbox"
                                    onChange={this.handleLastYearPerformanceEfficiency}
                                    style={{ cursor: 'pointer', marginTop: '5px' }}
                                    checked={this.state.lastYearPerformanceEfficiency ? true : ''}
                                />
                                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.lastYearPerformanceEfficiency}
                                    {/* <span className="requireStar">*</span> */}
                                </label>
                            </div>
                            {/* {
                                this.state.lastYearPerformanceEfficiencyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            } */}

                        </div>
                    </MDBCol>

                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                        <div className="form-group">
                            <div style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                <input
                                    type="checkbox"
                                    onChange={this.handleEmployeeAgreement}
                                    style={{ cursor: 'pointer', marginTop: '5px' }}
                                    checked={this.state.employeeAgreement ? true : ''}
                                />
                                <label className={this.props.isArabic ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : '' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.employeeAgreement}
                                    {/* <span className="requireStar">*</span> */}
                                </label>
                            </div>
                            {/* {
                                this.state.employeeAgreementErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            } */}

                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn type='submit' color="primary" className="submitBtn">
                        {i18n[this.props.isArabic ? 'ar' : 'en'].next_btn}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>

            </form>
        );
    }
}