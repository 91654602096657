import React, { Component } from 'react';
import { MDBCol, MDBRow, MDBFormInline, MDBBtn, MDBTableHead, MDBTable, MDBTableBody } from 'mdbreact';
import { saveCommentPayload, getRequestByIdPayload } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import Config from '../../utils/Config';
import i18n from '../../i18n';
import LoadingOverlay from 'react-loading-overlay'

export default class CommentComponentWithTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            requestType: '',
            isLoading: false,
            commentValue: '',
            commentsArray: [],              tableHeaderArray: [
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].CreatedBy },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].Comment },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].Date }
            ]
        };
        this.comment = "";
        this.HandelcheckBox = this.HandelcheckBox.bind(this);
    }

    componentDidMount = () => {
        
        if (this.props.requesttypeid) {
            if (this.props.requesttypeid ===10969) {
                this.setState({
                    requestType: Config.getSciRequestTypeId()
                }, () => {
                    this.callRequestById('GetStrategicPlanningforworkforceEnquirebyID');
                })
            }
       else      if (this.props.requesttypeid === Config.getSciRequestTypeId() || this.props.requesttypeid === Config.getSCILEgalSerbviceId() || this.props.requesttypeid === Config.getSCIComplainId() || this.props.requesttypeid === Config.getSCILEgalSuggestionId()
            || this.props.requesttypeid === Config.getSCIGeneralId() ){
                this.callRequestById('GetscirequestbyID');
            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.callRequestById('GetinformationrequestbyID');
            } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                this.callRequestById('GetbounsrequestbyID');
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.callRequestById('GetAmmendSalaryScalebyID');
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.callRequestById('GetAmmendEmployeeSalarybyID');
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.callRequestById('GetHRPolicyReviewbyID');
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.callRequestById('GetHousingPolicybyID');
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.callRequestById('GetFederalSecondmentbyID');
            } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                this.callRequestById('GetPoliciesPatientrequestbyID');
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
                this.callRequestById('GetRetirementbyID');
            }
            else if (this.props.requesttypeid === Config.getSickLeaveTypeId()) {
               
                this.callRequestById('GetSickLeaveById');
            
        }
            else if (this.props.requesttypeid === Config.getPromotionTypeId()) {
               
                this.callRequestById('GetPromotionById');
            
        }
        else if (this.props.requesttypeid === Config.getDisciplinaryboardTypeId()) {
               
            this.callRequestById('GetDisciplinaryboardById');
        
    } 
        
        else if (this.props.requesttypeid === Config.getExtendCreateObjectiveId()) {
                this.callRequestById('GetExtendCreateObjectivesbyID');
            } else if (this.props.requesttypeid === Config.getExtendReviewObjectiveId()) {
                this.callRequestById('GetExtendReviewObjectivesbyID');
            } else if (this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                this.callRequestById('GetExtendAnnualPerformancebyID');
            } else if (this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                this.callRequestById('GetEmpPerformancebyID');
            } else if (this.props.requesttypeid === Config.getJoinAndImplementId()) {
                this.callRequestById('GetJoinAndImplementSystemsbyID');
            } else if (this.props.requesttypeid === Config.getOpenComplainsStageId()) {
                this.callRequestById('GetOpenGrievanceProcessbyID');
            } else if (this.props.requesttypeid === Config.getAmendOnSharedGovermentId()) {
                this.callRequestById('GetAmendSystemsbyID');
            } else if (this.props.requesttypeid === Config.getReportAndStaticId()) {
                this.callRequestById('GetReportsRequestsbyID');
            } else if (this.props.requesttypeid === Config.getOtherRequest()) {
                this.callRequestById('GetOtherRequestsbyID');
            }
        }
    }

    callRequestById = (endPoint) => {
        let id = this.props.id;
        let payload = getRequestByIdPayload(id);
        // let endPoint = 'GetscirequestbyID'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let tempCommentsArray = [];
                        let commentDataFromRes = res.data.comments;
                        if (commentDataFromRes && commentDataFromRes.length > 0) {
                            for (let index = 0; index < commentDataFromRes.length; index++) {
                                let tempCommentObj = {};
                                let comment = commentDataFromRes[index].notes;
                                let commentedby = commentDataFromRes[index].commentedby;
                                let commentedon = Config.getFormattedDate(commentDataFromRes[index].commentedon);
                                if (comment !== "") {
                                    tempCommentObj.name = comment;
                                    tempCommentObj.commentedby = commentedby;
                                    tempCommentObj.commentedon = commentedon;
                                    tempCommentObj.id = commentDataFromRes[index].id;
                                    tempCommentObj.isPublished=  commentDataFromRes[index].isPublished;
                                    tempCommentsArray.push(tempCommentObj);
                                }
                            }
                            this.setState({
                                commentsArray: tempCommentsArray
                            }, () => {
                                // TODO
                            })
                        }
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    updateCommentsArray = (i) => {
        let temp = [...this.state.commentsArray];
        temp.splice(i, 1);
        this.setState({
            commentsArray: temp
        }, () => {
            this.props.update(this.state.commentsArray)
        });
    }

    HandelcheckBox(e) {
       
        const item = e.target.name;
        const isChecked = e.target.checked;
        let tempCommentsArray = [];
        var iterator1 =  this.state.commentsArray[Symbol.iterator]();
       
        for (let citem of iterator1) {
            let tempCommentObj = {};
            if(citem.id==item)
            {
                tempCommentObj.name = citem.name;
                tempCommentObj.commentedby = citem.commentedby;
                                    tempCommentObj.commentedon = citem.commentedon;
                                    tempCommentObj.isPublished = isChecked;
                                   
                                    tempCommentObj.id = citem.id;
          
            }
            else
            {
                tempCommentObj.name = citem.name;
                tempCommentObj.commentedby = citem.commentedby;
                tempCommentObj.commentedon = citem.commentedon;
                tempCommentObj.isPublished = citem.isPublished;
               
                tempCommentObj.id = citem.id;

            }
            tempCommentsArray.push(tempCommentObj);

        }
      
        this.setState({
            commentsArray: tempCommentsArray
        })
       

    }

    publishComments = () => {
        
        let obj = {};
        obj.requestid = this.props.id;
       
       obj.requesttype = this.props.requesttypeid;
       // obj.study = this.state.comment;
        obj.CheckedComments = '';
       
        var iterator1 =  this.state.commentsArray[Symbol.iterator]();
        for (let item of iterator1) {
            if(item.isPublished)
            {
            if(obj.CheckedComments!='')
            {
                obj.CheckedComments=obj.CheckedComments+','+item.id;
            }
            else
{
    obj.CheckedComments=item.id;
}
            }
        }

       

        // call save status update api
     /*   let payload = 
        updateStudyorFeedbackPayload(obj);*/
       
       
        
        let endPoint = "PublishComments";
        let url = Config.getUrl(endPoint);
        
        let attachment ='';// Config.getBase64ForInformatinRequestForReply();
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url,  obj, res => {
               
                if (res.Status) {
                    //this.props.toggle(true);
                     if (attachment !== '') {
                         this.uploadAttachment(attachment);
                     } else {
                         this.setState({
                             isLoading: false
                         }, () => {
                             this.props.toggle(true);
                         })
                     }
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.toggle(false);
                    })
                }
            });
        })
    };
    populateComments = () => {
        return (
            <div>
                <MDBRow style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    <MDBCol md="12">
                        <MDBTable striped responsive hover style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                            <MDBTableHead>
                                <tr style={{ textAlign: "center" }}>
                                    {
                                        this.state.tableHeaderArray ? this.state.tableHeaderArray.map((item, index) => {
                                            return (<th>
                                                {item.tableHeader}
                                            </th>
                                            )
                                        })
                                            : ''
                                    }
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                    this.state.commentsArray ? this.state.commentsArray.map((item, index) => {
                                        return (<tr style={{ textAlign: "center" }}>
                                            <td>
                                                {Config.getLocalStorageLoginData().RoleID==18 
                                                ||Config.getLocalStorageLoginData().RoleID==28
                                                ||Config.getLocalStorageLoginData().RoleID==29
                                                ||Config.getLocalStorageLoginData().RoleID==33
                                                ||Config.getLocalStorageLoginData().RoleID==53
                                                ||Config.getLocalStorageLoginData().RoleID==65
                                                ||Config.getLocalStorageLoginData().RoleID==64
                                                ||Config.getLocalStorageLoginData().RoleID==67
                                                ?
                                                
                                                <input type="checkbox" value={item.id} checked={item.isPublished} key={item.id} name={item.id}  onChange={this.HandelcheckBox} />
                                                :''
                                                 } 
                                                {'   '+item.commentedby}
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.commentedon}
                                            </td>
                                        </tr>)
                                    }) : ''
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
                 {Config.getLocalStorageLoginData().RoleID==18
||Config.getLocalStorageLoginData().RoleID==28
||Config.getLocalStorageLoginData().RoleID==29
||Config.getLocalStorageLoginData().RoleID==33
||Config.getLocalStorageLoginData().RoleID==53
||Config.getLocalStorageLoginData().RoleID==65
||Config.getLocalStorageLoginData().RoleID==64
||Config.getLocalStorageLoginData().RoleID==67
                                                ?
                                              
                                               
                                                 
                <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%',margin:'1% 0 0 0' }}>
                            <MDBBtn
                               onClick={this.publishComments} className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {" "}
                                {i18n[this.props.isArabic ? "ar" : "en"].postComment}{" "}
                            </MDBBtn>

                            <MDBBtn
                                onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}>
                                {" "}
                                {i18n[this.props.isArabic ? "ar" : "en"].cancle}{" "}
                            </MDBBtn>
                        </MDBRow> :''
                        }
                <br />
            </div>)
    }

    updateComment = () => {
        let obj = {}

        obj.id = this.props.id;;
        obj.requesttype = this.state.requestType;
        obj.notes = this.state.commentValue;

        let payload = saveCommentPayload(obj);
        let endPoint = 'SaveComment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let tempCommentsArray = [];
                        let commentDataFromRes = res.data;
                        if (commentDataFromRes && commentDataFromRes.length > 0) {
                            for (let index = 0; index < commentDataFromRes.length; index++) {
                                let tempCommentObj = {};
                                let comment = commentDataFromRes[index].notes;
                                let commentedby = commentDataFromRes[index].commentedby;
                                let commentedon = commentDataFromRes[index].commentedon;
                                if (comment !== "") {
                                    tempCommentObj.name = comment
                                    tempCommentObj.commentedby = commentedby
                                    tempCommentObj.commentedon = commentedon
                                    tempCommentsArray.push(tempCommentObj);
                                }
                            }
                            this.setState({
                                commentValue: '',
                                commentsArray: tempCommentsArray
                            }, () => {
                                this.props.update(this.state.commentsArray);
                            })
                        }
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    render() {
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBCol>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <MDBCol>

                        {!this.props.disabled ? <MDBRow style={{ justifyContent: 'center', maxHeight: '35vh' }}>
                            <MDBFormInline>
                                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center', paddingRight: '1%', paddingLeft: '1%' }}>
                                    <input
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                        type="search"
                                        className="MDBInputBorderOverRide"
                                        style={{ padding: '5px', direction: this.props.isArabic ? 'rtl' : 'ltr', margin: 0, width: '65%' }} value={this.state.commentValue} onChange={(e) => {
                                            this.setState({
                                                commentValue: e.target.value
                                            })
                                        }} />

                                    <MDBBtn size="md" style={{ maxHeight: '5vw', width: '30%' }} color="primary" onClick={() => {
                                        let temp = this.state.commentValue;
                                        temp = temp.trim();
                                        if (temp) {
                                            let tempArray = this.state.commentsArray;
                                            tempArray.push({ name: temp })
                                            this.setState({
                                                commentsArray: tempArray
                                            }, () => {
                                                this.updateComment();
                                            });
                                        }
                                        else {
                                            this.setState({
                                                commentValue: ''
                                            });
                                        }

                                    }}>{i18n[this.props.isArabic ? 'ar' : 'en'].postComment}</MDBBtn>

                                </MDBRow>

                            </MDBFormInline>

                        </MDBRow> : null}

                        <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                            {this.populateComments()}
                        </div>

                    </MDBCol>
                    {
                        !this.props.hideCloseBtn ?
                            <MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', width: '100%', paddingRight: '1%', paddingLeft: '1%', justifyContent: 'center' }}>

                                <MDBBtn size="md" style={{ maxHeight: '5vw' }} color="primary" onClick={() => this.props.toggle(false)}>{i18n[this.props.isArabic ? 'ar' : 'en'].update_status.Close}</MDBBtn>
                            </MDBRow>
                            :
                            null
                    }
                </LoadingOverlay>
            </MDBCol>
        );
    };
}