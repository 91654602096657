import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import StepsComponent from "../../components/StepsComponent";
import Config from "../../utils/Config";
import i18n from "./../../i18n";
import "./../../css/style.css";
import DownloadComponent from "./../DownloadComponent";



export default class Upload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requesttypeid: '',
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getExceptionAcompanypatientRequestTypeId(),
            passportCopy: '',
            passportCopyUrl: '',
            medicalReport:'',
            medicalReportUrl: '',
            letterFromEmbassy: '',
            letterFromEmbassyUrl: '',
            copyOftheDigest: '',
            copyOftheDigestUrl: '',
            copyOfHumanResoourcesCommittee:'',
            copyOfHumanResoourcesCommitteeUrl: '',
            documentationOfFinancialObligations:'',
            documentationOfFinancialObligationsShortName:'',
            documentationOfFinancialObligationsUrl: '',
            officialLetter: '',
            emiratedID: '',
            officialLetterUrl: '',

            passportCopyErr: "",
            medicalReportErr: "",
            letterFromEmbassyErr: "",
            copyOftheDigestErr: '',
            copyOfHumanResoourcesCommitteeErr: "",
            officialLetterErr: "",
            emiratedIDErr: "",
            documentationOfFinancialObligationsFileErr: '',

            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            isSubmit: false,
            file1: "",
            documentationOfFinObligationsFileObjArray: [],
            officialLetterFromHeadOfEntityFileObj: "",
            passportCopyFIleObj: "",
            medicalReportFIleObj: "",
            letterFromEmbassyFileObj: "",
            copyOfDigestFileObj: "",
            copyOfHumanResoourcesCommitteeFile: "",
            file8: "",
            editAttachmentArray: [],
            passportCopyArray: [],
            medicalReportArray: [],
            letterFromEmbassyArray: [],
            copyOfDigestArray: [],
            copyOfHumanResourceComitteArray: [],
            documentOfFinacialObligationArray: [],
            officialterArray: [],
            emiratesIdArray: [],
            emiratesIDFileObj: "",    OtherAttachArray: [],
            OtherAttachErr: "",
            OtherAttach: '',
            OtherAttachFileObj: "",
           
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
            this.setState({
                requesttypeid: editObj.requesttypeid,
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempPassportCopyArray = [];
                let tempMedicalReportArray = [];
                let tempLetterFromEmbassyArray = [];
                let tempCopyOfDigestArray = [];
                let tempCopyOfHumanResourceComitteArray = [];
                let tempDocumentOfFinacialObligationArray = [];
                let tempOfficialLetterArray = [];
                let tempEmiratesIdArray = [];
                let tempOtherAttachArray = [];
                
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getPatientPassortCopyId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempPassportCopyArray.push(temp);
                                this.setState({
                                    //passportCopy: item.filename,
                                    passportCopyUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientMedicalReportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempMedicalReportArray.push(temp);
                                this.setState({
                                    //medicalReport: item.filename,
                                    medicalReportUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientLetterFromEmbassyId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;
                                tempLetterFromEmbassyArray.push(temp);
                                this.setState({
                                    //letterFromEmbassy: item.filename,
                                    letterFromEmbassyUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientCopyOfdDigestId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;
                                tempCopyOfDigestArray.push(temp);
                                this.setState({
                                    //copyOftheDigest: item.filename,
                                    copyOftheDigestUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientHumanResourceComitteRecordId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempCopyOfHumanResourceComitteArray.push(temp);
                                this.setState({
                                    //copyOfHumanResoourcesCommittee: item.filename,
                                    copyOfHumanResoourcesCommitteeUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientFinancialObligationId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;
                                tempDocumentOfFinacialObligationArray.push(temp);
                                this.setState({
                                    //documentationOfFinancialObligations: item.filename,
                                    documentationOfFinancialObligationsUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getpatientDetailedsalaryCertificateId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempOfficialLetterArray.push(temp);
                                this.setState({
                                    //officialLetter: item.filename,
                                    officialLetterUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientEmiratesId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempEmiratesIdArray.push(temp);
                                this.setState({
                                    //emiratedID: item.filename
                                })
                                
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getRETOtherAttachId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempOtherAttachArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }
                        })
                    }
                }

                this.setState({
                    emiratesIdArray: tempEmiratesIdArray,
                    passportCopyArray: tempPassportCopyArray,
                    medicalReportArray: tempMedicalReportArray,
                    letterFromEmbassyArray: tempLetterFromEmbassyArray,
                    copyOfDigestArray: tempCopyOfDigestArray,
                    copyOfHumanResourceComitteArray: tempCopyOfHumanResourceComitteArray,
                    documentOfFinacialObligationArray: tempDocumentOfFinacialObligationArray,
                    officialterArray: tempOfficialLetterArray,
                    OtherAttachArray:tempOtherAttachArray
                });

            });

        }

    };

    goToPrevious = () => {
        this.props.getData.stepThreePrev({
            officialLetter: this.state.officialLetter,
            emiratedID: this.state.emiratedID,
            passportCopy: this.state.passportCopy,
            medicalReport: this.state.medicalReport,
            letterFromEmbassy: this.state.letterFromEmbassy,
            copyOftheDigest: this.state.copyOftheDigest,
            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
            documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
            OtherAttach:this.state.OtherAttach
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    checkValidations = () => {
        let officialLetterErr = '',
            passportCopyErr = '',
            medicalReportErr = '',
            letterFromEmbassyErr = '',
            copyOfHumanResoourcesCommitteeErr = '',
            emiratedIDErr = '';

        if (this.state.officialterArray && this.state.officialterArray.length > 0) {
            officialLetterErr = "";
        } else {
            if (!this.state.officialLetter) {
                officialLetterErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.passportCopyArray && this.state.passportCopyArray.length > 0) {
            passportCopyErr = "";
        } else {
            if (!this.state.passportCopy) {
                passportCopyErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.medicalReportArray && this.state.medicalReportArray.length > 0) {
            medicalReportErr = "";
        } else {
            if (!this.state.medicalReport) {
                medicalReportErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.letterFromEmbassyArray && this.state.letterFromEmbassyArray.length > 0) {
            letterFromEmbassyErr = "";
        } else {
            if (!this.state.letterFromEmbassy) {
                letterFromEmbassyErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.copyOfHumanResourceComitteArray && this.state.copyOfHumanResourceComitteArray.length > 0) {
            copyOfHumanResoourcesCommitteeErr = "";
        } else {
            if (!this.state.copyOfHumanResoourcesCommittee) {
                copyOfHumanResoourcesCommitteeErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.emiratesIdArray && this.state.emiratesIdArray.length > 0) {
            emiratedIDErr = "";
        } else {
            if (!this.state.emiratedID) {
                emiratedIDErr = 'thisFieldIsRequired';
            }
        }

        // if (this.state.copyOftheDigest === '') {
        //   this.setState({ copyOftheDigestErr: 'thisFieldIsRequired' });
        // }
        // if (this.state.documentationOfFinancialObligations === null) {
        //     this.setState({ documentationOfFinancialObligationsFileErr: 'thisFieldIsRequired' });
        // }

        this.setState({
            officialLetterErr: officialLetterErr,
            passportCopyErr: passportCopyErr,
            medicalReportErr: medicalReportErr,
            letterFromEmbassyErr: letterFromEmbassyErr,
            copyOfHumanResoourcesCommitteeErr: copyOfHumanResoourcesCommitteeErr,
            emiratedIDErr: emiratedIDErr
        }, () => {

            if (!officialLetterErr &&
                !passportCopyErr &&
                !medicalReportErr &&
                !letterFromEmbassyErr &&
                !copyOfHumanResoourcesCommitteeErr &&
                !emiratedIDErr) {

                this.props.getData.stepThreeData({
                    officialLetter: this.state.officialLetter,
                    passportCopy: this.state.passportCopy,
                    medicalReport: this.state.medicalReport,
                    letterFromEmbassy: this.state.letterFromEmbassy,
                    copyOftheDigest: this.state.copyOftheDigest,
                    copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
                    documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
                    documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
                    emiratedID: this.state.emiratedID,
                    isEdit: this.state.isEdit,
                    requestId: this.state.requestId,
                    OtherAttach:this.state.OtherAttach,
                });
            }
        });
    }

    OtherAttachOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ OtherAttach: e.target.files[0].name, OtherAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                OtherAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForOtherAttach(this.state.OtherAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ OtherAttach: '', OtherAttachErr: 'thisFieldIsRequired' });
        //}
    };

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        //check for edit flag, if edit false => attachment are not compulsary
        this.checkValidations();

    };

    clearForm = () => {
        this.setState({
            officialLetter: '',
            emiratedID: '',
            passportCopy: '',
            medicalReport: '',
            letterFromEmbassy: '',
            copyOftheDigest: '',
            copyOfHumanResoourcesCommittee: '',
            documentationOfFinancialObligations: ''
        });
    }

    copyOfHumanResoourcesCommitteeOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                let filesObj = e.target.files[0];
                let fileExt = inpFileName.split('.').pop().toUpperCase();
                let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                if (allowedExtensions.indexOf(fileExt) <= -1) {
                    e.target.value = '';
                    this.setState({
                        copyOfHumanResoourcesCommittee: '',
                        copyOfHumanResoourcesCommitteeErr: 'invalidFileType'
                    });
                } else {

                    this.setState({
                        copyOfHumanResoourcesCommittee: inpFileName,
                        copyOfHumanResoourcesCommitteeErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                file1: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient1(this.state.file1);
                            }
                            );
                        };

                        reader.readAsDataURL(file);

                    });
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    copyOfHumanResoourcesCommittee: '',
                    copyOfHumanResoourcesCommitteeErr: 'invalidFileSize'
                });
            }
        } else {
            e.target.value = '';
            this.setState({
                copyOfHumanResoourcesCommittee: "",
                copyOfHumanResoourcesCommitteeErr: 'thisFieldIsRequired'
            });
        }
    };

    documentationOfFinancialObligationsOnChange = e => {
        let fileNames = "";
        let fileSupportArray = [];

        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            documentationOfFinancialObligations: '',
                            documentationOfFinancialObligationsShortName: '',
                            documentationOfFinancialObligationsFileErr: 'invalidFileType'
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let filesObj = e.target.files[m];
                            let comma = "";
                            if (m > 0) {
                                comma = ", ";
                            }
                            fileNames += comma + e.target.files[m].name;
                            let documentationOfFinancialObligationsShortName = fileNames.substring(0, 50);

                            this.setState({
                                documentationOfFinancialObligations: fileNames,
                                documentationOfFinancialObligationsShortName: documentationOfFinancialObligationsShortName,
                                documentationOfFinancialObligationsFileErr: ''
                            }, () => {

                                let tempFile = filesObj;
                                let reader = new FileReader();

                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({
                                        documentationOfFinObligationsFileObjArray: fileSupportArray,
                                    }, () => {
                                        Config.setbase64ForexemptionFromAccompanyPatient2(this.state.documentationOfFinObligationsFileObjArray);
                                    });
                                }
                                reader.readAsDataURL(tempFile);
                            });
                        }

                        else {
                            e.target.value = '';
                            this.setState({
                                documentationOfFinancialObligations: '',
                                documentationOfFinancialObligationsShortName: '',
                                documentationOfFinancialObligationsFileErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({ 
                    documentationOfFinancialObligations: "",
                    documentationOfFinancialObligationsShortName: "",
                    documentationOfFinancialObligationsFileErr: 'fileLimitErr' 
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({
                documentationOfFinancialObligations: '',
                documentationOfFinancialObligationsShortName: '',
                //documentationOfFinancialObligationsFileErr: 'thisFieldIsRequired' 
            });
        }
    };

    officialLetterOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    officialLetter: '',
                    officialLetterErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        officialLetter: inpFileName,
                        officialLetterErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let tempFile = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                officialLetterFromHeadOfEntityFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient3(this.state.officialLetterFromHeadOfEntityFileObj);
                            }
                            );
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ officialLetter: '', officialLetterErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ officialLetter: '', officialLetterErr: 'thisFieldIsRequired' });
        }
    };

    emiratedIDOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ emiratedID: '', emiratedIDErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        emiratedID: inpFileName,
                        emiratedIDErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let tempFile = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                emiratesIDFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionEmiratedID(this.state.emiratesIDFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        emiratedID: '',
                        emiratedIDErr: 'invalidFileSize'
                    });
                }
            }

        } else {
            e.target.value = '';
            this.setState({
                emiratedID: '',
                emiratedIDErr: 'thisFieldIsRequired'
            });
        }
    };

    passportCopyOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ passportCopyErr: 'invalidFileType', passportCopy: '' })
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        passportCopy: inpFileName,
                        passportCopyErr: ''
                    }, () => {
                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                passportCopyFIleObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient4(this.state.passportCopyFIleObj);
                            }

                            );

                        };
                        reader.readAsDataURL(file);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ passportCopy: '', passportCopyErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                passportCopy: '', passportCopyErr: 'thisFieldIsRequired'
            });
        }
    };

    medicalReportOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ medicalReportErr: 'invalidFileType', medicalReport: '' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        medicalReport: inpFileName,
                        medicalReportErr: '',
                    }, () => {
                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                medicalReportFIleObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient5(this.state.medicalReportFIleObj);

                            });
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({
                        medicalReport: '',
                        medicalReportErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                medicalReport: '', medicalReportErr: 'thisFieldIsRequired'
            });
        }
    };

    letterFromEmbassyOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    letterFromEmbassyErr: 'invalidFileType',
                    letterFromEmbassy: ''
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        letterFromEmbassy: inpFileName,
                        letterFromEmbassyErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                letterFromEmbassyFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient6(this.state.letterFromEmbassyFileObj);
                            });
                        };

                        reader.readAsDataURL(file);
                    });

                }

                else {
                    e.target.value = '';
                    this.setState({
                        letterFromEmbassy: '',
                        letterFromEmbassyErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                letterFromEmbassy: '',
                letterFromEmbassyErr: 'thisFieldIsRequired'
            });
        }
    };

    copyOftheDigestOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    copyOftheDigestErr: 'invalidFileType',
                    copyOftheDigest: ''
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        copyOftheDigest: inpFileName,
                        copyOftheDigestErr: '',
                    }, () => {

                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                copyOfDigestFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient7(this.state.copyOfDigestFileObj);
                            });
                        };

                        reader.readAsDataURL(file);

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        copyOftheDigest: '',
                        copyOftheDigestErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                copyOftheDigest: '',
                copyOftheDigestErr: ''
            });
        }
    };

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            officialLetter: this.state.officialLetter,
            passportCopy: this.state.passportCopy,
            medicalReport: this.state.medicalReport,
            letterFromEmbassy: this.state.letterFromEmbassy,
            copyOftheDigest: this.state.copyOftheDigest,
            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
            documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
            emiratedID: this.state.emiratedID,
            OtherAttach:this.state.OtherAttach
        });
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            officialLetter: this.state.officialLetter,
            passportCopy: this.state.passportCopy,
            medicalReport: this.state.medicalReport,
            letterFromEmbassy: this.state.letterFromEmbassy,
            copyOftheDigest: this.state.copyOftheDigest,
            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
            emiratedID: this.state.emiratedID,
            OtherAttach:this.state.OtherAttach
        });
    };

    render() {

        return (
            <form
                style={{ paddingTop: '20', paddingBottom: '200px',direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                              'السيرة الذاتية'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                               'صورة شخصية'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                             'نسخة من محضر لجنة الموارد البشرية المعتمد'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                               'نموذج ترشيح موظف'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                              'نسخة من المؤهلات العلمية معتمدة حسب الأصول'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                               'نسخة من شهادات الخبرة'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>
                
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                              'نسخة من تقريري الكفاءة -لآخر سنتين'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                               'بيان آخر إجراء ذاتي للموظف'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>
                
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                             'جواز السفر'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                               'الهوية الإماراتية'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>
                
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                             'خلاصة القيد للمواطنين'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                            'البحث الجنائي'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>
                
                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                              'فحص اللياقة الطبية'
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

            <br />
                </MDBRow>
                                     
                <MDBRow style={{ display: "flex", flexDirection: "row" }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type="submit" color="primary">{i18n[this.props.isArabic ? "ar" : "en"].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }
}
