/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle, MDBDropdown } from "mdbreact";
import Config from './../utils/Config';
import Carousel from "./../components/Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Particles from 'react-particles-js';
import Slide from '@material-ui/core/Slide';
import i18n from './../i18n';

export default class DashboardScreen extends Component {

    constructor(props) {
        super(props);
        if (!localStorage.getItem('Lang')) {
            localStorage.setItem('Lang', 'ar');
        }
        this.state = {
            isArabic: true,
            showServicesBlock1: false,
            showServicesBlock2: false,
            serviceAnimationDirection: 'left'
        }
    }

    componentDidMount() {
     
        if (localStorage.getItem('Lang') === 'ar') {
            this.setState({
                isArabic: true
            })
        } else {
            this.setState({
                isArabic: false
            })
        }
        window.addEventListener('scroll', this.listenToScroll);
    }

    listenToScroll = () => {
        if (this.state.isArabic) {
            this.setState({ serviceAnimationDirection: 'left' })
        }
        else {
            this.setState({ serviceAnimationDirection: 'right' })
        }

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
     
        if (winScroll > 200) {
            this.setState({ showServicesBlock1: true });
        }
        if (winScroll > 400) {
            this.setState({ showServicesBlock2: true });
        }
    }

    loginButtonClick = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            this.props.history.replace('/landing');
        } else {
            this.props.history.replace('/login')
        }
    }

    requestInformation = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            //this.props.history.replace('/SCIRequest');
            this.props.history.replace('/listpage', { pathname: 'SCIRequest' });
        } else {
            this.props.history.replace('/loginScreen')
        }
    }

    salariesAndCompensation = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            this.props.history.replace('/landing');
        } else {
            this.props.history.replace('/loginScreen')
        }
    }

    communicateWithTheCommision = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            this.props.history.replace('/landing');
        } else {
            this.props.history.replace('/loginScreen')
        }
    }

    orgnizationalDesign = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            this.props.history.replace('/landing');
        } else {
            this.props.history.replace('/loginScreen')
        }
    }

    humanResourcesPolicies = () => {
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            this.props.history.replace('/landing');
        } else {
            this.props.history.replace('/loginScreen')
        }
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    getSocialLinks = (key) => {
        switch (key) {
            case 'facebook':
                return Config.getFacebookLink();
            case 'twitter':
                return Config.getTwitterLink();
            case 'google':
                return Config.getGoogleLink();
            case 'linkedin':
                return Config.getLinkedInLink();
            case 'youtube':
                return Config.getYoutubeLink();
            default:
                return "#";
        }
    }

    getSocialTitle = (key) => {
        switch (key) {
            case 'facebook':
                return i18n[this.getLocale()].facebook;
            case 'twitter':
                return i18n[this.getLocale()].twitter;
            case 'google':
                return i18n[this.getLocale()].google;
            case 'linkedin':
                return i18n[this.getLocale()].linkedin;
            case 'youtube':
                return i18n[this.getLocale()].youtube;
            default:
                return "";
        }
    }

    getLangName = () => {
        return this.state.isArabic ? 'العربية' : 'English'
    }

    getFlagImage = (lan) => {
        let lanFlag = 'ar';
        if (localStorage.getItem('Lang') !== '') {
            lanFlag = localStorage.getItem('Lang');
        }

        if (this.state.isArabic) {
            if (lan === lanFlag) {
                return <img src={require("./../assets/images/flags/ae.png")} alt="" />
            }
            else {
                return <img src={require("./../assets/images/flags/us.png")} alt="" />
            }
        } else if (this.state.isArabic) {
            return <img src={require("./../assets/images/flags/ae.png")} alt="" />
        } else {
            return <img src={require("./../assets/images/flags/us.png")} alt="" />
        }
    }

    handleNavigation = (route) => {
        this.props.history.replace('/listpage', {
            pathname: route.replace('/', '')
        });
    }

    onClickEServicesClick = () => {
        this.props.history.push('/faqs');
    }

    onClickHome = () => {
        return Config.getHraLink();
    }

    onClickAboutHra = () => {
        this.props.history.push('/aboutEservices');
    }

    render() {
        const logo = require('../assets/images/logo_bg.png');
        const home = require('../assets/images/home_icon.png');
        const service1 = require('../assets/images/service-1.png');
        const service2 = require('../assets/images/service-2.png');
        const service3 = require('../assets/images/service-3.png');
        const service4 = require('../assets/images/service-4.png');
        const service5 = require('../assets/images/service-5.png');

        return (

            <MDBContainer fluid>

                <MDBRow className={this.state.isArabic ? 'socialIconBar_ar' : 'socialIconBar_en'}>
                    <ul>
                        <li><a target="_blank" title={this.getSocialTitle('facebook')} href={this.getSocialLinks('facebook')} className="socialIcon"><img alt="" width="55%" src={require('./../assets/images/brands/facebook.png')} /></a></li>
                        <li><a target="_blank" title={this.getSocialTitle('twitter')} href={this.getSocialLinks('twitter')} className="socialIcon"><img alt="" width="55%" src={require('./../assets/images/brands/twitter.png')} /></a></li>
                        <li><a target="_blank" title={this.getSocialTitle('google')} href={this.getSocialLinks('google')} className="socialIcon"><img alt="" width="55%" src={require('./../assets/images/brands/google1.png')} /></a></li>
                        <li><a target="_blank" title={this.getSocialTitle('linkedin')} href={this.getSocialLinks('linkedin')} className="socialIcon"><img alt="" width="55%" src={require('./../assets/images/brands/linkedin.png')} /></a></li>
                        <li><a target="_blank" title={this.getSocialTitle('youtube')} href={this.getSocialLinks('youtube')} className="socialIcon borderNone"><img alt="" width="55%" src={require('./../assets/images/brands/youtube.png')} /></a></li>
                    </ul>
                </MDBRow>

                <MDBRow className="header" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                    <MDBCol md="8"></MDBCol>
                    <MDBCol md="4" style={{ textAlign: this.state.isArabic ? 'right' : 'left', paddingRight: this.state.isArabic ? '3%' : 0, paddingLeft: this.state.isArabic ? 0 : '3%', }}>
                        <a href={this.onClickHome()}>
                            <img
                                src={logo} alt="" className="logo"></img>
                        </a>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }} className="navigation">

                    <MDBCol md="7">
                        <ul style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', float: this.state.isArabic ? 'right' : 'left', marginRight: this.state.isArabic ? '3%' : '0', marginLeft: this.state.isArabic ? '0' : '3%' }} className="list-group list-group-horizontal">
                            <li
                                onClick={this.onClickEServicesClick}
                                style={{ paddingTop: '4px', paddingBottom: '4px' }} className="list-group-item borderNone" title={i18n[this.getLocale()].menu3}>{i18n[this.getLocale()].menu3}</li>
                            <li
                                onClick={this.onClickAboutHra}
                                style={{ paddingTop: '4px', paddingBottom: '4px', borderTop: 'transparent', borderBottom: 'transparent' }} title={i18n[this.getLocale()].menu2} className="list-group-item">{i18n[this.getLocale()].menu2}</li>
                            {/* <li
                                onClick={this.onClickHome}
                                style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                className="list-group-item active"
                                title={i18n[this.getLocale()].menu1}>
                                <img
                                    style={{ height: '20px', width: '20px' }}
                                    src={home} alt="" className=""></img>
                            </li> */}
                        </ul>
                    </MDBCol>

                    <MDBCol md="5" className={this.state.isArabic ? 'loginbtnCol' : 'loginbtnCol_en'}><button className="loginbtn" type="button" onClick={() => { this.props.history.push('/loginScreen'); }} title={i18n[this.getLocale()].login}>{i18n[this.getLocale()].login}</button>

                        <MDBDropdown style={{ float: this.state.isArabic ? 'left' : 'right', padding: 0, margin: 0, }} size="sm" className="mx-2">

                            <MDBDropdownToggle style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center', border: '1px solid gray', padding: '3px 10px', borderRadius: '40px', margin: 0, }} caret gradient="#6a99ad">
                                <span style={{ color: 'black', padding: '0 5px', fontSize: '12px' }}>{this.getFlagImage(localStorage.getItem('Lang'))}</span>
                                <span style={{ color: 'black', padding: '0 5px', fontSize: '12px' }}>{this.getLangName(localStorage.getItem('Lang'))}</span>
                            </MDBDropdownToggle>

                            <MDBDropdownMenu basic>

                                <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', padding: 5 }} onClick={() => { this.setState({ isArabic: true }, () => localStorage.setItem('Lang', 'ar')) }}>
                                    <span style={{ paddingLeft: '5px', marginRight: '5px' }}>
                                        <img src={require("./../assets/images/flags/ae.png")} alt="" />
                                    </span>
                                    <span style={{ textAlign: 'right', fontSize: '14px' }}>
                                        {i18n[this.getLocale()].arabic}
                                    </span>
                                </MDBDropdownItem>

                                <MDBDropdownItem divider />

                                <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', padding: 5 }} onClick={() => { this.setState({ isArabic: false }, () => localStorage.setItem('Lang', 'en')) }}>
                                    <span style={{ paddingLeft: 5, marginRight: '5px' }}>
                                        <img src={require("./../assets/images/flags/us.png")} alt="" />
                                    </span>
                                    <span style={{ textAlign: 'right', fontSize: '14px' }}>
                                        {i18n[this.getLocale()].english}
                                    </span>
                                </MDBDropdownItem>

                            </MDBDropdownMenu>

                        </MDBDropdown>

                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="slider">
                    <MDBCol md="12" style={{ padding: '0' }}>
                        <Carousel />
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', zIndex: 1000 }} className="servicesBlock">
                    <MDBCol md="1" className="displayDiv"> </MDBCol>
                    <MDBCol md="10">
                        <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="middleContainer">
                            <MDBCol id="colService5" sm="4" md="4" className="servicesLink"
                                //onClick={() => { this.handleNavigation("/informationRequest") }}
                                onClick={this.requestInformation}>
                                <Slide
                                    direction={this.state.serviceAnimationDirection}
                                    in={this.state.showServicesBlock1} timeout={1000}>
                                    <MDBRow title={i18n[this.getLocale()].serviceTitle5} style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="services">
                                        <MDBCol sm="8" md="8" size="8" className={this.state.isArabic ? 'serviceInfo_ar' : 'serviceInfo_en'}>
                                            <h4>{i18n[this.getLocale()].serviceTitle5}</h4>
                                            <p className="card-text">{i18n[this.getLocale()].serviceText5}</p>
                                        </MDBCol>
                                        <MDBCol sm="4" md="4" size="4">
                                            <img alt="" className="img-fluid serviceImage" src={service5}></img>
                                        </MDBCol>
                                    </MDBRow>
                                </Slide>
                            </MDBCol>
                            <MDBCol id="colService1" sm="4" md="4"  className="servicesLink"
                                onClick={() => { this.props.history.replace('/') }}>
                                <Slide
                                    direction={this.state.serviceAnimationDirection}
                                    in={this.state.showServicesBlock1} timeout={500}>
                                    <MDBRow title={i18n[this.getLocale()].serviceTitle1}
                                        onClick={this.requestInformation}
                                        //onClick={() => { this.props.history.replace('/') }}
                                        style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="services">
                                        <MDBCol sm="8" md="8" size="8" className={this.state.isArabic ? 'serviceInfo_ar' : 'serviceInfo_en'}>
                                            <h4>{i18n[this.getLocale()].serviceTitle1}</h4>
                                            <p className="card-text">{i18n[this.getLocale()].serviceText1}</p>
                                        </MDBCol>
                                        <MDBCol sm="4" md="4" size="4">
                                            <img alt="" className="img-fluid serviceImage" src={service1}></img>
                                        </MDBCol>
                                    </MDBRow>
                                </Slide>
                            </MDBCol>
                            <MDBCol id="colService4" sm="4" md="4" className="servicesLink"
                                //onClick={() => { this.handleNavigation("/generalManagerWizardScreen") }}
                                onClick={this.requestInformation}>
                                <Slide direction={this.state.serviceAnimationDirection} in={this.state.showServicesBlock1} timeout={100}>
                                    <MDBRow title={i18n[this.getLocale()].serviceTitle4} style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="services">
                                        <MDBCol sm="8" md="8" size="8" className={this.state.isArabic ? 'serviceInfo_ar' : 'serviceInfo_en'}>
                                            <h4>{i18n[this.getLocale()].serviceTitle4}</h4>
                                            <p className="card-text">{i18n[this.getLocale()].serviceText4}</p>
                                        </MDBCol>
                                        <MDBCol sm="4" md="4" size="4">
                                            <img alt="" className="img-fluid serviceImage" src={service4}></img>
                                        </MDBCol>
                                    </MDBRow>
                                </Slide>
                            </MDBCol>
                            <MDBCol sm="4" md="4"></MDBCol>
                            <MDBCol id="colService3" sm="4" md="4" className="servicesLink"
                                //onClick={() => { this.props.history.replace('/') }}
                                onClick={this.orgnizationalDesign}>
                                <Slide direction={this.state.serviceAnimationDirection} in={this.state.showServicesBlock2} timeout={500}>
                                    <MDBRow title={i18n[this.getLocale()].serviceTitle3} style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="services">
                                        <MDBCol sm="8" md="8" size="8" className={this.state.isArabic ? 'serviceInfo_ar' : 'serviceInfo_en'}>
                                            <h4>{i18n[this.getLocale()].serviceTitle3}</h4>
                                            <p className="card-text">{i18n[this.getLocale()].serviceText3}</p>
                                        </MDBCol>
                                        <MDBCol sm="4" md="4" size="4">
                                            <img alt="" className="img-fluid serviceImage" src={service3}></img>
                                        </MDBCol>
                                    </MDBRow>
                                </Slide>
                            </MDBCol>
                            <MDBCol id="colService2" sm="4" md="4" className="servicesLink"
                                //onClick={() => { this.props.history.replace('/') }}
                                onClick={this.humanResourcesPolicies}>
                                <Slide direction={this.state.serviceAnimationDirection} in={this.state.showServicesBlock2} timeout={100}>
                                    <MDBRow title={i18n[this.getLocale()].serviceTitle2} style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="services">
                                        <MDBCol sm="8" md="8" size="8" className={this.state.isArabic ? 'serviceInfo_ar' : 'serviceInfo_en'}>
                                            <h4>{i18n[this.getLocale()].serviceTitle2}</h4>
                                            <p className="card-text">{i18n[this.getLocale()].serviceText2}</p>
                                        </MDBCol>
                                        <MDBCol sm="4" md="4" size="4">
                                            <img alt="" className="img-fluid serviceImage" src={service2}></img>
                                        </MDBCol>
                                    </MDBRow>
                                </Slide>
                            </MDBCol>
                        </MDBRow>

                        <Particles className="particleBox" style={{ zIndex: 1 }} params={{ "particles": { "number": { "value": 50 }, "size": { "value": 3 } }, "interactivity": { "events": { "onhover": { "enable": true, "mode": "repulse" } } } }} />

                    </MDBCol>
                    <MDBCol md="1" className="displayDiv"></MDBCol>

                </MDBRow>

                <MDBRow className="footerDesign"></MDBRow>

                <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} className="footer">
                    <MDBCol sm="4" md="4" size="4"><p onClick={this.handleChange} className={this.state.isArabic ? "copyright_ar" : "copyright_en"}>{i18n[this.getLocale()].copyright}</p></MDBCol>
                    <MDBCol sm="8" md="8" size="8">
                        <ul style={{ float: this.state.isArabic ? 'right' : 'left',flexDirection: this.state.isArabic ? 'row' : 'row-reverse', }} className="list-group list-group-horizontal">
                            <li className="list-group-item" title={i18n[this.getLocale()].footermenu4}>{i18n[this.getLocale()].footermenu4}</li>
                            <li className="list-group-item" title={i18n[this.getLocale()].footermenu3}>{i18n[this.getLocale()].footermenu3}</li>
                            <li className="list-group-item" title={i18n[this.getLocale()].footermenu2}>{i18n[this.getLocale()].footermenu2}</li>
                            <li className="list-group-item" style={{ borderRight: 'none' }} title={i18n[this.getLocale()].footermenu1}>{i18n[this.getLocale()].footermenu1}</li>
                        </ul>
                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        );
    }
}