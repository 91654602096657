import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class SalaryStatus extends Component {

     
    componentDidMount = () => {
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    render() {
        return(
        <React.Fragment>
        <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].SickLeave.LeveSalryDetails} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
               
        <MDBRow className="formRow" style={{ display: 'flex', flexDirection:'row',paddingTop: '20px'  }}>
                      <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                          <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystoped}<span className="requireStar">*</span></label>
                                          <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                              <input
                                                  type="radio"
                                                  value="Yes"
                                                  checked={this.state.SalaryCointiunousOption === 'Yes'}
                                                  class="custom-control-input"
                                                  id="customControlValidation21"
                                                  onChange={this.handleSalayOptionChange}
                                                  name="radio-stackedsalary"
                                                  required />
                                              <label class="custom-control-label" for="customControlValidation21">
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystopedgoing}
                                              </label>
                                          </div>
                                          <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                              <input
                                                  type="radio"
                                                  value="No"
                                                  class="custom-control-input"
                                                  id="customControlValidation31"
                                                  name="radio-stackedsalary"
                                                  checked={this.state.SalaryCointiunousOption === 'No'}
                                                  onChange={this.handleSalayOptionChange}
                                                  required />
                                              <label class="custom-control-label" for="customControlValidation31">
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salarystopedstoped}
                                              </label>
                                          </div><br />
                                          {this.state.selectedOptionErr == 'thisFieldIsRequired' ?
                                              <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                              </div>
                                              :
                                              null}
                                      </div>
                                  </MDBCol>
                                  {this.state.SalaryCointiunousOption==='No' ? 
                                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.dateOfSalaryTermination}
                                  {/* <span className="requireStar">*</span> */}
                                  </label>
                                  <div
                                      // style={{ width: '100%', display: 'flex', flexDirection:'row' }}
                                      style={{ width: '100%', display: 'flex', flexDirection:'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }} 
                                      className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.dateOfSalaryTerminationErr ? "datePickerBorderColorErr" : ""}`}>
                                      <DatePicker
                                          className="form-control"
                                          todayButton={"Today"}
                                          minDate={this.state.dateBeforFiveYear}
                                          maxDate={this.state.dateAfterOneYear}
                                          selected={this.state.dateOfSalaryTermination}
                                          onChange={this.handleDateOfSalaryTermination}
                                          ref={el => this.onDatepickerRef(el)}
                                          customInput={<input />}
                                          dateFormat="MM/dd/yyyy"
                                          showMonthDropdown
                                          showYearDropdown
                                          //showTimeInput
                                         // required
                                      />
                                  </div>
                                  {/* {this.state.dateOfSalaryTerminationErr !== '' ?
                                      <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:'row' }}>
                                          {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                      </div>
                                      :
                                      null} */}
                              </div>
                          </MDBCol>:''}
                      
                                   </MDBRow>
      
                
      
                      <MDBRow className="formRow" style={{ display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
                          <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetAvailability}<span className="requireStar">*</span></label>
                                  <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                      <input
                                          type="radio"
                                          value="Yes"
                                          checked={this.state.selectedOption2 === 'Yes'}
                                          class="custom-control-input"
                                          id="customControlValidation4"
                                          onChange={this.handleOptionChange2}
                                          name="radio-stacked-2"
                                          required />
                                      <label class="custom-control-label" for="customControlValidation4">
                                          {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                      </label>
                                  </div>
                                  <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                      <input
                                          type="radio"
                                          value="No"
                                          class="custom-control-input"
                                          id="customControlValidation5"
                                          name="radio-stacked-2"
                                          checked={this.state.selectedOption2 === 'No'}
                                          onChange={this.handleOptionChange2}
                                          required />
                                      <label class="custom-control-label" for="customControlValidation5">
                                          {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                      </label>
                                  </div>
                                  <br />
                                  {
                                      this.state.selectedOption2Err == 'thisFieldIsRequired' ?
                                          <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                              {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                          </div>
                                          :
                                          null
                                  }
                                  {/* {
                                      this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                          <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                              {i18n[this.getLocale()].budgetAvailabilityError}
                                          </div>
                                          :
                                          null
                                  } */}
                              </div>
                          </MDBCol>
                          {
                              this.state.selectedOption2 === "Yes" ?
                                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                          <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetYear}
                                              <span className="requireStar">*</span>
                                          </label>
                                         
                                          <input
                                              type="text"
                                              className="form-control"
                                              id="budgetAvailabilityYear"
                                              name="budgetAvailabilityYear"
                                              value={this.state.budgetAvailabilityYear}
                                              onChange={this.handleBudgetAvailabilityYear}
                                              style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                              required
                                          />
      
                                          {
                                              this.state.budgetAvailabilityYearErr === 'thisFieldIsRequired' ?
                                                  <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                      {i18n[this.getLocale()].thisFieldIsRequired}
                                                  </div>
                                                  :
                                                  null
                                          }
      
                                          {
                                              this.state.budgetAvailabilityYearErr === 'invalidYear' ?
                                                  <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:'row' }}>
                                                      {i18n[this.getLocale()].invalidYear}
                                                  </div>
                                                  :
                                                  null
                                          }
                                      </div>
                                  </MDBCol>
                                  :
                                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ padding: '30px' }}>
                                  </MDBCol>
                          }
      
                      </MDBRow>
                      {
                          this.state.selectedOption2 === "No" ?
      
                              <MDBRow className="formRow" style={{ display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
      
                                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                          <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.requiredToPayBudget}<span className="requireStar">*</span></label>
                                          <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                              <input
                                                  type="radio"
                                                  value="Yes"
                                                  checked={this.state.requiredToPayBudget === 'Yes'}
                                                  class="custom-control-input"
                                                  id="customControlValidation2"
                                                  onChange={this.handleOptionChange}
                                                  name="radio-stacked"
                                                  required />
                                              <label class="custom-control-label" for="customControlValidation2">
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                              </label>
                                          </div>
                                          <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                              <input
                                                  type="radio"
                                                  value="No"
                                                  class="custom-control-input"
                                                  id="customControlValidation3"
                                                  name="radio-stacked"
                                                  checked={this.state.requiredToPayBudget === 'No'}
                                                  onChange={this.handleOptionChange}
                                                  required />
                                              <label class="custom-control-label" for="customControlValidation3">
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                              </label>
                                          </div><br />
                                          {this.state.selectedOptionErr == 'thisFieldIsRequired' ?
                                              <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                              </div>
                                              :
                                              null}
                                      </div>
                                  </MDBCol>
      
                              </MDBRow>
                              :
                              ''
                      }
      
                      <br />
                      {
                          (this.state.requiredToPayBudget === "Yes" && this.state.selectedOption2 !== "Yes") ?
                              // (this.state.selectedOption2 === "Yes") ?
      
                              <MDBRow className="formRow" style={{ display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
      
                                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                          <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SCI.enterTheYear}
                                              <span className="requireStar">*</span>
                                          </label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              id="budgetYear"
                                              name="budgetYear"
                                              value={this.state.budgetYear}
                                              onChange={this.handleBudgetYear}
                                              style={{
                                                  direction: this.props.isArabic ? "rtl" : 'ltr',
                                                  border: this.state.budgetYearErr !== '' ? '1px solid #dc3545' : '',
                                                  backgroundImage: this.state.budgetYearErr !== '' ? 'none' : '',
                                              }}
                                          // required
                                          />
      
                                          {
                                              this.state.budgetYearErr === 'thisFieldIsRequired' ?
                                                  <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                      {i18n[this.getLocale()].thisFieldIsRequired}
                                                  </div>
                                                  :
                                                  null
                                          }
                                          {
                                              this.state.budgetYearErr == 'invalidYear' ?
                                                  <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                      {i18n[this.getLocale()].invalidYear}
                                                  </div>
                                                  :
                                                  null
                                          }
                                      </div>
                                  </MDBCol>
      
                                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                          <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.budgetValue}
                                              <span className="requireStar">*</span>
                                          </label>
                                         
      
                                          <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.budgetValueErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                              <CurrencyFormat
                                                  thousandSeparator={true}
                                                  value={this.state.budgetValue}
                                                  onValueChange={(value) => { this.handleBudgetValue(value) }}
                                                  display={"text"}
                                                  maxLength={10}
                                                  style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                              />
                                          </div>
      
                                          {this.state.budgetValueErr === 'thisFieldIsRequired' ?
                                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:'row' }}>
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                              </div>
                                              :
                                              null}
                                      </div>
                                  </MDBCol>
      
                              </MDBRow>
      
                              :
                              null
                      }
      
                      <MDBRow className="formRow" style={{ display: 'flex', flexDirection:'row' }}>
      
                          <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                      <label style={{ width: '100%', display: 'flex', flexDirection:'row' }} htmlFor="exampleFormControlTextarea3">{this.state.requestType? this.state.requestType ==2 ? i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.justificationsEmployee: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.Resonforfirstandsecond:i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.justificationsEmployee}<span className="requireStar">*</span></label>
      
                                      <div style={{ border: this.state.justificationsEmployeeErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
      
                                          {
                                              this.props.isArabic ?
                                                  null :
                                                  <CKEditor
                                                      activeClass="p10"
                                                      content={this.state.justificationsEmployee}
                                                      events={{
                                                          "change": this.justificationsEmployeeOnChange
                                                      }}
                                                      config={{ language: "en", height: '10vh' }}
                                                  />
                                          }
      
                                          {
                                              this.props.isArabic ?
                                                  <CKEditor
                                                      activeClass="p10"
                                                      content={this.state.justificationsEmployee}
                                                      events={{
                                                          "change": this.justificationsEmployeeOnChange
                                                      }}
                                                      config={{ language: "ar", height: '10vh' }}
                                                  />
                                                  :
                                                  null
                                          }
                                      </div>
                                      <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                          {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                      </p>
                                      {
                                          this.state.justificationsEmployeeErr === 'thisFieldIsRequired' ?
                                              <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                  {i18n[this.getLocale()].thisFieldIsRequired}
                                              </div>
                                              :
                                              null
                                      }
                                  </div>
                              </div>
                          </MDBCol>
                      </MDBRow>
      
                      
                     
                    
      
               
      
      
            </div></div>
      
      </React.Fragment>   );   
    }
}