
import React, { Component } from 'react';

import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';

import Locali18n from './Locali18n';
 import ToastComponent from '../ToastComponent';
const striptags = require('striptags');
let moment = require('moment');

 export class UnitsFunctions extends Component {

    constructor(props) {
       
        super(props);
        this.state = {
           
            id:'0',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            // form variables 
            
           
            unitGoals:'',
            unitInternalRelations: '',
            unitExternalRelations: '',
            unitResponsibilities:'', 
            unitGoalsErr:'',
            unitInternalRelationsErr: '',
            unitExternalRelationsErr: '',
            unitResponsibilitiesErr:'',
            
            
        };     
    };

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }
    getUnitInfo=(unitid)=>{

    
        let obj={};
        obj.id=unitid;
        let payload = obj;
        let endpoint = 'GetUnitById';
        let url = Config.getUrl(endpoint);
  
         ApiRequest.sendRequestForPost(url, payload, res => {
              
         if (res.Status) {
        
          let info = res.data; 
           debugger;
           this.setState({
          
           unitGoals : res.data.objectives,
           unitResponsibilities : res.data.responsibilities,
           unitInternalRelations : res.data.internalrelations,
           unitExternalRelations : res.data.externalrelations,
         
        }); 
        
  
        }
  
      });
  
    };
   
    saveUnitDetails = (event) => {

        event.preventDefault();
        event.target.className += " was-validated";
     
      debugger;
        let obj = {};

        if (event.target.id === "1") {
 
         obj.draft = 1;
     } else {
         if(!this.Validate())
             return;
         else
         obj.draft = 0;
     }
           
            let getDataFromLocalStorage = Config.getLocalStorageLoginData();
            if (getDataFromLocalStorage) {
                obj.entityid = getDataFromLocalStorage.EntityID;
                obj.Userid = getDataFromLocalStorage.id;
            }
     
            obj.responsibilities = this.state.unitResponsibilities;
            obj.internalrelations = this.state.unitInternalRelations;
            obj.externalrelations = this.state.unitExternalRelations;
            obj.objectives = this.state.unitGoals;
            obj.branch = this.state.branch;
        
            obj.unitid = this.state.id;
          
            obj.id =this.state.id;
        
     
            let payload = obj;
     
            let  endPoint = 'SaveUnit';
       
            let url = Config.getUrl(endPoint);
     
            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                    this.setState({
                        isLoading: false
                    }
                    , () => {
                        if (res.Status) {
                           
                      ToastComponent.notifyInfo( Locali18n[this.props.isArabic ? 'ar' : 'en'].unitupdated);
                            
                        }
                         else {
                            this.setState({
                                isLoading: false
                            })
                           
                            ToastComponent.notifyInfo(res.Message);
                        
                        }
                        
                    })
                })
            })
     
            this.props.hide();
     };

    
    handleUnitResponsibilities = (e) => {
        var newContent = e.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                unitResponsibilitiesErr: "",
                unitResponsibilities: newContent
            })
        }
        else {
            this.setState({
                unitResponsibilitiesErr: "ckeditorCountErr"
            })
        }
    };
    handleUnitInternalRelations = (e) => {
        var newContent = e.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                unitInternalRelationsErr: "",
                unitInternalRelations: newContent
            })
        }
        else {
            this.setState({
                unitInternalRelationsErr: "ckeditorCountErr"
            })
        }
    }; 
    handleUnitGoals = (e) => {
        var newContent = e.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                unitGoalsErr: "",
                unitGoals: newContent
            })
        }
        else {
            this.setState({
                unitGoalsErr: "ckeditorCountErr"
            })
        }
    };
    handlUnitExternalRelations = (e) => {
        var newContent = e.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                unitExternalRelationsErr: "",
                unitExternalRelations: newContent
            })
        }
        else {
            this.setState({
                unitExternalRelationsErr: "ckeditorCountErr"
            })
        }
    };
   
    //----------
   
    
     Validate=()=> {
    
        
        let state = true;
          
    
        if( this.state.unitGoals==='' )
        {this.setState({unitGoalsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.unitGoalsErr !=='')
          state=false;   
        if( this.state.unitResponsibilities==='' )
        {this.setState({unitResponsibilitiesErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.unitResponsibilitiesErr !=='')
        state=false; 
        if( this.state.unitInternalRelations==='' )
        {this.setState({unitInternalRelationsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.unitInternalRelationsErr !=='')
        state=false; 
        if( this.state.unitExternalRelations==='' )
        {this.setState({unitExternalRelationsErr:'thisFieldIsRequired'}); state=false;}
        if( this.state.unitExternalRelationsErr !=='')
        state=false; 
       
       

       return state;
    };
    

}

export default UnitsFunctions



