import React, { Component } from "react";
import {
    MDBBtn,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBRow
} from "mdbreact";
import i18n from "../../i18n";



class IEMessage extends Component {

    constructor(props) {
        super(props);
        
    };
    
    componentWillReceiveProps = (props) => {
        this.permissionData();
    }

    componentDidMount = () => {
       
       
       
    }

 
   

    getLocale = () => {
        let value = localStorage.getItem("Lang") === "ar" ? true : false;
        if (value) {
            return "ar";
        } else {
            return "en";
        }
    };

   

    render() {

        return (
            <MDBPopover
            component="button" size="sm" placement={this.props.y > 555 ? "top" : "bottom"}
            popoverBody={i18n[this.getLocale()].actions} className="btn btn-primary btn-sm Ripple-parent gridActionBtn">
            <MDBPopoverHeader>
                {" "}
                {i18n[this.getLocale()].BrowserNotSupportedTitle}
            </MDBPopoverHeader>
            <MDBPopoverBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around"
                }}>

                <MDBRow>
                    

                    </MDBRow>
                    </MDBPopoverBody>
                    </MDBPopover>
                
            
        );
    }
}

                    

                    export default IEMessage;