import React, { Component } from "react";
import {
    MDBBtn,
    MDBPopover,
    MDBPopoverHeader,
    MDBPopoverBody,
    MDBRow
} from "mdbreact";
import i18n from "../../i18n";
import Config from './../../utils/Config';
import ToastComponent from './../ToastComponent';
import { getRequestByIdPayload, downloadAttachment } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import Locali18n from './Locali18n';

const actions = {
    VIEW: "view",
    EDIT: "edit",
    UPDATE_STATUS: "update_status",
    COMMENT: "comment",
    DELETE: "delete",
    ASSIGNTOSECTOR: "assigntosector",
    ASSIGNTODEP: "assigntodep",
    BACKTOMANAGER: "backtomanager",
    ASSIGNTOSPECIFICDEP: "assigntospecificdep",
    ASSIGNTODATADEP: "assigntodatadep",
    ASSIGNTOEMP: "assigntoemp",
    ASSIGNTOSPECIFICEMP: "assigntospecificemp",
    ASSIGNTOGMOFFICE: "assigntogmoffice",
    ASSIGNTOSTRATEGICOFFICE: "assigntostrategicoffice",
    ASSIGNBACKTOENTITY: "assignbacktoentity",
    ASSIGNTOENTITY: "assigntoentity",
    ADDSTUDY: "addStudy",
    ADDREPLY: "addReply",
    FOLLOW: "follow",
    VIEWDETAILS: 'viewdetails',
    OpenCreationStage:"opencreationstage",
    OrganizationStructureCompleted : 'organizationstructurecompleted'
};

class OrganizationStructurePopOverComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUserId: '',
            pathName: '',
            loginData: '',
            accounttypeid: '',
            requestStatus: '',
            requestStatusReason: '',
            canView: 0,
            canEdit: 0,
            canUpdateStatus: 0,
            canAddComment: 0,
            canAssignDept: 0,
            canAssignEmployee: 0,
            canProvideFeedback: 0,
            canAssignSector: 0,
            canAddHraStudy: 0,
            canViewHraStudy: 0,
            canAssignGm: 0,
            canAssignBackToEntity: 0,
            casAssignBackToDataDepartment: 0,
         
        }
    };
    
  
    componentWillReceiveProps = () => {

        
      this.setState({
         loginData: Config.getLocalStorageLoginData()

      })
     debugger;
       this.setState({
        canView: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
        canEdit: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.caneditorgrequest,
        canAddComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canaddorgcomment,
       })
       
  
     
    }

   

   

    getLocale = () => {
        let value = localStorage.getItem("Lang") === "ar" ? true : false;
        if (value) {
            return "ar";
        } else {
            return "en";
        }
    };

    getActionbuttonName = () => {
        // console.log('List_Page_Data: ', this.props.data);
        return i18n[this.getLocale()].statusUpdate;
    
    }
    getCompletedbuttonName = () => {
        // console.log('List_Page_Data: ', this.props.data);
        return Locali18n[this.getLocale()].OrganizationStructureCompleted;
    }
    getActionbuttonOpen = () => {
      return  this.state.loginData.ModifyStructure===1 ?
                   i18n[this.props.isArabic ? "ar" : "en"].OpenModificationStage
                   :i18n[this.getLocale()].OpenCreationStage;
    }

    getActionButtonNameForGmAndStrategic = () => {
        // console.log('List_Page_Data: ', this.props.data);
      return i18n[this.getLocale()].assignToGmOffice;
      
      
    }

    getActionButtonNameForAssignToEntity = () => {
        // console.log('List_Page_Data: ', this.props.data);
        let buttonname = i18n[this.getLocale()].assignBackToEntity;
        if (this.props.data.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === 'informationRequest') {
            buttonname = i18n[this.getLocale()].assignToEntity;
        }
        return buttonname;
    }

  

  

    render() {
       
        return (
            
            <MDBPopover
                component="button" size="sm" placement={this.props.y > 555 ? "top" : "bottom"}
                popoverBody={i18n[this.getLocale()].actions} className="btn btn-primary btn-sm Ripple-parent gridActionBtn">
                <MDBPopoverHeader>
                    {" "}
                    {i18n[this.getLocale()].select_action}
                </MDBPopoverHeader>
                <MDBPopoverBody
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around"
                    }}>

                    <MDBRow>
                        {
                           
                              (this.props.data.statusreasonid === 59 ) &&  this.state.canEdit ?
                                    <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.EDIT, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].edit}
                                    </MDBBtn>
                                    :
                                    <span></span>
                        }
                        {
                            this.state.canView ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.VIEW, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].view
                                    }
                                </MDBBtn> :
                                <span></span>
                        }

                    </MDBRow>


                    <MDBRow>
                        {
                            this.state.canView ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                  
                                        this.props.manageAction(actions.VIEWDETAILS, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].viewDetail
                                    }
                                </MDBBtn> :
                                <span></span>
                        }

                    </MDBRow>

                   

                    {
                     this.props.data.previousstep !== 0  && 
                     (this.props.data.statusreasonid === 59) 
                     //||  (this.props.data.statusreasonid === 897
                      && this.state.loginData.AccountypeID === 773  
                      && this.props.data.assigned === 1 ?
                        <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.UPDATE_STATUS, this.props.index);
                                    }}>
                                    {
                                        this.getActionbuttonName()
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                      }
                      {
                      this.state.loginData.AccountypeID === 816  && this.props.data.statusreasonid===898 &&
                       this.props.data.assigned === 1 ?
                        <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.OrganizationStructureCompleted, this.props.index);
                                    }}>
                                    {
                                        this.getCompletedbuttonName()
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                    }

                   {  
                     (this.props.data.statusreasonid === 155  && this.state.loginData.AccountypeID === 851) || 

                    (this.props.data.statusreasonid === 152  &&  this.state.loginData.AccountypeID === 851) 
                      &&  this.props.data.assigned === 1 ?
                        <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.OpenCreationStage, this.props.index);
                                    }}>
                                    {
                                        this.getActionbuttonOpen()
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    

                    {
                       ( this.state.loginData.AccountypeID === 773  && this.props.data.statusreasonid === 59) 
                        &&  this.props.data.assigned === 1 ?
                       
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNBACKTOENTITY, this.props.index);
                                    }}>
                                    {
                                        this.getActionButtonNameForAssignToEntity()
                                    }
                                   
                                </MDBBtn>
                             
                                : null
                    }
                  
                  

                    {
                      
                       ( this.props.data.statusreasonid === 155 && this.state.loginData.AccountypeID === 851)
                        || (  this.state.loginData.AccountypeID === 773 && this.props.data.statusreasonid === 59) || ( this.props.data.statusreasonid === 152 && this.state.loginData.AccountypeID === 851)
                        || (  this.state.loginData.AccountypeID === 851 && this.props.data.statusreasonid === 897)
                         &&   this.props.data.assigned === 1 ?
                                                <MDBBtn
                                                    className="gridActionBtn"
                                                    color="primary"
                                                    style={{ color: "var(--btnColor)" }}
                                                    size="sm"
                                                    onClick={() => {
                                                        ;
                                                        this.props.manageAction(actions.ASSIGNTOSECTOR, this.props.index);
                                                    }}
                                                >
                                                    {i18n[this.getLocale()].assignToSector}
                                                </MDBBtn>
                                                :
                                                null
                                       

                    }

                    
                   
                    
                    {
                      
                      (this.props.data.statusreasonid === 897 &&  this.state.loginData.AccountypeID === 851)

                    || (this.props.data.statusreasonid === 155 &&  this.state.loginData.AccountypeID === 851)
                        &&    this.props.data.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOEMP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToEmp}
                                </MDBBtn>
                                :
                                <span></span>
                    }
                   
 
                    {
                      
                      this.props.data.statusreasonid === 151 &&   this.state.loginData.AccountypeID === 771  &&  this.props.data.assigned === 1 ?
                         
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                       
                                        this.props.manageAction(actions.ASSIGNTODEP, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].assignToDep}
                                </MDBBtn>
                                :
                                <span></span>
                    }
                    
                    {
                      
                      
                        (this.state.loginData.AccountypeID === 854 && this.props.data.statusreasonid === 153) 
                      
                      && this.props.data.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.BACKTOMANAGER, this.props.index);
                                    }}
                                >
                                    {i18n[this.getLocale()].backToManager}
                                </MDBBtn>
                                :
                                null
                    }

                   {
                        //this.props.data.previousstep === 152  && 
                        this.props.data.statusreasonid === 151 &&
                        this.state.loginData.AccountypeID === 771  
                         && this.props.data.assigned === 1 ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.ASSIGNTOGMOFFICE,this.props.index);
                                          
                                    }}>
                                    {
                                      this.getActionButtonNameForGmAndStrategic()
                                    }
                                    {/* i18n[this.getLocale()].assignToGmOffice */}
                                </MDBBtn>
                                :
                                null
                                
                    }

                    {
                     this.props.data.assignedemployeeid === this.state.loginData.id && this.props.data.statusreasonid === 153
                      && this.state.loginData.AccountypeID === 854  
                      && this.props.data.assigned === 1 ?
                            
                              <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDSTUDY, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].addStudy}
                                    </MDBBtn>
                                   :null
                    }
                     {
                       this.props.data.statusreasonid === 153 && this.state.loginData.AccountypeID === 854
                       && this.props.data.assignedemployeeid !== this.state.loginData.id   
                       && this.props.data.assignedemployeeid === 0 &&
                       this.props.data.assigned === 1 ?
                       
                              <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ASSIGNTOSPECIFICEMP, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].loackrequest}
                                    </MDBBtn>
                                   :null
                    }
                   
                   {
                   /*  {
                       
                       this.props.data.previousstep === 151 &&  this.props.data.statusreasonid === 59 && this.state.loginData.AccountypeID === 773  
                       && this.props.data.assigned === 1 ?
                       <MDBBtn
                                        className="gridActionBtn"
                                        color="primary"
                                        style={{ color: "var(--btnColor)" }}
                                        size="sm"
                                        onClick={() => {
                                            this.props.manageAction(actions.ADDREPLY, this.props.index);
                                        }}
                                    >
                                        {i18n[this.getLocale()].addReply}
                                    </MDBBtn>
                                    :
                                    null
                                } */}
                     
                    <MDBRow>
                        {
                            this.state.canAddComment ?
                                <MDBBtn
                                    className="gridActionBtn"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    onClick={() => {
                                        this.props.manageAction(actions.COMMENT, this.props.index);
                                    }}
                                >
                                    {
                                        i18n[this.getLocale()].comment
                                    }
                                </MDBBtn>
                                :
                                <span></span>
                        }
                    </MDBRow>
                    

                </MDBPopoverBody>
            </MDBPopover >
        );
    }
}

export default OrganizationStructurePopOverComponent;
