import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import './../css/style.css';
import Header from './../components/Header';
import SideBar from './../components/SideBar';
import Footer from './../components/Footer';
import StepOne from '../components/ReviewHRPoliciesSteps/StepOne';
import i18n from './../i18n';
import Config from './../utils/Config';
import SubHeaderStrip from './../components/SubHeaderStrip';
import { saveHrPoliciesPayload, uploadDocument } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay'
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';

export default class ReviewHRPolicies extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            isEdit: false,
            requestId: '',
            draft: '',
            statusid: '',
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isLoading: false,
            step1: true,
            reasonsOfRequest: '',
            entityFormalRequest: '',
            standardComparisons: '',
            OtherAttach: '',
            OtherAttachFileObj:"",
            studies: '',
            studiesShortName: '',
            isSideBarMinimized: false,
            entityFormalRequestFileObj: "",
            standardComparisonsFileObj: "",
            studiesFileObjArray: []
        }
    }

    componentDidMount = () => {
        this.setState({ step1: true });
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    data = [
        { name: 'item1' },
        { name: 'item2' },
        { name: 'item3' }
    ];

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    navigate = () => {
        this.setState({
            step2: true, step1: false, step3: false
        })
    }

    uploadFile = (i, totalCount, resHrPolicies, multifileCount) => {
       
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};
            let isLastFileInMultiUpload = false;

            if (i === 0) {
                fileObj = Config.getBase64ForReviewHRPoliciesOne();
                obj.filename = this.state.entityFormalRequest;
                obj.filetype = Config.getHrReviewFormalRequestId();
                tempBase64 = fileObj;
            }
            else if (i === 1) {
                fileObj = Config.getBase64ForReviewHRPoliciesTwo();
                obj.filename = this.state.standardComparisons;
                obj.filetype = Config.getHrReviewStandardComparisonId();
                tempBase64 = fileObj;
            }
            else if (i === 2) {
                let fileObjArray = Config.getBase64ForReviewHRPoliciesThree();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }
                // let filenamesArray = this.state.studies.split(",");
                // obj.filename = filenamesArray[multifileCount];
                if (this.state.studies !== null) {
                    let filenamesArray = this.state.studies.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                obj.filetype = Config.getHrReviewStudiesId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                
                fileObj = Config.getBase64ForOtherAttach();
                obj.filename = this.state.OtherAttach;
                obj.filetype = Config.getRETOtherAttachId();
                tempBase64 = fileObj;
            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resHrPolicies && resHrPolicies.data && resHrPolicies.data.id) {
                    reqId = resHrPolicies.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resHrPolicies.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                this.setState({
                    isLoading: true
                }, () => {

                    ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {

                        if (resForEntityFIleRequestFile.Status) {
                            if (i === 2) {
                                if (isLastFileInMultiUpload) {
                                    this.uploadFile(i + 1, totalCount, resHrPolicies, multifileCount + 1);
                                } else {
                                    this.uploadFile(i, totalCount, resHrPolicies, multifileCount + 1);
                                }

                            } else {
                                this.uploadFile(i + 1, totalCount, resHrPolicies, 0);
                            }
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                             
                                // this.props.history.replace("/listPage", {
                                //     pathname: localStorage.getItem('currentPath')
                                // });
                                // unable to upload attachment
                                if (this.state.draft === "saveAsDraft") {
                                    this.setState({
                                        titleAlert: i18n[this.getLocale()].success,
                                        messageAlert: i18n[this.getLocale()].save_as_Draft
                                    }, () => {
                                        this.alertSubmitForSuccess();
                                    })
                                } else {
                                    this.setState({
                                        titleAlert: i18n[this.getLocale()].error,
                                        messageAlert: i18n[this.getLocale()].attachment_error
                                    }, () => {
                                        this.alertSubmit();
                                    })
                                }
                            })
                        }

                    });
                })
            } else {
                this.uploadFile(i + 1, totalCount, resHrPolicies, 0);
            }
        }
        else {
          
            this.setState({
                isLoading: false
            }, () => {
              
               
                if (this.state.isEdit) {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                    }
                } else {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    }

    setData = (obj) => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        let tempRequestId = '';
        let tempIsEdit = '';
       
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            tempRequestId = tempEditObj.requestId;
            tempIsEdit = obj.isEdit;
            this.setState({
                requestId: tempRequestId,
                isEdit: tempIsEdit,
                statusid: tempStatusId,
                draft: obj.formType,
                entityFormalRequest: obj.entityFormalRequest,
                entityFormalRequestFileObj: obj.entityFormalRequestFileObj,
                standardComparisons: obj.standardComparisons,
                standardComparisonsFileObj: obj.standardComparisonsFileObj,
                studies: obj.studies,
                studiesFileObjArray: obj.studiesFileObjArray,
                OtherAttach:obj.OtherAttach,
                OtherAttachFileObj:obj.OtherAttachFileObj
                
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

               
               
                let objStepOne = {
                    requestreason: obj.reasonsOfRequest,
                    requestId: this.state.requestId,
                    isEdit: this.state.isEdit
                }

                if (obj.formType === "saveAsDraft") {
                    objStepOne.draft = 1;
                } else {
                    objStepOne.draft = 0;
                }
                let payload = saveHrPoliciesPayload(objStepOne);
                let endPoint = 'SaveHRPolicyReview';
                let url = Config.getUrl(endPoint);
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resHrPolicies => {
                        if (resHrPolicies.Status) {
                            let totalcount = 4
                            this.uploadFile(0, totalcount, resHrPolicies)
                            // this.props.history.replace('/listpage', { pathname: 'reviewHRPolicies' });
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                              
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            })
                        }
                    })
                })
            })
        }
    }

    handlePreViewNavigation = (dataObj, attachmentArr, ckEditorArr) => {
        localStorage.setItem('currentPath', 'reviewHRPolicies');
        this.setState({
            pathName: 'reviewHRPolicies'
        }, () => {
            this.props.history.replace('/preview',
                { data: dataObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
        });
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    render() {
        const sideBarData = Config.getServiceSideBar('reviewHRPolicies', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid style={{ height: '100vh' }}>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'reviewHRPolicies' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].reviewHrPolicy}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            {
                                this.state.step1 ?
                                    <StepOne sendData={{
                                        reasonsOfRequest: this.state.reasonsOfRequest,
                                        entityFormalRequest: this.state.entityFormalRequest,
                                        standardComparisons: this.state.standardComparisons,
                                        studies: this.state.studies,
                                        studiesShortName: this.state.studiesShortName,
                                    }}
                                        setDataObject={this.setData} isArabic={this.state.isArabic}
                                        saveAsDraft={this.setData}
                                        previewPage={this.handlePreViewNavigation}

                                    />
                                    : null
                            }
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'120vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}