import { ToastContainer, toast, cssTransition, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../css/style.css';

toast.configure({
    autoClose: 3000,
    draggable: false,
});

const Zoom = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    // default to 750ms, can be omitted
    duration: 1750,
});

const ToastComponent = {

    notifyErr: (message) => {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Zoom,
            rtl: true
            //   closeButton: <CloseButton YouCanPassAnyProps="foo" />
        });
    },

    notifySuccess: (message) => {
        toast.success(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Zoom,
        });
    },

    notifyWarn: (message) => {
        toast.warn(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Zoom,
        });
    },

    notifyInfo: (message) => {
        toast.info(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Zoom,
        });
    }
}

export default ToastComponent;
