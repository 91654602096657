import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import ReactExport from "react-data-export";
import { getDate } from 'date-fns';
const striptags = require('striptags');
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default class ReportsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endDate: '',
            fromDate: '',
            SectorsArray: [],
            DepartmentsArray: [],
            disableSector: '',
disableDepartment: '',
            DataArray: [],
            ArrabicColumns: [], departmentID: 0, sectorID:0 ,  tempDepartmentArray:[]       }
        
        this.GetLabelByIndex = this.GetLabelByIndex.bind(this);
        this.handleChangeForDepartment = this.handleChangeForDepartment.bind(this);
        this.handleChangeForSector = this.handleChangeForSector.bind(this);
       
    }

    handleChangeForDepartment = (event) => {
        this.setState({ departmentID: event.target.value });
    }
    handleChangeForSector = (event) => {
        let tempdeparray = this.state.tempDepartmentArray;
        if(event.target.value==0)
        {
            this.setState({ sectorID: event.target.value,DataArray:[],DepartmentsArray:tempdeparray   });
        }
        else
        {
       
        const temparr = tempdeparray.filter(item => item.SectorID==event.target.value);
        this.setState({ sectorID: event.target.value,DataArray:[],DepartmentsArray:temparr
         });
        }
    }
    componentDidMount = () => {
      
      
        window.scrollTo(0, 0);
        let loginData = Config.getLocalStorageLoginData();
        
        this.setState({
            loginData: loginData
        }, () => {
            if (loginData) {
                let tempEntityName = this.props.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn
                this.setState({
                   // applicantsName: tempEntityName
                })
            }
            })

        let tempDepartmentArray = [];
        if (Config.getDepartmentData()) {
            tempDepartmentArray = Config.getDepartmentData();
        }
        let tempSectorArray = [];
        if (Config.getSectorData()) {
            tempSectorArray = Config.getSectorData();
        }

        let tempColumnsarray = [];
        tempColumnsarray[0] = 'حالة الطلب';
        tempColumnsarray[1] = 'اتفاقية مستوى الخدمة';
        tempColumnsarray[2] = 'تاريخ استكمال الطلب';
        tempColumnsarray[3] = 'تاريخ الطلب';
        tempColumnsarray[4] = 'الإدارة المعنية بالطلب';
        tempColumnsarray[5] = 'القطاع';
        tempColumnsarray[6] = 'الجهة الطالبة';
        tempColumnsarray[7] = 'عنوان الطلب';
        tempColumnsarray[8] = 'رقم الطلب';
        tempColumnsarray[9] = 'نوع الطلب';
        tempColumnsarray[10] = 'نوع الخدمة';
        let departmentID=0;
        let sectorID=0;
        let tempdisableSector='1',tempdisableDepartment='1';
        
        if ( loginData.RoleID===72)
        {
            // departmentID=loginData.userdepartmentid;
            sectorID=loginData.userSectorID;
            tempdisableSector='';
            tempdisableDepartment=1;
            let tempdeparray = Config.getDepartmentData();
            tempDepartmentArray = tempdeparray.filter(item => item.SectorID==sectorID);
            // this.setState({DepartmentsArray:temparr
            //  });

        }
     
        else    if(loginData.permissionType.canGenerateAllreports!=1)
        {
            departmentID=loginData.userdepartmentid;
            sectorID=loginData.userSectorID;
            tempdisableSector='';
            tempdisableDepartment='';

        }

        this.setState({
            ArrabicColumns: tempColumnsarray,
            SectorsArray: tempSectorArray,
            DepartmentsArray: tempDepartmentArray,  tempDepartmentArray:tempDepartmentArray,departmentID,sectorID,
            disableSector:tempdisableSector,
disableDepartment:tempdisableDepartment



        }, () => {

        });
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

 

    handleDateOfFromDate = (date) => {
        if (date) {
            // add +1 day
            var d = new Date(date);
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var tempDate = new Date(year, month, day +7);

            this.setState({
                fromDate: date,
                periodOfSendMentErr_0: '',
                endDate: tempDate
            });
        } else {
            this.setState({
                fromDate: null,
                endDate: null
            });
        }
       
    }

    handleDateofEndDate = (date) => {
        debugger;
        if (date) {
            // add +1 day
        

            this.setState({
             
               
                endDate: date
            });
        } else {
            this.setState({
                
                endDate: null
            });
        }
      
    }

    submitHandler = event => {


        event.preventDefault();
        event.target.className += " was-validated";
        let
            StartDateErr = '',
            EndDateErr = '';
        if (this.state.fromDate) {
            
        }
        else {
            StartDateErr = 'thisFieldIsRequired';
        }
        if (this.state.endDate) {
            var Endate = new Date(this.state.endDate);
            var Startdate = new Date(this.state.fromDate);
           
            if (Endate < Startdate) {
                EndDateErr = 'invalidEndDate';
            }
        }
        else {
            EndDateErr = 'thisFieldIsRequired';
        }

        this.setState({
            fromDateErr: StartDateErr,
            endDateerror: EndDateErr,
          
        }, () => {
            });
        if (!EndDateErr &&
            !StartDateErr) {
            debugger;
            var Endate = new Date(this.state.endDate);
            var Startdate = new Date(this.state.fromDate);
         

            var strStartDate = Startdate.getFullYear() + '-' + (Startdate.getMonth() + 1) + '-' + Startdate.getDate();
            var strendDate = Endate.getFullYear() + '-' + (Endate.getMonth() + 1) + '-' + Endate.getDate();
            var obj = {
                StartDate:strStartDate,
                endDate: strendDate,
                sectorID: this.state.sectorID,
                DepartmentID : this.state.departmentID
            
            }
            let endPoint = 'GenerateSystemReport'
            debugger;
            let url = Config.getUrl(endPoint);
          
            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, obj, res => {
                    if (res.Status) {
                        this.setState({ DataArray: res.data }, () => {
                            this.setState({
                                isLoading: false
                            })


                        });
                        


                    }
                })
            }
            )
                    
        }
    }
    GetLabelByIndex(index) {
        return this.state.ArrabicColumns[index];
    }

 
    render() {
        let tableDataArray = this.state.DataArray;
        let dataArray = [];
        if (tableDataArray.length > 0) {
            
            dataArray = tableDataArray.length ? Object.keys(tableDataArray[0]) : [];
        }
        var Endate = new Date(this.state.endDate);
        var Startdate = new Date(this.state.fromDate);


        var strStartDate = Startdate.getFullYear() + '-' + (Startdate.getMonth() + 1) + '-' + Startdate.getDate();
        var strendDate = Endate.getFullYear() + '-' + (Endate.getMonth() + 1) + '-' + Endate.getDate();
        return (
          
    

            <form
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                
                                {i18n[this.getLocale()].Reports.startdate}
                                <span className="requireStar">*</span>
                            </label>
                          
                            <div
                                style={{
                                    width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                }}
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.fromDateErr ? "datePickerBorderColorErr" : ""}`}>
                               
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    maxDate={this.state.dateAfterOneYear}
                                    style={{ width: '100%' }}
                                    placeholderText={i18n[this.props.isArabic ? 'ar' : 'en'].Reports.startdate}
                                    selected={this.state.fromDate}
                                    onChange={this.handleDateOfFromDate}
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required />
                            </div>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                
                                    {i18n[this.getLocale()].Reports.enddate}
                                    <span className="requireStar">*</span>
                                </label>
                                <div
                                    style={{
                                        width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                    }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.endDateerror ? "datePickerBorderColorErr" : ""}`}>
                                  
                                    <DatePicker
                                        className="form-control"
                                        todayButton={"Today"}
                                      
                                        style={{ width: '100%' }}
                                        placeholderText={i18n[this.props.isArabic ? 'ar' : 'en'].Reports.enddate}
                                        selected={this.state.endDate}
                                        onChange={this.handleDateofEndDate}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        dateFormat="MM/dd/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        //showTimeInput
                                        required />
                                </div>

                            </div>

                        </div>
                    </MDBCol>

                </MDBRow>
              


                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].Reports.sector}
                              
                            </label>
                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForSector}
                                disabled={this.state.disableSector? '':'true'}
                                value={this.state.sectorID}>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.SectorsArray ? this.state.SectorsArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                         
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].Reports.department}
                             
                            </label>
                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForDepartment}
                                disabled={this.state.disableDepartment? '':'true'}
                                value={this.state.departmentID}>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.DepartmentsArray ? this.state.DepartmentsArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                        </div>

                    </MDBCol>

                </MDBRow>

           

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                     
                    <MDBBtn className="submitBtn" type='submit' color="primary">
                        {i18n[this.getLocale()].exportToExcel}
                    </MDBBtn>
                    {this.state.DataArray.length>0 ? 
                    <ExcelFile
                        filename={i18n[this.props.isArabic ? 'ar' : 'en'].report + '-' + strStartDate + '-' + strendDate}
                                            element={<MDBBtn style={{ borderRadius: '5px' }} color="primary" >
                                                {i18n[this.props.isArabic ? "ar" : "en"].downloadExcel}
                                            </MDBBtn>}>
                        <ExcelSheet data={tableDataArray} name={i18n[this.props.isArabic ? 'ar' : 'en'].report + '-' + strStartDate + '-' + strendDate}>
                                {
                                    dataArray.map((item, index) => {
                                        return (
                                            <ExcelColumn label={this.GetLabelByIndex(index)} value={item} />
                                        )
                                    })
                                }

                                            </ExcelSheet>
                        </ExcelFile>
                        : null}
                </MDBRow>
            </form>
        );
    }
}

