import axios from 'axios';

let ApiRequest = {

    sendRequestForPost: (url, payload, CB) => {
      
        try {
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: JSON.stringify(
                    payload
                )
            };
            
            axios(options)
                .then(response => {
                    let responseOK = response && response.status === 200 && response.statusText === 'OK';
                    if (responseOK) {
                        CB(response.data);
                    } else {
                        CB(response.data);
                    }
                })
                .catch(error => {
                
                    CB(error.message);
                });

        } catch (e) {
            CB("Failed");
            
        }
    },

    sendRequestForPostawait:async  (url, payload, CB) => {
      
        try {
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: JSON.stringify(
                    payload
                )
            };
            
            await   axios(options)
                .then(response => {
                    let responseOK = response && response.status === 200 && response.statusText === 'OK';
                    if (responseOK) {
                        CB(response.data);
                    } else {
                        CB(response.data);
                    }
                })
                .catch(error => {
                 
                    CB(error.message);
                });

        } catch (e) {
            CB("Failed");
         
        }
    },

    sendRequestForGet: (url, CB) => {
        try {
            axios.get(url)
                .then(response => {
                    let responseOK = response && response.status === 200 && response.statusText === 'OK';
                    if (responseOK) {
                        CB(response.data);
                    } else {
                        CB(response.data);
                    }
                })
                .catch(error => {
          
                    CB(error.message);
                });

        } catch (e) {
            CB("Failed");
          
        }
    }
};

export default ApiRequest;