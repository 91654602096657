/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBTableHead, MDBTable, MDBTableBody } from 'mdbreact';
import { getRequestByIdPayload, downloadAttachment } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import Config from './../utils/Config';
import i18n from './../i18n';
import ToastComponent from './ToastComponent';

export default class ListAttachmentWithTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attachmentArray: [],
            requestType: '',
            isPdf: false,
            isImg: false,
            isDoc: false,
            tableHeaderArray: [
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].CreatedBy },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].fileName },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].fileType },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].Date },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].downloadHere }
            ]
        }
    }

    componentDidMount = () => {
       
        if (this.props.requesttypeid) {
            if (this.props.requesttypeid ===10969) {
                this.setState({
                    requestType: Config.getSciRequestTypeId()
                }, () => {
                    this.callRequestById('GetStrategicPlanningforworkforceEnquirebyID');
                })
            }
              
         else   if (this.props.requesttypeid === Config.getSciRequestTypeId() || this.props.requesttypeid === Config.getSCILEgalSerbviceId() || this.props.requesttypeid === Config.getSCIComplainId() || this.props.requesttypeid === Config.getSCILEgalSuggestionId()
            || this.props.requesttypeid === Config.getSCIGeneralId() ) {
                this.callRequestById('GetscirequestbyID');
            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.callRequestById('GetinformationrequestbyID');
            } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                this.callRequestById('GetbounsrequestbyID');
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.callRequestById('GetAmmendSalaryScalebyID');
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.callRequestById('GetAmmendEmployeeSalarybyID');
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.callRequestById('GetHRPolicyReviewbyID');
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.callRequestById('GetHousingPolicybyID');
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.callRequestById('GetFederalSecondmentbyID');
            } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                this.callRequestById('GetPoliciesPatientrequestbyID');
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
                this.callRequestById('GetRetirementbyID');
            } 
            else if (this.props.requesttypeid === Config.getSickLeaveTypeId()) {
               
                this.callRequestById('GetSickLeaveById');
            
        } 
            else if (this.props.requesttypeid === Config.getPromotionTypeId()) {
               
                    this.callRequestById('GetPromotionById');
                
            } 

            else if (this.props.requesttypeid === Config.getDisciplinaryboardTypeId()) {
               
                this.callRequestById('GetDisciplinaryboardById');
            
        } 
            else if (this.props.requesttypeid === Config.getExtendCreateObjectiveId()) {
                this.callRequestById('GetExtendCreateObjectivesbyID');
            } else if (this.props.requesttypeid === Config.getExtendReviewObjectiveId()) {
                this.callRequestById('GetExtendReviewObjectivesbyID');
            } else if (this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                this.callRequestById('GetExtendAnnualPerformancebyID');
            } else if (this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                this.callRequestById('GetEmpPerformancebyID');
            } else if (this.props.requesttypeid === Config.getJoinAndImplementId()) {
                this.callRequestById('GetJoinAndImplementSystemsbyID');
            } else if (this.props.requesttypeid === Config.getOpenComplainsStageId()) {
                this.callRequestById('GetOpenGrievanceProcessbyID');
            } else if (this.props.requesttypeid === Config.getAmendOnSharedGovermentId()) {
                this.callRequestById('GetAmendSystemsbyID');
            } else if (this.props.requesttypeid === Config.getReportAndStaticId()) {
                this.callRequestById('GetReportsRequestsbyID');
            } else if (this.props.requesttypeid === Config.getOtherRequest()) {
                this.callRequestById('GetOtherRequestsbyID');
            }
        }
    }

    callRequestById = (endPoint) => {
        let id = this.props.id;
        let payload = getRequestByIdPayload(id);
        //let endPoint = 'GetscirequestbyID'
        let url = Config.getUrl(endPoint);

        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let tempAttachmentArray = [];
                        let attachDataFromRes = res.data.attachments;
                        if (attachDataFromRes && attachDataFromRes.length > 0) {
                            for (let index = 0; index < attachDataFromRes.length; index++) {
                                let tempAttachObj = {};
                                let filename = attachDataFromRes[index].filename;
                                let aliasfilename = attachDataFromRes[index].aliasfilename;
                                let uploadedon = Config.getFormattedDate(attachDataFromRes[index].uploadedon);
                                let uploadedby = attachDataFromRes[index].uploadedby;
                                let url = attachDataFromRes[index].url;
                                let filetype = attachDataFromRes[index].filetype;

                                tempAttachObj.filename = filename
                                tempAttachObj.aliasfilename = aliasfilename
                                tempAttachObj.uploadedon = uploadedon
                                tempAttachObj.uploadedby = uploadedby
                                tempAttachObj.url = url
                                tempAttachObj.filetype = filetype;

                                tempAttachmentArray.push(tempAttachObj);
                            }
                            this.setState({
                                attachmentArray: tempAttachmentArray
                            }, () => {
                                //this.props.update(this.state.commentsArray);
                            })
                        }
                    } else {
                        //alert(res.Message);
                    }
                })
            })
        })
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    saveFile = (item) => {
       
        let obj = {}
        obj.filename = item.aliasfilename;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.requestid = this.props.id;

        let payload = downloadAttachment(obj);
        let endPoint = 'DownloadAttachment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                       
                        let fileData = res.data;
                        let filecontent = fileData.filecontent;
                        let contenttype = fileData.contenttype;
                        let blob = this.b64toBlob(filecontent, contenttype);
                       
                        if (navigator.msSaveBlob) {
                          
                            window.navigator.msSaveBlob(blob, item.filename);
                        } else {
                            let a = window.document.createElement('a');

                            a.href= URL.createObjectURL(blob);
                            //a.href = `data:${contenttype};base64,${filecontent}`;
                            a.download = item.filename;
                            a.text='download';
                            // Append anchor to body.
                            document.body.appendChild(a);
                            a.click();
                            // Remove anchor from body
                            document.body.removeChild(a);
                        }
                    } else {
                       
                        // i18n[this.props.isArabic ? 'ar' : 'en'].fileName
                        let tempFileNotFoundMessage = i18n[this.props.isArabic ? 'ar' : 'en'].filenotfound
                        if (res.Message === 'filenotfound') {
                            ToastComponent.notifyInfo(tempFileNotFoundMessage);
                        }
                    }
                })
            })
        })
    }

    render() {
        return (
            <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                {
                    <MDBContainer style={{ display: 'flex: ', flexDirection: !this.props.isArabic ? 'row-reverse' : 'row', height: '100%', }}>
                        <MDBRow style={{ display: 'flex', flexDirection: !this.props.isArabic ? 'row-reverse' : 'row', height: '100%', margin: '20px' }} >
                            <MDBCol md="12">
                                <MDBTable striped responsive hover style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                    <MDBTableHead>
                                        <tr style={{ textAlign: "center" }}>
                                            {
                                                this.state.tableHeaderArray ? this.state.tableHeaderArray.map((item, index) => {
                                                    if (item.tableHeader === i18n[this.props.isArabic ? 'ar' : 'en'].fileName) {
                                                        return '';
                                                    }
                                                    return (<th>
                                                        {item.tableHeader}
                                                    </th>
                                                    )
                                                })
                                                    :
                                                    ''
                                            }
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                            this.state.attachmentArray ? this.state.attachmentArray.map((item, index) => {
                                                return (<tr style={{ textAlign: "center" }}>
                                                    <td>
                                                        {item.uploadedby}
                                                    </td>
                                                    {/* <td>
                                                        {item.filename}
                                                    </td> */}
                                                    <td>
                                                        {item.filetype}
                                                    </td>
                                                    <td>
                                                        {item.uploadedon}
                                                    </td>
                                                    <td>
                                                        <img
                                                            onClick={() => this.saveFile(item)}
                                                            style={{
                                                                height: "auto", display: "inline-block", valign: "middle",
                                                                verticalAlign: "middle", width: "auto", maxWidth: "20px", maxHeight: "20px", marginLeft: '10px', cursor: "pointer"
                                                            }}
                                                            alt=""
                                                            src={require('./../assets/images/BlueDownloads.png')}
                                                            className="img-fluid" />
                                                    </td>
                                                </tr>)
                                            }) : ''
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                }
            </div>
        );
    }
}
