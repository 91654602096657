import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import StepsComponent from "../StepsComponent";
import Config from "../../utils/Config";
import i18n from "../../i18n";
import "./../../css/style.css";
import DownloadComponent from "../DownloadComponent";
export default class SickLeaveUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            isSubmit: false,
            //RequestType: this.props.sendData.RequestType,
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getSickLeaveTypeId(),
            editAttachmentArray:[],
            EmployeePassportArray: [],
            EmployeePassportErr: "",
            EmployeePassport:this.props.sendData.EmployeePassport,
            EmployeePassportFileObj: "",

            EmployeeEIDArray: [],
            EmployeeEIDErr: "",
            EmployeeEID:this.props.sendData.EmployeeEID,
            EmployeeEIDFileObj: "",

            SickLeaveHealthreportArray: [],
            SickLeaveHealthreportErr: "",
            SickLeaveHealthreport:this.props.sendData.SickLeaveHealthreport,
            SickLeaveHealthreportFileObj: "",

            SickLeaveEmbassyLetterArray: [],
            SickLeaveEmbassyLetterErr: "",
            SickLeaveEmbassyLetter:this.props.sendData.SickLeaveEmbassyLetter,
            SickLeaveEmbassyLetterFileObj: "",

            SickLeaveHRComArray: [],
            SickLeaveHRComErr: "",
            SickLeaveHRCom:this.props.sendData.SickLeaveHRCom,
            SickLeaveHRComFileObj: "",

            EmployeeIncomeArray: [],
            EmployeeIncomeErr: "",
            EmployeeIncome:this.props.sendData.EmployeeIncome,
            EmployeeIncomeFileObj: "",

            EmployeePassportUrl : '',
            EmployeeEIDUrl: '',
            SickLeaveHealthreportUrl: '',
            SickLeaveEmbassyLetterUrl: '',
            SickLeaveHRComUrl: '',
            EmployeeIncomeUrl: '',

        }
    }
    
    EmployeePassportOnChange= e => {
        if (e.target.files.length > 0) {
                  let inpFileName = e.target.files[0].name;
                  let fileSize = e.target.files[0].size;
                  let fileExt = inpFileName.split('.').pop().toUpperCase();
                  let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
                  if (allowedExtensions.indexOf(fileExt) <= -1) {
                      e.target.value = '';
                      this.setState({ EmployeePassport: '', EmployeePassportErr: 'invalidFileType' });
                  } else {
                      let fileTargetVarible = e.target.files[0];
                      if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                          this.setState({ EmployeePassport: e.target.files[0].name, EmployeePassportErr: '' }, () => {
                              let tempFile = fileTargetVarible;
                              let reader = new FileReader();
                              reader.onloadend = () => {
                                  this.setState({
                                      EmployeePassportFileObj: reader.result
                                  }, () => {
                                      Config.setBase64ForSickLeaveEmployeePassport(this.state.EmployeePassportFileObj);
                                  })
                              };
                              reader.readAsDataURL(tempFile);
                          });
                      }
                      else {
                          e.target.value = '';
                          this.setState({ EmployeePassport: '', EmployeePassportErr: 'invalidFileSize' });
                      }
                  }
              } 
      };
      EmployeeEIDOnChange= e => {
        if (e.target.files.length > 0) {
                  let inpFileName = e.target.files[0].name;
                  let fileSize = e.target.files[0].size;
                  let fileExt = inpFileName.split('.').pop().toUpperCase();
                  let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
                  if (allowedExtensions.indexOf(fileExt) <= -1) {
                      e.target.value = '';
                      this.setState({ EmployeeEID: '', EmployeeEIDErr: 'invalidFileType' });
                  } else {
                      let fileTargetVarible = e.target.files[0];
                      if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                          this.setState({ EmployeeEID: e.target.files[0].name, EmployeeEIDErr: '' }, () => {
                              let tempFile = fileTargetVarible;
                              let reader = new FileReader();
                              reader.onloadend = () => {
                                  this.setState({
                                      EmployeeEIDFileObj: reader.result
                                  }, () => {
                                      Config.setBase64ForSickLeaveEmployeeEID(this.state.EmployeeEIDFileObj);
                                  })
                              };
                              reader.readAsDataURL(tempFile);
                          });
                      }
                      else {
                          e.target.value = '';
                          this.setState({ EmployeeEID: '', EmployeeEIDErr: 'invalidFileSize' });
                      }
                  }
              } 
      
      };
   
     
    
      SickLeaveHealthreportOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ SickLeaveHealthreport: '', SickLeaveHealthreportErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ SickLeaveHealthreport: e.target.files[0].name, SickLeaveHealthreportErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                SickLeaveHealthreportFileObj: reader.result
                            }, () => {
                                Config.setBase64ForSickLeaveSickLeaveHealthreport(this.state.SickLeaveHealthreportFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ SickLeaveHealthreport: '', SickLeaveHealthreportErr: 'invalidFileSize' });
                }
            }
        }
    
    }; 
    
    SickLeaveEmbassyLetterOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ SickLeaveEmbassyLetter: '', SickLeaveEmbassyLetterErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ SickLeaveEmbassyLetter: e.target.files[0].name, SickLeaveEmbassyLetterErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                SickLeaveEmbassyLetterFileObj: reader.result
                            }, () => {
                                Config.setBase64ForSickLeaveSickLeaveEmbassyLetter(this.state.SickLeaveEmbassyLetterFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ SickLeaveEmbassyLetter: '', SickLeaveEmbassyLetterErr: 'invalidFileSize' });
                }
            }
        }
    
    };
    
    SickLeaveHRComOnChange = e => {
        debugger;
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ SickLeaveHRCom: '', SickLeaveHRComErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ SickLeaveHRCom: e.target.files[0].name,
                        SickLeaveHRComErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                SickLeaveHRComFileObj: reader.result
                            }, () => {
                                Config.setBase64ForSickLeaveHRCom(this.state.SickLeaveHRComFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ SickLeaveHRCom: '', SickLeaveHRComErr: 'invalidFileSize' });
                }
            }
        }
    
    };
    
    EmployeeIncomeOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ EmployeeIncome: '', EmployeeIncomeErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ EmployeeIncome: e.target.files[0].name, EmployeeIncomeErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                EmployeeIncomeFileObj: reader.result
                            }, () => {
                                Config.setBase64ForSickLeaveEmployeeIncome(this.state.EmployeeIncomeFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ EmployeeIncome: '', EmployeeIncomeErr: 'invalidFileSize' });
                }
            }
        }
    };
    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    componentDidMount = () => {
        debugger;
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
        
            this.setState({
            //    RequestType: editObj.RequestType,
                requestId: editObj.requestId,
                
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempEmployeePassportArray= [],
                tempEmployeeEIDArray= [],
                tempSickLeaveHealthreportArray= [],
                tempSickLeaveEmbassyLetterArray= [],
                tempSickLeaveHRComArray= [],
                tempEmployeeIncomeArray= [];
                
                
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getSickLeaveEmployeePassportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempEmployeePassportArray.push(temp);
                                this.setState({
                                    //passportCopy: item.filename,
                                    EmployeePassportUrl: item.aliasfilename
                                })
                            } 
                            else if (item.filetypeid === Config.getSickLeaveEmployeeEIDId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempEmployeeEIDArray.push(temp);
                                this.setState({
                                    //medicalReport: item.filename,
                                    EmployeeEIDUrl: item.aliasfilename
                                })
                            } 
                            else if (item.filetypeid === Config.getSickLeaveSickLeaveHealthreportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;
                                tempSickLeaveHealthreportArray.push(temp);
                                this.setState({
                                    //letterFromEmbassy: item.filename,
                                    SickLeaveHealthreportUrl: item.aliasfilename
                                })
                            } 
                            else if (item.filetypeid === Config.getSickLeaveSickLeaveEmbassyLetterId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;
                                tempSickLeaveEmbassyLetterArray.push(temp);
                                this.setState({
                                    //copyOftheDigest: item.filename,
                                    SickLeaveEmbassyLetterUrl: item.aliasfilename
                                })
                            }
                             else if (item.filetypeid === Config.getSickLeaveHRComId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;

                                tempSickLeaveHRComArray.push(temp);
                                this.setState({
                                    //copyOfHumanResoourcesCommittee: item.filename,
                                    SickLeaveHRComUrl: item.aliasfilename
                                })
                            } 
                            else if (item.filetypeid === Config.getSickLeaveEmployeeIncomeId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.RequestType;
                                tempEmployeeIncomeArray.push(temp);
                                this.setState({
                                    //documentationOfFinancialObligations: item.filename,
                                    EmployeeIncomeUrl: item.aliasfilename
                                })
                            }
                        })
                    }
                }

                this.setState({
               
                    EmployeePassportArray:tempEmployeePassportArray,
                    EmployeeEIDArray:tempEmployeeEIDArray,
                    SickLeaveHealthreportArray:tempSickLeaveHealthreportArray,
                    SickLeaveEmbassyLetterArray: tempSickLeaveEmbassyLetterArray,
                    SickLeaveHRComArray:  tempSickLeaveHRComArray,
                    EmployeeIncomeArray:  tempEmployeeIncomeArray,
                });

            });

        }

    };

    goToPrevious = () => {
        this.props.stepTwoPrev({
            EmployeePassport: this.state.EmployeePassport,
            EmployeeEID: this.state.EmployeeEID,
            SickLeaveHealthreport: this.state.SickLeaveHealthreport,
            SickLeaveEmbassyLetter: this.state.SickLeaveEmbassyLetter,
            SickLeaveHRCom: this.state.SickLeaveHRCom,
            EmployeeIncome: this.state.EmployeeIncome,
           
     });
    };
    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        //check for edit flag, if edit false => attachment are not compulsary
        this.checkValidations();

    };
    checkValidations = () => {
        let EmployeePassportErr = "",
            EmployeeEIDErr = "",
            SickLeaveHealthreportErr = "",
            SickLeaveEmbassyLetterErr = "",
            SickLeaveHRComErr = "",
            EmployeeIncomeErr = ""
           ;
        if (this.state.EmployeePassportArray && this.state.EmployeePassportArray.length > 0) {
            EmployeePassportErr = "";
        } else {
            if (!this.state.EmployeePassport) {
               EmployeePassportErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.EmployeeEIDArray && this.state.EmployeeEIDArray.length > 0) {
            EmployeeEIDErr = "";
        } else {
            if (!this.state.EmployeeEID) {
                EmployeeEIDErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.SickLeaveHealthreportArray && this.state.SickLeaveHealthreportArray.length > 0) {
            SickLeaveHealthreportErr = "";
        } else {
            if (!this.state.SickLeaveHealthreport) {
                SickLeaveHealthreportErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.SickLeaveEmbassyLetterArray && this.state.SickLeaveEmbassyLetterArray.length > 0) {
            SickLeaveEmbassyLetterErr = "";
        } else {
            if (!this.state.SickLeaveEmbassyLetter) {
                SickLeaveEmbassyLetterErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.SickLeaveHRComArray && this.state.SickLeaveHRComArray.length > 0) {
            SickLeaveHRComErr = "";
        } else {
            if (!this.state.SickLeaveHRCom) {
                SickLeaveHRComErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.EmployeeIncomeArray && this.state.EmployeeIncomeArray.length > 0) {
            EmployeeIncomeErr = "";
        } else {
            if (!this.state.EmployeeIncome) {
                EmployeeIncomeErr = 'thisFieldIsRequired';
            }
        }
      
        
    
        this.setState({
            EmployeePassportErr: EmployeePassportErr,
            EmployeeEIDErr: EmployeeEIDErr,
            SickLeaveHealthreportErr: SickLeaveHealthreportErr,
            SickLeaveEmbassyLetterErr: SickLeaveEmbassyLetterErr,
            EmployeeIncomeErr: EmployeeIncomeErr,
    
        }, () => {
    
            if (!EmployeePassportErr && !EmployeeEIDErr && !SickLeaveHealthreportErr && !SickLeaveEmbassyLetterErr && !SickLeaveHRComErr && !EmployeeIncomeErr) {
    
                this.props.stepTwoData({
    
                    isEdit: this.state.isEdit,
                    requestId: this.state.requestId,
                    formType: this.state.formType,
                    EmployeePassport: this.state.EmployeePassport,
                    EmployeeEID: this.state.EmployeeEID,
                    SickLeaveHealthreport: this.state.SickLeaveHealthreport,
                    SickLeaveEmbassyLetter: this.state.SickLeaveEmbassyLetter,
                    SickLeaveHRCom: this.state.SickLeaveHRCom,
                    EmployeeIncome: this.state.EmployeeIncome,
                    ExperienceCertificates: this.state.ExperienceCertificates,
                    competencyreports: this.state.competencyreports,
                    passport: this.state.passport,
                    Summaryofregistrationforcitizens: this.state.Summaryofregistrationforcitizens,
                    IDcard: this.state.IDcard,
                    CID: this.state.CID,
                    EducationalQualifications: this.state.EducationalQualifications,
                    Medicalfitnesscheckup: this.state.Medicalfitnesscheckup,
                    Anotheremployeesselfstatement: this.state.Anotheremployeesselfstatement,
                });
            }
        });
    
    
    }
    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            EmployeePassport:this.state.EmployeePassport,
            EmployeeEID:this.state.EmployeeEID,
            SickLeaveHealthreport:this.state.SickLeaveHealthreport,
            SickLeaveEmbassyLetter:this.state.SickLeaveEmbassyLetter,
            SickLeaveHRCom:this.state.SickLeaveHRCom,
            EmployeeIncome:this.state.EmployeeIncome,
        });
    }
    saveAsDraft = () => {
       
        this.props.saveAsDraft({
            requestId:this.state.requestId,
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            EmployeePassport:this.state.EmployeePassport,
            EmployeeEID:this.state.EmployeeEID,
            SickLeaveHealthreport:this.state.SickLeaveHealthreport,
            SickLeaveEmbassyLetter:this.state.SickLeaveEmbassyLetter,
            SickLeaveHRCom:this.state.SickLeaveHRCom,
            EmployeeIncome:this.state.EmployeeIncome,
        });
    };

    render() {
        debugger;
        return (
            <form
                style={{ paddingTop: '20', paddingBottom: '200px',direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                                 <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.EmployeePassport}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeePassportFile"
                                    type="file"
                                    name="EmployeePassport"
                                    onChange={event => this.EmployeePassportOnChange(event)}
                                />
                                <label
                                    htmlFor="EmployeePassportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeePassportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EmployeePassport}</span>
                                </label>
                            </div>
                            {
                                this.state.EmployeePassportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeePassportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeePassportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EmployeePassportArray &&
                                    this.state.EmployeePassportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EmployeePassportArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.EmployeeEID}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeeEIDFile"
                                    type="file"
                                    name="EmployeeEID"
                                    onChange={event => this.EmployeeEIDOnChange(event)}
                                />
                                <label
                                    htmlFor="EmployeeEIDFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeeEIDFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EmployeeEID}</span>
                                </label>
                            </div>
                            {
                                this.state.EmployeeEIDErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeEIDErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeEIDErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EmployeeEIDArray &&
                                    this.state.EmployeeEIDArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EmployeeEIDArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

           
                         </MDBRow>
                         <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                                 <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.SickLeaveHealthreport}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="SickLeaveHealthreportFile"
                                    type="file"
                                    name="SickLeaveHealthreport"
                                    onChange={event => this.SickLeaveHealthreportOnChange(event)}
                                />
                                <label
                                    htmlFor="SickLeaveHealthreportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="SickLeaveHealthreportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.SickLeaveHealthreport}</span>
                                </label>
                            </div>
                            {
                                this.state.SickLeaveHealthreportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SickLeaveHealthreportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SickLeaveHealthreportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.SickLeaveHealthreportArray &&
                                    this.state.SickLeaveHealthreportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.SickLeaveHealthreportArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.SickLeaveEmbassyLetter}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="SickLeaveEmbassyLetterFile"
                                    type="file"
                                    name="SickLeaveEmbassyLetter"
                                    onChange={event => this.SickLeaveEmbassyLetterOnChange(event)}
                                />
                                <label
                                    htmlFor="SickLeaveEmbassyLetterFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="SickLeaveEmbassyLetterFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.SickLeaveEmbassyLetter}</span>
                                </label>
                            </div>
                            {
                                this.state.SickLeaveEmbassyLetterErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SickLeaveEmbassyLetterErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SickLeaveEmbassyLetterErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.SickLeaveEmbassyLetterArray &&
                                    this.state.SickLeaveEmbassyLetterArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.SickLeaveEmbassyLetterArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

           
                         </MDBRow>
                         <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                                 <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.SickLeaveHRCom}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="SickLeaveHRComFile"
                                    type="file"
                                    name="SickLeaveHRCom"
                                    onChange={event => this.SickLeaveHRComOnChange(event)}
                                />
                                <label
                                    htmlFor="SickLeaveHRComFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="SickLeaveHRComFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.SickLeaveHRCom}</span>
                                </label>
                            </div>
                            {
                                this.state.SickLeaveHRComErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SickLeaveHRComErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.SickLeaveHRComErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.SickLeaveHRComArray &&
                                    this.state.SickLeaveHRComArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.SickLeaveHRComArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.EmployeeIncome}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeeIncomeFile"
                                    type="file"
                                    name="EmployeeIncome"
                                    onChange={event => this.EmployeeIncomeOnChange(event)}
                                />
                                <label
                                    htmlFor="EmployeeIncomeFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeeIncomeFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.EmployeeIncome}</span>
                                </label>
                            </div>
                            {
                                this.state.EmployeeIncomeErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeIncomeErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeIncomeErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.EmployeeIncomeArray &&
                                    this.state.EmployeeIncomeArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.EmployeeIncomeArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

           
                         </MDBRow>
                         <MDBRow style={{ display: "flex", flexDirection: "row" }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        // (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        //     null
                        //     :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type="submit" color="primary">{i18n[this.props.isArabic ? "ar" : "en"].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
                }
}