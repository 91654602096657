import React, { Component } from "react"
import {MDBModalBody,MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody ,
    MDBModal,MDBModalHeader, MDBModalFooter} from "mdbreact";

    import ExpansionPanel from '@material-ui/core/ExpansionPanel';
    import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
    import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
    import Typography from '@material-ui/core/Typography';
    import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import i18n from '../../i18n';
import Locali18n from './Locali18n';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';
import { uploadDocument ,OrganizationStrucutreRequestPayload} from '../../utils/Payload';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import CKEditorComponent from '../CKEditorComponent';
import DownloadComponent from "../DownloadComponent";
import UnitsFunctions from './UnitsFunctions';


export default class UnitesDetails extends UnitsFunctions{

    constructor(props)
    {super(props);
        
       debugger;
        this.state.id=this.props.info.node.id;

    }
   
    componentWillMount()
    {
       this.getUnitInfo(this.props.info.node.id);
    }

  
    
    render() 
    {
       

            return(

                <form  className="needs-validation formWeight formSpace"
                //onSubmit={this.saveUnitDetails} 
                 noValidate>
            <MDBContainer style={{ overflow: 'auto'}}>
            <MDBModalHeader>{Locali18n[this.getLocale()].unitdetails}</MDBModalHeader>
            
            <MDBModalBody style={{overflow: 'auto'}}>

              <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>
              
              <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                       <div className="form-group">
                          <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}
                           htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].goals}<span className="requireStar">*</span></label>
                          <div style={{ border: this.state.unitGoalsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                                           <CKEditorComponent
                                  isArabic={this.getLocale()}
                                  content={this.state.unitGoals}
                                  onChange={(e)=>this.handleUnitGoals(e)}
                                  ckEditorHeight={"10vh"}
                              />
                          </div>
                          <p style={{ display: "flex", flexDirection: this.getLocale() ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                              {Locali18n[this.getLocale()].allowCkEditorLimit}
                          </p>
                          {
                              this.state.unitGoalsErr === 'thisFieldIsRequired' ?
                                  <div className="textRed" style={{ textAlign: this.getLocale() ? 'right' : 'left', width: '100%' }}>
                                      {i18n[this.getLocale()].thisFieldIsRequired}
                                  </div>
                                  :
                                  null
                          }
                      </div>
                  </MDBCol>
             
              <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                      <div className="form-group">
                          <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}
                           htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].internalRelations}<span className="requireStar">*</span></label>
                          <div style={{ border: this.state.unitInternalRelationsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                              <CKEditorComponent
                                  isArabic={this.getLocale()}
                                  content={this.state.unitInternalRelations}
                                  onChange={(e)=>this.handleUnitInternalRelations(e)}
                                  ckEditorHeight={"10vh"}
                              />
                          </div>
                          <p style={{ display: "flex", flexDirection: this.getLocale() ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                              {Locali18n[this.getLocale()].allowCkEditorLimit}
                          </p>
                          {
                              this.state.unitInternalRelationsErr === 'thisFieldIsRequired' ?
                                  <div className="textRed" style={{ textAlign: this.getLocale() ? 'right' : 'left', width: '100%' }}>
                                      {i18n[this.getLocale()].thisFieldIsRequired}
                                  </div>
                                  :
                                  null
                          }
                      </div>
                  </MDBCol>

              <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                      <div className="form-group">
                          <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }} 
                          htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].externalRelations}<span className="requireStar">*</span></label>
                          <div style={{ border: this.state.unitExternalRelationsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                              <CKEditorComponent
                                  isArabic={this.getLocale()}
                                  content={this.state.unitExternalRelations}
                                  onChange={(e)=>this.handlUnitExternalRelations(e)}
                                  ckEditorHeight={"10vh"}
                              />
                          </div>
                          <p style={{ display: "flex", flexDirection: this.getLocale() ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                              {Locali18n[this.getLocale()].allowCkEditorLimit}
                          </p>
                          {
                              this.state.unitExternalRelationsErr === 'thisFieldIsRequired' ?
                                  <div className="textRed" style={{ textAlign: this.getLocale() ? 'right' : 'left', width: '100%' }}>
                                      {i18n[this.getLocale()].thisFieldIsRequired}
                                  </div>
                                  :
                                  null
                          }
                      </div>
                  </MDBCol>

               <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }} 
                            htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].responsibilities}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.unitResponsibilitiesErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.getLocale()}
                                    content={this.state.unitResponsibilities}
                                    onChange={(e)=>this.handleUnitResponsibilities(e)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale() ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.unitResponsibilitiesErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale() ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>


              </MDBRow>

             </MDBModalBody>
      
              <MDBModalFooter>
              <MDBRow style={{ display: "flex", flexDirection: this.getLocale() ? "row-reverse" : "row" }}>
              {
                       
                       <MDBBtn  type='button' id='1' color="primary" 
                        onClick={(event)=>this.saveUnitDetails(event)}>
                           {Locali18n[this.getLocale()].saveAsDraft}
                       </MDBBtn>
               }
                  <MDBBtn className="submitBtn" id='0'  color="primary"
                    onClick={(event)=>this.saveUnitDetails(event)}>
                      {Locali18n[this.getLocale()].submit}
                  </MDBBtn>
                  <MDBBtn   color="primary" onClick={this.props.hide}>
                      {Locali18n[this.getLocale()].close}
                  </MDBBtn>
              </MDBRow>
              
              </MDBModalFooter>
              </MDBContainer>

              </form>
                   
              
             
)};
}
