const i18n = {
  
    en: {
        StrategicPlanningforworkforce:
        {
            Title: 'Strategic Planning for Work Force',           
            Enquire: 'Strategic Planning for Work Force Inquires ',
            EntityName: 'Entity',
            RequesterName: 'Requester Name',
            JobTitle: 'Job Title',
            PhoneNumber:'Contact Number',
            VacancyAppointmentForm: 'Vacancy Appointment Form',
            AttachVacancyAppointmentForm: 'Attach Vacancy Appointment Form',
            AttachJobangel: 'Attach Job angel Form',
            Attachjobdescriptiontotherequiredvacancies: 'Attach Job Descriptiont Othe Required Vacancies',
            Jobangel: 'Job Angel',
            jobdescriptiontotherequiredvacancies: 'Job Descriptiont Othe Required Vacancies',   
            sucess: "Request Submitted Sucessfully",
            Year: 'Year',
        },
        Survey:
        {
            Subject:'Survey'
        },
        allgoventities:'All Government Entities',
        exxtendatereason:'Exten Date Reason',
        failedtoextend : 'Failed To Extend Date',
        failedtoloadextend :'Failed To Load Information',
        savedsuccessfully:'Successfully Saved',
        decision:'Decision',
        datefrominfo:'Due Date',
        WorkShopErrorMessage:
{
    invalidEmail: 'Eneter Valid Email',

        AttendanceEmailinvalidEmail: 'Enter Valid Attendance Email',

            invalidPhoneNumber: 'Enter Valid Phone Number',

                AttendanceinvalidPhoneNumber: 'Enter Valid Attendance Phone Number',
                    AttendancenameErr: "Enter the Attendance Name",
        AttendanceJobDegreeErr: "Enter the Attendance Job Degree", AttendanceJobTitleErr: "Enter the Attendance Job Title",
            AttendancePhoneErr: "Enter the Office Number",
                AttendanceEmailErr: "Enter the Email",
                                                                                                                 
                                        
    },
        ActionPlansErrorMessage:
    {
        invalidEmail:'Eneter Valid Email',

focalpointinvalidEmail:'Enter Valid Focal Point Email',

invalidPhoneNumber:'Enter Valid Phone Number',

focalpointinvalidPhoneNumber:'Enter Valid Focal Point Phone Number',
focalpointinvalidMobile:'Enter Valid Focal Point Mobile Number',

        ProjectBudgetError:"Sum of Projects / Tasks Budger Must be less than or equal Initiative Budget",
        DateValidation:"End Date Must Be Later to Start Date ",
        InitiativesNameErr:"Enter the Initiatives Name",
        InitiativeBudgetErr:"Enter the Initiative Budget",
        NameofresponsibleErr:"Enter the Name of responsible",
        RoleofresponsibleErr:"Enter the Role of responsible",
        ExpectedOutcomesErr:"Enter the Expected Outcomes",
        InitiativeStatusErr:"Enter the  Initiative Status",
        SupportDocumentsErr:"Attach the Support Documents",
        

        FocalPointNameErr:"Enter the Focal Point Name",
FoaclPointTitleErr:"Enter the Focal Point Title",
FocalPointPhoneErr:"Enter the Office Number",
FocalPointEmailErr:"Enter the Email",
FocalPointMobileErr:"Enter the Mobile Number",

        requesterNameErr: "Enter the name of the requester",
        requesterphoneNumber: "Enter requester phone number",
        rquesterEmail: "Enter requester Email",
        EmployeesTotalNumber: 'You must enter the total number of employees',
        UAEEmployeesTotalNumber: 'The total number of national employees must be entered',
        NonEmployeesTotalNumber: 'The total number of Non-National employees must be entered',
        NNUAEHAvePDP: 'The total number of national employees who have personal development plans must be entered.',
        NNNonUAEHAvePDP: 'The total number of non-national employees who have personal development plans must be entered.',
        NNUAEIncluded: 'You must enter the total number of national employees that were included in the Organizational Health initiatives and plans.',
        NNNONUAEIncluded: 'You must enter the total number of non-national employees who were included in the Organizational Health initiatives and plans.',
        NNEHave: 'The total number of national employees who have a training and development plan must be entered.',
        NNonUAEHave: 'The total number of non-national employees who have a training and development plan must be entered.',
        UAEEmployeesTotalNumberErr: 'The total number of national employees shall be equal or less than the total number of employees',
        NonEmployeesTotalNumberErr: 'The sum of the total number of national employees and the total number of Non-National employees shall be equal to the total number of employees',
        NNUAEHAvePDPErr: 'The total number of national employees with personal development plans must be equal or less than the total number of national employees',
        NNNonUAEHAvePDPErr: 'The total number of Non-National employees with personal development plans must be equal or less than the total number of Non-National employees',
        NNUAEIncludedErr: 'The total number of national employees who were included in the Organizational Health initiatives and plans must be equal or less than the total number of national employees',
        NNEHaveErr: 'The total number of national employees who have a training and development plan must be equal or less than the total number of national employees',
        NNonUAEHaveErr: 'The total number of Non-National employees who have a training and development plan must be equal or less than the total number of Non-National employees',
        NNNONUAEIncludedErr: 'The total number of Non-National employees included in the Organizational Health initiatives and plans must be equal or less than the total number of Non-National employees',
        /************* */
        ProjectBudgetEmptyErr:'You must enter the Project Budget or enter zero',
        
StartDateErr:'You must enter Start Date',
EndDateErr:'You must enter End Date',
MetricErr:'You must enter Metric (to track progress)',
ProjectStatusEmptyErr:'You must enter Projects/ Tasks Status',
PrjectStatusisIntitaionErr:'You must change Projects/ Tasks Status',
ProjectReasonErr:'You must enter Justification',
ProjectOtherReasonDescriptionErr:'You must enter Other Reason Description',
ProjectReasonTextErr:'You must enter Justification Description',
ProjectAttachErr:'You must Upload Project Support File',
PrjectTitleErr:'You must enter Project Title',
InitiativeStatusisIntitaionErr:'You must change Initiative Status',
        /************** */
    },
    Substitutionplan:
    {
        Year:'Year',
        Menusubject:"Replacement plan",
Subject:"Replacement plan for an employee before reaching the referral age for retirement",
Quarter:"Quarter for year " +new Date().getFullYear(),
q1:"Quarter 1",
q2:"Quarter 2",
q3:"Quarter 3",
q4:"Quarter 4",
sucess:"ٌPlan Send Sucessfully",
Template:"Download Plan Template",
Entity:"Entity Name",
QuarterTitle:"Quarter",
Plan:"Plan",
    }
    ,
    PoliceLib:
    {
        subject:"Policies Libary", 
    },
    dary:
    {
        subject:"Dary", 
    },
    Circularization:
    {
        Date:"Circular Date",
        "Page":"Page -"
,"Next":"Next >"
,"Previous":"< Previous",
        Circularsendsucess:'Circular Entered Sucessfully',
        subject:"Circularis",
        MessageSubject:"Title",
        MessageDetails:"Message",
        Entityes:"Entites",
        Attachments:"Attachments",
        Createduser:"Sender"
    },
        ActionPlans:
        {
            Expand:'Expand Project',
            collapse:'Collapse Project',
            OverAll:"Overall",
            ProjectsKPI:"Projects",
            EmployeeInfo:"Employees",
            NumberofCompletedProjectsWithBudget:'Number of Completed Projects / Tasks with Budget',
            NumberofCompletedProjectsWithoutBudget:'Number of Completed Projects / Tasks with out budget ',
            MertricDesctiption:'Determine the right metric you will use to track progress',
            NumberofApprovedInitiatives:'Number of Approved Initiatives',
NumberofApprovedKeyProjects:'Number of Approved Key Projects/ Tasks under each Initiatives',
NumberofKeyProjectsTasksundereachInitiativeswithBudget:'Number of Key Projects/ Tasks under each Initiatives with Budget',
NumberofKeyProjectsTasksundereachInitiativeswithOutBudget:'Number of Key Projects/ Tasks under each Initiatives with Out Budget',
NumberofCompletedProjectsTasksaccordingtotheplan:'Number of Completed Projects / Tasks according to the plan',
NumberofCompletedProjectTasksProjectsoutoftheplan:'Number of Completed Projects / Tasks  out of the plan',
NumberofCancelledProjectsTasksProjects:'Number of Cancelled Projects / Tasks Projects',
NumberofChangedProjectsTasksProjects:'Number of Changed Projects / Tasks Projects',
NumberofNationalEmployeeswhohavePersonalDevelopmentPlan:'Number of National Employees who have Personal Development Plan',
NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan:'Number of Non- National Employees who have Personal Development Plan',
NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans:"Number of National Employees who are included in the Organizational Health's initiatives & Plans",
NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans:'Number of Non- National Employees who are included in the initiatives & Plans',
NumberofNationalEmployeeswhohaveTrainingPlan:'Number of National Employees who have Training Plan',
NumberofNonNationalEmployeeswhohaveTrainingPlan:'Number of Non- National Employees who have Training Plan',
NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan:'Number of National Employees who have not Personal Development Plan',
NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans:'Number of National Employees who are not included in the initiatives & Plans',
NumberofNationalEmployeeswhohavenotTrainingPlan:'Number of National Employees who have not Training Plan',
            KPI:'Key Projects/ Tasks- KPIs',
            ViewDetails:'View Details',
            Edit:'Edit',
            sucess:'initiative was successfully entered',
            subject:'Organizations health initiatives', 
            
            Defniations:'Defniations',
            EmployeesTotalNumber:'The Employees Total Number',
            UAEEmployeesTotalNumber:'Total Number of the National Employees',
            NonEmployeesTotalNumber:'Total Number of the Non- National Employees',
            NNUAEHAvePDP:'Total Number of the National Employees who have Personal Development Plan',
            NNNonUAEHAvePDP:'Total Number of the Non- National Employees who have Personal Development Plan',
    
            NNUAEIncluded:'Total Number of the National Employees who are included in the Organizational Health\'s initiatives & Plans',
            NNNONUAEIncluded:'Total Number of the Non- National Employees who are included in the initiatives & Plans',
            
            NNEHave:'Total Number of the National Employees who have Training Plan',
           
            
            NNonUAEHave:'Total Number of the Non- National Employees who have Training Plan',
            FocalPoint:'Focal Point Details',
            FocalPointName:'Focal Point Name',
            FoaclPointTitle:'Focal Point Title',
            FocalPointMobile:'Mobile Number',
            FocalPointEmail:'Email',
            FocalPointPhone:'Office Number',
            removemember:'Remove member',
            addnewmember:'Add new member',
    
            Initiatives:'Organizations health initiatives',
            InitiativeName :'Initiatives Name',
            EntitName:'Entity Name',
    SerialNumber:'Serial Number',
            StartDate:'Start Date',
            EndDate:'End Date',
    InitiativeKeyProjectsTasks:'Key Projects/ Tasks',
    InitiativeBudget:'Initiative Budget',
    InitiativeStartDate:'Start Date',
    InitiativeEndDate:'End Date',
    InitiativeProjectsTasksBudget:'Project / Task Budget',
    InitiativeProjectsTasksStatus:'Projects/ Tasks Status',
    InitiativeDelayJustification:'Justification',
    InitiativeDelayJustificationTxt:'Justification Description',
    InitiativeSupportDocuments:'Support Documents',
    MainTheme:'OHI-Main Theme',
    MainPractice:'OHI-Main Practice',
    InitiativeKeyProjectsTasksTitle:'Project / Task Name',
    OtherDescrption:'Other Reason Description',
    addProject:'add Project',
    removProject:'remove Project',
    Nameofresponsible:'Name of responsible',
    Roleofresponsible:'Role of responsible',
    Metric:'Metric (to track progress)',
    ExpectedOutcomes:'Expected Outcomes',
    ActualOutcomes:'Actual Outcomes',
    InitiativeStatus:'Initiative Status',
    InitiativeUpdate:'Update Required'
    
    
    
    
    
        },
        
        editcomment:'Edit My Comment',
        update: 'Update',
        invalidToDate: 'To Date Must be grater than from Date',
        shortNameForServices: {
            
            generalWizard: 'SCI',
            informationRequest: 'IR',
            exemptionPatient: 'PE',
            hrPolicy: 'HR',
            adHousing: 'HP',
            secondment: 'FS',
            retirment: 'RT',
            bonusRequest: 'BR',
            amendSalary: 'AS',
            amendEmployee: 'AES',
            extendCreate: 'EC',
            extendReview: 'ER',
            extendAnnual: 'EA',
            addAndEditEmployeePerformance: 'EP',
            joinAndImplement: 'JI',
            openGravience: 'OG',
            amendSharedSyatem: 'AH',
            reportAndStatic: 'SR',
            otherRequest: 'OR'
        },
        q1: 'Q1',
        q2: 'Q2',
        q3: 'Q3',
        q4: 'Q4',
        viewMore: '...',
        failedtoassigngmoffice: 'Failed To Assign GM Office',
        failedtoassignentity: 'Failed To Assign Entity',
        failedtoassignemployee: 'Failed To Assign Employee',
        failedtoassigndepartment: 'Failed To Assign Department',
        failedtoassignsector: 'Failed To Assign Sector',
        failedtosavecomment: 'Failed To Save Comment',
        failedtoassign: 'Failed To Assign',
        filenotfound: "File Not Found",
        pleaseWait: 'Please Wait',
        exportToExcel: 'Export',
        writeusat: 'Write Us At: ',
        supportemail: 'hrahub.helpdesk@hra.gov.ae',
        allFieldsAreMandatory: 'All fields are mandatory.',
        fileLimitErr: "You are only allowed to upload a maximum of 10 files",
        failed_to_change_password: 'Failed To Changes Password',
        notifications: 'Notifications',
        helpfile: 'User Manual',
        Covid19:'Covid 19 Violations',
        salarymatrix: 'salary Matrix',
        viewDetail: 'VieW Detail',
        task: 'Tasks',
        inbox: 'Status List',
        enterusername: 'Enter Username',
        enterpassword: 'Enter Password',
        login: 'Login',
        loginwithcredentials: 'Login with your username and password',
        BrowserNotSupportedTitle: 'Dear visitor',
        BrowserNotSupported: 'Please note that the site is not supported by the browser in use, so please use one of the following browsers that support the system (Google Chrome, Firefox,Microsoft Edge) If you continue to use this browser, you may face problems that will affect your experience while using the system. HRAHUB Team',
        SurveyTitle: 'Your opinion matters',

        SurveyText: "Thank you for using the HRAHUB system, please evaluate your experience in using the system and providing the service",
         Sad: 'Very dissatisfied',
Good: 'Acceptable',
Excellent: 'Very satisfied',
FeedBack: "Please state the reasons for your dissatisfaction with the service and help us improve our services to obtain your satisfaction",
        signin: 'Sign in',
        preview: 'Preview',
        saveAsDraft: 'Save As Draft',
        uploaddocuments:'Upload documents',
        details: 'Details',
        requiredcondition: "Required conditions if not Exemption: ",
        patientrequestcondition: "1. Budget availabilty, if no available budget should provide the conditional required budget",
        secondmentreqquestconditionone: "1. Employee agreeement",
        secondmentreqquestconditiontwo: "2. Secondment renewal should be one year",
        secondmentreqquestconditionthree: "3. Employee should pass probation period",
        secondmentreqquestconditionfour: "4. Employee should have last year performance report more than week",
        retirementrequestcondition: "1. For national age should be less than 75 and for not national age should be less than 65",
        bonusrequestcondition: "1. When budget availabilty with attachment contain balance statement",
        serviceName: "Service Name",
        serviceDescription: "Service Description",
        requiredDocuments: "Required Document",
        specialConditions: "Special Conditions",
        durationOfTheService: "Duration Of The Service",
        courseOfAction: "Course Of Action ",
        infoReqDesc: "Information Request Description",
        infoReqSpecialCondtion: "Special Conditions",
        infoReqDurationOfService: "Required Duration For Completing Request",
        sciDesc: "SCI Description",
        sciSpecialCondtion: "Special Conditions",
        sciDurationOfService: "Required Duration For Completing Request",
        amendEmpSalDesc: "Amend Employee Salary Description",
        amendEmpSalSpecialCondtion: "Special Conditions",
        amendEmpSalDurationOfService: "Required Duration For Completing Request",
        amendSalScaleDesc: "Amend Salary Scale Description",
        amendSalScaleSpecialCondtion: "Special Conditions",
        amendSalScaleDurationOfService: "Required Duration For Completing Request",
        bonusReqDesc: "Bonus Req Description",
        bonusReqSpecialCondtion: "Special Conditions",
        bonusReqDurationOfService: "Required Duration For Completing Request",
        exempAccompanyPatientDesc: "Exemption From Accompany Patient Description",
        exempAccompanyPatientCondtion: "Special Conditions",
        exempAccompanyPatientDurationOfService: "Required Duration For Completing Request",
        reviewHRPoliciesDesc: "Review Hr Polices Description",
        reviewHRPoliciesCondtion: "Special Conditions",
        reviewHRPoliciesDurationOfService: "Required Duration For Completing Request",
        exceptionADHousingDesc: "Exception ADHousing Description",
        exceptionADHousingCondtion: "Special Conditions",
        exceptionADHousingDurationOfService: "Required Duration For Completing Request",
        secondmentOfFedralAgenDesc: "Secondment Of Fedral Agency Description",
        secondmentOfFedralAgenCondtion: "Special Conditions",
        secondmentOfFedralAgenDurationOfService: "Required Duration For Completing Request",
        excepOfSteerTheAgeOfRetirREQDesc: "Retirement Description",
        excepOfSteerTheAgeOfRetirREQCondtion: "Special Conditions",
        excepOfSteerTheAgeOfRetirREQDurationOfService: "Required Duration For Completing Request",
        follow: 'Follw',
        requestnumber: 'Request Number',
        ageFormat: 'For National age less than 75 and for Non-national age less than 65.',
        budgetAvailabilityError: 'You should have available budget to submit',
        manager: 'Manger',
        internalorexternal: "User Type",
        yearValidation: 'Please enter year greater than 1920',
        employeeContract: 'Employee Contract',
        flatContract: 'Flat Contract',
        retirementSubscriptions: 'Retirement Subscriptions',
        presentationforEntityAcheivements: 'Presentation For Entity Acheivements For the Years of Required Bonus Attachement',
        emiratesID: 'Emirates ID',
        exemptionType: "Exemption type",
        requestType: "Request type",
        replacePlansForNonLocals: "Replace Plans For Non Locals",
        help: 'Help',
        downloadAll: 'Download All',
        userManual: 'User Manual',
        userManualEnglish: 'User Manual For English',
        userManualArabic: 'User Manual For Arabic',
        userManualforAdminEnglish: 'User Manual For Admin English',
        userManualforAdminArabic: 'User Manual For Admin Arabic',
        table: 'Table',
        mailtemplatetype: 'Mail Template Type',
        statushistory: 'Status History',
        eservicesStats: 'Eservices Stats',
        pending: 'New',
        Underapproval:'Under approval',
        Late:"Late",
        Notification:'Send Notofication',
        inprogress: 'In Progress',
        onhold: 'On Hold',
        cancelled: 'Cancelled',
        completed: 'Completed',
        fileName: "File Name",
        fileType: "File Type",
        downloadHere: "Download Here",
        selectDropdownDefaultValue: '--select--',
        contractPeriod: 'Employee Contract Period',
        requiredRecomandation: 'Required Recomandation',
        englishName: 'English Name',
        Department: 'Department',
        Sector: 'Sector',
        arabicName: 'Arabic Name',
        shortName: 'Short Name',
        empNumber: 'Employee Number',
        isMotherComp: 'Is Mother Company',
        sector: 'Sector',
        industry: 'Industry',
        logo: 'Logo',
        entity: 'Entity',
        clientDescr: 'Client Description',
        city: 'City',
        isActive: 'Is Active',
        isDefault: 'Is Default',
        reasonToDeative: 'Reason To Deactivate',
        phnNumber: 'Phone Number',
        selectSector: 'Select Sector',
        selectCity: 'Select City',
        selectReason: 'Select Reason',
        selectIndustry: 'Select Industry',
        userNameLabel: "User Name",
        mobile: 'Mobile',
        phoneNumber: "Phone Number",
        designation: "Designation",
        accountType: 'Account Type',
        selectAccountType: 'Select Account Type',
        selectRole: 'Select Role',
        role: "Role",
        status: "Status",
        selectStatus: "Select Status",
        selectEntity: "Select Entity",
        reason: "Reason",
        title: "Title",
        EnglishTitle: "English Title",
        ArabicTitle: "Arabic Title",
        selectTitle: 'Select Title',
        permission: "Permission",
        admin: "Admin",
        isadmin: "Can Admin",

        // organization structure 

        ORG_Request: 'ORG Request',
        canSubmitORGRequest: " Submit ORG Request",
        canReviewORGRequest: "Review ORG Request",
        canEditORGRequest: "Edit ORG Request",
        canUpdateORGStatus: 'Update ORG Status',
        canAddORGComment: 'Add ORG Comment',
        canshowORGcomments: " Show ORG comment",
        canAssignORGDepartment: "Assign ORG Department",
        canAssignORGEmployee: "Assign ORG Employee",
        canProvideORGFeddback: 'Provide ORG Feedback',
        canViewORGFeddback: 'View ORG Feedback',
        canassignORGsector: "Assign ORG Sector",
        canassignORGGM: "Assign ORG GM",
        canassignORGBackToEntity: "Assign ORG To Entity",


        SCI_Request: 'SCI Request',
        canSubmitSCIRequest: "Submit SCI Request",
        canReviewSCIRequest: "Review SCI Request",
        canEditSCIRequest: "Edit SCI Request",
        canUpdateSCIStatus: 'Update SCI Status',
        canAddSCIComment: 'Add SCI Comment',
        canshowscicomments: "Can Show SCI comment",
        canAssignSCIDepartment: "Assign SCI Department",
        canAssignSCIEmployee: "Assign SCI Employee",
        canProvideSCIFeddback: 'Provide SCI Feedback',
        canViewSCIFeddback: 'View SCI Feedback',
        canassignscisector: "Assign SCI Sector",
        informationReq: "Information Request",
        canSubmitInfoReq: 'Submit Information Request',
        canReviewInfoReq: "Review Information Request",
        canviewirrequest: "View Information Request",
        canEditInfoReq: "Edit Information Request",
        canUpdateInfoStatus: "Update Information Request Status",
        canshowircomments: "Can Show Information Request comments",
        canAddInfoComment: "Add Information Request Comment",
        canAssignInfoDepartment: "Assign Information Request Department",
        canProvideInfoFeedback: 'Provide Information Request Feedback',
        canViewInfoFeedback: "View Information Feedback",
        canassignirsector: "Assign Information Request Sector",
        canreceiveirfeedback: "Receive Information Request Feedback",
        canAddInfoReqFeedback: "Add Information Request Feedback",
        exemptionFromAccompanyPatient: "Exemption From Acompany Patient",
        canSubmitExmFromAccoPatient: "Submit Exemption From Acompany Patient Request",
        canReviewExempFromAccomPatient: "View Exemption From Acompany Patient Request ",
        canEditExmpFromAccomaPatient: "Edit Exemption From Acompany Patient Request",
        canUpdateExmFromAccomPatientStatus: "Update Exemption From Acompany Patient Status",
        canAddExempFromAccoPatientComment: "Add Exemption From Acompany Patient Comment",
        canSHowExmPatientCOmment: "Show Exemption From Acompany Patient Comment",
        reviewHrPolices: "Review Hr Policies",
        canSubmitHrPolicyReq: "Submit Review Hr Policies Request",
        canViewHrPolicyReq: "View Review Hr Policies Request",
        canEditHrPoliciReq: "Edit Review Hr policies Request",
        canUpdateHrPoliciStatus: "Update Review Hr Policies Status",
        canAddHrPoliciesComment: "Add Review Hr Policies Comment",
        canShowHrPoliciesComment: "Show Review Hr Policies Comment",
        exemptionFromADHousPolicy: "Exemption From AD Housing Policy",
        canSubmitExmADHousingPolicy: "Submit Exemption AD Housing Policy Request",
        canReviewExemtionAdHousingPolicy: "View Exemtion AD Housing Policy Request",
        canEditExemADHousingPolicyReq: "Edit Exemtion AD Housing Policy Request",
        canUpdateExmADHousingPolicyStatus: "Update Exemtion AD housing Policy Status",
        canAddExemtionAddHousingPolicyComment: "Add Exemtion AD Housing Policy Comment",
        canShowAdHousingComment: "Show Exemption AD Housing Comment ",
        secndmentForFedralAgency: "Secondment For Fedral Agency",
        canSubmitSecndmentFedrlAfencyRequst: "Submit Secondment For Fedral Agency Request",
        canReviewSecondmentForFedralAgencyRequest: "View Secondment For Fedral Agency Request",
        canEditSecondmentForFedralAgencyReq: "Edit Secondment For Fedral Agency Request",
        canUpdateSecondmentForFedralAgencyStatus: "Update Secondment For Fedral Agency Status",
        canAddSecondmentForFedralAgencyComment: "Add Secondment For Fedral Agency Comment",
        canShowSecondmentComments: "Show Secondment For Fedral Agency Comment",
        bonusRequest: "Bonus Request ",
        canSubmitBonusRequestsRequest: "Submit Bonus Request's Request",
        canViewBonusRequestsReq: "View Bonus Request's Request",
        canEditBonusRequests: "Edit Bonus Request's Request",
        canUpdateBonusRequestsStatus: "Update Bonus Request's Status",
        canAddBonusRequestsComment: "Add Bonus Request's Comment",
        canShowBonusRequestsComment: "Show Bonus Request's Comment",
        amendSalaryScale: "Amend Salaries Scale",
        canSubmitAmendSalScaleReq: "Submit Amend Salaries Scale Request",
        canViewAmendSalaryScaleReq: "View Amend Salaries Scale Request",
        canEditAmendSalariScaleReq: "Edit Amend Salaries Scale Request",
        canUpdateAmendSalScaleStatus: "Update Amend Salaries Scale Status",
        canAddAmendSalScaleComm: "Add Amend Salaries Scale Comment",
        canshowamendscalecomments: "Show Amend Salary Scale Comment",
        amendEmpSalary: "Amend Employee Salary",
        canSubmitAmendEmpSalReq: "Submit Amend Employee Salary Request",
        canViewAmendEmpSalReq: "View Amend Employee Salary Request",
        canEditAmendEmpSalReq: "Edit Amend Employee Salary Request",
        canUpdateAmendEmpSalStatus: "Update Amend Employee Salary Status",
        canAddAmendEmpSalComment: "Add Amend Employee Salary Comment",
        canshowamendEmpComments: "Show Amend Employee Salary  Comment",
        retirement: "Retirement ",
        canSubmitRetirementReq: "Submit Retirement Request",
        canViewRetirementReq: "View Retirement Request",
        canEditRetirementReq: "Edit Retirement Request",
        canUpdateRetirementStatus: "Update Retirement Status",
        canAddRetirementComment: "Add Retirement Comment",
        canshowRetirementComments: "Show Retirement Comment",
        canassignsalariesdepartment: "Assign Salaries Department",
        canassignsalariesEmp: "Assign Salaries Employee",
        canProvideSalStudy: "Provide Salaries Study",
        canReceivesSalStudy: "Receives Salaries Study",
        canassignResearchdepartment: "Assign Research Department",
        canassignResearchEmp: "Assign Research Employee",
        canProvideResearchStudy: "Provide Research Study",
        canReceivesResearchStudy: "Receives Research Study",
        canassignPoliciesSector: "Assign Policies Sector",
        canassignPoliciesDept: "Assign Policies Department",
        canAssignPoliciesEmp: "Assign Policies Employee ",
        canProvidePoliciesStudy: "Provide Policies Study",
        canRecivesPoliciesStudy: "Recives Policies Study",

        roles: 'Roles',
        entities: 'Entities',
        contact: 'Contact',
        mailTemplate: 'Mail Template',
        lookUp: 'Look Up',
        jobTitle: 'Job Title',
        enterPercentage: 'Enter Percentage (%)',
        enterYear: 'Enter Year',
        enterTotalEntityEmployee: 'Enter Total Entity employee',
        enterExcelentEmployee: 'Enter Excelent employees',
        enterVeryGoodEmployee: 'Enter Very Good employees',
        enterGoodEmployee: 'Enter Good employees',
        enterAcceptableEmployee: 'Enter Acceptable employees',
        enterWeakEmployee: 'Enter Weak Employees',
        totalNumberWithPrivateContract: 'Enter Number Of Local With Private Contract',
        totalNumberWithPermenantContract: 'Enter Number Of Local With Permenant Contract',
        totalNumberWithnonPrivateContract: 'Enter Number Of Non-local With Private Contract',
        totalNumberWithnonPermenantContract: 'Enter Number of Non Local With Permenant Contract',
        percentageOfEmployeeGotExcellentGrade: 'Percentage Of Employee Got Excellent Grade',
        percentageOfEmployeeGotVeryGoodGrade: 'Percentage Of Employee Got Very Good Grade',
        percentageOfEmployeeGotGoodGrade: 'Percentage Of Employee Got Good Grade',
        percentageOfEmployeeGotAcceptableGrade: 'Percentage Of Employee Got Acceptable Grade',
        percentageOfEmployeeGotGradeGrade: 'Percentage Of Employee Got Weak Grade',
        permanentContract: 'Permanent Contract',
        privateContract: 'Private Contract',
        locals: 'Locals',
        nonLocals: 'Non-Locals',
        annualEvaluationForAYearWhichRequestedBonus: 'Annual Evaluation For A Year Which Requested Bonus',
        numberOfEmployeeWhichTheyNotEqual: 'Number Of Employee Which They Not Equal',
        percentage: 'Percentage (%)',
        excellent: 'Excellent',
        veryGood: 'Very Good',
        good: 'Good',
        acceptable: 'Acceptable',
        weak: 'Weak',
        invalidFileType: 'Invalid File Type',
        invalidFileSize: 'Invalid File Size',
        invalidFileSizeWorkshop:'Invalid File Size',
        approvedBudgetsOfPart: 'The Amounts Approved In The Budgets Of Part One',
        salaryScaleSummery: 'Salaries Scale Summary',
        add: 'Add',
        self: 'Self',
        //emailLogin: 'Email',
        emailLogin: 'Username',
        passwordLogin: 'Password',
        sign_in_cont: 'Sign In For Continue',
        forgotPasswordLogIn: 'Did You Forget Your Password?',
        logIn: 'Sign In',
        tagLineOfHra1: 'نهدف إلى :',
        tagLineOfHra2: 'تفعيل مشاركة',
        tagLineOfHra3: 'المواطنين في سوق العمل',
        adminPanel: 'Admin Panel',
        fromDate: 'From Date',
        toDate: 'To Date',
        fromDate1: 'From Date 1',
        toDate1: 'To Date 1',
        fromDate2: 'From Date 2',
        toDate2: 'To Date 2',
        fromDate3: 'From Date 3',
        toDate3: 'To Date 3',
        acceptedFormate: 'Accepted Formats: pdf, Size : upto 2mb',
        addStudy: 'Add Study',
        attahment: 'Attachment',
        addReply: 'Add Reply',
        SelectComment: 'Please select the comments to publish to external entity',
        cancle: 'Cancel',
        deptapproval: 'Department Approval',
        sectorapproval: 'Sector Approval',
        gmapproval: 'GM Approval',
        assignToSector: 'Assign To Sector',
        assignToGmOffice: 'Assign GM Office',
        assignToStrategicOffice: 'Assign To Partners Relationship Department',
        extendinfodate: "Extend  Due Date",
        replayextendinfodate: "Reaply To Extend  Due Date",
        assignBackToEntity: 'Assign Back To Entity',
        assignToEntity: 'Assign To Entity',
        select_sector: 'Select Sector',
        assignToDep: 'Assign To Department',
        backToManager: 'Back To Manager',
        assignToSpecificDep: 'Assign To Specific Department',
        assignToDataDep: 'Back To Data Department',
        sendToDataDep: 'Send To Data Department',
        loackrequest: 'Lock Request',
        assignToEmp: 'Assign To Employee',
        employee: 'Employee',
        department: 'Department',
        numberofdays: 'Number Of Day',
        statusreason: 'Status Reason',
        updatedon: 'Updated On',
        updatedby: 'Updated By',
        clickToDownload: 'Download File',
        select_status: 'Select Status',
        select_reason: 'Select Reason',
        add_comment: 'Add Comment',
        forgotPassword: 'Forgot Password',
        login_message_one: 'Login Message',
        success: 'Success',
        bookingSucess: 'WorkShop Booked Sucessfully',
        save_as_Draft: 'Request Saved As Draft',
        request_created: 'Successfully Created The Request',
        request_updated: 'Successfully Updated The Request',
        entity_created: 'Successfully Created The Entity',
        entity_updated: 'Successfully Updated The Entity',
        contact_created: 'Successfully Created The Contact',
        contact_updated: 'Successfully Updated The Contact',
        lookup_created: 'Successfully Created The Lookup',
        lookup_updated: 'Successfully Updated The Lookup',
        successful_delete_functionalblock: 'Successfully Delete The Functional Data',
        delete_functionalblock_error: 'Can Not Delete Parent Node, Remove The Child First',
        mailtemplate_created: 'Successfully Created The Mail template',
        mailtemplate_updated: 'Successfully Updated The Mail template',
        role_created: 'Successfully Created The Role',
        role_updated: 'Successfully Updated The Role',
        error: 'Error',
        network_message: 'Network Error, Please Check Network Connection',
        attachment_error: 'Unable To Upload Attachments',
        //error_message: 'Something Went Wrong',
        error_message: 'Unable To Save The Request',
        error_message_one: 'No Record found',
        message: 'Message',
        password_reset: 'Please Check You Email',
        password_change: 'Your Password Change Successfully',
        login_message_error: 'Please Check Username Or Password',
        ok: 'Ok',
        password: 'Password',
        confirmPass: 'Confirm Password',
        resetPass: 'Reset Password',
        passwrdErr: 'Password Do Not Match',
        enter_valid_email: "Enter Valid Email",
        information_request: 'Information Request',
        phoneNumberErr: 'Phone Number Error',
        create: 'Create',
        actions: 'Actions',
        search: 'Search',
        postComment: 'Save',
        CreatedBy: 'Created By',
        Date: 'Date',
        Comment: 'Comment',
        logout: 'Logout',
        settings: 'Settings',
        profile: 'Profile',
        facebook: 'Facebook',
        twitter: 'Twitter',
        google: 'Google',
        linkedin: 'LinkedIn',
        youtube: 'Youtube',
        select_action: 'Select Action',
        edit: 'EDIT',
        view: 'Other Deatils',
        Reopen:'Re Open Request',
        comment: 'COMMENT',
        statusUpdate: 'SUBMIT',
        welcome: 'Welcome',
        arabic: 'العربية',
        english: 'English',
        footerText: '© '+new Date().getFullYear()+'. Human Resources Authority',
        hraLabel: 'Human Resources Authority',
        dashboard: 'Dashboard',
        servicelistingtitle: 'Dashboard',
        general_manger_service: 'Connect To HRA',
        general_manger_wizard: 'General Manager Wizard',
        salaries: 'Salaries section',
        policies: 'Policies section',
        policiesServices:'HR policy and outreach consulting services',
        saleries_and_bonuos: 'Salaries And Bonuses',
        dataServices: 'Data Services',
        returntodefault:'All Services',
        amendSalariesScale: 'Amend Salaries Scale',
        exceptionOfSteerRetirementRequest: 'Exception Of Steer The Age Of Retirement Request',
        retirementWizard: 'Retirement Wizard',
        userName: 'Victoria Baker',
        userCountry: 'Santa Ana,CA',
        sci: 'Suggestion Complain Information',
        report: 'Report',
        canCloseRequest: 'Can Close Request',
        canViewEmployeeReport:'Can ViewEmployee Report',
        canGenerateAllreports:'Can Generate all Reports',
        canCreateWorkShop:'Create',
        canBookWorkShop:'Book',
        canViewDisciplinaryboard:'View',
        canCreateDisciplinaryboard:'Create',
        canEditDisciplinaryboard:'Edit',
        canAssignDisciplinaryboardToDGoffice:'Assign to GM',
        canAssignDisciplinaryboardtoEmployee:'Assign To Employee',
        canAddReplayDisciplinaryboard:'Add replay',
        canViewOHI:'View',
        reviewHrPolicy: 'Review The HR policies',
        current_status: 'Current Status',
        current_reason: 'Status Reason',
        amendEmployeeSalary: 'Amend Employee Salary',
        approvedScaleOfSalaries: 'Approved Scale Of Salaries',
        chooseFile: 'Choose File',
        acceptedFormatsPDF: 'Accepted Formats: pdf, Size : upto 2mb',
        employeePhoto: 'Employee Photo',
        thisFieldIsRequired: 'This field is required.',
        positionnotfound: 'Please List all not found positions and contact us on technical support E-mail for adding them',

        adminrolesrequired: 'Admin Roles Required',
        functionalrolesrequird: 'Functional Roles Required',
        organizationalrolesrequird: 'Organizational Roles Required',

        PasswordPolicy: 'This Pasword not match secuircty password. Password must continue 3 from this (1 captial letter, 1 small letter,1 special character,1 number). and the password length must be at least 8 charcter.',
        ckeditorCountErr: 'Please enter characters less than or equal to 250.',
        invalidYear: 'Invalid Year.',
        invalidEmail: 'Invalid Email.',
        invalidContactNumber: 'Invalid Contact Number.',
        invalidEmiratesID: 'Invalid Emirates ID.',
        invalidPhoneNumber: 'Invalid Phone Number.',
        invalidMobileNumber: 'Invalid Mobile Number.',
        invalidBonusNumber: 'This field should not be greater than 50.',
        allowedMultipleFile: 'Allowed multiple file upload',
        DateFilteration:'Date Filteration',
        DateFilterationFrom:'From',
        DateFilterationTo:'To',
        DateFilterationBtn:'Filter',
        ClearFilter:'Clear',
        DateFilteration_Empty_error_message:'You must select From and To Date',
        DateFilteration_Wrong_error_message:'To date must be greater than or equal start date',
        allowCkEditorLimit: 'Max character limit is 2000',
        acceptedFormatsImageOnly: 'Accepted Formats: Image Only (jpeg,png,jpg), Size : upto 2mb',
        acceptedFormatsAll: 'Accepted Formats: (pdf,doc,docx,ppt,pptx,xls,xlsx,jpeg,png,txt), Size : upto 2mb',
        acceptedFormatsAllWorkshop:'Accepted Formats: (pdf,doc,docx,ppt,pptx,xls,xlsx,jpeg,png,txt), Size : upto 5mb',
        acceptedFormats_pdf_doc_xlxs: 'Accepted Formats: (pdf,doc,docx,ppt,pptx,xls,xlsx), Size : upto 2mb',
        acceptedFormats_xlsx: 'Accepted Formats: xls,xlsx, Size : upto 2mb',
        acceptedFormatsXML: 'Accepted Formats: xml, Size : upto 2mb',
        ageErr: 'Age should be greater than 18.',
        salaryErr: 'Basic salary should be less than Total Salary',
        currentBasicSalErr: 'Current Basic Salary should be less than Proposed Basic Salary',
        housingAllowanceErr: 'Housing Allowance should be less than Total Salary',
        RetirementContributionsErr: 'Retirement Contribution should be less than total salary',
        monthlyContributionsErr: 'Monthly Contribution should be less than total salary',
        totalCalculationErr: 'Addition of below 4 types should equal to Total Number of Employees',
        totalEmpCulationErr: 'Addition of below 5 types of Employees should equal to Total Number of Employees',
        bonusNote: 'Note : Addition of Excellent, Very Good , Good, Acceptable, Weak Employee should be equal to Total Number of Employees',
        employeeName: 'Employee Name',
        nationality: 'Nationality',
        age: 'Age',
        next: 'Next',
        previous: 'Previous',
        finish: 'Finish',
        currentBasicSalary: 'Current Basic Salary',
        proposedBasicSalary: 'Proposed Basic Salary',
        percentageIncreaseRequiredBasicSalary: 'The Percentage Of Increase Required On The Basic Salary',
        designationDate: 'Designation Date',
        aacademicQualification: 'Aacademic Qualification',
        experienceAfterQualification: 'Experience After Qualification(In Years)',
        totalExperience: 'Total Experience(In Years)',
        employeeEmaritesId: 'Employee Emarites Id',
        previousAndCurrentApprovedCompetencyReport: 'Previous and Current Approved Competency Report',
        annualEvaluationforPreviousYear: 'Annual Evaluation For Previous Year',
        annualEvaluationforCurrentYear: 'Annual Evaluation For Current Year',
        currentJobTitle: 'Current Job Title',
        currentGrade: 'Current Grade',
        jobRank: 'Job Rank',
        procedureType: 'Procedure Type',
        financialRatioOfProcedure: 'Amendment Value',
        procedureDate: 'Procedure Date',
        copyOfLastProcedure: 'Copy Of Last Procedure',
        reasonToModifySalary: 'Reason To Modify Salary',
        baseUsedDetermineProposedIncreaseRate: 'The Bases Used To Determine The Proposed Increase Rate',
        hraEmployeeStudyMaker: 'HRA Employee Study Maker',
        hraStudy: 'HRA Study',
        EntityName: "Entity Name",
        information_req_step_one: 'Information Request Step - 1',
        information_req_step_2: 'Information Request Step - 2',
        contact_number: 'Contact Number',
        requestor_name: 'Requestor Name',
        what_are_the_info_req: 'Request Details',
        who_are_assign_entities: "Assigned Entity",
        next_btn: 'Next',
        attached_files: 'Attached Files',
        choose_file: 'Choose file',
        type_of_request: 'Type Of Request',
        type_of_contract: 'Type Of Contract',
        last_time_to_reply: 'Last Date To Reply',
        target_date: 'Target Date',
        the_required_statements: 'The Required Statements',
        request_new_bonus: 'Request New Bonus',
        human_res_auth: "Human Resource Authority @2019",
        infoRequest: 'Information Request',
        menu1: 'Home',
        menu2: 'About E-Services',
        menu3: 'FAQ',
        FAQ:'Frequently Asked Questions',
        login: 'LogIn',
        serviceTitle1: 'Salaries And Compensation',
        serviceTitle2: 'Human Resources Policies',
        serviceTitle3: 'Organizational Design',
        serviceTitle4: 'Communicate With HRA',
        serviceTitle5: 'Requiring Information',
        serviceText1: 'Services related to allowances and salaries',
        serviceText2: 'Services relating to the laws, regulations and laws in force for human resources',
        serviceText3: 'Services relating to organizational structures',
        serviceText4: 'Services related to inquiries and complaints',
        serviceText5: 'Third party communication services',
        footermenu1: 'Staff of the Human Resources Authority',
        footermenu2: 'Links',
        footermenu3: 'Questions and Answers',
        footermenu4: 'Site Map',
        copyright: 'All Rights Reserved © '+new Date().getFullYear(),
        deleteNode: 'Are you sure you want to delete this node?',
        organizationStructure: 'Organization Structure',
        treeView: 'Tree View',
        exemptionFromAccompanyPatien: 'Exemption From Accompany Patient',
        entityLogo: 'Entity Logo',
        entityName: 'Entity Name',
        firstcordinatorName: 'First Cordinator Name',
        secondcordinatorName: 'Second Cordinator Name',
        firstjobName: 'First Coordinator Job Name',
        secondjobName: 'Second Coordinator Job Name',
        firstcontactNumber: 'First Contact Number',
        secondcontactNumber: 'Second Contact Number',
        contactNumberPhone: 'Contact Number (Phone)',
        firstcontactNumberMobile: 'First Contact Number (Mobile)',
        secondcontactNumberMobile: 'Second Contact Number (Mobile)',
        firstemail: 'First Email',
        secondemail: 'Second Email',
        approvedGradesAndSalaries: 'Approved Grades And Salaries',
        havebudget: 'Do you have a budget for employee bonuses? Please indicate the value of the budget indicated in an official document',
        yes: 'Yes',
        no: 'No',
        enterTheBudget: 'Enter The Budget',
        budgetRelatedDocument: 'Budget Related Document',
        reasonsToPayBonuses: 'Reasons To Pay Bonuses',
        entityAchivementsForYear: 'Entity Achivements For Year',
        enterTheYear: 'Enter The Year',
        balancedScoreCard: 'Balanced Score Card',
        financialStatement: 'Financial Statement',
        taxSummary: 'Tax Summary',
        totalEmployeesOfEntityDetails: 'Total Employees Of Entity Details',
        percentageOfEmariatisation: 'Percentage Of Emariatisation',
        resultsOfEvaluationGeneralPerformance: 'Results of evaluation for general performance for all entity employees (including the employees which request annual bonus for them). Must be approved from Presedent of Entity.',
        privateContractsEmployee: 'For Private Contracts employee which request annual bonuses for them. Do their contracts have fixed value as annual bonus, and if present please upload copy of all their contacts (for all employees which request annual bonuses for them).',
        requestAnnualBonus: 'Enter Request Annual Bonus',
        uploadTheContracts: 'Upload attachment for private Contracts',
        explainTheEquation: 'Explain The Equation For Evaluate The Performance With Details',
        totalNumberOfEmployees: 'Total Number Of Employees',
        numberGredeEmployees: 'No. (Grade) Employees',
        downloadThisExcelFile: 'Please download and fill this excel file and reupload',
        downloadThisExcelFileORG: 'Kindly Download file And Fill All Fields Then Upload It Back',

        clickHereToDownload: 'EmployeeDetails',
        clickHereToDownloadORGOprationalTemplate: 'Download Operational Template',
        clickHereToDownloadORGTasksTemplate: 'Download Tasks And Specifications Template',

        uploadTheExcelAfterFill: 'Upload The Excel After Fill',
        HRAEmployeeStudyMaker: 'HRA Employee Study Maker',
        submit: 'Submit',
        select: '-Select-',
        person: 'Person',
        group: 'Group',
        step1: 'Step 1',
        step2: 'Step 2',
        step3: 'Step 3',
        step4: 'Step 4',
        step5: 'Step 5',
        Enter_the_request: 'Enter The Request',
        approved_scale_of_grades_salaries: 'Approved Scale of Grades & Salaries',
        salaries_compensation_policy: 'Salaries & Compensation Policy',
        justification_of_edit_the_scale: 'Justification Of Edit The Scale',
        principles_used_to_modify_the_scale: 'The Principles Used To Modify The Scale',
        table_of_the_alignment_of_the_grades: 'Table of the alignment of the grades (for civil service)',
        proposed_scale: 'Proposed Scale',
        accepted_formats_pdf: 'Accepted Formats: pdf, Size : upto 2mb',
        accepted_formats_excel: 'Accepted Formats: excel, Size : upto 2mb',
        comparative_study_with_similar_entities: 'Comparative study with similar entities',
        illustrations_current_and_proposed_salary_lines: 'Illustrations of current and proposed salary lines',
        sources_funding_for_proposed_increases: '1. Sources funding for proposed increases',
        sources_funding_for_proposed_increases_one: '2. Determine if there is attested budget',
        sources_funding_for_proposed_increases_two: '3. Add copy for attahcment in next page',
        necessary_financial_allocations: 'Have the necessary financial allocations been made within the approved budget',
        copy_of_the_decision: 'Copy of the decision of the competent administrative authority in this regard',
        details_of_annual_cost: 'Details of annual cost',
        current_basic_salary: 'Current monthly bonus',
        proposed_basic_salary: 'Proposed monthly bonus',
        current_annual_benefits: 'Current annual benefits',
        proposed_annual_benefits: 'Proposed annual benefits',
        current_financial_effect: 'Current financial effect',
        proposed_financial_effect: 'Proposed financial effect',
        other_bonus_name: 'Other Bonus Name',
        other_benefit_name: 'Other Benefit Name',
        Challenges_of_current_salaries: 'Challenges of current salaries scale',
        challenges_of_proposed_salaries: 'Challenges of proposed salaries scale',
        avarage_increase_for_current_salaries: 'Avarage of increase / decrease for current salaries scale',
        avarage_increase_for_proposed_salaries: 'Avarage of increase / decrease for proposed salaries scale',
        proposed_scale_on_current: 'Effect of applying the current scale',
        proposed_scale_on_proposed: 'Effect of applying the proposed scale',
        entity_chairman_approve: 'Entity Chairman approve',
        employee_study_maker: 'Employee Study maker',
        hra_study: 'HRA study',
        reviewHRPoliciesLabel: 'Review The HR policies',
        exceptionADHousing: 'Exception From AD Housing Policy',
        secondmentForFederal: 'Secondment Request',
        exceptionForPatient: 'Exemption From Accompany Patient Outside Of Country',
        exceptionForRetirment: 'Exemption Of Steer The Age Of Retirment',
        bonus_Request: 'Bonus Request',
        reviewHRPoliciesLable: 'Review The HR policies',
        amend_salaries_scale: 'Amend Salaries Scale',
        amend_employe_salary: 'Amend Employee Salary',
        exceptio_from_company_patient: 'Exemption From Accompany Patient',
        reveiew_hr_policies: 'Review The HR policies',

        //Data Services
        extendCreateObjectives: 'Extend Create Objectives',
        extendReviewObjectives: 'Extend Review Objectives',
        extendAnnualPerformanceEvaluationInterval: 'Extend Annual Performance Evaluation Interval',
        approveTheFinalPerformanceEvaluation: 'Approve The Final Performance Evaluation',
        addOrEditEmployeesPerformanceEvaluation: 'Approve / Add/Edit the Employee’s Performance Evaluation',
        joinAndImplementGovernmentResourcesSystemForNewEntity: 'Join and implement government resources system or one of the systems',
        openComplainsStage: 'Open Grievance Process',
        implementOneOfHRPrivateSystems: 'Implement One Of HR Private Systems',
        amendsOnSharedGovernmentHRManagementystem: 'Amends On Shared Government HR Management System',
        ReportsAndStaticRequest: 'Reports And Statics Requests',
        OtherRequests: 'Other Requests',
        //Data Services DashboardTable
        extendCreateObjectivesDesc: '',
        extendReviewObjectivesDesc: '',
        extendAnnualPerformanceEvaluationIntervalDesc: '',
        approveTheFinalPerformanceEvaluationDesc: '',
        addOrEditEmployeesPerformanceEvaluationDesc: '',
        joinAndImplementGovernmentResourcesSystemForNewEntityDesc: '',
        openComplainsStageDesc: '',
        implementOneOfHRPrivateSystemsDesc: '',
        amendsOnSharedGovernmentHRManagementystemDesc: '',
        ReportsAndStaticRequestDesc: '',
        OtherRequestsDesc: '',

        extendCreateObjectivesSpecialCondition: '',
        extendReviewObjectivesSpecialCondition: '',
        extendAnnualPerformanceEvaluationIntervalSpecialCondition: '',
        approveTheFinalPerformanceEvaluationSpecialCondition: '',
        addOrEditEmployeesPerformanceEvaluationSpecialCondition: '',
        joinAndImplementGovernmentResourcesSystemForNewEntitySpecialCondition: '',
        openComplainsStageSpecialCondition: '',
        implementOneOfHRPrivateSystemsSpecialCondition: '',
        amendsOnSharedGovernmentHRManagementystemSpecialCondition: '',
        ReportsAndStaticRequestSpecialCondition: '',
        OtherRequestsSpecialCondition: '',

        extendCreateObjectivesDuration: '',
        extendReviewObjectivesDuration: '',
        extendAnnualPerformanceEvaluationIntervalDuration: '',
        approveTheFinalPerformanceEvaluationDuration: '',
        addOrEditEmployeesPerformanceEvaluationDuration: '',
        joinAndImplementGovernmentResourcesSystemForNewEntityDuration: '',
        openComplainsStageDuration: '',
        implementOneOfHRPrivateSystemsDuration: '',
        amendsOnSharedGovernmentHRManagementystemDuration: '',
        ReportsAndStaticRequestDuration: '',
        OtherRequestsDuration: '',
        // Default for attachemnt dahborad table
        NoAttachmentrequired: 'None',
        EmptySLA: 'Depend on the request',
        excSteer: {
            nationalOrNot: 'National / Non-National',
            exceptionOfSteerTheAgeOfRetirementRequest: 'Exception Of Steer The Age Of Retirement Request',
            jobContract: 'Job Contract',
            reasonForExtendingTheService: 'Reasons For Extending The Service',
            annualPerformanceEvaluation: 'Annual Performance Evaluation',
            employeeName: 'Employee Name',
            employeeNumber: 'Employee Number',
            nationality: 'Nationality',
            dateOfBirth: 'Date Of Birth',
            jobTitle: 'Job Title',
            unifiedNumber: 'Unified Number',
            dateOfSteer: 'Date Of Steer',
            copyLabel: 'Do you have a copy of the previous approvals for extension from the jurisdiction',
            medicalApprovalsFromTheCometentMedicalCommittee: 'Medical Approvals From The Competent Medical Committee',
            passport: 'Passport',
            familyBook: 'Family Book',
            emaritesID: 'Emarites ID',
            annualPerformanceEvaluationattach: 'Annual Performance Evaluation Attach',
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: 'A Copy Of The Previous Approvals For Extension From The Jurisdiction',
            employeeCV: 'Employee CV',
            personalPhoto: 'Personal Photo',
            select: 'Select in ar',
            Dateofhiring: 'Date of hiring',
            CurrentGrade: 'Grade',
            Efficiency: 'Efficiency assessment',
            extendedservice: 'Previously extended service',
            Salary: 'Salary',
            Qualification: 'Qualification',
            GraduationYear: 'Graduation Year',
            DateofretirementAge: 'Date of retirement attainment age',
            TypeofContract: 'Type of Contract',
            Replacementplan: 'Replacement / succession plan',
            Passport: 'Passport',
            Summaryofregistrationforcitizens: '',
            Previousapprovalsfortheextension: '',
            Qualifications: '',
            
            Previousapprovalsfortheextension: '',
            Qualifications: '',
            Jobdescription:'',
            Careersuccessionplan: '',
            HRCom: '',
            OtherAttach: '',
            PoistioninStructure:'',
            prviousextensionwithoutapprovalfrom: '',
            previousextensionwithoutapprovalto: '',
            Previousextionsfrom: '',
            Previousextionsto: '',
            Replacementplanfrom: '',
            Replacementplanto: '',
            Jobdescription: '',
            Careersuccessionplan: '',
            HRCom: '',
            OtherAttach: '',
            prviousextensionwithoutapprovalfrom: '',
            previousextensionwithoutapprovalto: '',
            Previousextionsfrom: '',
            Previousextionsto: '',
            Replacementplanfrom: '',
            Replacementplanto: '',
            
        },
        Disciplinaryboard:
        {
            requesterinfo: 'Requester',
            entityname: 'Entity Name',
            requestername: 'Requester Name',
            phoneNumber: 'Office Number',
            email: 'Email',
            subject: 'Request to form a Disciplinary Board',
            intro: 'Special Conditions',
            requestype: 'Type',
            permanent: 'Permanent',
            temporary: 'Temporary',
            EmployeeInfoSubject: 'Employee Information',
            reason: 'Referral Reason',
            invalidPhoneNumber: 'Invalid Phone Number',
            removeEmployee: '- Remove Employee',
            addemployee: '+ Add Employee',
            Violations: 'Violations',
            investigation: 'Investigation facts and documents',

            memberslist: 'List of candidates for membership of the Disciplinary Board',
            membername: 'Member Name',
            title: 'Board Title',
            jobdegree: 'Functional class',
            workentity: 'Entity',
            addnewmember: '+ Add member',
            removemember: 'Remove member',
            formalrequestletter: 'The request to form a disciplinary board approved by the head of the agency',
            EmployeeNameErr: "employee's name must be entered",
            EmployeeJobTitleErr: 'Employee Job Title must be entered',
            EmpolyeeJobDegreeErr: 'Employee Job Degree must be entered',
            EmployeePhoneNumberErr: 'Employee Phone Number must be entered',
            EmployeeViloationErr: 'Violations Documents must be attached',
            EmployeeinvestigationErr: 'Investigation Documents must be attached',
            EmployeeReasonErr: 'Reason must be entered',
            MembernameErr: 'Member Name must be entered',
            jobdegreeErr: 'Member job degree must be entered',
            EntityErr: 'Entity must be selected',
            MembertitleErr: 'Member Board title must be entered',
            RequestAttach: 'Attachments',
            RequestDetails: 'Request Details',
            Requestnumber: 'Request Numbber',
            memberslengtherror: 'The number of members of the Disciplinary Board, including the Chairman of the Disciplinary Board, must not be less than three members of the first degree or more, and their number should be odd.',
            isinverstagiondone: 'Has an investigation committee been formed?',
            Yes: 'Yes',
            No: 'No',
            isinverstagiondoneErr: 'An investigation committee must be formed first',
            boradmember: 'Member',
            boardBoss: 'President',
            EntityErrChriman: 'It should be noted that the Chairman of the Disciplinary Council is not from the same government agency requesting the formation of the Council.',
            dublicateMembertitleErr:
                "The council cannot have more than one president.",
            BoardMemberJobTitle: 'Job title',
            MemberJobTitleErr: 'Job title must be entered',

        },
        Promotion:
        {
            subject: 'Exclusion of the qualification requirement for promotion or appointment',
            Description: "An exception to the qualification requirement when appointing / promoting a government employee as stipulated in the law and executive regulations.",
            Condtion: 'The government agency provides the CV, along with all the documents required by the authority for promotion or appointment. The researcher concerned with the Human Resources Authority checks the data contained in the letter of the government agency concerned, and then prepares a comprehensive study to submit to the Abu Dhabi Executive Office after taking the credits from the director of the department and sector and the general manager and the final approval of the president of the authority. After issuing the decision of the Executive Council, as appropriate, a response will be made to the government agency concerned with the content of the decision, with or without approval of the request.',
            DurationOfService: '10 working days',
            requestype: 'Request Type',
            hiring: 'Hiring',
            promotion: 'Promotion',
            jobDetails: 'Job Details',
            jobTybe: 'Job type',
            managmentjob: 'Administrative',
            technicianjob: 'Artistic',
            Reason: 'Justification for the exception request',
            EmployeeCV: 'CV',
            EmployeePhoto: 'Photo',
            positionofthenominatedposition: 'The position of the nominated position from the approved organizational structure',
            jobdescription: 'Approved job description for the job with technical or administrative job determination',
            HumanResourcesCommittee: 'Copy of the Approved Human Resources Committee minutes',
            EmployeeNominationForm: 'Employee Nomination Form',
            EducationalQualifications: 'Transcript of Qualifications',
            ExperienceCertificates: 'Copy of experience certificates',
            competencyreports: 'Copy of Employee competency report - for the last two years',
            passport: 'Passport',
            Summaryofregistrationforcitizens: "Citizen's record",
            IDcard: 'ID card',
            CID: 'Criminal Investigation',
            Medicalfitnesscheckup: 'Medical fitness check-up',
            Anotheremployeesselfstatement: 'Last employee self-statement',

        },
        SickLeave:
        {
            subject: 'Exception from employee salary deduction for over sick leave',


            LeaveSubject: 'Leave Details',
            YearlyLeaveBalance: 'Employee Leave Annual Balance',
            LeavePeriod: 'sick time period',
            BacktoWorkDate: 'Date of commencing work after returning from sick leave',
            LeveSalryDetails: 'Salary Disbursement Status',
            DescripeIssue: 'Monitoring violations regarding administrative procedures',
            EmployeePassport: "passport",
            EmployeeEID: "Emirates ID",
            SickLeaveHealthreport: "Medical Report from the Competent Committee",
            SickLeaveEmbassyLetter: "Letter of delegation or attachment to the Embassy of the United Arab Emirates in the country of treatment",
            SickLeaveHRCom: "A copy of the minutes of the Human Resources Committee approved by the agency’s president or his authorized representative",
            EmployeeIncome: "supporting documents for the employee's financial obligations or in the event that the employee is unable to fulfill the financial obligations",
        },

        WorkShop:
        {
            Attendancename:'Name',
AttendanceJobTitle:'Job Title',
AttendanceJobDegree:'Job Degree',
AttendanceEmail:'Email',
AttendancePhone:'Phone Number',
            AtteandanceDetails:'Atteandance Information',
            RequireAddAttandanceDetails:'Require Add Attandance Details',
            cancelBookingMessageSucess:'Your workhop booking has been canceled sucessfully.',
            workshopBookEdit:'Change your Booking',
            cancelBooking:'Cancel Booking',
            BookWorkShop: 'Reservation',
            subject: 'Awareness Workshops',
WorkShopInfo: 'Workshop Data',
Title: 'Workshop Title',
DiscussPoints: 'The points to be discussed in the Workshop',
Presentation: 'Presentation',
WorkShopTimes: 'Workshop Timings',
AttandanceNumbeRemaing: 'Number of Attendees - Vacant',
AttandanceNumber: 'Attendance Number',
AttandanceNumberBooked: 'Attendee - Reserved',
WorkShopDate: 'Workshop Date',
WorkShopTimefrom: 'start of workshop',
WorkShopTimeTo: 'End of Workshop',
Location: 'The meeting place',
AddNewDate: 'Add a new appointment',
Submit: 'Save workshop',
Cancel: 'Cancel',
Edit: 'Edit',
WorkShopTime: 'Workshop Schedule',
ViewDates: 'Workshop dates',
userinformation: 'applicant data',
EntitName: 'entity name',
Person: 'Name of the responsible person',
PhoneNumber: 'Phone number',
Email: 'Email',
invalidAttandanceNumberErr: 'Number of attendees is greater than remaining number, please note that the remaining number is',
MaxAllowedAttMessage: 'The reaming Number of attendees is',
cantbookedzeroErr:"The attendance number must be at least one person",
WorkShopAdd: 'Add new workshop',
WorkShopBookedList: 'existing reservations',
WorkShopManage: 'Workshop List',

WorkShopBooking: 'Book an Outreach Workshop',
WorkShopBookinglist: 'Workshop List',
WorkShopMyBookedList: 'My Appointments',
WorkShopADS:'ADS',

        },
                Common: {
                    EmployeeInfoSubject: 'Employee details',
                    EmployeeInfoJobTitle: 'Job title',
                    EmployeeInfoJobDegree: 'Job Degree',
                    // EmployeeInfoTotalSalary: 'Gross Salary',
                    // EmployeeInfoBasicSalary: 'Basic Salary',
                    // EmployeeInfoRetirementcontributions: 'Retirement Subscriptions',
        
                    EmployeeInfoTotalSalary: 'Job location of the candidate from the approved organizational structure',
                    EmployeeInfoBasicSalary: 'Basic Salary',
                    EmployeeInfoRetirementcontributions: 'Retirement Subscriptions',
        
                    EmployeeInfoEmployeeName: 'Employee Name',
                    EmployeeInfoEmirateID: 'Identity Number',
                    EmployeeInfoEmployeeNumber: 'Job Number',
                    EmployeeInfoEmployeeUID: 'Employee Unified Number',
                    // SalaryInfo: 'Salary Details',
                    SalaryInfo: 'Job Details',
        
                   
                },
        orgStructure: {
            expandAll: 'Expand All',
            collapseAll: 'Collapse All',
            search: 'Search',
            previous: 'Previous',
            next: 'Next',
            info: 'Info',
            edit: 'Edit',
            addUnit: 'Add Unit',
            addEmployee: 'Add Employee',
            delete: 'Delete',
            addNode: 'Add Node',
            updateNode: 'Update Node',
            treeView: 'Tree View',
            parent: 'Parent',
            englishName: 'English Name',
            arabicName: 'Arabic Name',
            close: 'Close',
            type: 'Type',
        },

        adminPanelKeys: {
            title: "Title",
            permission: "Permission",
            englishName: "English Name",
            arabicName: "Arabic Name",
            action: 'Action',
            shortName: "Short Name",
            subject: 'Subject',
            isMotherComp: "Is Mother Company",
            employeeNumber: "Employee Number",
            sector: "Sector",
            clientDesc: "Client Description",
            city: "City",
            industry: "Industry",
            isActive: "Is Active",
            isDefault: 'Is Default',
            reasonToDeative: "Reason To Deative",
            phnNumber: "Phone Number",
            userName: "User Name",
            email: "Email",
            mobile: "Mobile",
            designation: "Designation",
            accType: "Account Type",
            role: "Role",
            status: "Status",
            entity: "Entity",
            reason: "Reason",
            createdOn: "Created On",
            modifiedOn: "Modified On",
            roleList: "Role List",
            lookupList: "Look Up List",
            contactList: "Contact List",
            entityList: "Entity List",
            mailTemplateList: "Mail Template List",
            createContact: "Create Contact",
            createEntity: "Create Entity",
            createLookup: "Create Lookup",
            createMailTemplate: "Create Email Template",
            createRole: "Create Role",
            addEmailTemplate: "Add Email Template",
            EnglishTitle: 'English Title',
            ArabicTitle: 'Arabic Title',
            for: 'For'
        },

        ReviewHRPolicies: {
            ReasonsOfRequest: 'Reasons Of Request',
            Entity_formal_request: 'Entity Formal Request',
            standard_comparisons: 'Standard Comparisons',
            studies: 'Studies',
            HRA_Employee_study_maker: 'HRA Employee Study Maker',
            HRA_Study: 'HRA Study',
        },

        HRAPolicies: {
            ReasonsOfRequestHousing: 'Reasons Of Request',
            ReasonsOfRequest: 'Reasons Of Request',
            Entity_formal_request: 'Entity Formal Request',
            support_request: 'Attached Files For Support Request',
            Housing_allowance: 'Housing Allowance',
            Employee_number: 'Employee Number',
            Employee_name: 'Employee Name',
            Basic_salary: 'Basic Salary',
            Entity_name: 'Entity Name',
            Job_name: 'Job Name',
            Total_salary: 'Total Salary',
            Retirement_Contributions: 'Retirement Contributions',
            HRA_study: 'HRA Study',
            HRA_Employee_study_maker: 'HRA Employee Study Maker',
            Financial_cost: 'Financial Cost',
            file_required: 'This field is required'
        },
        NotAvibalelbl: "",
        secondement_For_Federal_Agency_Step: {
            reasonForGovPaySalary:'Reason to Pay Salary',
            BorrowEntityID:'Borrow Entity',
            RequestByTheCompetentEntityWithPayEmployeeSalary: 'Request by the competent entity with determine who will be responsible to pay employee salary during this period',
            fromDateLessThanToDate: 'From date must be less than to date',
            unifiedNumber: 'Unified Number',
            familyNumber: 'Family Number',
            secondement_For_Federal_Agency_Step_1: 'Secondement Request Step-1',
            secondement_For_Federal_Agency_Step_2: 'Secondement Request Step-2',
            secondement_For_Federal_Agency_Step_3: 'Secondement Request Step-3',
            secondement_For_Federal_Agency_Step_4: 'Secondement Request Step-4',
            Employee_Name: 'Employee Name',
            Entity_Name: 'Entity Name',
            Job_Name: "Job Name",
            Employee_Number: 'Employee Number',
            Total_Salary: 'Total Salary',
            Basic_Salary: 'Basic Salary',
            Retirement_Contributions: 'Retirement Contributions',
            Request_of_The_Borrowing_Entity: 'Request Of The Borrowing Entity',
            Request_By_The_Competent_Entity: 'Request By The Competent Entity',
            Period_of_Secondment: 'Period of Secondment',
            The_entity_which_responsible_for_salary: 'The Entity Which Responsible For Salary',
            Job_Description: 'Job Description',
            CurrentJobDesc:'Current Job Describtion',
            Approval_Of_Employee: 'Approval Of Employee',
            Copy_Of_HR_Committe: 'Copy Of HR Committe',
            goverment: 'Goverment',
            competent: 'Competent',
            Copy_of_last_year_eff_report: 'Copy Of Last Year Efficiency Report',
            Emairates_Id: 'Emarites Id',
            Passport_copy: 'Passport Copy',
            Family_book: 'Family Book',
            HRA_Employee_Study_Maker: 'HRA Employee Study Maker',
            HRA_Study: 'HRA Study',
            select_hra_study: 'Select HRA Study',
            empPassNoticePeriod: 'Employess Should Pass Probation Period',
            lastYearPerformanceEfficiency: 'Last Year Performance Degree More Than Weak',
            employeeAgreement: 'Employee Agreement'
        },

        update_status: {
            //Update_Status: 'Update Status',
            Update_Status: 'Submit',
            Update: 'Update',
            Close: 'Close',
            deleteRecord: 'Delete Record',
            editRecord: 'Edit Record',
            viewRecord: 'View Record'
        },

        landing_page: {
            SCI: 'SCI',
            exception_from_accompany_Patient: 'Exemption From Accompany Patient',
            request_Information: 'Request Information',
            review_The_HR_Policies: 'Review The HR policies',
            Exception_From_AD_Housing_Policy: 'Exception From AD Housing Policy',
            Secondment_For_Federal_Agency: 'Secondment For A Federal Agency',
            bonus_Request: 'Bonus Request',
            Amend_Salaries_Scale: 'Amend Salaries Scale',
            Amend_Employee_Salary: 'Amend Employee Salary',
            View_More: 'View More'
        },

        ExemptionFromAccompanyPatient: {
            Degreeofrelationship:'Degree of Relationship',
            ExemptionFromAccompanyPatientHeading: 'Exemption From Accompany Patient',
            step1: 'Exemption From Accompany Patient - Step 1',
            step2: 'Exemption From Accompany Patient - Step 2',
            step3: 'Exemption From Accompany Patient - Step 3',
            step4: 'Exemption From Accompany Patient - Step 4',
            step5: 'Exemption From Accompany Patient - Step 5',
            step6: 'Exemption From Accompany Patient - Step 6',
            Detailedsalarycertificate: 'Detailed salary certificate',
            emiratedID: 'Emirated ID',
            AcceptedFormat: 'Accepted Formats: pdf ,Size : upto 2mb',
            empJob: 'Employee Job',
            basicSalary: 'Basic Salary',
            grade: 'Grade',
            totalSalary: 'Total Salary',
            retirementContribution: 'Retirement Contributions',
            passportCopy: 'Passport Copy',
            medicalReport: 'The Medical Report',
            letterFromEmbassyFile: 'A Letter From The Embassy',
            copyOftheDigest: 'Copy Of The Digest Or Proof Of Kindship',
            durationOfAccompanyingLeave: 'Duration Of Accompanying Leave(In Days)',
            dateOfStartingWork: 'Date Of Starting Work',
            copyOfHumanResoourcesCommittee: 'Copy Of Human Resoources Committee Record',
            dateOfSalaryTermination: 'Date Of Salary Termination',
            documentationOfFinancialObligations: 'Documentation Of Financial Obligations',
            requiredToPayBudget: 'Required To Pay Additional Budget',
            budgetYear: 'Enter The Budget\'s Year',
            budgetValue: 'Budget\'s Value',
            annualBalanceOfVacations: 'The Annual Balance Of Vacations',
            justificationsEmployee: 'Justifications Employee To Accompany A Patient',
            husbandWifeWork: 'Husband / Wife Work',
            noOfChildren: 'Number Of Children',
            placeOfChildren: 'Place Of Children',
            irregularitiesTable: 'The Irregularities In The Administrative Procedures',
            year: 'Year',
            salaryStatus: 'Salary Status',
            month: 'Month',
            noOfDays: 'Number Of Days',
            totalSalaryDollar: 'Total Salary',
            basicSalaryTb: 'Basic Salary',
            totalDays: 'Total Days',
            totalSalaryPayed: 'Total Salary To Be Payed',
            totalAmountExempted: 'Total Amount To Be Exempted From Employee',
            HowToCalculate: 'How To Calculate',
            HRAEmployeeStudyMaker: 'HRA Employee Study maker',
            Person: 'Person',
            Group: 'Group',
            hraStudy: 'HRA Study',
            select: 'Select',
            dateOfDirectWork: 'The Date Of Direct Work',
            headlinesOfRequest: 'Request Title',
            employeeName: 'Employee Name',
            unifiedNumber: 'Unified Number',
            budgetAvailability: 'Budget Availability',
            violationLabel: 'Violation in administrative procedures in the case of continuing monthly salaries after legal period and relationship in accordance with the Human Resources Law',
            explain: 'Explain',
            noOfChildShouldBeLesThan20: "Number of Children Should be less than 20",
            employeeSalaryInformation: "Employee Salary Information",
            intervals: "Intervals",
            totalSalaryWithoutSubscription: "Total Salary Without Subscription",
            retirementSalary: "Monthly Contributions",
            total: "Total",
            from: "From",
            to: "To",
            subTotal: "Sub Total",
            invalidYear: "Invalid Year",
            invalidDay: "Invalid Day",
            pleaseEnterYearFirst: "Please enter year first"

        },

        SCI: {
            sci: 'SCI',
            requestType: 'Request Type',
            requestSubject: 'Request Subject',
            bonusRequestService: 'Bonus Request Service',
            entityLogo: 'Entity Logo',
            entityName: 'Entity Name',
            coordinatorName: 'Coordinator Name',
            jobName: 'Job Name',
            contactNumber: 'Contact Number',
            email: 'Email',
            approvedGradesandSalaries: 'Approved Grades And Salaries',
            doyouhaveabudget: 'Do you have a budget for employee bonuses? Please indicate the value of the budget indicated in an official document',
            yes: 'Yes',
            no: 'No',
            reasonsToPayBonuses: 'Reasons To Pay Bonuses',
            entityAttachmentsForYear: 'Entity Attachments For Year',
            enterTheYear: 'Enter The Year',
            balancedScoreCard: 'Balanced Score Card',
            financialStatement: 'Financial Statement',
            taxSummary: 'Tax Summary',
            previous: 'Previous',
            next: 'Next',
            totalEmployeesOfEntity: 'Total Employees Of Entity',
            pleaseFillAllTheDetails: 'Please fill all the details',
            enterTheTotalNumberOfEmployees: 'Enter The Total Number Of Employees',
            enterTotalNumberOfLocalsWithPrivateContract: 'Enter Total Number Of Locals With Private Contract',
            enterTotalNumberOfLocalsWithPermanentContract: 'Enter Total Number Of Locals With Permanent Contract',
            enterTotalNumberOfNonLocalsWithPrivateContract: 'Enter Total Number Of Non Locals With Private Contract',
            enterTotalNumberOfNonLocalsWithPermanentContract: 'Enter Total Number Of Non Locals With Permanent Contract',
            percentageOfEmiratisation: 'Percentage Of Emiratisation',
            suggestion: 'Suggestion',
            SuggestionGeneral: 'General',
            SuggestionPolicy: 'Legal',
            workforce:'Handbook of strategic planning for the workforce',
            complain: 'Complain',
            inquiry: 'Inquiry',
            EntityLegalOpinonDetials:"(For the purposes of organizing inquiries related to human resources policies, and the participation of specialists to express their opinion on the topics being inquired about, please indicate the opinion of the Legal Department or its equivalent in your authority with the inquiry sent to the Authority.)",
            EntityLegalOpinon:"Legal Opinion",
            requestDetails: 'Request Details',
            attachments: 'Attachments (If Present)',
            applicatorsName: 'Applicators Name',
            applicatorsJob: 'Applicators Job',
            hRAConcerenedSector: 'HRA-Concerened Sector',
            addNotes: 'Add Notes',
            transferToAssignedSector: 'Transfer To Assigned Sector',
            sendFeedback: 'Send Feedback',
            finish: 'Finish',
            submit: 'Submit',
            clear: 'Clear',
            chooseFile: 'Choose File',
            enquiry: 'Enquiry',
            attachedFiles: 'Attached Files',
            dateSubmitted: 'Data Submitted',
        }
//Trasol
,Trasol:'Tarasol'
        // organization structure 
        , OrganizationStructureCompleted: "Structure Completed",
        OpenCreationStage: "Open Creation Stage",
        OpenModificationStage: "Open Modification Stage",
        organizationStructure: 'Organization Structure',
        updateOrganizationStructure: ' Update Organization Structure',
        organizationStructureTree: 'Organization Structure Tree',
        organizationStructureTreePreview: 'Organization Structure Tree Preview',
        treeView: 'Tree View',
        orgStructure: {
            expandAll: 'Expand All',
            collapseAll: 'Collapse All',
            search: 'Search',
            previous: 'Previous',
            next: 'Next',
            info: 'Info',
            edit: 'Edit',
            addUnit: 'Add Unit',
            addEmployee: 'Add Employee',
            delete: 'Delete',
            addNode: 'Add Node',
            updateNode: 'Update Node',
            treeView: 'Tree View',
            parent: 'Parent',
            englishName: 'English Name',
            arabicName: 'Arabic Name',
            close: 'Close',
            type: 'Type',
            level: 'Level',
        }
        ,
        viewComponent: {
            EntityLegalOpinon:'Legal Opinion',
            Requestnumber:'Request Number',
            Requestreason:'Request Reason',
            Degreeofrelationship:'Degree of Relationship',
            ORG_Request: 'ORG Request',
            canSubmitORGRequest: "Submit ORG Request",
            canViewORGRequest: "view ORG Request",
            canOpenCreationStage: "Open Creation Stage",
            canEditORGRequest: "Edit ORG Request",
            canUpdateORGStatus: 'Update ORG Status',
            canAddORGComment: 'Add ORG Comment',
            canshowORGcomments: "Can Show ORG comment",
            canAssignORGDepartment: "Assign ORG Department",
            canAssignORGEmployee: "Assign ORG Employee",
            canProvideORGFeddback: 'Provide ORG Feedback',
            canViewORGFeddback: 'View ORG Feedback',
            canassignORGsector: "Assign ORG Sector",
            canassignORGGM: "Assign ORG GM",
            canassignORGBackToEntity: "Assign ORG To Entity",
            canviewtree: "View ORG Tree Only",
            Subject: 'subject',
            Entityname: 'Entity Name',
            Entityphonenumber: 'Entity PhoneNumber',
            Requestername: 'Requester Name',
            RequesterEmail: 'Requester Email',
            Requesterphonenumber: 'Requester Office Number',
            Requestermobilenumber: 'Requester Mobile Number',
            Requesterjob: "Applicant Job",

            employename: 'Employee Name',
            employeeage: 'Employee Age',
            employeename: "Employee Name",
            dob: 'Date of Birth',
            jobtitle: "Job Title",
            emiratesid: 'Emirates Id',
            familynumber: 'Family Number',
            // retirementcontribution:'retirementcontribution',
            unifiednumber: 'Unified Number',
            Dateofhiring: 'Date of hiring',
            CurrentGrade: 'Grade',
            Efficiency: 'Efficiency assessment',
            extendedservice: 'Previously extended service',
            Salary: 'Salary',
            Qualification: 'Qualification',
            GraduationYear: 'Graduation Year',
            DateofretirementAge: 'Date of retirement attainment age',
            TypeofContract: 'Type of Contract',
            Replacementplan: 'Replacement / succession plan',
            Passport: 'Passport',
            Summaryofregistrationforcitizens: '',
            Previousapprovalsfortheextension: '',
            Qualifications: '',
            
            Previousapprovalsfortheextension: '',
            Qualifications: '',
            Jobdescription: '',
            Careersuccessionplan: '',
            HRCom: '',
            OtherAttach: '',
            PoistioninStructure:'',
            prviousextensionwithoutapprovalfrom: '',
            previousextensionwithoutapprovalto: '',
            Previousextionsfrom: '',
            Previousextionsto: '',
           
            Jobdescription: '',
            Careersuccessionplan: '',
            HRCom: '',
            OtherAttach: '',
            prviousextensionwithoutapprovalfrom: '',
            previousextensionwithoutapprovalto: '',
            Previousextionsfrom: '',
            Previousextionsto: '',
            ReplacementPlanFrom: '',
            ReplacementPlanTo: '',
            grade: "Grade",
            steerfrom: 'Steer From',
            steerto: 'Steer To',
            dob: 'DOB',
            contactnameen: 'Contact Name',
            budgetapprovalamount: 'Budget Approval Amount',
            subjobname: 'Second Job Name',
            subcoordinatorname: 'Second Cooridnator Name',
            subemail: 'Second Email',
            subcontactnumber: 'Second Contact Number',
            subphonenumber: 'Second Phone Number',
            no: '#',
            reason: 'Reason',
            step: 'Step',
            action: 'Action',
            detail: "Detail",
            attachments: "Attachments",
            id: 'ID',
            statusHistory: "Status History",
            Requestnumber: 'Request Number',
            createdon: 'Created On',
            createdby: "Created By",
            modifiedon: "Modified On",
            modifiedby: "Modified By",
            status: "Status",
            statusid: "Status ID",
            statusreason: "Status Reason",
            statusreasonid: "Status Reason ID",
            requesttypeid: "Request Type ID",
            requesttype: "Request Type",
            // subject: "Subject",
            details: "Details",
            applicantname: "Applicant Name",
            sciapplicantjob: "Applicant Job",
            applicantjob: "Applicant Job",
            applicantcontactnumber: "Applicant Contact Number",
            contactnumber: "Contact number",
            datettoreply: "Last Time To Reply",
            informationrequired: "Required Information",
            assignedentityname: "Assign Entity Name",
            requesttypename: "Request Type",
            // assigeddepartmentid: "Assiged Department ID",
            // assigeddepartmentname: "Assiged Department Name",
            // assigedemployeeid: "Assiged Employee ID",
            // assigedemployeename: "Assiged Employee Name",
            history: "History",

            //Review HR policy
            laststatusdate: "Last Status Date",
            comments: "Comments",
            entityID: "Entity ID",

            entitynameen: "Entity Name",
            entitynamear: "Entity Name Arebic",
            contactID: "Contact ID",
            contactnameen: "Contact Name",
            contactnamear: "Contact Name",
            requestreason: "Request Reason",
            // studymakerid: "Study Maker ID",
            // studymaker: "Study Maker",

            //Exception from AD Housing Policy
            contracttype: 'Type Of Contract',
            financialcost: "Financial Cost",
            employeenumber: "Employee Number",
            housingallowance: "Housing Allowance",

            basicsalary: "Basic Salary",
            jobname: "Job Name",
            totalsalary: "Total Salary",
            retirementcontribution: "Retirement Contribution",

            contractperiod: 'Employee Contract Period',

            //Secondment for Federal Agency
            secondmententityname: "Secondment Entity Name",
            secondmentfrom: "Secondment From",
            secondmentto: "Secondment To",
            salaryownerid: "The Entity Which Responsible For Salary",
            reasonForGovPaySalary:'Reason to Goverment pay employee salary',
            BorrowEntityID:'Borrow Entity',
            employeeagreement: "Employee Agreement",
            passtestperiod: "Pass Probation Period",
            performancedegree: "Last Year Performance Degree",
            // salaryowner: "Salary Owner",

            //Exemption Patient

            durationofleave: "Duration Of Leave",
            dateofwork: "Date Of Work",
            dateofsalarytermination: "Date Of Salary Termination",
            isdocumentofobligatory: "Budget Availability",
            isrequriedtopaybybudget: "Required To Pay Additional Budget",
            isviolation: "Violation in administrative procedures",
            budgetyear: "Budget Year",
            budgetvalue: "Budget Value",
            annualleavebalance: "Annual Leave Balance",
            justification: "Justification",
            husbandwifework: "Husband Wife Work",
            numberofchidren: "Number Of Chidren",
            placeofchildren: "Place Of Children",
            workyear: "Work Year",
            worksalarystatus: "Work Salary Status",
            workmonth: "Work Month",
            worktotalsalary: "Work Total Salary",
            worknoofdays: "Work No Of Days",
            workbasicsalary: "Work Basic Salary",
            worktotaldays: "Work Total Days",
            // worktotalsalarytobepaid: "Work Total Salary To Be Paid",
            worktobeexempted: "Work To Be Exempted",
            // howtocalculateid: "How To Calculate ID",
            // howtocalculate: "How To Calculate",
            howtocalculaterule: "How To Calculate Rule",
            violationdetail: "Violation Details",
            requesttypetitle: "Request Type",
            budgetavailableyear: "Budget Available Year",

            //Bonus Request
            isbudgeted: 'Is Budgeted',
            email: 'Email',
            coordinatorname: 'Cordinator Name',
            budget: 'Budget',
            yearachievment: 'Entity Achivement For Year',
            bonusreason: 'Reason To Pay Bonuses',
            year1: 'Year 1',
            emiratizationpercentage: 'Emiratization Percentage One',
            year2: 'Year 2',
            emiratizationpercentage2: 'Emiratization Percentage Two',
            totalemployeedetails: 'Total Employee Details',
            iscontracted: 'Is Contracted',
            totalemployees: 'Total Employees',
            nonlocalwithprivate: 'Non-local With Private Contract',
            localwithprivate: 'Local With Private Contract',
            nonlocalwithpermanent: 'Non-local With Permanent',
            localwithpermanent: 'Local With Permanent',
            excellentemployees: 'Excellent Employee',
            verygoodemployees: 'Very Good Employee',
            goodemployees: 'Good Employee',
            acceptableemployees: 'Acceptable Employee',
            equation: 'Explain The Equation For Evaluate The Performance With Details',
            gradeemployees: 'Grade Employees',

            //Ammend Employee salary
            nationality: "Nationality",
            age: "Age",
            currentbasicsalary: "Current Basic Salary",
            proposedbasicsalary: "Proposed Basic Salary",
            percentageincrease: "Percentage Increase",
            designationdate: "Designation Date",
            qualification: "Qualification",
            exprienceafterqualification: "Exprience After Qualification",
            totalexpeirence: "Total Expeirence",
            annualprevevaluation: "Annual Previous Evaluation",
            annualcurrevaluation: "Annual Current Evaluation",

            jobrank: "Job Rank",
            // proceduretype: "Procedure Type",
            financialratio: "Financial Ratio",
            // proceduredate: "Procedure Date",
            reasontomodify: "Reason To Modify",
            baseofincrease: "Base Of Increase",

            //Ammend salary scale
            principle: "Principle",
            fundingid: "Funding ID",
            isallocated: "Is Allocated",
            currentannualbenefits: "Current Annual Benefits",
            proposedannualbenefits: "Proposed Annual Benefits",
            currentfinancialeffect: "Current Financial Effect",
            proposedfinancialeffect: "Proposed Financial Effect",
            currentscalechallenge: "Current Scale Challenge",
            proposedscalechallenge: "Proposed Scale Challenge",
            currentincreaeaverage: "Current Increase Average",
            currentdecreaseaverage: "Current Decrease Average",
            currentapplyeffect: "Current Apply Effect",
            proposedapplyeffect: "Proposed Apply Effect",

            // retirment 
            reasonforextending: 'Reason For Extending',
            isjurisdiction: "Copy Of The Previous Approvals",
            // EnglishTitle: 'العنوان باللغة بالانجليزية',
            // ArabicTitle: 'العنوان باللغة العربية',

            //contacts
            englishName: "English Name",
            arabicName: "Arabic Name",
            phonenumber: "Phone Number",
            username: "User Name",
            emailAddress: "Email",
            mobilenumber: "Mobile",
            designation: "Designation",
            accountType: "Account Type",
            role: "Role",

            //entities
            shortName: 'Short Name',
            empNumber: 'Employee Number',
            isMotherComp: 'Is Mother Company',
            industry: 'Industry',
            city: 'City',
            logo: 'Logo',
            clientDescr: 'Client Description',
            isActive: 'Is Active',
            isDefault: 'Is Default',
            reasonToDeative: 'Reason To Deactive',
            phnNumber: 'Phone Number',
            sector: 'Sector',

            //Roles
            EnglishTitle: 'English Title',
            ArabicTitle: 'Arabic Title',

            canassignscigm: "Assign To GM Office",
            canassignscibacktoentity: "Assign Back To Entity",
            cansubmitscirequest: "Submit SCI Request",
            canreviewscirequest: "View SCI Request",
            caneditscirequest: "Edit SCI Request",
            canupdatescistatus: 'Update SCI Status',
            canaddscicomment: 'Add SCI Comment',
            canassignscidepartment: "Assign SCI Department",
            canasignsciemployee: "Assign SCI Employee",
            canprovidescifeedback: 'Provide SCI Feedback',
            canviewscifeedback: 'View SCI Feedback',
            canassignscisector: "Assign SCI Sector",
            canshowscicomments: "Show SCI Comment",

            canassignirgm: "Assign To GM Office",
            canassignirbacktoentity: "Assign Back To Entity",
            cansubmitirrequest: 'Submit Information Request',
            canviewirrequest: "View Information Request",
            caneditirrequest: "Edit Information Request",
            canupdateirstatus: "Update Information Request Status",
            canaddircomments: "Add Information Request Comment",
            canassignirdepartment: "Assign Information Request Department",
            canassignirsector: "Assign Information Request Sector",
            canshowircomments: "Show Information Request Comments ",
            canreceiveirfeedback: "Receive Information Request Feedback",
            canprovideirfeedback: "Provide Information Request Feedback",

            canassignpatientgm: "Assign To GM Office",
            canassignpatientbacktoentity: "Assign Back To Entity",
            cansubmitpatientrequest: "Submit Exemption From Acompany Patient Request",
            canviewpatientrequest: "View Exemption From Acompany Patient Request ",
            caneditpatientrequest: "Edit Exemption From Acompany Patient Request",
            canupdatepatientstatus: "Update Exemption From Acompany Patient Status",
            canaddpatientcomments: "Add Exemption From Acompany Patient Comment",
            canshowpatientcomments: "Show Exemption From Acompany Patient Comment",
            canassignpatientdepartment: "Assign Exemption From Acompany Patient Department",
            canassignpatientemployee: "Assign Exemption From Acompany Patient Employee",
            canassignpatientsector: "Assign Exemption From Acompany Patient Sector",
            canprovidepatientstudy: "Provide Exemption From Acompany Patient Study",
            canreceivepatientstudy: "Receives Exemption From Acompany Patient Study",

            canassignhrgm: "Assign To GM Office",
            canassignhrbacktoentity: "Assign Back To Entity",
            cansubmitreviewhrrequest: "Submit Review Hr Policies Request",
            canviewreviewhrrequest: "View Review Hr Policies Request",
            caneditreviewhrrequest: "Edit Review Hr Policies Request",
            canupdatereviewhrstatus: "Update Review Hr Policies Status",
            canaddreviewhrcomments: "Add Review Hr Policies Comment",
            canshowreviewhrcomments: "Show Review Hr Policies Comment",
            canassignhrsector: "Assign Policies Sector",
            canassignhrdepartment: "Assign Review Hr Policies Department",
            canassignhremployee: "Assign Review Hr Policies Employee",
            canprovidehrstudy: "Provide Review Hr Policies Study",
            canreceivehrstudy: "Receives Review Hr Policies Study",

            canassignhousinggm: "Assign To GM Office",
            canassignhousingbacktoentity: "Assign Back To Entity",
            cansubmitadhousingrequest: "Submit Exemption AD Housing Policy Request",
            canviewadhousingrequest: "View Exemption AD Housing Policy Request",
            caneditadhousingrequest: "Edit Exemption AD Housing Policy Request",
            canupdateadhousingstatus: "Update Exemption AD Housing Policy Status",
            canaddadhousingcomments: "Add Exemption AD Housing Policy Comment",
            canshowadhousingcomments: "Show Exemption AD Housing Comment",
            canassignhousingdepartment: "Assign Exemption AD Housing Department",
            canassignhousingemployee: "Assign Exemption AD Housing Employee",
            canassignhousingsector: "Assign Exemption AD Housing Sector",
            canprovidehousingstudy: "Provide Exemption AD Housing Study",
            canreceivehousingstudy: "Receives Exemption AD Housing Study",

            canassignsecondmentgm: "Assign To GM Office",
            canassignsecondmentbacktoentity: "Assign Back To Entity",
            cansubmitsecondmentrequest: "Submit Secondment For Fedral Agency Request",
            canviewsecondmentrequest: "View Secondment For Fedral Agency Request",
            caneditsecondmentrequest: "Edit Secondment For Fedral Agency Request",
            canupdatesecondmentstatus: "Update Secondment For Fedral Agency Status",
            canaddsecondmentcomments: "Add Secondment For Fedral Agency Comment",
            canshowsecondmentcomments: "Show Secondment For Fedral Agency Comment",
            canassignsecondmentdepartment: "Assign Secondment For Fedral Agency Department",
            canassignsecondmetnemployee: "Assign Secondment For Fedral Agency Employee",
            canassignsecondmentsector: "Assign Secondment For Fedral Agency Sector",
            canprovidesecondmentstudy: "Provide Secondment For Fedral Agency Study",
            canreceivesecondmentstudy: "Receives Secondment For Fedral Agency Study",

            canassignbonusgm: "Assign To GM Office",
            canassignbonusbacktoentity: "Assign Back To Entity",
            cansubmitbonusrequest: "Submit Bonus Request's Request",
            canviewbonusrequest: "View Bonus Request's Request",
            caneditbonusrequest: "Edit Bonus Request's Request",
            canupdatebonusstatus: "Update Bonus Request's Status",
            canaddbonuscomments: "Add Bonus Request's Comment",
            canshowbonuscomments: "Show Bonus Request's Comment",
            canassignbounsdepartment: "Assign Bonus Department",
            canassignbounsemployee: "Assign Bonus Employee",
            canassignbonussector: "Assign Bonus Sector",
            canprovidebonusstudy: "Provide Bonus Study",
            canreceivebonusstudy: "Receives Bonus Study",

            canassignamendscalegm: "Assign To GM Office",
            canassignamendscalebacktoentity: "Assign Back To Entity",
            cansubmitamendscalerequest: "Submit Amend Salaries Scale Request",
            canviewamendscalerequest: "View Amend Salaries Scale Request",
            caneditamendscalerequest: "Edit Amend Salaries Scale Request",
            canupdateamendscalestatus: "Update Amend Salaries Scale Status",
            canaddamendscalecomments: "Add Amend Salaries Scale Comment",
            canshowamendscalecomments: "Show Amend Salaries Scale Comment",
            canassignamendscaledepartment: "Assign Amend Salaries Scale Department",
            canassignamendscaleemployee: "Assign Amend Salaries Scale Employee",
            canassignamendscalesector: "Assign Amend Salaries Scale Sector",
            canprovideamendscalestudy: "Provide Amend Salaries Scale Study",
            canreceiveamendscalestudy: "Receives Amend Salaries Scale Study",

            canassignamendsalarygm: "Assign To GM Office",
            canassignamendsalarybacktoentity: "Assign Back To Entity",
            cansubmitamendsalaryrequest: "Submit Amend Employee Salary Request",
            canviewamendsalaryrequest: "View Amend Employee Salary Request",
            caneditamendsalaryrequest: "Edit Amend Employee Salary Request",
            canupdateamendsalarystatus: "Update Amend Employee Salary Status",
            canaddamendsalarycomments: "Add Amend Employee Salary Comment",
            canshowamendsalarycomments: "Show Amend Employee Salary Comment",
            canassignamendsalarydepartment: "Assign Amend Employee Salary Department",
            canassignamendsalaryemployee: "Assign Amend Employee Salary Employee",
            canassignamendsalarysector: "Assign Amend Employee Salary Sector",
            canprovideamendsalarystudy: "Provide Amend Employee Salary Study",
            canreceiveamendsalarystudy: "Receives Amend Employee Salary Study",

            canassignretirementgm: "Assign To GM Office",
            canassignretirementbacktoentity: "Assign Back To Entity",
            cansubmitretirementrequest: "Submit Retirement Request",
            canviewretirementrequest: "View Retirement Request",
            caneditretirementrequest: "Edit Retirement Request",
            canupdateretirementstatus: "Update Retirement Status",
            canaddretirementcomments: "Add Retirement Comment",
            canshowretirementcomments: "Show Retirement Comment",
            canassignretirementdepartment: "Assign Retirement Department",
            canassignretirementemployees: "Assign Retirement Employee",
            canassignretirementsector: "Assign Retirement Sector",
            canprovideretirementstudy: "Provide Retirement Study",
            canreceiveretirementstudy: "Receives Retirement Study",

            //extend create objectives
            cansubmitextendcreaterequest: "Submit Extend Create Request",
            canviewextendcreaterequest: "View Extend Create Request",
            caneditextendcreaterequest: "Edit Extend Create Request",
            canupdateextendcreatestatus: "Update Extend Create Status",
            canshowextendcreatecomments: "Show Extend Create Comments",
            canaddextendcreatecomments: "Add Extend Create Comments",
            canprovideextendcreatestudy: "Provide Extend Create Study",
            canreceiveextendcreatestudy: "Receive Extend Create Study",
            canassignextendcreatesector: "Assign Extend Create Sector",
            canassignextendcreatedepartment: "Assign Extend Create Department",
            canassignextendcreateemployee: "Assign Extend Create Employee",
            canassignextendcreatebacktodepartment: "Assign Extend Create Back To Department",
            canassignextendcreatebacktoentity: "Assign Extend Create Back To Entity",
            canassignextendcreaterequesttogm: "Assign To Gm",

            //extend review objectives
            cansubmitextendreviewrequest: "Submit Extend Review Request",
            canviewextendreviewrequest: "View Extend Review Request",
            caneditextendreviewrequest: "Edit Extend Review Request",
            canupdateextendreviewstatus: "Update Extend Review Status",
            canshowextendreviewcomments: "Show Extend Review Comments",
            canaddextendreviewcomments: "Add Extend Review Comments",
            canprovideextendreviewstudy: "Provide Extend Review Study",
            canreceiveextendreviewstudy: "Receive Extend Review Study",
            canassignextendreviewsector: "Assign Extend Review Sector",
            canassignextendreviewdepartment: "Assign Extend Review Department",
            canassignextendreviewemployee: "Assign Extend Review Employee",
            canassignextendreviewbacktodepartment: "Assign Extend Review Back To Department",
            canassignextendreviewbacktoentity: "Assign Extend Review Back To Entity",
            canassignextendreviewrequesttogm: "Assign To Gm",

            //Eextend Annual objectives
            cansubmitextendannualrequest: "Submit Eextend Annual Request",
            canviewextendannualrequest: "View Eextend Annual Request",
            caneditextendannualrequest: "Edit Eextend Annual Request",
            canupdateextendannualstatus: "Update Eextend Annual Status",
            canshowextendannualcomments: "Show Eextend Annual Comments",
            canaddextendannualcomments: "Add Eextend Annual Comments",
            canprovideextendannualstudy: "Provide Eextend Annual Study",
            canreceiveextendannualstudy: "Receive Eextend Annual Study",
            canassignextendannualsector: "Assign Eextend Annual Sector",
            canassignextendannualdepartment: "Assign Eextend Annual Department",
            canassignextendannualemployee: "Assign Eextend Annual Employee",
            canassignextendannualbacktodepartment: "Assign Eextend Annual Back To Department",
            canassignextendannualbacktoentity: "Assign Eextend Annual Back To Entity",
            canassignextendannualrequesttogm: "Assign To Gm",

            //add / edit emp perf
            cansubmitemployeeperformancerequest: "Submit Employee Perfomance Request",
            canviewemployeeperformancerequest: "View Employee Performance Request",
            caneditemployeeperformancerequest: "Edit Employee Performance Request",
            canupdateemployeeperformancestatus: "Update Employee Performance Status",
            canshowemployeeperformancecomments: "Show Employee Performance Comments",
            canaddemployeeperformancecomments: "Add Employee Performance Comments",
            canprovideemployeeperformancestudy: "Provide Employee Performance Study",
            canreceiveemployeeperformancestudy: "Receive Employee Performance Study",
            canassignemployeeperformancesector: "Assign Employee Performance Sector",
            canassignemployeeperformancedepartment: "Assign Employee Performance Department",
            canassignemployeeperformanceemployee: "Assign Employee Performance Employee",
            canassignemployeeperformancebacktodepartment: "Assign Employee Performance Back To Department",
            canassignemployeeperformancebacktoentity: "Assign Employee Performance Back To Entity",
            canassignemployeeperformancerequesttogm: "Assign To Gm",

            //join and implement
            cansubmitjoinimplementrequest: "Submit Join And implement Request",
            canviewjoinimplementrequest: "View Join And Implement Request",
            caneditjoinimplementrequest: "Edit Join And Implement Request",
            canupdatejoinimplementstatus: "Update Join And Implement Status",
            canshowjoinimplementcomments: "Show Join And Implement Comments",
            canaddjoinimplementcomments: "Add Join And Implement Comments",
            canprovidejoinimplementstudy: "Provide Join And Implement Study",
            canreceivejoinimplementstudy: "Receive Join And Implement Study",
            canassignjoinimplementsector: "Assign Join And Implement Sector",
            canassignjoinimplementdepartment: "Assign Join And Implement Department",
            canassignjoinimplementemployee: "Assign Join And Implement Employee",
            canassignjoinimplementbacktodepartment: "Assign Join And Implement Back To Department",
            canassignjoinimplementbacktoentity: "Assign Join And Implement Back To Entity",
            canassignjoinimplementrequesttogm: "Assign To Gm",

            //Open complains
            cansubmitopencomplainsrequest: "Submit Open Complains Request",
            canviewopencomplainsrequest: "View Open Complains Request",
            caneditopencomplainsrequest: "Edit Open Complains Request",
            canupdateopencomplainsstatus: "Update Open Complains Status",
            canshowopencomplainscomments: "Show Open Complains Comments",
            canaddopencomplainscomments: "Add Open Complains Comments",
            canprovideopencomplainsstudy: "Provide Open Complains Study",
            canreceiveopencomplainsstudy: "Receive Open Complains Study",
            canassignopencomplainssector: "Assign Open Complains Sector",
            canassignopencomplainsdepartment: "Assign Open Complains Department",
            canassignopencomplainsemployee: "Assign Open Complains Employee",
            canassignopencomplainsbacktodepartment: "Assign Open Complains Back To Department",
            canassignopencomplainsbacktoentity: "Assign Open Complains Back To Entity",
            canassignopencomplainsrequesttogm: "Assign To Gm",

            //Amend system
            cansubmitamendsystemsrequest: "Submit Amend System Request",
            canviewamendsystemsrequest: "View Amend System Request",
            caneditamendsystemsrequest: "Edit Amend System Request",
            canupdateamendsystemsstatus: "Update Amend System Status",
            canshowamendsystemscomments: "Show Amend System Comments",
            canaddamendsystemscomments: "Add Amend System Comments",
            canprovideamendsystemsstudy: "Provide Amend System Study",
            canreceiveamendsystemsstudy: "Receive Amend System Study",
            canassignamendsystemssector: "Assign Amend System Sector",
            canassignamendsystemsdepartment: "Assign Amend System Department",
            canassignamendsystemsemployee: "Assign Amend System Employee",
            canassignamendsystemsbacktodepartment: "Assign Amend System Back To Department",
            canassignamendsystemsbacktoentity: "Assign Amend System Back To Entity",
            canassignamendsystemsrequesttogm: "Assign To Gm",

            //Report and static 
            cansubmitreportsstaticsrequest: "Submit Report And Static Request",
            canviewreportsstaticsrequest: "View Report And Static Request",
            caneditreportsstaticsrequest: "Edit Report And Static Request",
            canupdatereportsstaticsstatus: "Update Report And Static Status",
            canshowreportsstaticscomments: "Show Report And Static Comments",
            canaddreportsstaticscomments: "Add Report And Static Comments",
            canprovidereportsstaticsstudy: "Provide Report And Static Study",
            canreceivereportsstaticsstudy: "Receive Report And Static Study",
            canassignreportsstaticssector: "Assign Report And Static Sector",
            canassignreportsstaticsdepartment: "Assign Report And Static Department",
            canassignreportsstaticsemployee: "Assign Report And Static Employee",
            canassignreportsstaticsbacktodepartment: "Assign Report And Static Back To Department",
            canassignreportsstaticsbacktoentity: "Assign Report And Static Back To Entity",
            canassignreportsstaticsrequesttogm: "Assign To Gm",

            //Other request
            cansubmitotherrequestsrequest: "Submit Other Request Request",
            canviewotherrequestsrequest: "View Other Request Request",
            caneditotherrequestsrequest: "Edit Other Request Request",
            canupdateotherrequestsstatus: "Update Other Request Status",
            canshowotherrequestscomments: "Show Other Request Comments",
            canaddotherrequestscomments: "Add Other Request Comments",
            canprovideotherrequestsstudy: "Provide Other Request Study",
            canreceiveotherrequestsstudy: "Receive Other Request Study",
            canassignotherrequestssector: "Assign Other Request Sector",
            canassignotherrequestsdepartment: "Assign Other Request Department",
            canassignotherrequestsemployee: "Assign Other Request Employee",
            canassignotherrequestsbacktodepartment: "Assign Other Request Back To Department",
            canassignotherrequestsbacktoentity: "Assign Other Request Back To Entity",
            canassignotherrequesttogmsrequesttogm: "Assign To Gm",

            //Data Services
            requesttitle: 'Request Title',
            requestdetails: 'Request Details',
            todate: 'To Date',
            fromdate: 'From Date',
            systemtype: 'System Type',
            requestdate: 'Request Date'
        },

        dataServicesObj: {
            entityName: 'Entity Name',
            requestTitle: 'Request Title',
            requestDetails: 'Request Details',
            requestDate: 'Request Date',
            extendCreatingObjectivesIntervalFromDate: 'Extend Creating Objectives Interval From Date',
            extendCreatingObjectivesIntervalToDate: 'Extend Creating Objectives Interval To Date',
            extendReviewingObjectivesIntervalFromDate: 'Extend Review Objectives Interval From Date',
            extendReviewingObjectivesIntervalToDate: 'Extend Review Objectives Interval To Date',
            extendAnnualPerformanceEvaluationIntervalFromDate: 'Extend Annual Performance Evaluation Interval From Date',
            extendAnnualPerformanceEvaluationIntervalToDate: 'Extend Annual Performance Evaluation Interval To Date',
            addOrEditIntervalFromDate: 'Add/Edit Interval From Date',
            addOrEditIntervalToDate: 'Add/Edit Interval To Date',
            employeeName: 'Employee Name',
            //jobDesc: 'Job Description',
            jobDesc: 'Job Title',
            contactNumber: 'Contact Number',
            email: 'Email',
            formalReqFromEntityEmp: "Formal request from entity and employees final evaluation degrees sheet attested from top management in PDF file",
            employeesFinalEvaluationDegreesInExcelSheet: "Employess Final Evaluation Degrees Sheet In Excel",
            systemType: 'System Type',
            formalReqFromEntityEmp_for_AddOrEditPerEva: "Formal request from entity and employees Final Performance Evaluation Sheet Attested From Top Management In pdf File ",
            employeesFinalPerformanceEvaluationInExcelSheet_for_AddOrEditPerEva: "Employess Final Performance Evaluation Sheet In Excel",
            datesForOpeningComplainsStageFrom: 'Dates For Opening Complains Stage From',
            datesForOpeningComplainsStageto: 'Dates For Opening Complains Stage To',
            attachEntityAttestedPolicyPDFFile: 'Attach Entity Attested Policy File In PDF',
            attachEntityAttestedOrgStructureFileInPDF: 'Attach Entity Attested Organization Structure File In PDF',
            attachEntityCreationLawFileInPDF: 'Attach Entity Creation Law File In PDF',
            attachAgreementFromEntityTopManagementPDFFIle: 'Attach Agreement from Entity Top Management as PDF File',
            systemTypeHRSystem: 'System Type',
            attachementForRequiredReqortFieldsAndDetails: 'Attachement for required report fields and details',
            otherattachemnt: 'Other Attachment'
        },

        viewDetails: {
            EntityLegalOpinon:'Legal Opinion',
            LeaveSubject: 'Leave details',
YearlyLeaveBalance: 'Employee annual leave balance',
LeavePeriod: 'Sick Leave Time Period',
BacktoWorkDate: 'Work start date after returning from sick leave',
LeveSalryDetails: 'Pay Pay Status',
DescripeIssue: 'Detecting violations of administrative procedures',
EmployeePassport: "Passport",
EmployeeEID: "Emirates ID",
SickLeaveHealthreport: "Medical Report from the Competent Committee",
SickLeaveEmbassyLetter: "Letter of delegation or attachment to the Embassy of the United Arab Emirates in the country of treatment",
SickLeaveHRCom: "A copy of the minutes of the Human Resources Committee approved by the agency’s president or his authorized representative",
EmployeeIncome: "supporting documents for the employee's financial obligations or in the event that the employee is unable to fulfill the financial obligations",

            RequestType:'Request Type',
             EmployeeInfoJobTitle: 'Job title',
                        EmployeeInfoJobDegree: 'Job Degree',
      EmployeeInfoTotalSalary: 'Job location of the candidate from the approved organizational structure',
                        EmployeeInfoBasicSalary: 'Basic Salary',
                        EmployeeInfoRetirementcontributions: 'Retirement Subscriptions',
            
                        EmployeeInfoEmployeeName: 'Employee Name',
                        EmployeeInfoEmirateID: 'Identity Number',
                        EmployeeInfoEmployeeNumber: 'Job Number',
                        EmployeeInfoEmployeeUID: 'Employee Unified Number',
     EmployeeCV: 'CV',
    EmployeePhoto: 'Photo',
    positionofthenominatedposition: 'The position of the nominated position from the approved organizational structure',
    jobdescription: 'Approved job description for the job with technical or administrative job determination',
    HumanResourcesCommittee: 'Copy of the Approved Human Resources Committee minutes',
    EmployeeNominationForm: 'Employee Nomination Form',
    EducationalQualifications: 'Transcript of Qualifications',
    ExperienceCertificates: 'Copy of experience certificates',
    competencyreports: 'Copy of my competency report - for the last two years',
    passport: 'Passport',
    Summaryofregistrationforcitizens: "Citizen's record",
    IDcard: 'ID card',
    CID: 'Criminal Investigation',
    Medicalfitnesscheckup: 'Medical fitness check-up',
    Anotheremployeesselfstatement: 'Last employee self-statement',
            //SCI
            actualOfficialLetter: 'Attached Files',
            requestSubject: 'Request Subject',
            applicantsName: 'Applicators Name',
            reqDetails: 'Request Details',
            contactNumber: 'Contact Number',
            applicantsJob: 'Applicators Job',
            officialLetter: 'Attached Files',
            requestTypeLabelInSCI: 'Request Type',

            //Information Request
            requestorName: "Requestor Name",
            assignedEntitySelectedLabel: "Assigned Entity",
            whatInfoRequired: "Request Details",
            lastTimeToReply: "Last Date To Reply",
            typeOfRequestLabel: "Type Of Request",
            attachFile1: "Attached Files",
            statement: 'Request Details',

            //review hr policy
            reasonsOfRequest: 'Reasons Of Request',
            entityFormalRequest: 'Entity Formal Request',
            standardComparisons: 'Standard Comparisons',
            studies: 'Studies',

            //secondment
            RequestByTheCompetentEntityWithPayEmployeeSalary: 'Request by the competent entity with determine who will be responsible to pay employee salary during this period',
            fromDateLessThanToDate: 'From date must be less than to date',
            unifiedNumber: 'Unified Number',
            familyNumber: 'Family Number',
            employeeName: 'Employee Name',
            BorrowEntityID:'Borrow Entity',
            entityName: 'Entity Name',
            jobName: "Job Name",
            employeeNumber: 'Employee Number',
            totalSalary: 'Total Salary',
            basicSalary: 'Basic Salary',
            retirementCont: 'Retirement Contributions',
            emiratesID: "Emirates ID",
            reqForBrowEntity: 'Request Of The Borrowing Entity',
            reqByCompEnty: 'Request By The Competent Entity',
            fromDatePeriodOfSecndMent: 'Period of Secondment from date',
            toDatePeriodOfSecndMent: "Period of Secondment to date",
            entityRespForSal: 'The Entity Which Responsible For Salary',
            jobDescFileName: 'Job Description',
            CurrentJobDesc:'Curretn Job Description',
            approvalEmpFileName: 'Approval Of Employee',
            copyOfHRCommitteFileName: 'Copy Of HR Committe',
            goverment: 'Goverment',
            competent: 'Competent',
            copyOfLastYearEffiReportFileName: 'Copy Of Last Year Efficiency Report',
            emiratesIdFileName: 'Emarites Id',
            emiratesid: 'Emarites Id',
            passportCopyFileName: 'Passport Copy',
            Family_book: 'Family Book',
            reasonForGovPaySalary:'Reason to Goverment pay employee salary',
            
            HRA_Employee_Study_Maker: 'HRA Employee Study Maker',
            HRA_Study: 'HRA Study',
            select_hra_study: 'Select HRA Study',
            empShouldPassProbationPeriodLabel: 'Employess Should Pass Probation Period',
            lastYearPerfDegMoreThanWeekLabel: 'Last Year Performance Degree More Than Week',
            employeeAgreementLabel: 'Employee Agreement',

            //exception steer
            jobContract: 'Job Contract',
            reasonForExtendingTheService: 'Reasons For Extending The Service',
            annualPerformanceEvaluation: 'Annual Performance Evaluation',
            employeeName: 'Employee Name',
            employeeNumber: 'Employee Number',
            nationalityLabel: 'Nationality',
            dateOfBirth: 'Date Of Birth',
            jobTitle: 'Job Title',
            unifiedNumber: 'Unified Number',
            fromDate: 'Date Of Steer from date',
            toDate: 'Date Of Steer to date',
            copyButton: 'Do you have a copy of the previous approvals for extension from the jurisdiction',
            medicalApprovalsFromTheCometentMedicalCommittee: 'Medical Approvals From The Competent Medical Committee',
            age: "Age",
            passport: 'Passport',
            familyBook: 'Family Book',
            emiratesIDStepOne: 'Emarites ID',
            emaritesID: "Emarites ID",
            annualPerformanceEvaluationattach: 'Annual Performance Evaluation Attach',
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: 'A Copy Of The Previous Approvals For Extension From The Jurisdiction',
            employeeCV: 'Employee CV',
            personalPhoto: 'Personal Photo',
            replacePlansForNonLocalsAttachementFileName: "Replace Plans For Non Locals",

            //Exemption Patient
            //Step 1
            Degreeofrelationship:"Degree of relationship",
            requestType: "Request type",
            requestTypeLabel: "Request type",
            empJob: "Employee Job",
            headlinesOfRequest: 'Request Title',
            grade: 'Grade',
            retirementContributions: 'Retirement Contributions',
            durationOfAccompanyingLeave: 'Duration Of Accompanying Leave(In Days)',
            dateOfSalaryTermination: 'Date Of Salary Termination',
            dateOfDirectWork: 'The Date Of Direct Work',
            requiredToPayBudget: 'Required To Pay Additional Budget',
            selectedOption2: 'Budget Availability',
            budgetAvailabilityYear: 'Enter The Budget\'s Year',
            budgetYear: 'Enter The Year',
            budgetValue: 'Budget\'s Value',
            annualBalanceOfVacations: 'The Annual Balance Of Vacations',
            justificationsEmployee: 'Justifications Employee To Accompany A Patient',
            exemptionSelectedValue: "Exemption type",
            totalDays: "",
            husbandWifeWork: "Husband / Wife Work",
            totalAmountExempted: "Total Amount To Be Exempted From Employee",
            noOfChildren: "Number Of Children",
            placeOfChildren: "Place Of Children",
            violation: "Violation in administrative procedures in the case of continuing monthly salaries after legal period and relationship in accordance with the Human Resources Law",
            HowToCalculate: "How To Calculate",
            explain: "Explain",
            irregularitiesTable: 'The Irregularities In The Administrative Procedures',
            year: 'Year',
            salaryStatus: 'Salary Status',
            month: 'Month',
            noOfDays: 'Number Of Days',
            totalSalaryDollar: 'Total Salary',
            basicSalaryTable: 'Basic Salary',
            //totalDays: 'Total Days',
            totalSalaryPayed: 'Total Salary To Be Payed',
            intervals: "Intervals",
            totalSalaryWithSubscription: "Total Salary Without Contributions",
            retirementSalary: "Contributions",
            tswsrsSalary: "Total",
            fromDay: "From",
            toDay: "To",
            subTotalTotal: "Total Amount",
            subTotal: "Sub Total",
            totalNoOfDays: "Total Number Of Days",

            //Patient attachment
            officialLetter: "Official Letter From Head Of Entity",
            passportCopy: "Passport Copy",
            medicalReport: "The Medical Report",
            letterFromEmbassy: "A Letter From The Embassy",
            copyOftheDigest: "Copy Of The Digest Or Proof Of Kindship",
            copyOfHumanResoourcesCommittee: "Copy Of Human Resoources Committee Record",
            documentationOfFinancialObligations: "Documentation Of Financial Obligations",
            emiratedID: "Emirated ID",

            // Amend Employee Salary
            //Step 1
            nationalityLabel: "Nationality",
            age: "Age",
            designationDate: 'Designation Date',
            currentBasicSalary: 'Current Basic Salary',
            proposedBasicSalary: 'Proposed Basic Salary',
            financialRatioOfProcedure: 'Amendment Value',
            percentIncRequiredBasicSalary: 'The Percentage Of Increase Required On The Basic Salary',
            totalExperience: 'Total Experience(In Years)',
            procedureDate: 'Procedure Date',
            academicQualification: 'Aacademic Qualification',
            experienceAfterQualification: 'Experience After Qualification(In Years)',
            annualEvaluationforPreviousYear: 'Annual Evaluation For Previous Year',
            annualEvaluationforCurrentYear: 'Annual Evaluation For Current Year',
            currentJobTitle: 'Current Job Title',
            currentGrade: 'Current Grade',
            reasonToModifySalary: 'Reason To Modify Salary',
            proposedIncreaseRate: 'The Bases Used To Determine The Proposed Increase Rate',

            //Step 2
            employeeEmaritesId: 'Employee Emarites Id',
            employeePhoto: 'Employee Photo',
            copyOfLastProcedure: 'Copy Of Last Procedure',
            previousandCurrentApprovedCompetencyReport: 'Previous and Current Approved Competency Report',
            approvedScaleSalaries: 'Approved Scale Of Salaries',

            // Amend Employee Salary
            //Step 1
            sourcesFundingLabel: "Sources funding for proposed increases",
            principlesUsedToModifyScale: "The Principles Used To Modify The Scale",
            justificationOfEditTheScale: "Justification Of Edit The Scale",
            selectedOptionFinancial: "Have the necessary financial allocations been made within the approved budget",
            proposedBasicSalaryfunding: "Proposed monthly bonus",
            currentBasicSalarymonthly: "Current monthly bonus",
            proposedAnnualBenefits: "Proposed annual benefits",
            currentAnnualBenefits: "Current annual benefits",
            proposedFinancialEffect: "Proposed financial effect",
            currentFinancialEffect: "Current financial effect",
            proposedSalariesScale: "Challenges of proposed salaries scale",
            currentSalariesScale: "Challenges of current salaries scale",
            avarageForProposedSalariesScale: "Avarage of increase / decrease for proposed salaries scale",
            avarageForCurrentSalariesScale: "Avarage of increase / decrease for current salaries scale",
            EffectOfApplyingOnProposed: "Effect of applying the proposed scale",
            EffectOfApplyingOnCurrent: "Effect of applying the current scale",
            otherBonusTableData: "Other Bonus Name",
            otherBenefitTableData: "Other Benefit Name",
            approvedBudgetsOfPart: "The Amounts Approved In The Budgets Of Part One",

            //Step 2
            approvedScaleOfGrades: 'Approved Scale of Grades & Salaries',
            salariesCompensationPolicy: 'Salaries & Compensation Policy',
            tableOfTheAlignment: 'Table of the alignment of the grades (for civil service)',
            proposedScale: 'Proposed Scale',
            entityChairman: 'Entity Chairman approve',
            illustrationsOfCurrent: 'Illustrations of current and proposed salary lines',
            comparativeStudy: 'Comparative study with similar entities',
            detailsOfAnnualCost: 'Details of annual cost',

            //bonusRequest
            //step1
            entityname: 'Entity Name',
            jobname: 'First Coordinator Job Name',
            coordinatorname: 'First Cordinator Name',
            email: 'First Email',
            contactnumber: 'First Contact Number',
            phonenumber: 'First Contact Number (Mobile)',
            subcoordinatorname: 'Second Cordinator Name',
            subjobname: 'Second Coordinator Job Name',
            subcontactnumber: 'Second Contact Number',
            subphonenumber: 'Second Contact Number (Mobile)',
            subemail: 'Second Email',
            isbudgeted: 'Do you have a budget for employee bonuses? Please indicate the value of the budget indicated in an official document',
            budget: 'Enter The Budget',
            budgetyear: 'Enter The Year',
            yearachievment: 'Entity Achivements For Year',
            bonusreason: 'Reasons To Pay Bonuses',
            year1: 'Percentage Of Emariatisation Year1',
            emiratizationpercentage: 'Enter Percentage (%)',
            year2: 'Percentage Of Emariatisation Year2',
            iscontracted: 'For Private Contracts employee which request annual bonuses for them. Do their contracts have fixed value as annual bonus, and if present please upload copy of all their contacts (for all employees which request annual bonuses for them).',
            totalemployees: 'Total Number Of Employees',
            equation: 'Explain The Equation For Evaluate The Performance With Details',
            emiratizationpercentage2: "Enter Percentage (%)",
            nonlocalwithprivate: 'Non-Locals Private Contract',
            localwithprivate: 'Locals Private Contract',
            localwithpermanent: 'Locals Permanent Contract',
            nonlocalwithpermanent: 'Non Local Permanent Contract',
            excellentemployees: 'Excellent',
            verygoodemployees: 'Very Good',
            goodemployees: 'Good',
            acceptableemployees: 'Acceptable',
            weakemployees: 'Weak',
            isfixedvaluebonus: 'For Private Contracts employee which request annual bonuses for them. Do their contracts have fixed value as annual bonus, and if present please upload copy of all their contacts (for all employees which request annual bonuses for them).',

            //step3
            approvedGradesAndSalaries: 'Approved Grades And Salaries',
            balancedScoreCard: 'Balanced Score Card',
            financialStatement: 'Financial Statement',
            taxSummary: 'Approved Grades And Salaries',
            resultsOfEvaluationGeneralPerformance: 'Results of evaluation for general performance for all entity employees (including the employees which request annual bonus for them). Must be approved from Presedent of Entity.',
            uploadTheContracts: 'Upload attachment for private Contracts',
            uploadTheExcelAfterFill: 'Upload The Excel After Fill',
            budgetRelatedDocument: 'Budget Related Document',
            presentationforEntityAcheivementsDocument: 'Presentation For Entity Acheivements For the Years of Required Bonus Attachement',

            //Ad housing
            FinancialCost: 'Financial Cost',
            typeOfContractLabel: 'Type Of Contract',
            EmployeeName: 'Employee Name',
            EntityName: 'Entity Name',
            BasicSalary: 'Basic Salary',
            unifiedNumber: 'Unified Number',
            jobTitle: 'Job Title',
            contractPeriod: 'Employee Contract Period',
            TotalSalary: 'Total Salary',
            EmployeeNumber: 'Employee Number',
            HousingAllowance: 'Housing Allowance',
            RetirementContributions: 'Retirement Contributions',
            emiratesID: 'Emirates ID',
            employeeContract: 'Employee Contract',
            flatContract: 'Flat Contract',
            entityFormalRequest: 'Entity Formal Request',
            supportRequest: 'Attached Files For Support Request',
            ReasonsOfRequest: 'Reasons Of Request',
            ADHousingPassport:'Passport',
            ADHousingEID:'Emirates ID',
            ADHousingFamilyBook:'FamilyBook',
            //Data Services
            entityName: 'Entity Name',
            requestTitle: 'Request Title',
            requestDetails: 'Request Details',
            requestDate: 'Request Date',
            extendCreatingObjectivesIntervalFromDate: 'Extend Creating Objectives Interval From Date',
            extendCreatingObjectivesIntervalToDate: 'Extend creating objectives interval to date',
            extendReviewingObjectivesIntervalFromDate: 'Extend Review Objectives Interval From Date',
            extendReviewingObjectivesIntervalToDate: 'Extend Review objectives interval to date',
            extendAnnualPerformanceEvaluationIntervalFromDate: 'Extend Annual Performance Evaluation Interval From Date',
            extendAnnualPerformanceEvaluationIntervalToDate: 'Extend Annual Performance Evaluation Interval To Date',
            addOrEditIntervalFromDate: 'Add/Edit Interval From Date',
            addOrEditIntervalToDate: 'Add/Edit Interval To Date',
            employeeName: 'Employee Name',
            jobDesc: 'Job Title',
            contactNumber: 'Contact Number',
            emailId: 'Email',
            formalReqFromEntityEmp: "Formal request from entity and employees final evaluation degrees sheet attested from top management in pdf file",
            employeesFinalEvaluationDegreesInExcelSheet: "Employess Final Evaluation Degrees Sheet In Excel",
            systemType: 'System Type',
            formalReqFromEntityEmp_for_AddOrEditPerEva: "Formal request from entity and employees Final Performance Evaluation Sheet attested from top management in pdf file ",
            employeesFinalPerformanceEvaluationInExcelSheet_for_AddOrEditPerEva: "Employess Final Performance Evaluation Sheet In Excel",
            datesForOpeningComplainsStageFrom: 'Dates for opening complains stage from',
            datesForOpeningComplainsStageto: 'Dates for opening complains stage to',
            attachEntityAttestedPolicyPDFFile: 'Attach Entity Attested Policy File In PDF',
            attachEntityAttestedOrgStructureFileInPDF: 'Attach Entity Attested Organization Structure File In PDF',
            attachEntityCreationLawFileInPDF: 'Attach Entity Creation Law File In PDF',
            attachAgreementFromEntityTopManagementPDFFIle: 'Attach Agreement from Entity Top Management as PDF File',
            systemTypeHRSystem: 'System Type HR System',
            attachementForRequiredReqortFieldsAndDetails: 'Attachement for required report fields and details',
            otherattachemnt: 'Other Attachment'

        },
        Reports: {
            startdate: 'From',
            enddate: 'To' ,
            sector: 'Sector',
            department: 'Department',
            employeename:'Employee Name'
        }
        , viewDetailActions: {
            lastaction: 'Last Action',
            Employee: 'Created By',
            ActionType: 'Type',
            Comment: 'Comment',
            ViewDetails: 'ViewDetails', ViewLess: 'show less'
        }

    },

    ar: {
        StrategicPlanningforworkforce:
        {
            Title:'التخطيط الاستراتيجي للقوى العاملة',
            Enquire:'استفسار التخطيط الاستراتيجي للقوى العاملة',
            EntityName:'اسم الجهة',
            RequesterName:'اسم الشخص',
            JobTitle:'المسمى الوظيفي',
            PhoneNumber:'رقم التواصل',
            VacancyAppointmentForm:'نموذج تعيين الشواغر',
          AttachVacancyAppointmentForm:'إرفاق نموذج تعيين الشواغر',
          AttachJobangel:'إرفاق الملاك الوظيفي',
          Attachjobdescriptiontotherequiredvacancies:'إرفاق الوصف الوظيفي للشواغر المطلوبة',
          Jobangel:'الملاك الوظيفي',
          jobdescriptiontotherequiredvacancies:'الوصف الوظيفي للشواغر المطلوبة',
          sucess:"تم إرسال الطلب بنجاح",
          Year:'السنة',


        },
        Survey : 
        {
Subject:'استبيان'
        },
        allgoventities:'جميع الجهات الحكومية',
        exxtendatereason:'سبب تمديد فترة الرد',
        savedsuccessfully:'تم الحفظ بنجاح',
        failedtoextend : 'فشل في تعديل التاريخ',
        failedtoloadextend : 'فشل في تحميل البيانات',
        decision:'القرار',
        
        datefrominfo:'تاريخ الاستحقاق',
        editcomment: 'تعديل دراسة - رد - تعليق',
        
        //Trasol
        Trasol:'تراسل',
        // organization structure 
        OrganizationStructureCompleted: "تم استكمال الهيكل",
        organizationStructure: 'الهيكل التنظيمي',
        updateOrganizationStructure: ' تعديل هيكل تنظيمي',
        organizationStructureTree: 'شجرة الهيكل التنظيمي',
        organizationStructureTreePreview: 'معاينة شجرة الهيكل التنظيمية',
        treeView: 'عرض الشجرة',
        OpenCreationStage: "فتح مرحلة الانشاء",
        OpenModificationStage: "فتح مرحلة التعديل",

        update: 'تعديل',
        invalidToDate: 'يجب أن يكون تاريخ إلى أكبر من تاريخ من',
        shortNameForServices: {
            generalWizard: 'SCI',
            informationRequest: 'IR',
            exemptionPatient: 'PE',
            hrPolicy: 'HR',
            adHousing: 'HP',
            secondment: 'FS',
            retirment: 'RT',
            bonusRequest: 'BR',
            amendSalary: 'AS',
            amendEmployee: 'AES',
            extendCreate: 'EC',
            extendReview: 'ER',
            extendAnnual: 'EA',
            addAndEditEmployeePerformance: 'EP',
            joinAndImplement: 'JI',
            openGravience: 'OG',
            amendSharedSyatem: 'AH',
            reportAndStatic: 'SR',
            otherRequest: 'OR'
        },
        q1: 'Q1',
        q2: 'Q2',
        q3: 'Q3',
        q4: 'Q4',
        viewMore: '...',
        failedtoassigngmoffice: 'فشل في اسناد المهمة للمدير العام',
        failedtoassignentity: 'فشل في اارجاع الطلب للجهة الخارجية',
        failedtoassignemployee: 'فشل في اسناد المهمة للموظف',
        failedtoassigndepartment: 'فشل في اسناد المهمة للإدارة',
        failedtoassignsector: 'فشل فى اسناد المهمة للقطاع',
        failedtosavecomment: 'فشل في حفظ التعليقات',
        failedtoassign: 'فشل في تحديث حالة الطلب',
        filenotfound: "الملف غير موجود",
        pleaseWait: 'يرجى الانتظار',
        exportToExcel: 'تصدير',
        downloadExcel: 'تحميل التقرير',
        writeusat: 'سعداء بتقديم الدعم من خلال: ',
        supportemail: 'hrahub.helpdesk@hra.gov.ae',
        allFieldsAreMandatory: 'جميع الحقول إجبارية',
        fileLimitErr: "مسموح تحميل 10 ملفات كحد اقصى",
        failed_to_change_password: 'فشل في تغيير كلمة المرور',
        notifications: 'الإخطارات',
        helpfile: 'دليل الاستخدام',
        Covid19:'جدول المخالفات كوفيد 19 ',
        salarymatrix: 'مصفوفة الرواتب',
        viewDetail: 'عرض التفاصيل',
        task: 'المهام',
        inbox: 'التصنيف بحاله الطلب',
        enterusername: 'ادخل إسم المستخدم',
        enterpassword: 'ادخل كلمه المرور',
        login: 'تسجيل دخول',
        
        BrowserNotSupportedTitle: 'عزيزي المتصفح',
        BrowserNotSupported: 'يرجى العلم بأن الموقع غير مدعوم من قبل المتصفح المستخدم، لذا يرجى استخدام أحد المتصافحات التالية التي تدعم النظام (Google Chrome,Firefox,Microsoft Edge) في حال استمرارك في استخدام هذا المتصفح قد تلاحظ مشاكل ستؤثر على تجربة استخدامك للنظام. فريق الخدمات الإلكترونية لهيئة الموارد البشرية HRAHUB',
        SurveyTitle:'رايك يهمنا',
        SurveyText:'شكراً لاستخدام  نظام هيئة الموارد البشرية HRAHUB  يرجي تقييم تجربتك فى استخدام  النظام و تقديم الخدمة',
        Sad:'غير راضى جداً',
Good:'مقبول',
Excellent:'راضى جداً',
FeedBack:'يرجى ذكر اسباب عدم رضائك عن الخدمة و مساعدتنا على تحسين خدماتنا لننول رضائك',
        loginwithcredentials: 'ادخل إسم المستخدم وكلمه المرور',
        signin: 'تسجيل دخول',
        preview: 'استعراض',
        saveAsDraft: 'حفظ كمسودة',
        uploaddocuments:'ارفاق المستندات',
        requiredcondition: "يرجي توفر الشروط التالية لاستكمال الطلب ماعدا في حالة الاسثناء ",
        patientrequestcondition: " توافر موازنة مع توضيح المبلغ الإجمالى المراد اعفاء الموظف منه وتوضيح كيفية احتسابه",
        secondmentreqquestconditionone: " موافقة الموظف المعار الخطية علي الاعارة",
        secondmentreqquestconditiontwo: " يشترط ان تكون الاعارة لمدة سنه واحدة قابلة للتجديد",
        secondmentreqquestconditionthree: " لايجوز اعارة الموظف الا بعد اكماله لفتره الاختبار بنجاح",
        secondmentreqquestconditionfour: " لايجوز اعارة الموظف الحاصل علي تقرير كفاءة بدرجة ضعيف عن السنه السابقة للاعارة",
        retirementrequestcondition: " يشترط تقديم الطلب قبل 3 اشهر من المده المحدده لبلوغ الموظف سن الاحاله للتقاعد (60 عاماً للذكور, 55 عاماً للاناث).",
        bonusrequestcondition: "  توفر موازنة في الجهه لصرف المكافات للموظفين مع بيان قيمة الموازنة المرصودة بمستند رسمي",
        serviceName: "إسم الخدمة",
        serviceDescription: "وصف الخدمة",
        requiredDocuments: "المرفقات المطلوبة",
        specialConditions: "الشروط الخاصة",
        durationOfTheService: "الوقت المطلوب لتقديم الخدمة",
        courseOfAction: "الاجراءت ",
        infoReqDesc: "تتيح هذه الخدمة للجهات الحكومية في امارة ابوظبي وموظفي حكومة امارة ابوظبي التواصل مع هيئة الموارد البشرية لطلب اية معلومات او استفسارات.",
        infoReqSpecialCondtion: "لا يوجد",
        infoReqDurationOfService: "",
        sciDesc: "تتيح هذه الخدمة للجهات الحكومية في امارة ابوظبي وموظفي حكومة امارة ابوظبي التواصل مع هيئة الموارد البشرية لتقديم اية مقترحات لتحسين خدمات الهيئة أو اضافة خدمة جديدة لخدماتها كما تتيح تقديم استسفار عن طلب او خدمة بالإضافة الى تقديم اية شكاوي.  .",
        sciSpecialCondtion: "",
        sciDurationOfService: "",
        amendEmpSalDesc: "تتيح هذه الخدمة للجهات الحكومية بإمارة أبوظبي إمكانية تعديل راتب لموظف في جهة حكومية  وفق الصلاحيات الواردة في سياسة تعديل الراتب في اللائحة التنفيذية بعد صدور قرار من المجلس التنفيذي بالموافقة/عدم الموافقة على تعديل الراتب.بغرض تصحيح وضع الموظف مقارنة بزملائه أو مرؤوسيه وذلك بالعودة لسجل راتبه السابق مع بيان مبررات تعديل الراتب. ",
        amendEmpSalSpecialCondtion: "لا يوجد",
        amendEmpSalDurationOfService: "5 أيام عمل",
        amendSalScaleDesc: "تتيح هذه الخدمة للجهات الحكومية بإمارة أبوظبي تعديل سلم الرواتب في الجهة وفق الصلاحيات الواردة في سياسة تعديل الراتب في اللائحة التنفيذية بعد صدور قرار من المجلس التنفيذي بالموافقة/عدم الموافقة على تعديل الراتب.",
        amendSalScaleSpecialCondtion: "لا يوجد",
        amendSalScaleDurationOfService: "30 يوم عمل",
        bonusReqDesc: "تتيح هذه الخدمة للجهات الحكومية لإمارة أبوظبي بصرف مكافآت سنوية للموظفين وفق نتائج تقرير الأداء السنوي مع تقديم المبررات الداعمة لطلب المكافآت السنوية للموظفين . بعد صدور قرار من المجلس التنفيذي بالموافقة/عدم الموافقة على صرف المكافآت السنوية للموظفين.",
        bonusReqSpecialCondtion: "توفر ميزانية في الجهة لصرف المكافآت للموظفين مع بيان قيمة الموازنة المرصودة بمستند رسمي.",
        bonusReqDurationOfService: "15 يوم عمل",
        exempAccompanyPatientDesc: "تتيح هذه الخدمة لموظفي حكومة إمارة أبوظبي إمكانية طلب تمديد اجازة مرافقة مريض للعلاج خارج أو داخل الدولة بعد تعدي المدة المسموح بها حسب اللائحة التنفيذية لقانون الخدمة المدنية.",
        exempAccompanyPatientCondtion: "توفر الميزانية, توضيح المبلغ الإجمالي المراد اعفاء الموظف منه، و توضيح كيفية احتسابه.",
        exempAccompanyPatientDurationOfService: "10 أيام عمل",
        reviewHRPoliciesDesc: "تتيح هذه الخدمة للجهات الحكومية في امارة أبوظبي طلب اعتماد أو تعديل سياسات الموارد البشرية للجهات، مع بيان المبررات الداعمة للطلب.",
        reviewHRPoliciesCondtion: "لا يوجد",
        reviewHRPoliciesDurationOfService: "10 أيام عمل",
        exceptionADHousingDesc: "تتيح هذه الخدمة لجهات الحكومية بالتقدم لطلب استثناء الموظفين الحكوميين لديهم من تعميم رقم 14 لسنة 2012 بشأن سياسة السكن والذي يتضمن عدم استحقاق الموظف المقيم خارج إمارة أبوظبي علاوة السكن، ولا يجوز صرفها له بطريقة مباشرة أو غير مباشرة.",
        exceptionADHousingCondtion: "لا يوجد",
        exceptionADHousingDurationOfService: "5 أيام عمل",
        secondmentOfFedralAgenDesc: "تتيح هذه الخدمة للجهات الحكومية بقرار من السلطة المختصة بالتعيين إعارة موظف إلى جهة حكومية اتحادية أو محلية، وإلى أي من الأشخاص الاعتبارية العامة أو الخاصة داخل وخارج الدولة، وإلى الحكومات والهيئات والمنظمات العربية والأجنبية والدولية.",
        secondmentOfFedralAgenCondtion: "موافقة الموظف المعار الخطية على الإعارة.,يشترط أن تكون الإعارة لمدة سنة واحدة قابلة للتجديد.,لا يجوز إعارة الموظف إلا بعد إكماله لفترة الاختبار بنجاح.,لا يجوز إعارة الموظف الحاصل على تقرير كفاءة بدرجة ضعيف عن السنة السابقة للإعارة.",
        secondmentOfFedralAgenDurationOfService: "5 أيام عمل",
        excepOfSteerTheAgeOfRetirREQDesc: "تتيح هذه الخدمة للجهات الحكومية في إمارة أبوظبي التقدم بطلب تمديد الخدمة بعد بلوغ السن القانوني للإحالة للتقاعد في الحالات التي تقتضيها مصلحة العمل، حيث يجوز طلب مد خدمة الموظف لمدة سنة فأخرى، حتى بلوغ سن الخامسة والسبعون (75) عاماً كحد أقصى للموظـــف المواطــن، و(65) عامــاً لغير المواطن، بخلاف الحــالات الاســـتثنائية لغير المواطنين والتي تعرض على المجلس التنفيذي للاعتماد.",
        excepOfSteerTheAgeOfRetirREQCondtion: "يشترط عدم بلوغ الموظف سن (75) عاماً كحد أقصى للمواطن.,يشترط عدم بلوغ الموظف سن (65) عاماً كحد اقصى لغير المواطن.",
        excepOfSteerTheAgeOfRetirREQDurationOfService: "10 أيام عمل",
        follow: 'متابعة',
        requestnumber: 'رقم الطلب',
        //ageFormat: 'للمواطنين العمر اقل من 75 وغير المواطنين العمر اقل من 65',
        ageFormat: '',
        budgetAvailabilityError: 'لابد من توفر الموازنة المطلوبه',
        manager: 'المدير',
        internalorexternal: "نوع المستخدم",
        yearValidation: 'الرجاء ادخال السنه',
        retirementSubscriptions: 'اشتراكات التقاعد',
        presentationforEntityAcheivements: 'عرض تقديمي موضحا انجازات الجهة الحكومية للعام المطلوب له المكافاة',
        emiratesID: 'رقم الهوية',
        flatContract: 'عقد العقار',
        employeeContract: 'عقد الموظف',
        exemptionType: "نوع الاستثناء",
        requestType: "نوع الاستثناء",
        replacePlansForNonLocals: "خطه الاحلال لغير المواطنين",
        help: 'المساعده',
        userManual: 'دليل المستخدم',
        downloadAll: 'تحميل الجميع',
        userManualEnglish: 'دليل المستخدم باللغه الانجليزية',
        userManualArabic: 'دليل المستخدم باللغه العربية',
        userManualforAdminEnglish: 'دليل مدير النظام باللغه الانجليزية',
        userManualforAdminArabic: 'دليل مدير النظام باللغه العربية',
        table: 'جدول',
        mailtemplatetype: 'نوع قالب البريد',
        statushistory: 'تقرير الإجراءات',
        eservicesStats: '',
        pending: 'جديد',
        Underapproval:'قيد الاعتماد ',
        Late:'متأخر',
        Notification:'إرسال تذكير',
        inprogress: 'قيد الاجراء ',
        onhold: 'معلق',
        cancelled: 'تم الالغاء',
        completed: 'تم الانجاز',
        fileName: "إسم الملف",
        fileType: "نوع المرفق",
        downloadHere: "حمل الملف",
        self: 'ذاتي',
        englishName: 'الإسم باللغة الانجليزية',
        arabicName: 'الإسم باللغة العربية',
        Department: 'الإدارة',
        Sector: 'القطاع',
        shortName: 'إسم مختصر',
        empNumber: 'الرقم الوظيفي',
        isMotherComp: 'هل هي الشركه الام ؟',
        sector: 'قطاع الاعمال',
        entity: 'الجهة',
        industry: 'مجال العمل',
        logo: 'شعار الجهة',
        clientDescr: 'وصف الجهة',
        city: 'المدينة',
        isActive: 'نشط',
        reasonToDeative: 'سبب عدم التفعيل',
        phnNumber: 'رقم هاتف المكتب',
        selectSector: 'اختار القطاع',
        selectDropdownDefaultValue: '--اختار--',
        selectReason: 'اختار السبب',
        selectIndustry: 'اختار التخصص',
        selectEntity: 'اختار الجهه',
        userNameLabel: "إسم المستخدم",
        mobile: 'رقم التواصل',
        phoneNumber: "رقم هاتف المكتب",
        designation: "المسمي الوظيفي",
        accountType: 'نوع الحساب',
        selectAccountType: 'اختار نوع الحساب',
        selectRole: 'اختار الصلاحية',
        role: "الصلاحية",
        status: "الحالة",
        selectStatus: "اختار الحالة",
        reason: "السبب",
        title: "العنوان",
        selectTitle: 'اختار العنوان',
        permission: "الصلاحية",
        EnglishTitle: "الإسم باللغة بالانجليزية",
        ArabicTitle: "العنوان باللغة العربية",
        selectTitle: 'اختار العنوان',
        permission: "الصلاحية",
        admin: "مدير النظام",
        isadmin: "مدير النظام",

        // organization structure 

        ORG_Request: 'طلب انشاء هيكل تنظيمي',
        canSubmitORGRequest: "انشاء طلب",
        canReviewORGRequest: "مراجعة الطلب",
        canEditORGRequest: "تعديل الطلب",
        canUpdateORGStatus: 'تعديل حالة الطلب',
        canAddORGComment: 'اضافة تعليق',
        canshowORGcomments: "استعراض التعليقات",
        canAssignORGDepartment: "اسناد للقسم",
        canAssignORGEmployee: "اسناد للموظف",
        canProvideORGFeddback: 'اضافة افادة',
        canViewORGFeddback: 'استعراض الافادة',
        canassignORGsector: "اسناد للقطاع",
        canassignORGGM: "تحويل للمدير العام",
        canassignORGBackToEntity: "ارجاع للجهة",


        SCI_Request: 'طلب اقتراح / شكوى/استفسار',
        canSubmitSCIRequest: "تقديم طلب اقتراح / شكوى/استفسار",
        canReviewSCIRequest: "مراجعة طلب اقتراح / شكوى/استفسار",
        canEditSCIRequest: "تعديل طلب اقتراح / شكوى/استفسار",
        canUpdateSCIStatus: 'تحديث طلب اقتراح / شكوى/استفسار',
        canAddSCIComment: 'ادراج تعليق على طلب اقتراح / شكوى/استفسار',
        canAssignSCIDepartment: "اسناد طلب الاقتراحات / الشكاوى / الاستفسارات إلى إدارة",
        canAssignSCIEmployee: "اسناد طلب الاقتراحات / الشكاوى والاستفسارات إلى موظف",
        canProvideSCIFeddback: 'اعطاء افادة بخصوص اقتراح / شكوى/استفسار',
        canViewSCIFeddback: 'عرض افادة بخصوص اقتراح شكوى/استفسار',
        canassignscisector: "اسناد طلب اقتراح /الشكاوى والاستفسارات إلى اداره",

        informationReq: "طلب معلومات",
        canSubmitInfoReq: 'تقديم طلب معلومات',
        canReviewInfoReq: "مراجعة طلب معلومات",
        canviewirrequest: "عرض طلب معلومات",
        canEditInfoReq: "تعديل طلب معلومات",
        canUpdateInfoStatus: "تحديث حالة طلب معلومات",
        canAddInfoComment: "ادراج تعليق على طلب معلومات",
        canAssignInfoDepartment: "اسناد إدارة طلب معلومات",
        // canAssignInfoEmp: "اسناد موظف طلب معلومات",
        canprovideirfeedback: 'اعطاء افادة بخصوص طلب معلومات',
        canViewInfoFeedback: "عرض افادة بخصوص طلب معلومات",
        canshowscicomments: "عرض تعليق على طلب اقتراح / شكوى/استفسار",
        canassignirsector: "اسناد إدارة طلب معلومات",
        canshowircomments: "عرض تعليق على طلب معلومات",
        canreceiveirfeedback: "استلام افادة بخصوص طلب معلومات",
        canAddInfoReqFeedback: "اضافة افادة بخصوص طلب معلومات",
        exemptionFromAccompanyPatient: "طلب استثناء من أحكام إجازة مرافقة مريض",
        canSubmitExmFromAccoPatient: "تقديم طلب الإعفاء من مرافقة المريض",
        canReviewExempFromAccomPatient: "مراجعة طلب الإعفاء من مرافقة المريض",
        canEditExmpFromAccomaPatient: "تعديل طلب الإعفاء من مرافقة المريض",
        canUpdateExmFromAccomPatientStatus: "تحديث حالة طلب الإعفاء من مرافقة المريض",
        canAddExempFromAccoPatientComment: "اضافة تعليق على طلب الإعفاء من مرافقة المريض",
        canSHowExmPatientCOmment: "عرض تعليق على طلب الإعفاء من مرافقة المريض",
        reviewHrPolices: "مراجعة سياسات الموادر البشرية",
        canSubmitHrPolicyReq: "تقديم طلب مراجعة سياسات الموادر البشرية",
        canViewHrPolicyReq: "عرض طلب مراجعة سياسات الموادر البشرية",
        canEditHrPoliciReq: "تعديل طلب مراجعة سياسات الموادر البشرية",
        canUpdateHrPoliciStatus: "تحديث طلب مراجعة سياسات الموادر البشرية",
        canAddHrPoliciesComment: "اضافة تعليق على طلب مراجعة سياسات الموادر البشرية",
        canShowHrPoliciesComment: "عرض تعليق على طلب مراجعة سياسات الموادر البشرية",
        exemptionFromADHousPolicy: "الإعفاء من سياسة الاسكان في ابوظبي",
        canSubmitExmADHousingPolicy: "تقديم طلب الإعفاء من سياسة الاسكان في ابوظبي",
        canReviewExemtionAdHousingPolicy: "عرض طلب الإعفاء من سياسة الاسكان في ابوظبي",
        canEditExemADHousingPolicyReq: "تعديل طلب الإعفاء من سياسة الاسكان في ابوظبي",
        canUpdateExmADHousingPolicyStatus: "تحديث طلب الإعفاء من سياسة الاسكان في ابوظبي",
        canAddExemtionAddHousingPolicyComment: "اضافة تعليق على طلب الإعفاء من سياسة الاسكان في ابوظبي",
        canShowAdHousingComment: "عرض تعليق على طلب الإعفاء من سياسة الاسكان في ابوظبي",
        secndmentForFedralAgency: "الاعارة للجهات الاتحادية",
        canSubmitSecndmentFedrlAfencyRequst: "تقديم طلب الاعارة للجهات الاتحادية",
        canReviewSecondmentForFedralAgencyRequest: "عرض طلب الاعارة للجهات الاتحادية",
        canEditSecondmentForFedralAgencyReq: "تعديل طلب الاعارة للجهات الاتحادية",
        canUpdateSecondmentForFedralAgencyStatus: "تحديث طلب الاعارة للجهات الاتحادية",
        canAddSecondmentForFedralAgencyComment: "اضافة تعليق على طلب الاعارة للجهات الاتحادية",
        canShowSecondmentComments: "عرض تعليق على طلب الاعارة للجهات الاتحادية",
        bonusRequest: "صرف مكافأة مالية سنوية للجهة",
        canSubmitBonusRequestsRequest: "تقديم طلب صرف مكافأة مالية سنوية",
        canViewBonusRequestsReq: "عرض طلب صرف مكافأة مالية سنوية",
        canEditBonusRequests: "تعديل طلب صرف مكافأة مالية سنوية",
        canUpdateBonusRequestsStatus: "تحديث حالة طلب صرف مكافأة مالية سنوية",
        canAddBonusRequestsComment: "اضافة تعليق على طلب صرف مكافأة مالية سنوية",
        canShowBonusRequestsComment: "عرض تعليق على طلب صرف مكافأة مالية سنوية",
        canassignsalariesdepartment: "اسناد الطلب لإدارة الرواتب",
        canassignsalariesEmp: "اسناد الطلب لموظف الرواتب",
        canProvideSalStudy: "اعطاء دراسة رواتب",
        canReceivesSalStudy: "استلام دراسة رواتب",
        canassignResearchdepartment: "اسناد الطلب لإدارة الابحاث",
        canassignResearchEmp: "اسناد لموظف الابحاث",
        canProvideResearchStudy: "اعطاء دراسة الابحاث",
        canReceivesResearchStudy: "استلام دراسة الابحاث",
        canassignPoliciesSector: "اسناد الطلب لقطاع السياسات",
        canassignPoliciesDept: "اسناد الطلب لإدارة السياسات",
        canAssignPoliciesEmp: "اسناد الطلب لموظف السياسات",
        canProvidePoliciesStudy: "اعطاء دراسة السياسات",
        canRecivesPoliciesStudy: "استلام دراسة السياسات",
        amendSalaryScale:"تعديل جدول رواتب الجهة الحكومية",
        canSubmitAmendSalScaleReq: "تقديم طلب تعديل جدول الرواتب",
        canViewAmendSalaryScaleReq: "عرض طلب تعديل جدول الرواتب",
        canEditAmendSalariScaleReq: "تعديل طلب تعديل جدول الرواتب",
        canUpdateAmendSalScaleStatus: "تحديث حالة طلب تعديل جدول الرواتب",
        canAddAmendSalScaleComm: "اضافة تعليق على طلب تعديل جدول الرواتب",
        canshowamendscalecomments: "عرض تعليق على طلب تعديل جدول الرواتب",
        amendEmpSalary: "تعديل راتب موظف",
        canSubmitAmendEmpSalReq: "تقديم طلب تعديل راتب موظف",
        canViewAmendEmpSalReq: "عرض طلب تعديل راتب موظف",
        canEditAmendEmpSalReq: "تعديل طلب تعديل راتب موظف",
        canUpdateAmendEmpSalStatus: "تحديث حالة طلب تعديل راتب موظف",
        canAddAmendEmpSalComment: "اضافة تعليق على طلب تعديل راتب موظف",
        canshowamendEmpComments: "عرض تعليق على طلب تعديل راتب موظف",
        retirement: "طلب مد سن التقاعد",
        canSubmitRetirementReq: "تقديم طلب تقاعد",
        canViewRetirementReq: "عرض طلب تقاعد",
        canEditRetirementReq: "تعديل طلب تقاعد",
        canUpdateRetirementStatus: "تحديث حالة طلب تقاعد",
        canAddRetirementComment: "اضافة تعليق على طلب تقاعد",
        canshowRetirementComments: "عرض تعليق على طلب تقاعد",
        roles: 'الأدوار',
        entities: 'الجهات',
        contact: 'المستخدمين',
        adminPanel: 'لوحة مدير النظام',
        mailTemplate: 'قالب البريد',
        lookUp: 'البحث',
        contractPeriod: 'مدة عقد الموظف بالسنوات',
        requiredRecomandation: 'الترشيحات المطلوبة',
        jobTitle: 'المسمى الوظيفي',
        enterPercentage: '(%) النسبة',
        enterYear: 'السنة',
        enterTotalEntityEmployee: 'إجمالى موظفي الجهة',
        enterExcelentEmployee: 'عدد الموظفين الحاصلين على تقدير ممتاز',
        enterVeryGoodEmployee: 'عدد الموظفين الحاصلين على تقدير جيد جدا',
        enterGoodEmployee: 'عدد الموظفين الحاصلين على تقدير جيد',
        enterAcceptableEmployee: 'عدد الموظفين الحاصلين على تقدير مقبول',
        enterWeakEmployee: 'عدد الموظفين الحاصلين على تقدير ضعيف',
        totalNumberWithPrivateContract: 'عدد الموظفين (المواطنين) ذوي العقود الخاصة',
        totalNumberWithPermenantContract: 'عدد الموظفين (المواطنين) ذوي العقود الدائمة',
        totalNumberWithnonPrivateContract: 'عدد الموظفين (غير المواطنين) ذوي العقود الخاصة',
        totalNumberWithnonPermenantContract: 'عدد الموظفين (غير المواطنين) ذوي العقود الدائمة',
        percentageOfEmployeeGotExcellentGrade: 'النسبة المئوية للموظفين الحاصلين على تقدير ممتاز',
        percentageOfEmployeeGotVeryGoodGrade: 'النسبة المئوية للموظفين الحاصلين على تقدير جيد جدا',
        percentageOfEmployeeGotGoodGrade: 'النسبة المئوية للموظفين الحاصلين على تقدير جيد',
        percentageOfEmployeeGotAcceptableGrade: 'النسبة المئوية للموظفين الحاصلين على تقدير مقبول',
        percentageOfEmployeeGotGradeGrade: 'النسبة المئوية للموظفين الحاصلين على تقدير ضعيف ',
        permanentContract: 'عقد دائم',
        privateContract: 'عقد خاص',
        locals: 'مواطنين',
        nonLocals: 'غير مواطنين',
        annualEvaluationForAYearWhichRequestedBonus: 'التقييم السنوي للعام الذي يتطلب مكافأة',
        numberOfEmployeeWhichTheyNotEqual: 'توزيع عدد الموظفين',
        percentage: 'النسبة (%)',
        excellent: 'ممتاز',
        veryGood: 'جيد جداً',
        good: 'جيد',
        acceptable: 'مقبول',
        weak: 'ضعيف',
        //emailLogin: 'البريد الإلكتروني',
        emailLogin: 'إسم المستخدم',
        passwordLogin: 'كلمة المرور',
        sign_in_cont: 'تسجيل الدخول للمتابعة',
        forgotPasswordLogIn: 'هل نسيت كلمة المرور؟',
        logIn: 'تسجيل الدخول',
        tagLineOfHra1: 'نهدف إلى :',
        tagLineOfHra2: 'تفعيل مشاركة',
        tagLineOfHra3: 'المواطنين في سوق العمل',
        fromDate: 'من',
        toDate: 'الى',
        fromDate1: 'من',
        toDate1: 'الى',
        fromDate2: 'من',
        toDate2: 'الى',
        fromDate3: 'من',
        toDate3: 'الى',
        acceptedFormate: 'التنسيقات المقبولة: pdf. حجم الملف الأقصى 2 ميغابايت',
        addStudy: 'إضافة الدراسة',
        attahment: 'الملف المرفق',
        attachment: 'الملف المرفق',
        addReply: 'إضافة الرد',
        SelectComment: 'يرجى اختيار التعليقات التى يجب ان تراها الجهة الخاريجة',
        cancle: 'إلغاء',
        deptapproval: 'موافقة الإدارة',
        sectorapproval: 'موافقة القطاع',
        gmapproval: 'موافقة المدير العام',
        assignToSector: 'تحويل للقطاع',
        assignToGmOffice: 'تحويل إلى مكتب المدير العام',
        assignToStrategicOffice: 'تحويل إلى اداره علاقات الشركاء',
        extendinfodate: "طلب تمديد تاريخ الاستحقاق",
        replayextendinfodate: "قرار تمديد تاريخ الاستحقاق",

        assignBackToEntity: 'إرجاع للجهة صاحبة الطلب',
        assignToEntity: 'أرسال للجهة',
        select_sector: 'اختر القطاع',
        assignToDep: 'تحويل إلى إدارة',
        backToManager: 'إرجاع الطلب للمدير',
        assignToSpecificDep: 'تحويل الطلب إلى إدارة محدد',
        assignToDataDep: 'إرجاع الطلب إلى إدارة البيانات',
        sendToDataDep: 'إرسال الطلب إلى إدارة البيانات',
        loackrequest: 'سحب الطلب',
        assignToEmp: 'تحويل للموظف',
        employee: 'موظف',
        department: 'إدارة',
        statusreason: 'الإجراء',
        updatedon: 'تاريخ التحديث',
        updatedby: 'تم التحديث بواسطة',
        clickToDownload: 'تحميل الملف',
        select_status: 'اختر الحالة',
        select_reason: 'اختر السبب',
        add_comment: 'أضف تعليق',
        phoneNumberErr: 'هاتف صحيح',
        userNameHeader: 'عنوان الستخدم',
        create: 'إنشاء جديد',
        facebook: 'فيس بوك',
        twitter: 'تغريد',
        google: 'جوجل',
        linkedin: 'لينكد ان',
        youtube: 'يوتيوب',
        logout: 'تسجيل الخروج',
        settings: 'الإعدادات',
        profile: 'الملف الشخصي',
        welcome: 'أهلا بكم',
        current_status: 'الحالة الحالية',
        current_reason: 'سبب الحالة',
        reviewHrPolicy: 'مراجعة سياسة الموارد البشرية',
        userName: 'إسم المستخدم',
        userCountry: 'الدوله',
        arabic: 'العربية',
        report: 'التقارير',
        canCloseRequest: 'إغلاق الطلب',
        canViewEmployeeReport:'تقارير الموظفين',
        canGenerateAllreports:'استخراج كافة الإدارات و القطاعات',
        canCreateWorkShop:'انشاء ورشة توعية جديدة',
canBookWorkShop:'حجز ورشة توعية',
canViewDisciplinaryboard:'عرض طلب',
canCreateDisciplinaryboard:'اضافة طلب',
canEditDisciplinaryboard:'تعديل طلب ',
canAssignDisciplinaryboardToDGoffice:'تحويل للمدير العام',
canAssignDisciplinaryboardtoEmployee:'تحويل لموظف',
canAddReplayDisciplinaryboard:'اضافة رد',
canViewOHI:'عرض مبادرات الصحة المؤسسية',

        english: 'English',

        footerText: '© '+new Date().getFullYear()+'. هيئة الموارد البشرية',
        hraLabel: 'هيئة الموارد البشرية',
        dashboard: 'القائمة الرئيسية',
        servicelistingtitle: 'قائمة طلبات',
        general_manger_service:'تواصل الجهات مع هيئة الموارد البشرية',
        general_manger_wizard: 'معالج المدير العام',
        salaries: 'خدمات دعم عمليات الرواتب والتعويضات والمكافئات',
        policies: 'دعم شؤون موظفي الحكومة',
        policiesServices:'خدمات استشارات سياسات الموارد البشرية والتوعية',
        saleries_and_bonuos: 'الرواتب والتعويضات',
        dataServices: 'خدمات دعم عمليات الأنظمة الإلكترونية وبيانات الموارد البشرية',
        returntodefault:'جميع الخدمات',
        amendSalariesScale: 'تعديل جدول رواتب الجهة الحكومية',
        exceptionOfSteerRetirementRequest: 'استثناء من أحكام سياسة التقاعد/انهاء خدمة',
        retirementWizard: 'معالج التقاعد',
        sci: 'اقتراح / شكوى / استفسار',
        reviewHRPoliciesLabel: 'مراجعة سياسة الموارد البشرية',
        amendEmployeeSalary: 'تعديل راتب موظف',
        approvedScaleOfSalaries: 'جدول الرواتب والدرجات المعتمد',
        chooseFile: 'اختر ملف',
        acceptedFormatsPDF: 'أنواع الملفات المقبولة فقط pdf , الحجم لايزيد عن 2 ميجابيت',
        employeePhoto: 'صورة شخصية للموظف',
        thisFieldIsRequired: 'هذا الحقل اجباري',
        positionnotfound: 'في حالة عدم وجود وظائف في القائمة نرجو مراسلتنا علي بريد الدعم الفني لاضافتها للهيكل التنظيمي',

        adminrolesrequired: ' الادوار الادارية اجبارية ',
        functionalrolesrequird: 'الادوار الوظيفية اجبارية',
        organizationalrolesrequird: 'الادوار التنظيمية اجبارية',

        PasswordPolicy: 'يجب استخدام 8 أحرف أو أكثر باستعمال مزيج من الأحرف والأرقام والرموز',
        ckeditorCountErr: 'يرجى ادخال عدد أحرف أقل من 2000 حرف',
        invalidYear: 'السنه غير صحيحه',
        invalidEmail: 'الايميل غير صحيح',
        invalidContactNumber: 'رقم التواصل غير صحيح',
        invalidEmiratesID: 'يرجي التحقق من رقم الهوية الاماراتية',
        invalidPhoneNumber: 'رقم هاتف المكتب غير صحيح',
        invalidMobileNumber: 'رقم التواصل غير صحيح',
        invalidBonusNumber: 'يجب الا يتجاوز هذا الحقل 50 خانة',
        allowedMultipleFile: 'يمكن تحميل اكثر من ملف',
        DateFilteration:'ترشيح البيانات',
        DateFilterationFrom:'من',
        DateFilterationTo:'الى',
        DateFilterationBtn:'تطبيق ',
        ClearFilter:'الغاء',
        DateFilteration_Empty_error_message:'يجب اختيار تاريخ البداية و تاريخ النهاية',
        DateFilteration_Wrong_error_message:'يجب ان يكون تاريخ النهاية اكبر من او  يساوى تاريخ البداية',
        allowCkEditorLimit: 'يجب الأ يتجاوز عدد الأحرف 2000 حرف',
        acceptedFormatsImageOnly: 'انواع الملفات المقبوله فقط (jpeg,png,jpg) , الحجم لايزيد عن 2 ميجابيت',
        
        acceptedFormatsAll: 'انواع الملفات المقبوله (pdf,doc,docx,ppt,pptx,xls,xlsx,jpeg,png,txt) , الحجم لايزيد عن 2 ميجابيت',
        acceptedFormatsAllWorkshop: 'انواع الملفات المقبوله (pdf,doc,docx,ppt,pptx,xls,xlsx,jpeg,png,txt) , الحجم لايزيد عن 5 ميجابيت',
        acceptedFormats_pdf_doc_xlxs: 'نوع الملف المقبول (pdf,doc,docx,ppt,pptx,xls,xlsx) , الحجم لايزيد عن 2 ميجابيت',
        acceptedFormats_xlsx: 'انواع الملفات المقبوله : xls,xlsx , الحجم لايزيد عن 2 ميجابيت',
        acceptedFormatsXML: 'اضافة القيمة بالعربية مطابقاً للانجليزية',
        ageErr: 'يجب ان يكون العمر اكبر من 18 عام.',
        salaryErr: 'الراتب الاساسي يجب ان يكون اقل من إجمالي الراتب',
        housingAllowanceErr: 'بدل السكن اكبر من الراتب الإجمالى',
        RetirementContributionsErr: 'الاشتراكات الشهرية اكبر من الراتب الإجمالى',
        monthlyContributionsErr: 'يجب أن تكون المساهمة الشهرية أقل من إجمالي الراتب',
        totalCalculationErr: 'الانواع الاربعة الاضافية ادناه يجب ان تساوي العدد الإجمالى للموظفين',
        totalEmpCulationErr: 'الانواع الخمسة الاضافية ادناه يجب ان تساوي العدد الإجمالى للموظفين',
        bonusNote: 'يجب الا يتجاوز توزيع عدد الموظفين على مستويات التقييم العدد الإجمالي لموظفي الجهة الحكومية',
        employeeName: 'إسم الموظف',
        nationality: 'جنسية الموظف',
        age: 'سن الموظف',
        next: 'التالى',
        finish: 'إنهاء',
        currentBasicSalary: 'الراتب الأساسي الحالى',
        proposedBasicSalary: 'الراتب الأساسي المقترح',
        percentageIncreaseRequiredBasicSalary: 'نسبة التعديل المقترحة على الراتب الأساسي',
        designationDate: 'تاريخ مباشرة العمل في الجهة الحكومية',
        aacademicQualification: 'المؤهل العلمي للموظف',
        experienceAfterQualification: 'الخبرة الإجمالية بعد المؤهل العلمي بالسنوات',
        totalExperience: 'الخبرة الإجمالية بالسنوات',
        employeeEmaritesId: 'صورة من الهوية الاماراتية',
        previousAndCurrentApprovedCompetencyReport: 'إرفاق التقييم السنوي المعتمد السابق والحالى',
        annualEvaluationforPreviousYear: 'نتيجة التقييم السنوي المعتمد للعام السابق',
        annualEvaluationforCurrentYear: 'نتيجه التقييم السنوى المعتمد للعام الحالى',
        currentJobTitle: 'المسمى الوظيفي الحالى',
        currentGrade: 'الدرجة الوظيفية',
        jobRank: 'تصنيف الوظيفة',
        procedureType: 'نوع الإجراء الذي تم على راتب الموظف',
        financialRatioOfProcedure: 'قيمة التعديل  المطلوبه ',
        procedureDate: 'تاريخ آخر إجراء تم على راتب الموظف',
        copyOfLastProcedure: 'نسخة من آخر إجراء',
        reasonToModifySalary: 'مبررات طلب تعديل الراتب للموظف',
        baseUsedDetermineProposedIncreaseRate: 'الأسس المستخدمة في تحديد نسبة التعديل المقترحة',
        hraEmployeeStudyMaker: 'موظف الجهة الذى اعد الدراسة',
        hraStudy: 'الدراسة المعدة',
        nformation_req_step_one: '1- طلب معلومات الخطوة',
        information_req_step_2: '2- طلب معلومات الخطوة ',
        contact_number: 'رقم التواصل',
        requestor_name: 'مقدم الطلب',
        what_are_the_info_req: 'تفاصيل الطلب',
        who_are_assign_entities: "الجهة المعنية",
        next_btn: 'التالى',
        attached_files: 'المرفقات',
        choose_file: 'اختار ملف',
        type_of_request: 'نوع الطلب',
        type_of_contract: 'نوع عقد الموظف',
        last_time_to_reply: 'آخر تاريخ للرد',
        the_required_statements: 'الجمل المطلوبة',
        previous: 'السابق',
        request_new_bonus: 'طلب مكافأة جديدة',
        human_res_auth: "هيئة الموادر البشرية @2019",
        menu1: 'الرئيسية',
        menu2: 'حول الخدمات الإلكترونية',
        menu3: 'التعليمات',
        FAQ:'الأسئلة الأكثر تكراراً',
        login: 'تسجيل الدخول',
        serviceTitle1: 'الرواتب والتعويضات',
        serviceTitle2: 'سياسات الموارد البشرية',
        serviceTitle3: 'الهيكل التنظيمي',
        serviceTitle4: 'التواصل مع الجهة',
        serviceTitle5: 'طلب معلومات',
        serviceText4: 'الخدمات المتعلقة بالاستفسارات والشكاوي',
        serviceText1: 'الخدمات المتعلقة بالعلاوات والرواتب',
        serviceText5: 'خدمات التواصل مع الجهات الخارجية',
        serviceText2: 'الخدمات المتعلقة بالأنظمة والتشريعات والقوانين النافذة للموارد البشرية',
        serviceText3: 'الخدمات المتعلقة بالهياكل التنظيمية',
        footermenu1: 'موظفو هيئة الموارد البشرية',
        footermenu2: 'روابط',
        footermenu3: 'أسئلة وأجوبة',
        footermenu4: 'خريطة الموقع',
        copyright: 'جميع الحقوق محفوظة لهيئة الموارد البشرية © '+new Date().getFullYear(),
        deleteNode: 'هل أنت متأكد أنك تريد حذف هذه العقدة؟',
        organizationStructure: 'الهيكل التنظيمي',
        organizationStructureTree: 'انشاء الهيكل التنظيمي',
        organizationStructureTreePreview: 'شجرة الهيكل التنظيمي',

        exemptionFromAccompanyPatien: 'استثناء من أحكام أجازه مرافقة مريض',
        information_req_step_one: 'طلب معلومات الخطوة - 1',
        target_date: 'التاريخ المستهدف للرد',
        entityLogo: 'شعار الجهة',
        entityName: 'إسم الجهة',
        firstcordinatorName: 'إسم المنسق الاول',
        secondcordinatorName: 'إسم المنسق الثاني',
        firstjobName: 'المسمى الوظيفي للمنسق الاول',
        secondjobName: 'المسمى الوظيفي للمنسق الثاني',
        firstcontactNumber: 'رقم هاتف المكتب للمنسق الاول',
        secondcontactNumber: 'رقم هاتف المكتب للمنسق الثاني',
        firstcontactNumberMobile: 'رقم الهاتف المتحرك للمنسق الاول',
        secondcontactNumberMobile: 'رقم الهاتف المتحرك للمنسق الثاني',
        firstemail: 'البريد الإلكتروني للمنسق الاول',
        secondemail: 'البريد الإلكتروني للمنسق الثاني',
        approvedGradesAndSalaries: 'جدول الدرجات والرواتب المعتمد لدى الجهة الحكومية',
        havebudget: 'هل يتطلب صرف المكافأة المالية المطلوبة موازنة إضافية؟ في حال الإجابة بنعم، الرجاء توضيح قيمة المكافأة المالية.',
        yes: 'نعم',
        no: 'لا',
        enterTheBudget: 'قيمة المكافأة المالية',
        budgetRelatedDocument: 'أرفق الملف الداعم للموازنة',
        reasonsToPayBonuses: 'توضيح مبررات طلب صرف المكافأة المالية السنوية لموظفي الجهة ',
        entityAchivementsForYear: 'يرجى توضيح تفاصيل أهم إنجازات الجهة الحكومية للعام المطلوب له المكافأة المالية',
        infoRequest: 'طلب معلومات',
        enterTheYear: 'السنة',
        balancedScoreCard: 'تفاصيل نتائج الأداء المؤسسي مع إرفاق ما يثبت ذلك',
        financialStatement: 'تقرير يوضح نتائج الأداء المالى للجهة الحكومية للعام ',
        taxSummary: 'ملخص الضريبة',
        totalEmployeesOfEntityDetails: 'تفاصيل العدد الإجمالى لموظفي الجهة',
        percentageOfEmariatisation: 'نسبة التوطين المحققة لدى الجهة الحكومية للعام الحالى والعام السابق',
        resultsOfEvaluationGeneralPerformance: 'مستند معتمد لنتائج تقييم أداء الموظفين للعام',
        privateContractsEmployee: 'في حال طلب صرف مكافأة مالية لموظفي العقود الخاصة، يرجى الإفادة بالتالى:هل تتضمن عقودهم الوظيفية قيمة ثابته للمكافأة السنوية المستحقة؟ في حال الإجابة بنعم، يرجى تحميل نسخة من عقد الموظف مبيناً قيمة المكافأة المذكورة في خانة المستندات المطلوبة في شاشة المرفقات',
        requestAnnualBonus: 'المستندات المطلوب توفيرها عند تقديم طلب المكافأة المالية',
        uploadTheContracts: 'نسخ من العقود الخاصة التي نصت على صرف قيمة مكافأة مالية محددة للموظف',
        explainTheEquation: '(توضيح مستويات تقييم الأداء. (مثال: 5 يعادل ممتاز، 4 يعادل جيد جدا، 3 يعادل جيد إلخ',
        totalNumberOfEmployees: 'العدد الإجمالى لموظفي الجهة',
        numberGredeEmployees: 'عدد الموظفين',
        downloadThisExcelFile: 'استكمال جميع الخانات في نموذج كشف بيانات الموظفين بحسب النموذج الموضح أدناه وإعادة إرفاقه في خانة المستندات المطلوبة في شاشة المرفقات',

        downloadThisExcelFileORG: ' قم بتنزيل الملف واضافة البيانات المطلوبه وتحميله ',

        clickHereToDownload: 'تنزيل نموذج كشف الموظفين',
        clickHereToDownloadORGOprationalTemplate: 'تنزيل النموذج التشغيلي',
        clickHereToDownloadORGTasksTemplate: ' تنزيل نموزج المهام والاختصاصات',

        uploadTheExcelAfterFill: 'نموذج كشف الموظفين :الرجاء اعادة تحميل الملف بعد استكمال كافة البيانات المطلوبة ',
        HRAEmployeeStudyMaker: 'موظف الجهة المسؤول عن اعداد الدراسة',
        submit: 'إرسال',
        select: 'اختيار',
        person: 'شخص',
        group: 'مجموعة',
        step1: 'الخطوة 1',
        step2: 'الخطوة 2',
        step3: 'الخطوة3',
        step4: 'الخطوة 4',
        step5: 'الخطوة 5',
        Enter_the_request: 'الطلب',
        approved_scale_of_grades_salaries: 'جدول الدرجات والرواتب المعتمد لدى الجهة الحكومية',
        salaries_compensation_policy: 'سياسة الرواتب والتعويضات شاملة جميع العلاوات والمزايا',
        justification_of_edit_the_scale: 'مبررات التعديل على جدول الدرجات والرواتب للجهة الحكومية',
        principles_used_to_modify_the_scale: 'الأسس المستخدمة في تعديل جدول الدرجات والرواتب',
        table_of_the_alignment_of_the_grades: 'جدول مواءمة درجات الجهة مع درجات الخدمة المدنية مع بيان معايير المقارنة في الدراسة',
        proposed_scale: 'ارفاق جدول الدرجات والرواتب المقترح للجهة',
        accepted_formats_pdf: 'انواع الملفات المقبوله : pdf',
        accepted_formats_excel: 'انواع الملفات المقبوله فقط xlsx',
        comparative_study_with_similar_entities: 'دراسة مقارنة مع الجهات المماثلة وبيان معايير المقارنة في الدراسة',
        illustrations_current_and_proposed_salary_lines: 'رسومات توضيحية في ملف إكسل لخطوط الرواتب الحالية والمقترحة مقارنة بخط السوق / الجهات المماثلة(للمواطنين وغير المواطنين) لكل الدرجات في رسم توضيحي واحد مع إرفاق جدول في ملف إكسل بالقيم المقابلة لكل خط بحسب الدرجة',
        sources_funding_for_proposed_increases: 'مصادر التمويل للزيادات المقترحة',
        sources_funding_for_proposed_increases_one: ' تحديد ما إذا كان قد تم تخصيص الاعتمادات المالية اللازمة ضمن الموازنة المعتمدة للعام مع تحديد المبالغ المعتمدة بالموازنتين المعتمدتين للباب الأول',
        sources_funding_for_proposed_increases_two: ' إرفاق نسخة في جزئية المستندات المطلوبة في الصفحة التالية.',
        necessary_financial_allocations: 'هل لديكم مخصصات مالية اللازمة ضمن الموازنة المعتمدة',
        copy_of_the_decision: 'نسخة من قرار الجهة الادارية المختصة في هذا الشأن',
        details_of_annual_cost: 'تفاصيل التكلفة السنوية بالموازنة المعتمدة للعام بناء على بيانات ورواتب الموظفين الفعلية مع تحديد المخصصات الحالية والمقترحة بحسب الدرجة وبيان أعداد الموظفين بكل درجة (مواطنين وغير مواطنين كل على حدى)',
        current_basic_salary: 'العلاوات الشهرية الحالية',
        proposed_basic_salary: 'العلاوات الشهرية المقترحة',
        current_annual_benefits: 'المزايا السنوية الحالية',
        proposed_annual_benefits: 'المزايا السنوية المقترحة',
        current_financial_effect: 'الأثر المالى الحالى',
        proposed_financial_effect: 'الأثر المالى المقترح',
        other_bonus_name: 'علاوات أخرى',
        other_benefit_name: 'مزايا أخرى',
        Challenges_of_current_salaries: 'تحديات جدول الرواتب الحالى',
        challenges_of_proposed_salaries: 'تحديات جدول الرواتب المقترح',
        avarage_increase_for_current_salaries: 'متوسط نسبة الزيادة / التخفيض الإجمالية بين الجدول الحالى والمقترح',
        avarage_increase_for_proposed_salaries: 'معدل الزيادة / التخفيض للجدول المقترح',
        proposed_scale_on_current: 'التأثير المحقق من تطبيق جدول الرواتب المقترح (على الجدول الحالى)',
        proposed_scale_on_proposed: 'التأثير المحقق من تطبيق جدول الرواتب المقترح (على الجدول المقترح)',
        entity_chairman_approve: 'اعتماد رئيس الجهة',
        employee_study_maker: 'موظف هيئة الموارد البشرية (معد الدراسة)',
        hra_study: 'الدراسة المعدة',
        reviewHRPoliciesLable: 'طلب اعتماد او تعديل سياسة الموارد البشرية',
        exceptionADHousing: 'استثناء من أحكام سياسة السكن لموظفي الجهات الحكومية',
        secondmentForFederal: 'إعارة موظف حكومي',
        exceptionForPatient: 'استثناء من أحكام أجازه مرافقة مريض',
        exceptionForRetirment:'طلب الاستثناء من أحكام مد خدمة الموظف بعد بلوغ السن القانوني للإحالة للتقاعد',
        bonus_Request: 'صرف مكافأة مالية سنوية للجهة',
        select_action: 'اختر إجراء',
        edit: 'تعديل',
        Reopen:'اعادة فتح الطلب',
        view: 'عرض',
        comment: 'إضافة تعليق',
        statusUpdate: 'تعديل الحالة',
        postComment: 'حفظ',
        CreatedBy: 'تم انشاؤه بواسطه',
        Date: 'التاريخ ',
        Comment: 'تعليق',
        information_request: 'طلب معلومات',
        amend_salaries_scale: 'تعديل جدول رواتب الجهة الحكومية',
        amend_employe_salary: 'تعديل راتب موظف',
        exceptio_from_company_patient: 'استثناء من أحكام أجازه مرافقة مريض',
        reveiew_hr_policies: 'مراجعة سياسة الموارد البشرية',

        //Data Services
        extendCreateObjectives: 'تمديد فترة وضع الأهداف الوظيفية',
        extendReviewObjectives:'تمديد فترة مراجعة الأهداف الوظيفية',
        extendAnnualPerformanceEvaluationInterval:'تمديد فترة تقييم الأداء السنوي لموظفي الجهة',
        addOrEditEmployeesPerformanceEvaluation:'اعتماد/إضافة/تعديل تقييم الأداء السنوي لموظفي الجهة',
        joinAndImplementGovernmentResourcesSystemForNewEntity:'الربط الإلكتروني بأنظمة الموارد البشرية المعتمدة',
        openComplainsStage:'فتح فترة التظلم على نتائج الأداء الوظيفي',
        amendsOnSharedGovernmentHRManagementystem:'تحسين/إضافة خصائص لنظام أبوظبي لإدارة الموارد الحكومية',
        ReportsAndStaticRequest: 'توفير بيانات واحصائيات الموارد البشرية الحكومية',
        OtherRequests: 'طلبات أخرى',
        extendCreateObjectivesDesc: 'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب تمديد فترة الأهداف في حال عدم كفاية المدة المقررة مسبقاَ لوضع الأهداف.',
        extendReviewObjectivesDesc: 'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب تمديد مراجعة الأهداف في حال عدم كفاية المدة المقررة مسبقاَ لمراجعة الأهداف.',
        extendAnnualPerformanceEvaluationIntervalDesc: 'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب تمديد فترة تقييم الأداء السنوي في حال عدم كفاية المدة المقررة مسبقاَ لفترة تقييم الآداء السنوي للموظفين.',
        //approveTheFinalPerformanceEvaluationDesc:'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب اعتماد / إضافة / تعديل تقييم الأداء للموظفين.',
        addOrEditEmployeesPerformanceEvaluationDesc: 'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب اعتماد / إضافة / تعديل تقييم الأداء للموظفين.',
        joinAndImplementGovernmentResourcesSystemForNewEntityDesc: 'تتيح هذه الخدمة للجهات الغير مربوطة على النظام بالربط واستخدام الأنظمة المتوفرة.',
        openComplainsStageDesc: 'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب فتح فترة عملية التظلم للنتيجة النهائية للآداء السنوي للموظفين.',
        amendsOnSharedGovernmentHRManagementystemDesc: 'تتيح هذه الخدمة للجهات الحكومية الرابطة بالتقدم بطلب الدعم من الهيئة في حال وجود إضافة أو تعديل في النظام.',
        ReportsAndStaticRequestDesc: 'تتيح هذه الخدمة بالتقدم بطلب توفير البيانات  والإحصاءات من الهيئة',
        OtherRequestsDesc: 'تتيح هذه الخدمة بالتقدم بانواع الطلبات الأخرى',
        extendCreateObjectivesSpecialCondition: 'إرسال الطلب عن طريق النظام',
        extendReviewObjectivesSpecialCondition: 'إرسال الطلب عن طريق النظام',
        extendAnnualPerformanceEvaluationIntervalSpecialCondition: 'إرسال الطلب عن طريق النظام',
        addOrEditEmployeesPerformanceEvaluationSpecialCondition: 'معتمدة من الإدارة العليا للجهة',
        joinAndImplementGovernmentResourcesSystemForNewEntitySpecialCondition: 'معتمدة من الإدارة العليا للجهة',
        openComplainsStageSpecialCondition: 'معتمدة من الإدارة العليا للجهة',
        amendsOnSharedGovernmentHRManagementystemSpecialCondition: 'معتمدة من  مدير إدارة الموارد البشرية',
        ReportsAndStaticRequestSpecialCondition: 'اعتماد الإدارة العليا',
        OtherRequestsSpecialCondition: 'إرسال الطلب عن طريق النظام',

        extendCreateObjectivesDuration: '5 أيام عمل',
        extendReviewObjectivesDuration: '5 أيام عمل',
        extendAnnualPerformanceEvaluationIntervalDuration: '5 أيام عمل',

        addOrEditEmployeesPerformanceEvaluationDuration: '5 أيام عمل',
        joinAndImplementGovernmentResourcesSystemForNewEntityDuration: '6 شهور عمل',
        openComplainsStageDuration: '5 أيام عمل',

        amendsOnSharedGovernmentHRManagementystemDuration: '5 شهور عمل',
        ReportsAndStaticRequestDuration: '30 يوم عمل',
        OtherRequestsDuration: '',
        // Default for attachemnt dahborad table
        NoAttachmentrequired: 'لا يوجد',
        EmptySLA: 'بناء على الطلب',
        search: 'بحث',
        actions: 'إجراء',
        password: 'كلمه المرور',
        confirmPass: 'تأكيد كلمة المرور',
        passwrdErr: 'كلمات المرور غير متطابقة. أعد المحاولة.',
        resetPass: 'إعادة تعيين كلمة المرور',
        enter_valid_email: 'بريد الكتروني بصيغه سليمه',
        forgotPassword: 'نسيت كلمة المرور',
        login_message_one: 'خطأ دخول',
        success: 'نجاح',
        bookingSucess:'تم حجز الورشة بنجاح',
        save_as_Draft: 'تم الحفظ كمسودة',
        request_created: 'تم إنشاء الطلب بنجاح',
        request_updated: 'تم تحديث الطلب بنجاح',
        entity_created: 'تم إنشاء الجهة بنجاح',
        entity_updated: 'تم تحديث الجهة بنجاح',
        contact_created: 'تم إنشاء جهة الاتصال بنجاح',
        contact_updated: 'تم تحديث جهة الاتصال بنجاح',
        lookup_created: 'تم إنشاء البحث بنجاح',
        lookup_updated: 'تم تحديث البحث بنجاح',
        mailtemplate_created: 'تم إنشاء قالب البريد بنجاح',
        mailtemplate_updated: 'تم تحديث قالب البريد بنجاح',
        role_created: 'تم انشاء المهمه بنجاح',
        role_updated: 'تم تحديث الدور بنجاح',
        error: 'خطأ',
        network_message: 'الرجاء التاكد من الاتصال بالشبكه',
        attachment_error: 'غير قادر علي تحميل الملفات الرجاء التاكد من حجم الملفات',
        error_message: 'هناك خطأ ما',
        error_message_one: 'لايوجد بيانات',
        message: 'رسالة',
        password_reset: 'تمت إعادة تعيين كلمة المرور بنجاح',
        password_change: 'تم تغيير كلمة المرور بنجاح',
        login_message_error: 'الرجاء التحقق من إسم المستخدم وكلمة المرور',
        ok: 'حسنا',
        add: 'إضافة',
        approvedBudgetsOfPart: 'المبالغ المعتمدة في ميزانيات الباب الأول',
        salaryScaleSummery: 'استكمال نموذج ملخص جدول الدرجات والرواتب للجهة الحكومية',
        invalidFileType: 'نوع الملف غير صحيح',
        invalidFileSize: 'حجم الملف اكبر من 2 ميجابيت',
        invalidFileSizeWorkshop:'حجم الملف اكبر من 5 ميجابيت',
        orgStructure: {
            expandAll: 'اظهار الكل',
            collapseAll: 'تجمبع الكل',
            search: 'بحث',
            previous: 'سابق',
            next: 'التالى',
            info: 'معلومات',
            edit: 'تصحيح',
            add: 'إضافة',
            delete: 'حذف',
            addNode: 'إضافة العقدة',
            updateNode: 'تحديث العقدة',
            treeView: 'عرض الشجرة',
            parent: 'الأبوين',
            englishName: 'الإسم باللغة الانجليزية',
            arabicName: 'الإسم باللغة العربية',
            close: 'قريب',
            type: 'نوع',
            level: 'المستوى',
        },

        adminPanelKeys: {
            title: "عنوان",
            permission: "الصلاحية",
            englishName: 'الإسم باللغة الانجليزية',
            arabicName: 'الإسم باللغة العربية',
            shortName: 'إسم مختصر',
            subject: 'الموضوع',
            action: 'الإجراءات',
            isMotherComp: "الشركة الأم؟",
            employeeNumber: "الرقم الوظيفي",
            sector: "تصنيف القطاع",
            clientDesc: "وصف الجهة",
            city: "المدينة",
            industry: "مجال العمل",
            isActive: "نشط",
            reasonToDeative: "سبب الغاء التفعيل",
            phnNumber: "رقم التواصل",
            userName: "إسم المستخدم",
            email: "البريد الإلكتروني",
            mobile: "التليفون المحمول",
            designation: "المسمى الوظيفي",
            accType: "نوع الحساب",
            role: "الدور",
            status: "الحالة",
            entity: "الجهه",
            reason: "سبب عدم التفعيل",
            createdOn: "تم إنشاؤها بتاريخ",
            modifiedOn: "تم تعديله بتاريخ",
            roleList: "قائمة المهام",
            lookupList: "قائمة البحث",
            contactList: "قائمة المستخدمين",
            entityList: "قائمة الجهات",
            mailTemplateList: "قائمة نماذج الايميلات",
            createContact: "إنشاء مستخدم",
            createEntity: "إنشاء الجهة",
            createLookup: "إنشاء نوع بحث",
            createMailTemplate: "إنشاء قالب البريد الإلكتروني",
            createRole: "انشاء دور",
            addEmailTemplate: "إضافة قالب البريد الإلكتروني",
            Department: "إدارة",
            Employee: "موظف",
            Status: "الحالة",
            reason_for_sci: "سبب الاقتراح / الشكوى / الاستفسار",
            reason_for_: "سبب",
            EnglishTitle: 'العنوان باللغة بالانجليزية',
            ArabicTitle: 'الإسم باللغة العربية',
            for: 'لى ',
            isDefault: 'اختيار تلقائ',
        },

        ReviewHRPolicies: {
            ReasonsOfRequest: 'السياسة المراد تغييرها',
            Entity_formal_request: 'الطلب الرسمي من الجهة',
            standard_comparisons: 'مقارنات معيارية',
            studies: 'الدراسات و المستندات الداعمة',
            HRA_Employee_study_maker: 'معد الدراسة',
            HRA_Study: 'الدراسة المعدة',
        },
        Disciplinaryboard:
        {
subject:'طلب تشكيل مجلس تأديب',
intro:'الشروط الخاصة',
requestype:'نوع المجلس',
permanent:'دائم',
temporary:'مؤقت ',
EmployeeInfoSubject:'بيانات الموظف المحال لمجلس التأديب',
reason:'سبب الإحالة',
invalidPhoneNumber:'رقم الهاتف غير صحيح',
removeEmployee:'- حذف الموظف',
addemployee:'+ إضافة موظف',
Violations:'المخالفات المنسوبة إلى الموظف',
investigation:'وقائع ومستندات التحقيق',
requesterinfo:'بيانات مقدم الطلب',
entityname : 'اسم الجهة',
requestername:'اسم الشخص المسئول',
phoneNumber :'رقم الهاتف',
email:'البريد الإلكتروني',
memberslist:'قائمة المرشحين لعضوية مجلس التأديب',
membername:'اسم العضو',
title:'المنصب فى المجلس',
jobdegree:'الدرجة الوظيفية',
workentity:'جهة العمل',
addnewmember:'+ اضافة عضو اخر',
removemember:'حذف العضو',
formalrequestletter:'طلب تشكيل مجلس التأديب معتمد من رئيس الجهة',
EmployeeNameErr:'يجب ادخال اسم الموظف',
EmployeeJobTitleErr:'يجب ادخال المسمى الوظيفى',
EmpolyeeJobDegreeErr:'يجب ادخال الدرجة الوظيفية',
EmployeePhoneNumberErr:'يجب ادخال رقم الهاتف و التأكد من صحة الرقم',
EmployeeViloationErr:'يجب ارفاق المخالفات المنسوبة إلى الموظف ',
EmployeeinvestigationErr:'يجب ارفاق وقائع ومستندات التحقيق',
EmployeeReasonErr:'يجب ادخال سبب الإحالة',
MembernameErr:'يجب ادخال اسم العضو',
jobdegreeErr:'يجب ادخال الدرجة الوظيفية للعضو',
EntityErr:'يجب اختيار جهة عمل العضو',
MembertitleErr:'يجب ادخال منصب العضو فى المجلس',
RequestAttach:'مرفقات الطلب',
RequestDetails:'بيانات الطلب',
Requestnumber:'رقم الطلب',
memberslengtherror:'يجب ألا يقل عدد أعضاء مجلس التأديب بما فيهم رئيس مجلس التأديب عن ثلاثة أعضاء على الأقل من الدرجة الأولى فما فوق وأن يكون عددهم فردياً',
isinverstagiondone:'هل تم تشكيل لجنة تحقيق؟',
Yes:'نعم',
No:'لا',
isinverstagiondoneErr:'يجب أن يتم تشكيل لجنة تحقيق أولاً',
boradmember:'عضواً',
boardBoss:'رئيساً',
EntityErrChriman:'يُراعى ألا يكون رئيس مجلس التأديب من ذات الجهة الحكومية طالبة تشكيل المجلس',
dublicateMembertitleErr:'لا يمكن أن يكون للمجلس أكثر من رئيس',
BoardMemberJobTitle:'المسمى الوظيفي',
MemberJobTitleErr:'يجب ادخال المسمي الوظيفي',
       },
        Promotion:
        {
            subject:'استثناء من شرط المؤهل العلمي والخبرة في التعيين أو الترقية',
            Describtion:'الاستثناء من شرط المؤهل العلمي عند تعيين/ ترقية موظف حكومي بحسب ما نص عليه القانون واللائحة التنفيذية.',
            Condtion:'تقدم الجهة الحكومية السيرة الذاتية مرفقة بكافة الوثائق المطلوبة من قبل الهيئة للترقية أو التعيين. يقوم الباحث المختص بهيئة الموارد البشرية بتدقيق البيانات الواردة بكتاب الجهة الحكومية المعنية، ومن ثم إعداد دراسة شاملة للرفع لمكتب ابوظبي التنفيذي بعد اخذ الاعتمادات من مدير الإدارة والقطاع والمدير العام والاعتماد النهائي من رئيس الهيئة. بعد صدور قرار المجلس التنفيذي بحسب الأحوال، يتم الرد على الجهة الحكومية المعنية بمضمون القرار بالموافقة أو عدم الموافقة على الطلب.',
            DurationOfService:'10 أيام عمل',
            requestype:'نوع الطلب',
            hiring:'تعيين',
            promotion:'ترقية',
            jobDetails:'تفاصيل الوظيفة',
            jobTybe:'نوع الوظيفة',
            managmentjob:'إدارية',
            technicianjob:'فنية',
            Reason:'مبررات طلب الاستثناء',
            EmployeeCV:'السيرة الذاتية',
EmployeePhoto:'صورة شخصية',
positionofthenominatedposition:'موقع الوظيفة المرشح عليها من الهيكل التنظيمي المعتمد',
jobdescription:'الوصف الوظيفي المعتمد للوظيفة مع تحديد الوظيفة فنية أم إدارية',
HumanResourcesCommittee:'نسخة من محضر لجنة الموارد البشرية المعتمد',
EmployeeNominationForm:'نموذج ترشيح موظف',
EducationalQualifications:'نسخة من المؤهلات العلمية',
ExperienceCertificates:'نسخة من شهادات الخبرة',
competencyreports:'نسخة من تقريري الكفاءة -لآخر سنتين',
passport:'جواز السفر',
Summaryofregistrationforcitizens:'خلاصة القيد للمواطنين',
IDcard:'بطاقة الهوية',
CID:'البحث الجنائي',
Medicalfitnesscheckup:'فحص اللياقة الطبية',
Anotheremployeesselfstatement:'بيان آخر إجراء ذاتي للموظف',
        },
        SickLeave:
        {
             subject:'الاستثناء من خصم راتب الموظف لتجاوز مدة الإجازة المرضية',
           
LeaveSubject:'تفاصيل الإجازة',
YearlyLeaveBalance:'الرصيد السنوي لإجازات الموظف',
LeavePeriod:'الفترة الزمنية للإجازة المرضية',
BacktoWorkDate:'تاريخ مباشرة العمل بعد العودة من الإجازة المرضية',
LeveSalryDetails:'حالة صرف الراتب ',
DescripeIssue:'رصد المخالفات فى شأن الاجرئات الادارية',
EmployeePassport:"جواز السفر",
EmployeeEID:"الهوية الإماراتية",
SickLeaveHealthreport:"التقرير الطبي من اللجنة المختصة",
SickLeaveEmbassyLetter:"كتاب ايفاد الجهة أو الملحقية لسفارة دولة الإمارات العربية المتحدة فى بلد العلاج",
SickLeaveHRCom:"نسخة من محضر لجنة الموارد البشرية معتمد من رئيس الجهة او من يفوضه",
EmployeeIncome:"مستندات ثبوتية للالتزامات المالية للموظف أو فى حال عدم قدرة الموظف على الوفاء بالالتزامات المالية",

        },
Substitutionplan:
{
    Year:"السنة",
Subject:"خطة إحلال للموظف قبل بلوغه سن الإحالة للتقاعد",
Menusubject:"خطة أحلال",
Quarter:"الربع السنوي لسنة "+new Date().getFullYear(),
q1:"الربع الأول",
q2:"الربع الثاني",
q3:"الربع الثالث",
q4:"الربع الرابع",
sucess:"تم إرسال الخطة بنجاح",
Template:"تحميل نموذج خطة الاحلال الوظيفي",
Entity:"الجهة",
QuarterTitle:"الربع السنوي",
Plan:"الخطة",
},
PoliceLib:
{
    subject:"مكتبة السياسات", 
},
dary:
{
    subject:"حساب متوسط السكن", 
},
        Circularization:
        {
            Date:"تاريخ التعميم",
            "Page":"صفحة -"
,"Next":"التالى >"
,"Previous":" < السابق",
            Circularsendsucess:'تم ارسال التعميم بنجاح',
            subject:'التعاميم',
            MessageSubject:"الموضوع",
        MessageDetails:"الرسالة",
        Entityes:"الجهات",
        Attachments:"المرفقات",
        Createduser:"اسم المرسل",
        },
        ActionPlans:
        {
            Expand:'توسيع المشروع',
            collapse:'طوي المشروع',
            
            OverAll:"الجميع",
            ProjectsKPI:"المشاريع / المهام",
            EmployeeInfo:"الموظفين",
            NumberofCompletedProjectsWithBudget:'عدد المشاريع / المهام التي أكتملت بوجود ميزانية',
            NumberofCompletedProjectsWithoutBudget:'عدد المشاريع / المهام التي أكتملت بدون ميزانية',
            MertricDesctiption:'حدد المقياس الصحيح الذي ستستخدمه لمتابعه التقدم فى المشروع / المهمه',
            NumberofApprovedInitiatives:'عدد المبادرات المعتمدة',
NumberofApprovedKeyProjects:'عدد المشاريع / المهام التي تندرج تحت المبادرة',
NumberofKeyProjectsTasksundereachInitiativeswithBudget:'عدد المشاريع / المهام التي تندرج تحت المبادرة بوجود ميزانية',
NumberofKeyProjectsTasksundereachInitiativeswithOutBudget:'عدد المشاريع / المهام التي تندرج تحت المبادرة - بدون ميزانية',
NumberofCompletedProjectsTasksaccordingtotheplan:'عدد المشاريع / المهام المكتملة وفق الميزانية',
NumberofCompletedProjectTasksProjectsoutoftheplan:'عدد المشاريع / المهام المكتملة خارج الميزانية',
NumberofCancelledProjectsTasksProjects:'عدد المشاريع / المهام التي تم حذفها',
NumberofChangedProjectsTasksProjects:'عدد المشاريع / المهام التي تم تغييرها',
NumberofNationalEmployeeswhohavePersonalDevelopmentPlan:'عدد الموظفين المواطنين الذين لديهم خطط تطوير شخصية',
NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan:'عدد الموظفين غير المواطنين الذين لديهم خطط تطوير شخصية',
NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans:'عدد الموظفين المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية',
NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans:'عدد الموظفين الغير مواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية',
NumberofNationalEmployeeswhohaveTrainingPlan:'عدد الموظفين المواطنين الذين لديهم خطة تدريب وتطوير',
NumberofNonNationalEmployeeswhohaveTrainingPlan:'عدد الموظفين الغير مواطنين الذين لديهم خطة تدريب وتطوير',
NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan:'عدد الموظفين المواطنين الذين ليس لديهم خطط تطوير شخصية',
NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans:'عدد الموظفين المواطنين الذين لم يتم تضمينهم في مبادرات وخطط الصحة المؤسسية',
NumberofNationalEmployeeswhohavenotTrainingPlan:'عدد الموظفين المواطنين الذين ليس لديهم خطة تدريب وتطوير',

            KPI:'المشاريع / المهام الرئيسية- مؤشرات الأداء الرئيسية',
            Edit:'تعديل',
            ViewDetails:'عرض التفاصيل',
            sucess:'تم إدخال المبادره بنجاح',
            subject:'مبادرات الصحة المؤسسية', 
            
            Defniations:'التعاريف',
            EmployeesTotalNumber:'العدد الكلي للموظفين',
            UAEEmployeesTotalNumber:'العدد الكلي للموظفين المواطنين',
            NonEmployeesTotalNumber:'العدد الكلي للموظفين غير المواطنين',
            NNUAEHAvePDP:'العدد الكلي للموظفين المواطنين الذين لديهم خطط تطوير شخصية',
            NNNonUAEHAvePDP:'العدد الكلي للموظفين غير مواطنين الذين لديهم خطط تطوير شخصية',

            NNUAEIncluded:'العدد الكلي للموظفين المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية',
            NNNONUAEIncluded:'العدد الكلي للموظفين غير المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية',
            
            NNEHave:'العدد الكلي للموظفين المواطنين الذين لديهم خطة تدريب وتطوير',
           
            
            NNonUAEHave:'العدد الكلي للموظفين غير المواطنين الذين لديهم خطة تدريب وتطوير',
            FocalPoint:'بيانات منسق الجهة الحكومية',
            FocalPointName:'اسم المنسق',
            FoaclPointTitle:'المسمى الوظيفي',
            FocalPointMobile:'رقم الموبايل',
            FocalPointEmail:'البريد الإلكتروني',
            FocalPointPhone:'رقم هاتف المكتب',
            removemember:'حذف المنسق',
            addnewmember:'إضافة المنسق',

            Initiatives:'مبادرات الصحة المؤسسية',
            InitiativeName :'اسم المبادرة',
            EntitName:'اسم الجهة',
            SerialNumber:'التسلسل',
            StartDate:'تاريخ البداية',
            EndDate:'تاريخ الانتهاء',
InitiativeKeyProjectsTasks:'أسماء المشاريع / المهام الرئيسية',
InitiativeBudget:'ميزانية المبادرة',
InitiativeStartDate:'تاريخ البدء',
InitiativeEndDate:'تاريخ الانتهاء',
InitiativeProjectsTasksBudget:'ميزانية المشروع / المهمة',
InitiativeProjectsTasksStatus:'حالة المشاريع / المهام ',
InitiativeDelayJustification:'أسباب',
InitiativeDelayJustificationTxt:'تفاصيل الأسباب',
InitiativeSupportDocuments:'المستندات الداعمة',
MainTheme:'المحاور الرئيسية للصحة المؤسسية',
MainPractice:'المحاور الفرعية للصحة المؤسسية',
InitiativeKeyProjectsTasksTitle:'اسم المشروع / المهمة',
OtherDescrption:'وصف الأسباب الأخرى',
addProject:'إضافة مشروع /  مهام',
removProject:'حذف مشروع /  مهام',
Nameofresponsible:'اسم مسؤول المبادرة',
Roleofresponsible:'المسمى الوظيفي للمسؤول',
Metric:'ألية متابعة / قياس المهمة / المشروع',
ExpectedOutcomes:'مخرجات المبادرة المتوقع',
ActualOutcomes:'مخرجات المبادرة',
InitiativeStatus:'حالة المبادرة',
InitiativeUpdate:'يجب التعديل',




        },
        WorkShopErrorMessage:
        {
            invalidEmail: 'يجب ادخال بريد الإلكتروني صحيح',
        
                AttendanceEmailinvalidEmail: 'يجب ادخال بريد الإلكتروني صحيح للحضور',
        
                invalidPhoneNumber: 'يجب ادخال رقم هاتف صحيح',
        
                AttendanceinvalidPhoneNumber: 'يجب ادخال رقم هاتف صحيح للحضور',
                AttendancenameErr: "يجب ادخال اسم للحضور ",
                AttendanceJobDegreeErr: "يجب ادخال الدرجة الوظيفية للحضور", AttendanceJobTitleErr: "يجب ادخال  المسمي الوظيفي للحضور ",
                AttendancePhoneErr: "يجب ادخال رقم هاتف للحضور",
                AttendanceEmailErr: "يجب ادخال بريد اليكتروني للحضور",
                                                                                                                       
                                                
            },
        ActionPlansErrorMessage:
    {

        invalidEmail:'يجب ادخال بريد الإلكتروني صحيح',

focalpointinvalidEmail:'يجب ادخال بريد الإلكتروني صحيح للمنسق',

invalidPhoneNumber:'يجب ادخال رقم هاتف مكتب صحيح',

focalpointinvalidPhoneNumber:'يجب ادخال رقم هاتف مكتب صحيح للمنسق',
focalpointinvalidMobile:'يجب ادخال رقم موبايل صحيح للمنسق',

        ProjectBudgetError:"يجب أن يكون مجموع ميزاينة المشاريع المهام أقل من او مساوي لميزانية المبادرة",
        DateValidation:"يجب أن يكون تاريخ الانتهاء أكبر من  تاريخ البدء",

        InitiativesNameErr:"يجب ادخال اسم المبادرة",
        InitiativeBudgetErr:"يجب ادخال ميزانية المبادرة",
        NameofresponsibleErr:"يجب ادخال اسم مسؤول المبادرة",
        RoleofresponsibleErr:"يجب ادخال المسمى الوظيفي للمسؤول",
        ExpectedOutcomesErr:"يجب ادخال مخرجات المبادرة المتوقع",
        InitiativeStatusErr:"يجب ادخال حالة المبادرة",
        SupportDocumentsErr:"يجب ارفاق المستندات الداعمة",

        FocalPointNameErr:'يجب ادخال اسم المنسق',
FoaclPointTitleErr:'يجب ادخال المسمى الوظيفي',
FocalPointPhoneErr:'يجب ادخال رقم هاتف مكتب المنسق',
FocalPointMobileErr:'يجب ادخال رقم موبايل المنسق',
FocalPointEmailErr:'يجب ادخال البريد الإلكتروني للمنسق',

        requesterNameErr:'يجب ادخال اسم الشخص المسئول',
        requesterphoneNumber:'يجب ادخال رقم الهاتف',
rquesterEmail:'يجب ادخال البريد الإلكتروني',
EmployeesTotalNumber:'يجب ادخال العدد الكلي للموظفين',
UAEEmployeesTotalNumber:'يجب ادخال العدد الكلي للموظفين المواطنين',
NonEmployeesTotalNumber:'يجب ادخال العدد الكلي للموظفين غير المواطنين ',
NNUAEHAvePDP:'يجب ادخال العدد الكلي للموظفين المواطنين الذين لديهم خطط تطوير شخصية',
NNNonUAEHAvePDP:'يجب ادخال العدد الكلي للموظفين غير مواطنين الذين لديهم خطط تطوير شخصية',
NNUAEIncluded:'يجب ادخال العدد الكلي للموظفين المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية ',
NNNONUAEIncluded:'يجب ادخال العدد الكلي للموظفين غير المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية',
NNEHave:'يجب ادخال العدد الكلي للموظفين المواطنين الذين لديهم خطة تدريب وتطوير',
NNonUAEHave:'يجب ادخال العدد الكلي للموظفين غير المواطنين الذين لديهم خطة تدريب وتطوير',
UAEEmployeesTotalNumberErr:'يجب أن يكون العدد الكلي للموظفين المواطنين مساوي أو اقل من العدد الكلي للموظفين',
NonEmployeesTotalNumberErr:'يجب أن يكون حاصل جمع العدد الكلي للموظفين المواطنين و العدد الكلي للموظفين غير المواطنين مساوى للعدد الكلي للموظفين',
NNUAEHAvePDPErr:'يجب أن يكون العدد الكلي للموظفين المواطنين الذين لديهم خطط تطوير شخصية مساوي أو اقل من العدد الكلي للموظفين المواطنين',
NNNonUAEHAvePDPErr:'يجب أن يكون العدد الكلي للموظفين غير المواطنين الذين لديهم خطط تطوير شخصية مساوي أو اقل من العدد الكلي للموظفين غير المواطنين',
NNUAEIncludedErr:'يجب أن يكون العدد الكلي للموظفين المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية  مساوي أو اقل من العدد الكلي للموظفين المواطنين',
NNEHaveErr:'يجب أن يكون العدد الكلي للموظفين المواطنين الذين لديهم خطة تدريب وتطوير  مساوي أو اقل من العدد الكلي للموظفين المواطنين',
NNonUAEHaveErr:'يجب أن يكون العدد الكلي للموظفين غير المواطنين الذين لديهم خطة تدريب وتطوير مساوي أو اقل من العدد الكلي للموظفين غير المواطنين',
NNNONUAEIncludedErr:'يجب أن يكون العدد الكلي للموظفين غير المواطنين الذين تم تضمينهم في مبادرات وخطط الصحة المؤسسية مساوي أو اقل من العدد الكلي للموظفين غير المواطنين',
/************** */
ProjectBudgetEmptyErr:'يجب ادخال ميزانية المشروع / المهمة او ادخال صفر فى حالة ليس هناك ميزانية',
StartDateErr:'يجب ادخال تاريخ البدء',
EndDateErr:'يجب ادخال تاريخ الانتهاء',
MetricErr:'يجب ادخال ألية متابعة / قياس المهمة / المشروع',
ProjectStatusEmptyErr:'يجب ادخال حالة المشاريع / المهام',
PrjectStatusisIntitaionErr:'يجب تغيير حالة المشروع',
ProjectReasonErr:'يجب ادخال أسباب حالة المشروع',
ProjectOtherReasonDescriptionErr:'يجب ادخال وصف الأسباب الأخرى',
ProjectReasonTextErr:'يجب ادخال تفاصيل الأسباب',
ProjectAttachErr:'يجب ارفاق المستندات الداعمة للمشروع',
PrjectTitleErr:'يجب ادخال عنوان المشروع',
InitiativeStatusisIntitaionErr:'يجب تغيير حالةالمبادرة',
/******** */
    },
        WorkShop:
        {
            Attendancename:'الإسم',
AttendanceJobTitle:'المسمي الوظيفي',
AttendanceJobDegree:'الدرجة الوظيفية',
AttendanceEmail:'البريد الاليكتروني',
AttendancePhone:'رقم الهاتف',
            AtteandanceDetails:'بيانات الحضور',
            RequireAddAttandanceDetails:'مطلوب ادخال بيانات الحضور',
            cancelBookingMessageSucess:'تم الغاء حجز ورشة العمل بنجاح',
            workshopBookEdit:'تعديل الحجز',
            cancelBooking:'الغاء الحجز',
            BookWorkShop:'حجز',
            subject:'تنظيم ورش عمل توعوية (اللائحة التنفيذية وسياسات الموارد البشرية)',
WorkShopInfo:'بيانات الورشة',
Title:'عنوان الورشة',
DiscussPoints:'النقاط التى ستتم مناقشتها بالورشة',
Presentation:'عرض تقديمى',
WorkShopTimes:'مواعيد الورشة',
AttandanceNumbeRemaing:'عدد الحضور - شاغر',
AttandanceNumber:'عدد الحضور',
AttandanceNumberBooked:'عدد الحضور - محجوز',
WorkShopDate:'تاريخ الورشة',
WorkShopTimefrom:'بداية الورشة',
WorkShopTimeTo:'نهاية الورشة',
Location:'مكان الانعقاد',
AddNewDate:'اضافة موعد جديد',
Submit:'حفظ الورشة',
Cancel:'الغاء',
Edit:'تعديل',
WorkShopTime:'موعد الورشة',
ViewDates:'مواعيد الورشه',
userinformation:'بيانات مقدم الطلب',
EntitName:'اسم الجهة',
Person:'اسم الشخص المسئول',
PhoneNumber:'رقم الهاتف',
Email:'البريد الاليكترونى',
invalidAttandanceNumberErr:'عدد الحضور اكبر من العدد المتبقى يرجى العلم ان العدد المتبقى هو ',
MaxAllowedAttMessage:'عدد الحضور المتبقى هو',
cantbookedzeroErr:'يجب ان يكون عدد الحضور شخص واحد على الأقل',
WorkShopAdd:'إضافة ورشة جديدة',
WorkShopBookedList:'الحجوزات القائمة',
WorkShopManage:'قائمة الورش',

WorkShopBooking:'حجز ورشة توعية',
WorkShopBookinglist:'قائمة الورش',
WorkShopMyBookedList:'مواعيدى',
WorkShopADS:' تطبيق اللائحة التنفيذية لقانون الموارد البشرية',

        },
        Common:{
            EmployeeInfoSubject:'تفاصيل الموظف',
            EmployeeInfoJobTitle:'المسمى الوظيفي',
            EmployeeInfoJobDegree:'الدرجة الوظيفية',
            EmployeeInfoTotalSalary:'الراتب الإجمالي',
            EmployeeInfoBasicSalary:'الراتب الأساسي',
            EmployeeInfoRetirementcontributions:'اشتراكات التقاعد',

            // EmployeeInfoTotalSalary:'موقع الوظيفة المرشح عليها من الهيكل التنظيمي المعتمد ',
            // EmployeeInfoBasicSalary:'الراتب الأساسي',
            // EmployeeInfoRetirementcontributions:'اشتراكات التقاعد',

            EmployeeInfoEmployeeName:'إسم الموظف',
            EmployeeInfoEmirateID:'رقم الهوية',
            EmployeeInfoEmployeeNumber:'الرقم الوظيفي',
            EmployeeInfoEmployeeUID:'الرقم الموحد للموظف',
            SalaryInfo:'تفاصيل الراتب',
            // SalaryInfo:'تفاصيل الوظيفة',

           
        },
        HRAPolicies: {
            ReasonsOfRequestHousing:'مبررات الطلب',
            ReasonsOfRequest: 'السياسة المراد تغييرها',
            Entity_formal_request: 'طلب الجهة الرسمي',
            support_request: 'الدراسات و المستندات الداعمة',
            Housing_allowance: 'بدل السكن',
            Employee_number: 'الرقم الوظيفي',
            Employee_name: 'إسم الموظف',
            Basic_salary: 'الراتب الأساسي',
            Entity_name: 'إسم الجهة',
            Job_name: 'المسمى الوظيفي',
            Total_salary: 'الراتب الإجمالى',
            Retirement_Contributions: 'اشتراكات التقاعد',
            HRA_study: 'الدراسة المعدة',
            HRA_Employee_study_maker: 'موظف الجهة المسؤول عن اعداد الدراسة',
            Financial_cost: 'التكلفة المالية',
            file_required: 'هذا الحقل اجباري'
        },

        viewComponent: {
            EntityLegalOpinon:'الرأي القانوني',
            // organization structure 
            Requestnumber:'رقم',
            Requestreason:'أسباب الطلب',
            Degreeofrelationship:'درجة القرابة',
            ORG_Request: 'طلب انشاء هيكل تنظيمي',
            canSubmitORGRequest: "انشاء طلب",

            canViewORGRequest: "استعراض الطلب",
            canOpenCreationStage: "فتح مرحله الانشاء",
            canReviewORGRequest: "مراجعة الطلب",
            canEditORGRequest: "تعديل الطلب",
            canUpdateORGStatus: 'تعديل حالة الطلب',
            canAddORGComment: 'اضافة تعليق',
            canshowORGcomments: "استعراض التعليقات",
            canAssignORGDepartment: "اسناد للقسم",
            canAssignORGEmployee: "اسناد للموظف",
            canProvideORGFeddback: 'اضافة افادة',
            canViewORGFeddback: 'استعراض الافادة',
            canassignORGsector: "اسناد للقطاع",
            canassignORGGM: "تحويل للمدير العام",
            canassignORGBackToEntity: "ارجاع للجهة",
            canviewtree: "استعراض الهيكل",
            Subject: 'عنوان الطلب',
            Entityname: 'إسم الجهة',
            Entityphonenumber: 'رقم هاتف الجهة',
            Requestername: 'اسم مقدم الطلب',
            RequesterEmail: 'البريد الاليكتروني',
            Requesterphonenumber: 'هاتف المكتب',
            Requestermobilenumber: 'هاتف مقدم الطلب',
            Requesterjob: "وظيفة مقدم الطلب",
            employename: 'إسم الموظف',
            employeeage: 'سن الموظف',
            employeename: "إسم الموظف",
            dob: 'تاريخ الميلاد',


            jobtitle: "المسمى الوظيفي",
            emiratesid: 'بطاقة الهوية الاماراتية',

            familynumber: 'رقم الاسرة',
            unifiednumber: 'الرقم الموحد',
            // retirementcontribution:'retirementcontribution',

            Dateofhiring: 'تاريخ التعيين',
            CurrentGrade: 'الدرجة',
            Efficiency: 'تقييم الكفاءة',
            extendedservice: 'تمديد خدمة سابقاً',
            Salary: 'الراتب الإجمالي',
            Qualification: 'المؤهل العلمي',
            GraduationYear: 'سنة التخرج',
            DateofretirementAge: 'تاريخ بلوغ سن الإحالة للتقاعد',
            TypeofContract: 'نوع العقد',

            Passport: 'جواز السفر',
            Summaryofregistrationforcitizens: 'خلاصة القيد للمواطنين',
            Previousapprovalsfortheextension: 'الموافقات السابقة على التمديد',
            Qualifications: 'المؤهلات العلمية',
            Jobdescription: 'الوصف الوظيفي للوظيفة',
            Careersuccessionplan: 'خطة التعاقب الوظيفي للمواطنين',
            HRCom: 'محضر اجتماع لجنة الموارد البشرية',
            OtherAttach: 'مرفقات أخرى',
            PoistioninStructure:'موقع الوظيفة فى الهيكل التنظيمى المعتمد',
            prviousextensionwithoutapprovalfrom: 'مد خدمة الموظفة دون الحصول على موافقة مد الخدمة من',
            previousextensionwithoutapprovalto: 'مدد خدمة الموظفة دون الحصول على موافقة مد الخدمة إلي',
            Previousextionsfrom: 'فترة التمديد السابقة من',
            Previousextionsto: 'فترة التمديد السابقة إلي',
            ReplacementPlanFrom: 'خطة الاحلال/ التعاقب الوظيفي من',
            ReplacementPlanTo: 'خطة الاحلال/ التعاقب الوظيفي إلي',

            grade: "الدرجة الوظيفية",
            Requestnumber: 'رقم الطلب',
            steerfrom: 'تاريخ التمديد من',
            steerto: 'تاريخ التمديد إلى',
            dob: 'تاريخ الميلاد',
            contactnameen: 'الإسم باللغة الانجليزية',
            budgetapprovalamount: 'قيمة الموازنة المعتمدة',
            subjobname: 'المسمى الوظيفي',
            subcoordinatorname: 'إسم الموظف',
            subemail: 'البريد الإلكتروني',
            subcontactnumber: 'رقم التواصل',
            subphonenumber: 'رقم الهاتف المتحرك',
            no: '#',
            reason: 'السبب',
            step: 'المرحله',
            action: 'الإجراءات',
            detail: "التفاصيل",
            attachments: "المرفقات",
            comments: "الملاحظات",
            id: 'هوية شخصية',
            statusHistory: "تقرير الإجراءات",

            createdon: 'تاريخ الطلب',
            createdby: "مقدم الطلب",
            modifiedon: "تاريخ التعديل ",
            modifiedby: "تم التعديل بواسطة",
            status: "الحالة",
            statusid: "معرف الحالة",
            numberofdays: 'عدد الأيام',
            statusreason: "سبب الحالة",
            statusreasonid: "معرف سبب الحالة",
            requesttypeid: "نوع معرف الطلب",
            requesttype: "نوع الطلب",
            // subject: "موضوع",
            // Subject: "موضوع",
            assignedDepartmentid: "الإدارة المعني",
            assignedDepartmentname: "الإدارة المعني",
            assignedEmployeeid: "الموظف المعني",
            assignedEmployeeName: "الموظف المعني",
            details: "تفاصيل",
            applicantname: "الشخص المعني بالطلب",
            sciapplicantjob: 'المسمي الوظيفي',
            applicantjob: "وظيفة الشخص المعني بالطلب",
            applicantcontactnumber: "رقم التواصل",
            contactnumber: "رقم التواصل",
            datettoreply: "تاريخ الاستحقاق",
            informationrequired: "المعلومات المطلوبة",
            assignedentityname: "إسم الجهة المعنية",
            requesttypename: "نوع الطلب",
            // assigeddepartmentid: "رقم الإدارة المعني",
            // assigeddepartmentname: "الإدارة المعني",
            // assigedemployeeid: "الرقم الوظيفي",
            // assigedemployeename: "إسم الموظف",
            history: "سجل",

            //Review HR policy
            laststatusdate: "تاريخ الطلب",
            entityID: "رقم الجهة",
            entitynameen: "إسم الجهة",
            entitynamear: "إسم الجهة باللغه العربية",
            contactID: "رقم المستخدم",
            contactnameen: "إسم المستخدم باللغه الانجليزية",
            contactnamear: "إسم المستخدم باللغه العربية ",
            requestreason: "السبب",
            // studymakerid: "رقم  معد الدراسة",
            // studymaker: "إسم معد الدراسة",

            //Exception from AD Housing Policy
            contracttype: 'نوع العقد',
            financialcost: "التكلفة المالية",
            employeenumber: "الرقم الوظيفي",
            housingallowance: "بدل السكن",

            basicsalary: "الراتب الاساسي",
            jobname: "المسمى الوظيفي",
            totalsalary: "الراتب الإجمالى",
            retirementcontribution: "اشتراكات التقاعد",
            contractperiod: 'مدة عقد الموظف بالسنوات',

            //Secondment for Federal Agency
            secondmententityname: "إسم الجهة الطالبة للاعارة",
            secondmentfrom: "تاريخ بدء الاعارة",
            secondmentto: "تاريخ انتهاء الاعارة",
            employeeagreement: "موافقة الموظف",
            passtestperiod: "لابد ان يجتاز الموظف فترة الاختبار",
            performancedegree: "لابد ان يكون اداء الموظف اعلى من ضعيف",
            salaryownerid: "الجهة التي ستدفع راتب الموظف خلال فترة الاعارة",
            reasonForGovPaySalary:'سبب تحمل الجهة الحكومية للراتب',
            BorrowEntityID:'الجهة المستعيرة',
            // salaryowner: "متقاضي الراتب",

            //Exemption Patient

            durationofleave: "الفترة الزمنية لأجازه المرافقة الاستثنائية",
            dateofwork: "تاريخ مباشرة العمل بعد العودة من المرافقة",
            dateofsalarytermination: "تاريخ ايقاف الراتب",
            isdocumentofobligatory: "هل تتوفر موازنة",
            isrequriedtopaybybudget: "هل مطلوب صرف موازنة إضافية",

            isviolation: "مخالفة الإجراءات",
            budgetyear: "السنه",
            budgetvalue: " قيمة المبلغ المطلوب",
            annualleavebalance: "رصيد الاجازات السنوي",
           // justification: "اسباب المرافقه",
            justification: "اسباب الطلب",
            husbandwifework: "وظيفه الزوج/الزوجة",
            numberofchidren: "عدد الأبناء",
            placeofchildren: "مكان تواجد الأبناء فترة العلاج",
            workyear: "السنه التي يتوافر بها الموازنة",
            worksalarystatus: "حالة الراتب",
            workmonth: "الشهر",
            worktotalsalary: "إجمالي الرواتب",
            worknoofdays: "إجمالي عدد الأيام",
            workbasicsalary: "الراتب الأساسي",
            worktotaldays: "إجمالي عدد الأيام",
            // worktotalsalarytobepaid: "إجمالي المبالغ المطلوب صرفها",
            worktobeexempted: "المبلغ الإجمالى المطلوب اعفاء الموظف منه",
            // howtocalculateid: "كيفية حساب المبلغ",
            // howtocalculate: "كيفية حساب المبلغ",
            howtocalculaterule: "كيفية حساب المبلغ",
            violationdetail: "تفاصيل مخالفة القانون",
            requesttypetitle: "نوع الطلب",
            budgetavailableyear: "موازنة السنه المتاحه",

            //Bonus Request
            isbudgeted: 'هل يوجد موازنة',
            email: 'البريد الإلكتروني',
            coordinatorname: 'المنسق',
            budget: 'الموازنة',
            yearachievment: 'سنة الانجاز',
            bonusreason: 'مبررات الطلب',
            year1: 'السنة الاولى',
            emiratizationpercentage: 'نسبة التوطين',
            year2: 'السنة الثانية',
            emiratizationpercentage2: 'نسبة النوطين',
            totalemployeedetails: 'العدد الإجمالى لموظفي الجهة',
            iscontracted: 'موظفي العقود الخاصة',
            totalemployees: 'العدد الإجمالي لموظفي الجهة',
            nonlocalwithprivate: 'عدد غير المواطنين بعقود خاصة',
            localwithprivate: 'عدد المواطنين بعقود خاصة',
            nonlocalwithpermanent: 'عدد غير المواطنين بعقود دائمة',
            localwithpermanent: 'عدد المواطنين بعقود دائمة',
            excellentemployees: 'عدد الموظفين الحاصلين على درجة الممتاز',
            verygoodemployees: 'عددالموظفين الحاصلين على درجة جيد جدا',
            goodemployees: 'عدد الموظفين الحاصلين على درجة جيد',
            acceptableemployees: 'عدد الموظفين الحاصلين على درجة مفبول',
            equation: 'المعادلة المستخدمة',
            gradeemployees: 'الدرجه الوظيفية',

            //Ammend Employee salary
            nationality: "جنسية الموظف",
            age: "سن الموظف",
            currentbasicsalary: "الراتب الأساسي الحالى",
            proposedbasicsalary: "الراتب الأساسي المقترح",
            percentageincrease: "نسبة التعديل المقترحة",
            designationdate: " الحكومية في الجهة الحكومية",
           // qualification: "المؤهل العلمي",
            exprienceafterqualification: "الخبرة الإجمالية بعد المؤهل العلمي بالسنوات",
            totalexpeirence: "الخبرة الإجمالية",
            annualprevevaluation: "التقييم السنوي للعام الماضي",
            annualcurrevaluation: "نتيجة التقييم السنوي المعتمد للعام الحالى",

            jobrank: "تصنيف الوظيفة",
            // proceduretype: "نوع الإجراء",
            financialratio: "قيمة التعديل  المطلوبه ",
            proceduredate: "تاريخ آخر إجراء تم على راتب الموظف",
            reasontomodify: "مبررات طلب تعديل الراتب للموظف",
            baseofincrease: "الأسس المتبعة في تحديد المعدل التعديل",

            //Ammend salary scale
            principle: "الأسس المستخدمة في التعديل",
            fundingid: "مصدر التمويل",
            isallocated: "هل يوجد مخصصات مالية ضمن الموازنة",
            currentannualbenefits: "الراتب الأساسي الحالى والعلاوات",
            proposedannualbenefits: "الراتب الأساسي المقترح والعلاوات",
            currentfinancialeffect: "الأثر المالى الحالى",
            proposedfinancialeffect: "الأثر المالى المقترح",
            currentscalechallenge: "تحديات جدول الرواتب الحالى",
            proposedscalechallenge: "تحديات جدول الرواتب المقترح",
            currentincreaeaverage: "متوسط الزيادة / التخفيض الإجمالية",
            currentdecreaseaverage: "متوسط الزيادة / التخفيض للجدول المقترح",
            currentapplyeffect: "التأثير المحقق على الجدول الحالى",
            proposedapplyeffect: "التأثير المحقق على الجدول المقترح",

            // retirment 
            reasonforextending: 'مبررات مد الخدمة',
            isjurisdiction: "هل توجد موافقات لتمديد خدمة سابقة؟",
            // annualperformanceevaluation: 'تقييم الأداء السنوي',
            EnglishTitle: 'العنوان باللغة بالانجليزية',
            ArabicTitle: 'العنوان باللغة العربية',

            //contacts
            englishName: 'الإسم باللغة الانجليزية',
            arabicName: 'الإسم باللغة العربية',
            phonenumber: "رقم الهاتف",
            username: "إسم المستخدم",
            emailAddress: "البريد الإلكتروني",
            mobilenumber: "رقم التواصل",
            designation: "المنصب",
            accountType: 'نوع الحساب',
            role: "الصلاحية",

            //entities
            shortName: 'إسم مختصر',
            empNumber: 'الرقم الوظيفي',
            isMotherComp: 'هل هي الشركه الام ؟',
            industry: 'مجال العمل',
            city: 'المدينة',
            logo: 'شعار الجهة',
            clientDescr: 'وصف الجهة',
            isActive: 'نشط',
            isDefault: 'تلقائى',
            reasonToDeative: 'سبب عدم التفعيل',
            phnNumber: 'رقم الهاتف',
            sector: 'القطاع',

            //Roles
            canassignscigm: "تحويل لمكتب المدير العام",
            canassignscibacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitscirequest: "تقديم طلب اقتراح / شكوى/استفسار",
            canreviewscirequest: "مراجعة طلب اقتراح / شكوى/استفسار",
            caneditscirequest: "تعديل طلب اقتراح / شكوى/استفسار",
            canupdatescistatus: 'تحديث طلب اقتراح / شكوى/استفسار',
            canaddscicomment: 'ادراج تعليق على طلب اقتراح / شكوى/استفسار',
            canassignscidepartment: "اسناد طلب اقتراح / شكوى/استفسار إلى إدارة   ",
            canasignsciemployee: "اسناد  طلب اقتراح / شكوى/استفسار إلى موظف",
            canprovidescifeedback: 'اعطاء افادة بخصوص اقتراح / شكوى/استفسار',
            canviewscifeedback: 'عرض افادة بخصوص اقتراح / شكوى/استفسار',
            canassignscisector: "اسناد طلب اقتراح / شكوى/استفسار إلى قطاع",
            canshowscicomments: "عرض تعليق على اقتراح / شكوى/استفسار",

            canassignirgm: "تحويل لمكتب المدير العام",
            canassignirbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitirrequest: 'تقديم طلب معلومات',
            canviewirrequest: "عرض طلب معلومات",
            caneditirrequest: "تعديل طلب معلومات",
            canupdateirstatus: "تحديث حالة طلب معلومات",
            canshowircomments: "عرض تعليق على طلب معلومات",
            canaddircomments: "ادراج تعليق على طلب معلومات",
            canassignirdepartment: "اسناد إدارة طلب معلومات",
            canassignirsector: "اسناد طلب معلومات إلى قطاع",
            canprovideirfeedback: 'اعطاء افادة بخصوص طلب معلومات',
            canreceiveirfeedback: "استلام افادة بخصوص طلب معلومات",

            canassignpatientgm: "تحويل لمكتب المدير العام",
            canassignpatientbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitpatientrequest: "تقديم طلب الإعفاء من مرافقة المريض",
            canviewpatientrequest: "مراجعة طلب الإعفاء من مرافقة المريض",
            caneditpatientrequest: "تعديل طلب الإعفاء من مرافقة المريض",
            canupdatepatientstatus: "تحديث حالة طلب الإعفاء من مرافقة المريض",
            canshowpatientcomments: "عرض تعليق على طلب الإعفاء من مرافقة المريض",
            canaddpatientcomments: "اضافة تعليق على طلب الإعفاء من مرافقة المريض",
            canassignpatientdepartment: "تحويل استثناء مرافقه مريض إلى الإدارة المختص",
            canassignpatientemployee: "تحويل استثناء مرافقه مريض إلى الموظف المختص",
            canassignpatientsector: "تعيين الإعفاء من قطاع المريض المرافق",
            canprovidepatientstudy: "تجهيز ملاحظات علي استثناء مرافقه مريض ",
            canreceivepatientstudy: "استلام ملاحظات علي استثناء مرافقه مريض",

            canassignhrgm: "تحويل لمكتب المدير العام",
            canassignhrbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitreviewhrrequest: "تقديم طلب مراجعة سياسات الموادر البشرية",
            canviewreviewhrrequest: "عرض طلب مراجعة سياسات الموادر البشرية",
            caneditreviewhrrequest: "تعديل طلب مراجعة سياسات الموادر البشرية",
            canupdatereviewhrstatus: "تحديث طلب مراجعة سياسات الموادر البشرية",
            canaddreviewhrcomments: "اضافة تعليق على طلب مراجعة سياسات الموادر البشرية",
            canshowreviewhrcomments: "عرض تعليق على طلب مراجعة سياسات الموادر البشرية",
            canassignhrsector: "تعيين سياسات السياسات",
            canassignhrdepartment: "تعيين الإدارة المختص بمراجعه سياسه الموارد البشرية ",
            canassignhremployee: "تعيين الموظف المختص بمراجعه سياسه الموارد البشرية ",
            canprovidehrstudy: "تجهيز دراسه  مراجعه سياسه الموارد البشرية",
            canreceivehrstudy: "استلام مراجعه سياسه الموارد البشرية",

            canassignhousinggm: "تحويل لمكتب المدير العام",
            canassignhousingbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitadhousingrequest: "تقديم طلب الإعفاء من سياسة الاسكان في ابوظبي",
            canviewadhousingrequest: "عرض طلب الإعفاء من سياسة الاسكان في ابوظبي",
            caneditadhousingrequest: "تعديل طلب الإعفاء من سياسة الاسكان في ابوظبي",
            canupdateadhousingstatus: "تحديث طلب الإعفاء من سياسة الاسكان في ابوظبي",
            canaddadhousingcomments: "اضافة تعليق على طلب الإعفاء من سياسة الاسكان في ابوظبي",
            canshowadhousingcomments: "عرض تعليق على طلب الإعفاء من سياسة الاسكان في ابوظبي",
            canassignhousingdepartment: "تعيين الإدارة المختص باستثناء من سياسه سكن ابوظبي",
            canassignhousingemployee: "تعيين الموظف المختص بطلب الاستثناء من سياسه سكن ابوظبي",
            canassignhousingsector: "تعيين القطاع المختص بطلب الاستثناء من سياسه سكن ابوظبي",
            canprovidehousingstudy: "اضافه حاله المناقشه للاستثناء من سياسه سكن ابوظبي",
            canreceivehousingstudy: "استلام حاله المناقشه للاستثناء من سياسه سكن ابوظبي",

            canassignsecondmentgm: "تحويل لمكتب المدير العام",
            canassignsecondmentbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitsecondmentrequest: "تقديم طلب الاعارة للجهات الاتحادية",
            canviewsecondmentrequest: "عرض طلب الاعارة للجهات الاتحادية",
            caneditsecondmentrequest: "تعديل طلب الاعارة للجهات الاتحادية",
            canupdatesecondmentstatus: "تحديث طلب الاعارة للجهات الاتحادية",
            canaddsecondmentcomments: "اضافة تعليق على طلب الاعارة للجهات الاتحادية",
            canshowsecondmentcomments: "عرض تعليق على طلب الاعارة للجهات الاتحادية",
            canassignsecondmentdepartment: "تعيين الإدارة المختص باعاره موظف للجهات الاتحادية ",
            canassignsecondmetnemployee: "تعيين الموظف المختص باعاره موظف للجهات الاتحادية ",
            canassignsecondmentsector: "تعيين الاداره المختصه باعاره موظف للجهات الاتحادية",
            canprovidesecondmentstudy: "تجهيز دراسه اعاره موظف للجهات  الاتحادية",
            canreceivesecondmentstudy: "استلام دراسه اعاره موظف للجهات  الاتحادية",

            canassignbonusgm: "تحويل لمكتب المدير العام",
            canassignbonusbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitbonusrequest: "تقديم طلب صرف مكافأة مالية سنوية",
            canviewbonusrequest: "عرض طلب صرف مكافأة مالية سنوية",
            caneditbonusrequest: "تعديل طلب صرف مكافأة مالية سنوية",
            canupdatebonusstatus: "تحديث حالة طلب صرف مكافأة مالية سنوية",
            canaddbonuscomments: "اضافة تعليق على طلب صرف مكافأة مالية سنوية",
            canshowbonuscomments: "عرض تعليق على طلب صرف مكافأة مالية سنوية",
            canassignbounsdepartment: "تعيين الإدارة المختص بطلب المكافاه ",
            canassignbounsemployee: "تعيين الموظف المختص بطلب المكافاه ",
            canassignbonussector: "تعيين الاداره المختصه بالمكافات",
            canprovidebonusstudy: "تجهيز دراسه طلب المكافاه",
            canreceivebonusstudy: "استلام دراسه طلب المكافاه",

            canassignamendscalegm: "تحويل لمكتب المدير العام",
            canassignamendscalebacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitamendscalerequest: "تقديم طلب تعديل جدول الرواتب",
            canviewamendscalerequest: "عرض طلب تعديل جدول الرواتب",
            caneditamendscalerequest: "تعديل طلب تعديل جدول الرواتب",
            canupdateamendscalestatus: "تحديث حالة طلب تعديل جدول الرواتب",
            canaddamendscalecomments: "اضافة تعليق على طلب تعديل جدول الرواتب",
            canshowamendscalecomments: "عرض تعليق على طلب تعديل جدول الرواتب",
            canassignamendscaledepartment: "تعيين الإدارة المختص بطلب تعديل علي جدول الرواتب ",
            canassignamendscaleemployee: "تعيين الموظف المختص بطلب تعديل علي جدول الرواتب ",
            canassignamendscalesector: "تعيين الاداره المختصه بتعديل جدول الرواتب",
            canprovideamendscalestudy: "تجهيز دراسه طلب تعديل  جدول الرواتب",
            canreceiveamendscalestudy: "استلام دراسه طلب تعديل  جدول الرواتب",

            canassignamendsalarygm: "تحويل لمكتب المدير العام",
            canassignamendsalarybacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitamendsalaryrequest: "تقديم طلب تعديل راتب موظف",
            canviewamendsalaryrequest: "عرض طلب تعديل راتب موظف",
            caneditamendsalaryrequest: "تعديل طلب تعديل راتب موظف",
            canupdateamendsalarystatus: "تحديث حالة طلب تعديل راتب موظف",
            canaddamendsalarycomments: "اضافة تعليق على طلب تعديل راتب موظف",
            canshowamendsalarycomments: "عرض تعليق على طلب تعديل راتب موظف",
            canassignamendsalarydepartment: "تعيين الإدارة المختص بطلب تعديل  الرواتب ",
            canassignamendsalaryemployee: "تعين الموظف المختص بطلب تعديل  الرواتب ",
            canassignamendsalarysector: "تعيين الاداره المختصه بتعديل الرواتب",
            canprovideamendsalarystudy: "تجهيز دراسه طلب تعديل  الرواتب",
            canreceiveamendsalarystudy: "استلام دراسه  طلب تعديل الرواتب",

            canassignretirementgm: "تحويل لمكتب المدير العام",
            canassignretirementbacktoentity: "إرجاع للجهة صاحبة الطلب",
            cansubmitretirementrequest: "تقديم طلب تقاعد",
            canviewretirementrequest: "عرض طلب تقاعد",
            caneditretirementrequest: "تعديل طلب تقاعد",
            canupdateretirementstatus: "تحديث حالة طلب تقاعد",
            canaddretirementcomments: "اضافة تعليق على طلب تقاعد",
            canshowretirementcomments: "عرض تعليق على طلب تقاعد",
            canassignretirementdepartment: "تعيين الإدارة المختص بالتقاعد",
            canassignretirementemployees: "تعيين الموظف المختص بالتقاعد",
            canassignretirementsector: "تعيين الاداره المختصه بطلب التقاعد",
            canprovideretirementstudy: "تجهيز دراسه التقاعد",
            canreceiveretirementstudy: "استلام دراسه التقاعد",

            cansubmitextendcreaterequest: "انشاء الطلب ",
            canviewextendcreaterequest: "استعراض الطلب ",
            caneditextendcreaterequest: "تعديل الطلب ",
            canupdateextendcreatestatus: "تعديل حالة الطلب ",
            canshowextendcreatecomments: "اسنعراض التعليقات علي الطلب ",
            canaddextendcreatecomments: "اضافه تعليقات علي الطلب ",
            canprovideextendcreatestudy: "عمل دراسه علي الطلب ",
            canreceiveextendcreatestudy: "استقبال الدراسه علي الطلب ",
            canassignextendcreatesector: "تحويل الطلب إلى القطاع",
            canassignextendcreatedepartment: "تحويل الطلب إلى الإدارة",
            canassignextendcreateemployee: "تحويل الطلب إلى الموظف",
            canassignextendcreatebacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignextendcreatebacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignextendcreaterequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            // extend review objectives
            cansubmitextendreviewrequest: "انشاء الطلب",
            canviewextendreviewrequest: "استعراض الطلب",
            caneditextendreviewrequest: "تعديل الطلب",
            canupdateextendreviewstatus: "تعديل حالة الطلب",
            canshowextendreviewcomments: "اسنعراض التعليقات علي الطلب",
            canaddextendreviewcomments: "اضافه تعليقات علي الطلب",
            canprovideextendreviewstudy: "عمل دراسه علي الطلب",
            canreceiveextendreviewstudy: "استقبال الدراسه علي الطلب",
            canassignextendreviewsector: "تحويل الطلب إلى القطاع",
            canassignextendreviewdepartment: "تحويل الطلب إلى الإدارة",
            canassignextendreviewemployee: "تحويل الطلب إلى الموظف",
            canassignextendreviewbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignextendreviewbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignextendreviewrequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //Eextend Annual objectives
            cansubmitextendannualrequest: "انشاء الطلب",
            canviewextendannualrequest: "استعراض الطلب",
            caneditextendannualrequest: "تعديل الطلب",
            canupdateextendannualstatus: "تعديل حالة الطلب",
            canshowextendannualcomments: "اسنعراض التعليقات علي الطلب",
            canaddextendannualcomments: "اضافه تعليقات علي الطلب",
            canprovideextendannualstudy: "عمل دراسه علي الطلب",
            canreceiveextendannualstudy: "استقبال الدراسه علي الطلب",
            canassignextendannualsector: "تحويل الطلب إلى القطاع",
            canassignextendannualdepartment: "تحويل الطلب إلى الإدارة",
            canassignextendannualemployee: "تحويل الطلب إلى الموظف",
            canassignextendannualbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignextendannualbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignextendannualrequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //add / edit emp perf
            cansubmitemployeeperformancerequest: "انشاء الطلب",
            canviewemployeeperformancerequest: "استعراض الطلب",
            caneditemployeeperformancerequest: "تعديل الطلب",
            canupdateemployeeperformancestatus: "تعديل حالة الطلب",
            canshowemployeeperformancecomments: "اسنعراض التعليقات علي الطلب",
            canaddemployeeperformancecomments: "اضافه تعليقات علي الطلب",
            canprovideemployeeperformancestudy: "عمل دراسه علي الطلب",
            canreceiveemployeeperformancestudy: "استقبال الدراسه علي الطلب",
            canassignemployeeperformancesector: "تحويل الطلب إلى القطاع",
            canassignemployeeperformancedepartment: "تحويل الطلب إلى الإدارة",
            canassignemployeeperformanceemployee: "تحويل الطلب إلى الموظف",
            canassignemployeeperformancebacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignemployeeperformancebacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignemployeeperformancerequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //join and implement
            cansubmitjoinimplementrequest: "انشاء الطلب",
            canviewjoinimplementrequest: "استعراض الطلب",
            caneditjoinimplementrequest: "تعديل الطلب",
            canupdatejoinimplementstatus: "تعديل حالة الطلب",
            canshowjoinimplementcomments: "اسنعراض التعليقات علي الطلب",
            canaddjoinimplementcomments: "اضافه تعليقات علي الطلب",
            canprovidejoinimplementstudy: "عمل دراسه علي الطلب",
            canreceivejoinimplementstudy: "استقبال الدراسه علي الطلب",
            canassignjoinimplementsector: "تحويل الطلب إلى القطاع",
            canassignjoinimplementdepartment: "تحويل الطلب إلى الإدارة",
            canassignjoinimplementemployee: "تحويل الطلب إلى الموظف",
            canassignjoinimplementbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignjoinimplementbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignjoinimplementrequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //Open complains
            cansubmitopencomplainsrequest: "انشاء الطلب",
            canviewopencomplainsrequest: "استعراض الطلب",
            caneditopencomplainsrequest: "تعديل الطلب",
            canupdateopencomplainsstatus: "تعديل حالة الطلب",
            canshowopencomplainscomments: "اسنعراض التعليقات علي الطلب",
            canaddopencomplainscomments: "اضافه تعليقات علي الطلب",
            canprovideopencomplainsstudy: "عمل دراسه علي الطلب",
            canreceiveopencomplainsstudy: "استقبال الدراسه علي الطلب",
            canassignopencomplainssector: "تحويل الطلب إلى القطاع",
            canassignopencomplainsdepartment: "تحويل الطلب إلى الإدارة",
            canassignopencomplainsemployee: "تحويل الطلب إلى الموظف",
            canassignopencomplainsbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignopencomplainsbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignopencomplainsrequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //Amend system
            cansubmitamendsystemsrequest: "انشاء الطلب",
            canviewamendsystemsrequest: "استعراض الطلب",
            caneditamendsystemsrequest: "تعديل الطلب",
            canupdateamendsystemsstatus: "تعديل حالة الطلب",
            canshowamendsystemscomments: "اسنعراض التعليقات علي الطلب",
            canaddamendsystemscomments: "اضافه تعليقات علي الطلب",
            canprovideamendsystemsstudy: "عمل دراسه علي الطلب",
            canreceiveamendsystemsstudy: "استقبال الدراسه علي الطلب",
            canassignamendsystemssector: "تحويل الطلب إلى القطاع",
            canassignamendsystemsdepartment: "تحويل الطلب إلى الإدارة",
            canassignamendsystemsemployee: "تحويل الطلب إلى الموظف",
            canassignamendsystemsbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignamendsystemsbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignamendsystemsrequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //Report and static 
            cansubmitreportsstaticsrequest: "انشاء الطلب",
            canviewreportsstaticsrequest: "استعراض الطلب",
            caneditreportsstaticsrequest: "تعديل الطلب",
            canupdatereportsstaticsstatus: "تعديل حالة الطلب",
            canshowreportsstaticscomments: "اسنعراض التعليقات علي الطلب",
            canaddreportsstaticscomments: "اضافه تعليقات علي الطلب",
            canprovidereportsstaticsstudy: "عمل دراسه علي الطلب",
            canreceivereportsstaticsstudy: "استقبال الدراسه علي الطلب",
            canassignreportsstaticssector: "تحويل الطلب إلى القطاع",
            canassignreportsstaticsdepartment: "تحويل الطلب إلى الإدارة",
            canassignreportsstaticsemployee: "تحويل الطلب إلى الموظف",
            canassignreportsstaticsbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignreportsstaticsbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignreportsstaticsrequesttogm: "تحويل الطلب إلى مكتب المدير العام",

            //Other request
            cansubmitotherrequestsrequest: "انشاء الطلب",
            canviewotherrequestsrequest: "استعراض الطلب",
            caneditotherrequestsrequest: "تعديل الطلب",
            canupdateotherrequestsstatus: "تعديل حالة الطلب",
            canshowotherrequestscomments: "اسنعراض التعليقات علي الطلب",
            canaddotherrequestscomments: "اضافه تعليقات علي الطلب",
            canprovideotherrequestsstudy: "عمل دراسه علي الطلب",
            canreceiveotherrequestsstudy: "استقبال الدراسه علي الطلب",
            canassignotherrequestssector: "تحويل الطلب إلى القطاع",
            canassignotherrequestsdepartment: "تحويل الطلب إلى الإدارة",
            canassignotherrequestsemployee: "تحويل الطلب إلى الموظف",
            canassignotherrequestsbacktodepartment: "تحويل الطلب إلى إدارة البيانات",
            canassignotherrequestsbacktoentity: "إرجاع الطلب إلى الجهة الخارجية",
            canassignotherrequesttogmsrequesttogm: "تحويل الطلب إلى مكتب المدير العام",


            //Data Services
            requesttitle: 'عنوان الطلب',
            requestdetails: 'تفاصيل الطلب',
            todate: 'إلى تاريخ',
            fromdate: 'من تاريخ',
            systemtype: 'نوع النظام',
            requestdate: 'تاريخ الطلب'
        },
        NotAvibalelbl: "",
        secondement_For_Federal_Agency_Step: {

            reasonForGovPaySalary:'سبب تحمل الجهة الحكومية للراتب',
            BorrowEntityID:'الجهة المستعيرة',
            RequestByTheCompetentEntityWithPayEmployeeSalary: 'بطلب من الجهة المعيرة بتحديد الجهة التي ستدفع راتب الموظف خلال تلك الفترة',
            fromDateLessThanToDate: 'من تاريخ يجب ان يكون اقل من الى تاريخ',
            unifiedNumber: 'الرقم الموحد',
            familyNumber: 'رقم الأسرة',
            secondement_For_Federal_Agency_Step_1: 'طلب اعارة الخطوة 1',
            secondement_For_Federal_Agency_Step_2: 'طلب اعارة الخطوة 2',
            secondement_For_Federal_Agency_Step_3: 'طلب اعارة الخطوة 3',
            secondement_For_Federal_Agency_Step_4: 'طلب اعارة الخطوة 4',
            Employee_Name: 'إسم الموظف',
            Entity_Name: 'إسم الجهة',
            Job_Name: "المسمى الوظيفي",
            Employee_Number: 'الرقم الوظيفي',
            Total_Salary: 'الراتب الإجمالى',
            Basic_Salary: 'الراتب الأساسي',
            Retirement_Contributions: 'اشتراكات التقاعد',
            Request_of_The_Borrowing_Entity: 'طلب الجهة المستعيرة',
            Request_By_The_Competent_Entity: 'طلب الجهة المعيرة',
            Period_of_Secondment: 'فترة الاعارة',
            The_entity_which_responsible_for_salary: 'الجهة التي ستدفع راتب الموظف خلال فترة الاعارة',
            Job_Description: 'المهام المكلف بها لدى الجهة المعار لها',
            CurrentJobDesc:'الوصف الوظيفي المعتمد للوظيفة الحالية للموظف المعار',
            Approval_Of_Employee: 'الموافقة الخطية للموظف',
            Copy_Of_HR_Committe: 'نسخة من محضر لجنة الموارد البشرية التابعة للجهة في حال أن الموظف من الدرجات الخاصة فما أقل معتمد من رئيس الجهة او من يفوضه ',
            goverment: 'حكومي',
            competent: 'الجهة المستعيرة',
            Copy_of_last_year_eff_report: 'نسخة من تقرير كفاءة العام الماضي',
            Emairates_Id: 'الهوية الاماراتية',
            Passport_copy: 'صورة جواز السفر',
            Family_book: 'خلاصة القيد',

            HRA_Employee_Study_Maker: 'موظف الجهة المكلف بإعداد الدراسة',
            HRA_Study: 'دراسة الجهة',
            select_hra_study: 'أضف الدراسة',
            empPassNoticePeriod: 'لابد ان يجتاز الموظف فتره الاختبار لاستكمال الطلب',
            lastYearPerformanceEfficiency: 'لابد ان يكون اداء الموظف العام الاخير اعلى من ضعيف',
            employeeAgreement: 'موافقة الموظف'
        },

        update_status: {
            assignTo: 'تحويل الى',
            //Update_Status: 'تعديل حالة الطلب',
            Update_Status: 'حفظ',
            Update: 'تعديل',
            Close: 'إغلاق',
            deleteRecord: 'حذف السجل',
            editRecord: 'تعديل السجل',
            viewRecord: 'عرض السجل'
        },

        landing_page: {
            SCI: 'اقتراح / شكوى / استفسار',
            exception_from_accompany_Patient: 'استثناء من أحكام أجازه مرافقة مريض',
            request_Information: 'طلب معلومات',
            review_The_HR_Policies: 'مراجعة سياسة الموارد البشرية',
            Exception_From_AD_Housing_Policy: 'استثناء من أحكام سياسة السكن لموظفي الجهات الحكومية',
            Secondment_For_Federal_Agency: 'إعارة موظف حكومي',
            bonus_Request: ' صرف مكافأة مالية سنوية للجهة',
            Amend_Salaries_Scale: 'تعديل جدول رواتب الجهة الحكومية',
            Amend_Employee_Salary: 'تعديل راتب موظف',
            View_More: 'عرض المزيد'
        },

        ExemptionFromAccompanyPatient: {
            Degreeofrelationship:'درجة القرابة',
            salarystoped:'حالة صرف راتب الموظف',
            salarystopedgoing:'مستمر ',
            salarystopedstoped:'ايقاف',
            Resonforfirstandsecond:'مبررات عدم مرافقة ذوي المريض من الدرجة الأولى أو الثانية',
            ExemptionFromAccompanyPatientHeading: 'استثناء من أحكام أجازه مرافقة مريض',
            step1: 'استثناء من أحكام أجازه مرافقة مريض الخطوة 1',
            step2: 'استثناء من أحكام أجازه مرافقة مريض الخطوة 2',
            step3: 'استثناء من أحكام أجازه مرافقة مريض الخطوة 3',
            step4: 'استثناء من أحكام أجازه مرافقة مريض الخطوة 4',
            step5: 'استثناء من أحكام أجازه مرافقة مريض الخطوة 5',
            step6: 'استثناء من أحكام أجازه مرافقة مريض الخطوة 6',
            officialLetter: 'شهادة الراتب التفصيلية',
            emiratedID: 'الهوية الاماراتية',
            AcceptedFormat: 'انواع الملفات المقبوله فقط pdf, Size : upto 2mb',
            empJob: 'المسمى الوظيفي',
            basicSalary: 'الراتب الأساسي',
            grade: 'الدرجة الوظيفية',
            totalSalary: 'الراتب الإجمالي',
            retirementContribution: 'اشتراكات التقاعد',
            passportCopy: 'نسخة من جواز السفر',
            medicalReport: 'تقرير طبي صادر عن اللجنة الطبية المختصة',
            letterFromEmbassyFile: 'كتاب ايفاد الجهة المختصة ( داخل الدولة) / الملحقية الصحية لسفارة دولة الإمارات العربية المتحدة في بلد العلاج( خارج الدولة)',
            copyOftheDigest: 'في حال اصطحاب مريض من الدرجة الأولى أو الثانية , يرجى ارسال نسخة من ملخص إثبات القرابة ( خلاصة القيد )',
            durationOfAccompanyingLeave: 'الفترة الزمنية لأجازه المرافقة الاستثنائية بالايام',
            dateOfStartingWork: 'تاريخ مباشرة العمل بعد العودة من المرافقة',
            copyOfHumanResoourcesCommittee: 'نسخة من محضر لجنة الموارد البشرية معتمد من رئيس الحهة او من يفوضه',
            dateOfSalaryTermination: 'تاريخ ايقاف الراتب',
            documentationOfFinancialObligations: 'توثيق الالتزامات المالية للموظف أو إذا كان الموظف غير قادر على دفع الالتزامات المالية',
            requiredToPayBudget: 'هل مطلوب صرف موازنة إضافية',
            budgetYear: 'السنة',
            budgetValue: 'قيمة المبلغ',
            annualBalanceOfVacations: 'الرصيد السنوي لإجازات الموظف (في حال وجود رصيد إجازات مخصوم من فترة الاصطحاب)',
            justificationsEmployee: 'مبررات طلب الاستثناء',
            husbandWifeWork: 'عمل الزوج / الزوجة',
            noOfChildren: 'عدد الأبناء',
            placeOfChildren: 'مكان تواجد الأبناء فترة العلاج',
            irregularitiesTable: 'توضيح المبلغ الإجمالي المراد اعفاء الموظف منه، وتوضيح كيفية احتسابه( كل شهر على حده) ',
            year: 'السنة',
            salaryStatus: 'حالة الراتب',
            month: 'الشهر',
            noOfDays: 'عدد الأيام',
            totalSalaryDollar: 'الراتب الإجمالي',
            basicSalaryTb: 'الراتب الأساسي',
            totalDays: 'عدد الأيام الإجمالى',
            totalSalaryPayed: 'المبلغ الإجمالي المطلوب صرفه',
            totalAmountExempted: 'المبلغ الإجمالي المطلوب إعفاء الموظف منه ، متضمنا الاشتراكات ',
            HowToCalculate: 'شرح كيفية الحساب',
            HRAEmployeeStudyMaker: 'الموظف-معد الدراسة',
            Person: 'شخص ',
            Group: 'مجموعة',
            hraStudy: 'الدراسة',
            select: 'تحديد',
            dateOfDirectWork: 'تاريخ مباشرة العمل بعد العودة من المرافقة',
            headlinesOfRequest: 'عنوان الطلب',
            unifiedNumber: 'الرقم الموحد للموظف',
            budgetAvailability: 'هل تتوفر موازنة؟',
            employeeName: 'إسم الموظف',
            violationLabel: 'مخالفة الإجراءات الإدارية في حال استمرارية صرف الرواتب الشهرية بعد تجاوز الفترة القانونية وصلة القرابة بحسب قانون الموارد البشرية',
            explain: 'شرح تفاصيل مخالفة القانون',
            noOfChildShouldBeLesThan20: "عدد الاطفال اكبر من الطبيعي",
            employeeSalaryInformation: "بيانات راتب الموظف",
            intervals: "الفترات",
            totalSalaryWithoutSubscription: "الراتب الإجمالى بدون الاشتراكات",
            retirementSalary: "الاشتراكات",
            total: "الإجمالى",
            from: "يبدا في",
            to: "ينتهي في",
            subTotal: "الراتب الإجمالى",
            invalidYear: "سنه غير صحيحه",
            invalidDay: "اليوم غير صحيح",
            pleaseEnterYearFirst: "الرجاء ادخال السنه اولا"
        },

        excSteer: {
            nationalOrNot: 'مواطن / غير مواطن',
            exceptionOfSteerTheAgeOfRetirementRequest:'الاستثناء من أحكام مد خدمة الموظف بعد بلوغ السن القانوني للإحالة للتقاعد',
            jobContract: 'عقد العمل',
            reasonForExtendingTheService: 'مبررات مد الخدمة',
            annualPerformanceEvaluation: 'تقييم الأداء السنوي',
            employeeName: 'إسم الموظف',
            employeeNumber: "الرقم الوظيفي",
            nationality: 'الجنسية',
            dateOfBirth: 'تاريخ الميلاد',
            jobTitle: 'المسمى الوظيفي',
            unifiedNumber: 'الرقم الموحد',
            dateOfSteer: 'تاريخ التمديد',
            copyLabel: 'هل توجد موافقات لتمديد خدمة سابقة ؟ في حال وجودها يرجي ارفاقها',
            medicalApprovalsFromTheCometentMedicalCommittee: 'الموافقات الطبية المعتمدة ',
            passport: 'جواز السفر',
            familyBook: 'القيد العائلسي',
            emaritesID: 'الهوية الاماراتية',
            annualPerformanceEvaluationattach: 'تقييم الأداء السنوي مرفق',
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: 'نسخة من الموافقات السابقة للتمديد من التقاعد',
            employeeCV: 'السيرة الذاتية للموظف',
            personalPhoto: 'الصورة الشخصية للموظف',
            select: 'تحديد',
            Dateofhiring: 'تاريخ التعيين',
            CurrentGrade: 'الدرجة',
            Efficiency: 'تقييم الكفاءة',
            extendedservice: 'تمديد خدمة سابقاً',
            Salary: 'الراتب الإجمالي',
            Qualification: 'المؤهل العلمي',
            GraduationYear: 'سنة التخرج',
            DateofretirementAge: 'تاريخ بلوغ سن الإحالة للتقاعد',
            TypeofContract: 'نوع العقد',

            Passport: 'جواز السفر',
            Summaryofregistrationforcitizens: 'خلاصة القيد للمواطنين',
            Previousapprovalsfortheextension: 'الموافقات السابقة على التمديد',
            Qualifications: 'المؤهلات العلمية',
            Jobdescription: 'الوصف الوظيفي للوظيفة',
            Careersuccessionplan: 'خطة التعاقب الوظيفي للمواطنين',
            HRCom: 'محضر اجتماع لجنة الموارد البشرية',
            OtherAttach: 'مرفقات أخرى',
            PoistioninStructure:'موقع الوظيفة فى الهيكل التنظيمى المعتمد',
            prviousextensionwithoutapprovalfrom: 'مد خدمة الموظفة دون الحصول على موافقة مد الخدمة من',
            previousextensionwithoutapprovalto: 'مدد خدمة الموظفة دون الحصول على موافقة مد الخدمة إلي',
            Previousextionsfrom: 'فترة التمديد السابقة من',
            Previousextionsto: 'فترة التمديد السابقة إلي',
            Replacementplanfrom: 'خطة الاحلال/ التعاقب الوظيفي من',
            Replacementplanto: 'خطة الاحلال/ التعاقب الوظيفي إلي',

        },

        SCI: {
            sci: 'اقتراح / شكوى / استفسار',
            requestType: 'نوع الطلب',
            requestSubject: 'موضوع الطلب',
            bonusRequestService: 'خدمة طلب مكافأة',
            entityLogo: 'شعار الجهة',
            entityName: 'إسم الجهة',
            coordinatorName: 'المنسق',
            jobName: 'المسمى الوظيفي',
            contactNumber: 'رقم التواصل',
            email: 'البريد الإلكتروني',
            approvedGradesandSalaries: 'ارفاق جدول الدرجات والرواتب المعتمد  للجهة',
            doyouhaveabudget: 'هل يوجد لديكم موازنة كافية لصرف مكافآت للموظفين؟؟, الرجاء ايفاؤنا بقيمة الموازنة في ملف رسمي مرفق',
            yes: 'نعم',
            no: 'لا',
            reasonsToPayBonuses: 'المبررات الداعمة لطلب المكافآت السنوية للموظفين',
            entityAttachmentsForYear: 'يرجى توضيح تفاصيل أهم إنجازات الجهة الحكومية للعام المطلوب له المكافأة المالية',
            enterTheYear: 'السنة',
            balancedScoreCard: 'نتائج الأداء المؤسسي للجهة',
            financialStatement: 'لبيانات المالية',
            taxSummary: 'ملخص الضريبة',
            previous: 'السابق',
            next: 'التالى',
            totalEmployeesOfEntity: 'إجمالي القوى العاملة في الجهة',
            pleaseFillAllTheDetails: 'الرجاء تعبئة جميع الحقول الضرورية',
            enterTheTotalNumberOfEmployees: 'العدد الإجمالى لموظفي الجهة المطلوب صرف مكافآت لهم',
            enterTotalNumberOfLocalsWithPrivateContract: 'عدد الموظفين المواطنين ذوو العقود الخاصة',
            enterTotalNumberOfLocalsWithPermanentContract: 'عدد الموظفين المواطنين ذوو العقود الدائمة',
            enterTotalNumberOfNonLocalsWithPrivateContract: 'عدد الموظفين غير المواطنين ذوو العقود الخاصة',
            enterTotalNumberOfNonLocalsWithPermanentContract: 'عدد الموظفين غير المواطنين ذوو العقود الدائمة',
            percentageOfEmiratisation: 'نسبة التوطين المحققة لآخر عامين متتاليين',
            suggestion: 'اقتراح',
            SuggestionGeneral: 'عام',
            SuggestionPolicy: 'قانونى',
            workforce:'دليل التخطيط الاستراتيجي للقوى العاملة',

            complain: 'شكوى',
            inquiry: 'استفسار',
            EntityLegalOpinonDetials:'(لغايات تنظيم الاستفسارات المتعلقة بسياسات الموارد البشرية، ومشاركة المختصين لإبداء رأيهم في المواضيع التي يتم الاستفسار عنها، نرجو  بيان رأي الإدارة القانونية أو من في حكمها بجهتكم مع الاستفسار المرسل للهيئة)',
            EntityLegalOpinon:'الرأي القانوني',
            requestDetails: 'تفاصيل الطلب',
            attachments: 'المرفقات',
            applicatorsName: 'مقدم الطلب',
            applicatorsJob: 'المسمى الوظيفي',
            hRAConcerenedSector: 'هيئة الموارد البشرية - القطاع المعني بالطلب',
            addNotes: 'إضافة ملاحظات',
            transferToAssignedSector: 'تحويل الطلب للقطاع المعني',
            sendFeedback: 'ارسال الإفادة',
            finish: 'إنهاء',
            submit: 'حفظ',
            clear: 'حذف',
            chooseFile: 'اختر ملف',
            enquiry: 'استفسار',
            attachedFiles: 'المرفقات',
            dateSubmitted: 'البيانات المعتمدة',
        },

        dataServicesObj: {
            entityName: 'إسم الجهة',
            requestTitle: 'عنوان الطلب',
            requestDetails: 'تفاصيل الطلب',
            requestDate: 'تاريخ الطلب',
            extendCreatingObjectivesIntervalFromDate: 'تمديد فترة وضع الأهداف من تاريخ',
            extendCreatingObjectivesIntervalToDate: 'تمديد فترة وضع الأهداف إلى تاريخ',
            extendReviewingObjectivesIntervalFromDate: 'تمديد مراجعة الأهداف من تاريخ',
            extendReviewingObjectivesIntervalToDate: 'تمديد مراجعة الأهداف إلى تاريخ',
            extendAnnualPerformanceEvaluationIntervalFromDate: 'تمديد فترة تقييم الأداء السنوي من تاريخ',
            extendAnnualPerformanceEvaluationIntervalToDate: 'تمديد فترة تقييم الأداء السنوي إلى تاريخ',
            // addOrEditIntervalFromDate: 'Add/Edit Interval From Date',
            // addOrEditIntervalToDate: 'Add/Edit Interval To Date',
            employeeName: 'إسم الموظف',
            jobDesc: 'المسمى الوظيفي',
            contactNumber: 'رقم التواصل',
            email: 'البريد الإلكتروني',
            formalReqFromEntityEmp: "الطلب الرسمي من الجهة + درجات التقييم النهائي للموظفين معتمدة من الإدارة العليا للجهة",
            employeesFinalEvaluationDegreesInExcelSheet: "ملف اكسل يحتوي على درجات التقييم النهائي للموظفين",
            systemType: 'نوع النظام',
            formalReqFromEntityEmp: "الطلب الرسمي من الجهة + درجات التقييم النهائي للموظفين معتمدة من الإدارة العليا للجهة",
            employeesFinalEvaluationDegreesInExcelSheet: "ملف اكسل يحتوي على درجات التقييم النهائي للموظفين",
            formalReqFromEntityEmp_for_AddOrEditPerEva: "خطاب رسمي من الجهة يحتوي على درجات التقييم النهائي للموظفين",
            employeesFinalPerformanceEvaluationInExcelSheet_for_AddOrEditPerEva: "ملف اكسل يحتوي على درجات التقييم النهائي للموظفين",
            datesForOpeningComplainsStageFrom: 'من تاريخ',
            datesForOpeningComplainsStageto: 'إلى تاريخ',
            attachEntityAttestedPolicyPDFFile: 'إرفاق سياسة الجهة المعتمدة',
            attachEntityAttestedOrgStructureFileInPDF: 'إرفاق الهيكل التنظيمي المعتمد',
            attachEntityCreationLawFileInPDF: 'إرفاق قانون إنشاء الجهة',
            attachAgreementFromEntityTopManagementPDFFIle: 'ارفاق موافقة الإدارة العليا',
            systemTypeHRSystem: 'نوع النظام',
            attachementForRequiredReqortFieldsAndDetails: 'ارفاق ملف يحتوي الخانات المطلوبة',
            otherattachemnt: 'ملفات أخرى'
        },

        viewDetails: {
            EntityLegalOpinon: 'الرأي القانوني',
            LeaveSubject: 'تفاصيل الإجازة',
            YearlyLeaveBalance: 'الرصيد السنوي لإجازات الموظف',
            LeavePeriod: 'الفترة الزمنية للإجازة المرضية',
            BacktoWorkDate: 'تاريخ مباشرة العمل بعد العودة من الإجازة المرضية',
            LeveSalryDetails: 'حالة صرف الراتب ',
            DescripeIssue: 'رصد المخالفات فى شأن الاجرئات الادارية',
            EmployeePassport: "جواز السفر",
            EmployeeEID: "الهوية الإماراتية",
            SickLeaveHealthreport: "التقرير الطبي من اللجنة المختصة",
            SickLeaveEmbassyLetter: "كتاب ايفاد الجهة أو الملحقية لسفارة دولة الإمارات العربية المتحدة فى بلد العلاج",
            SickLeaveHRCom: "نسخة من محضر لجنة الموارد البشرية معتمد من رئيس الجهة او من يفوضه",
            EmployeeIncome: "مستندات ثبوتية للالتزامات المالية للموظف أو فى حال عدم قدرة الموظف على الوفاء بالالتزامات المالية",

            RequestType: 'نوع الطلب',
            EmployeeInfoEmployeeName: 'إسم الموظف',
            EmployeeInfoEmirateID: 'رقم الهوية',
            EmployeeInfoEmployeeNumber: 'الرقم الوظيفي',
            EmployeeInfoEmployeeUID: 'الرقم الموحد للموظف',

            EmployeeInfoBasicSalary: 'الراتب الأساسي',
            EmployeeInfoJobTitle: 'المسمى الوظيفي',
            EmployeeInfoJobDegree: 'الدرجة الوظيفية',
            EmployeeInfoTotalSalary: 'الراتب الإجمالي',
            EmployeeInfoBasicSalary: 'الراتب الأساسي',
            EmployeeInfoRetirementcontributions: 'اشتراكات التقاعد',
            EmployeeCV: 'السيرة الذاتية',
            EmployeePhoto: 'صورة شخصية',
            positionofthenominatedposition: 'موقع الوظيفة المرشح عليها من الهيكل التنظيمي المعتمد',
            jobdescription: 'الوصف الوظيفي المعتمد للوظيفة مع تحديد الوظيفة فنية أم إدارية',
            HumanResourcesCommittee: 'نسخة من محضر لجنة الموارد البشرية المعتمد',
            EmployeeNominationForm: 'نموذج ترشيح موظف',
            EducationalQualifications: 'نسخة من المؤهلات العلمية',
            ExperienceCertificates: 'نسخة من شهادات الخبرة',
            competencyreports: 'نسخة من تقريري الكفاءة -لآخر سنتين',
            passport: 'جواز السفر',
            Summaryofregistrationforcitizens: 'خلاصة القيد للمواطنين',
            IDcard: 'بطاقة الهوية',
            CID: 'البحث الجنائي',
            Medicalfitnesscheckup: 'فحص اللياقة الطبية',
            Anotheremployeesselfstatement: 'بيان آخر إجراء ذاتي للموظف',
            //SCI
            actualOfficialLetter: '',
            requestSubject: 'موضوع الطلب',
            applicantsName: 'مقدم الطلب',
            reqDetails: 'تفاصيل الطلب',
            contactNumber: 'رقم التواصل',
            applicantsJob: 'المسمى الوظيفي',
            officialLetter: 'المرفقات',
            requestTypeLabelInSCI: "نوع الطلب",

            //info
            requestorName: 'مقدم الطلب',
            assignedEntitySelectedLabel: "الجهة المعنية",
            whatInfoRequired: 'تفاصيل الطلب',
            lastTimeToReply: 'آخر تاريخ للرد',
            typeOfRequestLabel: 'نوع الطلب',
            attachFile1: 'المرفقات',
            statement: 'Request Details',

            //Review HR         
            reasonsOfRequest: 'مبررات الطلب',
            entityFormalRequest: 'الطلب الرسمي من الجهة',
            standardComparisons: 'مقارنات معيارية',
            studies: 'الدراسات و المستندات الداعمة',

            //Secondment 
            RequestByTheCompetentEntityWithPayEmployeeSalary: 'بطلب من الجهة المعيرة بتحديد الجهة التي ستدفع راتب الموظف خلال تلك الفترة',
            fromDateLessThanToDate: 'من تاريخ يجب ان يكون اقل من الى تاريخ',
            unifiedNumber: 'الرقم الموحد',
            familyNumber: 'رقم الأسرة',
            employeeName: 'إسم الموظف',
            BorrowEntityID: 'الجهة المستعيرة',
            entityName: 'إسم الجهة',
            jobName: "المسمى الوظيفي",
            employeeNumber: 'الرقم الوظيفي',
            totalSalary: 'الراتب الإجمالى',
            basicSalary: 'الراتب الأساسي',
            retirementCont: 'اشتراكات التقاعد',
            reqForBrowEntity: 'طلب الجهة المستعيرة',
            reqByCompEnty: 'طلب الجهة المعيرة',
            fromDatePeriodOfSecndMent: 'تاريخ بداية الاعارة',
            toDatePeriodOfSecndMent: "تاريخ نهاية الاعارة",
            entityRespForSal: 'الجهة التي ستدفع راتب الموظف خلال فترة الاعارة',
            jobDescFileName: 'المهام المكلف بها لدى الجهة المعار لها',
            CurrentJobDesc: 'الوصف الوظيفي المعتمد للوظيفة الحالية للموظف المعار',
            approvalEmpFileName: 'الموافقة الخطية للموظف',
            copyOfHRCommitteFileName: 'نسخة من محضر لجنة الموارد البشرية التابعة للجهة في حال أن الموظف من الدرجات الخاصة فما أقل معتمد من رئيس الجهة او من يفوضه ',
            emiratesIdFileName: 'الهوية الاماراتية',

            copyOfLastYearEffiReportFileName: 'نسخة من تقرير كفاءة العام الماضي',
            emiratesID: 'الهوية الاماراتية',
            emiratesid: 'الهوية الاماراتية',
            passportCopyFileName: 'صورة جواز السفر',
            Family_book: 'خلاصة القيد',
            reasonForGovPaySalary: 'سبب تحمل الجهة الحكومية للراتب',

            empShouldPassProbationPeriodLabel: 'لا بد ان يجتاز الموظف فتره الاختبار ',
            lastYearPerfDegMoreThanWeekLabel: 'لابد ان يكون اداء الموظف اعلى من ضعيف',
            employeeAgreementLabel: 'موافقة الموظف',
            // HRA_Employee_Study_Maker: 'موظف الجهة المكلف بإعداد الدراسة',
            // HRA_Study: 'دراسة الجهة',
            // select_hra_study: 'أضف الدراسة',
            empShouldPassProbationPeriodLabel: 'لابد ان يجتاز الموظف فتره الاختبار لاستكمال الطلب',
            lastYearPerfDegMoreThanWeekLabel: 'لابد ان يكون اداء الموظف العام الاخير اعلى من ضعيف',
            employeeAgreementLabel: 'موافقة الموظف',

            //Exception steer Retirement
            nationalOrNot: 'مواطن / غير مواطن',
            exceptionOfSteerTheAgeOfRetirementRequest: 'طلب الاستثناء من أحكام مد خدمة الموظف بعد بلوغ السن القانوني للإحالة للتقاعد',
            jobContract: 'عقد العمل',
            reasonForExtendingTheService: 'مبررات مد الخدمة',
            annualPerformanceEvaluation: 'تقييم الأداء السنوي',
            employeeName: 'إسم الموظف',
            employeeNumber: "الرقم الوظيفي",
            nationalityLabel: 'الجنسية',
            dateOfBirth: 'تاريخ الميلاد',
            jobTitle: 'المسمى الوظيفي',
            unifiedNumber: 'الرقم الموحد',
            fromDate: ' تاريخ بداية التمديد',
            toDate: 'تاريخ نهاية التمديد',
            copyButton: 'هل لديك مرفقات طلبات تمديد السابقة ؟   في حال نعم ,  نرجو ارفاق مستند ',
            medicalApprovalsFromTheCometentMedicalCommittee: 'الموافقات الطبية المعتمدة ',
            passport: 'جواز السفر',
            familyBook: 'القيد العائلسي',
            emaritesID: 'الهوية الاماراتية',
            emiratesIDStepOne: 'الهوية الاماراتية',
            annualPerformanceEvaluationattach: 'تقييم الأداء السنوي مرفق',
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: 'نسخة من الموافقات السابقة للتمديد من التقاعد',
            employeeCV: 'السيرة الذاتية للموظف',
            personalPhoto: 'الصورة الشخصية للموظف',
            replacePlansForNonLocalsAttachementFileName: "خطه الاحلال لغير المواطنين",
            //Exemption Patient
            Degreeofrelationship: "درجة القرابة",
            requestTypeLabel: "نوع الاستثناء",
            officialLetter: 'خطاب رسمي معتمد من رئيس الجهة',
            emiratedID: 'الهوية الاماراتية',
            empJob: 'المسمى الوظيفي',
            basicSalary: 'الراتب الأساسي',
            grade: 'الدرجة الوظيفية',
            totalSalary: 'الراتب الإجمالي',
            retirementContributions: 'اشتراكات التقاعد',
            passportCopy: 'نسخة من جواز السفر',
            medicalReport: 'تقرير طبي صادر عن اللجنة الطبية المختصة',
            letterFromEmbassy: 'كتاب ايفاد الجهة أو الملحقية الصحية لسفارة دولة الإمارات العربية المتحدة في بلد العلاج.',
            copyOftheDigest: 'في حال اصطحاب مريض من الدرجة الأولى أو الثانية , يرجى ارسال نسخة من ملخص إثبات القرابة ( خلاصة القيد )',
            durationOfAccompanyingLeave: 'الفترة الزمنية لأجازه المرافقة الاستثنائية بالايام',
            dateOfStartingWork: 'تاريخ مباشرة العمل بعد العودة من المرافقة',
            copyOfHumanResoourcesCommittee: 'نسخة من محضر لجنة الموارد البشرية',
            dateOfSalaryTermination: 'تاريخ ايقاف الراتب',
            documentationOfFinancialObligations: 'توثيق الالتزامات المالية للموظف أو إذا كان الموظف غير قادر على دفع الالتزامات المالية',
            requiredToPayBudget: 'هل مطلوب صرف موازنة إضافية',
            budgetAvailabilityYear: 'السنة',
            budgetValue: 'قيمة المبلغ المطلوب',
            annualBalanceOfVacations: 'الرصيد السنوي لإجازات الموظف (في حال وجود رصيد إجازات مخصوم من فترة الاصطحاب)',
            justificationsEmployee: 'مبررات طلب الاستثناء',
            husbandWifeWork: 'عمل الزوج / الزوجة',
            noOfChildren: 'عدد الأبناء',
            placeOfChildren: 'مكان تواجد الأبناء فترة العلاج',
            irregularitiesTable: 'توضيح المبلغ الإجمالي المراد اعفاء الموظف منه، وتوضيح كيفية احتسابه( كل شهر على حده) ',
            year: 'السنة',
            salaryStatus: 'حالة الراتب',
            month: 'الشهر',
            noOfDays: 'عدد الأيام',
            totalSalaryDollar: 'الراتب الإجمالي',
            basicSalaryTable: 'الراتب الأساسي',
            totalDays: 'عدد الأيام الإجمالى',
            totalSalaryPayed: 'المبلغ الإجمالي المطلوب صرفه',
            totalAmountExempted: 'المبلغ الإجمالي المطلوب إعفاء الموظف منه ، متضمنا الاشتراكات ',
            HowToCalculate: 'شرح كيفية الحساب',
            HRAEmployeeStudyMaker: 'الموظف-معد الدراسة',
            Person: 'شخص ',
            Group: 'مجموعة',
            hraStudy: 'الدراسة',
            budgetYear: 'السنة',

            dateOfDirectWork: 'تاريخ مباشرة العمل بعد العودة من المرافقة',
            headlinesOfRequest: 'عنوان الطلب',
            selectedOption2: 'هل تتوفر موازنة؟',
            violation: 'مخالفة الإجراءات الإدارية في حال استمرارية صرف الرواتب الشهرية بعد تجاوز الفترة القانونية وصلة القرابة بحسب قانون الموارد البشرية',
            explain: 'شرح تفاصيل مخالفة القانون',
            noOfChildShouldBeLesThan20: "عدد الاطفال اكبر من الطبيعي",
            employeeSalaryInformation: "بيانات راتب الموظف",
            intervals: "الفترات",
            totalSalaryWithSubscription: "الراتب الإجمالى بدون الاشتراكات",
            retirementSalary: "الاشتراكات",
            subTotal: "الإجمالى",
            fromDay: "يبدا في",
            toDay: "ينتهي في",
            Total: "المجموع",
            invalidYear: "سنه غير صحيحه",
            invalidDay: "اليوم غير صحيح",
            pleaseEnterYearFirst: "الرجاء ادخال السنه اولا",
            tswsrsSalary: "الراتب الإجمالى",
            subTotalTotal: "الراتب الإجمالى",
            totalNoOfDays: "إجمالى عدد الأيام",

            // Amend Employee Salary
            //Step 1
            nationalityLabel: 'جنسية الموظف',
            age: 'سن الموظف',
            designationDate: 'تاريخ مباشرة العمل في الجهة الحكومية',
            currentBasicSalary: 'الراتب الأساسي الحالى',
            proposedBasicSalary: 'الراتب الأساسي المقترح',
            financialRatioOfProcedure: 'قيمة التعديل  المطلوبة ',
            percentIncRequiredBasicSalary: 'نسبة التعديل المقترحة على الراتب الأساسي',
            totalExperience: 'الخبرة الإجمالية بالسنوات',
            procedureDate: 'تاريخ آخر إجراء تم على راتب الموظف',
            academicQualification: 'المؤهل العلمي للموظف',
            experienceAfterQualification: 'الخبرة الإجمالية بعد المؤهل العلمي بالسنوات',
            annualEvaluationforPreviousYear: 'نتيجة التقييم السنوي المعتمد للعام السابق',
            annualEvaluationforCurrentYear: 'نتيجه التقييم السنوى المعتمد للعام الحالى',
            currentJobTitle: 'المسمى الوظيفي الحالى',
            currentGrade: 'الدرجة الوظيفية',
            reasonToModifySalary: 'مبررات طلب تعديل الراتب للموظف',
            proposedIncreaseRate: 'الأسس المستخدمة في تحديد نسبة التعديل المقترحة',

            //Step 2
            employeeEmaritesId: 'صورة من الهوية الاماراتية',
            employeePhoto: 'صورة شخصية للموظف',
            copyOfLastProcedure: 'نسخة من آخر إجراء',
            previousandCurrentApprovedCompetencyReport: 'إرفاق التقييم السنوي المعتمد السابق والحالى',
            approvedScaleSalaries: 'جدول الرواتب والدرجات المعتمد',

            // Amend Salary Scale
            //Step 1
            sourcesFundingLabel: 'مصادر التمويل للزيادات المقترحة',
            principlesUsedToModifyScale: 'الأسس المستخدمة في تعديل جدول الدرجات والرواتب',
            justificationOfEditTheScale: 'مبررات التعديل على جدول الدرجات والرواتب للجهة الحكومية',
            selectedOptionfinancial: 'هل لديكم المخصصات المالية اللازمة ضمن الموازنة المعتمدة',
            proposedBasicSalaryfunding: 'العلاوات الشهرية المقترحة',
            currentBasicSalarymonthly: 'العلاوات الشهرية الحالية',
            proposedAnnualBenefits: 'المزايا السنوية المقترحة',
            currentAnnualBenefits: 'المزايا السنوية الحالية',
            proposedFinancialEffect: 'الأثر المالى المقترح',
            currentFinancialEffect: 'الأثر المالى الحالى',
            proposedSalariesScale: 'تحديات جدول الرواتب المقترح',
            currentSalariesScale: 'تحديات جدول الرواتب الحالى',
            avarageForProposedSalariesScale: 'معدل الزيادة / التخفيض للجدول المقترح',
            avarageForCurrentSalariesScale: 'متوسط نسبة الزيادة / التخفيض الإجمالية بين الجدول الحالى والمقترح',
            EffectOfApplyingOnProposed: 'التأثير المحقق من تطبيق جدول الرواتب المقترح (على الجدول المقترح)',
            EffectOfApplyingOnCurrent: 'التأثير المحقق من تطبيق جدول الرواتب الحالى (على الجدول الحالى)',
            otherBonusTableData: 'علاوات أخرى',
            otherBenefitTableData: 'مزايا أخرى',
            approvedBudgetsOfPart: 'المبالغ المعتمدة في موازنة الباب الاول',

            //Step 2
            approvedScaleOfGrades: 'جدول الدرجات والرواتب المعتمد لدى الجهة الحكومية',
            salariesCompensationPolicy: 'سياسة الرواتب والتعويضات شاملة جميع العلاوات والمزايا',
            tableOfTheAlignment: 'جدول مواءمة درجات الجهة مع درجات الخدمة المدنية مع بيان معايير المقارنة في الدراسة',
            proposedScale: 'ارفاق جدول الدرجات والرواتب المقترح للجهة',
            entityChairman: 'اعتماد رئيس الجهة',
            illustrationsOfCurrent: 'رسومات توضيحية في ملف إكسل لخطوط الرواتب الحالة والمقترحة مقارنة بخط السوق / الجهات المماثلة(للمواطنين وغير المواطنين) لكل الدرجات في رسم توضيحي واحد مع إرفاق جدول في ملف إكسل بالقيم المقابلة لكل خط بحسب الدرجة',
            comparativeStudy: 'دراسة مقارنة مع الجهات المماثلة وبيان معايير المقارنة في الدراسة',
            detailsOfAnnualCost: 'تفاصيل التكلفة السنوية بالموازنة المعتمدة للعام بناء على بيانات ورواتب الموظفين الفعلية مع تحديد المخصصات الحالية والمقترحة بحسب الدرجة وبيان أعداد الموظفين بكل درجة (مواطنين وغير مواطنين كل على حدى)',
            //bonusRequest
            //step1
            entityname: 'إسم الجهة',
            jobname: 'المسمى الوظيفي للمنسق الاول',
            coordinatorname: 'إسم المنسق الاول',
            email: 'البريد الإلكتروني للمنسق الاول',
            contactnumber: 'رقم هاتف المكتب للمنسق الاول',
            phonenumber: 'رقم الهاتف المتحرك للمنسق الاول',
            subcoordinatorname: 'إسم المنسق الثاني',
            subjobname: 'المسمى الوظيفي للمنسق الثاني',
            subcontactnumber: 'رقم هاتف المكتب للمنسق الثاني',
            subphonenumber: 'رقم الهاتف المتحرك للمنسق الثاني',
            subemail: 'البريد الإلكتروني للمنسق الثاني',
            isbudgeted: 'هل يتطلب صرف المكافأة المالية المطلوبة موازنة إضافية؟ في حال الإجابة بنعم، الرجاء توضيح قيمة المكافأة ',
            budget: 'قيمة المبلغ',
            budgetyear: 'السنة',
            yearachievment: 'يرجى توضيح تفاصيل أهم إنجازات الجهة الحكومية للعام المطلوب له المكافأة المالية',
            bonusreason: 'توضيح مبررات طلب صرف المكافأة المالية السنوية لموظفي الجهة ',
            year1: 'نسبه التوطين السنه الاولي',
            emiratizationpercentage: '(%) النسبة',
            year2: 'نسبه التوطين السنه الثانية',
            iscontracted: 'في حال طلب صرف مكافأة مالية لموظفي العقود الخاصة، يرجى الإفادة بالتالى:هل تتضمن عقودهم الوظيفية قيمة ثابته للمكافأة السنوية المستحقة؟ في حال الإجابة بنعم، يرجى تحميل نسخة من عقد الموظف مبيناً قيمة المكافأة المذكورة في خانة المستندات المطلوبة في شاشة المرفقات',
            totalemployees: 'العدد الإجمالى لموظفي الجهة',
            equation: '(توضيح مستويات تقييم الأداء. (مثال: 5 يعادل ممتاز، 4 يعادل جيد جدا، 3 يعادل جيد إلخ',
            emiratizationpercentage2: '(%) النسبة',
            nonlocalwithprivate: 'العقود الخاصة بغير المواطنين',
            localwithprivate: 'العقود الخاصة بالمواطنين',
            localwithpermanent: 'العقود الدائمة للمواطنين',
            nonlocalwithpermanent: 'العقود الدائمة لغير الموطنين',
            excellentemployees: 'ممتاز',
            verygoodemployees: 'جيد جداً',
            goodemployees: 'جيد',
            acceptableemployees: 'مقبول',
            weakemployees: 'ضعيف',
            isfixedvaluebonus: 'في حال طلب صرف مكافأة مالية لموظفي العقود الخاصة، يرجى الإفادة بالتالى:هل تتضمن عقودهم الوظيفية قيمة ثابته للمكافأة السنوية المستحقة؟ في حال الإجابة بنعم، يرجى تحميل نسخة من عقد الموظف مبيناً قيمة المكافأة المذكورة في خانة المستندات المطلوبة في شاشة المرفقات',

            //step3
            approvedGradesAndSalaries: 'ارفاق جدول الدرجات والرواتب المعتمد  للجهة',
            balancedScoreCard: 'تفاصيل نتائج الأداء المؤسسي مع إرفاق ما يثبت ذلك',
            financialStatement: 'تقرير يوضح نتائج الأداء المالى للجهة الحكومية للعام ',
            // taxSummary:'Approved Grades And Salaries',
            resultsOfEvaluationGeneralPerformance: 'مستند معتمد لنتائج تقييم أداء الموظفين للعام',
            uploadTheContracts: 'نسخ من العقود الخاصة التي نصت على صرف قيمة مكافأة مالية محددة للموظف',
            uploadTheExcelAfterFill: 'نموذج كشف الموظفين :الرجاء اعادة تحميل الملف بعد استكمال كافة البيانات المطلوبة ',
            budgetRelatedDocument: 'أرفق الملف الداعم للموازنة',
            presentationforEntityAcheivementsDocument: 'عرض تقديمي موضحا انجازات الجهة الحكومية للعام المطلوب له المكافاة',

            //Ad housing
            FinancialCost: 'التكلفة المالية',
            typeOfContractLabel: 'نوع عقد الموظف',
            EmployeeName: 'إسم الموظف',
            EntityName: 'إسم الجهة',
            BasicSalary: 'الراتب الأساسي',
            unifiedNumber: 'الرقم الموحد',
            jobTitle: 'المسمى الوظيفي',
            contractPeriod: 'مدة عقد الموظف بالسنوات',
            TotalSalary: 'الراتب الإجمالى',
            EmployeeNumber: 'الرقم الوظيفي',
            HousingAllowance: 'بدل السكن',
            RetirementContributions: 'اشتراكات التقاعد',
            emiratesID: 'رقم الهوية',
            employeeContract: 'عقد الموظف',
            flatContract: 'عقد العقار',
            entityFormalRequest: 'طلب الجهة الرسمي',
            supportRequest: 'الدراسات و المستندات الداعمة',
            ReasonsOfRequest: 'مبررات طلب الاستثناء',
            ADHousingPassport: 'جواز السفر',
            ADHousingEID: 'بطاقة الهوية',
            ADHousingFamilyBook: 'خلاصة القيد للمواطنين',

            //Data Services
            entityName: 'إسم الجهة',
            requestTitle: 'عنوان الطلب',
            requestDetails: 'تفاصيل الطلب',
            requestDate: 'تاريخ الطلب',
            extendCreatingObjectivesIntervalFromDate: 'تمديد فترة وضع الأهداف من تاريخ',
            extendCreatingObjectivesIntervalToDate: 'تمديد فترة وضع الأهداف إلى تاريخ',
            extendReviewingObjectivesIntervalFromDate: 'تمديد مراجعة الأهداف من تاريخ',
            extendReviewingObjectivesIntervalToDate: 'تمديد مراجعة الأهداف إلى تاريخ',
            extendAnnualPerformanceEvaluationIntervalFromDate: 'تمديد فترة تقييم الأداء السنوي من تاريخ',
            extendAnnualPerformanceEvaluationIntervalToDate: 'تمديد فترة تقييم الأداء السنوي إلى تاريخ',
            addOrEditIntervalFromDate: 'Add/Edit Interval From Date',
            addOrEditIntervalToDate: 'Add/Edit Interval To Date',
            employeeName: 'إسم الموظف',
            jobDesc: 'المسمي الوظيفي',
            contactNumber: 'رقم التواصل',
            emailId: 'البريد الإلكتروني',
            formalReqFromEntityEmp: "الطلب الرسمي من الجهة + درجات التقييم النهائي للموظفين معتمدة من الإدارة العليا للجهة",
            employeesFinalEvaluationDegreesInExcelSheet: "ملف اكسل يحتوي على درجات التقييم النهائي للموظفين",
            systemType: 'نوع النظام',
            formalReqFromEntityEmp_for_AddOrEditPerEva: "الطلب الرسمي من الجهة + درجات التقييم النهائي للموظفين معتمدة من الإدارة العليا للجهة ",
            employeesFinalPerformanceEvaluationInExcelSheet_for_AddOrEditPerEva: "ملف اكسل يحتوي على درجات التقييم النهائي للموظفين",
            datesForOpeningComplainsStageFrom: 'من تاريخ',
            datesForOpeningComplainsStageto: 'إلى تاريخ',
            attachEntityAttestedPolicyPDFFile: 'إرفاق سياسة الجهة المعتمدة',
            attachEntityAttestedOrgStructureFileInPDF: 'إرفاق الهيكل التنظيمي المعتمد',
            attachEntityCreationLawFileInPDF: 'إرفاق قانون إنشاء الجهة',
            attachAgreementFromEntityTopManagementPDFFIle: 'ارفاق موافقة الإدارة العليا للجهة',
            systemTypeHRSystem: 'نوع النظام',
            attachementForRequiredReqortFieldsAndDetails: 'ارفاق ملف  يحتوي الخانات المطلوبة',
            otherattachemnt: 'ملفات أخرى',
            OtherAttach: 'مرفقات أخرى'
        },

        Reports: {
            startdate: 'من تاريخ',
            enddate: 'إلى تاريخ',
            sector: 'القطاع',
            department: 'الإدارة',
            employeename:'اسم الموظف'
        },
        viewDetailActions: {
            lastaction: 'آخر إجراء',
            Employee: 'الشخص المعني',
            ActionType: 'نوع الإجراء',
            Comment: 'التعليق',
            ViewDetails: 'عرض المزيد',
            ViewLess: 'عرض المختصر',
        }
    },


}

export default i18n;