import React, { Component } from 'react';
import { MDBCol, MDBRow } from "mdbreact";
import { getRequestByIdPayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import DownloadExcel from './../DownloadExcel';
import TableDataComponent from './TableDataComponent';
import Config from './../../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import i18n from './../../i18n';
let moment = require('moment');

export default class ViewStatusHistoryWithTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createdon: this.props.createdon,
            targetdate: this.props.targetdate,
            showNumberOfDaysData: false,
            pathName: '',
            histroyArray: [],
            requestType: '',
            listArray: [
                { name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry' },
                { name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry' },
                { name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry' },
                { name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry' },
                { name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry' }

            ],
            tableHeaderArray: [
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].updatedby },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].updatedon },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].statusreason },
                { "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].viewComponent.status },
                //{ "tableHeader": i18n[this.props.isArabic ? 'ar' : 'en'].numberofdays }
            ]
        };
    }

    componentDidMount = () => {
        if(this.props.requesttypeid) {
          
if (this.props.requesttypeid ===10969) {
                this.setState({
                    requestType: Config.getSciRequestTypeId()
                }, () => {
                    this.callRequestById('GetStrategicPlanningforworkforceEnquirebyID');
                })
            }
       else     if (this.props.requesttypeid === Config.getSciRequestTypeId() || this.props.requesttypeid === Config.getSCILEgalSerbviceId() || this.props.requesttypeid === Config.getSCIComplainId() || this.props.requesttypeid === Config.getSCILEgalSuggestionId()
            || this.props.requesttypeid === Config.getSCIGeneralId() ) {
                this.callRequestById('GetscirequestbyID');
            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.callRequestById('GetinformationrequestbyID');
            }

            else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                this.callRequestById('GetinformationrequestbyID');
            }
            
            else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                this.callRequestById('GetbounsrequestbyID');
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                this.callRequestById('GetAmmendSalaryScalebyID');
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                this.callRequestById('GetAmmendEmployeeSalarybyID');
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                this.callRequestById('GetHRPolicyReviewbyID');
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                this.callRequestById('GetHousingPolicybyID');
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                this.callRequestById('GetFederalSecondmentbyID');
            } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                this.callRequestById('GetPoliciesPatientrequestbyID');
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
                this.callRequestById('GetRetirementbyID');
            } 
            else if (this.props.requesttypeid === Config.getPromotionTypeId()) {
                this.callRequestById('GetSickLeaveById');
            }
            else if (this.props.requesttypeid === Config.getPromotionTypeId()) {
                this.callRequestById('GetPromotionById');
            }
            else if (this.props.requesttypeid === Config.getExtendCreateObjectiveId()) {
                this.callRequestById('GetExtendCreateObjectivesbyID');
            } else if (this.props.requesttypeid === Config.getExtendReviewObjectiveId()) {
                this.callRequestById('GetExtendReviewObjectivesbyID');
            } else if (this.props.requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                this.callRequestById('GetExtendAnnualPerformancebyID');
            } else if (this.props.requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                this.callRequestById('GetEmpPerformancebyID');
            } else if (this.props.requesttypeid === Config.getJoinAndImplementId()) {
                this.callRequestById('GetJoinAndImplementSystemsbyID');
            } else if (this.props.requesttypeid === Config.getOpenComplainsStageId()) {
                this.callRequestById('GetOpenGrievanceProcessbyID');
            } else if (this.props.requesttypeid === Config.getAmendOnSharedGovermentId()) {
                this.callRequestById('GetAmendSystemsbyID');
            } else if (this.props.requesttypeid === Config.getReportAndStaticId()) {
                this.callRequestById('GetReportsRequestsbyID');
            } else if (this.props.requesttypeid === Config.getOtherRequest()) {
                this.callRequestById('GetOtherRequestsbyID');
            }
              // organization structure
              else if (this.props.requesttypeid === Config.getORGRequestTypeId()) {
               
                this.callRequestById('GetOrganizationStructurebyID');
            }

            else if (this.props.requesttypeid === Config.getDisciplinaryboardTypeId()) {
               
                this.callRequestById('GetDisciplinaryboardById');
            
        } 
        }
    }

    calculateDays = (firstDate, secondDate) => {
        let finalFirstDate = moment(firstDate, 'YYYY/MM/DD HH:MM:SS');
        let finalSecondDate = moment(secondDate, 'YYYY/MM/DD HH:MM:SS');
        let days = finalSecondDate.diff(finalFirstDate, 'days');
       
        return days;
    }

    callRequestById = (endPoint) => {
        let id = this.props.id;
        let payload = getRequestByIdPayload(id);
        let url = Config.getUrl(endPoint);
        
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        if (this.props.requesttypeid === Config.getSciRequestTypeId()) {
                            this.setState({
                                showNumberOfDaysData: true
                            })
                        }

                        let tempHistorysArray = [];
                        let historyDataFromRes = res.data.history;

                        if (historyDataFromRes && historyDataFromRes.length > 0) {
                            for (let index = 0; index < historyDataFromRes.length; index++) {
                                let tempHistoryObj = {};
                                let tempNumberOfDays = '';
                                if (this.props.requesttypeid === Config.getSciRequestTypeId()) {
                                    // make date in yyy-mm-dd format 
                                    let tempCreatedDate = '';
                                    let tempTargetDate = '';

                                    if (this.state.createdon) {
                                        tempCreatedDate = this.state.createdon.split(" ")[0];
                                    }
                                    if (this.state.targetdate) {
                                        // tempTargetDate = this.state.targetdate.split(" ")[0];
                                    }

                                    let statusUpdateDate = moment(historyDataFromRes[index].updatedon).format('YYYY-MM-DD');
                                    let lastStatusUpdateDate = moment(historyDataFromRes[historyDataFromRes.length - 1].updatedon).format('YYYY-MM-DD');

                                    let totalDaysRequiredToCompleteRrquest = this.calculateDays(tempCreatedDate, lastStatusUpdateDate);;
                                    let totalDaysGivenToCompleteRequest = this.calculateDays(tempCreatedDate, tempTargetDate);;

                                 

                                    if (index === 0) {
                                        tempNumberOfDays = this.calculateDays(tempCreatedDate, statusUpdateDate);
                                    } else {
                                        let objectOneDate = moment(historyDataFromRes[index].updatedon).format('YYYY-MM-DD');
                                        let objectTwoDate = moment(historyDataFromRes[index - 1].updatedon).format('YYYY-MM-DD');
                                        tempNumberOfDays = this.calculateDays(objectTwoDate, objectOneDate);
                                    }
                                }

                                let status = historyDataFromRes[index].status;
                                let updatedon = Config.getFormattedDate(historyDataFromRes[index].updatedon);
                                let updatedby = historyDataFromRes[index].updatedby;
                                let statusreason = historyDataFromRes[index].statusreason;

                                //if (status != "") {
                                tempHistoryObj.status = status
                                tempHistoryObj.updatedon = updatedon
                                tempHistoryObj.updatedby = updatedby
                                tempHistoryObj.statusreason = statusreason
                                tempHistoryObj.numberOfDays = tempNumberOfDays
                                tempHistoryObj.numberOfDays = ""

                                tempHistorysArray.push(tempHistoryObj);
                                //}
                            }

                            this.setState({
                                histroyArray: tempHistorysArray
                            }, () => {
                            })
                        }
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    callInformationRequestById = () => {
        let id = this.props.id;
        let payload = getRequestByIdPayload(id);
        let endPoint = 'GetinformationrequestbyID'
        let url = Config.getUrl(endPoint);

        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let tempHistorysArray = [];
                        let historyDataFromRes = res.data.history;
                        if (historyDataFromRes && historyDataFromRes.length > 0) {
                            for (let index = 0; index < historyDataFromRes.length; index++) {
                                let tempHistoryObj = {};
                                let status = historyDataFromRes[index].status;
                                let updatedon = historyDataFromRes[index].updatedon;
                                let updatedby = historyDataFromRes[index].updatedby;
                                let statusreason = historyDataFromRes[index].statusreason;
                                if (status !== "") {
                                    tempHistoryObj.status = status
                                    tempHistoryObj.updatedon = updatedon
                                    tempHistoryObj.updatedby = updatedby
                                    tempHistoryObj.statusreason = statusreason
                                    tempHistorysArray.push(tempHistoryObj);
                                }
                            }
                            this.setState({
                                histroyArray: tempHistorysArray
                            }, () => {
                                // TODO
                            })
                        }
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    populateStatusHistroy = (historyArray) => {
        return (
            <div >
                <MDBRow style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    <MDBCol md="12">
                        <TableDataComponent historyArray={historyArray} tableHeaderArray={this.state.tableHeaderArray} />
                    </MDBCol>
                </MDBRow>
                <br />
            </div>)
    }

    render() {
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBCol style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <MDBCol>
                        {this.populateStatusHistroy(this.state.histroyArray)}
                    </MDBCol>
                </LoadingOverlay>
            </MDBCol>
        );
    };
}