import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol,MDBBtn } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import SickLeaveForm from '../components/SickLeave/SickLeaveForm';

import SickLeaveUpload from '../components/SickLeave/SickLeaveUpload';
import SubHeaderStrip from './../components/SubHeaderStrip';
import i18n from './../i18n';
import Config from './../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import Footer from './../components/Footer';
import { SaveSickLeaveSalaryMatrix,saveSickLeave, uploadDocument } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import moment from 'moment';

export default class SickLeave extends Component {
    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            
            isEdit: '',
            requestId: '',
            statusid: '',
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isNextScreenActive: 1,
            empJob: "",
            grade: "",
            basicSalary: "",
            totalSalary: "",
            retirementContributions: "",
            passportCopy: "",
            medicalReport: "",
            letterFromEmbassy: "",
            copyOftheDigest: "",
            durationOfAccompanyingLeave: "",
            dateOfStartingWork: "",
            officialLetter: "",
            emiratedID: "",
            copyOfHumanResoourcesCommitteeFile: null,
            documentationOfFinancialObligationsFile: null,
            copyOfHumanResoourcesCommittee: "",
            dateOfSalaryTermination: null,
            documentationOfFinancialObligations: '',
            documentationOfFinancialObligationsShortName: '',
            requiredToPayBudget: "",
            selectedOption2: "Yes",
            budgetYear: "",
            budgetValue: "",
            annualBalanceOfVacations: "",
            justificationsEmployee: "",
            husbandWifeWork: "",
            noOfChildren: "",
            placeOfChildren: "",
            year: "",
            salaryStatus: "",
            totalSalaryDollar: "",
            noOfDays: "",
            basicSalaryTb: "",
            totalDays: "",
            totalSalaryPayed: 0,
            totalAmountExempted: "",
            HowToCalculate: "",
            violation: "",
            explain: "",
            dateOfDirectWork: null,
            headlinesOfRequest: "",
            employeeName: "",
            unifiedNumber: "",
            budgetAvailabilityYear: "",
            emiratesIDStepOne: '',
            exemptionSelectedValue: "",
            tableData: [{
                year: "",
                month: "1",
                fromDay: "",
                toDay: "",
                salaryStatus: "Continuous",
                subTotal: 0,
                noOfDays: 0
            }],
            view: 0,
            myTemp: 0,
            basicSalaryTable: '',
            subTotalTotal: '',
            totalNoOfDays: '',
            totalSalaryWithSubscription: '',
            retirementSalary: '',
            tswsrsSalary: '',
            requestType: '',
            officialLetterFromHeadOfEntityFileObj: null,
            passportCopyFIleObj: null,
            medicalReportFIleObj: null,
            letterFromEmbassyFileObj: null,
            copyOfDigestFileObj: null,
            documentationOfFinObligationsFileObjArray: [],
            emiratesIDFileObj: null,
            formType: "",
            requestTypeLabel: "",
           
            OtherAttach: '',
            OtherAttachFileObj: "",
            EmployeePassport: '',
            EmployeeEID: '',
            SickLeaveHealthreport: '',
            SickLeaveEmbassyLetter: '',
            SickLeaveHRCom: '',
            EmployeeIncome: '',
            EmployeePassportFileObj: '',
            EmployeeEIDFileObj: '',
            SickLeaveHealthreportFileObj: '',
            SickLeaveEmbassyLetterFileObj: '',
            SickLeaveHRComFileObj: '',
            EmployeeIncomeFileObj: '',
        };
        this.stepOneData = this.stepOneData.bind(this);
        this.stepTwoData = this.stepTwoData.bind(this);
        this.stepTwoPrev = this.stepTwoPrev.bind(this);
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
alertSubmit = () => {
    confirmAlert({
        title: this.state.titleAlert,
        message: this.state.messageAlert,
        buttons: [
            {
                label: i18n[this.getLocale()].ok
            }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />,
    });
    setTimeout(() => {
        document.getElementById('alertOkButton').focus()
    }, 100);
};
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    nextButtonPressed = (isNextScreenActive) => {
        this.setState({ isNextScreenActive: isNextScreenActive });
    }
    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
    
        stepTwoPrev: this.stepTwoPrev,
    
    };

   

    
componentDidMount = () => {
    if (Config.getIsPreviewFlag()) {
        this.setState({
            step1: false,
            step2: true,
          
        })
    }

    let loginData = Config.getLocalStorageLoginData();
    if (loginData) {
        this.setState({
            loginData: loginData
        }, () => {
            this.setState({
                canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
            }, () => {
            })
        })
    }
}

getPrev = {
    stepOneDataPrev: this.stepOneDataPrev,
    stepTwoDataPrev: this.stepTwoDataPrev,
    
};

stepTwoDataPrev = () => {
    this.setState({ step1: true, step2: false});
}



componentWillMount() {
    let previewFlag = Config.getIsPreviewFlag();
    let previewckEditorArr = Config.getPreviewckEditorArray();
    let previewObj = Config.getPreviewObj();
    let previewAttachmentArray = Config.getPreviewAttachmentArray();

    if (previewFlag) {

        let HowToCalculate = '',
            explain = "",
            violation = "",
            officialLetter = '',
            passportCopy = "",
            medicalReport = "",
            letterFromEmbassy = "",
            copyOftheDigest = "",
            copyOfHumanResoourcesCommittee = "",
            documentationOfFinancialObligations = "",
            emiratedID = "",
            OtherAttach = "",
            justificationsEmployee = '',EmployeePassport= '',
            EmployeeEID= '',
            SickLeaveHealthreport= '',
            SickLeaveEmbassyLetter= '',
            SickLeaveHRCom= '',
            EmployeeIncome= '';

        //required to pay budget
        let requiredToPayBudgetValueFromPreview = "";
        if (previewObj.requiredToPayBudget) {
            if (previewObj.requiredToPayBudget === i18n["ar"].yes || previewObj.requiredToPayBudget === i18n["en"].yes) {
                requiredToPayBudgetValueFromPreview = "Yes";
            } else if (previewObj.requiredToPayBudget === i18n["ar"].no || previewObj.requiredToPayBudget === i18n["en"].no) {
                requiredToPayBudgetValueFromPreview = "No";
            }
        }

        //selected option 2
        let selectedOption2ValueFromPreview = "";
        if (previewObj.selectedOption2) {
            if (previewObj.selectedOption2 === i18n["ar"].yes || previewObj.selectedOption2 === i18n["en"].yes) {
                selectedOption2ValueFromPreview = "Yes";
            } else if (previewObj.selectedOption2 === i18n["ar"].no || previewObj.selectedOption2 === i18n["en"].no) {
                selectedOption2ValueFromPreview = "No";
            }
        }

        if (previewckEditorArr) {
            if (previewckEditorArr.length > 0) {
                for (let j = 0; j < previewckEditorArr.length; j++) {
                    if (previewckEditorArr[j].label === 'justificationsEmployee') {
                        justificationsEmployee = previewckEditorArr[j].value;
                    }
                }
            }
        }

        let violationFromPreview = "";
        if (previewckEditorArr) {
            if (previewckEditorArr.length > 0) {
                for (let j = 0; j < previewckEditorArr.length; j++) {
                    if (previewckEditorArr[j].label === 'HowToCalculate') {
                        HowToCalculate = previewckEditorArr[j].value;
                    }
                    else if (previewckEditorArr[j].label === 'explain') {
                        explain = previewckEditorArr[j].value;
                    }
                    else if (previewckEditorArr[j].label === 'violation') {
                        violation = previewckEditorArr[j].value;
                        if (violation === i18n["ar"].yes || violation === i18n["en"].yes) {
                            violationFromPreview = "Yes";
                        } else if (violation === i18n["ar"].no || violation === i18n["en"].no) {
                            violationFromPreview = "No";
                        }
                    }

                }
            }
        }
     
      
        if (previewAttachmentArray.length > 0) {
         
            for (let index = 0; index < previewAttachmentArray.length; index++) {
           
               
                if (previewAttachmentArray[index].label === 'EmployeePassport') {
                    EmployeePassport = previewAttachmentArray[index].fileName;
                }
                else if (previewAttachmentArray[index].label === 'EmployeeEID') {
                    EmployeeEID = previewAttachmentArray[index].fileName;
                }
                else if (previewAttachmentArray[index].label === 'SickLeaveHealthreport') {
                    SickLeaveHealthreport = previewAttachmentArray[index].fileName;
                }
                else if (previewAttachmentArray[index].label === 'SickLeaveEmbassyLetter') {
                    SickLeaveEmbassyLetter = previewAttachmentArray[index].fileName;
                }
                else if (previewAttachmentArray[index].label === 'SickLeaveHRCom') {
                    SickLeaveHRCom = previewAttachmentArray[index].fileName;
                }
                else if (previewAttachmentArray[index].label === 'EmployeeIncome') {
                    EmployeeIncome = previewAttachmentArray[index].fileName;
                }
        
            }
        }

        this.setState({
            step1: false,
            step2: true,
         
            requestType: previewObj.requestType,
            empJob: previewObj.empJob,
            headlinesOfRequest: previewObj.headlinesOfRequest,
            employeeName: previewObj.employeeName,
            grade: previewObj.grade,
            basicSalary: previewObj.basicSalary,
            unifiedNumber: previewObj.unifiedNumber,
            totalSalary: previewObj.totalSalary,
            retirementContributions: previewObj.retirementContributions,
            durationOfAccompanyingLeave: previewObj.durationOfAccompanyingLeave,
            dateOfSalaryTermination: new Date(previewObj.dateOfSalaryTermination),
            dateOfDirectWork: new Date(previewObj.dateOfDirectWork),
            requiredToPayBudget: requiredToPayBudgetValueFromPreview,
            selectedOption2: selectedOption2ValueFromPreview,
            budgetAvailabilityYear: previewObj.budgetAvailabilityYear,
            budgetYear: previewObj.budgetYear,
            budgetValue: previewObj.budgetValue,
            annualBalanceOfVacations: previewObj.annualBalanceOfVacations,
            emiratesIDStepOne: previewObj.emiratesIDStepOne,
            requestTypeLabel: previewObj.requestTypeLabel,
            justificationsEmployee: justificationsEmployee,
          
            tableData: previewObj.tableData,
            basicSalaryTable: previewObj.basicSalaryTable,
            totalSalaryWithSubscription: previewObj.totalSalaryWithSubscription,
            retirementSalary: previewObj.retirementSalary,
            tswsrsSalary: previewObj.tswsrsSalary,
            subTotalTotal: previewObj.subTotalTotal,
            totalNoOfDays: previewObj.totalNoOfDays,
            HowToCalculate: HowToCalculate,
            explain: explain,
            violation: violationFromPreview,
            EmployeePassport:EmployeePassport,
            EmployeeEID:EmployeeEID,
            SickLeaveHealthreport:SickLeaveHealthreport,
            SickLeaveEmbassyLetter:SickLeaveEmbassyLetter,
            SickLeaveHRCom:SickLeaveHRCom,
            EmployeeIncome:EmployeeIncome,
        });
    }
}


uploadFile = (i, totalCount, resAcompanyPatient, multifileCount) => {
    if (i < totalCount) {
        let fileObj = null;
        let tempBase64 = {};
        let isLastFileInMultiUpload = false;
        let obj = {};
        if (i === 0) {
            fileObj = Config.getBase64ForSickLeaveEmployeePassport();
            obj.filename = this.state.EmployeePassport;
            obj.filetype = Config.getSickLeaveEmployeePassportId();
            tempBase64 = fileObj;
        }

        else if (i === 1) {
            fileObj = Config.getBase64ForSickLeaveEmployeeEID();
            obj.filename = this.state.EmployeeEID;
            obj.filetype = Config.getSickLeaveEmployeeEIDId();
            tempBase64 = fileObj;
        }
        else if (i === 2) {
            fileObj = Config.getBase64ForSickLeaveSickLeaveHealthreport();
            obj.filename = this.state.SickLeaveHealthreport;
            obj.filetype = Config.getSickLeaveSickLeaveHealthreportId();
            tempBase64 = fileObj;
        }
        else if (i === 3) {
            fileObj = Config.getBase64ForSickLeaveSickLeaveEmbassyLetter();
            obj.filename = this.state.SickLeaveEmbassyLetter;
            obj.filetype = Config.getSickLeaveSickLeaveEmbassyLetterId();
            tempBase64 = fileObj;
        }
        else if (i === 4) {
            debugger;
            fileObj = Config.getBase64ForSickLeaveHRCom();
            obj.filename = this.state.SickLeaveHRCom;
            obj.filetype = Config.getSickLeaveHRComId();
            tempBase64 = fileObj;
        }
        else if (i === 5) {
            fileObj = Config.getBase64ForSickLeaveEmployeeIncome();
            obj.filename = this.state.EmployeeIncome;
            obj.filetype = Config.getSickLeaveEmployeeIncomeId();
            tempBase64 = fileObj;
        }
        if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
            let reqId = 10925;
            if (resAcompanyPatient && resAcompanyPatient.data && resAcompanyPatient.data.id) {
                reqId = resAcompanyPatient.data.id;
                obj.requestid = reqId;
            } else {
                obj.requestid = reqId;
            }

            if (tempBase64.includes(",")) {
                obj.fileContent = tempBase64.split(',')[1];
            } else {
                obj.fileContent = tempBase64;
            }
            let payload = uploadDocument(obj);
            let endPoint = 'UploadAttachment';
            let url = Config.getUrl(endPoint);

            ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                if (resForEntityFIleRequestFile.Status) {

                    {
                        this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
                    }
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {

                        if (this.state.formType === "saveAsDraft") {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].save_as_Draft
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].attachment_error
                            }, () => {
                                this.alertSubmit();
                            })
                        }
                    })
                }
            })
        } else {
            this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
        }

    }
    else {
        this.setState({
            isLoading: false
        }, () => {
            if (this.state.isEdit) {
                if (this.state.formType === "saveAsDraft") {
                    this.setState({
                        titleAlert: i18n[this.getLocale()].success,
                        messageAlert: i18n[this.getLocale()].save_as_Draft
                    }, () => {
                        this.alertSubmitForSuccess();
                    })
                } else {
                    if (this.state.statusid === Config.getDraftId()) {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_updated
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                    // if (this.state.formType) {
                    //     this.setState({
                    //         titleAlert: i18n[this.getLocale()].success,
                    //         messageAlert: i18n[this.getLocale()].request_updated
                    //     }, () => {
                    //         this.alertSubmitForSuccess();
                    //     })
                    // } else {
                    //     this.setState({
                    //         titleAlert: i18n[this.getLocale()].success,
                    //         messageAlert: i18n[this.getLocale()].request_created
                    //     }, () => {
                    //         this.alertSubmitForSuccess();
                    //     })
                    // }
                }
            } else {
                if (this.state.formType === "saveAsDraft") {
                    this.setState({
                        titleAlert: i18n[this.getLocale()].success,
                        messageAlert: i18n[this.getLocale()].save_as_Draft
                    }, () => {
                        this.alertSubmitForSuccess();
                    })
                } else {
                    this.setState({
                        titleAlert: i18n[this.getLocale()].success,
                        messageAlert: i18n[this.getLocale()].request_created
                    }, () => {
                        this.alertSubmitForSuccess();
                    })
                }
            }
        })
    }
}

   

handlePreViewNavigation = (obj) => {
    let editObj = Config.getEditObj();
    localStorage.setItem('currentPath', 'SickLeave');
    let previewObj = {};
    previewObj.isEdit = editObj.isEdit;
    previewObj.requestId = editObj.requestId;
    previewObj.employeeName = this.state.employeeName;
    previewObj.empJob = this.state.empJob;
    previewObj.grade = this.state.grade;
    previewObj.unifiedNumber = this.state.unifiedNumber;
    previewObj.emiratesIDStepOne = this.state.emiratesIDStepOne;
    // previewObj.isEdit = this.state.isEdit;
    // previewObj.requestId = this.state.requestId;
    previewObj.requestType = this.state.requestType;
    //previewObj.requestTypeLabel = this.state.requestTypeLabel;
   
    previewObj.basicSalary = this.state.basicSalary;
    previewObj.totalSalary = this.state.totalSalary;
    previewObj.retirementContributions = this.state.retirementContributions;
    previewObj.durationOfAccompanyingLeave = this.state.durationOfAccompanyingLeave;
    if( moment(this.state.dateOfSalaryTermination).format('L').toString()!='Invalid date')
    {
        alert(moment(this.state.dateOfSalaryTermination).format('L').toString());
    previewObj.dateOfSalaryTermination = this.state.dateOfSalaryTermination ? moment(this.state.dateOfSalaryTermination).format('L').toString() : "";
    }
    //selected option 2
    if (this.state.selectedOption2 === "Yes") {
        previewObj.selectedOption2 = i18n[this.state.isArabic ? "ar" : "en"].yes
    } else {
        previewObj.selectedOption2 = i18n[this.state.isArabic ? "ar" : "en"].no
    }

    previewObj.annualBalanceOfVacations = this.state.annualBalanceOfVacations;
    previewObj.dateOfDirectWork = moment(this.state.dateOfDirectWork).format('L').toString();
   
    //alert( previewObj.dateOfDirectWork);
    //previewObj.headlinesOfRequest = this.state.headlinesOfRequest;
    

  
    if (this.state.selectedOption2 === "Yes") {
        previewObj.budgetAvailabilityYear = this.state.budgetAvailabilityYear;
    } else {
        // previewObj.budgetYear = "";
        // previewObj.requiredToPayBudget = this.state.requiredToPayBudget;
        //required to pay budget
        if (this.state.requiredToPayBudget === "Yes") {
            previewObj.requiredToPayBudget = i18n[this.state.isArabic ? "ar" : "en"].yes
        } else {
            previewObj.requiredToPayBudget = i18n[this.state.isArabic ? "ar" : "en"].no
        }

        if (this.state.requiredToPayBudget === "Yes") {
            previewObj.budgetValue = this.state.budgetValue;
            previewObj.budgetYear = this.state.budgetYear;
        }
    }

    //step 2
  
    previewObj.tableData = this.state.tableData;
    // previewObj.totalDays = this.state.totalDays;
    previewObj.basicSalaryTable = this.state.basicSalaryTable;
    previewObj.subTotalTotal = this.state.subTotalTotal;
    previewObj.totalNoOfDays = this.state.totalNoOfDays;
    previewObj.totalSalaryWithSubscription = this.state.totalSalaryWithSubscription;
    previewObj.retirementSalary = this.state.retirementSalary;
    previewObj.tswsrsSalary = this.state.tswsrsSalary;
    previewObj.documentationOfFinancialObligationsShortName = obj.documentationOfFinancialObligationsShortName;

    let explainValue = '';
    if (this.state.violation === 'Yes') {
        explainValue = this.state.explain;
    }

    let tempViolationValue = "";
    if (this.state.violation === "Yes") {
        tempViolationValue = i18n[this.state.isArabic ? "ar" : "en"].yes
    } else {
        tempViolationValue = i18n[this.state.isArabic ? "ar" : "en"].no
    }

    let ckEditorArr = [
        {
            label: "violation",
            value: tempViolationValue,
            flag: "radio"
        },
        {
            label: 'justificationsEmployee',
            value: this.state.justificationsEmployee,
        },
        {
            label: 'HowToCalculate',
            value: this.state.HowToCalculate,
        },
    ];

    if (this.state.violation === "Yes") {
        ckEditorArr.push({
            label: 'explain',
            value: explainValue,
        })
    }

    let previewAttachMentArray =
        [
            {
                "label": "EmployeePassport",
                "fileName": obj.EmployeePassport,
                "fileObj": Config.getBase64ForSickLeaveEmployeePassport()
            },
            {
                "label": "EmployeeEID",
                "fileName": obj.EmployeeEID,
                "fileObj": Config.getBase64ForSickLeaveEmployeeEID()
            },
            {
                "label": "SickLeaveHealthreport",
                "fileName": obj.SickLeaveHealthreport,
                "fileObj": Config.getBase64ForSickLeaveSickLeaveHealthreport()
            },
            {
                "label": "SickLeaveEmbassyLetter",
                "fileName": obj.SickLeaveEmbassyLetter,
                "fileObj": Config.getBase64ForSickLeaveSickLeaveEmbassyLetter()
            },
            {
                "label": "SickLeaveHRCom",
                "fileName": obj.SickLeaveHRCom,
                "fileObj": Config.getBase64ForSickLeaveHRCom()
            },


            {
                "label": "EmployeeIncome",
                "fileName": obj.EmployeeIncome,
                "fileObj": Config.getBase64ForSickLeaveEmployeeIncome()
            },

        ];

    this.props.history.replace('/preview', { data: previewObj, attachmentArr: previewAttachMentArray, ckEditorArr: ckEditorArr });
}



stepOneData = (obj) => {
    let editObj = Config.getEditObj();
    if (editObj) {
        let setConfigObj = editObj;
        //From Step 1
        setConfigObj.worktotaldays = obj.totalDays;
        setConfigObj.worktotalsalarytobepaid = obj.myTemp;
        setConfigObj.dateofstarting = obj.dateOfStartingWork;

        setConfigObj.howtocalculaterule = obj.HowToCalculate;
        setConfigObj.isviolation = obj.violation === "Yes" ? 1 : 0;
        setConfigObj.violationdetail = obj.explain;
        setConfigObj.basicsalary2 = obj.basicSalaryTable;
        setConfigObj.totalsalarywithsubscription = obj.totalSalaryWithSubscription;
        setConfigObj.retirement = obj.retirementSalary;
        setConfigObj.totalsalary2 = obj.tswsrsSalary;
        setConfigObj.salarymatrix = obj.tableData;
        setConfigObj.jobtitle = obj.empJob;
        setConfigObj.employeename = obj.employeeName;
        setConfigObj.requestheadline = obj.headlinesOfRequest;
        setConfigObj.grade = obj.grade;
        setConfigObj.basicsalary = obj.basicSalary;
        setConfigObj.unifiednumber = obj.unifiedNumber;
        setConfigObj.totalsalary = obj.totalSalary;
        setConfigObj.retirementcontribution = obj.retirementContributions;
        setConfigObj.durationofleave = obj.durationOfAccompanyingLeave;
        // dateOfSalaryTermination: new Date(`${editObj.dateofsalarytermination}`),
        // dateOfDirectWork: new Date(`${editObj.dateofwork}`),
        setConfigObj.dateofsalarytermination = obj.dateOfSalaryTermination;
        setConfigObj.dateofwork = obj.dateOfDirectWork;
        // setConfigObj.isdocumentofobligatory = obj.requiredToPayBudget;
        setConfigObj.isdocumentofobligatory = obj.selectedOption2 === "Yes" ? 1 : 0;
        //setConfigObj.isrequriedtopaybybudget = obj.selectedOption2;
        setConfigObj.isrequriedtopaybybudget = obj.requiredToPayBudget === "Yes" ? 1 : 0;

        setConfigObj.budgetyear = obj.budgetYear;
        setConfigObj.budgetavailableyear = obj.budgetAvailabilityYear;
        setConfigObj.budgetvalue = obj.budgetValue;
        setConfigObj.annualleavebalance = obj.annualBalanceOfVacations;
        setConfigObj.justification = obj.justificationsEmployee;
        setConfigObj.emiratesid = obj.emiratesIDStepOne;
        //exemptionSelectedValue: editObj.exemptionSelectedValue,
       
      
        // overrite data in edit object
        Config.setEditObj(setConfigObj);
    }

    this.setState({

        step1: false, step2: true,
        year: obj.year,
        //salaryStatus: obj.salaryStatus,
        //totalSalaryDollar: obj.totalSalaryDollar,
        //noOfDays: obj.noOfDays,
        //basicSalaryTb: obj.basicSalaryTb,
        totalDays: obj.totalDays,
        myTemp: obj.myTemp,
        dateOfStartingWork: obj.dateOfStartingWork,
        husbandWifeWork: obj.husbandWifeWork,
        noOfChildren: obj.noOfChildren,
        placeOfChildren: obj.placeOfChildren,
        totalAmountExempted: obj.totalAmountExempted,
        HowToCalculate: obj.HowToCalculate,
        violation: obj.violation,
        explain: obj.explain,
        tableData: obj.tableData,
        basicSalaryTable: obj.basicSalaryTable,
        totalSalaryWithSubscription: obj.totalSalaryWithSubscription,
        retirementSalary: obj.retirementSalary,
        tswsrsSalary: obj.tswsrsSalary,
        subTotalTotal: obj.subTotalTotal,
        totalNoOfDays: obj.totalNoOfDays,
        empJob: obj.empJob,
        grade: obj.grade,
        basicSalary: obj.basicSalary,
        totalSalary: obj.totalSalary,
        retirementContributions: obj.retirementContributions,
        durationOfAccompanyingLeave: obj.durationOfAccompanyingLeave,
        dateOfSalaryTermination: obj.dateOfSalaryTermination,
        requiredToPayBudget: obj.requiredToPayBudget,
        selectedOption2: obj.selectedOption2,
        budgetYear: obj.budgetYear,
        budgetValue: obj.budgetValue,
        annualBalanceOfVacations: obj.annualBalanceOfVacations,
        justificationsEmployee: obj.justificationsEmployee,
        dateOfDirectWork: obj.dateOfDirectWork,
        headlinesOfRequest: obj.headlinesOfRequest,
        employeeName: obj.employeeName,
        unifiedNumber: obj.unifiedNumber,
        budgetAvailabilityYear: obj.budgetAvailabilityYear,
        emiratesIDStepOne: obj.emiratesIDStepOne,
        exemptionSelectedValue: obj.exemptionSelectedValue,
       
        
    }, () => {
        this.nextButtonPressed(2);
    });
}


   


submitApiCall = () => {
    debugger;
    let tempEditObj = Config.getEditObj();
    let tempStatusId = '';
    if (tempEditObj) {
        tempStatusId = tempEditObj.statusid;
        this.setState({
            statusid: tempStatusId
        }, () => {
            debugger;
            Config.setIsEditFlag(false);
            Config.setIsPreviewFlag(false);
            Config.setPreviewObj();
            Config.setPreviewAttachmentArray();
            Config.setPreviewckEditorArray();

            let resposeObj = {};
            if (this.state.formType === "saveAsDraft") {
                resposeObj.draft = 1;
            } else {
                resposeObj.draft = 0;
            }
            resposeObj.isEdit = this.state.isEdit;
            resposeObj.requestId = this.state.requestId;
            
            resposeObj.jobtitle = this.state.empJob;
            resposeObj.grade = this.state.grade;
            resposeObj.basicsalary = this.state.basicSalary;
            resposeObj.totalsalary = this.state.totalSalary;
            resposeObj.unifiedNumber = this.state.unifiedNumber;
            resposeObj.headlinesOfRequest = this.state.headlinesOfRequest;
            resposeObj.employeeName = this.state.employeeName;
            resposeObj.retirementcontribution = this.state.retirementContributions;
            resposeObj.durationofleave = this.state.durationOfAccompanyingLeave;
           
            let tempDate = '';
            if (this.state.dateOfSalaryTermination !== '' && this.state.dateOfSalaryTermination !== null) {
                tempDate = new Date(this.state.dateOfSalaryTermination);
                let day = tempDate.getDate();
                let month = tempDate.getMonth() + 1;
                let year = tempDate.getFullYear();
                resposeObj.dateofsalarytermination = year + '-' + month + '-' + day;
            } else {
                resposeObj.dateofsalarytermination = tempDate;
            }

            // resposeObj.isrequriedtopaybybudget = this.state.selectedOption2 === "Yes" ? 1 : 0;
            // resposeObj.isdocumentofobligatory = this.state.requiredToPayBudget === "Yes" ? 1 : 0;
            resposeObj.isrequriedtopaybybudget = this.state.requiredToPayBudget === "Yes" ? 1 : 0;
            resposeObj.isdocumentofobligatory = this.state.selectedOption2 === "Yes" ? 1 : 0;
            resposeObj.budgetyear = this.state.budgetYear;
            resposeObj.budgetvalue = this.state.budgetValue;
            resposeObj.selectedOption2 = this.state.selectedOption2;
            let tempDateOne = '';
            if (this.state.dateOfDirectWork !== '' && this.state.dateOfDirectWork !== null) {
                tempDateOne = new Date(this.state.dateOfDirectWork);
                let day = tempDateOne.getDate();
                let month = tempDateOne.getMonth() + 1;
                let year = tempDateOne.getFullYear();
                resposeObj.dateofwork = year + '-' + month + '-' + day;
            } else {
                resposeObj.dateofsalarytermination = tempDateOne;
            }

            resposeObj.annualleavebalance = this.state.annualBalanceOfVacations;
            resposeObj.justification = this.state.justificationsEmployee;
            resposeObj.budgetavailableyear = this.state.budgetAvailabilityYear;
            resposeObj.husbandwifework = this.state.husbandWifeWork;
            resposeObj.numberofchidren = this.state.noOfChildren;
            resposeObj.placeofchildren = this.state.placeOfChildren;
            resposeObj.worktotaldays = this.state.totalDays;
            resposeObj.worktotalsalarytobepaid = this.state.myTemp;
            resposeObj.worktobeexempted = this.state.totalAmountExempted;
            resposeObj.howtocalculaterule = this.state.HowToCalculate;
            resposeObj.violation = this.state.violation === "Yes" ? 1 : 0;
            resposeObj.explain = this.state.explain;
            resposeObj.howtocalculateid = '1';
            resposeObj.emiratesid = this.state.emiratesIDStepOne;
            resposeObj.exemptionSelectedValue = this.state.exemptionSelectedValue;

            let tempDate2 = new Date(this.state.dateOfStartingWork);
            let day2 = tempDate2.getDate();
            let month2 = tempDate2.getMonth() + 1;
            let year2 = tempDate2.getFullYear();
            resposeObj.dateofstarting = year2 + '-' + month2 + '-' + day2;

            resposeObj.basicsalary2 = this.state.basicSalaryTable;
            resposeObj.totalsalarywithsubscription = this.state.totalSalaryWithSubscription;
            resposeObj.retirement = this.state.retirementSalary;
            resposeObj.totalsalary2 = this.state.tswsrsSalary;
            resposeObj.totalamount = this.state.subTotalTotal;
            resposeObj.totaldays = this.state.totalNoOfDays;

            let payload = saveSickLeave(resposeObj);
            let endPoint = 'SaveSickLeave'
            let url = Config.getUrl(endPoint);

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                    if (resAcompanyPatient.Status) {
                        debugger;
                        let tempTableData = this.state.tableData;
                        let payloadForPatientSalaryMatrix = SaveSickLeaveSalaryMatrix(resAcompanyPatient.data.id, tempTableData);

                        let endPoint = 'SaveSickLeaveSalaryMatrix'
                        let urlForSalaryMatrix = Config.getUrl(endPoint);

                        ApiRequest.sendRequestForPost(urlForSalaryMatrix, payloadForPatientSalaryMatrix, resForSalaryMatrix => {
                            if (resForSalaryMatrix.Status) {
                                let totalFile = 9;
                                this.uploadFile(0, totalFile, resAcompanyPatient, 0);
                            }
                        })


                    } else {
                        debugger;
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].error_message
                            }, () => {
                                this.alertSubmit();
                            })
                        });
                    }
                })
            })
        })
    }
}



stepTwoPrev = (obj) => {

    this.setState({
        step1: true, step2: false,
        EmployeePassport: obj.EmployeePassport,
        EmployeeEID: obj.EmployeeEID,
        SickLeaveHealthreport: obj.SickLeaveHealthreport,
        SickLeaveEmbassyLetter: obj.SickLeaveEmbassyLetter,
        SickLeaveHRCom: obj.SickLeaveHRCom,
        EmployeeIncome: obj.EmployeeIncome,
    });
}

saveAsDraft = (obj) => {

    debugger;
    if (obj.saveFrom === "Step1") {
        this.setState({
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            formType: obj.formType,
          
            empJob: obj.empJob,
            grade: obj.grade,
            basicSalary: obj.basicSalary,
            totalSalary: obj.totalSalary,
            retirementContributions: obj.retirementContributions,
            durationOfAccompanyingLeave: obj.durationOfAccompanyingLeave,
            dateOfSalaryTermination: obj.dateOfSalaryTermination,
            requiredToPayBudget: obj.requiredToPayBudget,
            selectedOption2: obj.selectedOption2,
            budgetYear: obj.budgetYear,
            budgetValue: obj.budgetValue,
            annualBalanceOfVacations: obj.annualBalanceOfVacations,
            justificationsEmployee: obj.justificationsEmployee,
            dateOfDirectWork: obj.dateOfDirectWork,
            headlinesOfRequest: obj.headlinesOfRequest,
            employeeName: obj.employeeName,
            unifiedNumber: obj.unifiedNumber,
            budgetAvailabilityYear: obj.budgetAvailabilityYear,
            emiratesIDStepOne: obj.emiratesIDStepOne,
            exemptionSelectedValue: obj.exemptionSelectedValue,
            totalDays: obj.totalDays,
            myTemp: obj.myTemp,
            // dateOfStartingWork: obj.dateOfStartingWork,

            HowToCalculate: obj.HowToCalculate,
            violation: obj.violation,
            explain: obj.explain,
            tableData: obj.tableData,
            basicSalaryTable: obj.basicSalaryTable,
            subTotalTotal: obj.subTotalTotal,
            totalNoOfDays: obj.totalNoOfDays,
            totalSalaryWithSubscription: obj.totalSalaryWithSubscription,
            retirementSalary: obj.retirementSalary,
            tswsrsSalary: obj.tswsrsSalary
          
        }, () => {
            this.submitApiCall();
        })
    }else {

        this.setState({
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            formType: obj.formType,
            // requestType: obj.requestType,
            // RequestType: obj.RequestType,
            EmployeePassport: obj.EmployeePassport,
            EmployeeEID: obj.EmployeeEID,
            SickLeaveHealthreport: obj.SickLeaveHealthreport,
            SickLeaveEmbassyLetter: obj.SickLeaveEmbassyLetter,
            SickLeaveHRCom: obj.SickLeaveHRCom,
            EmployeeIncome: obj.EmployeeIncome,
        }, () => {
            this.submitApiCall();
        })
    }
  

}



stepTwoData = (obj) => {
    if (obj.saveFrom === "Step1") {
        this.setState({
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            formType: obj.formType,
            employeeName: obj.employeeName,
            EmployeeInfoEmployeeNumber: obj.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: obj.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: obj.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: obj.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: obj.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: obj.EmployeeInfoTotalSalary,
            EmployeeInfoBasicSalary: obj.EmployeeInfoBasicSalary,
            //durationOfAccompanyingLeave: obj.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: obj.EmployeeInfoRetirementcontributions,
            administrativeProcedures: obj.administrativeProcedures,
            EmployeeYearBalance: obj.EmployeeYearBalance,
            EmployeeLeavePeriod: obj.EmployeeLeavePeriod,
            DatetoReturnToWork: obj.DatetoReturnToWork,
            DateoSalaryStop: obj.DateoSalaryStop,
            SalaryStatus: obj.SalaryStatus,
            BudgetAviable: obj.BudgetAviable,
            YearForBudgetAviable: obj.YearForBudgetAviable,
            RequiredAdditionalBudget: obj.RequiredAdditionalBudget,
            RequiredAdditionalBudgetYear: obj.RequiredAdditionalBudgetYear,
            RequiredAdditionalBudgetVal: obj.RequiredAdditionalBudgetVal,
            totalSalaryWithSubscription: obj.totalSalaryWithSubscription,
            retirementSalary: obj.retirementSalary,
            tswsrsSalary: obj.tswsrsSalary,
            
        }, () => {
            this.submitApiCall();
        })
    }
    else {
        this.setState({
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            formType: obj.formType,
            EmployeePassport: obj.EmployeePassport,
            EmployeeEID: obj.EmployeeEID,
            SickLeaveHealthreport: obj.SickLeaveHealthreport,
            SickLeaveEmbassyLetter: obj.SickLeaveEmbassyLetter,
            SickLeaveHRCom: obj.SickLeaveHRCom,
            EmployeeIncome: obj.EmployeeIncome,
        }, () => {
            this.submitApiCall();
        });
    }

}




    render() {
     //   let x = this.state.EmployeeInfoEmployeeNumber;
       debugger;
        const sideBarData = Config.getServiceSideBar('SickLeave', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid style={{ height: '100vh' }}>
            <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                    <MDBRow
                        className="formWhiteContainer"
                        style={{
                            //  width: '100vw',
                            flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                        }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'SickLeave' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].SickLeave.subject}
                                    />
                                </MDBCol>
                            </MDBRow>

                            
                      
                          {/* <Upload  isArabic={this.state.isArabic}/> */}
                      
                        
 { this.state.step1 ? <SickLeaveForm        isArabic={this.state.isArabic}
                                        sendData={{
                                            empJob: this.state.empJob,
                                            requestType: this.state.requestType,
                                            grade: this.state.grade,
                                            basicSalary: this.state.basicSalary,
                                            totalSalary: this.state.totalSalary,
                                            retirementContributions: this.state.retirementContributions,
                                            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                            dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                            requiredToPayBudget: this.state.requiredToPayBudget,
                                            selectedOption2: this.state.selectedOption2,
                                            budgetYear: this.state.budgetYear,
                                            budgetValue: this.state.budgetValue,
                                            annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                            justificationsEmployee: this.state.justificationsEmployee,
                                            dateOfDirectWork: this.state.dateOfDirectWork,
                                            headlinesOfRequest: this.state.headlinesOfRequest,
                                            employeeName: this.state.employeeName,
                                            unifiedNumber: this.state.unifiedNumber,
                                            budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                            emiratesIDStepOne: this.state.emiratesIDStepOne,
                                            exemptionSelectedValue: this.state.exemptionSelectedValue,
                                            requestTypeLabel: this.state.requestTypeLabel,
                                            totalDays: this.state.totalDays,
                                            myTemp: this.state.myTemp,
                                                //  dateOfStartingWork: this.state.dateOfStartingWork,
                                            
                                                    dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                                     
                                                              
                                                                    HowToCalculate: this.state.HowToCalculate,
                                                                        violation: this.state.violation,
                                                                            explain: this.state.explain,
                                                                                tableData: this.state.tableData,
                                                                                    basicSalaryTable: this.state.basicSalaryTable != '' ? this.state.basicSalaryTable : this.state.basicSalary,
                                        
                                                                                        totalNoOfDays: this.state.totalNoOfDays,
                                                                                            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription != '' ? this.state.totalSalaryWithSubscription : this.state.totalSalary,
                                                                                                retirementSalary: this.state.retirementSalary != '' ? this.state.retirementSalary : this.state.retirementContributions,
                                                                                                    tswsrsSalary: this.state.tswsrsSalary != '' ? this.state.tswsrsSalary : parseFloat(this.state.totalSalary.replace(',', '')) + parseFloat(this.state.retirementContributions.replace(',', '')),
                                        
                                        }}
                                        getData={this.getData}
                                        saveAsDraft={this.saveAsDraft} 
                                        stepOneData={this.stepOneData}
                                        />
                                    :

                                    <SickLeaveUpload isArabic={this.state.isArabic} 
                                    getData={this.getData}
                                    stepTwoData={ this.stepTwoData}
    
        stepTwoPrev={ this.stepTwoPrev}
                                      saveAsDraft={this.saveAsDraft}
                                    sendData={{
                                        isEdit: this.state.isEdit,
                                        requestId: this.state.requestId,
                                        EmployeePassport:this.state.EmployeePassport,
                                                EmployeeEID:this.state.EmployeeEID,
                                                SickLeaveHealthreport:this.state.SickLeaveHealthreport,
                                                SickLeaveEmbassyLetter:this.state.SickLeaveEmbassyLetter,
                                                SickLeaveHRCom:this.state.SickLeaveHRCom,
                                                EmployeeIncome:this.state.EmployeeIncome,
                                    }} previewPage={this.handlePreViewNavigation} />
                                    } 
                        <div style={{clear:"both",height:"100px"}}/>
                          <Footer isArabic={this.state.isArabic} />
                            </MDBCol>
                            
                            <SideBar
                            canViewSCI={1}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
        </MDBRow>
        </LoadingOverlay>
</MDBContainer>
        );
    }
}