import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import StepsComponent from '../StepsComponent';
import i18n from '../../i18n';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';

export default class SecondmentForFederalAgencyStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestType: Config.getSecondmentForFederalAgencyRequestTypeId(),
            file1: '',
            radio: 0,
            isNextScreenActive: false,
            isArabic: true,

            //Step 1 variables
            employeeName: this.props.sendData.employeeName,
            entityName: this.props.sendData.entityName,
            jobName: this.props.sendData.jobName,
            employeeNumber: this.props.sendData.employeeNumber,
            basicSalary: this.props.sendData.basicSalary,
            totalSalary: this.props.sendData.totalSalary,
            entityRespForSal: this.props.sendData.entityRespForSal,
            fromDatePeriodOfSecndMent: this.props.sendData.fromDatePeriodOfSecndMent,
            toDatePeriodOfSecndMent: this.props.sendData.toDatePeriodOfSecndMent,
            retirementCont: this.props.sendData.retirementCont,
            unifiedNumber: this.props.sendData.unifiedNumber,
            emiratesID: this.props.sendData.emiratesID,
            empPassNoticePeriod: this.props.sendData.empPassNoticePeriod,
            lastYearPerformanceEfficiency: this.props.sendData.lastYearPerformanceEfficiency,
            employeeAgreement: this.props.sendData.employeeAgreement,
            empShouldPassProbationPeriodLabel: this.props.sendData.empShouldPassProbationPeriodLabel,
            lastYearPerfDegMoreThanWeekLabel: this.props.sendData.lastYearPerfDegMoreThanWeekLabel,
            employeeAgreementLabel: this.props.sendData.employeeAgreementLabel,
            reasonForGovPaySalary:this.props.sendData.reasonForGovPaySalary,
            BorrowEntityID:this.props.sendData.BorrowEntityID,
            //Step 2 variables
            reqForBrowEntity: this.props.sendData.reqForBrowEntity,
            reqForBrowEntityFileObj: this.props.sendData.reqForBrowEntityFileObj,
            reqForBorrowUrl: '',
            jobDescFileName: this.props.sendData.jobDescFileName,
            jobDescFileNameFileObj: this.props.sendData.jobDescFileNameFileObj,
            jobDescUrl: '',
            approvalOfEmpFileObj: this.props.sendData.approvalOfEmpFileObj,
            approvalEmpFileName: this.props.sendData.approvalEmpFileName,
            approvalUrl: '',
            copyOfHRCommFileObj: this.props.sendData.copyOfHRCommFileObj,
            copyOfHRCommitteFileName: this.props.sendData.copyOfHRCommitteFileName,
            copyOfHrCommUrl: '',
            copyOfLastYearEffiFileObj: this.props.sendData.copyOfLastYearEffiFileObj,
            copyOfLastYearEffiReportFileName: this.props.sendData.copyOfLastYearEffiReportFileName,
            copyOfLastYearEffUrl: '',
            emiratesIdFileObj: this.props.sendData.emiratesIdFileObj,
            emiratesIdFileName: this.props.sendData.emiratesIdFileName,
            emiratedIdUrl: '',
            passportCopyFileObj: this.props.sendData.passportCopyFileObj,
            passportCopyFileName: this.props.sendData.passportCopyFileName,
            passportCopyUrl: '',
            familyBookFileObj: this.props.sendData.familyBookFileObj,
            famliyBookFileName: this.props.sendData.famliyBookFileName,
            familyBookUrl: '',
            reqForBrowEntityErr: "",
            periodOfSendMentErr: '',
            jobDescFileErr: '',
            copyOfHRCommitteError: '',
            approvalEmpFileErr: '',
            copyOfLastYearEffiReportFileError: '',
            familyBookFileErr: '',
            passportCopyFileError: '',
            emiratesIdFileErr: '',
            hraEmpStudyFileObj: this.props.sendData.hraEmpStudyFileObj,
            hraEmpStudyFileErr: '',
            // reqByCompEntyErr: '',
            // reqByCompEnty: this.props.sendData.reqByCompEnty,
            // reqByCompEntyFileObj: this.props.sendData.reqByCompEntyFileObj,
            // reqByCompEntyUrl: '',
            requestId: '',
            isEdit: false,
            statusid: '',
            editAttachmentArray: [],
            approveEmployeeArray: [],
            requestBrowingEntityArray: [],
            jobDescriptionArray: [],
            lastYearEfficiencyArray: [],
            emaritesArray: [],
            passportCopyArray: [],
            familyBookArray: [],
            copyOfHrComitteArrayArray: [],
            reqByTheCompetentEntityArray: [],    OtherAttachArray: [],
            OtherAttachErr: "",
            OtherAttach: this.props.sendData.OtherAttach,
            OtherAttachFileObj: this.props.sendData.OtherAttachFileObj,

            CurrentJobDescErr: "",
            CurrentJobDesc: this.props.sendData.CurrentJobDesc,
            CurrentJobDescFileObj:this.props.sendData.CurrentJobDescFileObj,
            CurrentJobDescUrl:"",
            CurrentJobDescArray:[],
        }
    }

    componentDidMount = () => {

        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
            this.setState({
                isEdit: editFlag,
                requestId: editObj.requestId,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempِApproveEmployeeArray = [];
                let tempRequestBrowingEntityArray = [];
                let tempJobDescriptionArray = [];
                let tempLastYearEfficiencyArray = [];
                let tempEmaritesArray = [];
                let tempPassportCopyArray = [];
                let tempFamilyBookArray = [];
                let tempCopyOfHrComitteArrayArray = [];
                let tempReqByTheCompetentEntityArray = [];
                let tempOtherAttachArray = [];
                let tempCurrentJobDescArray=[];
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getApprovalEmployeeId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempِApproveEmployeeArray.push(temp);

                                this.setState({
                                    //approvalEmpFileName: item.filename,
                                    approvalUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBorrowingEntityId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempRequestBrowingEntityArray.push(temp);

                                this.setState({
                                    //reqForBrowEntity: item.filename,
                                    reqForBorrowUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getJobDescriptionId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempJobDescriptionArray.push(temp);

                                this.setState({
                                    //jobDescFileName: item.filename,
                                    jobDescUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getLastYearEfficiencyReportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempLastYearEfficiencyArray.push(temp);

                                this.setState({
                                    //copyOfLastYearEffiReportFileName: item.filename,
                                    copyOfLastYearEffUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getEmaritesId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEmaritesArray.push(temp);

                                this.setState({
                                    //emiratesIdFileName: item.filename,
                                    emiratedIdUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPassportCopyId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPassportCopyArray.push(temp);

                                this.setState({
                                    //passportCopyFileName: item.filename,
                                    passportCopyUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getFamilyBookId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempFamilyBookArray.push(temp);
 
                                this.setState({
                                    //famliyBookFileName: item.filename,
                                    familyBookUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getHrCommitteId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempCopyOfHrComitteArrayArray.push(temp);

                                this.setState({
                                    //copyOfHRCommitteFileName: item.filename,
                                    copyOfHrCommUrl: item.aliasfilename
                                })
                            } 
                            else if (item.filetypeid === Config.getCompetentEntityId()) 
                            {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempReqByTheCompetentEntityArray.push(temp);

                                this.setState({
                                    //reqByCompEnty: item.filename,
                                    reqByCompEntyUrl: item.aliasfilename
                                })

                                
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETOtherAttachId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempOtherAttachArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getFederalCurrentJobDescId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempCurrentJobDescArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                    CurrentJobDescUrl:item.aliasfilename
                                })
                            }
                        })

                        this.setState({
                            approveEmployeeArray: tempِApproveEmployeeArray,
                            requestBrowingEntityArray: tempRequestBrowingEntityArray,
                            jobDescriptionArray: tempJobDescriptionArray,
                            lastYearEfficiencyArray: tempLastYearEfficiencyArray,
                            emaritesArray: tempEmaritesArray,
                            passportCopyArray: tempPassportCopyArray,
                            // familyBookArray: tempFamilyBookArray,
                            copyOfHrComitteArrayArray: tempCopyOfHrComitteArrayArray,
                            // reqByTheCompetentEntityArray: tempReqByTheCompetentEntityArray,
                            OtherAttachArray:tempOtherAttachArray,
                            CurrentJobDescArray:tempCurrentJobDescArray,
                        })
                    }
                }

            });

        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    handlePreViewNavigation = () => {
        this.props.previewPage({
            approvalEmpFileName: this.state.approvalEmpFileName,
            reqForBrowEntity: this.state.reqForBrowEntity,
            copyOfHRCommitteFileName: this.state.copyOfHRCommitteFileName,
            jobDescFileName: this.state.jobDescFileName,
            copyOfLastYearEffiReportFileName: this.state.copyOfLastYearEffiReportFileName,
            emiratesIdFileName: this.state.emiratesIdFileName,
            passportCopyFileName: this.state.passportCopyFileName,
            // famliyBookFileName: this.state.famliyBookFileName,
            // reqByCompEnty: this.state.reqByCompEnty,
            OtherAttach:this.state.OtherAttach,
            CurrentJobDesc:this.state.CurrentJobDesc,
        });
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            approvalEmpFileName: this.state.approvalEmpFileName,
            reqForBrowEntity: this.state.reqForBrowEntity,
            copyOfHRCommitteFileName: this.state.copyOfHRCommitteFileName,
            jobDescFileName: this.state.jobDescFileName,
            copyOfLastYearEffiReportFileName: this.state.copyOfLastYearEffiReportFileName,
            emiratesIdFileName: this.state.emiratesIdFileName,
            passportCopyFileName: this.state.passportCopyFileName,
            // famliyBookFileName: this.state.famliyBookFileName,
            // reqByCompEnty: this.state.reqByCompEnty,
            OtherAttach:this.state.OtherAttach,
            CurrentJobDesc:this.state.CurrentJobDesc,
        });
    };

    checkValidations = () => {

        let approvalEmpFileErr = '',
            reqByCompEntyErr = '',
            reqForBrowEntityErr = '',
            copyOfHRCommitteError = '',
            jobDescFileErr = '',
            copyOfLastYearEffiReportFileError = '',
            emiratesIdFileErr = '',
            passportCopyFileError = '',
            familyBookFileErr = '',CurrentJobDescErr='';

            if(this.state.CurrentJobDescArray && this.state.CurrentJobDescArray.length>0)
            {
                CurrentJobDescErr='';
            }
            else
            {
                if(!this.state.CurrentJobDesc)
                {
                    CurrentJobDescErr='thisFieldIsRequired';
                }
                
            }
        if (this.state.approveEmployeeArray && this.state.approveEmployeeArray.length > 0) {
            approvalEmpFileErr = "";
        } else {
            if (!this.state.approvalEmpFileName) {
                approvalEmpFileErr = "thisFieldIsRequired";
            }
        }

        if (this.state.copyOfHrComitteArrayArray && this.state.copyOfHrComitteArrayArray.length > 0) {
            copyOfHRCommitteError = "";
        } else {
            if (!this.state.copyOfHRCommitteFileName) {
                copyOfHRCommitteError = "thisFieldIsRequired";
            }
        }

        if (this.state.reqByTheCompetentEntityArray && this.state.reqByTheCompetentEntityArray.length > 0) {
            reqByCompEntyErr = "";
        } else {
            if (!this.state.reqByCompEnty)
                reqByCompEntyErr = "thisFieldIsRequired";
        }

        if (this.state.requestBrowingEntityArray && this.state.requestBrowingEntityArray.length > 0) {
            reqForBrowEntityErr = "";
        } else {
            if (!this.state.reqForBrowEntity)
                reqForBrowEntityErr = "thisFieldIsRequired";
        }

        if (this.state.jobDescriptionArray && this.state.jobDescriptionArray.length > 0) {
            jobDescFileErr = "";
        } else {
            if (!this.state.jobDescFileName)
                jobDescFileErr = "thisFieldIsRequired";
        }

        if (this.state.lastYearEfficiencyArray && this.state.lastYearEfficiencyArray.length > 0) {
            copyOfLastYearEffiReportFileError = ""
        } else {
            if (!this.state.copyOfLastYearEffiReportFileName)
                copyOfLastYearEffiReportFileError = "thisFieldIsRequired";
        }

        if (this.state.emaritesArray && this.state.emaritesArray.length > 0) {
            emiratesIdFileErr = "";
        } else {
            if (!this.state.emiratesIdFileName)
                emiratesIdFileErr = "thisFieldIsRequired";
        }

        if (this.state.passportCopyArray && this.state.passportCopyArray.length > 0) {
            passportCopyFileError = "";
        } else {
            if (!this.state.passportCopyFileName)
                passportCopyFileError = "thisFieldIsRequired";
        }

        // if (this.state.familyBookArray.length > 0) {
        //     familyBookFileErr = "";
        // } else {
        //     if (!this.state.famliyBookFileName)
        //         familyBookFileErr = "thisFieldIsRequired";
        // }

        this.setState({
            approvalEmpFileErr: approvalEmpFileErr,
            reqByCompEntyErr: reqByCompEntyErr,
            reqForBrowEntityErr: reqForBrowEntityErr,
            copyOfHRCommitteError: copyOfHRCommitteError,
            jobDescFileErr: jobDescFileErr,
            copyOfLastYearEffiReportFileError: copyOfLastYearEffiReportFileError,
            emiratesIdFileErr: emiratesIdFileErr,
            passportCopyFileError: passportCopyFileError,
            familyBookFileErr: familyBookFileErr,CurrentJobDescErr
        }, () => {
         
            if (!approvalEmpFileErr && 
                // !reqByCompEntyErr && 
                !reqForBrowEntityErr && 
                !copyOfHRCommitteError && 
                !jobDescFileErr && 
                !copyOfLastYearEffiReportFileError && 
                !emiratesIdFileErr && 
                !passportCopyFileError && !CurrentJobDescErr
            ) {
                this.props.getData.stepTwoData({
                    approvalEmpFileName: this.state.approvalEmpFileName,
                    reqForBrowEntity: this.state.reqForBrowEntity,
                    copyOfHRCommitteFileName: this.state.copyOfHRCommitteFileName,
                    jobDescFileName: this.state.jobDescFileName,
                    copyOfLastYearEffiReportFileName: this.state.copyOfLastYearEffiReportFileName,
                    emiratesIdFileName: this.state.emiratesIdFileName,
                    passportCopyFileName: this.state.passportCopyFileName,
                    // famliyBookFileName: this.state.famliyBookFileName,
                    reqByCompEnty: this.state.reqByCompEnty,
                    requestId: this.state.requestId,
                    isEdit: this.state.isEdit,
                    OtherAttach:this.state.OtherAttach,
                    CurrentJobDesc:this.state.CurrentJobDesc,
                });
            }
        });
    }

    OtherAttachOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ OtherAttach: e.target.files[0].name, OtherAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                OtherAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForOtherAttach(this.state.OtherAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ OtherAttach: '', OtherAttachErr: 'thisFieldIsRequired' });
        //}
    };
    withoutValidation = () => {
        this.props.getData.stepTwoData({
            approvalEmpFileName: this.state.approvalEmpFileName,
            reqForBrowEntity: this.state.reqForBrowEntity,
            copyOfHRCommitteFileName: this.state.copyOfHRCommitteFileName,
            jobDescFileName: this.state.jobDescFileName,
            copyOfLastYearEffiReportFileName: this.state.copyOfLastYearEffiReportFileName,
            emiratesIdFileName: this.state.emiratesIdFileName,
            passportCopyFileName: this.state.passportCopyFileName,
            // famliyBookFileName: this.state.famliyBookFileName,
            // reqByCompEnty: this.state.reqByCompEnty,
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            CurrentJobDesc:this.state.CurrentJobDesc,
            OtherAttach:this.state.OtherAttach
        });
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
    };

    approvalEmpFileChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ approvalEmpFileName: '', approvalEmpFileErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let fileTargetVarible = e.target.files[0];
                    this.setState({
                        approvalEmpFileName: e.target.files[0].name,
                        approvalOfEmpFileObj: e.target.files[0],
                        approvalEmpFileErr: ''
                    }, () => {

                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                approvalEmpFileNameFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_approvalEmpFileName(this.state.approvalEmpFileNameFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ approvalEmpFileName: '', approvalEmpFileErr: 'invalidFileSize' });
                }
            }
        };
    }

    reqForBrowEntityOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ reqForBrowEntity: '', reqForBrowEntityErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({
                        reqForBrowEntity: e.target.files[0].name,
                        reqForBrowEntityFileObj: e.target.files[0],
                        reqForBrowEntityErr: ''
                    }, () => {

                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                reqForBrowEntityFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_reqForBrowEntity(this.state.reqForBrowEntityFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ reqForBrowEntity: '', reqForBrowEntityErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ reqForBrowEntity: '', reqForBrowEntityErr: "thisFieldIsRequired" });
        }
    };

    copyOfHRCommitteFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ copyOfHRCommitteFileName: '', copyOfHRCommitteError: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        copyOfHRCommitteFileName: e.target.files[0].name, 
                        copyOfHRCommFileObj: e.target.files[0], copyOfHRCommitteError: '' 
                    }, () => {

                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                copyOfHRCommFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_copyOfHRCommitteFileName(this.state.copyOfHRCommFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ copyOfHRCommitteFileName: '', copyOfHRCommitteError: 'invalidFileSize' });
                }
            }
        }
        else {
            e.target.value = '';
            this.setState({ copyOfHRCommitteFileName: '' });
        }

    }

    jobDescFileOnChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ jobDescFileName: '', jobDescFileErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        jobDescFileName: e.target.files[0].name, 
                        jobDescFileErr: '' 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                jobDescFileNameFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_jobDescFileName(this.state.jobDescFileNameFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ jobDescFileName: '', jobDescFileErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ jobDescFileName: '', jobDescFileErr: "thisFieldIsRequired" });
        }
    };

    copyOfLastYearEfficiencyReportChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ 
                    copyOfLastYearEffiReportFileName: '', 
                    copyOfLastYearEffiReportFileError: "invalidFileType" 
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        copyOfLastYearEffiReportFileName: e.target.files[0].name, 
                        copyOfLastYearEffiFileObj: e.target.files[0], 
                        copyOfLastYearEffiReportFileError: '' 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);

                        reader.onloadend = () => {
                            this.setState({
                                copyOfLastYearEffiFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_copyOfLastYearEffiReportFileName(this.state.copyOfLastYearEffiFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ copyOfLastYearEffiReportFileName: '', copyOfLastYearEffiReportFileError: 'invalidFileSize' });
                }
            }

        } else {
            e.target.value = '';
            this.setState({ copyOfLastYearEffiReportFileName: '', copyOfLastYearEffiReportFileError: "thisFieldIsRequired" });
        }
    };

    emiratesIdFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ emiratesIdFileName: '', emiratesIdFileErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        emiratesIdFileName: e.target.files[0].name, 
                        emiratesIdFileErr: '', 
                        emiratesIdFileObj: e.target.files[0] 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);

                        reader.onloadend = () => {
                            this.setState({
                                emiratesIdFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_emiratesIdFileName(this.state.emiratesIdFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ emiratesIdFileName: '', emiratesIdFileErr: 'invalidFileSize' });
                }
            }
        }
        else {
            e.target.value = '';
            this.setState({ emiratesIdFileName: '', emiratesIdFileErr: "thisFieldIsRequired" });
        }
    };

    passportCopyFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ passportCopyFileName: '', passportCopyFileError: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        passportCopyFileName: e.target.files[0].name, passportCopyFileError: '', passportCopyFileObj: e.target.files[0] 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);

                        reader.onloadend = () => {
                            this.setState({
                                passportCopyFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_passportCopyFileName(this.state.passportCopyFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ passportCopyFileName: '', passportCopyFileError: "invalidFileSize" });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ passportCopyFileName: '', passportCopyFileError: "thisFieldIsRequired" });
        }
    };

    reqByCompEntyOnChange = (e) => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({ reqByCompEnty: '', reqByCompEntyErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        reqByCompEnty: e.target.files[0].name, 
                        reqByCompEntyErr: '' 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);

                        reader.onloadend = () => {
                            this.setState({
                                reqByCompEntyFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_reqForCompEntity(this.state.reqByCompEntyFileObj);
                            });
                        }
                    });

                }
                else {
                    this.setState({ reqByCompEnty: '', reqByCompEntyErr: "invalidFileSize" });
                }
            }

        } else {
            this.setState({ reqByCompEnty: '', reqByCompEntyErr: "thisFieldIsRequired" });
        }
    };

    familyBookFileChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ famliyBookFileName: '', familyBookFileErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        famliyBookFileName: e.target.files[0].name, 
                        familyBookFileErr: '', 
                        familyBookFileObj: e.target.files[0] 
                    }, () => {

                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);

                        reader.onloadend = () => {
                            this.setState({
                                familyBookFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_famliyBookFileName(this.state.familyBookFileObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ famliyBookFileName: '', familyBookFileErr: "invalidFileSize" });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ famliyBookFileName: '', familyBookFileErr: "thisFieldIsRequired" });
        }
    };

    CurrentJobDescChange = e => {
        debugger;
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ CurrentJobDesc: '', CurrentJobDescErr: "invalidFileType" });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        CurrentJobDesc: e.target.files[0].name, 
                        CurrentJobDescErr: '', 
                        CurrentJobDescObj: e.target.files[0] 
                    }, () => {

                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);

                        reader.onloadend = () => {
                            this.setState({
                                CurrentJobDescObj: reader.result,
                            }, () => {
                                Config.setBase64ForSecondment_CurrentJobDesc(this.state.CurrentJobDescObj);
                            });
                        }
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ CurrentJobDesc: '', CurrentJobDescErr: "invalidFileSize" });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ CurrentJobDesc: '', CurrentJobDescErr: "thisFieldIsRequired" });
        }
    };

    prev = () => {
        this.props.getPrev.stepTwoDataPrev({
            approvalEmpFileName: this.state.approvalEmpFileName,
            reqForBrowEntity: this.state.reqForBrowEntity,
            copyOfHRCommitteFileName: this.state.copyOfHRCommitteFileName,
            jobDescFileName: this.state.jobDescFileName,
            copyOfLastYearEffiReportFileName: this.state.copyOfLastYearEffiReportFileName,
            emiratesIdFileName: this.state.emiratesIdFileName,
            passportCopyFileName: this.state.passportCopyFileName,
            famliyBookFileName: this.state.famliyBookFileName,
            reqByCompEnty: this.state.reqByCompEnty,
            OtherAttach:this.state.OtherAttach,CurrentJobDesc:this.state.CurrentJobDesc
        })
    }

    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <br />

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Request_By_The_Competent_Entity}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="reqByCompEntyFile"
                                    type="file"
                                    name="reqByCompEnty"
                                    onChange={(event) => this.reqByCompEntyOnChange(event)}
                                    required
                                />
                                <label htmlFor="reqByCompEntyFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}</label>
                                <label htmlFor="reqByCompEntyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.reqByCompEnty}</span></label>
                            </div>

                            {
                                this.state.reqByCompEntyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.reqByCompEntyErr === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.reqByCompEntyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {/* {
                            this.state.isEdit ?
                                <DownloadComponent filesObj={this.state.reqByTheCompetentEntityArray} /> : ''
                        } */}
                        {/* {
                            this.state.isEdit ?
                                this.state.reqByTheCompetentEntityArray.length > 0 ?
                                    <DownloadComponent filesObj={this.state.reqByTheCompetentEntityArray} /> : ''
                                :
                                null
                        } */}
                        {/* {
                            (this.state.reqByTheCompetentEntityArray &&
                                this.state.reqByTheCompetentEntityArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.reqByTheCompetentEntityArray} />
                                : <span></span>
                        }
                    </MDBCol> */}
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"> 

                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Request_of_The_Borrowing_Entity}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp" >
                                <input
                                    id="reqForBrowEntityFile"
                                    type="file"
                                    name="reqForBrowEntity"
                                    onChange={(event) => this.reqForBrowEntityOnChange(event)}
                                    required
                                />
                                <label htmlFor="reqForBrowEntityFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.getLocale()].chooseFile}
                                </label>
                                <label htmlFor="reqForBrowEntityFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.reqForBrowEntity}</span>
                                </label>
                            </div>

                            {this.state.reqForBrowEntityErr === "thisFieldIsRequired" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}

                            {
                                this.state.reqForBrowEntityErr === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.reqForBrowEntityErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {/* {
                            this.state.isEdit ?
                                this.state.requestBrowingEntityArray.length > 0 ?
                                    <DownloadComponent filesObj={this.state.requestBrowingEntityArray} /> : ''
                                :
                                null
                        } */}
                        {
                            (this.state.requestBrowingEntityArray != undefined &&
                                this.state.requestBrowingEntityArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.requestBrowingEntityArray} />
                                : <span></span>
                        }
                    </MDBCol>
                </MDBRow>
                <br />

                <MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Job_Description}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="jobDescFileId"
                                    type="file"
                                    name="jobDesc"
                                    onChange={(event) => this.jobDescFileOnChange(event)}
                                    required
                                />
                                <label htmlFor="jobDescFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label htmlFor="jobDescFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.jobDescFileName}</span></label>
                            </div>
                            {
                                this.state.jobDescFileErr === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.jobDescFileErr === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.jobDescFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {/* {
                            this.state.isEdit ?
                                this.state.jobDescriptionArray.length > 0 ?
                                    <DownloadComponent filesObj={this.state.jobDescriptionArray} /> : ''
                                :
                                null
                        } */}
                        {
                            (this.state.jobDescriptionArray &&
                                this.state.jobDescriptionArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.jobDescriptionArray} />
                                : <span></span>
                        }
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="CurrentJobDesc" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.CurrentJobDesc}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="CurrentJobDesc"
                                    type="file"
                                    name="CurrentJobDesc"
                                    onChange={(event) => this.CurrentJobDescChange(event)}
                                    required
                                />
                                <label htmlFor="CurrentJobDesc" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="CurrentJobDesc" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.CurrentJobDesc}</span></label>
                            </div>
                            {
                                this.state.CurrentJobDescErr === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.CurrentJobDescErr === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.CurrentJobDescErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].acceptedFormatsAll}
                            </p>
                        </div>
                        {/* {
                            this.state.isEdit ?
                                this.state.lastYearEfficiencyArray.length > 0 ?
                                    <DownloadComponent filesObj={this.state.lastYearEfficiencyArray} /> : ''
                                :
                                null
                        } */}
                        {
                            (this.state.CurrentJobDescArray &&
                                this.state.CurrentJobDescArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.CurrentJobDescArray} />
                                : <span></span>
                        }
                    </MDBCol>
                </MDBRow>
                <br />

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Emairates_Id}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="emiratesIdFileName"
                                    type="file"
                                    name="emiratesIdFileName"
                                    onChange={(event) => this.emiratesIdFileChange(event)}
                                    required
                                />
                                <label htmlFor="emiratesIdFileName" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="emiratesIdFileName" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.emiratesIdFileName}</span></label>
                            </div>
                            {
                                this.state.emiratesIdFileErr === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emiratesIdFileErr === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emiratesIdFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {
                            (this.state.emaritesArray &&
                                this.state.emaritesArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.emaritesArray} />
                                : <span></span>
                        }
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Passport_copy}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFileId"
                                    type="file"
                                    name="passportCopyFileName"
                                    onChange={(event) => this.passportCopyFileChange(event)}
                                    required
                                />
                                <label htmlFor="passportCopyFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}</label>
                                <label htmlFor="passportCopyFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.passportCopyFileName}</span></label>
                            </div>
                            {
                                this.state.passportCopyFileError === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyFileError === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyFileError === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {
                            (this.state.passportCopyArray &&
                                this.state.passportCopyArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                : <span></span>
                        }
                    </MDBCol>
                </MDBRow>
                <br />

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Family_book}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="familyBookFileId"
                                    type="file"
                                    name="famliyBookFileName"
                                    onChange={(event) => this.familyBookFileChange(event)}
                                    required
                                />
                                <label htmlFor="familyBookFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}</label>
                                <label htmlFor="familyBookFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.famliyBookFileName}</span></label>
                            </div>


                            {this.state.familyBookFileErr == "thisFieldIsRequired" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}

                            {this.state.familyBookFileErr == "invalidFileType" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                </div>
                                :
                                null}

                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {
                            this.state.isEdit ? <DownloadComponent filesObj={this.state.familyBookArray} /> : ''
                        }
                    </MDBCol> */}

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" className={this.props.isArabic ?'txtRight':'txtLeft'}>
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Copy_Of_HR_Committe}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="copyOfHRComFileId"
                                    type="file"
                                    name="copyOfHRCommitteFileName"
                                    onChange={(event) => this.copyOfHRCommitteFileChange(event)}
                                    required
                                />
                                <label htmlFor="copyOfHRComFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}</label>
                                <label htmlFor="copyOfHRComFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.copyOfHRCommitteFileName}</span></label>
                            </div>
                            {
                                this.state.copyOfHRCommitteError === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfHRCommitteError === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfHRCommitteError === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].acceptedFormatsAll}
                            </p>
                        </div>
                        {
                            (this.state.copyOfHrComitteArrayArray &&
                                this.state.copyOfHrComitteArrayArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.copyOfHrComitteArrayArray} />
                                : <span></span>
                        }
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', paddingBottom: '3%'  }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Copy_of_last_year_eff_report}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="copyOfLastYearEffFileId"
                                    type="file"
                                    name="copyOfLastYearEffiReportFileName"
                                    onChange={(event) => this.copyOfLastYearEfficiencyReportChange(event)}
                                    required
                                />
                                <label htmlFor="copyOfLastYearEffFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="copyOfLastYearEffFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.copyOfLastYearEffiReportFileName}</span></label>
                            </div>
                            {
                                this.state.copyOfLastYearEffiReportFileError === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfLastYearEffiReportFileError === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfLastYearEffiReportFileError === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].acceptedFormatsAll}
                            </p>
                        </div>
                        {/* {
                            this.state.isEdit ?
                                this.state.lastYearEfficiencyArray.length > 0 ?
                                    <DownloadComponent filesObj={this.state.lastYearEfficiencyArray} /> : ''
                                :
                                null
                        } */}
                        {
                            (this.state.lastYearEfficiencyArray &&
                                this.state.lastYearEfficiencyArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.lastYearEfficiencyArray} />
                                : <span></span>
                        }
                    </MDBCol>
                
                </MDBRow>
                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Approval_Of_Employee}
                            <span className="requireStar"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="approvalEmpFileId"
                                    type="file"
                                    name="approvalEmpFileName"
                                    onChange={(event) => this.approvalEmpFileChange(event)}
                                    required
                                />
                                <label htmlFor="approvalEmpFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}</label>
                                <label htmlFor="approvalEmpFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.approvalEmpFileName}</span></label>
                            </div>
                            {
                                this.state.approvalEmpFileErr === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.approvalEmpFileErr === "invalidFileType" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.approvalEmpFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].acceptedFormatsAll}
                            </p>
                        </div>
                        {
                            (this.state.approveEmployeeArray &&
                                this.state.approveEmployeeArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.approveEmployeeArray} />
                                : <span></span>
                        }
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.OtherAttach}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="OtherAttachFile"
                                        type="file"
                                        name="OtherAttach"
                                        onChange={(event) => this.OtherAttachOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="OtherAttachFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="OtherAttachFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.OtherAttach}</span></label>
                                </div>

                                {
                                    this.state.OtherAttachErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.OtherAttachArray &&
                                    this.state.OtherAttachArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.OtherAttachArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>


                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn onClick={this.prev} color="gray" className="previousAndClearBtn">
                        {i18n[this.getLocale()].previous}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn type='submit' color="primary" className="submitBtn">
                        {i18n[this.props.isArabic ? 'ar' : 'en'].submit}
                    </MDBBtn>
                </MDBRow>
            </form>
        );
    }
}