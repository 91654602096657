import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import StepOne from '../components/SCIRequest/SCICreationForm';
import SubHeaderStrip from './../components/SubHeaderStrip';
import Footer from './../components/Footer';
import i18n from './../i18n';
import ApiRequest from './../services/ApiRequest';
import Config from './../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import { saveSciRequestPayload, uploadDocument } from './../utils/Payload';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import RequestSurvey from "../components/ServicesSurvey/RequestSurvey";
export default class SCIRequest extends Component {

    constructor() {
        super();
        // disableBrowserBackButton();
        this.state = {
            showpoup:false,
            isLoading: false,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isNextScreenActive: false,
            dataObject: {},
            requestType: 2,
            requestTypeLabelInSCI: '',
            applicantsJob: '',
            contactNumber: '',
            reqDetails: '',
            applicantsName: '',
            requestSubject: '',
            hraConcernedDept: '',
            hraFeedback: '',
            isSideBarMinimized: false,
            officialLetter: "",
            attachedTwo: "",
            canViewSCI: 0,
            canViewInformation: 0,
            loginData: ''
        }
    }

    componentDidMount = () => {
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData,
            }, () => {
                this.setState({
                    canViewSCI: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreviewscirequest,
                    canViewInformation: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewirrequest,
                })
            })
        }
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccessnew = () => {
        debugger; 
        this.setState({ showpoup:true});
        
        // confirmAlert({
        //     title: this.state.titleAlert,
        //     message: this.state.messageAlert,
        //     buttons: [
        //         {
        //             label: i18n[this.getLocale()].ok,
        //             onClick: () => this.props.history.replace('/listpage', {
        //                 pathname: 'SCIRequest'
        //             })
        //         }
        //     ],
        //     closeOnEscape: false,
        //     closeOnClickOutside: false,
        //     childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        // });
        // setTimeout(() => {
        //     document.getElementById('alertOkButton').focus()
        // }, 100);
    };

    alertSubmitForSuccess = () => {
        debugger;
        return (<RequestSurvey isArabic={this.state.isArabic} y={this.props.y} />);
        //  confirmAlert({
        //      title: this.state.titleAlert,
        //      message: this.state.messageAlert,
        //      buttons: [
        //          {
        //              label: i18n[this.getLocale()].ok,
        //              onClick: () => this.props.history.replace('/listpage', {
        //                  pathname: 'SCIRequest'
        //              })
        //          }
        //      ],
        //      closeOnEscape: false,
        //      closeOnClickOutside: false,
        //      childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        //  });
        //  setTimeout(() => {
        //      document.getElementById('alertOkButton').focus()
        //  }, 100);
    };

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    nextButtonPressed = (isNextScreenActive) => {
        
        this.setState({ isNextScreenActive: isNextScreenActive });
    }

    setData = (obj) => {
       
        Config.setIsEditFlag(false);
        Config.setIsPreviewFlag(false);
        Config.setPreviewObj();
        Config.setPreviewAttachmentArray();
        Config.setPreviewckEditorArray();
debugger;
        this.setState({
            dataObject: obj
        }, () => {
            if (this.state.dataObject.formType === "saveAsDraft") {
                // this.state.dataObject.status = 'saveAsDraft';
                this.state.dataObject.draft = 1;
            } else {
                // this.state.dataObject.status = 'submit';
                this.state.dataObject.draft = 0;
            }
            debugger;
            let payload = saveSciRequestPayload(this.state.dataObject);
            debugger;
                let endPoint = 'SaveSCIRequest';

            let url = Config.getUrl(endPoint);
            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                   
                    if (res.Status) {
                         console.log(this.state.dataObject);
                        if (this.state.dataObject.Files && this.state.dataObject.Files.length > 0) {
                            let Files = this.state.dataObject.Files;
                            let res1 = false;
                            if (this.state.dataObject.actualOfficialLetter)      
                        {
                            for(let i=0;i<Files.length;i++)
                            {
                                let obj = {};
                                obj.requestid = res.data.id;
                                obj.filetype = Config.getSciAttachmentId();
                                obj.filename = Files[i].filename;     
                                obj.fileContent=Files[i].filecontent;
                                let payload = uploadDocument(obj);
                                let endPoint = 'UploadAttachment';
                                let url = Config.getUrl(endPoint);
                                if (obj.filename && obj.filename !== '' && obj.filename !== undefined) {
                                    ApiRequest.sendRequestForPost(url, payload, res => {
                                        this.setState({
                                            isLoading: false
                                        }, () => {
                                            if (res.Status) {
                                                res1=true;
                                                if (i = this.state.dataObject.actualOfficialLetter.length-1) {

                                                    if (res1) {
                                                        if (this.state.dataObject.isEdit) {
                                                            if (this.state.dataObject.formType === "saveAsDraft") {
                                                                this.setState({
                                                                    titleAlert: i18n[this.getLocale()].success,
                                                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                                                }, () => {
                                                                    this.alertSubmitForSuccess();
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    titleAlert: i18n[this.getLocale()].success,
                                                                    messageAlert: i18n[this.getLocale()].request_updated
                                                                }, () => {
                                                                    this.alertSubmitForSuccess();
                                                                })
                                                            }
                                                        } else {
                                                            this.setState({
                                                                titleAlert: i18n[this.getLocale()].success,
                                                                messageAlert: i18n[this.getLocale()].request_created
                                                            }, () => {
                                                                this.alertSubmitForSuccess();
                                                            })
                                                        }
                                                    }
                                                
                                                    else {
                                                        this.setState({
                                                            titleAlert: i18n[this.getLocale()].error,
                                                            messageAlert: i18n[this.getLocale()].attachment_error
                                                        }, () => {
                                                            this.alertSubmit();
                                                        })
                                                
                                                
                                                
                                                    }
                                                
                                                
                                                }

                                                else
                                                {
                                                    if (this.state.dataObject.isEdit) {
                                                        if (this.state.dataObject.formType === "saveAsDraft") {
                                                            this.setState({
                                                                titleAlert: i18n[this.getLocale()].success,
                                                                messageAlert: i18n[this.getLocale()].save_as_Draft
                                                            }, () => {
                                                                this.alertSubmitForSuccess();
                                                            })
                                                        } else {
                                                            this.setState({
                                                                titleAlert: i18n[this.getLocale()].success,
                                                                messageAlert: i18n[this.getLocale()].request_updated
                                                            }, () => {
                                                                this.alertSubmitForSuccess();
                                                            })
                                                        }
                                                    } else {
                                                        this.setState({
                                                            titleAlert: i18n[this.getLocale()].success,
                                                            messageAlert: i18n[this.getLocale()].request_created
                                                        }, () => {
                                                            this.alertSubmitForSuccess();
                                                        })
                                                    }  
                                                }
                                                
                                                
                                                
                                            }
                                        })
                                    })
                                }
                                       
                            
                            }

                      
                
                        }
                             else {
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    if (this.state.dataObject.isEdit) {
                                        if (this.state.dataObject.formType === "saveAsDraft") {
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].success,
                                                messageAlert: i18n[this.getLocale()].save_as_Draft
                                            }, () => {
                                                this.alertSubmitForSuccess();
                                            })
                                        } else {
                                            if (this.state.dataObject.formType) {
                                                this.setState({
                                                    titleAlert: i18n[this.getLocale()].success,
                                                    messageAlert: i18n[this.getLocale()].request_updated
                                                }, () => {
                                                    this.alertSubmitForSuccess();
                                                })
                                            } else {
                                                this.setState({
                                                    titleAlert: i18n[this.getLocale()].success,
                                                    messageAlert: i18n[this.getLocale()].request_created
                                                }, () => {
                                                    this.alertSubmitForSuccess();
                                                })
                                            }
                                        }
                                    } else {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].request_created
                                        }, () => {
                                            this.alertSubmit();
                                        })
                                    }
                                })
                            }
                        } else {
                            
                            debugger;
                            this.setState({
                                isLoading: false
                            }, () => {
                                if (this.state.dataObject.isEdit) {
                                    if (this.state.dataObject.formType === "saveAsDraft") {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].save_as_Draft
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    } else {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].request_updated
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    }
                                } else {
                                    if (this.state.dataObject.formType === "saveAsDraft") {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].save_as_Draft
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    } else {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].request_created
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    }
                                }
                            })
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].error_message
                            }, () => {
                                this.alertSubmit();
                            })
                        })
                    }
                })
            })
        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    handlePreViewNavigation = (dataObj, attachmentArr, ckEditorArr) => {
        localStorage.setItem('currentPath', 'SCIRequest');
        this.setState({
            pathName: 'SCIRequest'
        }, () => {
            this.props.history.replace('/preview',
                { data: dataObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
        });
    }

    render() {
      
        const sideBarData = Config.getServiceSideBar('SCIRequest', this.state.isArabic, this, 0);
        return (

            <MDBContainer fluid>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'SCIRequest' }) }} isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].sci} />
                                </MDBCol>
                            </MDBRow>
                          {/* {this.state.showpoup?:null} */}
                            <div style={{ paddingTop: "10px" }}>
                                
                                <StepOne
                                    dataObject={{
                                        applicantsJob: this.state.applicantsJob,
                                        contactNumber: this.state.contactNumber,
                                        reqDetails: this.state.reqDetails,
                                        requestSubject: this.state.requestSubject,
                                        applicantsName: this.state.applicantsName,
                                        officialLetter: this.state.officialLetter
                                    }}
                                     setDataObject={this.setData}
                                    //setDataObject={this.alertSubmitForSuccess}
                                    isArabic={this.state.isArabic}
                                    onNextButtonPressed={this.nextButtonPressed}
                                     saveAsDraft={this.setData}
                                    //saveAsDraft={this.alertSubmitForSuccess}
                                    previewPage={this.handlePreViewNavigation}
                                />
                            </div>
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
<div style={{paddingTop:100}}></div>
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            height={'140vh'}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}