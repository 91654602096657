import React, { Component } from 'react';
import Config from '../../utils/Config';

    
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalBody, MDBModalHeader,
     MDBModalFooter, MDBBtn, MDBPopover, MDBPopoverBody, MDBPopoverHeader } from "mdbreact";
import Footer from '../Footer';
import Header from '../Header';
import SideBar from '../SideBar';
import i18n from '../../i18n';
import Locali18n from './Locali18n';
import SubHeaderStrip from '../SubHeaderStrip';

import LoadingOverlay from 'react-loading-overlay';

import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';

import TreeNode from './TreeNode';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import './../../css/react-confirm-alert.css';
import SortableTree, { toggleExpandedForAll, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import Positions from './Positions';
import PositionsBasic  from './PositionsBasic';

import FTE from './FTE';
import Preview from './Preview';

import ApiRequest from '../../services/ApiRequest';
import ToastComponent from './../ToastComponent';
import { getFunctionBlockPayload, saveFunctionalDataPayload,
    deleteFunctionalDataPayload, downloadAttachment } from '../../utils/Payload';

import OrganizationStructureFunctions from './OrganizationStructureFunctions';
import UnitsDetails from './UnitesDetails';

let moment = require('moment');
var _ = require('lodash');
const striptags = require('striptags');

const maxDepth = 5;
// let sector = 'fa-sector div'; // type --> sector
// let dept = 'fa-dept'; // type --> department
// let section = 'fa-section'; // type --> section
// let position = 'fa-position'; // type --> position



export  class OrganizationStructureTree extends OrganizationStructureFunctions {

    constructor(props){
        super(props);
      this.state={
        isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,

      }
    }

    
    componentDidMount = () => {
       
        let tempLoginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: tempLoginData
        },()=>{
            this.setState({
            canvieworgrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
            canvieworgrequesttree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.CanCreateStructure,
            canviewtree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewtree,
        });

        });


        window.scrollTo(0, 0);
    
        // call get function block API to get the orginasation structure data
        

        this.getAllFunctionData(0);
   
        let tempTypeArray = [];
        if (Config.getFunctionalBlockTypeData()) {
            tempTypeArray = Config.getFunctionalBlockTypeData();
        }
        this.setState({
            typeArray: tempTypeArray
        })

    }
    
    
  

    render() {

        const sideBarData = Config.getSidebarForOrgStructure(this.state.isArabic, this, 0);

        const {
            treeData,
            searchString,
            searchFocusIndex,
            //searchFoundCount,
        } = this.state;
        let rowDirection = 'ltr';

        if (this.state.isArabic) {
            rowDirection = 'rtl';
        }

        
        // Case insensitive search of `node.title`
        const customSearchMethod = ({ node, searchQuery }) =>
            searchQuery &&
            node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

        let  tree = this.state.treeData;
        
        let titleArray = [];
        let t = this.ttype;

        var buildTree = function (tree) {
            _.each(tree, function (item) {
                let titleObj = {};

             
               
                // titleObj.id = item.id;
                titleObj.title = item.title;
                titleObj.Id = item.Id;
                titleObj.unitType = item.unittype;
                titleObj.nodeType = item.typeid;
                titleObj.branch = item.branch;

               
                    titleArray.push(titleObj);

            
             if (item.children) {
                    buildTree(item.children);
                }
            
            });
        }

        buildTree(tree);

       
        return (
            <MDBContainer fluid style={{ height: '100vh' }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} />

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', 'backgroundColor': '#dbd9be' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'OrganizationStructure' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].organizationStructure}
                                    />
                                </MDBCol>
                            </MDBRow>

                           
                            <div className="wrapper">
                             
                                <div className="bar-wrapper">

                                    <MDBRow
                                        style={{
                                            display: 'flex',
                                            flexDirection: this.state.isArabic ? 'row-reverse' : 'row'
                                        }}
                                    >

                                        <MDBCol xs="1" sm="1" md="1" lg="1" xl="1" style={{ maxWidth: '5%' }}>
                                            <label
                                                style={{
                                                    width: '100%',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                {Locali18n[this.getLocale()].orgStructure.search}
                                            </label>
                                        </MDBCol>
                                      {/* search*/}
                                        <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                                            <input type="search" onChange={this.handleSearchOnChange} className="form-control" />
                                        </MDBCol>

                                        <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '14%' }}>
                                            <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.handleViewTreeNavigation}>
                                            
                                                {Locali18n[this.getLocale()].orgStructure.treeView}
                                            </button>
                                        </MDBCol>
                                 {/* expand all*/}
                                        <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '11%' }}>
                                            <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.toggleNodeExpansion.bind(this, true)}>
                                                {Locali18n[this.getLocale()].orgStructure.expandAll}
                                            </button>
                                        </MDBCol>
                                 {/* collapse all*/}
                                        <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '11%' }}>
                                            <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.toggleNodeExpansion.bind(this, false)}
                                            >
                                                {Locali18n[this.getLocale()].orgStructure.collapseAll}
                                            </button>
                                        </MDBCol>
 {/* desgin template*/}
 <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '14%' }}>
                                          <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.createDesign}>
                                                {Locali18n[this.getLocale()].orgStructure.finalDesign}
                                            </button> 


                                        </MDBCol>
                            {/* tree type*/}
                              <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '10%' }}>
                              <select
                                    className="browser-default custom-select"
                                    id="sources-funding"
                                    onChange={(e) =>this.ReloadTree(e) }
                                       
                                    name='nodeType'
                                    required>

                                    <option value='0'> {this.getLocale() === 'ar' ? 'الكل' : 'All'} </option>
                                    <option value='1'> {this.getLocale() === 'ar' ? 'الهياكل' : 'Unites'}</option>
                                    <option value='2'>{this.getLocale() === 'ar' ? 'الوظائف' : 'Positions'}</option>
                                   
                                </select>

                                        </MDBCol>

                                    </MDBRow>
                               
                                </div>

                                {/*tree div */}
                                <div className="tree-wrapper">
                                    <SortableTree
                                        treeData={tree}
                                        
                                        onChange={this.handleTreeOnChange}
                                        onMoveNode={({ node, treeIndex, path }) =>
                                            global.console.debug(
                                                'node:',
                                                node,
                                                'treeIndex:',
                                                treeIndex,
                                                'path:',
                                                path
                                            )
                                          
                                        }
                                        
                                        maxDepth={maxDepth}
                                        rowDirection={rowDirection}
                                        searchMethod={customSearchMethod  }//added custom search
                                             
                                        searchQuery={searchString}

                                        searchFocusOffset={searchFocusIndex}
                                        canDrag={false}
                                        canDrop={false}
                                        // canDrag={({ node }) => !node.noDragging}
                                        // canDrop={({ nextParent }) => !nextParent || !nextParent.noChildren}
                                        searchFinishCallback={matches =>
                                            this.setState({
                                                searchFoundCount: matches.length,
                                                searchFocusIndex:
                                                    matches.length > 0 ? 
                                                    searchFocusIndex % matches.length : 0,
                                            })
                                        }
                                        isVirtualized={true}
                                        generateNodeProps={rowInfo => {
                                       
                                            let nodeProps = {
                                                nodeInfo : rowInfo,
                                                className: `${rowInfo.node.className}`,
                                                buttons: [

                                                  
                                                // pop over div
                                                rowInfo.node.typeid !== 2  ?  
                                               <div style={{ display: "flex" }}>
                                               
                              <MDBPopover component="button" size="sm" placement={this.props.y > 555 ? "top" : "bottom"}
                popoverBody={i18n[this.getLocale()].actions} 
                className= { this.getcolor(rowInfo) }  >
               
               
                
                <MDBPopoverHeader>
                    {" "}
                    {i18n[this.getLocale()].select_action}
                </MDBPopoverHeader>
                <MDBPopoverBody
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around"
                        
                    }}>
                          {/* node buttons */} 

                                                               

                                                                  
                                                                        {
                                                                           
                                                                         
                                                                         
                                                                              rowInfo.node.typeid === 0 
                                                                                ?
                                                                                <React.Fragment>
                                                                                    {
                                                                                       rowInfo.node.id !== 0 ?
                                                                                        <React.Fragment>
                                                                                    <MDBRow>
                                                                                     <MDBBtn className="gridActionBtn"
                                                                                color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                   onClick={(event) => 
                                                                                   { 
                                                                                      this.setState({edit:1});
                                                                                      rowInfo.edit=1;
                                                                                      this.showAddNodePopup(rowInfo);
                                                                           
                                                                                   }}>
                                                                                    { Locali18n[this.getLocale()].editunit}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                                  
                                                                                    <MDBRow>
                                                                                        
                                                                                    </MDBRow>
                                                                                 
                                                                                   <MDBRow>
                                                                        <MDBBtn className="gridActionBtn"
                                                                                color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                        onClick={(event) => this.removeNode(rowInfo)}>
                                                                            {Locali18n[this.getLocale()].removeNode}
                                                                        </MDBBtn>
                                                                        </MDBRow>
                                                                   
                                                                                   <MDBRow>
                                                                                 <MDBBtn className="gridActionBtn"
                                                                               color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                onClick={(event) => this.showUnitDetailsModal(rowInfo)}>
                                                                                    { Locali18n[this.getLocale()].unitdetails}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                             
                                                                        </React.Fragment>  
                                                                                           : null
                                                                                   }

                                                 
                                                                  <MDBRow>
                                                                                 <MDBBtn className="gridActionBtn"
                                                                               color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                onClick={(event) => this.showpositionsBasicModal(rowInfo,0,false)}>
                                                                                    { Locali18n[this.getLocale()].addPosition}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                               {    

                                                                              rowInfo.node.branch==0?
                                                                               <MDBRow>
                                                                               <MDBBtn className="gridActionBtn"
                                                                               color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                 onClick={(event) => this.showAddNodePopup(rowInfo)}>
                                                                                     { Locali18n[this.getLocale()].addunit}
                                                                                 </MDBBtn>
                                                                                 </MDBRow>
                                                                                 :null
                                                                              }
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                    
                                                                                    <MDBRow>
                                                                                 <MDBBtn className="gridActionBtn"
                                                                               color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                onClick={(event) => this.showpositionsBasicModal(rowInfo,0,false)}>
                                                                                    { Locali18n[this.getLocale()].addPosition}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                                    <MDBRow>
                                                                                 <MDBBtn className="gridActionBtn"
                                                                               color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                onClick={(event) => this.showpositionsBasicModal(rowInfo,1,false)}>
                                                                                    { Locali18n[this.getLocale()].editPosition}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                          {/*     <MDBRow>    
                                                                             <MDBBtn className="gridActionBtn"
                                                                           color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                            onClick={(event) => this.showpositionsModal(rowInfo,1,true)}>
                                                                                { Locali18n[this.getLocale()].positiondetails}
                                                                                </MDBBtn>
                                                                                </MDBRow> */}
                                                                               <MDBRow>
                                                                                 <MDBBtn className="gridActionBtn"
                                                                               color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                onClick={(event) => this.showpositionsModal(rowInfo,1,false)}>
                                                                                    { Locali18n[this.getLocale()].editPositionDetails}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                                 
                                                                               <MDBRow>
                                                                                <MDBBtn className="gridActionBtn"
                                                                                color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                                onClick={(event) => this.showFTEModal(rowInfo)}>
                                                                                    { Locali18n[this.getLocale()].addFunctionalLoad}
                                                                                    </MDBBtn>
                                                                                    </MDBRow>
                                                                           
                                                                          
                                                                               
                                                                            
                                                                             
                                                                             <MDBRow>
                                                                        <MDBBtn className="gridActionBtn"
                                                                                color="primary" style={{ color: "var(--btnColor)" }}size="sm"
                                                                        onClick={(event) => this.removeNode(rowInfo)}>
                                                                            {Locali18n[this.getLocale()].removeNode}
                                                                        </MDBBtn>
                                                                        </MDBRow>
                                                                
                                                                       
                                                                             </React.Fragment>
                                                                        }

{/*  <MDBRow>
           <MDBBtn className="gridActionBtn"
          color="primary" style={{ color: "var(--btnColor)" }}size="sm"
          onClick={(event) => this.Preview(rowInfo)}>
           {Locali18n[this.getLocale()].preview}
          </MDBBtn>
      </MDBRow>
      
   
           <MDBRow>
           <MDBBtn className="gridActionBtn"
          color="primary" style={{ color: "var(--btnColor)" }}size="sm"
          onClick={(event) => this.Preview(rowInfo)}>
           {Locali18n[this.getLocale()].preview}
          </MDBBtn>
      </MDBRow>
       */}
  
  


                                                               
                                                            </MDBPopoverBody>

                                                        </MDBPopover>

                                                 </div> : null,

                                               // pop over div end

                                                ],
                                            };
                                            if (this.state.isArabic) {
                                                nodeProps.title = rowInfo.node.arabicTitle;
                                            }
                                            return nodeProps;
                                        }}

                                    />
                                </div>

                                 {/*tree div end */}
                            </div>

                            <Footer isArabic={this.state.isArabic} />

                        </MDBCol>

                        <SideBar
                         canvieworganizationsturcture={this.state.canvieworganizationsturcture}
                            height={'300vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>

                </LoadingOverlay>

                {/* node popup */}
                <MDBModal 
                    isOpen={this.state.modalAddNode}
                    onHide={this.closeAddNodePopup}
                    
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>

                  <TreeNode 
                   add = {this.addNode}
                   info={this.state.rowInfo} 
                   hide = {this.closeAddNodePopup}
                   parent={this.state.parentNodeValue} 
                   reload ={this.reload}
                  open={this.state.modalAddNode}></TreeNode>

                </MDBModal >


  {/* position basic popup */}
  <MDBModal   size="lg"
                    isOpen={this.state.positionsBasicModal}
                    toggle={this.state.positionsBasicModal}
                   
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>
                     <PositionsBasic  info={this.state.rowInfo} 
                     edit = {this.state.edit} 
                      positiondetails={0}
                      hide = {this.closepositionsBasicModal} 
                      parent={this.state.parentNodeValue}
                      open={this.state.positionsBasicModal} 
                      reload ={this.reload}></PositionsBasic>

              </MDBModal >

                {/* position popup */}
                <MDBModal size="xl" 
                    isOpen={this.state.positionsModal}
                    toggle={this.state.positionsModal}
                   
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>
                     <Positions  info={this.state.rowInfo} edit = {this.state.edit} 
                      positiondetails={1}
                      hide = {this.closepositionsModal} parent={this.state.parentNodeValue}
                      open={this.state.positionsModal} 
                      reload ={this.reload}></Positions>

              </MDBModal >

               
                {/* Unit Details popup */}
                <MDBModal size="lg" 
                    isOpen={this.state.UnitDetailsModal}
                    toggle={this.state.UnitDetailsModal}
                   
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>
                     <UnitsDetails  hide = {this.closeUnitDetailsModal} 
                      open={this.state.UnitDetailsModal}  info={this.state.rowInfo}    
                     reload ={this.reload}></UnitsDetails>


              </MDBModal >

                {/* FTE popup */}
                <MDBModal size="lg" 
                    isOpen={this.state.FTEModal}
                    toggle={this.state.FTEModal}
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>
                     <FTE  hide = {this.closeFTEModal} info={this.state.rowInfo} 
                      parent={this.state.parentNodeValue} open={this.state.FTEModal}
                      reload ={this.reload}></FTE>
                      

              </MDBModal >

              {/*Preview */}

             
                <MDBModal 
                size="lg" 
                    isOpen={this.state.modalPeview}
                    onHide={this.closePreview}
                    
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>

                  <Preview 
                  
                   info={this.state.rowInfo} 
                   hide = {this.closePreview}
                  open={this.state.modalPeview}></Preview>

                </MDBModal >
               
            </MDBContainer >


        );
    }
}

export default OrganizationStructureTree;