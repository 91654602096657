import React, { Component } from "react";
import { MDBModalHeader, MDBModalBody, MDBModal, MDBContainer, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBCard, MDBCardBody, MDBBtn } from "mdbreact";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import i18n from "../i18n";
import SubHeaderStrip from "../components/SubHeaderStrip";
import Config from "../utils/Config";
import LoadingOverlay from "react-loading-overlay";
import ViewDetailPatientItem from "./../components/ViewDetailPatientItem";
import CKEditor from "react-ckeditor-component";
import Footer from './../components/Footer';
import { black } from "ansi-colors";
import ApiRequest from './../services/ApiRequest';

import ViewDetailsAction from "../components/ViewDetailsAction/ViewDetailsAction"
import ViewRecordComponent from '../components/PopOverSubComponents/ViewRecordComponent';
import DeleteRecordComponent from '../components/PopOverSubComponents/DeleteRecordComponent';
import StatusUpdateComponent from '../components/PopOverSubComponents/StatusUpdateComponent';
import CommentComponent from '../components/PopOverSubComponents/CommentComponent';
import FollowComponent from '../components/PopOverSubComponents/FollowComponent';
import AssignToSector from '../components/PopOverSubComponents/AssignToSector';
import AssignToGm from '../components/PopOverSubComponents/AssignToGm';
import AssignBackToEntity from '../components/PopOverSubComponents/AssignBackToEntity';
import AddStudy from "../components/PopOverSubComponents/AddStudy";
import AssignToComponent from "../components/PopOverSubComponents/AssignToComponent";

import ExtendDateComponent from '../components/PopOverSubComponents/ExtendDateComponent';
import ExtendDateReplayComponent from '../components/PopOverSubComponents/ExtendDateReplayComponent';

import { GetLastActionIdPayload, saveStatusPayload, assginGmPayload, getRequestByIdPayload, getLookupRequestByIdPayload, downloadAttachment } from './../utils/Payload';
import OpenCreationStageComponent from '../components/OrganizationStructure/OpenCreationStage';
import OrganizationStructureCompleted from '../components/OrganizationStructure/OrganizationStructureCompleted';
import { dir } from "glamor";

let propsData = {};
let requesttypeid = '';

export default class ViewContainer extends Component {
    constructor(props) {

        super(props);
        this.state = {
            id: "",
            pathName: "",
            modal: false,
            listHeadings: [],
            listData: [],
            headerArebic: [],
            headerEnglish: [],
            data: [],
            activeIndex: 1,
            canViewComment: 1,
            loginData: "",
            canViewSCI: 0,
            canViewInformation: 0,
            canviewbonusrequest: 0,
            canviewamendscalerequest: 0,
            canviewamendsalaryrequest: 0,
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,

            canvieworgrequest: 0,
            canvieworgrequesttree: 0,
            canviewtree: 0,

            isInternal: 1,
            isArabic: localStorage.getItem("Lang") === "ar" ? true : false,
            routingdetails: {},
            isSideBarMinimized: false,
            ckEditorArr: [],
            requesttypeid: '',
            lastcationdata: {},
            showhidecomment: 0,
            viewactionsdata: {},
            isviewdetails: 1,
            ActionData: [],
            comments: [
                []
            ],
            statusHistory: [],

            Attachments: [

            ],
            DegreeofrelationshipArray: [

                {
                    id: "1",
                    EnglishTitle: "First Degree",
                    ArabicTitle: "الدرجة الأولى",
                },
                {
                    id: "2",
                    EnglishTitle: "In-laws first class",
                    ArabicTitle: "الأصهار من الدرجة الأولى",
                },
                {
                    id: "3",
                    EnglishTitle: "Second degree",
                    ArabicTitle: "الدرجة الثانية",
                },
                {
                    id: "4",
                    EnglishTitle: "Third degree",
                    ArabicTitle: "الدرجة الثالثة",
                },
                {
                    id: "5",
                    EnglishTitle: "Fourth degree",
                    ArabicTitle: "الدرجة الرابعة",
                }, {
                    id: "6",
                    EnglishTitle: "",
                    ArabicTitle: "",
                }
            ],

            // patient request type drop down value
            requestTypeArray: [

                {
                    id: "1",
                    EnglishTitle: "Exception from relative degree",
                    ArabicTitle: "الإستثناء من درجه القرابه"
                },
                {
                    id: "2",
                    EnglishTitle: "Exception from allowed legal interval for patient accompany",
                    ArabicTitle: "الإستثناء من المده القانونية المسموح بها لإجازه مرافق مريض"
                }
            ]
        };
        this.en_keys = [];
        this.enKeys = {};
        this.arKeys = {};
        this.respKeys = [];
        this.colorArray = [];
        this.showhidecomment = this.showhidecomment.bind(this);
        this.MGM = this.MGM.bind(this);
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };



    componentDidMount = () => {
        debugger;
    this.setState({routingdetails : this.props.location.state.data});  

        let pathName = "";
        let dataObj = {};
        let requesttypeid = '';
        if (this.props.location.state) {
            dataObj = this.props.location.state.data;
            requesttypeid = this.props.location.state.requesttypeid;
        }

        if (requesttypeid === Config.getSciRequestTypeId() || requesttypeid === Config.getSCILEgalSerbviceId() || requesttypeid === Config.getSCIComplainId() || requesttypeid === Config.getSCILEgalSuggestionId() || requesttypeid === Config.getSCIGeneralId()) {
            requesttypeid = 4;
        }
        let keys = Object.keys(dataObj);
        debugger;
        let ckEditorArr = [];
        if (requesttypeid) {
            
            switch (requesttypeid) {

                //case "generalManagerWizardScreen":
                case 10969:
                    for (let i = 0; i < keys.length; i++) {

                        if (keys[i] == "details" || keys[i] == "EntityLegalOpinon") {

                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;
                case 4:
                    for (let i = 0; i < keys.length; i++) {

                        if (keys[i] == "details" || keys[i] == "EntityLegalOpinon") {

                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;
                case 401:
                    for (let i = 0; i < keys.length; i++) {

                        if (keys[i] == "details" || keys[i] == "EntityLegalOpinon") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;
                case 10960:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "details") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 2:
                    for (let i = 0; i < keys.length; i++) {
                        if (
                            keys[i] == "currentscalechallenge" ||
                            keys[i] == "proposedscalechallenge" ||
                            keys[i] == "currentapplyeffect" ||
                            keys[i] == "proposedapplyeffect" ||
                            keys[i] == "justification" ||
                            keys[i] == "principle"
                        ) {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 8:
                    {

                        for (let i = 0; i < keys.length; i++) {
                            if (
                                keys[i] == "justification" ||
                                keys[i] == "howtocalculaterule" ||
                                keys[i] == "violationdetail"
                            ) {
                                let ckData = {
                                    key: keys[i],
                                    value: dataObj[keys[i]]
                                };
                                ckEditorArr.push(ckData);

                            }
                        }


                        break;
                    }

                case 10925:
                    {

                        for (let i = 0; i < keys.length; i++) {
                            if (
                                keys[i] == "justification" ||
                                keys[i] == "howtocalculaterule" ||
                                keys[i] == "violationdetail"
                            ) {
                                let ckData = {
                                    key: keys[i],
                                    value: dataObj[keys[i]]
                                };
                                ckEditorArr.push(ckData);

                            }
                        }

                        break;
                    }

                case 10926:
                    {

                        for (let i = 0; i < keys.length; i++) {
                            if (
                                keys[i] == "PromotionRequestreason"

                            ) {
                                // alert(keys[i]);
                                let ckData = {
                                    key: keys[i],
                                    value: dataObj[keys[i]]
                                };
                                ckEditorArr.push(ckData);

                            }
                        }

                        break;
                    }

                case 1:
                    for (let i = 0; i < keys.length; i++) {
                        if (
                            keys[i] == "yearachievment" ||
                            keys[i] == "bonusreason" ||
                            keys[i] == "equation"
                        ) {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 7:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].toLowerCase() == "informationrequired") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 165:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "reasonforextending") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 10:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestreason") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 11:
                    {

                        for (let i = 0; i < keys.length; i++) {
                            if (keys[i] == "salaryownerid") {
                                if (dataObj[keys[i]] != 1) {
                                    ckEditorArr = [];
                                }

                            }
                            if (keys[i] == "reasonForGovPaySalary") {

                                let ckData = {
                                    key: keys[i],
                                    value: dataObj[keys[i]]
                                };
                                ckEditorArr.push(ckData);
                            }
                        }
                        break;
                    }

                case 3:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "reasontomodify" || keys[i] == "baseofincrease") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 9:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestreason") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 827:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 828:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 829:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 830:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 831:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails" || keys[i] == "systemtype") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 832:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails" || keys[i] == "systemtype") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 833:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 834:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;

                case 835:
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;
                // organization structure 

                case 874:

                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i] == "requestdetails") {
                            let ckData = {
                                key: keys[i],
                                value: dataObj[keys[i]]
                            };
                            ckEditorArr.push(ckData);
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        // get login data
        let loginData = Config.getLocalStorageLoginData();
        //let keys = Object.keys(this.props.data);
        let headerArrayar = [];
        let headerArrayen = [];
        this.enKeys = i18n["en"].viewComponent;
        this.arKeys = i18n["ar"].viewComponent;
        this.en_keys = Object.keys(this.enKeys);

        let data = {};
        this.respKeys = {};

        if (this.props && this.props.location && this.props.location.state) {
            pathName = this.props.location.state.pathname;
            data = Object.values(this.props.location.state.data);
            this.respKeys = Object.keys(this.props.location.state.data);
        } else {
            pathName = localStorage.getItem("currentPath");
        }
        let obj = {};
        obj.requestid = this.props.location.state.id;
        obj.requestType = requesttypeid;

        let tempactionsdata = {};
        tempactionsdata.requesttypeid = requesttypeid;
        this.setState({ viewactionsdata: tempactionsdata });
        this.setState({ requesttypeid: requesttypeid });
        this.setState({ Attachments: this.props.location.state.data.attachments });
        this.setState({ comments: this.props.location.state.data.comments });

        this.setState({ statusHistory: this.props.location.state.data.history });
        // let payload = GetLastActionIdPayload(obj);
        // let url = Config.getUrl('GetRequestLastAction');
        // ApiRequest.sendRequestForPost(url, payload, res => {
        //     this.setState({
        //         isLoading: true
        //     }, () => {
        //         this.setState({
        //             isLoading: false
        //         }, () => {

        //             if (res.Status) {
        //                 this.setState({lastcationdata: res.data});


        //             }
        //             })

        //     });
        // });


        this.setState({
            requesttypeid: requesttypeid,
            loginData: loginData,
            ckEditorArr: ckEditorArr,
            pathName: pathName,
            headerArebic: headerArrayar,
            headerEnglish: headerArrayen,
            data: data
        }, () => {
            this.setState({
                isInternal: this.state.loginData && this.state.loginData.internalcontact,
                canViewSCI: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreviewscirequest,
                canViewInformation: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewirrequest,
                canviewbonusrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                canviewamendscalerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                canviewamendsalaryrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest,
                canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest,
                // organization structure
                canvieworgrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
                canvieworgrequesttree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.CanCreateStructure,
                canviewtree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewtree,

            });
        });
    };

    toggleLanguage = lan => {
        this.setState({ isArabic: lan === "ar" ? true : false }, () => {
            // window.location.reload();
        });
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    };

    onClickHelpPage = () => {
        this.props.history.replace("/help");
    };

    showhidecomment = () => {

        if (this.state.showhidecomment == 0) {
            this.setState({ showhidecomment: 1 });
        }
        else {
            this.setState({ showhidecomment: 0 });

        }
    }

    editRecord = () => {
        if (this.state.pathName === 'entity' || this.state.pathName === 'contact') {

        } else {
            this.setState({
                no: this.props.location.state.data.no,
                createdby: this.props.location.state.data.createdby,
                requestNo: this.props.location.state.data.requestNo,
                reason: this.props.location.state.data.reason,
                status: this.props.location.state.data.status,
                createdon: this.props.location.state.data.createdon,
                actions: this.props.location.state.data.actions,
            });
        }
    }
    MGM() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            this.setState({
                changeState: !this.state.changeState
            }, () => {
                this.componentDidMount();


            });
        });
    }
    toggle = (reloadFlag) => {
        debugger;
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState
                }, () => {
                    this.props.history.goBack();
                    //this.componentDidMount();
                });
            }
        });
    }


    manageAction = (name) => {
        if (name === 'edit') {
            this.editRecord()
        }

        // for (let i = 0; i < this.state.data.rows.length; i++) {
        //     if (document.getElementById('checkbox' + i)) {
        //         if (i % 2 === 0) {
        //             document.getElementById('checkbox' + i).parentElement.parentElement.style.backgroundColor = '#ECECEC';
        //         }
        //         else {
        //             document.getElementById('checkbox' + i).parentElement.parentElement.style.backgroundColor = 'white';
        //         }
        //     } else {
        //         break;
        //     }
        // }


        // document.getElementById('checkbox' ).parentElement.parentElement.style.backgroundColor = 'var(--btnColor)';
        this.setState({ selectedAction: name });
        //this.setState({ selectedIndex: index});
        this.toggle();
        //  this.setState({
        //    selectedAction: name,
        //  selectedIndex: index
        //}, () => {
        //     this.toggle();
        //});

    }

    getModalTitle = () => {


        switch (this.state.selectedAction) {
            case 'view':
                return i18n[this.getLocale()].viewComponent.viewRecord
            case 'edit':
                return i18n[this.getLocale()].viewComponent.editRecord
            case 'delete':
                return i18n[this.getLocale()].viewComponent.deleteRecord
            case 'comment':
                return i18n[this.getLocale()].viewComponent.comments
            case 'update_status':
                return i18n[this.getLocale()].update_status.Update_Status
            case "assigntodep":
                return i18n[this.getLocale()].assignToDep;
            case "backtomanager":
                return i18n[this.getLocale()].backToManager;
            case "assigntospecificdep":
                return i18n[this.getLocale()].assignToSpecificDep;
            case "assigntodatadep":
                return i18n[this.getLocale()].assignToDataDep
            case "assigntospecificemp":
                return i18n[this.getLocale()].loackrequest;
            case "assigntoemp":
                return i18n[this.getLocale()].assignToEmp;
            case "assigntogmoffice":
                return i18n[this.getLocale()].assignToGmOffice;
            case "assigntostrategicoffice":
                return i18n[this.getLocale()].assignToStrategicOffice;
            case "assignbacktoentity":
                return i18n[this.getLocale()].assignBackToEntity;
            case "assigntoentity":
                return i18n[this.getLocale()].assignToEntity;
            case "addStudy":
                return i18n[this.getLocale()].addStudy;
            case "addReply":
                return i18n[this.getLocale()].addReply;
            case "assigntosector":
                return i18n[this.getLocale()].assignToSector;
            case "follow":
                return i18n[this.getLocale()].follow;
            case "CloseRequest":
                return i18n[this.getLocale()].canCloseRequest;

            default:
                return '';
        }

    }
    callRequestById = (requestId, endPoint) => {
        //let id = requestId;
        let payload = getRequestByIdPayload(requestId);
        let url = Config.getUrl(endPoint);

        ApiRequest.sendRequestForPost(url, payload, res => {

            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {

                    if (res.Status) {



                        if (this.state.pathName === 'reviewHRPolicies') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {};
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.requestreason = res.data.requestreason;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/reviewHRPolicies', {
                                pathname: 'reviewHRPolicies',
                                data: res.data
                            });

                        } else if (this.state.pathName === 'exceptionADHousing') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employeename = res.data.employeename;
                            editObj.entityname = res.data.entityname;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.financialcost = res.data.financialcost;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.housingallowance = res.data.housingallowance;
                            //editObj.jobname = res.data.jobname;
                            editObj.jobname = res.data.jobname;
                            editObj.contractperiod = res.data.contractperiod;
                            editObj.retirementcontribution = res.data.retirementcontribution;
                            editObj.requestreason = res.data.requestreason;
                            editObj.contracttype = res.data.contracttypeid;
                            editObj.recommendation = res.data.recommendation;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/exceptionADHousing', {
                                pathname: 'exceptionADHousing',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'amendEmployeeSalary') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employeename = res.data.employeename;
                            editObj.nationality = res.data.nationality;
                            editObj.age = res.data.age;
                            editObj.designationdate = res.data.designationdate.split(' ')[0];
                            editObj.currentbasicsalary = res.data.currentbasicsalary;
                            editObj.proposedbasicsalary = res.data.proposedbasicsalary;
                            editObj.percentageincrease = res.data.percentageincrease;
                            editObj.totalexpeirence = res.data.totalexpeirence;
                            editObj.qualification = res.data.qualification;
                            editObj.exprienceafterqualification = res.data.exprienceafterqualification;
                            editObj.annualprevevaluation = res.data.annualprevevaluation;
                            editObj.annualcurrevaluation = res.data.annualcurrevaluation;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.grade = res.data.grade;
                            editObj.jobrank = res.data.jobrank;
                            editObj.proceduretype = res.data.proceduretype;
                            editObj.financialratio = res.data.financialratio;
                            editObj.proceduredate = res.data.proceduredate.split(' ')[0];
                            editObj.reasontomodify = res.data.reasontomodify;
                            editObj.baseofincrease = res.data.baseofincrease;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/amendEmployeeSalary', {
                                pathname: 'amendEmployeeSalary',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'amendSalaryScreen') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.justification = res.data.justification;
                            editObj.principle = res.data.principle;
                            editObj.fundingid = res.data.fundingid;
                            editObj.isallocated = res.data.isallocated;
                            editObj.budgetapprovalamount = res.data.budgetapprovalamount;
                            editObj.currentbasicsalary = res.data.currentbasicsalary;
                            editObj.proposedbasicsalary = res.data.proposedbasicsalary;
                            editObj.currentannualbenefits = res.data.currentannualbenefits;
                            editObj.proposedannualbenefits = res.data.proposedannualbenefits;
                            editObj.currentfinancialeffect = res.data.currentfinancialeffect;
                            editObj.proposedfinancialeffect = res.data.proposedfinancialeffect;
                            editObj.currentscalechallenge = res.data.currentscalechallenge;
                            editObj.proposedscalechallenge = res.data.proposedscalechallenge;
                            editObj.currentincreaeaverage = res.data.currentincreaeaverage;
                            editObj.currentdecreaseaverage = res.data.currentdecreaseaverage;
                            editObj.currentapplyeffect = res.data.currentapplyeffect;
                            editObj.proposedappleffect = res.data.proposedapplyeffect;
                            // editObj.amendSalaryScaleMatrix = res.data.AmendSalaryScaleMatrix[0];
                            editObj.amendSalaryScaleMatrix = res.data.AmendSalaryScaleMatrix;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/amendSalaryScreen', {
                                pathname: 'amendSalaryScreen',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'secondmentForFederalAgency') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.BorrowEntityID = res.data.BorrowEntityID;
                            editObj.reasonForGovPaySalary = res.data.reasonForGovPaySalary;
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.secondmententityname = res.data.secondmententityname;
                            editObj.employeename = res.data.employeename;
                            editObj.jobname = res.data.jobname;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.retirementcontribution = this.props.location.state.data.retirementcontribution;
                            editObj.secondmentfrom = res.data.secondmentfrom;
                            editObj.secondmentto = res.data.secondmentto;
                            editObj.salaryownerid = res.data.salaryownerid;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.familynumber = res.data.familynumber;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.employeeagreement = res.data.employeeagreement;
                            editObj.passtestperiod = res.data.passtestperiod;
                            editObj.performancedegree = res.data.performancedegree;

                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/secondmentForFederalAgency', {
                                pathname: 'secondmentForFederalAgency',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'informationRequest') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.datettoreply = res.data.datettoreply;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestorname = res.data.requestorname;
                            editObj.informationrequired = res.data.informationrequired;
                            editObj.statement = res.data.statement;
                            editObj.assignedentity = res.data.assignedentity;
                            editObj.lastTimeToReply = res.data.datettoreply;
                            editObj.requesttype = res.data.requesttype;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/informationRequest', {
                                pathname: 'informationRequest',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'SCIRequest') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.requestTypeForRadioButton = res.data.requesttype;
                            editObj.applicantsJob = res.data.applicantjob;
                            editObj.contactnumber = res.data.applicantcontactnumber;
                            editObj.reqDetails = res.data.details;
                            editObj.requesttypeForSuggesationComplainEnquiry = res.data.requesttype;
                            editObj.requestSubject = res.data.subject;
                            editObj.applicantsName = res.data.applicantname;
                            editObj.attachments = res.data.attachments;
                            editObj.requesttype = res.data.requesttype;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/SCIRequest', {
                                pathname: 'SCIRequest',
                                data: this.props.location.state.data
                            });


                        } else if (this.state.pathName === 'bonusRequest') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.entityname = res.data.entityname;

                            editObj.jobname = res.data.jobname;
                            editObj.coordinatorname = res.data.coordinatorname;
                            editObj.email = res.data.email;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.phonenumber = res.data.phonenumber;

                            editObj.subjobname = res.data.subjobname;
                            editObj.subcoordinatorname = res.data.subcoordinatorname;
                            editObj.subemail = res.data.subemail;
                            editObj.subcontactnumber = res.data.subcontactnumber;
                            editObj.subphonenumber = res.data.subphonenumber;

                            editObj.isbudgeted = res.data.isbudgeted;
                            editObj.budget = res.data.budget;
                            editObj.budgetyear = res.data.budgetyear;
                            editObj.yearachievment = res.data.yearachievment;
                            editObj.bonusreason = res.data.bonusreason;
                            editObj.year1 = res.data.year1;
                            editObj.emiratizationpercentage = res.data.emiratizationpercentage;
                            editObj.totalemployeedetails = res.data.totalemployeedetails;
                            editObj.year2 = res.data.year2;
                            editObj.iscontracted = res.data.iscontracted;
                            editObj.totalemployees = res.data.totalemployees;
                            editObj.equation = res.data.equation;
                            //editObj.requestId = res.data.gradeemployees;
                            editObj.emiratizationpercentage2 = res.data.emiratizationpercentage2;
                            editObj.nonlocalwithprivate = res.data.nonlocalwithprivate;
                            editObj.localwithprivate = res.data.localwithprivate;
                            editObj.nonlocalwithpermanent = res.data.nonlocalwithpermanent;
                            editObj.localwithpermanent = res.data.localwithpermanent;
                            editObj.excellentemployees = res.data.excellentemployees;
                            editObj.verygoodemployees = res.data.verygoodemployees;
                            editObj.goodemployees = res.data.goodemployees;
                            editObj.acceptableemployees = res.data.acceptableemployees;
                            editObj.weakemployees = res.data.weakemployees;
                            editObj.isfixedvaluebonus = res.data.isfixedvaluebonus;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/bonusRequest', {
                                pathname: 'bonusRequest',
                                data: this.props.location.state.data
                            });
                        }
                        else if (this.state.pathName === 'exemptionFromAccompanyPatient') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.grade = res.data.grade;
                            editObj.requesttype = res.data.requesttype;
                            editObj.employeename = res.data.employeename;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.requestheadline = res.data.requestheadline;
                            editObj.budgetavailableyear = res.data.budgetavailableyear;
                            editObj.dateofstarting = res.data.dateofstarting;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.retirementcontribution = res.data.retirementcontribution;
                            editObj.durationofleave = res.data.durationofleave;
                            editObj.dateofwork = res.data.dateofwork;
                            editObj.dateofsalarytermination = res.data.dateofsalarytermination;
                            editObj.isdocumentofobligatory = res.data.isdocumentofobligatory;
                            editObj.budgetyear = res.data.budgetyear;
                            editObj.budgetvalue = res.data.budgetvalue;
                            editObj.annualleavebalance = res.data.annualleavebalance;
                            editObj.justification = res.data.justification;
                            editObj.husbandwifework = res.data.husbandwifework;
                            editObj.numberofchidren = res.data.numberofchidren;
                            editObj.placeofchildren = res.data.placeofchildren;
                            editObj.worktotaldays = res.data.worktotaldays;
                            editObj.worktotalsalarytobepaid = res.data.worktotalsalarytobepaid;
                            editObj.worktobeexempted = res.data.worktobeexempted;
                            editObj.howtocalculaterule = res.data.howtocalculaterule;
                            editObj.isrequriedtopaybybudget = res.data.isrequriedtopaybybudget;
                            editObj.isviolation = res.data.isviolation;
                            editObj.violationdetail = res.data.violationdetail;
                            editObj.salarymatrix = res.data.salarymatrix;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.basicsalary2 = res.data.basicsalary2;
                            editObj.totalsalarywithsubscription = res.data.totalsalarywithsubscription;
                            editObj.retirement = res.data.retirement;
                            editObj.totalsalary2 = res.data.totalsalary2;
                            editObj.Degreeofrelationship = res.data.Degreeofrelationship;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/exemptionFromAccompanyPatient', {
                                pathname: 'exemptionFromAccompanyPatient',
                                data: this.props.location.state.data
                            });
                        } else if (this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employename = res.data.employename;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.nationalityid = res.data.nationalityid;
                            editObj.dob = res.data.dob;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.steerfrom = res.data.steerfrom;
                            editObj.steerto = res.data.steerto;
                            editObj.isjurisdiction = res.data.isjurisdiction;
                            editObj.reasonforextending = res.data.reasonforextending;
                            editObj.annualperformanceevaluation = res.data.annualperformanceevaluation;
                            editObj.emiratesid = res.data.emiratesid;

                            editObj.attachments = res.data.attachments;
                            editObj.HiringDate = res.data.HiringDate;
                            editObj.CurrentGrade = res.data.CurrentGrade;
                            editObj.Efficiency = res.data.Efficiency;
                            editObj.extendedservice = res.data.extendedservice;
                            editObj.Salary = res.data.Salary;
                            editObj.Qualification = res.data.Qualification;
                            editObj.GraduationYear = res.data.GraduationYear;
                            editObj.DateofretirementAge = res.data.DateofretirementAge;
                            editObj.TypeofContract = res.data.TypeofContract;
                            editObj.prviousextensionwithoutapprovalfrom = res.data.prviousextensionwithoutapprovalfrom;
                            editObj.prviousextensionwithoutapprovalto = res.data.prviousextensionwithoutapprovalto;
                            editObj.Previousextionsfrom = res.data.Previousextionsfrom;
                            editObj.Previousextionsto = res.data.Previousextionsto;
                            editObj.Replacementplanfrom = res.data.ReplacementPlanFrom;
                            editObj.Replacementplanto = res.data.ReplacementPlanTo;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/exceptionOfSteerTheAgeOfRetirementRequest', {
                                pathname: 'exceptionOfSteerTheAgeOfRetirementRequest',
                                data: this.props.location.state.data
                            });
                        }
                        else if (this.state.pathName === 'Promotion') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employename = res.data.employeename;
                            // editObj.employeenumber = res.data.employeenumber;
                            // editObj.nationalityid = res.data.nationalityid;
                            // editObj.dob = res.data.dob;
                            // editObj.jobtitle = res.data.jobtitle;
                            // editObj.unifiednumber = res.data.unifiednumber;
                            // editObj.steerfrom = res.data.steerfrom;
                            // editObj.steerto = res.data.steerto;
                            // editObj.isjurisdiction = res.data.isjurisdiction;
                            // editObj.reasonforextending = res.data.reasonforextending;
                            // editObj.annualperformanceevaluation = res.data.annualperformanceevaluation;
                            // editObj.emiratesid = res.data.emiratesid;

                            // editObj.attachments = res.data.attachments;
                            // editObj.HiringDate = res.data.HiringDate;
                            // editObj.CurrentGrade = res.data.CurrentGrade;
                            // editObj.Efficiency = res.data.Efficiency;
                            // editObj.extendedservice = res.data.extendedservice;
                            // editObj.Salary = res.data.Salary;
                            // editObj.Qualification = res.data.Qualification;
                            // editObj.GraduationYear = res.data.GraduationYear;
                            // editObj.DateofretirementAge = res.data.DateofretirementAge;
                            // editObj.TypeofContract = res.data.TypeofContract;
                            // editObj.prviousextensionwithoutapprovalfrom = res.data.prviousextensionwithoutapprovalfrom;
                            // editObj.prviousextensionwithoutapprovalto = res.data.prviousextensionwithoutapprovalto;
                            // editObj.Previousextionsfrom = res.data.Previousextionsfrom;
                            // editObj.Previousextionsto = res.data.Previousextionsto;
                            // editObj.Replacementplanfrom = res.data.ReplacementPlanFrom;
                            // editObj.Replacementplanto = res.data.ReplacementPlanTo;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/Promotion', {
                                pathname: 'Promotion',
                                data: this.props.location.state.data
                            });
                        }
                        else if (this.state.pathName === 'SickLeave') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employename = res.data.employeename;
                            // editObj.employeenumber = res.data.employeenumber;
                            // editObj.nationalityid = res.data.nationalityid;
                            // editObj.dob = res.data.dob;
                            // editObj.jobtitle = res.data.jobtitle;
                            // editObj.unifiednumber = res.data.unifiednumber;
                            // editObj.steerfrom = res.data.steerfrom;
                            // editObj.steerto = res.data.steerto;
                            // editObj.isjurisdiction = res.data.isjurisdiction;
                            // editObj.reasonforextending = res.data.reasonforextending;
                            // editObj.annualperformanceevaluation = res.data.annualperformanceevaluation;
                            // editObj.emiratesid = res.data.emiratesid;

                            // editObj.attachments = res.data.attachments;
                            // editObj.HiringDate = res.data.HiringDate;
                            // editObj.CurrentGrade = res.data.CurrentGrade;
                            // editObj.Efficiency = res.data.Efficiency;
                            // editObj.extendedservice = res.data.extendedservice;
                            // editObj.Salary = res.data.Salary;
                            // editObj.Qualification = res.data.Qualification;
                            // editObj.GraduationYear = res.data.GraduationYear;
                            // editObj.DateofretirementAge = res.data.DateofretirementAge;
                            // editObj.TypeofContract = res.data.TypeofContract;
                            // editObj.prviousextensionwithoutapprovalfrom = res.data.prviousextensionwithoutapprovalfrom;
                            // editObj.prviousextensionwithoutapprovalto = res.data.prviousextensionwithoutapprovalto;
                            // editObj.Previousextionsfrom = res.data.Previousextionsfrom;
                            // editObj.Previousextionsto = res.data.Previousextionsto;
                            // editObj.Replacementplanfrom = res.data.ReplacementPlanFrom;
                            // editObj.Replacementplanto = res.data.ReplacementPlanTo;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/SickLeave', {
                                pathname: 'SickLeave',
                                data: this.props.location.state.data
                            });
                        }
                        else if (this.state.pathName === 'extendCreateObjectives') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.fromdate = res.data.fromdate;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/extendCreateObjectives', {
                                pathname: 'extendCreateObjectives',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'extendReviewObjectives') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.fromdate = res.data.fromdate;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/extendReviewObjectives', {
                                pathname: 'extendReviewObjectives',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'extendAnnualPerformance') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.fromdate = res.data.fromdate;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/extendAnnualPerformance', {
                                pathname: 'extendAnnualPerformance',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;

                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/addOrEditEmployeesPerformanceEvaluation', {
                                pathname: 'addOrEditEmployeesPerformanceEvaluation',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.systemlist = res.data.systemlist;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/joinAndImplementGovResourcesSystemForNewEntity', {
                                pathname: 'joinAndImplementGovResourcesSystemForNewEntity',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'openComplainsStage') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.todate = res.data.todate;
                            editObj.fromdate = res.data.fromdate;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/openComplainsStage', {
                                pathname: 'openComplainsStage',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'amendsSharedGovernmentHRManagement') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.systemlist = res.data.systemlist;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/amendsSharedGovernmentHRManagement', {
                                pathname: 'amendsSharedGovernmentHRManagement',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'reportsAndStaticRequest') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/reportsAndStaticRequest', {
                                pathname: 'reportsAndStaticRequest',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'otherRequests') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/otherRequests', {
                                pathname: 'otherRequests',
                                data: this.props.location.state.data
                            });

                        } else if (this.state.pathName === 'entity') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.englishName = res.data.englishName;
                            editObj.arabicName = res.data.arabicName;
                            editObj.shortName = res.data.shortName;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.ismothercompany = res.data.ismothercompany;
                            editObj.phonenumber = res.data.phonenumber;
                            editObj.mothercompanyid = res.data.mothercompanyid;
                            editObj.industryid = res.data.industryid;
                            editObj.cityid = res.data.cityid;
                            editObj.description = res.data.description;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.sectorid = res.data.sectorid;
                            editObj.statusid = res.data.statusid;
                            editObj.attachments = res.data.attachments;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/entity', {
                                pathname: 'entity',
                                data: this.props.location.state.data
                            });

                        }

                        else if (this.state.pathName === 'OrganizationStructure') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.requestdate = res.data.requestdate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);


                            this.props.history.push('/OrganizationStructure', {
                                pathname: 'OrganizationStructure',
                                data: this.props.location.state.data
                            });


                        }

                        else if (this.state.pathName === 'contact') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.englishName = res.data.englishName;
                            editObj.arabicName = res.data.arabicName;
                            editObj.username = res.data.username;
                            editObj.emailAddress = res.data.emailAddress;
                            editObj.mobilenumber = res.data.mobilenumber;
                            editObj.phonenumber = res.data.phonenumber;
                            editObj.designation = res.data.designation;
                            editObj.accountType = res.data.accountTypeID;
                            editObj.roleID = res.data.roleID;
                            editObj.status = res.data.statusid;
                            editObj.entityName = res.data.entityID;
                            editObj.statusreason = res.data.statusreasonid;
                            editObj.internalcontact = res.data.internalcontact;
                            editObj.manager = res.data.manager;
                            editObj.departmentID = res.data.departmentID;
                            editObj.sectorID = res.data.sectorID;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/contact', {
                                pathname: 'contact',
                                data: this.props.location.state.data
                            });
                        } else if (this.state.pathName === 'mailTemplate') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.title = res.data.title;
                            editObj.content = res.data.content;
                            editObj.notificationtype = res.data.notificationtype;

                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/mailTemplate', {
                                pathname: 'mailTemplate',
                                data: this.props.location.state.data
                            });
                        } else if (this.state.pathName === 'role') {
                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {};
                            editObj.canviewreport = res.data.canviewreport;
                            editObj.requestId = res.data.id;
                            editObj.titleSelectedValue = res.data.EnglishTitle;
                            editObj.arabicTitle = res.data.ArabicTitle;

                            // organization structure 

                            editObj.cansubmitorgrequest = res.data.cansubmitorgrequest
                            editObj.canvieworgrequest = res.data.canvieworgrequest
                            editObj.canvieworgrequesttree = res.data.CanCreateStructure

                            editObj.caneditorgrequest = res.data.caneditorgrequest
                            editObj.canupdateorgstatus = res.data.canupdateorgstatus
                            editObj.canaddorgcomment = res.data.canaddorgcomment
                            editObj.canassignorgdepartment = res.data.canassignorgdepartment
                            editObj.canasignorgemployee = res.data.canasignorgemployee
                            editObj.canprovideorgfeedback = res.data.canprovideorgfeedback
                            editObj.canvieworgfeedback = res.data.canvieworgfeedback
                            editObj.canassignorgsector = res.data.canassignorgsector
                            editObj.canshoworgcomments = res.data.canshoworgcomments
                            editObj.canassignorggm = res.data.canassignorggm
                            editObj.canassignorgbacktoentity = res.data.canassignorgbacktoentity



                            editObj.isadmin = res.data.isadmin;
                            //sci
                            editObj.cansubmitscirequest = res.data.cansubmitscirequest;
                            editObj.canreviewscirequest = res.data.canreviewscirequest;
                            editObj.caneditscirequest = res.data.caneditscirequest;
                            editObj.canupdatescistatus = res.data.canupdatescistatus;
                            editObj.canaddscicomment = res.data.canaddscicomment;
                            editObj.canassignscidepartment = res.data.canassignscidepartment;
                            editObj.canasignsciemployee = res.data.canasignsciemployee;
                            editObj.canprovidescifeedback = res.data.canprovidescifeedback;
                            editObj.canviewscifeedback = res.data.canviewscifeedback;
                            editObj.canassignscisector = res.data.canassignscisector;
                            editObj.canshowscicomments = res.data.canshowscicomments;
                            editObj.canassignscigm = res.data.canassignscigm;
                            editObj.canassignscibacktoentity = res.data.canassignscibacktoentity;

                            //Info request
                            editObj.cansubmitirrequest = res.data.cansubmitirrequest;
                            editObj.caneditirrequest = res.data.caneditirrequest;
                            editObj.canupdateirstatus = res.data.canupdateirstatus;
                            editObj.canaddircomments = res.data.canaddircomments;
                            editObj.canassignirdepartment = res.data.canassignirdepartment;
                            editObj.canprovideirfeedback = res.data.canprovideirfeedback;
                            editObj.canviewirrequest = res.data.canviewirrequest;
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canassignirsector = res.data.canassignirsector;
                            editObj.canreceiveirfeedback = res.data.canreceiveirfeedback;
                            editObj.canassignirgm = res.data.canassignirgm;
                            editObj.canassignirbacktoentity = res.data.canassignirbacktoentity;

                            //Exemption patient
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canviewpatientrequest = res.data.canviewpatientrequest;
                            editObj.caneditpatientrequest = res.data.caneditpatientrequest;
                            editObj.canupdatepatientstatus = res.data.canupdatepatientstatus;
                            editObj.canaddpatientcomments = res.data.canaddpatientcomments;
                            editObj.canassignpatientdepartment = res.data.canassignpatientdepartment;
                            editObj.canassignpatientemployee = res.data.canassignpatientemployee;
                            editObj.canassignpatientsector = res.data.canassignpatientsector;
                            editObj.canprovidepatientstudy = res.data.canprovidepatientstudy;
                            editObj.canreceivepatientstudy = res.data.canreceivepatientstudy;
                            editObj.canshowpatientcomments = res.data.canshowpatientcomments;
                            editObj.cansubmitpatientrequest = res.data.cansubmitpatientrequest;
                            editObj.canassignpatientgm = res.data.canassignpatientgm;
                            editObj.canassignpatientbacktoentity = res.data.canassignpatientbacktoentity;

                            //review hr policy
                            editObj.cansubmitreviewhrrequest = res.data.cansubmitreviewhrrequest;
                            editObj.canviewreviewhrrequest = res.data.canviewreviewhrrequest;
                            editObj.caneditreviewhrrequest = res.data.caneditreviewhrrequest;
                            editObj.canupdatereviewhrstatus = res.data.canupdatereviewhrstatus;
                            editObj.canaddreviewhrcomments = res.data.canaddreviewhrcomments;
                            editObj.canshowreviewhrcomments = res.data.canshowreviewhrcomments;
                            editObj.canassignhrdepartment = res.data.canassignhrdepartment;
                            editObj.canassignhrsector = res.data.canassignhrsector;
                            editObj.canassignhremployee = res.data.canassignhremployee;
                            editObj.canprovidehrstudy = res.data.canprovidehrstudy;
                            editObj.canassignhrgm = res.data.canassignhrgm;
                            editObj.canassignhrbacktoentity = res.data.canassignhrbacktoentity;

                            //AD Housing
                            editObj.cansubmitadhousingrequest = res.data.cansubmitadhousingrequest;
                            editObj.canviewadhousingrequest = res.data.canviewadhousingrequest;
                            editObj.caneditadhousingrequest = res.data.caneditadhousingrequest;
                            editObj.canupdateadhousingstatus = res.data.canupdateadhousingstatus;
                            editObj.canaddadhousingcomments = res.data.canaddadhousingcomments;
                            editObj.canshowadhousingcomments = res.data.canshowadhousingcomments;
                            editObj.canassignhousingdepartment = res.data.canassignhousingdepartment;
                            editObj.canassignhousingemployee = res.data.canassignhousingemployee;
                            editObj.canassignhousingsector = res.data.canassignhousingsector;
                            editObj.canprovidehousingstudy = res.data.canprovidehousingstudy;
                            editObj.canreceivehousingstudy = res.data.canreceivehousingstudy;
                            editObj.canassignhousinggm = res.data.canassignhousinggm;
                            editObj.canassignhousingbacktoentity = res.data.canassignhousingbacktoentity;

                            //Secondment
                            editObj.cansubmitsecondmentrequest = res.data.cansubmitsecondmentrequest;
                            editObj.canviewsecondmentrequest = res.data.canviewsecondmentrequest;
                            editObj.caneditsecondmentrequest = res.data.caneditsecondmentrequest;
                            editObj.canupdatesecondmentstatus = res.data.canupdatesecondmentstatus;
                            editObj.canaddsecondmentcomments = res.data.canaddsecondmentcomments;
                            editObj.canshowsecondmentcomments = res.data.canshowsecondmentcomments;
                            editObj.canassignsecondmentdepartment = res.data.canassignsecondmentdepartment;
                            editObj.canassignsecondmetnemployee = res.data.canassignsecondmetnemployee;
                            editObj.canassignsecondmentsector = res.data.canassignsecondmentsector;
                            editObj.canprovidesecondmentstudy = res.data.canprovidesecondmentstudy;
                            editObj.canreceivesecondmentstudy = res.data.canreceivesecondmentstudy;
                            editObj.canassignsecondmentgm = res.data.canassignsecondmentgm;
                            editObj.canassignsecondmentbacktoentity = res.data.canassignsecondmentbacktoentity;

                            //Bonus request
                            editObj.cansubmitbonusrequest = res.data.cansubmitbonusrequest;
                            editObj.canviewbonusrequest = res.data.canviewbonusrequest;
                            editObj.caneditbonusrequest = res.data.caneditbonusrequest;
                            editObj.canupdatebonusstatus = res.data.canupdatebonusstatus;
                            editObj.canaddbonuscomments = res.data.canaddbonuscomments;
                            editObj.canshowbonuscomments = res.data.canshowbonuscomments;
                            editObj.canassignbounsdepartment = res.data.canassignbounsdepartment;
                            editObj.canassignbounsemployee = res.data.canassignbounsemployee;
                            editObj.canassignbonussector = res.data.canassignbonussector;
                            editObj.canprovidebonusstudy = res.data.canprovidebonusstudy;
                            editObj.canreceivebonusstudy = res.data.canreceivebonusstudy;
                            editObj.canassignbonusgm = res.data.canassignbonusgm;
                            editObj.canassignbonusbacktoentity = res.data.canassignbonusbacktoentity;

                            //Ammend salary scale
                            editObj.cansubmitamendscalerequest = res.data.cansubmitamendscalerequest;
                            editObj.canviewamendscalerequest = res.data.canviewamendscalerequest;
                            editObj.caneditamendscalerequest = res.data.caneditamendscalerequest;
                            editObj.canupdateamendscalestatus = res.data.canupdateamendscalestatus;
                            editObj.canshowamendscalecomments = res.data.canshowamendscalecomments;
                            editObj.canaddamendscalecomments = res.data.canaddamendscalecomments;
                            editObj.canassignamendscaledepartment = res.data.canassignamendscaledepartment;
                            editObj.canassignamendscaleemployee = res.data.canassignamendscaleemployee;
                            editObj.canassignamendscalesector = res.data.canassignamendscalesector;
                            editObj.canprovideamendscalestudy = res.data.canprovideamendscalestudy;
                            editObj.canreceiveamendscalestudy = res.data.canreceiveamendscalestudy;
                            editObj.canassignamendscalegm = res.data.canassignamendscalegm;
                            editObj.canassignamendscalebacktoentity = res.data.canassignamendscalebacktoentity;

                            //Amend emp salary
                            editObj.cansubmitamendsalaryrequest = res.data.cansubmitamendsalaryrequest;
                            editObj.canviewamendsalaryrequest = res.data.canviewamendsalaryrequest;
                            editObj.caneditamendsalaryrequest = res.data.caneditamendsalaryrequest;
                            editObj.canupdateamendsalarystatus = res.data.canupdateamendsalarystatus;
                            editObj.canaddamendsalarycomments = res.data.canaddamendsalarycomments;
                            editObj.canshowamendsalarycomments = res.data.canshowamendsalarycomments;
                            editObj.canassignamendsalarydepartment = res.data.canassignamendsalarydepartment;
                            editObj.canassignamendsalaryemployee = res.data.canassignamendsalaryemployee;
                            editObj.canassignamendsalarysector = res.data.canassignamendsalarysector;
                            editObj.canprovideamendsalarystudy = res.data.canprovideamendsalarystudy;
                            editObj.canreceiveamendsalarystudy = res.data.canreceiveamendsalarystudy;
                            editObj.canassignamendsalarygm = res.data.canassignamendsalarygm;
                            editObj.canassignamendsalarybacktoentity = res.data.canassignamendsalarybacktoentity;

                            //retirement
                            editObj.cansubmitretirementrequest = res.data.cansubmitretirementrequest;
                            editObj.canviewretirementrequest = res.data.canviewretirementrequest;
                            editObj.caneditretirementrequest = res.data.caneditretirementrequest;
                            editObj.canupdateretirementstatus = res.data.canupdateretirementstatus;
                            editObj.canaddretirementcomments = res.data.canaddretirementcomments;
                            editObj.canshowretirementcomments = res.data.canshowretirementcomments;
                            editObj.canassignretirementdepartment = res.data.canassignretirementdepartment;
                            editObj.canassignretirementemployees = res.data.canassignretirementemployees;
                            editObj.canassignretirementsector = res.data.canassignretirementsector;
                            editObj.canprovideretirementstudy = res.data.canprovideretirementstudy;
                            editObj.canreceiveretirementstudy = res.data.canreceiveretirementstudy;
                            editObj.canassignretirementgm = res.data.canassignretirementgm;
                            editObj.canassignretirementbacktoentity = res.data.canassignretirementbacktoentity;

                            // extend create objective
                            editObj.cansubmitextendcreaterequest = res.data.cansubmitextendcreaterequest;
                            editObj.canviewextendcreaterequest = res.data.canviewextendcreaterequest;
                            editObj.caneditextendcreaterequest = res.data.caneditextendcreaterequest;
                            editObj.canupdateextendcreatestatus = res.data.canupdateextendcreatestatus;
                            editObj.canshowextendcreatecomments = res.data.canshowextendcreatecomments;
                            editObj.canaddextendcreatecomments = res.data.canaddextendcreatecomments;
                            editObj.canprovideextendcreatestudy = res.data.canprovideextendcreatestudy;
                            editObj.canreceiveextendcreatestudy = res.data.canreceiveextendcreatestudy;
                            editObj.canassignextendcreatesector = res.data.canassignextendcreatesector;
                            editObj.canassignextendcreatedepartment = res.data.canassignextendcreatedepartment;
                            editObj.canassignextendcreateemployee = res.data.canassignextendcreateemployee;
                            editObj.canassignextendcreatebacktodepartment = res.data.canassignextendcreatebacktodepartment;
                            editObj.canassignextendcreatebacktoentity = res.data.canassignextendcreatebacktoentity;
                            editObj.canassignextendcreaterequesttogm = res.data.canassignextendcreaterequesttogm;

                            // exten review objective
                            editObj.cansubmitextendreviewrequest = res.data.cansubmitextendreviewrequest;
                            editObj.canviewextendreviewrequest = res.data.canviewextendreviewrequest;
                            editObj.caneditextendreviewrequest = res.data.caneditextendreviewrequest;
                            editObj.canupdateextendreviewstatus = res.data.canupdateextendreviewstatus;
                            editObj.canshowextendreviewcomments = res.data.canshowextendreviewcomments;
                            editObj.canaddextendreviewcomments = res.data.canaddextendreviewcomments;
                            editObj.canprovideextendreviewstudy = res.data.canprovideextendreviewstudy;
                            editObj.canreceiveextendreviewstudy = res.data.canreceiveextendreviewstudy;
                            editObj.canassignextendreviewsector = res.data.canassignextendreviewsector;
                            editObj.canassignextendreviewdepartment = res.data.canassignextendreviewdepartment;
                            editObj.canassignextendreviewemployee = res.data.canassignextendreviewemployee;
                            editObj.canassignextendreviewbacktodepartment = res.data.canassignextendreviewbacktodepartment;
                            editObj.canassignextendreviewbacktoentity = res.data.canassignextendreviewbacktoentity;
                            editObj.canassignextendreviewrequesttogm = res.data.canassignextendreviewrequesttogm;

                            // extend annual
                            editObj.cansubmitextendannualrequest = res.data.cansubmitextendannualrequest;
                            editObj.canviewextendannualrequest = res.data.canviewextendannualrequest;
                            editObj.caneditextendannualrequest = res.data.caneditextendannualrequest;
                            editObj.canupdateextendannualstatus = res.data.canupdateextendannualstatus;
                            editObj.canshowextendannualcomments = res.data.canshowextendannualcomments;
                            editObj.canaddextendannualcomments = res.data.canaddextendannualcomments;
                            editObj.canprovideextendannualstudy = res.data.canprovideextendannualstudy;
                            editObj.canreceiveextendannualstudy = res.data.canreceiveextendannualstudy;
                            editObj.canassignextendannualsector = res.data.canassignextendannualsector;
                            editObj.canassignextendannualdepartment = res.data.canassignextendannualdepartment;
                            editObj.canassignextendannualemployee = res.data.canassignextendannualemployee;
                            editObj.canassignextendannualbacktodepartment = res.data.canassignextendannualbacktodepartment;
                            editObj.canassignextendannualbacktoentity = res.data.canassignextendannualbacktoentity;
                            editObj.canassignextendannualrequesttogm = res.data.canassignextendannualrequesttogm;

                            // add and edit employee performance
                            editObj.cansubmitemployeeperformancerequest = res.data.cansubmitemployeeperformancerequest;
                            editObj.canviewemployeeperformancerequest = res.data.canviewemployeeperformancerequest;
                            editObj.caneditemployeeperformancerequest = res.data.caneditemployeeperformancerequest;
                            editObj.canupdateemployeeperformancestatus = res.data.canupdateemployeeperformancestatus;
                            editObj.canshowemployeeperformancecomments = res.data.canshowemployeeperformancecomments;
                            editObj.canaddemployeeperformancecomments = res.data.canaddemployeeperformancecomments;
                            editObj.canprovideemployeeperformancestudy = res.data.canprovideemployeeperformancestudy;
                            editObj.canreceiveemployeeperformancestudy = res.data.canreceiveemployeeperformancestudy;
                            editObj.canassignemployeeperformancesector = res.data.canassignemployeeperformancesector;
                            editObj.canassignemployeeperformancedepartment = res.data.canassignemployeeperformancedepartment;
                            editObj.canassignemployeeperformanceemployee = res.data.canassignemployeeperformanceemployee;
                            editObj.canassignemployeeperformancebacktodepartment = res.data.canassignemployeeperformancebacktodepartment;
                            editObj.canassignemployeeperformancebacktoentity = res.data.canassignemployeeperformancebacktoentity;
                            editObj.canassignemployeeperformancerequesttogm = res.data.canassignemployeeperformancerequesttogm;

                            // join and implement
                            editObj.cansubmitjoinimplementrequest = res.data.cansubmitjoinimplementrequest;
                            editObj.canviewjoinimplementrequest = res.data.canviewjoinimplementrequest;
                            editObj.caneditjoinimplementrequest = res.data.caneditjoinimplementrequest;
                            editObj.canupdatejoinimplementstatus = res.data.canupdatejoinimplementstatus;
                            editObj.canshowjoinimplementcomments = res.data.canshowjoinimplementcomments;
                            editObj.canaddjoinimplementcomments = res.data.canaddjoinimplementcomments;
                            editObj.canprovidejoinimplementstudy = res.data.canprovidejoinimplementstudy;
                            editObj.canreceivejoinimplementstudy = res.data.canreceivejoinimplementstudy;
                            editObj.canassignjoinimplementsector = res.data.canassignjoinimplementsector;
                            editObj.canassignjoinimplementdepartment = res.data.canassignjoinimplementdepartment;
                            editObj.canassignjoinimplementemployee = res.data.canassignjoinimplementemployee;
                            editObj.canassignjoinimplementbacktodepartment = res.data.canassignjoinimplementbacktodepartment;
                            editObj.canassignjoinimplementbacktoentity = res.data.canassignjoinimplementbacktoentity;
                            editObj.canassignjoinimplementrequesttogm = res.data.canassignjoinimplementrequesttogm;

                            // open 
                            editObj.cansubmitopencomplainsrequest = res.data.cansubmitopencomplainsrequest;
                            editObj.canviewopencomplainsrequest = res.data.canviewopencomplainsrequest;
                            editObj.caneditopencomplainsrequest = res.data.caneditopencomplainsrequest;
                            editObj.canupdateopencomplainsstatus = res.data.canupdateopencomplainsstatus;
                            editObj.canshowopencomplainscomments = res.data.canshowopencomplainscomments;
                            editObj.canaddopencomplainscomments = res.data.canaddopencomplainscomments;
                            editObj.canprovideopencomplainsstudy = res.data.canprovideopencomplainsstudy;
                            editObj.canreceiveopencomplainsstudy = res.data.canreceiveopencomplainsstudy;
                            editObj.canassignopencomplainssector = res.data.canassignopencomplainssector;
                            editObj.canassignopencomplainsdepartment = res.data.canassignopencomplainsdepartment;
                            editObj.canassignopencomplainsemployee = res.data.canassignopencomplainsemployee;
                            editObj.canassignopencomplainsbacktodepartment = res.data.canassignopencomplainsbacktodepartment;
                            editObj.canassignopencomplainsbacktoentity = res.data.canassignopencomplainsbacktoentity;
                            editObj.canassignopencomplainsrequesttogm = res.data.canassignopencomplainsrequesttogm;

                            // amend systemcansubmitamendsystemsrequest
                            editObj.cansubmitamendsystemsrequest = res.data.cansubmitamendsystemsrequest;
                            editObj.canviewamendsystemsrequest = res.data.canviewamendsystemsrequest;
                            editObj.caneditamendsystemsrequest = res.data.caneditamendsystemsrequest;
                            editObj.canupdateamendsystemsstatus = res.data.canupdateamendsystemsstatus;
                            editObj.canshowamendsystemscomments = res.data.canshowamendsystemscomments;
                            editObj.canaddamendsystemscomments = res.data.canaddamendsystemscomments;
                            editObj.canprovideamendsystemsstudy = res.data.canprovideamendsystemsstudy;
                            editObj.canreceiveamendsystemsstudy = res.data.canreceiveamendsystemsstudy;
                            editObj.canassignamendsystemssector = res.data.canassignamendsystemssector;
                            editObj.canassignamendsystemsdepartment = res.data.canassignamendsystemsdepartment;
                            editObj.canassignamendsystemsemployee = res.data.canassignamendsystemsemployee;
                            editObj.canassignamendsystemsbacktodepartment = res.data.canassignamendsystemsbacktodepartment;
                            editObj.canassignamendsystemsbacktoentity = res.data.canassignamendsystemsbacktoentity;
                            editObj.canassignamendsystemsrequesttogm = res.data.canassignamendsystemsrequesttogm;

                            // report and static
                            editObj.cansubmitreportsstaticsrequest = res.data.cansubmitreportsstaticsrequest;
                            editObj.canviewreportsstaticsrequest = res.data.canviewreportsstaticsrequest;
                            editObj.caneditreportsstaticsrequest = res.data.caneditreportsstaticsrequest;
                            editObj.canupdatereportsstaticsstatus = res.data.canupdatereportsstaticsstatus;
                            editObj.canshowreportsstaticscomments = res.data.canshowreportsstaticscomments;
                            editObj.canaddreportsstaticscomments = res.data.canaddreportsstaticscomments;
                            editObj.canprovidereportsstaticsstudy = res.data.canprovidereportsstaticsstudy;
                            editObj.canreceivereportsstaticsstudy = res.data.canreceivereportsstaticsstudy;
                            editObj.canassignreportsstaticssector = res.data.canassignreportsstaticssector;
                            editObj.canassignreportsstaticsdepartment = res.data.canassignreportsstaticsdepartment;
                            editObj.canassignreportsstaticsemployee = res.data.canassignreportsstaticsemployee;
                            editObj.canassignreportsstaticsbacktodepartment = res.data.canassignreportsstaticsbacktodepartment;
                            editObj.canassignreportsstaticsbacktoentity = res.data.canassignreportsstaticsbacktoentity;
                            editObj.canassignreportsstaticsrequesttogm = res.data.canassignreportsstaticsrequesttogm;

                            // other request 
                            editObj.cansubmitotherrequestsrequest = res.data.cansubmitotherrequestsrequest;
                            editObj.canviewotherrequestsrequest = res.data.canviewotherrequestsrequest;
                            editObj.caneditotherrequestsrequest = res.data.caneditotherrequestsrequest;
                            editObj.canupdateotherrequestsstatus = res.data.canupdateotherrequestsstatus;
                            editObj.canshowotherrequestscomments = res.data.canshowotherrequestscomments;
                            editObj.canaddotherrequestscomments = res.data.canaddotherrequestscomments;
                            editObj.canprovideotherrequestsstudy = res.data.canprovideotherrequestsstudy;
                            editObj.canreceiveotherrequestsstudy = res.data.canreceiveotherrequestsstudy;
                            editObj.canassignotherrequestssector = res.data.canassignotherrequestssector;
                            editObj.canassignotherrequestsdepartment = res.data.canassignotherrequestsdepartment;
                            editObj.canassignotherrequestsemployee = res.data.canassignotherrequestsemployee;
                            editObj.canassignotherrequestsbacktodepartment = res.data.canassignotherrequestsbacktodepartment;
                            editObj.canassignotherrequestsbacktoentity = res.data.canassignotherrequestsbacktoentity;
                            editObj.canassignotherrequesttogmsrequesttogm = res.data.canassignotherrequesttogmsrequesttogm;
                            editObj.canCloseRequest = res.data.canCloseRequest;



                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/role', {
                                pathname: 'role',
                                data: this.props.location.state.data
                            });
                        }
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message_one
                        }, () => {
                            this.alert();
                        })
                        // break;                         
                    }
                })
            })
        })
    }
    callRequestByIdForViewDeatils = (requestId, endPoint) => {
        //let id = requestId;
        debugger;
        let payload = getRequestByIdPayload(requestId);
        let url = Config.getUrl(endPoint);

        debugger;
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        debugger;
                        let tempData = res.data;
                        let requesttypeid = tempData.requesttypeid;
                        this.setState({ routingdetails: res.data });
                        debugger;
                        this.props.history.replace('/viewContainer', {
                            requesttypeid: requesttypeid,
                            id: tempData.id,
                            pathname: this.props.location.state,
                            data: tempData,
                            ActionData: this.props.location.state.data
                        })



                    } else {

                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message_one
                        }, () => {
                            this.alert();
                        })
                    }
                })
            })
        })
    }

    saveFile = (item) => {
        let obj = {}

        obj.filename = item.aliasfilename;
        obj.requesttype = this.state.requesttypeid;
        obj.requestid = this.props.location.state.id;

        let payload = downloadAttachment(obj);
        let endPoint = 'DownloadAttachment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let fileData = res.data;
                        let filecontent = fileData.filecontent;
                        let contenttype = fileData.contenttype
                        if (navigator.msSaveBlob) {
                            let blob = this.b64toBlob(filecontent, contenttype);
                            window.navigator.msSaveBlob(blob, item.filename);
                        } else {
                            let a = window.document.createElement('a');
                            a.href = `data:${contenttype};base64,${filecontent}`;
                            a.download = item.filename;
                            // Append anchor to body.
                            document.body.appendChild(a);
                            a.click();
                            // Remove anchor from body
                            document.body.removeChild(a);
                        }
                    } else {

                    }
                })
            })
        })
    }
    selectModalView = () => {
        debugger;
        switch (this.state.selectedAction) {
            case 'view':
                return (
                    <ViewRecordComponent
                        isArabic={this.state.isArabic}
                        Attachments={this.props.location.state.data.attachments}
                        comment={this.props.location.state.data.comments}
                        toggle={this.toggle}
                        statusHistory={this.props.location.state.data.history}
                        data={this.props.location.state.data}
                        location={this.props.location.state} />
                )
            case 'viewdetails':
                if (this.props.location.state.data) {
debugger;
                    let requesttypeid = this.props.location.state.data.requesttypeid;

                    if (requesttypeid === 10969) {
                        let sciRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(sciRequestId, 'GetStrategicPlanningforworkforceEnquirebyID');
                    } 
                  else  if (requesttypeid === Config.getSciRequestTypeId() || requesttypeid === Config.getSCILEgalSerbviceId() || requesttypeid === Config.getSCIComplainId() || requesttypeid === Config.getSCILEgalSuggestionId() || requesttypeid === Config.getSCIGeneralId()) {
                        let sciRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(sciRequestId, 'GetscirequestbyID');
                    } else if (requesttypeid === Config.getInformationRequestTypeId()) {
                        debugger;
                        let informationRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(informationRequestId, 'GetinformationrequestbyID');
                    } else if (requesttypeid === Config.getBonusRequestTypeId()) {
                        let bonusRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(bonusRequestId, 'GetbounsrequestbyID');
                    } else if (requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                        let amendSalaryScaleRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(amendSalaryScaleRequestId, 'GetAmmendSalaryScalebyID');
                    } else if (requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                        let amendEmployeeSalaryRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(amendEmployeeSalaryRequestId, 'GetAmmendEmployeeSalarybyID');
                    } else if (requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                        let reviewhrPolicyRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(reviewhrPolicyRequestId, 'GetHRPolicyReviewbyID');
                    } else if (requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                        let adHousingRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(adHousingRequestId, 'GetHousingPolicybyID');
                    } else if (requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                        let secondmentFederalRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(secondmentFederalRequestId, 'GetFederalSecondmentbyID');
                    } else if (requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                        let patientRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(patientRequestId, 'GetPoliciesPatientrequestbyID');
                    }
                    else if (requesttypeid === Config.getSickLeaveTypeId()) {
                        let patientRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(patientRequestId, 'GetSickLeaveById');
                    }
                    else if (requesttypeid === Config.getPromotionTypeId()) {
                        let patientRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(patientRequestId, 'GetPromotionById');
                    }

                    else if (requesttypeid === Config.getRetirmentRequestTypeId()) {
                        let retirementRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(retirementRequestId, 'GetRetirementbyID');
                    }
                    else if (requesttypeid === Config.getPromotionTypeId()) {
                        let retirementRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(retirementRequestId, 'GetPromotionById');
                    }
                    else if (requesttypeid === Config.getExtendCreateObjectiveId()) {
                        let extendCreateObjectiveRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(extendCreateObjectiveRequestId, 'GetExtendCreateObjectivesbyID');
                    } else if (requesttypeid === Config.getExtendReviewObjectiveId()) {
                        let extendReviewObjectiveRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(extendReviewObjectiveRequestId, 'GetExtendReviewObjectivesbyID');
                    } else if (requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                        let extendAnnualPerformanceRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(extendAnnualPerformanceRequestId, 'GetExtendAnnualPerformancebyID');
                    } else if (requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                        let addOrEditEmployeePerformanceRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(addOrEditEmployeePerformanceRequestId, 'GetEmpPerformancebyID');
                    } else if (requesttypeid === Config.getJoinAndImplementId()) {
                        let joinAndImplementRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(joinAndImplementRequestId, 'GetJoinAndImplementSystemsbyID');
                    } else if (requesttypeid === Config.getOpenComplainsStageId()) {
                        let openComplainsStageRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(openComplainsStageRequestId, 'GetOpenGrievanceProcessbyID');
                    } else if (requesttypeid === Config.getAmendOnSharedGovermentId()) {
                        let amendsSharedGovermentHrRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(amendsSharedGovermentHrRequestId, 'GetAmendSystemsbyID');
                    } else if (requesttypeid === Config.getReportAndStaticId()) {
                        let reportAndStaticRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(reportAndStaticRequestId, 'GetReportsRequestsbyID');
                    } else if (requesttypeid === Config.getOtherRequest()) {
                        let otherRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(otherRequestId, 'GetOtherRequestsbyID');
                    }
                    else if (requesttypeid === Config.getORGRequestTypeId()) {
                        let orgRequestId = this.props.location.state.data.id;
                        this.callRequestByIdForViewDeatils(orgRequestId, 'GetOrganizationStructurebyID');
                    }
                }
                break;
            case 'edit':

                if (this.props.location.state.data) {
                    let requesttypeid = this.props.location.state.data.requesttypeid;

                    if (this.state.pathName === 'SCIRequest') {
                        let sciRequestId = this.props.location.state.data.id;
                        this.callRequestById(sciRequestId, 'GetscirequestbyID');
                    } else if (this.state.pathName === 'informationRequest') {
                        let informationRequestId = this.props.location.state.data.id;
                        this.callRequestById(informationRequestId, 'GetinformationrequestbyID');
                    } else if (this.state.pathName === 'amendEmployeeSalary') {
                        let amendEmployeeSalaryRequestId = this.props.location.state.data.id;
                        this.callRequestById(amendEmployeeSalaryRequestId, 'GetAmmendEmployeeSalarybyID');
                    } else if (this.state.pathName === 'amendSalaryScreen') {
                        let amendSalaryScaleRequestId = this.props.location.state.data.id;
                        this.callRequestById(amendSalaryScaleRequestId, 'GetAmmendSalaryScalebyID');
                    } else if (this.state.pathName === 'reviewHRPolicies') {
                        let reviewhrPolicyRequestId = this.props.location.state.data.id;
                        this.callRequestById(reviewhrPolicyRequestId, 'GetHRPolicyReviewbyID');
                    } else if (this.state.pathName === 'exceptionADHousing') {
                        let adHousingRequestId = this.props.location.state.data.id;
                        this.callRequestById(adHousingRequestId, 'GetHousingPolicybyID');
                    } else if (this.state.pathName === 'secondmentForFederalAgency') {
                        let secondmentFederalRequestId = this.props.location.state.data.id;
                        this.callRequestById(secondmentFederalRequestId, 'GetFederalSecondmentbyID');
                    } else if (this.state.pathName === 'bonusRequest') {
                        let bonusRequestId = this.props.location.state.data.id;
                        this.callRequestById(bonusRequestId, 'GetbounsrequestbyID');
                    } else if (this.state.pathName === 'exemptionFromAccompanyPatient') {
                        let patientRequestId = this.props.location.state.data.id;
                        this.callRequestById(patientRequestId, 'GetPoliciesPatientrequestbyID');
                    } else if (this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                        let retirementRequestId = this.props.location.state.data.id;
                        this.callRequestById(retirementRequestId, 'GetRetirementbyID');
                    }
                    else if (this.state.pathName === 'Promotion') {
                        let retirementRequestId = this.props.location.state.data.id;
                        this.callRequestById(retirementRequestId, 'GetPromotionById');
                    }
                    else if (this.state.pathName === 'SickLeave') {
                        let retirementRequestId = this.props.location.state.data.id;
                        this.callRequestById(retirementRequestId, 'GetSickLeaveById');
                    }
                    else if (this.state.pathName === 'extendCreateObjectives') {
                        let extendCreateObjectiveRequestId = this.props.location.state.data.id;
                        this.callRequestById(extendCreateObjectiveRequestId, 'GetExtendCreateObjectivesbyID');
                    } else if (this.state.pathName === 'extendReviewObjectives') {
                        let extendReviewObjectiveRequestId = this.props.location.state.data.id;
                        this.callRequestById(extendReviewObjectiveRequestId, 'GetExtendReviewObjectivesbyID');
                    } else if (this.state.pathName === 'extendAnnualPerformance') {
                        let extendAnnualPerformanceRequestId = this.props.location.state.data.id;
                        this.callRequestById(extendAnnualPerformanceRequestId, 'GetExtendAnnualPerformancebyID');
                    } else if (this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                        let addOrEditEmployeePerformanceRequestId = this.props.location.state.data.id;
                        this.callRequestById(addOrEditEmployeePerformanceRequestId, 'GetEmpPerformancebyID');
                    } else if (this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                        let joinAndImplementRequestId = this.props.location.state.data.id;
                        this.callRequestById(joinAndImplementRequestId, 'GetJoinAndImplementSystemsbyID');
                    } else if (this.state.pathName === 'openComplainsStage') {
                        let openComplainsStageRequestId = this.props.location.state.data.id;
                        this.callRequestById(openComplainsStageRequestId, 'GetOpenGrievanceProcessbyID');
                    } else if (this.state.pathName === 'amendsSharedGovernmentHRManagement') {
                        let amendsSharedGovermentHrRequestId = this.props.location.state.data.id;
                        this.callRequestById(amendsSharedGovermentHrRequestId, 'GetAmendSystemsbyID');
                    } else if (this.state.pathName === 'reportsAndStaticRequest') {
                        let reportAndStaticRequestId = this.props.location.state.data.id;
                        this.callRequestById(reportAndStaticRequestId, 'GetReportsRequestsbyID');
                    } else if (this.state.pathName === 'otherRequests') {
                        let otherRequestId = this.props.location.state.data.id;
                        this.callRequestById(otherRequestId, 'GetOtherRequestsbyID');
                    } else if (this.state.pathName === 'entity') {
                        let entityId = this.props.location.state.data.id;
                        this.callRequestById(entityId, 'GetEntitiesbyID');
                    } else if (this.state.pathName === 'contact') {
                        let contactId = this.props.location.state.data.id;
                        this.callRequestById(contactId, 'GetContactbyID');
                    } else if (this.state.pathName === 'lookUp') {
                        let lookupId = this.props.location.state.data.id;
                        this.calllookupRequestById(lookupId, 'GetLookupbyID');
                    } else if (this.state.pathName === 'mailTemplate') {
                        let mailTemplateId = this.props.location.state.data.id;
                        this.callRequestById(mailTemplateId, 'GetMailTemplatebyID');
                    } else if (this.state.pathName === 'role') {
                        let roleId = this.props.location.state.data.id;
                        this.callRequestById(roleId, 'GetRolebyID');
                    }
                    // organization structure


                    else if (this.state.pathName === 'OrganizationStructure') {

                        let orgRequestId = this.props.location.state.data.id;
                        this.callRequestById(orgRequestId, 'GetOrganizationStructurebyID');

                    }
                }
                break;
            case 'delete':
                return <DeleteRecordComponent data={this.state.data.rows[this.state.selectedIndex]} />
            case 'comment':
                if (this.props.location.state.data) {
                    return (
                        <CommentComponent
                            isArabic={this.state.isArabic}
                            update={this.updateComments}
                            comments={this.state.comments[this.state.selectedIndex]}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            id={this.props.location.state.data.id}
                            toggle={this.toggle}
                            location={this.props.location.state} />)
                }
                break;
            case "update_status":
                if (this.props.location.state.data) {
                    return (
                        <StatusUpdateComponent
                            id={this.props.location.state.data.id}
                            cureentStatus={this.props.location.state.data.status}
                            currentStatusReason={this.props.location.state.data.statusreason}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            toggle={this.toggle}
                            location={this.props.location.state}
                            onUpdateData={this.statusUpdateInfo}
                        />
                    );
                }
                break;

            case 'assigntosector':

                debugger;
                if (this.props.location.state.data) {
                    return (
                        <AssignToSector
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case 'assignbacktoentity':
                if (this.props.location.state.data) {
                    return (
                        <AssignBackToEntity
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case 'assigntoentity':
                if (this.props.location.state.data) {
                    return (
                        <AssignBackToEntity
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case "assigntostrategicoffice":
                if (this.props.location.state.data) {
                    return (
                        <AssignToGm
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case 'assigntogmoffice':
                if (this.props.location.state.data) {
                    return (
                        <AssignToGm
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case "assigntodep":
                if (this.props.location.state.data) {

                    return (
                        <AssignToComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            assigneddepartmentid={this.props.location.state.data.assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntodep'
                        //departmentArray={this.state.departmentArray}
                        />
                    );

                }
                break;
            case "backtomanager":
                if (this.props.location.state.data) {
                    return (
                        <AssignToComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            assigneddepartmentid={this.props.location.state.data.assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='backtomanager'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                }
                break;
            case "assigntospecificdep":
                if (this.props.location.state.data) {
                    return (
                        <AssignToComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            assigneddepartmentid={this.props.location.state.data.assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntospecificdep'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                }
                break;
            case "assigntodatadep":
                if (this.props.location.state.data) {
                    return (
                        <AssignToComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            assigneddepartmentid={this.props.location.state.data.assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntodatadep'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                }
                break;
            case "assigntospecificemp":
                if (this.props.location.state.data) {
                    return (
                        <AssignToComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            assignedemployeeid={this.props.location.state.data.assignedemployeeid}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntospecificemp'
                        //employeeArray={this.state.employeeArray}
                        />
                    );
                }
                break;
            case "assigntoemp":
                if (this.props.location.state.data) {
                    return (
                        <AssignToComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            assignedemployeeid={this.props.location.state.data.assignedemployeeid}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            isviewdetails={this.state.isviewdetails}
                            //  MGM={this.MGM}
                            title='assigntoemp'
                        //employeeArray={this.state.employeeArray}
                        />
                    );
                }
                break;
            // organization structure
            case "opencreationstage":
                if (this.props.location.state.data) {
                    return (
                        <OpenCreationStageComponent
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            assignedemployeeid={this.props.location.state.data.assignedemployeeid}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='opencreationstage'

                        />
                    );
                }
                break;
            case "organizationstructurecompleted":
                if (this.props.location.state.data) {
                    return (
                        <OrganizationStructureCompleted
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            assignedemployeeid={this.props.location.state.data.assignedemployeeid}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.props.location.state.data}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='organizationstructuresompleted'

                        />
                    );
                }
                break;
            case "addStudy":


                if (this.props.location.state.data) {
                    return (
                        <AddStudy
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='addStudy'
                        />
                    );
                }
                break;
            case "addReply":
                if (this.props.location.state.data) {
                    return (
                        <AddStudy
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='addReply'
                        />
                    );
                }
                break;
            case "CloseRequest":
                if (this.props.location.state.data) {
                    return (
                        <AddStudy
                            id={this.props.location.state.data.id}
                            currentStatusId={this.props.location.state.data.statusid}
                            currentStatusReasonId={this.props.location.state.data.statusreasonid}
                            currentRequestNumber={this.props.location.state.data.Requestnumber}
                            requesttypeid={this.props.location.state.data.requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='CloseRequest'
                        />
                    );
                }
                break;

            case "follow":
                return (
                    <FollowComponent
                        // followData={this.props.location.state.data.history}
                        id={this.props.location.state.data.id}
                        requesttypeid={this.props.location.state.data.requesttypeid}
                        isArabic={this.state.isArabic}
                        location={this.props.location.state}
                        toggle={this.toggle}
                    />
                );
           
                case 'ExtendDate':
                return <ExtendDateComponent
                // data={this.state.data.rows[this.state.selectedIndex]}
                    isArabic={this.state.isArabic}
                    id={this.props.location.state.data.id}
                    requesttypeid={this.props.location.state.data.requesttypeid}
                    location={this.props.location.state}
                    toggle={this.toggle}
                />
            case 'ExtendDateReplay':
                return <ExtendDateReplayComponent 
                // data={this.state.data.rows[this.state.selectedIndex]}
                isArabic={this.state.isArabic}
                id={this.props.location.state.data.id}
                requesttypeid={this.props.location.state.data.requesttypeid}
                location={this.props.location.state}
                toggle={this.toggle}
                />

                default:
                return '';
        }
    }

    render() {

        let
            sideBarData = [],
            pathName = '';


        debugger;
        if (this.props.location.state) {
            propsData = this.props.location.state.data;
            requesttypeid = this.props.location.state.data.requesttypeid

        }

        if (!this.props.location.state) {
            pathName = localStorage.getItem('currentPath');
        } else if (this.props.location.state) {

            if (requesttypeid) {
                if (requesttypeid === 10969) {
                    pathName = "StrategicPlanningforworkforceEnquire";
                } 
                if (requesttypeid === Config.getSciRequestTypeId() || requesttypeid === Config.getSCILEgalSerbviceId() || requesttypeid === Config.getSCIComplainId() || requesttypeid === Config.getSCILEgalSuggestionId() || requesttypeid === Config.getSCIGeneralId()) {
                    pathName = "SCIRequest";
                } else if (requesttypeid === Config.getInformationRequestTypeId()) {
                    pathName = "informationRequest";
                } else if (requesttypeid === Config.getBonusRequestTypeId()) {
                    pathName = "bonusRequest";
                } else if (requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                    pathName = "amendSalaryScreen";
                } else if (requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                    pathName = "amendEmployeeSalary";
                } else if (requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                    pathName = "reviewHRPolicies";
                } else if (requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                    pathName = "exceptionADHousing";
                } else if (requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                    pathName = "secondmentForFederalAgency";
                } else if (requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                    pathName = "exemptionFromAccompanyPatient";
                }
                else if (requesttypeid === Config.getPromotionTypeId()) {
                    pathName = "Promotion";
                }
                else if (requesttypeid === Config.getSickLeaveTypeId()) {
                    pathName = "SickLeave";
                }
                else if (requesttypeid === Config.getRetirmentRequestTypeId()) {
                    pathName = "exceptionOfSteerTheAgeOfRetirementRequest";
                } else if (requesttypeid === Config.getExtendCreateObjectiveId()) {
                    pathName = 'extendCreateObjectives';
                } else if (requesttypeid === Config.getExtendReviewObjectiveId()) {
                    pathName = 'extendReviewObjectives';
                } else if (requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                    pathName = 'extendAnnualPerformance';
                } else if (requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                    pathName = 'addOrEditEmployeesPerformanceEvaluation';
                } else if (requesttypeid === Config.getJoinAndImplementId()) {
                    pathName = 'joinAndImplementGovResourcesSystemForNewEntity';
                } else if (requesttypeid === Config.getOpenComplainsStageId()) {
                    pathName = 'openComplainsStage';
                } else if (requesttypeid === Config.getAmendOnSharedGovermentId()) {
                    pathName = 'amendsSharedGovernmentHRManagement';
                } else if (requesttypeid === Config.getReportAndStaticId()) {
                    pathName = 'reportsAndStaticRequest';
                } else if (requesttypeid === Config.getOtherRequest()) {
                    pathName = 'otherRequests';
                }
                // organization structure
                else if (requesttypeid === Config.getORGRequestTypeId()) {
                    pathName = 'OrganizationStructure';
                }
            }
        }
        
        sideBarData = Config.getServiceSideBar(pathName, this.state.isArabic, this, 0);

        return (

            <MDBContainer
                fluid
                style={{ height: "100vh", backgroundColor: "#fffffff" }}>

                <LoadingOverlay active={this.state.isLoading} spinner={true}>

                    <Header
                        goToHelpPage={this.onClickHelpPage}
                        toggleSideBarSize={this.toggleSideBarSize}
                        logout={this.logout}
                        toggleLanguage={this.toggleLanguage}
                        isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic}
                    />


                    <MDBRow
                        style={{
                            flexDirection: this.state.isArabic ? "row" : "row-reverse",
                            backgroundColor: "#ffffff"
                        }}
                    >
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol
                                    xs="12" sm="12" md="12" lg="12" xl="12"
                                    style={{
                                        paddingLeft: "0",
                                        paddingRight: "0",
                                        backgroundColor: "#fffffff"
                                    }}
                                >
                                    <SubHeaderStrip
                                        goBack={() => {
                                            this.props.history.replace("/listpage", {
                                                pathname: pathName
                                            });
                                        }}
                                        isArabic={this.state.isArabic}
                                        title={
                                            i18n[this.state.isArabic ? "ar" : "en"].viewComponent.detail
                                        }
                                    />
                                </MDBCol>

                                <MDBCol md="12" sm="12" style={{ padding: '0px' }}>
                                    {this.state.isInternal == 1 && this.state.lastcationdata.ActionType ?
                                        <div class="LastActionVDdiv" style={{ direction: this.state.isArabic ? "rtl" : "ltr", textAlign: this.state.isArabic ? "right" : "left" }}>
                                            <h4>  {i18n[this.state.isArabic ? "ar" : "en"].viewDetailActions.lastaction}</h4>


                                            <MDBRow style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between', color: '#000', padding: '5px 15px' }}>
                                                <MDBCard style={{ width: "100%" }}>
                                                    <MDBCardBody style={{ display: 'flex' }}>
                                                        <MDBCol>
                                                            <MDBRow md='12' style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row' : 'row', width: '100%', textAlign: this.state.isArabic ? 'right' : 'left' }}>
                                                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3" style={{ flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? 'rtl' : 'ltr', textAlign: this.state.isArabic ? "right" : "left" }}>
                                                                    <lable>{i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.Employee + ' : '}</lable><lable>{this.state.lastcationdata.EmployeeName}</lable>
                                                                </MDBCol>
                                                                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3" style={{ flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? 'rtl' : 'ltr', textAlign: this.state.isArabic ? "right" : "left" }}>

                                                                    <lable>{i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.ActionType + ' : '}</lable><lable> {this.state.lastcationdata.ActionType} </lable>
                                                                </MDBCol>

                                                                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ flexDirection: this.state.isArabic ? 'row-reverse' : 'row', direction: this.state.isArabic ? 'rtl' : 'ltr', textAlign: this.state.isArabic ? "right" : "left" }}>
                                                                    {this.state.lastcationdata.ShortComment
                                                                        ?
                                                                        <div>
                                                                            {this.state.showhidecomment == 0 ?



                                                                                <div>
                                                                                    <label id="shortcomment" style={{ direction: this.state.isArabic ? "rtl" : "ltr" }}>{i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.Comment + ' : ' + this.state.lastcationdata.ShortComment}</label>
                                                                                    <div style={{ textAlign: "center" }} >
                                                                                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.showhidecomment}>
                                                                                            {i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.ViewDetails}
                                                                                        </MDBBtn>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <label id="fullcomment" style={{ direction: this.state.isArabic ? "rtl" : "ltr" }}>{i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.Comment + ' : ' + this.state.lastcationdata.Comment}</label>
                                                                                    <div style={{ textAlign: "center" }} >
                                                                                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.showhidecomment}>
                                                                                            {i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.ViewLess}
                                                                                        </MDBBtn>
                                                                                    </div>
                                                                                </div>

                                                                            }

                                                                        </div>
                                                                        :
                                                                        <label style={{ direction: this.state.isArabic ? "rtl" : "ltr" }}>{i18n[this.state.isArabic ? 'ar' : 'en'].viewDetailActions.Comment + ' : ' + this.state.lastcationdata.ShortComment}</label>

                                                                    }


                                                                </MDBCol>
                                                            </MDBRow>


                                                        </MDBCol>
                                                    </MDBCardBody>
                                                </MDBCard>
                                            </MDBRow>
                                        </div>
                                        : ''}
                                </MDBCol>


                            </MDBRow>



                            <MDBRow

                                className="outerContainer"
                                style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', }}
                            >
                                { }
                                {
                                    this.en_keys.map((item, index) => {
                                        if (
                                            item.toLowerCase() !== "detail" &&
                                            item.toLowerCase() !== "history" &&
                                            item.toLowerCase() !== "statushistory" &&
                                            item.toLowerCase() !== "action" &&
                                            item.toLowerCase() !== "reason" &&
                                            item.toLowerCase() !== "no" &&
                                            item.toLowerCase() !== "statusid" &&
                                            item.toLowerCase() !== "statusreasonid" &&
                                            item.toLowerCase() !== "requesttypeid" &&
                                            item.toLowerCase() !== "assigeddepartmentid" &&
                                            item.toLowerCase() !== "assigedemployeeid" &&
                                            item.toLowerCase() !== "attachments" &&
                                            item.toLowerCase() !== "comments" &&
                                            item.toLowerCase() !== "id" &&
                                            item.toLowerCase() !== "entityid" &&
                                            item.toLowerCase() !== "contactid" &&
                                            item.toLowerCase() !== "studymakerid" &&
                                            item.toLowerCase() !== "entitynameen" &&
                                            item.toLowerCase() !== "entitynamear" &&
                                            item.toLowerCase() !== "contactnameen" &&
                                            item.toLowerCase() !== "contactnamear" &&
                                            item.toLowerCase() !== "createdon" &&
                                            item.toLowerCase() !== "createdby" &&
                                            item.toLowerCase() !== "modifiedon" &&
                                            item.toLowerCase() !== "modifiedby" &&
                                            item.toLowerCase() !== "status" &&
                                            item.toLowerCase() !== "statusreason" &&
                                            item.toLowerCase() !== "statusreason" &&
                                            item.toLowerCase() !== "laststatusdate" &&
                                            item.toLowerCase() !== "totalemployeedetails" &&
                                            item.toLowerCase() !== "workyear" &&
                                            item.toLowerCase() !== "worksalarystatus" &&
                                            item.toLowerCase() !== "workmonth" &&
                                            item.toLowerCase() !== "workbasicsalary" &&
                                            item.toLowerCase() !== "worktotalsalary" &&
                                            item.toLowerCase() !== "worknoofdays"
                                        ) {
                                            if (this.state.loginData.EntityID !== 1) {
                                                if (item === "assigeddepartmentname") {
                                                    return "";
                                                }
                                                if (item === "assigedemployeename") {
                                                    return "";
                                                }
                                            }

                                            if (item === "Subject") {
                                                return "";
                                            }

                                            // ask pavan
                                            if ((this.state.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId() || this.state.pathName === "exemptionFromAccompanyPatient" || this.state.pathName === "Promotion") && (item === "employeenumber" || item === "requesttype")) {
                                                return null;
                                            }

                                            if (
                                                (this.state.requesttypeid === Config.getInformationRequestTypeId() || this.state.pathName === "informationRequest") &&
                                                (item === "assigeddepartmentname" ||
                                                    item === "requesttype")
                                            ) {
                                                return null;
                                            }

                                            let flag = false;
                                            for (let i = 0; i < this.respKeys.length; i++) {
                                                if (
                                                    this.respKeys[i].toLowerCase() ===
                                                    item.toLowerCase()
                                                ) {
                                                    if (this.state.requesttypeid || this.state.pathName) {
                                                    
                                                        debugger;
                                                        switch (this.state.requesttypeid || this.state.pathName) {
                                                            case 10960:
                                                                if (this.respKeys[i].toLowerCase() == "details" || this.respKeys[i].toLowerCase() == "EntityLegalOpinon".toLocaleLowerCase()) {

                                                                    return null;
                                                                }
                                                                break;

                                                            case 401:
                                                                if (this.respKeys[i].toLowerCase() == "details" || this.respKeys[i].toLowerCase() == "EntityLegalOpinon".toLocaleLowerCase()) {

                                                                    return null;
                                                                }
                                                                break;
                                                            case 10969 || "StrategicPlanningforworkforceEnquire":
                                                                {

                                                                    if (this.respKeys[i].toLowerCase() == "details" || this.respKeys[i].toLowerCase() == "EntityLegalOpinon".toLocaleLowerCase()) {

                                                                        return null;
                                                                    }
                                                                } break;
                                                            case 4 || 401 || 10960 ||  "SCIRequest":
                                                                {

                                                                    if (this.respKeys[i].toLowerCase() == "details" || this.respKeys[i].toLowerCase() == "EntityLegalOpinon".toLocaleLowerCase()) {

                                                                        return null;
                                                                    }
                                                                }
                                                                break;

                                                            case 11 || "secondmentForFederalAgency":
                                                                if (this.respKeys[i].toLowerCase() == "familynumber" || this.respKeys[i].toLowerCase() == "reasonforgovpaysalary") {

                                                                    return null;
                                                                }
                                                                break;

                                                            case 2 || "amendSalaryScreen":
                                                                if (
                                                                    this.respKeys[i].toLowerCase() == "currentscalechallenge" ||
                                                                    this.respKeys[i].toLowerCase() == "proposedscalechallenge" ||
                                                                    this.respKeys[i].toLowerCase() == "currentapplyeffect" ||
                                                                    this.respKeys[i].toLowerCase() == "proposedapplyeffect" ||
                                                                    this.respKeys[i].toLowerCase() == "justification" ||
                                                                    this.respKeys[i].toLowerCase() == "principle" ||
                                                                    this.respKeys[i].toLowerCase() == "proposedannualbenefits" ||
                                                                    this.respKeys[i].toLowerCase() == "currentannualbenefits" ||
                                                                    this.respKeys[i].toLowerCase() == "currentbasicsalary" ||
                                                                    this.respKeys[i].toLowerCase() == "proposedbasicsalary"
                                                                ) {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 8 || "exemptionFromAccompanyPatient":
                                                                {
                                                                    let isrequriedtopaybybudget = 0;
                                                                    for (let i = 0; i < this.respKeys.length; i++) {
                                                                        if (this.respKeys[i] == "isrequriedtopaybybudget") {
                                                                            isrequriedtopaybybudget = this.props.location.state.data[this.respKeys[i]];

                                                                        }
                                                                    }
                                                                    if (this.respKeys[i] == "budgetyear" && isrequriedtopaybybudget == 0 || this.respKeys[i] == "budgetvalue" && isrequriedtopaybybudget == 0) {
                                                                        return null;
                                                                    }
                                                                    if (
                                                                        this.respKeys[i].toLowerCase() == "justification" ||
                                                                        this.respKeys[i].toLowerCase() == "howtocalculaterule" ||
                                                                        this.respKeys[i].toLowerCase() == "basicsalary" ||
                                                                        this.respKeys[i].toLowerCase() == "totalsalary" ||
                                                                        this.respKeys[i].toLowerCase() == "violationdetail"
                                                                    ) {
                                                                        return null;
                                                                    }
                                                                    break;
                                                                }

                                                            case 1 || "bonusRequest":
                                                                if (
                                                                    this.respKeys[i].toLowerCase() == "yearachievment" ||
                                                                    this.respKeys[i].toLowerCase() == "bonusreason" ||
                                                                    this.respKeys[i].toLowerCase() == "equation"
                                                                ) {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 7 || "informationRequest":
                                                                if (this.respKeys[i].toLowerCase() == "statement" || this.respKeys[i].toLowerCase() == "informationrequired") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 165 || "exceptionOfSteerTheAgeOfRetirementRequest":
                                                                if (this.respKeys[i].toLowerCase() == "reasonforextending") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 10 || "exceptionADHousing":
                                                                if (this.respKeys[i].toLowerCase() == "requestreason" || this.respKeys[i].toLowerCase() == "entityName") {
                                                                    return null;
                                                                }
                                                                break;
                                                            case 10926 || "Promotion":
                                                                if (this.respKeys[i].toLowerCase() == "requestreason" ||
                                                                    this.respKeys[i].toLowerCase() == "justification") {
                                                                    return null;
                                                                }
                                                                break;
                                                            case 10925 || "SickLeave":
                                                                {
                                                                    let isrequriedtopaybybudget = 0;
                                                                    for (let i = 0; i < this.respKeys.length; i++) {
                                                                        if (this.respKeys[i] == "isrequriedtopaybybudget") {
                                                                            isrequriedtopaybybudget = this.props.location.state.data[this.respKeys[i]];

                                                                        }
                                                                    }
                                                                    if (this.respKeys[i] == "budgetyear" && isrequriedtopaybybudget == 0 || this.respKeys[i] == "budgetvalue" && isrequriedtopaybybudget == 0) {
                                                                        return null;
                                                                    }
                                                                    if (
                                                                        this.respKeys[i].toLowerCase() == "justification" ||
                                                                        this.respKeys[i].toLowerCase() == "howtocalculaterule" ||
                                                                        this.respKeys[i].toLowerCase() == "basicsalary" ||
                                                                        this.respKeys[i].toLowerCase() == "totalsalary" ||
                                                                        this.respKeys[i].toLowerCase() == "violationdetail"
                                                                    ) {
                                                                        return null;
                                                                    }
                                                                    break;
                                                                }

                                                            case 3 || "amendEmployeeSalary":
                                                                if (
                                                                    this.respKeys[i].toLowerCase() == "reasontomodify" ||
                                                                    this.respKeys[i].toLowerCase() == "baseofincrease"
                                                                ) {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 9 || "reviewHRPolicies":
                                                                if (this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 827:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 828:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 829:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 830:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 831:
                                                                if (
                                                                    this.respKeys[i].toLowerCase() == "requestdetails" ||
                                                                    this.respKeys[i].toLowerCase() == "systemtype" ||
                                                                    this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 832:
                                                                if (
                                                                    this.respKeys[i].toLowerCase() == "requestdetails" ||
                                                                    this.respKeys[i].toLowerCase() == "systemtype" ||
                                                                    this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 833:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 834:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            case 835:
                                                                if (this.respKeys[i].toLowerCase() == "requestdetails" || this.respKeys[i].toLowerCase() == "requestreason") {
                                                                    return null;
                                                                }
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                    }
                                                    flag = true;
                                                    break;
                                                }
                                            }
                                            if (!flag) {
                                                return "";
                                            }

                                            return (


                                                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" className='viewContainerClass'>

                                                    {


                                                        //this.state.requesttypeid == 950 || this.state.pathName == "reviewHRPolicies10" ?
                                                        this.state.requesttypeid == 9 || this.state.pathName == "reviewHRPolicies" ?
                                                            <div
                                                                style={{ color: '#847373', fontWeight: 400, display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row' }}>
                                                                <div
                                                                    // className="aaa"
                                                                    style={{
                                                                        marginLeft: this.state.isArabic ? 10 : 0,
                                                                        marginRight: this.state.isArabic ? 0 : 10,
                                                                        direction: this.state.isArabic ? "rtl" : "ltr",
                                                                        textAlign: this.state.isArabic ? "rtl" : "ltr"
                                                                    }}
                                                                >
                                                                    {this.state.isArabic
                                                                        ? this.arKeys[this.en_keys[index]]
                                                                        : this.enKeys[this.en_keys[index]]}{" : "}
                                                                </div>

                                                                <div
                                                                    // className="afsefsefe"
                                                                    style={{
                                                                        textAlign: this.state.isArabic
                                                                            ? "right"
                                                                            : "left",
                                                                        direction: this.state.isArabic ? "rtl" : "ltr"
                                                                    }}
                                                                >
                                                                    {propsData[this.en_keys[index]]}
                                                                    {

                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div
                                                                className="grid-item"
                                                                style={{

                                                                    display: "flex",
                                                                    flexDirection: this.state.isArabic
                                                                        ? "row-reverse"
                                                                        : "row",
                                                                    borderBottom: "1px solid rgb(221, 221, 221)"
                                                                }}>

                                                                <div
                                                                    className="grid-key"
                                                                    style={{
                                                                        textAlign: this.state.isArabic ? "right" : "left",
                                                                        marginLeft: this.state.isArabic ? 10 : 0,
                                                                        marginRight: this.state.isArabic ? 0 : 10,
                                                                        direction: this.state.isArabic ? "rtl" : "ltr",

                                                                    }}>
                                                                    {
                                                                        this.state.isArabic
                                                                            ?
                                                                            this.arKeys[this.en_keys[index]] === 'إلى تاريخ' ?
                                                                                this.state.requesttypeid == 829 ?
                                                                                    i18n[this.getLocale()].viewDetails.extendCreatingObjectivesIntervalToDate
                                                                                    :
                                                                                    this.state.requesttypeid == 830 ?
                                                                                        i18n[this.getLocale()].viewDetails.extendReviewingObjectivesIntervalToDate
                                                                                        :
                                                                                        this.state.requesttypeid == 828 ?
                                                                                            i18n[this.getLocale()].viewDetails.extendAnnualPerformanceEvaluationIntervalToDate
                                                                                            :
                                                                                            this.arKeys[this.en_keys[index]]
                                                                                :
                                                                                this.arKeys[this.en_keys[index]] === 'من تاريخ' ?
                                                                                    this.state.requesttypeid == 829 ?
                                                                                        i18n[this.getLocale()].viewDetails.extendCreatingObjectivesIntervalFromDate
                                                                                        :
                                                                                        this.state.requesttypeid == 830 ?
                                                                                            i18n[this.getLocale()].viewDetails.extendReviewingObjectivesIntervalFromDate
                                                                                            :
                                                                                            this.state.requesttypeid == 828 ?
                                                                                                i18n[this.getLocale()].viewDetails.extendAnnualPerformanceEvaluationIntervalFromDate
                                                                                                :
                                                                                                null :

                                                                                    this.arKeys[this.en_keys[index]]
                                                                            :
                                                                            this.enKeys[this.en_keys[index]] === 'To Date' ?
                                                                                this.state.requesttypeid == 829 ?
                                                                                    i18n[this.getLocale()].viewDetails.extendCreatingObjectivesIntervalToDate
                                                                                    :
                                                                                    this.state.requesttypeid == 830 ?
                                                                                        i18n[this.getLocale()].viewDetails.extendReviewingObjectivesIntervalToDate
                                                                                        :
                                                                                        this.state.requesttypeid == 828 ?
                                                                                            i18n[this.getLocale()].viewDetails.extendAnnualPerformanceEvaluationIntervalToDate
                                                                                            :
                                                                                            this.enKeys[this.en_keys[index]]
                                                                                :
                                                                                this.enKeys[this.en_keys[index]] === 'From Date' ?
                                                                                    this.state.requesttypeid == 829 ?
                                                                                        i18n[this.getLocale()].viewDetails.extendCreatingObjectivesIntervalFromDate
                                                                                        :
                                                                                        this.state.requesttypeid == 830 ?
                                                                                            i18n[this.getLocale()].viewDetails.extendReviewingObjectivesIntervalFromDate
                                                                                            :
                                                                                            this.state.requesttypeid == 828 ?
                                                                                                i18n[this.getLocale()].viewDetails.extendAnnualPerformanceEvaluationIntervalFromDate
                                                                                                :
                                                                                                this.state.requesttypeid == 7 ?
                                                                                                i18n[this.getLocale()].datefrominfo
                                                                                                :
                                                                                                this.enKeys[this.en_keys[index]]
                                                                                    :
                                                                                    this.enKeys[this.en_keys[index]]
                                                                    }{" "}
                                                                    :
                                                            </div>

                                                                <div
                                                                    className="grid-value"
                                                                    style={{
                                                                        textAlign: this.state.isArabic
                                                                            ? "right"
                                                                            : "left",
                                                                        direction: this.state.isArabic ? "rtl" : "ltr"
                                                                    }}>
                                                                    {/* {propsData[this.en_keys[index]]} */}

                                                                    {

                                                                        this.enKeys[this.en_keys[index]] == 'Is Budgeted' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Funding ID' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Is Allocated' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Is Contracted' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Violation in administrative procedures' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Budget Availability' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Required To Pay Additional Budget' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Copy Of The Previous Approvals' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Employee Agreement' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Pass Probation Period' ||
                                                                            this.enKeys[this.en_keys[index]] == 'Last Year Performance Degree' ?
                                                                            propsData[this.en_keys[index]] == 1
                                                                                ?
                                                                                i18n[this.getLocale()].yes
                                                                                :
                                                                                i18n[this.getLocale()].no
                                                                            :
                                                                            this.enKeys[this.en_keys[index]] == 'The Entity Which Responsible For Salary' ?
                                                                                propsData[this.en_keys[index]] == 1
                                                                                    ?
                                                                                    i18n[this.getLocale()].secondement_For_Federal_Agency_Step.goverment
                                                                                    :
                                                                                    i18n[this.getLocale()].secondement_For_Federal_Agency_Step.competent
                                                                                :
                                                                                this.enKeys[this.en_keys[index]] === 'Degree of Relationship' ?
                                                                                    this.getLocale() == 'ar' ?

                                                                                        this.state.DegreeofrelationshipArray[propsData[this.en_keys[index]] - 1].ArabicTitle :
                                                                                        this.state.DegreeofrelationshipArray[propsData[this.en_keys[index]] - 1].EnglishTitle :
                                                                                    propsData[this.en_keys[index]] ?
                                                                                        propsData[this.en_keys[index]] :
                                                                                        this.enKeys[this.en_keys[index]] == 'RequestType' ? propsData[this.en_keys[index]] == '1'
                                                                                            ? i18n[this.getLocale()].Promotion.promotion : i18n[this.getLocale()].Promotion.hiring
                                                                                            :
                                                                                            i18n[this.getLocale()].NotAvibalelbl
                                                                        //this.enKeys[this.en_keys[index]]==='Degree of Relationship' ?this.state.DegreeofrelationshipArray[propsData[this.en_keys[index]]-1]:this.state.DegreeofrelationshipArray[propsData[this.en_keys[index]]-1]
                                                                    }
                                                                </div>

                                                            </div>
                                                    }
                                                </MDBCol>
                                            );
                                        }
                                    })}
                                {/* </MDBCol> */}

                                {
                                    (this.state.requesttypeid ===
                                        Config.getExceptionAcompanypatientRequestTypeId() || this.state.pathName === "exemptionFromAccompanyPatient" || this.state.requesttypeid ===
                                        Config.getSickLeaveTypeId() || this.state.pathName === "SickLeave") ? (
                                            <MDBRow style={{ width: '100%' }}>

                                                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                                    {
                                                        propsData.salarymatrix ? (
                                                            <div style={{ paddingLeft: '15px' }}>
                                                                <p
                                                                    className="viewHeading"
                                                                    style={{
                                                                        textAlign: this.state.isArabic ? 'right' : 'left'
                                                                    }}
                                                                >
                                                                    {i18n[this.getLocale()].ExemptionFromAccompanyPatient.irregularitiesTable}
                                                                </p>
                                                                <MDBTable
                                                                    bordered
                                                                    style={{
                                                                        direction: this.state.isArabic ? "rtl" : "ltr"
                                                                    }}
                                                                >
                                                                    <MDBTableBody>
                                                                        <tr>
                                                                            <td width="15%">
                                                                                <div
                                                                                    className="tableHeading"
                                                                                    style={{
                                                                                        textAlign: this.state.isArabic ? "right" : ""
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        i18n[this.state.isArabic ? "ar" : "en"]
                                                                                            .ExemptionFromAccompanyPatient.employeeSalaryInformation
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <MDBTable
                                                                                    bordered
                                                                                    style={{
                                                                                        direction: this.state.isArabic ? "rtl" : "ltr"
                                                                                    }}
                                                                                >
                                                                                    <MDBTableBody>
                                                                                        <tr>
                                                                                            <td align="center">
                                                                                                <b>
                                                                                                    <lable>
                                                                                                        {
                                                                                                            i18n[
                                                                                                                this.state.isArabic ? "ar" : "en"
                                                                                                            ].ExemptionFromAccompanyPatient.basicSalaryTb
                                                                                                        }
                                                                                                    </lable>
                                                                                                </b>
                                                                                                <div align="center">
                                                                                                    {propsData.basicsalary ? propsData.basicsalary : ''}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td align="center">
                                                                                                <b>
                                                                                                    {" "}
                                                                                                    <lable>
                                                                                                        {
                                                                                                            i18n[
                                                                                                                this.state.isArabic ? "ar" : "en"
                                                                                                            ].ExemptionFromAccompanyPatient.totalSalaryWithoutSubscription
                                                                                                        }
                                                                                                    </lable>
                                                                                                </b>
                                                                                                <div align="center">
                                                                                                    {
                                                                                                        propsData.totalsalarywithsubscription ? propsData.totalsalarywithsubscription : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                            <td align="center">
                                                                                                <b>
                                                                                                    {" "}
                                                                                                    <lable>
                                                                                                        {
                                                                                                            i18n[
                                                                                                                this.state.isArabic ? "ar" : "en"
                                                                                                            ].ExemptionFromAccompanyPatient.retirementSalary
                                                                                                        }
                                                                                                    </lable>
                                                                                                </b>
                                                                                                <div className="form-group">
                                                                                                    <div align="center">
                                                                                                        {propsData.retirement ? propsData.retirement : ''}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td align="center">
                                                                                                <b>
                                                                                                    <lable>
                                                                                                        {
                                                                                                            i18n[
                                                                                                                this.state.isArabic ? "ar" : "en"
                                                                                                            ].ExemptionFromAccompanyPatient.total
                                                                                                        }
                                                                                                    </lable>
                                                                                                </b>
                                                                                                <div align="center">
                                                                                                    {

                                                                                                        propsData.totalsalarywithsubscription ? parseFloat(propsData.totalsalarywithsubscription.replace(',', '')) + parseFloat(propsData.retirement.replace(',', '')) : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </MDBTableBody>
                                                                                </MDBTable>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    className="tableHeading"
                                                                                    style={{
                                                                                        textAlign: this.state.isArabic
                                                                                            ? "right"
                                                                                            : "",
                                                                                        width: "100%"
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        i18n[this.state.isArabic ? "ar" : "en"]
                                                                                            .ExemptionFromAccompanyPatient.intervals
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <MDBTable
                                                                                    bordered
                                                                                    style={{
                                                                                        direction: this.state.isArabic ? "rtl" : "ltr"
                                                                                    }}
                                                                                >
                                                                                    <MDBTableBody>
                                                                                        <tr>
                                                                                            <th />
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.year
                                                                                                }
                                                                                            </th>
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.month
                                                                                                }
                                                                                            </th>
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.from
                                                                                                }
                                                                                            </th>
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.to
                                                                                                }
                                                                                            </th>
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.salaryStatus
                                                                                                }
                                                                                            </th>
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.subTotal
                                                                                                }
                                                                                            </th>
                                                                                            <th
                                                                                                style={{
                                                                                                    textAlign: this.state.isArabic ? "right" : ""
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    i18n[
                                                                                                        this.state.isArabic ? "ar" : "en"
                                                                                                    ].ExemptionFromAccompanyPatient.noOfDays
                                                                                                }
                                                                                            </th>
                                                                                        </tr>
                                                                                        {propsData.salarymatrix
                                                                                            ? propsData.salarymatrix.map(
                                                                                                (item, i) => (
                                                                                                    <ViewDetailPatientItem
                                                                                                        isArabic={this.state.isArabic}
                                                                                                        key={i}
                                                                                                        i={i}
                                                                                                        item={item}
                                                                                                        index={i}
                                                                                                    />
                                                                                                )
                                                                                            )
                                                                                            : null}

                                                                                        <tr>
                                                                                            <td style={{ border: "none" }}> </td>
                                                                                            <td style={{ border: "none" }}> </td>
                                                                                            <td style={{ border: "none" }}> </td>
                                                                                            <td style={{ border: "none" }}> </td>
                                                                                            <td style={{ border: "none" }}> </td>
                                                                                            <td style={{ border: "none" }}> </td>
                                                                                            <td>
                                                                                                <div className="form-group">
                                                                                                    {propsData.totalamount}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-group">
                                                                                                    {propsData.totaldays}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </MDBTableBody>
                                                                                </MDBTable>
                                                                            </td>
                                                                        </tr>
                                                                    </MDBTableBody>
                                                                </MDBTable>
                                                            </div>
                                                        ) : null}
                                                </MDBCol>
                                            </MDBRow>
                                        ) : null
                                }
                            </MDBRow>





                            <div style={{ height: '10px' }} />

                            {
                                (this.state.requesttypeid === Config.getAmendSalaryScaleRequestTypeId() || this.state.pathName === "amendSalaryScreen")
                                    ?
                                    propsData.AmendSalaryScaleMatrix && propsData.AmendSalaryScaleMatrix.length > 0 ?

                                        <div className="patientTable">

                                            <MDBTable bordered style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}>

                                                <MDBTableBody>
                                                    <tr>
                                                        <td width="2%">&nbsp;</td>
                                                        <td
                                                            width="22%"
                                                            style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}> {i18n[this.state.isArabic ? 'ar' : 'en'].current_basic_salary}

                                                        </td>
                                                        <td width="22%">
                                                            <div className="form-group">
                                                                <div
                                                                    className="viewContainerAmendSalaryScale"
                                                                    style={{
                                                                        textAlign: this.state.isArabic ? 'right' : 'left'
                                                                    }}>
                                                                    {
                                                                        propsData.AmendSalaryScaleMatrix[0].currentmonthlybonus ? propsData.AmendSalaryScaleMatrix[0].currentmonthlybonus.map((item, index) => {
                                                                            return (
                                                                                <p>{item.value ? item.value : ''}</p>
                                                                            )
                                                                        })
                                                                            : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td width="22%"
                                                            style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].proposed_basic_salary}

                                                        </td>
                                                        <td width="22%">
                                                            <div className="form-group">
                                                                <div
                                                                    className="viewContainerAmendSalaryScale"
                                                                    style={{
                                                                        textAlign: this.state.isArabic ? 'right' : 'left'
                                                                    }}>
                                                                    {
                                                                        propsData.AmendSalaryScaleMatrix[0].proposedmonthlybonus ? propsData.AmendSalaryScaleMatrix[0].proposedmonthlybonus.map((item, index) => {
                                                                            return (
                                                                                <p>{item.value ? item.value : ''}</p>
                                                                            )
                                                                        })
                                                                            : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* Start Other Annual Bonuses */}
                                                    {propsData.AmendSalaryScaleMatrix[0].otherbonus && propsData.AmendSalaryScaleMatrix[0].otherbonus.length > 0 ? propsData.AmendSalaryScaleMatrix[0].otherbonus.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                                                                    {i18n[this.state.isArabic ? 'ar' : 'en'].other_bonus_name}
                                                                </td>

                                                                <td>
                                                                    <div
                                                                        className="viewContainerAmendSalaryScale"
                                                                        style={{
                                                                            textAlign: this.state.isArabic ? 'right' : 'left'
                                                                        }}>
                                                                        <p>{item.othercurrentmonthlybonus ? item.othercurrentmonthlybonus : ''}</p>
                                                                    </div>
                                                                </td>

                                                                <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].other_bonus_name}
                                                                </td>

                                                                <td>
                                                                    <div
                                                                        className="viewContainerAmendSalaryScale"
                                                                        style={{
                                                                            textAlign: this.state.isArabic ? 'right' : 'left'
                                                                        }}>
                                                                        <p>{item.otherproposedmonthlybonus ? item.otherproposedmonthlybonus : ''}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                        : null
                                                    }
                                                    {/* End Other Annual Bonuses */}
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].current_annual_benefits}

                                                        </td>
                                                        <td>
                                                            <div className="form-group">
                                                                <div
                                                                    className="viewContainerAmendSalaryScale"
                                                                    style={{
                                                                        textAlign: this.state.isArabic ? 'right' : 'left'
                                                                    }}>
                                                                    {
                                                                        propsData.AmendSalaryScaleMatrix[0].currentannualbenefits ? propsData.AmendSalaryScaleMatrix[0].currentannualbenefits.map((item, index) => {
                                                                            return (
                                                                                <p>{item.value ? item.value : ''}</p>
                                                                            )
                                                                        })
                                                                            : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        </td>

                                                        <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].proposed_annual_benefits}

                                                        </td>
                                                        <td>
                                                            <div className="form-group">
                                                                <div
                                                                    className="viewContainerAmendSalaryScale"
                                                                    style={{
                                                                        textAlign: this.state.isArabic ? 'right' : 'left'
                                                                    }}>
                                                                    {
                                                                        propsData.AmendSalaryScaleMatrix[0].proposedannualbenefits ? propsData.AmendSalaryScaleMatrix[0].proposedannualbenefits.map((item, index) => {
                                                                            return (
                                                                                <p>{item.value ? item.value : ''}</p>
                                                                            )
                                                                        })
                                                                            : ""
                                                                    }
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* Start Other Benefit Name */}
                                                    {propsData.AmendSalaryScaleMatrix[0].otherbenefits && propsData.AmendSalaryScaleMatrix[0].otherbenefits.length > 0 ? propsData.AmendSalaryScaleMatrix[0].otherbenefits.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                                                                    {i18n[this.state.isArabic ? 'ar' : 'en'].other_benefit_name}
                                                                </td>

                                                                <td>
                                                                    <div
                                                                        className="viewContainerAmendSalaryScale"
                                                                        style={{
                                                                            textAlign: this.state.isArabic ? 'right' : 'left'
                                                                        }}>
                                                                        <p>{item.othercurrentannualbenefits ? item.othercurrentannualbenefits : ''}</p>
                                                                    </div>
                                                                </td>

                                                                <td style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.state.isArabic ? 'ar' : 'en'].other_benefit_name}
                                                                </td>

                                                                <td>
                                                                    <div
                                                                        className="viewContainerAmendSalaryScale"
                                                                        style={{
                                                                            textAlign: this.state.isArabic ? 'right' : 'left'
                                                                        }}>
                                                                        <p>{item.otherproposedannualbenefits ? item.otherproposedannualbenefits : ''}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                        :
                                                        null
                                                    }
                                                    {/* End Other Benefit Name */}

                                                </MDBTableBody>
                                            </MDBTable>

                                        </div>
                                        :
                                        null
                                    : null
                            }
                            {
                                this.props.location.state.data.attachments ?
                                    <div style={{ direction: this.state.isArabic ? "rtl" : "ltr", textAlign: this.state.isArabic ? "right" : "left" }}>
                                        <h3>{i18n[this.getLocale()].viewComponent.attachments}</h3><br />
                                        <MDBRow>
                                            <MDBCol>
                                                {this.props.location.state.data.attachments.map((item, index) => {

                                                    return (

                                                        <div style={{ display: "inline", flex: "auto", float: this.state.isArabic ? "right" : "left", padding: "5px", marginLeft: "10px", marginRight: "10px" }}>



                                                            <img onClick={() => this.saveFile(item)} style={{
                                                                height: "auto", display: "inline-block", valign: "middle",
                                                                verticalAlign: "middle", width: "auto", maxWidth: "40px", maxHeight: "40px", marginLeft: '10px', cursor: "pointer"
                                                            }} alt="" src={require('./../assets/images/BlueDownloads.png')}
                                                                className="img-fluid" />


                                                            {'   ' + item.filename}






                                                        </div>

                                                    )
                                                })
                                                }</MDBCol>                                            </MDBRow>



                                    </div>
                                    : ''

                            }
                            <div style={{ height: '20px' }}>&nbsp;</div>

                            {
                                this.state.ckEditorArr &&
                                this.state.ckEditorArr.length > 0 &&
                                this.state.ckEditorArr.map((item, i) => {

                                    return (


                                        <div>


                                            <MDBCol md="12" sm="12" style={{ padding: '0px' }}>
                                                <div
                                                    className="grid-key"
                                                    style={{
                                                        marginLeft: this.state.isArabic ? 10 : 0,
                                                        marginRight: this.state.isArabic ? 0 : 10,
                                                        direction: this.state.isArabic ? "rtl" : "ltr",
                                                        paddingRight: this.state.isArabic ? 15 : 0,
                                                        textAlign: this.state.isArabic ? "right" : "left"
                                                    }}
                                                >
                                                    {this.state.isArabic
                                                        ? this.arKeys[item.key]
                                                        : this.enKeys[item.key]}
                                                </div>
                                            </MDBCol>
                                            <MDBCol md="12" sm="12" style={{ padding: '0px' }}>
                                                {
                                                    this.state.isArabic ?
                                                        null
                                                        :
                                                        <div className="viewCk">
                                                            <CKEditor
                                                                activeClass="viewCk"
                                                                content={item.value}
                                                                config={{ language: "en", height: 'auto', readOnly: true }}
                                                            />
                                                        </div>
                                                }
                                                {
                                                    this.state.isArabic ?
                                                        <div className="viewCk">
                                                            <CKEditor
                                                                activeClass="viewCk"
                                                                content={item.value}
                                                                config={{ language: "ar", height: 'auto', readOnly: true }}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }

                                                <hr />

                                            </MDBCol>

                                        </div>
                                    );
                                })}

                            <div style={{ height: "100px" }}></div>

                            <div class="ViewDeatilsActions" style={{ direction: this.state.isArabic ? "rtl" : "ltr", textAlign: this.state.isArabic ? "right" : "left" }}>

                                <ViewDetailsAction
                                    ActionData={this.props.location.state.ActionData}
                                    requesttypeid={this.state.requesttypeid}
                                    viewactionsdata={this.state.viewactionsdata}
                                   // routingdetails={this.state.routingdetails}
                                    manageAction={this.manageAction.bind(this)} Attachments={this.state.Attachments} />


                            </div>


                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            canViewSCI={this.state.canViewSCI}
                            canvieworgrequest={this.state.canvieworgrequest}
                            canvieworgrequesttree={this.state.canvieworgrequesttree}
                            canViewInformation={this.state.canViewInformation}
                            canviewbonusrequest={this.state.canviewbonusrequest}
                            canviewamendscalerequest={this.state.canviewamendscalerequest}
                            canviewamendsalaryrequest={this.state.canviewamendsalaryrequest}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            isArabic={this.state.isArabic}
                            items={sideBarData}
                        />
                    </MDBRow>
                    <MDBModal
                        className={this.state.isArabic ? 'comment_ar viewDetailModal' : 'viewDetailModal'}
                        isOpen={this.state.selectedAction === 'viewdetails' || this.state.selectedAction === 'edit' ? false : this.state.modal}
                        toggle={this.toggle}
                        size={

                            "fluid"

                        } position="bottom">

                        <MDBModalHeader
                            style={{
                                padding: '0.5rem 1rem',
                                flexDirection: this.state.isArabic ? 'row-reverse' : 'row',
                                textAlign: this.state.isArabic ? "right" : "left"
                            }}
                            toggle={this.toggle}>{this.getModalTitle()}
                        </MDBModalHeader>

                        <MDBModalBody>
                            {this.selectModalView()}
                        </MDBModalBody>
                    </MDBModal>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}