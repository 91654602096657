import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import Config from '../../utils/Config';

export default class LookupForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radio: 0,
            isNextScreenActive: false,
            selectedOptionForIsActive: '',
            selectedOptionForIsDefault: '',
            englishName: '',
            arabicName: '',
            isActive: '',
            isArabic: true,
            statusSelectedValue: '',
            statusArray: [],
            selectedOptionForIsActiveErr: "",
            selectedOptionForIsDefaultErr: ""
        }
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    componentDidMount = () => {

        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();        

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                englishName: editObj.englishtitle,
                arabicName: editObj.arabictitle,
                selectedOptionForIsActive: parseInt(editObj.status) === 0 ? "Yes" : "No",
                selectedOptionForIsDefault: parseInt(editObj.isdefault) === 1 ? "Yes" : "No",
                statusSelectedValue: editObj.parentid
            }, () => {
                Config.setIsEditFlag(false);
            })
        }

        let tempStatus = [];
        if (Config.getStatusData()) {
            tempStatus = Config.getStatusData();
        }
        this.setState({
            statusArray: tempStatus,
        });
    }

    handleChangeForStatus = (event) => {
        this.setState({ statusSelectedValue: event.target.value });
    };

    handleEnglishName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== "") {
            if (regex.test(event.target.value)) {
                this.setState({ englishName: event.target.value });
            }
        } else {
            this.setState({ englishName: "" });
        }
    };

    handleArabicName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== "") {
            if (regex.test(event.target.value)) {
                this.setState({ arabicName: event.target.value }, () => {
                   
                });
            }
        } else {
            this.setState({ arabicName: "" });
        }
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    submitHandler = (event) => {

        event.preventDefault();
        event.target.className += " was-validated";

        let lookUpObject = {};
        let selectedOptionForIsActiveErr = "",
            selectedOptionForIsDefaultErr = "";

        

        if (!this.state.selectedOptionForIsActive) {
            selectedOptionForIsActiveErr = "thisFieldIsRequired";
        }
        if (!this.state.selectedOptionForIsDefault) {
            selectedOptionForIsDefaultErr = "thisFieldIsRequired";
        }

        

        this.setState({
            selectedOptionForIsActiveErr: selectedOptionForIsActiveErr,
            selectedOptionForIsDefaultErr: selectedOptionForIsDefaultErr
        }, () => {
            if (
                !selectedOptionForIsActiveErr &&
                !selectedOptionForIsDefaultErr
            ) {
                if (
                    this.state.englishName &&
                    this.state.arabicName
                ) {
                    lookUpObject.englishtitle = this.state.englishName;
                    lookUpObject.arabictitle = this.state.arabicName;
                    lookUpObject.status = this.state.selectedOptionForIsActive;
                    lookUpObject.isdefault = this.state.selectedOptionForIsDefault;
                    lookUpObject.parentid = this.state.statusSelectedValue;
                    lookUpObject.filtertype = localStorage.getItem('lookupDropdownValue');

                    this.props.onSubmitData(lookUpObject);
                }
            }

        });

    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    // handleEnglishName = (event) => {
    //     let regex = /^[\u0621-\u064AA-Za-z0-9 \n]{0,50}$/;
    //     if (regex.test(event.target.value)) {
    //         this.setState({ englishName: event.target.value }, () => {
    
    //         });
    //     }
    // };

    // handleArabicName = (event) => {
    //     let regex = /^[\u0621-\u064AA-Za-z0-9 \n]{0,50}$/;
    //     if (regex.test(event.target.value)) {
    //         this.setState({ arabicName: event.target.value }, () => {
    
    //         });
    //     }
    // };

    handleOptionChangeForIsActive = (e) => {
        if (e.target.value) {
            this.setState({
                selectedOptionForIsActive: e.target.value,
                selectedOptionForIsActiveErr: ""
            });
        }
    };

    handleOptionChangeForIsDefault = (e) => {
        if (e.target.value) {
            this.setState({
                selectedOptionForIsDefault: e.target.value,
                selectedOptionForIsDefaultErr: ""
            });
        }
    };

    render() {

        return (

            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate >

                {
                    this.props.isStatusDropdown ?

                        <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                                <div className="form-group">

                                    <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>

                                        {i18n[this.getLocale()].status}
                                        <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>

                                    </label>

                                    <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForStatus}
                                        value={this.state.statusSelectedValue}
                                        required >

                                        <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.statusArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                            })
                                        }

                                    </select>

                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>

                                </div>

                            </MDBCol>

                        </MDBRow> :
                        null
                }

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">

                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                {i18n[this.getLocale()].englishName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>

                            </label>

                            <input
                                type="text"
                                className="form-control"
                                id="englishName"
                                name="englishName"
                                value={this.state.englishName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEnglishName}
                                required
                            />


                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>

                        </div>

                    </MDBCol>


                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">

                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                {i18n[this.getLocale()].arabicName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>

                            </label>

                            <input
                                type="text"
                                className="form-control"
                                id="arabicName"
                                name="arabicName"
                                value={this.state.arabicName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleArabicName}
                                required
                            />


                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>

                        </div>

                    </MDBCol>
                    
                </MDBRow>

                {/* <MDBRow>

                                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div className="form-group" style={{ textAlign: this.props.isArabic ? 'right' : 'left', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>

                                        <label style={{ width: '100%', display: 'flex', }} htmlFor="formGroupExampleInput">

                                            {i18n[this.getLocale()].isActive}
                                            <span className="requireStar">*</span></label>


                                        <div class="custom-control custom-radio custom-control-inline" >
                                            <input
                                                type="radio"
                                                value="yes"
                                                class="custom-control-input"
                                                id="one"
                                                checked={this.state.selectedOptionForIsActive === 'yes'}
                                                onChange={this.handleOptionChangeForIsActive}
                                                name="active"
                                                required />

                                            <label class="custom-control-label" for="one">
                                                {i18n[this.getLocale()].yes}
                                            </label>

                                        </div>


                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                value="no"
                                                class="custom-control-input"
                                                id="two"
                                                name="active"
                                                checked={this.state.selectedOptionForIsActive === 'no'}
                                                onChange={this.handleOptionChangeForIsActive}
                                                required />
                                            <label class="custom-control-label" for="two">
                                                {i18n[this.getLocale()].no}
                                            </label>

                                            <br />

                                            <div className="invalid-feedback" style={{ width: '100%', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                {"No"}
                                            </div>

                                        </div>

                                    </div>

                                </MDBCol>
                                
 */}


                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">

                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].isActive}<span className="requireStar">*</span></label>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">

                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOptionForIsActive === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidationMotherComp4"
                                    onChange={this.handleOptionChangeForIsActive}
                                    name="radio-stacked-2_1"
                                    required />

                                <label class="custom-control-label" for="customControlValidationMotherComp4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>

                            </div>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidationMotherComp5"
                                    name="radio-stacked-2_1"
                                    checked={this.state.selectedOptionForIsActive === 'No'}
                                    onChange={this.handleOptionChangeForIsActive}
                                    required />

                                <label class="custom-control-label" for="customControlValidationMotherComp5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>

                            </div>

                            <br />

                            {this.state.selectedOptionForIsActiveErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>

                    </MDBCol>

                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].adminPanelKeys.isDefault}<span className="requireStar">*</span></label>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOptionForIsDefault === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidation4"
                                    onChange={this.handleOptionChangeForIsDefault}
                                    name="radio-stacked-2"
                                    required />
                                <label class="custom-control-label" for="customControlValidation4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.selectedOptionForIsDefault === 'No'}
                                    onChange={this.handleOptionChangeForIsDefault}
                                    required />

                                <label class="custom-control-label" for="customControlValidation5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>

                            </div>

                            <br />

                            {this.state.selectedOptionForIsDefaultErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].submit}</MDBBtn>
                </MDBRow>

            </form >
        );
    }
}
