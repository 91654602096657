import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBRow, MDBCol } from "mdbreact";
import { getRequestByIdPayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import Config from './../../utils/Config';
import i18n from './../../i18n';
import LoadingOverlay from 'react-loading-overlay'

class FollowComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            followData: [],
            modalData: {}
        };
    }

    componentDidMount = () => {
        let pathName = localStorage.getItem('currentPath');
        if (pathName) {
            if (pathName === 'StrategicPlanningforworkforceEnquire') {
                this.setState({
                    requestType: Config.getSciRequestTypeId()
                }, () => {
                    this.callRequestById('GetStrategicPlanningforworkforceEnquirebyID');
                })
            }
         else   if (pathName === 'SCIRequest') {
                this.setState({
                    requestType: Config.getSciRequestTypeId()
                }, () => {
                    this.callRequestById('GetscirequestbyID');
                })
            } else if (pathName === 'informationRequest') {
                this.setState({
                    requestType: Config.getInformationRequestTypeId()
                }, () => {
                    this.callRequestById('GetinformationrequestbyID');
                })
            } else if (pathName === 'bonusRequest') {
                this.setState({
                    requestType: Config.getBonusRequestTypeId()
                }, () => {
                    this.callRequestById('GetbounsrequestbyID');
                })
            } else if (pathName === 'amendSalaryScreen') {
                this.setState({
                    requestType: Config.getAmendSalaryScaleRequestTypeId()
                }, () => {
                    this.callRequestById('GetamendsalaryscalebyID');
                })
            } else if (pathName === 'amendEmployeeSalary') {
                this.setState({
                    requestType: Config.getAmendEmployeeSalaryRequestTypeId()
                }, () => {
                    this.callRequestById('GetAmmendEmployeeSalarybyID');
                })
            } else if (pathName === 'reviewHRPolicies') {
                this.setState({
                    requestType: Config.getHrReviewPolicyRequestTypeId()
                }, () => {
                    this.callRequestById('GetHRPolicyReviewbyID');
                })
            } else if (pathName === 'exceptionADHousing') {
                this.setState({
                    requestType: Config.getExceptionADHousingRequestTypeId()
                }, () => {
                    this.callRequestById('GetHousingPolicybyID');
                })
            } else if (pathName === 'secondmentForFederalAgency') {
                this.setState({
                    requestType: Config.getSecondmentForFederalAgencyRequestTypeId()
                }, () => {
                    this.callRequestById('GetFederalSecondmentbyID');
                })
            } else if (pathName === 'exemptionFromAccompanyPatient') {
                this.setState({
                    requestType: Config.getExceptionAcompanypatientRequestTypeId()
                }, () => {
                    this.callRequestById('GetPoliciesPatientrequestbyID');
                })
            } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                this.setState({
                    requestType: Config.getRetirmentRequestTypeId()
                }, () => {
                    this.callRequestById('GetRetirementbyID');
                })
            }
        }
    }

    callRequestById = (endPoint) => {
        let id = this.props.id;
        let payload = getRequestByIdPayload(id);
        let url = Config.getUrl(endPoint);

        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let historyDataFromRes = res.data.history;
                        if (historyDataFromRes && historyDataFromRes.length > 0) {
                            this.setState({
                                followData: historyDataFromRes
                            }, () => {
                                // TODO
                            })
                        }
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    toggle = () => {
        this.setState({
            modal: false
        })
    }

    render() {
        let statusBody = { fontSize: '12px', height: '60%', width: '90%', alignSelf: 'center', textAlign: 'center', fontFamily: 'ge_ss_text_Regular', };
        let statustext = { fontSize: '12px', width: '30%', textAlign: this.props.isArabic ? 'left' : 'right', fontWeight: 'bold', fontFamily: 'ge_ss_text_Regular', };
        let statusDatatext = { fontSize: '12px', width: '65%', left: '5%', textAlign: this.props.isArabic ? 'right' : 'left', fontFamily: 'ge_ss_text_Regular', };
        let modalStyle = { fontSize: '14px', width: '100%', fontFamily: 'ge_ss_text_Regular', };
        let firstArr = [];
        let secondArr = [];
        let thirdArr = [];

        if (this.state.followData && this.state.followData.length > 6) {
            for (let index = 0; index < this.state.followData.length; index++) {
                if (index < 6) {
                    firstArr.push(this.state.followData[index])
                } else if (index >= 6 && index < 12) {
                    secondArr.push(this.props.folloFData[index])
                } else if (index >= 12 && index < 18) {
                    thirdArr.push(this.state.followData[index])
                }
            }
        } else {
            firstArr = this.state.followData ? this.state.followData : []
        }

        return (
            <MDBContainer
                style={{
                    display: "flex: ",
                    width: "100%",
                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <MDBModal
                        className={this.props.isArabic ? 'comment_ar' : ''}
                        isOpen={this.state.modal}
                        toggle={this.toggle}
                        position={this.props.isArabic ? 'top-left' : 'top-right'}
                        side>

                        <MDBModalHeader style={{ padding: '0.5rem 1rem', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', textAlign: this.props.isArabic ? "right" : "left" }}
                            toggle={this.toggle}>
                            <p style={modalStyle}>
                                <b>{this.state.modalData.status}
                                </b>
                            </p>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow style={{ backgroundColor: '#ffffff', direction: this.props.isArabic ? 'rtl' : 'ltr' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <p style={statustext}>{`${i18n[this.props.isArabic ? 'ar' : 'en'].requestnumber} :`}</p>
                                    <p style={{ width: '5%' }}></p>
                                    <p style={statusDatatext}>{this.state.modalData.requestnumber}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <p style={statustext}>{`${i18n[this.props.isArabic ? 'ar' : 'en'].status} :`}</p>
                                    <p style={{ width: '5%' }}></p>
                                    <p style={statusDatatext}>{this.state.modalData.status}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <p style={statustext}>{`${i18n[this.props.isArabic ? 'ar' : 'en'].statusreason} :`}</p>
                                    <p style={{ width: '5%' }}></p>
                                    <p style={statusDatatext}>{this.state.modalData.statusreason}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <p style={statustext}>{`${i18n[this.props.isArabic ? 'ar' : 'en'].updatedon} :`}</p>
                                    <p style={{ width: '5%' }}></p>
                                    <p style={statusDatatext}>{this.state.modalData.updatedon}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                    <p style={statustext}>{`${i18n[this.props.isArabic ? 'ar' : 'en'].updatedby} :`}</p>
                                    <p style={{ width: '5%' }}></p>
                                    <p style={statusDatatext}>{this.state.modalData.updatedby}</p>
                                </div>
                            </MDBRow>
                        </MDBModalBody>
                    </MDBModal>

                    <MDBRow xs="12" sm="12" md="12" lg="12" xl="12" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                        {
                            firstArr.map((item, index) => {
                                return <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ height: '20vh' }}>
                                    <MDBRow xs="12" sm="12" md="12" lg="12" xl="12" style={{ direction: 'ltr' }}>
                                        <MDBCol style={{ alignItems: 'center', height: '20vh', border: '1px solid #72b9d8', borderRadius: '10px' }} xs="10" sm="10" md="10" lg="10" xl="10">
                                            <MDBRow style={{ borderBottom: '0.5px solid #6a99ad', borderRadius: '10px 10px 0 0', backgroundColor: '#eaeaea', height: '4vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                                <p style={{ fontSize: '14px', color: '#72b9d8', justifyContent: 'center', display: 'flex', width: '70%' }}>{item.status}</p>
                                                <img
                                                    onClick={() => {
                                                        this.setState({
                                                            modalData: item, modal: true
                                                        })
                                                    }}
                                                    style={{ maxWidth: '16px', maxHeight: '16px', alignSelf: 'center' }}
                                                    src={require("./../../assets/images/icons/info.png")} alt="" />
                                            </MDBRow>
                                            <MDBRow style={{ height: '16vh', paddingLeft: '3%', borderBottom: '1px solid #72b9d8', backgroundColor: '#eaeaea', borderRadius: '0 0 10px 10px' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                                <p style={statusBody}>{item.statusreason}</p>
                                            </MDBRow>
                                        </MDBCol>

                                        <MDBCol style={{ height: '10vh', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }} xs="2" sm="2" md="2" lg="2" xl="2">
                                            {
                                                this.props.isArabic ?
                                                    index === 0 ?
                                                        null :
                                                        <img
                                                            // onClick={}
                                                            style={{ maxWidth: '16px', maxHeight: '16px', position: 'absolute', bottom: '-8px', alignSelf: 'center' }}
                                                            src={require("./../../assets/images/arrow-left.png")} alt="" />
                                                    :
                                                    index === firstArr.length - 1 ?
                                                        null :
                                                        <img
                                                            // onClick={}
                                                            style={{ maxWidth: '16px', maxHeight: '16px', position: 'absolute', bottom: '-8px', alignSelf: 'center' }}
                                                            src={require("./../../assets/images/arrow-right.png")} alt="" />
                                            }
                                        </MDBCol></MDBRow>
                                </MDBCol>
                            })
                        }
                    </MDBRow>

                    <MDBCol style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row' : 'row-reverse', right: this.props.isArabic ? 'unset' : '5%', left: this.props.isArabic ? '6%' : 'unset' }} xs="12" sm="12" md="12" lg="12" xl="12">
                        {
                            this.state.followData && this.state.followData.length > 6 ?
                                <div style={{ height: '10vh', display: 'flex' }}
                                >
                                    <img
                                        style={{ maxWidth: '16px', maxHeight: '16px', alignSelf: 'center' }}
                                        src={require("./../../assets/images/arrow_down.png")} alt="" />
                                </div>
                                : null
                        }
                    </MDBCol>

                    <MDBRow xs="12" sm="12" md="12" lg="12" xl="12" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}>
                        {
                            this.state.followData && this.state.followData.length > 6 ?
                                secondArr.map((item, index) => {
                                    return <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ height: '20vh' }}>
                                        <MDBRow xs="12" sm="12" md="12" lg="12" xl="12" style={{ direction: 'ltr' }}>
                                            <MDBCol style={{ alignItems: 'center', height: '20vh', border: '1px solid #72b9d8', borderRadius: '10px' }} xs="10" sm="10" md="10" lg="10" xl="10">
                                                <MDBRow style={{ borderBottom: '0.5px solid #6a99ad', borderRadius: '10px 10px 0 0', backgroundColor: '#eaeaea', height: '4vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <p style={{ fontSize: '14px', color: '#72b9d8', justifyContent: 'center', display: 'flex', width: '70%' }}>{item.status}</p>
                                                    <img
                                                        onClick={() => {
                                                            this.setState({
                                                                modalData: item, modal: true
                                                            })
                                                        }}
                                                        style={{ maxWidth: '16px', maxHeight: '16px', alignSelf: 'center' }}
                                                        src={require("./../../assets/images/icons/info.png")} alt="" />
                                                </MDBRow>
                                                <MDBRow style={{ height: '16vh', paddingLeft: '3%', borderBottom: '1px solid #72b9d8', backgroundColor: '#eaeaea', borderRadius: '0 0 10px 10px' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <p style={statusBody}>{item.statusreason}</p>

                                                </MDBRow>
                                            </MDBCol>

                                            <MDBCol style={{ height: '10vh', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }} xs="2" sm="2" md="2" lg="2" xl="2">
                                                {
                                                    this.props.isArabic ?

                                                        index === secondArr.length - 1 ?
                                                            null :
                                                            <img
                                                                // onClick={}
                                                                style={{ maxWidth: '16px', maxHeight: '16px', position: 'absolute', bottom: '-8px', alignSelf: 'center' }}
                                                                src={require("./../../assets/images/arrow-right.png")} alt="" />
                                                        :

                                                        index === 0 ?
                                                            null :
                                                            <img
                                                                // onClick={}
                                                                style={{ maxWidth: '16px', maxHeight: '16px', position: 'absolute', bottom: '-8px', alignSelf: 'center' }}
                                                                src={require("./../../assets/images/arrow-left.png")} alt="" />
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                })
                                : null
                        }
                    </MDBRow>
                    {
                        this.state.followData && this.state.followData.length > 12 ?
                            <MDBCol style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', right: this.props.isArabic ? '5%' : 'unset', left: this.props.isArabic ? 'unset' : '6%' }} xs="12" sm="12" md="12" lg="12" xl="12">

                                {
                                    this.state.followData && this.state.followData.length > 6 ?

                                        <div style={{ height: '10vh', display: 'flex' }}
                                        >
                                            <img
                                                style={{ maxWidth: '16px', maxHeight: '16px', alignSelf: 'center' }}
                                                src={require("./../../assets/images/arrow_down.png")} alt="" />
                                        </div>
                                        : null
                                }
                            </MDBCol>
                            : null
                    }

                    <MDBRow xs="12" sm="12" md="12" lg="12" xl="12" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} >
                        {
                            this.state.followData && this.state.followData.length > 12 ?
                                thirdArr.map((item, index) => {
                                    return <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ height: '20vh' }}>
                                        <MDBRow xs="12" sm="12" md="12" lg="12" xl="12" style={{ direction: 'ltr' }}>
                                            <MDBCol style={{ alignItems: 'center', height: '20vh', border: '1px solid #72b9d8', borderRadius: '10px' }} xs="10" sm="10" md="10" lg="10" xl="10">
                                                <MDBRow style={{ borderBottom: '0.5px solid #6a99ad', borderRadius: '10px 10px 0 0', backgroundColor: '#eaeaea', height: '4vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <p style={{ fontSize: '14px', color: '#72b9d8', justifyContent: 'center', display: 'flex', width: '70%' }}>{item.status}</p>
                                                    <img
                                                        onClick={() => {
                                                            this.setState({
                                                                modalData: item, modal: true
                                                            })
                                                        }}
                                                        style={{ maxWidth: '16px', maxHeight: '16px', alignSelf: 'center' }}
                                                        src={require("./../../assets/images/icons/info.png")} alt="" />
                                                </MDBRow>
                                                <MDBRow style={{ height: '16vh', paddingLeft: '3%', borderBottom: '1px solid #72b9d8', backgroundColor: '#eaeaea', borderRadius: '0 0 10px 10px' }} xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <p style={statusBody}>{item.statusreason}</p>

                                                </MDBRow>
                                            </MDBCol>
                                            {/* ,borderBottom: index === firstArr.length-1 ? 0 :'2.4px solid black', */}
                                            <MDBCol style={{ height: '10vh', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }} xs="2" sm="2" md="2" lg="2" xl="2">
                                                {
                                                    this.props.isArabic ?
                                                        index === 0 ?
                                                            null :
                                                            <img
                                                                // onClick={}
                                                                style={{ maxWidth: '16px', maxHeight: '16px', position: 'absolute', bottom: '-8px', alignSelf: 'center' }}
                                                                src={require("./../../assets/images/arrow-left.png")} alt="" />
                                                        :
                                                        index === thirdArr.length - 1 ?
                                                            null :
                                                            <img
                                                                // onClick={}
                                                                style={{ maxWidth: '16px', maxHeight: '16px', position: 'absolute', bottom: '-8px', alignSelf: 'center' }}
                                                                src={require("./../../assets/images/arrow-right.png")} alt="" />
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                })
                                : null
                        }
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}

export default FollowComponent;