import React, { Component } from 'react';

import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody } from "mdbreact";

import Footer from '../Footer';
import Header from '../Header';
import SideBar from '../SideBar';
import i18n from '../../i18n';
import Locali18n from './Locali18n';
import  RequestFunctions from './RequestFunctions';

import SubHeaderStrip from '../SubHeaderStrip';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';
import { uploadDocument ,OrganizationStrucutreRequestPayload} from '../../utils/Payload';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import CKEditorComponent from '../CKEditorComponent';
import DownloadComponent from "../DownloadComponent";
import './../../css/style.css';
import './../../css/util.css';

import "react-datepicker/dist/react-datepicker.css";
const striptags = require('striptags');

let moment = require('moment');


export default class OrganizationStructureRequest extends Component {

    constructor(props) {
        super(props);
       
         this.state = {
             isEdit: '',
             requestId: '',
             draft: 0,
             statusid: '',
             
             isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
             // form variables
             entityName: '',
             
             requestDetails: '',
             requestDate: new Date(),
             employeeName: '',
             
             contactNumber: '',
             email: '',
             requesttypeid:0,
             statusreasonid:0,
             requestTitle:'',
             jobDesc:'',
             // attachements
             RequestReasonFileName: '',
             EntityFormalRequestFileName: '',
             EntityEstablishmentLawFileName: '',
             otherAttachmentFileName: '',
             TasksAndSpecificationsTemplateFileName: '',	
             OrganizationStrucutreOperationalTemplateFileName: '',
             CurrentOrganizationStructureFileName: '',	
             SuggestedOrganizationStructureFileName: '',	

             editAttachmentArray:[],
 
 
             RequestReasonFileref: '',
             EntityFormalRequestFileref: '',
             EntityEstablishmentLawFileref: '',
             otherAttachmentFileref: '',
             TasksAndSpecificationsTemplateFileref: '',	
             OrganizationStrucutreOperationalTemplateFileref: '',
             CurrentOrganizationStructureFileref: '',	
             SuggestedOrganizationStructureFileref: '',	
 
             otherAttachmentShortName: '',
 
             RequestReasonFileObjArray: [],
             EntityFormalRequestFileObjArray: [],
             EntityEstablishmentLawFileObjArray: [],
             otherAttachmentFileObjArray: [],
             TasksAndSpecificationsTemplateFileObjArray: [],	
             OrganizationStrucutreOperationalTemplateFileObjArray: [],
             CurrentOrganizationStructureFileObjArray: [],	
             SuggestedOrganizationStructureFileObjArray: [],	
           
             EditRequestReasonFileObjArray: [],
             EditEntityFormalRequestFileObjArray: [],
             EditEntityEstablishmentLawFileObjArray: [],
             EditotherAttachmentFileObjArray: [],
             EditTasksAndSpecificationsTemplateFileObjArray: [],	
             EditOrganizationStrucutreOperationalTemplateFileObjArray: [],
             EditCurrentOrganizationStructureFileObjArray: [],	
             EditSuggestedOrganizationStructureFileObjArray: [],	
 
             // Organization Strucutreservices
             canvieworgrequest: 0,
             canvieworgrequesttree: 0,
             canviewtree:0,
 
 
             EntityFormalRequestFileErr:'',
             otherAttchmentErr:'',
             RequestReasonFileErr:'',
             EntityEstablishmentLawFileErr:'',
             TasksAndSpecificationsTemplateFileErr:'',	
             OrganizationStrucutreOperationalTemplateFileErr:'',
             CurrentOrganizationStructureFileErr:'',	
             SuggestedOrganizationStructureFileErr:'',	
            
             requestDetailsErr: '',
             employeeNameErr: '',
             contactNumberErr: '',
             emailErr: '',
 
             // Roles
 
             
           
         };     
     };
     componentDidMount = () => {
       
                
         //this.setState({'isArabic':localStorage.getItem('Lang') === 'ar' ? true : false});
          
         let loginData = Config.getLocalStorageLoginData();
         //console.log(loginData);
         let getloginDataReceivedFirstTime = Config.getloginDataReceivedFirstTime();

           this.setState({
            canvieworgrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
            canvieworgrequesttree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.CanCreateStructure,
            canviewtree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewtree,

              })

           let editFlag = Config.getIsEditFlag();
           let editObj = Config.getEditObj();
           console.log("loginData :",loginData);

           this.state.isArabic? this.setState({entityName: loginData.EntityNameAr})
           :this.setState({entityName: loginData.EntityNameEn})


           if (editFlag) {
               this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                requesttypeid: editObj.requesttypeid,
                statusreasonid: editObj.statusreasonid,
                statusid: editObj.statusid,
                requestTitle: editObj.requesttitle,

                email: editObj.email,
                //requestDate: editObj.requestdate ,
                employeeName: editObj.employeename,

                contactNumber: editObj.contactnumber,
                requestDetails: editObj.requestdetails,
                jobDesc: editObj.jobtitle,
                entityName : editObj.entityarabicname,
                editAttachmentArray: editObj.attachments

                 
               });
            


                       if(editObj.attachments.length>0)
                       {
                       editObj.attachments.forEach((item, index) => {
                        
                       if (item.filetypeid == Config.getOtherAttachmentForOrganizationStructurRequestId() )
                               {
                                   let temp = {}
                                   temp.url = item.aliasfilename;
                                   temp.fileName = item.filename;
                                   if (this.state.requestId) {
                                       temp.requestId = this.state.requestId;
                                   } else {
                                       temp.requestId = editObj.requestId
                                   } 
                                   this.setState({
                                    otherAttachmentFileName :  temp.fileName,
                                    otherAttachmentFileObjArray: temp
                                   })
                                
                               }
                             else  if (item.filetypeid == Config.getbase64CurrentOrganizationStructureId() )
                               {
                                   let temp = {}
                                   temp.url = item.aliasfilename;
                                   temp.fileName = item.filename;
                                   if (this.state.requestId) {
                                       temp.requestId = this.state.requestId;
                                   } else {
                                       temp.requestId = editObj.requestId
                                   } 
                                   this.setState({
                                    CurrentOrganizationStructureFileName :  temp.fileName,
                                      CurrentOrganizationStructureFileObjArray: temp
                                   })
                                
                               }
                        else  if (item.filetypeid == Config.getOrganizationStructurRequestReasonId()
                               ) {
                                   let temp = {}
                                   temp.url = item.aliasfilename;
                                   temp.fileName = item.filename;
                                   if (this.state.requestId) {
                                       temp.requestId = this.state.requestId;
                                   } else {
                                       temp.requestId = editObj.requestId
                                   }
                                   this.setState({
                                    RequestReasonFileName :  temp.fileName,

                                    RequestReasonFileObjArray: temp
                                })
                               }
                               else   if (item.filetypeid==Config.getOrganizationStructurEntityFormalRequestId() 
                               ) {
                                   let temp = {}
                                   temp.url = item.aliasfilename;
                                   temp.fileName = item.filename;
                                   if (this.state.requestId) {
                                       temp.requestId = this.state.requestId;
                                   } else {
                                       temp.requestId = editObj.requestId
                                   }
                                   this.setState({
                                    EntityFormalRequestFileName :  temp.fileName,

                                    EntityFormalRequestFileObjArray: temp
                                })            
                                             
                            }
                            else if (item.filetypeid == Config.getOrganizationStructurEntityEstablishmentLawId()
                            ) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                if (this.state.requestId) {
                                    temp.requestId = this.state.requestId;
                                } else {
                                    temp.requestId = editObj.requestId
                                }
                                this.setState({
                                    EntityEstablishmentLawFileName :  temp.fileName,
                                 EntityEstablishmentLawFileObjArray: temp
                             })                       
                                    }
                                    else if (item.filetypeid == Config.getBase64ForTasksAndSpecificationsTemplateId()
                                    ) {
                                        let temp = {}
                                        temp.url = item.aliasfilename;
                                        temp.fileName = item.filename;
                                        if (this.state.requestId) {
                                            temp.requestId = this.state.requestId;
                                        } else {
                                            temp.requestId = editObj.requestId
                                        }
                                        this.setState({
                                            TasksAndSpecificationsTemplateFileName :  temp.fileName,

                                         TasksAndSpecificationsTemplateFileObjArray: temp
                                     })                               
                                    }
                                    else  if (item.filetypeid == Config.getbase64OrganizationStrucutreOperationalTemplateId()
                                    ) {
                                        let temp = {}
                                        temp.url = item.aliasfilename;
                                        temp.fileName = item.filename;
                                        if (this.state.requestId) {
                                            temp.requestId = this.state.requestId;
                                        } else {
                                            temp.requestId = editObj.requestId
                                        }
                                        this.setState({
                                            OrganizationStrucutreOperationalTemplateFileName :  temp.fileName,
                                         OrganizationStrucutreOperationalTemplateFileObjArray: temp
                                     })                   
                               }
                               else if (item.filetypeid == Config.getbase64CurrentOrganizationStructureId
                               ) {
                                   let temp = {}
                                   temp.url = item.aliasfilename;
                                   temp.fileName = item.filename;
                                   if (this.state.requestId) {
                                       temp.requestId = this.state.requestId;
                                   } else {
                                       temp.requestId = editObj.requestId
                                   }
                                   this.setState({
                                    CurrentOrganizationStructureFileName :  temp.fileName,
                                    CurrentOrganizationStructureFileObjArray: temp
                                })        
                                                 
                            }
                            else if (item.filetypeid == Config.getbase64SuggestedOrganizationStructureId()
                            ) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                if (this.state.requestId) {
                                    temp.requestId = this.state.requestId;
                                } else {
                                    temp.requestId = editObj.requestId
                                }
                                this.setState({
                                    SuggestedOrganizationStructureFileName :  temp.fileName,

                                 SuggestedOrganizationStructureFileObjArray: temp
                             })            
                                     
                            }

                }) 
            }
            }

           else 
           {
          
            if (loginData && !getloginDataReceivedFirstTime) {
                // get email, contact and , entity id and entity name from login
                let tempEntityName = this.state.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
                let tempEmployeeName = this.state.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
                this.setState({
                    employeeName: tempEmployeeName,
                    entityName: tempEntityName,
                    jobDesc: loginData && loginData.JobTitle,
                    contactNumber: loginData && loginData.ContactNumber,
                    email: loginData && loginData.Email,
                   
                    //requestDate : new Date().getDate()
                }, () => {
                    Config.setLoginDataReceivedFirstTime(true);
                });
            }
           }
           this.setState({requestDateErr:''});

           
        }
    
   
      

   handlePreViewNavigation = (obj) => {
     let editObj = Config.getEditObj();
     let previewObj = {};
     previewObj.isEdit = Config.getIsEditFlag();
     previewObj.requestId = editObj.requestId;
     previewObj.entityname = obj.entityName;
   
     previewObj.contactNumber = obj.contactNumber;
     previewObj.requestDate = moment(obj.requestDate).format('L').toString();
     previewObj.emailId = obj.email;
     previewObj.employeeName = obj.employeeName;
     previewObj.otherAttachmentShortName = obj.otherAttachmentShortName;
   
 
     let attachmentArr = [
         {
             label: 'OrganizationStructurRequestReason',
             fileName: obj.OrganizationStructureRequestReason,
             fileObj: Config.getBase64ForOrganizationStructurRequestReason()
         },
         {
             label: 'OrganizationStructurEntityEstablishmentLaw',
             fileName: obj.EntityEstablishmentLaw,
             fileObj: Config.getBase64ForOrganizationStructurEntityEstablishmentLaw()
         },
         {
             label: 'getOrganizationStructurEntityFormalRequest',
             fileName: obj.EntityFormalRequest,
             fileObj: Config.getBase64ForOrganizationStructurEntityFormalRequest()
         },
         {
             label: 'otherattachemnt',
             fileName: obj.otherAttachment,
             fileObj: Config.getbase64OtherAttachmentForOrganizationStructurRequest()
         },
         
         {
             label: 'TasksAndSpecificationsTemplate',
             fileName: obj.TasksAndSpecificationsTemplate,
             fileObj: Config.getBase64ForTasksAndSpecificationsTemplate()
         },
         {
             label: 'OrganizationStrucutreOperationalTemplate',
             fileName: obj.OrganizationStrucutreOperationalTemplate,
             fileObj: Config.getBase64ForOrganizationStrucutreOperationalTemplate()
         },
         {
             label: 'CurrentOrganizationStructure',
             fileName: obj.CurrentOrganizationStructure,
             fileObj: Config.getBase64ForCurrentOrganizationStructure()
         },
         {
             label: 'SuggestedOrganizationStructure',
             fileName: obj.SuggestedOrganizationStructure,
             fileObj: Config.getbase64SuggestedOrganizationStructure()
         }
     ];
 
     let ckEditorArr = [
         {
             label: 'requestDetails',
             value: obj.requestDetails
         }
     ];
 
     let temp = editObj;
     temp.email = obj.email;
     temp.requestdate = obj.requestDate ? moment(obj.requestDate).format('L').toString() : '';
     temp.employeename = obj.employeeName;
     temp.contactnumber = obj.contactNumber;
     temp.requestdetails = obj.requestDetails;
     
     temp.attachments = editObj.attachments;
     Config.setEditObj(temp)
 
     localStorage.setItem("currentPath", "OrganizationStructureRequest");     
     this.props.history.replace("/preview", { data: previewObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
    
 };
 
 
 handleEntityName = (event) => {
     let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
     if (event.target.value.trim()) {
         if (regex.test(event.target.value)) {
             this.setState({ entityName: event.target.value });
         }
     } else {
         this.setState({ entityName: '' });
     }
 };
 
 
 handleRequestDetails = (evt) => {
     var newContent = evt.editor.getData();
     let newContent1 = striptags(newContent);
     if (newContent1.length <= Config.getMaxCKEditorLimit()) {
         this.setState({
             requestDetailsErr: "",
             requestDetails: newContent
         })
     }
     else {
         this.setState({
             requestDetailsErr: "ckeditorCountErr"
         })
     }
 };
 
 handleRequestDate=(date)=> {
     this.setState({
         requestDate: date,
         requestDateErr: ''
 
     });
 };
 
 handleEmployeeName = (event) => {
     let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
     if (event.target.value.trim()) {
         if (regex.test(event.target.value)) {
             this.setState({ employeeName: event.target.value });
         }
         else {
             this.setState({ employeeName: ''  , employeeNameErr:'InvalidEmployeeNameFormate'});
         }
     } 
     else {
         this.setState({ employeeName: ''  , employeeNameErr:'thisFieldIsRequired'});
         
     }
 };
 
 
 handleContactNumberChange = (event) => {
     let contactNumber = event.target.value;
     if (event.target.value !== '') {
         this.setState({ contactNumber: event.target.value }, () => {
             if (contactNumber.length === 11 || contactNumber.length === 12) {
                 this.setState({ contactNumberErr: '' });
             } else {
                 this.setState({ contactNumberErr: 'invalidContactNumber' });
             }
         });
     } else {
         this.setState({ contactNumber: '', contactNumberErr: 'thisFieldIsRequired' });
     }
 };
 
 handleEmail = (event) => {
     if (event.target.value.trim()) {
         if (event.target.value.length <= 50) {
             this.setState({ email: event.target.value, emailErr: '' });
         }
     }
     else {
         this.setState({ email: '', emailErr: 'thisFieldIsRequired' });
     }
 };
 
 
 
   onRequestDatepickerRef=(el)=> {
     if (el && el.input) {
         if (this.state.requestDate !== null) {
             el.input.disabled = true;
             el.input.readOnly = false;
             el.input.classList.remove('white_bg');
         } else {
             el.input.disabled = false;
             el.input.readOnly = true;
             el.input.classList.add('white_bg');
         }
         if (el.input.type === "blur") {
             el.input.target.style.backgroundColor = "white";
         }
     }
 };
 
 onDatepickerRef=(el)=> {
     if (el && el.input) {
         el.input.readOnly = true;
         el.input.classList.add('white_bg');
     }
 }
 
 
 toggleSideBarSize = () => {
     this.setState({
         isSideBarMinimized: !this.state.isSideBarMinimized
     });
 }
 
 onClickHelpPage = () => {
     this.props.history.replace('/help');
 }
 // attchments
 
 otherAttachmentOnChnage = (e) => {
     debugger;
     let fileNames = "";
     let fileOtherAttachmnetArray = [];
 
     if (e.target.files.length > 0) {
         if (e.target.files.length <= Config.getMaxFileCount()) {
             for (let m = 0; m < e.target.files.length; m++) {
                let inpFileName = e.target.files[m].name;
                let fileSize = e.target.files[m].size;
                let fileExt = inpFileName.split('.').pop().toUpperCase();
                let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
 
                 if (allowedExtensions.indexOf(fileExt) <= -1) {
                     e.target.value = '';
                     this.setState({
                         otherAttachment: '',
                         otherAttachmentShortName: '',
                         otherAttchmentErr: 'invalidFileType'
                        });
                    }
                    else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                         let comma = "";
                         if (m > 0) {
                             comma = ", "
                         }
                         fileNames += comma + e.target.files[m].name;
                         let otherAttachmentShortName = fileNames.substring(0, 50);
 
                         let reader = new FileReader();
                         let tempFile = e.target.files[m];
                         reader.onloadend = () => {
                             fileOtherAttachmnetArray.push(reader.result);
                             this.setState({
                                 otherAttachmentFileObjArray: fileOtherAttachmnetArray,
                             }, () => {
                                 Config.setbase64OtherAttachmentForOrganizationStructurRequest(this.state.otherAttachmentFileObjArray);
                             });
                         };
                         reader.readAsDataURL(tempFile);
                         this.setState({
                             otherAttachmentFileName: fileNames,
                             otherAttachmentShortName: otherAttachmentShortName,
                             otherAttchmentErr: ''
                         });
                     } else {
                         e.target.value = '';
                         this.setState({
                             otherAttachment: '',
                             otherAttachmentShortName: '',
                             otherAttchmentErr: 'invalidFileSize'
                         });
                         break;
                     }
                 }
             }
         }
         else {
             e.target.value = '';
             this.setState({
                 otherAttachment: "",
                 otherAttachmentShortName: "",
                 otherAttchmentErr: 'fileLimitErr'
             });
         }
     }
     else {
         e.target.value = '';
         this.setState({
             otherAttachment: "",
             otherAttachmentShortName: "",
             otherAttchmentErr: ''
         });
     }
 };
 
 TasksAndSpecificationsTemplateOnChange = (e) => {
     debugger;
 
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PPTX'];
 
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 TasksAndSpecificationsTemplateFileName: '',
                 TasksAndSpecificationsTemplateFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 this.setState({
                     TasksAndSpecificationsTemplateFileName: e.target.files[0].name,
                      TasksAndSpecificationsTemplateFileErr: ''
                 }, () => {
                    
                     let tempFile = fileObj;
                     let reader = new FileReader();
                     reader.readAsDataURL(tempFile);
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             TasksAndSpecificationsTemplateFileObjArray: TempArray,
                         }, () => {
                             Config.setBase64ForTasksAndSpecificationsTemplate(this.state.TasksAndSpecificationsTemplateFileObjArray);
                         });
                     }
                 });
             } else {
                 e.target.value = '';
                 this.setState({
                     TasksAndSpecificationsTemplateFileName: '',
                     TasksAndSpecificationsTemplateFileErr: 'invalidFileSize'
                 });
             }
 
         }
     } else {
         this.setState({ TasksAndSpecificationsTemplateFileName: '', TasksAndSpecificationsTemplateFileErr: 'thisFieldIsRequired' });
     }
 };
  
 OrganizationStrucutreOperationalTemplateOnChange = (e) => {
     debugger;
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PPTX'];
 
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 OrganizationStrucutreOperationalTemplateFileName: '',
                 OrganizationStrucutreOperationalTemplateFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 this.setState({
                     OrganizationStrucutreOperationalTemplateFileName: e.target.files[0].name,
                      OrganizationStrucutreOperationalTemplateFileErr: ''
                 }, () => {
                     let tempFile = fileObj;
                     let reader = new FileReader();
                     reader.readAsDataURL(tempFile);
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             OrganizationStrucutreOperationalTemplateFileObjArray: TempArray,
                         }, () => {
                             Config.setbase64OrganizationStrucutreOperationalTemplate(this.state.OrganizationStrucutreOperationalTemplateFileObjArray);
                         });
                     }
                 });
             } else {
                 e.target.value = '';
                 this.setState({
                     OrganizationStrucutreOperationalTemplateFileName: '',
                     OrganizationStrucutreOperationalTemplateFileErr: 'invalidFileSize'
                 });
             }
 
         }
     } else {
         this.setState({ OrganizationStrucutreOperationalTemplateFileName: '', 
         OrganizationStrucutreOperationalTemplateFileErr: 'thisFieldIsRequired' });
     }
 };
      
 CurrentOrganizationStructureOnChange = (e) => {
     debugger;
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX'];
 
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 CurrentOrganizationStructureFileName: '',
                 CurrentOrganizationStructureFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 this.setState({
                     CurrentOrganizationStructureFileName: e.target.files[0].name, 
                     CurrentOrganizationStructureFileErr: ''
                 }, () => {
                     let tempFile = fileObj;
                     let reader = new FileReader();
                     reader.readAsDataURL(tempFile);
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             CurrentOrganizationStructureFileObjArray: TempArray,
                         }, () => {
                             Config.setbase64CurrentOrganizationStructure(this.state.CurrentOrganizationStructureFileObjArray);
                         });
                     }
                 });
             } else {
                 e.target.value = '';
                 this.setState({
                     CurrentOrganizationStructureFileName: '',
                     CurrentOrganizationStructureFileErr: 'invalidFileSize'
                 });
             }
 
         }
     } else {
         this.setState({ CurrentOrganizationStructureFileName: '', CurrentOrganizationStructureFileErr: 'thisFieldIsRequired' });
     }
 };
     
     
     
 SuggestedOrganizationStructureOnChange = (e) => {
     debugger;
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX'];
 
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 SuggestedOrganizationStructureFileName: '',
                 SuggestedOrganizationStructureFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 this.setState({
                     SuggestedOrganizationStructureFileName: e.target.files[0].name, SuggestedOrganizationStructureFileErr: ''
                 }, () => {
                     let tempFile = fileObj;
                     let reader = new FileReader();
                     reader.readAsDataURL(tempFile);
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             SuggestedOrganizationStructureFileObjArray: TempArray,
                         }, () => {
                             Config.setbase64SuggestedOrganizationStructure(this.state.SuggestedOrganizationStructureFileObjArray);
                         });
                     }
                 });
             } else {
                 e.target.value = '';
                 this.setState({
                     SuggestedOrganizationStructureFileName: '',
                     SuggestedOrganizationStructureFileErr: 'invalidFileSize'
                 });
             }
 
         }
     } else {
         this.setState({ SuggestedOrganizationStructureFileName: '', SuggestedOrganizationStructureFileErr: 'thisFieldIsRequired' });
     }
 };
 
 EntityEstablishmentLawFileOnChange = (e) => {
     debugger;
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX'];
 
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 EntityEstablishmentLawFileName: '',
                 EntityEstablishmentLawFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 this.setState({
                     EntityEstablishmentLawFileName: e.target.files[0].name, EntityEstablishmentLawFileErr: ''
                 }, () => {
                     let tempFile = fileObj;
                     let reader = new FileReader();
                     reader.readAsDataURL(tempFile);
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             EntityEstablishmentLawFileObjArray: TempArray,
                         }, () => {
                             Config.setBase64ForOrganizationStructurEntityEstablishmentLaw(this.state.EntityEstablishmentLawFileObjArray);
                         });
                     }
                 });
             } else {
                 e.target.value = '';
                 this.setState({
                     EntityEstablishmentLawFileName: '',
                     EntityEstablishmentLawFileErr: 'invalidFileSize'
                 });
             }
 
         }
     } else {
         this.setState({ EntityEstablishmentLawFileName: '', EntityEstablishmentLawFileErr: 'thisFieldIsRequired' });
     }
 };
 
 EntityFormalRequestFileOnChange = (e) => {
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX'];
 
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 EntityFormalRequestFileName: '',
                 EntityFormalRequestFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 this.setState({
                     EntityFormalRequestFileName: e.target.files[0].name, EntityFormalRequestFileErr: ''
                 }, () => {
                     let tempFile = fileObj;
                     let reader = new FileReader();
                     reader.readAsDataURL(tempFile);
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             EntityFormalRequestFileObjArray: TempArray,
                         }, () => {
                             Config.setBase64ForOrganizationStructurEntityFormalRequest(this.state.EntityFormalRequestFileObjArray);
                         });
                     }
                 });
             } else {
                 e.target.value = '';
                 this.setState({
                     EntityFormalRequestFileName: '',
                     EntityFormalRequestFileErr: 'invalidFileSize'
                 });
             }
 
         }
     } else {
         this.setState({ EntityFormalRequestFileName: '', EntityFormalRequestFileErr: 'thisFieldIsRequired' });
     }
 };
 
 RequestReasonFileOnChange = (e) => {
     let TempArray = [];
     if (e.target.files.length > 0) {
         let inpFileName = e.target.files[0].name;
         let fileSize = e.target.files[0].size;
         let fileExt = inpFileName.split('.').pop().toUpperCase();
         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX'];
         if (allowedExtensions.indexOf(fileExt) <= -1) {
             this.setState({
                 RequestReasonFileName: '', RequestReasonFileErr: 'invalidFileType'
             });
         }
         else {
             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                 let fileObj = e.target.files[0];
                 
 
                 this.setState({
                     
                     RequestReasonFileName: e.target.files[0].name, RequestReasonFileErr: ''
                 }, () => {
                     debugger;
                     let tempFile = fileObj;
                     let reader = new FileReader();
                   
                     reader.onloadend = () => {
                         TempArray.push(reader.result);
                         this.setState({
                             RequestReasonFileObjArray: TempArray
                         }, () => {
                           
                             Config.setBase64ForOrganizationStructurRequestReason(this.state.RequestReasonFileObjArray);
                         }); 
                                      
                     }
                     debugger;   
                     reader.readAsDataURL(tempFile);
                 });
 
             } else {
                 e.target.value = '';
                 this.setState({
                     RequestReasonFileName: '',
                     RequestReasonFileErr: 'invalidFileSize'
                 });
             }
         }
     } else {
         this.setState({ RequestReasonFileName: '', RequestReasonFileErr: 'thisFieldIsRequired' });
     }
 };
 
  Validate=()=> {
 
     
     let state = true;
       if( this.state.employeeName==='' )
          {this.setState({employeeNameErr:'thisFieldIsRequired'}); state=false;}
       if (this.state.employeeNameErr !=='')
          state=false;   
     
   
     if( this.state.email==='' )
      {this.setState({emailErr:'thisFieldIsRequired'}); state=false;}   
     if( this.state.emailErr!=='')
     state=false;   
 
     if( this.state.contactNumber==='' )
     {this.setState({contactNumberErr:'thisFieldIsRequired'}); state=false;}
     if(  this.state.contactNumberErr!=='' )
     state=false;  
 
     if( this.state.requestDetails==='' )
     {this.setState({requestDetailsErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.requestDetailsErr !=='')
     state=false;   
 
 
 
     if( this.state.EntityEstablishmentLawFileObjArray.length === 0 )
     {this.setState({EntityEstablishmentLawFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.EntityEstablishmentLawFileErr!=='' )
     state=false; 
 
     if( this.state.EntityFormalRequestFileObjArray.length === 0 )
     {this.setState({EntityFormalRequestFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.EntityFormalRequestFileErr!=='')
     state=false;
     
     if( this.state.RequestReasonFileObjArray.length===0 )
     {this.setState({RequestReasonFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.RequestReasonFileErr !=='')
     state=false; 
 
 
     if( this.state.TasksAndSpecificationsTemplateFileObjArray.length === 0 )
     {this.setState({TasksAndSpecificationsTemplateFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.TasksAndSpecificationsTemplateFileErr!=='' )
     state=false; 
 
     if( this.state.OrganizationStrucutreOperationalTemplateFileObjArray.length === 0 )
     {this.setState({OrganizationStrucutreOperationalTemplateFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.OrganizationStrucutreOperationalTemplateFileErr!=='')
     state=false;
     
     if( this.state.CurrentOrganizationStructureFileObjArray.length===0 )
     {this.setState({CurrentOrganizationStructureFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.CurrentOrganizationStructureFileErr !=='')
     state=false; 
     
       if( this.state.SuggestedOrganizationStructureFileObjArray.length===0 )
     {this.setState({SuggestedOrganizationStructureFileErr:'thisFieldIsRequired'}); state=false;}
     if( this.state.SuggestedOrganizationStructureFileErr !=='')
     state=false;
 
 
     
    return state;
 }
 
   clearForm = () => {
            this.setState({
                requestId: '',
                isEdit: '',
                entityName: '',
              
                requestDetails: '',
                requestDate: null,
                employeeName: '',
               
                contactNumber: '',
                email: '',
                requestDateErr: '',
                emailErr: '',
                contactNumberErr: '',
            });
        };

  
    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    };

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    };

    alertSubmit = () => {
       
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
         
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                  
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', 
                    {pathname: localStorage.getItem('currentPath')}
                        
                    )
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    };

    handlePreViewNavigation = () => {
        this.setState({
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            entityName: this.state.entityName,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            employeeName: this.state.employeeName,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            
            otherAttachmentShortName: this.state.otherAttachmentShortName,
        });
    };
    
    saveAsDraft = () => {
        this.setState({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestDate: this.state.requestDate,
            employeeName: this.state.employeeName,
            requestDetails: this.state.requestDetails,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            // attchment names
            EntityEstablishmentLaw : this.state.EntityEstablishmentLaw,
            RequestReason : this.state.RequestReason,
            EntityFormalRequest : this.status.EntityFormalRequest,
            otherAttachment : this.state.otherAttachment ,
            otherAttachmentShortName: this.state.otherAttachmentShortName,

            TasksAndSpecificationsTemplate : this.state.TasksAndSpecificationsTemplate,
            OrganizationStrucutreOperationalTemplate : this.state.OrganizationStrucutreOperationalTemplate,
            CurrentOrganizationStructure : this.status.CurrentOrganizationStructure,
            SuggestedOrganizationStructure : this.state.SuggestedOrganizationStructure 

        })
    };

    uploadFile = (i, totalCount, res, multifileCount) => {
       debugger;
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let isLastFileInMultiUpload = false;
            let obj = {};
            if (i === 0) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForOrganizationStructurRequestReason();
                obj.filename = this.state.RequestReasonFileName;
                obj.filetype = Config.getOrganizationStructurRequestReasonId();
                tempBase64 = fileObj;
            } else if (i === 1) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForOrganizationStructurEntityEstablishmentLaw();
                obj.filename = this.state.EntityEstablishmentLawFileName;
                obj.filetype = Config.getOrganizationStructurEntityEstablishmentLawId();
                tempBase64 = fileObj;
            } 
            else if (i === 2) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForOrganizationStructurEntityFormalRequest();
                obj.filename = this.state.EntityFormalRequestFileName;
                obj.filetype = Config.getOrganizationStructurEntityFormalRequestId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                // get base 64 for attachement
                fileObj = Config.getbase64OtherAttachmentForOrganizationStructurRequest();
                obj.filename = this.state.otherAttachmentFileName;
                obj.filetype = Config.getOtherAttachmentForOrganizationStructurRequestId();
                tempBase64 = fileObj;
            }
            if (i === 4) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForTasksAndSpecificationsTemplate();
                obj.filename = this.state.TasksAndSpecificationsTemplateFileName;
                obj.filetype = Config.getBase64ForTasksAndSpecificationsTemplateId();
                tempBase64 = fileObj;
            } else if (i === 5) {
                // get base 64 for attachement
                fileObj = Config.getbase64OrganizationStrucutreOperationalTemplate();
                obj.filename = this.state.OrganizationStrucutreOperationalTemplateFileName;
                obj.filetype = Config.getbase64OrganizationStrucutreOperationalTemplateId();
                tempBase64 = fileObj;
            } 
            else if (i === 6) {
                // get base 64 for attachement
                fileObj = Config.getbase64CurrentOrganizationStructure();
                obj.filename = this.state.CurrentOrganizationStructureFileName;
                obj.filetype = Config.getbase64CurrentOrganizationStructureId();
                tempBase64 = fileObj;
            }
            else if (i === 7) {
                // get base 64 for attachement
                fileObj = Config.getbase64SuggestedOrganizationStructure();
                obj.filename = this.state.SuggestedOrganizationStructureFileName;
                obj.filetype = Config.getbase64SuggestedOrganizationStructureId();
                tempBase64 = fileObj;
            }
            if (obj.filename && tempBase64) {
                debugger;
                let reqId = 0;
                if (res && res.data && res.data.id) {
                    reqId = res.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                if (tempBase64[0].includes(",")) {
                    obj.fileContent = tempBase64[0].split(',')[1];
                } else {
                    obj.fileContent = tempBase64[0];
                }
               
                obj.position = 0;

                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);
               
                ApiRequest.sendRequestForPost(url, payload, resForOtherAttachmentFile => {
                   
                    if (resForOtherAttachmentFile.Status) {
                        if (i === 0) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, res, multifileCount + 1);
                            } else {
                                this.uploadFile(i + 1, totalCount, res, multifileCount + 1);
                            }
                        } else {
                            this.uploadFile(i + 1, totalCount, res, 0);
                        }
                    }
                    else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                if (this.state.draft === "saveAsDraft") {
                                    this.setState({
                                        titleAlert: i18n[this.getLocale()].success,
                                        messageAlert: i18n[this.getLocale()].save_as_Draft
                                    }, () => {
                                        this.alertSubmitForSuccess();
                                    })
                                } else {
                                    this.setState({
                                        titleAlert: i18n[this.getLocale()].error,
                                        messageAlert: i18n[this.getLocale()].attachment_error
                                    }, () => {
                                        this.alertSubmit();
                                    })
                                }
                            })
                        }
                    
                })
            } else {
                this.uploadFile(i + 1, totalCount, res, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                    }
                } else {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    };

    submitApiCall = (e) => {
       debugger;

       e.preventDefault();
       e.target.className += " was-validated";

        let reqObj = {};
        if(this.state.requestId === '')
          reqObj.requestId = 0;
         else
           reqObj.requestId = this.state.requestId;

           reqObj.email = this.state.email;
 
        let tempDate = '';
        if (this.state.requestDate !== '' && this.state.requestDate !== null) {
            tempDate = new Date(this.state.requestDate);
            let day = tempDate.getDate();
            let month = tempDate.getMonth() + 1;
            let year = tempDate.getFullYear();
            reqObj.requestdate = year + '-' + month + '-' + day;
        } else 
        {
            reqObj.requestdate = tempDate;
        }
        reqObj.employeename = this.state.employeeName;
        reqObj.contactnumber = this.state.contactNumber;
        reqObj.requestdetails = this.state.requestDetails;

        if (e.target.id ==='1') {

            reqObj.draft = 1;
        } else {
            if(!this.Validate())
                return;
            else
                 reqObj.draft = 0;
        }

        let payload = OrganizationStrucutreRequestPayload(reqObj);
        let endPoint = 'SaveOrganizatonStructureRequest';
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {

                if (res.Status) {
                    let totalFile = 8;
                    this.uploadFile(0, totalFile, res, 0);
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message
                        }, () => {
                            this.alertSubmit();
                        })
                    })
                }
            })
        })
    };

  
    render() 
    {

        const sideBarData = Config.getSidebarForOrgStructure(this.state.isArabic, this, 0);
        return (

            <MDBContainer fluid style={{ height: '100vh' , flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>

                            <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>

                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'OrganizationStructure' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].organizationStructure}
                                    />
                                </MDBCol>

                            </MDBRow>

                <form
                className="needs-validation formWeight formSpace"
                //onSubmit={this.submitApiCall}
                noValidate>
                 <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                   
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 
                            'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].entityName}
                            
                             <span className="requireStar">*</span></label>
                            <input
                                disabled={true}
                                value={this.state.entityName}
                               onChange={this.handleEntityName}
                                type="text"
                                id="entityName"
                                className="form-control"
                                name="entityName"
                                required
                                style={{ direction:  this.state.isArabic  ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={ this.state.isArabic  ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{Locali18n[ this.state.isArabic  ? 'ar' : 'en'].requestDate}<span className="requireStar">*</span></label>
                            <div
                                style={{
                                    width: '100%', direction:  this.state.isArabic  ? 'rtl' : ''
                                }}
                                className={`${ this.state.isArabic  ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.requestDateErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    disabled={true}
                                    minDate={new Date()}
                                    className="form-control"
                                    todayButton={"Today"}
                                    style={{ width: '100%' }}
                                    selected={this.state.requestDate}
                                    onChange={this.handleRequestDate}
                                    ref={el => this.onRequestDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    required />
                                    
                            </div>
                            {
                                this.state.requestDateErr !== '' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                        {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                   
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                   
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{Locali18n[ this.state.isArabic  ? 'ar' : 'en'].email}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleEmail}
                                style={{ direction:  this.state.isArabic  ? "rtl" : 'ltr' }}
                                value={this.state.email}
                                required
                            />
                            {
                                this.state.emailErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emailErr === 'invalidEmail' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].invalidEmail}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{Locali18n[ this.state.isArabic  ? 'ar' : 'en'].employeeName}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="employeeName"
                                name="employeeName"
                                onChange={this.handleEmployeeName}
                                value={this.state.employeeName}
                                required
                                style={{ direction:  this.state.isArabic  ? "rtl" : 'ltr' }}
                            />
                             { this.state.contactNumberErr === 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={ this.state.isArabic  ?
                                 { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } :
                                  { justifyContent: 'flex-start', width: '100%' }}>
                                {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>: null
                            }
                        </div>
                    </MDBCol>
                
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                    
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{Locali18n[ this.state.isArabic  ? 'ar' : 'en'].contactNumber}<span className="requireStar">*</span></label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                className="form-control"
                                required
                                style={{ direction:  this.state.isArabic  ? "rtl" : 'ltr' }}
                            />
                            {
                                this.state.contactNumberErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                        {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.contactNumberErr === 'invalidContactNumber' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                        {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
               
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                   
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{Locali18n[ this.state.isArabic  ? 'ar' : 'en'].requestDetails}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.requestDetailsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={ this.state.isArabic }
                                    content={this.state.requestDetails}
                                    onChange={this.handleRequestDetails}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection:  this.state.isArabic  ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.requestDetailsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
               
                </MDBRow><MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

<div className="form-group">
    <label htmlFor="formDownloadThisExcelFile">{i18n[this.getLocale()].downloadThisExcelFileORG}</label>
  <div style={{paddingTop:'30px',paddingBottom:'30px'}}>  <a style={{fontSize:'16px',fontWeight:'bold',color:'#ffffff',backgroundColor:'#40505f',margin:'30px',padding:'20px'}} href="Tasks And Specifications Template.pptx" target="_blank">{i18n[this.getLocale()].clickHereToDownloadORGTasksTemplate}</a></div>
</div>
</MDBCol>
<MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
<div className="form-group">
                            <label htmlFor="formDownloadThisExcelFile">{i18n[this.getLocale()].downloadThisExcelFileORG}</label>
                          <div style={{paddingTop:'30px',paddingBottom:'30px'}}>  <a style={{fontSize:'16px',fontWeight:'bold',color:'#ffffff',backgroundColor:'#40505f',margin:'30px',padding:'20px'}} href="Organization Structure Operational Template.pptx" target="_blank">{i18n[this.getLocale()].clickHereToDownloadORGOprationalTemplate}</a></div>
                        </div>
                        </MDBCol>
                </MDBRow>

   <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
  
                   
   <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">           
      <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
          {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].TasksAndSpecificationsTemplate}
          <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
      </label>
      <div className="input-group">
          <div className="custom-file-inp">
              <input
                  id="TasksAndSpecificationsTemplateFileId"
                  type="file"
                  name="TasksAndSpecificationsTemplateFile"
                  onChange={(event) => this.TasksAndSpecificationsTemplateOnChange(event)}
                  required
                 // accept=".pdf,.doc,.docx"
              />
              <label htmlFor="TasksAndSpecificationsTemplateFileId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                  {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
              </label>

              <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="TasksAndSpecificationsTemplateFileId" className="fileName"><span>{this.state.TasksAndSpecificationsTemplateFileName}</span></label>
          </div>
          {
              this.state.TasksAndSpecificationsTemplateFileErr === 'thisFieldIsRequired' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].thisFieldIsRequired}
                  </div>
                  :
                  null
          }
          {
              this.state.TasksAndSpecificationsTemplateFileErr === 'invalidFileType' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].invalidFileType}
                  </div>
                  :
                  null
          }
          {
              this.state.TasksAndSpecificationsTemplateFileFileErr === 'invalidFileSize' ?
                  <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                      {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                  </div>
                  :
                  null
          }

          <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
              {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORGTemplate}
          </p>
          {
              (this.state.TasksAndSpecificationsTemplateFileObjArray &&
                  this.state.TasksAndSpecificationsTemplateFileObjArray.length >0 && this.state.id > 0)
                  ? <DownloadComponent filesObj={this.state.TasksAndSpecificationsTemplateFileObjArray} />
                  : <span></span>
          }
      </div>
  </MDBCol>

  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
 

      <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
          {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].OrganizationStrucutreOperationalTemplate}
          <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
      </label>
      
      <div className="input-group">
          <div className="custom-file-inp">
              <input
                  id="OrganizationStrucutreOperationalTemplateId"
                  type="file"
                  name="OrganizationStrucutreOperationalTemplate"
                  onChange={(event) => this.OrganizationStrucutreOperationalTemplateOnChange(event)}
                  required
                 // accept=".pdf,.doc,.docx"
              />
              <label htmlFor="OrganizationStrucutreOperationalTemplateId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                  {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
              </label>
              <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="OrganizationStrucutreOperationalTemplateId" className="fileName"><span>{this.state.OrganizationStrucutreOperationalTemplateFileName}</span></label>
          </div>
          {
              this.state.OrganizationStrucutreOperationalTemplateFileErr === 'thisFieldIsRequired' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].thisFieldIsRequired}
                  </div>
                  :
                  null
          }
          {
              this.state.OrganizationStrucutreOperationalTemplateFileErr === 'invalidFileType' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].invalidFileType}
                  </div>
                  :
                  null
          }
          {
              this.state.OrganizationStrucutreOperationalTemplateFileErr === 'invalidFileSize' ?
                  <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                      {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                  </div>
                  :
                  null
          }

          <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
              {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORGTemplate}
          </p>
          {
              (this.state.OrganizationStrucutreOperationalTemplateFileObjArray &&
                  this.state.OrganizationStrucutreOperationalTemplateFileObjArray.length  >0 && this.state.id > 0)
                  ? <DownloadComponent filesObj={this.state.OrganizationStrucutreOperationalTemplateFileObjArray} />
                  : <span></span>
          }
      </div>
  </MDBCol>
</MDBRow>


   <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
 
  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
      <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
          {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].CurrentOrganizationStructure}
          <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
      </label>
      <div className="input-group">
          <div className="custom-file-inp">
              <input
                  id="CurrentOrganizationStructureId"
                  type="file"
                  name="CurrentOrganizationStructure"
                  onChange={(event) => this.CurrentOrganizationStructureOnChange(event)}
                  required
                 // accept=".pdf,.doc,.docx"
              />
              <label htmlFor="CurrentOrganizationStructureId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                  {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
              </label>

              <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="CurrentOrganizationStructureId" className="fileName"><span>{this.state.CurrentOrganizationStructureFileName}</span></label>
          </div>
          {
              this.state.CurrentOrganizationStructureFileErr === 'thisFieldIsRequired' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].thisFieldIsRequired}
                  </div>
                  :
                  null
          }
          {
              this.state.CurrentOrganizationStructureFileErr === 'invalidFileType' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].invalidFileType}
                  </div>
                  :
                  null
          }
          {
              this.state.CurrentOrganizationStructureFileErr === 'invalidFileSize' ?
                  <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                      {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                  </div>
                  :
                  null
          }

          <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
              {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORG}
          </p>
          {
              (this.state.CurrentOrganizationStructureFileObjArray &&
                  this.state.CurrentOrganizationStructureFileObjArray.length  >0 && this.state.id > 0)
                  ? <DownloadComponent filesObj={this.state.CurrentOrganizationStructureFileObjArray} />
                  : <span></span>
          }
      </div>
  </MDBCol>

  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
      <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
          {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].SuggestedOrganizationStructure}
          <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
      </label>
      
      <div className="input-group">
          <div className="custom-file-inp">
              <input
                  id="SuggestedOrganizationStructureId"
                  type="file"
                  name="SuggestedOrganizationStructure"
                  onChange={(event) => this.SuggestedOrganizationStructureOnChange(event)}
                  required
                 // accept=".pdf,.doc,.docx"
              />
              <label htmlFor="SuggestedOrganizationStructureId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                  {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
              </label>
              <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="SuggestedOrganizationStructureId" className="fileName"><span>{this.state.SuggestedOrganizationStructureFileName}</span></label>
          </div>
          {
              this.state.SuggestedOrganizationStructureFileErr === 'thisFieldIsRequired' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].thisFieldIsRequired}
                  </div>
                  :
                  null
          }
          {
              this.state.SuggestedOrganizationStructureFileErr === 'invalidFileType' ?
                  <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                      {Locali18n[this.getLocale()].invalidFileType}
                  </div>
                  :
                  null
          }
          {
              this.state.SuggestedOrganizationStructureFileErr === 'invalidFileSize' ?
                  <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                      {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                  </div>
                  :
                  null
          }

          <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
              {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORG}
          </p>
          {
              (this.state.SuggestedOrganizationStructureFileObjArray &&
                  this.state.SuggestedOrganizationStructureFileObjArray.length  >0 && this.state.id > 0)
                  ? <DownloadComponent filesObj={this.state.SuggestedOrganizationStructureFileObjArray} />
                  : <span></span>
          }
      </div>
  </MDBCol>
</MDBRow>



                 <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                   
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
                            {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].EntityEstablishmentLaw}
                            <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EntityEstablishmentLawFileId"
                                    type="file"
                                    name="EntityEstablishmentLawFile"
                                    onChange={(event) => this.EntityEstablishmentLawFileOnChange(event)}
                                    required
                                   // accept=".pdf,.doc,.docx"
                                />
                                <label htmlFor="EntityEstablishmentLawFileId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
                                </label>

                                <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="EntityEstablishmentLawFileId" className="fileName"><span>{this.state.EntityEstablishmentLawFileName}</span></label>
                            </div>
                            {
                                this.state.EntityEstablishmentLawFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EntityEstablishmentLawFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EntityEstablishmentLawFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                        {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }

                            <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORG}
                            </p>
                            {
                                (this.state.EntityEstablishmentLawFileObjArray &&
                                    this.state.EntityEstablishmentLawFileObjArray.length  >0 && this.state.id > 0)
                                    ? <DownloadComponent filesObj={this.state.EntityEstablishmentLawFileObjArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
                            {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].RequestReasonFile}
                            <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="RequestReasonFileId"
                                    type="file"
                                    name="RequestReasonFile"
                                    onChange={(event) => this.RequestReasonFileOnChange(event)}
                                    required
                                   // accept=".pdf,.doc,.docx"
                                />
                                <label htmlFor="RequestReasonFileId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} htmlFor="RequestReasonFileId" className="fileName"><span>{this.state.RequestReasonFileName}</span></label>
                            </div>
                            {
                                this.state.RequestReasonFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.RequestReasonFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.RequestReasonFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                        {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }

                            <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORG}
                            </p>
                            {
                                (this.state.RequestReasonFileObjArray &&
                                    this.state.RequestReasonFileObjArray.length  >0 && this.state.id > 0)
                                    ? <DownloadComponent filesObj={this.state.RequestReasonFileObjArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                 <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', width: '100%' }}>
                            {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].EntityFormalRequest}
                            <span className="requireStar" style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EntityFormalRequestFileId"
                                    type="file"
                                    name="EntityFormalRequestFile"
                                    onChange={(event) => this.EntityFormalRequestFileOnChange(event)}
                                    required
                                   // accept=".pdf,.doc,.docx"
                                />
                                <label htmlFor="EntityFormalRequestFileId" className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} 
                                htmlFor="EntityFormalRequestFileId" className="fileName">
                                    <span>{this.state.EntityFormalRequestFileName}</span></label>
                            </div>
                            {
                                this.state.EntityFormalRequestFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EntityFormalRequestFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EntityFormalRequestFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                        {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }

                            <p style={{ display: "flex", direction:  this.state.isArabic  ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORG}
                            </p>
                            {
                                (this.state.EntityFormalRequestFileObjArray &&
                                    this.state.EntityFormalRequestFileObjArray.length  >0 && this.state.id > 0)
                                    ? <DownloadComponent filesObj={this.state.EntityFormalRequestFileObjArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                   
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group"  >
                            <label style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }} >{Locali18n[ this.state.isArabic  ? 'ar' : 'en'].otherattachemnt}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="otherFile"
                                        type="file"
                                        name="others"
                                        multiple
                                        onChange={(event) => this.otherAttachmentOnChnage(event)}
                                    />
                                    <label
                                        htmlFor="otherFile"
                                        className={ this.state.isArabic  ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection:  this.state.isArabic 
                                                ? "row"
                                                : "row-reverse"
                                        }}>
                                        {Locali18n[ this.state.isArabic  ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="otherFile"
                                        className="fileName"
                                        style={{ textAlign:  this.state.isArabic  ? "right" : "" }}>
                                        {/* {this.state.otherAttachment} */}

                                        <span className={`${ this.state.isArabic  ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.otherAttachmentShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.otherAttachmentShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.otherAttachment}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.otherAttchmentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign:  this.state.isArabic  ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                            {Locali18n[ this.state.isArabic  ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}>
                                            {Locali18n[ this.state.isArabic  ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ color: '#000', display: "flex", flexDirection: ! this.state.isArabic  ? "row-reverse" : "", width: '100%' }}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].acceptedFormatsORG}
                            </p>

                            <p style={{ display: "flex", flexDirection:  this.state.isArabic  ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {//Locali18n[ this.state.isArabic  ? "ar" : "en"].allowedMultipleFile
                            }
                            </p>
                            {
                                (this.state.otherAttachmentArray &&
                                    this.state.otherAttachmentArray.length  >0 && this.state.id > 0)
                                    ? <DownloadComponent filesObj={this.state.otherAttachmentArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: "flex", flexDirection:  this.state.isArabic  ? "row-reverse" : "row" }}>
                  {/*   {
                        ((!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) && !(this.state.statusreasonid === Config.getReasonForReturnToEntityId())) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].preview}
                            </MDBBtn>
                    } */}
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn"  color="primary" id='1'
                            onClick={(e)=>this.submitApiCall(e)}>
                                {Locali18n[ this.state.isArabic  ? "ar" : "en"].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" color="primary" id='0'
                     onClick={(e)=>this.submitApiCall(e)}>
                        {Locali18n[ this.state.isArabic  ? "ar" : "en"].submit}
                    </MDBBtn>
                </MDBRow>
            </form>

            <MDBRow style={{ display: 'flex', flexDirection:  this.state.isArabic  ? 'row-reverse' : 'row' }}></MDBRow>
                        </MDBCol>

                        <SideBar
                            
                            canvieworgrequest={this.state.canvieworgrequest}
                            canvieworgrequesttree={this.state.canvieworgrequesttree}

                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>

                </LoadingOverlay>

            </MDBContainer>

        );
    }
}


