import React, { Component } from 'react';
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";

export default class TableDataComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <MDBTable striped responsive hover style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                <MDBTableHead>
                    <tr style={{ textAlign: "center" }}>
                        {

                            this.props.tableHeaderArray ? this.props.tableHeaderArray.map((item, index) => {
                                // if (!this.state.showNumberOfDaysData) {
                                //     return;
                                // }
                                return (
                                    <th>
                                        {item.tableHeader}
                                    </th>
                                )
                            })
                                :
                                ''
                        }
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        this.props.historyArray ? this.props.historyArray.map((item, index) => {
                            return (<tr style={{ textAlign: "center" }}>
                                <td>
                                    {item.updatedby}
                                </td>
                                <td>
                                    {item.updatedon}
                                </td>
                                <td>
                                    {item.status}
                                </td>
                                <td>
                                    {item.statusreason}
                                </td>
                            </tr>)
                        }) : ''
                    }
                </MDBTableBody>
            </MDBTable>
        );
    };
}