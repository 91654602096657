import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import Validator from './../../utils/Validator';
import DownloadComponent from "../DownloadComponent";
import CKEditorComponent from '../CKEditorComponent';
const striptags = require('striptags');

export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: '',
            statusid: '',
            statusreasonid: '',
            requesttypeid: '',
            entityName: this.props.sendData.entityName,
            requestTitle: this.props.sendData.requestTitle,
            requestDetails: this.props.sendData.requestDetails,
            requestDate: this.props.sendData.requestDate,
            intervalFromDate: this.props.sendData.intervalFromDate,
            intervalToDate: this.props.sendData.intervalToDate,
            employeeName: this.props.sendData.employeeName,
            jobDesc: this.props.sendData.jobDesc,
            contactNumber: this.props.sendData.contactNumber,
            email: this.props.sendData.email,
            // attachment
            otherAttachment: this.props.sendData.otherAttachment,
            otherAttachmentShortName: this.props.sendData.otherAttachmentShortName,
            otherAttachmentFileObjArray: [],
            // error variables
            requestDateErr: '',
            emailErr: '',
            contactNumberErr: '',
            intervalFromDateErr: '',
            intervalToDateErr: '',
            requestDetailsErr: '',
            jobDescErr: '',

            // edit attachment array
            editAttachmentArray: [],
            otherAttachmentArray: []
        }

        this.handleRequestDate = this.handleRequestDate.bind(this);
        this.handleIntervalFromDate = this.handleIntervalFromDate.bind(this);
        this.handleIntervalToDate = this.handleIntervalToDate.bind(this);
    }

    componentDidMount = () => {
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            let getloginDataReceivedFirstTime = Config.getloginDataReceivedFirstTime()
            // get email, contact and , entity id and entity name from login
            if (loginData && !getloginDataReceivedFirstTime) {
                let tempEntityName = this.props.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
                let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
                this.setState({
                    employeeName: tempEmployeeName,
                    entityName: tempEntityName,
                    jobDesc: loginData && loginData.JobTitle,
                    contactNumber: loginData && loginData.ContactNumber,
                    email: loginData && loginData.Email
                }, () => {
                    Config.setLoginDataReceivedFirstTime(true);
                })
            }
        }

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                requesttypeid: editObj.requesttypeid,
                statusid: editObj.statusid,
                statusreasonid: editObj.statusreasonid,
                requestTitle: editObj.requesttitle,
                email: editObj.email,
                requestDate: editObj.requestdate ? new Date(`${editObj.requestdate}`) : null,
                employeeName: editObj.employeename,
                intervalFromDate: editObj.fromdate ? new Date(`${editObj.fromdate}`) : null,
                intervalToDate: editObj.todate ? new Date(`${editObj.todate}`) : null,
                contactNumber: editObj.contactnumber ? editObj.contactnumber : '',
                requestDetails: editObj.requestdetails,
                jobDesc: editObj.jobtitle,
                editAttachmentArray: editObj.attachments
            }, () => {
                let tempOtherAttachmentArray = [];
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getOtherAttachmentsExtendCreateObjectives()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                if (this.state.requestId) {
                                    temp.requestId = this.state.requestId;
                                } else {
                                    temp.requestId = editObj.requestId
                                }
                                tempOtherAttachmentArray.push(temp);
                            }
                        })
                        this.setState({
                            otherAttachmentArray: tempOtherAttachmentArray
                        })
                    }
                }
            });
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";

        let
            requestDateErr = '',
            intervalFromDateErr = '',
            intervalToDateErr = '',
            contactNumberErr = '',
            emailErr = '';

        if (!this.state.requestDate) {
            requestDateErr = 'thisFieldIsRequired';
        }

        if (!this.state.intervalFromDate) {
            intervalFromDateErr = 'thisFieldIsRequired';
        }

        if (!this.state.intervalToDate) {
            intervalToDateErr = 'thisFieldIsRequired';
        } else {
            if (this.state.intervalFromDate && this.state.intervalToDate) {
                if (this.state.intervalFromDate > this.state.intervalToDate) {
                    intervalToDateErr = 'invalidToDate';
                }
            }
        }

        if (this.state.contactNumber) {
            if (!Validator.validateContactNumber(this.state.contactNumber)) {
                contactNumberErr = 'invalidContactNumber';
            }
        }
        else {
            contactNumberErr = 'thisFieldIsRequired';
        }

        if (this.state.email) {
            if (!Validator.validateEmail(this.state.email)) {
                emailErr = 'invalidEmail';
            } else {
                emailErr = '';
            }
        }
        else {
            emailErr = 'thisFieldIsRequired';
        }

        let tempJobDescErr = "";
        if (!this.state.jobDesc) {
            tempJobDescErr = 'thisFieldIsRequired';
        }

        let tempReqDetailsErr = "";
        if (this.state.requestDetails && this.state.requestDetails.trim()) {
            let val = striptags(this.state.requestDetails.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                tempReqDetailsErr = 'thisFieldIsRequired';
            }
        } else {
            tempReqDetailsErr = 'thisFieldIsRequired';
        }

        this.setState({
            requestDateErr: requestDateErr,
            intervalFromDateErr: intervalFromDateErr,
            intervalToDateErr: intervalToDateErr,
            contactNumberErr: contactNumberErr,
            emailErr: emailErr,
            requestDetailsErr: tempReqDetailsErr,
            jobDescErr: tempJobDescErr
        });

        if (
            !requestDateErr &&
            !intervalFromDateErr &&
            !intervalToDateErr &&
            !contactNumberErr &&
            !emailErr &&
            !tempReqDetailsErr &&
            !tempJobDescErr
        ) {
            if (
                this.state.entityName &&
                this.state.requestTitle &&
                striptags(this.state.requestDetails) &&
                this.state.requestDate &&
                this.state.employeeName &&
                striptags(this.state.jobDesc) &&
                this.state.contactNumber &&
                this.state.email
            ) {

                this.props.getData.stepOneData({
                    isEdit: this.state.isEdit,
                    requestId: this.state.requestId,
                    entityName: this.state.entityName,
                    requestTitle: this.state.requestTitle,
                    requestDetails: this.state.requestDetails,
                    requestDate: this.state.requestDate,
                    intervalFromDate: this.state.intervalFromDate,
                    intervalToDate: this.state.intervalToDate,
                    employeeName: this.state.employeeName,
                    jobDesc: this.state.jobDesc,
                    contactNumber: this.state.contactNumber,
                    email: this.state.email,
                    // attchment names
                    otherAttachment: this.state.otherAttachment,
                    otherAttachmentShortName: this.state.otherAttachmentShortName,
                });
            }
        }
    };

    handleEntityName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim()) {
            if (regex.test(event.target.value)) {
                this.setState({ entityName: event.target.value });
            }
        } else {
            this.setState({ entityName: '' });
        }
    };

    handleRequestTitle = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim()) {
            if (regex.test(event.target.value)) {
                this.setState({ requestTitle: event.target.value });
            }
        } else {
            this.setState({ requestTitle: '' });
        }
    };

    handleRequestDetails = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                requestDetailsErr: "",
                requestDetails: newContent
            })
        }
        else {
            this.setState({
                requestDetailsErr: "ckeditorCountErr"
            })
        }
    }

    handleJobDesc = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim()) {
            if (regex.test(event.target.value)) {
                this.setState({
                    jobDescErr: "",
                    jobDesc: event.target.value
                })
            }
        }
        else {
            this.setState({
                jobDesc: ""
            })
        }
    }

    handleRequestDate(date) {
        if (date) {
            this.setState({
                requestDate: date,
                requestDateErr: ''
            });
        }
        else {
            this.setState({
                requestDate: null,
                requestDateErr: 'thisFieldIsRequired'
            });
        }

    }

    handleIntervalFromDate(date) {
        if (date) {
            this.setState({
                intervalFromDate: date,
                intervalFromDateErr: ''
            });
        }
        else {
            this.setState({
                intervalFromDate: null,
                intervalFromDateErr: 'thisFieldIsRequired'
            });
        }
    };

    handleIntervalToDate(date) {
        if (date) {
            this.setState({
                intervalToDate: date,
                intervalToDateErr: ''
            });
        }
        else {
            this.setState({
                intervalToDate: null,
                intervalToDateErr: 'thisFieldIsRequired'
            });
        }
    };

    handleEmployeeName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim()) {
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };

    handleContactNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({
                contactNumber: event.target.value
            }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ contactNumberErr: '' });
                } else {
                    this.setState({ contactNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ contactNumber: '', contactNumberErr: 'thisFieldIsRequired' });
        }
    };

    handleEmail = (event) => {
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ email: event.target.value, emailErr: '' });
            }
        }
        else {
            this.setState({ email: '', emailErr: 'thisFieldIsRequired' });
        }
    };

    onRequestDatepickerRef(el) {
        if (el && el.input) {
            if (this.state.requestDate !== null) {
                el.input.disabled = true;
                el.input.readOnly = false;
                el.input.classList.remove('white_bg');
            } else {
                el.input.disabled = false;
                el.input.readOnly = true;
                el.input.classList.add('white_bg');
            }
            if (el.input.type === "blur") {
                el.input.target.style.backgroundColor = "white";
            }
        }
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    onDatepickerRef1(el) {
        if (el && el.input) {
            if (this.state.intervalFromDate === null) {
                el.input.disabled = true;
                el.input.readOnly = false;
                el.input.classList.remove('white_bg');
            } else {
                el.input.disabled = false;
                el.input.readOnly = true;
                el.input.classList.add('white_bg');
            }
            if (el.input.type === "blur") {
                el.input.target.style.backgroundColor = "white";
            }
        }
    }

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestTitle: this.state.requestTitle,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            intervalFromDate: this.state.intervalFromDate,
            intervalToDate: this.state.intervalToDate,
            employeeName: this.state.employeeName,
            jobDesc: this.state.jobDesc,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            // attchment names
            otherAttachment: this.state.otherAttachment,
            otherAttachmentShortName: this.state.otherAttachmentShortName,
        });
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestTitle: this.state.requestTitle,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            intervalFromDate: this.state.intervalFromDate,
            intervalToDate: this.state.intervalToDate,
            employeeName: this.state.employeeName,
            jobDesc: this.state.jobDesc,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            // attchment names
            otherAttachment: this.state.otherAttachment,
            otherAttachmentShortName: this.state.otherAttachmentShortName,
        });
    };

    otherAttachmentOnChnage = (e) => {
        let fileNames = "";
        let fileOtherAttachmnetArray = [];

        if (e.target.files.length > 0) {

            if (e.target.files.length <= Config.getMaxFileCount()) {

                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            otherAttachment: '',
                            otherAttachmentShortName: '',
                            otherAttchmentErr: 'invalidFileType'
                        });
                        break;
                    }
                    else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let otherAttachmentShortName = fileNames.substring(0, 50);

                            let reader = new FileReader();
                            let tempFile = e.target.files[m];
                            reader.onloadend = () => {
                                fileOtherAttachmnetArray.push(reader.result);
                                this.setState({
                                    otherAttachmentFileObjArray: fileOtherAttachmnetArray,
                                }, () => {
                                    Config.setbase64OtherAttachmentForExtendCreate(this.state.otherAttachmentFileObjArray);
                                });
                            };
                            reader.readAsDataURL(tempFile);
                            this.setState({
                                otherAttachment: fileNames,
                                otherAttachmentShortName: otherAttachmentShortName,
                                otherAttchmentErr: ''
                            });
                        } else {
                            e.target.value = '';
                            this.setState({
                                otherAttachment: '',
                                otherAttachmentShortName: '',
                                otherAttchmentErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    otherAttachment: "",
                    otherAttachmentShortName: "",
                    otherAttchmentErr: 'fileLimitErr'
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({
                otherAttachment: '',
                otherAttachmentShortName: '',
                otherAttchmentErr: ''
            });
        }
    };

    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.entityName}<span className="requireStar">*</span></label>
                            <input
                                disabled={true}
                                value={this.state.entityName}
                                onChange={this.handleEntityName}
                                type="text"
                                id="entityName"
                                className="form-control"
                                name="entityName"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestTitle}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.requestTitle}
                                onChange={this.handleRequestTitle}
                                type="text"
                                id="defaultFormRegisterPasswordEx4"
                                className="form-control"
                                name="requestTitle"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.email}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleEmail}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.email}
                                required
                            />
                            {
                                this.state.emailErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emailErr === 'invalidEmail' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmail}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestDate}<span className="requireStar">*</span></label>
                            <div
                                style={{
                                    width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                }}
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.requestDateErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    disabled={true}
                                    minDate={new Date()}
                                    className="form-control"
                                    todayButton={"Today"}
                                    style={{ width: '100%' }}
                                    selected={this.state.requestDate}
                                    onChange={this.handleRequestDate}
                                    ref={el => this.onRequestDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required />
                            </div>
                            {
                                this.state.requestDateErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.extendCreatingObjectivesIntervalFromDate}<span className="requireStar">*</span></label>
                            <div
                                style={{
                                    width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                }}
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.intervalFromDateErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    style={{ width: '100%' }}
                                    minDate={this.state.requestDate}
                                    selected={this.state.intervalFromDate}
                                    onChange={this.handleIntervalFromDate}
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required />
                            </div>
                            {
                                this.state.intervalFromDateErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.extendCreatingObjectivesIntervalToDate}<span className="requireStar">*</span></label>
                            <div
                                style={{
                                    width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                }}
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.intervalToDateErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    style={{ width: '100%' }}
                                    // placeholderText={i18n[this.props.isArabic ? 'ar' : 'en'].toDate}
                                    minDate={this.state.intervalFromDate}
                                    selected={this.state.intervalToDate}
                                    onChange={this.handleIntervalToDate}
                                    disabled={this.state.intervalFromDate === null ? true : false}
                                    ref={el => this.onDatepickerRef1(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    required
                                />
                            </div>
                            {
                                this.state.intervalToDateErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.intervalToDateErr === 'invalidToDate' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidToDate}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.employeeName}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="employeeName"
                                name="employeeName"
                                onChange={this.handleEmployeeName}
                                value={this.state.employeeName}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.jobDesc}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="jobDesc"
                                name="jobDesc"
                                onChange={this.handleJobDesc}
                                value={this.state.jobDesc}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.contactNumber}<span className="requireStar">*</span></label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                className="form-control"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                required
                            />
                            {
                                this.state.contactNumberErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.contactNumberErr === 'invalidContactNumber' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group"  >
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} >{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.otherattachemnt}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="otherFile"
                                        type="file"
                                        name="others"
                                        multiple
                                        onChange={(event) => this.otherAttachmentOnChnage(event)}
                                    />
                                    <label
                                        htmlFor="otherFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic ? "row" : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="otherFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        {/* {this.state.otherAttachment} */}
                                        <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.otherAttachmentShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.otherAttachmentShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.otherAttachment}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.otherAttchmentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                            </p>
                            {
                                (this.state.otherAttachmentArray &&
                                    this.state.otherAttachmentArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.otherAttachmentArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestDetails}<span className="requireStar">*</span></label>

                            <div style={{ border: this.state.requestDetailsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.props.isArabic}
                                    content={this.state.requestDetails}
                                    onChange={this.handleRequestDetails}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.requestDetailsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    {
                        ((!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) && !(this.state.statusreasonid === Config.getReasonForReturnToEntityId())) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.props.isArabic ? "ar" : "en"].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.props.isArabic ? "ar" : "en"].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn type="submit" color="primary" className="submitBtn" >
                        {i18n[this.props.isArabic ? "ar" : "en"].submit}
                    </MDBBtn>
                </MDBRow>
            </form>
        );
    }
}