import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import i18n from '../i18n';
import './../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import SubHeaderStrip from '../components/SubHeaderStrip';
import StepOne from '../components/OpenComplainsStage/StepOne';
import Config from '../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import ApiRequest from './../services/ApiRequest';
import { saveOpenComplainsStagePayload, uploadDocument } from '../utils/Payload';

let moment = require('moment');

export default class OpenComplainsStage extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            isEdit: '',
            requestId: '',
            draft: '',
            statusid: '',
            step1: true,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isNextScreenActive: 1,
            isEdit: '',
            requestId: '',
            entityName: '',
            requestTitle: '',
            requestDetails: '',
            requestDate: new Date(),
            datesForOpeningComplainsStageFrom: null,
            datesForOpeningComplainsStageTo: null,
            employeeName: '',
            jobDesc: '',
            contactNumber: '',
            email: '',
            // attachment 
            otherAttachment: '',
            otherAttachmentShortName: '',
            otherAttachmentFileObjArray: [],
            // data services
            canviewextendcreaterequest: 0,
            canviewextendreviewrequest: 0,
            canviewextendannualrequest: 0,
            canviewemployeeperformancerequest: 0,
            canviewjoinimplementrequest: 0,
            canviewopencomplainsrequest: 0,
            canviewamendsystemsrequest: 0,
            canviewreportsstaticsrequest: 0,
            canviewotherrequestsrequest: 0,
            // end of data services varibles
        };
    }

    componentDidMount = () => {
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    // Data services permission
                    canviewextendcreaterequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendcreaterequest,
                    canviewextendreviewrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendreviewrequest,
                    canviewextendannualrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendannualrequest,
                    canviewemployeeperformancerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewemployeeperformancerequest,
                    canviewjoinimplementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewjoinimplementrequest,
                    canviewopencomplainsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewopencomplainsrequest,
                    canviewamendsystemsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsystemsrequest,
                    canviewreportsstaticsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreportsstaticsrequest,
                    canviewotherrequestsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewotherrequestsrequest
                }, () => {
                })
            })
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='okaaa'  name={i18n[this.getLocale()].ok}/>
        });
        setTimeout(() => {
            document.getElementById('okaaa').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='okaaa'  name={i18n[this.getLocale()].ok}/>
        });
        setTimeout(() => {
            document.getElementById('okaaa').focus()
        }, 100);
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    stepOneData = (obj) => {
        this.setState({
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            draft: obj.formType,
            entityName: obj.entityName,
            requestTitle: obj.requestTitle,
            requestDetails: obj.requestDetails,
            requestDate: obj.requestDate,
            datesForOpeningComplainsStageFrom: obj.datesForOpeningComplainsStageFrom,
            datesForOpeningComplainsStageTo: obj.datesForOpeningComplainsStageTo,
            employeeName: obj.employeeName,
            jobDesc: obj.jobDesc,
            contactNumber: obj.contactNumber,
            email: obj.email,
            // attchment names
            otherAttachment: obj.otherAttachment,
            otherAttachmentShortName: obj.otherAttachmentShortName,
        }, () => {
            this.submitApiCall();
        });
    }

    uploadFile = (i, totalCount, res, multifileCount) => {
       
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let isLastFileInMultiUpload = false;
            let obj = {};
           
            if (i === 0) {
                // get base 64 for attachement
                let fileObjArray = Config.getbase64OtherAttachmentForOpenGravience();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }
                if (this.state.otherAttachment) {
                    let filenamesArray = this.state.otherAttachment.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                obj.filetype = Config.getOtherAttachmentsOpenComplainsStage();
                tempBase64 = fileObj;
            }
            if (obj.filename && tempBase64) {
                let reqId = 0;
                if (res && res.data && res.data.id) {
                    reqId = res.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForOtherAttachmentFile => {
                    if (resForOtherAttachmentFile.Status) {
                        if (i === 0) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, res, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, res, multifileCount + 1);
                            }
                        } else {
                            this.uploadFile(i + 1, totalCount, res, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            if (this.state.draft === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })
            } else {
               
                this.uploadFile(i + 1, totalCount, res, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
               
                if (this.state.isEdit) {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                    }
                } else {
                   
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    }

    submitApiCall = () => {
     

        let reqObj = {};
        reqObj.requestId = this.state.requestId;
        reqObj.requesttitle = this.state.requestTitle;
        reqObj.email = this.state.email;
        let tempDate = '';
        if (this.state.datesForOpeningComplainsStageFrom !== '' && this.state.datesForOpeningComplainsStageFrom !== null) {
            tempDate = new Date(this.state.datesForOpeningComplainsStageFrom);
            let day = tempDate.getDate();
            let month = tempDate.getMonth() + 1;
            let year = tempDate.getFullYear();
            reqObj.fromdate = year + '-' + month + '-' + day;
        } else {
            reqObj.fromdate = tempDate;
        }
        let tempDateOne = '';
        if (this.state.datesForOpeningComplainsStageTo !== '' && this.state.datesForOpeningComplainsStageTo !== null) {
            tempDateOne = new Date(this.state.datesForOpeningComplainsStageTo);
            let day = tempDateOne.getDate();
            let month = tempDateOne.getMonth() + 1;
            let year = tempDateOne.getFullYear();
            reqObj.todate = year + '-' + month + '-' + day;
        } else {
            reqObj.todate = tempDateOne;
        }
        let tempDateThree = '';
        if (this.state.requestDate !== '' && this.state.requestDate !== null) {
            tempDateThree = new Date(this.state.requestDate);
            let day = tempDateThree.getDate();
            let month = tempDateThree.getMonth() + 1;
            let year = tempDateThree.getFullYear();
            reqObj.requestdate = year + '-' + month + '-' + day;
        } else {
            reqObj.requestdate = tempDateThree;
        }
        reqObj.employeename = this.state.employeeName;
        reqObj.contactnumber = this.state.contactNumber;
        reqObj.requestdetails = this.state.requestDetails;
        reqObj.jobdescription = this.state.jobDesc;

        if (this.state.draft === "saveAsDraft") {
            reqObj.draft = 1;
        } else {
            reqObj.draft = 0;
        }

        let payload = saveOpenComplainsStagePayload(reqObj);
        let endPoint = 'SaveOpenGrievanceProcess';
        let url = Config.getUrl(endPoint);
       
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                if (res.Status) {
                    let totalFile = 1;
                    this.uploadFile(0, totalFile, res, 0);
                    // if (this.state.isEdit) {
                    //     if (this.state.draft === "saveAsDraft") {
                    //         this.setState({
                    //             titleAlert: i18n[this.getLocale()].success,
                    //             messageAlert: i18n[this.getLocale()].save_as_Draft
                    //         }, () => {
                    //             this.alertSubmitForSuccess();
                    //         })
                    //     } else {
                    //         if (this.state.statusid === Config.getDraftId()) {
                    //             this.setState({
                    //                 titleAlert: i18n[this.getLocale()].success,
                    //                 messageAlert: i18n[this.getLocale()].request_created
                    //             }, () => {
                    //                 this.alertSubmitForSuccess();
                    //             })
                    //         } else {
                    //             this.setState({
                    //                 titleAlert: i18n[this.getLocale()].success,
                    //                 messageAlert: i18n[this.getLocale()].request_updated
                    //             }, () => {
                    //                 this.alertSubmitForSuccess();
                    //             })
                    //         }
                    //     }
                    // } else {
                    //     if (this.state.draft === "saveAsDraft") {
                    //         this.setState({
                    //             titleAlert: i18n[this.getLocale()].success,
                    //             messageAlert: i18n[this.getLocale()].save_as_Draft
                    //         }, () => {
                    //             this.alertSubmitForSuccess();
                    //         })
                    //     } else {
                    //         this.setState({
                    //             titleAlert: i18n[this.getLocale()].success,
                    //             messageAlert: i18n[this.getLocale()].request_created
                    //         }, () => {
                    //             this.alertSubmitForSuccess();
                    //         })
                    //     }
                    // }
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message
                        }, () => {
                            this.alertSubmit();
                        })
                    })
                }
            })
        })
    }

    getData = {
        stepOneData: this.stepOneData,
    };

    toggleSideBarSize = () => {

        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });

    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    componentWillMount = () => {
        let previewFlag = Config.getIsPreviewFlag();
        let previewCKObj = Config.getPreviewckEditorArray();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();
        let otherattachemnt = '';

        if (previewFlag) {

            let requestDetails = '';
            if (previewCKObj) {
                for (let index = 0; index < previewCKObj.length; index++) {
                    if (previewCKObj[index].label === 'requestDetails') {
                        requestDetails = previewCKObj[index].value;
                    }
                }
            }

            if (previewAttachmentArray) {
                for (let index = 0; index < previewAttachmentArray.length; index++) {
                    if (previewAttachmentArray[index].label === 'otherattachemnt') {
                        otherattachemnt = previewAttachmentArray[index].fileName;
                    }
                }
            }

            this.setState({
                step1: true,
                isEdit: previewObj.isEdit,
                requestId: previewObj.requestId,
                entityName: previewObj.entityname,
                requestTitle: previewObj.requestTitle,
                contactNumber: previewObj.contactNumber,
                requestDate: new Date(previewObj.requestDate),
                datesForOpeningComplainsStageFrom: previewObj.datesForOpeningComplainsStageFrom ? new Date(previewObj.datesForOpeningComplainsStageFrom) : null,
                datesForOpeningComplainsStageTo: previewObj.datesForOpeningComplainsStageto ? new Date(previewObj.datesForOpeningComplainsStageto) : null,
                email: previewObj.emailId,
                employeeName: previewObj.employeeName,
                requestDetails: requestDetails,
                jobDesc: previewObj.jobDesc,
                otherAttachment :otherattachemnt,
                otherAttachmentShortName: previewObj.otherAttachmentShortName
            });
        }
    }

    handlePreViewNavigation = (obj) => {
        let editObj = Config.getEditObj();
        let previewObj = {};
        previewObj.isEdit = Config.getIsEditFlag();
        previewObj.requestId = editObj.requestId;
        previewObj.entityname = obj.entityName;
        previewObj.requestTitle = obj.requestTitle;
        previewObj.contactNumber = obj.contactNumber;
        previewObj.requestDate = moment(obj.requestDate).format('L').toString();
        previewObj.emailId = obj.email;
        previewObj.employeeName = obj.employeeName;
        previewObj.jobDesc = obj.jobDesc;
        previewObj.otherAttachmentShortName = obj.otherAttachmentShortName;

        if (obj.datesForOpeningComplainsStageFrom) {
            previewObj.datesForOpeningComplainsStageFrom = moment(obj.datesForOpeningComplainsStageFrom).format('L').toString();
        }

        if (obj.datesForOpeningComplainsStageTo) {
            previewObj.datesForOpeningComplainsStageto = moment(obj.datesForOpeningComplainsStageTo).format('L').toString();
        }

        let ckEditorArr = [
            {
                label: 'requestDetails',
                value: obj.requestDetails
            }
        ];

        let attachmentArr = [
            {
                label: 'otherattachemnt',
                fileName: obj.otherAttachment,
                fileObj: Config.getbase64OtherAttachmentForOpenGravience()
            }
        ];

        let temp = editObj;
        temp.requesttitle = obj.requestTitle;
        temp.email = obj.email;
        temp.requestdate = obj.requestDate ? moment(obj.requestDate).format('L').toString() : '';
        temp.todate = obj.datesForOpeningComplainsStageTo ? moment(obj.datesForOpeningComplainsStageTo).format('L').toString() : '';
        temp.fromdate = obj.datesForOpeningComplainsStageFrom ? moment(obj.datesForOpeningComplainsStageFrom).format('L').toString() : '';
        temp.employeename = obj.employeeName;
        temp.contactnumber = obj.contactNumber;
        temp.requestdetails = obj.requestDetails;
        temp.jobtitle = obj.jobDesc;

        Config.setEditObj(temp)

        localStorage.setItem("currentPath", "openComplainsStage");
        this.props.history.replace("/preview", { data: previewObj, ckEditorArr: ckEditorArr, attachmentArr: attachmentArr });
       
    };

    render() {

        const sideBarData = Config.getServiceSideBar('openComplainsStage', this.state.isArabic, this, 0);

        return (
            <MDBContainer fluid style={{ height: '100vh' }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>

                            <MDBRow>

                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'openComplainsStage' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].openComplainsStage}
                                    />
                                </MDBCol>

                            </MDBRow>

                            {
                                this.state.step1 ?
                                    <StepOne
                                        isArabic={this.state.isArabic}
                                        sendData={{
                                            // form variables
                                            entityName: this.state.entityName,
                                            requestTitle: this.state.requestTitle,
                                            requestDetails: this.state.requestDetails,
                                            requestDate: this.state.requestDate,
                                            datesForOpeningComplainsStageFrom: this.state.datesForOpeningComplainsStageFrom,
                                            datesForOpeningComplainsStageTo: this.state.datesForOpeningComplainsStageTo,
                                            employeeName: this.state.employeeName,
                                            jobDesc: this.state.jobDesc,
                                            contactNumber: this.state.contactNumber,
                                            email: this.state.email,
                                            // attachment name
                                            otherAttachment: this.state.otherAttachment,
                                            otherAttachmentShortName: this.state.otherAttachmentShortName,
                                        }}
                                        getData={this.getData}
                                        previewPage={this.handlePreViewNavigation}
                                        saveAsDraft={this.stepOneData}
                                    />
                                    : null
                            }

                            <MDBRow style={{ height: '10vh' }}> <Footer isArabic={this.state.isArabic} /></MDBRow>

                        </MDBCol>

                        <SideBar
                            // data services
                            canviewextendcreaterequest={this.state.canviewextendcreaterequest}
                            canviewextendreviewrequest={this.state.canviewextendreviewrequest}
                            canviewextendannualrequest={this.state.canviewextendannualrequest}
                            canviewemployeeperformancerequest={this.state.canviewemployeeperformancerequest}
                            canviewjoinimplementrequest={this.state.canviewjoinimplementrequest}
                            canviewopencomplainsrequest={this.state.canviewopencomplainsrequest}
                            canviewamendsystemsrequest={this.state.canviewamendsystemsrequest}
                            canviewreportsstaticsrequest={this.state.canviewreportsstaticsrequest}
                            canviewotherrequestsrequest={this.state.canviewotherrequestsrequest}
                            // end of data services
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>

                </LoadingOverlay>

            </MDBContainer>

        );
    }
}