import React, { Component } from 'react'

export default class PreviewItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            salaryStatusArr: [
                {
                    id: 1,
                    EnglishName: 'Continuous',
                    ArabicName: 'مستمر'
                },
                {
                    id: 2,
                    EnglishName: 'Stop',
                    ArabicName: 'متوقف'
                }
            ],
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    render() {
        return (
            <tr>
                <td style={{ textAlign: this.props.isArabic ? "right" : '' }}>{this.props.index + 1}</td>
                <td>
                    <div
                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                        className="form-group">
                        {this.props.item.year}
                    </div>
                </td>
                <td style={{ width: '15%' }}>
                    <div
                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                        className="form-group">
                        {this.props.item.month}
                    </div>
                </td>
                <td>
                    <div
                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                        className="form-group">
                        {this.props.item.fromDay}
                    </div>
                </td>
                <td>
                    <div
                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                        className="form-group">
                        {this.props.item.toDay}
                    </div>
                </td><td>
                    <div
                      style={{ textAlign: this.props.isArabic ? "right" : '' }}
                      className="form-group">
                        {
                            (
                                parseInt(this.props.item.salaryStatus) !== 1 &&
                                parseInt(this.props.item.salaryStatus) !== 2)
                                ?
                                this.props.item.salaryStatus
                                :
                                this.state.salaryStatusArr ? this.state.salaryStatusArr.map((itemStatus, index) => {

                                    return (
                                        parseInt(itemStatus.id) === parseInt(this.props.item.salaryStatus)
                                            ?
                                            this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName
                                            :
                                            null
                                    )

                                }) : null

                        }
                        {/* {
                            this.props.item.salaryStatus
                        } */}
                    </div>
                </td>
                <td>
                    <div
                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                        className="form-group">
                        {this.props.item.subTotal+'MGM'}
                    </div>
                </td>
                <td>
                    <div
                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                        className="form-group">
                        {this.props.item.noOfDays}
                    </div>
                </td>
            </tr>
        )
    }
}