import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class LeaveDetails extends Component {

     
    componentDidMount = () => {
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    render() {
        return(
        <React.Fragment>
        <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].SickLeave.LeaveSubject} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
                  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.YearlyLeaveBalance}<span className="requireStar">*</span></label>
                                  <input
                                      value={this.state.employeeName}
                                      onChange={this.handleEmployeeName}
                                      type="text"
                                      id="employeeName"
                                      className="form-control"
                                      name="empJob"
                                      required
                                      //maxlength="50"
                                      style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                  />
                                  <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                      {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                  </div>
                              </div>
                          </MDBCol>
                          <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.LeavePeriod}<span className="requireStar">*</span></label>
                                  <input
                                      value={this.state.employeeName}
                                      onChange={this.handleEmployeeName}
                                      type="text"
                                      id="employeeName"
                                      className="form-control"
                                      name="empJob"
                                      required
                                      //maxlength="50"
                                      style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                  />
                                  <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                      {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                  </div>
                              </div>
                          </MDBCol>
      
                     
      </MDBRow>
      
      <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.BacktoWorkDate}<span className="requireStar">*</span></label>
                                  <input
                                      value={this.state.employeeName}
                                      onChange={this.handleEmployeeName}
                                      type="text"
                                      id="employeeName"
                                      className="form-control"
                                      name="empJob"
                                      required
                                      //maxlength="50"
                                      style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                  />
                                  <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                      {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                  </div>
                              </div>
                          </MDBCol>
                      
                     
      </MDBRow>
      
      
            </div></div>
      
      </React.Fragment>);
    }
}