import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import CKEditorComponent from '../CKEditorComponent';
import Validator from "../../utils/Validator";
import Select from 'react-select';
import DownloadComponent from "./../DownloadComponent";

const striptags = require('striptags');
const englishArray = [];
const arebicArray = [];

export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // steps: this.props.steps,
            requestId: '',
            isEdit: '',
            statusid: '',
            statusreasonid: '',
            requesttypeid: '',
            // attement one
            attachEntityAttestedPolicyFileInPDFObj: this.props.sendData.attachEntityAttestedPolicyFileInPDFObj,
            attachEntityAttestedPolicyFileInPDFFileName: this.props.sendData.attachEntityAttestedPolicyFileInPDFFileName,
            // attement two
            attachEntityAttestedOrgStructureFileInPDFObj: this.props.sendData.attachEntityAttestedOrgStructureFileInPDFObj,
            attachEntityAttestedOrgStructureFileInPDFFileName: this.props.sendData.attachEntityAttestedOrgStructureFileInPDFFileName,
            // attement three
            attachEntityCreationLawFileInPDFObj: this.props.sendData.attachEntityCreationLawFileInPDFObj,
            attachEntityCreationLawFileInPDFFileName: this.props.sendData.attachEntityCreationLawFileInPDFFileName,
            // attement four
            attachAgreementFromEntityTopManagementPDFFileObj: this.props.sendData.attachAgreementFromEntityTopManagementPDFFileObj,
            attachAgreementFromEntityTopManagementPDFFileName: this.props.sendData.attachAgreementFromEntityTopManagementPDFFileName,
            // attachmnet five
            otherAttachment: this.props.sendData.otherAttachment,
            otherAttachmentShortName: this.props.sendData.otherAttachmentShortName,
            otherAttachmentFileObjArray: [],
            // multiselect 
            systemTypeHRSystem_SelectedValue: this.props.sendData.systemTypeHRSystem_SelectedValue,
            // form variable
            employeeName: this.props.sendData.employeeName,
            jobDesc: this.props.sendData.jobDesc,
            contactNumber: this.props.sendData.contactNumber,
            email: this.props.sendData.email,
            entityName: this.props.sendData.entityName,
            requestTitle: this.props.sendData.requestTitle,
            requestDetails: this.props.sendData.requestDetails,
            requestDate: this.props.sendData.requestDate,
            // form variable
            requestDateErr: '',
            emailErr: '',
            contactNumberErr: '',
            systemTypeErr: '',
            // attament error
            attachEntityAttestedPolicyFileInPDFFileErr: '',
            attachEntityAttestedOrgStructureFileInPDFFileErr: '',
            attachEntityCreationLawFileInPDFFileErr: '',
            attachAgreementFromEntityTopManagementPDFFileErr: '',
            // edit attachment array
            editAttachmentArray: [],
            otherAttachmentArray: [],
            attestedPolicyFileArray: [],
            attestedOrganizationStrutureFileArray: [],
            entityCreationLawFileArray: [],
            agreementForEntityTopManagementArray: [],
            systemTypeArray: [],
        }
        this.handleRequestDate = this.handleRequestDate.bind(this);
    }

    componentDidMount = () => {
        let tempSystemArray = []
        if (Config.getSystemList()) {
            tempSystemArray = Config.getSystemList();
        }
        this.setState({
            systemTypeArray: tempSystemArray
        }, () => {
            let loginData = Config.getLocalStorageLoginData();
            let getloginDataReceivedFirstTime = Config.getloginDataReceivedFirstTime();

            if (loginData && !getloginDataReceivedFirstTime) {
                // get email, contact and , entity id and entity name from login
                let tempEntityName = this.props.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
                let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
                this.setState({
                    employeeName: tempEmployeeName,
                    entityName: tempEntityName,
                    jobDesc: loginData && loginData.JobTitle,
                    contactNumber: loginData && loginData.ContactNumber,
                    email: loginData && loginData.Email
                }, () => {
                    Config.setLoginDataReceivedFirstTime(true);
                })
            }

            if (this.state.systemTypeArray.length && this.state.systemTypeArray) {
                if (this.state.systemTypeArray.length !== englishArray.length) {
                    for (let index = 0; index < this.state.systemTypeArray.length; index++) {
                        englishArray.push({ value: this.state.systemTypeArray[index].englishtitle.toString(), label: this.state.systemTypeArray[index].englishtitle.toString() });
                        arebicArray.push({ value: this.state.systemTypeArray[index].arabictitle.toString(), label: this.state.systemTypeArray[index].arabictitle.toString() });
                    }
                }
            }


            let editFlag = Config.getIsEditFlag();
            let editObj = Config.getEditObj();
            if (editFlag) {
                this.setState({
                    requestId: editObj.requestId,
                    isEdit: editFlag,
                    requesttypeid: editObj.requesttypeid,
                    statusid: editObj.statusid,
                    statusreasonid: editObj.statusreasonid,
                    requestTitle: editObj.requesttitle,
                    email: editObj.email,
                    requestDate: editObj.requestdate ? new Date(editObj.requestdate) : null,
                    employeeName: editObj.employeename,
                    contactNumber: editObj.contactnumber,
                    requestDetails: editObj.requestdetails,
                    jobDesc: editObj.jobtitle,
                    systemTypeHRSystem_SelectedValue: editObj.systemlist,
                    editAttachmentArray: editObj.attachments
                }, () => {
                    let tempOtherAttachmentArray = [];
                    let tempAttestedPolicyFileArray = [];
                    let tempAttestedOrganizationStrutureFileArray = [];
                    let tempEntityCreationLawFileArray = [];
                    let tempAgreementForEntityTopManagementArray = [];
                    if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                        let tempAttactmentArray = [...this.state.editAttachmentArray];
                        if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                            tempAttactmentArray.forEach((item, index) => {
                                if (item.filetypeid === Config.getJoinAndImplementPolicyAttestedId()) {
                                    let temp = {}
                                    temp.url = item.aliasfilename;
                                    temp.fileName = item.filename;
                                    if (this.state.requestId) {
                                        temp.requestId = this.state.requestId;
                                    } else {
                                        temp.requestId = editObj.requestId
                                    }
                                    tempAttestedPolicyFileArray.push(temp);
                                } else if (item.filetypeid === Config.getJoinAndImplementOrgnisationStrutureId()) {
                                    let temp = {}
                                    temp.url = item.aliasfilename;
                                    temp.fileName = item.filename;
                                    if (this.state.requestId) {
                                        temp.requestId = this.state.requestId;
                                    } else {
                                        temp.requestId = editObj.requestId
                                    }
                                    tempAttestedOrganizationStrutureFileArray.push(temp);
                                } else if (item.filetypeid === Config.getJoinAndImplementEntityCreationLawId()) {
                                    let temp = {}
                                    temp.url = item.aliasfilename;
                                    temp.fileName = item.filename;
                                    if (this.state.requestId) {
                                        temp.requestId = this.state.requestId;
                                    } else {
                                        temp.requestId = editObj.requestId
                                    }
                                    tempEntityCreationLawFileArray.push(temp);
                                } else if (item.filetypeid === Config.getJoinAndImplementAttachAgreementFormEntityId()) {
                                    let temp = {}
                                    temp.url = item.aliasfilename;
                                    temp.fileName = item.filename;
                                    if (this.state.requestId) {
                                        temp.requestId = this.state.requestId;
                                    } else {
                                        temp.requestId = editObj.requestId
                                    }
                                    tempAgreementForEntityTopManagementArray.push(temp);
                                } else if (item.filetypeid === Config.getOtherAttachmentsImplementSystems()) {
                                    let temp = {}
                                    temp.url = item.aliasfilename;
                                    temp.fileName = item.filename;
                                    if (this.state.requestId) {
                                        temp.requestId = this.state.requestId;
                                    } else {
                                        temp.requestId = editObj.requestId
                                    }
                                    tempOtherAttachmentArray.push(temp);
                                }
                            })
                            this.setState({
                                otherAttachmentArray: tempOtherAttachmentArray,
                                attestedPolicyFileArray: tempAttestedPolicyFileArray,
                                attestedOrganizationStrutureFileArray: tempAttestedOrganizationStrutureFileArray,
                                entityCreationLawFileArray: tempEntityCreationLawFileArray,
                                agreementForEntityTopManagementArray: tempAgreementForEntityTopManagementArray
                            })
                        }
                    }
                })
            }
        })
    }

    submitHandler = (event) => {

        event.preventDefault();
        event.target.className += " was-validated";

        let
            tempRequestDateErr = "",
            tempAttchmentattachAgreementFromEntityTopManagementPDFFileErr = "", tempattachEntityAttestedPolicyFileInPDFFileErr = "",
            tempattachEntityCreationLawFileInPDFFileErr = "",
            tempattachEntityAttestedOrgStructureFileInPDFFileNameErr = "",
            tempEmailErr = "",
            tempContactNumberErr = "",
            systemTypeErr = "",
            tempJobDescErr = "",
            tempReqDetailsErr = "";

        if (!this.state.requestDate) {
            tempRequestDateErr = "thisFieldIsRequired";
        }

        //attachAgreementFromEntityTopMngemntPdfFIle
        if (this.state.agreementForEntityTopManagementArray && this.state.agreementForEntityTopManagementArray.length > 0) {
            tempAttchmentattachAgreementFromEntityTopManagementPDFFileErr = "";
        } else {
            if (!this.state.attachAgreementFromEntityTopManagementPDFFileName) {
                tempAttchmentattachAgreementFromEntityTopManagementPDFFileErr = "thisFieldIsRequired";
            }
        }

        //attachEntityAttestedPolicyFileInPDFFileName
        if (this.state.attestedPolicyFileArray && this.state.attestedPolicyFileArray.length > 0) {
            tempattachEntityAttestedPolicyFileInPDFFileErr = "";
        } else {
            if (!this.state.attachEntityAttestedPolicyFileInPDFFileName) {
                tempattachEntityAttestedPolicyFileInPDFFileErr = "thisFieldIsRequired";
            }
        }

        if (this.state.entityCreationLawFileArray && this.state.entityCreationLawFileArray.length > 0) {
            tempattachEntityCreationLawFileInPDFFileErr = "";
        } else {
            if (!this.state.attachEntityCreationLawFileInPDFFileName) {
                tempattachEntityCreationLawFileInPDFFileErr = "thisFieldIsRequired";
            }
        }

        //attachEntityCreationLawFileInPDFFileName
        if (this.state.attestedOrganizationStrutureFileArray && this.state.attestedOrganizationStrutureFileArray.length > 0) {
            tempattachEntityAttestedOrgStructureFileInPDFFileNameErr = "";
        } else {
            if (!this.state.attachEntityAttestedOrgStructureFileInPDFFileName) {
                tempattachEntityAttestedOrgStructureFileInPDFFileNameErr = "thisFieldIsRequired";
            }
        }

        if (this.state.email) {
            if (!Validator.validateEmail(this.state.email)) {
                tempEmailErr = "invalidEmail";
            }
        } else {
            tempEmailErr = "thisFieldIsRequired";
        }

        if (this.state.contactNumber) {
            if (!Validator.validateContactNumber(this.state.contactNumber)) {
                tempContactNumberErr = "invalidContactNumber";
            }
        }
        else {
            tempContactNumberErr = "thisFieldIsRequired";
        }

        if (this.state.systemTypeHRSystem_SelectedValue) {
            if (this.state.systemTypeHRSystem_SelectedValue.length == 0) {
                systemTypeErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.jobDesc && this.state.jobDesc.trim()) {
            let val = striptags(this.state.jobDesc.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                tempJobDescErr = 'thisFieldIsRequired';
            }
        } else {
            tempJobDescErr = 'thisFieldIsRequired';
        }

        if (this.state.requestDetails && this.state.requestDetails.trim()) {
            let val = striptags(this.state.requestDetails.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                tempReqDetailsErr = 'thisFieldIsRequired';
            }
        } else {
            tempReqDetailsErr = 'thisFieldIsRequired';
        }

        this.setState({
            reqDetailsErr: tempReqDetailsErr,
            jobDescErr: tempJobDescErr,
            systemTypeErr: systemTypeErr,
            contactNumberErr: tempContactNumberErr,
            requestDateErr: tempRequestDateErr,
            emailErr: tempEmailErr,
            attachAgreementFromEntityTopManagementPDFFileErr: tempAttchmentattachAgreementFromEntityTopManagementPDFFileErr,
            attachEntityAttestedOrgStructureFileInPDFFileErr: tempattachEntityAttestedOrgStructureFileInPDFFileNameErr,
            attachEntityCreationLawFileInPDFFileErr: tempattachEntityCreationLawFileInPDFFileErr,
            attachEntityAttestedPolicyFileInPDFFileErr: tempattachEntityAttestedPolicyFileInPDFFileErr
        }, () => {
            if (
                !this.state.jobDescErr &&
                !this.state.reqDetailsErr &&
                !this.state.contactNumberErr &&
                !this.state.emailErr &&
                !this.state.systemTypeErr &&
                !this.state.attachAgreementFromEntityTopManagementPDFFileErr &&
                !this.state.attachEntityAttestedOrgStructureFileInPDFFileErr &&
                !this.state.attachEntityCreationLawFileInPDFFileErr &&
                !this.state.attachEntityAttestedPolicyFileInPDFFileErr &&
                !this.state.requestDateErr
            ) {
                if (this.state.entityName &&
                    this.state.requestTitle &&
                    this.state.requestDetails &&
                    this.state.requestDate &&
                    this.state.systemTypeHRSystem_SelectedValue &&
                    this.state.employeeName && this.state.jobDesc &&
                    this.state.contactNumber && this.state.email
                ) {

                    this.props.getData.stepOneData({
                        isEdit: this.state.isEdit,
                        requestId: this.state.requestId,
                        // attchment names
                        otherAttachment: this.state.otherAttachment,
                        otherAttachmentShortName: this.state.otherAttachmentShortName,
                        attachEntityAttestedPolicyFileInPDFFileName: this.state.attachEntityAttestedPolicyFileInPDFFileName,
                        attachEntityAttestedOrgStructureFileInPDFFileName: this.state.attachEntityAttestedOrgStructureFileInPDFFileName,
                        attachEntityCreationLawFileInPDFFileName: this.state.attachEntityCreationLawFileInPDFFileName,
                        attachAgreementFromEntityTopManagementPDFFileName: this.state.attachAgreementFromEntityTopManagementPDFFileName,
                        // form variables
                        entityName: this.state.entityName,
                        requestTitle: this.state.requestTitle,
                        requestDetails: this.state.requestDetails,
                        requestDate: this.state.requestDate,
                        systemTypeHRSystem_SelectedValue: this.state.systemTypeHRSystem_SelectedValue,
                        employeeName: this.state.employeeName,
                        jobDesc: this.state.jobDesc,
                        contactNumber: this.state.contactNumber,
                        email: this.state.email
                    });
                }
            }
        })
    };

    handleEntityName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ entityName: event.target.value });
            }
        } else {
            this.setState({ entityName: "" }, () => {
            });
        }
    };

    handleRequestTitle = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ requestTitle: event.target.value });
            }
        } else {
            this.setState({ requestTitle: "" }, () => {
            });
        }
    };

    handleRequestDetails = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                requestDetails: newContent,
                reqDetailsErr: ""
            });
        } else {
            this.setState({ reqDetailsErr: "ckeditorCountErr" });
        }
    };

    handleRequestDate(date) {
        this.setState({
            requestDate: date,
            requestDateErr: ''
        });
    }

    handleEmployeeName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: "" }, () => {
            });
        }
    }

    handleJobDesc = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim()) {
            if (regex.test(event.target.value)) {
                this.setState({
                    jobDescErr: "",
                    jobDesc: event.target.value
                })
            }
        }
        else {
            this.setState({
                jobDesc: ""
            })
        }
    }

    handleEmailChange = (event) => {
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ email: event.target.value, emailErr: '' });
            } else {
                this.setState({ email: "", emailErr: "thisFieldIsRequired" });
            }
        } else {
            this.setState({ email: "", emailErr: "thisFieldIsRequired" });
        }
    };

    handleContactNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ contactNumber: event.target.value }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ contactNumberErr: '' });
                } else {
                    this.setState({ contactNumberErr: "invalidContactNumber" });
                }
            });
        } else {
            this.setState({ contactNumber: '', contactNumberErr: "thisFieldIsRequired" });
        }
    };

    handleChangeForSystemTypeDropdown = (event) => {
        this.setState({
            systemTypeHRSystem_SelectedValue: event,
            systemTypeErr: ''
        });
    };

    attachEntityAttestedPolicyPDFFileOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({
                    attachEntityAttestedPolicyFileInPDFFileName: '',
                    attachEntityAttestedPolicyFileInPDFFileErr: "invalidFileType"
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileObj = e.target.files[0];
                    this.setState({
                        attachEntityAttestedPolicyFileInPDFFileName: e.target.files[0].name, attachEntityAttestedPolicyFileInPDFFileErr: ''
                    }, () => {
                        let tempFile = fileObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                attachEntityAttestedPolicyFileInPDFObj: reader.result,
                            }, () => {
                                Config.setBase64ForAttachEntityAttestedPolicyFileInPDF(this.state.attachEntityAttestedPolicyFileInPDFObj);
                            });
                        }
                    });
                } else {
                    e.target.value = '';
                    this.setState({
                        attachEntityAttestedPolicyFileInPDFFileName: '',
                        attachEntityAttestedPolicyFileInPDFFileErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            this.setState({
                attachEntityAttestedPolicyFileInPDFFileName: '',
                attachEntityAttestedPolicyFileInPDFFileErr: "thisFieldIsRequired"
            });
        }
    }

    attachEntityAttestedOrgStructureFileOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ["PDF"];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({
                    attachEntityAttestedOrgStructureFileInPDFFileName: '', attachEntityAttestedOrgStructureFileInPDFFileErr: "invalidFileType"
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileObj = e.target.files[0];
                    this.setState({
                        attachEntityAttestedOrgStructureFileInPDFFileName: e.target.files[0].name, attachEntityAttestedOrgStructureFileInPDFFileErr: ''
                    }, () => {
                        let tempFile = fileObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                attachEntityAttestedOrgStructureFileInPDFObj: reader.result,
                            }, () => {
                                Config.setBase64ForAttachEntityAttestedOrganizationStructureFileInPDF(this.state.attachEntityAttestedOrgStructureFileInPDFObj);
                            });
                        }
                    });
                } else {
                    e.target.value = '';
                    this.setState({
                        attachEntityAttestedOrgStructureFileInPDFFileName: '',
                        attachEntityAttestedOrgStructureFileInPDFFileErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            this.setState({
                attachEntityAttestedOrgStructureFileInPDFFileName: '', attachEntityAttestedOrgStructureFileInPDFFileErr: "thisFieldIsRequired"
            });
        }
    }

    attachEntityCreationLawFileInPDFOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ["PDF"];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({
                    attachEntityCreationLawFileInPDFFileName: '',
                    attachEntityCreationLawFileInPDFFileErr: "invalidFileType"
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileObj = e.target.files[0];
                    this.setState({
                        attachEntityCreationLawFileInPDFFileName: e.target.files[0].name, attachEntityCreationLawFileInPDFFileErr: ''
                    }, () => {
                        let tempFile = fileObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                attachEntityCreationLawFileInPDFObj: reader.result,
                            }, () => {
                                Config.setBase64ForAttachEntityCreationLawFileInPDF(this.state.attachEntityCreationLawFileInPDFObj);
                            });
                        }
                    });
                } else {
                    e.target.value = '';
                    this.setState({
                        attachEntityCreationLawFileInPDFFileName: '',
                        attachEntityCreationLawFileInPDFFileErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            this.setState({
                attachEntityCreationLawFileInPDFFileName: '',
                attachEntityCreationLawFileInPDFFileErr: "thisFieldIsRequired"
            });
        }
    }

    attachAgreementFromEntityTopManagentAsFilePDFOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ["PDF",];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({
                    attachAgreementFromEntityTopManagementPDFFileName: '', attachAgreementFromEntityTopManagementPDFFileErr: "invalidFileType"
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileObj = e.target.files[0];
                    this.setState({
                        attachAgreementFromEntityTopManagementPDFFileName: e.target.files[0].name, attachAgreementFromEntityTopManagementPDFFileErr: ''
                    }, () => {
                        let tempFile = fileObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                attachAgreementFromEntityTopManagementPDFFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForAttachAgreementFromEntityTopManagentPDF(this.state.attachAgreementFromEntityTopManagementPDFFileObj);
                            });
                        }
                    });
                } else {
                    e.target.value = '';
                    this.setState({
                        attachAgreementFromEntityTopManagementPDFFileName: '',
                        attachAgreementFromEntityTopManagementPDFFileErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            this.setState({
                attachAgreementFromEntityTopManagementPDFFileName: '', attachAgreementFromEntityTopManagementPDFFileErr: "thisFieldIsRequired"
            });
        }
    }

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestTitle: this.state.requestTitle,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            attachEntityAttestedPolicyFileInPDFFileName: this.state.attachEntityAttestedPolicyFileInPDFFileName,
            attachEntityAttestedOrgStructureFileInPDFFileName: this.state.attachEntityAttestedOrgStructureFileInPDFFileName,
            attachEntityCreationLawFileInPDFFileName: this.state.attachEntityCreationLawFileInPDFFileName,
            attachAgreementFromEntityTopManagementPDFFileName: this.state.attachAgreementFromEntityTopManagementPDFFileName,
            employeeName: this.state.employeeName,
            jobDesc: this.state.jobDesc,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            systemTypeHRSystem_SelectedValue: this.state.systemTypeHRSystem_SelectedValue,
            systemTypeLable: this.state.systemTypeLable,
            otherAttachment: this.state.otherAttachment,
            otherAttachmentShortName: this.state.otherAttachmentShortName,
        });
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            entityName: this.state.entityName,
            requestTitle: this.state.requestTitle,
            requestDetails: this.state.requestDetails,
            requestDate: this.state.requestDate,
            employeeName: this.state.employeeName,
            jobDesc: this.state.jobDesc,
            contactNumber: this.state.contactNumber,
            email: this.state.email,
            systemTypeHRSystem_SelectedValue: this.state.systemTypeHRSystem_SelectedValue,
            systemTypeLable: this.state.systemTypeLable,
            // attchment names
            otherAttachment: this.state.otherAttachment,
            otherAttachmentShortName: this.state.otherAttachmentShortName,
            attachEntityAttestedPolicyFileInPDFFileName: this.state.attachEntityAttestedPolicyFileInPDFFileName,
            attachEntityAttestedOrgStructureFileInPDFFileName: this.state.attachEntityAttestedOrgStructureFileInPDFFileName,
            attachEntityCreationLawFileInPDFFileName: this.state.attachEntityCreationLawFileInPDFFileName,
            attachAgreementFromEntityTopManagementPDFFileName: this.state.attachAgreementFromEntityTopManagementPDFFileName
        });
    };

    onRequestDatepickerRef(el) {
        if (el && el.input) {
            if (this.state.requestDate !== null) {
                el.input.disabled = true;
                el.input.readOnly = false;
                el.input.classList.remove('white_bg');
            } else {
                el.input.disabled = false;
                el.input.readOnly = true;
                el.input.classList.add('white_bg');
            }
            if (el.input.type === "blur") {
                el.input.target.style.backgroundColor = "white";
            }
        }
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    otherAttachmentOnChnage = (e) => {
        let fileNames = "";
        let fileOtherAttachmnetArray = [];

        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            otherAttachment: '',
                            otherAttachmentShortName: '',
                            otherAttchmentErr: 'invalidFileType'
                        });
                        break;
                    }
                    else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let otherAttachmentShortName = fileNames.substring(0, 50);

                            let reader = new FileReader();
                            let tempFile = e.target.files[m];
                            reader.onloadend = () => {
                                fileOtherAttachmnetArray.push(reader.result);
                                this.setState({
                                    otherAttachmentFileObjArray: fileOtherAttachmnetArray,
                                }, () => {
                                    Config.setbase64OtherAttachmentForJoinAndImplement(this.state.otherAttachmentFileObjArray);
                                });
                            };
                            reader.readAsDataURL(tempFile);
                            this.setState({
                                otherAttachment: fileNames,
                                otherAttachmentShortName: otherAttachmentShortName,
                                otherAttchmentErr: ''
                            });
                        } else {
                            e.target.value = '';
                            this.setState({
                                otherAttachment: '',
                                otherAttachmentShortName: '',
                                otherAttchmentErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    otherAttachment: "",
                    otherAttachmentShortName: "",
                    otherAttchmentErr: 'fileLimitErr'
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({
                otherAttachment: "",
                otherAttachmentShortName: "",
                otherAttchmentErr: ''
            });
        }
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    render() {

        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.entityName}<span className="requireStar">*</span></label>
                            <input
                                disabled={true}
                                value={this.state.entityName}
                                onChange={this.handleEntityName}
                                type="text"
                                id="entityName"
                                className="form-control"
                                name="entityName"
                                required
                                maxLength={50}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestTitle}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.requestTitle}
                                onChange={this.handleRequestTitle}
                                type="text"
                                id="defaultFormRegisterPasswordEx4"
                                className="form-control"
                                name="requestTitle"
                                required
                                maxLength={50}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.contactNumber}<span className="requireStar">*</span></label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                className="form-control"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                required
                            />
                            {
                                this.state.contactNumberErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : null
                            }
                            {
                                this.state.contactNumberErr === 'invalidContactNumber' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestDate}<span className="requireStar">*</span></label>
                            <div
                                style={{
                                    width: '100%', direction: this.props.isArabic ? 'rtl' : ''
                                }}
                                className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.requestDateErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    disabled={true} 
                                    minDate={new Date()}
                                    className="form-control"
                                    todayButton={"Today"}
                                    style={{ width: '100%' }}
                                    selected={this.state.requestDate}
                                    onChange={this.handleRequestDate}
                                    ref={el => this.onRequestDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required />
                            </div>
                            {
                                this.state.requestDateErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.email}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                maxLength={50}
                                onChange={this.handleEmailChange}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.email}
                                required
                            />
                            {
                                this.state.emailErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : null
                            }
                            {
                                this.state.emailErr === 'invalidEmail' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidEmail}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.employeeName}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="employeeName"
                                name="employeeName"
                                onChange={this.handleEmployeeName}
                                value={this.state.employeeName}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.jobDesc}<span className="requireStar">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="jobDesc"
                                name="jobDesc"
                                onChange={this.handleJobDesc}
                                value={this.state.jobDesc}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"
                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.systemTypeHRSystem}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>

                            <div style={{ width: 'auto', display: 'flex', height: 'auto', borderRadius: '.25rem', padding: '1px', border: this.state.systemTypeErr === 'thisFieldIsRequired' ? '1px solid red' : '', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                                <Select
                                    options={this.props.isArabic ? arebicArray : englishArray}
                                    style={{
                                        display: 'flex',
                                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        textAlign: this.props.isArabic ? "right" : "left",
                                    }}
                                    placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                    onChange={this.handleChangeForSystemTypeDropdown}
                                    value={this.state.systemTypeHRSystem_SelectedValue}
                                    required
                                    isMulti
                                    closeMenuOnSelect={false}
                                />
                            </div>
                            {
                                this.state.systemTypeErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'unset' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.requestDetails}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <div style={{ border: this.state.reqDetailsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.props.isArabic}
                                    content={this.state.requestDetails}
                                    onChange={this.handleRequestDetails}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                        </div>
                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                        {
                            this.state.reqDetailsErr === 'thisFieldIsRequired' ?

                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.attachEntityAttestedPolicyPDFFile}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="attachEntityAttestedPolicyFilePDFFileId"
                                    type="file"
                                    accept="application/pdf"
                                    name="attachEntityAttestedPolicyFilePDFFile"
                                    onChange={(event) => this.attachEntityAttestedPolicyPDFFileOnChange(event)}
                                    required
                                />
                                <label htmlFor="attachEntityAttestedPolicyFilePDFFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label htmlFor="attachEntityAttestedPolicyFilePDFFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.attachEntityAttestedPolicyFileInPDFFileName}</span></label>
                            </div>
                            {
                                this.state.attachEntityAttestedPolicyFileInPDFFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachEntityAttestedPolicyFileInPDFFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachEntityAttestedPolicyFileInPDFFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }

                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsPDF}
                            </p>
                            {
                                (this.state.attestedPolicyFileArray &&
                                    this.state.attestedPolicyFileArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.attestedPolicyFileArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.attachEntityAttestedOrgStructureFileInPDF}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="attachEntityAttestedOrgStructureFileId"
                                    type="file"
                                    accept="application/pdf"
                                    name="attachEntityAttestedOrgStructureFile"
                                    onChange={(event) => this.attachEntityAttestedOrgStructureFileOnChange(event)}
                                    required
                                />
                                <label htmlFor="attachEntityAttestedOrgStructureFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label htmlFor="attachEntityAttestedOrgStructureFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.attachEntityAttestedOrgStructureFileInPDFFileName}</span></label>
                            </div>
                            {
                                this.state.attachEntityAttestedOrgStructureFileInPDFFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachEntityAttestedOrgStructureFileInPDFFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachEntityAttestedOrgStructureFileInPDFFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }

                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsPDF}
                            </p>
                            {
                                (this.state.attestedOrganizationStrutureFileArray &&
                                    this.state.attestedOrganizationStrutureFileArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.attestedOrganizationStrutureFileArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.attachEntityCreationLawFileInPDF}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="attachEntityCreationLawFilePDFFileId"
                                    type="file"
                                    accept="application/pdf"
                                    name="attachEntityCreationLawFilePDFFile"
                                    onChange={(event) => this.attachEntityCreationLawFileInPDFOnChange(event)}
                                    required
                                />
                                <label htmlFor="attachEntityCreationLawFilePDFFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label htmlFor="attachEntityCreationLawFilePDFFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.attachEntityCreationLawFileInPDFFileName}</span></label>
                            </div>
                            {
                                this.state.attachEntityCreationLawFileInPDFFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachEntityCreationLawFileInPDFFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachEntityCreationLawFileInPDFFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsPDF}
                            </p>
                            {
                                (this.state.entityCreationLawFileArray &&
                                    this.state.entityCreationLawFileArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.entityCreationLawFileArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.attachAgreementFromEntityTopManagementPDFFIle}
                            <span className="requireStar" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="attachAgreementFromEntityTopManagementAsPDFFileId"
                                    type="file"
                                    accept="application/pdf"
                                    name="attachAgreementFromEntityTopManagementAsPDFFile"
                                    onChange={(event) => this.attachAgreementFromEntityTopManagentAsFilePDFOnChange(event)}
                                    required
                                />
                                <label htmlFor="attachAgreementFromEntityTopManagementAsPDFFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label htmlFor="attachAgreementFromEntityTopManagementAsPDFFileId" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.attachAgreementFromEntityTopManagementPDFFileName}</span></label>
                            </div>
                            {
                                this.state.attachAgreementFromEntityTopManagementPDFFileErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachAgreementFromEntityTopManagementPDFFileErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.attachAgreementFromEntityTopManagementPDFFileErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", direction: this.props.isArabic ? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsPDF}
                            </p>
                            {
                                (this.state.agreementForEntityTopManagementArray &&
                                    this.state.agreementForEntityTopManagementArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.agreementForEntityTopManagementArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group"  >
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} >{i18n[this.props.isArabic ? 'ar' : 'en'].dataServicesObj.otherattachemnt}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="otherFile"
                                        type="file"
                                        name="others"
                                        multiple
                                        onChange={(event) => this.otherAttachmentOnChnage(event)}
                                    />
                                    <label
                                        htmlFor="otherFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row"
                                                : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="otherFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        {/* {this.state.otherAttachment} */}

                                        <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.otherAttachmentShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.otherAttachmentShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.otherAttachment}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.otherAttchmentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.otherAttchmentErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                            </p>
                            {
                                (this.state.otherAttachmentArray &&
                                    this.state.otherAttachmentArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.otherAttachmentArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    {
                        ((!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) && !(this.state.statusreasonid === Config.getReasonForReturnToEntityId())) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.props.isArabic ? "ar" : "en"].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.props.isArabic ? "ar" : "en"].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type="submit" color="primary" >
                        {i18n[this.props.isArabic ? "ar" : "en"].submit}
                    </MDBBtn>
                </MDBRow>
            </form>
        );
    }
}