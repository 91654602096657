import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class Step1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isArabic:true,
            employeeName:'',
            EmployeeInfoEmployeeNumber:'',
            EmployeeInfoJobTitle:'',
            EmployeeInfoJobDegree:'',
            EmployeeInfoEmirateID:'',
            EmployeeInfoEmployeeUID:'',
            employeeNameErr:'',
            EmployeeInfoEmployeeNumberErr:'',
            EmployeeInfoJobTitleErr:'',
            EmployeeInfoJobDegreeErr:'',
            EmployeeInfoEmirateIDErr:'',
            EmployeeInfoEmployeeUIDErr: '',
            EmployeeInfoTotalSalary: "",
            EmployeeInfoTotalSalaryErr:'',
            EmployeeInfoBasicSalary: '',
            EmployeeInfoBasicSalaryErr:'',
            
            EmployeeInfoRetirementcontributions: "",
            EmployeeInfoRetirementcontributionsErr:'',
            RequestType:'',
            RequestTypeErr:'',
            isEdit:this.props.isEdit,
            requestId:this.props.requestId,
            EmployeeJobNumber:'',
            EmployeeJobNumberErr:'',
            salaryErr:'',
        }
    }

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            RequestType: this.state.RequestType,
            employeeName: this.state.employeeName,
            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
            EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
           
        });
    };

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };
    handleEmployeeInfoJobDegree = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoJobDegree: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoJobDegree: '' });
        }
    };
    handleEmployeeInfoBasicSalary = (value) => {
        if (value) {
            this.setState({ EmployeeInfoBasicSalary: value.formattedValue, EmployeeInfoBasicSalaryErr: "" });
        }
    };
    handleEmployeeInfoRetirementcontributions = (value) => {
        if (value) {
            this.setState({ EmployeeInfoRetirementcontributions: value.formattedValue, EmployeeInfoRetirementcontributionsErr: "" });
        }
    };
    handleEmployeeInfoTotalSalary = (value) => {
        if (value) {
            this.setState({ EmployeeInfoTotalSalary: value.formattedValue, EmployeeInfoTotalSalaryErr: "" });
        }
    };
    handleEmployeeInfoJobTitle = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoJobTitle: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoJobTitle: '' });
        }
    };
    handleEmployeeInfoEmployeeNumber = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoEmployeeNumber : event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoEmployeeNumber: '' });
        }
    };
    handleEmployeeInfoEmployeeUID = (event) => {
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ EmployeeInfoEmployeeUID: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoEmployeeUID: '' })
        }
    };

    
    componentDidMount = () => {
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
debugger;
        if (editFlag) {
            this.setState({
                isArabic:this.props.isArabic,
                employeeName:editObj.employeename,
                EmployeeInfoEmployeeNumber:editObj.employeenumber,
                EmployeeInfoJobTitle:editObj.jobtitle,
                EmployeeInfoJobDegree:editObj.grade,
                EmployeeInfoEmirateID:editObj.emiratesid,
                EmployeeInfoEmployeeUID:editObj.unifiednumber,
                EmployeeInfoBasicSalary:editObj.basicsalary,
                EmployeeInfoTotalSalary:editObj.totalsalary,
EmployeeInfoRetirementcontributions: editObj.retirementcontribution,
RequestType:editObj.requesttype,
requestId:editObj.requestId
            });
        }
    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
        this.setState({isArabic:this.props.isArabic});
    }
    handleEmployeeInfoEmirateID = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ EmployeeInfoEmirateID: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ EmployeeInfoEmirateIDErr: '' });
                } else {
                    this.setState({ EmployeeInfoEmirateIDErr: 'invalidEmiratesId' });
                }
            });
        }
        else {
            this.setState({ EmployeeInfoEmirateID: '', EmployeeInfoEmirateIDErr: 'thisFieldisRequired' });
        }
    }

    

    submitHandler =(event) =>{
        debugger;
        event.preventDefault();
        event.target.className += " was-validated";
        
        let pathname = 'ActionPlansEntity';
        this.props.history.replace(pathname, {
            pathname: pathname
        });
       
            // this.setState({
            //     isNextClick: true,
            //     isfirstPageValidate: true
            // }, () => {
            //     this.props.getData.stepOneData({
            //         isEdit: this.state.isEdit,
                
            //     });
            // });
        }

    

    
    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };

    render() {
      
       
        return (
            // <React.Fragment>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2","Step3"]}
                    currentIndex={1}
                    isArabic={!this.state.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
                <ul class="nav nav-tabs">
                    <li class="nav-item Customcontainer">
                        <a class="nav-link active" href="#">{i18n[this.getLocale()].ActionPlans.Defniations} </a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                        {this.props.isArabic?
                        <ol className="DisciplinaryboardIntrolist">
  <li>خطة التطوير الشخصية : هي عملية إعداد خطة عمل للموظف مبنية على وعي وقيم أخلاقية، محددة الأهداف والمدة الزمنية - قصيرة وطويلة المدى- وتهدف إلى مساعدة الموظف لتحقيق هذه الأهداف وتحسين أدائه الوظيفي في الوظيفة الحالية، وإعداده للوظيفة المستقبلية، ويجب أن تتضمن المحاور التالي:                               <span>  <br/>1. كفاءات / مهارات سلوكية و فنية                                                         <br/>2. المعارف المستهدفة / المطلوبة                                                        <br/>3. البرامج التدريبية والتطويرية                                                                 <br/>4. التطوير الوظيفي- الوظائف المستقبلية                                                <br/> 5.المدة الزمنية (تاريخ البدء - تاريخ الإنتهاء)</span></li>

<li>الكفاءات السلوكية : هي مجموعة من السمات والصفات الشخصية التي قد يمتلكها الموظف / يحتاج لتطويرها والتي تساعد في تحديد مدى إنجازه في أداء المهام الوظيفية للوظيفة التي يشغلها أو الوظيفة المستقبلية، كذلك يمكن تحدد نقاط القوة السلوكية للموظف للنجاح في المستقبل.</li>
<li>الكفاءات الفنية  : هي التعليم والمعارف والمهارات اللازمة التي يمتلكها الموظف لأداء فعال في وظيفة معينة أو مجموعة من الوظائف داخل المؤسسة.</li>

                        </ol>
                        :
                        <ol className="DisciplinaryboardIntrolist">
                          <li>Personal Development Plan (PDP) : is the process of creating an action plan based on awareness, values, reflection, goal-setting and planning for personal development within the context of a career, education, relationship or for self-improvement, also  to help employees reach short and long-term career goals, as well as improve current job performance</li>



                        
<li>PDP should Include 

                               <span>  <br/>1. Competencies both (Technical & Behavioral) <br/>2. Education                                                       <br/>3. Training<br/>4. Career Steps<br/> 5. Clear Timeline</span></li>


<li>Behavioral competency  : Attributes and personality traits a person might have, such as knowledge and skill set, leadership skills which can help determine how successful he/she will be at the job they are holding / applying for. It can help define employee's behavioral strengths for the future success in the workplace</li>


<li>Technical competency : Describe the application of knowledge and skills needed to perform effectively in a specific job or group of jobs within the organization. These types of competencies are closely aligned with the knowledge and skills or “know-how” needed for successful performance.</li>

                        </ol>
    }
                          <br />
                           
                            {/* {
                                this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].budgetAvailabilityError}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>

                        </MDBRow>


                    </div>
                </div>

             
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
             <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].next_btn}
                           </MDBBtn>
       
                           {
                            //    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            //        null
                            //        :
                                //    <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                //        {i18n[this.getLocale()].saveAsDraft}
                                //    </MDBBtn>
                           }
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                    </MDBCol>
                       </MDBRow>
       
      
               
                       </form>    

    //   </React.Fragment>
              
        );
    }
}