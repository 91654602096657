import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from './../components/Header';
import SideBar from './../components/SideBar';
import StepOne from './../components/AmendEmployeeSalarySteps/StepOne';
import StepTwo from './../components/AmendEmployeeSalarySteps/StepTwo';
import SubHeaderStrip from './../components/SubHeaderStrip';
import Footer from './../components/Footer';
import i18n from './../i18n';
import './../css/style.css';
import Config from './../utils/Config';
import { saveAmendEmployeeSalaryPayload, uploadDocument } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import moment from 'moment';

export default class AmendEmployeeSalary extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            isEdit: '',
            requestId: '',
            formType: '',
            statusid: '',
            canviewbonusrequest: 0,
            canviewamendscalerequest: 0,
            canviewamendsalaryrequest: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isNextScreenActive: 1,
            approvedScaleSalaries: "",
            employeePhoto: "",
            employeeName: "",
            nationality: "",
            age: "",
            approvedScaleSalariesErr: "",
            employeePhotoErr: "",
            designationDate: new Date(),
            percentIncRequiredBasicSalary: '',
            proposedBasicSalary: '',
            currentBasicSalary: '',
            academicQualification: '',
            experienceAfterQualification: '',
            totalExperience: '',
            employeeEmaritesId: '',
            previousandCurrentApprovedCompetencyReport: '',
            previousandCurrentApprovedCompetencyReportShortName: '',

            annualEvaluationforPreviousYear: '',
            annualEvaluationforCurrentYear: '',
            currentJobTitle: '',
            currentGrade: '',
            jobRank: 'test',
            // procedureDate: new Date(),
            procedureDate: new Date(),
            procedureType: '1',
            financialRatioOfProcedure: '',
            copyOfLastProcedure: '',
            reasonToModifySalary: '',
            proposedIncreaseRate: '',
            hraEmployeeStudyMaker: '',
            hraStudy: '',
            isSideBarMinimized: false,
            nationalityLabel: ""
        };
    }

    componentWillMount() {

        let previewFlag = Config.getIsPreviewFlag();
        let previewCKObj = Config.getPreviewckEditorArray();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();
        let employeeEmaritesId = '',
            employeePhoto = '',
            copyOfLastProcedure = '',
            approvedScaleSalaries = '',
            previousandCurrentApprovedCompetencyReport = '',
            reasonToModifySalary = '',
            proposedIncreaseRateck = '',
            percentIncRequiredBasicSalary = '';

        if (previewFlag) {

            if (previewAttachmentArray && previewAttachmentArray.length > 0) {
                for (let index = 0; index < previewAttachmentArray.length; index++) {
                    if (previewAttachmentArray[index].label === 'employeeEmaritesId') {
                        employeeEmaritesId = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'employeePhoto') {
                        employeePhoto = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'copyOfLastProcedure') {
                        copyOfLastProcedure = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'previousandCurrentApprovedCompetencyReport') {
                        previousandCurrentApprovedCompetencyReport = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'approvedScaleSalaries') {
                        approvedScaleSalaries = previewAttachmentArray[index].fileName;
                    }
                }
            }

            if (previewCKObj && previewCKObj.length > 0) {
                for (let index = 0; index < previewCKObj.length; index++) {
                    if (previewCKObj[index].label == 'reasonToModifySalary') {
                        reasonToModifySalary = previewCKObj[index].value;
                    } else if (previewCKObj[index].label == 'proposedIncreaseRate') {
                        proposedIncreaseRateck = previewCKObj[index].value;
                    } else if (previewCKObj[index].label == 'percentIncRequiredBasicSalary') {
                        percentIncRequiredBasicSalary = previewCKObj[index].value;
                    }
                }
            }

            this.setState({
                isEdit: previewObj.isEdit,
                requestId: previewObj.requestId,
                employeeName: previewObj.employeeName,
                nationality: previewObj.nationality,
                age: previewObj.age,
                designationDate: new Date(previewObj.designationDate),
                currentBasicSalary: previewObj.currentBasicSalary,
                proposedBasicSalary: previewObj.proposedBasicSalary,
                financialRatioOfProcedure: previewObj.financialRatioOfProcedure,
                // percentIncRequiredBasicSalary: previewObj.percentIncRequiredBasicSalary,
                totalExperience: previewObj.totalExperience,
                procedureDate: new Date(previewObj.procedureDate),
                academicQualification: previewObj.academicQualification,
                experienceAfterQualification: previewObj.experienceAfterQualification,
                annualEvaluationforPreviousYear: previewObj.annualEvaluationforPreviousYear,
                annualEvaluationforCurrentYear: previewObj.annualEvaluationforCurrentYear,
                currentJobTitle: previewObj.currentJobTitle,
                currentGrade: previewObj.currentGrade,
                nationalityLabel: previewObj.nationalityLabel,
                employeeEmaritesId: employeeEmaritesId,
                employeePhoto: employeePhoto,
                copyOfLastProcedure: copyOfLastProcedure,

                previousandCurrentApprovedCompetencyReport: previousandCurrentApprovedCompetencyReport,
                previousandCurrentApprovedCompetencyReportShortName: previewObj.previousandCurrentApprovedCompetencyReportShortName,

                approvedScaleSalaries: approvedScaleSalaries,
                // step one ck editor
                reasonToModifySalary: reasonToModifySalary,
                proposedIncreaseRate: proposedIncreaseRateck,
                percentIncRequiredBasicSalary: percentIncRequiredBasicSalary
            });
        }

    }

    componentDidMount = () => {

        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewbonusrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                    canviewamendscalerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                    canviewamendsalaryrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest
                }, () => {
                })
            })
        }
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    nextButtonPressed = (isNextScreenActive) => {
        this.setState({ isNextScreenActive: isNextScreenActive });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    stepOneData = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            //step 1 data
            setConfigObj.employeename = obj.employeeName;
            setConfigObj.nationality = obj.nationality;
            setConfigObj.age = obj.age;
            setConfigObj.designationdate = obj.designationDate;
            setConfigObj.proceduredate = obj.procedureDate;
            setConfigObj.currentbasicsalary = obj.currentBasicSalary;
            setConfigObj.proposedbasicsalary = obj.proposedBasicSalary;
            setConfigObj.percentageincrease = obj.percentIncRequiredBasicSalary;
            setConfigObj.totalexpeirence = obj.totalExperience;
            setConfigObj.qualification = obj.academicQualification;
            setConfigObj.exprienceafterqualification = obj.experienceAfterQualification;
            setConfigObj.annualprevevaluation = obj.annualEvaluationforPreviousYear;
            setConfigObj.annualcurrevaluation = obj.annualEvaluationforCurrentYear;
            setConfigObj.jobtitle = obj.currentJobTitle;
            setConfigObj.grade = obj.currentGrade;
            setConfigObj.jobrank = obj.jobRank;
            setConfigObj.proceduretype = obj.procedureType;
            setConfigObj.financialratio = obj.financialRatioOfProcedure;
            setConfigObj.reasontomodify = obj.reasonToModifySalary;
            setConfigObj.baseofincrease = obj.proposedIncreaseRate
         
            Config.setEditObj(setConfigObj);
         
        }

        this.setState({
            step1: false,
            step2: true,
            isEdit: obj.isEdit,
            requestId: obj.requestId,
            employeeName: obj.employeeName,
            nationality: obj.nationality,
            age: obj.age,
            designationDate: obj.designationDate,
            currentBasicSalary: obj.currentBasicSalary,
            proposedBasicSalary: obj.proposedBasicSalary,
            percentIncRequiredBasicSalary: obj.percentIncRequiredBasicSalary,
            totalExperience: obj.totalExperience,
            academicQualification: obj.academicQualification,
            experienceAfterQualification: obj.experienceAfterQualification,
            annualEvaluationforPreviousYear: obj.annualEvaluationforPreviousYear,
            annualEvaluationforCurrentYear: obj.annualEvaluationforCurrentYear,
            currentJobTitle: obj.currentJobTitle,
            currentGrade: obj.currentGrade,
            financialRatioOfProcedure: obj.financialRatioOfProcedure,
            procedureDate: obj.procedureDate,
            reasonToModifySalary: obj.reasonToModifySalary,
            proposedIncreaseRate: obj.proposedIncreaseRate,
            nationalityLabel: obj.nationalityLabel
        });
    };

    submitApiCall = () => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let reqObj = {};
                reqObj.isEdit = this.state.isEdit;
                reqObj.requestId = this.state.requestId;
                reqObj.employeename = this.state.employeeName;
                reqObj.nationality = this.state.nationality;
                reqObj.age = this.state.age;
                reqObj.currentbasicsalary = this.state.currentBasicSalary;
                reqObj.proposedbasicsalary = this.state.proposedBasicSalary;
                reqObj.financialratio = this.state.financialRatioOfProcedure;
                reqObj.percentageincrease = this.state.percentIncRequiredBasicSalary;
                reqObj.totalexpeirence = this.state.totalExperience;
                reqObj.annualprevevaluation = this.state.annualEvaluationforPreviousYear;
                reqObj.qualification = this.state.academicQualification;
                reqObj.exprienceafterqualification = this.state.experienceAfterQualification;
                reqObj.annualcurrevaluation = this.state.annualEvaluationforCurrentYear;
                reqObj.jobtitle = this.state.currentJobTitle;
                reqObj.grade = this.state.currentGrade;
                reqObj.reasontomodify = this.state.reasonToModifySalary;
                reqObj.baseofincrease = this.state.proposedIncreaseRate;

                if (this.state.formType === "saveAsDraft") {
                    reqObj.draft = 1;
                } else {
                    reqObj.draft = 0;
                }

                // let tempDate = new Date(this.state.designationDate);
                // let day = tempDate.getDate();
                // let month = tempDate.getMonth();
                // let year = tempDate.getFullYear();
                // reqObj.designationdate = year + '-' + month + '-' + day;
                let tempDate = '';
                if (this.state.designationDate !== '' && this.state.designationDate !== null) {
                    tempDate = new Date(this.state.designationDate);
                    let day = tempDate.getDate();
                    let month = tempDate.getMonth() + 1;
                    let year = tempDate.getFullYear();
                    reqObj.designationdate = year + '-' + month + '-' + day;
                } else {
                    reqObj.designationdate = tempDate;
                }

                // let tempDate1 = new Date(this.state.procedureDate);
                // let day1 = tempDate1.getDate();
                // let month1 = tempDate1.getMonth();
                // let year1 = tempDate1.getFullYear();
                // reqObj.procedureDate = year1 + '-' + month1 + '-' + day1;
                let tempDateOne = '';
                if (this.state.procedureDate !== '' && this.state.procedureDate !== null) {
                    tempDateOne = new Date(this.state.procedureDate);
                    let day = tempDateOne.getDate();
                    let month = tempDateOne.getMonth() + 1;
                    let year = tempDateOne.getFullYear();
                    reqObj.procedureDate = year + '-' + month + '-' + day;
                } else {
                    reqObj.procedureDate = tempDateOne;
                }

                let payload = saveAmendEmployeeSalaryPayload(reqObj);
                let endPoint = 'SaveAmmendEmployeeSalary';
                let url = Config.getUrl(endPoint);

                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resForAmendEmployeeSalary => {
                        if (resForAmendEmployeeSalary.Status) {
                            let totalcount = 5;
                            this.uploadFile(0, totalcount, resForAmendEmployeeSalary, 0);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            })
                        }
                    })
                });
            })
        }
    }

    stepTwoData = (obj) => {
        if (obj.saveFrom === 'step1') {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                employeeName: obj.employeeName,
                nationality: obj.nationality,
                age: obj.age,
                designationDate: obj.designationDate,
                currentBasicSalary: obj.currentBasicSalary,
                proposedBasicSalary: obj.proposedBasicSalary,
                percentIncRequiredBasicSalary: obj.percentIncRequiredBasicSalary,
                totalExperience: obj.totalExperience,
                academicQualification: obj.academicQualification,
                experienceAfterQualification: obj.experienceAfterQualification,
                annualEvaluationforPreviousYear: obj.annualEvaluationforPreviousYear,
                annualEvaluationforCurrentYear: obj.annualEvaluationforCurrentYear,
                currentJobTitle: obj.currentJobTitle,
                currentGrade: obj.currentGrade,
                financialRatioOfProcedure: obj.financialRatioOfProcedure,
                procedureDate: obj.procedureDate,
                reasonToModifySalary: obj.reasonToModifySalary,
                proposedIncreaseRate: obj.proposedIncreaseRate
            }, () => {
                this.submitApiCall();
            });
        } else {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                employeeEmaritesId: obj.employeeEmaritesId,
                employeePhoto: obj.employeePhoto,
                copyOfLastProcedure: obj.copyOfLastProcedure,
                previousandCurrentApprovedCompetencyReport: obj.previousandCurrentApprovedCompetencyReport,
                previousandCurrentApprovedCompetencyReportShortName: obj.previousandCurrentApprovedCompetencyReportShortName,
                approvedScaleSalaries: obj.approvedScaleSalaries
            }, () => {
                this.submitApiCall();
            });
        }

    }

    uploadFile = (i, totalCount, resForAmendEmployeeSalary, multifileCount) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};
            let isLastFileInMultiUpload = false;
            if (i === 0) {
                fileObj = Config.getbase64ForAmendEmployeeSalaryThree();
                obj.filename = this.state.employeeEmaritesId;
                obj.filetype = Config.getAmendEmployeeSalEmiratesId();
                tempBase64 = fileObj;
            }
            else if (i === 1) {
                fileObj = Config.getbase64ForAmendEmployeeSalaryTwo();
                obj.filename = this.state.employeePhoto;
                obj.filetype = Config.getAmendEmployeeSalEmpPhotoId();
                tempBase64 = fileObj;
            }
            else if (i === 2) {
                fileObj = Config.getbase64ForAmendEmployeeSalaryFive();
                obj.filename = this.state.copyOfLastProcedure;
                obj.filetype = Config.getAmendEmployeeSalPreviousCopyOfLastProcedureId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                let fileObjArray = Config.getbase64ForAmendEmployeeSalaryFour();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }
                // let filenamesArray = this.state.previousandCurrentApprovedCompetencyReport.split(",");
                // obj.filename = filenamesArray[multifileCount];
                if (this.state.previousandCurrentApprovedCompetencyReport !== null) {
                    let filenamesArray = this.state.previousandCurrentApprovedCompetencyReport.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                obj.filetype = Config.getAmendEmployeeSalPreviousCompentaencyReportId();
                tempBase64 = fileObj;
            }
            else if (i === 4) {
                fileObj = Config.getbase64ForAmendEmployeeSalaryOne();
                obj.filename = this.state.approvedScaleSalaries;
                obj.filetype = Config.getAmendEmployeeSalAprrovedScaleAndSalariedId();
                tempBase64 = fileObj;
            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resForAmendEmployeeSalary && resForAmendEmployeeSalary.data && resForAmendEmployeeSalary.data.id) {
                    reqId = resForAmendEmployeeSalary.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resForAmendEmployeeSalary.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                // below commented code will use insted of include method
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);
            

                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        if (i === 3) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, resForAmendEmployeeSalary, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, resForAmendEmployeeSalary, multifileCount + 1);
                            }
                        } else {
                            this.uploadFile(i + 1, totalCount, resForAmendEmployeeSalary, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.draft === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })

            } else {
                this.uploadFile(i + 1, totalCount, resForAmendEmployeeSalary, 0);
            }
        }
        else {
            this.setState({
                isLoading: false
            }, () => {
                // this.props.history.replace("/listPage", {
                //     pathname: localStorage.getItem('currentPath')
                // });
                if (this.state.isEdit) {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.draft === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
           
        }
    }

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
    };

    handlePreViewNavigation = (obj) => {
        localStorage.setItem('currentPath', 'amendEmployeeSalary');

        let prevObj = {};
        prevObj.requestId = this.state.requestId;
        prevObj.employeeName = this.state.employeeName;
        prevObj.nationalityLabel = this.state.nationalityLabel;
        prevObj.nationality = this.state.nationality;
        prevObj.age = this.state.age;
        prevObj.designationDate = this.state.designationDate ? moment(this.state.designationDate).format('L').toString() : "";
        prevObj.currentBasicSalary = this.state.currentBasicSalary;
        prevObj.proposedBasicSalary = this.state.proposedBasicSalary;
        prevObj.financialRatioOfProcedure = this.state.financialRatioOfProcedure;
        // prevObj.percentIncRequiredBasicSalary = this.state.percentIncRequiredBasicSalary;
        prevObj.totalExperience = this.state.totalExperience;
        prevObj.procedureDate = this.state.procedureDate ? moment(this.state.procedureDate).format('L').toString() : "";
        prevObj.academicQualification = this.state.academicQualification;
        prevObj.experienceAfterQualification = this.state.experienceAfterQualification;
        prevObj.annualEvaluationforPreviousYear = this.state.annualEvaluationforPreviousYear;
        prevObj.annualEvaluationforCurrentYear = this.state.annualEvaluationforCurrentYear;
        prevObj.currentJobTitle = this.state.currentJobTitle;
        prevObj.currentGrade = this.state.currentGrade;

        prevObj.previousandCurrentApprovedCompetencyReportShortName = obj.previousandCurrentApprovedCompetencyReportShortName;

        let attachmentArr = [
            {
                label: 'employeeEmaritesId',
                fileName: obj.employeeEmaritesId,
                fileObj: Config.getbase64ForAmendEmployeeSalaryThree()
            },
            {
                label: 'employeePhoto',
                fileName: obj.employeePhoto,
                fileObj: Config.getbase64ForAmendEmployeeSalaryTwo()
            },
            {
                label: 'copyOfLastProcedure',
                fileName: obj.copyOfLastProcedure,
                fileObj: Config.getbase64ForAmendEmployeeSalaryFive()
            },
            {
                label: 'previousandCurrentApprovedCompetencyReport',
                fileName: obj.previousandCurrentApprovedCompetencyReport,
                fileObj: Config.getbase64ForAmendEmployeeSalaryFour()
            },
            {
                label: 'approvedScaleSalaries',
                fileName: obj.approvedScaleSalaries,
                fileObj: Config.getbase64ForAmendEmployeeSalaryOne()
            }
        ];

        let ckEditorArr = [
            {
                label: 'percentIncRequiredBasicSalary',
                value: this.state.percentIncRequiredBasicSalary,
            },
            {
                label: 'reasonToModifySalary',
                value: this.state.reasonToModifySalary,
            },
            {
                label: 'proposedIncreaseRate',
                value: this.state.proposedIncreaseRate,
            }
        ];

        localStorage.setItem("currentPath", "amendEmployeeSalary");
        this.props.history.replace("/preview", { data: prevObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
    };

    stepTwoDataPrev = obj => {
        this.setState({
            step1: true,
            step2: false,
            hraEmployeeStudyMaker: obj.hraEmployeeStudyMaker,
            employeeEmaritesId: obj.employeeEmaritesId,
            employeePhoto: obj.employeePhoto,
            hraStudy: obj.hraStudy,
            copyOfLastProcedure: obj.copyOfLastProcedure,
            previousandCurrentApprovedCompetencyReport: obj.previousandCurrentApprovedCompetencyReport,
            previousandCurrentApprovedCompetencyReportShortName: obj.previousandCurrentApprovedCompetencyReportShortName,
            approvedScaleSalaries: obj.approvedScaleSalaries
        });
    };

    getPrev = {
        stepTwoDataPrev: this.stepTwoDataPrev,
    };

    render() {
        const sideBarData = Config.getServiceSideBar('amendEmployeeSalary', this.state.isArabic, this, 0) ;
        return (
            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header toggleSideBarSize={this.toggleSideBarSize} goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12">
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'amendEmployeeSalary' })
                                        }} isArabic={this.state.isArabic} title={i18n[this.getLocale()].amendEmployeeSalary}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            {
                                this.state.step1 ?
                                    <StepOne
                                        sendData={{
                                            employeeName: this.state.employeeName,
                                            nationality: this.state.nationality,
                                            age: this.state.age,
                                            designationDate: this.state.designationDate,
                                            currentBasicSalary: this.state.currentBasicSalary,
                                            proposedBasicSalary: this.state.proposedBasicSalary,
                                            percentIncRequiredBasicSalary: this.state.percentIncRequiredBasicSalary,
                                            totalExperience: this.state.totalExperience,
                                            academicQualification: this.state.academicQualification,
                                            experienceAfterQualification: this.state.experienceAfterQualification,
                                            annualEvaluationforPreviousYear: this.state.annualEvaluationforPreviousYear,
                                            annualEvaluationforCurrentYear: this.state.annualEvaluationforCurrentYear,
                                            currentJobTitle: this.state.currentJobTitle,
                                            currentGrade: this.state.currentGrade,
                                            jobRank: this.state.jobRank,
                                            procedureType: this.state.procedureType,
                                            financialRatioOfProcedure: this.state.financialRatioOfProcedure,
                                            procedureDate: this.state.procedureDate,
                                            reasonToModifySalary: this.state.reasonToModifySalary,
                                            proposedIncreaseRate: this.state.proposedIncreaseRate,
                                            nationalityLabel: this.state.nationalityLabel
                                        }}
                                        getData={this.getData}
                                        isArabic={this.state.isArabic}
                                        getPrev={this.getPrev}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        saveAsDraft={this.stepTwoData}
                                    />
                                    :
                                    null
                            }
                            {
                                this.state.step2 ?
                                    <StepTwo
                                        sendData={{
                                            // step one variables
                                            employeeName: this.state.employeeName,
                                            nationality: this.state.nationality,
                                            age: this.state.age,
                                            designationDate: this.state.designationDate,
                                            currentBasicSalary: this.state.currentBasicSalary,
                                            proposedBasicSalary: this.state.proposedBasicSalary,
                                            percentIncRequiredBasicSalary: this.state.percentIncRequiredBasicSalary,
                                            totalExperience: this.state.totalExperience,
                                            academicQualification: this.state.academicQualification,
                                            experienceAfterQualification: this.state.experienceAfterQualification,
                                            annualEvaluationforPreviousYear: this.state.annualEvaluationforPreviousYear,
                                            annualEvaluationforCurrentYear: this.state.annualEvaluationforCurrentYear,
                                            currentJobTitle: this.state.currentJobTitle,
                                            currentGrade: this.state.currentGrade,
                                            jobRank: this.state.jobRank,
                                            procedureType: this.state.procedureType,
                                            financialRatioOfProcedure: this.state.financialRatioOfProcedure,
                                            procedureDate: this.state.procedureDate,
                                            reasonToModifySalary: this.state.reasonToModifySalary,
                                            proposedIncreaseRate: this.state.proposedIncreaseRate,
                                            nationalityLabel: this.state.nationalityLabel,
                                            // step two variables
                                            hraEmployeeStudyMaker: this.state.hraEmployeeStudyMaker,
                                            employeeEmaritesId: this.state.employeeEmaritesId,
                                            employeePhoto: this.state.employeePhoto,
                                            hraStudy: this.state.hraStudy,
                                            copyOfLastProcedure: this.state.copyOfLastProcedure,
                                            previousandCurrentApprovedCompetencyReport: this.state.previousandCurrentApprovedCompetencyReport,
                                            previousandCurrentApprovedCompetencyReportShortName: this.state.previousandCurrentApprovedCompetencyReportShortName,

                                            approvedScaleSalaries: this.state.approvedScaleSalaries
                                        }} getData={this.getData} isArabic={this.state.isArabic} getPrev={this.getPrev} onNextButtonPressed={this.nextButtonPressed} onPrevButtonPressed={this.nextButtonPressed} previewPage={this.handlePreViewNavigation} />
                                    :
                                    null
                            }
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewbonusrequest={this.state.canviewbonusrequest}
                            canviewamendscalerequest={this.state.canviewamendscalerequest}
                            canviewamendsalaryrequest={this.state.canviewamendsalaryrequest}
                            // height={'250vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            isArabic={this.state.isArabic}
                            items={sideBarData} />

                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}