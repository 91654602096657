import React, { Component } from 'react';
import {    MDBCardBody, MDBCard,MDBContainer, MDBRow, MDBCol,MDBBtn,MDBModalBody,MDBModalHeader,MDBTableBody,MDBTableHead,MDBModal,MDBTable, MDBTableFoot }
 from "mdbreact";
 
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import ApiRequest from '../../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay'
import { saveSciRequestPayload, uploadDocument } from '../../utils/Payload';
import { confirmAlert } from 'react-confirm-alert';
import '../../css/react-confirm-alert.css';

import CircularizationForm from '../../components/Circularization/CircularizationForm';
import Config from '../../utils/Config';

export default class Circularization extends Component {
    constructor() {
        super();
        this.state = {
            
            CircularTitle:'',
            Page:1,
            Count:10,
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            ShowDetails:true,
            popUpRowArray:[],
            popUpRowArrayTemp:[],
            entityArray:[],
            entitySelectedValue:'',
            isinternal:false,
            TotalCirculars:0
        }
    }

    handlePrevious=()=>{ 
        
        let temppage = this.state.Page-1;
        
        if(temppage<1)
        {
            temppage=1;
        }
        
        this.setState({
            Page:temppage
    }, () => {
        
       this.callGetActionPlanInitiativesList();
        
    })}
handleNext=()=>{ 
    
    let temppage = this.state.Page+1;
        
   
    
    this.setState({
        Page:temppage
}, () => {
    this.callGetActionPlanInitiativesList();
})}
toggleLanguage = (lan) => {
    this.setState({ isArabic: lan === 'ar' ? true : false });
}
validatekey=(event)=>

{
 let   str=event.target.value;
    this.setState({
        CircularTitle:str
}, () => {
    this.callGetActionPlanInitiativesList();
})
    

}
    create = () => {
        let pathname ='CircularizationCreate';
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    componentDidMount=()=>
    {
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    isinternal:this.state.loginData.internalcontact,
                    entityArray:tempEntityArray,
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
        this.callGetActionPlanInitiativesList();
    }
    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    ViewDates =(index,id)=>
    {
        
        Config.setActionPlanID(id);
        let pathname ='ViewDetailsCircular';
        this.props.history.replace(pathname, {
                      pathname: pathname
                  });
    }
    renderDataTableRows = (item, index) => {
        
        debugger;
        let tempdate =new Date(item.CreatedDate); 
         return (

             <tr style={{ textAlign: "center", padding: '10%' }}>
           <td>{tempdate.getDate()+" - " +parseInt(tempdate.getMonth()+1) + " - " + tempdate.getFullYear()}</td>
             <td>{item.CircularSubject}</td>
             {/* <td dangerouslySetInnerHTML ={{__html: item.Description}}></td> */}
          
             <td>{item.CreatedUser}</td>
             <td>
           <MDBBtn
                                     className="gridActionBtn "
                                     color="primary"
                                     style={{ color: "var(--btnColor)" }}
                                     size="sm"
                                     // onClick={() => {
                                     //     this.props.manageAction(item.action, this.props.index);
                                     // }}
                                     onClick={() => this.ViewDates(index,item.ID)}                       >
                                         {i18n[this.getLocale()].ActionPlans.ViewDetails}
                                  
                                 </MDBBtn></td>
            
            {/* <td> 
             <MDBBtn
                                     className="gridActionBtn "
                                     color="primary"
                                     style={{ color: "var(--btnColor)" }}
                                     size="sm"
                                     // onClick={() => {
                                     //     this.props.manageAction(item.action, this.props.index);
                                     // }}
                                     onClick={() => this.ViewDates(index)}                       >
                                         {i18n[this.getLocale()].WorkShop.ViewDates}
                                  
                                 </MDBBtn>
                  <MDBBtn
                                     className="gridActionBtn GreenBG"
                                     color="primary"
                                     style={{ color: "var(--btnColor)" }}
                                     size="sm"
                                     // onClick={() => {
                                     //     this.props.manageAction(item.action, this.props.index);
                                     // }}
                                     onClick={() => this.EditWorkShop(item)}                       >
                                         {i18n[this.getLocale()].WorkShop.Edit}
                                  
                                 </MDBBtn>
   </td> */}
             </tr>
         )
     }
    callGetActionPlanInitiativesList= () => {
        let payload = {};
        payload.Upcoming=1;
 
        payload.lang=this.state.isArabic ? 1:0;
  let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        payload.entityid = getDataFromLocalStorage.EntityID;
        payload.userId =  getDataFromLocalStorage.id;
        payload.CircularTitle=this.state.CircularTitle;
        payload.Page=this.state.Page;
        payload.Count=this.state.Count;
        let endPoint = 'GetallCirculas'
        let url = Config.getUrl(endPoint);
       this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let TotalCirculars=0;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        if(TotalCirculars==0)
                        {
                            TotalCirculars=resArray[index].TotalCirculars;
                            
                        }
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            Title:resArray[index].CircularSubject,
                            Description:resArray[index].Description,
                            CreatedUser:resArray[index].CreatedUser,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };

                    if(this.state.isinternal)
                    {
                        debugger;
                        this.setState({
                            data: mainArray,
                            popUpRowArraytemp: resArray,
                            actualArray: resArray,
                            dataRow: actualRowArray,
                            TotalCirculars:TotalCirculars
                        }, () => {
                           
                          //  this.handleLoadForEntity();
                           this.updateListData(this.state.data, this.state.popUpRowArraytemp);
                        })
                    }
                    else
                    {
                        this.setState({
                            data: mainArray,
                            popUpRowArray: resArray,
                            actualArray: resArray,
                            dataRow: actualRowArray
                        }, () => {
                  
                           this.updateListData(this.state.data, this.state.popUpRowArray);
                        })
                    }
               
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArraytemp: [],
                TotalCirculars:TotalCirculars

                    }, () => {
                         this.updateListData(this.state.data, this.state.popUpRowArraytemp);
                    })
                }
            } else {
                // TODO
            }
        })

      
    }

    updateListData = (data, popUpRowArray) => {
        
     debugger;
        
        let tempIndex = 0;
        if (popUpRowArray && popUpRowArray.length === 1) {
      
            this.setState({
                data: data,
                popUpRowArraytemp: popUpRowArray,
                
                selectedIndex: tempIndex
            });
        } else {
      
            
            this.setState({
                data: data,
                popUpRowArray:popUpRowArray,
                popUpRowArraytemp: popUpRowArray,
            });
        }
    }
    render() {
      
        const sideBarData = Config.getServiceSideBar('Circularization', this.state.isArabic, this, 0);
      return (
        <MDBContainer fluid>
                     <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip circular={true} enableCreate={Config.getLocalStorageLoginData().internalcontact} goBack={() => { this.props.history.replace('/landing') }}  create={this.create}  isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].Circularization.subject} />
                                </MDBCol>
                            </MDBRow>
                          
                            <div style={{ paddingTop: "10px" }}>
                                {/* <CircularizationForm       isArabic={this.state.isArabic}/>  */}
                            </div>

                            <div style={{ paddingTop: "10px" }}>
                                <MDBTable striped responsive hover style={{ marginBottom: '80px', direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                                <MDBTableHead>
                                    <tr ><th colspan="4">  <input class="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search}  onKeyDown={this.validatekey} ref="SearchInput" />
                                    </th>
                                    </tr>
                        
                        <tr className="tableHeader"> 
                        <th><span >{i18n[this.getLocale()].Circularization.Date}</span> 
                         </th>         
<th><span >{i18n[this.getLocale()].Circularization.MessageSubject}</span>  </th>
{/* <th><span >{i18n[this.getLocale()].Circularization.MessageDetails}</span></th> */}
<th><span >{i18n[this.getLocale()].Circularization.Createduser}</span></th>
<th><span ></span></th>

                               
                        </tr>

                        
                        </MDBTableHead>
                        <MDBTableBody>
                         
                        {
                            
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ? this.state.popUpRowArray.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                              
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                     null
                                }
                        </MDBTableBody>
                       <MDBTableFoot>
                       <tr className="tableHeader">
                  <td   colspan="1"  className=" col-span-3	 px-4 py-3 bg-gray-300 text-left px-2 text-lg	 font-normal  uppercase tracking-wider bg-red-800 text-white mb-4">
                  {this.state.Page>1? <MDBBtn style={{float: this.state.isArabic ? 'right' : 'left'}} onClick={this.handlePrevious} className="btn btn-primary Ripple-parent fixcusotmbtn">
                     {i18n[this.getLocale()].Circularization.Previous}
                    </MDBBtn>
                  :null}
                   
                  </td>
       <td  colspan="2"  className=" justify-center text-center col-span-3	 px-4 py-3 bg-gray-300 text-left text-lg	 font-normal  bg-red-800 text-white uppercase tracking-wider">{i18n[this.getLocale()].Circularization.Page} {this.state.Page}</td>
                  <td  style={{float: this.state.isArabic ? 'left':'right' }}  colspan="1" className="px-2 justify-center text-center  col-span-3	 px-4 py-3 bg-gray-300 text-right text-lg	 font-normal  bg-red-800 text-white uppercase tracking-wider">
                
                      {this.state.TotalCirculars>this.state.Count && this.state.TotalCirculars>this.state.Count*this.state.Page ? 
                      <MDBBtn className="btn btn-primary Ripple-parent fixcusotmbtn" onClick={this.handleNext}>
                  {i18n[this.getLocale()].Circularization.Next} </MDBBtn>:
                  null}
                   </td>
                  </tr>
               
      
                       </MDBTableFoot>
                       
                        </MDBTable>
                                </div><div style={{ paddingTop: "10px" }}>
                                </div>
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            height={'140vh'}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>  
            </MDBContainer>
     );
    }
}