import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import StepOne from '../components/InformationRequestSteps/StepOne';
import i18n from './../i18n';
import SubHeaderStrip from './../components/SubHeaderStrip';
import Footer from './../components/Footer';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';
import { saveInformationRequestPayload, uploadDocument } from './../utils/Payload';
import LoadingOverlay from 'react-loading-overlay'
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';

export default class InformationRequest extends Component {

    constructor() {
        super();
        // disableBrowserBackButton();
        this.state = {
            requestId: '',
            isEdit: '',
            draft: '',
            isNextScreenActive: false,
            contactNumber: '',
            requestorName: '',
            whatInfoRequired: '',
            attachFile1: '',
            typeOfRequest: '',
            typeOfRequestLabel: '',
            actualAttachFileOne: null,
            typeRequestSelectedValue: '',
            lastTimeToReply: '',
            whatInfoRequiredForStepTwo: '',
            attachFile2: '',
            actualAttachFileTwo: '',
            assignedEntitySelectedValue: '',
            assignedEntitySelectedLabel: '',
            ChangeLastTimeSelectedValue1: '',
            ChangeLastTimeSelectedValue2: '',
            ChangeLastTimeSelectedValue3: '',
            step1: true,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            canViewSCI: 0,
            canViewInformation: 0,
            loginData: ''
        }
    }

    componentDidMount = () => {
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData,
            }, () => {
                this.setState({
                    canViewSCI: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreviewscirequest,
                    canViewInformation: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewirrequest,
                })
            })
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', { pathname: 'informationRequest' })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    handlePreViewNavigation = (dataObj, attachmentArr, ckEditorArr) => {
        localStorage.setItem('currentPath', 'informationRequest');
        this.setState({
            pathName: 'informationRequest'
        }, () => {
            this.props.history.replace('/preview',
                { data: dataObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
        });
    }

    stepOneData = (obj) => {
        Config.setIsEditFlag(false);
        Config.setIsPreviewFlag(false);
        Config.setPreviewObj();
        Config.setPreviewAttachmentArray();
        Config.setPreviewckEditorArray();

        this.setState({
            requestId: obj.requestId,
            isEdit: obj.isEdit,
            draft: obj.formType,
            requestorName: obj.requestorName,
            contactNumber: obj.contactNumber,
            whatInfoRequired: obj.whatInfoRequired,
            assignedEntitySelectedValue: obj.assignedEntitySelectedValue,
            attachFile1: obj.attachFile1,
            typeRequestSelectedValue: obj.typeRequestSelectedValue,
            lastTimeToReply: obj.lastTimeToReply,
            whatInfoRequiredForStepTwo: obj.whatInfoRequiredForStepTwo,
            attachFile2: obj.attachFile2,
            typeOfRequest: obj.typeOfRequest
        }, () => {
            // get base 64 for attachement
            let attachmentOne = Config.getBase64ForInformatinRequestOne();
            //let attachmentTwo = Config.getBase64ForInformatinRequestTwo();
          
            let objForInformationRequest = {};

            let tempDate = '';
            if (this.state.lastTimeToReply) {
                tempDate = new Date(this.state.lastTimeToReply);
                let day = tempDate.getDate();
                let month = tempDate.getMonth() + 1;
                let year = tempDate.getFullYear();
                let finalDate = year + '-' + month + '-' + day;
                objForInformationRequest.datettoreply = finalDate;
            } else {
                objForInformationRequest.datettoreply = tempDate;
            }

            objForInformationRequest.isEdit = this.state.isEdit;
            objForInformationRequest.requestId = this.state.requestId;
            objForInformationRequest.requestorname = this.state.requestorName;
            objForInformationRequest.contactnumber = this.state.contactNumber;
            objForInformationRequest.informationrequired = this.state.whatInfoRequired;
            objForInformationRequest.assignedentity = this.state.assignedEntitySelectedValue;
            objForInformationRequest.requesttype = this.state.typeOfRequest;
            // objForInformationRequest.statement = this.state.whatInfoRequiredForStepTwo;
            objForInformationRequest.statement = this.state.whatInfoRequired;

            if (obj.formType === "saveAsDraft") {
                // this.state.dataObject.status = 'saveAsDraft';
                objForInformationRequest.draft = 1;
            } else {
                // this.state.dataObject.status = 'submit';
                objForInformationRequest.draft = 0;
            }

            let payloadForInformationRequest = saveInformationRequestPayload(objForInformationRequest);
            let endPointForInforamtionRequest = 'Saveinformationrequest'
            let urlForInformationRequest = Config.getUrl(endPointForInforamtionRequest);

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(urlForInformationRequest, payloadForInformationRequest, resForInformation => {
                    if (resForInformation.Status) {

                        // upload attachement
                        if (attachmentOne !== '') {
                            let objForAttachmentOne = {};
                            objForAttachmentOne.requestid = resForInformation.data.id;
                            objForAttachmentOne.filetype = Config.getInformationRequestAttachmentId();
                            objForAttachmentOne.filename = this.state.attachFile1;

                            let tempBase64 = attachmentOne;
                            objForAttachmentOne.fileContent = tempBase64.split(',')[1];
                            // call upload attachment API
                            let payloadForAttachmentOne = uploadDocument(objForAttachmentOne);
                            let endPointForAttachmentOne = 'UploadAttachment'
                            let urlForAttachmentOne = Config.getUrl(endPointForAttachmentOne);

                            if (objForAttachmentOne.filename && objForAttachmentOne.filename !== '' && tempBase64 && tempBase64 !== '') {
                                ApiRequest.sendRequestForPost(urlForAttachmentOne, payloadForAttachmentOne, resForAttachmentOne => {
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        if (resForAttachmentOne.Status) {
                                            // this.props.history.replace('/listpage', { pathname: 'informationRequest' });
                                            if (this.state.isEdit) {
                                                if (this.state.draft === "saveAsDraft") {
                                                    this.setState({
                                                        titleAlert: i18n[this.getLocale()].success,
                                                        messageAlert: i18n[this.getLocale()].save_as_Draft
                                                    }, () => {
                                                        this.alertSubmitForSuccess();
                                                    })
                                                } else {
                                                    this.setState({
                                                        titleAlert: i18n[this.getLocale()].success,
                                                        messageAlert: i18n[this.getLocale()].request_updated
                                                    }, () => {
                                                        this.alertSubmitForSuccess();
                                                    })
                                                }
                                            } else {
                                                this.setState({
                                                    titleAlert: i18n[this.getLocale()].success,
                                                    messageAlert: i18n[this.getLocale()].request_created
                                                }, () => {
                                                    this.alertSubmitForSuccess();
                                                })
                                            }
                                        } else {
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].error,
                                                messageAlert: i18n[this.getLocale()].attachment_error
                                            }, () => {
                                                this.alertSubmit();
                                            })
                                        }
                                    });
                                })
                            } else {
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    if (this.state.isEdit) {
                                        if (this.state.draft === "saveAsDraft") {
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].success,
                                                messageAlert: i18n[this.getLocale()].save_as_Draft
                                            }, () => {
                                                this.alertSubmitForSuccess();
                                            })
                                        } else {
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].success,
                                                messageAlert: i18n[this.getLocale()].request_updated
                                            }, () => {
                                                this.alertSubmitForSuccess();
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].request_updated
                                        }, () => {
                                            this.alertSubmit();
                                        })
                                    }
                                })
                            }
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                if (this.state.isEdit) {
                                    if (this.state.draft === "saveAsDraft") {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].save_as_Draft
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    } else {
                                        if (this.state.formType) {
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].success,
                                                messageAlert: i18n[this.getLocale()].request_updated
                                            }, () => {
                                                this.alertSubmitForSuccess();
                                            })
                                        } else {
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].success,
                                                messageAlert: i18n[this.getLocale()].request_created
                                            }, () => {
                                                this.alertSubmitForSuccess();
                                            })
                                        }
                                    }
                                } else {
                                    if (this.state.draft === "saveAsDraft") {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].save_as_Draft
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    } else {
                                        this.setState({
                                            titleAlert: i18n[this.getLocale()].success,
                                            messageAlert: i18n[this.getLocale()].request_created
                                        }, () => {
                                            this.alertSubmitForSuccess();
                                        })
                                    }
                                }
                            })
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].error_message
                            }, () => {
                                this.alertSubmit();
                            })
                        })
                    }
                })
            })
        })
    }

    getData = {
        stepOneData: this.stepOneData
    };

    callApi = () => {
    }

    navigate = () => {
        this.setState({
            step2: true, step1: false
        })
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    render() {
        
        const sideBarData = Config.getServiceSideBar('informationRequest', this.state.isArabic, this, 0) ;
        return (

            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'informationRequest' }) }} isArabic={this.state.isArabic} title={i18n[this.getLocale()].information_request} />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            <StepOne
                                isArabic={this.state.isArabic}
                                sendData={{
                                    requestorName: this.state.requestorName,
                                    contactNumber: this.state.contactNumber,
                                    assignedEntitySelectedValue: this.state.assignedEntitySelectedValue,
                                    whatInfoRequired: this.state.whatInfoRequired,
                                    lastTimeToReply: this.state.lastTimeToReply,
                                    typeOfRequest: this.state.typeOfRequest,
                                    attachFile1: this.state.attachFile1,
                                    actualAttachFileOne: this.state.actualAttachFileOne
                                }}
                                getData={this.getData}
                                onNextButtonPressed={this.nextButtonPressed}
                                saveAsDraft={this.stepOneData}
                                previewPage={this.handlePreViewNavigation}
                            />

                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic}
                        />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}