/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import ApiRequest from './../services/ApiRequest';
import Config from './../utils/Config';
import { downloadAttachment } from './../utils/Payload';
import i18n from './../i18n';
import ToastComponent from './ToastComponent';

export default class DownloadComponent extends Component {

    constructor(props) {
        super(props);
        this.setState({
            fileArray: [],
            requestType: ''
        })
    }

    componentWillReceiveProps = (props) => {
        let temp = [];
        if (props.filesObj && props.filesObj.length > 0) {
            temp = props.filesObj;
        }
      
        this.setState({
            fileArray: temp
        }, () => {
      
        })
    }

    componentWillMount = () => {
        let temp = [];
        if (this.props.filesObj && this.props.filesObj.length > 0) {
            temp = this.props.filesObj;
        }
      
        this.setState({
            fileArray: temp
        }, () => {
         
        })
    }

    componentDidMount = () => {
       
        let pathName = localStorage.getItem('currentPath');
        if (pathName) {
            if (pathName === 'SCIRequest') {
                this.setState({
                    requestType: Config.getSciRequestTypeId()
                })
            } else if (pathName === 'informationRequest') {
                this.setState({
                    requestType: Config.getInformationRequestTypeId()
                })
            } else if (pathName === 'bonusRequest') {
                this.setState({
                    requestType: Config.getBonusRequestTypeId()
                })
            } else if (pathName === 'amendSalaryScreen') {
                this.setState({
                    requestType: Config.getAmendSalaryScaleRequestTypeId()
                })
            } else if (pathName === 'amendEmployeeSalary') {
                this.setState({
                    requestType: Config.getAmendEmployeeSalaryRequestTypeId()
                })
            } else if (pathName === 'reviewHRPolicies') {
                this.setState({
                    requestType: Config.getHrReviewPolicyRequestTypeId()
                })
            } else if (pathName === 'exceptionADHousing') {
                this.setState({
                    requestType: Config.getExceptionADHousingRequestTypeId()
                })
            } else if (pathName === 'secondmentForFederalAgency') {
                this.setState({
                    requestType: Config.getSecondmentForFederalAgencyRequestTypeId()
                })
            } else if (pathName === 'exemptionFromAccompanyPatient') {
                this.setState({
                    requestType: Config.getExceptionAcompanypatientRequestTypeId()
                })
            } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                this.setState({
                    requestType: Config.getRetirmentRequestTypeId()
                })
            } else if (pathName === 'extendCreateObjectives') {
                this.setState({
                    requestType: Config.getExtendCreateObjectiveId()
                })
            } else if (pathName === 'extendReviewObjectives') {
                this.setState({
                    requestType: Config.getExtendReviewObjectiveId()
                })
            } else if (pathName === 'extendAnnualPerformance') {
                this.setState({
                    requestType: Config.getExtendAnnualPerformaceEvaluationId()
                })
            } else if (pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                this.setState({
                    requestType: Config.getAddAndEditEmployeePerformanceEvaluationId()
                })
            } else if (pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                this.setState({
                    requestType: Config.getJoinAndImplementId()
                })
            } else if (pathName === 'openComplainsStage') {
                this.setState({
                    requestType: Config.getOpenComplainsStageId()
                })
            } else if (pathName === 'amendsSharedGovernmentHRManagement') {
                this.setState({
                    requestType: Config.getAmendOnSharedGovermentId()
                })
            } else if (pathName === 'reportsAndStaticRequest') {
                this.setState({
                    requestType: Config.getReportAndStaticId()
                })
            } else if (pathName === 'otherRequests') {
                this.setState({
                    requestType: Config.getOtherRequest()
                })
            } else if (pathName === 'entity') {
                this.setState({
                    requestType: Config.getEntityRequestType()
                })
            }
            else if (pathName === 'SickLeave') {
                this.setState({
                    requestType: Config.getSickLeaveTypeId()
                })
            }
            else if (pathName === 'Promotion') {
                this.setState({
                    requestType: Config.getPromotionTypeId()
                })
            }

            else if (pathName === 'WorkShopAdd') {
                this.setState({
                    requestType: Config.getWorkShopTypeId()
                })
            }
            // organization structure
           
            else if (pathName === 'OrganizationStructure') {
                this.setState({
                    requestType: Config.getorganizationStrucutreRequest()
                })
            }
            else if (pathName==='Disciplinaryboard')
            {
                this.setState({
                    requestType: Config.getDisciplinaryboardTypeId()
                })
            }
        }
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    saveFile = (item) => {
        let obj = {}
        if(item.requestType=10953)
        {
            obj.filename = item.aliasfilename;
            obj.requesttype = 10953;
            obj.requestid = item.requestid;
            item.fileName=item.filename;
        }
        else
        {
            obj.filename = item.url;
            obj.requesttype = this.state.requestType;
            obj.requestid = item.requestId;
        }

        let payload = downloadAttachment(obj);
        let endPoint = 'DownloadAttachment'
        let url = Config.getUrl(endPoint);
     

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let fileData = res.data;
                        let filecontent = fileData.filecontent;
                        let contenttype = fileData.contenttype;

                        if (navigator.msSaveBlob) {
                            let blob = this.b64toBlob(filecontent, contenttype);
                            window.navigator.msSaveBlob(blob, item.fileName);
                        } else {
                            let a = window.document.createElement('a');
                            a.href = `data:${contenttype};base64,${filecontent}`;
                            a.download = item.fileName;
                            // Append anchor to body.
                            document.body.appendChild(a);
                            a.click();
                            // Remove anchor from body
                            document.body.removeChild(a);
                        }
                    } else {
                    
                    
                        let tempFileNotFoundMessage = i18n[this.props.isArabic ? 'ar' : 'en'].filenotfound
                        if (res.Message === 'filenotfound') {
                            ToastComponent.notifyInfo(tempFileNotFoundMessage);
                        }
                    }
                })
            })
        })
    }

    deleteFile = (arrayItem) => {
        let obj = {}
        obj.filename = arrayItem.url;
        obj.requesttype = this.state.requestType;
        obj.requestid = arrayItem.requestId;
        let payload = downloadAttachment(obj);
        let endPoint = 'DeleteAttachment'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        ToastComponent.notifyInfo(res.Message);
                       
                        let tempFileArray = [...this.state.fileArray];
                   
                        if (tempFileArray && tempFileArray.length > 0) {
                            tempFileArray.map((item, index) => {
                                if (item.fileName === arrayItem.fileName) {
                                    tempFileArray.splice(index, 1);
                                }
                            })
                        }
                     
                        if (tempFileArray && tempFileArray.length > 0) {
                            this.setState({
                                fileArray: tempFileArray
                            })
                        } else {
                            let emptyTempArray = [];
                            this.setState({
                                fileArray: emptyTempArray
                            })
                        }
                    } else {
                        ToastComponent.notifyInfo(res.Message);
                    }
                })
            })
        })
    }

    render() {
      
        return (
            <div style={{ maxHeight: '60px', overflowY: 'scroll', width: '100%' }}>
                {
                    // this.props.filesObj && this.props.filesObj.length > 0 ?
                    this.state.fileArray && this.state.fileArray.length > 0 ?
                        this.state.fileArray.map((item, index) => {
                        // this.props.filesObj.map((item, index) => {
                            return (
                                <MDBRow key={index} xs="12" sm="12" md="12" lg="12" xl="12" style={{ flexDirection: 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    <MDBCol xs="10" sm="10" md="10" lg="10" xl="10">
                                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", justifyContent: 'flex-end' }}>
                                      {item.fileName ? item.fileName : item.filename}
                                        </p>
                                    </MDBCol>
                                    <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ textAlign: 'center' }}>
                                        {/* <a href={item.url} download target="_blank" className="download-iocn"> */}
                                        <img
                                            onClick={() => this.saveFile(item)}
                                            style={{ cursor: 'pointer' }}
                                            className="download-img"
                                            src={require("./../assets/images/icons/download.png")} alt="pencil-icon" />
                                        <img
                                            onClick={() => this.deleteFile(item)}
                                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                                            className="delete-img"
                                            src={require("./../assets/images/trash.png")} alt="pencil-icon" />
                                        {/* </a> */}
                                    </MDBCol>
                                </MDBRow>
                            )
                        })
                        :
                        ''
                }
            </div>
        );
    }
}