import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import MailTemplateForm from '../../components/AdminPanel/MailTemplateForm';
import i18n from '../../i18n';
import { saveMailTemplatePayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';

export default class MailTemplate extends Component {

    constructor(props) {
        super(props);
        disableBrowserBackButton();
        this.state = {
            loginData: '',
            isLoading: false,
            theme: 'light',
            y: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isEdit: false,
            requestId: ''
        }
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    onSubmitData = (title, mailTemplateTypeSelectedValue, isEdit, requestId) => {
        let base64OfMailTemplateBody = Config.getBase64MailTemplateBody();
    

        let payload = saveMailTemplatePayload(title, mailTemplateTypeSelectedValue, isEdit, requestId);
        let endPoint = 'SaveMailTemplate';
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {

            ApiRequest.sendRequestForPost(url, payload, res => {

                if (res.Status) {

                    if (isEdit) {
                        this.setState({
                            isLoading: false,
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].mailtemplate_updated
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].mailtemplate_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }

                } else {
                    this.setState({
                        isLoading: false,
                        titleAlert: i18n[this.getLocale()].error,
                        messageAlert: i18n[this.getLocale()].error_message
                    }, () => {
                        this.alertSubmit();
                    })
                }

            })

        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }


    render() {

        let sideBarData = [];
        sideBarData = Config.getServiceSideBar('mailTemplate', this.state.isArabic, this, 0);

        return (

            <MDBContainer fluid >
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} goToLandingPage={this.goToLandingPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>

                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'mailTemplate' }) }} isArabic={this.state.isArabic} title={i18n[this.getLocale()].adminPanelKeys.createMailTemplate} />
                                </MDBCol>
                            </MDBRow>

                            {/* <MDBCard style={{ marginTop: '1%', marginBottom: '1%' }}> */}

                            {/* <MDBCardBody className="hratablerow"> */}

                            <MailTemplateForm isArabic={this.state.isArabic} onSubmitData={this.onSubmitData} />

                            {/* </MDBCardBody> */}

                            {/* </MDBCard> */}

                            <Footer isArabic={this.state.isArabic} />

                        </MDBCol>

                        <SideBar
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} 
                            // height={'200vh'}
                            handleNavigation={this.handleNavigation} />

                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>

        );
    }

}