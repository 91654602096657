import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class violation extends Component {

     
    componentDidMount = () => {
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    render() {
        let element = this.state.administrativeProcedures.map((item, index) => {
            return (
                <tr>
                    <td>
                        {
                            index > 0 ?
                                <div className="deleteBox">
                                    <MDBBtn className="primary deleteBtn" onClick={() => this.deleteView(index)}>-</MDBBtn>
                                </div>
                                : ''
                        }
                    </td>
                    <td>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "year"}
                                name="year"
                                value={item.year}
                                required
                                onChange={(e) => {
                                    let regex = /^[0-9\b]{0,4}$/;
                                    if (e.target.value) {
                                        if (regex.test(e.target.value)) {
                                            item.year = e.target.value;
                                            this.setState({ year: e.target.value });
                                        }
                                    } else {
                                        item.year = '';
                                        this.setState({ year: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    let year = e.target.value;
                                    if (year !== "" && parseInt(year) > 0) {
                                        if (!Validator.validateYear(year)) {
                                            item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].yearValidation;
                                            this.setState({
                                                yearErr: i18n[this.props.isArabic ? 'ar' : 'en'].yearValidation
                                            });
                                        } else {
                                            item.year = e.target.value;
                                            item.yearErr = "";
                                            item.fromDayErr = "";

                                            item.fromDay = '';
                                            item.toDay = '';

                                            this.setState({
                                                year: e.target.value,
                                                yearErr: "",

                                                fromDay: '',
                                                toDay: '',
                                                fromDayErr: ""
                                            });
                                        }
                                    } else {
                                        item.yearErr = "";
                                        item.year = '';
                                        this.setState({
                                            year: "",
                                            yearErr: ''
                                        });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: 100 }}
                            />
                            <div className='textRed'>{item.yearErr}</div>
                        </div>
                    </td>
                    <td style={{ width: '15%' }}>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <select
                                className="browser-default custom-select"
                                id={item.ref + "month"}
                                style={{ width: '125px', fontSize: '13px' }}
                                value={item.month}
                                onChange={(e) => {
                                    item.month = e.target.value;
                                    item.fromDay = '';
                                    item.toDay = '';
                                    item.noOfDays = '';
                                    this.setState({
                                        month: e.target.value,
                                        fromDay: '',
                                        toDay: '',
                                        noOfDays: '',
                                        fromDayErr: ""
                                    }, () => {
                                        this.recalculateSubTotalAndNoOfDays(item);
                                    });
                                }}
                                name='month'>
                                {
                                    this.state.monthArray.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </td>
                    <td>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "fromDay"}
                                name="fromDay"
                                value={item.fromDay}
                                required
                                onChange={(e) => {
                                    if (!item.year) {
                                        item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst;
                                        this.setState({ yearErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst });
                                        return;
                                    }

                                    if (e.target.value != "") {
                                        let regex = /^[0-9]{0,2}$/;
                                        let fromDay = parseInt(e.target.value);
                                        let toDay = item.toDay;
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();

                                        if (fromDay > 0 && fromDay <= daysInMonth) {
                                            if (toDay > 0) {
                                                if (fromDay <= toDay ) {
                                                    item.fromDay = fromDay;
                                                    this.recalculateSubTotalAndNoOfDays(item, daysInMonth);
                                                    item.fromDayErr = '';
                                                    this.setState({ fromDay: fromDay, fromDayErr: '' });
                                                } else {
                                                    //show error fromDay value should be less than ToDay
                                                    item.fromDay = '';
                                                    item.fromDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                                    this.setState({
                                                        fromDay: '',
                                                        fromDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                                    });
                                                }
                                            } else {
                                                if (regex.test(e.target.value)) {
                                                    item.fromDay = fromDay;
                                                    item.fromDayErr = '';
                                                    this.setState({ fromDay: fromDay, fromDayErr: '' });
                                                }
                                            }
                                        } else {
                                            item.fromDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                            this.setState({
                                                fromDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                            });
                                        }
                                    } else {
                                        this.recalculateSubTotalAndNoOfDays(item, 0);
                                        item.fromDay = '';
                                        this.setState({ fromDay: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (e.target.value != "") {
                                        let fromDay = parseInt(e.target.value);
                                        let toDay = item.toDay;
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();

                                        if (fromDay > 0 && fromDay <= daysInMonth) {
                                            if (toDay > 0 && fromDay < toDay) {
                                                item.fromDayErr = '';
                                                this.setState({ fromDayErr: '' });
                                            }
                                        }
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: '70px' }}
                            />
                            <div className='textRed'>{item.fromDayErr}</div>
                        </div>
                    </td>
                    <td>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "toDay"}
                                name="toDay"
                                required
                                value={item.toDay}
                                onChange={(e) => {
                                    if (!item.year) {
                                        item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst;
                                        item.toDay = '';
                                        this.setState({ toDay: '' });
                                        return;
                                    }

                                    if (e.target.value !== "") {
                                        let regex = /^[0-9]{0,2}$/;
                                        let toDay = parseInt(e.target.value);
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                        if (toDay > 0 && toDay <= daysInMonth) {
                                            if (regex.test(e.target.value)) {
                                                item.toDay = toDay;
                                                item.toDayErr = '';
                                                this.setState({ toDay: toDay, toDayErr: '' });
                                            }
                                        } else {
                                            item.toDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                            this.setState({
                                                toDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                            });
                                        }
                                    } else {
                                        item.toDay = '';
                                        this.setState({ toDay: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (item.yearErr === '') {
                                        if (item.toDay != "") {
                                            let toDay = item.toDay;
                                            item.toDay = toDay;
                                            let fromDay = item.fromDay;
                                            if (fromDay > 0) {
                                                if (fromDay <= toDay) {
                                                    let temp = toDay - fromDay;
                                                    item.noOfDays = temp;
                                                    this.setState({ noOfDays: temp });
                                                    let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                                    this.recalculateSubTotalAndNoOfDays(item, daysInMonth);

                                                    item.toDayErr = '';
                                                    this.setState({ toDayErr: '' });

                                                } else {
                                                    //show error formDay value should be less than ToDay
                                                    item.toDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                                    this.setState({
                                                        toDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                                    });
                                                }

                                            } else {
                                                item.toDay = e.target.value;
                                                item.toDayErr = '';
                                                this.setState({ toDay: e.target.value, toDayErr: '' });
                                            }
                                        }
                                    } else {
                                        item.toDay = e.target.value;
                                        this.setState({ toDay: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: '70px' }}
                            />
                            <div className='textRed'>{item.toDayErr}</div>
                        </div>
                    </td>
                    <td>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <select
                                className="browser-default custom-select"
                                id={item.ref + "salaryStatus"}
                                name="salaryStatus"
                                value={item.salaryStatus}
                                onChange={(e) => {
                                    item.salaryStatus = e.target.value;
                                    this.setState({ salaryStatus: e.target.value });
                                }}
                                style={{ width: 'auto', fontSize: '13px' }}>
                                {
                                    this.state.salaryStatusArr ? this.state.salaryStatusArr.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName}</option>
                                    }) : null
                                }
                            </select>
                        </div>
                    </td>
                    <td>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <input
                                type="text"
                                className="form-control"
                                pattern="^[1-9]\d*$"
                                required
                                id={item.ref + "subTotal"}
                                name="subTotal"
                                onChange={(e) => {
                                    let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                    let regex = /^[0-9\b.]{0,15}$/;
                                    let splitArr = e.target.value.split('.');
                                   
                                    if (regex.test(e.target.value) && splitArr.length <= 2) {
                                        item.subTotal = e.target.value;
                                        this.setState({ subTotal: e.target.value }, () => {
                                            this.recalculateSubTotalAndNoOfDays(item, daysInMonth);
                                        });
                                    }
                                }}
                                value={item.subTotal}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', overflowX: 'scroll' }}
                            />
                        </div>
                    </td>
                    <td>
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "noOfDays"}
                                name="noOfDays"
                                readOnly
                                value={item.noOfDays}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>
                </tr>
            )
        })
  return(      <React.Fragment>
    <ul class="nav nav-tabs">
    <li class="nav-item Customcontainer">
      <a class="nav-link active" href="#">{i18n[this.getLocale()].SickLeave.DescripeIssue} </a>
    </li>
  </ul>
  
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  
    <MDBRow style={{ display: 'flex', flexDirection:'row',paddingTop: '20px', justifyContent: this.props.isArabic ? 'flex-start' : 'flex-end', }}>
  
  <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
          {/* <label style={{ width: '100%', display: 'flex', flexDirection:'row',paddingTop: '20px' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.violationLabel}<span className="requireStar">*</span></label> */}
  
          <label htmlFor="formGroupExampleInput" style={{ direction: !this.props.isArabic ? 'ltr' : 'rtl', textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.getLocale()].ExemptionFromAccompanyPatient.violationLabel}<span className="requireStar">*</span></label>
  
          <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
              <input
                  type="radio"
                  value="Yes"
                  checked={this.state.violation === 'Yes'}
                  class="custom-control-input"
                  id="customControlValidation2"
                  onChange={this.handleViolationChange}
                  name="radio-stacked"
                  required />
              <label class="custom-control-label" for="customControlValidation2">
                  {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
              </label>
          </div>
          <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
              <input
                  type="radio"
                  value="No"
                  class="custom-control-input"
                  id="customControlValidation3"
                  name="radio-stacked"
                  checked={this.state.violation === 'No'}
                  onChange={this.handleViolationChange}
                  required />
              <label class="custom-control-label" for="customControlValidation3">
                  {i18n[this.props.isArabic ? 'ar' : 'en'].no}
              </label>
  
  
          </div> <br />
          {this.state.violationErr == 'thisFieldIsRequired' ?
              <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                  {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
              </div>
              :
              null}
      </div>
  </MDBCol>
  
  {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"></MDBCol> */}
  
  </MDBRow>
  
  {
  this.state.violation === 'Yes' ?
      <MDBRow style={{ display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
          <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                  <label style={{ width: '100%', display: 'flex', flexDirection:'row',paddingTop: '20px' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.explain}<span className="requireStar">*</span></label>
                  <div style={{ border: this.state.explainErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                      {
                          this.props.isArabic ?
                              null
                              :
                              <CKEditor
                                  activeClass="p10"
                                  content={this.state.explain}
                                  events={{
                                      "change": this.handleExplainOnChange
                                  }}
                                  config={{ language: "en", height: '10vh' }}
                              />
                      }
                      {
                          this.props.isArabic ?
                              <CKEditor
                                  activeClass="p10"
                                  content={this.state.explain}
                                  events={{
                                      "change": this.handleExplainOnChange
                                  }}
                                  config={{ language: "ar", height: '10vh' }}
                              />
                              :
                              null
                      }
                  </div>
                  <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                      {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                  </p>
                  {
                      this.state.explainErr === 'thisFieldisRequired' ?
                          <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                              {i18n[this.getLocale()].thisFieldIsRequired}
                          </div>
                          :
                          null
                  }
  
              </div>
          </MDBCol>
      </MDBRow>
      :
      ''
  }
  
  <MDBRow style={{ display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
  <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
      <label htmlFor="formGroupExampleInput" style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.irregularitiesTable}</label>
      <MDBTable bordered style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}>
          <MDBTableBody>
              <div>
                  <tr>
                      <td width="15%"><div className="tableHeading" style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.employeeSalaryInformation}</div></td>
                      <td>
                          <div>
                              <tr>
                                  <td width="20%">
                                      <lable style={{ display: 'flex' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.basicSalaryTb}</lable>
  
                                      <div
                                          className=" currencyFormatInputCSS currencyFormat"
                                          style={{
                                              direction: this.props.isArabic ? 'rtl' : 'ltr',
                                              border: this.state.basicSalErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                          }}>
                                          <CurrencyFormat
                                              thousandSeparator={true}
                                              value={this.state.basicSalaryTable}
                                              onValueChange={(value) => { this.handleBasicSalaryTable(value) }}
                                              display={"text"}
                                              maxLength={10}
                                          />
                                      </div>
  
                                      {
                                          this.state.basicSalErr === 'basicSalErr' ?
                                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
                                                  {i18n[this.props.isArabic ? 'ar' : 'en'].salaryErr}
                                              </div>
                                              :
                                              null
                                      }
  
                                  </td>
                                  <td width="30%">
                                      <lable style={{ display: 'flex' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalSalaryWithoutSubscription}</lable>
                                      <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                          <CurrencyFormat
                                              thousandSeparator={true}
                                              value={this.state.totalSalaryWithSubscription}
                                              onValueChange={(value) => { this.handletotalSalaryWithSubscriptionTable(value) }}
                                              display={"text"}
                                              maxLength={10}
                                          />
                                      </div>
                                  </td>
                                  <td width="20%">
                                      <lable style={{ display: 'flex' }}>
                                          {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.retirementSalary}</lable>
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
  
                                          <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', border: this.state.retirementSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                              <CurrencyFormat
                                                  thousandSeparator={true}
                                                  value={this.state.retirementSalary}
                                                  onValueChange={(value) => { this.handleretirementSalaryTable(value) }}
                                                  display={"text"}
                                                  maxLength={10}
                                              />
  
                                          </div>
  
                                          {
                                              this.state.retirementSalaryErr === 'retirementSalaryErr' ?
                                                  <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                      {i18n[this.getLocale()].monthlyContributionsErr}
                                                  </div>
                                                  :
                                                  null
                                          }
  
                                      </div>
                                  </td>
                                  <td width="20%">
                                      <lable style={{ display: 'flex' }}>
                                          {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.total}</lable>
                                                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                          <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                              <CurrencyFormat
                                                  thousandSeparator={true}
                                                  value={this.state.tswsrsSalary}
                                                  onValueChange={(value) => { this.handletswsrsSalaryTable(value) }}
                                                  display={"text"}
                                                  maxLength={10}
                                                  disabled={true}
                                              />
                                          </div>
                                      </div>
                                  </td>
  
                              </tr>
                          </div>
                      </td>
                  </tr>
  
                  <tr>
                      <td >
                          <div className="tableHeading"
                              style={{
                                  textAlign: this.props.isArabic ? "right" : '',
                                  width: 'auto'
                              }}
                          >
                              {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.intervals}
                          </div>
  
                          <div className="deleteBox">
                              <MDBBtn className="primary deleteBtn" onClick={() => this.addView()} >+</MDBBtn>
                          </div>
                      </td>
                      <td>
                          <div>
                              <table>
                                  <tr>
                                      <th></th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.year} <span class="requireStar">*</span> </th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.month} <span class="requireStar">*</span> </th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.from} <span class="requireStar">*</span> </th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.to} <span class="requireStar">*</span></th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salaryStatus} <span class="requireStar">*</span></th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.subTotal} <span class="requireStar">*</span></th>
                                      <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.noOfDays} </th>
                                  </tr>
  
                                  {element}
  
                                  <tr>
                                      <td colSpan={6} style={{ border: 'none' }}>
                                          {
                                              this.state.intervalsErr ?
                                                  <div className="textRed"
                                                      style={{
                                                          width: '100%',
                                                          display: 'flex',
                                                          textAlign: this.props.isArabic ? 'right' : 'left',
                                                          flexDirection:'row',paddingTop: '20px',
                                                          direction: 'ltr'
                                                      }}
                                                  >
                                                      {i18n[this.props.isArabic ? 'ar' : 'en'].allFieldsAreMandatory}
                                                  </div>
                                                  :
                                                  null
                                          }
                                      </td>
  
                                      <td>
                                          {/* <lable>Amount Should be Paid</lable> */}
                                                                <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="subTotalTotal"
                                                  name="subTotalTotal"
                                                  readOnly
                                                  value={this.state.subTotalTotal}
                                                  style={{ direction: this.props.isArabic ? "rtl" : 'ltr', overflowX: 'auto' }}
                                              />
                                          </div>
                                      </td>
                                      <td>
                                          {/* <lable>Total Days</lable> */}
                                                                <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="daysTotal"
                                                  name="daysTotal"
                                                  disabled
                                                  value={this.state.totalNoOfDays}
                                                  style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                              />
                                          </div>
                                      </td>
                                  </tr>
                              </table>
                          </div>
                      </td>
                  </tr>
  
              </div>
  
          </MDBTableBody>
      </MDBTable>
  </MDBCol>
  </MDBRow>
  
  <MDBRow style={{ display: 'flex', flexDirection:'row',paddingTop: '20px' }}>
  <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
          <label style={{ width: '100%', display: 'flex', flexDirection:'row',paddingTop: '20px' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.HowToCalculate}<span className="requireStar">*</span></label>
  
          <div style={{ border: this.state.howToCalculateErr === 'thisFieldisRequired' ? '1px solid red' : '' }}>
              {
                  this.props.isArabic ?
                      null :
                      <CKEditor
                          activeClass="p10"
                          content={this.state.HowToCalculate}
                          events={{
                              "change": this.handleHowToCalculate
                          }}
                          config={{ language: "en", height: '10vh' }}
                      />
              }
              {
                  this.props.isArabic ?
                      <CKEditor
                          activeClass="p10"
                          content={this.state.HowToCalculate}
                          events={{
                              "change": this.handleHowToCalculate
                          }}
                          config={{ language: "ar", height: '10vh' }}
                      />
                      :
                      null
              }
          </div>
          <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
              {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
          </p>
          {
              this.state.howToCalculateErr === 'thisFieldisRequired' ?
                  <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                      {i18n[this.getLocale()].thisFieldIsRequired}
                  </div>
                  :
                  null
          }
      </div>
  </MDBCol>
  </MDBRow>
  
        </div></div>           
  
  </React.Fragment>      
  );
    }
}