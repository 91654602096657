import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import { components } from 'react-select';
import { Multiselect } from 'multiselect-react-dropdown';
import { confirmAlert } from 'react-confirm-alert';
import doc from './../../assets/نموذج تعيين الشواغر.xlsx';
import { saveStrategicPlanningforworkforce, uploadDocument } from '../../utils/Payload';
const striptags = require('striptags');

export default class StrategicPlanningforworkforceFormcom extends Component 

{
    constructor (props)
    {
        super(props);
        this.state=
        {
            selectedQuarter:0,
            selectedQuarterErr:'',
          
            officalLetterErr: '',
            actualOfficialLetter: '',
            officialLetter:'',
            EntityName:'',
            RequesterName:'',
            RequesterJobTitle:'',
            RequesterContactNumber:'',
            VacancyAppointmentForm:'',
            JobangelForm:'',
            jobdescriptiontotherequiredvacancies:'',

            VacancyAppointmentFormFiles:[],
            JobangelFormFiles:[],
            jobdescriptiontotherequiredvacanciesFiles:[],
            RequestID:'0',
            EntityNameErr:'',
            RequesterNameErr:'',
            RequesterJobTitleErr:'',
            RequesterContactNumberErr:'',
            VacancyAppointmentFormFilesErr:'',
            JobangelFormFilesErr:'',
            jobdescriptiontotherequiredvacanciesFilesErr:'',

            VacancyAppointmentFormFilesArr:[],
            JobangelFormFilesArr:[],
            jobdescriptiontotherequiredvacanciesFilesArr:[],
        }

    }
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }
 componentDidMount = () => {
    let loginData = Config.getLocalStorageLoginData();
    let tempEntityName = this.props.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn;
    
  let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
    this.setState({
        EntityName: tempEntityName,
        RequesterName:tempEmployeeName,
        RequesterJobTitle  :loginData.JobTitle,
        RequesterContactNumber:loginData.ContactNumber,

    })
 }




    handleContactNumberChange = (event) => {

        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ RequesterContactNumber: event.target.value }, () => {
                if (Validator.validateContactNumber(contactNumber)) {
                    this.setState({ RequesterContactNumberErr: '' });
                } else {
                    this.setState({ RequesterContactNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ RequesterContactNumber: '', RequesterContactNumberErr: 'thisFieldIsRequired' });
        }
    };


 submitHandler = event => {
 debugger;
    event.preventDefault();
    event.target.className += " was-validated";
//      let Quartererror='',officalLetterErr='';
  
//      if(!this.state.selectedQuarter || this.state.selectedQuarter===0 || this.state.selectedQuarter==='0')
// {
//     Quartererror="thisFieldIsRequired";
// }

     let VacancyAppointmentFormFilesErr = '', JobangelFormFilesErr = '', jobdescriptiontotherequiredvacanciesFilesErr = '';
 if(this.state.VacancyAppointmentFormFiles.length===0)
 {
     VacancyAppointmentFormFilesErr="thisFieldIsRequired";

 }
     if (this.state.JobangelFormFiles.length===0)
 {
     JobangelFormFilesErr="thisFieldIsRequired";

 }
 if(this.state.jobdescriptiontotherequiredvacanciesFiles.length===0)
 {
     jobdescriptiontotherequiredvacanciesFilesErr="thisFieldIsRequired";

 }


 this.setState({
     VacancyAppointmentFormFilesErr: VacancyAppointmentFormFilesErr,
     JobangelFormFilesErr: JobangelFormFilesErr,
     jobdescriptiontotherequiredvacanciesFilesErr: jobdescriptiontotherequiredvacanciesFilesErr
    
 },()=>{})

   

 if (
   
     !VacancyAppointmentFormFilesErr &&
     !JobangelFormFilesErr && !jobdescriptiontotherequiredvacanciesFilesErr
 
 )
{
    let dataObject = {}
    dataObject.RequestID=this.state.selectedQuarter;
    dataObject.RequesterName=this.state.RequesterName;
    dataObject.RequesterJobTitle=this.state.RequesterJobTitle;
    dataObject.RequesterContactNumber=this.state.RequesterContactNumber;
    
  let tempVacancyAppointmentFormFiles=[],tempJobangelFormFiles=[],tempjobdescriptiontotherequiredvacanciesFiles
=[]  ;
tempVacancyAppointmentFormFiles = this.state.VacancyAppointmentFormFiles;
    dataObject.VacancyAppointmentFormFilesname=tempVacancyAppointmentFormFiles[0].filename;
    dataObject.VacancyAppointmentFormFilesContent=tempVacancyAppointmentFormFiles[0].filecontent;

    tempJobangelFormFiles = this.state.VacancyAppointmentFormFiles;
    dataObject.JobangelFormFilesname=tempJobangelFormFiles[0].filename;
    dataObject.JobangelFormFilesConetent=tempJobangelFormFiles[0].filecontent;


    tempjobdescriptiontotherequiredvacanciesFiles = this.state.VacancyAppointmentFormFiles;
    dataObject.jobdescriptiontotherequiredvacanciesFilesname=tempjobdescriptiontotherequiredvacanciesFiles[0].filename;
    dataObject.jobdescriptiontotherequiredvacanciesFilesContent=tempjobdescriptiontotherequiredvacanciesFiles[0].filecontent;


    // dataObject.filecontent=this.state.actualOfficialLetter;
    let payload = saveStrategicPlanningforworkforce(dataObject);
    
    debugger;
    let endPoint = 'SaveStrategicPlanningforworkforce'
    let url = Config.getUrl(endPoint);
    this.setState({
        isLoading: true
    }, () => {
        ApiRequest.sendRequestForPost(url, payload, res => {
            if (res.Status) {
              

                this.setState({
                    isLoading: false,
                    titleAlert: i18n[this.getLocale()].success,
                    messageAlert: i18n[this.getLocale()].StrategicPlanningforworkforce.sucess
                }, () => {
                    this.alertSubmitForSuccess();
                })
            }
        }
        )

 }
);
}
 }


 handleChangeForQuarter = (e) => {
let selectedvalue = parseInt(e.target.value);
if(selectedvalue > 0)
{
    this.setState({selectedQuarter:selectedvalue,selectedQuarterErr:''});
}
else
{
    this.setState({selectedQuarter:selectedvalue,selectedQuarterErr:'thisFieldisRequired'});
}

 }

 VacancyAppointmentFormFilesOnChange = e => {
     let tempfiles = [];    debugger;
    if (e.target.files.length > 0) {
        for(let i=0; i<e.target.files.length;i++)
        {
            let element=e.target.files[i];
        
        
            let inpFileName = element.name;
            let fileSize = element.size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['XLS', 'XLSX'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    VacancyAppointmentForm: '',
                    VacancyAppointmentFormFilesErr: 'invalidFileType'
                });
                return;
            }
           
           
           
            else {
                this.setState({VacancyAppointmentFormFilesErr:''});
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let tempobj = {};
                    let fileObj = element;
                    tempobj.filename=element.name;
                    let reader = new FileReader();
                    let file = fileObj;
                    reader.onloadend = () => {
                        tempobj.filecontent=reader.result;
                    };
                    reader.readAsDataURL(file);
                    tempfiles.push(tempobj);
                }
                else {
                    e.target.value = '';
                    this.setState({
                        VacancyAppointmentForm: '',
                        VacancyAppointmentFormFilesErr: 'invalidFileSize'
                    });
                    return;
                }  
            }
            

        }
      
this.setState({
    VacancyAppointmentFormFiles:tempfiles
});
       

    } else {
        e.target.value = '';
        this.setState({ VacancyAppointmentForm: '' });
    }

};

JobangelFormFilesOnChange = e => {
    let tempfiles = [];    debugger;
   if (e.target.files.length > 0) {
       for(let i=0; i<e.target.files.length;i++)
       {
           let element=e.target.files[i];
       
       
           let inpFileName = element.name;
           let fileSize = element.size;
           let fileExt = inpFileName.split('.').pop().toUpperCase();
           let allowedExtensions = ['XLS', 'XLSX'];
           if (allowedExtensions.indexOf(fileExt) <= -1) {
               e.target.value = '';
               this.setState({
                JobangelForm: '',
                JobangelFormFilesErr: 'invalidFileType'
               });
               return;
           }
          
          
          
           else {
               this.setState({officalLetterErr:''});
               if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                   let tempobj = {};
                   let fileObj = element;
                   tempobj.filename=element.name;
                   let reader = new FileReader();
                   let file = fileObj;
                   reader.onloadend = () => {
                       tempobj.filecontent=reader.result;
                   };
                   reader.readAsDataURL(file);
                   tempfiles.push(tempobj);
               }
               else {
                   e.target.value = '';
                   this.setState({
                    JobangelForm: '',
                       JobangelFormFilesErr: 'invalidFileSize'
                   });
                   return;
               }  
           }
           

       }
     
this.setState({
    JobangelFormFiles:tempfiles
});
     

   } else {
       e.target.value = '';
       this.setState({ JobangelForm: '' });
   }

};

jobdescriptiontotherequiredvacanciesOnChange = e => {
    let tempfiles = [];    debugger;
   if (e.target.files.length > 0) {
       for(let i=0; i<e.target.files.length;i++)
       {
           let element=e.target.files[i];
       
       
           let inpFileName = element.name;
           let fileSize = element.size;
           let fileExt = inpFileName.split('.').pop().toUpperCase();
           let allowedExtensions = ['XLS', 'XLSX'];
           if (allowedExtensions.indexOf(fileExt) <= -1) {
               e.target.value = '';
               this.setState({
                jobdescriptiontotherequiredvacancies: '',
                jobdescriptiontotherequiredvacanciesFilesErr: 'invalidFileType'
               });
               return;
           }
          
          
          
           else {
               this.setState({officalLetterErr:''});
               if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                   let tempobj = {};
                   let fileObj = element;
                   tempobj.filename=element.name;
                   let reader = new FileReader();
                   let file = fileObj;
                   reader.onloadend = () => {
                       tempobj.filecontent=reader.result;
                   };
                   reader.readAsDataURL(file);
                   tempfiles.push(tempobj);
               }
               else {
                   e.target.value = '';
                   this.setState({
                    jobdescriptiontotherequiredvacancies: '',
                       jobdescriptiontotherequiredvacanciesFilesErr: 'invalidFileSize'
                   });
                   return;
               }  
           }
           

       }
     
this.setState({
    jobdescriptiontotherequiredvacanciesFiles:tempfiles
});
     

   } else {
       e.target.value = '';
       this.setState({ jobdescriptiontotherequiredvacancies: '' });
   }

};
alertSubmitForSuccess = () => {
    debugger;
    //return (<RequestSurvey isArabic={this.state.isArabic} y={this.props.y} />);
     confirmAlert({
         title: this.state.titleAlert,
         message: this.state.messageAlert,
         buttons: [
             {
                 label: i18n[this.getLocale()].ok,
                 onClick: () =>   this.props.history.replace('/StrategicPlanningforworkforce', {
                    pathname: '/StrategicPlanningforworkforce'
                })
             }
         ],
         closeOnEscape: false,
         closeOnClickOutside: false,
         childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
     });
     setTimeout(() => {
         document.getElementById('alertOkButton').focus()
     }, 100);
};
 render() {

    return (

        <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate>

            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].StrategicPlanningforworkforce.EntityName }
                            <span className="requireStar">*</span>
                        </label>
                        <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="EntityName"
                                name="EntityName"
                                maxLength={50}
                                value={this.state.EntityName}
                               
                                required
                            />
                    </div>
                </MDBCol>

                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].StrategicPlanningforworkforce.RequesterName }
                            <span className="requireStar">*</span>
                        </label>
                        <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                            
                                type="text"
                                className="form-control"
                                id="RequesterName"
                                name="RequesterName"
                                maxLength={50}
                                value={this.state.RequesterName}
                            onChange={(event) => {
                                if (event.target.value && event.target.value.trim()) {
                                    let regex = /^[\u0621-\u064AA-Za-z ]*$/;
                                    if (regex.test(event.target.value)) {
                                        this.setState({ RequesterName: event.target.value });
                                    }
                                }
                                else {
                                    this.setState({ RequesterName: '', RequesterNameErr:'1' });
                                }
                            }}
                                required
                            />
{this.state.RequesterNameErr  ?
                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                         :null}
                    </div>
                </MDBCol>
            </MDBRow>
         

            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].StrategicPlanningforworkforce.JobTitle }
                            <span className="requireStar">*</span>
                        </label>
                        <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                               
                                type="text"
                                className="form-control"
                                id="RequesterName"
                                name="RequesterName"
                                maxLength={50}
                                value={this.state.RequesterJobTitle}
                            onChange={(event) => {
                                if (event.target.value && event.target.value.trim()) {
                                    let regex = /^[\u0621-\u064AA-Za-z ]*$/;
                                    if (regex.test(event.target.value)) {
                                        this.setState({ RequesterJobTitle: event.target.value });
                                    }
                                }
                                else {
                                    this.setState({ RequesterJobTitle: '', RequesterJobTitleErr: '1' });
                                }
                            }}
                                required
                            />
{this.state.RequesterJobTitleErr  ?
                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                         :null}
                    </div>
                </MDBCol>

                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].StrategicPlanningforworkforce.PhoneNumber }
                            <span className="requireStar">*</span>
                        </label>

                        <InputMask
                            mask="999-999-9999"
                            maskChar={null}
                            style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            value={this.state.RequesterContactNumber}
                            onChange={this.handleContactNumberChange}
                            className="form-control"
                            required
                        />
                        {
                            this.state.RequesterContactNumberErr == 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }
                        {
                            this.state.RequesterContactNumberErr == 'invalidContactNumber' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].invalidContactNumber}
                                </div>
                                :
                                null
                        }
                          

                    </div>
                </MDBCol>
            </MDBRow>

            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
          <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
             <a href={doc} className="nodiecroation" target="_blank">
                        <div className="flagclass" style={{ display: 'flex', 
                        flexDirection: this.props.isArabic  ? 'row-reverse' : 'row', alignItems: this.props.isArabic  ?'right':'left',
                         justifyContent: this.props.isArabic  ?'right':'left' }} caret gradient="var(--primaryColor)">
                          <span className="VacancyAppointmentFormLink"  >
                                 <span style={{margin:'15px'}}>
                              {i18n[this.props.isArabic ? 'ar' : 'en'].    StrategicPlanningforworkforce.VacancyAppointmentForm}
                              </span>
                              </span>
                           
                        </div>
                   </a>
             </MDBCol>
         </MDBRow>


            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
         <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
              <div className="form-group">
                  <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="VacancyAppointmentFormFilesfile">{i18n[this.getLocale()].StrategicPlanningforworkforce.AttachVacancyAppointmentForm}</label>

                  <div className="input-group">
                      <div className="custom-file-inp">
                          <input required
                              id="VacancyAppointmentFormFilesfile"
                              type="file"
                              name="VacancyAppointmentFormFiles"
                              onChange={(event) => this.VacancyAppointmentFormFilesOnChange(event)}
                          />
                          <label htmlFor="VacancyAppointmentFormFilesfile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].SCI.chooseFile}</label>
                          <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="VacancyAppointmentFormFilesfile" className="fileName">
                       
                              {this.state.VacancyAppointmentFormFiles.length>0
                              ?this.state.VacancyAppointmentFormFiles.map((item, index) => {
                                return (
                                    item.filename
                                )
                              }
                              )
                              :''
                              }
                              {/* <span>{this.state.officialLetter}</span> */}
                          </label>
                      </div>
                      {
                          this.state.VacancyAppointmentFormFilesErr === 'thisFieldIsRequired' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                              </div>
                              :
                              null
                      }
                      {
                          this.state.VacancyAppointmentFormFilesErr === 'invalidFileType' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                              </div>
                              :
                              null
                      }
                      {
                          this.state.VacancyAppointmentFormFilesErr === 'invalidFileSize' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                              </div>
                              :
                              null
                      }

                  </div>
                  <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                      {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormats_xlsx}
                  </p>
                  {
                      (this.state.VacancyAppointmentFormFilesArray &&
                          this.state.VacancyAppointmentFormFilesArray.length > 0)
                          ? <DownloadComponent filesObj={this.state.VacancyAppointmentFormFilesArray} />
                          : <span></span>

                  }
              </div>
          </MDBCol>

      </MDBRow>
      <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
          <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
               <div className="form-group">
                   <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="Attachedfilesforsupportrequest">{i18n[this.getLocale()].StrategicPlanningforworkforce.AttachJobangel}</label>
 
                   <div className="input-group">
                       <div className="custom-file-inp">
                           <input required
                               id="JobangelFormFilesfile"
                               type="file"
                               name="JobangelFormFiles"
                               onChange={(event) => this.JobangelFormFilesOnChange(event)}
                           />
                           <label htmlFor="JobangelFormFilesfile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].SCI.chooseFile}</label>
                           <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="JobangelFormFilesfile" className="fileName">
                        
                               {this.state.JobangelFormFiles.length>0
                               ?this.state.JobangelFormFiles.map((item, index) => {
                                 return (
                                     item.filename
                                 )
                               }
                               )
                               :''
                               }
                               {/* <span>{this.state.officialLetter}</span> */}
                           </label>
                       </div>
                       {
                           this.state.JobangelFormFilesErr === 'thisFieldIsRequired' ?
                               <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                   {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                               </div>
                               :
                               null
                       }
                       {
                           this.state.JobangelFormFilesErr === 'invalidFileType' ?
                               <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                   {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                               </div>
                               :
                               null
                       }
                       {
                           this.state.JobangelFormFilesErr === 'invalidFileSize' ?
                               <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                   {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                               </div>
                               :
                               null
                       }
 
                   </div>
                   <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                       {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormats_xlsx}
                   </p>
                   {
                       (this.state.JobangelFormFilesArray &&
                           this.state.JobangelFormFilesArray.length > 0)
                           ? <DownloadComponent filesObj={this.state.JobangelFormFilesArray} />
                           : <span></span>
 
                   }
               </div>
           </MDBCol>
 
       </MDBRow>
       <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
          <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
               <div className="form-group">
                   <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="Attachedfilesforsupportrequest">{i18n[this.getLocale()].StrategicPlanningforworkforce.Attachjobdescriptiontotherequiredvacancies}</label>
 
                   <div className="input-group">
                       <div className="custom-file-inp">
                           <input required
                               id="jobdescriptiontotherequiredvacanciesFilesfile"
                               type="file"
                               name="jobdescriptiontotherequiredvacanciesFiles"
                               onChange={(event) => this.jobdescriptiontotherequiredvacanciesOnChange(event)}
                           />
                           <label htmlFor="jobdescriptiontotherequiredvacanciesFilesfile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].SCI.chooseFile}</label>
                           <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="jobdescriptiontotherequiredvacanciesFilesfile" className="fileName">
                        
                               {this.state.jobdescriptiontotherequiredvacanciesFiles.length>0
                               ?this.state.jobdescriptiontotherequiredvacanciesFiles.map((item, index) => {
                                 return (
                                     item.filename
                                 )
                               }
                               )
                               :''
                               }
                               {/* <span>{this.state.officialLetter}</span> */}
                           </label>
                       </div>
                       {
                           this.state.jobdescriptiontotherequiredvacanciesFilesErr === 'thisFieldIsRequired' ?
                               <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                   {i18n[this.props.isArabic ? "ar" : "en"].thisFieldIsRequired}
                               </div>
                               :
                               null
                       }
                       {
                           this.state.jobdescriptiontotherequiredvacanciesFilesErr === 'invalidFileType' ?
                               <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                   {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                               </div>
                               :
                               null
                       }
                       {
                           this.state.jobdescriptiontotherequiredvacanciesFilesErr === 'invalidFileSize' ?
                               <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                   {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                               </div>
                               :
                               null
                       }
 
                   </div>
                   <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                       {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormats_xlsx}
                   </p>
                   {
                       (this.state.jobdescriptiontotherequiredvacanciesFilesArray &&
                           this.state.jobdescriptiontotherequiredvacanciesFilesArray.length > 0)
                           ? <DownloadComponent filesObj={this.state.jobdescriptiontotherequiredvacanciesFilesArray} />
                           : <span></span>
 
                   }
               </div>
           </MDBCol>
 
       </MDBRow>

            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                {/* {
                    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        null
                        :
                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                            {i18n[this.getLocale()].preview}
                        </MDBBtn>
                } */}
                {/* {
                    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        null
                        :
                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                            {i18n[this.getLocale()].saveAsDraft}
                        </MDBBtn>
                } */}
                <MDBBtn className="submitBtn" type='submit' color="primary">
                    {i18n[this.getLocale()].submit}
                </MDBBtn>
            </MDBRow>
        </form>
    );
}
}

