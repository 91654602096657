import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import StepsComponent from '../../components/StepsComponent';
import './../../css/style.css';
import i18n from './../../i18n';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";

export default class StepTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            requestId: '',
            statusid: '',
            requestType: Config.getAmendEmployeeSalaryRequestTypeId(),

            // step two variables
            attachmentFour: this.props.sendData.attachmentFour,
            employeeEmaritesId: this.props.sendData.employeeEmaritesId,
            employeePhoto: this.props.sendData.employeePhoto,
            copyOfLastProcedure: this.props.sendData.copyOfLastProcedure,
            previousandCurrentApprovedCompetencyReport: this.props.sendData.previousandCurrentApprovedCompetencyReport,
            previousandCurrentApprovedCompetencyReportShortName: this.props.sendData.previousandCurrentApprovedCompetencyReportShortName,
            employeeEmaritesIdErr: '',
            employeeEmaritesIdUrl: '',
            employeePhotoErr: '',
            employeePhotoUrl: '',
            copyOfLastProcedureErr: '',
            copyOfLastProcedureUrl: '',
            hraStudy: this.props.sendData.hraStudy,
            fileReportErrHra: '',
            previousandCurrentApprovedCompetencyReportErr: '',
            previousAndCurrentApprovedCompetencyReportUrl: '',
            approvedScaleSalariesErr: '',
            approvedScaleSalariesUrl: '',
            isNextClick: false,
            isArabic: true,
            editAttachmentArray: [],
            employeeEmaritesArray: [],
            employeePhotoArray: [],
            copyOfLastProcedureArray: [],
            previousAndCurrentArray: [],
            approvedScaleSalariesArray: [],
            employeeEmaritesIdObj: '',
            employeePhotoObj: '',
            copyOfLastProcedureObj: '',
            previousandCurrentApprovedCompetencyReportObj: '',
            approvedScaleSalariesObj: '',
            approvedScaleSalaries: this.props.sendData.approvedScaleSalaries
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempEmployeeEmaritesArray = [];
                let tempEmployeePhotoArray = [];
                let tempCopyOfLastProcedureArray = [];
                let tempPreviousAndCurrentArray = [];
                let tempApprovedScaleSalariesArray = [];

                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
              
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getAmendEmployeeSalEmiratesId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEmployeeEmaritesArray.push(temp);

                                this.setState({
                                    //employeeEmaritesId: item.filename,
                                    employeeEmaritesIdUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getAmendEmployeeSalEmpPhotoId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEmployeePhotoArray.push(temp);

                                this.setState({
                                    //employeePhoto: item.filename,
                                    employeePhotoUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getAmendEmployeeSalPreviousCopyOfLastProcedureId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempCopyOfLastProcedureArray.push(temp);

                                this.setState({
                                    //copyOfLastProcedure: item.filename,
                                    copyOfLastProcedureUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getAmendEmployeeSalPreviousCompentaencyReportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPreviousAndCurrentArray.push(temp);

                                this.setState({
                                    //previousandCurrentApprovedCompetencyReport: item.filename,
                                    previousAndCurrentApprovedCompetencyReportUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getAmendEmployeeSalAprrovedScaleAndSalariedId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempApprovedScaleSalariesArray.push(temp);

                                this.setState({
                                    approvedScaleSalariesUrl: item.aliasfilename
                                })
                            }
                        })

                        this.setState({
                            employeeEmaritesArray: tempEmployeeEmaritesArray,
                            employeePhotoArray: tempEmployeePhotoArray,
                            copyOfLastProcedureArray: tempCopyOfLastProcedureArray,
                            previousAndCurrentArray: tempPreviousAndCurrentArray,
                            approvedScaleSalariesArray: tempApprovedScaleSalariesArray,
                        })
                    }
                }

            });

        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }

    goToPrevious = () => {
        this.props.getPrev.stepTwoDataPrev({
            hraEmployeeStudyMaker: this.state.hraEmployeeStudyMaker,
            employeeEmaritesId: this.state.employeeEmaritesId,
            employeePhoto: this.state.employeePhoto,
            hraStudy: this.state.hraStudy,
            copyOfLastProcedure: this.state.copyOfLastProcedure,
            previousandCurrentApprovedCompetencyReport: this.state.previousandCurrentApprovedCompetencyReport,
            previousandCurrentApprovedCompetencyReportShortName: this.state.previousandCurrentApprovedCompetencyReportShortName,
            approvedScaleSalaries: this.state.approvedScaleSalaries
        });
    }

    onChangehandler = () => {
        this.setState({ isNextClick: false })
    }

    checkValidations = () => {

        let employeeEmaritesIdErr = '',
            employeePhotoErr = '',
            copyOfLastProcedureErr = '',
            previousandCurrentApprovedCompetencyReportErr = '',
            //jobDescFileErr = '',
            //copyOfLastYearEffiReportFileError = '',
            approvedScaleSalariesErr = '';

        if (this.state.employeeEmaritesArray && this.state.employeeEmaritesArray.length > 0) {
            employeeEmaritesIdErr = "";
        } else {
            if (!this.state.employeeEmaritesId) {
                employeeEmaritesIdErr = 'thisFieldIsRequired'
            }
        }

        if (this.state.employeePhotoArray && this.state.employeePhotoArray.length > 0) {
            employeePhotoErr = "";
        } else {
            if (!this.state.employeePhoto) {
                employeePhotoErr = 'thisFieldIsRequired'
            }
        }
        if (this.state.copyOfLastProcedureArray && this.state.copyOfLastProcedureArray.length > 0) {
            copyOfLastProcedureErr = "";
        } else {
            if (!this.state.copyOfLastProcedure) {
                copyOfLastProcedureErr = 'thisFieldIsRequired'
            }
        }

        if (this.state.previousAndCurrentArray && this.state.previousAndCurrentArray.length > 0) {
            previousandCurrentApprovedCompetencyReportErr = "";
        } else {
            if (!this.state.previousandCurrentApprovedCompetencyReport) {
                previousandCurrentApprovedCompetencyReportErr = 'thisFieldIsRequired'
            }
        }

        if (this.state.approvedScaleSalariesArray && this.state.approvedScaleSalariesArray.length > 0) {
            approvedScaleSalariesErr = "";
        } else {
            if (!this.state.approvedScaleSalaries) {
                approvedScaleSalariesErr = 'thisFieldIsRequired'
            }
        }

        this.setState({
            employeeEmaritesIdErr: employeeEmaritesIdErr,
            employeePhotoErr: employeePhotoErr,
            copyOfLastProcedureErr: copyOfLastProcedureErr,
            previousandCurrentApprovedCompetencyReportErr: previousandCurrentApprovedCompetencyReportErr,
            approvedScaleSalariesErr: approvedScaleSalariesErr
        }, () => {         
            if (!this.state.employeeEmaritesIdErr && !this.state.employeePhotoErr && !this.state.copyOfLastProcedureErr && !this.state.approvedScaleSalariesErr && !this.state.previousandCurrentApprovedCompetencyReportErr) {
                this.props.getData.stepTwoData({
                    requestId: this.state.requestId,
                    isEdit: this.state.isEdit,
                    employeeEmaritesId: this.state.employeeEmaritesId,
                    employeePhoto: this.state.employeePhoto,
                    copyOfLastProcedure: this.state.copyOfLastProcedure,
                    previousandCurrentApprovedCompetencyReport: this.state.previousandCurrentApprovedCompetencyReport,
                    approvedScaleSalaries: this.state.approvedScaleSalaries
                })
            }
        })

    }

    submitHandler = event => {
        // when submit the request
        // Config.setIsEditFlag(false);
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
    }

    onChangehandler = (value) => {
        this.setState({
            isNextClick: value
        });
    }

    employeeEmaritesIdChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ employeeEmaritesId: '', employeeEmaritesIdErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        employeeEmaritesId: e.target.files[0].name,
                        employeeEmaritesIdErr: ''
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                employeeEmaritesIdObj: reader.result
                            }, () => {
                                Config.setbase64ForAmendEmployeeSalaryThree(this.state.employeeEmaritesIdObj);
                            });
                        };
                        reader.readAsDataURL(file);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ employeeEmaritesId: '', employeeEmaritesIdErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ employeeEmaritesId: '', employeeEmaritesIdErr: 'thisFieldIsRequired' });
        }
    }

    previousandCurrentApprovedCompetencyReportOnChange = e => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                let fileNames = "";
                let fileSupportArray = [];
                for (let m = 0; m < e.target.files.length; m++) {
                    let inpFileName = e.target.files[m].name;
                    let fileSize = e.target.files[m].size;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            previousandCurrentApprovedCompetencyReport: '',
                            previousandCurrentApprovedCompetencyReportShortName: '',
                            previousandCurrentApprovedCompetencyReportErr: 'invalidFileType'
                        });
                    }
                    else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let previousandCurrentApprovedCompetencyReportShortName = fileNames.substring(0, 50);

                            let reader = new FileReader();
                            let file = e.target.files[0];

                            reader.onloadend = () => {
                                fileSupportArray.push(reader.result);
                                this.setState({
                                    prevAndCureentApprovedCompReportFileObj: fileSupportArray,
                                    previousandCurrentApprovedCompetencyReportErr: '',
                                    previousandCurrentApprovedCompetencyReport: fileNames,
                                    previousandCurrentApprovedCompetencyReportShortName: previousandCurrentApprovedCompetencyReportShortName,
                                }, () => {
                                    Config.setbase64ForAmendEmployeeSalaryFour(this.state.prevAndCureentApprovedCompReportFileObj);
                                });
                            }
                            reader.readAsDataURL(file);
                        }
                        else {
                            e.target.value = '';
                            this.setState({
                                previousandCurrentApprovedCompetencyReport: '',
                                previousandCurrentApprovedCompetencyReportShortName: '',
                                previousandCurrentApprovedCompetencyReportErr: 'invalidFileSize'
                            })
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    previousandCurrentApprovedCompetencyReport: '',
                    previousandCurrentApprovedCompetencyReportShortName: '',
                    previousandCurrentApprovedCompetencyReportErr: 'fileLimitErr'
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({
                previousandCurrentApprovedCompetencyReport: '',
                previousandCurrentApprovedCompetencyReportShortName: '',
                previousandCurrentApprovedCompetencyReportErr: 'thisFieldIsRequired'
            });
        }
    }

    approvedScaleSalariesOnChange = (e) => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ approvedScaleSalaries: '', approvedScaleSalariesErr: 'invalidFileType' });
            }
            else {

                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({
                        approvedScaleSalaries: e.target.files[0].name,
                        approvedScaleSalariesErr: ''
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                approvedScaleOfSalariesFileObj: reader.result,
                            }, () => {
                                Config.setbase64ForAmendEmployeeSalaryOne(this.state.approvedScaleOfSalariesFileObj);
                            });
                        };
                        reader.readAsDataURL(file);
                    });


                }
                else {
                    e.target.value = '';
                    this.setState({ approvedScaleSalaries: '', approvedScaleSalariesErr: 'invalidFileSize' })
                }
            }
        } else {
            e.target.value = '';
            this.setState({ approvedScaleSalaries: '', approvedScaleSalariesErr: 'thisFieldIsRequired' });
        }
    }

    employeePhotoOnChange = (e) => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ employeePhoto: '', employeePhotoErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];

                    this.setState({ employeePhoto: e.target.files[0].name, employeePhotoErr: '' }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                empPhotoFileObj: reader.result,
                            }, () => {
                                Config.setbase64ForAmendEmployeeSalaryTwo(this.state.empPhotoFileObj);
                            });
                        };
                        reader.readAsDataURL(file);
                    });


                }
                else {
                    e.target.value = '';
                    this.setState({ employeePhoto: '', employeePhotoErr: 'invalidFileSize' })
                }
            }
        }
        else {
            e.target.value = '';
            this.setState({ employeePhoto: '', employeePhotoErr: 'thisFieldIsRequired' });
        }
    }

    copyOfLastProcedureFileOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ copyOfLastProcedure: '', copyOfLastProcedureErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    this.setState({
                        copyOfLastProcedure: e.target.files[0].name,
                        copyOfLastProcedureErr: ''
                    }, () => {
                        reader.onloadend = () => {
                            this.setState({
                                copyOfLastProcedureFileObj: reader.result,
                            }, () => {
                                Config.setbase64ForAmendEmployeeSalaryFive(this.state.copyOfLastProcedureFileObj);
                            });
                        };
                        reader.readAsDataURL(file);
                    });


                }
                else {
                    e.target.value = '';
                    this.setState({ copyOfLastProcedure: '', copyOfLastProcedureErr: 'invalidFileSize' })
                }
            }
        } else {
            e.target.value = '';
            this.setState({ copyOfLastProcedure: '', copyOfLastProcedureErr: 'thisFieldIsRequired' });
        }
    }

    saveAsDraft = () => {
        this.props.getData.stepTwoData({
            formType: 'saveAsDraft',
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            hraEmployeeStudyMaker: this.state.hraEmployeeStudyMaker,
            employeeEmaritesId: this.state.employeeEmaritesId,
            employeePhoto: this.state.employeePhoto,
            hraStudy: this.state.hraStudy,
            copyOfLastProcedure: this.state.copyOfLastProcedure,
            previousandCurrentApprovedCompetencyReport: this.state.previousandCurrentApprovedCompetencyReport,
            previousandCurrentApprovedCompetencyReportShortName: this.state.previousandCurrentApprovedCompetencyReportShortName,
            approvedScaleSalaries: this.state.approvedScaleSalaries
        });
    };

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,

            // step two variables
            employeeEmaritesId: this.state.employeeEmaritesId,
            employeePhoto: this.state.employeePhoto,
            copyOfLastProcedure: this.state.copyOfLastProcedure,
            previousandCurrentApprovedCompetencyReport: this.state.previousandCurrentApprovedCompetencyReport,
            previousandCurrentApprovedCompetencyReportShortName: this.state.previousandCurrentApprovedCompetencyReportShortName,
            approvedScaleSalaries: this.state.approvedScaleSalaries
        });
    }

    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent steps={['Step1', 'Step2']} currentIndex={2} isArabic={this.props.isArabic} lineColor='#00BCD4' onChange={this.onChangehandler} activeColor='#00BCD4' nonActiveColor='#00BCD4' />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput"> {i18n[this.getLocale()].employeeEmaritesId}<span className="requireStar">*</span>  </label>
                        <div className="input-group" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                            <div className="custom-file-inp">
                                <input
                                    id="employeeEmaritesIdFile"
                                    type="file"
                                    name="employeeEmaritesId"
                                    onChange={(event) => this.employeeEmaritesIdChange(event)}
                                    required
                                />
                                <label htmlFor="employeeEmaritesIdFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="employeeEmaritesIdFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                ><span>{this.state.employeeEmaritesId}</span></label>
                            </div>
                            {
                                this.state.employeeEmaritesIdErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.employeeEmaritesIdErr == 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.employeeEmaritesIdErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', color: '#000' }}>
                            {i18n[this.getLocale()].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.employeeEmaritesArray &&
                                this.state.employeeEmaritesArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.employeeEmaritesArray} />
                                : <span></span>
                        }
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].employeePhoto}<span className="requireStar">*</span></label>
                        <div className="input-group" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                            <div className="custom-file-inp">
                                <input
                                    id="employeePhotoFile"
                                    type="file"
                                    name="employeePhoto"
                                    onChange={(event) => this.employeePhotoOnChange(event)}
                                    required
                                />
                                <label htmlFor="employeePhotoFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="employeePhotoFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                ><span>{this.state.employeePhoto}</span></label>
                            </div>
                            {
                                this.state.employeePhotoErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.employeePhotoErr == 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.employeePhotoErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', color: '#000' }}>
                            {i18n[this.getLocale()].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.employeePhotoArray &&
                                this.state.employeePhotoArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.employeePhotoArray} />
                                : <span></span>
                        }
                    </MDBCol>
                </MDBRow>

                <br />

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].copyOfLastProcedure}<span className="requireStar">*</span></label>
                        <div className="input-group" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                            <div className="custom-file-inp">
                                <input
                                    id="copyOfLastProcedureFile"
                                    type="file"
                                    name="copyOfLastProcedure"
                                    onChange={(event) => this.copyOfLastProcedureFileOnChange(event)}
                                    required
                                />
                                <label htmlFor="copyOfLastProcedureFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>  {i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="copyOfLastProcedureFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                ><span>{this.state.copyOfLastProcedure}</span></label>
                            </div>
                            {
                                this.state.copyOfLastProcedureErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.copyOfLastProcedureErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfLastProcedureErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', color: '#000' }}>
                            {i18n[this.getLocale()].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.copyOfLastProcedureArray &&
                                this.state.copyOfLastProcedureArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.copyOfLastProcedureArray} />
                                : <span></span>
                        }
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].previousAndCurrentApprovedCompetencyReport}<span className="requireStar">*</span></label>
                        <div className="input-group" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                            <div className="custom-file-inp">
                                <input
                                    id="previousandCurrentApprovedCompetencyReportFile"
                                    type="file"
                                    name="previousandCurrentApprovedCompetencyReport"
                                    onChange={(event) => this.previousandCurrentApprovedCompetencyReportOnChange(event)}
                                    required
                                    multiple
                                />
                                <label htmlFor="previousandCurrentApprovedCompetencyReportFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.getLocale()].chooseFile}
                                </label>

                                <label htmlFor="previousandCurrentApprovedCompetencyReportFile" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    className="fileName">
                                    {/* <span>{this.state.previousandCurrentApprovedCompetencyReport}</span> */}

                                    <span className="attachmentName">{this.state.previousandCurrentApprovedCompetencyReportShortName}</span>
                                    <span className="attachmentShortName">
                                        {
                                            this.state.previousandCurrentApprovedCompetencyReportShortName ?
                                                <MDBPopover
                                                    placement="bottom"
                                                    popover
                                                    clickable
                                                    id="popper12"
                                                    component="label"
                                                    popoverBody={i18n[this.getLocale()].viewMore}
                                                    size="sm"
                                                >
                                                    <MDBPopoverBody
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "space-around"
                                                        }}>
                                                        <span>{this.state.previousandCurrentApprovedCompetencyReport}</span>
                                                    </MDBPopoverBody>
                                                </MDBPopover>
                                                :
                                                ''
                                        }
                                    </span>
                                </label>
                            </div>
                            {
                                this.state.previousandCurrentApprovedCompetencyReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.previousandCurrentApprovedCompetencyReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.previousandCurrentApprovedCompetencyReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.previousandCurrentApprovedCompetencyReportErr === 'fileLimitErr' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', color: '#000' }}>
                            {i18n[this.getLocale()].acceptedFormatsAll}
                        </p>
                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                            {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                        </p>
                        {
                            (this.state.previousAndCurrentArray &&
                                this.state.previousAndCurrentArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.previousAndCurrentArray} />
                                : <span></span>
                        }
                    </MDBCol>

                </MDBRow>
                <br />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput"> {i18n[this.getLocale()].approvedScaleOfSalaries}<span className="requireStar">*</span></label>
                        <div className="input-group" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                            <div className="custom-file-inp">
                                <input
                                    id="approvedScaleSalariesFile"
                                    type="file"
                                    name="approvedScaleSalaries"
                                    onChange={(event) => this.approvedScaleSalariesOnChange(event)}
                                    required
                                />
                                <label htmlFor="approvedScaleSalariesFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                <label htmlFor="approvedScaleSalariesFile" style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    className="fileName"><span>{this.state.approvedScaleSalaries}</span></label>
                            </div>
                            {
                                this.state.approvedScaleSalariesErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.approvedScaleSalariesErr == 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.approvedScaleSalariesErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ textAlign: this.props.isArabic ? 'left' : 'right', color: '#000' }}>
                            {i18n[this.getLocale()].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.approvedScaleSalariesArray &&
                                this.state.approvedScaleSalariesArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.approvedScaleSalariesArray} />
                                : <span></span>
                        }
                    </MDBCol>

                </MDBRow>

                <br />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn" >{i18n[this.getLocale()].previous}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].submit}</MDBBtn>
                </MDBRow>
            </form>

        );
    }
}