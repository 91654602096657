import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Footer from './../components/Footer';
import Header from './../components/Header';
import SideBar from './../components/SideBar';
import i18n from './../i18n';
import './../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import SubHeaderStrip from './../components/SubHeaderStrip';
import StepOne from '../components/ExemptionFromAccompanyPatientSteps/StepOne';
import StepTwo from '../components/ExemptionFromAccompanyPatientSteps/StepTwo';
import StepThree from '../components/ExemptionFromAccompanyPatientSteps/StepThree';
import Config from './../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import ApiRequest from './../services/ApiRequest';
import { saveExemptionFromAccompanyPatient, uploadDocument, savePatientSalaryMatrix } from './../utils/Payload';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import moment from 'moment';

export default class ExemptionFromAccompanyPatien extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            step3: false,
            isEdit: '',
            requestId: '',
            statusid: '',
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isNextScreenActive: 1,
            empJob: "",
            grade: "",
            basicSalary: "",
            totalSalary: "",
            retirementContributions: "",
            passportCopy: "",
            medicalReport: "",
            letterFromEmbassy: "",
            copyOftheDigest: "",
            durationOfAccompanyingLeave: "",
            dateOfStartingWork: "",
            officialLetter: "",
            emiratedID: "",
            copyOfHumanResoourcesCommitteeFile: null,
            documentationOfFinancialObligationsFile: null,
            copyOfHumanResoourcesCommittee: "",
            dateOfSalaryTermination: null,
            documentationOfFinancialObligations: '',
            documentationOfFinancialObligationsShortName: '',
            requiredToPayBudget: "",
            selectedOption2: "Yes",
            budgetYear: "",
            budgetValue: "",
            annualBalanceOfVacations: "",
            justificationsEmployee: "",
            husbandWifeWork: "",
            noOfChildren: "",
            placeOfChildren: "",
            year: "",
            salaryStatus: "",
            totalSalaryDollar: "",
            noOfDays: "",
            basicSalaryTb: "",
            totalDays: "",
            totalSalaryPayed: 0,
            totalAmountExempted: "",
            HowToCalculate: "",
            violation: "",
            explain: "",
            dateOfDirectWork: null,
            headlinesOfRequest: "",
            employeeName: "",
            unifiedNumber: "",
            budgetAvailabilityYear: "",
            emiratesIDStepOne: '',
            exemptionSelectedValue: "",
            tableData: [{
                year: "",
                month: "1",
                fromDay: "",
                toDay: "",
                salaryStatus: "Continuous",
                subTotal: 0,
                noOfDays: 0
            }],
            view: 0,
            myTemp: 0,
            basicSalaryTable: '',
            subTotalTotal: '',
            totalNoOfDays: '',
            totalSalaryWithSubscription: '',
            retirementSalary: '',
            tswsrsSalary: '',
            requestType: '',
            officialLetterFromHeadOfEntityFileObj: null,
            passportCopyFIleObj: null,
            medicalReportFIleObj: null,
            letterFromEmbassyFileObj: null,
            copyOfDigestFileObj: null,
            documentationOfFinObligationsFileObjArray: [],
            emiratesIDFileObj: null,
            formType: "",
            requestTypeLabel: "",
            Degreeofrelationship:"",
            OtherAttach: '',
            OtherAttachFileObj:"",
            DegreeofrelationshipArray:[
                {
                    id:"1",
                    EnglishTitle:"First Degree",
                    ArabicTitle:"الدرجة الأولى",
            },
            {
                id:"2",
                EnglishTitle:"In-laws first class",
                ArabicTitle:"الأصهار من الدرجة الأولى",
        },
        {
            id:"3",
            EnglishTitle:"Second degree",
            ArabicTitle:"الدرجة الثانية",
    },
    {
        id:"4",
        EnglishTitle:"Third degree",
        ArabicTitle:"الدرجة الثالثة",
},
{
    id:"5",
    EnglishTitle:"Fourth degree",
    ArabicTitle:"الدرجة الرابعة",
},
            ],
        };
    }

    componentDidMount = () => {
        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: false,
                step3: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
    }

    componentWillMount() {
        let previewFlag = Config.getIsPreviewFlag();
        let previewckEditorArr = Config.getPreviewckEditorArray();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();

        if (previewFlag) {

            let HowToCalculate = '',
                explain = "",
                violation = "",
                officialLetter = '',
                passportCopy = "",
                medicalReport = "",
                letterFromEmbassy = "",
                copyOftheDigest = "",
                copyOfHumanResoourcesCommittee = "",
                documentationOfFinancialObligations = "",
                emiratedID = "",
                OtherAttach="",
                justificationsEmployee = '';

            //required to pay budget
            let requiredToPayBudgetValueFromPreview = "";
            if (previewObj.requiredToPayBudget) {
                if (previewObj.requiredToPayBudget === i18n["ar"].yes || previewObj.requiredToPayBudget === i18n["en"].yes) {
                    requiredToPayBudgetValueFromPreview = "Yes";
                } else if (previewObj.requiredToPayBudget === i18n["ar"].no || previewObj.requiredToPayBudget === i18n["en"].no) {
                    requiredToPayBudgetValueFromPreview = "No";
                }
            }

            //selected option 2
            let selectedOption2ValueFromPreview = "";
            if (previewObj.selectedOption2) {
                if (previewObj.selectedOption2 === i18n["ar"].yes || previewObj.selectedOption2 === i18n["en"].yes) {
                    selectedOption2ValueFromPreview = "Yes";
                } else if (previewObj.selectedOption2 === i18n["ar"].no || previewObj.selectedOption2 === i18n["en"].no) {
                    selectedOption2ValueFromPreview = "No";
                }
            }

            if (previewckEditorArr) {
                if (previewckEditorArr.length > 0) {
                    for (let j = 0; j < previewckEditorArr.length; j++) {
                        if (previewckEditorArr[j].label === 'justificationsEmployee') {
                            justificationsEmployee = previewckEditorArr[j].value;
                        }
                    }
                }
            }

            let violationFromPreview = "";
            if (previewckEditorArr) {
                if (previewckEditorArr.length > 0) {
                    for (let j = 0; j < previewckEditorArr.length; j++) {
                        if (previewckEditorArr[j].label === 'HowToCalculate') {
                            HowToCalculate = previewckEditorArr[j].value;
                        }
                        else if (previewckEditorArr[j].label === 'explain') {
                            explain = previewckEditorArr[j].value;
                        }
                        else if (previewckEditorArr[j].label === 'violation') {
                            violation = previewckEditorArr[j].value;
                            if (violation === i18n["ar"].yes || violation === i18n["en"].yes) {
                                violationFromPreview = "Yes";
                            } else if (violation === i18n["ar"].no || violation === i18n["en"].no) {
                                violationFromPreview = "No";
                            }
                        }

                    }
                }
            }

            if (previewAttachmentArray.length > 0) {
                for (let index = 0; index < previewAttachmentArray.length; index++) {
                    if (previewAttachmentArray[index].label === 'officialLetter') {
                        officialLetter = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'passportCopy') {
                        passportCopy = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'medicalReport') {
                        medicalReport = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'letterFromEmbassy') {
                        letterFromEmbassy = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'copyOftheDigest') {
                        copyOftheDigest = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'copyOfHumanResoourcesCommittee') {
                        copyOfHumanResoourcesCommittee = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'documentationOfFinancialObligations') {
                        documentationOfFinancialObligations = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'emiratedID') {
                        emiratedID = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'OtherAttach') {
                        OtherAttach = previewAttachmentArray[index].fileName;
                    }
                }
            }

            this.setState({
                step1: false,
                step2: false,
                step3: true,
                requestType: previewObj.requestType,
                empJob: previewObj.empJob,
                headlinesOfRequest: previewObj.headlinesOfRequest,
                employeeName: previewObj.employeeName,
                grade: previewObj.grade,
                basicSalary: previewObj.basicSalary,
                unifiedNumber: previewObj.unifiedNumber,
                totalSalary: previewObj.totalSalary,
                retirementContributions: previewObj.retirementContributions,
                durationOfAccompanyingLeave: previewObj.durationOfAccompanyingLeave,
                dateOfSalaryTermination: new Date(previewObj.dateOfSalaryTermination),
                dateOfDirectWork: new Date(previewObj.dateOfDirectWork),
                requiredToPayBudget: requiredToPayBudgetValueFromPreview,
                selectedOption2: selectedOption2ValueFromPreview,
                budgetAvailabilityYear: previewObj.budgetAvailabilityYear,
                budgetYear: previewObj.budgetYear,
                budgetValue: previewObj.budgetValue,
                annualBalanceOfVacations: previewObj.annualBalanceOfVacations,
                emiratesIDStepOne: previewObj.emiratesIDStepOne,
                requestTypeLabel: previewObj.requestTypeLabel,
                justificationsEmployee: justificationsEmployee,
                husbandWifeWork: previewObj.husbandWifeWork,
                noOfChildren: previewObj.noOfChildren,
                placeOfChildren: previewObj.placeOfChildren,
                totalAmountExempted: previewObj.totalAmountExempted,
                tableData: previewObj.tableData,
                basicSalaryTable: previewObj.basicSalaryTable,
                totalSalaryWithSubscription: previewObj.totalSalaryWithSubscription,
                retirementSalary: previewObj.retirementSalary,
                tswsrsSalary: previewObj.tswsrsSalary,
                subTotalTotal: previewObj.subTotalTotal,
                totalNoOfDays: previewObj.totalNoOfDays,
                HowToCalculate: HowToCalculate,
                explain: explain,
                violation: violationFromPreview,
                officialLetter: officialLetter,
                passportCopy: passportCopy,
                medicalReport: medicalReport,
                letterFromEmbassy: letterFromEmbassy,
                copyOftheDigest: copyOftheDigest,
                copyOfHumanResoourcesCommittee: copyOfHumanResoourcesCommittee,
                documentationOfFinancialObligations: documentationOfFinancialObligations,
                documentationOfFinancialObligationsShortName: previewObj.documentationOfFinancialObligationsShortName,
                emiratedID: emiratedID,
                Degreeofrelationship:previewObj.Degreeofrelationship,
                OtherAttach:OtherAttach
            });
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    nextButtonPressed = (isNextScreenActive) => {
        this.setState({ isNextScreenActive: isNextScreenActive });
    }

    stepTwoDataPrev = () => {
        this.setState({ step1: true, step2: false, step3: false });
    }

    stepThreeDataPrev = () => {
        this.setState({ step1: false, step2: true, step3: false });
    }

    getPrev = {
        stepOneDataPrev: this.stepOneDataPrev,
        stepTwoDataPrev: this.stepTwoDataPrev,
        stepThreeDataPrev: this.stepThreeDataPrev,
    };

    uploadFile = (i, totalCount, resAcompanyPatient, multifileCount) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let isLastFileInMultiUpload = false;
            let obj = {};
             if (i === 0) {
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient1();
                obj.filename = this.state.copyOfHumanResoourcesCommittee;
                obj.filetype = Config.getPatientHumanResourceComitteRecordId();
                tempBase64 = fileObj;
            }
            else if (i === 1) {
                
                // get base 64 for attachement
                let fileObjArray = Config.getbase64ForexemptionFromAccompanyPatient2();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }

                if (!this.state.documentationOfFinancialObligations) {
                    let filenamesArray = this.state.documentationOfFinancialObligations.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                obj.filetype = Config.getPatientFinancialObligationId();
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient9();
                obj.filename = this.state.documentationOfFinancialObligations;
                tempBase64 = fileObj[0];
            }
            else if (i === 2) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient3();
                obj.filename = this.state.officialLetter;
                obj.filetype = Config.getpatientDetailedsalaryCertificateId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient4();
                obj.filename = this.state.passportCopy;
                obj.filetype = Config.getPatientPassortCopyId();
                tempBase64 = fileObj;
            }
            else if (i === 4) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient5();
                obj.filename = this.state.medicalReport;
                obj.filetype = Config.getPatientMedicalReportId();
                tempBase64 = fileObj;
            }
            else if (i === 5) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient6();
                obj.filename = this.state.letterFromEmbassy;
                obj.filetype = Config.getPatientLetterFromEmbassyId();
                tempBase64 = fileObj;
            }
            else if (i === 6) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient7();
                obj.filename = this.state.copyOftheDigest;
                obj.filetype = Config.getPatientCopyOfdDigestId();
                tempBase64 = fileObj;
            }
            else if (i === 7) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionEmiratedID();
                obj.filename = this.state.emiratedID;
                obj.filetype = Config.getPatientEmiratesId();
                tempBase64 = fileObj;
            }

            else if (i === 8) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForOtherAttach();
                obj.filename = this.state.OtherAttach;
                obj.filetype = Config.getRETOtherAttachId();
                tempBase64 = fileObj;
            }
           /*  else if (i === 9) {
                // get base 64 for attachement
                fileObj = Config.getbase64ForexemptionFromAccompanyPatient9();
                obj.filename = this.state.OtherAttach;
                obj.filetype = Config.getPatientFinancialObligationId();
                tempBase64 = fileObj;
            } */

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resAcompanyPatient && resAcompanyPatient.data && resAcompanyPatient.data.id) {
                    reqId = resAcompanyPatient.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resAcompanyPatient.data.id;;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } 
                else {
                    obj.fileContent = tempBase64;
                }
                debugger;
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        if (i === 1 && obj.filename !== this.state.documentationOfFinancialObligations) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, resAcompanyPatient, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, resAcompanyPatient, multifileCount + 1);
                            }
                        } else {
                            this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    }

    stepOneData = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            //From Step 1
            setConfigObj.jobtitle = obj.empJob;
            setConfigObj.employeename = obj.employeeName;
            setConfigObj.requestheadline = obj.headlinesOfRequest;
            setConfigObj.grade = obj.grade;
            setConfigObj.basicsalary = obj.basicSalary;
            setConfigObj.unifiednumber = obj.unifiedNumber;
            setConfigObj.totalsalary = obj.totalSalary;
            setConfigObj.retirementcontribution = obj.retirementContributions;
            setConfigObj.durationofleave = obj.durationOfAccompanyingLeave;
            // dateOfSalaryTermination: new Date(`${editObj.dateofsalarytermination}`),
            // dateOfDirectWork: new Date(`${editObj.dateofwork}`),
            setConfigObj.dateofsalarytermination = obj.dateOfSalaryTermination;
            setConfigObj.dateofwork = obj.dateOfDirectWork;
            // setConfigObj.isdocumentofobligatory = obj.requiredToPayBudget;
            setConfigObj.isdocumentofobligatory = obj.selectedOption2 === "Yes" ? 1 : 0;
            //setConfigObj.isrequriedtopaybybudget = obj.selectedOption2;
            setConfigObj.isrequriedtopaybybudget = obj.requiredToPayBudget === "Yes" ? 1 : 0;

            setConfigObj.budgetyear = obj.budgetYear;
            setConfigObj.budgetavailableyear = obj.budgetAvailabilityYear;
            setConfigObj.budgetvalue = obj.budgetValue;
            setConfigObj.annualleavebalance = obj.annualBalanceOfVacations;
            setConfigObj.justification = obj.justificationsEmployee;
            setConfigObj.emiratesid = obj.emiratesIDStepOne;
            //exemptionSelectedValue: editObj.exemptionSelectedValue,
            setConfigObj.requestTypeLabel = obj.requestTypeLabel;
            setConfigObj.requesttype = obj.requestType;
            setConfigObj.Degreeofrelationship=obj.Degreeofrelationship;
            // overrite data in edit object
            Config.setEditObj(setConfigObj);
        }

        this.setState({
            
            step1: false, step2: true, step3: false,
            requestType: obj.requestType,
            empJob: obj.empJob,
            grade: obj.grade,
            basicSalary: obj.basicSalary,
            totalSalary: obj.totalSalary,
            retirementContributions: obj.retirementContributions,
            durationOfAccompanyingLeave: obj.durationOfAccompanyingLeave,
            dateOfSalaryTermination: obj.dateOfSalaryTermination,
            requiredToPayBudget: obj.requiredToPayBudget,
            selectedOption2: obj.selectedOption2,
            budgetYear: obj.budgetYear,
            budgetValue: obj.budgetValue,
            annualBalanceOfVacations: obj.annualBalanceOfVacations,
            justificationsEmployee: obj.justificationsEmployee,
            dateOfDirectWork: obj.dateOfDirectWork,
            headlinesOfRequest: obj.headlinesOfRequest,
            employeeName: obj.employeeName,
            unifiedNumber: obj.unifiedNumber,
            budgetAvailabilityYear: obj.budgetAvailabilityYear,
            emiratesIDStepOne: obj.emiratesIDStepOne,
            exemptionSelectedValue: obj.exemptionSelectedValue,
            requestTypeLabel: obj.requestTypeLabel,
            Degreeofrelationship:obj.Degreeofrelationship
        }, () => {
            this.nextButtonPressed(2);
        });
    }

    stepTwoData = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            // set step 2 varavles in edit obj
            setConfigObj.worktotaldays = obj.totalDays;
            setConfigObj.worktotalsalarytobepaid = obj.myTemp;
            setConfigObj.dateofstarting = obj.dateOfStartingWork;
            setConfigObj.husbandwifework = obj.husbandWifeWork;
            setConfigObj.numberofchidren = obj.noOfChildren;
            setConfigObj.placeofchildren = obj.placeOfChildren;
            setConfigObj.worktobeexempted = obj.totalAmountExempted;
            setConfigObj.howtocalculaterule = obj.HowToCalculate;
            setConfigObj.isviolation = obj.violation === "Yes" ? 1 : 0;
            setConfigObj.violationdetail = obj.explain;
            setConfigObj.basicsalary2 = obj.basicSalaryTable;
            setConfigObj.totalsalarywithsubscription = obj.totalSalaryWithSubscription;
            setConfigObj.retirement = obj.retirementSalary;
            setConfigObj.totalsalary2 = obj.tswsrsSalary;
            setConfigObj.salarymatrix = obj.tableData;
            // 
            Config.setEditObj(setConfigObj);
        }

        //MGM
        this.setState({

            step1: false, step2: false, step3: true,
            totalDays: obj.totalDays,
            myTemp: obj.myTemp,
            // dateOfStartingWork: obj.dateOfStartingWork,
            husbandWifeWork: obj.husbandWifeWork,
            noOfChildren: obj.noOfChildren,
            placeOfChildren: obj.placeOfChildren,
            totalAmountExempted: obj.totalAmountExempted,
            HowToCalculate: obj.HowToCalculate,
            violation: obj.violation,
            explain: obj.explain,
            tableData: obj.tableData,
            basicSalaryTable: obj.basicSalaryTable,
            subTotalTotal: obj.subTotalTotal,
            totalNoOfDays: obj.totalNoOfDays,
            totalSalaryWithSubscription: obj.totalSalaryWithSubscription,
            retirementSalary: obj.retirementSalary,
            tswsrsSalary: obj.tswsrsSalary
        }, () => {
            this.nextButtonPressed(3);
        });
    }

    stepTwoPrev = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;

            setConfigObj.worktotaldays = obj.totalDays;
            setConfigObj.worktotalsalarytobepaid = obj.myTemp;
            setConfigObj.dateofstarting = obj.dateOfStartingWork;
            setConfigObj.husbandwifework = obj.husbandWifeWork;
            setConfigObj.numberofchidren = obj.noOfChildren;
            setConfigObj.placeofchildren = obj.placeOfChildren;
            setConfigObj.worktobeexempted = obj.totalAmountExempted;
            setConfigObj.howtocalculaterule = obj.HowToCalculate;
            setConfigObj.isviolation = obj.violation === "Yes" ? 1 : 0;
            setConfigObj.violationdetail = obj.explain;
            setConfigObj.basicsalary2 = obj.basicSalaryTable;
            setConfigObj.totalsalarywithsubscription = obj.totalSalaryWithSubscription;
            setConfigObj.retirement = obj.retirementSalary;
            setConfigObj.totalsalary2 = obj.tswsrsSalary;
            setConfigObj.salarymatrix = obj.tableData;

            Config.setEditObj(setConfigObj);
        }

        this.setState({
            step1: true, step2: false, step3: false,
            year: obj.year,
            //salaryStatus: obj.salaryStatus,
            //totalSalaryDollar: obj.totalSalaryDollar,
            //noOfDays: obj.noOfDays,
            //basicSalaryTb: obj.basicSalaryTb,
            totalDays: obj.totalDays,
            myTemp: obj.myTemp,
            dateOfStartingWork: obj.dateOfStartingWork,
            husbandWifeWork: obj.husbandWifeWork,
            noOfChildren: obj.noOfChildren,
            placeOfChildren: obj.placeOfChildren,
            totalAmountExempted: obj.totalAmountExempted,
            HowToCalculate: obj.HowToCalculate,
            violation: obj.violation,
            explain: obj.explain,
            tableData: obj.tableData,
            basicSalaryTable: obj.basicSalaryTable,
            totalSalaryWithSubscription: obj.totalSalaryWithSubscription,
            retirementSalary: obj.retirementSalary,
            tswsrsSalary: obj.tswsrsSalary,
            subTotalTotal: obj.subTotalTotal,
            totalNoOfDays: obj.totalNoOfDays
            // emiratesID: obj.emiratedID
        });
    }

    stepThreePrev = (obj) => {
        this.setState({
            step1: false, step2: true, step3: false,
            officialLetter: obj.officialLetter,
            emiratedID: obj.emiratedID,
            passportCopy: obj.passportCopy,
            medicalReport: obj.medicalReport,
            letterFromEmbassy: obj.letterFromEmbassy,
            copyOftheDigest: obj.copyOftheDigest,
            copyOfHumanResoourcesCommittee: obj.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: obj.documentationOfFinancialObligations,
            documentationOfFinancialObligationsShortName: obj.documentationOfFinancialObligationsShortName,
            OtherAttach:obj.OtherAttach
        });
    }

    submitApiCall = () => {
        
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let resposeObj = {};
                if (this.state.formType === "saveAsDraft") {
                    resposeObj.draft = 1;
                } else {
                    resposeObj.draft = 0;
                }
                resposeObj.isEdit = this.state.isEdit;
                resposeObj.requestId = this.state.requestId;
                resposeObj.requesttype = this.state.requestType;
                resposeObj.jobtitle = this.state.empJob;
                resposeObj.grade = this.state.grade;
                resposeObj.basicsalary = this.state.basicSalary;
                resposeObj.totalsalary = this.state.totalSalary;
                resposeObj.unifiedNumber = this.state.unifiedNumber;
                resposeObj.headlinesOfRequest = this.state.headlinesOfRequest;
                resposeObj.employeeName = this.state.employeeName;
                resposeObj.retirementcontribution = this.state.retirementContributions;
                resposeObj.durationofleave = this.state.durationOfAccompanyingLeave;
                resposeObj.Degreeofrelationship=this.state.Degreeofrelationship;
                let tempDate = '';
                if (this.state.dateOfSalaryTermination !== '' && this.state.dateOfSalaryTermination !== null) {
                    tempDate = new Date(this.state.dateOfSalaryTermination);
                    let day = tempDate.getDate();
                    let month = tempDate.getMonth() + 1;
                    let year = tempDate.getFullYear();
                    resposeObj.dateofsalarytermination = year + '-' + month + '-' + day;
                } else {
                    resposeObj.dateofsalarytermination = tempDate;
                }

                // resposeObj.isrequriedtopaybybudget = this.state.selectedOption2 === "Yes" ? 1 : 0;
                // resposeObj.isdocumentofobligatory = this.state.requiredToPayBudget === "Yes" ? 1 : 0;
                resposeObj.isrequriedtopaybybudget = this.state.requiredToPayBudget === "Yes" ? 1 : 0;
                resposeObj.isdocumentofobligatory = this.state.selectedOption2 === "Yes" ? 1 : 0;
                resposeObj.budgetyear = this.state.budgetYear;
                resposeObj.budgetvalue = this.state.budgetValue;
                resposeObj.selectedOption2= this.state.selectedOption2;
                let tempDateOne = '';
                if (this.state.dateOfDirectWork !== '' && this.state.dateOfDirectWork !== null) {
                    tempDateOne = new Date(this.state.dateOfDirectWork);
                    let day = tempDateOne.getDate();
                    let month = tempDateOne.getMonth() + 1;
                    let year = tempDateOne.getFullYear();
                    resposeObj.dateofwork = year + '-' + month + '-' + day;
                } else {
                    resposeObj.dateofsalarytermination = tempDateOne;
                }

                resposeObj.annualleavebalance = this.state.annualBalanceOfVacations;
                resposeObj.justification = this.state.justificationsEmployee;
                resposeObj.budgetavailableyear = this.state.budgetAvailabilityYear;
                resposeObj.husbandwifework = this.state.husbandWifeWork;
                resposeObj.numberofchidren = this.state.noOfChildren;
                resposeObj.placeofchildren = this.state.placeOfChildren;
                resposeObj.worktotaldays = this.state.totalDays;
                resposeObj.worktotalsalarytobepaid = this.state.myTemp;
                resposeObj.worktobeexempted = this.state.totalAmountExempted;
                resposeObj.howtocalculaterule = this.state.HowToCalculate;
                resposeObj.violation = this.state.violation === "Yes" ? 1 : 0;
                resposeObj.explain = this.state.explain;
                resposeObj.howtocalculateid = '1';
                resposeObj.emiratesid = this.state.emiratesIDStepOne;
                resposeObj.exemptionSelectedValue = this.state.exemptionSelectedValue;

                let tempDate2 = new Date(this.state.dateOfStartingWork);
                let day2 = tempDate2.getDate();
                let month2 = tempDate2.getMonth() + 1;
                let year2 = tempDate2.getFullYear();
                resposeObj.dateofstarting = year2 + '-' + month2 + '-' + day2;

                resposeObj.basicsalary2 = this.state.basicSalaryTable;
                resposeObj.totalsalarywithsubscription = this.state.totalSalaryWithSubscription;
                resposeObj.retirement = this.state.retirementSalary;
                resposeObj.totalsalary2 = this.state.tswsrsSalary;
                resposeObj.totalamount = this.state.subTotalTotal;
                resposeObj.totaldays = this.state.totalNoOfDays;

                let payload = saveExemptionFromAccompanyPatient(resposeObj);
                let endPoint = 'SavePoliciesPatient'
                let url = Config.getUrl(endPoint);

                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                        if (resAcompanyPatient.Status) {
                            let tempTableData = this.state.tableData;
                            let payloadForPatientSalaryMatrix = savePatientSalaryMatrix(resAcompanyPatient.data.id, tempTableData);

                            let endPoint = 'SavePoliciesPatientSalaryMatrix'
                            let urlForSalaryMatrix = Config.getUrl(endPoint);

                            ApiRequest.sendRequestForPost(urlForSalaryMatrix, payloadForPatientSalaryMatrix, resForSalaryMatrix => {
                                if(resForSalaryMatrix.Status)
                                {
                                let totalFile = 9;
                                this.uploadFile(0, totalFile, resAcompanyPatient, 0);
                                }
                            })

                         
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            });
                        }
                    })
                })
            })
        }
    }

    stepThreeData = (obj) => {
        if (obj.saveFrom === "Step1") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                requestType: obj.requestType,
                empJob: obj.empJob,
                grade: obj.grade,
                basicSalary: obj.basicSalary,
                totalSalary: obj.totalSalary,
                retirementContributions: obj.retirementContributions,
                durationOfAccompanyingLeave: obj.durationOfAccompanyingLeave,
                dateOfSalaryTermination: obj.dateOfSalaryTermination,
                requiredToPayBudget: obj.requiredToPayBudget,
                selectedOption2: obj.selectedOption2,
                budgetYear: obj.budgetYear,
                budgetValue: obj.budgetValue,
                annualBalanceOfVacations: obj.annualBalanceOfVacations,
                justificationsEmployee: obj.justificationsEmployee,
                dateOfDirectWork: obj.dateOfDirectWork,
                headlinesOfRequest: obj.headlinesOfRequest,
                employeeName: obj.employeeName,
                unifiedNumber: obj.unifiedNumber,
                budgetAvailabilityYear: obj.budgetAvailabilityYear,
                emiratesIDStepOne: obj.emiratesIDStepOne,
                exemptionSelectedValue: obj.exemptionSelectedValue,
                Degreeofrelationship:obj.Degreeofrelationship
            }, () => {
                this.submitApiCall();
            })
        } else if (obj.saveFrom === "Step2") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                totalDays: obj.totalDays,
                myTemp: obj.myTemp,
                // dateOfStartingWork: obj.dateOfStartingWork,
                husbandWifeWork: obj.husbandWifeWork,
                noOfChildren: obj.noOfChildren,
                placeOfChildren: obj.placeOfChildren,
                totalAmountExempted: obj.totalAmountExempted,
                HowToCalculate: obj.HowToCalculate,
                violation: obj.violation,
                explain: obj.explain,
                tableData: obj.tableData,
                basicSalaryTable: obj.basicSalaryTable,
                subTotalTotal: obj.subTotalTotal,
                totalNoOfDays: obj.totalNoOfDays,
                totalSalaryWithSubscription: obj.totalSalaryWithSubscription,
                retirementSalary: obj.retirementSalary,
                tswsrsSalary: obj.tswsrsSalary
            }, () => {
                this.submitApiCall();
            })
        }
        else {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                officialLetter: obj.officialLetter,
                emiratedID: obj.emiratedID,
                OtherAttach:obj.OtherAttach,
                passportCopy: obj.passportCopy,
                medicalReport: obj.medicalReport,
                letterFromEmbassy: obj.letterFromEmbassy,
                copyOftheDigest: obj.copyOftheDigest,
                copyOfHumanResoourcesCommittee: obj.copyOfHumanResoourcesCommittee,
                documentationOfFinancialObligations: obj.documentationOfFinancialObligations,
                documentationOfFinancialObligationsShortName: obj.documentationOfFinancialObligationsShortName,
                //file code
                officialLetterFromHeadOfEntityFileObj: obj.officialLetterFromHeadOfEntityFileObj,
                passportCopyFIleObj: obj.passportCopyFIleObj,
                medicalReportFIleObj: obj.medicalReportFIleObj,
                letterFromEmbassyFileObj: obj.letterFromEmbassyFileObj,
                copyOfDigestFileObj: obj.copyOfDigestFileObj,
                copyOfHumanResoourcesCommitteeFile: obj.copyOfHumanResoourcesCommitteeFile,
                documentationOfFinObligationsFileObjArray: obj.documentationOfFinObligationsFileObjArray,
                emiratesIDFileObj: obj.emiratesIDFileObj
            }, () => {
                this.submitApiCall();
            });
        }

    }

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
        stepThreeData: this.stepThreeData,
        stepTwoPrev: this.stepTwoPrev,
        stepThreePrev: this.stepThreePrev
    };

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    handlePreViewNavigation = (obj) => {
        let editObj = Config.getEditObj();
        localStorage.setItem('currentPath', 'exemptionFromAccompanyPatient');
        let previewObj = {};
        previewObj.isEdit = editObj.isEdit;
        previewObj.requestId = editObj.requestId;
        // previewObj.isEdit = this.state.isEdit;
        // previewObj.requestId = this.state.requestId;
        previewObj.requestType = this.state.requestType;
        previewObj.requestTypeLabel = this.state.requestTypeLabel;
        previewObj.empJob = this.state.empJob;
        previewObj.grade = this.state.grade;
        previewObj.basicSalary = this.state.basicSalary;
        previewObj.totalSalary = this.state.totalSalary;
        previewObj.retirementContributions = this.state.retirementContributions;
        previewObj.durationOfAccompanyingLeave = this.state.durationOfAccompanyingLeave;
        previewObj.dateOfSalaryTermination = this.state.dateOfSalaryTermination ? moment(this.state.dateOfSalaryTermination).format('L').toString() : "";

        //selected option 2
        if (this.state.selectedOption2 === "Yes") {
            previewObj.selectedOption2 = i18n[this.state.isArabic ? "ar" : "en"].yes
        } else {
            previewObj.selectedOption2 = i18n[this.state.isArabic ? "ar" : "en"].no
        }

        previewObj.annualBalanceOfVacations = this.state.annualBalanceOfVacations;
        previewObj.dateOfDirectWork = moment(this.state.dateOfDirectWork).format('L').toString();
        previewObj.headlinesOfRequest = this.state.headlinesOfRequest;
        previewObj.employeeName = this.state.employeeName;
        previewObj.unifiedNumber = this.state.unifiedNumber;
        previewObj.emiratesIDStepOne = this.state.emiratesIDStepOne;
    
        previewObj.Degreeofrelationship =this.state.isArabic ?  this.state.DegreeofrelationshipArray[this.state.Degreeofrelationship-1].ArabicTitle:this.state.DegreeofrelationshipArray[this.state.Degreeofrelationship-1].EnglishTitle ;
        if (this.state.selectedOption2 === "Yes") {
            previewObj.budgetAvailabilityYear = this.state.budgetAvailabilityYear;
        } else {
            // previewObj.budgetYear = "";
            // previewObj.requiredToPayBudget = this.state.requiredToPayBudget;
            //required to pay budget
            if (this.state.requiredToPayBudget === "Yes") {
                previewObj.requiredToPayBudget = i18n[this.state.isArabic ? "ar" : "en"].yes
            } else {
                previewObj.requiredToPayBudget = i18n[this.state.isArabic ? "ar" : "en"].no
            }

            if (this.state.requiredToPayBudget === "Yes") {
                previewObj.budgetValue = this.state.budgetValue;
                previewObj.budgetYear = this.state.budgetYear;
            }
        }

        //step 2
        previewObj.husbandWifeWork = this.state.husbandWifeWork;
        previewObj.noOfChildren = this.state.noOfChildren;
        previewObj.placeOfChildren = this.state.placeOfChildren;
        previewObj.totalAmountExempted = this.state.totalAmountExempted;

        previewObj.tableData = this.state.tableData;
        // previewObj.totalDays = this.state.totalDays;
        previewObj.basicSalaryTable = this.state.basicSalaryTable;
        previewObj.subTotalTotal = this.state.subTotalTotal;
        previewObj.totalNoOfDays = this.state.totalNoOfDays;
        previewObj.totalSalaryWithSubscription = this.state.totalSalaryWithSubscription;
        previewObj.retirementSalary = this.state.retirementSalary;
        previewObj.tswsrsSalary = this.state.tswsrsSalary;
        previewObj.documentationOfFinancialObligationsShortName = obj.documentationOfFinancialObligationsShortName;

        let explainValue = '';
        if (this.state.violation === 'Yes') {
            explainValue = this.state.explain;
        }

        let tempViolationValue = "";
        if (this.state.violation === "Yes") {
            tempViolationValue = i18n[this.state.isArabic ? "ar" : "en"].yes
        } else {
            tempViolationValue = i18n[this.state.isArabic ? "ar" : "en"].no
        }

        let ckEditorArr = [
            {
                label: "violation",
                value: tempViolationValue,
                flag: "radio"
            },
            {
                label: 'justificationsEmployee',
                value: this.state.justificationsEmployee,
            },
            {
                label: 'HowToCalculate',
                value: this.state.HowToCalculate,
            },
        ];

        if (this.state.violation === "Yes") {
            ckEditorArr.push({
                label: 'explain',
                value: explainValue,
            })
        }

        let previewAttachMentArray =
            [
                {
                    "label": "officialLetter",
                    "fileName": obj.officialLetter,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient3()
                },
                {
                    "label": "passportCopy",
                    "fileName": obj.passportCopy,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient4()
                },
                {
                    "label": "medicalReport",
                    "fileName": obj.medicalReport,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient5()
                },
                {
                    "label": "letterFromEmbassy",
                    "fileName": obj.letterFromEmbassy,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient6()
                },
                {
                    "label": "copyOftheDigest",
                    "fileName": obj.copyOftheDigest,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient7()
                },
                {
                    "label": "copyOfHumanResoourcesCommittee",
                    "fileName": obj.copyOfHumanResoourcesCommittee,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient1()

                },
                {
                    "label": "documentationOfFinancialObligations",
                    "fileName": obj.documentationOfFinancialObligations,
                    "fileObj": Config.getbase64ForexemptionFromAccompanyPatient2()
                },
                {
                    "label": "emiratedID",
                    "fileName": obj.emiratedID,
                    "fileObj": Config.getbase64ForexemptionEmiratedID()
                },
                {
                    "label": "OtherAttach",
                    "fileName": obj.OtherAttach,
                    "fileObj": Config.getBase64ForOtherAttach()
                }
            ];

        this.props.history.replace('/preview', { data: previewObj, attachmentArr: previewAttachMentArray, ckEditorArr: ckEditorArr });
    }

    render() {
        const sideBarData = Config.getServiceSideBar('exemptionFromAccompanyPatient', this.state.isArabic, this, 0);


        return (
            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'exemptionFromAccompanyPatient' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].exemptionFromAccompanyPatien}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            {
                                this.state.step1 ?
                                    <StepOne
                                        isArabic={this.state.isArabic}
                                        sendData={{
                                            empJob: this.state.empJob,
                                            requestType: this.state.requestType,
                                            grade: this.state.grade,
                                            basicSalary: this.state.basicSalary,
                                            totalSalary: this.state.totalSalary,
                                            retirementContributions: this.state.retirementContributions,
                                            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                                            dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                            requiredToPayBudget: this.state.requiredToPayBudget,
                                            selectedOption2: this.state.selectedOption2,
                                            budgetYear: this.state.budgetYear,
                                            budgetValue: this.state.budgetValue,
                                            annualBalanceOfVacations: this.state.annualBalanceOfVacations,
                                            justificationsEmployee: this.state.justificationsEmployee,
                                            dateOfDirectWork: this.state.dateOfDirectWork,
                                            headlinesOfRequest: this.state.headlinesOfRequest,
                                            employeeName: this.state.employeeName,
                                            unifiedNumber: this.state.unifiedNumber,
                                            budgetAvailabilityYear: this.state.budgetAvailabilityYear,
                                            emiratesIDStepOne: this.state.emiratesIDStepOne,
                                            exemptionSelectedValue: this.state.exemptionSelectedValue,
                                            requestTypeLabel: this.state.requestTypeLabel,
                                            Degreeofrelationship:this.state.Degreeofrelationship
                                        }}
                                        getData={this.getData}
                                        saveAsDraft={this.stepThreeData}
                                    />
                                    : null
                            }
                            {
                                this.state.step2 ?
                                    <StepTwo
                                        isArabic={this.state.isArabic}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        onPrevButtonPressed={this.nextButtonPressed}
                                        sendData={{
                                            totalDays: this.state.totalDays,
                                            myTemp: this.state.myTemp,
                                            //  dateOfStartingWork: this.state.dateOfStartingWork,
                                            husbandWifeWork: this.state.husbandWifeWork,
                                            dateOfSalaryTermination: this.state.dateOfSalaryTermination,
                                            noOfChildren: this.state.noOfChildren,
                                            placeOfChildren: this.state.placeOfChildren,
                                            totalAmountExempted: this.state.totalAmountExempted,
                                            HowToCalculate: this.state.HowToCalculate,
                                            violation: this.state.violation,
                                            explain: this.state.explain,
                                            tableData: this.state.tableData,
                                            basicSalaryTable: this.state.basicSalaryTable !='' ?  this.state.basicSalaryTable:this.state.basicSalary,
                                            
                                            totalNoOfDays: this.state.totalNoOfDays,
                                            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription !='' ? this.state.totalSalaryWithSubscription:this.state.totalSalary ,
                                            retirementSalary: this.state.retirementSalary !='' ? this.state.retirementSalary:this.state.retirementContributions,
                                            tswsrsSalary: this.state.tswsrsSalary !='' ? this.state.tswsrsSalary:parseFloat(this.state.totalSalary.replace(',',''))+parseFloat(this.state.retirementContributions.replace(',','')),
                                            
                                            
                                        }}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        saveAsDraft={this.stepThreeData}
                                    />
                                    :
                                    null
                            }
                            {
                                this.state.step3 ?
                                    <StepThree
                                        isArabic={this.state.isArabic}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        onPrevButtonPressed={this.nextButtonPressed}
                                        sendData={{
                                            officialLetter: this.state.officialLetter,
                                            emiratedID: this.state.emiratedID,
                                            passportCopy: this.state.passportCopy,
                                            medicalReport: this.state.medicalReport,
                                            letterFromEmbassy: this.state.letterFromEmbassy,
                                            copyOftheDigest: this.state.copyOftheDigest,
                                            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
                                            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
                                            documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
                                            requestType:this.state.requestType,
                                            OtherAttach:this.state.OtherAttach
                                        }}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        saveAsDraft={this.stepThreeData}
                                        previewPage={this.handlePreViewNavigation}
                                    />
                                    :
                                    null
                            }
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            // height={'270vh'}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}