import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

const striptags = require('striptags');



export default class Step3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChrimanSelected:'',
            MembersArray:[],
            entityArray:[],
            formalrequestletterArray: [],
            formalrequestletterErr: "",
            formalrequestletter: this.props.sendData.formalrequestletter,
            formalrequestletterFileObj: "",
            formalrequestletterUrl:'',
            requesterentityname:'',
requesterentitynameErr:'',
requesterName:'',
requesterNameErr:'',
requesterphoneNumber:'',
requesterphoneNumberErr:'',
rquesterEmail:'',
rquesterEmailErr:'',
MemberJobTitleErr:'',
   
            isEdit:this.props.isEdit,
            requestId:this.props.sendData.requestId,
            memberTitleId:'',
            
       
            requesterName:this.props.sendData.requesterName,
            rquesterEmail: this.props.sendData.rquesterEmail,
            requesterphoneNumber: this.props.sendData.requesterphoneNumber,
            formalrequestletter:this.props.sendData.formalrequestletter,
            MembersArray:this.props.sendData.MembersArray,

   poistionsTypeArray: [
                {
                    id: "1",
                    EnglishTitle: "Chairman",
                    ArabicTitle: "رئيساً"
                },
                {
                    id: "2",
                    EnglishTitle: "Member",
                    ArabicTitle: "عضواً"
                }
            ],
        }
    }

    FormalrequestletterOnChange = e => {
        let inpFileName = e.target.files[0].name;
        let fileSize = e.target.files[0].size;
        let fileExt = inpFileName.split('.').pop().toUpperCase();
        if (e.target.files.length > 0) {
          
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ formalrequestletter: '', formalrequestletterErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ formalrequestletter: inpFileName, formalrequestletterErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                formalrequestletterFileObj: reader.result
                            }, () => {
                                Config.setBase64ForDisciplinaryboardformalrequestletter(this.state.formalrequestletterFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ formalrequestletterFile: '',formalrequestletterFileErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            RequestType: this.state.RequestType,
            employeeName: this.state.employeeName,
            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
            EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
           
        });
    };

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ requesterName: event.target.value });
            }
        } else {
            this.setState({ requesterName: '' });
        }
    };

    handleRquesterEmail = (event) => {
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ rquesterEmail: event.target.value, rquesterEmailErr: '' });
            }
            else {
                this.setState({ rquesterEmail: "", rquesterEmailErr: "thisFieldIsRequired" });
            }
        } else {
            this.setState({ rquesterEmail: "", rquesterEmailErr: "thisFieldIsRequired" });
        }
    };

    handleRequesterPhoneNumber = (event) => {
        let requesterphoneNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ requesterphoneNumber: event.target.value }, () => {
                if (Validator.validateContactNumber(requesterphoneNumber))
                {
                    this.setState({ requesterphoneNumberErr: '' });
                } else {
                    this.setState({ requesterphoneNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ requesterphoneNumber: '', requesterphoneNumberErr: 'thisFieldIsRequired' });
        }
    };

  
 

    goToPrevious = () => {
        this.props.getData.stepThreePrev({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            // formType: 'saveAsDraft',
             saveFrom: "Step3",
            //RequestType: this.state.RequestType,
            requesterName:this.state.requesterName,
            rquesterEmail: this.state.rquesterEmail,
            requesterphoneNumber: this.state.requesterphoneNumber,
            formalrequestletter:this.state.formalrequestletter,
            MembersArray:this.state.MembersArray,
            
        });
    };
    componentDidMount = () => {
        let temp = [];
        let obj = {};
        temp.push(obj);
        this.setState({MembersArray:temp});
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
debugger;
this.setState({entityArray:tempEntityArray,MembernameErr:'1',
jobdegreeErr:'1',
EntityErr:'1',
MembertitleErr:'1'});

        if (editFlag) {
            this.setState({
                
                employeeName:editObj.employeename,
                EmployeeInfoEmployeeNumber:editObj.employeenumber,
                EmployeeInfoJobTitle:editObj.jobtitle,
                EmployeeInfoJobDegree:editObj.grade,
                EmployeeInfoEmirateID:editObj.emiratesid,
                EmployeeInfoEmployeeUID:editObj.unifiednumber,
                EmployeeInfoBasicSalary:editObj.basicsalary,
                EmployeeInfoTotalSalary:editObj.totalsalary,
EmployeeInfoRetirementcontributions: editObj.retirementcontribution,
RequestType:editObj.requesttype,
requestId:editObj.requestId
            });
        }
        else
        {
            let loginData = Config.getLocalStorageLoginData();
            let tempEntityName = this.props.isArabic ? loginData && loginData.EntityNameAr : loginData && loginData.EntityNameEn;
            let tempEmployeeName = this.props.isArabic ? loginData && loginData.NameAr : loginData && loginData.NameEn;
            this.setState({requesterentityname:tempEntityName,
                requesterName:tempEmployeeName,
                requesterphoneNumber:loginData.ContactNumber,
                rquesterEmail:loginData.Email,});

        }
    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    handleEmployeeInfoEmirateID = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ EmployeeInfoEmirateID: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ EmployeeInfoEmirateIDErr: '' });
                } else {
                    this.setState({ EmployeeInfoEmirateIDErr: 'invalidEmiratesId' });
                }
            });
        }
        else {
            this.setState({ EmployeeInfoEmirateID: '', EmployeeInfoEmirateIDErr: 'thisFieldisRequired' });
        }
    }


    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    
    addnewmember = () => { 
        
        if(this.state.MembernameErr!='1' && this.state.MembertitleErr!='1'&& this.state.MembertitleErr!='2' && this.state.jobdegreeErr!='1' &&
        this.state.EntityErr!='1' &&  this.state.EntityErr!='2' && this.state.MemberJobTitleErr!='1')
        {
        let obj = {};
        obj.WorkShopLocation='';
        obj.WorkShopDate='';
        obj.WorkShopfrom='';
        obj.WorkShopto='';

        if (this.state.MembersArray.length < 40) {
            this.setState({
                MembersArray: this.state.MembersArray.concat(obj),
                view: this.state.view + 1,MembernameErr:'1',
                jobdegreeErr:'1',
                EntityErr:'1',
                MembertitleErr:'1',
                MemberJobTitleErr:'1'
            });
        }
    }
    else

    {
        let errormessage = '';
        if(this.state.MembernameErr=='1')
        {
            errormessage  = errormessage+i18n[this.getLocale()].Disciplinaryboard.MembernameErr;
        }
        if(this.state.MemberJobTitleErr=='1')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.MemberJobTitleErr;
        }
         if (this.state.MembertitleErr=='1')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.MembertitleErr;
        }
        if (this.state.MembertitleErr=='2')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.dublicateMembertitleErr;
        }
        
         if (this.state.jobdegreeErr=='1')
        {
            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.jobdegreeErr;
        }
             if (this.state.EntityErr=='1')
        {
            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EntityErr;

        }
        if (this.state.EntityErr=='2')
                {
                    errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EntityErrChriman;
        
                }
   

        

        this.setState({
            titleAlert: i18n[this.getLocale()].error,
            messageAlert: errormessage
        }, () => {
            this.alertSubmit();
        })
    }

    }

    removemember = (indexVal) => {
        var array = this.state.MembersArray;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        MembersArray: array
                    });

                    //ReCalculate total no of days & total salary to be payed
                 
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            MembersArray: array
                        });
                     
                    }
                }
            }
        }

    }

    submitHandler =(event) =>{
        debugger;
        event.preventDefault();
        event.target.className += " was-validated";
        let requesterNameErr='',
        rquesterEmailErr='',memberslengtherror='',
        requesterphoneNumberErr='',formalrequestletterErr=''
        ;
        if (!this.state.requesterName) {
            requesterNameErr = 'thisFieldIsRequired';
        }
        if (!this.state.rquesterEmail) {
            rquesterEmailErr = 'thisFieldIsRequired';
        }
        if (!this.state.requesterphoneNumber) {
            requesterphoneNumberErr = 'thisFieldIsRequired';
        }
        if (!this.state.formalrequestletter) {
            formalrequestletterErr = 'thisFieldIsRequired';
        }
        let iseven=false;
        if (this.state.MembersArray.length%2 == 0)
        {
            iseven =true;
        }
    
        if(this.state.MembersArray.length<3 || iseven)
        {
            memberslengtherror='1';
        }
    

      
      
   let tempobj=this.state.MembersArray;

this.setState({ requesterNameErr, rquesterEmailErr,
    rquesterEmailErr,formalrequestletterErr
   }, () => {
            

        if (!requesterNameErr && !rquesterEmailErr && !rquesterEmailErr && !formalrequestletterErr &&this.state.MembernameErr!='1' && this.state.MembertitleErr!='1' && this.state.MembertitleErr!='2' && this.state.jobdegreeErr!='1' &&
        this.state.EntityErr!='1' &&   this.state.EntityErr!='2' && memberslengtherror!='1' && this.state.MemberJobTitleErr!='1') {
            

          
                {
                    this.setState({
                        isNextClick: true,
                        isfirstPageValidate: true
                    }, () => {
                        this.props.getData.stepThreeData({
                            isEdit: this.state.isEdit,
                            requestId: this.state.requestId,
                            // formType: 'saveAsDraft',
                             saveFrom: "Step3",
                            //RequestType: this.state.RequestType,
                            requesterName:this.state.requesterName,
                            rquesterEmail: this.state.rquesterEmail,
                            requesterphoneNumber: this.state.requesterphoneNumber,
                            formalrequestletter:this.state.formalrequestletter,
                            MembersArray:this.state.MembersArray,
                        });
                    });
                }
            }
            else
            {
                let errormessage = '';
                
                if(this.state.MembernameErr=='1')
                {
                    errormessage  = errormessage+i18n[this.getLocale()].Disciplinaryboard.MembernameErr;
                }
                if(this.state.MemberJobTitleErr=='1')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.MemberJobTitleErr;
        }
                if(memberslengtherror=='1')
                {
                    errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.memberslengtherror;
                }
                
                 if (this.state.MembertitleErr=='1')
                {
                    errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.MembertitleErr;
                }
                if (this.state.MembertitleErr=='2')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.dublicateMembertitleErr;
        }
                
                 if (this.state.jobdegreeErr=='1')
                {
                    errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.jobdegreeErr;
                }
                     if (this.state.EntityErr=='1')
                {
                    errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EntityErr;
        
                }
                if (this.state.EntityErr=='2')
                {
                    errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EntityErrChriman;
        
                }
           
           
        
                
        
                this.setState({
                    titleAlert: i18n[this.getLocale()].error,
                    messageAlert: errormessage
                }, () => {
                    this.alertSubmit();
                })

            } 
            
           
        
    });

    }

    
    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };

    handleChangeForEntity = (event,index) => {
        debugger;
        let entityname = event.target.value;
        let errcode='',EntityErr='';
        event.target.parentNode.className += " was-validated";
        if(entityname=="")
        {
            errcode='thisFieldIsRequired';
            EntityErr='1';
        }
        if(this.state.memberTitleId==1 || this.state.memberTitleId=='1')
        {
            let requesterEntityID = Config.getLocalStorageLoginData().EntityID;
        if(event.target.value==requesterEntityID)
        {
            EntityErr='2'; 
        }
        }
        let tempobj = this.state.MembersArray;
        tempobj[index].entity=entityname;
        tempobj[index].entityErr=errcode;
        this.setState({MembersArray:tempobj,EntityErr:EntityErr});
    
    
    };

  

    handleMemberName= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].membername=event.target.value;
        tempobj[index].membernameErr='';
        
            this.setState({
                MembersArray:tempobj,
                MembernameErr:'',
            });
            }
        } else {
            
        
            tempobj[index].membername=event.target.value;
            tempobj[index].membernameErr='thisFieldIsRequired';
             this.setState({ MembernameErr: '1',MembersArray:tempobj });
        }
    }

    handleMemberMemberJobTitle= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].MemberJobTitle=event.target.value;
        tempobj[index].MemberJobTitleErr='';
        
            this.setState({
                MembersArray:tempobj,
                MemberJobTitleErr:'',
            });
            }
        } else {
            
        
            tempobj[index].MemberJobTitle=event.target.value;
            tempobj[index].MemberJobTitleErr='thisFieldIsRequired';
             this.setState({ MemberJobTitleErr: '1',MembersArray:tempobj });
        }
    }
   /* Member Title* */
   handleMemberTitle = (event, index) => {
    let dropDownLabel = '';
    let chrimaneerror = '0';
    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.MembersArray;
    if (this.state.poistionsTypeArray && this.state.poistionsTypeArray.length > 0) {
        for (let index = 0; index < this.state.poistionsTypeArray.length; index++) {
            if (parseInt(event.target.value) === parseInt(this.state.poistionsTypeArray[index].id)) {
                dropDownLabel = this.props.isArabic ?
                    this.state.poistionsTypeArray[index].ArabicTitle :
                    this.state.poistionsTypeArray[index].EnglishTitle;
            }
        }
    }
    if (event.target.value > 0 && dropDownLabel != '') {

        debugger;
        //if (event.target.value.trim() != '')

        {
            // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;



            tempobj[index].title = dropDownLabel;
            tempobj[index].titlevalue = event.target.value;
            tempobj[index].titleErr = '';
            let isChrimanSelected = '';
            if ((event.target.value == 1 || event.target.value == '1') && (this.state.isChrimanSelected == '')) {
                this.setState({

                    isChrimanSelected: '0',
                });
            }
            else if (event.target.value=='1') {


                chrimaneerror = '1';
            }

            if (chrimaneerror == '1') {
                this.setState({
                    MembersArray: tempobj,
                    MembertitleErr: '2',
                    memberTitleId: event.target.value,


                });

            }
            else {
                this.setState({
                    MembersArray: tempobj,
                    MembertitleErr: '',
                    memberTitleId: event.target.value
                });
            }
        }

    }
    else {
        tempobj[index].title = '';
        tempobj[index].titlevalue = event.target.value;
        tempobj[index].titleErr = 'thisFieldIsRequired';
        this.setState({
            MembertitleErr: '1', MembersArray: tempobj,
            memberTitleId: event.target.value
        })
    }
}
   /** */

    handleMemberJobDegree= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let tempobj = this.state.MembersArray;
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            
            if (regex.test(event.target.value)) {
                
        
        tempobj[index].jobdegree=event.target.value;
        tempobj[index].jobdegreeErr='';
        
            this.setState({
                MembersArray:tempobj,
                jobdegreeErr:'',
            });
            }
        } else {
            
        
            tempobj[index].jobdegree=event.target.value;
            tempobj[index].jobdegreeErr='thisFieldIsRequired';
             this.setState({ jobdegreeErr: '1',MembersArray:tempobj });
        }
            }

    render() {
      
        let element = this.state.MembersArray.map((item, index) => {
            return (<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.membername}<span className="requireStar">*</span></label>
                        
                                <input
                                type="text"   className="form-control"
                                 
                                    value={item.membername}
                                onChange={(value) => { this.handleMemberName(value,index) }}
                                   required
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                           
                            {
                                item.membernameErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.title}<span className="requireStar">*</span></label>
                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select"
                                id="sources-funding"
                                onChange={(value) => { this.handleMemberTitle(value,index) }}
                                value={item.titlevalue}
                                required>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                    
                   this.state.poistionsTypeArray ? this.state.poistionsTypeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            {/* <input
                                type="text"
                                value={item.title}   className="form-control"
                                    onChange={(value) => { this.handleMemberTitle(value,index) }}
                                  
                                  
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                /> */}
                            
                            {
                                item.titleErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
        
        
                </MDBRow>
        
                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.jobdegree}<span className="requireStar">*</span></label>
                            
                            <input
                                type="text"   className="form-control"
                                value={item.jobdegree}
                                onChange={(value) => { this.handleMemberJobDegree(value,index) }}
                                   
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            
                            {
                                item.jobdegreeErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
        
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.workentity}<span className="requireStar">*</span></label>
        
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={e=>this.handleChangeForEntity(e,index)}
                                value={item.entity}
                                required>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                        if(item.id>4)
                                        {
                                        return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}</option>
                                        }
                                    }) : null
                                }
                            </select>
        
                            {
                                item.entityErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
        
                </MDBRow>
                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.BoardMemberJobTitle}<span className="requireStar">*</span></label>
                        
                                <input
                                type="text"   className="form-control"
                                 
                                    value={item.MemberJobTitle}
                                onChange={(value) => { this.handleMemberMemberJobTitle(value,index) }}
                                   required
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                           
                            {
                                item.MemberJobTitleErr == 'thisFieldIsRequired' ?
                                    <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    : ''}
                        </div>
                    </MDBCol>
                   </MDBRow>
                <MDBRow style={{ margin: '5px 34%' }}>
                    <MDBBtn onClick={() => this.addnewmember()} className="gridActionBtAttch whitetext">
                        {i18n[this.props.isArabic ? "ar" : "en"].Disciplinaryboard.addnewmember}
                    </MDBBtn>
                    <MDBBtn onClick={() => this.removemember
                        (index)} className="gridUploadAttch whitetext">
                        {i18n[this.props.isArabic ? "ar" : "en"].Disciplinaryboard.removemember}
                    </MDBBtn>
                </MDBRow>
        
        
        
            </div>)
        })
        return (
            // <React.Fragment>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2","Step3"]}
                    currentIndex={3}
                    isArabic={!this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
         
                <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].Disciplinaryboard.requesterinfo} </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  
  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>

  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.entityname}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.requesterentityname}
                                onChange={this.handleEmployeeInfoEmployeeNumber}
                                type="text"
                                id="requesterEntity"
                                className="form-control"
                                name="requesterEntity"
                                disabled={true}
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                             {
                                this.state.requesterentitynameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.requestername}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.requesterName}
                                onChange={this.handleEmployeeName}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.requesterNameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>
                
               
</MDBRow>

<MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.phoneNumber}<span className="requireStar">*</span></label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.requesterphoneNumber}
                                onChange={this.handleRequesterPhoneNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                               {
                                this.state.requesterphoneNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.requesterphoneNumberErr == 'invalidContactNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.email}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.rquesterEmail}
                                onChange={this.handleRquesterEmail}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {this.state.rquesterEmailErr == "thisFieldIsRequired" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                            {this.state.rquesterEmailErr == "invalidEmail" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidEmail}
                                </div>
                                :
                                null}

                        </div>
                    </MDBCol>

               
</MDBRow>
<MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.formalrequestletter}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="formalrequestletterFile"
                                    type="file"
                                    name="formalrequestletter"
                                    onChange={event => this.FormalrequestletterOnChange(event)}
                                />
                                <label
                                    htmlFor="formalrequestletterFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="formalrequestletterFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.formalrequestletter}</span>
                                </label>
                            </div>
                            {
                                this.state.formalrequestletterErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.formalrequestletterErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.formalrequestletterErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.formalrequestletterArray &&
                                    this.state.formalrequestletterArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.formalrequestletterArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

</MDBRow>
    

      </div>
      </div>

      <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].Disciplinaryboard.memberslist} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
          {element}
     </div>
          
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
             <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn>
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].submit}
                           </MDBBtn>
       
                           {/* {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    } */}
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                       </MDBRow>
       
      
               
                       </form>    

    //   </React.Fragment>
              
        );
    }
}