import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class Step1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            employeeName:'',
            EmployeeInfoEmployeeNumber:'',
            EmployeeInfoJobTitle:'',
            EmployeeInfoJobDegree:'',
            EmployeeInfoEmirateID:'',
            EmployeeInfoEmployeeUID:'',
            employeeNameErr:'',
            EmployeeInfoEmployeeNumberErr:'',
            EmployeeInfoJobTitleErr:'',
            EmployeeInfoJobDegreeErr:'',
            EmployeeInfoEmirateIDErr:'',
            EmployeeInfoEmployeeUIDErr: '',
            EmployeeInfoTotalSalary: "",
            EmployeeInfoTotalSalaryErr:'',
            EmployeeInfoBasicSalary: '',
            EmployeeInfoBasicSalaryErr:'',
            
            EmployeeInfoRetirementcontributions: "",
            EmployeeInfoRetirementcontributionsErr:'',
            RequestType:'',
            RequestTypeErr:'',
            isEdit:this.props.isEdit,
            requestId:this.props.requestId,
            EmployeeJobNumber:'',
            EmployeeJobNumberErr:'',
            salaryErr:'',
        }
    }

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            RequestType: this.state.RequestType,
            employeeName: this.state.employeeName,
            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
            EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
           
        });
    };

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };
    handleEmployeeInfoJobDegree = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoJobDegree: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoJobDegree: '' });
        }
    };
    handleEmployeeInfoBasicSalary = (value) => {
        if (value) {
            this.setState({ EmployeeInfoBasicSalary: value.formattedValue, EmployeeInfoBasicSalaryErr: "" });
        }
    };
    handleEmployeeInfoRetirementcontributions = (value) => {
        if (value) {
            this.setState({ EmployeeInfoRetirementcontributions: value.formattedValue, EmployeeInfoRetirementcontributionsErr: "" });
        }
    };
    handleEmployeeInfoTotalSalary = (value) => {
        if (value) {
            this.setState({ EmployeeInfoTotalSalary: value.formattedValue, EmployeeInfoTotalSalaryErr: "" });
        }
    };
    handleEmployeeInfoJobTitle = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoJobTitle: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoJobTitle: '' });
        }
    };
    handleEmployeeInfoEmployeeNumber = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoEmployeeNumber : event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoEmployeeNumber: '' });
        }
    };
    handleEmployeeInfoEmployeeUID = (event) => {
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ EmployeeInfoEmployeeUID: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoEmployeeUID: '' })
        }
    };

    
    componentDidMount = () => {
    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    handleEmployeeInfoEmirateID = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ EmployeeInfoEmirateID: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ EmployeeInfoEmirateIDErr: '' });
                } else {
                    this.setState({ EmployeeInfoEmirateIDErr: 'invalidEmiratesId' });
                }
            });
        }
        else {
            this.setState({ EmployeeInfoEmirateID: '', EmployeeInfoEmirateIDErr: 'thisFieldisRequired' });
        }
    }

    

    submitHandler =(event) =>{
        debugger;
        event.preventDefault();
        event.target.className += " was-validated";
        

       
            this.setState({
                isNextClick: true,
                isfirstPageValidate: true
            }, () => {
                this.props.getData.stepOneData({
                    isEdit: this.state.isEdit,
                
                });
            });
        }

    

    
    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };

    render() {
      
       
        return (
            // <React.Fragment>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2","Step3"]}
                    currentIndex={1}
                    isArabic={!this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
                <ul class="nav nav-tabs">
                    <li class="nav-item Customcontainer">
                        <a class="nav-link active" href="#">{i18n[this.getLocale()].Disciplinaryboard.intro} </a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                        {this.props.isArabic?
                        <ol className="DisciplinaryboardIntrolist">
                            <li>	يجب ألا يقل عدد أعضاء مجلس التأديب بما فيهم رئيس مجلس التأديب عن ثلاثة أعضاء على الأقل من الدرجة الأولى فما فوق وأن يكون عددهم فردياً، مع مراعاة ألا تقل درجة أي منهم عن درجة الموظف المحال إلى مجلس التأديب.</li>
                            <li>يُراعى ألا يكون رئيس مجلس التأديب من ذات الجهة الحكومية طالبة تشكيل المجلس، على أن يكون من جهة حكومية أخرى ولا تقل درجته الوظيفية عن درجة مدير تنفيذي. </li>
                            <li>يجب ألا يكون عضو مجلس التأديب قد سبق له إبداء الرأي في موضوع التحقيق بأي صفة كانت. </li>
                            <li>
                            لا يجوز إحالة الموظف إلى مجلس التأديب إلا بعد قيام الجهة الحكومية بتشكيل لجنة من موظفين على الأقل للتحقيق في المخالفة المنسوبة للموظف. 
                            </li>
                            <li>
                            	يتم إحالة الموظف إلى مجلس التأديب بموجب قرار من رئيس الجهة الحكومية متضمناً بياناً بالوقائع المسندة للموظف. 
                            </li>
                            <li>
                            يصدر قرار مجلس التأديب مشتملاً على أسبابه التي أقيم عليها ويتم النطق به في الجلسة المحددة لذلك، ويُبلغ الموظف بالقرار وأسبابه كتابة خلال أسبوعين من صدوره، وتُرسل نسخة منه لجهة عمله والهيئة.
                            </li>

                        </ol>
                        :
                        <ol className="DisciplinaryboardIntrolist">
                            <li>The number of members of the Disciplinary Board, including the President of the Disciplinary Council, must not be less than at least three members of the first degree or more, and their number should be individual, taking into account that the degree of any of them is not less than that of the employee referred to the Disciplinary Council.
</li>
                            <li>It should be noted that the Chairman of the Disciplinary Board is not from the same government agency requesting the formation of the Council, provided that he is from another government agency and his job grade is not less than that of an executive director.
</li>
                            <li>The disciplinary council member must not have previously given an opinion on the subject of the investigation in any capacity.
</li>
                            <li>The employee may not be referred to the Disciplinary Board except after the government agency has formed a committee of at least employees to investigate the violation attributed to the employee.
</li>
                            <li>The employee is referred to the Disciplinary Board according to a decision by the head of the government agency, including a statement of the facts assigned to the employee.
</li>
                            <li>The disciplinary board’s decision shall be issued, including the reasons on which it was evaluated and pronounced in the session specified for that. The employee shall be informed of the decision and the reasons thereof in writing within two weeks of its issuance, and a copy of it shall be sent to his work authority and the authority.
</li>
                        </ol>
    }
                          <br />
                           
                            {/* {
                                this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].budgetAvailabilityError}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>

                        </MDBRow>


                    </div>
                </div>

             
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].next_btn}
                           </MDBBtn>
       
                           {
                            //    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            //        null
                            //        :
                                //    <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                //        {i18n[this.getLocale()].saveAsDraft}
                                //    </MDBBtn>
                           }
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                       </MDBRow>
       
      
               
                       </form>    

    //   </React.Fragment>
              
        );
    }
}