import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol,MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import i18n from './../i18n';
import PopOverComponent from './../components/PopOverComponent';
import ViewRecordComponent from '../components/PopOverSubComponents/ViewRecordComponent';
import DeleteRecordComponent from '../components/PopOverSubComponents/DeleteRecordComponent';
import StatusUpdateComponent from '../components/PopOverSubComponents/StatusUpdateComponent';
import CommentComponent from '../components/PopOverSubComponents/CommentComponent';
import FollowComponent from '../components/PopOverSubComponents/FollowComponent';
import ExtendDateComponent from '../components/PopOverSubComponents/ExtendDateComponent';
import ExtendDateReplayComponent from '../components/PopOverSubComponents/ExtendDateReplayComponent';

import AssignToSector from './../components/PopOverSubComponents/AssignToSector';
import AssignToGm from './../components/PopOverSubComponents/AssignToGm';
import AssignBackToEntity from './../components/PopOverSubComponents/AssignBackToEntity';
import AddStudy from "./../components/PopOverSubComponents/AddStudy";
import AssignToComponent from "../components/PopOverSubComponents/AssignToComponent";
import SubHeaderStrip from '../components/SubHeaderStrip';
import ApiRequest from './../services/ApiRequest';
import OpenCreationStageComponent from './../components/OrganizationStructure/OpenCreationStage';
import OrganizationStructureCompleted from './../components/OrganizationStructure/OrganizationStructureCompleted';

import Config from './../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { saveStatusPayload, assginGmPayload, getRequestByIdPayload, getLookupRequestByIdPayload,getUnderApprovalPayLoadById } from './../utils/Payload';
import Footer from './../components/Footer';
import "react-tabs/style/react-tabs.css";
import DataTable from './../components/DataTable'
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';
import { AlternateEmail } from '@material-ui/icons';
var isArabicVariable;

export default class ListPage extends Component {

    constructor(props) {
        super(props);
        disableBrowserBackButton();
        this.child = React.createRef();
        this.state = {
            isreolad:false,
            CurretntUnderApprovalID:0,
            changeState: false,
            titleAlert: '',
            messageAlert: '',
            loginData: '',
            canViewSCI: 0,
            canViewInformation: 0,
            canviewbonusrequest: 0,
            canviewamendscalerequest: 0,
            canviewamendsalaryrequest: 0,
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            // data services
            canviewextendcreaterequest: 0,
            canviewextendreviewrequest: 0,
            canviewextendannualrequest: 0,
            canviewemployeeperformancerequest: 0,
            canviewjoinimplementrequest: 0,
            canviewopencomplainsrequest: 0,
            canviewamendsystemsrequest: 0,
            canviewreportsstaticsrequest: 0,
            canviewotherrequestsrequest: 0,
            // end of data services varibles

            // organization structure
            canvieworgrequest: 0,
            canvieworgrequesttree: 0,
            canviewtree: 0,

            isLoading: false,
            theme: 'light',
            y: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            modal: false,
            selectedIndex: 0,
            userEditedTableData: {},
            viewObject: {},
            comment: '',
            statusHistory: [],
            isSideBarMinimized: false,
            Attachments: [
                
            ],
            selectedAction: '',
            comments: [
                []
            ],
            temp: {
                columns: [
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.no,
                        field: 'no',
                        sort: 'asc'
                    },
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.createdBy,
                        field: 'createdby',
                        sort: 'asc'
                    },
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.requestNumber,
                        field: 'requestNo',
                        sort: 'asc'
                    },
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.reason,
                        field: 'reason',
                        sort: 'asc'
                    },
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.status,
                        field: 'status',
                        sort: 'asc'
                    },
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.createdOn,
                        field: 'createdon',
                        sort: 'asc'
                    },
                    {
                        label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.action,
                        field: 'actions',
                        sort: 'asc'
                    }
                ]
            },
            data: {},
            popUpRowArray: [],
            entityName: '',
            pathName: '',
            isFromLookup: false,
            columnData: [
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.no,
                    field: 'no',
                    sort: 'asc'
                },
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.createdBy,
                    field: 'createdby',
                    sort: 'asc'
                },
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.requestNumber,
                    field: 'requestNo',
                    sort: 'asc'
                },
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.reason,
                    field: 'reason',
                    sort: 'asc'
                },
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.status,
                    field: 'status',
                    sort: 'asc'
                },
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.createdOn,
                    field: 'createdon',
                    sort: 'asc'
                },
                {
                    label: i18n[isArabicVariable === 'ar' ? 'ar' : 'en'].viewComponent.action,
                    field: 'actions',
                    sort: 'asc'
                },
            ],
            isexportVisible: true
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        // recevied login data flag
        Config.setLoginDataReceivedFirstTime(false);

        if (!this.props.location.state) {
            this.setState({
                pathName: localStorage.getItem('currentPath')
            })
        } else if (this.props.location.state) {
            localStorage.setItem('currentPath', this.props.location.state.pathname);
            this.setState({
                pathName: this.props.location.state.pathname
            })
        } else if (this.state.pathName === "lookUp") {
            this.setState({ isFromLookup: true });
        }

        let tempLoginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: tempLoginData
        }, () => {
           
            this.setState({
                isInternal: this.state.loginData && this.state.loginData.internalcontact,
                canViewSCI: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canreviewscirequest,
                canViewInformation: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewirrequest,
                canviewbonusrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                canviewamendscalerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                canviewamendsalaryrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest,
                canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest,
                // Data services
                canviewextendcreaterequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendcreaterequest,
                canviewextendreviewrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendreviewrequest,
                canviewextendannualrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewextendannualrequest,
                canviewemployeeperformancerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewemployeeperformancerequest,
                canviewjoinimplementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewjoinimplementrequest,
                canviewopencomplainsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewopencomplainsrequest,
                canviewamendsystemsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsystemsrequest,
                canviewreportsstaticsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreportsstaticsrequest,
                canviewotherrequestsrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewotherrequestsrequest,
                // organization structure

                canvieworgrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
                canvieworgrequesttree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.CanCreateStructure,
                canviewtree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewtree,


            }, () => {
                Config.setCanViewTree(this.state.canvieworgrequesttree);
            })
        })
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    updateListData = (data, popUpRowArray) => {
        
      
        
        let tempIndex = 0;
        if (popUpRowArray && popUpRowArray.length === 1) {
      
            this.setState({
                data: data,
                popUpRowArray: popUpRowArray,
                selectedIndex: tempIndex
            });
        } else {
      
            
            this.setState({
                data: data,
                popUpRowArray: popUpRowArray,
            });
        }
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    getModalTitle = () => {
        
        
        switch (this.state.selectedAction) {
            case 'view':
                return i18n[this.getLocale()].viewComponent.viewRecord
            case 'edit':
                return i18n[this.getLocale()].viewComponent.editRecord
            case 'delete':
                return i18n[this.getLocale()].viewComponent.deleteRecord
            case 'comment':
                return i18n[this.getLocale()].viewComponent.comments
            case 'Reopen':
                return i18n[this.getLocale()].Reopen
                case 'EditComment':
                    return i18n[this.getLocale()].editcomment
            case 'update_status':
                return i18n[this.getLocale()].update_status.Update_Status
            case "assigntodep":
                return i18n[this.getLocale()].assignToDep;
            case "backtomanager":
                return i18n[this.getLocale()].backToManager;
            case "assigntospecificdep":
                return i18n[this.getLocale()].assignToSpecificDep;
            case "assigntodatadep":
                return i18n[this.getLocale()].assignToDataDep
            case "assigntospecificemp":
                return i18n[this.getLocale()].loackrequest;
            case "assigntoemp":
                return i18n[this.getLocale()].assignToEmp;
            case "assigntogmoffice":
                return i18n[this.getLocale()].assignToGmOffice;
            case "assigntostrategicoffice":
                return i18n[this.getLocale()].assignToStrategicOffice;
            case "assignbacktoentity":
                return i18n[this.getLocale()].assignBackToEntity;
            case "assigntoentity":
                return i18n[this.getLocale()].assignToEntity;
            case "addStudy":
                return i18n[this.getLocale()].addStudy;
            case "addReply":
                return i18n[this.getLocale()].addReply;
            case "assigntosector":
                return i18n[this.getLocale()].assignToSector;
            case "follow":
                return i18n[this.getLocale()].follow;
            case "CloseRequest":
                return i18n[this.getLocale()].canCloseRequest;
            case "ExtendDate":
                return i18n[this.getLocale()].extendinfodate;
            case "ExtendDateReplay":
                return i18n[this.getLocale()].replayextendinfodate;
            default:
                return '';
        }

    }

    updateComments = (obj) => {
        let c = [...this.state.comments];
        c[this.state.selectedIndex] = obj;
        this.setState({
            comments: c
        })
    }

    alert = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [{
                label: i18n[this.getLocale()].ok,
                onClick: () => this.props.history.replace('/listpage', {
                    pathname: this.state.pathName
                })
            }],
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    manageAction = (name, index) => {
       
        debugger;
       
        if (name === 'edit') {
            this.editRecord(index)
        }

        for (let i = 0; i < this.state.data.rows.length; i++) {
            if (document.getElementById('checkbox' + i)) {
                if (i % 2 === 0) {
                    document.getElementById('checkbox' + i).parentElement.parentElement.style.backgroundColor = '#ECECEC';
                }
                else {
                    document.getElementById('checkbox' + i).parentElement.parentElement.style.backgroundColor = 'white';
                }
            } else {
                break;
            }
        }

      
        document.getElementById('checkbox' + index).parentElement.parentElement.style.backgroundColor = 'var(--btnColor)';
        this.setState({ selectedAction: name });
        this.setState({ selectedIndex: index });
        this.toggle();
        // this.setState({
        //     selectedAction: name,
        //     selectedIndex: index
        // }, () => {
        //     this.toggle();
        // });

    }

    callRequestById = (requestId, endPoint) => {
      
        //let id = requestId;
        let payload = getRequestByIdPayload(requestId);
        let url = Config.getUrl(endPoint);

        ApiRequest.sendRequestForPost(url, payload, res => {
           
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                   
                    if (res.Status) {

                        if (this.state.pathName === 'Disciplinaryboard') {
                            Config.setIsEditFlag(true);
                            let editObj = {};
                            Config.setEditObj(editObj);
                            editObj.EmployeesArray = res.data.BoardEmployees;
                            editObj.requestId = res.data.id;
                            editObj.RequestType = res.data.requesttype;

                            this.props.history.push('/Disciplinaryboard', {
                                pathname: 'Disciplinaryboard',
                                data: res.data
                            });
                        }


                        if (this.state.pathName === 'reviewHRPolicies') {
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {};
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.requestreason = res.data.requestreason;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/reviewHRPolicies', {
                                pathname: 'reviewHRPolicies',
                                data: res.data
                            });
                    
                        } else if (this.state.pathName === 'exceptionADHousing') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employeeName = res.data.employeename;
                            editObj.entityname = res.data.entityname;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.financialcost = res.data.financialcost;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.EmployeeJobNumber = res.data.employeenumber;
                            editObj.housingallowance = res.data.housingallowance;
                            //editObj.jobname = res.data.jobname;
                            editObj.jobname = res.data.jobname;
                            editObj.contractperiod = res.data.contractperiod;
                            editObj.retirementcontribution = res.data.retirementcontribution;
                            editObj.requestreason = res.data.requestreason;
                            editObj.contracttype = res.data.contracttypeid;
                            editObj.recommendation = res.data.recommendation;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/exceptionADHousing', {
                                pathname: 'exceptionADHousing',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'amendEmployeeSalary') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employeename = res.data.employeename;
                            editObj.nationality = res.data.nationality;
                            editObj.age = res.data.age;
                            editObj.designationdate = res.data.designationdate.split(' ')[0];
                            editObj.currentbasicsalary = res.data.currentbasicsalary;
                            editObj.proposedbasicsalary = res.data.proposedbasicsalary;
                            editObj.percentageincrease = res.data.percentageincrease;
                            editObj.totalexpeirence = res.data.totalexpeirence;
                            editObj.qualification = res.data.qualification;
                            editObj.exprienceafterqualification = res.data.exprienceafterqualification;
                            editObj.annualprevevaluation = res.data.annualprevevaluation;
                            editObj.annualcurrevaluation = res.data.annualcurrevaluation;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.grade = res.data.grade;
                            editObj.jobrank = res.data.jobrank;
                            editObj.proceduretype = res.data.proceduretype;
                            editObj.financialratio = res.data.financialratio;
                            editObj.proceduredate = res.data.proceduredate.split(' ')[0];
                            editObj.reasontomodify = res.data.reasontomodify;
                            editObj.baseofincrease = res.data.baseofincrease;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/amendEmployeeSalary', {
                                pathname: 'amendEmployeeSalary',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'amendSalaryScreen') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.justification = res.data.justification;
                            editObj.principle = res.data.principle;
                            editObj.fundingid = res.data.fundingid;
                            editObj.isallocated = res.data.isallocated;
                            editObj.budgetapprovalamount = res.data.budgetapprovalamount;
                            editObj.currentbasicsalary = res.data.currentbasicsalary;
                            editObj.proposedbasicsalary = res.data.proposedbasicsalary;
                            editObj.currentannualbenefits = res.data.currentannualbenefits;
                            editObj.proposedannualbenefits = res.data.proposedannualbenefits;
                            editObj.currentfinancialeffect = res.data.currentfinancialeffect;
                            editObj.proposedfinancialeffect = res.data.proposedfinancialeffect;
                            editObj.currentscalechallenge = res.data.currentscalechallenge;
                            editObj.proposedscalechallenge = res.data.proposedscalechallenge;
                            editObj.currentincreaeaverage = res.data.currentincreaeaverage;
                            editObj.currentdecreaseaverage = res.data.currentdecreaseaverage;
                            editObj.currentapplyeffect = res.data.currentapplyeffect;
                            editObj.proposedappleffect = res.data.proposedapplyeffect;
                            // editObj.amendSalaryScaleMatrix = res.data.AmendSalaryScaleMatrix[0];
                            editObj.amendSalaryScaleMatrix = res.data.AmendSalaryScaleMatrix;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/amendSalaryScreen', {
                                pathname: 'amendSalaryScreen',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'secondmentForFederalAgency') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.BorrowEntityID=res.data.BorrowEntityID;
                            editObj.reasonForGovPaySalary=res.data.reasonForGovPaySalary;
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.secondmententityname = res.data.secondmententityname;
                            editObj.employeename = res.data.employeename;
                            editObj.jobname = res.data.jobname;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.retirementcontribution = this.state.popUpRowArray[this.state.selectedIndex].retirementcontribution;
                            editObj.secondmentfrom = res.data.secondmentfrom;
                            editObj.secondmentto = res.data.secondmentto;
                            editObj.salaryownerid = res.data.salaryownerid;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.familynumber = res.data.familynumber;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.employeeagreement = res.data.employeeagreement;
                            editObj.passtestperiod = res.data.passtestperiod;
                            editObj.performancedegree = res.data.performancedegree;
                    
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/secondmentForFederalAgency', {
                                pathname: 'secondmentForFederalAgency',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'informationRequest') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.datettoreply = res.data.datettoreply;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestorname = res.data.requestorname;
                            editObj.informationrequired = res.data.informationrequired;
                            editObj.statement = res.data.statement;
                            editObj.assignedentity = res.data.assignedentity;
                            editObj.lastTimeToReply = res.data.datettoreply;
                            editObj.requesttype = res.data.requesttype;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/informationRequest', {
                                pathname: 'informationRequest',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        }
                        else if (this.state.pathName === 'SCIRequest') {
                   
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.EntityLegalOpinon=res.data.EntityLegalOpinon;
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.requestTypeForRadioButton = res.data.requesttype;
                            editObj.applicantsJob = res.data.applicantjob;
                            editObj.contactnumber = res.data.applicantcontactnumber;
                            editObj.reqDetails = res.data.details;
                            editObj.requesttypeForSuggesationComplainEnquiry = res.data.requesttype;
                            editObj.requestSubject = res.data.subject;
                            editObj.applicantsName = res.data.applicantname;
                            editObj.attachments = res.data.attachments;
                            editObj.requesttype=res.data.requesttype;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/SCIRequest', {
                                pathname: 'SCIRequest',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                    
                        }
                        else if (this.state.pathName === 'StrategicPlanningforworkforceEnquire') {

                            // set flag
                            Config.setIsEditFlag(true);

                            let editObj = {}
                            editObj.EntityLegalOpinon = res.data.EntityLegalOpinon;
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.requestTypeForRadioButton = res.data.requesttype;
                            editObj.applicantsJob = res.data.applicantjob;
                            editObj.contactnumber = res.data.applicantcontactnumber;
                            editObj.reqDetails = res.data.details;
                            editObj.requesttypeForSuggesationComplainEnquiry = res.data.requesttype;
                            editObj.requestSubject = res.data.subject;
                            editObj.applicantsName = res.data.applicantname;
                            editObj.attachments = res.data.attachments;
                            editObj.requesttype = res.data.requesttype;
                            // set obj
                            Config.setEditObj(editObj);

                            this.props.history.push('/StrategicPlanningforworkforceEnquire', {
                                pathname: 'StrategicPlanningforworkforceEnquire',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });


                        }
                        else if (this.state.pathName === 'bonusRequest') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.entityname = res.data.entityname;
                    
                            editObj.jobname = res.data.jobname;
                            editObj.coordinatorname = res.data.coordinatorname;
                            editObj.email = res.data.email;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.phonenumber = res.data.phonenumber;
                    
                            editObj.subjobname = res.data.subjobname;
                            editObj.subcoordinatorname = res.data.subcoordinatorname;
                            editObj.subemail = res.data.subemail;
                            editObj.subcontactnumber = res.data.subcontactnumber;
                            editObj.subphonenumber = res.data.subphonenumber;
                    
                            editObj.isbudgeted = res.data.isbudgeted;
                            editObj.budget = res.data.budget;
                            editObj.budgetyear = res.data.budgetyear;
                            editObj.yearachievment = res.data.yearachievment;
                            editObj.bonusreason = res.data.bonusreason;
                            editObj.year1 = res.data.year1;
                            editObj.emiratizationpercentage = res.data.emiratizationpercentage;
                            editObj.totalemployeedetails = res.data.totalemployeedetails;
                            editObj.year2 = res.data.year2;
                            editObj.iscontracted = res.data.iscontracted;
                            editObj.totalemployees = res.data.totalemployees;
                            editObj.equation = res.data.equation;
                            //editObj.requestId = res.data.gradeemployees;
                            editObj.emiratizationpercentage2 = res.data.emiratizationpercentage2;
                            editObj.nonlocalwithprivate = res.data.nonlocalwithprivate;
                            editObj.localwithprivate = res.data.localwithprivate;
                            editObj.nonlocalwithpermanent = res.data.nonlocalwithpermanent;
                            editObj.localwithpermanent = res.data.localwithpermanent;
                            editObj.excellentemployees = res.data.excellentemployees;
                            editObj.verygoodemployees = res.data.verygoodemployees;
                            editObj.goodemployees = res.data.goodemployees;
                            editObj.acceptableemployees = res.data.acceptableemployees;
                            editObj.weakemployees = res.data.weakemployees;
                            editObj.isfixedvaluebonus = res.data.isfixedvaluebonus;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/bonusRequest', {
                                pathname: 'bonusRequest',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } 
                        
                        else if (this.state.pathName === 'exemptionFromAccompanyPatient') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.grade = res.data.grade;
                            editObj.requesttype = res.data.requesttype;
                            editObj.employeename = res.data.employeename;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.requestheadline = res.data.requestheadline;
                            editObj.budgetavailableyear = res.data.budgetavailableyear;
                            editObj.dateofstarting = res.data.dateofstarting;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.retirementcontribution = res.data.retirementcontribution;
                            editObj.durationofleave = res.data.durationofleave;
                            editObj.dateofwork = res.data.dateofwork;
                            editObj.dateofsalarytermination = res.data.dateofsalarytermination;
                            editObj.isdocumentofobligatory = res.data.isdocumentofobligatory;
                            editObj.budgetyear = res.data.budgetyear;
                            editObj.budgetvalue = res.data.budgetvalue;
                            editObj.annualleavebalance = res.data.annualleavebalance;
                            editObj.justification = res.data.justification;
                            editObj.husbandwifework = res.data.husbandwifework;
                            editObj.numberofchidren = res.data.numberofchidren;
                            editObj.placeofchildren = res.data.placeofchildren;
                            editObj.worktotaldays = res.data.worktotaldays;
                            editObj.worktotalsalarytobepaid = res.data.worktotalsalarytobepaid;
                            editObj.worktobeexempted = res.data.worktobeexempted;
                            editObj.howtocalculaterule = res.data.howtocalculaterule;
                            editObj.isrequriedtopaybybudget = res.data.isrequriedtopaybybudget;
                            editObj.isviolation = res.data.isviolation;
                            editObj.violationdetail = res.data.violationdetail;
                            editObj.salarymatrix = res.data.salarymatrix;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.basicsalary2 = res.data.basicsalary2;
                            editObj.totalsalarywithsubscription = res.data.totalsalarywithsubscription;
                            editObj.retirement = res.data.retirement;
                            editObj.totalsalary2 = res.data.totalsalary2;
                            editObj.Degreeofrelationship=res.data.Degreeofrelationship;
                            editObj.attachments = res.data.attachments;
                            editObj.Degreeofrelationship = res.data.Degreeofrelationship;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/exemptionFromAccompanyPatient', {
                                pathname: 'exemptionFromAccompanyPatient',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } 


                        
                        else if (this.state.pathName ===  'SickLeave') {
                    debugger;
                            // set flag
                           
                    
                            let editObj = {}
                            Config.setIsEditFlag(true);
                    
                           
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.grade = res.data.grade;
                            editObj.requesttype = res.data.requesttype;
                            editObj.employeename = res.data.employeename;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.requestheadline = res.data.requestheadline;
                            editObj.budgetavailableyear = res.data.budgetavailableyear;
                            editObj.dateofstarting = res.data.dateofstarting;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.retirementcontribution = res.data.retirementcontribution;
                            editObj.durationofleave = res.data.durationofleave;
                            editObj.dateofwork = res.data.dateofwork;
                            editObj.dateofsalarytermination = res.data.dateofsalarytermination;
                            editObj.isdocumentofobligatory = res.data.isdocumentofobligatory;
                            editObj.budgetyear = res.data.budgetyear;
                            editObj.budgetvalue = res.data.budgetvalue;
                            editObj.annualleavebalance = res.data.annualleavebalance;
                            editObj.justification = res.data.justification;
                           
                            editObj.worktotaldays = res.data.worktotaldays;
                            editObj.worktotalsalarytobepaid = res.data.worktotalsalarytobepaid;
                            editObj.worktobeexempted = res.data.worktobeexempted;
                            editObj.howtocalculaterule = res.data.howtocalculaterule;
                            editObj.isrequriedtopaybybudget = res.data.isrequriedtopaybybudget;
                            editObj.isviolation = res.data.isviolation;
                            editObj.violationdetail = res.data.violationdetail;
                            editObj.salarymatrix = res.data.salarymatrix;
                            editObj.emiratesid = res.data.emiratesid;
                            editObj.basicsalary2 = res.data.basicsalary2;
                            editObj.totalsalarywithsubscription = res.data.totalsalarywithsubscription;
                            editObj.retirement = res.data.retirement;
                            editObj.totalsalary2 = res.data.totalsalary2;
                            editObj.Degreeofrelationship=res.data.Degreeofrelationship;
                            editObj.attachments = res.data.attachments;
                           
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/SickLeave', {
                                pathname: 'SickLeave',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } 
                        else if (this.state.pathName === 'Promotion') {
                 
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.requesttype = res.data.requesttype;
                            editObj.statusid = res.data.statusid;
                            editObj.grade = res.data.grade;
                            editObj.requesttype = res.data.requesttype;
                            editObj.employeename = res.data.employeename;
                            editObj.employeenumber=res.data.employeenumber;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.requestheadline = res.data.requestheadline;
                            editObj.budgetavailableyear = res.data.budgetavailableyear;
                            editObj.dateofstarting = res.data.dateofstarting;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.basicsalary = res.data.basicsalary;
                            editObj.totalsalary = res.data.totalsalary;
                            editObj.retirementcontribution = res.data.retirementcontribution;
                            editObj.durationofleave = res.data.durationofleave;
                            editObj.dateofwork = res.data.dateofwork;
                            editObj.dateofsalarytermination = res.data.dateofsalarytermination;
                            editObj.isdocumentofobligatory = res.data.isdocumentofobligatory;
                            editObj.budgetyear = res.data.budgetyear;
                            editObj.budgetvalue = res.data.budgetvalue;
                            editObj.annualleavebalance = res.data.annualleavebalance;
                            editObj.justification = res.data.justification;
                            editObj.husbandwifework = res.data.husbandwifework;
                            editObj.numberofchidren = res.data.numberofchidren;
                            editObj.placeofchildren = res.data.placeofchildren;
                            editObj.worktotaldays = res.data.worktotaldays;
                            editObj.worktotalsalarytobepaid = res.data.worktotalsalarytobepaid;
                            editObj.worktobeexempted = res.data.worktobeexempted;
                            editObj.howtocalculaterule = res.data.howtocalculaterule;
                            editObj.isrequriedtopaybybudget = res.data.isrequriedtopaybybudget;
                            
                            editObj.emiratesid = res.data.emiratesid;
                           
                            editObj.attachments = res.data.attachments;
                           
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/Promotion', {
                                pathname: 'Promotion',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } 
                        
                        else if (this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.employename = res.data.employename;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.nationalityid = res.data.nationalityid;
                            editObj.dob = res.data.dob;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.unifiednumber = res.data.unifiednumber;
                            editObj.steerfrom = res.data.steerfrom;
                            editObj.steerto = res.data.steerto;
                            editObj.isjurisdiction = res.data.isjurisdiction;
                            editObj.reasonforextending = res.data.reasonforextending;
                            editObj.annualperformanceevaluation = res.data.annualperformanceevaluation;
                            editObj.emiratesid = res.data.emiratesid;
                    
                            editObj.attachments = res.data.attachments;
                            editObj.HiringDate = res.data.HiringDate;
                            editObj.CurrentGrade = res.data.CurrentGrade;
                            editObj.Efficiency = res.data.Efficiency;
                            editObj.extendedservice = res.data.extendedservice;
                            editObj.Salary = res.data.Salary;
                            editObj.Qualification = res.data.Qualification;
                            editObj.GraduationYear = res.data.GraduationYear;
                            editObj.DateofretirementAge = res.data.DateofretirementAge;
                            editObj.TypeofContract = res.data.TypeofContract;
                            editObj.prviousextensionwithoutapprovalfrom = res.data.prviousextensionwithoutapprovalfrom;
                            editObj.prviousextensionwithoutapprovalto = res.data.prviousextensionwithoutapprovalto;
                            editObj.Previousextionsfrom = res.data.Previousextionsfrom;
                            editObj.Previousextionsto = res.data.Previousextionsto;
                            let temp = 'No';
                            if (editObj.nationalityid === Config.getNationalId()) {
                                temp = 'Yes'
                            }
                            editObj.nationalOrNot = temp;
                            
                            editObj.Replacementplanfrom = res.data.ReplacementPlanFrom;
                            editObj.Replacementplanto = res.data.ReplacementPlanTo;
                            // set obj
                            
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/exceptionOfSteerTheAgeOfRetirementRequest', {
                                pathname: 'exceptionOfSteerTheAgeOfRetirementRequest',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } else if (this.state.pathName === 'extendCreateObjectives') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.fromdate = res.data.fromdate;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/extendCreateObjectives', {
                                pathname: 'extendCreateObjectives',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'extendReviewObjectives') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.fromdate = res.data.fromdate;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/extendReviewObjectives', {
                                pathname: 'extendReviewObjectives',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'extendAnnualPerformance') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.fromdate = res.data.fromdate;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/extendAnnualPerformance', {
                                pathname: 'extendAnnualPerformance',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                    
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/addOrEditEmployeesPerformanceEvaluation', {
                                pathname: 'addOrEditEmployeesPerformanceEvaluation',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.systemlist = res.data.systemlist;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/joinAndImplementGovResourcesSystemForNewEntity', {
                                pathname: 'joinAndImplementGovResourcesSystemForNewEntity',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'openComplainsStage') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.todate = res.data.todate;
                            editObj.fromdate = res.data.fromdate;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/openComplainsStage', {
                                pathname: 'openComplainsStage',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'amendsSharedGovernmentHRManagement') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.systemlist = res.data.systemlist;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/amendsSharedGovernmentHRManagement', {
                                pathname: 'amendsSharedGovernmentHRManagement',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'reportsAndStaticRequest') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/reportsAndStaticRequest', {
                                pathname: 'reportsAndStaticRequest',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'otherRequests') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.todate = res.data.todate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/otherRequests', {
                                pathname: 'otherRequests',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } else if (this.state.pathName === 'entity') {
                    
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.englishName = res.data.englishName;
                            editObj.arabicName = res.data.arabicName;
                            editObj.shortName = res.data.shortName;
                            editObj.employeenumber = res.data.employeenumber;
                            editObj.ismothercompany = res.data.ismothercompany;
                            editObj.phonenumber = res.data.phonenumber;
                            editObj.mothercompanyid = res.data.mothercompanyid;
                            editObj.industryid = res.data.industryid;
                            editObj.cityid = res.data.cityid;
                            editObj.description = res.data.description;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.sectorid = res.data.sectorid;
                            editObj.statusid = res.data.statusid;
                            editObj.attachments = res.data.attachments;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/entity', {
                                pathname: 'entity',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                    
                        } 

                        else if (this.state.pathName === 'OrganizationStructure') {
                            
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.requesttypeid = res.data.requesttypeid;
                            editObj.statusid = res.data.statusid;
                            editObj.statusreasonid = res.data.statusreasonid;
                            editObj.requesttitle = res.data.requesttitle;
                            editObj.email = res.data.email;
                            editObj.requestdate = res.data.requestdate;
                            editObj.employeename = res.data.employeename;
                            editObj.requestdate = res.data.requestdate;
                            editObj.contactnumber = res.data.contactnumber;
                            editObj.requestdetails = res.data.requestdetails;
                            editObj.jobtitle = res.data.jobtitle;
                            editObj.attachments = res.data.attachments;
                            // set obj
                            Config.setEditObj(editObj);
                    

                            this.props.history.push('/OrganizationStructure', {
                                pathname: 'OrganizationStructure',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });

                    
                        }

                        else if (this.state.pathName === 'contact') {
                            // set flag
                            Config.setIsEditFlag(true);
                           
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.englishName = res.data.englishName;
                            editObj.arabicName = res.data.arabicName;
                            editObj.username = res.data.username;
                            editObj.emailAddress = res.data.emailAddress;
                            editObj.mobilenumber = res.data.mobilenumber;
                            editObj.phonenumber = res.data.phonenumber;
                            editObj.designation = res.data.designation;
                            editObj.accountType = res.data.accountTypeID;
                            editObj.roleID = res.data.roleID;
                            editObj.status = res.data.statusid;
                            editObj.entityName = res.data.entityID;
                            editObj.statusreason = res.data.statusreasonid;
                            editObj.internalcontact = res.data.internalcontact;
                            editObj.manager = res.data.manager;
                            editObj.departmentID = res.data.departmentID;
                            editObj.sectorID = res.data.sectorID;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/contact', {
                                pathname: 'contact',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } else if (this.state.pathName === 'mailTemplate') {
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {}
                            editObj.requestId = res.data.id;
                            editObj.title = res.data.title;
                            editObj.content = res.data.content;
                            editObj.notificationtype = res.data.notificationtype;
                    
                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/mailTemplate', {
                                pathname: 'mailTemplate',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        } else if (this.state.pathName === 'role') {
                            // set flag
                            Config.setIsEditFlag(true);
                    
                            let editObj = {};
                            editObj.canCreateWorkShop = res.data.canCreateWorkShop;
editObj.canBookWorkShop = res.data.canBookWorkShop;
editObj.canViewDisciplinaryboard = res.data.canViewDisciplinaryboard;
editObj.canCreateDisciplinaryboard = res.data.canCreateDisciplinaryboard;
editObj.canEditDisciplinaryboard = res.data.canEditDisciplinaryboard;
editObj.canAssignDisciplinaryboardToDGoffice = res.data.canAssignDisciplinaryboardToDGoffice;
editObj.canAssignDisciplinaryboardtoEmployee = res.data.canAssignDisciplinaryboardtoEmployee;
editObj.canAddReplayDisciplinaryboard = res.data.canAddReplayDisciplinaryboard;
editObj.canViewOHI = res.data.canViewOHI;
                            editObj.canviewreport = res.data.canviewreport;
                            editObj.requestId = res.data.id;
                            editObj.titleSelectedValue = res.data.EnglishTitle;
                            editObj.arabicTitle = res.data.ArabicTitle;
                    
          // organization structure 

                            // organization structure 

                            editObj.cansubmitorgrequest = res.data.cansubmitorgrequest
                            editObj.canvieworgrequest = res.data.canvieworgrequest
                            editObj.canvieworgrequesttree = res.data.CanCreateStructure

                            editObj.caneditorgrequest = res.data.caneditorgrequest
                            editObj.canupdateorgstatus = res.data.canupdateorgstatus
                            editObj.canaddorgcomment = res.data.canaddorgcomment
                            editObj.canassignorgdepartment = res.data.canassignorgdepartment
                            editObj.canasignorgemployee = res.data.canasignorgemployee
                            editObj.canprovideorgfeedback = res.data.canprovideorgfeedback
                            editObj.canvieworgfeedback = res.data.canvieworgfeedback
                            editObj.canassignorgsector = res.data.canassignorgsector
                            editObj.canshoworgcomments = res.data.canshoworgcomments
                            editObj.canassignorggm = res.data.canassignorggm
                            editObj.canassignorgbacktoentity = res.data.canassignorgbacktoentity



                            editObj.isadmin = res.data.isadmin;
                            //sci
                            editObj.cansubmitscirequest = res.data.cansubmitscirequest;
                            editObj.canreviewscirequest = res.data.canreviewscirequest;
                            editObj.caneditscirequest = res.data.caneditscirequest;
                            editObj.canupdatescistatus = res.data.canupdatescistatus;
                            editObj.canaddscicomment = res.data.canaddscicomment;
                            editObj.canassignscidepartment = res.data.canassignscidepartment;
                            editObj.canasignsciemployee = res.data.canasignsciemployee;
                            editObj.canprovidescifeedback = res.data.canprovidescifeedback;
                            editObj.canviewscifeedback = res.data.canviewscifeedback;
                            editObj.canassignscisector = res.data.canassignscisector;
                            editObj.canshowscicomments = res.data.canshowscicomments;
                            editObj.canassignscigm = res.data.canassignscigm;
                            editObj.canassignscibacktoentity = res.data.canassignscibacktoentity;

                            //Info request
                            editObj.cansubmitirrequest = res.data.cansubmitirrequest;
                            editObj.caneditirrequest = res.data.caneditirrequest;
                            editObj.canupdateirstatus = res.data.canupdateirstatus;
                            editObj.canaddircomments = res.data.canaddircomments;
                            editObj.canassignirdepartment = res.data.canassignirdepartment;
                            editObj.canprovideirfeedback = res.data.canprovideirfeedback;
                            editObj.canviewirrequest = res.data.canviewirrequest;
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canassignirsector = res.data.canassignirsector;
                            editObj.canreceiveirfeedback = res.data.canreceiveirfeedback;
                            editObj.canassignirgm = res.data.canassignirgm;
                            editObj.canassignirbacktoentity = res.data.canassignirbacktoentity;

                            //Exemption patient
                            editObj.canshowircomments = res.data.canshowircomments;
                            editObj.canviewpatientrequest = res.data.canviewpatientrequest;
                            editObj.caneditpatientrequest = res.data.caneditpatientrequest;
                            editObj.canupdatepatientstatus = res.data.canupdatepatientstatus;
                            editObj.canaddpatientcomments = res.data.canaddpatientcomments;
                            editObj.canassignpatientdepartment = res.data.canassignpatientdepartment;
                            editObj.canassignpatientemployee = res.data.canassignpatientemployee;
                            editObj.canassignpatientsector = res.data.canassignpatientsector;
                            editObj.canprovidepatientstudy = res.data.canprovidepatientstudy;
                            editObj.canreceivepatientstudy = res.data.canreceivepatientstudy;
                            editObj.canshowpatientcomments = res.data.canshowpatientcomments;
                            editObj.cansubmitpatientrequest = res.data.cansubmitpatientrequest;
                            editObj.canassignpatientgm = res.data.canassignpatientgm;
                            editObj.canassignpatientbacktoentity = res.data.canassignpatientbacktoentity;
                    
                            //review hr policy
                            editObj.cansubmitreviewhrrequest = res.data.cansubmitreviewhrrequest;
                            editObj.canviewreviewhrrequest = res.data.canviewreviewhrrequest;
                            editObj.caneditreviewhrrequest = res.data.caneditreviewhrrequest;
                            editObj.canupdatereviewhrstatus = res.data.canupdatereviewhrstatus;
                            editObj.canaddreviewhrcomments = res.data.canaddreviewhrcomments;
                            editObj.canshowreviewhrcomments = res.data.canshowreviewhrcomments;
                            editObj.canassignhrdepartment = res.data.canassignhrdepartment;
                            editObj.canassignhrsector = res.data.canassignhrsector;
                            editObj.canassignhremployee = res.data.canassignhremployee;
                            editObj.canprovidehrstudy = res.data.canprovidehrstudy;
                            editObj.canassignhrgm = res.data.canassignhrgm;
                            editObj.canassignhrbacktoentity = res.data.canassignhrbacktoentity;
                    
                            //AD Housing
                            editObj.cansubmitadhousingrequest = res.data.cansubmitadhousingrequest;
                            editObj.canviewadhousingrequest = res.data.canviewadhousingrequest;
                            editObj.caneditadhousingrequest = res.data.caneditadhousingrequest;
                            editObj.canupdateadhousingstatus = res.data.canupdateadhousingstatus;
                            editObj.canaddadhousingcomments = res.data.canaddadhousingcomments;
                            editObj.canshowadhousingcomments = res.data.canshowadhousingcomments;
                            editObj.canassignhousingdepartment = res.data.canassignhousingdepartment;
                            editObj.canassignhousingemployee = res.data.canassignhousingemployee;
                            editObj.canassignhousingsector = res.data.canassignhousingsector;
                            editObj.canprovidehousingstudy = res.data.canprovidehousingstudy;
                            editObj.canreceivehousingstudy = res.data.canreceivehousingstudy;
                            editObj.canassignhousinggm = res.data.canassignhousinggm;
                            editObj.canassignhousingbacktoentity = res.data.canassignhousingbacktoentity;
                    
                            //Secondment
                            editObj.cansubmitsecondmentrequest = res.data.cansubmitsecondmentrequest;
                            editObj.canviewsecondmentrequest = res.data.canviewsecondmentrequest;
                            editObj.caneditsecondmentrequest = res.data.caneditsecondmentrequest;
                            editObj.canupdatesecondmentstatus = res.data.canupdatesecondmentstatus;
                            editObj.canaddsecondmentcomments = res.data.canaddsecondmentcomments;
                            editObj.canshowsecondmentcomments = res.data.canshowsecondmentcomments;
                            editObj.canassignsecondmentdepartment = res.data.canassignsecondmentdepartment;
                            editObj.canassignsecondmetnemployee = res.data.canassignsecondmetnemployee;
                            editObj.canassignsecondmentsector = res.data.canassignsecondmentsector;
                            editObj.canprovidesecondmentstudy = res.data.canprovidesecondmentstudy;
                            editObj.canreceivesecondmentstudy = res.data.canreceivesecondmentstudy;
                            editObj.canassignsecondmentgm = res.data.canassignsecondmentgm;
                            editObj.canassignsecondmentbacktoentity = res.data.canassignsecondmentbacktoentity;
                    
                            //Bonus request
                            editObj.cansubmitbonusrequest = res.data.cansubmitbonusrequest;
                            editObj.canviewbonusrequest = res.data.canviewbonusrequest;
                            editObj.caneditbonusrequest = res.data.caneditbonusrequest;
                            editObj.canupdatebonusstatus = res.data.canupdatebonusstatus;
                            editObj.canaddbonuscomments = res.data.canaddbonuscomments;
                            editObj.canshowbonuscomments = res.data.canshowbonuscomments;
                            editObj.canassignbounsdepartment = res.data.canassignbounsdepartment;
                            editObj.canassignbounsemployee = res.data.canassignbounsemployee;
                            editObj.canassignbonussector = res.data.canassignbonussector;
                            editObj.canprovidebonusstudy = res.data.canprovidebonusstudy;
                            editObj.canreceivebonusstudy = res.data.canreceivebonusstudy;
                            editObj.canassignbonusgm = res.data.canassignbonusgm;
                            editObj.canassignbonusbacktoentity = res.data.canassignbonusbacktoentity;
                    
                            //Ammend salary scale
                            editObj.cansubmitamendscalerequest = res.data.cansubmitamendscalerequest;
                            editObj.canviewamendscalerequest = res.data.canviewamendscalerequest;
                            editObj.caneditamendscalerequest = res.data.caneditamendscalerequest;
                            editObj.canupdateamendscalestatus = res.data.canupdateamendscalestatus;
                            editObj.canshowamendscalecomments = res.data.canshowamendscalecomments;
                            editObj.canaddamendscalecomments = res.data.canaddamendscalecomments;
                            editObj.canassignamendscaledepartment = res.data.canassignamendscaledepartment;
                            editObj.canassignamendscaleemployee = res.data.canassignamendscaleemployee;
                            editObj.canassignamendscalesector = res.data.canassignamendscalesector;
                            editObj.canprovideamendscalestudy = res.data.canprovideamendscalestudy;
                            editObj.canreceiveamendscalestudy = res.data.canreceiveamendscalestudy;
                            editObj.canassignamendscalegm = res.data.canassignamendscalegm;
                            editObj.canassignamendscalebacktoentity = res.data.canassignamendscalebacktoentity;
                    
                            //Amend emp salary
                            editObj.cansubmitamendsalaryrequest = res.data.cansubmitamendsalaryrequest;
                            editObj.canviewamendsalaryrequest = res.data.canviewamendsalaryrequest;
                            editObj.caneditamendsalaryrequest = res.data.caneditamendsalaryrequest;
                            editObj.canupdateamendsalarystatus = res.data.canupdateamendsalarystatus;
                            editObj.canaddamendsalarycomments = res.data.canaddamendsalarycomments;
                            editObj.canshowamendsalarycomments = res.data.canshowamendsalarycomments;
                            editObj.canassignamendsalarydepartment = res.data.canassignamendsalarydepartment;
                            editObj.canassignamendsalaryemployee = res.data.canassignamendsalaryemployee;
                            editObj.canassignamendsalarysector = res.data.canassignamendsalarysector;
                            editObj.canprovideamendsalarystudy = res.data.canprovideamendsalarystudy;
                            editObj.canreceiveamendsalarystudy = res.data.canreceiveamendsalarystudy;
                            editObj.canassignamendsalarygm = res.data.canassignamendsalarygm;
                            editObj.canassignamendsalarybacktoentity = res.data.canassignamendsalarybacktoentity;
                    
                            //retirement
                            editObj.cansubmitretirementrequest = res.data.cansubmitretirementrequest;
                            editObj.canviewretirementrequest = res.data.canviewretirementrequest;
                            editObj.caneditretirementrequest = res.data.caneditretirementrequest;
                            editObj.canupdateretirementstatus = res.data.canupdateretirementstatus;
                            editObj.canaddretirementcomments = res.data.canaddretirementcomments;
                            editObj.canshowretirementcomments = res.data.canshowretirementcomments;
                            editObj.canassignretirementdepartment = res.data.canassignretirementdepartment;
                            editObj.canassignretirementemployees = res.data.canassignretirementemployees;
                            editObj.canassignretirementsector = res.data.canassignretirementsector;
                            editObj.canprovideretirementstudy = res.data.canprovideretirementstudy;
                            editObj.canreceiveretirementstudy = res.data.canreceiveretirementstudy;
                            editObj.canassignretirementgm = res.data.canassignretirementgm;
                            editObj.canassignretirementbacktoentity = res.data.canassignretirementbacktoentity;
                    
                            // extend create objective
                            editObj.cansubmitextendcreaterequest = res.data.cansubmitextendcreaterequest;
                            editObj.canviewextendcreaterequest = res.data.canviewextendcreaterequest;
                            editObj.caneditextendcreaterequest = res.data.caneditextendcreaterequest;
                            editObj.canupdateextendcreatestatus = res.data.canupdateextendcreatestatus;
                            editObj.canshowextendcreatecomments = res.data.canshowextendcreatecomments;
                            editObj.canaddextendcreatecomments = res.data.canaddextendcreatecomments;
                            editObj.canprovideextendcreatestudy = res.data.canprovideextendcreatestudy;
                            editObj.canreceiveextendcreatestudy = res.data.canreceiveextendcreatestudy;
                            editObj.canassignextendcreatesector = res.data.canassignextendcreatesector;
                            editObj.canassignextendcreatedepartment = res.data.canassignextendcreatedepartment;
                            editObj.canassignextendcreateemployee = res.data.canassignextendcreateemployee;
                            editObj.canassignextendcreatebacktodepartment = res.data.canassignextendcreatebacktodepartment;
                            editObj.canassignextendcreatebacktoentity = res.data.canassignextendcreatebacktoentity;
                            editObj.canassignextendcreaterequesttogm = res.data.canassignextendcreaterequesttogm;
                    
                            // exten review objective
                            editObj.cansubmitextendreviewrequest = res.data.cansubmitextendreviewrequest;
                            editObj.canviewextendreviewrequest = res.data.canviewextendreviewrequest;
                            editObj.caneditextendreviewrequest = res.data.caneditextendreviewrequest;
                            editObj.canupdateextendreviewstatus = res.data.canupdateextendreviewstatus;
                            editObj.canshowextendreviewcomments = res.data.canshowextendreviewcomments;
                            editObj.canaddextendreviewcomments = res.data.canaddextendreviewcomments;
                            editObj.canprovideextendreviewstudy = res.data.canprovideextendreviewstudy;
                            editObj.canreceiveextendreviewstudy = res.data.canreceiveextendreviewstudy;
                            editObj.canassignextendreviewsector = res.data.canassignextendreviewsector;
                            editObj.canassignextendreviewdepartment = res.data.canassignextendreviewdepartment;
                            editObj.canassignextendreviewemployee = res.data.canassignextendreviewemployee;
                            editObj.canassignextendreviewbacktodepartment = res.data.canassignextendreviewbacktodepartment;
                            editObj.canassignextendreviewbacktoentity = res.data.canassignextendreviewbacktoentity;
                            editObj.canassignextendreviewrequesttogm = res.data.canassignextendreviewrequesttogm;
                    
                            // extend annual
                            editObj.cansubmitextendannualrequest = res.data.cansubmitextendannualrequest;
                            editObj.canviewextendannualrequest = res.data.canviewextendannualrequest;
                            editObj.caneditextendannualrequest = res.data.caneditextendannualrequest;
                            editObj.canupdateextendannualstatus = res.data.canupdateextendannualstatus;
                            editObj.canshowextendannualcomments = res.data.canshowextendannualcomments;
                            editObj.canaddextendannualcomments = res.data.canaddextendannualcomments;
                            editObj.canprovideextendannualstudy = res.data.canprovideextendannualstudy;
                            editObj.canreceiveextendannualstudy = res.data.canreceiveextendannualstudy;
                            editObj.canassignextendannualsector = res.data.canassignextendannualsector;
                            editObj.canassignextendannualdepartment = res.data.canassignextendannualdepartment;
                            editObj.canassignextendannualemployee = res.data.canassignextendannualemployee;
                            editObj.canassignextendannualbacktodepartment = res.data.canassignextendannualbacktodepartment;
                            editObj.canassignextendannualbacktoentity = res.data.canassignextendannualbacktoentity;
                            editObj.canassignextendannualrequesttogm = res.data.canassignextendannualrequesttogm;
                    
                            // add and edit employee performance
                            editObj.cansubmitemployeeperformancerequest = res.data.cansubmitemployeeperformancerequest;
                            editObj.canviewemployeeperformancerequest = res.data.canviewemployeeperformancerequest;
                            editObj.caneditemployeeperformancerequest = res.data.caneditemployeeperformancerequest;
                            editObj.canupdateemployeeperformancestatus = res.data.canupdateemployeeperformancestatus;
                            editObj.canshowemployeeperformancecomments = res.data.canshowemployeeperformancecomments;
                            editObj.canaddemployeeperformancecomments = res.data.canaddemployeeperformancecomments;
                            editObj.canprovideemployeeperformancestudy = res.data.canprovideemployeeperformancestudy;
                            editObj.canreceiveemployeeperformancestudy = res.data.canreceiveemployeeperformancestudy;
                            editObj.canassignemployeeperformancesector = res.data.canassignemployeeperformancesector;
                            editObj.canassignemployeeperformancedepartment = res.data.canassignemployeeperformancedepartment;
                            editObj.canassignemployeeperformanceemployee = res.data.canassignemployeeperformanceemployee;
                            editObj.canassignemployeeperformancebacktodepartment = res.data.canassignemployeeperformancebacktodepartment;
                            editObj.canassignemployeeperformancebacktoentity = res.data.canassignemployeeperformancebacktoentity;
                            editObj.canassignemployeeperformancerequesttogm = res.data.canassignemployeeperformancerequesttogm;
                    
                            // join and implement
                            editObj.cansubmitjoinimplementrequest = res.data.cansubmitjoinimplementrequest;
                            editObj.canviewjoinimplementrequest = res.data.canviewjoinimplementrequest;
                            editObj.caneditjoinimplementrequest = res.data.caneditjoinimplementrequest;
                            editObj.canupdatejoinimplementstatus = res.data.canupdatejoinimplementstatus;
                            editObj.canshowjoinimplementcomments = res.data.canshowjoinimplementcomments;
                            editObj.canaddjoinimplementcomments = res.data.canaddjoinimplementcomments;
                            editObj.canprovidejoinimplementstudy = res.data.canprovidejoinimplementstudy;
                            editObj.canreceivejoinimplementstudy = res.data.canreceivejoinimplementstudy;
                            editObj.canassignjoinimplementsector = res.data.canassignjoinimplementsector;
                            editObj.canassignjoinimplementdepartment = res.data.canassignjoinimplementdepartment;
                            editObj.canassignjoinimplementemployee = res.data.canassignjoinimplementemployee;
                            editObj.canassignjoinimplementbacktodepartment = res.data.canassignjoinimplementbacktodepartment;
                            editObj.canassignjoinimplementbacktoentity = res.data.canassignjoinimplementbacktoentity;
                            editObj.canassignjoinimplementrequesttogm = res.data.canassignjoinimplementrequesttogm;
                    
                            // open 
                            editObj.cansubmitopencomplainsrequest = res.data.cansubmitopencomplainsrequest;
                            editObj.canviewopencomplainsrequest = res.data.canviewopencomplainsrequest;
                            editObj.caneditopencomplainsrequest = res.data.caneditopencomplainsrequest;
                            editObj.canupdateopencomplainsstatus = res.data.canupdateopencomplainsstatus;
                            editObj.canshowopencomplainscomments = res.data.canshowopencomplainscomments;
                            editObj.canaddopencomplainscomments = res.data.canaddopencomplainscomments;
                            editObj.canprovideopencomplainsstudy = res.data.canprovideopencomplainsstudy;
                            editObj.canreceiveopencomplainsstudy = res.data.canreceiveopencomplainsstudy;
                            editObj.canassignopencomplainssector = res.data.canassignopencomplainssector;
                            editObj.canassignopencomplainsdepartment = res.data.canassignopencomplainsdepartment;
                            editObj.canassignopencomplainsemployee = res.data.canassignopencomplainsemployee;
                            editObj.canassignopencomplainsbacktodepartment = res.data.canassignopencomplainsbacktodepartment;
                            editObj.canassignopencomplainsbacktoentity = res.data.canassignopencomplainsbacktoentity;
                            editObj.canassignopencomplainsrequesttogm = res.data.canassignopencomplainsrequesttogm;
                    
                            // amend systemcansubmitamendsystemsrequest
                            editObj.cansubmitamendsystemsrequest = res.data.cansubmitamendsystemsrequest;
                            editObj.canviewamendsystemsrequest = res.data.canviewamendsystemsrequest;
                            editObj.caneditamendsystemsrequest = res.data.caneditamendsystemsrequest;
                            editObj.canupdateamendsystemsstatus = res.data.canupdateamendsystemsstatus;
                            editObj.canshowamendsystemscomments = res.data.canshowamendsystemscomments;
                            editObj.canaddamendsystemscomments = res.data.canaddamendsystemscomments;
                            editObj.canprovideamendsystemsstudy = res.data.canprovideamendsystemsstudy;
                            editObj.canreceiveamendsystemsstudy = res.data.canreceiveamendsystemsstudy;
                            editObj.canassignamendsystemssector = res.data.canassignamendsystemssector;
                            editObj.canassignamendsystemsdepartment = res.data.canassignamendsystemsdepartment;
                            editObj.canassignamendsystemsemployee = res.data.canassignamendsystemsemployee;
                            editObj.canassignamendsystemsbacktodepartment = res.data.canassignamendsystemsbacktodepartment;
                            editObj.canassignamendsystemsbacktoentity = res.data.canassignamendsystemsbacktoentity;
                            editObj.canassignamendsystemsrequesttogm = res.data.canassignamendsystemsrequesttogm;
                    
                            // report and static
                            editObj.cansubmitreportsstaticsrequest = res.data.cansubmitreportsstaticsrequest;
                            editObj.canviewreportsstaticsrequest = res.data.canviewreportsstaticsrequest;
                            editObj.caneditreportsstaticsrequest = res.data.caneditreportsstaticsrequest;
                            editObj.canupdatereportsstaticsstatus = res.data.canupdatereportsstaticsstatus;
                            editObj.canshowreportsstaticscomments = res.data.canshowreportsstaticscomments;
                            editObj.canaddreportsstaticscomments = res.data.canaddreportsstaticscomments;
                            editObj.canprovidereportsstaticsstudy = res.data.canprovidereportsstaticsstudy;
                            editObj.canreceivereportsstaticsstudy = res.data.canreceivereportsstaticsstudy;
                            editObj.canassignreportsstaticssector = res.data.canassignreportsstaticssector;
                            editObj.canassignreportsstaticsdepartment = res.data.canassignreportsstaticsdepartment;
                            editObj.canassignreportsstaticsemployee = res.data.canassignreportsstaticsemployee;
                            editObj.canassignreportsstaticsbacktodepartment = res.data.canassignreportsstaticsbacktodepartment;
                            editObj.canassignreportsstaticsbacktoentity = res.data.canassignreportsstaticsbacktoentity;
                            editObj.canassignreportsstaticsrequesttogm = res.data.canassignreportsstaticsrequesttogm;
                    
                            // other request 
                            editObj.cansubmitotherrequestsrequest = res.data.cansubmitotherrequestsrequest;
                            editObj.canviewotherrequestsrequest = res.data.canviewotherrequestsrequest;
                            editObj.caneditotherrequestsrequest = res.data.caneditotherrequestsrequest;
                            editObj.canupdateotherrequestsstatus = res.data.canupdateotherrequestsstatus;
                            editObj.canshowotherrequestscomments = res.data.canshowotherrequestscomments;
                            editObj.canaddotherrequestscomments = res.data.canaddotherrequestscomments;
                            editObj.canprovideotherrequestsstudy = res.data.canprovideotherrequestsstudy;
                            editObj.canreceiveotherrequestsstudy = res.data.canreceiveotherrequestsstudy;
                            editObj.canassignotherrequestssector = res.data.canassignotherrequestssector;
                            editObj.canassignotherrequestsdepartment = res.data.canassignotherrequestsdepartment;
                            editObj.canassignotherrequestsemployee = res.data.canassignotherrequestsemployee;
                            editObj.canassignotherrequestsbacktodepartment = res.data.canassignotherrequestsbacktodepartment;
                            editObj.canassignotherrequestsbacktoentity = res.data.canassignotherrequestsbacktoentity;
                            editObj.canassignotherrequesttogmsrequesttogm = res.data.canassignotherrequesttogmsrequesttogm;
                            editObj.canCloseRequest = res.data.canCloseRequest;
                            editObj.canViewEmployeeReport = res.data.canViewEmployeeReport;
                            editObj.canGenerateAllreports = res.data.canGenerateAllreports;


                            // set obj
                            Config.setEditObj(editObj);
                    
                            this.props.history.push('/role', {
                                pathname: 'role',
                                data: this.state.popUpRowArray[this.state.selectedIndex]
                            });
                        }
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message_one
                        }, () => {
                            this.alert();
                        })
                        // break;                         
                    }
                })
            })
        })
    }

    callRequestByIdForViewDeatils = (requestId, endPoint) => {

        //let id = requestId;

        let payload = getRequestByIdPayload(requestId);
        let url = Config.getUrl(endPoint);


        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                       
                        let tempData = res.data;
                        let requesttypeid = tempData.requesttypeid;
                        let view = '/viewContainer';
                        if (requesttypeid == Config.getDisciplinaryboardTypeId()) {
                            view = '/ViewDetailsDisciplinaryboard';
                        }

                        let ActionData = this.state.popUpRowArray[this.state.selectedIndex];
                        

                        this.props.history.replace(view, {
                            requesttypeid: requesttypeid,
                           id:tempData.id,
                            pathname: this.props.location.state,
                            data: tempData,
                            
                            ActionData: ActionData
                        })

                    } else {
                       
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message_one
                        }, () => {
                            this.alert();
                        })
                    }
                })
            })
        })
    }

    calllookupRequestById = (requestId, endPoint) => {
        //let id = requestId;
        let filterType = localStorage.getItem("lookupDropdownValue");
        let obj = {};
        obj.id = requestId;
        obj.filtertype = filterType;

        let payload = getLookupRequestByIdPayload(obj);
        let url = Config.getUrl(endPoint);

        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        // set flag
                        Config.setIsEditFlag(true);

                        let editObj = {}
                        editObj.requestId = res.data.id;
                        editObj.englishtitle = res.data.englishtitle;
                        editObj.arabictitle = res.data.arabictitle;
                        editObj.status = res.data.status;
                        editObj.parentid = res.data.parentid;
                        editObj.parent = res.data.parent;
                        editObj.isdefault = res.data.isdefault;

                        // set obj
                        Config.setEditObj(editObj);

                        this.props.history.push('/lookUp', {
                            pathname: 'lookUp',
                            data: this.state.popUpRowArray[this.state.selectedIndex]
                        });

                    } else {

                    }
                })
            })
        })
    }
    Notification=()=>
    {
        // alert('1');
        let endPoint="SendNotification";
    
        let requestId=
        this.state.popUpRowArray[this.state.selectedIndex].id;
        let RequestType=this.state.popUpRowArray[this.state.selectedIndex].requesttypeid;
        let payload = getUnderApprovalPayLoadById(requestId,RequestType);
        let url = Config.getUrl(endPoint);
    if(this.state.CurretntUnderApprovalID!=requestId){
        ApiRequest.sendRequestForPost(url, payload, res => {
           
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false,isreolad:true,CurretntUnderApprovalID:requestId
                }, () => {
                   
                    if (res.Status) {
                    }
                }
                )}
        );
            });
        }     
    }
UnderApproval=()=>
{
    let endPoint="UnderApproval";
    
    let requestId=
    this.state.popUpRowArray[this.state.selectedIndex].id;
    let RequestType=this.state.popUpRowArray[this.state.selectedIndex].requesttypeid;
    let payload = getUnderApprovalPayLoadById(requestId,RequestType);
    let url = Config.getUrl(endPoint);
if(this.state.CurretntUnderApprovalID!=requestId){
    ApiRequest.sendRequestForPost(url, payload, res => {
       
        this.setState({
            isLoading: true
        }, () => {
            this.setState({
                isLoading: false,isreolad:true,CurretntUnderApprovalID:requestId
            }, () => {
               
                if (res.Status) {
                }
            }
            )}
    );
        });
    }
}

    selectModalView = () => {


        switch (this.state.selectedAction) {
            case 'Notification':
                {
               
                    this.Notification();
                    return(
                        <MDBContainer
                        style={{ justifyContent: this.state.isArabic ? "row-reverse" : "row" }}
                    >
         <MDBCol
                                            xs="12"
                                            sm="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            style={{ height: "20vh" }}
                                        >
                                            <div className="form-group">
                                                <label
                                                    htmlFor="Entityformalrequest"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: this.state.isArabic
                                                            ? "row-reverse"
                                                            : "row"
                                                    }}
                                                >
                  {this.state.isArabic? 'تم إرسال التذكير':'Notification Send'}
        </label>
        {/* <MDBBtn
                                onClick={this.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}>
                                {" "}
                                {i18n[this.props.isArabic ? "ar" : "en"].cancle}{" "}
                            </MDBBtn> */}
        </div> </MDBCol>
        </MDBContainer>
                
                    );
                   break;
                }
            case 'Underapproval':
                {
               
                    this.UnderApproval();
                    return(
                        <MDBContainer
                        style={{ justifyContent: this.state.isArabic ? "row-reverse" : "row" }}
                    >
         <MDBCol
                                            xs="12"
                                            sm="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            style={{ height: "20vh" }}
                                        >
                                            <div className="form-group">
                                                <label
                                                    htmlFor="Entityformalrequest"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: this.state.isArabic
                                                            ? "row-reverse"
                                                            : "row"
                                                    }}
                                                >
                  {this.state.isArabic? 'تم تحويل الطلب إلى حالة قيد الاعتماد':'Request Status changed to Under Approval.'}
        </label>
        </div> </MDBCol>
        </MDBContainer>
                
                    );
                   break;
                }
            case 'EditComment':
                {
                    if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                        return (
                            <AddStudy
                                isArabic={this.state.isArabic}
                                update={this.updateComments}
                                comments={this.state.comments[this.state.selectedIndex]}
                                requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                                id={this.state.popUpRowArray[this.state.selectedIndex].id}
                                toggle={this.toggle}
                                location={this.props.location.state} iseditcomment={this.state.popUpRowArray[this.state.selectedIndex].CanEditComment} />)
                    }
                }
            case 'view':
                return (
                    <ViewRecordComponent
                        isArabic={this.state.isArabic}
                        Attachments={this.state.Attachments}
                        comment={this.state.comments}
                        toggle={this.toggle}
                        statusHistory={this.state.statusHistory}
                        data={this.state.popUpRowArray[this.state.selectedIndex]}
                        location={this.props.location.state} />
                )
            case 'viewdetails':
                
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                   
                    let requesttypeid = this.state.popUpRowArray[this.state.selectedIndex].requesttypeid;
                    
                    if (requesttypeid === 10969) {
                       let sciRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                       this.callRequestByIdForViewDeatils(sciRequestId, 'GetStrategicPlanningforworkforceEnquirebyID');
                   }

                   else if (requesttypeid === Config.getSciRequestTypeId() || requesttypeid === Config.getSCILEgalSerbviceId()
                     || requesttypeid === Config.getSCIComplainId() || requesttypeid === Config.getSCILEgalSuggestionId() ||
                      requesttypeid === Config.getSCIGeneralId()) {
                        let sciRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(sciRequestId, 'GetscirequestbyID');
                    } else if (requesttypeid === Config.getInformationRequestTypeId()) {
                        let informationRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(informationRequestId, 'GetinformationrequestbyID');
                    } else if (requesttypeid === Config.getBonusRequestTypeId()) {
                        let bonusRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(bonusRequestId, 'GetbounsrequestbyID');
                    } else if (requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                        let amendSalaryScaleRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(amendSalaryScaleRequestId, 'GetAmmendSalaryScalebyID');
                    } else if (requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                        let amendEmployeeSalaryRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(amendEmployeeSalaryRequestId, 'GetAmmendEmployeeSalarybyID');
                    } else if (requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                        let reviewhrPolicyRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(reviewhrPolicyRequestId, 'GetHRPolicyReviewbyID');
                    } else if (requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                        let adHousingRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(adHousingRequestId, 'GetHousingPolicybyID');
                    } else if (requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                        let secondmentFederalRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(secondmentFederalRequestId, 'GetFederalSecondmentbyID');
                    } else if (requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                        let patientRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(patientRequestId, 'GetPoliciesPatientrequestbyID');
                    } else if (requesttypeid === Config.getRetirmentRequestTypeId()) {
                        let retirementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(retirementRequestId, 'GetRetirementbyID');

                    } 
                    else if (requesttypeid === Config.getSickLeaveTypeId()) {
                        let retirementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(retirementRequestId, 'GetSickLeaveById');
                    } 
                    else if (requesttypeid === Config.getPromotionTypeId()) {
                        let retirementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(retirementRequestId, 'GetPromotionViewDetailsById');
                    } 

                    else if (requesttypeid === Config.getDisciplinaryboardTypeId()) {
                        let retirementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(retirementRequestId, 'GetDisciplinaryboardById');
                    } 

                    else if (requesttypeid === Config.getExtendCreateObjectiveId()) {
                        let extendCreateObjectiveRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(extendCreateObjectiveRequestId, 'GetExtendCreateObjectivesbyID');
                    } else if (requesttypeid === Config.getExtendReviewObjectiveId()) {
                        let extendReviewObjectiveRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(extendReviewObjectiveRequestId, 'GetExtendReviewObjectivesbyID');
                    } else if (requesttypeid === Config.getExtendAnnualPerformaceEvaluationId()) {
                        let extendAnnualPerformanceRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(extendAnnualPerformanceRequestId, 'GetExtendAnnualPerformancebyID');
                    } else if (requesttypeid === Config.getAddAndEditEmployeePerformanceEvaluationId()) {
                        let addOrEditEmployeePerformanceRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(addOrEditEmployeePerformanceRequestId, 'GetEmpPerformancebyID');
                    } else if (requesttypeid === Config.getJoinAndImplementId()) {
                        let joinAndImplementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(joinAndImplementRequestId, 'GetJoinAndImplementSystemsbyID');
                    } else if (requesttypeid === Config.getOpenComplainsStageId()) {
                        let openComplainsStageRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(openComplainsStageRequestId, 'GetOpenGrievanceProcessbyID');
                    } else if (requesttypeid === Config.getAmendOnSharedGovermentId()) {
                        let amendsSharedGovermentHrRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(amendsSharedGovermentHrRequestId, 'GetAmendSystemsbyID');
                    } else if (requesttypeid === Config.getReportAndStaticId()) {
                        let reportAndStaticRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(reportAndStaticRequestId, 'GetReportsRequestsbyID');
                    } else if (requesttypeid === Config.getOtherRequest()) {
                        let otherRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(otherRequestId, 'GetOtherRequestsbyID');
                    }
                    else if (requesttypeid === Config.getORGRequestTypeId()) {
                        let orgRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestByIdForViewDeatils(orgRequestId, 'GetOrganizationStructurebyID');
                    }
                }
                break;
            case 'edit':
               
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    let requesttypeid = this.state.popUpRowArray[this.state.selectedIndex].requesttypeid;
                    if (this.state.pathName === 'StrategicPlanningforworkforceEnquire') {
                        let sciRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(sciRequestId, 'GetStrategicPlanningforworkforceEnquirebyID');
                    }
                 else   if (this.state.pathName === 'SCIRequest') {
                        let sciRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(sciRequestId, 'GetscirequestbyID');
                    }
                    else if (this.state.pathName === 'Disciplinaryboard') {
                        let            DisciplinaryboardById  = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(DisciplinaryboardById, 'GetDisciplinaryboardById');
                    }
                    else if (this.state.pathName === 'informationRequest') {
                        let informationRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(informationRequestId, 'GetinformationrequestbyID');
                    } else if (this.state.pathName === 'amendEmployeeSalary') {
                        let amendEmployeeSalaryRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(amendEmployeeSalaryRequestId, 'GetAmmendEmployeeSalarybyID');
                    } else if (this.state.pathName === 'amendSalaryScreen') {
                        let amendSalaryScaleRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(amendSalaryScaleRequestId, 'GetAmmendSalaryScalebyID');
                    } else if (this.state.pathName === 'reviewHRPolicies') {
                        let reviewhrPolicyRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(reviewhrPolicyRequestId, 'GetHRPolicyReviewbyID');
                    } else if (this.state.pathName === 'exceptionADHousing') {
                        let adHousingRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(adHousingRequestId, 'GetHousingPolicybyID');
                    } else if (this.state.pathName === 'secondmentForFederalAgency') {
                        let secondmentFederalRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(secondmentFederalRequestId, 'GetFederalSecondmentbyID');
                    } else if (this.state.pathName === 'bonusRequest') {
                        let bonusRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(bonusRequestId, 'GetbounsrequestbyID');
                    } else if (this.state.pathName === 'exemptionFromAccompanyPatient') {
                        let patientRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(patientRequestId, 'GetPoliciesPatientrequestbyID');
                    } 
                    else if (this.state.pathName === 'SickLeave') {
                        let patientRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(patientRequestId, 'GetSickLeaveById');
                    }
                   
                    else if (this.state.pathName === 'Promotion') {
                        let patientRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(patientRequestId, 'GetPromotionById');
                    }
                    else if (this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                        let retirementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(retirementRequestId, 'GetRetirementRequestEdit');
                    } else if (this.state.pathName === 'extendCreateObjectives') {
                        let extendCreateObjectiveRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(extendCreateObjectiveRequestId, 'GetExtendCreateObjectivesbyID');
                    } else if (this.state.pathName === 'extendReviewObjectives') {
                        let extendReviewObjectiveRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(extendReviewObjectiveRequestId, 'GetExtendReviewObjectivesbyID');
                    } else if (this.state.pathName === 'extendAnnualPerformance') {
                        let extendAnnualPerformanceRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(extendAnnualPerformanceRequestId, 'GetExtendAnnualPerformancebyID');
                    } 
                    
                    else if (this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                        let addOrEditEmployeePerformanceRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(addOrEditEmployeePerformanceRequestId, 'GetEmpPerformancebyID');
                    } else if (this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                        let joinAndImplementRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(joinAndImplementRequestId, 'GetJoinAndImplementSystemsbyID');
                    } else if (this.state.pathName === 'openComplainsStage') {
                        let openComplainsStageRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(openComplainsStageRequestId, 'GetOpenGrievanceProcessbyID');
                    } else if (this.state.pathName === 'amendsSharedGovernmentHRManagement') {
                        let amendsSharedGovermentHrRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(amendsSharedGovermentHrRequestId, 'GetAmendSystemsbyID');
                    } else if (this.state.pathName === 'reportsAndStaticRequest') {
                        let reportAndStaticRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(reportAndStaticRequestId, 'GetReportsRequestsbyID');
                    } else if (this.state.pathName === 'otherRequests') {
                        let otherRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(otherRequestId, 'GetOtherRequestsbyID');
                    } else if (this.state.pathName === 'entity') {
                        let entityId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(entityId, 'GetEntitiesbyID');
                    } else if (this.state.pathName === 'contact') {
                        let contactId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(contactId, 'GetContactbyID');
                    } else if (this.state.pathName === 'lookUp') {
                        let lookupId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.calllookupRequestById(lookupId, 'GetLookupbyID');
                    } else if (this.state.pathName === 'mailTemplate') {
                        let mailTemplateId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(mailTemplateId, 'GetMailTemplatebyID');
                    } else if (this.state.pathName === 'role') {
                        let roleId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(roleId, 'GetRolebyID');
                    }
                    // organization structure
                        

                    else if (this.state.pathName === 'OrganizationStructure' ) {
                       
                        let orgRequestId = this.state.popUpRowArray[this.state.selectedIndex].id;
                        this.callRequestById(orgRequestId, 'GetOrganizationStructurebyID');
                       
                    }
                }
                break;
            case 'delete':
                return <DeleteRecordComponent data={this.state.data.rows[this.state.selectedIndex]} />
            case 'Reopen': 
            
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <CommentComponent
                            isReopen={true}
                            isArabic={this.state.isArabic}
                            update={this.updateComments}
                            comments={this.state.comments[this.state.selectedIndex]}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            toggle={this.toggle}
                            location={this.props.location.state} />)
                }
            case 'comment':
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <CommentComponent
                            isReopen={false}
                            isArabic={this.state.isArabic}
                            update={this.updateComments}
                            comments={this.state.comments[this.state.selectedIndex]}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            toggle={this.toggle}
                            location={this.props.location.state} />)
                }
                break;
            case "update_status":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <StatusUpdateComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            cureentStatus={this.state.popUpRowArray[this.state.selectedIndex].status}
                            currentStatusReason={this.state.popUpRowArray[this.state.selectedIndex].statusreason}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            toggle={this.toggle}
                            location={this.props.location.state}
                            onUpdateData={this.statusUpdateInfo}
                        />
                    );
                }
                break;
            case 'assigntosector':
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToSector
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case 'assignbacktoentity':
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignBackToEntity
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case 'assigntoentity':
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignBackToEntity
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case "assigntostrategicoffice":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToGm
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case 'assigntogmoffice':
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToGm
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle} />
                    );
                }
                break;
            case "assigntodep":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                   
                    return (
                        <AssignToComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            assigneddepartmentid={this.state.popUpRowArray[this.state.selectedIndex].assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntodep'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                   
                }
                break;
            case "backtomanager":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            assigneddepartmentid={this.state.popUpRowArray[this.state.selectedIndex].assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='backtomanager'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                }
                break;
            case "assigntospecificdep":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            assigneddepartmentid={this.state.popUpRowArray[this.state.selectedIndex].assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntospecificdep'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                }
                break;
            case "assigntodatadep":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            assigneddepartmentid={this.state.popUpRowArray[this.state.selectedIndex].assigneddepartmentid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntodatadep'
                        //departmentArray={this.state.departmentArray}
                        />
                    );
                }
                break;
            case "assigntospecificemp":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            assignedemployeeid={this.state.popUpRowArray[this.state.selectedIndex].assignedemployeeid}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntospecificemp'
                        //employeeArray={this.state.employeeArray}
                        />
                    );
                }
                break;
            case "assigntoemp":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AssignToComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            assignedemployeeid={this.state.popUpRowArray[this.state.selectedIndex].assignedemployeeid}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='assigntoemp'
                        //employeeArray={this.state.employeeArray}
                        />
                    );
                }
                break;
            // organization structure
            case "opencreationstage":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <OpenCreationStageComponent
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            assignedemployeeid={this.state.popUpRowArray[this.state.selectedIndex].assignedemployeeid}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='opencreationstage'

                        />
                    );
                }
                break;
            case "organizationstructurecompleted":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <OrganizationStructureCompleted
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            assignedemployeeid={this.state.popUpRowArray[this.state.selectedIndex].assignedemployeeid}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            data={this.state.popUpRowArray[this.state.selectedIndex]}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='organizationstructuresompleted'

                        />
                    );
                }
                break;
            case "addStudy":
              
                
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AddStudy
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='addStudy'
                        />
                    );
                }
                break;
            case "addReply":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AddStudy
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='addReply'
                        />
                    );
                }
                break;
            case "CloseRequest":
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    return (
                        <AddStudy
                            id={this.state.popUpRowArray[this.state.selectedIndex].id}
                            currentStatusId={this.state.popUpRowArray[this.state.selectedIndex].statusid}
                            currentStatusReasonId={this.state.popUpRowArray[this.state.selectedIndex].statusreasonid}
                            currentRequestNumber={this.state.popUpRowArray[this.state.selectedIndex].Requestnumber}
                            requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                            onUpdateData={this.statusUpdateInfo}
                            type={this.state.selectedAction}
                            isArabic={this.state.isArabic}
                            location={this.props.location.state}
                            toggle={this.toggle}
                            title='CloseRequest'
                        />
                    );
                }
                break;

            case "follow":
                return (
                    <FollowComponent
                        // followData={this.state.popUpRowArray[this.state.selectedIndex].history}
                        id={this.state.popUpRowArray[this.state.selectedIndex].id}
                        requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                        isArabic={this.state.isArabic}
                        location={this.props.location.state}
                        toggle={this.toggle}
                    />
                );
            case 'ExtendDate':
                return <ExtendDateComponent data={this.state.data.rows[this.state.selectedIndex]}
                    isArabic={this.state.isArabic}
                    id={this.state.popUpRowArray[this.state.selectedIndex].id}
                    requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                    location={this.props.location.state}
                    toggle={this.toggle}
                />
            case 'ExtendDateReplay':
                return <ExtendDateReplayComponent data={this.state.data.rows[this.state.selectedIndex]}
                    isArabic={this.state.isArabic}
                    id={this.state.popUpRowArray[this.state.selectedIndex].id}
                    requesttypeid={this.state.popUpRowArray[this.state.selectedIndex].requesttypeid}
                    location={this.props.location.state}
                    toggle={this.toggle}
                />
            default:
                return '';
        }
    }

    editRecord = (i) => {
        if (this.state.pathName === 'entity' || this.state.pathName === 'contact') {

        } else {
            
            this.setState({
                no: this.state.data.rows[i].no,
                createdby: this.state.data.rows[i].createdby,
                requestNo: this.state.data.rows[i].requestNo,
                reason: this.state.data.rows[i].reason,
                status: this.state.data.rows[i].status,
                createdon: this.state.data.rows[i].createdon,
                actions: this.state.data.rows[i].actions,
            });
        }
    }

    viewRecord = (i) => {
        this.setState({
            viewObject: this.state.data.rows[i]
        }, () => { this.toggle() })
    }

    updateStatus = (i) => {
        this.setState({ selectedRecord: i })
    }

    comment = (i) => {
        this.setState({ selectedRecord: i })
    }

    deleteRecord = (i) => {
    }

    toggleTheme = () => {
        const theme = this.state.theme === 'light' ? 'dark' : 'light';
        this.setState({ theme: theme });
        document.documentElement.classList.add('theme-transition');
        document.documentElement.setAttribute('data-theme', theme);
        setTimeout(() => {
            document.documentElement.classList.remove('theme-transition');
        }, 1000);
    }

    toggle = (reloadFlag) => {
        if(this.state.isreolad)
        {
            reloadFlag=true;
        }
        this.setState({
            modal: !this.state.modal
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
               
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState
                }, () => {
                    this.child.current.componentDidMount();
                });
            }
        });
    }

    toggleLanguage = (lan) => {
        isArabicVariable = lan;
        this.setState({ isArabic: lan === 'ar' ? true : false }, () => {
            // window.location.reload();
            // this.props.history.replace(this.props.location.pathname);
            this.setState({
                changeState: !this.state.changeState
            }, () => {
                this.child.current.componentDidMount();
            })
        });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    goToLandingPage = () => {
        this.props.history.replace('/landing');
    }

    saveValue = () => {
        let tableRecord = {}
        tableRecord.mo = this.state.no
        tableRecord.createdby = this.state.createdby
        tableRecord.requestNo = this.state.requestNo
        tableRecord.reason = this.state.reason
        tableRecord.status = this.state.status
        tableRecord.createdon = this.state.createdon
        tableRecord.actions = this.state.actions

        let temp = [...this.state.data.rows];
        temp[this.state.selectedIndex] = tableRecord;
        let temp1 = {};
        temp1.rows = [...temp];
        temp1.columns = [...this.state.data.columns]
        this.setState({
            modal: !this.state.modal,
            data: temp1
        })
    }

    onOkPressed = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    setValue = (value, id) => {
        if (id === 'createdby') {
            this.setState({
                createdby: value,
            })
        } else if (id === 'requestNo') {
            this.setState({
                requestNo: value,
            })

        } else if (id === 'reason') {
            this.setState({
                reason: value,
            })
        } else if (id === 'status') {
            this.setState({
                status: value,
            })
        } else if (id === 'createdon') {
            this.setState({
                createdon: value,
            })
        }
    }

    getTitle = () => {
        let pathName = '';

        if (!this.props.location.state) {
            pathName = localStorage.getItem('currentPath');
        } else if (this.props.location.state) {
            pathName = this.props.location.state.pathname
        } else if (pathName === "lookUp") {
            this.setState({ isFromLookup: true })
        }

        switch (pathName) {
            case 'Disciplinaryboard':
                return i18n[this.state.isArabic ? 'ar' : 'en'].Disciplinaryboard.subject;
                case 'StrategicPlanningforworkforceEnquire':
                return i18n[this.state.isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Enquire;
            case 'SCIRequest':
                return i18n[this.state.isArabic ? 'ar' : 'en'].sci;
            case 'informationRequest':
                return i18n[this.state.isArabic ? 'ar' : 'en'].infoRequest;
            case 'bonusRequest':
                return i18n[this.state.isArabic ? 'ar' : 'en'].bonusRequest;
            case 'amendSalaryScreen':
                return i18n[this.state.isArabic ? 'ar' : 'en'].amendSalariesScale;
            case 'amendEmployeeSalary':
                return i18n[this.state.isArabic ? 'ar' : 'en'].amendEmployeeSalary;
            case 'exemptionFromAccompanyPatient':
                return i18n[this.state.isArabic ? 'ar' : 'en'].exemptionFromAccompanyPatien;
                case 'SickLeave':
                        return i18n[this.state.isArabic ? 'ar' : 'en'].SickLeave.subject;
                        case 'Promotion':
                                return i18n[this.state.isArabic ? 'ar' : 'en'].Promotion.subject;
                               
            case 'exceptionOfSteerTheAgeOfRetirementRequest':
                return i18n[this.state.isArabic ? 'ar' : 'en'].exceptionForRetirment;
            case 'reviewHRPolicies':
                return i18n[this.state.isArabic ? 'ar' : 'en'].reviewHrPolicy;
            case 'exceptionADHousing':
                return i18n[this.state.isArabic ? 'ar' : 'en'].exceptionADHousing;
            case 'secondmentForFederalAgency':
                return i18n[this.state.isArabic ? 'ar' : 'en'].secondmentForFederal;
            case 'role':
                return i18n[this.state.isArabic ? 'ar' : 'en'].adminPanelKeys.roleList;
            case 'entity':
                return i18n[this.state.isArabic ? 'ar' : 'en'].adminPanelKeys.entityList;
            case 'lookUp':
                return i18n[this.state.isArabic ? 'ar' : 'en'].adminPanelKeys.lookupList;
            case 'contact':
                return i18n[this.state.isArabic ? 'ar' : 'en'].adminPanelKeys.contactList;
            case 'mailTemplate':
                return i18n[this.state.isArabic ? 'ar' : 'en'].adminPanelKeys.mailTemplateList;
            case 'inprogress':
                return i18n[this.state.isArabic ? 'ar' : 'en'].inprogress;
            case 'pending':
                return i18n[this.state.isArabic ? 'ar' : 'en'].pending;
                case 'Underapproval':
                    return i18n[this.state.isArabic ? 'ar' : 'en'].Underapproval;
                    case 'Late':
                        return i18n[this.state.isArabic ? 'ar' : 'en'].Late;
            case 'completed':
                return i18n[this.state.isArabic ? 'ar' : 'en'].completed;
            case 'cancelled':
                return i18n[this.state.isArabic ? 'ar' : 'en'].cancelled;
            case 'onhold':
                return i18n[this.state.isArabic ? 'ar' : 'en'].onhold;
            case 'currentUserTasks':
                return i18n[this.state.isArabic ? 'ar' : 'en'].task;

            //Data Services
            case 'extendCreateObjectives':
                return i18n[this.getLocale()].extendCreateObjectives;
            case 'extendReviewObjectives':
                return i18n[this.getLocale()].extendReviewObjectives;
            case 'extendAnnualPerformance':
                return i18n[this.getLocale()].extendAnnualPerformanceEvaluationInterval;
            case 'addOrEditEmployeesPerformanceEvaluation':
                return i18n[this.getLocale()].addOrEditEmployeesPerformanceEvaluation;
            case 'joinAndImplementGovResourcesSystemForNewEntity':
                return i18n[this.getLocale()].joinAndImplementGovernmentResourcesSystemForNewEntity;
            case 'openComplainsStage':
                return i18n[this.getLocale()].openComplainsStage;
            case 'amendsSharedGovernmentHRManagement':
                return i18n[this.getLocale()].amendsOnSharedGovernmentHRManagementystem;
            case 'reportsAndStaticRequest':
                return i18n[this.getLocale()].ReportsAndStaticRequest;
            case 'otherRequests':
                return i18n[this.getLocale()].OtherRequests;
            // organization structure 
            case 'OrganizationStructure':

                return this.state.loginData.ModifyStructure === 1 ? i18n[this.getLocale()].organizationStructure
                    : i18n[this.getLocale()].organizationStructure



                
            default:
                return '';
        }
    }

    statusUpdateInfo = (obj) => {
        this.toggle();
    }

    exportToExcel = () => {
        let pathname = localStorage.getItem('currentPath');
     
        return this.state.data.rows;
    }

    create = () => {
        let pathname = localStorage.getItem('currentPath');
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });

        })

    }


    createRequest = () => {
        this.props.history.push('/SCIRequest', {
            pathname: 'SCIRequest',
            data: this.state.popUpRowArray[this.state.selectedIndex],
        });
    }

    updateYClickCoOrdinate = (y) => {
        this.setState({
            y
        });
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    render() {

        let sideBarData = [];
        let pathName = '';
        if (!this.props.location.state) {
            pathName = localStorage.getItem('currentPath');
        } else if (this.props.location.state) {
            pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }
      
        sideBarData = Config.getServiceSideBar(pathName, this.state.isArabic, this, 0);
       

        return (

            <MDBContainer style={{ height: '100%' }} fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header
                        goToHelpPage={this.onClickHelpPage}
                        goToLandingPage={this.goToLandingPage}
                        toggleSideBarSize={this.toggleSideBarSize}
                        logout={this.logout}
                        toggleLanguage={this.toggleLanguage}
                        isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow style={{ height: '100%', flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol className="formWhiteContainer" md={this.state.isSideBarMinimized ? "11" : "10"} sm={this.state.isSideBarMinimized ? "11" : "11"} size={this.state.isSideBarMinimized ? "11" : "11"}>
                            <MDBRow>
                                <MDBCol md="12" sm="12" size="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                {/* <div  style={{
                                    textAlign:'center',
                                   'margin':'15px', width: '98%', display: 'flex', padding: '2% 1%', flexDirection: 'row', justifyContent: this.props.isSideBarMinimized ? 'center' : 'space-between', alignItems: 'center', backgroundColor:'#B8860B',color:'#ffffff' }}>
    <h1 style={{'text-align':'center','width':'100%'}}>{this.state.isArabic?'يرجى التكرم بمراجعة مكتبة سيساسات الموارد البشرية قبل ارسال طلبك فالمكتبة تحتوي جميع التشريعات و القرارت الصادرة عن الهيئة':'We recomaned that you search first in Policies Lib for you answer before you submit your rquest.'}</h1>
</div> */}
                                    <SubHeaderStrip enableCreate={true} exportVisible={this.state.isexportVisible} 
                                    goBack={() => { this.props.history.replace('/landing') }} create={this.create} 
                                    export={this.state.data.rows ? this.state.data.rows : []}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].servicelistingtitle + ' ' + this.getTitle()} />
                                </MDBCol>
                            </MDBRow>

                            <manageAction
                                ref={this.child}
                                changeState={this.state.changeState}
                                isInternal={this.state.isInternal}
                                manageAction={this.manageAction.bind(this)}
                                // pageLimit={this.state.data.pageLimit}
                                // pageNumber={this.state.data.pageNumber}
                                // columns={this.state.columnData} 
                                // rows={this.state.data.rows}
                                isArabic={this.state.isArabic}
                                location={this.props.location.state}
                                y={this.state.y}
                                updateYClickCoOrdinate={this.updateYClickCoOrdinate}
                                toggleLoadingSpinner={this.toggleLoadingSpinner}
                                updateListData={this.updateListData}
                                modaleClose={this.state.modal}
                                entityName={this.state.entityName} />
                            <DataTable
                                ref={this.child}
                                changeState={this.state.changeState}
                                isInternal={this.state.isInternal}
                                manageAction={this.manageAction.bind(this)}
                                // pageLimit={this.state.data.pageLimit}
                                // pageNumber={this.state.data.pageNumber}
                                // columns={this.state.columnData} 
                                // rows={this.state.data.rows}
                                isArabic={this.state.isArabic}
                                location={this.props.location.state}
                                y={this.state.y}
                                updateYClickCoOrdinate={this.updateYClickCoOrdinate}
                                toggleLoadingSpinner={this.toggleLoadingSpinner}
                                updateListData={this.updateListData}
                                modaleClose={this.state.modal}
                                entityName={this.state.entityName} />
                            <Footer isArabic={this.state.isArabic} />

                        </MDBCol>

                        <SideBar
                            isInternal={this.state.isInternal}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            canviewbonusrequest={this.state.canviewbonusrequest}
                            canviewamendscalerequest={this.state.canviewamendscalerequest}
                            canviewamendsalaryrequest={this.state.canviewamendsalaryrequest}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // data services
                            canviewextendcreaterequest={this.state.canviewextendcreaterequest}
                            canviewextendreviewrequest={this.state.canviewextendreviewrequest}
                            canviewextendannualrequest={this.state.canviewextendannualrequest}
                            canviewemployeeperformancerequest={this.state.canviewemployeeperformancerequest}
                            canviewjoinimplementrequest={this.state.canviewjoinimplementrequest}
                            canviewopencomplainsrequest={this.state.canviewopencomplainsrequest}
                            canviewamendsystemsrequest={this.state.canviewamendsystemsrequest}
                            canviewreportsstaticsrequest={this.state.canviewreportsstaticsrequest}
                            canviewotherrequestsrequest={this.state.canviewotherrequestsrequest}
                            // end of data services

                            //organization structure
                            canvieworgrequest={this.state.canvieworgrequest}
                            canvieworgrequesttree={Config.getCanViewTree()}
                            canviewtree={this.state.canviewtree}

                            //{this.state.canvieworgrequesttree}

                            isSideBarMinimized={this.state.isSideBarMinimized}
                            isArabic={this.state.isArabic}
                            items={sideBarData}
                            toggleTheme={this.toggleTheme.bind(this)}
                        />
                    </MDBRow>
                    {/* <MDBRow>
                        <MDBCol md="12" size="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                    </MDBRow> */}

                    {/* Modal view */}
                    <MDBModal
                        className={this.state.isArabic ? 'comment_ar viewDetailModal' : 'viewDetailModal'}
                        isOpen={this.state.selectedAction === 'viewdetails' || this.state.selectedAction === 'edit' ? false : this.state.modal}
                        toggle={this.toggle}
                        size={
                            
                                 "fluid"
                                
                        } position="bottom">

                        <MDBModalHeader
                            style={{
                                padding: '0.5rem 1rem',
                                flexDirection: this.state.isArabic ? 'row-reverse' : 'row',
                                textAlign: this.state.isArabic ? "right" : "left"
                            }}
                            toggle={this.toggle}>{this.getModalTitle()}
                        </MDBModalHeader>

                        <MDBModalBody>
                            {this.selectModalView()}
                        </MDBModalBody>
                    </MDBModal>

                </LoadingOverlay>

            </MDBContainer>
        );
    }
}