import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import StepsComponent from './../StepsComponent';
import Config from '../../utils/Config';
import i18n from './../../i18n';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import Validator from './../../utils/Validator';
import CKEditor from "react-ckeditor-component";

const striptags = require('striptags');

export default class StepOne extends Component {

    constructor(props) {
        debugger;
        super(props);
        this.state = {
            isEdit: false,
            requestId: '',
            statusid: '',
            steps: this.props.steps,
            employeeName: this.props.sendData.employeeName,
            employeeNumber: this.props.sendData.employeeNumber,
            nationality: this.props.sendData.nationality,
            dateOfBirth: this.props.sendData.dateOfBirth,
            jobTitle: this.props.sendData.jobTitle,
            unifiedNumber: this.props.sendData.unifiedNumber,
            fromDate: this.props.sendData.fromDate,
            toDate: this.props.sendData.toDate,
            copyButton: this.props.sendData.copyButton,
            reasonForExtendingTheService: this.props.sendData.reasonForExtendingTheService,
            // annualPerformanceEvaluation: this.props.sendData.annualPerformanceEvaluation,
            emiratesIDStepOne: this.props.sendData.emiratesIDStepOne,
            nationalOrNot: this.props.sendData.nationalOrNot,
            age: this.props.sendData.age,
            dateOfBirthErr: '',
            fromDateErr: '',
            toDateErr: '',
            copyButtonErr: '',
            emiratesIDStepOneErr: '',
            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            nationalityArr: [],
            // reasonForExtendingTheService: RichTextEditor.createValueFromString(markup1, 'html'),
            reasonForExtendingTheServiceErr: '',
            // annualPerformanceEvaluation: RichTextEditor.createValueFromString(markup2, 'html'),
            annualPerformanceEvaluationErr: '',
            dateDOB: Validator.calculateMaxiumAge(),
            minDOB: Validator.calculateMinimumAge(), //new Date(),
            minHiringDate: Validator.calculateMinHiringDate(),
            maxHiringDate: Validator.calculateMaxHiringDate(),
            // nationalitySelectedValue: this.props.sendData.nationalitySelectedValue,
            nationalityLabel: this.props.sendData.nationalityLabel,
            valHiringDate: this.props.sendData.HiringDate,
            valCurrentGrade: this.props.sendData.CurrentGrade,
            valEfficiency: this.props.sendData.Efficiency,
           // valextendedservice: this.props.sendData.HiringDate,
            valSalary: this.props.sendData.Salary,
            valQualification: this.props.sendData.Qualification,
            valGraduationYear: this.props.sendData.GraduationYear,
            valDateofretirementAge: this.props.sendData.DateofretirementAge,
            valTypeofContract: this.props.sendData.TypeofContract,
           // valReplacementplan: this.props.sendData.HiringDate,
            valprviousextensionwithoutapprovalfrom: this.props.sendData.prviousextensionwithoutapprovalfrom,
            valprviousextensionwithoutapprovalto: this.props.sendData.prviousextensionwithoutapprovalto,
            valPreviousextionsfrom: this.props.sendData.Previousextionsfrom,
            valPreviousextionsto: this.props.sendData.Previousextionsto,
            valReplacementplanfrom: this.props.sendData.Replacementplanfrom,
            valReplacementplanto: this.props.sendData.Replacementplanto,
            HiringDateErr: '',
            CurrentGradeErr: '',
            EfficiencyErr: '',
            extendedserviceErr: '',
            SalaryErr: '',
            QualificationErr: '',
            GraduationYearErr: '',
            DateofretirementAgeErr: '',
            TypeofContractErr: '',
            ReplacementplanErr: '',
           
            ReplacementplanfromErr: '',
            ReplacementplantoErr: '',
            EfficiencyArray: [],
            contractArray: [
                {
                    id: 1,
                    EnglishTitle: 'Permanent',
                    ArabicTitle: 'دائم'
                },
                {
                    id: 2,
                    EnglishTitle: 'Private',
                    ArabicTitle: 'خاص'
                }
            ]

        }
        this.handleDateOfBirth = this.handleDateOfBirth.bind(this);
        this.handleFromDate = this.handleFromDate.bind(this);
        this.handleValHiringDate = this.handleValHiringDate.bind(this);
        this.handelCurrentGrade = this.handelCurrentGrade.bind(this);
        this.handleEfficiency = this.handleEfficiency.bind(this);
        this.handleSalary = this.handleSalary.bind(this);
        this.handleGraduationYear = this.handleGraduationYear.bind(this);
        this.handleQualification = this.handleQualification.bind(this);
        this.handleDateofretirementAge = this.handleDateofretirementAge.bind(this);

        this.handleprviousextensionwithoutapprovalfrom = this.handleprviousextensionwithoutapprovalfrom.bind(this);
        this.handlepreviousextensionwithoutapprovalto = this.handlepreviousextensionwithoutapprovalto.bind(this);
        this.handlePreviousextionsfrom = this.handlePreviousextionsfrom.bind(this);
        this.handlePreviousextionsto = this.handlePreviousextionsto.bind(this);
        this.handleReplacementplanfrom = this.handleReplacementplanfrom.bind(this);
        this.handleReplacementplanto = this.handleReplacementplanto.bind(this);
        this.handleTypeofContract = this.handleTypeofContract.bind(this);

    }

    componentDidMount = () => {

        window.scrollTo(0, 0);

        let tempNationalityArray = [];
        let tempEfficiencyArray = [];
        if (Config.getNationalityData()) {
            tempNationalityArray = Config.getNationalityData();
        }
        if (Config.getEfficiencyData()) {
            tempEfficiencyArray = Config.getEfficiencyData();
        }
        this.setState({
            nationalityArr: tempNationalityArray,
            EfficiencyArray: tempEfficiencyArray
        });

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            debugger;
            let tempArray = this.state.contractArray;
            let TypeofContract = '';
            if (tempArray.length > 0) {
                for (let index = 0; index < tempArray.length; index++) {
                    if (parseInt(editObj.TypeofContract) === tempArray[index].id) {
                        TypeofContract = this.props.isArabic ? tempArray[index].ArabicTitle : tempArray[index].EnglishTitle;
                    }
                }
            }

            let age = '';
            if (editObj.dob) {
                age = Validator.calculateAgeforRetirment(new Date(`${editObj.dob}`), this.state.isArabic);
            } else {
                age = '';
            }
            
            editObj.employeage = age;
            let temp = 'No';
            if (editObj.nationalityid === Config.getNationalId()) {
                temp = 'Yes'
            }
            let tempDateOne = '', tempDateTwo = '', tempDateThree = ''
            if (editObj.dob) {
                tempDateOne = new Date(editObj.dob);
            } else {
                tempDateOne = null;
            }

            if (editObj.steerfrom) {
                tempDateTwo = new Date(editObj.steerfrom);
            } else {
                tempDateTwo = null;
            }

            if (editObj.steerto) {
                tempDateThree = new Date(editObj.steerto);
            } else {
                tempDateThree = null;
            }

            //getting nationality label using nationality id
            let nantionalityLabel = "";

            if (tempNationalityArray && tempNationalityArray.length > 0) {
                for (let index = 0; index < tempNationalityArray.length; index++) {


                    if (parseInt(editObj.nationalityid) === parseInt(tempNationalityArray[index].id)) {

                        nantionalityLabel = this.props.isArabic ? tempNationalityArray[index].ArabicTitle : tempNationalityArray[index].EnglishTitle;
                    }
                }
            }
            debugger;
            let HiringDate = '';
            if (editObj.HiringDate) {
                HiringDate = new Date(editObj.HiringDate);
            }
            let CurrentGrade = '';
            if (editObj.CurrentGrade) {
                CurrentGrade = editObj.CurrentGrade;
            }
            let Efficiency = '';
            if (editObj.Efficiency) {
               
            }
            let extendedservice = '';
            if (editObj.extendedservice) {
                extendedservice = editObj.extendedservice;
            }
            let Salary = '';
            if (editObj.Salary) {
                Salary = editObj.Salary;
            }
            let Qualification = '';
            if (editObj.Qualification) {
                Qualification = editObj.Qualification;
            }
            let GraduationYear = '';
            if (editObj.GraduationYear) {
                GraduationYear = editObj.GraduationYear;
            }
            let DateofretirementAge = '';
            if (editObj.DateofretirementAge) {
                DateofretirementAge = new Date(editObj.DateofretirementAge);
            }
            //let TypeofContract = '';
            //if (editObj.TypeofContract) {
                
            //}
            let prviousextensionwithoutapprovalfrom = '';
            if (editObj.prviousextensionwithoutapprovalfrom) {
                prviousextensionwithoutapprovalfrom = new Date(editObj.prviousextensionwithoutapprovalfrom);
            }
            let prviousextensionwithoutapprovalto = '';
            if (editObj.prviousextensionwithoutapprovalto) {
                prviousextensionwithoutapprovalto = new Date(editObj.prviousextensionwithoutapprovalto);
            }
            let Previousextionsfrom = '';
            if (editObj.Previousextionsfrom) {
                Previousextionsfrom = new Date(editObj.Previousextionsfrom);
            }
            let Previousextionsto = '';
            if (editObj.Previousextionsto) {
                Previousextionsto = new Date(editObj.Previousextionsto);
            }
            let Replacementplanfrom = '';
            if (editObj.Replacementplanfrom) {
                Replacementplanfrom = new Date(editObj.Replacementplanfrom);
            }
            let Replacementplanto = '';
            if (editObj.Replacementplanto) {
                Replacementplanto = new Date(editObj.Replacementplanto);
            }
         debugger;
            

            this.setState({ nationalOrNot: temp }, () => {
         
              }); 
              
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                age: age,

                employeeName: editObj.employename,
                employeeNumber: editObj.employeenumber,
                nationality: editObj.nationalityid,
                nationalOrNot: temp,               
                // dateOfBirth: new Date(`${editObj.dob}`),
                dateOfBirth: tempDateOne,
                jobTitle: editObj.jobtitle,
                unifiedNumber: editObj.unifiednumber,
                // fromDate: new Date(`${editObj.steerfrom}`),
                // toDate: new Date(`${editObj.steerto}`),
                nationalityLabel: nantionalityLabel,

                fromDate: tempDateTwo,
                toDate: tempDateThree,
                copyButton: parseInt(editObj.isjurisdiction) === 1 ? 'Yes' : 'No',
                reasonForExtendingTheService: editObj.reasonforextending,
                //  annualPerformanceEvaluation: editObj.annualperformanceevaluation,
                emiratesIDStepOne: editObj.emiratesid,

                valHiringDate: HiringDate,
                valCurrentGrade: CurrentGrade,
                valEfficiency: editObj.Efficiency,
                valextendedservice: extendedservice,
                valSalary: Salary,
                valQualification: Qualification,
                valGraduationYear: GraduationYear,
                valDateofretirementAge: DateofretirementAge,
                valTypeofContract: editObj.TypeofContract,
                valprviousextensionwithoutapprovalfrom: prviousextensionwithoutapprovalfrom,
                valprviousextensionwithoutapprovalto: prviousextensionwithoutapprovalto,
                valPreviousextionsfrom: Previousextionsfrom,
                valPreviousextionsto: Previousextionsto,
                valReplacementplanfrom: Replacementplanfrom,
                valReplacementplanto: Replacementplanto

            }, () => {
            });

            //For Preview Form
            var date = new Date(editObj.dob);
            // birth date validation
            // date.setDate(date.getDate() - 1);

            //this.setState({ dateDOB: date });
            //Calculate min DOB
            // if (parseInt(this.state.nationality) === Config.getNationalId()) {
            //     // Set DOB min date = (Current date) - 74
            //     var tempDate = Validator.calculateMinimumAge();
            //     this.setState({
            //         minDOB: tempDate,
            //     });
            // } else {
            //     // Set DOB min date = (Current date) - 64
            //     var tempDate1 = Validator.calculateMinimumAge();
            //     this.setState({
            //         minDOB: tempDate1,
            //     });
            // }
        } else {


            if (parseInt(this.state.nationality) === Config.getNationalId()) {
                // Set DOB min date = (Current date) - 74
                //var tempDate = Validator.calculateMinimumAge();
                this.setState({
                    // minDOB: tempDate,
                    nationalOrNot: 'Yes',
                });
            } else {
                // Set DOB min date = (Current date) - 64
                //var tempDate1 = Validator.calculateMinimumAge();
                this.setState({
                    //minDOB: tempDate1,
                    nationalOrNot: 'No'
                });
            }
        }
    }

    onClick = nr => () => {
        this.setState({
            radio: nr
        });
    }


    handleTypeContractChange = (event) => {
        // this.setState({ typeOfContract: parseInt(event.target.value) });
        let dropDownValue = event.target.value;

        let tempArray = this.state.contractArray;
        let dropDownLabel = '';
        if (tempArray.length > 0) {
            for (let index = 0; index < tempArray.length; index++) {
                if (parseInt(dropDownValue) === tempArray[index].id) {
                    dropDownLabel = this.props.isArabic ? tempArray[index].ArabicTitle : tempArray[index].EnglishTitle;
                    if (dropDownValue == "1") {
                        this.state.contractperiodrequired = "1";
                        debugger;
                        //this.refs.contractperiodrequiredspan.style('display:none');
                       // document.getElementById('contractperiodrequiredspan').style.display = "none";
                    //    document.getElementById('exampleFormControlTextarea2').required = false;
                    }
                    else {
                      //  document.getElementById('contractperiodrequiredspan').style.display = "flex";
                     //   document.getElementById('exampleFormControlTextarea2').required = true;
                    }
                }
            }
        }

        this.setState({
            valTypeofContract: parseInt(event.target.value),
            //typeOfContractLabel: dropDownLabel
        });
    };

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    checkValidations = () => {
     
        let dateOfBirthErr = '', fromDateErr = '', toDateErr = '', copyButtonErr = '', tempreasonForExtendingTheServiceErr = '', emiratesIDStepOneErr = '', nationalOrNotErr = '', HiringDateErr = '', CurrentGradeErr = '',
            EfficiencyErr = '',
            extendedserviceErr = '',
            SalaryErr = '',
            QualificationErr = '',
            GraduationYearErr = '',
            DateofretirementAgeErr = '',
            ReplacementplanfromErr='',
            ReplacementplantoErr = '', TypeofContractErr='';
  
        if (!this.state.dateOfBirth) {
            dateOfBirthErr = 'thisFieldIsRequired';
        }
        if (!this.state.fromDate) {
            fromDateErr = 'thisFieldIsRequired';
        }

        if (!this.state.valReplacementplanfrom) {
            ReplacementplanfromErr = 'thisFieldIsRequired';
        }

        
        if (!this.state.valReplacementplanto) {
            ReplacementplantoErr = 'thisFieldIsRequired';
        }
        if (!this.state.toDate) {
            toDateErr = 'thisFieldIsRequired';
        }
        if (!this.state.copyButton) {
            copyButtonErr = 'thisFieldIsRequired';
        }
        if (!this.state.valHiringDate) {
            HiringDateErr = 'thisFieldIsRequired';
        }
        if (!this.state.valCurrentGrade) {
            CurrentGradeErr = 'thisFieldIsRequired';
        }
        if (!this.state.valEfficiency) {
            EfficiencyErr = 'thisFieldIsRequired';
        }
        /*   if (!this.state.valextendedservice) {
               extendedserviceErr = 'thisFieldIsRequired';
           }*/
        if (!this.state.valSalary) {
            SalaryErr = 'thisFieldIsRequired';
        }
        if (!this.state.valQualification) {
            QualificationErr = 'thisFieldIsRequired';
        }
        if (!this.state.valGraduationYear) {
            GraduationYearErr = 'thisFieldIsRequired';
        }
        if (!this.state.valDateofretirementAge) {
            DateofretirementAgeErr = 'thisFieldIsRequired';
        }
        if (!this.state.valTypeofContract) {
               TypeofContractErr = 'thisFieldIsRequired';
           }
          


        if (this.state.reasonForExtendingTheService && this.state.reasonForExtendingTheService.trim()) {
            let val = striptags(this.state.reasonForExtendingTheService.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempreasonForExtendingTheServiceErr = "thisFieldisRequired";
            } else {
                tempreasonForExtendingTheServiceErr = "";
            }
        } else {
            tempreasonForExtendingTheServiceErr = "thisFieldisRequired";
        }

        if (this.state.emiratesIDStepOne) {
            if (!Validator.validateEmiratesId(this.state.emiratesIDStepOne)) {
                emiratesIDStepOneErr = "invalidemiratesID";
            } else {
                emiratesIDStepOneErr = "";
            }
        }
        else {
            emiratesIDStepOneErr = "thisFieldisRequired";
        }
        debugger;
        if (!this.state.nationalOrNot) {
            nationalOrNotErr = "thisFieldisRequired";
        }

        this.setState({
            dateOfBirthErr: dateOfBirthErr,
            fromDateErr: fromDateErr,
            toDateErr: toDateErr,
            copyButtonErr: copyButtonErr,
            reasonForExtendingTheServiceErr: tempreasonForExtendingTheServiceErr,
            emiratesIDStepOneErr: emiratesIDStepOneErr,
            nationalOrNotErr: nationalOrNotErr,
            HiringDateErr: HiringDateErr,ReplacementplanfromErr,ReplacementplantoErr
        }, () => {

            if (!dateOfBirthErr && !fromDateErr && !toDateErr && !copyButtonErr && !emiratesIDStepOneErr && !tempreasonForExtendingTheServiceErr && !HiringDateErr && !CurrentGradeErr && !EfficiencyErr && !extendedserviceErr && !SalaryErr && !QualificationErr && !GraduationYearErr && !DateofretirementAgeErr && !TypeofContractErr && !ReplacementplanfromErr && !ReplacementplantoErr ) {
                if (this.state.employeeName &&
                    this.state.employeeNumber &&
                    this.state.nationality &&
                    this.state.dateOfBirth &&
                    this.state.jobTitle &&
                    this.state.unifiedNumber &&
                    this.state.fromDate &&
                    this.state.toDate &&
                    this.state.copyButton &&
                    striptags(this.state.reasonForExtendingTheService) &&
                    this.state.emiratesIDStepOne &&
                    this.state.nationalOrNot &&
                    this.state.age && this.state.valHiringDate &&
                    this.state.valCurrentGrade &&
                    this.state.valEfficiency &&
                   
                    this.state.valSalary &&
                    this.state.valQualification &&
                    this.state.valGraduationYear &&
                    this.state.valDateofretirementAge &&
                    this.state.valTypeofContract
                    &&
                    this.state.valReplacementplanfrom
                    &&
                    this.state.valReplacementplanto
                      /*&&
                   this.state.valprviousextensionwithoutapprovalfrom
                    &&
                    this.state.valprviousextensionwithoutapprovalto
                    &&
                    this.state.valPreviousextionsfrom
                    &&
                    this.state.valPreviousextionsto
            */

                ) {
                    debugger;
                    this.setState({ isNextClick: true, isfirstPageValidate: true }, () => {

                        this.props.getData.stepOneData({
                            employeeName: this.state.employeeName,
                            employeeNumber: this.state.employeeNumber,
                            nationality: this.state.nationality,
                            dateOfBirth: this.state.dateOfBirth,
                            jobTitle: this.state.jobTitle,
                            unifiedNumber: this.state.unifiedNumber,
                            fromDate: this.state.fromDate,
                            toDate: this.state.toDate,
                            copyButton: this.state.copyButton,
                            reasonForExtendingTheService: this.state.reasonForExtendingTheService,
                            //annualPerformanceEvaluation: this.state.annualPerformanceEvaluation,
                            emiratesIDStepOne: this.state.emiratesIDStepOne,
                            nationalOrNot: this.state.nationalOrNot,
                            nationalityLabel: this.state.nationalityLabel,
                            age: this.state.age,
                            HiringDate: this.state.valHiringDate,
                            CurrentGrade: this.state.valCurrentGrade,
                            Efficiency: this.state.valEfficiency,
                            extendedservice: this.state.valextendedservice,
                            Salary: this.state.valSalary,
                            Qualification: this.state.valQualification,
                            GraduationYear: this.state.valGraduationYear,
                            DateofretirementAge: this.state.valDateofretirementAge,
                            TypeofContract: this.state.valTypeofContract,
                            prviousextensionwithoutapprovalfrom: this.state.valprviousextensionwithoutapprovalfrom,
                            prviousextensionwithoutapprovalto: this.state.valprviousextensionwithoutapprovalto,
                            Previousextionsfrom: this.state.valPreviousextionsfrom,
                            Previousextionsto: this.state.valPreviousextionsto,
                            Replacementplanfrom: this.state.valReplacementplanfrom,
                            Replacementplanto: this.state.valReplacementplanto
                        });
                    });
                }
            }
        })
    }

    submitHandler = (event) => {
        debugger;
        event.preventDefault();
        event.target.className += " was-validated";
        if (!this.state.isEdit || (this.state.statusid === Config.getDraftId())) {
            this.checkValidations();
        } else {
            debugger;
            this.setState({ isNextClick: true, isfirstPageValidate: true }, () => {
                this.props.getData.stepOneData({
                    employeeName: this.state.employeeName,
                    employeeNumber: this.state.employeeNumber,
                    nationality: this.state.nationality,
                    dateOfBirth: this.state.dateOfBirth,
                    jobTitle: this.state.jobTitle,
                    unifiedNumber: this.state.unifiedNumber,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate,
                    copyButton: this.state.copyButton,
                    reasonForExtendingTheService: this.state.reasonForExtendingTheService,
                    //annualPerformanceEvaluation: this.state.annualPerformanceEvaluation,
                    emiratesIDStepOne: this.state.emiratesIDStepOne,
                    // nationality: this.state.nationality,
                    nationalOrNot: this.state.nationalOrNot,
                    age: this.state.age,
                    nationalityLabel: this.state.nationalityLabel,
                    HiringDate: this.state.valHiringDate,
                    CurrentGrade: this.state.valCurrentGrade,
                    Efficiency: this.state.valEfficiency,
                    extendedservice: this.state.valextendedservice,
                    Salary: this.state.valSalary,
                    Qualification: this.state.valQualification,
                    GraduationYear: this.state.valGraduationYear,
                    DateofretirementAge: this.state.valDateofretirementAge,
                    TypeofContract: this.state.valTypeofContract,
                    prviousextensionwithoutapprovalfrom: this.state.valprviousextensionwithoutapprovalfrom,
                    prviousextensionwithoutapprovalto: this.state.valprviousextensionwithoutapprovalto,
                    Previousextionsfrom: this.state.valPreviousextionsfrom,
                    Previousextionsto: this.state.valPreviousextionsto,
                    Replacementplanfrom: this.state.valReplacementplanfrom,
                    Replacementplanto: this.state.valReplacementplanto
                });

            });

        }
    };

    clearForm = () => {
        this.setState({
            employeeName: '',
            employeeNumber: '',
            nationality: '',
            dateOfBirth: '',
            jobTitle: '',
            unifiedNumber: '',
            fromDate: '',
            toDate: '',
            copyButton: '',
            reasonForExtendingTheService: '',
            //annualPerformanceEvaluation: this.state.annualPerformanceEvaluation,
            emiratesIDStepOne: '',
            nationality: '',
            nationalOrNot: '',
            age: '',
            valHiringDate: '',
            valCurrentGrade: '',
            valEfficiency: '',
            valextendedservice: '',
            valSalary: '',
            valQualification: '',
            valGraduationYear: '',
            valDateofretirementAge: '',
            valTypeofContract: '',
            valprviousextensionwithoutapprovalfrom: '',
            valprviousextensionwithoutapprovalto: '', 
            valPreviousextionsfrom: '',
            valPreviousextionsto: '',
            valReplacementplanfrom: '',
            valReplacementplanto: ''
        });
    }

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };

    handleEmployeeNumber = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ employeeNumber: event.target.value });
        }
    };

    handleNationality = (event) => {
       
        let dropDownLabel = '';
        this.setState({
            nationality: event.target.value,
            dateOfBirth: '',
            age: ''
        }, () => {

            if (this.state.nationalityArr.length > 0) {
                for (let index = 0; index < this.state.nationalityArr.length; index++) {

                    if (parseInt(this.state.nationality) === this.state.nationalityArr[index].id) {

                        dropDownLabel = this.props.isArabic ? this.state.nationalityArr[index].ArabicTitle : this.state.nationalityArr[index].EnglishTitle;
                    }
                }
            }

            this.setState({
                //typeOfRequest: parseInt(event.target.value),
                nationalityLabel: dropDownLabel
            }, () => {

            });

            if (parseInt(this.state.nationality) === Config.getNationalId()) {
                //Set DOB min date = (Current date) - 74
                // var tempDate = Validator.calculateMinimumAge();
                
                this.setState({
                    // minDOB: tempDate,
                   
                    nationalOrNot: 'Yes'
                }
                ,()=>{
                    debugger;
                
                }
                );
            } else {
                
                //Set DOB min date = (Current date) - 64
                //var tempDate1 = Validator.calculateMinimumAge();
                this.setState({
                    //  minDOB: tempDate1,
                    nationalOrNot: 'No'
                },()=>{
                    debugger;
                  
                });
            }
          
           
        });
    };

    handleJobTitle = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ jobTitle: event.target.value });
            }
        }
        else {
            this.setState({ jobTitle: '' });
        }
    };

    handleUnifiedNumber = (event) => {
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ unifiedNumber: event.target.value });
            }
        }
        else {
            this.setState({ unifiedNumber: "" })
        }
    };

    handleReasonForExtendingTheService = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                reasonForExtendingTheServiceErr: "",
                reasonForExtendingTheService: newContent
            });
        } else {
            this.setState({
                reasonForExtendingTheServiceErr: "ckeditorCountErr"
            });
        }
    };


    handleAnnualPerformanceEvaluation = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            annualPerformanceEvaluationErr: false,
            annualPerformanceEvaluation: newContent
        })
    };

    handleDateOfBirth(date) {
        debugger;
        let age = Validator.calculateAgeforRetirment(date, this.state.isArabic);
        this.setState({
            age: age,
            dateOfBirth: date,
            dateOfBirthErr: ''
        });
    }

    handleFromDate(date) {
        this.setState({
            fromDate: date,
            fromDateErr: ''
        }, () => {
            var d = new Date(date);
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var tempDate = new Date(year + 1, month, day - 1);
            this.setState({ toDate: tempDate, toDateErr: '' });
        });
    }

    handleValHiringDate(date) {
        this.setState(
            {
                valHiringDate: date,
                HiringDateErr: ''
            });

    }

   
    handelCurrentGrade = (event) => { this.setState({ valCurrentGrade: event.target.value }); };

    handleTypeofContract = (event) => { this.setState({ valTypeofContract: event.target.value }); };

    handleEfficiency = (event) => { this.setState({ valEfficiency: event.target.value }); };
    handleSalary = (event) => { this.setState({ valSalary: event.target.value }); };
    handleGraduationYear = (event) => { this.setState({ valGraduationYear: event.target.value }); };
    handleQualification = (event) => { this.setState({ valQualification: event.target.value }); };
    handleGrade = (event) => { this.setState({ valCurrentGrade: event.target.value }); };
    handleDateofretirementAge = (date) => { this.setState({ valDateofretirementAge: date }); };

    handleprviousextensionwithoutapprovalfrom = (date) => { this.setState({ valprviousextensionwithoutapprovalfrom: date }); };
    handlepreviousextensionwithoutapprovalto = (date) => { this.setState({ valprviousextensionwithoutapprovalto: date }); };
    handlePreviousextionsfrom = (date) => { this.setState({ valPreviousextionsfrom: date }); };
    handlePreviousextionsto = (date) => { this.setState({ valPreviousextionsto: date }); };
    handleReplacementplanfrom = (date) => { this.setState({ valReplacementplanfrom: date }); };
    handleReplacementplanto = (date) => { this.setState({ valReplacementplanto: date }); };


    handleCopyButton = (e) => {
        if (e.target.value !== '') {
            this.setState({ copyButton: e.target.value, copyButtonErr: '' });
        } else {
            this.setState({ copyButtonErr: 'thisFieldIsRequired' });
        }
    };



    handleemiratesIDStepOneChange = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ emiratesIDStepOne: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ emiratesIDStepOneErr: '' });
                } else {
                    this.setState({ emiratesIDStepOneErr: 'invalidemiratesID' });
                }
            });
        }
        else {
            this.setState({ emiratesIDStepOne: '', emiratesIDStepOneErr: 'thisFieldisRequired' });
        }
    }

    handleOptionChange = (e) => {
        this.setState({
            nationalOrNot: e.target.value,
            nationalOrNotErr: ''
        });
    };

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    saveAsDraft = () => {
        debugger;

        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            employeeName: this.state.employeeName,
            employeeNumber: this.state.employeeNumber,
            nationality: this.state.nationality,
            dateOfBirth: this.state.dateOfBirth,
            jobTitle: this.state.jobTitle,
            unifiedNumber: this.state.unifiedNumber,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            copyButton: this.state.copyButton,
            reasonForExtendingTheService: this.state.reasonForExtendingTheService,
            //annualPerformanceEvaluation: this.state.annualPerformanceEvaluation,
            emiratesIDStepOne: this.state.emiratesIDStepOne,
            nationalOrNot: this.state.nationalOrNot,
            age: this.state.age,
            HiringDate: this.state.valHiringDate,
            CurrentGrade: this.state.valCurrentGrade,
            Efficiency: this.state.valEfficiency,
            extendedservice: this.state.valextendedservice,
            Salary: this.state.valSalary,
            Qualification: this.state.valQualification,
            GraduationYear: this.state.valGraduationYear,
            DateofretirementAge: this.state.valDateofretirementAge,
            TypeofContract: this.state.valTypeofContract,
            prviousextensionwithoutapprovalfrom: this.state.valprviousextensionwithoutapprovalfrom,
            prviousextensionwithoutapprovalto: this.state.valprviousextensionwithoutapprovalto,
            Previousextionsfrom: this.state.valPreviousextionsfrom,
            Previousextionsto: this.state.valPreviousextionsto,
            Replacementplanfrom: this.state.valReplacementplanfrom,
            Replacementplanto: this.state.valReplacementplanto
        });

    };


    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={['Step1', 'Step2']}
                    currentIndex={1}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />


                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.employeeName}<span className="requireStar">*</span></label>
                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                type="text"
                                className="form-control"
                                id="employeeName"
                                name="employeeName"
                                onChange={this.handleEmployeeName}
                                required
                                value={this.state.employeeName}
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.nationality}<span className="requireStar">*</span></label>

                            <select
                                className="browser-default custom-select"
                                id="sources-funding"
                                onChange={this.handleNationality}
                                name='nationality'
                                value={this.state.nationality}
                                required
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                            >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.nationalityArr ? this.state.nationalityArr.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicTitle : itemStatus.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>


                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>



                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.jobTitle}<span className="requireStar">*</span></label>
                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                type="text"
                                className="form-control"
                                id="jobTitle"
                                name="jobTitle"
                                onChange={this.handleJobTitle}
                                required
                                value={this.state.jobTitle}
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.employeeNumber}<span className="requireStar">*</span></label>
                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                type="text"
                                className="form-control"
                                id="employeeNumber"
                                name="employeeNumber"
                                onChange={this.handleEmployeeNumber}
                                required
                                value={this.state.employeeNumber}
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>




                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.dateOfBirth}<span className="requireStar">*</span></label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.dateOfBirthErr ? "datePickerBorderColorErr" : ""}`}>
                                    <DatePicker
                                        className="form-control"


                                        maxDate={this.state.minDOB}
                                        minDate={this.state.dateDOB}
                                        selected={this.state.dateOfBirth}
                                        onChange={this.handleDateOfBirth}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        required
                                    />
                                </div>
                                {
                                    this.state.dateOfBirthErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>


                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].age}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="age"
                                name="age"
                                value={this.state.age}
                                disabled
                                required
                            />
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#FF3333' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].ageFormat}
                            </p>

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.props.isArabic ? 'ar' : 'en'].emiratesID}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>

                            <InputMask
                                mask="999-9999-9999999-9"
                                maskChar={null}
                                value={this.state.emiratesIDStepOne}
                                onChange={this.handleemiratesIDStepOneChange}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                className="form-control"
                                required
                            />
                            {
                                this.state.emiratesIDStepOneErr == 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.emiratesIDStepOneErr == 'invalidemiratesID' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmiratesID}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.unifiedNumber}<span className="requireStar">*</span></label>

                            <input
                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                type="text"
                                className="form-control"
                                id="unifiedNumber"
                                name="unifiedNumber"
                                onChange={this.handleUnifiedNumber}
                                required
                                value={this.state.unifiedNumber}
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}</div>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Dateofhiring}
                                    <span className="requireStar">*</span>
                                </label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.HiringDateErr ? "datePickerBorderColorErr" : ""}`}>
                                    <DatePicker
                                        style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : "ltr" }}
                                        className="form-control"
                                        maxDate={this.state.minHiringDate}
                                        minDate={this.state.maxHiringDate}
                                        selected={this.state.valHiringDate}
                                        onChange={this.handleValHiringDate}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        dateFormat="MM/dd/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        required
                                    />
                                </div>
                                {
                                    this.state.HiringDateErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.CurrentGrade}
                                    <span className="requireStar">*</span>
                                </label>
                                <input
                                    style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                    type="text"
                                    className="form-control"
                                    id="employeeName"
                                    name="employeeName"
                                    onChange={this.handleGrade}
                                    required
                                    value={this.state.valCurrentGrade}
                                />
                                {
                                    this.state.CurrentGradeErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Efficiency}
                                    <span className="requireStar">*</span>
                                </label>
                                <select
                                    className="browser-default custom-select"
                                    id="sources-funding"
                                    onChange={this.handleEfficiency}
                                    name='Efficiency'
                                    value={this.state.valEfficiency}
                                    required
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                >
                                    <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                    {
                                        this.state.EfficiencyArray ? this.state.EfficiencyArray.map((itemStatus, index) => {
                                            return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicTitle : itemStatus.EnglishTitle}</option>
                                        }) : null
                                    }
                                </select>
                                {
                                    this.state.EfficiencyErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Salary}
                                    <span className="requireStar">*</span>
                                </label>
                                <input
                                    style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                    type="text"
                                    className="form-control"
                                    id="employeeName"
                                    name="employeeName"
                                    onChange={this.handleSalary}
                                    required
                                    value={this.state.valSalary}
                                />
                                {
                                    this.state.SalaryErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.GraduationYear}
                                    <span className="requireStar">*</span>
                                </label>
                                <input
                                    style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                    type="text"
                                    className="form-control"
                                    id="employeeName"
                                    name="employeeName"
                                    onChange={this.handleGraduationYear}
                                    required
                                    value={this.state.valGraduationYear}
                                />
                                {
                                    this.state.GraduationYearErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Qualification}
                                    <span className="requireStar">*</span>
                                </label>
                                <input
                                    style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                    type="text"
                                    className="form-control"
                                    id="employeeName"
                                    name="employeeName"
                                    onChange={this.handleQualification}
                                    required
                                    value={this.state.valQualification}
                                />
                                {
                                    this.state.QualificationErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.DateofretirementAge}
                                    <span className="requireStar">*</span>
                                </label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.fromDateErr ? "datePickerBorderColorErr" : ""}`}>
                                    <DatePicker
                                        style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : "ltr" }}
                                        className="form-control"
                                        todayButton={"Today"}
                                        selected={this.state.valDateofretirementAge}
                                        onChange={this.handleDateofretirementAge}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        dateFormat="MM/dd/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        required
                                    />
                                </div>
                                {
                                    this.state.fromDateErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.TypeofContract}
                                    <span className="requireStar">*</span>
                                </label>


                                <select
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    className="browser-default custom-select"
                                    id="sources-funding"
                                    onChange={this.handleTypeContractChange}
                                    required
                                    name="typeOfRequest"
                                    value={this.state.valTypeofContract}
                                >
                                    <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                    {
                                        this.state.contractArray.map((item, index) => {
                                            return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                        })
                                    }
                                </select>
                                {
                                    this.state.TypeOfContractErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.dateOfSteer}&nbsp;
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].fromDate}
                                    <span className="requireStar">*</span>
                                </label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.fromDateErr ? "datePickerBorderColorErr" : ""}`}>
                                    <DatePicker
                                        style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : "ltr" }}
                                        className="form-control"
                                        todayButton={"Today"}
                                        selected={this.state.fromDate}
                                        onChange={this.handleFromDate}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        dateFormat="MM/dd/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        required
                                    />
                                </div>
                                {
                                    this.state.fromDateErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.dateOfSteer}&nbsp;
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].toDate}
                                    <span className="requireStar">*</span></label>

                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar" : "react-datepicker-popper"} >
                                    <DatePicker
                                        className="form-control"
                                        todayButton={"Today"}
                                        selected={this.state.toDate}
                                        disabled={true}
                                        required
                                    />
                                </div>

                                {this.state.toDateErr !== '' ?
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>



                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.copyLabel}<span className="requireStar">*</span></label>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left', marginRight: "0px" }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.copyButton === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidation2"
                                    onChange={this.handleCopyButton}
                                    name="radio-stacked"
                                    required />
                                <label class="custom-control-label" for="customControlValidation2">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>

                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation3"
                                    name="radio-stacked"
                                    checked={this.state.copyButton === 'No'}
                                    onChange={this.handleCopyButton}
                                    required />
                                <label class="custom-control-label" for="customControlValidation3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>
                            </div>
                            <br />
                            {this.state.copyButtonErr == 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                </MDBRow>

                {this.state.copyButton == 'Yes' ?
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.prviousextensionwithoutapprovalfrom}</label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} `}>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.valprviousextensionwithoutapprovalfrom}
                                        onChange={this.handleprviousextensionwithoutapprovalfrom}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        
                                    />
                                </div>
                                 
                             
                            </div>
                        </MDBCol>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.previousextensionwithoutapprovalto}</label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} `}>
                                    <DatePicker
                                        className="form-control"

                                        selected={this.state.valprviousextensionwithoutapprovalto}
                                        onChange={this.handlepreviousextensionwithoutapprovalto}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        
                                    />
                                </div>

                            </div>
                        </MDBCol>

                    </MDBRow>

                    : ''}

                {this.state.copyButton == 'Yes' ?
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Previousextionsfrom}</label>
           
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} `}>
                                    <DatePicker
                                        className="form-control"

                                        selected={this.state.valPreviousextionsfrom}
                                        onChange={this.handlePreviousextionsfrom}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        
                                    />
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Previousextionsto}</label>
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} `}>
                                    <DatePicker
                                        className="form-control"

                                        selected={this.state.valPreviousextionsto}
                                        onChange={this.handlePreviousextionsto}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        
                                    />
                                </div>
                            </div>
                        </MDBCol>

                    </MDBRow>

                    : ''}


                
                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Replacementplanfrom}<span className="requireStar">*</span></label>
          
                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.ReplacementplanfromErr ? "datePickerBorderColorErr" : ""}`}>
                                    <DatePicker
                                        className="form-control"

                                        selected={this.state.valReplacementplanfrom}
                                        onChange={this.handleReplacementplanfrom}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        required
                                    />
                                </div>
                                <br />
                                {this.state.ReplacementplanfromErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            </div>
                        </MDBCol>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Replacementplanfrom}<span className="requireStar">*</span></label>

                                <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                    className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.ReplacementplantoErr ? "datePickerBorderColorErr" : ""}`}>
                                    <DatePicker
                                        className="form-control"

                                        selected={this.state.valReplacementplanto}
                                        onChange={this.handleReplacementplanto}
                                        ref={el => this.onDatepickerRef(el)}
                                        customInput={<input />}
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="MM/dd/yyyy"
                                        required
                                    />
                                </div>
                                <br />
                                {this.state.ReplacementplantoErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            </div>
                        </MDBCol>

                    </MDBRow>

                    

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.reasonForExtendingTheService}<span className="requireStar">*</span></label>
                                <div style={{ border: this.state.reasonForExtendingTheServiceErr === 'thisFieldisRequired' ? '1px solid red' : '' }}
                                    className="ckEditorStyle">
                                    {
                                        this.props.isArabic ?
                                            null :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.reasonForExtendingTheService}
                                                events={{
                                                    "change": this.handleReasonForExtendingTheService
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.reasonForExtendingTheService}
                                                events={{
                                                    "change": this.handleReasonForExtendingTheService
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.reasonForExtendingTheServiceErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn type='submit' color="primary" className="submitBtn">{i18n[this.props.isArabic ? 'ar' : 'en'].next_btn}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>
            </form>
        );
    }
}