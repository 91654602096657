import React,{ Component } from 'react'
import InternalSurvey from './../../components/Survey/InternalSurvey'
import ExternalSurvey from './../../components/Survey/ExternalSurvey'
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import {    MDBCardBody, MDBCard,MDBContainer, MDBRow, MDBCol,MDBBtn,MDBModalBody,MDBModalHeader,MDBTableBody,MDBTableHead,MDBModal,MDBTable, MDBTableFoot }
 from "mdbreact";
 import { confirmAlert } from 'react-confirm-alert';
import '../../css/react-confirm-alert.css';
import Config from '../../utils/Config';
import ApiRequest from '../../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay';
import { Route , withRouter} from 'react-router-dom';


export default class Survey extends Component {
    constructor() {
        super();
        this.state = {
            isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            isInternal:false,
            
        }
    }

    alertSubmitForSuccess = () => {
        debugger;
        //return (<RequestSurvey isArabic={this.state.isArabic} y={this.props.y} />);
         confirmAlert({
             title:this.state.isArabic? 'الاستبيان':'Survey',
             message:this.state.isArabic? 'تم حفظ الاستبيان بنجاح':'Survey Submited Sucessfully',
             buttons: [
                 {
                     label: i18n[this.getLocale()].ok,
                     onClick: () =>  this.props.history.push("/landing") 
                 }
             ],
             closeOnEscape: false,
             closeOnClickOutside: false,
             childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
         });
         setTimeout(() => {
             document.getElementById('alertOkButton').focus()
         }, 100);
    };
 logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }
    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }
     componentDidMount=()=>
    {
        let loginData = Config.getLocalStorageLoginData();
       
      
        this.setState({
            isInternal:loginData.internalcontact
        })
    }
    
    render() 
    {
        
        const sideBarData = Config.getServiceSideBar('Survey', this.state.isArabic, this, 0);
        return(

            <MDBContainer fluid>
            <LoadingOverlay
           active={this.state.isLoading}
           spinner={true}>
           <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
           <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
               <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                   <MDBRow>
                       <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                           <SubHeaderStrip Substitutionplan={false} enableCreate={false} goBack={() => { this.props.history.replace('/') }}  create={false}  isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].Survey.Subject} />

                           </MDBCol>
                           </MDBRow>
                           <MDBRow>                           
                           <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                           <InternalSurvey alertSubmitForSuccess={this.alertSubmitForSuccess}/>
{/* {this.state.isInternal? <InternalSurvey/>:<ExternalSurvey/>} */}
</MDBCol>
</MDBRow></MDBCol>   <SideBar
                            height={'140vh'}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic} /></MDBRow></LoadingOverlay></MDBContainer>
        );
    
    

    }

}