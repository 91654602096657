import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody } from "mdbreact";
import './../../css/style.css';
import i18n from './../../i18n';
import StepsComponent from "./../StepsComponent";
import Config from '../../utils/Config';
import DownloadComponent from "./../DownloadComponent";

export default class StepThree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            requestId: '',
            statusid: '',
            requestType: Config.getBonusRequestTypeId(),
            presentationforEntityAcheivementsErr: '',
            presentationforEntityAcheivements: this.props.sendData.presentationforEntityAcheivements,
            enterTheYear1: this.props.sendData.enterTheYear1,
            totalEmployeesOfEntityDetails: this.props.sendData.totalEmployeesOfEntityDetails,
            percentageOfEmariatisation: this.props.sendData.percentageOfEmariatisation,
            isSubmit: false,
            presentationforEntityAcheivementsObj: null,
            balancesScoreCardFileObj: null,
            taxSummaryFileObj: null,
            financialStatementFileObj: null,
            approvedGradesAndSalariesFileObj: null,
            resultsOfEvaluationGeneralPerformanceFileObj: null,
            uploadTheContractsFileObj: null,
            budgetRelatedDocumentFileObj: null,
            //entityLogo: this.props.sendData.entityLogo,
            //entityLogoUrl: '',
            approvedGradesAndSalaries: this.props.sendData.approvedGradesAndSalaries,
            approvedGradesAndSalariesUrl: '',
            balancedScoreCard: this.props.sendData.balancedScoreCard,
            balancedScoreCardUrl: '',
            financialStatement: this.props.sendData.financialStatement,
            financialStatementUrl: '',
            taxSummary: this.props.sendData.taxSummary,
            resultsOfEvaluationGeneralPerformance: this.props.sendData.resultsOfEvaluationGeneralPerformance,
            resultsOfEvaluationGeneralPerformanceShortName: this.props.sendData.resultsOfEvaluationGeneralPerformanceShortName,
            resultsOfEvaluationGeneralPerformanceUrl: '',
            uploadTheContracts: this.props.sendData.uploadTheContracts,
            uploadTheContractsShortName: this.props.sendData.uploadTheContractsShortName,
            uploadTheContractsUrl: '',
            uploadTheExcelAfterFill: this.props.sendData.uploadTheExcelAfterFill,
            uploadTheExcelAfterFillUrl: '',
            budgetRelatedDocument: this.props.sendData.budgetRelatedDocument,
            budgetRelatedDocumentUrl: '',
            presentationforEntityAcheivementsDocument: this.props.sendData.presentationforEntityAcheivementsDocument,
            presentationforEntityAcheivementsUrl: '',
            haveBudget: this.props.sendData.haveBudget,
            haveBudget2: this.props.sendData.haveBudget2,
            //entityLogoErr: '',
            approvedGradesAndSalariesErr: '',
            balancedScoreCardErr: '',
            financialStatementErr: '',
            taxSummaryErr: '',
            resultsOfEvaluationGeneralPerformanceErr: '',
            uploadTheContractsErr: '',
            uploadTheExcelAfterFillErr: '',
            budgetRelatedDocumentErr: '',
            editAttachmentArray: [],
            aprrovedGradesAndSalariesArray: [],
            //entityLogoArray: [],
            balancedScoreCardArray: [],
            excelAfterFillArray: [],
            generalPerformanceArray: [],
            financialStatementArray: [],
            privateContractArray: [],
            budgetReedDocArray: [],
            presentationforEntityAcheivementsArr: [],
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
                haveBudget: editObj.haveBudget === "1" ? "Yes" : "No",
                haveBudget2: editObj.haveBudget2 === "1" ? "Yes" : "No",
            }, () => {
                let tempAprrovedGradesAndSalariesArray = [];
                let tempEntityLogoArray = [];
                let tempBalancedScoreCardArray = [];
                let tempExcelAfterFillArray = [];
                let tempGeneralPerformanceArray = [];
                let tempFinancialStatementArray = [];
                let tempPrivateContractArray = [];
                let tempBudgetReletedDocArray = [];
                let temppresentationforEntityAcheivementsArr = [];

                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                   
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getBonusApprovedGradesAndSalarieId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempAprrovedGradesAndSalariesArray.push(temp);

                                this.setState({
                                    //approvedGradesAndSalaries: item.filename,
                                    approvedGradesAndSalariesUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusEntityLogoId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEntityLogoArray.push(temp);

                                this.setState({
                                    //entityLogo: item.filename,
                                    entityLogoUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusBalancedScoreCardId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempBalancedScoreCardArray.push(temp);

                                this.setState({
                                    //balancedScoreCard: item.filename,
                                    balancedScoreCardUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusUploadExcelAfterFillId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempExcelAfterFillArray.push(temp);

                                this.setState({
                                    //uploadTheExcelAfterFill: item.filename,
                                    uploadTheExcelAfterFillUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusResultForEvalutionForGeneralPerformanceId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempGeneralPerformanceArray.push(temp);

                                this.setState({
                                    //resultsOfEvaluationGeneralPerformance: item.filename,
                                    resultsOfEvaluationGeneralPerformanceUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusFinacialStatementId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempFinancialStatementArray.push(temp);

                                this.setState({
                                    //financialStatement: item.filename,
                                    financialStatementUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusForPrivateContractId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPrivateContractArray.push(temp);

                                this.setState({
                                    //uploadTheContracts: item.filename,
                                    uploadTheContractsUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getBonusBudgetReletedDocumentId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempBudgetReletedDocArray.push(temp);

                                this.setState({
                                    //budgetRelatedDocument: item.filename,
                                    budgetRelatedDocumentUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPresentationforEntityAcheivementsId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                temppresentationforEntityAcheivementsArr.push(temp);

                                this.setState({
                                    //presentationforEntityAcheivements: item.filename,
                                    presentationforEntityAcheivementsUrl: item.aliasfilename
                                })
                            }
                        })

                        this.setState({
                            aprrovedGradesAndSalariesArray: tempAprrovedGradesAndSalariesArray,
                            entityLogoArray: tempEntityLogoArray,
                            balancedScoreCardArray: tempBalancedScoreCardArray,
                            excelAfterFillArray: tempExcelAfterFillArray,
                            generalPerformanceArray: tempGeneralPerformanceArray,
                            financialStatementArray: tempFinancialStatementArray,
                            privateContractArray: tempPrivateContractArray,
                            budgetReedDocArray: tempBudgetReletedDocArray,
                            presentationforEntityAcheivementsArr: temppresentationforEntityAcheivementsArr
                        })
                    }
                }

            });
        }
    }

    checkValidations = () => {
        let //entityLogoErr = '',
            approvedGradesAndSalariesErr = '',
            balancedScoreCardErr = '',
            financialStatementErr = '',
            resultsOfEvaluationGeneralPerformanceErr = '',
            uploadTheExcelAfterFillErr = '',
            presentationforEntityAcheivementsErr = '',
            budgetRelatedDocumentErr = '',
            uploadTheContractsErr = '';

        // if (this.state.entityLogo === '') {
        //     entityLogoErr = 'thisFieldIsRequired';
        // }

        if (this.state.aprrovedGradesAndSalariesArray && this.state.aprrovedGradesAndSalariesArray.length > 0) {
            approvedGradesAndSalariesErr = "";
        } else {
            if (!this.state.approvedGradesAndSalaries) {
                approvedGradesAndSalariesErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.balancedScoreCardArray && this.state.balancedScoreCardArray.length > 0) {
            balancedScoreCardErr = "";
        } else {
            if (!this.state.balancedScoreCard) {
                balancedScoreCardErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.financialStatementArray && this.state.financialStatementArray.length > 0) {
            financialStatementErr = "";
        } else {

            if (!this.state.financialStatement) {
                financialStatementErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.generalPerformanceArray && this.state.generalPerformanceArray.length > 0) {
            resultsOfEvaluationGeneralPerformanceErr = "";
        } else {
            //if (this.state.resultsOfEvaluationGeneralPerformance === '') {
            if (!this.state.resultsOfEvaluationGeneralPerformance) {
                resultsOfEvaluationGeneralPerformanceErr = 'thisFieldIsRequired';
            }
        }

       

        if (this.state.excelAfterFillArray && this.state.excelAfterFillArray.length > 0) {
            uploadTheExcelAfterFillErr = "";
        } else {
            if (!this.state.uploadTheExcelAfterFill) {
                uploadTheExcelAfterFillErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.presentationforEntityAcheivementsArr && this.state.presentationforEntityAcheivementsArr.length > 0) {
            presentationforEntityAcheivementsErr = "";
        } else {
            if (!this.state.presentationforEntityAcheivementsDocument) {
                presentationforEntityAcheivementsErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.haveBudget === 'Yes') {
            if (!this.state.budgetRelatedDocument) {
                budgetRelatedDocumentErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.haveBudget2 === 'Yes') {
            if (!this.state.uploadTheContracts) {
                uploadTheContractsErr = 'thisFieldIsRequired';
            }
        }

        this.setState({
            //entityLogoErr: entityLogoErr,
            approvedGradesAndSalariesErr: approvedGradesAndSalariesErr,
            balancedScoreCardErr: balancedScoreCardErr,
            financialStatementErr: financialStatementErr,
            resultsOfEvaluationGeneralPerformanceErr: resultsOfEvaluationGeneralPerformanceErr,
            uploadTheContractsErr: uploadTheContractsErr,
            budgetRelatedDocumentErr: budgetRelatedDocumentErr,
            uploadTheExcelAfterFillErr: uploadTheExcelAfterFillErr,
            presentationforEntityAcheivementsErr: presentationforEntityAcheivementsErr
        }, () => {
           
            if (
                !approvedGradesAndSalariesErr &&
                !balancedScoreCardErr &&
                !financialStatementErr &&
                !resultsOfEvaluationGeneralPerformanceErr &&
                !budgetRelatedDocumentErr &&
                !uploadTheExcelAfterFillErr &&
                !presentationforEntityAcheivementsErr
            ) {

                let formValidated = false;

                // if (this.state.haveBudget === 'Yes') {
                //     if (
                //         //this.state.entityLogo !== '' &&
                //         this.state.presentationforEntityAcheivementsDocument !== '' && this.state.approvedGradesAndSalaries !== '' &&
                //         this.state.balancedScoreCard !== '' &&
                //         this.state.financialStatement !== '' && 
                //         this.state.resultsOfEvaluationGeneralPerformance !== null && this.state.uploadTheExcelAfterFill !== '' &&
                //         this.state.budgetRelatedDocument !== '') {
                //         formValidated = true;
                //     } else {
                //         formValidated = false;
                //     }

                // }
                // else {
                //     if (
                //         //this.state.entityLogo !== '' &&
                //         this.state.approvedGradesAndSalaries !== '' && this.state.presentationforEntityAcheivementsDocument !== '' && this.state.balancedScoreCard !== '' &&
                //         this.state.financialStatement !== '' && 
                //         this.state.resultsOfEvaluationGeneralPerformance !== null && this.state.uploadTheExcelAfterFill !== '') {
                //         formValidated = true;
                //     } else {
                //         formValidated = false;
                //     }
                // }
                // if (this.state.haveBudget2 === 'Yes') {
                //     if (
                //         //this.state.entityLogo !== '' &&
                //         this.state.presentationforEntityAcheivementsDocument !== '' && this.state.approvedGradesAndSalaries !== '' &&
                //         this.state.balancedScoreCard !== '' &&
                //         this.state.financialStatement !== '' && this.state.resultsOfEvaluationGeneralPerformance !== null && this.state.uploadTheContracts !== null &&
                //         this.state.uploadTheExcelAfterFill !== '' &&
                //         this.state.uploadTheContractsErr === '') {
                //         formValidated = true;
                //     } else {
                //         formValidated = false;
                //     }

                // }
                // else {
                //     if (
                //         //this.state.entityLogo !== '' &&
                //         this.state.presentationforEntityAcheivementsDocument !== '' && this.state.approvedGradesAndSalaries !== '' &&
                //         this.state.balancedScoreCard !== '' &&
                //         this.state.financialStatement !== '' && this.state.resultsOfEvaluationGeneralPerformance !== null && this.state.uploadTheExcelAfterFill !== ''
                //     ) {
                //         formValidated = true;
                //     } else {
                //         formValidated = false;
                //     }
                // }

                if (this.state.haveBudget2 === 'Yes') {
                    if (!uploadTheContractsErr) {
                        formValidated = true;
                    } else {
                        formValidated = false;
                    }
                } else {
                    formValidated = true;
                }

                if (formValidated) {
                    this.props.getData.stepThreeData({
                        isEdit: this.state.isEdit,
                        requestId: this.state.requestId,
                        //entityLogo: this.state.entityLogo,
                        approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
                        balancedScoreCard: this.state.balancedScoreCard,
                        financialStatement: this.state.financialStatement,
                        resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
                        resultsOfEvaluationGeneralPerformanceShortName: this.state.resultsOfEvaluationGeneralPerformanceShortName,
                        uploadTheContracts: this.state.uploadTheContracts,
                        uploadTheContractsShortName: this.state.uploadTheContractsShortName,
                        uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
                        budgetRelatedDocument: this.state.budgetRelatedDocument,
                        presentationforEntityAcheivementsDocument: this.state.presentationforEntityAcheivementsDocument,
                        haveBudget: this.props.sendData.haveBudget,
                        haveBudget2: this.props.sendData.haveBudget2,
                    })
                }
            }
        });
    }

    submitHandler = (event) => {
        // Config.setIsEditFlag(false);
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
        // if (!this.state.isEdit || (this.state.statusid === Config.getDraftId())) {
        //     this.checkValidations();
        // } else {
        //     this.props.getData.stepThreeData({
        //         isEdit: this.state.isEdit,
        //         requestId: this.state.requestId,
        //         //entityLogo: this.state.entityLogo,
        //         // approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
        //         // balancedScoreCard: this.state.balancedScoreCard,
        //         // financialStatement: this.state.financialStatement,
        //         // taxSummary: this.state.taxSummary,
        //         // resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
        //         // uploadTheContracts: this.state.uploadTheContracts,
        //         // uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
        //         // budgetRelatedDocument: this.state.budgetRelatedDocument,
        //         // presentationforEntityAcheivements: this.state.presentationforEntityAcheivements,
        //         approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
        //         balancedScoreCard: this.state.balancedScoreCard,
        //         financialStatement: this.state.financialStatement,
        //         resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
        //         uploadTheContracts: this.state.uploadTheContracts,
        //         uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
        //         budgetRelatedDocument: this.state.budgetRelatedDocument,
        //         presentationforEntityAcheivementsDocument: this.state.presentationforEntityAcheivementsDocument,
        //         haveBudget: this.props.sendData.haveBudget,
        //         haveBudget2: this.props.sendData.haveBudget2,
        //     })
        // }
    };

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
            balancedScoreCard: this.state.balancedScoreCard,
            financialStatement: this.state.financialStatement,
            taxSummary: this.state.taxSummary,
            resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
            resultsOfEvaluationGeneralPerformanceShortName: this.state.resultsOfEvaluationGeneralPerformanceShortName,
            uploadTheContracts: this.state.uploadTheContracts,
            uploadTheContractsShortName: this.state.uploadTheContractsShortName,
            uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
            budgetRelatedDocument: this.state.budgetRelatedDocument,
            presentationforEntityAcheivementsDocument: this.state.presentationforEntityAcheivementsDocument,
            haveBudget: this.props.sendData.haveBudget,
            haveBudget2: this.props.sendData.haveBudget2
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    goToPrevious = () => {
        this.props.getPrev.stepThreeDataPrev({
            entityLogo: this.state.entityLogo,
            approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
            balancedScoreCard: this.state.balancedScoreCard,
            financialStatement: this.state.financialStatement,
            taxSummary: this.state.taxSummary,
            resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
            resultsOfEvaluationGeneralPerformanceShortName: this.state.resultsOfEvaluationGeneralPerformanceShortName,
            uploadTheContracts: this.state.uploadTheContracts,
            uploadTheContractsShortName: this.state.uploadTheContractsShortName,
            uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
            budgetRelatedDocument: this.state.budgetRelatedDocument,
            // haveBudget: this.state.haveBudget,
            // haveBudget2: this.state.haveBudget2,
            haveBudget: this.props.sendData.haveBudget,
            haveBudget2: this.props.sendData.haveBudget2,
            presentationforEntityAcheivementsDocument: this.state.presentationforEntityAcheivementsDocument,
        });
    }

    balancedScoreCardOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    balancedScoreCard: '',
                    balancedScoreCardErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        balancedScoreCard: e.target.files[0].name,
                        balancedScoreCardErr: ''
                    }, () => {
                        let reader = new FileReader();
                        let tempFile = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                balancesScoreCardFileObj: reader.result
                            }, () => {
                                Config.setBase64BonusRequest_BalanceScoreCard(this.state.balancesScoreCardFileObj);
                            });
                        }
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ balancedScoreCard: '', balancedScoreCardErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ balancedScoreCard: '', balancedScoreCardErr: 'thisFieldIsRequired' });
        }
    }

    financialStatementOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    financialStatement: '',
                    financialStatementErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        financialStatement: e.target.files[0].name, financialStatementErr: ''
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({
                                financialStatementFileObj: reader.result,
                            }, () => {
                                Config.setBase64BonusRequest_FinancialStatement(this.state.financialStatementFileObj);
                            });

                        }
                        reader.readAsDataURL(tempFile);

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        financialStatement: '',
                        financialStatementErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                financialStatement: '',
                financialStatementErr: 'thisFieldIsRequired'
            });
        }
    }

    presentationforEntityAcheivementsOnChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    presentationforEntityAcheivementsDocument: '',
                    presentationforEntityAcheivementsErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        presentationforEntityAcheivementsDocument: e.target.files[0].name, presentationforEntityAcheivementsErr: ''
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({
                                presentationforEntityAcheivementsObj: reader.result,
                            }, () => {
                                Config.setBase64BonusRequest_PresentationforEntityAcheivements(this.state.presentationforEntityAcheivementsObj);
                            });

                        }
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ presentationforEntityAcheivementsDocument: '', presentationforEntityAcheivementsErr: 'invalidFileSize' });
                }
            }

        } else {
            e.target.value = '';
            this.setState({ presentationforEntityAcheivementsDocument: '', presentationforEntityAcheivementsErr: 'thisFieldIsRequired' });
        }
    }

    taxSummaryOnChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    taxSummary: '',
                    taxSummaryErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        taxSummary: e.target.files[0].name,
                        taxSummaryErr: ''
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                taxSummaryFileObj: reader.result,
                            }, () => {
                                Config.setBase64BonusRequest_TaxSummary(this.state.taxSummaryFileObj);
                            });
                        }
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ taxSummary: '', taxSummaryErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ taxSummary: '', taxSummaryErr: 'thisFieldIsRequired' });
        }
    }

    uploadTheExcelAfterFillOnChange = e => {

        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ uploadTheExcelAfterFill: '', uploadTheExcelAfterFillErr: 'invalidFileType' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        uploadTheExcelAfterFill: e.target.files[0].name,
                        uploadTheExcelAfterFillErr: ''
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({
                                uploadExcelAfterFillFileObj: reader.result
                            }, () => {
                                Config.setBase64BonusRequest_uploadExcelAfterFill(this.state.uploadExcelAfterFillFileObj);
                            });
                        }
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ uploadTheExcelAfterFill: '', uploadTheExcelAfterFillErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ uploadTheExcelAfterFill: '', uploadTheExcelAfterFillErr: 'thisFieldIsRequired' });
        }
    };

    approvedGradesAndSalariesOnChange = e => {
        if (e.target.files.length > 0) {

            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    approvedGradesAndSalaries: '',
                    approvedGradesAndSalariesErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        approvedGradesAndSalaries: e.target.files[0].name,
                        approvedGradesAndSalariesErr: ''
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({
                                approvedGradesAndSalariesFileObj: reader.result,
                            }, () => {
                                Config.setBase64rBonusRequest_ForApprovedGrades(this.state.approvedGradesAndSalariesFileObj);
                            });
                        }
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ approvedGradesAndSalaries: '', approvedGradesAndSalariesErr: 'invalidFileSize' });
                }
            }

        } else {
            e.target.value = '';
            this.setState({
                approvedGradesAndSalaries: '',
                approvedGradesAndSalariesErr: 'thisFieldIsRequired'
            });
        }
    }

    resultsOfEvaluationGeneralPerformanceOnChange = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                let fileNames = "";
                let fileSupportArray = [];

                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            resultsOfEvaluationGeneralPerformance: '',
                            resultsOfEvaluationGeneralPerformanceShortName: '',
                            resultsOfEvaluationGeneralPerformanceErr: 'invalidFileType'
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let filesObj = e.target.files[0];
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let resultsOfEvaluationGeneralPerformanceShortName = fileNames.substring(0, 50);

                            this.setState({
                                resultsOfEvaluationGeneralPerformance: fileNames,
                                resultsOfEvaluationGeneralPerformanceShortName: resultsOfEvaluationGeneralPerformanceShortName,
                                resultsOfEvaluationGeneralPerformanceErr: ''
                            }, () => {

                                let tempFile = filesObj;
                                let reader = new FileReader();

                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({
                                        resultsOfEvaluationGeneralPerformanceFileObj: fileSupportArray,
                                    }, () => {
                                        Config.setBase64BonusRequest_resultsOfEvaluationGeneralPerformance(this.state.resultsOfEvaluationGeneralPerformanceFileObj);
                                    });
                                }
                                reader.readAsDataURL(tempFile);
                            });

                        }
                        else {
                            e.target.value = '';
                            this.setState({ 
                                resultsOfEvaluationGeneralPerformance: '', 
                                resultsOfEvaluationGeneralPerformanceShortName: '', 
                                resultsOfEvaluationGeneralPerformanceErr: 'invalidFileSize' 
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({ 
                    resultsOfEvaluationGeneralPerformance: '',
                    resultsOfEvaluationGeneralPerformanceShortName: '',
                    resultsOfEvaluationGeneralPerformanceErr: 'fileLimitErr' 
                });
            }
        } else {
            e.target.value = '';
            this.setState({ 
                resultsOfEvaluationGeneralPerformance: '',
                resultsOfEvaluationGeneralPerformanceShortName: '',
                resultsOfEvaluationGeneralPerformanceErr: 'thisFieldIsRequired' 
            });
        }
    }

    uploadTheContractsOnChange = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                let fileNames = "";
                let fileSupportArray = [];

                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            uploadTheContracts: '',
                            uploadTheContractsShortName: '',
                            uploadTheContractsErr: 'invalidFileType'
                        });
                        break;
                    } else {

                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let filesObj = e.target.files[0];
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let uploadTheContractsShortName = fileNames.substring(0, 50);

                            this.setState({
                                uploadTheContracts: fileNames,
                                uploadTheContractsShortName: uploadTheContractsShortName,
                                uploadTheContractsErr: ''
                            }, () => {

                                let tempFile = filesObj;
                                let reader = new FileReader();

                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({
                                        uploadTheContractsFileObj: fileSupportArray,
                                    }, () => {
                                        Config.setBase64BonusRequest_UploadContrACT(this.state.uploadTheContractsFileObj);
                                    });
                                }
                                reader.readAsDataURL(tempFile);
                            });

                        }
                        else {
                            e.target.value = '';
                            this.setState({ 
                                uploadTheContracts: '',
                                uploadTheContractsShortName: '',
                                uploadTheContractsErr: 'invalidFileSize' 
                            });
                            break;
                        }
                    }
                }
            }

            else {
                e.target.value = '';
                this.setState({ 
                    uploadTheContracts: '',
                    uploadTheContractsShortName: '',
                    uploadTheContractsErr: 'fileLimitErr' 
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({ uploadTheContracts: null, uploadTheContractsErr: 'thisFieldIsRequired' });
        }
    }

    budgetRelatedDocumentOnChange = (e) => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    budgetRelatedDocument: '',
                    budgetRelatedDocumentErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        budgetRelatedDocument: e.target.files[0].name,
                        budgetRelatedDocumentErr: ''
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                budgetRelatedDocumentFileObj: reader.result,
                            }, () => {
                                Config.setBase64rBonusRequest_ForBudgeDoc(this.state.budgetRelatedDocumentFileObj);
                            });
                        }
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ budgetRelatedDocument: '', budgetRelatedDocumentErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ budgetRelatedDocument: '', budgetRelatedDocumentErr: 'thisFieldIsRequired' });
        }
    }

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
            balancedScoreCard: this.state.balancedScoreCard,
            financialStatement: this.state.financialStatement,
            taxSummary: this.state.taxSummary,
            resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
            resultsOfEvaluationGeneralPerformanceShortName: this.state.resultsOfEvaluationGeneralPerformanceShortName,
            uploadTheContracts: this.state.uploadTheContracts,
            uploadTheContractsShortName: this.state.uploadTheContractsShortName,
            uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
            budgetRelatedDocument: this.state.budgetRelatedDocument,
            presentationforEntityAcheivementsDocument: this.state.presentationforEntityAcheivementsDocument,
            haveBudget: this.props.sendData.haveBudget,
            haveBudget2: this.props.sendData.haveBudget2
        });
    }

    render() {

        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2", "Step3"]}
                    currentIndex={3}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow className="paddingTopForm" style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formFinancialStatement" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}><span className="requireStar">*</span>{i18n[this.getLocale()].financialStatement}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="financialStatement"
                                        id="financialStatement"
                                        className="input-default-js"
                                        onChange={(event) => this.financialStatementOnChange(event)} required />

                                    <label htmlFor="financialStatement" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                    <label htmlFor="financialStatement" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.financialStatement}</span></label>
                                </div>
                                {
                                    this.state.financialStatementErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.financialStatementErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.financialStatementErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.financialStatementArray &&
                                        this.state.financialStatementArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.financialStatementArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formApprovedGradesAndSalaries" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}><span className="requireStar">*</span>{i18n[this.getLocale()].approvedGradesAndSalaries}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="approvedGradesAndSalaries"
                                        type="file"
                                        name="approvedGradesAndSalaries"
                                        onChange={(event) => this.approvedGradesAndSalariesOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="approvedGradesAndSalaries" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                    <label htmlFor="approvedGradesAndSalaries" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.approvedGradesAndSalaries}</span></label>
                                </div>
                                {
                                    this.state.approvedGradesAndSalariesErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.approvedGradesAndSalariesErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.approvedGradesAndSalariesErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.aprrovedGradesAndSalariesArray &&
                                        this.state.aprrovedGradesAndSalariesArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.aprrovedGradesAndSalariesArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <br />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formUploadTheExcelAfterFill" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}><span className="requireStar">*</span>{i18n[this.getLocale()].uploadTheExcelAfterFill}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="uploadTheExcelAfterFill"
                                        id="uploadTheExcelAfterFill"
                                        className="input-default-js"
                                        onChange={(event) => this.uploadTheExcelAfterFillOnChange(event)} required />
                                    <label htmlFor="uploadTheExcelAfterFill" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                    <label htmlFor="uploadTheExcelAfterFill" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.uploadTheExcelAfterFill}</span></label>
                                </div>
                                {
                                    this.state.uploadTheExcelAfterFillErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.uploadTheExcelAfterFillErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.uploadTheExcelAfterFillErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.excelAfterFillArray &&
                                        this.state.excelAfterFillArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.excelAfterFillArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formBalancedScoreCard" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}><span className="requireStar">*</span>{i18n[this.getLocale()].balancedScoreCard}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input type="file" name="balancedScoreCard" id="balancedScoreCard" className="input-default-js" onChange={(event) => this.balancedScoreCardOnChange(event)} required />

                                    <label htmlFor="balancedScoreCard" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>

                                    <label htmlFor="balancedScoreCard" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.balancedScoreCard}</span></label>

                                </div>
                                {
                                    this.state.balancedScoreCardErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.balancedScoreCardErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.balancedScoreCardErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.balancedScoreCardArray &&
                                        this.state.balancedScoreCardArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.balancedScoreCardArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <br />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>


                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formFinancialStatement" style={{ direction: !this.props.isArabic ? 'ltr' : 'rtl' }}>
                                {i18n[this.getLocale()].presentationforEntityAcheivements}
                                <span className="requireStar">*</span>
                            </label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="presentationforEntity"
                                        id="presentationforEntity"
                                        className="input-default-js"
                                        onChange={(event) => this.presentationforEntityAcheivementsOnChange(event)} required />

                                    <label htmlFor="presentationforEntity" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>

                                    <label htmlFor="presentationforEntity" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                        <span>{this.state.presentationforEntityAcheivementsDocument}
                                        </span></label>
                                </div>
                                {
                                    this.state.presentationforEntityAcheivementsErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.presentationforEntityAcheivementsErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.presentationforEntityAcheivementsErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.presentationforEntityAcheivementsArr &&
                                        this.state.presentationforEntityAcheivementsArr.length > 0)
                                        ? <DownloadComponent filesObj={this.state.presentationforEntityAcheivementsArr} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formResultsOfEvaluationGeneralPerformance" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].resultsOfEvaluationGeneralPerformance}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input type="file"
                                        name="resultsOfEvaluationGeneralPerformance" id="resultsOfEvaluationGeneralPerformance"
                                        className="input-default-js"
                                        onChange={(event) => this.resultsOfEvaluationGeneralPerformanceOnChange(event)}
                                        multiple
                                        required
                                    />
                                    <label htmlFor="resultsOfEvaluationGeneralPerformance" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>

                                    <label htmlFor="resultsOfEvaluationGeneralPerformance" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                        {/* <span>{this.state.resultsOfEvaluationGeneralPerformance}</span> */}

                                        <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.resultsOfEvaluationGeneralPerformanceShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.resultsOfEvaluationGeneralPerformanceShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.resultsOfEvaluationGeneralPerformance}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.resultsOfEvaluationGeneralPerformanceErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.resultsOfEvaluationGeneralPerformanceErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.resultsOfEvaluationGeneralPerformanceErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.resultsOfEvaluationGeneralPerformanceErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                                </p>
                                {
                                    (this.state.generalPerformanceArray &&
                                        this.state.generalPerformanceArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.generalPerformanceArray} />
                                        : <span></span>
                                }

                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <br />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formRelatedDocument" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>{i18n[this.getLocale()].budgetRelatedDocument}<span className="requireStar">*</span></label>
                            <div className="input-group" style={{ textAlign: this.props.isArabic ? 'left' : 'left' }}>
                                <div className="custom-file-inp">
                                    <input
                                        id="budgetRelatedDocument"
                                        type="file"
                                        name="budgetRelatedDocument"
                                        onChange={(event) => this.budgetRelatedDocumentOnChange(event)}
                                        required />

                                    <label htmlFor="budgetRelatedDocument" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                    <label htmlFor="budgetRelatedDocument" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.budgetRelatedDocument}</span></label>
                                </div>
                                {
                                    this.state.budgetRelatedDocumentErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.budgetRelatedDocumentErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.budgetRelatedDocumentErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.budgetReedDocArray &&
                                        this.state.budgetReedDocArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.budgetReedDocArray} />
                                        : <span></span>
                                }

                            </div>
                        </div>
                    </MDBCol>

                    {
                        this.props.sendData.haveBudget2 === 'Yes' ?

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label htmlFor="formUploadTheContracts" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}><span className="requireStar">*</span>{i18n[this.getLocale()].uploadTheContracts}</label>
                                    <div className="input-group">
                                        <div className="custom-file-inp">
                                            <input
                                                type="file"
                                                name="uploadTheContracts"
                                                id="uploadTheContracts"
                                                className="input-default-js"
                                                onChange={(event) => this.uploadTheContractsOnChange(event)} required
                                                multiple
                                            />

                                            <label htmlFor="uploadTheContracts" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>

                                            <label htmlFor="uploadTheContracts" className="fileName" style={{ textAlign: 'left' }} style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                                {/* <span>{this.state.uploadTheContracts}</span> */}

                                                <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.uploadTheContractsShortName}</span>
                                                <span className="attachmentShortName">
                                                    {
                                                        this.state.uploadTheContractsShortName ?
                                                            <MDBPopover
                                                                placement="bottom"
                                                                popover
                                                                clickable
                                                                id="popper12"
                                                                component="label"
                                                                popoverBody={i18n[this.getLocale()].viewMore}
                                                                size="sm"
                                                            >
                                                                <MDBPopoverBody
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-around"
                                                                    }}>
                                                                    <span>{this.state.uploadTheContracts}</span>
                                                                </MDBPopoverBody>
                                                            </MDBPopover>
                                                            :
                                                            ''
                                                    }
                                                </span>
                                            </label>
                                        </div>
                                        {
                                            this.state.uploadTheContractsErr == 'thisFieldIsRequired' ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            this.state.uploadTheContractsErr == 'invalidFileType' ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.getLocale()].invalidFileType}
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            this.state.uploadTheContractsErr === 'invalidFileSize' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                    {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            this.state.uploadTheContractsErr === 'fileLimitErr' ?
                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                    {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                                </div>
                                                :
                                                null
                                        }
                                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                        </p>
                                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                                        </p>
                                        {
                                            (this.state.privateContractArray &&
                                                this.state.privateContractArray.length > 0)
                                                ? <DownloadComponent filesObj={this.state.privateContractArray} />
                                                : <span></span>
                                        }
                                    </div>
                                </div>
                            </MDBCol>
                            :
                            null
                    }

                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.getLocale()].previous}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }
}