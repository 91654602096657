import React, { Component } from 'react';
import { MDBRow, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBCol } from "mdbreact";
import { getNotificationPayload } from './../utils/Payload';
import i18n from './../i18n';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            notificationBadgeCount: 0
        }
    }

    componentDidMount = () => {
        this.callNotificationList();
    }

    callNotificationList = () => {
        let payload = getNotificationPayload();
        let endPoint = 'GetNotificationList'
        let url = Config.getUrl(endPoint);
        ApiRequest.sendRequestForPost(url, payload, res => {
            if (res.Status) {
             
                let temp = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let obj = {
                            title: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            read: false
                        };
                        temp.push(obj);
                    }
                } else {
                    let temp = [{
                        title: 'No Notifications',
                        read: false
                    }]
                    this.setState({
                        notificationBadgeCount: 0,
                        notifications: temp
                    })
                    return;
                }
                this.setState({
                    notifications: temp
                }, () => {
                    let temp = 0;
                    if (this.state.notifications.length > 0) {
                        for (let i = 0; i < this.state.notifications.length; i++) {
                            if (!this.state.notifications[i].read) {
                                ++temp;
                            }
                        }
                        this.setState({
                            notificationBadgeCount: temp
                        });
                    } else {
                        this.setState({
                            notificationBadgeCount: temp
                        });
                    }
                });
            } else {
             
            }
        })
    }

    render() {
        return (
            <MDBDropdown size="3" sm="3" md="3" className="mx-2">

                <MDBDropdownToggle className="flagclass" style={{ display: 'flex', flexDirection: this.props.isArabic === 'ar' ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center' }} caret gradient="var(--primaryColor)">
                    {this.state.notificationBadgeCount ? <div style={{ backgroundColor: '#9AD4A1', borderRadius: 50, height: 20, width: 20, boxShadow: '2px 2px rgba(0, 0, 255, .2)', marginRight: 2 }}>{this.state.notificationBadgeCount}</div> : null}
                    <img src={require("./../assets/images/notification_white.png")} alt="" />
                </MDBDropdownToggle>

                <MDBDropdownMenu style={{ padding: 3, maxHeight: '40vh', overflowY: 'auto' }} basic>
                    {
                        this.state.notifications ? this.state.notifications.map((item, i) =>
                            <span key={i}>
                                {!i
                                    ?
                                    // boxShadow: '2px 2px rgba(0, 0, 255, .2)',
                                    //  padding: '2%' 
                                    <div style={{ backgroundColor: 'white', height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #9AD4A1' }}>
                                        {i18n[this.props.isArabic === 'ar' ? 'ar' : 'en'].notifications}
                                        <span style={{ marginLeft: 5, marginRight: 5, backgroundColor: '#9AD4A1', borderRadius: 50, height: 28, width: 28, textAlign: 'center', color: 'white', marginRight: 2, padding: '2%' }}>
                                            {this.state.notificationBadgeCount}
                                        </span>
                                    </div>
                                    :
                                    null
                                }

                                <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', justifyContent: 'space-between', padding: '1%', backgroundColor: item.read ? 'lightgrey' : 'white', height: 40, boxShadow: item.read ? '2px 2px #6A96B0' : '2px 2px #9AD4A1' }}
                                    onClick={() => {
                                        let temp = item.read ? 'already read' : 'unread';
                                        //alert('You Clicked ' + item.title + ' which is ' + temp);
                                    }}>
                                    <span style={{ padding: '2px 10px' }}>
                                        {item.title}
                                    </span>
                                    <span style={{ padding: '2px 10px' }}>
                                        {item.statusreason}
                                    </span>
                                    {item.read ? null : <span style={{ height: 6, width: 6, dispaly: 'flex', alignSelf: 'center', justifyContent: 'flex-end', borderRadius: 6, backgroundColor: '#9AD4A1', marginRight: '10%', boxShadow: '2px 2px rgba(0, 0, 255, .2)' }}></span>}
                                </MDBDropdownItem>
                                <MDBDropdownItem divider />
                            </span>
                        )
                            :
                            null
                    }
                </MDBDropdownMenu>

            </MDBDropdown>

        );
    }
}