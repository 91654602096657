import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import StepsComponent from '../StepsComponent';
import './../../css/style.css';
import Config from '../../utils/Config';
import i18n from '../../i18n';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CKEditor from "react-ckeditor-component";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');

export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            requestId: '',
            statusid: '',
            isArabic: true,
            employeeName: this.props.sendData.employeeName,
            //nationality: this.props.sendData.nationality,
            nationality: this.props.sendData.nationality,
            age: this.props.sendData.age,
            designationDate: this.props.sendData.designationDate,
            currentBasicSalary: this.props.sendData.currentBasicSalary,
            proposedBasicSalary: this.props.sendData.proposedBasicSalary,
            percentIncRequiredBasicSalary: this.props.sendData.percentIncRequiredBasicSalary,
            totalExperience: this.props.sendData.totalExperience,
            academicQualification: this.props.sendData.academicQualification,
            experienceAfterQualification: this.props.sendData.experienceAfterQualification,
            annualEvaluationforPreviousYear: this.props.sendData.annualEvaluationforPreviousYear,
            annualEvaluationforCurrentYear: this.props.sendData.annualEvaluationforCurrentYear,
            currentJobTitle: this.props.sendData.currentJobTitle,
            currentGrade: this.props.sendData.currentGrade,
            jobRank: this.props.sendData.jobRank,
            procedureType: this.props.sendData.procedureType,
            financialRatioOfProcedure: this.props.sendData.financialRatioOfProcedure,
            procedureDate: this.props.sendData.procedureDate,
            reasonToModifySalary: this.props.sendData.reasonToModifySalary,
            proposedIncreaseRate: this.props.sendData.proposedIncreaseRate,
            nationalityLabel: this.props.sendData.nationalityLabel,
            nationalityArray: [],
            procedureTypeArray: [],
            reasonToModifySalaryErr: '',
            proposedIncreaseRateErr: '',
            currentBasicSalErr: "",
            currentProposedSalErr: "",
            ageErr: ''
        }
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateChangeForProcedure = this.handleDateChangeForProcedure.bind(this);

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let tempNationalityArray = [];
        if (Config.getNationalityData()) {
            tempNationalityArray = Config.getNationalityData()
        }
        let tempProcedureTypeArray = [];
        if (Config.getProcedureTypeData()) {
            tempProcedureTypeArray = Config.getProcedureTypeData();
        }
        this.setState({
            nationalityArray: tempNationalityArray,
            procedureTypeArray: tempProcedureTypeArray
        });

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            let tempDateOne = '', tempDateTwo = '';
            if (editObj.designationdate !== "") {
                tempDateOne = new Date(editObj.designationdate);
            } else {
                tempDateOne = null;
            }

            if (editObj.proceduredate !== "") {
                tempDateTwo = new Date(editObj.proceduredate);
            } else {
                tempDateTwo = null;
            }

            let tempProcedureTypeId = '';
            let tempProcedureTypeSelectedValue = '';
            if (tempProcedureTypeArray && tempProcedureTypeArray.length > 0) {
                tempProcedureTypeId = editObj.proceduretype;
                tempProcedureTypeArray.map((item, index) => {
                    if (parseInt(tempProcedureTypeId) === parseInt(item.id)) {
                        // tempProcedureTypeSelectedValue = this.props.isArabic ? item.arabicName : item.englishName;
                        tempProcedureTypeSelectedValue = parseInt(tempProcedureTypeId);
                    }
                })
            }
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                employeeName: editObj.employeename,
                nationality: editObj.nationality,
                age: editObj.age,
                // designationDate: new Date(`${editObj.designationdate}`),
                // procedureDate: new Date(`${editObj.proceduredate}`),
                designationDate: tempDateOne,
                procedureDate: tempDateTwo,
                currentBasicSalary: editObj.currentbasicsalary,
                proposedBasicSalary: editObj.proposedbasicsalary,
                percentIncRequiredBasicSalary: editObj.percentageincrease,
                totalExperience: editObj.totalexpeirence,
                academicQualification: editObj.qualification,
                experienceAfterQualification: editObj.exprienceafterqualification,
                annualEvaluationforPreviousYear: editObj.annualprevevaluation,
                annualEvaluationforCurrentYear: editObj.annualcurrevaluation,
                currentJobTitle: editObj.jobtitle,
                currentGrade: editObj.grade,
                jobRank: editObj.jobrank,
                // procedureType: editObj.proceduretype,
                procedureType: tempProcedureTypeSelectedValue,
                financialRatioOfProcedure: editObj.financialratio,
                reasonToModifySalary: editObj.reasontomodify,
                proposedIncreaseRate: editObj.baseofincrease
            }, () => {

            })
        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    onChangehandler = () => {
        this.setState({ isNextClick: false })
    }

    checkValidations = () => {
        let currentBasicSalErr = "",
            currentProposedSalErr = "",
            ageErr = '',
            tempReasonToModifySalaryErr = '',
            tempproposedIncreaseRateErr = '';

        if (!this.state.currentBasicSalary) {
            currentBasicSalErr = 'thisFieldIsRequired';
        }
        if (!this.state.proposedBasicSalary) {
            currentProposedSalErr = 'thisFieldIsRequired';
        }

        if (this.state.reasonToModifySalary && this.state.reasonToModifySalary.trim()) {
            let val = striptags(this.state.reasonToModifySalary.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempReasonToModifySalaryErr = 'thisFieldisRequired';
            }
        } else {
            tempReasonToModifySalaryErr = 'thisFieldisRequired';
        }

        if (this.state.proposedIncreaseRate && this.state.proposedIncreaseRate.trim()) {
            let val = striptags(this.state.proposedIncreaseRate.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempproposedIncreaseRateErr = 'thisFieldisRequired';
            }
        } else {
            tempproposedIncreaseRateErr = 'thisFieldisRequired';
        }

        if (!this.state.age) {
            ageErr = 'thisFieldisRequired';
        } else if (parseInt(this.state.age) < 18) {
            ageErr = 'ageErr';
        }

        if (
            this.state.currentBasicSalary &&
            this.state.proposedBasicSalary
        ) {
            let currentBasicSalaryAfterReplace = this.state.currentBasicSalary.replace(/,/g, "");
            let proposedBasicSalaryAfterReplace = this.state.proposedBasicSalary.replace(/,/g, "");

            if (parseFloat(currentBasicSalaryAfterReplace) > parseFloat(proposedBasicSalaryAfterReplace)) {
                currentBasicSalErr = 'currentBasicSalErr';
            } else {
                currentBasicSalErr = '';
            }
        }

        this.setState({
            reasonToModifySalaryErr: tempReasonToModifySalaryErr,
            proposedIncreaseRateErr: tempproposedIncreaseRateErr,
            currentBasicSalErr: currentBasicSalErr,
            currentProposedSalErr: currentProposedSalErr,
            ageErr: ageErr
        }, () => {
            if (
                !tempReasonToModifySalaryErr &&
                !tempproposedIncreaseRateErr &&
                !currentBasicSalErr &&
                !currentProposedSalErr &&
                !ageErr
            ) {

                // this.state.procedureType !== '' -- commented this code
                if (
                    this.state.employeeName &&
                    this.state.nationality &&
                    this.state.age &&
                    this.state.designationDate &&
                    this.state.currentBasicSalary &&
                    this.state.proposedBasicSalary &&
                    this.state.percentIncRequiredBasicSalary &&
                    this.state.totalExperience &&
                    this.state.academicQualification &&
                    this.state.experienceAfterQualification &&
                    this.state.annualEvaluationforPreviousYear
                    && this.state.annualEvaluationforCurrentYear &&
                    this.state.currentJobTitle
                    &&
                    this.state.currentGrade
                    &&
                    this.state.financialRatioOfProcedure
                    &&
                    this.state.procedureDate
                    &&
                    striptags(this.state.reasonToModifySalary)
                    &&
                    striptags(this.state.proposedIncreaseRate)) {

                    this.props.getData.stepOneData({
                        employeeName: this.state.employeeName,
                        nationality: this.state.nationality,
                        age: this.state.age,
                        designationDate: this.state.designationDate,
                        currentBasicSalary: this.state.currentBasicSalary,
                        proposedBasicSalary: this.state.proposedBasicSalary,
                        percentIncRequiredBasicSalary: this.state.percentIncRequiredBasicSalary,
                        totalExperience: this.state.totalExperience,
                        academicQualification: this.state.academicQualification,
                        experienceAfterQualification: this.state.experienceAfterQualification,
                        annualEvaluationforPreviousYear: this.state.annualEvaluationforPreviousYear,
                        annualEvaluationforCurrentYear: this.state.annualEvaluationforCurrentYear,
                        currentJobTitle: this.state.currentJobTitle,
                        currentGrade: this.state.currentGrade,
                        jobRank: this.state.jobRank,
                        procedureType: this.state.procedureType,
                        financialRatioOfProcedure: this.state.financialRatioOfProcedure,
                        procedureDate: this.state.procedureDate,
                        reasonToModifySalary: this.state.reasonToModifySalary,
                        proposedIncreaseRate: this.state.proposedIncreaseRate,
                        requestId: this.state.requestId,
                        isEdit: this.state.isEdit,
                        nationalityLabel: this.state.nationalityLabel
                    })
                } else {
                   
                }
            }
        })
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

        if (!this.state.isEdit || (this.state.statusid === Config.getDraftId())) {
            this.checkValidations();
        } else {
            this.props.getData.stepOneData({
                employeeName: this.state.employeeName,
                nationality: this.state.nationality,
                age: this.state.age,
                designationDate: this.state.designationDate,
                currentBasicSalary: this.state.currentBasicSalary,
                proposedBasicSalary: this.state.proposedBasicSalary,
                percentIncRequiredBasicSalary: this.state.percentIncRequiredBasicSalary,
                totalExperience: this.state.totalExperience,
                academicQualification: this.state.academicQualification,
                experienceAfterQualification: this.state.experienceAfterQualification,
                annualEvaluationforPreviousYear: this.state.annualEvaluationforPreviousYear,
                annualEvaluationforCurrentYear: this.state.annualEvaluationforCurrentYear,
                currentJobTitle: this.state.currentJobTitle,
                currentGrade: this.state.currentGrade,
                jobRank: this.state.jobRank,
                procedureType: this.state.procedureType,
                financialRatioOfProcedure: this.state.financialRatioOfProcedure,
                procedureDate: this.state.procedureDate,
                reasonToModifySalary: this.state.reasonToModifySalary,
                proposedIncreaseRate: this.state.proposedIncreaseRate,
                requestId: this.state.requestId,
                isEdit: this.state.isEdit,
                nationalityLabel: this.state.nationalityLabel
            })
        }
    };

    saveAsDraft = () => {
        this.props.saveAsDraft({
            saveFrom: 'step1',
            formType: 'saveAsDraft',
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            employeeName: this.state.employeeName,
            nationality: this.state.nationality,
            age: this.state.age,
            designationDate: this.state.designationDate,
            currentBasicSalary: this.state.currentBasicSalary,
            proposedBasicSalary: this.state.proposedBasicSalary,
            percentIncRequiredBasicSalary: this.state.percentIncRequiredBasicSalary,
            totalExperience: this.state.totalExperience,
            academicQualification: this.state.academicQualification,
            experienceAfterQualification: this.state.experienceAfterQualification,
            annualEvaluationforPreviousYear: this.state.annualEvaluationforPreviousYear,
            annualEvaluationforCurrentYear: this.state.annualEvaluationforCurrentYear,
            currentJobTitle: this.state.currentJobTitle,
            currentGrade: this.state.currentGrade,
            jobRank: this.state.jobRank,
            procedureType: this.state.procedureType,
            financialRatioOfProcedure: this.state.financialRatioOfProcedure,
            procedureDate: this.state.procedureDate,
            reasonToModifySalary: this.state.reasonToModifySalary,
            proposedIncreaseRate: this.state.proposedIncreaseRate,
        });
    };

    clearForm = () => {
        this.setState({
            employeeName: '',
            nationality: '',
            age: '',
            designationDate: '',
            currentBasicSalary: '',
            proposedBasicSalary: '',
            percentIncRequiredBasicSalary: '',
            totalExperience: '',
            academicQualification: '',
            experienceAfterQualification: '',
            annualEvaluationforPreviousYear: '',
            annualEvaluationforCurrentYear: '',
            currentJobTitle: '',
            currentGrade: '',
            jobRank: '',
            procedureType: '',
            financialRatioOfProcedure: '',
            procedureDate: '',
            reasonToModifySalary: '',
            proposedIncreaseRate: ''
        })
    }

    onChangeEmailEditor = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            reasonToModifySalaryErr: '',
            reasonToModifySalary: newContent
        })
    }

    onChangeEmailEditor1 = (evt) => {
        var newContent = evt.editor.getData();
        this.setState({
            reqDetailsErr: '',
            reqDetails: newContent
        })
    }

    changeHandlerForReasonToModifySalary = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ reasonToModifySalary: newContent, reasonToModifySalaryErr: '' });
        } else {
            this.setState({ reasonToModifySalaryErr: 'ckeditorCountErr' });
        }
    }

    changeHandlerForProposedIncreaseRate = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                proposedIncreaseRateErr: '',
                proposedIncreaseRate: newContent
            });
        } else {
            this.setState({ proposedIncreaseRateErr: 'ckeditorCountErr' });
        }
    }

    handleDateChange = (date) => {
        this.setState({ designationDate: date });
    }

    handleChangeForProcedureType = (event) => {
        this.setState({ procedureType: event.target.value });
    }

    handleDateChangeForProcedure(date) {
        this.setState({ procedureDate: date });
    }

    percentIncRequiredBasicSalaryOnChange = (event) => {
        let regex = /^[0-9\b.]{0,3}$/;
        if (regex.test(event.target.value) && event.target.value < 100) {
            this.setState({ percentIncRequiredBasicSalary: event.target.value });
        }
    }

    handleChangeForCountry = (event) => {
        let dropDownLabel = '';
        this.setState({ nationality: event.target.value }, () => {
         
            if (this.state.nationalityArray.length > 0) {
                for (let index = 0; index < this.state.nationalityArray.length; index++) {
                    if (parseInt(this.state.nationality) === this.state.nationalityArray[index].id) {
                        dropDownLabel = this.props.isArabic ? this.state.nationalityArray[index].ArabicTitle : this.state.nationalityArray[index].EnglishTitle;
                    }
                }
            }

            this.setState({
                nationalityLabel: dropDownLabel
            });
        })
    };

    calculateAmendmentValue = () => {
        let currentBasicSalAfterReplace = this.state.currentBasicSalary.replace(/,/g, "");
        let tempBasicSal = parseFloat(currentBasicSalAfterReplace);
        let currentProposedSalAfterReplace = this.state.proposedBasicSalary.replace(/,/g, "");
        let tempPropsedBasicSal = parseFloat(currentProposedSalAfterReplace);
        
        let tempFincacialRatioofProcedure = '';
        let tempPercentIncRequiredBasicSalary = '';
        if (tempBasicSal > 0 || tempPropsedBasicSal > 0) {
            tempFincacialRatioofProcedure = tempPropsedBasicSal - tempBasicSal;
            if (tempFincacialRatioofProcedure) {
                tempPercentIncRequiredBasicSalary = (tempFincacialRatioofProcedure / tempBasicSal) * 100;
                this.setState({
                    financialRatioOfProcedure: tempFincacialRatioofProcedure,
                    percentIncRequiredBasicSalary: tempPercentIncRequiredBasicSalary.toFixed(2)
                })
            } else {
                this.setState({
                    financialRatioOfProcedure: "0",
                    percentIncRequiredBasicSalary: "0"
                })
            }
        }
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.classList.add('white_bg');
            el.input.readOnly = true;
        }
    }

    onDatepickerRef1(el) {
        if (el && el.input) {
            el.input.classList.add('white_bg');
            el.input.readOnly = true;
        }
    }

    handleCurrentBasicSalary = (value) => {
        if (value) {
            let proposedBasicSalaryAfterReplace = this.state.proposedBasicSalary.replace(/,/g, "");
            if (value.value !== proposedBasicSalaryAfterReplace) {
                this.setState({
                    currentBasicSalary: value.formattedValue,
                    currentBasicSalErr: ""
                }, () => {
                    this.calculateAmendmentValue();
                });
            }
            else {
                // TODO
            }
        }
    };

    handleCurrentProposedSalary = (value) => {
        if (value) {
         
            this.setState({
                proposedBasicSalary: value.formattedValue,
                currentProposedSalErr: ""
            }, () => {
                this.calculateAmendmentValue();
            });

        }
    };

    render() {

        return (

            <form
                style={{ marginTop: '20px', marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent steps={['Step1', 'Step2']} currentIndex={1} isArabic={this.props.isArabic} onChange={this.onChangehandler} activeColor='#00BCD4' nonActiveColor='#eeeeee' />

                <MDBRow
                    style={{ marginTop: "10px", display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}
                >
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].employeeName}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="employeeName"
                                name="employeeName"
                                value={this.state.employeeName}
                                onChange={(e) => {
                                    if (e.target.value && e.target.value.trim()) {
                                        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
                                        if (regex.test(e.target.value) && e.target.value.length < 51) {
                                            this.setState({ employeeName: e.target.value });
                                        }
                                    } else {
                                        this.setState({ employeeName: '' })

                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].nationality}<span className="requireStar">*</span></label>
                            {/* <input
                                    style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                    type="text"
                                    className="form-control"
                                    id="nationality"
                                    name="nationality"
                                    value={this.state.nationality}
                                    onChange={(e) => {
                                        let regex = /^[\u0621-\u064AA-Za-z ]*$/;
                                        if (regex.test(e.target.value)) {
                                            this.setState({ nationality: e.target.value });
                                        }
                                    }}
                                    required
                                /> */}
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select" id="sources-funding"
                                onChange={this.handleChangeForCountry}
                                value={this.state.nationality}
                                required>

                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.nationalityArray ? this.state.nationalityArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: 'row', alignItems: 'right' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].age}
                                <span className="requireStar">*</span>
                            </label>


                            <input
                                style={{
                                    display: 'flex',
                                    flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                                    direction: this.props.isArabic ? "rtl" : 'ltr',
                                    border: this.state.ageErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                }}
                                type="text"
                                className="form-control"
                                id="age"
                                name="age"
                                value={this.state.age}
                                onChange={event => {
                                    let regex = /^[0-9\.]{0,4}$/;
                                    if (event.target.value) {
                                        if (regex.test(event.target.value) && parseInt(event.target.value) < 150) {
                                            this.setState({ age: event.target.value, ageErr: "" });
                                        }
                                    } else {
                                        this.setState({ age: "", ageErr: "thisFieldisRequired" });
                                    }
                                }}
                                required
                            />

                            {
                                this.state.ageErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.ageErr === 'ageErr' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].ageErr}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].designationDate}<span className="requireStar">*</span></label>
                            <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} >
                                <DatePicker
                                    maxDate={new Date()}
                                    className="form-control"
                                    todayButton={"Today"}
                                    dateFormat="MM/dd/yyyy"
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={<input labelPosition="right" label={<div className="label__text">Date</div>} />}
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required
                                    selected={this.state.designationDate}
                                    onChange={this.handleDateChange}
                                />
                            </div>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].currentBasicSalary}<span className="requireStar">*</span></label>
                            {/* <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="currentBasicSalary"
                                name="currentBasicSalary"
                                value={this.state.currentBasicSalary}
                                onChange={(event) => {
                                    let regex = /^[0-9\b.]{0,15}$/;
                                    if (regex.test(event.target.value)) {
                                        if (event.target.value.split('.').length <= 2) {
                                            this.setState({
                                                currentBasicSalary: event.target.value
                                            }, () => {
                                                this.calculateAmendmentValue();
                                            });
                                        }
                                    }

                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div> */}

                            <div
                                className="currencyFormatInputCSS currencyFormat"
                                style={{
                                    border: this.state.currentBasicSalErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                }}
                            >
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.currentBasicSalary}
                                    onValueChange={(value) => { this.handleCurrentBasicSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                />
                            </div>

                            {
                                this.state.currentBasicSalErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.currentBasicSalErr === 'currentBasicSalErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].currentBasicSalErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].proposedBasicSalary}<span className="requireStar">*</span></label>
                            {/* <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="proposedBasicSalary"
                                name="proposedBasicSalary"
                                value={this.state.proposedBasicSalary}
                                onChange={(event) => {
                                    let regex = /^[0-9\b.]{0,15}$/;
                                    if (regex.test(event.target.value)) {
                                        if (event.target.value.split('.').length <= 2) {
                                            this.setState({
                                                proposedBasicSalary: event.target.value
                                            }, () => {
                                                this.calculateAmendmentValue();
                                            });
                                        }
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div> */}

                            <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.currentProposedSalErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.proposedBasicSalary}
                                    onValueChange={(value) => { this.handleCurrentProposedSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                />
                            </div>

                            {this.state.currentProposedSalErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].financialRatioOfProcedure}<span className="requireStar">*</span></label>
                            <input
                                disabled={true}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="financialRatioOfProcedure"
                                name="financialRatioOfProcedure"
                                value={this.state.financialRatioOfProcedure}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].percentageIncreaseRequiredBasicSalary}<span className="requireStar">*</span></label>
                            <input
                                disabled={true}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="percentIncRequiredBasicSalary"
                                name="percentIncRequiredBasicSalary"
                                value={this.state.percentIncRequiredBasicSalary + "%"}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].totalExperience}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="totalExperience"
                                name="totalExperience"
                                value={this.state.totalExperience}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        if (event.target.value.split('.').length <= 2) {
                                            // let regex = /^[\u0621-\u064Aa-zA-Z0-9\b. ]{0,15}$/;
                                            let regex = /^[0-9\b.]{0,15}$/;
                                            if (regex.test(event.target.value)) {
                                                if (parseFloat(event.target.value) < 99) {
                                                    this.setState({ totalExperience: event.target.value })
                                                }
                                            }
                                        }
                                    } else {
                                        this.setState({ totalExperience: '' })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].procedureDate}<span className="requireStar">*</span></label>
                            <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} >
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    selected={this.state.procedureDate}
                                    onChange={this.handleDateChangeForProcedure}
                                    //customInput={<DateButtonComponent isArabic={this.props.isArabic} />}
                                    //timeInputLabel="Time:"
                                    ref={el => this.onDatepickerRef1(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    required
                                />
                            </div>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].procedureType}<span className="requireStar">*</span></label>
                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select" id="sources-funding"
                                onChange={this.handleChangeForProcedureType}
                                value={this.state.procedureType}
                                required >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.procedureTypeArray ? this.state.procedureTypeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    })
                                        :
                                        null
                                }

                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol> */}
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].aacademicQualification}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="academicQualification"
                                name="academicQualification"
                                value={this.state.academicQualification}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9 ]{0,50}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ academicQualification: event.target.value })
                                        }
                                    } else {
                                        this.setState({ academicQualification: '' })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].experienceAfterQualification}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="experienceAfterQualification"
                                name="experienceAfterQualification"
                                value={this.state.experienceAfterQualification}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        // let regex = /^[\u0621-\u064Aa-zA-Z0-9\b. ]{0,15}$/;
                                        let regex = /^[0-9\b.]{0,15}$/;
                                        if (regex.test(event.target.value)) {
                                            if (event.target.value.split('.').length <= 2) {
                                                if (parseFloat(event.target.value) < 99) {
                                                    this.setState({ experienceAfterQualification: event.target.value })
                                                }
                                            }
                                        }
                                    } else {
                                        this.setState({ experienceAfterQualification: '' })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].annualEvaluationforPreviousYear}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="annualEvaluationforPreviousYear"
                                name="annualEvaluationforPreviousYear"
                                value={this.state.annualEvaluationforPreviousYear}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9 ]{0,51}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ annualEvaluationforPreviousYear: event.target.value })
                                        }
                                    } else {
                                        this.setState({
                                            annualEvaluationforPreviousYear: ''
                                        })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].annualEvaluationforCurrentYear}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="annualEvaluationforCurrentYear"
                                name="annualEvaluationforCurrentYear"
                                value={this.state.annualEvaluationforCurrentYear}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9 ]{0,50}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ annualEvaluationforCurrentYear: event.target.value })
                                        }
                                    } else {
                                        this.setState({
                                            annualEvaluationforCurrentYear: ''
                                        })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].currentJobTitle}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="currentJobTitle"
                                name="currentJobTitle"
                                value={this.state.currentJobTitle}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
                                        if (regex.test(event.target.value) && event.target.value.length < 51) {
                                            this.setState({ currentJobTitle: event.target.value })
                                        }
                                    } else {
                                        this.setState({ currentJobTitle: '' })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].currentGrade}<span className="requireStar">*</span></label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="currentGrade"
                                name="currentGrade"
                                value={this.state.currentGrade}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9 ]{0,51}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ currentGrade: event.target.value })
                                        }
                                    } else {
                                        this.setState({
                                            currentGrade: ''
                                        })
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].jobRank}<span className="requireStar">*</span></label>
                                <input
                                    style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                    type="text"
                                    className="form-control"
                                    id="jobRank"
                                    name="jobRank"
                                    value={this.state.jobRank}
                                    onChange={(event) => {
                                        if (event.target.value.trim() != '') {
                                        let regex = /^[\u0621-\u064Aa-zA-Z0-9 ]{0,51}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ jobRank: event.target.value })
                                        }}else{
                                            this.setState({
                                                jobRank:''
                                            })
                                        }
                                    }}
                                    required
                                />
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                            </div>
                        </MDBCol> */}
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].reasonToModifySalary}<span className="requireStar">*</span></label>
                                <div style={{ border: this.state.reasonToModifySalaryErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle" >
                                    {
                                        this.props.isArabic ?
                                            null
                                            :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.reasonToModifySalary}
                                                events={{
                                                    "change": this.changeHandlerForReasonToModifySalary
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.reasonToModifySalary}
                                                events={{
                                                    "change": this.changeHandlerForReasonToModifySalary
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.reasonToModifySalaryErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].baseUsedDetermineProposedIncreaseRate}<span className="requireStar">*</span></label>

                                <div style={{ border: this.state.proposedIncreaseRateErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">

                                    {
                                        this.props.isArabic ?
                                            null
                                            :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.proposedIncreaseRate}
                                                events={{
                                                    "change": this.changeHandlerForProposedIncreaseRate
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.proposedIncreaseRate}
                                                events={{
                                                    "change": this.changeHandlerForProposedIncreaseRate
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.proposedIncreaseRateErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].next}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>

            </form>

        );
    }
}