import React from "react";
import CKEditor from "react-ckeditor-component";

const CKEditorComponent = (props) => {

    return (
        <div>
            {
                props.isArabic ?
                    null
                    :
                    <CKEditor
                        activeClass="p10"
                        content={props.content}
                        events={{
                            "change": props.onChange
                        }}
                        config={{ language: "en", height: props.ckEditorHeight, width: props.ckEditorWidth }}
                    />
            }
            {
                props.isArabic ?

                    <CKEditor
                        activeClass="p10"
                        content={props.content}
                        events={{
                            "change": props.onChange
                        }}
                        config={{ language: "ar", height: props.ckEditorHeight, width: props.ckEditorWidth }}
                    />
                    :
                    null
            }
        </div>
    );
};

export default CKEditorComponent;




