import i18n from './../i18n';
let moment = require('moment');

 //Dev
/*   let domainUrl = 'http://localhost:3000/';
let baseUrl= 'http://localhost:58779/api/Request/';

let DYNAPIURL='http://172.19.21.133:8080/api/WorkFlow/';  */

let trasolURL='http://172.19.21.132:8989/?token='; 


 
// producation
   let domainUrl = 'https://eservices.hra.gov.ae';
 let baseUrl = 'https://eservices.hra.gov.ae/api/Request/';
  let DYNAPIURL='https://eservices.hra.gov.ae/api/WorkFlow/';  



// Dev
// let passportcopyForAdHousingId=10921;
// let familybooknumberForAdHousingId=10922;
// let EidcopyForAdHousingId=10923;

// let FederalCurrentJobDescId=10924;

//let baseUrl= 'http://172.19.21.133:8080/api/Request/';



let MainPracticeArray=[];
let passportcopyForAdHousingId=10921;
let familybooknumberForAdHousingId=10922;
let EidcopyForAdHousingId=10923;

let FederalCurrentJobDescId=10924;
let ActionPlanListEntity=0;




let currenttreeview=0;
let CurrentEntityId=0;
let CanViewTree=0;
let nationalId = 552;
let maxFileCount = 10;
let draftId = 819;
let bonusRequestType = 1;
let amendSalaryScaleRequestType = 2;
let amendEmployeeSalaryRequestType = 3;
let sciRequestType = 4;
let ORGRequestType = 874;
let ORGRequestPosition = 901;

let DisciplinaryboardType= 10953;
let DisciplinaryboardformalrequestletterId=10952;

let informationRequestType = 7;
let exceptionAcompanypatientRequestType = 8;
let hrReviewPolicyRequestType = 9;
let exceptionADHousingRequestType = 10;
let secondmentForFederalAgencyRequestType = 11;
let retirementRequestType = 165;
let addAndEditEmployeePerformanceEvaluationId = 827;
let extendAnnualPerformaceEvaluationId = 828;
let extendCreateObjectiveId = 829;
let extendReviewObjectiveId = 830;
let joinAndImplementId = 831;
let amendOnSharedGovermentId = 832;
let openComplainsStageId = 833;
let reportAndStaticId = 834;
let otherRequest = 835;
let entityRequestType = 0;

let statusPendingId = 38;
let statusInProgressId = 31;
let statusOnHoldId = 37;
let statusCompletedId = 23;
let statusCanceledId = 164;
let statusWaitingForExecutiveOffice = 843;

let reasonAcceptedId = 157;
let reasonSCIInitialSubmissionId = 136;
let reasonSCISectorAssignedId = 133;
let reasonSCIDepartmentAssignedId = 134;
let reasonSCIEmployeeAssignedId = 135;


//Promotion
let SickLeaveRequestType =10925;
let PromotionRequestType =10926;
let PromotionEmployeeCVId =10927 ;
let PromotionEmployeePhotoId = 10928;
let PromotionpositionofthenominatedpositionId =10929 ;
let PromotionjobdescriptionId = 10930 ;
let PromotionHumanResourcesCommitteeId =10931 ;
let PromotionEmployeeNominationFormId =10932 ;
let PromotionEducationalQualificationsId =10933 ;
let PromotionExperienceCertificatesId =10934 ;
let PromotioncompetencyreportsId = 10935;
let PromotionpassportId= 10936;
let PromotionSummaryofregistrationforcitizensId =10937 ;
let PromotionIDcardId =10938 ;
let PromotionCIDId =10939 ;
let PromotionMedicalfitnesscheckupId =10940 ;
let PromotionAnotheremployeesselfstatementId = 10941;
let SickLeaveEmployeePassportId=10942,
SickLeaveEmployeeEIDId=10943,
SickLeaveHealthreportId=10944,
SickLeaveEmbassyLetterId=10945,
SickLeaveHRComId=10946,
SickLeaveEmployeeIncomeId=10947;
let workshoppresId = 10950;
let workshopRequestType = 10951;
let InitiativeFileId=10961;
let ActionPlanRequestType=10962; 



let base64ForDisciplinaryboardformalrequestletter= '',base64ForDisciplinaryboardEmployeeViloation='',base64ForDisciplinaryboardEmployeeinvestigation='';
let base64ForPromotionEmployeeCV= '',
base64ForPromotionEmployeePhoto= '',
base64ForPromotionpositionofthenominatedposition= '',
base64ForPromotionjobdescription= '',
base64ForPromotionHumanResourcesCommittee= '',
base64ForPromotionEmployeeNominationForm= '',
base64ForPromotionEducationalQualifications= '',
base64ForPromotionExperienceCertificates= '',
base64ForPromotioncompetencyreports= '',
base64ForPromotionpassport= '',
base64ForPromotionSummaryofregistrationforcitizens= '',
base64ForPromotionIDcard= '',
base64ForPromotionCID= '',
base64ForPromotionMedicalfitnesscheckup= '',
base64ForPromotionAnotheremployeesselfstatement= '';

let base64ForSickLeaveEmployeePassport= '',
base64ForSickLeaveEmployeeEID= '',
base64ForSickLeaveHealthreport= '',
base64ForSickLeaveEmbassyLetter= '',
base64ForSickLeaveHRCom= '',
base64ForSickLeaveEmployeeIncome= '',
base64ForWorkShopPres='',base64ForInitiativeFile='';
// organization structure 

let OrganizationStructurFunctionalLoad;
let OrganizationStructurJobEvaluation;

let OrganizationStructurOtherAttachment;
let OrganizationStructurRequestReason;
let OrganizationStructurEntityEstablishmentLaw;
let OrganizationStructurEntityFormalRequest;

let OrganizationStructurOtherAttachmentId='875';
let OrganizationStructurRequestReasonId='876';
let OrganizationStructurEntityEstablishmentLawId='877';
let OrganizationStructurEntityFormalRequestId='878';
let OrganizationStructurRequestId='874';
let OrganizationStructurFunctionalLoadId='879';
let OrganizationStructurJobEvaluationId='880';

let TasksAndSpecificationsTemplateId='893';
let OrganizationStrucutreOperationalTemplateId='894';
let CurrentOrganizationStructureId='895';
let SuggestedOrganizationStructureId='896';

let TasksAndSpecificationsTemplate;
let OrganizationStrucutreOperationalTemplate;
let CurrentOrganizationStructure;
let SuggestedOrganizationStructure;


let OrganizationStructurAdminRoles=[];
let OrganizationStructurFunctionalRoles=[];
let OrganizationStructurOrganizationalRoles=[];

// let reasonSCIFeedbackProvidedId = 137;
let reasonSCIFeedbackProvidedId = 813;
let reasonSCISectorFeedbackProvidedId = 814;
let reasonSCIStrategicFeedbackProvidedId = 815;

let reasonInformationSectorAssignedId = 142;
let reasonInformationDepartmentAssignedId = 143;
let reasonInformationEmployeeAssignedId = 144;
let reasonInformationInitialSubmissionId = 145;
let reasonInformationFeedbackProvidedId = 146;
let reasonForStrategicAssignedId = 811;

let reasonForDepartmentalApprovalId = 161;
let reasonForSectorApprovalId = 162;
let reasonForGMApprovalId = 163;
let replacePlansForNonLocalsId = 806;

let RETSummaryofregistrationforcitizensId=912;
let RETPreviousapprovalsfortheextensionId=913;
let RETQualificationsId=914;
let RETJobdescriptionId=915;
let RETCareersuccessionplanId=916;
let RETHRComId=917;
let RETOtherAttachId=918;
let RETPoistioninStructureId=919;

// for all other services
let reasonForGmAssignedId = 59;
let reasonForSectorAssignedId = 151;
let reasonForDepartmentAssignedId = 152;
let reasonForEmployeeAssignedId = 153;
let reasonForStudyProvidedId = 155;

let reasonForReturnToEntityId = 809;
let reasonForAssignToEntityId = 812;

let reasonForExecutiveOffice = 846;

// account type
let accountTypeEmployeeForPolicies = 770;
let accountTypePolicesSectionMager = 906;
let accountTypeResearchSectionManager=905;
let accountTypeEmployeeForSalaries = 822;
let accountTypeEmployeeForData = 824;
let accountTypeEmployeeForHRMS = 853;
let accountTypeEmployeeForOrgnisation = 854;
let accountTypeSectorManager = 771;
// account type for department
let accountTypePoliciesDeptManager = 772;
let accountTypeCultureandTalentManagementSectorManager=10949;
let accountPolicesector =905 ;
let accountResearchSector =906;
let accountTypeSalariesDeptManager = 820;
let accountTypeDataDeptManager = 825;
let accountTypeOrganizationDeptManager = 851;
let accountTypeHRMSDeptManager = 849;
let accountTypeGMOffice = 773;
let accountTypeSPOffice = 818;

// data depeartment id 
let dataDepartmentId = 783;
let
    isEditSCI = false,
    isPreview = false,
    loginDataReceivedFirstTime = false,
    allDpDwnAPIresponseFirstTime = false;
let
    editobj = {},
    previewObj = {},
    attachmentArr = [],
    ckEditorArr = [];

    let workshopItem ={};
    let workShopTimeID={};
let base64ForSciOfficalLetter = '';

let base64ForInformationRequestOne = '';
let base64ForInformationRequestForReply = '';

let base64ForExmptionHousingPolicy_EntityFormalReq = '';
let base64ForExmptionHousingPolicy_attachFileSupport = '';
let base64ForExmptionHousingPolicy_EID = '';
let base64ForExmptionHousingPolicy_familybooknumber = '';
let base64ForExmptionHousingPolicy_Passport = '';
let base64ForExmptionHousingPolicy_employee = '';
let base64ForExmptionHousingPolicy_flat = '';
let base64ForExmptionHousingPolicy_HRA_study = '';

let base64rBonusRequest_approvedGradesAndSalaries = '';
let base64rBonusRequest_BudgetDoc = '';
let base64ForrBonusRequest_EntityLogoFileChange = '';
// let base64BonusRequest_BudgetDoc = '';
let base64BonusRequest_FinancialStatement = ""
let base64BonusRequest_PresentationforEntityAcheivements = ""
let base64BonusRequest_TaxSummary = '';
let base64BonusRequest_BalanceScoreCard = '';
let base64resultsOfBonusRequest_EvaluationGeneralPerformance = '';
let base64resultsOfBonusRequest_UploadContract = '';
// let base64resultsOfBonusRequest__hraStudy = '';
let base64resultsOfBonusRequest_uploadExcelAfterFill = ''

let base64ForSecondment_reqForBrowEntity
    , base64ForSecondment_reqForCompEntity
    , base64ForSecondment_jobDescFileName
    , base64ForSecondment_copyOfHRCommitteFileName
    , base64ForSecondment_copyOfLastYearEffiReportFileName
    , base64ForSecondment_emiratesIdFileName
    , base64ForSecondment_passportCopyFileName
    , base64ForSecondment_famliyBookFileName
    , base64ForSecondment_hraEmpStudyFileName
    , base64ForSecondment_approvalEmpFileName,base64ForSecondment_CurrentJobDesc;

let base64ofAmendSalaryScreenfile1 = '';
let base64ofAmendSalaryScreenfile2 = '';
let base64ofAmendSalaryScreenfile3 = '';
let base64ofAmendSalaryScreenfile4 = '';
let base64ofAmendSalaryScreenfile5 = '';
let base64ofAmendSalaryScreenfile6 = '';
let base64ofAmendSalaryScreenfile7 = '';
let base64ofAmendSalaryScreenfile8 = '';
let base64ofAmendSalaryScreenfile9 = '';
let base64ofAmendSalaryScreenfile10 = '';

let base64ForReviewHRPoliciesOne = '';
let base64ForReviewHRPoliciesTwo = '';
let base64ForReviewHRPoliciesThree = '';
let base64ForReviewHRPoliciesFour = '';

let base64ForAmendEmployeeSalaryOne = '';
let base64ForAmendEmployeeSalaryTwo = '';
let base64ForAmendEmployeeSalaryThree = '';
let base64ForAmendEmployeeSalaryFour = '';
let base64ForAmendEmployeeSalaryFive = '';
let base64ForAmendEmployeeSalarySix = '';

let base64ForexemptionFromAccompanyPatient1 = '';
let base64ForexemptionFromAccompanyPatient2 = '';
let base64ForexemptionFromAccompanyPatient3 = '';
let base64ForexemptionFromAccompanyPatient4 = '';
let base64ForexemptionFromAccompanyPatient5 = '';
let base64ForexemptionFromAccompanyPatient6 = '';
let base64ForexemptionFromAccompanyPatient7 = '';
let base64ForexemptionFromAccompanyPatient8 = '';
let base64ForexemptionFromAccompanyPatient9 = '';

let base64ForexemptionEmiratedID = '';

let base64ForJobContract
    , base64MedicalApprovalsFromTheCometentMedicalCommittee
    , base64ForPassport
    , base64ForFamilyBook
    , base64ForEmaritesID
    , base64ForAnnualPerformanceEvaluationattach
    , base64ForAcopyofthepreviousapprovals
    , base64ForEmployeeCV
    , base64ForPersonalPhoto
    ,base64ForSummaryofregistrationforcitizens
    ,base64ForPoistioninStructure
    ,base64ForPreviousapprovalsfortheextension
    ,base64ForQualifications
    ,base64ForJobdescription
    ,base64ForCareersuccessionplan
    ,base64ForHRCom
    ,base64ForOtherAttach;

let base64BonusRequest_MailTemplateBody;
let base64EntityForm_Logo;

let ExceptionOfSteerAgeOfRetirment_EmpAttachment_FileName,
    ExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName;

// sci
let sciAttachmentId = 32;
let sciReplyAttachmentId = 33;
let SCILEgalSerbviceId=401;
let SCIComplainId=16;
let SCISuggestionId=15;
let SCIGeneralId=17;

// information
let informationRequestAttachmentId = 61;
let informationRequestFeedbackAttachmentId = 62;
// Amend salary scale
let amendSalaryScaleApprovedScaleAndGradeAttachmentId = 72;
let amendSalaryScaleSalariesAndConpansationAttachmentId = 73;
let amendSalaryScalePropsedScaleAttachmentId = 74;
let amendSalaryScaleAlignmentTableGradeAttachmentId = 75;
let amendSalaryScaleStudyWithSimilarEntityAttachmentId = 76;
let amendSalaryScaleCurrentAndProposedSalaryLineAttachmentId = 77;
let amendSalaryScaleAdminAuthIsRegisteredAttachmentId = 78;
let amendSalaryScaleAnnualCostAttachmentId = 79;
let amendSalaryScaleChairmanApproveAttachmentId = 80;
let hraStudyForAmendSalaryScaleId = 81;
// amend employee sal
let amendEmployeeSalAprrovedScaleAndSalariedId = 82;
let amendEmployeeSalEmpPhotoId = 83;
let amendEmployeeSalEmiratesId = 84;
let amendEmployeeSalPreviousCompentaencyReportId = 85;
let amendEmployeeSalPreviousCopyOfLastProcedureId = 87;
let hraStudyForEmployeeSalId = 88;
// hr review
let hrReviewFormalRequestId = 97;
let hrReviewStandardComparisonId = 98;
let hrReviewStudiesId = 99;
let hraStudyForHrReviewId = 100;
//patient 
let patientPassortCopyId = 90;
let patientMedicalReportId = 91;
let patientLetterFromEmbassyId = 92;
let patientCopyOfdDigestId = 93;
let patientHumanResourceComitteRecordId = 94;
let patientFinancialObligationId = 95;
let patientLetterFromHeadOfEntityId = 89;
let patientDetailedsalaryCertificate = 921;
let patientEmiratesId = 743;
let hraStudyForPatientId = 96;
 //secondment
let approvalEmployeeId = 107;
let borrowingEntityId = 104;
let jobDescriptionId = 106;
let lastYearEfficiencyReportId = 109;
let emaritesId = 110;
let passportCopyId = 111;
let familyBookId = 112;
let hrCommitteId = 108;
let competentEntityId = 105;
let hraStudyForSecondmentId = 113;



// bonus 
let bonusApprovedGradesAndSalarieId = 64;
let bonusEntityLogoId = 63;
let bonusBalancedScoreCardId = 66;
let bonusUploadExcelAfterFillId = 70;
let bonusFinacialStatementId = 67;
let bonusResultForEvalutionForGeneralPerformanceId = 68;
let hraStudyForBonusId = 71;
let bonusBudgetReletedDocumentId = 65;
let bonusForPrivateContractId = 69;
let presentationforEntityAcheivementsId = 805;
// AD housing
let adHousingEntityFormalRequestId = 101;
let adHousingAttachedFileForSupportRequestId = 102;
let adHousingAttachedFileForEmployee = 807;
let adHousingAttachedFileForFlat = 808;
let adHousingAttachedFileForHraStudyId = 103;
let hraStudyForAdHousingId = 103;

// Retirment
let retirmentJobContractId = 114;
let retirmentMedicalApprovalId = 115;
let retirmentCopyOfPassportId = 116;
let retirmentFamilyBookId = 117;
let retirmenEmiratesId = 118;
let retirmenAnnualPerformanceEvaluationId = 119;
let retirmenCopyOfPreviousApprovalId = 120;
let retirmenEmployeeCvId = 121;
let retirmenPersonalPhotoId = 122;

//data services
let formalReqFromEnitiesAndEmp_FileName = '';
let AddOrEditEmpPerfEva_employeesFinalPerfEvaluationSheetInExcel_FileName = '';
let JoinAndImpGovResSys_AttachEntityAttestedPolicyFile_FileName = '';
let JoinAndImpGovResSys_AttachEntityAttestedOrgStructure_FileName = '';
let JoinAndImpGovResSys_AttachEntityCreationLaw_FileName = '';
let JoinAndImpGovResSys_AttachAgreeFromEntityTopMngmntAsPDF_FileName = '';
let base64ForAttachementForRequiredReqortFieldsAndDetails = '';
let base64OtherAttachmentForExtendCreate
    , base64OtherAttachmentForExtendReview
    , base64OtherAttachmentForExtendAnnual
    , base64OtherAttachmentForAddAndEdit
    , base64OtherAttachmentForJoinAndImplement
    , base64OtherAttachmentForOpenGravience
    , base64OtherAttachmentForAmendOnShared
    , base64OtherAttachmentForReportAndStatic
    , base64OtherAttachmentForOtherRequest

// study for data services
let hraStudyForExtendCreate = 857;
let hraStudyForExtendReview = 858;
let hraStudyForExtendAnnualPerformance = 856;
let hraStudyForAddAndEditPerformance = 855;
let hraStudyForJoinAndImplement = 859;
let hraStudyForOpenGrievvance = 861;
let hraStudyForAmendOnSharedGoverment = 860;
let hraStudyForReportAndStatic = 862;
let hraStudyForOtherRequest = 863;
// organization structure
let hraStudyForOrganizationStructureRequest = 900;

// other attachment
let otherAttachmentsEmployeePerformance = 864;
let otherAttachmentsExtendAnnualPerformance = 865;
let otherAttachmentsExtendCreateObjectives = 866;
let otherAttachmentsExtendReviewObjectives = 867;
let otherAttachmentsImplementSystems = 868;
let otherAttachmentsAmendEntitySystems = 869;
let otherAttachmentsOpenComplainsStage = 870;
let otherAttachmentsStatisticsRequest = 871;
let otherAttachmentsOtherRequest = 872;

// file type for data services
// add and edit
let empPerformanceFormalRequestEntityAndEmpId = 836;
let empPerformanceFinalPerofrmanceId = 837;
// join and implement 
let joinAndImplementPolicyAttestedId = 838;
let joinAndImplementOrgnisationStrutureId = 839;
let joinAndImplementEntityCreationLawId = 840;
let joinAndImplementAttachAgreementFormEntityId = 841;
// report and static
let reportAndStaticReportFeildsAndDetails = 842;

// attachment limitation
let fileSize = 2097152,WorkshopfileSize=5242880, ckEditorLimit = 2000;
const crypto = require('crypto');

// key  name changed for security purpose
const filedisc = new Uint8Array([13, 78, 135, 106, 70, 102, 131, 120, 79, 100, 78, 208, 254, 223, 30, 113, 100, 32, 188, 126, 40, 89, 80, 175, 9, 15, 139, 53, 89, 149, 217, 65]);
// iv  name changed for security purpose
const fileSizeArr = new Uint8Array([213, 4, 147, 122, 191, 82, 89, 155, 208, 154, 204, 78, 101, 51, 219, 36]);

const encrypt = (text) => {
    let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(filedisc), fileSizeArr);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { encryptedData: encrypted.toString('hex') };
}

const decrypt = (text) => {
    let ivkey = fileSizeArr;
    if (text) {
        let encryptedText = Buffer.from(text.encryptedData, 'hex');
        let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(filedisc), ivkey);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    } else {
        return {};
    }
}

let Config = {
    SetActionPlanListEntity:(ActionPlanListEntityvalue)=>{
localStorage.setItem('ActionPlanListEntity',ActionPlanListEntityvalue)
    },
    GetActionPlanListEntity:()=>{
        let loginData = localStorage.getItem('ActionPlanListEntity');
      
        return loginData;

    },
    SetMainPracticeArray: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('MainPracticeArray', JSON.stringify(temp));
    },

    GetMainPracticeArray: () => {
        let roleListData = localStorage.getItem('MainPracticeArray');
        let roleListDataEncrypted = JSON.parse(roleListData);
        return JSON.parse(decrypt(roleListDataEncrypted));
    },
    setWorkShopDatesFlag:()=>{
        localStorage.setItem('WorkShopDatesFlag', true);
    },
    getWorkShopDatesFlag: () => {
        
       let loginData = localStorage.getItem('WorkShopDatesFlag');
      
       return loginData;
   },

   unsetWorkShopDatesFlag: () => {
       // let AdsDataEncrypted = encrypt(JSON.stringify(loginData));
        localStorage.setItem('WorkShopDatesFlag', false);
    },

    getBase64ForSickLeaveEmployeePassport: () => {
        return base64ForSickLeaveEmployeePassport;
    },
setBase64ForSickLeaveEmployeePassport: (base64) => {
        base64ForSickLeaveEmployeePassport = base64;
    },
getSickLeaveEmployeePassportId: () => {
        return  SickLeaveEmployeePassportId
    },
getBase64ForSickLeaveEmployeeEID: () => {
        return base64ForSickLeaveEmployeeEID;
    },
setBase64ForSickLeaveEmployeeEID: (base64) => {
        base64ForSickLeaveEmployeeEID = base64;
    },
getSickLeaveEmployeeEIDId: () => {
        return   SickLeaveEmployeeEIDId;
    },
getBase64ForSickLeaveSickLeaveHealthreport: () => {
        return base64ForSickLeaveHealthreport;
    },
setBase64ForSickLeaveSickLeaveHealthreport: (base64) => {
        base64ForSickLeaveHealthreport = base64;
    },
getSickLeaveSickLeaveHealthreportId: () => {
        return   SickLeaveHealthreportId;
    },
getBase64ForSickLeaveSickLeaveEmbassyLetter: () => {
        return base64ForSickLeaveEmbassyLetter;
    },
setBase64ForSickLeaveSickLeaveEmbassyLetter: (base64) => {
        base64ForSickLeaveEmbassyLetter= base64;
    },
getSickLeaveSickLeaveEmbassyLetterId: () => {
        return    SickLeaveEmbassyLetterId;
    },
getBase64ForSickLeaveHRCom: () => {
        return base64ForSickLeaveHRCom;
    },
setBase64ForSickLeaveHRCom: (base64) => {
        base64ForSickLeaveHRCom = base64;
    },
getSickLeaveHRComId: () => {
        return     SickLeaveHRComId;
    },
getBase64ForSickLeaveEmployeeIncome: () => {
        return base64ForSickLeaveEmployeeIncome;
    },
setBase64ForSickLeaveEmployeeIncome: (base64) => {
        base64ForSickLeaveEmployeeIncome = base64;
    },
getSickLeaveEmployeeIncomeId: () => {
        return      SickLeaveEmployeeIncomeId;
    },

    getBase64ForWorkShopPres: () => {
        return base64ForWorkShopPres;
    },

    

    setBase64ForWorkShopPres: (base64) => {
        base64ForWorkShopPres = base64;
    },
getWorkShopPresId: () => {
        return      workshoppresId;
    },

    getBase64ForInitiativeFile: () => {
        return base64ForInitiativeFile;
    },

    

    setBase64ForInitiativeFile: (base64) => {
        base64ForInitiativeFile = base64;
    },
getInitiativeFileId: () => {
        return      InitiativeFileId;
    },

    getDisciplinaryboardformalrequestletterID:()=>{
return DisciplinaryboardformalrequestletterId;
    },
    getPromotionEmployeeCVId: () => {
        return PromotionEmployeeCVId;
    },
getPromotionEmployeePhotoId: () => {
        return PromotionEmployeePhotoId;
    },

getPromotionpositionofthenominatedpositionId: () => {

        return PromotionpositionofthenominatedpositionId },


getPromotionjobdescriptionId: () => {
   return PromotionjobdescriptionId;
    },


     
getPromotionHumanResourcesCommitteeId: () => {
 return PromotionHumanResourcesCommitteeId;
    },
        
getPromotionEmployeeNominationFormId: () => {
  return PromotionEmployeeNominationFormId;
    },

getPromotionEducationalQualificationsId: () => {
  return PromotionEducationalQualificationsId;
    },

getPromotionExperienceCertificatesId: () => {
  return PromotionExperienceCertificatesId;
    },

getPromotioncompetencyreportsId: () => {
  return PromotioncompetencyreportsId;
    },

getPromotionpassportId: () => {
  return PromotionpassportId;
    },
       
getPromotionSummaryofregistrationforcitizensId: () => {
  return PromotionSummaryofregistrationforcitizensId;
    },

getPromotionIDcardId: () => {
        return PromotionIDcardId;
    },
getPromotionCIDId: () => {
        return PromotionCIDId;
    },
getPromotionMedicalfitnesscheckupId: () => {
        return         PromotionMedicalfitnesscheckupId;
    },
getPromotionAnotheremployeesselfstatementId: () => {
        return         PromotionAnotheremployeesselfstatementId;
    },
    setBase64ForPromotionEmployeeCV: (base64) => {
        base64ForPromotionEmployeeCV = base64;
    },

    getBase64ForPromotionEmployeeCV: () => {
        return base64ForPromotionEmployeeCV;
    },

 setBase64ForPromotionEmployeePhoto: (base64) => {
        base64ForPromotionEmployeePhoto = base64;
    },

    getBase64ForPromotionEmployeePhoto: () => {
        return base64ForPromotionEmployeePhoto;
    },


 setBase64ForPromotionpositionofthenominatedposition: (base64) => {
        base64ForPromotionpositionofthenominatedposition = base64;
    },

    getBase64ForPromotionpositionofthenominatedposition: () => {
        return base64ForPromotionpositionofthenominatedposition;
    },


 setBase64ForPromotionjobdescription: (base64) => {
        base64ForPromotionjobdescription = base64;
    },

    getBase64ForPromotionjobdescription: () => {
        return base64ForPromotionjobdescription;
    },


 setBase64ForPromotionHumanResourcesCommittee: (base64) => {
        base64ForPromotionHumanResourcesCommittee = base64;
    },

    getBase64ForPromotionHumanResourcesCommittee: () => {
        return base64ForPromotionHumanResourcesCommittee;
    },


 setBase64ForPromotionEmployeeNominationForm: (base64) => {
        base64ForPromotionEmployeeNominationForm = base64;
    },

    getBase64ForPromotionEmployeeNominationForm: () => {
        return base64ForPromotionEmployeeNominationForm;
    },


 setBase64ForPromotionEducationalQualifications: (base64) => {
        base64ForPromotionEducationalQualifications = base64;
    },

    getBase64ForPromotionEducationalQualifications: () => {
        return base64ForPromotionEducationalQualifications;
    },


 setBase64ForPromotionExperienceCertificates: (base64) => {
        base64ForPromotionExperienceCertificates = base64;
    },

    getBase64ForPromotionExperienceCertificates: () => {
        return base64ForPromotionExperienceCertificates;
    },


 setBase64ForPromotioncompetencyreports: (base64) => {
        base64ForPromotioncompetencyreports = base64;
    },

    getBase64ForPromotioncompetencyreports: () => {
        return base64ForPromotioncompetencyreports;
    },


 setBase64ForPromotionpassport: (base64) => {
        base64ForPromotionpassport= base64;
    },

    getBase64ForPromotionpassport: () => {
        return base64ForPromotionpassport;
    },


 setBase64ForPromotionSummaryofregistrationforcitizens: (base64) => {
        base64ForPromotionSummaryofregistrationforcitizens = base64;
    },

    getBase64ForPromotionSummaryofregistrationforcitizens: () => {
        return base64ForPromotionSummaryofregistrationforcitizens;
    },


 setBase64ForPromotionIDcard: (base64) => {
        base64ForPromotionIDcard = base64;
    },

    getBase64ForPromotionIDcard: () => {
        return base64ForPromotionIDcard;
    },


 setBase64ForPromotionCID: (base64) => {
        base64ForPromotionCID = base64;
    },

    getBase64ForPromotionCID: () => {
        return base64ForPromotionCID;
    },


 setBase64ForPromotionMedicalfitnesscheckup: (base64) => {
        base64ForPromotionMedicalfitnesscheckup = base64;
    },

    getBase64ForPromotionMedicalfitnesscheckup: () => {
        return base64ForPromotionMedicalfitnesscheckup;
    },


 setBase64ForPromotionAnotheremployeesselfstatement: (base64) => {
        base64ForPromotionAnotheremployeesselfstatement = base64;
    },

    getBase64ForPromotionAnotheremployeesselfstatement: () => {
        return base64ForPromotionAnotheremployeesselfstatement;
    },


    setBase64ForDisciplinaryboardformalrequestletter: (base64) => {
        base64ForDisciplinaryboardformalrequestletter = base64;
    },

    getBase64ForDisciplinaryboardformalrequestletter: () => {
        return base64ForDisciplinaryboardformalrequestletter;
    },

    setBase64ForDisciplinaryboardEmployeeViloation: (base64) => {
        base64ForDisciplinaryboardEmployeeViloation = base64;
    },

    getBase64ForDisciplinaryboardEmployeeViloation: () => {
        return base64ForDisciplinaryboardEmployeeViloation;
    },

    setBase64ForDisciplinaryboardEmployeeinvestigation: (base64) => {
        base64ForDisciplinaryboardEmployeeinvestigation = base64;
    },

    getBase64ForDisciplinaryboardEmployeeinvestigation: () => {
        return base64ForDisciplinaryboardEmployeeinvestigation;
    },
   

    GetTrasolURL:(token)=>
    {
        
        return `${trasolURL}${token}`;
    },


    getUrl: (endPoint) => {
        if(endPoint=='version')
        {
            return `${domainUrl}${endPoint}`;
        }
        if(endPoint=='SaveSCIRequest')
        {
            return `${DYNAPIURL}${endPoint}`;
        }
        else if(endPoint=='GenerateSystemReport')
        {
            return `${baseUrl}${endPoint}`;
        }
        else
        {
            return `${baseUrl}${endPoint}`;
        }
        
    },

    /*getDomainUrl: (endPoint) => {
        return `${domainUrl}${endPoint}`;
    },*/

    getNationalId: () => {
        return nationalId;
    },

    getDraftId: () => {
        return draftId;
    },
 getCanViewTree: () => {
        return CanViewTree;
    }
    ,setCanViewTree: (value) => {
         CanViewTree = value;
    },
    // account type method getAccountTypeEmployee
    getAccountTypeEmployeeForPolicies: () => {
        return accountTypeEmployeeForPolicies;
    },
    getAccountTypeResearchSectionManager: () => {
        return accountTypeResearchSectionManager;
    },
    getAccountTypePolicesSectionMager: () => {
        return accountTypePolicesSectionMager;
    },

    getAccountTypeEmployeeForSalaries: () => {
        return accountTypeEmployeeForSalaries;
    },

    getAccountTypeEmployeeForData: () => {
        return accountTypeEmployeeForData;
    },

    getCurrentTreeView: () => {
        return currenttreeview;
    },
    setCurrentTreeView: (value) => {
         currenttreeview=value;
    },
    getAccountTypeEmployeeForHRMS: () => {
        return accountTypeEmployeeForHRMS;
    },

    getAccountTypeEmployeeForOrgnisation: () => {
        return accountTypeEmployeeForOrgnisation;
    },

    getAccountTypeSectorManager: () => {
        return accountTypeSectorManager;
    },
    getAccountTypeCultureandTalentManagementSectorManager:()=>
    {
return accountTypeCultureandTalentManagementSectorManager;
    },
    getAccountTypePoliciesDeptManager: () => {
        return accountTypePoliciesDeptManager;
    },
    getAccountPolicesector: () => {
        return accountPolicesector;
    },
    getAccountResearchSector: () => {
        return accountResearchSector;
    },

    getAccountTypeSalariesDeptManager: () => {
        return accountTypeSalariesDeptManager;
    },

    getAccountTypeDataDeptManager: () => {
        return accountTypeDataDeptManager;
    },

    getAccountTypeOrganizationDeptManager: () => {
        return accountTypeOrganizationDeptManager;
    },

    getAccountTypeHRMSDeptManager: () => {
        return accountTypeHRMSDeptManager;
    },

    getAccountTypeDataDeptManager: () => {
        return accountTypeDataDeptManager;
    },
    
    getAccountTypeGMOffice: () => {
        return accountTypeGMOffice;
    },
    // End of account type

    getAccountTypeSPOfiice: () => {
        return accountTypeSPOffice;
    },

    getDataDepartmentId: () => {
        return dataDepartmentId;
    },

    getRetirmentJobContractId: () => {
        return retirmentJobContractId;
    },

    getRetirmentMedicalApprovalId: () => {
        return retirmentMedicalApprovalId;
    },

    getRetirmentCopyOfPassportId: () => {
        return retirmentCopyOfPassportId;
    },

    getRetirmentFamilyBookId: () => {
        return retirmentFamilyBookId;
    },

    getRetirmenEmiratesId: () => {
        return retirmenEmiratesId;
    },

    getRetirmenAnnualPerformanceEvaluationId: () => {
        return retirmenAnnualPerformanceEvaluationId;
    },

    getRetirmenCopyOfPreviousApprovalId: () => {
        return retirmenCopyOfPreviousApprovalId;
    },

    getRetirmenEmployeeCvId: () => {
        return retirmenEmployeeCvId;
    },

    getRetirmenPersonalPhotoId: () => {
        return retirmenPersonalPhotoId;
    },

    getAdHousingAttachedFileForHraStudyId: () => {
        return adHousingAttachedFileForHraStudyId;
    },

    getAdHousingEntityFormalRequestId: () => {
        return adHousingEntityFormalRequestId;
    },

    getHraStudyForAdHousingId: () => {
        return hraStudyForAdHousingId;
    },


    getAdHousingAttachedFileForEID: () => {
        return EidcopyForAdHousingId;
    },
    getAdHousingAttachedFileForPassport: () => {
        return passportcopyForAdHousingId;
    },
    getAdHousingAttachedFileForFamilyBook: () => {
        return familybooknumberForAdHousingId;
    },
    getAdHousingAttachedFileForSupportRequestId: () => {
        return adHousingAttachedFileForSupportRequestId;
    },

    getAdHousingAttachedFileForEmployee: () => {
        return adHousingAttachedFileForEmployee;
    },

    getAdHousingAttachedFileForFlat: () => {
        return adHousingAttachedFileForFlat;
    },

    getBonusApprovedGradesAndSalarieId: () => {
        return bonusApprovedGradesAndSalarieId;
    },

    getBonusEntityLogoId: () => {
        return bonusEntityLogoId;
    },

    getBonusBalancedScoreCardId: () => {
        return bonusBalancedScoreCardId;
    },

    getBonusUploadExcelAfterFillId: () => {
        return bonusUploadExcelAfterFillId;
    },

    getPresentationforEntityAcheivementsId: () => {
        return presentationforEntityAcheivementsId;
    },
    // getBonusTaxSummaryId: () => {
    //     return bonusTaxSummaryId;
    // },

    getBonusFinacialStatementId: () => {
        return bonusFinacialStatementId;
    },

    getBonusResultForEvalutionForGeneralPerformanceId: () => {
        return bonusResultForEvalutionForGeneralPerformanceId;
    },

    getBonusBudgetReletedDocumentId: () => {
        return bonusBudgetReletedDocumentId;
    },

    getBonusForPrivateContractId: () => {
        return bonusForPrivateContractId;
    },

    getHraStudyForBonusId: () => {
        return hraStudyForBonusId;
    },

    getApprovalEmployeeId: () => {
        return approvalEmployeeId;
    },

    getBorrowingEntityId: () => {
        return borrowingEntityId;
    },

    getHrCommitteId: () => {
        return hrCommitteId;
    },

    getCompetentEntityId: () => {
        return competentEntityId;
    },

    getFederalCurrentJobDescId:()=>
    {
    return FederalCurrentJobDescId;
},

    getHraStudyForSecondmentId: () => {
        return hraStudyForSecondmentId;
    },

    getJobDescriptionId: () => {
        return jobDescriptionId;
    },

    getLastYearEfficiencyReportId: () => {
        return lastYearEfficiencyReportId;
    },

    getEmaritesId: () => {
        return emaritesId;
    },

    getPassportCopyId: () => {
        return passportCopyId;
    },

    getFamilyBookId: () => {
        return familyBookId;
    },

    getPatientPassortCopyId: () => {
        return patientPassortCopyId;
    },

    getPatientMedicalReportId: () => {
        return patientMedicalReportId;
    },

    getPatientLetterFromEmbassyId: () => {
        return patientLetterFromEmbassyId;
    },

    getPatientCopyOfdDigestId: () => {
        return patientCopyOfdDigestId;
    },

    getPatientHumanResourceComitteRecordId: () => {
        return patientHumanResourceComitteRecordId;
    },

    getPatientFinancialObligationId: () => {
        return patientFinancialObligationId;
    },
  
    getPatientEmiratesId: () => {
        return patientEmiratesId;
    },

    getPatientLetterFromHeadOfEntityId: () => {
        return patientLetterFromHeadOfEntityId;
    },
    
    getpatientDetailedsalaryCertificateId:()=>{return patientDetailedsalaryCertificate;},

    getHraStudyForPatientId: () => {
        return hraStudyForPatientId;
    },

    getHrReviewFormalRequestId: () => {
        return hrReviewFormalRequestId;
    },

    getHrReviewStandardComparisonId: () => {
        return hrReviewStandardComparisonId;
    },

    getHrReviewStudiesId: () => {
        return hrReviewStudiesId;
    },

    getHraStudyForHrReviewId: () => {
        return hraStudyForHrReviewId;
    },

    getAmendEmployeeSalPreviousCopyOfLastProcedureId: () => {
        return amendEmployeeSalPreviousCopyOfLastProcedureId;
    },

    getHraStudyForEmployeeSalID: () => {
        return hraStudyForEmployeeSalId;
    },


    getAmendEmployeeSalPreviousCompentaencyReportId: () => {
        return amendEmployeeSalPreviousCompentaencyReportId;
    },

    getAmendEmployeeSalEmiratesId: () => {
        return amendEmployeeSalEmiratesId;
    },

    getAmendEmployeeSalEmpPhotoId: () => {
        return amendEmployeeSalEmpPhotoId;
    },

    getAmendEmployeeSalAprrovedScaleAndSalariedId: () => {
        return amendEmployeeSalAprrovedScaleAndSalariedId;
    },

    getAmendSalaryScaleChairmanApproveAttachmentId: () => {
        return amendSalaryScaleChairmanApproveAttachmentId;
    },

    getHraStudyForAmendSalaryScaleId: () => {
        return hraStudyForAmendSalaryScaleId;
    },

    getAmendSalaryScaleAnnualCostAttachmentId: () => {
        return amendSalaryScaleAnnualCostAttachmentId;
    },

    getAmendSalaryScaleAdminAuthIsRegisteredAttachmentId: () => {
        return amendSalaryScaleAdminAuthIsRegisteredAttachmentId;
    },

    getAmendSalaryScaleCurrentAndProposedSalaryLineAttachmentId: () => {
        return amendSalaryScaleCurrentAndProposedSalaryLineAttachmentId;
    },

    getAmendSalaryScaleStudyWithSimilarEntityAttachmentId: () => {
        return amendSalaryScaleStudyWithSimilarEntityAttachmentId;
    },

    getAmendSalaryScaleAlignmentTableGradeAttachmentId: () => {
        return amendSalaryScaleAlignmentTableGradeAttachmentId;
    },

    getAmendSalaryScalePropsedScaleAttachmentId: () => {
        return amendSalaryScalePropsedScaleAttachmentId;
    },

    getAmendSalaryScaleSalariesAndConpansationAttachmentId: () => {
        return amendSalaryScaleSalariesAndConpansationAttachmentId;
    },

    getAmendSalaryScaleApprovedScaleAndGradeAttachmentId: () => {
        return amendSalaryScaleApprovedScaleAndGradeAttachmentId;
    },

    getSciAttachmentId: () => {
        return sciAttachmentId;
    },

    getSciReplyAttachmentId: () => {
        return sciReplyAttachmentId;
    },

    getInformationRequestAttachmentId: () => {
        return informationRequestAttachmentId;
    },

    getInformationRequestFeedbackAttachmentId: () => {
        return informationRequestFeedbackAttachmentId;
    },

    getReplacePlansForNonLocalsId: () => {
        return replacePlansForNonLocalsId;

    },

    getRETSummaryofregistrationforcitizensId: () => {
        return RETSummaryofregistrationforcitizensId;

    },
   
    getRETPreviousapprovalsfortheextensionId: () => {
        return RETPreviousapprovalsfortheextensionId;

    },
    getRETQualificationsId: () => {
        return RETQualificationsId;

    },
    getRETJobdescriptionId: () => {
        return RETJobdescriptionId;

    },
    getRETCareersuccessionplanId: () => {
        return RETCareersuccessionplanId;

    },
    getRETHRComId: () => {
        return RETHRComId;

    },
    getRETOtherAttachId: () => {
        return RETOtherAttachId;

    },
    
    getRETPoistioninStructureId: () => {
        return RETPoistioninStructureId;

    },

    getServiceSideBar: (servicename, isArabic,that,flag) =>
    {
        flag = 0;
        
        switch (servicename) {
            case 'StrategicPlanningforworkforce':
                {return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

              
                { header: false, active:true , name: i18n[isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Title, fn: () => that.props.history.replace('/StrategicPlanningforworkforce'), class: 'far fa-list-alt', img: 'information_request.png' },

                { header: false, active:  false, name: i18n[isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Enquire,fn: () => that.props.history.replace('/landing', { pathname: 'StrategicPlanningforworkforceEnquire' }),class: 'far fa-list-alt', img: 'information_request.png' }   
            ]);       
            break; 

            }

            case 'StrategicPlanningforworkforceEnquire':
                {return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

              
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Title, fn: () => that.props.history.replace('/StrategicPlanningforworkforce'), class: 'far fa-list-alt', img: 'information_request.png' },

                { header: false, active: true, name: i18n[isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Enquire,fn: () => that.props.history.replace('/landing', { pathname: 'StrategicPlanningforworkforceEnquire' }), class: 'far fa-list-alt', img: 'information_request.png' }   
            ]);       
            break; 

            }
            case 'Survey':
                {return ([
                   
    
                  
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Survey.Subject, fn: () => that.props.history.replace('/Survey'), class: 'far fa-list-alt', img: 'information_request.png' },
                   
                ]);       
                break; 

                }
            case'Substitutionplan':
        {
            return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

              
                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Substitutionplan.Subject, fn: () => that.props.history.replace('/Substitutionplan'), class: 'far fa-list-alt', img: 'information_request.png' },
               
            ]);       
            break; 

        }

            case'FAQ':
            {
               return ([
                   { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
   
                 
   
                  
               ]);       
               break;    }
            case 'Circularization':
        {

            // if(Config.getLocalStorageLoginData().internalcontact)
            {
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
    
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Circularization.subject, fn: () => that.props.history.replace('/Circularization'), class: 'far fa-list-alt', img: 'information_request.png' },
                ]);
            }
            break;
        }

            case 'ActionPlans':
        {
            
            if(Config.getLocalStorageLoginData().internalcontact)
            {
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
    
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].ActionPlans.subject, fn: () => that.props.history.replace('/ActionPlansListinternal'), class: 'far fa-list-alt', img: 'sci.png' },

                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].ActionPlans.KPI, fn: () => that.props.history.replace('/ActionPlanskpi'), class: 'far fa-list-alt', img: 'sci.png' },
    
                   
    
                ]);
                
            }
            else
            {
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
    
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].ActionPlans.subject, fn: () => that.props.history.replace('/ActionPlansListinternal'), class: 'far fa-list-alt', img: 'sci.png' },
    
                   
    
                ]);
            }
            break;
        }
            case'Disciplinaryboard':
            {
               return ([
                   { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
   
                   { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Disciplinaryboard.subject, fn: () => that.props.history.replace('/landing', { pathname: 'Disciplinaryboard' }), class: 'far fa-list-alt', img: 'sci.png' },
   
                  
               ]);       
               break;   }
            case'WorkShopBookedList':
            {
               return ([
                   { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
   
                   { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopBooking' }), class: 'far fa-list-alt', img: 'sci.png' },
   
                  
               ]);       
               break;  }
           case'WorkShopMyBookedList':
           {
              return ([
                  { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
  
                  { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopBooking' }), class: 'far fa-list-alt', img: 'sci.png' },
  
                 
              ]);       
              break;    }
            case'WorkShopBookinglist':
            {
               return ([
                   { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
   
                   { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopBooking' }), class: 'far fa-list-alt', img: 'sci.png' },
   
                  
               ]);       
               break;     }
             case'WorkShopBooking':
             {
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
    
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopBooking' }), class: 'far fa-list-alt', img: 'sci.png' },
    
                   
                ]);       
                break;     }
            case 'WorkShopManage':
                {
                    return ([
                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
        
                        { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopManage' }), class: 'far fa-list-alt', img: 'sci.png' },
        
                       
                    ]);       
                    break;          }

                case 'WorkShopAdd':
                    {
                        return ([
                            { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
            
                            { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopManage' }), class: 'far fa-list-alt', img: 'sci.png' },
            
                           
                        ]);       
                        break;          }
                    case 'Promotion1':
                        {
                            return ([
                                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                
                                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].WorkShop.subject, fn: () => that.props.history.replace('/landing', { pathname: 'WorkShopManage' }), class: 'far fa-list-alt', img: 'sci.png' },
                
                               
                            ]);       
                            break;             }
             case'SickLeave':
              return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].SickLeave.subject, fn: () => that.props.history.replace('/landing', { pathname: 'SickLeave' }), class: 'far fa-list-alt', img: 'sci.png' },

               
            ]);
            case'Promotion':
              return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Promotion.subject, fn: () => that.props.history.replace('/landing', { pathname: 'Promotion' }), class: 'far fa-list-alt', img: 'sci.png' },

               
            ]);
            case 'Search':
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
    
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Promotion.subject, fn: () => that.props.history.replace('/landing', { pathname: 'Promotion' }), class: 'far fa-list-alt', img: 'sci.png' },
    
                   
                ]);  
             case 'OrganizationStructure': return ([

                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].organizationStructure, fn: () => that.props.history.replace('/landing', { pathname: 'OrganizationStructure' }), class: 'far fa-list-alt', img: 'orgnisation_structure-white.png' },

               
                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].organizationStructureTree, fn: () => that.props.history.push('OrganizationStructureTree'), class: 'far fa-list-alt', img: 'orgnisation_structure-white.png' },
                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].organizationStructureTreePreview, fn: () => that.props.history.push('OrganizationStructureTreePreview'), class: 'far fa-list-alt', img: 'orgnisation_structure-white.png' }

            ]);
            case 'SCIRequest':  return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].sci, fn: () => that.props.history.replace('/landing', { pathname: 'SCIRequest' }), class: 'far fa-list-alt', img: 'sci.png' },

               
            ]);

            case 'Report': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].report, fn: () => that.props.history.replace('/landing', { pathname: 'Report' }), class: 'far fa-list-alt', img: 'sci.png' },


            ]);
  
            case 'informationRequest': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].infoRequest, fn: () => that.props.history.replace('/landing', { pathname: 'informationRequest' }), class: 'far fa-list-alt', img: 'information_request.png' }

            ]);
            case 'exemptionFromAccompanyPatient': return ([

                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].exceptionForPatient, fn: () => that.props.history.replace('/landing', { pathname: 'exemptionFromAccompanyPatient' }), class: 'far fa-list-alt', img: 'exemption_from_accompany_patient_outside_of_country.png' }
            ]);
            case 'bonusRequest': return ([

                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].bonus_Request, fn: () => that.props.history.replace('/landing', { pathname: 'bonusRequest' }), class: 'far fa-list-alt', img: 'bonus_request.png' },
            ]);
            case 'amendSalaryScreen': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].amendSalariesScale, fn: () => that.props.history.replace('/landing', { pathname: 'amendSalaryScreen' }), class: 'far fa-list-alt', img: 'amend_salary_scale.png' }
            ]);
            case 'amendEmployeeSalary': return ([

                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].amendEmployeeSalary, fn: () => that.props.history.replace('/landing', { pathname: 'amendEmployeeSalary' }), class: 'far fa-list-alt', img: 'amend_employee_salary.png' }
            ]);
            case 'exceptionADHousing': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].exceptionADHousing, fn: () => that.props.history.replace('/landing', { pathname: 'exceptionADHousing' }), class: 'far fa-list-alt', img: 'exception_of_housing_policy.png' },

            ]);
            case 'secondmentForFederalAgency': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].secondmentForFederal, fn: () => that.props.history.replace('/landing', { pathname: 'secondmentForFederalAgency' }), class: 'far fa-list-alt', img: 'secondmend_for_federal_agency.png' },

            ]);
            case 'reviewHRPolicies': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel, fn: () => that.props.history.replace('/landing', { pathname: 'reviewHRPolicies' }), class: 'far fa-list-alt', img: 'review_the_hr_policies.png' },

            ]);
            case 'exceptionOfSteerTheAgeOfRetirementRequest': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].exceptionForRetirment, fn: () => that.props.history.replace('/landing', { pathname: 'exceptionOfSteerTheAgeOfRetirementRequest' }), class: 'far fa-list-alt', img: 'exception_of_steer_age_of_retirement_request.png' }

            ]);
            case 'role': {
                flag = 1; return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                    { header: false, active: flag.toString() === '1' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].roles, fn: () => that.props.history.replace('/landing', { pathname: 'role' }), class: 'far fa-list-alt', img: 'roles_gray.png' },

                ]);
            }
            case 'entity': {
                return ([

                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].entities, fn: () => that.props.history.replace('/landing', { pathname: 'entity' }), class: 'far fa-list-alt', img: 'entities_gray.png' },
                ]);
            }
            case 'reports': {
                return ([

                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].entities, fn: () => that.props.history.replace('/landing', { pathname: 'reports' }), class: 'far fa-list-alt', img: 'entities_gray.png' },
                ]);
            }
            case 'EmployeesReports': return ([
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].canViewEmployeeReport, fn: () => that.props.history.replace('/landing', { pathname: 'EmployeesReports' }), class: 'far fa-list-alt', img: 'sci.png' },


            ]);
            
            case 'contact': {
                flag = 2; return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '2' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].contact, fn: () => that.props.history.replace('/landing', { pathname: 'contact' }), class: 'far fa-list-alt', img: 'contact_gray.png' },

                ])
            };
            case 'mailTemplate': flag = 3; {
                return ([

                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '3' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].mailTemplate, fn: () => that.props.history.replace('/landing', { pathname: 'mailTemplate' }), class: 'far fa-list-alt', img: 'mail_templte.png' },

                ]);
            }
            case 'lookUp': {
                flag = 4; return ([

                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '4' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].lookUp, fn: () => that.props.history.replace('/landing', { pathname: 'lookUp' }), class: 'far fa-list-alt', img: 'lookup.png' }


                ]);
            }
          
            case 'extendCreateObjectives': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].extendCreateObjectives, fn: () => that.props.history.replace('/landing', { pathname: 'extendCreateObjectives' }), class: 'far fa-list-alt', img: 'extend_create_objctive.png' },
                ]
            );
            case 'extendReviewObjectives': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].extendReviewObjectives, fn: () => that.props.history.replace('/landing', { pathname: 'extendReviewObjectives' }), class: 'far fa-list-alt', img: 'extend_review_objectives.png' }
                ]
            );
            case 'extendAnnualPerformance': return ([{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

            { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationInterval, fn: () => that.props.history.replace('/landing', { pathname: 'extendAnnualPerformance' }), class: 'far fa-list-alt', img: 'extend_annual_performance_evlusion.png' }
            ]);
            case 'addOrEditEmployeesPerformanceEvaluation': return ([

                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluation, fn: () => that.props.history.replace('/landing', { pathname: 'addOrEditEmployeesPerformanceEvaluation' }), class: 'far fa-list-alt', img: 'AddEditEmployeePerformance Evaluation.png' }
            ]);
            case 'joinAndImplementGovResourcesSystemForNewEntity': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntity, fn: () => that.props.history.replace('/landing', { pathname: 'joinAndImplementGovResourcesSystemForNewEntity' }), class: 'far fa-list-alt', img: 'Join_and_implement_government_resources_system_for_new_entity.png' }
                ]
            );
            case 'openComplainsStage': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].openComplainsStage, fn: () => that.props.history.replace('/landing', { pathname: 'openComplainsStage' }), class: 'far fa-list-alt', img: 'Amends_on_shared_government_HR_management_system.png' }
                ]
            );
            case 'amendsSharedGovernmentHRManagement': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystem, fn: () => that.props.history.replace('/landing', { pathname: 'amendsSharedGovernmentHRManagement' }), class: 'far fa-list-alt', img: 'Amends_on_shared_government_HR_management_system.png' }
                ]
            );
            case 'reportsAndStaticRequest': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].ReportsAndStaticRequest, fn: () => that.props.history.replace('/landing', { pathname: 'reportsAndStaticRequest' }), class: 'far fa-list-alt', img: 'stats_reports.png' }
                ]
            );
            case 'otherRequests': return (
                [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].OtherRequests, fn: () => that.props.history.replace('/landing', { pathname: 'otherRequests' }), class: 'far fa-list-alt', img: 'other_request.png' }
                ]
            );
            case 'inprogress': {
                flag = 0;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].inprogress, fn: () => that.props.history.replace('/landing', { pathname: 'inprogress' }), class: 'far fa-list-alt', img: 'in_progress.png' }
                ]);
            }  case 'Late': {
                flag = 0;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Late, fn: () => that.props.history.replace('/landing', { pathname: 'inprogress' }), class: 'far fa-list-alt', img: 'in_progress.png' }
                ]);
            }
            case 'pending': {
                flag = 1;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '1' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].pending, fn: () => that.props.history.replace('/landing', { pathname: 'pending' }), class: 'far fa-list-alt', img: 'pending_icon.png' }

                ]);
            }
            case 'Underapproval': {
                flag = 1;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '1' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].Underapproval, fn: () => that.props.history.replace('/landing', { pathname: 'Underapproval' }), class: 'far fa-list-alt', img: 'pending_icon.png' }

                ]);
            }
            case 'completed': {
                flag = 2;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                    { header: false, active: flag.toString() === '2' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].completed, fn: () => that.props.history.replace('/landing', { pathname: 'completed' }), class: 'far fa-list-alt', img: 'completed.png' }

                ]);
            }
            case 'cancelled': {
                flag = 3;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                    { header: false, active: flag.toString() === '3' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].cancelled, fn: () => that.props.history.replace('/landing', { pathname: 'cancelled' }), class: 'far fa-list-alt', img: 'cancelled.png' }
                ]);
            }
            case 'onhold': {
                flag = 4;
                return ([
                    { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },
                    { header: false, active: flag.toString() === '4' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].onhold, fn: () => that.props.history.replace('/landing', { pathname: 'onhold' }), class: 'far fa-list-alt', img: 'onhold_icon.png' }

                ]);
            }
            case 'currentUserTasks':
                return (
                    [{ header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                    { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].task,
                     fn: () => that.props.history.replace('/landing', 
                    { pathname: 'currentUserTasks' }), class: 'far fa-list-alt', img: 'task.png' }]
                );
           
            case 'Landing':
                {
                    return (
                        [
                            { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard, fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 'dashboard_white.png' },

                        { header: true, name: i18n[isArabic ? 'ar' : 'en'].general_manger_service, class: 'far fa-list-alt', img: 'amend_employee_salary.png' },

                        { header: false, name: i18n[isArabic ? 'ar' : 'en'].general_manger_wizard, fn: () => that.props.history.replace('/listpage', { pathname: 'generalManagerWizardScreen' }), class: '', img: 'sci.png' },

                        { header: false, name: i18n[isArabic ? 'ar' : 'en'].sci, fn: () => that.props.history.replace('/listpage', { pathname: 'generalManagerWizardScreen' }), class: 'far fa-list-alt', img: 'sci.png' },

                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].infoRequest, fn: () => that.props.history.replace('/listpage', { pathname: 'informationRequest' }), class: 'far fa-list-alt', img: 'information_request.png' },

                        { header: true, name: i18n[isArabic ? 'ar' : 'en'].policies, class: 'far fa-list-alt', img: 'amend_employee_salary.png' },

                        { header: false, name: i18n[isArabic ? 'ar' : 'en'].exceptionForPatient, fn: () => that.props.history.replace('/listpage', { pathname: 'exemptionFromAccompanyPatient' }), class: 'far fa-list-alt', img: 'exception_from_acccompany_patient.png' },

                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel, fn: () => that.props.history.replace('/listpage', { pathname: 'reviewHRPolicies' }), class: 'far fa-list-alt', img: 'review_hr_policies.png' },

                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].exceptionADHousing, fn: () => that.props.history.replace('/listpage', { pathname: 'exceptionADHousing' }), class: 'far fa-list-alt', img: 'expection_housing_policy.png' },

                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].secondmentForFederal, fn: () => that.props.history.replace('/listpage', { pathname: 'secondmentForFederalAgency' }), class: 'far fa-list-alt', img: 'secondement_forfederalagency.png' },

                        { header: true, name: i18n[isArabic ? 'ar' : 'en'].saleries_and_bonuos, class: 'far fa-list-alt', img: 'amend_employee_salary.png' },

                        { header: false, name: i18n[isArabic ? 'ar' : 'en'].bonus_Request, fn: () => that.props.history.replace('/listpage', { pathname: 'bonusRequest' }), class: 'far fa-list-alt', img: 'bonus_request.png' },

                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].amendSalariesScale, fn: () => that.props.history.replace('/listpage', { pathname: 'amendSalaryScreen' }), class: 'far fa-list-alt', img: 'amend_salary_scale.png' },

                        { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].amendEmployeeSalary, fn: () => that.props.history.replace('/listpage', { pathname: 'amendEmployeeSalary' }), class: 'far fa-list-alt', img: 'amend_employee_salary.png' }],
                        
                        { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].entities, 
                        fn: () => that.props.history.replace('/listpage', { pathname: 'reports' }), class: 'far fa-list-alt', img: 
                        'entities_gray.png' },
                        { header: false, active: flag.toString() === '0' ? true : false, name: i18n[isArabic ? 'ar' : 'en'].entities, 
                        fn: () => that.props.history.replace('/listpage', { pathname: 'EmployeesReports' }), class: 'far fa-list-alt', img: 
                        'employeesicon.png' },
   // organization structure
                     { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].organizationStructure,
                     fn: () => that.props.history.replace('/listpage',
                      { pathname: 'OrganizationStructure' }), class: 'far fa-list-alt', 
                     img: 'orgnisation_structure-white.png' }  
                    
                    
                     );
                    }
                default: return '';
            }
    
        },
              

// organization structure
    getSidebarForOrgStructure: (isArabic, that, flag) => {
        return (
            [
                { header: false, active: false, name: i18n[isArabic ? 'ar' : 'en'].dashboard,
                 fn: () => that.props.history.replace('/landing'), class: 'fas fa-home', img: 
                 'dashboard_white.png' },
               
                 { header: false, active: flag.toString() === '0' ? true : false, 
                name: i18n[isArabic ? 'ar' : 'en'].organizationStructure,
                 fn: () => that.props.history.replace('/ListPage', 
                { pathname: 'OrganizationStructure' }), class: 'far fa-list-alt', 
                img: 'orgnisation_structure-white.png' },

               
            ]
        )
    },

    getSidebarForhierachyContainer: (isArabic, that, flag) => {
      
    },

    getSessionLoginData: () => {
        let laginData = sessionStorage.getItem('LoginInformation');
        return JSON.parse(laginData);
        //return sessionStorage.getItem('LoginInformation');
    },

    clearSessionStorageByKey: (key) => {
        sessionStorage.removeItem(key);
    },

    setLocalStorageLoginData: (loginData) => {
        let loginDataEncrypted = encrypt(JSON.stringify(loginData));
        localStorage.setItem('LoginInformation', JSON.stringify(loginDataEncrypted));
    },
   setLocalStorageeservicesemail: (eservicesemail) => {
        let enc = encrypt(JSON.stringify(eservicesemail));
        localStorage.setItem('eservicesemail', JSON.stringify(enc));
    },
    getLocalStorageLoginData: () => {
        
        let loginData = localStorage.getItem('LoginInformation');
        let loginDataEncrypted = JSON.parse(loginData);
        return JSON.parse(decrypt(loginDataEncrypted));
    },
    getLocalStorageeservicesemail: () => {
        
        let data = localStorage.getItem('eservicesemail');
        let dec = JSON.parse(data);
        return JSON.parse(decrypt(dec));
    },
    setLocalStorageAds: () => {
       // let AdsDataEncrypted = encrypt(JSON.stringify(loginData));
        localStorage.setItem('AdsInformation', true);
    },

    getLocalStorageAdsData: () => {
        
        let loginData = localStorage.getItem('AdsInformation');
       
        return loginData;
    },

    unsetLocalStorageAds: () => {
        // let AdsDataEncrypted = encrypt(JSON.stringify(loginData));
         localStorage.setItem('AdsInformation', false);
     },

    clearLocalStorageByKey: (key) => {
        localStorage.removeItem(key);
    },

    isAuthenticate: () => {
        let tempLoginData = localStorage.getItem('LoginInformation');
        if (tempLoginData === undefined || tempLoginData === null)
            return false;
        return true;
    },
    setPatientFinancialObligationId: (value) => {
          patientFinancialObligationId = value;
    },
    getStatusPendingId: () => {
        return statusPendingId;
    },
    getCurrentEntity: () => {
        return CurrentEntityId;
    },
    setCurrentEntity: (value) => {
         CurrentEntityId=value;
    },

    getStatusInProgressId: () => {
        return statusInProgressId;
    },

    getStatusOnHoldId: () => {
        return statusOnHoldId;
    },

    getStatusCompletedId: () => {
        return statusCompletedId;
    },

    getReasonAcceptedId: () => {
        return reasonAcceptedId;
    },

    getStatusCanceledId: () => {
        return statusCanceledId;
    },

    getStatusWaitingForExecutiveOffice: () => {
        return statusWaitingForExecutiveOffice;
    },

    getReasonForDepartmentalApprovalId: () => {
        return reasonForDepartmentalApprovalId;
    },

    getReasonForSectorApprovalId: () => {
        return reasonForSectorApprovalId;
    },

    getReasonForGMApprovalId: () => {
        return reasonForGMApprovalId;
    },

    getReasonForStudyProvidedId: () => {
        return reasonForStudyProvidedId;
    },

    getReasonForReturnToEntityId: () => {
        return reasonForReturnToEntityId;
    },

    getReasonForAssignToEntityId: () => {
        return reasonForAssignToEntityId;
    },

    getReasonForExecutiveOffice: () => {
        return reasonForExecutiveOffice;
    },

    getReasonForSectorAssignedId: () => {
        return reasonForSectorAssignedId;
    },

    // getReasonForAssignToDept -- previous name
    getReasonForDepartmentAssignedId: () => {
        return reasonForDepartmentAssignedId;
    },

    getReasonForGmAssignedId: () => {
        return reasonForGmAssignedId;
    },

    getReasonForStrategicAssignedId: () => {
        return reasonForStrategicAssignedId;
    },

    getReasonForEmployeeAssignedId: () => {
        return reasonForEmployeeAssignedId;
    },

    getReasonSCISectorAssignedId: () => {
        return reasonSCISectorAssignedId;
    },

    getReasonSCIDepartmentAssignedId: () => {
        return reasonSCIDepartmentAssignedId;
    },

    getReasonSCIEmployeeassignedId: () => {
        return reasonSCIEmployeeAssignedId;
    },

    getReasonSCIInitialsubmissionId: () => {
        return reasonSCIInitialSubmissionId;
    },

    getReasonSCIFeedbackProvidedId: () => {
        return reasonSCIFeedbackProvidedId;
    },

    getReasonSCIStrategicFeedbackProvidedId: () => {
        return reasonSCIStrategicFeedbackProvidedId;
    },

    getReasonSCISectorFeedbackProvidedId: () => {
        return reasonSCISectorFeedbackProvidedId;
    },

    getReasonInformationSectorAssignedId: () => {
        return reasonInformationSectorAssignedId;
    },

    getReasonInformationDepartmentAssignedId: () => {
        return reasonInformationDepartmentAssignedId;
    },

    getReasonInformationEmployeeassignedId: () => {
        return reasonInformationEmployeeAssignedId;
    },

    getReasonInformationInitialsubmissionId: () => {
        return reasonInformationInitialSubmissionId;
    },

    getReasonInformationFeedbackProvidedId: () => {
        return reasonInformationFeedbackProvidedId;
    },

    getBonusRequestTypeId: () => {
        return bonusRequestType;
    },

    getAmendSalaryScaleRequestTypeId: () => {
        return amendSalaryScaleRequestType;
    },

    getAmendEmployeeSalaryRequestTypeId: () => {
        return amendEmployeeSalaryRequestType;
    },

    getSciRequestTypeId: () => {
        return sciRequestType;
    },
    getDisciplinaryboardTypeId:()=>
    {
        return DisciplinaryboardType;
    },
     getORGRequestTypeId: () => {
        return ORGRequestType;
    },
    getORGRequestPosition: () => {
        return ORGRequestPosition;
    },
    getInformationRequestTypeId: () => {
        return informationRequestType;
    },

    getExceptionAcompanypatientRequestTypeId: () => {
        return exceptionAcompanypatientRequestType;
    },
    getWorkShopTypeId:()=>{
        return workshopRequestType;
    },
    getActionPlanId:()=>{
        return ActionPlanRequestType;
    },
    getSickLeaveTypeId: () => {
        return SickLeaveRequestType;
    },
    getPromotionTypeId: () => {
        return PromotionRequestType;
    },

    getHrReviewPolicyRequestTypeId: () => {
        return hrReviewPolicyRequestType;
    },

    getExceptionADHousingRequestTypeId: () => {
        return exceptionADHousingRequestType;
    },

    getSecondmentForFederalAgencyRequestTypeId: () => {
        return secondmentForFederalAgencyRequestType;
    },

    getRetirmentRequestTypeId: () => {
        return retirementRequestType;
    },

    getAddAndEditEmployeePerformanceEvaluationId: () => {
        return addAndEditEmployeePerformanceEvaluationId;
    },

    getExtendAnnualPerformaceEvaluationId: () => {
        return extendAnnualPerformaceEvaluationId;
    },

    getExtendCreateObjectiveId: () => {
        return extendCreateObjectiveId;
    },

    getExtendReviewObjectiveId: () => {
        return extendReviewObjectiveId;
    },

    getJoinAndImplementId: () => {
        return joinAndImplementId;
    },

    getAmendOnSharedGovermentId: () => {
        return amendOnSharedGovermentId;
    },

    getOpenComplainsStageId: () => {
        return openComplainsStageId;
    },

    getReportAndStaticId: () => {
        return reportAndStaticId;
    },

    getOtherRequest: () => {
        return otherRequest;
    },
   

    getEntityRequestType: () => {
        return entityRequestType;
    },

    setIsEditFlag: (flag) => {
        isEditSCI = flag;
    },

    getIsEditFlag: () => {
        return isEditSCI;
    },

    setEditObj: (obj) => {
       
        editobj = obj;
    },

    getEditObj: () => {
        return editobj;
    },

    setEntityData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('EntityData', JSON.stringify(temp));
    },

    getEntityData: () => {
        let entityData = localStorage.getItem('EntityData');
        let entityDataEncrypted = JSON.parse(entityData);
        if (entityDataEncrypted) {
            return JSON.parse(decrypt(entityDataEncrypted));
        } else {
            return [];
        }
    },

    setRoleListData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('RoleListData', JSON.stringify(temp));
    },

    getRoleListData: () => {
        let roleListData = localStorage.getItem('RoleListData');
        let roleListDataEncrypted = JSON.parse(roleListData);
        return JSON.parse(decrypt(roleListDataEncrypted));
    },

    setStatusData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('StatusData', JSON.stringify(temp));
    },

    getStatusData: () => {
        let statusData = localStorage.getItem('StatusData');
        let statusDataEncrypted = JSON.parse(statusData);
        return JSON.parse(decrypt(statusDataEncrypted));
    },

    setContactData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ContactData', JSON.stringify(temp));
    },

    getContactData: () => {
        let contactData = localStorage.getItem('ContactData');
        let contactDataEncrypted = JSON.parse(contactData);
        return JSON.parse(decrypt(contactDataEncrypted));
    },

    setReasonDataForSCI: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ReasonDataForSCI', JSON.stringify(temp));
    },

    getReasonDataForSCI: () => {
        let reasonDataForSCI = localStorage.getItem('ReasonDataForSCI');
        let reasonDataForSCIEncrypted = JSON.parse(reasonDataForSCI);
        return JSON.parse(decrypt(reasonDataForSCIEncrypted));
    },

    setReasonDataForInformation: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ReasonDataForInformation', JSON.stringify(temp));
    },

    getReasonDataForInformation: () => {
        let reasonDataForInformation = localStorage.getItem('ReasonDataForInformation');
        let reasonDataForInformationEncrypted = JSON.parse(reasonDataForInformation);
        return JSON.parse(decrypt(reasonDataForInformationEncrypted));
    },

    setReasonArrayForAllOtherServices: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ReasonDataForAllOtherServices', JSON.stringify(temp));
    },

    getReasonArrayForAllOtherServices: () => {
        let reasonDataForAllServicesExculdingSciAndInformation = localStorage.getItem('ReasonDataForAllOtherServices');
        let reasonDataForAllServicesExculdingSciAndInformationEncrypted = JSON.parse(reasonDataForAllServicesExculdingSciAndInformation);
        return JSON.parse(decrypt(reasonDataForAllServicesExculdingSciAndInformationEncrypted));
    },

    setEmployeeData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('EmployeeData', JSON.stringify(temp));
    },

    getEmployeeData: () => {
       
        let EmployeeData = localStorage.getItem('EmployeeData');
        let EmployeeDataEncrypted = JSON.parse(EmployeeData);
        if(EmployeeDataEncrypted==null)
        {return EmployeeDataEncrypted;}
        else
{
        return JSON.parse(decrypt(EmployeeDataEncrypted));
}
    },

    setNationalityData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('NationalityData', JSON.stringify(temp));
    },

    getNationalityData: () => {
        let NationalityData = localStorage.getItem('NationalityData');
        let NationalityDataEncrypted = JSON.parse(NationalityData);
        return JSON.parse(decrypt(NationalityDataEncrypted));
    },
    setEfficiencyData:(obj)=>
    {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('EfficiencyData',JSON.stringify(temp));
    },
    getEfficiencyData:()=>{
        let EfficiencyData = localStorage.getItem('EfficiencyData');
        let EfficiencyDataEncrypted = JSON.parse(EfficiencyData);
        return JSON.parse(decrypt(EfficiencyDataEncrypted));
    },
    setCityData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('CityData', JSON.stringify(temp));
    },

    getCityData: () => {
        let CityData = localStorage.getItem('CityData');
        let CityDataEncrypted = JSON.parse(CityData);
        return JSON.parse(decrypt(CityDataEncrypted));
    },

    setIndustryData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('IndustryData', JSON.stringify(temp));
    },

    getIndustryData: () => {
        let IndustryData = localStorage.getItem('IndustryData');
        let IndustryDataEncrypted = JSON.parse(IndustryData);
        return JSON.parse(decrypt(IndustryDataEncrypted));
    },

    setAccountTypeData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('AccountTypeData', JSON.stringify(temp));
    },

    getAccountTypeData: () => {
        let AccountTypeData = localStorage.getItem('AccountTypeData');
        let AccountTypeDataEncrypted = JSON.parse(AccountTypeData);
        return JSON.parse(decrypt(AccountTypeDataEncrypted));
    },

    setProcedureTypeData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ProcedureTypeData', JSON.stringify(temp));
    },

    getProcedureTypeData: () => {
        let ProcedureTypeData = localStorage.getItem('ProcedureTypeData');
        let ProcedureTypeDataEncrypted = JSON.parse(ProcedureTypeData);
        return JSON.parse(decrypt(ProcedureTypeDataEncrypted));
    },

    setReasonToDeactivateData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ReasonToDeactivateData', JSON.stringify(temp));
    },

    getReasonToDeactivateData: () => {
        let ReasonToDeactivateData = localStorage.getItem('ReasonToDeactivateData');
        let ReasonToDeactivateDataEncrypted = JSON.parse(ReasonToDeactivateData);
        return JSON.parse(decrypt(ReasonToDeactivateDataEncrypted));
    },

    setContactStatusData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ContactStatusData', JSON.stringify(temp));
    },

    getContactStatusData: () => {
        let ContactStatusData = localStorage.getItem('ContactStatusData');
        let ContactStatusDataEncrypted = JSON.parse(ContactStatusData);
        return JSON.parse(decrypt(ContactStatusDataEncrypted));
    },

    setContactReasonData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('ContactReasonData', JSON.stringify(temp));
    },

    getContactReasonData: () => {
        let ContactReasonData = localStorage.getItem('ContactReasonData');
        let ContactReasonDataEncrypted = JSON.parse(ContactReasonData);
        return JSON.parse(decrypt(ContactReasonDataEncrypted));
    },

    setFunctionalBlockTypeData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('FunctionalBlockTypeData', JSON.stringify(temp));
    },

    getFunctionalBlockTypeData: () => {
        let FunctionalBlockTypeData = localStorage.getItem('FunctionalBlockTypeData');
        let FunctionalBlockTypeDataEncrypted = JSON.parse(FunctionalBlockTypeData);
        return JSON.parse(decrypt(FunctionalBlockTypeDataEncrypted));
    },

    setAdminSectorData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('AdminSectorData', JSON.stringify(temp));
    },

    getAdminSectorData: () => {
        let AdminSectorData = localStorage.getItem('AdminSectorData');
        let AdminSectorDataEncrypted = JSON.parse(AdminSectorData);
        return JSON.parse(decrypt(AdminSectorDataEncrypted));
    },

    setDepartmentData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('DepartmentData', JSON.stringify(temp));
    },

    getDepartmentData: () => {
        let DepartmentData = localStorage.getItem('DepartmentData');
        let DepartmentDataEncrypted = JSON.parse(DepartmentData);
        return JSON.parse(decrypt(DepartmentDataEncrypted));
    },

    setInternalEmployeesData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('InternalEmployeesData', JSON.stringify(temp));
    },

    getInternalEmployeesData: () => {
        let DepartmentData = localStorage.getItem('InternalEmployeesData');
        let DepartmentDataEncrypted = JSON.parse(DepartmentData);
        return JSON.parse(decrypt(DepartmentDataEncrypted));
    },

    setSectorData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('SectorData', JSON.stringify(temp));
    },

    getSectorData: () => {
        let SectorData = localStorage.getItem('SectorData');
        let SectorDataEncrypted = JSON.parse(SectorData);
        return JSON.parse(decrypt(SectorDataEncrypted));
    },

    setSystemList: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('SystemList', JSON.stringify(temp));
    },

    getSystemList: () => {
        let SectorData = localStorage.getItem('SystemList');
        let SectorDataEncrypted = JSON.parse(SectorData);
        return JSON.parse(decrypt(SectorDataEncrypted));
    },

    setMailTemplateTypeData: (obj) => {
        let temp = encrypt(JSON.stringify(obj));
        localStorage.setItem('MailTemplateTypeData', JSON.stringify(temp));
    },

    getMailTemplateTypeData: () => {
        let MailTemplateTypeData = localStorage.getItem('MailTemplateTypeData');
        let MailTemplateTypeDataEncrypted = JSON.parse(MailTemplateTypeData);
        return JSON.parse(decrypt(MailTemplateTypeDataEncrypted));
    },
    setWorkShopTimeID: (wsitem) =>
    {
      
        debugger;
        localStorage.setItem('workShopTimeID', JSON.stringify(wsitem));
        // workShopTimeID = wsitem;
        
    },
    getWorkShopTimeID: () => {
        debugger;
        workShopTimeID=  JSON.parse(localStorage.getItem('workShopTimeID'));
         return workShopTimeID;
    },

    setActionPlanID: (wsitem) =>
    {
        debugger;
        localStorage.setItem('ActionPlanID', JSON.stringify(wsitem));
        
        // workshopItem = wsitem;
        
    },
    getActionPlanID: () => {
        debugger;
        workshopItem=  JSON.parse(localStorage.getItem('ActionPlanID'));
        
        return workshopItem;
        // return workshopItem;
    },

    setWorkShopItem: (wsitem) =>
    {
        debugger;
        localStorage.setItem('workshopItem', JSON.stringify(wsitem));
        
        // workshopItem = wsitem;
        
    },
    getWorkShopItem: () => {
        debugger;
        workshopItem=  JSON.parse(localStorage.getItem('workshopItem'));
        
        return workshopItem;
        // return workshopItem;
    },
    setBase64ForSciOfficalLetter: (base64) => {
        base64ForSciOfficalLetter = base64;
    },

    getBase64ForSciOfficalLetter: () => {
        return base64ForSciOfficalLetter;
    },

    setBase64ForInformatinRequestOne: (base64) => {
        base64ForInformationRequestOne = base64;
    },

    getBase64ForInformatinRequestOne: () => {
        return base64ForInformationRequestOne;
    },

    setBase64ForInformatinRequestForReply: (base64) => {
        base64ForInformationRequestForReply = base64;
    },

    getBase64ForInformatinRequestForReply: () => {
        return base64ForInformationRequestForReply;
    },

    getHraLink: () => {
        return "https://www.hra.ae";
    },

    getFacebookLink: () => {
        return "https://www.facebook.com/humanresourcesauthority/";
    },

    getTwitterLink: () => {
        return "https://twitter.com/abudhabihra";
    },

    getGoogleLink: () => {
        return "#"
    },

    getLinkedInLink: () => {
        return "https://www.linkedin.com/company/human-resources-authority"
    },

    getYoutubeLink: () => {
        return "https://www.youtube.com/channel/UCC95tVygrj2Rrfs_ypo9q0w"
    },

    setBase64ForExemptionHousingPolicy_EntityFormalReq: (base64) => {
        base64ForExmptionHousingPolicy_EntityFormalReq = base64;
    },

    getBase64ForExemptionHousingPolicy_EntityFormalReq: () => {
        return base64ForExmptionHousingPolicy_EntityFormalReq;
    },


    setBase64ForExemptionHousingPolicy_attachFileSupport: (base64) => {
        base64ForExmptionHousingPolicy_attachFileSupport = base64;
    },
   

    getBase64ForExemptionHousingPolicy_attachFileSupport: () => {
        return base64ForExmptionHousingPolicy_attachFileSupport;
    },

    setBase64ForExemptionHousingPolicy_Passport: (base64) => {
        base64ForExmptionHousingPolicy_Passport = base64;
    },
    getBase64ForExemptionHousingPolicy_Passport: () => {
        return base64ForExmptionHousingPolicy_Passport;
    },
    setBase64ForExemptionHousingPolicy_familybooknumber: (base64) => {
        base64ForExmptionHousingPolicy_familybooknumber = base64;
    },
    getBase64ForExemptionHousingPolicy_familybooknumber: () => {
        return base64ForExmptionHousingPolicy_familybooknumber;
    },
    setBase64ForExemptionHousingPolicy_EID: (base64) => {
        base64ForExmptionHousingPolicy_EID = base64;
    },
    getBase64ForExemptionHousingPolicy_EID: () => {
        return base64ForExmptionHousingPolicy_EID;
    },

    setBase64ForExemptionHousingPolicy_HRA_study: (base64) => {
        base64ForExmptionHousingPolicy_HRA_study = base64;
    },

    getBase64ForExemptionHousingPolicy_HRA_study: () => {
        return base64ForExmptionHousingPolicy_HRA_study;
    },

    setBase64ForBonusRequest_EntityLogoFileChange: (base64) => {
        base64ForrBonusRequest_EntityLogoFileChange = base64;
    },

    getBase64ForBonusRequest_EntityLogoFileChange: () => {
        return base64ForrBonusRequest_EntityLogoFileChange;
    },

    setBase64rBonusRequest_ForApprovedGrades: (base64) => {
        base64rBonusRequest_approvedGradesAndSalaries = base64;
    },

    getBase64rBonusRequest_ForApprovedGrades: () => {
        return base64rBonusRequest_approvedGradesAndSalaries;
    },

    setBase64rBonusRequest_ForBudgeDoc: (base64) => {
        base64rBonusRequest_BudgetDoc = base64;
    },

    getBase64ForBonusRequest_BudgeDoc: () => {
        return base64rBonusRequest_BudgetDoc;
    },

    setBase64BonusRequest_FinancialStatement: (base64) => {
        base64BonusRequest_FinancialStatement = base64;
    },

    getBase64BonusRequest_FinancialStatement: () => {
        return base64BonusRequest_FinancialStatement;
    },

    setBase64BonusRequest_PresentationforEntityAcheivements: (base64) => {
        base64BonusRequest_PresentationforEntityAcheivements = base64;
    },

    getBase64BonusRequest_PresentationforEntityAcheivements: () => {
        return base64BonusRequest_PresentationforEntityAcheivements;
    },

    setBase64BonusRequest_TaxSummary: (base64) => {
        base64BonusRequest_TaxSummary = base64;
    },

    getBase64BonusRequest_TaxSummary: () => {
        return base64BonusRequest_TaxSummary;
    },

    setBase64BonusRequest_BalanceScoreCard: (base64) => {
        base64BonusRequest_BalanceScoreCard = base64;
    },

    getBase64BonusRequest_BalanceScoreCard: () => {
        return base64BonusRequest_BalanceScoreCard;
    },

    setBase64BonusRequest_UploadContrACT: (base64) => {
        base64resultsOfBonusRequest_UploadContract = base64;
    },

    getBase64BonusRequest_UploadContrACT: () => {
        return base64resultsOfBonusRequest_UploadContract;
    },

    setBase64BonusRequest_resultsOfEvaluationGeneralPerformance: (base64) => {
        base64resultsOfBonusRequest_EvaluationGeneralPerformance = base64;
    },

    getBase64BonusRequest_resultsOfEvaluationGeneralPerformance: () => {
        return base64resultsOfBonusRequest_EvaluationGeneralPerformance;
    },

    setBase64BonusRequest_uploadExcelAfterFill: (base64) => {
        base64resultsOfBonusRequest_uploadExcelAfterFill = base64;
    },

    getBase64BonusRequest_uploadExcelAfterFill: () => {
        return base64resultsOfBonusRequest_uploadExcelAfterFill;
    },

    setBase64ForSecondment_reqForBrowEntity: (base64) => {
        base64ForSecondment_reqForBrowEntity = base64;
    },

    getBase64ForSecondment_reqForBrowEntity: () => {
        return base64ForSecondment_reqForBrowEntity;
    },
    setBase64ForSecondment_CurrentJobDesc: (base64) => {
        base64ForSecondment_CurrentJobDesc = base64;
    },

    getBase64ForSecondment_CurrentJobDesc: () => {
        return base64ForSecondment_CurrentJobDesc;
    },
    setBase64ForSecondment_reqForCompEntity: (base64) => {
        base64ForSecondment_reqForCompEntity = base64;
    },

    getBase64ForSecondment_reqForCompEntity: () => {
        return base64ForSecondment_reqForCompEntity;
    },

    setBase64ForSecondment_jobDescFileName: (base64) => {
        base64ForSecondment_jobDescFileName = base64;
    },

    getBase64ForSecondment_jobDescFileName: () => {
        return base64ForSecondment_jobDescFileName;
    },

    setBase64ForSecondment_approvalEmpFileName: (base64) => {
        base64ForSecondment_approvalEmpFileName = base64;
    },

    getBase64ForSecondment_approvalEmpFileName: () => {
        return base64ForSecondment_approvalEmpFileName;
    },

    setBase64ForSecondment_copyOfHRCommitteFileName: (base64) => {
        base64ForSecondment_copyOfHRCommitteFileName = base64;
    },

    getBase64ForSecondment_copyOfHRCommitteFileName: () => {
        return base64ForSecondment_copyOfHRCommitteFileName;
    },

    setBase64ForSecondment_copyOfLastYearEffiReportFileName: (base64) => {
        base64ForSecondment_copyOfLastYearEffiReportFileName = base64;
    },

    getBase64ForSecondment_copyOfLastYearEffiReportFileName: () => {
        return base64ForSecondment_copyOfLastYearEffiReportFileName;
    },

    setBase64ForSecondment_emiratesIdFileName: (base64) => {
        base64ForSecondment_emiratesIdFileName = base64;
    },

    getBase64ForSecondment_emiratesIdFileName: () => {
        return base64ForSecondment_emiratesIdFileName;
    },

    setBase64ForSecondment_passportCopyFileName: (base64) => {
        base64ForSecondment_passportCopyFileName = base64;
    },

    getBase64ForSecondment_passportCopyFileName: () => {
        return base64ForSecondment_passportCopyFileName;
    },

    setBase64ForSecondment_famliyBookFileName: (base64) => {
        base64ForSecondment_famliyBookFileName = base64;
    },

    getBase64ForSecondment_famliyBookFileName: () => {
        return base64ForSecondment_famliyBookFileName;
    },

    setBase64ForSecondment_hraEmpStudyFileName: (base64) => {
        base64ForSecondment_hraEmpStudyFileName = base64;
    },

    getBase64ForSecondment_hraEmpStudyFileName: () => {
        return base64ForSecondment_hraEmpStudyFileName;
    },

    getbase64ofAmendSalaryScreenfile1: () => {
        return base64ofAmendSalaryScreenfile1;
    },

    setbase64ofAmendSalaryScreenfile1: (base64) => {
        base64ofAmendSalaryScreenfile1 = base64;
    },

    getbase64ofAmendSalaryScreenfile2: () => {
        return base64ofAmendSalaryScreenfile2;
    },

    setbase64ofAmendSalaryScreenfile2: (base64) => {
        base64ofAmendSalaryScreenfile2 = base64;
    },

    getbase64ofAmendSalaryScreenfile3: () => {
        return base64ofAmendSalaryScreenfile3;
    },

    setbase64ofAmendSalaryScreenfile3: (base64) => {
        base64ofAmendSalaryScreenfile3 = base64;
    },

    getbase64ofAmendSalaryScreenfile4: () => {
        return base64ofAmendSalaryScreenfile4;
    },

    setbase64ofAmendSalaryScreenfile4: (base64) => {
        base64ofAmendSalaryScreenfile4 = base64;
    },

    getbase64ofAmendSalaryScreenfile5: () => {
        return base64ofAmendSalaryScreenfile5;
    },

    setbase64ofAmendSalaryScreenfile5: (base64) => {
        base64ofAmendSalaryScreenfile5 = base64;
    },

    getbase64ofAmendSalaryScreenfile6: () => {
        return base64ofAmendSalaryScreenfile6;
    },

    setbase64ofAmendSalaryScreenfile6: (base64) => {
        base64ofAmendSalaryScreenfile6 = base64;
    },

    getbase64ofAmendSalaryScreenfile7: () => {
        return base64ofAmendSalaryScreenfile7;
    },

    setbase64ofAmendSalaryScreenfile7: (base64) => {
        base64ofAmendSalaryScreenfile7 = base64;
    },

    getbase64ofAmendSalaryScreenfile8: () => {
        return base64ofAmendSalaryScreenfile8;
    },

    setbase64ofAmendSalaryScreenfile8: (base64) => {
        base64ofAmendSalaryScreenfile8 = base64;
    },

    getbase64ofAmendSalaryScreenfile9: () => {
        return base64ofAmendSalaryScreenfile9;
    },

    setbase64ofAmendSalaryScreenfile9: (base64) => {
        base64ofAmendSalaryScreenfile9 = base64;
    },

    getbase64ofAmendSalaryScreenfile10: () => {
        return base64ofAmendSalaryScreenfile10;
    },

    setbase64ofAmendSalaryScreenfile10: (base64) => {
        base64ofAmendSalaryScreenfile10 = base64;
    },

    setBase64ForReviewHRPoliciesOne: (base64) => {
        base64ForReviewHRPoliciesOne = base64;
    },

    getBase64ForReviewHRPoliciesOne: () => {
        return base64ForReviewHRPoliciesOne;
    },

    setBase64ForReviewHRPoliciesTwo: (base64) => {
        base64ForReviewHRPoliciesTwo = base64;
    },

    getBase64ForReviewHRPoliciesTwo: () => {
        return base64ForReviewHRPoliciesTwo;
    },

    setBase64ForReviewHRPoliciesThree: (base64) => {
        base64ForReviewHRPoliciesThree = base64;
    },

    getBase64ForReviewHRPoliciesThree: () => {
        return base64ForReviewHRPoliciesThree;
    },

    setBase64ForReviewHRPoliciesFour: (base64) => {
        base64ForReviewHRPoliciesFour = base64;
    },

    getBase64ForInformatinRequestFour: () => {
        return base64ForReviewHRPoliciesFour;
    },

    setbase64ForAmendEmployeeSalaryOne: (base64) => {
        base64ForAmendEmployeeSalaryOne = base64;
    },

    getbase64ForAmendEmployeeSalaryOne: () => {
        return base64ForAmendEmployeeSalaryOne;
    },

    setbase64ForAmendEmployeeSalaryTwo: (base64) => {
        base64ForAmendEmployeeSalaryTwo = base64;
    },

    getbase64ForAmendEmployeeSalaryTwo: () => {
        return base64ForAmendEmployeeSalaryTwo;
    },

    setbase64ForAmendEmployeeSalaryThree: (base64) => {
        base64ForAmendEmployeeSalaryThree = base64;
    },

    getbase64ForAmendEmployeeSalaryThree: () => {
        return base64ForAmendEmployeeSalaryThree;
    },

    setbase64ForAmendEmployeeSalaryFour: (base64) => {
        base64ForAmendEmployeeSalaryFour = base64;
    },

    getbase64ForAmendEmployeeSalaryFour: () => {
        return base64ForAmendEmployeeSalaryFour;
    },

    setbase64ForAmendEmployeeSalaryFive: (base64) => {
        base64ForAmendEmployeeSalaryFive = base64;
    },

    getbase64ForAmendEmployeeSalaryFive: () => {
        return base64ForAmendEmployeeSalaryFive;
    },

    setbase64ForAmendEmployeeSalarySix: (base64) => {
        base64ForAmendEmployeeSalarySix = base64;
    },

    getbase64ForAmendEmployeeSalarySix: () => {
        return base64ForAmendEmployeeSalarySix;
    },

    setbase64ForexemptionFromAccompanyPatient1: (base64) => {
        base64ForexemptionFromAccompanyPatient1 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient1: () => {
        return base64ForexemptionFromAccompanyPatient1;
    },

    setbase64ForexemptionFromAccompanyPatient2: (base64) => {
        base64ForexemptionFromAccompanyPatient2 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient2: () => {
        return base64ForexemptionFromAccompanyPatient2;
    },

    setbase64ForexemptionFromAccompanyPatient3: (base64) => {
        base64ForexemptionFromAccompanyPatient3 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient3: () => {
        return base64ForexemptionFromAccompanyPatient3;
    },

    setbase64ForexemptionFromAccompanyPatient4: (base64) => {
        base64ForexemptionFromAccompanyPatient4 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient4: () => {
        return base64ForexemptionFromAccompanyPatient4;
    },

    setbase64ForexemptionFromAccompanyPatient5: (base64) => {
        base64ForexemptionFromAccompanyPatient5 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient5: () => {
        return base64ForexemptionFromAccompanyPatient5;
    },

    setbase64ForexemptionFromAccompanyPatient6: (base64) => {
        base64ForexemptionFromAccompanyPatient6 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient6: () => {
        return base64ForexemptionFromAccompanyPatient6;
    },

    setbase64ForexemptionFromAccompanyPatient7: (base64) => {
        base64ForexemptionFromAccompanyPatient7 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient7: () => {
        return base64ForexemptionFromAccompanyPatient7;
    },

    setbase64ForexemptionFromAccompanyPatient8: (base64) => {
        base64ForexemptionFromAccompanyPatient8 = base64;
    },

    getbase64ForexemptionFromAccompanyPatient8: () => {
        return base64ForexemptionFromAccompanyPatient8;
    },
    setbase64ForexemptionFromAccompanyPatient9: (base64) => {
        base64ForexemptionFromAccompanyPatient9 = base64;
    },
    getbase64ForexemptionFromAccompanyPatient9: () => {
        return base64ForexemptionFromAccompanyPatient9;
    },

    setbase64ForexemptionEmiratedID: (base64) => {
        base64ForexemptionEmiratedID = base64;
    },

    getbase64ForexemptionEmiratedID: () => {
        return base64ForexemptionEmiratedID;
    },

    //Exception Of Retirement Request
    setBase64ForJobContract: (base64) => {
        base64ForJobContract = base64;
    },

    getBase64ForJobContract: () => {
        return base64ForJobContract;
    },

    // MGM
    setBase64ForSummaryofregistrationforcitizens: (base64) => {
        base64ForSummaryofregistrationforcitizens = base64;
    },

    

    getBase64ForSummaryofregistrationforcitizens: () => {
        return base64ForSummaryofregistrationforcitizens;
    },

    setBase64ForPoistioninStructure: (base64) => {
        base64ForPoistioninStructure = base64;
    },

    getBase64ForPoistioninStructure: () => {
        return base64ForPoistioninStructure;
    },

    setBase64ForPreviousapprovalsfortheextension: (base64) => {
        base64ForPreviousapprovalsfortheextension = base64;
    },

    getBase64ForPreviousapprovalsfortheextension: () => {
        return base64ForPreviousapprovalsfortheextension;
    },

    setBase64ForQualifications: (base64) => {
        base64ForQualifications = base64;
    },

    getBase64ForQualifications: () => {
        return base64ForQualifications;
    },

    setBase64ForJobdescription: (base64) => {
        base64ForJobdescription = base64;
    },

    getBase64ForJobdescription: () => {
        return base64ForJobdescription;
    },

    setBase64ForCareersuccessionplan: (base64) => {
        base64ForCareersuccessionplan = base64;
    },

    getBase64ForCareersuccessionplan: () => {
        return base64ForCareersuccessionplan;
    },

    setBase64ForHRCom: (base64) => {
        base64ForHRCom = base64;
    },

    getBase64ForHRCom: () => {
        return base64ForHRCom;
    },

    setBase64ForOtherAttach: (base64) => {
        base64ForOtherAttach = base64;
    },

    getBase64ForOtherAttach: () => {
        return base64ForOtherAttach;
    },

    // MGM

    setBase64MedicalApprovalsFromTheCometentMedicalCommittee: (base64) => {
        base64MedicalApprovalsFromTheCometentMedicalCommittee = base64;
    },

    getbase64MedicalApprovalsFromTheCometentMedicalCommittee: () => {
        return base64MedicalApprovalsFromTheCometentMedicalCommittee;
    },

    setBase64ForPassport: (base64) => {
        base64ForPassport = base64;
    },

    getbase64ForPassport: () => {
        return base64ForPassport;
    },

    setBase64ForFamilyBook: (base64) => {
        base64ForFamilyBook = base64;
    },

    getbase64ForFamilyBook: () => {
        return base64ForFamilyBook;
    },

    setBase64ForEmaritesID: (base64) => {
        base64ForEmaritesID = base64;
    },

    getbase64ForEmaritesID: () => {
        return base64ForEmaritesID;
    },

    setBase64ForAnnualPerformanceEvaluationattach: (base64) => {
        base64ForAnnualPerformanceEvaluationattach = base64;
    },

    getbase64ForAnnualPerformanceEvaluationattach: () => {
        return base64ForAnnualPerformanceEvaluationattach;
    },

    setBase64ForAcopyofthepreviousapprovals: (base64) => {
        base64ForAcopyofthepreviousapprovals = base64;
    },

    getbase64ForAcopyofthepreviousapprovals: () => {
        return base64ForAcopyofthepreviousapprovals;
    },

    setBase64ForEmployeeCV: (base64) => {
        base64ForEmployeeCV = base64;
    },

    getbase64ForEmployeeCV: () => {
        return base64ForEmployeeCV;
    },

    setBase64ForPersonalPhoto: (base64) => {
        base64ForPersonalPhoto = base64;
    },

    getbase64ForPersonalPhoto: () => {
        return base64ForPersonalPhoto;
    },

    setBase64OfMailTemplateBody: (base64) => {
        base64BonusRequest_MailTemplateBody = base64;
    },

    getBase64MailTemplateBody: () => {
        return base64BonusRequest_MailTemplateBody;
    },

    setBase64OfEntityLogo: (base64) => {
        base64EntityForm_Logo = base64;
    },

    getBase64EntityLogo: () => {
        return base64EntityForm_Logo;
    },

    setBase64ForFormalReqFromEntityAndEmployeesFinalPerformance: (base64) => {
        formalReqFromEnitiesAndEmp_FileName = base64;
    },

    getBase64ForFormalReqFromEntityAndEmployeesFinalPerformance: () => {
        return formalReqFromEnitiesAndEmp_FileName;
    },

    setBase64ForEmployeesFinalPerformanceEvaluationSheetInExcel: (base64) => {
        AddOrEditEmpPerfEva_employeesFinalPerfEvaluationSheetInExcel_FileName = base64;
    },

    getBase64ForEmployeesFinalPerformanceEvaluationSheetInExcel: () => {
        return AddOrEditEmpPerfEva_employeesFinalPerfEvaluationSheetInExcel_FileName;
    },

    //Join
    setBase64ForAttachEntityAttestedPolicyFileInPDF: (base64) => {
        JoinAndImpGovResSys_AttachEntityAttestedPolicyFile_FileName = base64;
    },

    getBase64ForAttachEntityAttestedPolicyFileInPDF: () => {
        return JoinAndImpGovResSys_AttachEntityAttestedPolicyFile_FileName;
    },


    setBase64ForAttachEntityAttestedOrganizationStructureFileInPDF: (base64) => {
        JoinAndImpGovResSys_AttachEntityAttestedOrgStructure_FileName = base64;
    },

    getBase64ForAttachEntityAttestedOrganizationStructureFileInPDF: () => {
        return JoinAndImpGovResSys_AttachEntityAttestedOrgStructure_FileName;
    },

    setBase64ForAttachEntityCreationLawFileInPDF: (base64) => {
        JoinAndImpGovResSys_AttachEntityCreationLaw_FileName = base64;
    },

    getBase64ForAttachEntityCreationLawFileInPDF: () => {
        return JoinAndImpGovResSys_AttachEntityCreationLaw_FileName;
    },

    setBase64ForAttachAgreementFromEntityTopManagentPDF: (base64) => {
        JoinAndImpGovResSys_AttachAgreeFromEntityTopMngmntAsPDF_FileName = base64;
    },

    getBase64ForAttachAgreementFromEntityTopManagentPDF: () => {
        return JoinAndImpGovResSys_AttachAgreeFromEntityTopMngmntAsPDF_FileName;
    },

    setBase64ForExceptionOfSteerAgeOfRetirment_EmpAttachment_FileName: (base64) => {
        ExceptionOfSteerAgeOfRetirment_EmpAttachment_FileName = base64;
    },

    getBase64ForExceptionOfSteerAgeOfRetirment_EmpAttachment_FileName: () => {
        return ExceptionOfSteerAgeOfRetirment_EmpAttachment_FileName;
    },

    setBase64ForExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName: (base64) => {
        ExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName = base64;
    },

    getBase64ForExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName: () => {
        return ExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName;
    },

    setBase64ForExemptionHousingPolicy_employeeContract: (base64) => {
        base64ForExmptionHousingPolicy_employee = base64;
    },

    getBase64ForExemptionHousingPolicy_employeeContract: () => {
        return base64ForExmptionHousingPolicy_employee;
    },

    setBase64ForExemptionHousingPolicy_flatContract: (base64) => {
        base64ForExmptionHousingPolicy_flat = base64;
    },

    getBase64ForExemptionHousingPolicy_flatContract: () => {
        return base64ForExmptionHousingPolicy_flat;
    },

    // Data Services - Attachement for required reqort fields and details
    setBase64ForAttachementForRequiredReqortFieldsAndDetails: (base64) => {
        base64ForAttachementForRequiredReqortFieldsAndDetails = base64;
    },

    getBase64ForAttachementForRequiredReqortFieldsAndDetails: () => {
        return base64ForAttachementForRequiredReqortFieldsAndDetails;
    },
    // End Attachement for required reqort fields and details

    // data services attachment file type id
    getEmpPerformanceFormalRequestEntityAndEmpId: () => {
        return empPerformanceFormalRequestEntityAndEmpId;
    },

    getEmpPerformanceFinalPerofrmanceId: () => {
        return empPerformanceFinalPerofrmanceId;
    },

    getJoinAndImplementPolicyAttestedId: () => {
        return joinAndImplementPolicyAttestedId;
    },

    getJoinAndImplementOrgnisationStrutureId: () => {
        return joinAndImplementOrgnisationStrutureId;
    },

    getJoinAndImplementEntityCreationLawId: () => {
        return joinAndImplementEntityCreationLawId;
    },

    getJoinAndImplementAttachAgreementFormEntityId: () => {
        return joinAndImplementAttachAgreementFormEntityId;
    },

    // report and static
    getReportAndStaticReportFeildsAndDetailsId: () => {
        return reportAndStaticReportFeildsAndDetails;
    },
    // end data services attachment file type id 

    getFormattedDate: (date) => {
        let tempDate = '';
        if (date) {
            tempDate = new Date(date);
            return moment(tempDate).format("YYYY-MM-DD");
        } else {
            return '';
        }
    },

    setIsPreviewFlag: (flag) => {
        isPreview = flag;
    },

    getIsPreviewFlag: () => {
        return isPreview;
    },

    setPreviewObj: (obj) => {
        previewObj = obj;
    },

    getPreviewObj: () => {
        return previewObj;
    },

    setPreviewAttachmentArray: (attachmentArray) => {
        attachmentArr = attachmentArray;
    },

    getPreviewAttachmentArray: () => {
        return attachmentArr;
    },

    setPreviewckEditorArray: (ckEditorArray) => {
        ckEditorArr = ckEditorArray;
    },

    getPreviewckEditorArray: () => {
        return ckEditorArr;
    },

    getMaxFileCount: () => {
        return maxFileCount;
    },

    getMaxFileSize: () => {
        return fileSize;
    },
    getWorkshopMaxFileSize: () => {
        return WorkshopfileSize;
    },


    getMaxCKEditorLimit: () => {
        return ckEditorLimit;
    },

    //Set Login Data in config only once
    getloginDataReceivedFirstTime: () => {
        return loginDataReceivedFirstTime;
    },

    setLoginDataReceivedFirstTime: (val) => {
        loginDataReceivedFirstTime = val;
    },

    //Set All Dropdown api Data in config only once
    getAllDpDwnAPIresponseFirstTime: () => {
        return allDpDwnAPIresponseFirstTime;
    },

    setAllDpDwnAPIresponseFirstTime: (val) => {
        allDpDwnAPIresponseFirstTime = val;
    },

    // set and get for data services hra study
    getHraStudyForExtendCreate: () => {
        return hraStudyForExtendCreate;
    },

    getHraStudyForExtendReview: () => {
        return hraStudyForExtendReview;
    },

    getHraStudyForExtendAnnualPerformance: () => {
        return hraStudyForExtendAnnualPerformance;
    },

    getHraStudyForAddAndEditPerformance: () => {
        return hraStudyForAddAndEditPerformance;
    },

    getHraStudyForJoinAndImplement: () => {
        return hraStudyForJoinAndImplement;
    },

    getHraStudyForOpenGrievvance: () => {
        return hraStudyForOpenGrievvance;
    },

    getHraStudyForAmendOnSharedGoverment: () => {
        return hraStudyForAmendOnSharedGoverment;
    },

    getHraStudyForReportAndStatic: () => {
        return hraStudyForReportAndStatic;
    },

    getHraStudyForOtherRequest: () => {
        return hraStudyForOtherRequest;
    },
    // organization structure
 getHraStudyForOrganizationStructureRequest: () => {
        return hraStudyForOrganizationStructureRequest;
    },
    // other attachemnt for DS
    // Extend Create
    setbase64OtherAttachmentForExtendCreate: (base64) => {
        base64OtherAttachmentForExtendCreate = base64;
    },

    getbase64OtherAttachmentForExtendCreate: () => {
        return base64OtherAttachmentForExtendCreate;
    },

    // Extend Review
    setbase64OtherAttachmentForExtendReview: (base64) => {
        base64OtherAttachmentForExtendReview = base64;
    },

    getbase64OtherAttachmentForExtendReview: () => {
        return base64OtherAttachmentForExtendReview;
    },

    // Extend Annual
    setbase64OtherAttachmentForExtendAnnual: (base64) => {
        base64OtherAttachmentForExtendAnnual = base64;
    },

    getbase64OtherAttachmentForExtendAnnual: () => {
        return base64OtherAttachmentForExtendAnnual;
    },

    // Add and Edit
    setbase64OtherAttachmentForAddAndEdit: (base64) => {
        base64OtherAttachmentForAddAndEdit = base64;
    },

    getbase64OtherAttachmentForAddAndEdit: () => {
        return base64OtherAttachmentForAddAndEdit;
    },

    // Join And Implement
    setbase64OtherAttachmentForJoinAndImplement: (base64) => {
        base64OtherAttachmentForJoinAndImplement = base64;
    },

    getbase64OtherAttachmentForJoinAndImplement: () => {
        return base64OtherAttachmentForJoinAndImplement;
    },

    // Open gravience process
    setbase64OtherAttachmentForOpenGravience: (base64) => {
        base64OtherAttachmentForOpenGravience = base64;
    },

    getbase64OtherAttachmentForOpenGravience: () => {
        return base64OtherAttachmentForOpenGravience;
    },

    // Amend On Shared
    setbase64OtherAttachmentForAmendOnShared: (base64) => {
        base64OtherAttachmentForAmendOnShared = base64;
    },

    getbase64OtherAttachmentForAmendOnShared: () => {
        return base64OtherAttachmentForAmendOnShared;
    },

    // Report and static
    setbase64OtherAttachmentForReportAndStatic: (base64) => {
        base64OtherAttachmentForReportAndStatic = base64;
    },

    getbase64OtherAttachmentForReportAndStatic: () => {
        return base64OtherAttachmentForReportAndStatic;
    },

    // Other request
    setbase64OtherAttachmentForOtherRequest: (base64) => {
        base64OtherAttachmentForOtherRequest = base64;
    },

    getbase64OtherAttachmentForOtherRequest: () => {
        return base64OtherAttachmentForOtherRequest;
    },
    // End of other attachment for DS


    // file type other attachment for DS
    getOtherAttachmentsEmployeePerformance: () => {
        return otherAttachmentsEmployeePerformance;
    },

    getOtherAttachmentsExtendAnnualPerformance: () => {
        return otherAttachmentsExtendAnnualPerformance;
    },

    getOtherAttachmentsExtendCreateObjectives: () => {
        return otherAttachmentsExtendCreateObjectives;
    },
    
    getOtherAttachmentsExtendReviewObjectives: () => {
        return otherAttachmentsExtendReviewObjectives;
    },
    
    getOtherAttachmentsImplementSystems: () => {
        return otherAttachmentsImplementSystems;
    },
    
    getOtherAttachmentsAmendEntitySystems: () => {
        return otherAttachmentsAmendEntitySystems;
    },
    
    getOtherAttachmentsOpenComplainsStage: () => {
        return otherAttachmentsOpenComplainsStage;
    },
    
    getOtherAttachmentsStatisticsRequest: () => {
        return otherAttachmentsStatisticsRequest;
    },
    
    getOtherAttachmentsOtherRequest: () => {
        return otherAttachmentsOtherRequest;
    },
    getSCILEgalSerbviceId: () => {
        return SCILEgalSerbviceId;
    },
    getSCIComplainId: () => {
        return SCIComplainId;
    },
    getSCILEgalSuggestionId: () => {
        return SCISuggestionId;
    },
    getSCIGeneralId: () => {
        return SCIGeneralId;
    },
    // End of file type otehr attachmnet for DS
// organization structure



getOrganizationStructurAdminRoles: () => {
    return OrganizationStructurAdminRoles;
},
getOrganizationStructurFunctionalRoles: () => {
    return OrganizationStructurFunctionalRoles;
},
getOrganizationStructurOrganizationalRoles: () => {
    return OrganizationStructurOrganizationalRoles;
},


setOrganizationStructurAdminRoles: (roles) => {
     OrganizationStructurAdminRoles=roles;
},
setOrganizationStructurFunctionalRoles: (roles) => {
     OrganizationStructurFunctionalRoles=roles;
},
setOrganizationStructurOrganizationalRoles: (roles) => {
     OrganizationStructurOrganizationalRoles=roles;
},


getorganizationStrucutreRequest: () => {
    return OrganizationStructurRequestId;
},
getOrganizationStructurRequestReasonId: () => {
    return OrganizationStructurRequestReasonId;
},
getOrganizationStructurEntityEstablishmentLawId: () => {
    return OrganizationStructurEntityEstablishmentLawId;
},
getOrganizationStructurEntityFormalRequestId: () => {
    return OrganizationStructurEntityFormalRequestId;
},
getOtherAttachmentForOrganizationStructurRequestId: () => {
    return OrganizationStructurOtherAttachmentId;
},


setBase64ForOrganizationStructurRequestReason: (base64) => {
     OrganizationStructurRequestReason=base64;
},
setBase64ForOrganizationStructurEntityEstablishmentLaw: (base64) => {
     OrganizationStructurEntityEstablishmentLaw=base64;
},
setBase64ForOrganizationStructurEntityFormalRequest: (base64) => {
     OrganizationStructurEntityFormalRequest=base64;
},
setbase64OtherAttachmentForOrganizationStructurRequest: (base64) => {
     OrganizationStructurOtherAttachment=base64;
},

setBase64ForOrganizationStructurFunctionalLoad: (base64) => {
    OrganizationStructurFunctionalLoad=base64;
},
setBase64ForOrganizationStructurJobEvaluation: (base64) => {
    OrganizationStructurJobEvaluation=base64;
},
setBase64ForTasksAndSpecificationsTemplate: (base64) => {
     TasksAndSpecificationsTemplate = base64;
},
setbase64OrganizationStrucutreOperationalTemplate: (base64) => {
     OrganizationStrucutreOperationalTemplate = base64;
},
setbase64CurrentOrganizationStructure: (base64) => {
     CurrentOrganizationStructure = base64;
},
setbase64SuggestedOrganizationStructure: (base64) => {
     SuggestedOrganizationStructure = base64;
},


getBase64ForOrganizationStructurFunctionalLoad: () => {
  return  OrganizationStructurFunctionalLoad;
},
getBase64ForOrganizationStructurJobEvaluation: () => {
    return OrganizationStructurJobEvaluation;
},

getBase64ForOrganizationStructurRequestReason: () => {
    return OrganizationStructurRequestReason;
},
getBase64ForOrganizationStructurEntityEstablishmentLaw: () => {
    return OrganizationStructurEntityEstablishmentLaw;
},
getBase64ForOrganizationStructurEntityFormalRequest: () => {
    return OrganizationStructurEntityFormalRequest;
},
getbase64OtherAttachmentForOrganizationStructurRequest: () => {
    return OrganizationStructurOtherAttachment;
},
getbase64OrganizationStructurFunctionalLoadId: () => {
    return OrganizationStructurFunctionalLoadId;
},
getbase64OrganizationStructurJobEvaluationId: () => {
    return OrganizationStructurJobEvaluationId;
},
getBase64ForTasksAndSpecificationsTemplateId: () => {
    return TasksAndSpecificationsTemplateId;
},
getbase64OrganizationStrucutreOperationalTemplateId: () => {
    return OrganizationStrucutreOperationalTemplateId;
},
getbase64CurrentOrganizationStructureId: () => {
    return CurrentOrganizationStructureId;
},
getbase64SuggestedOrganizationStructureId: () => {
    return SuggestedOrganizationStructureId;
}

,
getBase64ForTasksAndSpecificationsTemplate: () => {
    return TasksAndSpecificationsTemplate;
},
getbase64OrganizationStrucutreOperationalTemplate: () => {
    return OrganizationStrucutreOperationalTemplate;
},
getbase64CurrentOrganizationStructure: () => {
    return CurrentOrganizationStructure;
},
getbase64SuggestedOrganizationStructure: () => {
    return SuggestedOrganizationStructure;
}

};


export default Config;