
import {    MDBCardBody, MDBCard,MDBContainer, MDBRow, MDBCol,MDBBtn,MDBModalBody,MDBModalHeader,MDBTableBody,MDBTableHead,MDBModal,MDBTable, MDBTableFoot }
 from "mdbreact";
 import React from 'react';
 import { Component } from 'react';
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import ApiRequest from '../../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay'
import { saveSciRequestPayload, uploadDocument } from '../../utils/Payload';
import { confirmAlert } from 'react-confirm-alert';
import '../../css/react-confirm-alert.css';

// import CircularizationForm from '../../components/Circularization/CircularizationForm';
import Config from '../../utils/Config';

export default class StrategicPlanningEnquireforworkforceForm extends Component {
    constructor() {
        super();
        this.state = {
            isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            isexportVisible:false,
            data: [],
            popUpRowArraytemp: [],
            isLoading:false,
            ShowDetails:true,
            popUpRowArray:[],
            popUpRowArrayTemp:[],
            entityArray:[],
            entitySelectedValue:'',
            isinternal:false,
            selectedQuarter:0,
selectedYear:0
        }
    }
    handleChangeForEntity = (event) => {
        let selectvalue =event.target.value;
      
        // let tempmainprac = []
        
        // for(let i=0;i<this.state.popUpRowArraytemp.length;i++)
        // {
        // let tempitem = this.state.popUpRowArraytemp[i];
        
        // if(tempitem.EntityID==selectvalue)
        // {
        //     tempmainprac.push(tempitem);
        // }
        // }
        this.setState({entitySelectedValue:selectvalue }, () => {
            this.callGetActionPlanInitiativesList();
        });
         
        
        //Config.SetActionPlanListEntity(selectvalue);
    };
    handleChangeForYear = (event) => {
        let selectvalue =event.target.value;
      
        // let tempmainprac = []
        
        // for(let i=0;i<this.state.popUpRowArraytemp.length;i++)
        // {
        // let tempitem = this.state.popUpRowArraytemp[i];
        
        // if(tempitem.EntityID==selectvalue)
        // {
        //     tempmainprac.push(tempitem);
        // }
        // }
        this.setState({selectedYear:selectvalue }, () => {
            this.callGetActionPlanInitiativesList();
        });
         
        
        //Config.SetActionPlanListEntity(selectvalue);
    };
    handleChangeForQuarter = (event) => {
        let selectvalue =event.target.value;
      
        // let tempmainprac = []
        
        // for(let i=0;i<this.state.popUpRowArraytemp.length;i++)
        // {
        // let tempitem = this.state.popUpRowArraytemp[i];
        
        // if(tempitem.EntityID==selectvalue)
        // {
        //     tempmainprac.push(tempitem);
        // }
        // }
        this.setState({selectedQuarter:selectvalue }, () => {
            this.callGetActionPlanInitiativesList();
        });
         
        
        //Config.SetActionPlanListEntity(selectvalue);
    };
    saveFile = (item,filename) => {
        let obj = {}
        let reqid= Config.getActionPlanID();
        obj.filename = filename;
        obj.requesttype = 10960;
        obj.requestid = reqid;
        const linkSource = item;
        const downloadLink = document.createElement("a");
        const fileName = filename;
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        // let endPoint = 'DownloadAttachment'
        // let url = Config.getUrl(endPoint);
        // if (res.Status) 
        // {
        //     let fileData = item;
        //     let filecontent = item;
        //     let contenttype = '.pdf';
        //     if (navigator.msSaveBlob) {
        //         let blob = this.b64toBlob(filecontent, contenttype);
        //         window.navigator.msSaveBlob(blob, item.filename);
        //     } else {
        //         let a = window.document.createElement('a');
        //         a.href = `data:${contenttype};base64,${filecontent}`;
        //         a.download = item.filename;
        //         // Append anchor to body.
        //         document.body.appendChild(a);
        //         a.click();
        //         // Remove anchor from body
        //         document.body.removeChild(a);
        //     }
        // }
    }
    updateListData = (data, popUpRowArray) => {
        
        debugger;
           
           let tempIndex = 0;
           if (popUpRowArray && popUpRowArray.length === 1) {
         
               this.setState({
                   data: data,
                   popUpRowArray:popUpRowArray,
                   popUpRowArraytemp: popUpRowArray,
                   
                   selectedIndex: tempIndex
               });
           } else {
         
               
               this.setState({
                   data: data,
                   popUpRowArray:popUpRowArray,
                   popUpRowArraytemp: popUpRowArray,
               });
           }
       }

       GetQuarterName=(quarter)=>
       {
           
         let queartname=  quarter===1 ? this.state.isArabic ? "الربع الأول":"Quarter 1":quarter===2? this.state.isArabic ?
         "الربع الثاني":"Quarter 2":quarter===3? this.state.isArabic ?
         "الربع الثالث":"Quarter 3":quarter===4? this.state.isArabic ?
         "الربع الرابع":"Quarter 4":'';
         return queartname

       }
    renderDataTableRows = (item, index) => {
        
        
         return (
             <tr style={{ textAlign: "center", padding: '10%' }}>
           
             <td>{item.EntityName}</td>
             <td>{item.Year}</td>
             <td >{this.GetQuarterName(item.Quarter)}</td>
          
             <td style={{textAlign:this.state.isArabic ?"right":"left",paddingRight:"15%",paddingLeft:"15%"}}>
             <img onClick={() => this.saveFile (item.filecontent,item.filename)} style={{
                height: "auto", display: "inline-block", valign: "middle",
                verticalAlign: "middle", width: "auto", maxWidth: "40px", maxHeight: "40px", marginLeft: '10px', cursor: "pointer"
            }} alt="" src={require('./../../assets/images/BlueDownloads.png')}
                className="img-fluid" />
           
                 {item.filename.includes('.xls')?item.filename.split('.xls')[0]:item.filename}</td>
          
            {/* <td> 
             <MDBBtn
                                     className="gridActionBtn "
                                     color="primary"
                                     style={{ color: "var(--btnColor)" }}
                                     size="sm"
                                     // onClick={() => {
                                     //     this.props.manageAction(item.action, this.props.index);
                                     // }}
                                     onClick={() => this.ViewDates(index)}                       >
                                         {i18n[this.getLocale()].WorkShop.ViewDates}
                                  
                                 </MDBBtn>
                  <MDBBtn
                                     className="gridActionBtn GreenBG"
                                     color="primary"
                                     style={{ color: "var(--btnColor)" }}
                                     size="sm"
                                     // onClick={() => {
                                     //     this.props.manageAction(item.action, this.props.index);
                                     // }}
                                     onClick={() => this.EditWorkShop(item)}                       >
                                         {i18n[this.getLocale()].WorkShop.Edit}
                                  
                                 </MDBBtn>
   </td> */}
             </tr>
         )
     }
    callGetActionPlanInitiativesList= () => {
        debugger;
        let payload = {};
        payload.Upcoming=1;
 
        payload.lang=this.state.isArabic ? 1:0;
  let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        payload.EntityID =this.state.entitySelectedValue? this.state.entitySelectedValue :getDataFromLocalStorage.EntityID;
        
        payload.Quarter =this.state.selectedQuarter;
        payload.Year =this.state.selectedYear;
         payload.userid =  getDataFromLocalStorage.id;
        // payload.CircularTitle=this.state.CircularTitle;
        // payload.Page=this.state.Page;
        // payload.Count=this.state.Count;
        let endPoint = 'GetallSaveSubstitutionplan'
        let url = Config.getUrl(endPoint);
       this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let TotalCirculars=0;
                
                if (resArray && resArray.length > 0) {
             
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': resArray
                    };

                    if(this.state.isinternal)
                    {
                        debugger;
                        this.setState({
                            data: mainArray,
                            popUpRowArraytemp: resArray,
                            actualArray: resArray,
                            dataRow: resArray,
                            TotalCirculars:TotalCirculars
                        }, () => {
                           
                          //  this.handleLoadForEntity();
                           this.updateListData(this.state.data, this.state.popUpRowArraytemp);
                        })
                    }
                    else
                    {
                        this.setState({
                            data: mainArray,
                            popUpRowArray: resArray,
                            actualArray: resArray,
                            dataRow: resArray
                        }, () => {
                  
                           this.updateListData(this.state.data, this.state.popUpRowArray);
                        })
                    }
               
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArraytemp: [],
              

                    }, () => {
                         this.updateListData(this.state.data, this.state.popUpRowArraytemp);
                    })
                }
            }
           // } 
            // else {
            //     // TODO
            // }
        })

      
    }
    create = () => {
        let pathname ='StrategicPlanningforworkforceForm';
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    componentDidMount=()=>
    {
        localStorage.setItem('currentPath','StrategicPlanningforworkforceForm');
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    isinternal:this.state.loginData.internalcontact,
                    entityArray:tempEntityArray,
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
        this.callGetActionPlanInitiativesList();
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }
    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }
    render() {
      
        const sideBarData = Config.getServiceSideBar('StrategicPlanningforworkforce', this.state.isArabic, this, 0);
      return (
        <MDBContainer fluid>
                     <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip Substitutionplan={true} enableCreate={!Config.getLocalStorageLoginData().internalcontact} goBack={() => { this.props.history.replace('/StrategicPlanningforworkforce') }}  create={this.create}  isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Title} />

                                    {this.state.isinternal ? 
                                   <div style={{ paddingTop: "10px",paddingLeft:"30px",paddingRight:"30px" }}>
                                   <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
  <MDBCol xs="12" sm="12" md="6" lg="12" xl="12">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.EntitName}<span className="requireStar">*</span>   
                            </label>
                      
                            <select style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForEntity}
                                        value={this.state.entitySelectedValue}
                                        required>
                                        <option value="0">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                                return <option value={item.id}>{this.state.isArabic ? item.arabicName : item.englishName}</option>
                                            }) : null
                                        }
                                    </select>
                                   
                            
                        </div>
                    </MDBCol>

                                       </MDBRow>
                                       <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
  <MDBCol xs="12" sm="12" md="6" lg="12" xl="12">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].Substitutionplan.QuarterTitle}<span className="requireStar">*</span>   
                            </label>
                      
                            <select style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForQuarter}
                                        value={this.state.selectedQuarter}
                                        required>
                                        <option value="0">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        <option value="1" >{this.GetQuarterName
                                        (1)}</option>
                                        <option value="2" >{this.GetQuarterName(2)}</option>
                                        <option value="3" >{this.GetQuarterName(3)}</option>
                                        <option value="4" >{this.GetQuarterName(4)}</option>
                                    </select>
                                   
                            
                        </div>
                    </MDBCol>

                                       </MDBRow>
                                       <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
  <MDBCol xs="12" sm="12" md="6" lg="12" xl="12">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].Substitutionplan.Year}<span className="requireStar">*</span>   
                            </label>
                      
                            <select style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForYear}
                                        value={this.state.selectedYear}
                                        required>
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        <option value="2021" >2021</option>
                                    </select>
                                   
                            
                        </div>
                    </MDBCol>

                                       </MDBRow>
                                   </div>:null}
                                </MDBCol>
                            </MDBRow>
                          
                            <div style={{ paddingTop: "10px" }}>
                                {/* <CircularizationForm       isArabic={this.state.isArabic}/>  */}
                            </div>

                            <div style={{ paddingTop: "10px" }}>
                                <MDBTable striped responsive hover style={{ marginBottom: '80px', direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                                <MDBTableHead>
                                    <tr ><th colspan="4">  
                                    {/* <input class="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search}  onKeyDown={this.validatekey} ref="SearchInput" /> */}
                                    </th>
                                    </tr>
                        
                        <tr className="tableHeader"> 
                     
<th><span >{i18n[this.getLocale()].Substitutionplan.Entity}</span>  </th>
<th><span >{i18n[this.getLocale()].Substitutionplan.Year}</span>  </th>
<th><span >{i18n[this.getLocale()].Substitutionplan.QuarterTitle}</span></th>
<th><span >{i18n[this.getLocale()].Substitutionplan.Plan}</span></th>
                      
                        </tr>

                        
                        </MDBTableHead>
                        <MDBTableBody>
                         
                        {
                            
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ? this.state.popUpRowArray.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                              
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                     null
                                }
                        </MDBTableBody>
                       {/* <MDBTableFoot>
                       <tr className="tableHeader">
                  <td   colspan="1"  className=" col-span-3	 px-4 py-3 bg-gray-300 text-left px-2 text-lg	 font-normal  uppercase tracking-wider bg-red-800 text-white mb-4">
                  {this.state.Page>1? <MDBBtn style={{float: this.state.isArabic ? 'right' : 'left'}} onClick={this.handlePrevious} className="btn btn-primary Ripple-parent fixcusotmbtn">
                     {i18n[this.getLocale()].Circularization.Previous}
                    </MDBBtn>
                  :null}
                   
                  </td>
       <td  colspan="2"  className=" justify-center text-center col-span-3	 px-4 py-3 bg-gray-300 text-left text-lg	 font-normal  bg-red-800 text-white uppercase tracking-wider">{i18n[this.getLocale()].Circularization.Page} {this.state.Page}</td>
                  <td  style={{float: this.state.isArabic ? 'left':'right' }}  colspan="1" className="px-2 justify-center text-center  col-span-3	 px-4 py-3 bg-gray-300 text-right text-lg	 font-normal  bg-red-800 text-white uppercase tracking-wider">
                
                      {this.state.TotalCirculars>this.state.Count && this.state.TotalCirculars>this.state.Count*this.state.Page ? 
                      <MDBBtn className="btn btn-primary Ripple-parent fixcusotmbtn" onClick={this.handleNext}>
                  {i18n[this.getLocale()].Circularization.Next} </MDBBtn>:
                  null}
                   </td>
                  </tr>
               
      
                       </MDBTableFoot> */}
                       
                        </MDBTable>
                                </div><div style={{ paddingTop: "10px" }}>
                                </div>
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            height={'140vh'}
                            canViewSCI={this.state.canViewSCI}
                            canViewInformation={this.state.canViewInformation}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData} isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay>  
            </MDBContainer>
    );
    }
}

