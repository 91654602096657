import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import i18n from '../../i18n';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import ApiRequest from '../../services/ApiRequest';
import { getFunctionBlockPayload, saveFunctionalDataPayload,
    deleteFunctionalDataPayload, editFunctionalDataPayload } from '../../utils/Payload';
 import SortableTree, { toggleExpandedForAll, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import Chart from './Chart';
import OrganizationStructureFunctions from './OrganizationStructureFunctions';
import Locali18n from './Locali18n';

const MyNodeComponent = ({ node }) => {
    return (
        <div className="initechNode">{node.name}</div>
    );
};
export default class HeirarchyContainer extends Component {

    constructor(props) {

        super(props);

        this.state = {
           
            View:[],
            selectedNode: "",
            selectedNodeDepth: "",
            selectedNodeUid: "",
            name: "",
            uid: "",
            englishName: "",
            arabicName: "",
            director: "",
            droppedItem: this.props.droppedItem,
            loginData:'',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,

        };
        this.dropped = {};
    }
    componentWillMount(){

        let tempLoginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: tempLoginData
        },()=>{
            this.setState({
            canvieworgrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
            canvieworgrequesttree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.CanCreateStructure,
            canviewtree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewtree,
            entitySelectedValue : this.state.loginData && this.state.loginData.permissionType && this.state.loginData.EntityID,

        });

        });

        this.getAllFunctionData(0);
    
    }
    getAllFunctionData = (positionId) => {

debugger;
        let payload = getFunctionBlockPayload();
        payload.positionid = positionId;
        payload.typeid = Config.getCurrentTreeView();

        let endPoint = 'GetTreeNodes'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {

            ApiRequest.sendRequestForPost(url, payload, res => {

                this.setState({
                    isLoading: false
                }, () => {

                    if (res.Status) {

                        this.setState({View:res.data[0]});
                    } else {

                    }
                })
            })
        })

    }

    getView = (positionId) => {

  debugger;
        let payload = getFunctionBlockPayload();
        payload.positionid = positionId;
       
        this.ttype ? payload.typeid = this.ttype :  payload.typeid =0;
        
     if(Config.getCurrentEntity() !== 0)
           payload.EntityID = Config.getCurrentEntity();


        let endPoint = 'GetTree'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
              
            ApiRequest.sendRequestForPost(url, payload, res => {
               
                this.setState({
                    isLoading: false
                }, () => {
                      
    
                    if (res.Status) {
                           debugger;
                      this.setState({View:res.data[0]});
                   
                    } else {
                        
                    }
                })
            })
        })
      
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    handleViewTreeNavigation = (route) => {
        debugger;
        localStorage.setItem('currentPath', 'OrganizationStructure');
        this.setState({
            pathName: 'OrganizationStructure'
        }, () => {
            if(this.state.loginData.internalcontact ===1 ||this.state.loginData.isadmin ===1)
            this.props.history.replace('/OrganizationStructureTreePreview');
            else
            this.props.history.replace('/OrganizationStructureTree');

        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }
    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    render() {
      

        const sideBarData = Config.getSidebarForhierachyContainer(this.state.isArabic, this, 0);
       
        return (
            <MDBContainer fluid style={{ height: '100vh' }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} />

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', 'backgroundColor': '#dbd9be' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            
                            <MDBRow>
                            
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                            
                                    <SubHeaderStrip goBack={this.handleViewTreeNavigation} isArabic={this.state.isArabic} title={Locali18n[this.getLocale()] .orgStructure.treeView} />
                            
                                </MDBCol>
                            
                            </MDBRow>

                            <MDBRow style={{ paddingTop: '20px', 'overflow-x': 'scroll' }}>
                             
                                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                             
                                    <Chart  tree={this.state.View}  NodeComponent={MyNodeComponent} />
                             
                                </MDBCol>
                            
                            </MDBRow>

                        </MDBCol>

                        <SideBar height={'300vh'} isSideBarMinimized={this.state.isSideBarMinimized} items={sideBarData} isArabic={this.state.isArabic} />

                    </MDBRow>

                </LoadingOverlay>

            </MDBContainer >

        );
    }

}