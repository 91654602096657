import React, { Component } from 'react';




import { MDBContainer, MDBRow, MDBCol,MDBBtn,MDBTableBody,MDBTable,MDBTableHead,MDBModalHeader,MDBModalBody,MDBModal } from "mdbreact";
import TableLabel from '../../components/TableLabel';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../../components/Footer';
import { saveSecondmentRequestPayload, uploadDocument,downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import WorkShopList from '../../components/WorkShop/WorkShopList'  ;
import { Card,Accordion,Button  } from 'react-bootstrap';
import './../../css/bootstrap.min.css';

let moment = require('moment');
// const decoratedOnClick = useAccordionToggle(eventKey, onClick);

export default class WorkShopMyBookedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            ShowDetails:false,
        }
    }
    BookWorkShop = (item) =>{
        debugger;
       
       debugger;
        Config.setWorkShopTimeID(item);
        
        this.create();
        
    }
    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }
    alertSubmitForSuccess = () => {
        confirmAlert({
            title: i18n[this.getLocale()].WorkShop.cancelBooking,
            message: i18n[this.getLocale()].WorkShop.cancelBookingMessageSucess,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.componentDidMount()
                 
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    CancelWorkShop = (item) =>{
       
        let payload = {};
        payload.WorkShopID=item.WorkShopID;
        
        payload.lang=this.state.isArabic ? 1:0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();

        payload.EntityID=getDataFromLocalStorage.EntityID;
        let endPoint = 'CancelBokkedByentites '
        let url = Config.getUrl(endPoint);
       this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                
             //   this.componentDidMount();
                this. alertSubmitForSuccess();
            } else {
                // TODO
            }
        })
        
    }
    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    saveFile = (item) => {
        let obj = {}

        obj.filename = item.aliasfilename;
        obj.requesttype = this.state.requesttypeid;
        obj.requestid = this.props.location.state.id;

        let payload = downloadAttachment(obj);
        let endPoint = 'DownloadAttachment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let fileData = res.data;
                        let filecontent = fileData.filecontent;
                        let contenttype = fileData.contenttype
                        if (navigator.msSaveBlob) {
                            let blob = this.b64toBlob(filecontent, contenttype);
                            window.navigator.msSaveBlob(blob, item.filename);
                        } else {
                            let a = window.document.createElement('a');
                            a.href = `data:${contenttype};base64,${filecontent}`;
                            a.download = item.filename;
                            // Append anchor to body.
                            document.body.appendChild(a);
                            a.click();
                            // Remove anchor from body
                            document.body.removeChild(a);
                        }
                    } else {
                       
                    }
                })
            })
        })
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    callGetAllWorkShops = () => {
        let payload = {};
        payload.Upcoming=1;
 
        payload.lang=this.state.isArabic ? 1:0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();

        payload.EntityID=getDataFromLocalStorage.EntityID;
        let endPoint = 'GetALLBokkedByentites '
        let url = Config.getUrl(endPoint);
       this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            WorkShopTitle:resArray[index].WorkShopTitle,
                            //WorkShopList:resArray[index].ID,
                            EntityName:resArray[index].EntityName,
                            PersonName:resArray[index].PersonName,
                            WorkShopLocation:resArray[index].WorkShopLocation,
                            WorkShopTime:  resArray[index].WorkShopTime,
                            AttandenceNumber:resArray[index].AttandenceNumber,
                            workShopDates:resArray[index].WorkShopDates,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    toggle = (reloadFlag) => {
        debugger;
        this.setState({
            modal: !this.state.modal,
            ShowDetails :!this.state.ShowDetails
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState
                }, () => {
                    this.props.history.goBack();
                    //this.componentDidMount();
                });
            }
        });
    }
    ViewDates =(index)=>
    {
     
        this.setState({selectedIndex:index ,ShowDetails: true});
        this.toggle();
    }
    renderDates12 ()
    {
        
      debugger;
        let index = this.state.selectedIndex ? this.state.selectedIndex: null;
        if(index !=null)
        
        {
                  
        let selecteddates = this.state.popUpRowArray[index].workShopDates;

        return (
            <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                {
                    <MDBContainer style={{ display: 'flex: ', flexDirection: !this.state.isArabic ? 'row-reverse' : 'row', height: '100%', }}>
                        <MDBRow style={{ display: 'flex', flexDirection: !this.state.isArabic ? 'row-reverse' : 'row', height: '100%', margin: '20px' }} >
                            <MDBCol md="12">
                                <MDBTable striped responsive hover style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                                    <MDBTableHead>
                                        <tr style={{ textAlign: "center" }}>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.WorkShopDate}
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.WorkShopTimefrom}   
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.WorkShopTimeTo} 
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.Location} 
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.AttandanceNumbeRemaing} 
                                           </th>
                                           <th></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                           selecteddates ? selecteddates.map((item, index) => {
                                                return (<tr style={{ textAlign: "center" }}>
                                                    <td>
                                                        {item.WorkShopDate}
                                                    </td>
                                                   
                                                    <td>
                                                        {item.StartTime}
                                                    </td>
                                                    <td>
                                                        {item.EndTime}
                                                    </td>
                                                    <td>
                                                        {item.WorkShopLocation}
                                                    </td>
                                                    <td>
                                                        {item.ReaminAttandance}
                                                    </td>
                                                     <td>
                                                     {item.ReaminAttandance>0?        <MDBBtn
                                    className="gridActionBtn GreenBG"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.BookWorkShop(item)}                       >
                                        {i18n[this.getLocale()].WorkShop.workshopBookEdit}
                                 
                                </MDBBtn>:null}
                                                    </td>
                                                </tr>)
                                            }) : ''
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                }
            </div>
        );
       
        }
        else
        {
            try
            {
            let selecteddates = this.state.popUpRowArray[this.state.selectedIndex].workShopDates;

            return (
                <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                    {
                        <MDBContainer style={{ display: 'flex: ', flexDirection: !this.state.isArabic ? 'row-reverse' : 'row', height: '100%', }}>
                            <MDBRow style={{ display: 'flex', flexDirection: !this.state.isArabic ? 'row-reverse' : 'row', height: '100%', margin: '20px' }} >
                                <MDBCol md="12">
                                    <MDBTable striped responsive hover style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                                        <MDBTableHead>
                                            <tr style={{ textAlign: "center" }}>
                                               <th>
                                               {i18n[this.getLocale()].WorkShop.WorkShopDate}
                                               </th>
                                               <th>
                                               {i18n[this.getLocale()].WorkShop.WorkShopTimefrom}   
                                               </th>
                                               <th>
                                               {i18n[this.getLocale()].WorkShop.WorkShopTimeTo} 
                                               </th>
                                               <th>
                                               {i18n[this.getLocale()].WorkShop.Location} 
                                               </th>
                                               <th>
                                               {i18n[this.getLocale()].WorkShop.AttandanceNumbeRemaing} 
                                               </th>
                                               <th></th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {
                                               selecteddates ? selecteddates.map((item, index) => {
                                                    return (<tr style={{ textAlign: "center" }}>
                                                        <td>
                                                            {item.WorkShopDate}
                                                        </td>
                                                       
                                                        <td>
                                                            {item.StartTime}
                                                        </td>
                                                        <td>
                                                            {item.EndTime}
                                                        </td>
                                                        <td>
                                                            {item.WorkShopLocation}
                                                        </td>
                                                        <td>
                                                            {item.ReaminAttandance}
                                                        </td>
                                                        <td>
                                                        {item.ReaminAttandance>0?                    <MDBBtn
                                    className="gridActionBtn GreenBG"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.BookWorkShop(item)}                       >
                                        {i18n[this.getLocale()].WorkShop.workshopBookEdit}
                                 
                                </MDBBtn>:null}
                                                    </td>
                                                    </tr>)
                                                }) : ''
                                            }
                                        </MDBTableBody>
                                    </MDBTable>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    }
                </div>
            );
                }
                catch{}
        }
    }
    renderDataTableRows = (item, index) => {
        return (
            <tr style={{ textAlign: "center", padding: '10%' }}>
            <td>{item.WorkShopTitle}</td>
       
            <td>{item.AttandenceNumber}</td>
            <td>{item.WorkShopLocation}</td>
            <td dangerouslySetInnerHTML ={{__html: item.WorkShopTime}}></td>
           
           <td>
            <MDBBtn
                                    className="gridActionBtn "
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.ViewDates(index)}                       >
                                        {i18n[this.getLocale()].WorkShop.workshopBookEdit}
                                 
                                </MDBBtn>
               <MDBBtn
                                    className="gridActionBtn RedBG"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.CancelWorkShop(item)}                       >
                                        {i18n[this.getLocale()].WorkShop.cancelBooking}
                                 
                                </MDBBtn>
  </td>
            </tr>
        )
    }
    updateListData = (data, popUpRowArray) => {
        
      
        
        let tempIndex = 0;
        if (popUpRowArray && popUpRowArray.length === 1) {
      
            this.setState({
                data: data,
                popUpRowArray: popUpRowArray,
                selectedIndex: tempIndex
            });
        } else {
      
            
            this.setState({
                data: data,
                popUpRowArray: popUpRowArray,
            });
        }
    }

    componentWillMount = () => {
    }

    componentDidMount=()=>
    {
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
        this.callGetAllWorkShops();
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    create = () => {
        let pathname ='WorkShopBooking';
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    render() {
        const sideBarData = Config.getServiceSideBar('WorkShopManage', this.state.isArabic, this, 0);
        return(
        <MDBContainer fluid style={{ height: '100vh' }}>
        <LoadingOverlay
                active={this.state.isLoading}
                spinner={true}>
                <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                <MDBRow
                    className="formWhiteContainer"
                    style={{
                        //  width: '100vw',
                        flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                    }}>
                    <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                        <MDBRow>
                            <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                <SubHeaderStrip
                                    goBack={() => {
                                        Config.setIsEditFlag(false);
                                        this.props.history.replace('/listpage', { pathname: 'WorkShopManage' })
                                    }}
                                   // enableCreate={true}
                                    create={this.create}
                                    isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.subject}
                                />
                                <div style={{ paddingTop: "10px" }}>
                                <MDBTable striped responsive hover style={{ marginBottom: '80px', direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                        <MDBTableHead>
                        <tr className="tableHeader"> 
                     
<th><span >{i18n[this.getLocale()].WorkShop.Title}</span>  </th>
<th><span >{i18n[this.getLocale()].WorkShop.AttandanceNumberBooked}</span></th>
<th><span >{i18n[this.getLocale()].WorkShop.Location}</span></th>
<th><span >{i18n[this.getLocale()].WorkShop.WorkShopTime}</span></th>
                     <th></th>
                        </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                        {
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ? this.state.popUpRowArray.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                                
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                        null
                                }
                        </MDBTableBody>
                        </MDBTable>
                                </div>
                                <div style={{clear:"both",height:"100px"}}/>
                                  <Footer isArabic={this.state.isArabic} />

                            </MDBCol>
                            </MDBRow>
                          
                        
   
                        </MDBCol>
                            <SideBar
                            canViewSCI={'1'}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                       
                        </MDBRow>

                        <MDBModal
                        className={this.state.isArabic ? 'comment_ar viewDetailModal70' : 'viewDetailModal70'}
                        isOpen={this.state.ShowDetails === true}  toggle={this.toggle}
                        size={
                            
                                 "fluid"
                                
                        } position="bottom">
 <MDBModalHeader
                            style={{
                                padding: '0.5rem 1rem',
                                flexDirection: this.state.isArabic ? 'row-reverse' : 'row',
                                textAlign: this.state.isArabic ? "right" : "left"
                            }}
                            toggle={this.toggle}>{i18n[this.getLocale()].WorkShop.WorkShopTimes}
                        </MDBModalHeader>
                        <MDBModalBody>
                        {this.renderDates12()}
                        </MDBModalBody>
                        </MDBModal>
   </LoadingOverlay>
   
  
   </MDBContainer>);
    }
}