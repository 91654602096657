import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import StepsComponent from '../../components/StepsComponent';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import i18n from './../../i18n';

export default class StepTwo extends Component {

    constructor(props) {
        super(props);
        debugger;
        this.state = {
            requestType: Config.getRetirmentRequestTypeId(),
            isEdit: false,
            requestId: '',
            statusid: '',
            jobContractErr: "",
            medicalApprovalsFromTheCometentMedicalCommitteeErr: "",
            passportErr: "",
            // familyBookErr: "",
            emaritesIDErr: "",
            annualPerformanceEvaluationattachErr: '',
            employeeCVErr: "",
            personalPhotoErr: "",
            acopyofthepreviousapprovalsforextensionfromthejurisdictionErr: "",
         
            // step two variables
            copyButton: this.props.sendData.copyButton,
            nationalOrNot: this.props.sendData.nationalOrNot,
            jobContract: this.props.sendData.jobContract,
            medicalApprovalsFromTheCometentMedicalCommittee: this.props.sendData.medicalApprovalsFromTheCometentMedicalCommittee,
            medicalApprovalsFromTheCometentMedicalCommitteeShortName: this.props.sendData.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
            passport: this.props.sendData.passport,
            //familyBook: this.props.sendData.familyBook,
            emaritesID: this.props.sendData.emaritesID,
            personalPhoto: this.props.sendData.personalPhoto,
            annualPerformanceEvaluationattach: this.props.sendData.annualPerformanceEvaluationattach,
            employeeCV: this.props.sendData.employeeCV,
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: this.props.sendData.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: this.props.sendData.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
            replacePlansForNonLocalsAttachmentFileObj: this.props.sendData.replacePlansForNonLocalsAttachmentFileObj,
            replacePlansForNonLocalsAttachementFileName: this.props.sendData.replacePlansForNonLocalsAttachementFileName,
            empPhotoAttachmentArray: [],
            replacePlansForNonLocalsAttachmentError: '',
            replacePlansForNonLocalsArray: [],
            replacePlansForNonLocalsErr: '',
            replacePlansForNonLocalFileName: '',
            editAttachmentArray: [],
            jobContractArray: [],
            medicalApproveArray: [],
            passportArray: [],
            // familyBookArray: [],
            emiratesArray: [],
            personalPhotoArray: [],
            annualPerformanceArray: [],
            previoudApprovalArray: [],
            employeeCvArray: [],
            isNextScreenActive: false,
            isSecondPageValidate: false,
            isNextClick: false,
            isArabic: true,
            jobContractsFileObj: "",
            medicalApprovalFromCompMedicalCommiteeFileArray: [],
            acopyofthepreviousapprovalsFileArray: [],
            passportFileObj: "",
            replacePlansForNonLocalsFileObj: "",
            emiratesIDFileObj: "",
            annualPerfEvaAttachFileObj: "",
            copyOfApprovalsForExtensionFromJurisd: "",
            employeeCVFileObj: "",
            personalPhotoFileObj: "",
            //Summaryofregistrationforcitizens:"",
            SummaryofregistrationforcitizensArray:[],
            SummaryofregistrationforcitizensErr: "",
            Summaryofregistrationforcitizens: this.props.sendData.Summaryofregistrationforcitizens,

            PoistioninStructureArray:[],
            PoistioninStructureErr: "",
            PoistioninStructure: this.props.sendData.PoistioninStructure,
               PreviousapprovalsfortheextensionArray: [],
            PreviousapprovalsfortheextensionErr: "",
            Previousapprovalsfortheextension: this.props.sendData.Previousapprovalsfortheextension,

               QualificationsArray: [],
           QualificationsErr: "",
            Qualifications: this.props.sendData.Qualifications,

               JobdescriptionArray: [],
            JobdescriptionErr: "",
            Jobdescription: this.props.sendData.Jobdescription,

               CareersuccessionplanArray: [],
            CareersuccessionplanErr: "",
            Careersuccessionplan: this.props.sendData.Careersuccessionplan,

               HRComArray: [],
            HRComErr: "",
            HRCom: this.props.sendData.HRCom,

              OtherAttachArray: [],
            OtherAttachErr: "",
            OtherAttach: this.props.sendData.OtherAttach,

            SummaryofregistrationforcitizensFileObj: "",
            PoistioninStructureFileObj:"",
PreviousapprovalsfortheextensionFileObj: "",
            QualificationsFileObj: "",
            JobdescriptionFileObj: "",
            CareersuccessionplanFileObj: "",
            HRComFileObj: "",
            OtherAttachFileObj: ""

        }
    }

    componentDidMount = () => {  
        window.scrollTo(0, 0);
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments
            }, () => {
                
                let tempJobContractArray = [];
                let tempMedicalApproveArray = [];
                let tempPassportArray = [];
                //  let tempFamilyBookArray = [];
                let tempReplaceForNonLocalsAttachmentArray = [];
                //let tempReplacePlansForNonLocalsArray = [];
                let tempEmiratesArray = [];
                let tempPersonalPhotoArray = [];
                let tempAnnualPerformanceArray = [];
                let tempPrevioudApprovalArray = [];
                let tempEmployeeCvArray = [];
                let tempSummaryofregistrationforcitizensArray = [];
                let tempPoistioninStructureArray=[];
                let tempPreviousapprovalsfortheextensionArray = [];
                let tempQualificationsArray = [];
                let tempJobdescriptionArray = [];
                let tempCareersuccessionplanArray = [];
                let tempHRComArray = [];
                let tempOtherAttachArray = [];

                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid && item.filetypeid === Config.getRetirmentJobContractId()) {

                                let temp = {};
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempJobContractArray.push(temp);

                                this.setState({
                                    //jobContract: item.filename
                                })

                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmentMedicalApprovalId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempMedicalApproveArray.push(temp);

                                this.setState({
                                    //medicalApprovalsFromTheCometentMedicalCommittee: item.filename
                                })

                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmentCopyOfPassportId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPassportArray.push(temp);

                                this.setState({
                                    //passport: item.filename
                                })
                            } else if (item.filetypeid && item.filetypeid === Config.getReplacePlansForNonLocalsId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempReplaceForNonLocalsAttachmentArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmenEmiratesId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEmiratesArray.push(temp);

                                this.setState({
                                    //emaritesID: item.filename
                                })

                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmenPersonalPhotoId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPersonalPhotoArray.push(temp);

                                this.setState({
                                    //personalPhoto: item.filename
                                })

                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmenAnnualPerformanceEvaluationId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempAnnualPerformanceArray.push(temp);

                                this.setState({
                                    //annualPerformanceEvaluationattach: item.filename
                                })

                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmenCopyOfPreviousApprovalId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPrevioudApprovalArray.push(temp);

                                this.setState({
                                    //acopyofthepreviousapprovalsforextensionfromthejurisdiction: item.filename
                                })

                            } else if (item.filetypeid && item.filetypeid === Config.getRetirmenEmployeeCvId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEmployeeCvArray.push(temp);

                                this.setState({
                                    //employeeCV: item.filename
                                })

                            }
                            else if (item.filetypeid && item.filetypeid === Config.getReplacePlansForNonLocalsId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempReplaceForNonLocalsAttachmentArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETSummaryofregistrationforcitizensId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempSummaryofregistrationforcitizensArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETPoistioninStructureId()) {
                                alert('12');
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPoistioninStructureArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETPreviousapprovalsfortheextensionId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPreviousapprovalsfortheextensionArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETQualificationsId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempQualificationsArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETJobdescriptionId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempJobdescriptionArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETCareersuccessionplanId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempCareersuccessionplanArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETHRComId()) {
                         
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempHRComArray.push(temp);
                              
                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }

                            else if (item.filetypeid && item.filetypeid === Config.getRETOtherAttachId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempOtherAttachArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }
                        })

                        this.setState({
                            jobContractArray: tempJobContractArray,
                            medicalApproveArray: tempMedicalApproveArray,
                            passportArray: tempPassportArray,
                            //familyBookArray: tempFamilyBookArray,
                            empPhotoAttachmentArray: tempReplaceForNonLocalsAttachmentArray,
                            emiratesArray: tempEmiratesArray,
                            personalPhotoArray: tempPersonalPhotoArray,
                            annualPerformanceArray: tempAnnualPerformanceArray,
                            previoudApprovalArray: tempPrevioudApprovalArray,
                            employeeCvArray: tempEmployeeCvArray,
                            SummaryofregistrationforcitizensArray:tempSummaryofregistrationforcitizensArray,
                            PoistioninStructureArray:tempPoistioninStructureArray,
                            PreviousapprovalsfortheextensionArray:tempPreviousapprovalsfortheextensionArray,
                            QualificationsArray:tempQualificationsArray,
                            JobdescriptionArray:tempJobdescriptionArray,
                            CareersuccessionplanArray:tempCareersuccessionplanArray,
                            HRComArray:tempHRComArray,
                            OtherAttachArray:tempOtherAttachArray
                        })
                    }
                }

            });

        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    };

    onClick = (nr) => () => {
        this.setState({
            radio: nr
        });
    }

    goToPrevious = () => {
        debugger;
        this.props.getPrev.stepTwoDataPrev({
            jobContract: this.state.jobContract,
            medicalApprovalsFromTheCometentMedicalCommittee: this.state.medicalApprovalsFromTheCometentMedicalCommittee,
            medicalApprovalsFromTheCometentMedicalCommitteeShortName: this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
            passport: this.state.passport,
            //  familyBook: this.state.familyBook,
            replacePlansForNonLocalsAttachmentFileObj: this.state.replacePlansForNonLocalsAttachmentFileObj,
            replacePlansForNonLocalsAttachementFileName: this.state.replacePlansForNonLocalsAttachementFileName,
            emaritesID: this.state.emaritesID,
            personalPhoto: this.state.personalPhoto,
            annualPerformanceEvaluationattach: this.state.annualPerformanceEvaluationattach,
            employeeCV: this.state.employeeCV,
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
            Summaryofregistrationforcitizens:this.state.Summaryofregistrationforcitizens,
            PoistioninStructure:this.state.PoistioninStructure,
            Previousapprovalsfortheextension:this.state.Previousapprovalsfortheextension,
            Qualifications:this.state.Qualifications,
            Jobdescription:this.state.Jobdescription,
            Careersuccessionplan:this.state.Careersuccessionplan,
            HRCom:this.state.HRCom,
            OtherAttach:this.state.OtherAttach
        });
    }

    checkValidations = () => {
        debugger;
        let jobContractErr = '',
            medicalApprovalsFromTheCometentMedicalCommitteeErr = '',
            passportErr = '',
            familyBookErr = '',
            emaritesIDErr = '',
            annualPerformanceEvaluationattachErr = '',
            employeeCVErr = '',
            personalPhotoErr = '',
            replacePlansForNonLocalsAttachmentError = '',
            SummaryofregistrationforcitizensErr = '',
            PoistioninStructureErr = '',
            PreviousapprovalsfortheextensionErr = '',
            QualificationsErr = '',
            JobdescriptionErr = '',
            CareersuccessionplanErr = '',
            HRComErr = '';
            //OtherAttachError = '';
        
        if (this.state.SummaryofregistrationforcitizensArray.length > 0 ) {
            SummaryofregistrationforcitizensErr = "";
        } else if (this.state.nationalOrNot == 'Yes')
         {
            if (this.state.jobContract === '') {
                SummaryofregistrationforcitizensErr = "thisFieldIsRequired";
            }
        }
        debugger;
        if (this.state.PoistioninStructureArray.length > 0 ) {
            PoistioninStructureErr = "";
        } else 
         {
            if (this.state.PoistioninStructure === '') {
                PoistioninStructureErr = "thisFieldIsRequired";
            }
        }
       if (this.state.PreviousapprovalsfortheextensionArray.length > 0) {
            PreviousapprovalsfortheextensionErr = "";
       } else if (this.state.copyButton === 'Yes' ) {
            if (this.state.Previousapprovalsfortheextension === '') {
                PreviousapprovalsfortheextensionErr = "thisFieldIsRequired";
            }
        }
        if (this.state.QualificationsArray.length > 0) {
            QualificationsErr = "";
        } else {
            if (this.state.Qualifications === '') {
                QualificationsErr = "thisFieldIsRequired";
            }
        }
        if (this.state.JobdescriptionArray.length > 0) {
            JobdescriptionErr = "";
        } else {
            if (this.state.Jobdescription === '') {
                JobdescriptionErr = "thisFieldIsRequired";
            }
        }
        if (this.state.CareersuccessionplanArray.length > 0) {
            CareersuccessionplanErr = "";
        } else if (this.state.nationalOrNot == 'Yes') {
            if (this.state.Careersuccessionplan === '') {
                CareersuccessionplanErr = "thisFieldIsRequired";
            }
        }
        if (this.state.HRComArray.length > 0) {
            HRComErr = "";
        } else {
            if (this.state.HRCom === '') {
                HRComErr = "thisFieldIsRequired";
            }
        }

        if (this.state.jobContractArray.length > 0) {
            jobContractErr = "";
        } else {
            if (this.state.jobContract === '') {
                jobContractErr = "thisFieldIsRequired";
            }
        }

      
        
        if (this.state.medicalApproveArray.length > 0) {
            medicalApprovalsFromTheCometentMedicalCommitteeErr = "";
        } else {
            if (this.state.medicalApprovalsFromTheCometentMedicalCommittee === '') {
                medicalApprovalsFromTheCometentMedicalCommitteeErr = "thisFieldIsRequired";
            }
        }

        if (this.state.passportArray.length > 0) {
            passportErr = "";
        } else {
            if (this.state.passport === '') {
                passportErr = "thisFieldIsRequired";
            }
        }

        // if (this.state.passportArray.length > 0) {
        //     passportErr = "";
        // } else {
        //     if (this.state.passport === '') {
        //         passportErr = "thisFieldIsRequired";
        //     }
        // }

        if (this.state.emiratesArray.length > 0) {
            emaritesIDErr = "";
        } else {
            if (this.state.emaritesID === '') {
                emaritesIDErr = "thisFieldIsRequired";
            }
        }

        // if (this.state.familyBook === '') {
        //     familyBookErr = 'thisFieldIsRequired';
        // }

        if (this.state.annualPerformanceArray.length > 0) {
            annualPerformanceEvaluationattachErr = "";
        } else {
            if (this.state.annualPerformanceEvaluationattach === '') {
                annualPerformanceEvaluationattachErr = "thisFieldIsRequired";
            }
        }

        if (this.state.employeeCvArray.length > 0) {
            employeeCVErr = "";
        } else {
            if (this.state.employeeCV === '') {
                employeeCVErr = "thisFieldIsRequired";
            }
        }

        // if (this.state.personalPhotoArray.length > 0) {
        //     personalPhotoErr = "";
        // } else {
        //     if (this.state.personalPhoto === '') {
        //         personalPhotoErr = "thisFieldIsRequired";
        //     }
        // }

        if (this.state.nationalOrNot === 'No') {
            if (this.state.replacePlansForNonLocalsAttachementFileName === '') {
                replacePlansForNonLocalsAttachmentError = 'thisFieldIsRequired';
            }
        } else {
            replacePlansForNonLocalsAttachmentError = '';
        }
        
        this.setState({
            jobContractErr,
            medicalApprovalsFromTheCometentMedicalCommitteeErr,
            passportErr,
            familyBookErr,
            emaritesIDErr,
            annualPerformanceEvaluationattachErr,
            employeeCVErr,
            personalPhotoErr,
            replacePlansForNonLocalsAttachmentError ,
            SummaryofregistrationforcitizensErr,
            PoistioninStructureErr,
            PreviousapprovalsfortheextensionErr,
            QualificationsErr,
            JobdescriptionErr,
            CareersuccessionplanErr,
            HRComErr
        }, () => {
        
            if (
                !jobContractErr &&
                !medicalApprovalsFromTheCometentMedicalCommitteeErr &&
                !passportErr &&
                !emaritesIDErr &&
                !annualPerformanceEvaluationattachErr &&
                !employeeCVErr &&
                !personalPhotoErr &&
                !replacePlansForNonLocalsAttachmentError &&
                !SummaryofregistrationforcitizensErr &&
                !PoistioninStructureErr&&
                !PreviousapprovalsfortheextensionErr &&
                !QualificationsErr &&
                !JobdescriptionErr &&
                !CareersuccessionplanErr &&
                !HRComErr
            ) {

                this.props.getData.stepTwoData({
                    isEdit: this.state.isEdit,
                    requestId: this.state.requestId,
                    jobContract: this.state.jobContract,
                    medicalApprovalsFromTheCometentMedicalCommittee: this.state.medicalApprovalsFromTheCometentMedicalCommittee,
                    medicalApprovalsFromTheCometentMedicalCommitteeShortName: this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
                    passport: this.state.passport,
                    // familyBook: this.state.familyBook,
                    emaritesID: this.state.emaritesID,
                    personalPhoto: this.state.personalPhoto,
                    annualPerformanceEvaluationattach: this.state.annualPerformanceEvaluationattach,
                    employeeCV: this.state.employeeCV,
                    acopyofthepreviousapprovalsforextensionfromthejurisdiction: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
                    acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
                    copyOfApprovalsForExtensionFromJurisd: this.state.copyOfApprovalsForExtensionFromJurisd,
                    replacePlansForNonLocalsAttachementFileName: this.state.replacePlansForNonLocalsAttachementFileName,
                    Summaryofregistrationforcitizens:this.state.Summaryofregistrationforcitizens,
                    PoistioninStructure:this.state.PoistioninStructure,
Previousapprovalsfortheextension:this.state.Previousapprovalsfortheextension,
Qualifications:this.state.Qualifications,
Jobdescription:this.state.Jobdescription,
Careersuccessionplan:this.state.Careersuccessionplan,
HRCom:this.state.HRCom,
OtherAttach:this.state.OtherAttach,

                });
            }
        });
    }

    submitHandler = (event) => {
        Config.setIsEditFlag(false);
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
    };

    jobContractOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ jobContract: '', jobContractErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ jobContract: e.target.files[0].name, jobContractErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({ 
                                jobContractsFileObj: reader.result 
                            }, () => {
                                Config.setBase64ForJobContract(this.state.jobContractsFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ jobContract: '', jobContractErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ jobContract: '', jobContractErr: 'thisFieldIsRequired' });
        }
    };

    medicalApprovalsFromTheCometentMedicalCommitteeOnChange = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                let fileNames = "";
                let fileSupportArray = [];
                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            medicalApprovalsFromTheCometentMedicalCommittee: '',
                            medicalApprovalsFromTheCometentMedicalCommitteeShortName: '',
                            medicalApprovalsFromTheCometentMedicalCommitteeErr: 'invalidFileType'
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let medicalApprovalsFromTheCometentMedicalCommitteeShortName = fileNames.substring(0, 50);

                            let fileTargetVarible = e.target.files[m];
                            this.setState({
                                medicalApprovalsFromTheCometentMedicalCommittee: fileNames,
                                medicalApprovalsFromTheCometentMedicalCommitteeShortName: medicalApprovalsFromTheCometentMedicalCommitteeShortName,
                                medicalApprovalsFromTheCometentMedicalCommitteeErr: ''
                            }, () => {
                                let tempFile = fileTargetVarible;
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({ medicalApprovalFromCompMedicalCommiteeFileArray: fileSupportArray }, () => {
                                        Config.setBase64MedicalApprovalsFromTheCometentMedicalCommittee(this.state.medicalApprovalFromCompMedicalCommiteeFileArray);
                                    })
                                }
                                reader.readAsDataURL(tempFile);
                            });
                        } else {
                            e.target.value = '';
                            this.setState({
                                medicalApprovalsFromTheCometentMedicalCommittee: '',
                                medicalApprovalsFromTheCometentMedicalCommitteeShortName: '',
                                medicalApprovalsFromTheCometentMedicalCommitteeErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    medicalApprovalsFromTheCometentMedicalCommittee: "",
                    medicalApprovalsFromTheCometentMedicalCommitteeShortName: "",
                    medicalApprovalsFromTheCometentMedicalCommitteeErr: 'fileLimitErr'
                });
            }
        } else {
            e.target.value = '';
            this.setState({
                medicalApprovalsFromTheCometentMedicalCommittee: '',
                medicalApprovalsFromTheCometentMedicalCommitteeShortName: '',
                medicalApprovalsFromTheCometentMedicalCommitteeErr: 'thisFieldIsRequired'
            });
        }
    }
//{/*MGM */}
    SummaryofregistrationforcitizensOnChange = e => {
        
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Summaryofregistrationforcitizens: '', SummaryofregistrationforcitizensErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Summaryofregistrationforcitizens: e.target.files[0].name, SummaryofregistrationforcitizensErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                SummaryofregistrationforcitizensFileObj: reader.result
                            }, () => {
                                Config.setBase64ForSummaryofregistrationforcitizens(this.state.SummaryofregistrationforcitizensFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Summaryofregistrationforcitizens: '', SummaryofregistrationforcitizensErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ Summaryofregistrationforcitizens: '', SummaryofregistrationforcitizensErr: 'thisFieldIsRequired' });
        }
    };


    PoistioninStructureOnChange = e => {
        
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ PoistioninStructure: '', PoistioninStructureErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ PoistioninStructure: e.target.files[0].name, PoistioninStructureErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                PoistioninStructureFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPoistioninStructure(this.state.PoistioninStructureFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ PoistioninStructure: '', PoistioninStructureErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ PoistioninStructure: '', PoistioninStructureErr: 'thisFieldIsRequired' });
        }
    };

   PreviousapprovalsfortheextensionOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Previousapprovalsfortheextension: '', PreviousapprovalsfortheextensionErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Previousapprovalsfortheextension: e.target.files[0].name, PreviousapprovalsfortheextensionErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        
                        reader.onloadend = () => {
                            this.setState({
                                PreviousapprovalsfortheextensionFileObj: reader.result
                            }, () => {
                                Config.setBase64ForPreviousapprovalsfortheextension(this.state.PreviousapprovalsfortheextensionFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                     
                       
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Previousapprovalsfortheextension: '', PreviousapprovalsfortheextensionErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ Previousapprovalsfortheextension: '', PreviousapprovalsfortheextensionErr: 'thisFieldIsRequired' });
        }
    };

    QualificationsOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Qualifications: '', QualificationsErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Qualifications: e.target.files[0].name, QualificationsErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                QualificationsFileObj: reader.result
                            }, () => {
                                Config.setBase64ForQualifications(this.state.QualificationsFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Qualifications: '', QualificationsErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ Qualifications: '', QualificationsErr: 'thisFieldIsRequired' });
        }
    };

    JobdescriptionOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Jobdescription: '', JobdescriptionErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Jobdescription: e.target.files[0].name, JobdescriptionErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                JobdescriptionFileObj: reader.result
                            }, () => {
                                Config.setBase64ForJobdescription(this.state.JobdescriptionFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Jobdescription: '', JobdescriptionErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ Jobdescription: '', JobdescriptionErr: 'thisFieldIsRequired' });
        }
    };

    CareersuccessionplanOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ Careersuccessionplan: '', CareersuccessionplanErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ Careersuccessionplan: e.target.files[0].name, CareersuccessionplanErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                CareersuccessionplanFileObj: reader.result
                            }, () => {
                                Config.setBase64ForCareersuccessionplan(this.state.CareersuccessionplanFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ Careersuccessionplan: '', CareersuccessionplanErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ Careersuccessionplan: '', CareersuccessionplanErr: 'thisFieldIsRequired' });
        }
    };

    HRComOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ HRCom: '', HRComErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ HRCom: e.target.files[0].name, HRComErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                HRComFileObj: reader.result
                            }, () => {
                                Config.setBase64ForHRCom(this.state.HRComFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ HRCom: '', HRComErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ HRCom: '', HRComErr: 'thisFieldIsRequired' });
        }
    };

    OtherAttachOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ OtherAttach: e.target.files[0].name, OtherAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                OtherAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForOtherAttach(this.state.OtherAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ OtherAttach: '', OtherAttachErr: 'thisFieldIsRequired' });
        //}
    };

    //{/*MGM */}
    passportOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ passport: '', passportErr: 'invalidFileType' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ passport: e.target.files[0].name, passportErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({ 
                                passportFileObj: reader.result 
                            }, () => {
                                Config.setBase64ForPassport(this.state.passportFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ passport: '', passportErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ passport: '', passportErr: 'thisFieldIsRequired' });
        }
    };

    handlePreViewNavigation = () => {
        // employeage: this.state.employeage,
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
           
            jobContract: this.state.jobContract,
            medicalApprovalsFromTheCometentMedicalCommittee: this.state.medicalApprovalsFromTheCometentMedicalCommittee,
            medicalApprovalsFromTheCometentMedicalCommitteeShortName: this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
            passport: this.state.passport,
            emaritesID: this.state.emaritesID,
            personalPhoto: this.state.personalPhoto,
            annualPerformanceEvaluationattach: this.state.annualPerformanceEvaluationattach,
            employeeCV: this.state.employeeCV,
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
            replacePlansForNonLocalsAttachementFileName: this.state.replacePlansForNonLocalsAttachementFileName,
            Summaryofregistrationforcitizens:this.state.Summaryofregistrationforcitizens,
            PoistioninStructure:this.state.PoistioninStructure,
Previousapprovalsfortheextension:this.state.Previousapprovalsfortheextension,
Qualifications:this.state.Qualifications,
Jobdescription:this.state.Jobdescription,
Careersuccessionplan:this.state.Careersuccessionplan,
HRCom:this.state.HRCom,
OtherAttach:this.state.OtherAttach
        });
    }

    saveAsDraft = () => {
        debugger;
        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            jobContract: this.state.jobContract,
            medicalApprovalsFromTheCometentMedicalCommittee: this.state.medicalApprovalsFromTheCometentMedicalCommittee,
            medicalApprovalsFromTheCometentMedicalCommitteeShortName: this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
            passport: this.state.passport,
            // familyBook: this.state.familyBook,
            emaritesID: this.state.emaritesID,
            personalPhoto: this.state.personalPhoto,
            annualPerformanceEvaluationattach: this.state.annualPerformanceEvaluationattach,
            employeeCV: this.state.employeeCV,
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
            replacePlansForNonLocalsAttachementFileName: this.state.replacePlansForNonLocalsAttachementFileName,
            Summaryofregistrationforcitizens:this.state.Summaryofregistrationforcitizens,
            PoistioninStructure:this.state.PoistioninStructure,
Previousapprovalsfortheextension:this.state.Previousapprovalsfortheextension,
Qualifications:this.state.Qualifications,
Jobdescription:this.state.Jobdescription,
Careersuccessionplan:this.state.Careersuccessionplan,
HRCom:this.state.HRCom,
OtherAttach:this.state.OtherAttach
        });
    };

    replacePlansForNonLocalsOnChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    replacePlansForNonLocalsAttachementFileName: '',
                    replacePlansForNonLocalsAttachmentError: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({
                        replacePlansForNonLocalsAttachementFileName: e.target.files[0].name, replacePlansForNonLocalsAttachmentError: ''
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                replacePlansForNonLocalsFileObj: reader.result
                            }, () => {
                                Config.setBase64ForExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName(this.state.replacePlansForNonLocalsFileObj);
                            });
                        };
                        reader.readAsDataURL(tempFile);
                    });


                }
                else {
                    e.target.value = '';
                    this.setState({ replacePlansForNonLocalsAttachementFileName: '', replacePlansForNonLocalsAttachmentError: 'invalidFileSize' });
                }
            }

        } else {
            e.target.value = '';
            if (this.state.nationalOrNot === 'No') {
                this.setState({
                    replacePlansForNonLocalsAttachementFileName: '',
                    replacePlansForNonLocalsAttachmentError: 'thisFieldIsRequired'
                });
            } else {
                this.setState({
                    replacePlansForNonLocalsAttachementFileName: '',
                    replacePlansForNonLocalsAttachmentError: ''
                });
            }
        }
    };

    emaritesIDOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ emaritesID: '', emaritesIDErr: 'invalidFileType' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({
                        emaritesID: e.target.files[0].name,
                        emaritesIDErr: ''
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                emiratesIDFileObj: reader.result
                            }, () => {
                                Config.setBase64ForEmaritesID(this.state.emiratesIDFileObj);
                            });
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ emaritesID: '', emaritesIDErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ emaritesID: '', emaritesIDErr: 'thisFieldIsRequired' });
        }
    };

    annualPerformanceEvaluationattachOnChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    annualPerformanceEvaluationattach: '',
                    annualPerformanceEvaluationattachErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({
                        annualPerformanceEvaluationattach: e.target.files[0].name, annualPerformanceEvaluationattachErr: ''
                    }, () => {

                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({
                                annualPerfEvaAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForAnnualPerformanceEvaluationattach(this.state.annualPerfEvaAttachFileObj);
                            });
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        annualPerformanceEvaluationattach: '',
                        annualPerformanceEvaluationattachErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                annualPerformanceEvaluationattach: '',
                annualPerformanceEvaluationattachErr: 'thisFieldIsRequired'
            });
        }
    };

    acopyofthepreviousapprovalsforextensionfromthejurisdictionOnChange = e => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                let fileNames = "";
                let fileSupportArray = [];

                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            acopyofthepreviousapprovalsforextensionfromthejurisdiction: '',
                            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: '',
                            acopyofthepreviousapprovalsforextensionfromthejurisdictionErr: 'invalidFileType'
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName = fileNames.substring(0, 50);

                            let fileTargetVarible = e.target.files[m];

                            this.setState({
                                acopyofthepreviousapprovalsforextensionfromthejurisdiction: fileNames,
                                acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
                                acopyofthepreviousapprovalsforextensionfromthejurisdictionErr: ''
                            }, () => {

                                let tempFile = fileTargetVarible;
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({
                                        acopyofthepreviousapprovalsFileArray: fileSupportArray
                                    }, () => {
                                        Config.setBase64ForAcopyofthepreviousapprovals(this.state.acopyofthepreviousapprovalsFileArray);
                                    });

                                }
                                reader.readAsDataURL(tempFile);
                            });

                        }
                        else {
                            e.target.value = '';
                            this.setState({
                                acopyofthepreviousapprovalsforextensionfromthejurisdiction: '',
                                acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: '',
                                 acopyofthepreviousapprovalsforextensionfromthejurisdictionErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    acopyofthepreviousapprovalsforextensionfromthejurisdiction: "",
                    acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: "",
                    acopyofthepreviousapprovalsforextensionfromthejurisdictionErr: 'fileLimitErr'
                });
            }
        } else {
            e.target.value = '';
            this.setState({ 
                acopyofthepreviousapprovalsforextensionfromthejurisdiction: '',
                acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: ''
            });
        }
    };

    employeeCVOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ employeeCV: '', employeeCVErr: 'invalidFileType' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        employeeCV: e.target.files[0].name, 
                        employeeCVErr: '' 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({ 
                                employeeCVFileObj: reader.result 
                            }, () => {
                                Config.setBase64ForEmployeeCV(this.state.employeeCVFileObj);
                            });
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ employeeCV: '', employeeCVErr: 'invalidFileSize' });
                }
            }

        } else {
            e.target.value = '';
            this.setState({ employeeCV: '', employeeCVErr: 'thisFieldIsRequired' });
        }
    };

    personalPhotoOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ 
                    personalPhoto: '', 
                    personalPhotoErr: 'invalidFileType' 
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let fileTargetVarible = e.target.files[0];
                    this.setState({ 
                        personalPhoto: e.target.files[0].name, 
                        personalPhotoErr: '' 
                    }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();

                        reader.onloadend = () => {
                            this.setState({ 
                                personalPhotoFileObj: reader.result 
                            }, () => {
                                Config.setBase64ForPersonalPhoto(this.state.personalPhotoFileObj);
                            });
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ personalPhoto: '', personalPhotoErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ personalPhoto: '', personalPhotoErr: 'thisFieldIsRequired' });
        }
    };

    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={['Step1', 'Step2']}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.jobContract}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="jobContractFile"
                                        type="file"
                                        name="jobContract"
                                        onChange={(event) => this.jobContractOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="jobContractFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="jobContractFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.jobContract}</span></label>
                                </div>

                                {
                                    this.state.jobContractErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.jobContractErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.jobContractErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.jobContractArray &&
                                    this.state.jobContractArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.jobContractArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.medicalApprovalsFromTheCometentMedicalCommittee}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="medicalApprovalsFromTheCometentMedicalCommitteeFile"
                                        type="file"
                                        name="medicalApprovalsFromTheCometentMedicalCommittee"
                                        onChange={(event) => this.medicalApprovalsFromTheCometentMedicalCommitteeOnChange(event)}
                                        multiple
                                        required
                                    />
                                    <label htmlFor="medicalApprovalsFromTheCometentMedicalCommitteeFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>

                                    <label htmlFor="medicalApprovalsFromTheCometentMedicalCommitteeFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                        {/* <span>{this.state.medicalApprovalsFromTheCometentMedicalCommittee}</span> */}

                                        <span className="attachmentName">{this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.medicalApprovalsFromTheCometentMedicalCommittee}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.medicalApprovalsFromTheCometentMedicalCommitteeErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.medicalApprovalsFromTheCometentMedicalCommitteeErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.medicalApprovalsFromTheCometentMedicalCommitteeErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.medicalApprovalsFromTheCometentMedicalCommitteeErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                            </p>
                            {
                                (this.state.medicalApproveArray &&
                                    this.state.medicalApproveArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalApproveArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                {/*MGM*/}
                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.HRCom}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="HRComFile"
                                        type="file"
                                        name="HRCom"
                                        onChange={(event) => this.HRComOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="HRComFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="HRCom" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.HRCom}</span></label>
                                </div>

                                {
                                    this.state.HRComErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.HRComErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.HRComErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.HRComArray &&
                                    this.state.HRComArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.HRComArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        {this.state.copyButton === 'Yes' ? <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Previousapprovalsfortheextension}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="PreviousapprovalsfortheextensionFile"
                                        type="file"
                                        name="Previousapprovalsfortheextension"
                                        onChange={(event) => this.PreviousapprovalsfortheextensionOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="PreviousapprovalsfortheextensionFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="Previousapprovalsfortheextension" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.Previousapprovalsfortheextension}</span></label>
                                </div>

                                {
                                    this.state.PreviousapprovalsfortheextensionErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.PreviousapprovalsfortheextensionErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.PreviousapprovalsfortheextensionErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.PreviousapprovalsfortheextensionArray &&
                                    this.state.PreviousapprovalsfortheextensionArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.PreviousapprovalsfortheextensionArray} />
                                    : <span></span>
                            }

                        </div> : ''}
              
                    </MDBCol>
                </MDBRow>
                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Qualifications}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="QualificationsFile"
                                        type="file"
                                        name="Qualifications"
                                        onChange={(event) => this.QualificationsOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="QualificationsFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="Qualifications" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.Qualifications}</span></label>
                                </div>

                                {
                                    this.state.QualificationsErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.QualificationsErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.QualificationsErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.QualificationsArray &&
                                    this.state.QualificationsArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.QualificationsArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Jobdescription}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="JobdescriptionFile"
                                        type="file"
                                        name="Jobdescription"
                                        onChange={(event) => this.JobdescriptionOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="JobdescriptionFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="Jobdescription" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.Jobdescription}</span></label>
                                </div>

                                {
                                    this.state.JobdescriptionErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.JobdescriptionErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.JobdescriptionErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.JobdescriptionArray &&
                                    this.state.JobdescriptionArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.JobdescriptionArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>
                </MDBRow>
                 {this.state.nationalOrNot == 'Yes' ?   
                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}> 
                
                        
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Summaryofregistrationforcitizens}<span className="requireStar">*</span></label>
                                <div className="input-group">
                                    <div className="custom-file-inp">
                                        <input
                                            id="SummaryofregistrationforcitizensFile"
                                            type="file"
                                            name="Summaryofregistrationforcitizens"
                                            onChange={(event) => this.SummaryofregistrationforcitizensOnChange(event)}
                                            required
                                        />
                                        <label htmlFor="SummaryofregistrationforcitizensFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                        <label htmlFor="Summaryofregistrationforcitizens" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.Summaryofregistrationforcitizens}</span></label>
                                    </div>

                                    {
                                        this.state.SummaryofregistrationforcitizensErr === 'thisFieldIsRequired' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.SummaryofregistrationforcitizensErr === 'invalidFileType' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].invalidFileType}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.SummaryofregistrationforcitizensErr === 'invalidFileSize' ?
                                            <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.SummaryofregistrationforcitizensArray &&
                                        this.state.SummaryofregistrationforcitizensArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.SummaryofregistrationforcitizensArray} />
                                        : <span></span>
                                }

                            </div>
                        </MDBCol>
                    
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                      <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.Careersuccessionplan}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="CareersuccessionplanFile"
                                        type="file"
                                        name="Careersuccessionplan"
                                        onChange={(event) => this.CareersuccessionplanOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="CareersuccessionplanFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="Careersuccessionplan" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.Careersuccessionplan}</span></label>
                                </div>

                                {
                                    this.state.CareersuccessionplanErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.CareersuccessionplanErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.CareersuccessionplanErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.CareersuccessionplanArray &&
                                    this.state.CareersuccessionplanArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.CareersuccessionplanArray} />
                                    : <span></span>
                            }

                        </div>
                       
                    </MDBCol>
   
                  
                </MDBRow>
                    : ''} 
              

              <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}> 
                
                        
                <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <div className="form-group">
                        <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.PoistioninStructure}<span className="requireStar">*</span></label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="PoistioninStructureFile"
                                    type="file"
                                    name="PoistioninStructure"
                                    onChange={(event) => this.PoistioninStructureOnChange(event)}
                                    required
                                />
                                <label htmlFor="PoistioninStructureFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                <label htmlFor="PoistioninStructure" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.PoistioninStructure}</span></label>
                            </div>

                            {
                                this.state.PoistioninStructureErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.PoistioninStructureErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.PoistioninStructureErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>

                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                            {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.PoistioninStructureArray &&
                                this.state.PoistioninStructureArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.PoistioninStructureArray} />
                                : <span></span>
                        }

                    </div>
                </MDBCol>
            
               </MDBRow>


                {/*MGM*/}
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.passport}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="passportFile"
                                        type="file"
                                        name="passport"
                                        onChange={(event) => this.passportOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="passportFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="passportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.passport}</span></label>
                                </div>
                                {
                                    this.state.passportErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.passportErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.passportErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportArray &&
                                    this.state.passportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.annualPerformanceEvaluationattach}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="annualPerformanceEvaluationattachFile"
                                        type="file"
                                        name="annualPerformanceEvaluationattach"
                                        onChange={(event) => this.annualPerformanceEvaluationattachOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="annualPerformanceEvaluationattachFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="annualPerformanceEvaluationattachFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.annualPerformanceEvaluationattach}</span></label>
                                </div>
                                {
                                    this.state.annualPerformanceEvaluationattachErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.annualPerformanceEvaluationattachErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.annualPerformanceEvaluationattachErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.annualPerformanceArray &&
                                    this.state.annualPerformanceArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.annualPerformanceArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.emaritesID}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="emaritesIDFile"
                                        type="file"
                                        name="emaritesID"
                                        onChange={(event) => this.emaritesIDOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="emaritesIDFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="emaritesIDFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.emaritesID}</span></label>
                                </div>
                                {
                                    this.state.emaritesIDErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.emaritesIDErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.emaritesIDErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.emiratesArray &&
                                    this.state.emiratesArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.emiratesArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.employeeCV}<span className="requireStar">*</span></label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="employeeCVFile"
                                        type="file"
                                        name="employeeCV"
                                        onChange={(event) => this.employeeCVOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="employeeCVFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="employeeCVFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.employeeCV}</span></label>
                                </div>
                                {
                                    this.state.employeeCVErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.employeeCVErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.employeeCVErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.employeeCvArray &&
                                    this.state.employeeCvArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.employeeCvArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.personalPhoto}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="personalPhotoFile"
                                        type="file"
                                        name="personalPhoto"
                                        onChange={(event) => this.personalPhotoOnChange(event)}
                                        required />
                                    <label htmlFor="personalPhotoFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="personalPhotoFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.personalPhoto}</span></label>
                                </div>
                                {
                                    this.state.personalPhotoErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.state.personalPhotoErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.personalPhotoArray &&
                                    this.state.personalPhotoArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.personalPhotoArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        {this.state.nationalOrNot == 'No' ? 
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].replacePlansForNonLocals}
                                {
                                    this.state.nationalOrNot === 'No' ?
                                        <span className="requireStar">*</span>
                                        :
                                        null
                                }
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="replacePlansForNonLocalsFileID"
                                        type="file"
                                        name="replacePlansForNonLocalsFile"
                                        onChange={(event) => this.replacePlansForNonLocalsOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="replacePlansForNonLocalsFileID" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="replacePlansForNonLocalsFileID" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.replacePlansForNonLocalsAttachementFileName}</span></label>
                                </div>
                                {
                                    this.state.replacePlansForNonLocalsAttachmentError === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.replacePlansForNonLocalsAttachmentError === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.replacePlansForNonLocalsAttachmentError === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.replacePlansForNonLocalsArray &&
                                    this.state.replacePlansForNonLocalsArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.replacePlansForNonLocalsArray} />
                                    : <span></span>
                            }
                        </div>
                         : ''} 
                        
                    </MDBCol>

             
                </MDBRow>

                
                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.OtherAttach}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="OtherAttachFile"
                                        type="file"
                                        name="OtherAttach"
                                        onChange={(event) => this.OtherAttachOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="OtherAttachFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="OtherAttachFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.OtherAttach}</span></label>
                                </div>

                                {
                                    this.state.OtherAttachErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.OtherAttachArray &&
                                    this.state.OtherAttachArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.OtherAttachArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>


                </MDBRow>
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">{i18n[this.props.isArabic ? 'ar' : 'en'].previous}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.props.isArabic ? 'ar' : 'en'].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }

}