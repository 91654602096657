
const validator = {

    validateNotEmpty: (str) => {
        let regex = new RegExp('.{1,}', 'g');
        return regex.test(str);
    },

    /*validateEmiratesId: (str) => {
        if (str) {
            let str1 = str.replace("-", "");
            let str2 = str1.replace("-", "");
            let str3 = str2.replace("-", "");
            if (str.length === 18 && parseInt(str3) > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },*/

    validateEmiratesId: (str) => {
       
        let isValidEmirate = false;
        let emirateId = str;
        if (str) {
            let str1 = str.replace("-", "");
            let str2 = str1.replace("-", "");
            let str3 = str2.replace("-", "");
            emirateId = str3;
            let chars = emirateId.split('');
            let numberMultiSum = 0;
            let remainingDigitSum = 0;
            for (let i = 1; i < chars.length; i += 2) {
                let num = (parseInt(chars[i]) * 2).toString();
                if (num > 9) {
                    let char1 = num.split('');
                    let tempSum = 0;
                    for (let j = 0; j < char1.length; j++) {
                        tempSum = tempSum + parseInt(char1[j]);
                    }
                    numberMultiSum = numberMultiSum + tempSum;
                } else {
                    numberMultiSum = numberMultiSum + parseInt(num);
                }
            }
            // System.out.println();
            for (let i = chars.length - 3; i >= 0; i -= 2) {
                remainingDigitSum = remainingDigitSum + parseInt(chars[i]);
            }
            let totalSum = numberMultiSum + remainingDigitSum;
            let nextHighestNumber = 0;
            if (totalSum % 10 == 0) {
                nextHighestNumber = totalSum;
            } else {
                let tt = 10 - totalSum % 10;
                nextHighestNumber = totalSum + tt;
            }
            if ((nextHighestNumber - totalSum) == parseInt(chars[chars.length - 1])) {
                isValidEmirate = true;
            }
        } else {
            isValidEmirate = false;
        }
        return isValidEmirate;
       
    },

    validateEmail: (email) => {
        if (!email) {
            return false;
        }
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(email)) {
            return true;
        } else {
            return false;
        }
    },

    validateYear: (year) => {
        if (!year) {
            return false;
        }
        if (parseInt(year) > 1920) {
            return true;
        }
        else {
            return false;
        }
    },

    validateContactNumber: (contactNumber) => {
        if (!contactNumber) {
            return false;
        }
        
        let contactNumberLen = contactNumber.length;
        let contactNumAfterReplace = contactNumber.replace("-", "");
        let contactNumAfterReplace2 = contactNumAfterReplace.replace("-", "");
        let posIfValueIsPresentInStringOrNot = contactNumber.indexOf("-");
        // include method is not supported by IE
        // if (contactNumber.includes("-")) {
        if (posIfValueIsPresentInStringOrNot >= 0) {
            debugger;
            if ((contactNumberLen === 11 || contactNumberLen === 12) && parseInt(contactNumAfterReplace2) > 0) {
                //Check for string starts with 05, 02, 03
                let firstLetter = contactNumber.charAt(0);
                let secondLetter = contactNumber.charAt(1);
                if (
                    parseInt(firstLetter) === 0 &&
                    (
                        parseInt(secondLetter) === 5 ||
                        parseInt(secondLetter) === 2 ||
                        parseInt(secondLetter) === 3
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            if ((contactNumberLen === 9 || contactNumberLen === 10) && parseInt(contactNumAfterReplace2) > 0) {
                //Check for string starts with 05, 02, 03
                let firstLetter = contactNumber.charAt(0);
                let secondLetter = contactNumber.charAt(1);
                if (
                    parseInt(firstLetter) === 0 &&
                    (
                        parseInt(secondLetter) === 5 ||
                        parseInt(secondLetter) === 2 ||
                        parseInt(secondLetter) === 3
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },

    validateUnifiedNumber: (unifiedNumber) => {
        if (!unifiedNumber) {
            return false;
        } else {
            let regex = /^[0-9]{0,9}$/;
            if (regex.test(unifiedNumber)) {
                return true;
            }
        }
    },

    calculateAge: (dob) => {
        if (!dob) {
            return false;
        }
        let today = new Date();
        let birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        let month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
        }
        return age;
    },
   
    calculateAgeforRetirment: (dob , isArabic) => {
        if (!dob) {
            return false;
        }
        let today = new Date();
        let birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        let returnval ='';
        let month = today.getMonth() - birthDate.getMonth();

        let months=        today.getMonth() - birthDate.getMonth();
        if(months<0)
        {
            months=months+12;
        }

        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
        }
        if(isArabic==1)
        {
            returnval = age + 'سنة و ' + months +' شهر ';
        }
        else
        {
            returnval = age + ' Years and ' +month +'Months';
        }
        return returnval;
    },

    calculateMinDOBForNational: () => {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var tempDate = new Date(year - 59, month, day);
        return tempDate;
    },

    calculateMinDOBForNonNational: () => {
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        var tempDate1 = new Date(year - 54, month-2, day);
        return tempDate1;
    },
    calculateMaxiumAge: () => {
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var tempDate = new Date(year - 75, month-2, day);
        return tempDate;
    },
    calculateMinimumAge: () => {
        var d = new Date();
       //s d=Moment(d).subtract(3, 'month');
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var tempDate = new Date(year - 54, month-2, day);
        return tempDate;
    },
    calculateMinHiringDate: () => {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var tempDate = new Date(year - 1, month+3 , day);
        return tempDate;
    },
    calculateMaxHiringDate: () => {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var tempDate = new Date(year - 56, month, day);
        return tempDate;
    }
   

};

export default validator;