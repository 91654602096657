/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import classNames from "classnames";

import {
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBModalFooter,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    Popover
} from "mdbreact";
import logovert from "./../assets/images/logo-vert.png";
import HRAHubLogo from './../assets/images/HRA-logo-newLogo.png';
import showHidePasswordIcon from "./../assets/images/icons/show_hide_password-10-512.png";
import hideShowPasswordIcon from "./../assets/images/icons/hide_show_password-09-512.png";
import LoadingOverlay from "react-loading-overlay";
import ApiRequest from "./../services/ApiRequest";
import Config from "./../utils/Config";
import { passwordResetPayload } from "./../utils/Payload";
import { confirmAlert } from "react-confirm-alert";
import "./../css/react-confirm-alert.css";
import "../css/style.css";
import "../css/util.css";
import "../css/main.css";
import "../css/login.css";
import i18n from "../i18n";
import Switch from "react-switch";
import Validator from "./../utils/Validator";
import Footer from './../components/Footer';
import IEMessage from './../components/IEMessage/IEMessage';


export default class LoginScreen extends Component {
    constructor(props) {
        super(props);
        if (!localStorage.getItem("Lang")) {
            localStorage.setItem("Lang", "ar");
        }
        this.state = {
            email: '',
            titleAlert: "",
            messageAlert: "",
            checked: localStorage.getItem("Lang") === "ar" ? true : false,
            isLoading: false,
            fields: {},
            errors: {},
            username: "",
            password: "",
            hidePass: true,
            modal: false,
            isArabic: localStorage.getItem("Lang") === "ar" ? true : false,
            // isArabic: true,
        };

     
        
    }
    

    componentDidMount = () => {
        //Set All Dropdown api Data in config only once - By default false
        Config.setAllDpDwnAPIresponseFirstTime(false);
        // let endPoint = "version";
        // let url = Config.getUrl(endPoint);
        // this.setState({ isLoading: true }, () => {
        //     ApiRequest.sendRequestForGet(url, res => {
        //         this.setState({
        //             isLoading: false
        //         }, () => {
        //             if (res === "Network Error") {
        //                 this.setState({
        //                     titleAlert: i18n[this.getLocale()].error,
        //                     messageAlert: i18n[this.getLocale()].network_message
        //                 }, () => {
        //                    // this.alertSubmit();
        //                 });
        //             } else {
        //                 if (res.Status) {
        //                     let serverVersion = res.data;
        //                     let localVersion = Config.getVersionNumer();
        //                     if (serverVersion != localVersion) {
        //                         Config.setVersionNumber(serverVersion);
        //                         window.location.reload(true);
        //                     }
        //                 } else {
        //                     //alert(res.Message);
        //                     this.setState({
        //                         titleAlert: i18n[this.getLocale()].error,
        //                         messageAlert: i18n[this.getLocale()].login_message_error
        //                     }, () => {
        //                     //s    this.alertSubmit();
        //                     });
        //                 }
        //             }
        //         });
        //     });
        // });
    };

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    getLocale = () => {
        if (this.state.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    handleUserNameChange = (event) => {
        if (event.target.value && event.target.value.trim()) {
            let regex = /^[\u0621-\u064AA-Za-z0-9_ \n]{0,50}$/;
            if (regex.test(event.target.value) && event.target.value.length < 50) {
                this.setState({ username: event.target.value });
            }
        } else {
            this.setState({ username: '' });
        }
    };

    handlePasswordChange = e => {
        let temp = e.target.value;
        this.setState({
            password: temp
        });
    };

    loginButtonClick = (e) => {
        
        
        e.preventDefault();
        let loginObject = {};
        loginObject.Username = this.state.username;
        loginObject.Password = this.state.password;
       // localStorage.setItem('eservicesemail' , this.state.username);
        let endPoint = "login";
        let url = Config.getUrl(endPoint);
      
       

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, loginObject, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res === "Network Error") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].network_message
                        }, () => {
                            this.alertSubmit();
                        });
                    } else {
                        if (res.Status) {
                            let loginData = res.data;
                           // console.log('login data : ' , loginData);

                            debugger;

                            Config.setLocalStorageAds();
                            Config.setWorkShopDatesFlag();
                            Config.setLocalStorageLoginData(loginData);
                           // Config.setLocalStorageeservicesemail(loginData.email);
                            let isPasswordReset = res.data.IspasswordReset;
                            if (isPasswordReset === 1) {
                                this.props.history.push("/confirmPassword");
                            } else {
                                if(res.data.redirectToSurvey)
                                {
                                    this.props.history.push("/survey");
                                }
                                else
                                {
                                this.props.history.push("/landing");
                                }
                            }
                            //this.props.history.push('/confirmPassword');
                        } else {
                            //alert(res.Message);
                            this.setState({
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].login_message_error
                            }, () => {
                                this.alertSubmit();
                            });
                        }
                    }
                });
            });
        });
    };

    toggle = () => {
        this.setState({
            email: '',
            emailError:'',
            modal: !this.state.modal
        });
    };

    handleSwitch = checked => {
        if (this.state.checked === true) {
            this.setState({ isArabic: false });
            localStorage.setItem("Lang", "en");
        } else {
            this.setState({ isArabic: true });
            localStorage.setItem("Lang", "ar");
        }
        this.setState({ checked });
    };

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        
 


        let emailError = '';
        if (this.state.email === "") {
            emailError = 'thisFieldIsRequired';
        } else {
            if (!Validator.validateEmail(this.state.email)) {
                emailError = 'enter_valid_email';
            } else {
                emailError = '';
            }
        }

        this.setState({
            emailError: emailError
        }, () => {
            if (emailError === '') {
                // call the password reset api
                let obj = {};
                obj.email = this.state.email;
                // call save status update api
                let payload = passwordResetPayload(obj);
                let endPoint = "passwordreset";
                let url = Config.getUrl(endPoint);
               
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, res => {
                        this.setState({
                            isLoading: false
                        }, () => {
                            if (res.Status) {
                                this.toggle();
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].message,
                                    messageAlert: i18n[this.getLocale()].password_reset
                                }, () => {
                                    this.alertSubmit();
                                });
                            } else {
                                this.toggle();
                            }
                        });
                    });
                }
                );
            }
        });

    };

    handleemailChange = event => {
        if (event.target.value) {
            if (event.target.value.length < 50) {
                this.setState({ email: event.target.value, emailError: "" });
            }
        } else {
            this.setState({ email: '' });
        }

    };

    showPassIconClick = (e) => {
        //e.preventDefault();
        this.setState({ hidePass: !this.state.hidePass });
    };
    
    IEBobUp()
    {
        
        const isIE = /*@cc_on!@*/false || !!document.documentMode;

       
        if(isIE!=false)
        {
            confirmAlert({
                title: i18n[this.getLocale()].BrowserNotSupportedTitle,
                message:  i18n[this.getLocale()].BrowserNotSupported,
                buttons: [
                    {
                        label: i18n[this.getLocale()].ok
                    }
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
                childrenElement: () => <button id='IEalertOkButton'  name={i18n[this.getLocale()].ok}/>
            });
            setTimeout(() => {
                document.getElementById('IEalertOkButton').focus()
            }, 100);
          
        }
        
    }

    render() {
      
      
        

     
        this.IEBobUp();

        return (
            <div className="loginBoxContainer">
                <LoadingOverlay active={this.state.isLoading} spinner={true}>
                    <form className={classNames("form-signin")}>
                    
                        <div className="text-center mb-4">
                            <div className="Login_LogoDiv" style={{flexDirection:this.state.isArabic ? 'row-reverse' : 'row'}}>
                                <img className="mb-4" src={logovert} alt="HRA Logo" />
                                {/* <img className="mb-4" style={{height:'115px'}} src={HRAHubLogo} alt="HRA Logo" /> */}
                            </div>
                            <div className="LoginHeader" style={{flexDirection:this.state.isArabic ? 'row-reverse' : 'row'}}>
                               <div style={{width:'80%'}}>
                                    <h1 className="font-weight-normal" style={{textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.getLocale()].login}</h1>
                                    <p style={{ fontFamily: 'ge_ss_text_Regular' ,textAlign: this.state.isArabic ? 'right' : 'left' }}>{i18n[this.getLocale()].loginwithcredentials}</p>
                               </div>
                               <div style={{width:'30%'}}>
                                    <img className="" src={HRAHubLogo} alt="HRALogonew" style={{ width:100 }} />
                               </div>
                            </div>
                        </div>
                        <div className="form-label-group" style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                            <input
                                type="text"
                                name="username"
                                value={this.state.username}
                                onChange={this.handleUserNameChange}
                                placeholder={i18n[this.getLocale()].enterusername}
                                className="form-control"
                                style={{ direction: this.state.isArabic ? "rtl" : "ltr" }}
                            />
                            <label htmlFor="username">{i18n[this.getLocale()].emailLogin}</label>
                            {/* <span className="text-danger">{errors.username}</span> */}
                        </div>
                        <div className="form-label-group" style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                            <input
                                type={this.state.hidePass ? "password" : "text"}
                                name="password"
                                onChange={this.handlePasswordChange}
                                placeholder={i18n[this.getLocale()].enterpassword}
                                className="form-control"
                                style={{ direction: this.state.isArabic ? "rtl" : "ltr" }}
                            />
                            <img className={this.state.isArabic ? "field-icon-ar" : "field-icon-en"} src={this.state.hidePass ? hideShowPasswordIcon : showHidePasswordIcon} alt="show hide icon" onClick={e => this.showPassIconClick(e)} />
                            <label htmlFor="password">{i18n[this.getLocale()].passwordLogin} </label>
                            {/* <span className="text-danger">{errors.password}</span> */}
                        </div>
                        
                        {/* Forgot Password */}
                        <div
                            className="flex-sb-m w-full p-t-3 p-b-32"
                            style={{
                                flexDirection: this.state.isArabic ? "row" : "row-reverse"
                            }}>
                            <div>
                                <p
                                    className="txt1"
                                    onClick={this.toggle.bind(this)}
                                    style={{
                                        cursor: "pointer",
                                        fontFamily: "ge_ss_text_Regular",
                                        fontSize: "14px",
                                        marginRight: "12px",
                                        textAlign: this.state.isArabic ? "left" : "right"
                                    }}
                                >
                                    {i18n[this.getLocale()].forgotPasswordLogIn}
                                </p>
                            </div>
                        </div>
                        {/* End Forgot Password */}

                        <button
                            onClick={e => this.loginButtonClick(e)}
                            // className="btn btn-lg btn-primary btn-block" type="submit"
                            className="btn btn-lg btn-primary btn-block">
                            {i18n[this.getLocale()].signin}
                        </button>
                        <div style={{ display: "flex", flexDirection: "row", padding: "20px", justifyContent: "center", alignItems: "center" }}>
                            <label style={{ paddingRight: "10px", paddingLeft: "10px", fontFamily: "ge_ss_text_Regular", fontSize: "14px", color: "#999999" }}>
                                {i18n[this.getLocale()].english}
                            </label>
                            <Switch
                                onChange={this.handleSwitch}
                                checked={this.state.checked}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                height={20}
                                onColor={"#6a99ad"}
                                offColor={"#6a99ad"}
                            />
                            <label style={{ paddingRight: "10px", paddingLeft: "10px", fontFamily: "ge_ss_text_Regular", fontSize: "14px", color: "#999999" }}>
                                {i18n[this.getLocale()].arabic}
                            </label>
                        </div>
                  
                       
                     

                    </form>
                    <div style={{ display: 'flex',justifyContent: 'center',position:'static',borderTop:'none'}} className="footerTextlogin">
                    <div style={{ display: 'block', direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                {/* <div style={{ textAlign:"center" }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].writeusat}
                                <a style={{ color: 'blue' }} href={"mailto:" + i18n[this.state.isArabic ? 'ar' : 'en'].supportemail} className="footerSupportTxt">{i18n[this.state.isArabic ? 'ar' : 'en'].supportemail}
                </a>
                </div>
                 */}
            <div style={{ display: 'block', direction: this.state.isArabic ? 'rtl' : 'ltr' }}><br/> {i18n[this.state.isArabic  ? 'ar' : 'en'].footerText}</div>

           
            </div>        
        </div>
                    {/* Forgot Password footer */}
                    <MDBModal
                        isOpen={this.state.modal}
                        toggle={this.toggle}
                        position="bottom">
                        <MDBModalHeader
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                fontFamily: "ge_ss_text_Regular",
                                width: "100%"
                            }}
                            toggle={this.toggle}>
                            <span style={{ display: "flex", flexDirection: this.state.isArabic ? "row-reverse" : "row", width: "100%" }}>
                                {i18n[this.getLocale()].forgotPassword  }
                            </span>
                        </MDBModalHeader>

                        <MDBModalBody style={{ backgroundColor: "#6a99ad" }}>
                            <MDBContainer fluid style={{ overflow: "hidden" }}>
                                <MDBRow md="12" style={{ backgroundColor: "#6a99ad" }}>
                                    <MDBCol
                                        md="12"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>

                                        <form
                                            style={{ marginTop: 20, width:'100%' }}
                                            className="needs-validation"
                                            onSubmit={this.submitHandler}
                                            noValidate>
                                            <MDBRow className="formRow">
                                                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div className="form-group">
                                                        <label
                                                            style={{
                                                                fontFamily: "ge_ss_text_Regular",
                                                                display: "flex",
                                                                flexDirection: this.state.isArabic
                                                                    ? "row-reverse"
                                                                    : "row",
                                                                width: "100%",
                                                                color: "white"
                                                            }}
                                                            htmlFor="formGroupExampleInput">
                                                            {i18n[this.getLocale()].adminPanelKeys.email}
                                                            <span
                                                                style={{
                                                                    flexDirection: this.state.isArabic
                                                                        ? "row-reverse"
                                                                        : "row",
                                                                    color: "red"
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>

                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="Email"
                                                            maxLength={50}
                                                            name="Email"
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: this.state.isArabic
                                                                    ? "row-reverse"
                                                                    : "row",
                                                                direction: this.state.isArabic ? "rtl" : "ltr",
                                                                borderRadius: "5px"
                                                            }}
                                                            value={this.state.email}
                                                            onChange={this.handleemailChange}
                                                            required
                                                        />

                                                        {
                                                            this.state.emailError == 'enter_valid_email' ?
                                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%', fontWeight: '600' }}>
                                                                    {i18n[this.getLocale()].enter_valid_email}
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            this.state.emailError == 'thisFieldIsRequired' ?
                                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%', fontWeight: '600' }}>
                                                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </form>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBRow
                                style={{
                                    display: "flex",
                                    flexDirection: this.state.isArabic ? "row-reverse" : "row",
                                    width: "100%",
                                    paddingRight: "1%",
                                    paddingLeft: "1%"
                                }}>
                                <MDBBtn
                                    size="md"
                                    type="submit"
                                    style={{
                                        textAlign: "center",
                                        maxHeight: "5vw",
                                        fontFamily: "ge_ss_text_Regular"
                                    }}
                                    color="primary"
                                    onClick={this.submitHandler}
                                >
                                    {" "}
                                    {i18n[this.getLocale()].submit}
                                </MDBBtn>

                              
                            </MDBRow>
                           
                        </MDBModalFooter>
                    </MDBModal>


{/**** IE  */}


                </LoadingOverlay>
            </div>
        );
        
    }
}
