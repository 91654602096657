import React, { Component } from 'react';
import { MDBCol, MDBCard, MDBCardBody, MDBRow, MDBFormInline, MDBBtn } from 'mdbreact';
import { saveCommentPayload, getRequestByIdPayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import Config from './../../utils/Config';
import i18n from './../../i18n';
import LoadingOverlay from 'react-loading-overlay'
import ToastComponent from './../ToastComponent';

export default class CommentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestType: '',
            isLoading: false,
            commentValue: '',
            commentsArray: [],
            commentValueErr: false,
            iseditcomment:this.props.iseditcomment,
            
        };
        this.comment = "";
    }
GetComment =()=>{

    let id = this.props.id;
    let payload ={};
    payload.requestid=id;
    payload.requestType=this.props.requesttypeid;
    payload.userid=Config.getLocalStorageLoginData().id;
     let endPoint = 'GetCommentbyID'
    let url = Config.getUrl(endPoint);
    this.setState({
        isLoading: true
    }, () => {
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.setState({
                isLoading: false
            }, () => {
                if (res.Status) {
                 
                    let commentValue = res.data.comment;
                
                     
                        this.setState({
                            commentValue: commentValue
                        }, () => {
                            // TODO
                        })
                   
                } else {
                    // TODO
                }
            })
        })
    })

}
    componentDidMount = () => {
   
      
        if(this.state.iseditcomment !=0)
       {
      
            this.GetComment();}
        if (this.props.requesttypeid) {
            if (this.props.requesttypeid === Config.getSciRequestTypeId()) {
                // this.callRequestById('GetscirequestbyID');
            } else if (this.props.requesttypeid === Config.getInformationRequestTypeId()) {
                // this.callRequestById('GetinformationrequestbyID');
            } else if (this.props.requesttypeid === Config.getBonusRequestTypeId()) {
                // this.callRequestById('GetbounsrequestbyID');
            } else if (this.props.requesttypeid === Config.getAmendSalaryScaleRequestTypeId()) {
                // this.callRequestById('GetamendsalaryscalebyID');
            } else if (this.props.requesttypeid === Config.getAmendEmployeeSalaryRequestTypeId()) {
                // this.callRequestById('GetAmmendEmployeeSalarybyID');
            } else if (this.props.requesttypeid === Config.getHrReviewPolicyRequestTypeId()) {
                // this.callRequestById('GetHRPolicyReviewbyID');
            } else if (this.props.requesttypeid === Config.getExceptionADHousingRequestTypeId()) {
                // this.callRequestById('GetHousingPolicybyID');
            } else if (this.props.requesttypeid === Config.getSecondmentForFederalAgencyRequestTypeId()) {
                // this.callRequestById('GetFederalSecondmentbyID');
            } else if (this.props.requesttypeid === Config.getExceptionAcompanypatientRequestTypeId()) {
                // this.callRequestById('GetPoliciesPatientrequestbyID');
            } else if (this.props.requesttypeid === Config.getRetirmentRequestTypeId()) {
                // this.callRequestById('GetRetirementbyID');
            }
        }
    }

    callRequestById = (endPoint) => {
        let id = this.props.id;
        let payload = getRequestByIdPayload(id);
        // let endPoint = 'GetscirequestbyID'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let tempCommentsArray = [];
                        let commentDataFromRes = res.data.comments;
                        if (commentDataFromRes && commentDataFromRes.length > 0) {
                            for (let index = 0; index < commentDataFromRes.length; index++) {
                                let tempCommentObj = {};
                                let comment = commentDataFromRes[index].notes;
                                let commentedby = commentDataFromRes[index].commentedby;
                                let commentedon = commentDataFromRes[index].commentedon;
                                if (comment !== "") {
                                    tempCommentObj.name = comment
                                    tempCommentObj.commentedby = commentedby
                                    tempCommentObj.commentedon = commentedon
                                    tempCommentsArray.push(tempCommentObj);
                                }
                            }
                            this.setState({
                                commentsArray: tempCommentsArray
                            }, () => {
                                // TODO
                            })
                        }
                    } else {
                        // TODO
                    }
                })
            })
        })
    }

    updateCommentsArray = (i) => {
        let temp = [...this.state.commentsArray];
        temp.splice(i, 1);
        this.setState({
            commentsArray: temp
        }, () => {
            this.props.update(this.state.commentsArray)
        });
    }

    populateComments = () => {
        return (
            this.state.commentsArray && this.state.commentsArray.map((item, index) => {
                return (
                    <div key={index}>
                        <MDBRow style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <MDBCard style={{ width: "100%" }}>
                                <MDBCardBody style={{ display: 'flex' }}>
                                    <MDBCol>
                                        <MDBRow md='12' style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                                <lable>{i18n[this.props.isArabic ? 'ar' : 'en'].CreatedBy}:
                                            asd    {item.commentedby}</lable>
                                            </MDBCol>
                                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>

                                                <lable>{i18n[this.props.isArabic ? 'ar' : 'en'].Date} :
                                                 {item.commentedon}</lable>
                                            </MDBCol>
                                        </MDBRow>

                                        <MDBRow md='12' style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            <MDBCol md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                                <label>{i18n[this.props.isArabic ? 'ar' : 'en'].Comment}: {item.name}</label>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBRow>
                        <br />

                    </div>)
            })
        );
    }

    updateComment = () => {
        let obj = {}
        obj.id = this.props.id;;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
        obj.notes = this.state.commentValue;
        obj.isEditComment=this.state.iseditcomment;
        let payload = saveCommentPayload(obj);
        payload.isReopen = this.props.isReopen;
        let endPoint = 'SaveComment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        this.props.toggle(true);
                        // let tempCommentsArray = [];
                        // let commentDataFromRes = res.data;
                        // if (commentDataFromRes && commentDataFromRes.length > 0) {
                        //     for (let index = 0; index < commentDataFromRes.length; index++) {
                        //         let tempCommentObj = {};
                        //         let comment = commentDataFromRes[index].notes;
                        //         let commentedby = commentDataFromRes[index].commentedby;
                        //         let commentedon = commentDataFromRes[index].commentedon;
                        //         if (comment !== "") {
                        //             tempCommentObj.name = comment
                        //             tempCommentObj.commentedby = commentedby
                        //             tempCommentObj.commentedon = commentedon

                        //             tempCommentsArray.push(tempCommentObj);
                        //         }
                        //     }
                        //     this.setState({
                        //         commentValue: '',
                        //         commentsArray: tempCommentsArray
                        //     }, () => {
                        //         this.props.update(this.state.commentsArray);
                        //     })
                        // }
                    } else {
                        // TODO
                        this.props.toggle(false);
                        ToastComponent.notifyErr(i18n[this.props.isArabic ? 'ar' : 'en'].failedtosavecomment);
                    }
                })
            })
        })
    }

    render() {

        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        {this.props.isReopen ? 
                        <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center', }}>
                                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left'  }}>
                                    <span>يرجي ذكر أسباب أعادة فتح الطلب</span>
                                    
                                    
                            </MDBCol>
                            </MDBRow>
                            : null}
                        {
                            !this.props.disabled ? <MDBRow style={{ justifyContent: 'center', maxHeight: '100%' }}>
                                <MDBFormInline>
                                   
                                    <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center', }}>
                                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>

                                            <textarea
                                                className="form-control"
                                                id="comment"
                                                name="comment"
                                                rows="4"
                                                maxLength="2000"
                                                onChange={(e) => {
                                                    if (e.target.value.trim() != '') {
                                                        this.setState({
                                                            commentValue: e.target.value,
                                                            commentValueErr: false
                                                        })
                                                    } else {
                                                        this.setState({
                                                            commentValue: '',
                                                        })
                                                    }
                                                }}
                                                value={this.state.commentValue}
                                                style={{
                                                    padding: '5px', direction: this.props.isArabic ? 'rtl' : 'ltr',
                                                    margin: 0, width: '100%'
                                                }}
                                                required
                                            />
                                            {
                                                this.state.commentValueErr ?
                                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </MDBCol>
                                    </MDBRow>

                                    {/* <MDBRow>
                                        {
                                            this.state.commentValueErr ?
                                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                                </div>
                                                :
                                                null
                                        }
                                    </MDBRow> */}
                                </MDBFormInline>
                            </MDBRow> : null}
                        <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                            {this.populateComments()}
                        </div>
                    </MDBCol>
                    {
                        !this.props.hideCloseBtn ?
                            <MDBRow style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', width: '100%', justifyContent: 'center' }}>
                                <MDBBtn size="md" style={{ maxHeight: '5vw' }} color="primary" onClick={() => {
                                    if (this.state.commentValue.trim() != '') {
                                        this.updateComment();
                                    } else {
                                        this.setState({
                                            commentValueErr: true
                                        })
                                    }

                                }}>{i18n[this.props.isArabic ? 'ar' : 'en'].postComment}</MDBBtn>
                                <MDBBtn size="md" style={{ maxHeight: '5vw' }} color="primary" onClick={() => this.props.toggle(false)}>{i18n[this.props.isArabic ? 'ar' : 'en'].update_status.Close}</MDBBtn>
                            </MDBRow>
                            :
                            null
                    }
                </LoadingOverlay>
            </MDBCol>
        );
    };
}