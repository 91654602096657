import React, { Component } from "react"
import {MDBModalBody,MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody ,
    MDBModal,MDBModalHeader, MDBModalFooter} from "mdbreact";

    import ExpansionPanel from '@material-ui/core/ExpansionPanel';
    import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
    import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
    import Typography from '@material-ui/core/Typography';
    import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import i18n from '../../i18n';
import Locali18n from './Locali18n';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';
import { uploadDocument } from '../../utils/Payload';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import CKEditorComponent from '../CKEditorComponent';
import DownloadComponent from "../DownloadComponent";
import PostionsFunctions from './PostionsFunctions';




export default class Positions extends PostionsFunctions{

  

    constructor(props)
    {
        super(props);
      
 
    }


  componentWillMount()
  {
    this.getpositionscategories(); 
    this.geteducationlevels();
    this.getpositionsTypes();
    this.getgrades();
   
  if(this.props.edit)
  {
    let obj={};

          obj.id=this.props.info.node.id;
        
    
          obj.lang = this.props.info.node.lang;
          obj.entityid = this.props.info.node.entityid;
          obj.userid = this.props.info.node.UserId;
    
    
    
          let payload = obj;
          let endpoint = 'GetPosition';
          let url = Config.getUrl(endpoint);
    
           ApiRequest.sendRequestForPost(url, payload, res => {
              
           if (res.Status) { 
               debugger;
            this.setState({
               
                parentId : res.data.id,
               nameen : res.data.nameen,
               namear :  res.data.namear,
               reportto :  res.data.reportto,
               categoryid :  res.data.categoryid,  
               educationLevel : res.data.qualifications,

               typeid : 1,
               unittype  : res.data.unittype,
               positiontypeid : res.data.positiontypeid,
               grade : res.data.grade,
               numberOfPositions : res.data.number,
               positiongoals : res.data.objectives,
               organizationalRelations : res.data.organizationalrelations,
               internalRelations : res.data.internalrelations,
               externalRelations : res.data.externalrelations,
               knowledgeAndLevel : res.data.knowledgeandlevel,
               experience : res.data.experience,
               adminrRoles: res.data.adminroles,
               organizationalRoles : res.data.organizationalroles,
               functionalRoles : res.data.functionalroles,
               dailyhours:res.data.dailyhours,
                yearlyvacation : res.data.yearlyvacationdays,
                monthlydays : res.data.monthlydays,
                dmy : res.data.dmy,
                subgrade : res.data.subgrade

        
            }); 

            if(res.data.attachments.length>0)
            {
            this.setState({functionalLoadFileName: res.data.attachments[0].filename,
            functionalLoadFileref: res.data.attachments[0].aliasfilename
        });
           }
            
             if(res.data.attachments.length>1)
             this.setState({jobEvaluationFileName: res.data.attachments[1].filename,
                jobEvaluationFileref: res.data.attachments[1].aliasfilename
            });
             
          
          Config.setOrganizationStructurAdminRoles(res.data.adminroles);
          Config.setOrganizationStructurFunctionalRoles(res.data.functionalroles);
          Config.setOrganizationStructurOrganizationalRoles(res.data.organizationalroles);
    
    
          }
    
     
        });
    
  
   }

 
  }
  
  getPositionInfo=(positionid)=>{
    debugger;
        
          let obj={};
          obj.id=positionid;
        
    
          obj.lang = this.props.info.node.lang;
          obj.entityid = this.props.info.node.entityid;
          obj.userid = this.props.info.node.UserId;
    
    
    
          let payload = obj;
          let endpoint = 'GetPosition';
          let url = Config.getUrl(endpoint);
    
           ApiRequest.sendRequestForPost(url, payload, res => {
                
           if (res.Status) {
            this.setState({
                parentId : res.data.id,
               nameen : res.data.nameen,
               namear :  res.data.namear,
               typeid : 1,
               unittype  : res.data.unittype,
               positiontypeid : res.data.positiontypeid,
               grade : res.data.grade,
               numberOfPositions : res.data.number,
               positiongoals : res.data.objectives,
               organizationalRelations : res.data.organizationalrelations,
               internalRelations : res.data.internalrelations,
               externalRelations : res.data.externalrelations,
               knowledgeAndLevel : res.data.knowledgeandlevel,
               educationLevel : res.data.qualifications,
               experience : res.data.experience,
               adminrRoles: res.data.adminroles,
               organizationalRoles : res.data.organizationalroles,
               functionalRoles : res.data.functionalroles,
               reportto :  res.data.reportto,
               categoryid :  res.data.categoryid,
        
                
              functionalLoadFileName: res.data.attachments[0].filename,
               jobEvaluationFileName: res.data.attachments[1].filename,
        
               functionalLoadFileref: res.data.attachments[0].aliasfilename,
               jobEvaluationFileref: res.data.attachments[1].aliasfilename
              
        
            }); 
          
          Config.setOrganizationStructurAdminRoles(res.data.adminroles);
          Config.setOrganizationStructurFunctionalRoles(res.data.functionalroles);
          Config.setOrganizationStructurOrganizationalRoles(res.data.organizationalroles);
    
    
          }
    
     
        });
    
      };
    
    render() 
    {
        

            return(

                <form  className="needs-validation formWeight formSpace"
                 // onSubmit={this.addPosition} 
                   noValidate>

            <MDBContainer >

            <MDBModalHeader>
            {  ( this.props.edit === 1)? 
              Locali18n[this.getLocale()].editPosition
             : Locali18n[this.getLocale()].addPosition }</MDBModalHeader>
              
            <MDBModalBody  style={{overflow: 'auto'}}>
     
          <div >
            <ExpansionPanel className='tablerow'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
         
          <Typography >
          <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ?
           'row-reverse' : 'row', fontWeight: '400' }}>
          
          {Locali18n[this.getLocale()].goalsandrelations}
           </label>
             </Typography>
         
         
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <Typography>

           <MDBRow style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()?
                             'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {Locali18n[this.getLocale()].positionGoals}
                            <span className="requireStar">*</span></label>
                            <div style={{ border: this.state.positionGoalsErr === 'thisFieldIsRequired' ? 
                            '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.isArabic}
                                    content={this.state.positiongoals}
                                    onChange={(evt)=>this.handlePositionGoals(evt)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row",
                             color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.positionGoalsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()?
                                     'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
               
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}
                             htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].organizationalRelations}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.organizationalRelationsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.isArabic}
                                    content={this.state.organizationalRelations}
                                    onChange={(evt)=>this.handleOrganizationalRelations(evt)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.organizationalRelationsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}
                             htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].internalRelations}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.internalRelationsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.isArabic}
                                    content={this.state.internalRelations}
                                    onChange={(evt)=>this.handleInternalRelations(evt)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.internalRelationsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }} 
                            htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].externalRelations}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.externalRelationsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.isArabic}
                                    content={this.state.externalRelations}
                                    onChange={(evt)=>this.handlExternalRelations(evt)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.externalRelationsErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
             </MDBRow>
                </Typography>
            </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel  className='tablerow'>
           <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
         
          <Typography >
          <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ?
           'row-reverse' : 'row', fontWeight: '500'}} >
          
          {Locali18n[this.getLocale()].educationandexperience}
           </label>
            </Typography>
       
       
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <Typography>

            <MDBRow style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>
                   
                  
                     <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}
                             htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].knowledgeAndLevel}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.knowledgeAndLevelErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                            <CKEditorComponent
                                    isArabic={this.isArabic}
                                    content={this.state.knowledgeAndLevel}
                                    onChange={(evt)=>this.handleKnowledgeAndLevel(evt)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.knowledgeAndLevelErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                 <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()?
                             'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {Locali18n[this.getLocale()].experience}
                            <span className="requireStar">*</span></label>
                            <div style={{ border: this.state.experienceErr === 'thisFieldIsRequired' ? 
                            '1px solid red' : '' }} >
                                <CKEditorComponent
                                    isArabic={this.isArabic}
                                    content={this.state.experience}
                                    onChange={(evt)=>this.handleExperience(evt)}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                            {
                                this.state.experienceErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                </MDBRow>
           
                </Typography>
            </ExpansionPanelDetails>
            </ExpansionPanel>


            <MDBRow style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>  
                      
                      <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                     <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row', width: '100%' }}>
                                  {Locali18n[this.getLocale()].functionalLoadFileName}
                                  <span className="requireStar" style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row', }}>*</span>
                              </label>
                     <div className="form-group">
                              
                              <div className="input-group">
                                  <div className="custom-file-inp">
                                      <input
                                          id="FunctionalLoadFileId"
                                          type="file"
                                          name="FunctionalLoadFile"
                                          onChange={(event) => this.FunctionalLoadFileOnChange(event)}
                                          required
                                         // accept=".pdf,.doc,.docx"
                                      />
                                      <label htmlFor="FunctionalLoadFileId" className={this.getLocale()? 
                                          "chooseFileLabel_ar" : "chooseFileLabel"}>
                                          {Locali18n[this.getLocale()].chooseFile}
                                      </label>
      
                                      <label style={{ display: 'flex', flexDirection: this.getLocale()? 
                                      'row-reverse' : 'row' }} 
                                      htmlFor="FunctionalLoadFileId" className="fileName">
                                          <span>{this.state.functionalLoadFileName}</span></label>
                                  </div>
                                  {
                                      this.state.FunctionalLoadFileErr === 'thisFieldIsRequired' ?
                                          <div className="textRed" style={{ textAlign: this.getLocale()? 
                                          'right' : 'left', width: '100%' }}>
                                              {Locali18n[this.getLocale()].thisFieldIsRequired}
                                          </div>
                                          :
                                          null
                                  }
                                  {
                                      this.state.FunctionalLoadFileErr === 'invalidFileType' ?
                                          <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                              {Locali18n[this.getLocale()].invalidFileType}
                                          </div>
                                          :
                                          null
                                  }
                                  {
                                      this.state.FunctionalLoadFileErr === 'invalidFileSize' ?
                                          <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>
                                              {Locali18n[this.getLocale()].invalidFileSize}
                                          </div>
                                          :
                                          null
                                  }
      
                                  <p style={{ display: "flex", direction: this.getLocale()? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                      {Locali18n[this.getLocale()].acceptedFormatsAll}
                                  </p>
                                  {
                                     
                                      ( this.state.functionalLoadFileObjArray &&
                                          this.state.functionalLoadFileObjArray.length > 0)
                                          ? <DownloadComponent filesObj={this.state.functionalLoadFileObjArray} />
                                          : <span></span>
                                  }
                              </div>
                              </div>
                              </MDBCol>
                      <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                      <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row', width: '100%' }}>
                                  {Locali18n[this.getLocale()].jobEvaluationFileName}
                                  <span className="requireStar" style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row', }}>*</span>
                              </label>
                              <div className="form-group">
                            
                              <div className="input-group">
                                  <div className="custom-file-inp">
                                      <input
                                          id="JobEvaluationFileId"
                                          type="file"
                                          name="JobEvaluationFile"
                                          onChange={(event) => this.JobEvaluationFileOnChange(event)}
                                          required
                                         // accept=".pdf,.doc,.docx"
                                      />
                                      <label htmlFor="JobEvaluationFileId" className={this.getLocale()? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                          {Locali18n[this.getLocale()].chooseFile}
                                      </label>
                                      <label style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }} 
                                      htmlFor="JobEvaluationFileId" className="fileName"><span>{this.state.jobEvaluationFileName}</span></label>
                                  </div>
                                  {
                                      this.state.JobEvaluationFileErr === 'thisFieldIsRequired' ?
                                          <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                              {Locali18n[this.getLocale()].thisFieldIsRequired}
                                          </div>
                                          :
                                          null
                                  }
                                  {
                                      this.state.JobEvaluationFileErr === 'invalidFileType' ?
                                          <div className="textRed" style={{ textAlign: this.getLocale()? 'right' : 'left', width: '100%' }}>
                                              {Locali18n[this.getLocale()].invalidFileType}
                                          </div>
                                          :
                                          null
                                  }
                                  {
                                      this.state.JobEvaluationFileErr === 'invalidFileSize' ?
                                          <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>
                                              {Locali18n[this.getLocale()].invalidFileSize}
                                          </div>
                                          :
                                          null
                                  }
      
                                  <p style={{ display: "flex", direction: this.getLocale()? "ltr" : "rtl", width: '100%', color: '#000' }}>
                                      {Locali18n[this.getLocale()].acceptedFormatsAll}
                                  </p>
                                  {
                                      (this.state.jobEvaluationFileObjArray &&
                                          this.state.jobEvaluationFileObjArray.length > 0)
                                          ? <DownloadComponent filesObj={this.state.jobEvaluationFileObjArray} />
                                          : <span></span>
                                  }
                              </div>
                       
                              </div>
                              </MDBCol>
                   
                     
                      </MDBRow>
      
            </div >
         
              </MDBModalBody>
 
               <MDBModalFooter>
            
                <MDBRow style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row" }}>
                
                   
                    {
                       
                            <MDBBtn  type='button' id='1' color="primary" type="submit"
                             onClick={(event)=>this.addPosition(event)}>
                                {Locali18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                   {
                      this.state.positiondetails===false ?  null : 
                    <MDBBtn className="submitBtn" id='0' type="submit" color="primary"
                     onClick={(event)=>this.addPosition(event)}>
                        {Locali18n[this.getLocale()].submit}
                    </MDBBtn>
                   }

                    <MDBBtn   color="primary" onClick={this.props.hide}>
                        {Locali18n[this.getLocale()].close}
                    </MDBBtn>
                    
                </MDBRow>
                </MDBModalFooter>
             </MDBContainer>
                </form>
)};
}
