import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Footer from '../components/Footer';
import "react-datepicker/dist/react-datepicker.css";
import Header from '../components/Header';
import SideBar from '../components/SideBar';
import i18n from '../i18n';
import './../css/style.css';
import SubHeaderStrip from '../components/SubHeaderStrip';
import StepOne from '../components/ExceptionOfSteerTheAgeOfRetirementRequestSteps/StepOne';
import StepTwo from '../components/ExceptionOfSteerTheAgeOfRetirementRequestSteps/StepTwo';
import Config from '../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { saveExceptionRetirement, uploadDocument } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
let moment = require('moment');

export default class ExceptionOfSteerTheAgeOfRetirementRequest extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            isEdit: false,
            requestId: '',
            statusid: '',
            formType: "",
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isNextScreenActive: 1,
            employeeName: '',
            employeeNumber: '',
            nationality: '',
            dateOfBirth: null,
            jobTitle: '',
            unifiedNumber: '',
            fromDate: null,
            toDate: null,
            copyButton: '',
            reasonForExtendingTheService: '',
            // annualPerformanceEvaluation: '',
            emaritesID: '',
            emiratesIDStepOne: '',
            nationalOrNot: '',
            age: '',
            jobContract: '',
            medicalApprovalsFromTheCometentMedicalCommittee: '',
            medicalApprovalsFromTheCometentMedicalCommitteeShortName: '',
            passport: '',
            familyBook: '',
            annualPerformanceEvaluationattach: '',
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: '',
            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: '',
            employeeCV: '',
            personalPhoto: '',
            replacePlansForNonLocalsAttachementFileName: "",
            replacePlansForNonLocalsAttachmentFileObj: null,
            nationalityLabel: "",
            HiringDate: '',
            CurrentGrade: '',
            Efficiency: '',
            extendedservice: '',
            Salary: '',
            Qualification: '',
            GraduationYear: '',
            DateofretirementAge: '',
            TypeofContract: '',
            prviousextensionwithoutapprovalfrom: '',
            prviousextensionwithoutapprovalto: '',
            Previousextionsfrom: '',
            Previousextionsto: '',
            Replacementplanfrom: '',
            Replacementplanto: '',

            
            
            Summaryofregistrationforcitizens: '',
            PoistioninStructure: '',
            Previousapprovalsfortheextension: '',
            Qualifications: '',
            Jobdescription: '',
            Careersuccessionplan: '',
            HRCom: '',
            OtherAttach: ''
        };
    }

    componentWillMount = () => {

        let previewFlag = Config.getIsPreviewFlag();
        let previewObj = Config.getPreviewObj();
        let previewckEditorArr = Config.getPreviewckEditorArray();
        let previewArray = Config.getPreviewAttachmentArray();

        let reasonForExtendingTheService = '',
            copyButton = '',
            jobContract = "",
            medicalApprovalsFromTheCometentMedicalCommittee = "",
            passport = "",
            emaritesID = "",
            personalPhoto = "",
            annualPerformanceEvaluationattach = "",
            employeeCV = "",
            acopyofthepreviousapprovalsforextensionfromthejurisdiction = "",
            copyOfApprovalsForExtensionFromJurisd = "",
            replacePlansForNonLocalsAttachementFileName = "",
            Summaryofregistrationforcitizens="",
            PoistioninStructure="",
            Previousapprovalsfortheextension="",
            Qualifications="",
            Jobdescription="",
            Careersuccessionplan="",
            HRCom="",
            OtherAttach="";

        if (previewFlag) {

            if (previewckEditorArr) {
                if (previewckEditorArr.length > 0) {
                    for (let j = 0; j < previewckEditorArr.length; j++) {
                        if (previewckEditorArr[j].label === 'copyButton') {
                            copyButton = previewckEditorArr[j].value;
                        }
                        if (previewckEditorArr[j].label === 'reasonForExtendingTheService') {
                            reasonForExtendingTheService = previewckEditorArr[j].value;
                        }
                    }
                }
            }

            //For Attachments
            if (previewArray.length) {
                for (let index = 0; index < previewArray.length; index++) {
                    if (previewArray[index].label === 'jobContract') {
                        jobContract = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'medicalApprovalsFromTheCometentMedicalCommittee') {
                        medicalApprovalsFromTheCometentMedicalCommittee = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'passport') {
                        passport = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'emaritesID') {
                        emaritesID = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'personalPhoto') {
                        personalPhoto = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'annualPerformanceEvaluationattach') {
                        annualPerformanceEvaluationattach = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'employeeCV') {
                        employeeCV = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'acopyofthepreviousapprovalsforextensionfromthejurisdiction') {
                        acopyofthepreviousapprovalsforextensionfromthejurisdiction = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'replacePlansForNonLocalsAttachementFileName') {
                        replacePlansForNonLocalsAttachementFileName = previewArray[index].fileName;
                    }
                    
                    else if (previewArray[index].label === 'Summaryofregistrationforcitizens') {
                        Summaryofregistrationforcitizens = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'PoistioninStructure') {
                        PoistioninStructure = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'Previousapprovalsfortheextension') {
                        Previousapprovalsfortheextension = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'Qualifications') {
                        Qualifications = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'Jobdescription') {
                        Jobdescription = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'Careersuccessionplan') {
                        Careersuccessionplan = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'HRCom') {
                        HRCom = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label === 'OtherAttach') {
                        OtherAttach = previewArray[index].fileName;
                    }
                }

            }

            let copyButtonValueFromPreview = ""
            if (copyButton) {
                if (copyButton === i18n["ar"].yes || copyButton === i18n["en"].yes) {
                    copyButtonValueFromPreview = "Yes";
                }
                else if (copyButton === i18n["ar"].no || copyButton === i18n["en"].no) {
                    copyButtonValueFromPreview = "No";
                }
            }

            this.setState({
                isEdit: previewObj.isEdit,
                requestId: previewObj.requestId,
                employeeName: previewObj.employeeName,
                employeeNumber: previewObj.employeeNumber,
                nationality: previewObj.nationality,
                dateOfBirth: previewObj.dateOfBirth ? new Date(previewObj.dateOfBirth) : null,
                jobTitle: previewObj.jobTitle,
                unifiedNumber: previewObj.unifiedNumber,
                fromDate: previewObj.fromDate ? new Date(previewObj.fromDate) : null,
                toDate: previewObj.toDate ? new Date(previewObj.toDate) : null,
                copyButton: copyButtonValueFromPreview,
                //annualPerformanceEvaluation: this.state.annualPerformanceEvaluation,
                emiratesIDStepOne: previewObj.emiratesIDStepOne,
                nationalOrNot: previewObj.nationalOrNot,
                nationalityLabel: previewObj.nationalityLabel,
                age: previewObj.age,
                HiringDate: previewObj.HiringDate,
                CurrentGrade: previewObj.CurrentGrade,
                Efficiency: previewObj.Efficiency,
                extendedservice: previewObj.extendedservice,
                Salary: previewObj.Salary,
                Qualification: previewObj.Qualification,
                GraduationYear: previewObj.GraduationYear,
                DateofretirementAge: previewObj.DateofretirementAge,
                TypeofContract: previewObj.TypeofContract,
                prviousextensionwithoutapprovalfrom: previewObj.prviousextensionwithoutapprovalfrom,
                prviousextensionwithoutapprovalto: previewObj.prviousextensionwithoutapprovalto,
                Previousextionsfrom: previewObj.Previousextionsfrom,
                Previousextionsto: previewObj.Previousextionsto,
                Replacementplanfrom: previewObj.Replacementplanfrom,
                Replacementplanto: previewObj.Replacementplanto,
                reasonForExtendingTheService: reasonForExtendingTheService,
                jobContract: jobContract,
                medicalApprovalsFromTheCometentMedicalCommittee: medicalApprovalsFromTheCometentMedicalCommittee,
                medicalApprovalsFromTheCometentMedicalCommitteeShortName: previewObj.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
                passport: passport,
                emaritesID: emaritesID,
                personalPhoto: personalPhoto,
                annualPerformanceEvaluationattach: annualPerformanceEvaluationattach,
                employeeCV: employeeCV,
                acopyofthepreviousapprovalsforextensionfromthejurisdiction: acopyofthepreviousapprovalsforextensionfromthejurisdiction,
                acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: previewObj.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
                copyOfApprovalsForExtensionFromJurisd: copyOfApprovalsForExtensionFromJurisd,
                replacePlansForNonLocalsAttachementFileName: replacePlansForNonLocalsAttachementFileName,
                Summaryofregistrationforcitizens:Summaryofregistrationforcitizens,
                PoistioninStructure:PoistioninStructure,
            Previousapprovalsfortheextension:Previousapprovalsfortheextension,
            Qualifications:Qualifications,
            Jobdescription:Jobdescription,
            Careersuccessionplan:Careersuccessionplan,
            HRCom:HRCom,
            OtherAttach:OtherAttach
            }, () => {
            
                
            });
        }
    }

    componentDidMount = () => {

        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton' name={i18n[this.getLocale()].ok} />,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    stepTwoDataPrev = (obj) => {
        
        debugger;
        this.setState({
            step1: true,
            step2: false,
            jobContract: obj.jobContract,
            medicalApprovalsFromTheCometentMedicalCommittee: obj.medicalApprovalsFromTheCometentMedicalCommittee,
            medicalApprovalsFromTheCometentMedicalCommitteeShortName: obj.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
            passport: obj.passport,
            // familyBook: obj.familyBook,
            replacePlansForNonLocalsAttachmentFileObj: obj.replacePlansForNonLocalsAttachmentFileObj,
            replacePlansForNonLocalsAttachementFileName: obj.replacePlansForNonLocalsAttachementFileName,
            emaritesID: obj.emaritesID,
            personalPhoto: obj.personalPhoto,
            annualPerformanceEvaluationattach: obj.annualPerformanceEvaluationattach,
            employeeCV: obj.employeeCV,
            acopyofthepreviousapprovalsforextensionfromthejurisdiction: obj.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: obj.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
            Summaryofregistrationforcitizens: obj.Summaryofregistrationforcitizens,
            PoistioninStructure:obj.PoistioninStructure,
            Previousapprovalsfortheextension: obj.Previousapprovalsfortheextension,
            Qualifications: obj.Qualifications,
            Jobdescription: obj.Jobdescription,
            Careersuccessionplan: obj.Careersuccessionplan,
            HRCom: obj.HRCom,
            OtherAttach: obj.OtherAttach,

        }, () => {
     
        });
    }

    getPrev = {
        stepTwoDataPrev: this.stepTwoDataPrev,
    };

    stepOneData = (obj) => {
        debugger;
        let editObj = Config.getEditObj();

        if (editObj) {
            let setConfigObj = editObj;
            //step 1
            setConfigObj.age = obj.age;
            setConfigObj.subject = obj.employeeName;
            setConfigObj.employeenumber = obj.employeeNumber;
            setConfigObj.nationalityid = obj.nationality;
            setConfigObj.dob = obj.dateOfBirth;
            setConfigObj.jobtitle = obj.jobTitle;
            setConfigObj.unifiednumber = obj.unifiedNumber;
            setConfigObj.steerfrom = obj.fromDate;
            setConfigObj.steerto = obj.toDate;
            setConfigObj.isjurisdiction = obj.copyButton === "Yes" ? 1 : 0;
            setConfigObj.reasonforextending = obj.reasonForExtendingTheService;
            setConfigObj.emiratesid = obj.emiratesIDStepOne;

            setConfigObj.HiringDate = obj.HiringDate;
            setConfigObj.CurrentGrade = obj.CurrentGrade;
            setConfigObj.Efficiency = obj.Efficiency;
            setConfigObj.extendedservice = obj.extendedservice;
            setConfigObj.Salary = obj.Salary;
            setConfigObj.Qualification = obj.Qualification;
            setConfigObj.GraduationYear = obj.GraduationYear;
            setConfigObj.DateofretirementAge = obj.DateofretirementAge;
            setConfigObj.TypeofContract = obj.TypeofContract;
            setConfigObj.prviousextensionwithoutapprovalfrom = obj.prviousextensionwithoutapprovalfrom;
            setConfigObj.prviousextensionwithoutapprovalto = obj.prviousextensionwithoutapprovalto;
            setConfigObj.Previousextionsfrom = obj.Previousextionsfrom;
            setConfigObj.Previousextionsto = obj.Previousextionsto;
            setConfigObj.Replacementplanfrom = obj.Replacementplanfrom;
            setConfigObj.Replacementplanto = obj.Replacementplanto;

            Config.setEditObj(setConfigObj);
        }
        debugger;
        this.setState({
            step1: false, step2: true,
            employeeName: obj.employeeName,
            employeeNumber: obj.employeeNumber,
            nationality: obj.nationality,
            dateOfBirth: obj.dateOfBirth,
            jobTitle: obj.jobTitle,
            unifiedNumber: obj.unifiedNumber,
            fromDate: obj.fromDate,
            emiratesIDStepOne: obj.emiratesIDStepOne,
            toDate: obj.toDate,
            copyButton: obj.copyButton,
            reasonForExtendingTheService: obj.reasonForExtendingTheService,
            nationalOrNot: obj.nationalOrNot,
            age: obj.age,
            nationalityLabel: obj.nationalityLabel,
            formType: obj.formType,
            HiringDate: obj.HiringDate,
            CurrentGrade: obj.CurrentGrade,
            Efficiency: obj.Efficiency,
            extendedservice: obj.extendedservice,
            Salary: obj.Salary,
            Qualification: obj.Qualification,
            GraduationYear: obj.GraduationYear,
            DateofretirementAge: obj.DateofretirementAge,
            TypeofContract: obj.TypeofContract,
            prviousextensionwithoutapprovalfrom: obj.prviousextensionwithoutapprovalfrom,
            prviousextensionwithoutapprovalto: obj.prviousextensionwithoutapprovalto,
            Previousextionsfrom: obj.Previousextionsfrom,
            Previousextionsto: obj.Previousextionsto,
            Replacementplanfrom: obj.Replacementplanfrom,
            Replacementplanto: obj.Replacementplanto
            // annualPerformanceEvaluation: obj.annualPerformanceEvaluation,
        });
    }

    submitApiCall = () => {
        debugger;
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let requestObj = {};
                if (this.state.formType === "saveAsDraft") {
                    requestObj.draft = 1;
                } else {
                    requestObj.draft = 0;
                }
                requestObj.employeeName = this.state.employeeName;
                requestObj.employeeNumber = this.state.employeeNumber;
                requestObj.nationality = this.state.nationality;
                requestObj.jobTitle = this.state.jobTitle;
                requestObj.unifiedNumber = this.state.unifiedNumber;
                requestObj.emiratesid = this.state.emiratesIDStepOne;
                requestObj.copyButton = this.state.copyButton === "Yes" ? 1 : 0;
                requestObj.reasonForExtendingTheService = this.state.reasonForExtendingTheService;
                requestObj.nationalOrNot = this.state.nationalOrNot;
                requestObj.age = this.state.age;
                requestObj.HiringDate = this.state.HiringDate;
                requestObj.CurrentGrade = this.state.CurrentGrade;
                requestObj.Efficiency = this.state.Efficiency;
                requestObj.extendedservice = this.state.extendedservice;
                requestObj.Salary = this.state.Salary;
                requestObj.Qualification = this.state.Qualification;
                requestObj.GraduationYear = this.state.GraduationYear;
                requestObj.DateofretirementAge = this.state.DateofretirementAge;
                requestObj.TypeofContract = this.state.TypeofContract;
                requestObj.prviousextensionwithoutapprovalfrom = this.state.prviousextensionwithoutapprovalfrom;
                requestObj.prviousextensionwithoutapprovalto = this.state.prviousextensionwithoutapprovalto;
                requestObj.Previousextionsfrom = this.state.Previousextionsfrom;
                requestObj.Previousextionsto = this.state.Previousextionsto;
                requestObj.Replacementplanfrom = this.state.Replacementplanfrom;
                requestObj.Replacementplanto = this.state.Replacementplanto
                // requestObj.annualPerformanceEvaluation = this.state.annualPerformanceEvaluation;

                let tempDate1 = '';
                if (this.state.dateOfBirth !== '' && this.state.dateOfBirth !== null) {
                    tempDate1 = new Date(this.state.dateOfBirth);
                    let day1 = tempDate1.getDate();
                    let month1 = tempDate1.getMonth() + 1;
                    let year1 = tempDate1.getFullYear();
                    requestObj.dateOfBirth = year1 + '-' + month1 + '-' + day1;
                } else {
                    requestObj.dateOfBirth = tempDate1;
                }

                let tempDate2 = '';
                if (this.state.fromDate !== '' && this.state.fromDate !== null) {
                    tempDate2 = new Date(this.state.fromDate);
                    let day2 = tempDate2.getDate();
                    let month2 = tempDate2.getMonth() + 1;
                    let year2 = tempDate2.getFullYear();
                    requestObj.fromDate = year2 + '-' + month2 + '-' + day2;
                } else {
                    requestObj.fromDate = tempDate2;
                }

                let tempDate3 = '';
                if (this.state.toDate !== '' && this.state.toDate !== null) {
                    let tempDate3 = new Date(this.state.toDate);
                    let day3 = tempDate3.getDate();
                    let month3 = tempDate3.getMonth() + 1;
                    let year3 = tempDate3.getFullYear();
                    requestObj.toDate = year3 + '-' + month3 + '-' + day3;
                } else {
                    requestObj.toDate = tempDate3;
                }

                requestObj.isEdit = this.state.isEdit;
                requestObj.requestId = this.state.requestId;

                let payload = saveExceptionRetirement(requestObj);
                let endPoint = 'SaveRetirement';
                let url = Config.getUrl(endPoint);

                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resRetirement => {
                        if (resRetirement.Status) {
                            let totalCount = 17;
                            this.uploadFile(0, totalCount, resRetirement, 0);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            });
                        }
                    })
                });
            })
        }
    }

    stepTwoData = (obj) => {
        //alert('debug');
        debugger;
        if (obj.saveFrom === "Step1") {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                employeeName: obj.employeeName,
                employeeNumber: obj.employeeNumber,
                nationality: obj.nationality,
                dateOfBirth: obj.dateOfBirth,
                jobTitle: obj.jobTitle,
                unifiedNumber: obj.unifiedNumber,
                fromDate: obj.fromDate,
                emiratesIDStepOne: obj.emiratesIDStepOne,
                toDate: obj.toDate,
                copyButton: obj.copyButton,
                reasonForExtendingTheService: obj.reasonForExtendingTheService,
                nationalOrNot: obj.nationalOrNot,
                age: obj.age,
                nationalityLabel: obj.nationalityLabel,
                HiringDate: obj.HiringDate,
                CurrentGrade: obj.CurrentGrade,
                Efficiency: obj.Efficiency,
                extendedservice: obj.extendedservice,
                Salary: obj.Salary,
                Qualification: obj.Qualification,
                GraduationYear: obj.GraduationYear,
                DateofretirementAge: obj.DateofretirementAge,
                TypeofContract: obj.TypeofContract,
                prviousextensionwithoutapprovalfrom: obj.prviousextensionwithoutapprovalfrom,
                prviousextensionwithoutapprovalto: obj.prviousextensionwithoutapprovalto,
                Previousextionsfrom: obj.Previousextionsfrom,
                Previousextionsto: obj.Previousextionsto,
                Replacementplanfrom: obj.Replacementplanfrom,
                Replacementplanto: obj.Replacementplanto
                //  formType: obj.formType
                // annualPerformanceEvaluation: obj.annualPerformanceEvaluation,
            }, () => {
                this.submitApiCall();
            });
        }
        else {
            this.setState({
                formType: obj.formType,
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                jobContract: obj.jobContract,
                medicalApprovalsFromTheCometentMedicalCommittee: obj.medicalApprovalsFromTheCometentMedicalCommittee,
                medicalApprovalsFromTheCometentMedicalCommitteeShortName: obj.medicalApprovalsFromTheCometentMedicalCommitteeShortName,
                passport: obj.passport,
                familyBook: obj.familyBook,
                emaritesID: obj.emaritesID,
                personalPhoto: obj.personalPhoto,
                annualPerformanceEvaluationattach: obj.annualPerformanceEvaluationattach,
                employeeCV: obj.employeeCV,
                replacePlansForNonLocalsAttachementFileName: obj.replacePlansForNonLocalsAttachementFileName,
                acopyofthepreviousapprovalsforextensionfromthejurisdiction: obj.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
                acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: obj.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
                copyOfApprovalsForExtensionFromJurisd: obj.copyOfApprovalsForExtensionFromJurisd,
                Summaryofregistrationforcitizens:obj.Summaryofregistrationforcitizens,
                PoistioninStructure:obj.PoistioninStructure,
                Previousapprovalsfortheextension: obj.Previousapprovalsfortheextension,
            Qualifications:obj.Qualifications,
            Jobdescription:obj.Jobdescription,
            Careersuccessionplan:obj.Careersuccessionplan,
            HRCom:obj.HRCom,
            OtherAttach:obj.OtherAttach
            }, () => {
               
            
                this.submitApiCall();
            });
        }

    }

    uploadFile = (i, totalCount, resRetirement, multifileCount) => {
        debugger;
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};
            let isLastFileInMultiUpload = false;

            if (i === 0) {
                fileObj = Config.getBase64ForJobContract();
                obj.filename = this.state.jobContract;
                obj.filetype = Config.getRetirmentJobContractId();
                tempBase64 = fileObj;
            }
            else if (i === 1) {
                fileObj = Config.getbase64ForPassport();
                obj.filename = this.state.passport;
                obj.filetype = Config.getRetirmentCopyOfPassportId();
                tempBase64 = fileObj;
            }
            else if (i === 2) {
                fileObj = Config.getbase64ForPersonalPhoto();
                obj.filename = this.state.personalPhoto;
                obj.filetype = Config.getRetirmenPersonalPhotoId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                fileObj = Config.getbase64ForEmaritesID();
                obj.filename = this.state.emaritesID;
                obj.filetype = Config.getRetirmenEmiratesId();
                tempBase64 = fileObj;
            }
            else if (i === 4) {
                fileObj = Config.getbase64ForAnnualPerformanceEvaluationattach();
                obj.filename = this.state.annualPerformanceEvaluationattach;
                obj.filetype = Config.getRetirmenAnnualPerformanceEvaluationId();
                tempBase64 = fileObj;
            } else if (i === 5) {
                let fileObjArray = Config.getbase64MedicalApprovalsFromTheCometentMedicalCommittee();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }
                if (!this.state.medicalApprovalsFromTheCometentMedicalCommittee) {
                    let filenamesArray = this.state.medicalApprovalsFromTheCometentMedicalCommittee.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                else
                {
                    obj.filename=this.state.medicalApprovalsFromTheCometentMedicalCommittee;
                }
                obj.filetype = Config.getRetirmentMedicalApprovalId();
                tempBase64 = fileObj;

            
            } else if (i === 6) {
                fileObj = Config.getbase64ForEmployeeCV();
                obj.filename = this.state.employeeCV;
                obj.filetype = Config.getRetirmenEmployeeCvId();
                tempBase64 = fileObj;
            } else if (i === 7) {
                let fileObjCopyOfPreviudApprovalArray = Config.getbase64ForAcopyofthepreviousapprovals();
                if (fileObjCopyOfPreviudApprovalArray !== undefined && fileObjCopyOfPreviudApprovalArray.length > 0) {
                    if (multifileCount === fileObjCopyOfPreviudApprovalArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjCopyOfPreviudApprovalArray[multifileCount];
                }
                if (!this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction) {
                    let fileCopyOfPreviousNamesArray = this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction.split(",");
                    obj.filename = fileCopyOfPreviousNamesArray[multifileCount];
                }
                obj.filetype = Config.getRetirmenCopyOfPreviousApprovalId();
                tempBase64 = fileObj;
            }
            else if (i === 8) {
                fileObj = Config.getBase64ForExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName();
                obj.filename = this.state.replacePlansForNonLocalsAttachementFileName;
                obj.filetype = Config.getReplacePlansForNonLocalsId();
                tempBase64 = fileObj;
            }
            else if(i===9)
            {
              
                fileObj = Config.getBase64ForSummaryofregistrationforcitizens();
                obj.filename = this.state.Summaryofregistrationforcitizens;
                obj.filetype = Config.getRETSummaryofregistrationforcitizensId();
                tempBase64 = fileObj;

            }
            else if(i===10)
            {
                debugger;
                fileObj = Config.getBase64ForPreviousapprovalsfortheextension();
                obj.filename = this.state.Previousapprovalsfortheextension;
                obj.filetype = Config.getRETPreviousapprovalsfortheextensionId();
                tempBase64 = fileObj;
           

            }
            else if(i===11)
            {
                debugger;
                fileObj = Config.getBase64ForQualifications();
                obj.filename = this.state.Qualifications;
                obj.filetype = Config.getRETQualificationsId();
                tempBase64 = fileObj;

            }
            else if(i===12)
            {
                fileObj = Config.getBase64ForJobdescription();
                obj.filename = this.state.Jobdescription;
                obj.filetype = Config.getRETJobdescriptionId();
                tempBase64 = fileObj;

            }
            else if(i===13)
            {
                fileObj = Config.getBase64ForCareersuccessionplan();
                obj.filename = this.state.Careersuccessionplan;
                obj.filetype = Config.getRETCareersuccessionplanId();
                tempBase64 = fileObj;

            }
            else if(i===14)
            {
               
               
                fileObj = Config.getBase64ForHRCom();
                obj.filename = this.state.HRCom;
                obj.filetype = Config.getRETHRComId();
                tempBase64 = fileObj;

            }
            else if(i===15)
            {
               
                fileObj = Config.getBase64ForOtherAttach();
                obj.filename = this.state.OtherAttach;
                obj.filetype = Config.getRETOtherAttachId();
               
                tempBase64 = fileObj;

            }
          
            else if(i===16)
            {
              
                fileObj = Config.getBase64ForPoistioninStructure();
                obj.filename = this.state.PoistioninStructure;
                obj.filetype = Config.getRETPoistioninStructureId();
                tempBase64 = fileObj;

            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resRetirement && resRetirement.data && resRetirement.data.id) {
                    reqId = resRetirement.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resRetirement.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                  
                    if (resForEntityFIleRequestFile.Status) {
                        if (i === 5) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, resRetirement, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, resRetirement, multifileCount + 1);
                            }
                        } else if (i == 7) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, resRetirement, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, resRetirement, multifileCount + 1);
                            }
                        } else {
                           
                            this.uploadFile(i + 1, totalCount, resRetirement, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {

                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        });
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resRetirement, 0);
            }
        }
        else {
          
            this.setState({
                isLoading: false
            }, () => {
                // this.props.history.replace("/listPage", {
                //     pathname: localStorage.getItem('currentPath')
                // });
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }

                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            });
        }
    }

    getData = {

        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
    };

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    handlePreViewNavigation = (obj) => {
        debugger;
        localStorage.setItem('currentPath', 'exceptionOfSteerTheAgeOfRetirementRequest');

        let previewObj = {};
        previewObj.employeeName = this.state.employeeName;
        previewObj.employeeNumber = this.state.employeeNumber;
        previewObj.nationality = this.state.nationality;
        previewObj.dateOfBirth = this.state.dateOfBirth ? moment(this.state.dateOfBirth).format('L').toString() : "";
        previewObj.jobTitle = this.state.jobTitle;
        previewObj.unifiedNumber = this.state.unifiedNumber;
        previewObj.fromDate = this.state.fromDate ? moment(this.state.fromDate).format('L').toString() : "";
        previewObj.emiratesIDStepOne = this.state.emiratesIDStepOne;
        previewObj.toDate = this.state.toDate ? moment(this.state.toDate).format('L').toString() : "";
        previewObj.copyButton = this.state.copyButton;
        previewObj.nationalityLabel = this.state.nationalityLabel;
        previewObj.nationalOrNot = this.state.nationalOrNot;
        previewObj.age = this.state.age;
        previewObj.HiringDate = this.state.HiringDate;
        previewObj.CurrentGrade = this.state.CurrentGrade;
        previewObj.Efficiency = this.state.Efficiency;
        previewObj.extendedservice = this.state.extendedservice;
        previewObj.Salary = this.state.Salary;
        previewObj.Qualification = this.state.Qualification;
        previewObj.GraduationYear = this.state.GraduationYear;
        previewObj.DateofretirementAge = this.state.DateofretirementAge;
        previewObj.TypeofContract = this.state.TypeofContract;
        previewObj.prviousextensionwithoutapprovalfrom = this.state.prviousextensionwithoutapprovalfrom;
        previewObj.prviousextensionwithoutapprovalto = this.state.prviousextensionwithoutapprovalto;
        previewObj.Previousextionsfrom = this.state.Previousextionsfrom;
        previewObj.Previousextionsto = this.state.Previousextionsto;
        previewObj.Replacementplanfrom = this.state.Replacementplanfrom;
        previewObj.Replacementplanto = this.state.Replacementplanto;
        previewObj.medicalApprovalsFromTheCometentMedicalCommitteeShortName = obj.medicalApprovalsFromTheCometentMedicalCommitteeShortName;
        previewObj.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName = obj.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName;

        let copyButton = '';
        if (this.state.copyButton === "Yes") {
            // previewObj.copyButton = i18n[this.state.isArabic ? "ar" : "en"].yes
            copyButton = i18n[this.state.isArabic ? "ar" : "en"].yes;
        } else {
            // previewObj.copyButton = i18n[this.state.isArabic ? "ar" : "en"].no
            copyButton = i18n[this.state.isArabic ? "ar" : "en"].no;
        }

        let previewAttachMentArray = [
            {
                "label": "jobContract",
                "fileName": obj.jobContract,
                "fileObj": Config.getBase64ForJobContract()
            },
            {
                "label": "medicalApprovalsFromTheCometentMedicalCommittee",
                "fileName": obj.medicalApprovalsFromTheCometentMedicalCommittee,
                "fileObj": Config.getbase64MedicalApprovalsFromTheCometentMedicalCommittee()
            },
            {
                "label": "passport",
                "fileName": obj.passport,
                "fileObj": Config.getbase64ForPassport()
            },
            {
                "label": "emaritesID",
                "fileName": obj.emaritesID,
                "fileObj": Config.getbase64ForEmaritesID()
            },
            {
                "label": "personalPhoto",
                "fileName": obj.personalPhoto,
                "fileObj": Config.getbase64ForPersonalPhoto()
            },
            {
                "label": "annualPerformanceEvaluationattach",
                "fileName": obj.annualPerformanceEvaluationattach,
                "fileObj": Config.getbase64ForAnnualPerformanceEvaluationattach()
            },
            {
                "label": "employeeCV",
                "fileName": obj.employeeCV,
                "fileObj": Config.getbase64ForEmployeeCV()
            },

            {
                "label": "replacePlansForNonLocalsAttachementFileName",
                "fileName": obj.replacePlansForNonLocalsAttachementFileName,
                "fileObj": Config.getBase64ForExceptionOfSteerAgeOfRetirment_replacePlansForNonLocals_FileName()
            },


            {
                "label": "Summaryofregistrationforcitizens",
                "fileName": obj.Summaryofregistrationforcitizens,
                "fileObj": Config.getBase64ForSummaryofregistrationforcitizens()
            },
            {
                "label": "PoistioninStructure",
                "fileName": obj.PoistioninStructure,
                "fileObj": Config.getBase64ForPoistioninStructure()
            },
            {
                "label": "Previousapprovalsfortheextension",
                "fileName": obj.Previousapprovalsfortheextension,
                "fileObj": Config.getBase64ForPreviousapprovalsfortheextension()
            },
            {
                "label": "Qualifications",
                "fileName": obj.Qualifications,
                "fileObj": Config.getBase64ForQualifications()
            },
            
            {
                "label": "Jobdescription",
                "fileName": obj.Jobdescription,
                "fileObj": Config.getBase64ForJobdescription()
            },
            {
                "label": "Careersuccessionplan",
                "fileName": obj.Careersuccessionplan,
                "fileObj": Config.getBase64ForCareersuccessionplan()
            },
            {
                
                "label": "replacePlansForHRCom",
                "fileName": obj.replacePlansForHRCom,
                "fileObj": Config.getBase64ForHRCom()
            },
            {
                "label": "OtherAttach",
                "fileName": obj.OtherAttach,
                "fileObj": Config.getBase64ForOtherAttach()
            }
        ];

        if (this.state.copyButton === "Yes") {
            previewAttachMentArray.push({
                "label": "acopyofthepreviousapprovalsforextensionfromthejurisdiction",
                "fileName": obj.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
                "fileObj": Config.getbase64ForAcopyofthepreviousapprovals()
            })
        }

        let ckEditorArr = [
            {
                label: 'copyButton',
                value: copyButton,
            },
            {
                label: 'reasonForExtendingTheService',
                value: this.state.reasonForExtendingTheService,
            },
        ];

        this.setState({
            pathName: 'exceptionOfSteerTheAgeOfRetirementRequest'
        }, () => {
            this.props.history.replace('/preview',
                { data: previewObj, attachmentArr: previewAttachMentArray, ckEditorArr: ckEditorArr });
        });
    }

    render() {

        const sideBarData = Config.getServiceSideBar('exceptionOfSteerTheAgeOfRetirementRequest', this.state.isArabic, this, 0);

        return (
            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'exceptionOfSteerTheAgeOfRetirementRequest' }) }} isArabic={this.state.isArabic} title={i18n[this.getLocale()].excSteer.exceptionOfSteerTheAgeOfRetirementRequest} />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '15px' }}> */}
                            {/* <MDBCardBody> */}
                            {
                                this.state.step1 ?
                                    <StepOne
                                        isArabic={this.state.isArabic}
                                        sendData={{
                                            employeeName: this.state.employeeName,
                                            employeeNumber: this.state.employeeNumber,
                                            nationality: this.state.nationality,
                                            dateOfBirth: this.state.dateOfBirth,
                                            jobTitle: this.state.jobTitle,
                                            emiratesIDStepOne: this.state.emiratesIDStepOne,
                                            unifiedNumber: this.state.unifiedNumber,
                                            fromDate: this.state.fromDate,
                                            toDate: this.state.toDate,
                                            copyButton: this.state.copyButton,
                                            reasonForExtendingTheService: this.state.reasonForExtendingTheService,
                                            annualPerformanceEvaluation: this.state.annualPerformanceEvaluation,
                                            nationalOrNot: this.state.nationalOrNot,
                                            age: this.state.age,
                                            nationalityLabel: this.state.nationalityLabel,
                                            HiringDate: this.state.HiringDate,
                                            CurrentGrade: this.state.CurrentGrade,
                                            Efficiency: this.state.Efficiency,
                                            extendedservice: this.state.extendedservice,
                                            Salary: this.state.Salary,
                                            Qualification: this.state.Qualification,
                                            GraduationYear: this.state.GraduationYear,
                                            DateofretirementAge: this.state.DateofretirementAge,
                                            TypeofContract: this.state.TypeofContract,
                                            prviousextensionwithoutapprovalfrom: this.state.prviousextensionwithoutapprovalfrom,
                                            prviousextensionwithoutapprovalto: this.state.prviousextensionwithoutapprovalto,
                                            Previousextionsfrom: this.state.Previousextionsfrom,
                                            Previousextionsto: this.state.Previousextionsto,
                                            Replacementplanfrom: this.state.Replacementplanfrom,
                                            Replacementplanto: this.state.Replacementplanto
                                        }}
                                        getData={this.getData}
                                        saveAsDraft={this.stepTwoData}
                                    />
                                    :
                                    null
                            }
                            {
                                this.state.step2 ?
                                    <StepTwo
                                        isArabic={this.state.isArabic}
                                        sendData={{
                                            nationalOrNot: this.state.nationalOrNot,
                                            copyButton: this.state.copyButton,
                                            jobContract: this.state.jobContract,

                                            medicalApprovalsFromTheCometentMedicalCommittee: this.state.medicalApprovalsFromTheCometentMedicalCommittee,
                                            medicalApprovalsFromTheCometentMedicalCommitteeShortName: this.state.medicalApprovalsFromTheCometentMedicalCommitteeShortName,

                                            passport: this.state.passport,
                                            //familyBook: this.state.familyBook,
                                            replacePlansForNonLocalsAttachementFileName: this.state.replacePlansForNonLocalsAttachementFileName,
                                            replacePlansForNonLocalsAttachmentFileObj: this.state.replacePlansForNonLocalsAttachmentFileObj,
                                            emaritesID: this.state.emaritesID,
                                            personalPhoto: this.state.personalPhoto,
                                            annualPerformanceEvaluationattach: this.state.annualPerformanceEvaluationattach,
                                            employeeCV: this.state.employeeCV,
                                            acopyofthepreviousapprovalsforextensionfromthejurisdiction: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdiction,
                                            acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName: this.state.acopyofthepreviousapprovalsforextensionfromthejurisdictionShortName,
                                            Summaryofregistrationforcitizens:this.state.Summaryofregistrationforcitizens,
                                            PoistioninStructure:this.state.PoistioninStructure,
        Previousapprovalsfortheextension:this.state.Previousapprovalsfortheextension,
            Qualifications:this.state.Qualifications,
            Jobdescription:this.state.Jobdescription,
            Careersuccessionplan:this.state.Careersuccessionplan,
            HRCom:this.state.HRCom,
            OtherAttach:this.state.OtherAttach
                                        }}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        saveAsDraft={this.stepTwoData}
                                        previewPage={this.handlePreViewNavigation}
                                    />
                                    :
                                    null
                            }
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'220vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                    </MDBRow>
                </LoadingOverlay >
            </MDBContainer >
        );
    }
}