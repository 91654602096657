import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { MDBCol,MDBRow, MDBBtn, MDBTableHead, MDBTable, MDBTableBody, MDBCard, MDBCardBody, MDBFormInline, MDBContainer } from "mdbreact";
import i18n from "../../i18n";



class RequestSurvey extends Component {

    constructor(props) {
        super(props);
        
    };
    
    componentWillMount = (props) => {
        debugger;
     

       // this.permissionData();
    }

    componentDidMount = () => {
       debugger;
     
       
    }



    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    render() {
     
        return (

            <MDBContainer
            style={{ justifyContent: this.props.isArabic ? "row-reverse" : "row" }}
        >
            <form
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
            // action='next'
            >
                <LoadingOverlay active={false} spinner={true}>
                    <MDBCol
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style={{ width: "100%", alignItems: "center" }}

                        
                    >
                          <MDBCol
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    style={{ height: "20vh" }}
                                >
                                      <div className="form-group">
                                      <img src='../../images/Survey/01.png' width="100" height="100" />  <label
                                            htmlFor="Entityformalrequest"
                                            style={{
                                                display: "flex",
                                                flexDirection: this.props.isArabic
                                                    ? "row-reverse"
                                                    : "row"
                                            }}
                                        > {i18n[this.getLocale()].SurveyTitle}</label>
                                        </div>
                                </MDBCol>

</MDBCol></LoadingOverlay>
</form>
            </MDBContainer>
       
        );
    }
}

                    

                    export default RequestSurvey;