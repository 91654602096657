import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import StepsComponent from "../../components/StepsComponent";
import Config from "../../utils/Config";
import i18n from "./../../i18n";
import "./../../css/style.css";
import DownloadComponent from "./../DownloadComponent";

export default class StepOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requesttypeid: '',
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getExceptionAcompanypatientRequestTypeId(),
            passportCopy: this.props.sendData.passportCopy,
            passportCopyUrl: '',
            medicalReport: this.props.sendData.medicalReport,
            medicalReportUrl: '',
            letterFromEmbassy: this.props.sendData.letterFromEmbassy,
            letterFromEmbassyUrl: '',
            copyOftheDigest: this.props.sendData.copyOftheDigest,
            copyOftheDigestUrl: '',
            copyOfHumanResoourcesCommittee: this.props.sendData.copyOfHumanResoourcesCommittee,
            copyOfHumanResoourcesCommitteeUrl: '',
            documentationOfFinancialObligations: this.props.sendData.documentationOfFinancialObligations,
            documentationOfFinancialObligationsShortName: this.props.sendData.documentationOfFinancialObligationsShortName,
            documentationOfFinancialObligationsUrl: '',
            officialLetter: this.props.sendData.officialLetter,
            emiratedID: this.props.sendData.emiratedID,
            officialLetterUrl: '',

            passportCopyErr: "",
            medicalReportErr: "",
            letterFromEmbassyErr: "",
            copyOftheDigestErr: '',
            copyOfHumanResoourcesCommitteeErr: "",
            officialLetterErr: "",
            emiratedIDErr: "",
            documentationOfFinancialObligationsFileErr: '',

            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            isSubmit: false,
            file1: "",
            documentationOfFinObligationsFileObjArray: [],
            officialLetterFromHeadOfEntityFileObj: "",
            passportCopyFIleObj: "",
            medicalReportFIleObj: "",
            letterFromEmbassyFileObj: "",
            copyOfDigestFileObj: "",
            copyOfHumanResoourcesCommitteeFile: "",
            file8: "",
            editAttachmentArray: [],
            passportCopyArray: [],
            medicalReportArray: [],
            letterFromEmbassyArray: [],
            copyOfDigestArray: [],
            copyOfHumanResourceComitteArray: [],
            documentOfFinacialObligationArray: [],
            officialterArray: [],
            emiratesIdArray: [],
            emiratesIDFileObj: "", 
               OtherAttachArray: [],
            OtherAttachErr: "",
            OtherAttach: this.props.sendData.OtherAttach,
            OtherAttachFileObj: "",
           
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag && editObj) {
            this.setState({
                requesttypeid: editObj.requesttypeid,
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempPassportCopyArray = [];
                let tempMedicalReportArray = [];
                let tempLetterFromEmbassyArray = [];
                let tempCopyOfDigestArray = [];
                let tempCopyOfHumanResourceComitteArray = [];
                let tempDocumentOfFinacialObligationArray = [];
                let tempOfficialLetterArray = [];
                let tempEmiratesIdArray = [];
                let tempOtherAttachArray = [];
                
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getPatientPassortCopyId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempPassportCopyArray.push(temp);
                                this.setState({
                                    //passportCopy: item.filename,
                                    passportCopyUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientMedicalReportId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempMedicalReportArray.push(temp);
                                this.setState({
                                    //medicalReport: item.filename,
                                    medicalReportUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientLetterFromEmbassyId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;
                                tempLetterFromEmbassyArray.push(temp);
                                this.setState({
                                    //letterFromEmbassy: item.filename,
                                    letterFromEmbassyUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientCopyOfdDigestId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;
                                tempCopyOfDigestArray.push(temp);
                                this.setState({
                                    //copyOftheDigest: item.filename,
                                    copyOftheDigestUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientHumanResourceComitteRecordId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempCopyOfHumanResourceComitteArray.push(temp);
                                this.setState({
                                    //copyOfHumanResoourcesCommittee: item.filename,
                                    copyOfHumanResoourcesCommitteeUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientFinancialObligationId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;
                                tempDocumentOfFinacialObligationArray.push(temp);
                                this.setState({
                                    //documentationOfFinancialObligations: item.filename,
                                    documentationOfFinancialObligationsUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getpatientDetailedsalaryCertificateId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempOfficialLetterArray.push(temp);
                                this.setState({
                                    //officialLetter: item.filename,
                                    officialLetterUrl: item.aliasfilename
                                })
                            } else if (item.filetypeid === Config.getPatientEmiratesId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                // temp.requestId = this.state.requesttypeid;

                                tempEmiratesIdArray.push(temp);
                                this.setState({
                                    //emiratedID: item.filename
                                })
                                
                            }
                            else if (item.filetypeid && item.filetypeid === Config.getRETOtherAttachId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempOtherAttachArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }
                        })
                    }
                }

                this.setState({
                    emiratesIdArray: tempEmiratesIdArray,
                    passportCopyArray: tempPassportCopyArray,
                    medicalReportArray: tempMedicalReportArray,
                    letterFromEmbassyArray: tempLetterFromEmbassyArray,
                    copyOfDigestArray: tempCopyOfDigestArray,
                    copyOfHumanResourceComitteArray: tempCopyOfHumanResourceComitteArray,
                    documentOfFinacialObligationArray: tempDocumentOfFinacialObligationArray,
                    officialterArray: tempOfficialLetterArray,
                    OtherAttachArray:tempOtherAttachArray
                });

            });

        }

    };

    goToPrevious = () => {
        this.props.getData.stepThreePrev({
            officialLetter: this.state.officialLetter,
            emiratedID: this.state.emiratedID,
            passportCopy: this.state.passportCopy,
            medicalReport: this.state.medicalReport,
            letterFromEmbassy: this.state.letterFromEmbassy,
            copyOftheDigest: this.state.copyOftheDigest,
            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
            documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
            OtherAttach:this.state.OtherAttach
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    checkValidations = () => {
        let officialLetterErr = '',
            passportCopyErr = '',
            medicalReportErr = '',
            letterFromEmbassyErr = '',
            copyOfHumanResoourcesCommitteeErr = '',
            emiratedIDErr = '';

        if (this.state.officialterArray && this.state.officialterArray.length > 0) {
            officialLetterErr = "";
        } else {
            if (!this.state.officialLetter) {
                officialLetterErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.passportCopyArray && this.state.passportCopyArray.length > 0) {
            passportCopyErr = "";
        } else {
            if (!this.state.passportCopy) {
                passportCopyErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.medicalReportArray && this.state.medicalReportArray.length > 0) {
            medicalReportErr = "";
        } else {
            if (!this.state.medicalReport) {
                medicalReportErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.letterFromEmbassyArray && this.state.letterFromEmbassyArray.length > 0) {
            letterFromEmbassyErr = "";
        } else {
            if (!this.state.letterFromEmbassy) {
                letterFromEmbassyErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.copyOfHumanResourceComitteArray && this.state.copyOfHumanResourceComitteArray.length > 0) {
            copyOfHumanResoourcesCommitteeErr = "";
        } else {
            if (!this.state.copyOfHumanResoourcesCommittee) {
                copyOfHumanResoourcesCommitteeErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.emiratesIdArray && this.state.emiratesIdArray.length > 0) {
            emiratedIDErr = "";
        } else {
            if (!this.state.emiratedID) {
                emiratedIDErr = 'thisFieldIsRequired';
            }
        }

        // if (this.state.copyOftheDigest === '') {
        //   this.setState({ copyOftheDigestErr: 'thisFieldIsRequired' });
        // }
        // if (this.state.documentationOfFinancialObligations === null) {
        //     this.setState({ documentationOfFinancialObligationsFileErr: 'thisFieldIsRequired' });
        // }

        this.setState({
            officialLetterErr: officialLetterErr,
            passportCopyErr: passportCopyErr,
            medicalReportErr: medicalReportErr,
            letterFromEmbassyErr: letterFromEmbassyErr,
            copyOfHumanResoourcesCommitteeErr: copyOfHumanResoourcesCommitteeErr,
            emiratedIDErr: emiratedIDErr
        }, () => {

            if (!officialLetterErr &&
                !passportCopyErr &&
                !medicalReportErr &&
                !letterFromEmbassyErr &&
                !copyOfHumanResoourcesCommitteeErr &&
                !emiratedIDErr) {

                    
                this.props.getData.stepThreeData({
                    officialLetter: this.state.officialLetter,
                    passportCopy: this.state.passportCopy,
                    medicalReport: this.state.medicalReport,
                    letterFromEmbassy: this.state.letterFromEmbassy,
                    copyOftheDigest: this.state.copyOftheDigest,
                    copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
                    documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
                    documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
                    emiratedID: this.state.emiratedID,
                    isEdit: this.state.isEdit,
                    requestId: this.state.requestId,
                    OtherAttach:this.state.OtherAttach,
                });
            }
        });
    }

    OtherAttachOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ OtherAttach: e.target.files[0].name, OtherAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                OtherAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForOtherAttach(this.state.OtherAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ OtherAttach: '', OtherAttachErr: 'thisFieldIsRequired' });
        //}
    };

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        //check for edit flag, if edit false => attachment are not compulsary
        this.checkValidations();

    };

    clearForm = () => {
        this.setState({
            officialLetter: '',
            emiratedID: '',
            passportCopy: '',
            medicalReport: '',
            letterFromEmbassy: '',
            copyOftheDigest: '',
            copyOfHumanResoourcesCommittee: '',
            documentationOfFinancialObligations: ''
        });
    }

    copyOfHumanResoourcesCommitteeOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                let filesObj = e.target.files[0];
                let fileExt = inpFileName.split('.').pop().toUpperCase();
                let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                if (allowedExtensions.indexOf(fileExt) <= -1) {
                    e.target.value = '';
                    this.setState({
                        copyOfHumanResoourcesCommittee: '',
                        copyOfHumanResoourcesCommitteeErr: 'invalidFileType'
                    });
                } else {

                    this.setState({
                        copyOfHumanResoourcesCommittee: inpFileName,
                        copyOfHumanResoourcesCommitteeErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                file1: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient1(this.state.file1);
                            }
                            );
                        };

                        reader.readAsDataURL(file);

                    });
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    copyOfHumanResoourcesCommittee: '',
                    copyOfHumanResoourcesCommitteeErr: 'invalidFileSize'
                });
            }
        } else {
            e.target.value = '';
            this.setState({
                copyOfHumanResoourcesCommittee: "",
                copyOfHumanResoourcesCommitteeErr: 'thisFieldIsRequired'
            });
        }
    };

    documentationOfFinancialObligationsOnChange = e => {
        let fileNames = "";
        let fileSupportArray = [];

        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({
                            documentationOfFinancialObligations: '',
                            documentationOfFinancialObligationsShortName: '',
                            documentationOfFinancialObligationsFileErr: 'invalidFileType'
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let filesObj = e.target.files[m];
                            let comma = "";
                            if (m > 0) {
                                comma = ", ";
                            }
                            fileNames += comma + e.target.files[m].name;
                            let documentationOfFinancialObligationsShortName = fileNames.substring(0, 50);

                            this.setState({
                                documentationOfFinancialObligations: fileNames,
                                documentationOfFinancialObligationsShortName: documentationOfFinancialObligationsShortName,
                                documentationOfFinancialObligationsFileErr: ''
                            }, () => {

                                let tempFile = filesObj;
                                let reader = new FileReader();

                                reader.onloadend = () => {
                                    fileSupportArray.push(reader.result);
                                    this.setState({
                                        documentationOfFinObligationsFileObjArray: fileSupportArray,
                                    }, () => {
                                        Config.setbase64ForexemptionFromAccompanyPatient9(this.state.documentationOfFinObligationsFileObjArray);
                                    });
                                }
                                reader.readAsDataURL(tempFile);
                            });
                        }

                        else {
                            e.target.value = '';
                            this.setState({
                                documentationOfFinancialObligations: '',
                                documentationOfFinancialObligationsShortName: '',
                                documentationOfFinancialObligationsFileErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({ 
                    documentationOfFinancialObligations: "",
                    documentationOfFinancialObligationsShortName: "",
                    documentationOfFinancialObligationsFileErr: 'fileLimitErr' 
                });
            }
        }
        else {
            e.target.value = '';
            this.setState({
                documentationOfFinancialObligations: '',
                documentationOfFinancialObligationsShortName: '',
                //documentationOfFinancialObligationsFileErr: 'thisFieldIsRequired' 
            });
        }
    };

    officialLetterOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    officialLetter: '',
                    officialLetterErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        officialLetter: inpFileName,
                        officialLetterErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let tempFile = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                officialLetterFromHeadOfEntityFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient3(this.state.officialLetterFromHeadOfEntityFileObj);
                            }
                            );
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ officialLetter: '', officialLetterErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ officialLetter: '', officialLetterErr: 'thisFieldIsRequired' });
        }
    };

    emiratedIDOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ emiratedID: '', emiratedIDErr: 'invalidFileType' });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        emiratedID: inpFileName,
                        emiratedIDErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let tempFile = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                emiratesIDFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionEmiratedID(this.state.emiratesIDFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        emiratedID: '',
                        emiratedIDErr: 'invalidFileSize'
                    });
                }
            }

        } else {
            e.target.value = '';
            this.setState({
                emiratedID: '',
                emiratedIDErr: 'thisFieldIsRequired'
            });
        }
    };

    passportCopyOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ passportCopyErr: 'invalidFileType', passportCopy: '' })
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        passportCopy: inpFileName,
                        passportCopyErr: ''
                    }, () => {
                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                passportCopyFIleObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient4(this.state.passportCopyFIleObj);
                            }

                            );

                        };
                        reader.readAsDataURL(file);
                    });

                }
                else {
                    e.target.value = '';
                    this.setState({ passportCopy: '', passportCopyErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                passportCopy: '', passportCopyErr: 'thisFieldIsRequired'
            });
        }
    };

    medicalReportOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ medicalReportErr: 'invalidFileType', medicalReport: '' });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        medicalReport: inpFileName,
                        medicalReportErr: '',
                    }, () => {
                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                medicalReportFIleObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient5(this.state.medicalReportFIleObj);

                            });
                        };
                        reader.readAsDataURL(file);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({
                        medicalReport: '',
                        medicalReportErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                medicalReport: '', medicalReportErr: 'thisFieldIsRequired'
            });
        }
    };

    letterFromEmbassyOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    letterFromEmbassyErr: 'invalidFileType',
                    letterFromEmbassy: ''
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        letterFromEmbassy: inpFileName,
                        letterFromEmbassyErr: ''
                    }, () => {

                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                letterFromEmbassyFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient6(this.state.letterFromEmbassyFileObj);
                            });
                        };

                        reader.readAsDataURL(file);
                    });

                }

                else {
                    e.target.value = '';
                    this.setState({
                        letterFromEmbassy: '',
                        letterFromEmbassyErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                letterFromEmbassy: '',
                letterFromEmbassyErr: 'thisFieldIsRequired'
            });
        }
    };

    copyOftheDigestOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    copyOftheDigestErr: 'invalidFileType',
                    copyOftheDigest: ''
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {

                    let filesObj = e.target.files[0];

                    this.setState({
                        copyOftheDigest: inpFileName,
                        copyOftheDigestErr: '',
                    }, () => {

                        let reader = new FileReader();
                        let file = filesObj;

                        reader.onloadend = () => {
                            this.setState({
                                copyOfDigestFileObj: reader.result
                            }, () => {
                                Config.setbase64ForexemptionFromAccompanyPatient7(this.state.copyOfDigestFileObj);
                            });
                        };

                        reader.readAsDataURL(file);

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        copyOftheDigest: '',
                        copyOftheDigestErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                copyOftheDigest: '',
                copyOftheDigestErr: ''
            });
        }
    };

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            officialLetter: this.state.officialLetter,
            passportCopy: this.state.passportCopy,
            medicalReport: this.state.medicalReport,
            letterFromEmbassy: this.state.letterFromEmbassy,
            copyOftheDigest: this.state.copyOftheDigest,
            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
            documentationOfFinancialObligationsShortName: this.state.documentationOfFinancialObligationsShortName,
            emiratedID: this.state.emiratedID,
            OtherAttach:this.state.OtherAttach
        });
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            officialLetter: this.state.officialLetter,
            passportCopy: this.state.passportCopy,
            medicalReport: this.state.medicalReport,
            letterFromEmbassy: this.state.letterFromEmbassy,
            copyOftheDigest: this.state.copyOftheDigest,
            copyOfHumanResoourcesCommittee: this.state.copyOfHumanResoourcesCommittee,
            documentationOfFinancialObligations: this.state.documentationOfFinancialObligations,
            emiratedID: this.state.emiratedID,
            OtherAttach:this.state.OtherAttach
        });
    };

    render() {

        return (
            <form
                style={{ paddingTop: '20', paddingBottom: '200px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2", "Step3"]}
                    currentIndex={3}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row",
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row"
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                                i18n[this.props.isArabic ? "ar" : "en"]
                                    .ExemptionFromAccompanyPatient.passportCopy
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="passportCopyFile"
                                    type="file"
                                    name="passportCopy"
                                    onChange={event => this.passportCopyOnChange(event)}
                                />
                                <label
                                    htmlFor="passportCopyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="passportCopyFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.passportCopy}</span>
                                </label>
                            </div>
                            {
                                this.state.passportCopyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.passportCopyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.passportCopyArray &&
                                    this.state.passportCopyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.passportCopyArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row"
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                                i18n[this.props.isArabic ? "ar" : "en"]
                                    .ExemptionFromAccompanyPatient.medicalReport
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="medicalReportFile"
                                    type="file"
                                    name="medicalReport"
                                    onChange={event => this.medicalReportOnChange(event)}
                                />
                                <label
                                    htmlFor="medicalReportFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="medicalReportFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.medicalReport}</span>
                                </label>
                            </div>
                            {
                                this.state.medicalReportErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.medicalReportErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.medicalReportArray &&
                                    this.state.medicalReportArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.medicalReportArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                <MDBRow
                    className="formRow rowBottom"
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                textAlign: this.props.isArabic ? 'right' : 'left'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                                i18n[this.props.isArabic ? "ar" : "en"]
                                    .ExemptionFromAccompanyPatient.letterFromEmbassyFile
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="letterFromEmbassyFile"
                                    type="file"
                                    name="letterFromEmbassy"
                                    onChange={event =>
                                        this.letterFromEmbassyOnChange(event)
                                    }
                                />
                                <label
                                    htmlFor="letterFromEmbassyFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label
                                    htmlFor="letterFromEmbassyFile"
                                    className="fileName"
                                    style={{ textAlign: this.props.isArabic ? "right" : '' }}
                                >
                                    <span>{this.state.letterFromEmbassy}</span>
                                </label>
                            </div>
                            {
                                this.state.letterFromEmbassyErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.letterFromEmbassyErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.letterFromEmbassyErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.letterFromEmbassyArray &&
                                    this.state.letterFromEmbassyArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.letterFromEmbassyArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                textAlign: this.props.isArabic ? "right" : "left",
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                                i18n[this.props.isArabic ? "ar" : "en"]
                                    .ExemptionFromAccompanyPatient.officialLetter
                            }
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="officialLetterFile"
                                    type="file"
                                    name="officialLetter"
                                    onChange={event => this.officialLetterOnChange(event)}
                                    required
                                />

                                <label
                                    htmlFor="officialLetterFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>

                                <label htmlFor="officialLetterFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.officialLetter}</span>
                                </label>
                            </div>
                            {
                                this.state.officialLetterErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.officialLetterErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.officialLetterErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                            {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.officialterArray &&
                                this.state.officialterArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.officialterArray} />
                                : <span></span>
                        }
                    </MDBCol>

                </MDBRow>

                <MDBRow
                    className="formRow rowBottom"
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row"
                                }}
                                htmlFor="formGroupExampleInput">
                                {
                                    i18n[this.props.isArabic ? "ar" : "en"]
                                        .ExemptionFromAccompanyPatient
                                        .copyOfHumanResoourcesCommittee
                                }
                                <span className="requireStar">*</span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="copyOfHumanResoourcesCommitteeFile"
                                        type="file"
                                        name="copyOfHumanResoourcesCommittee"
                                        onChange={event =>
                                            this.copyOfHumanResoourcesCommitteeOnChange(event)
                                        }
                                    />
                                    <label
                                        htmlFor="copyOfHumanResoourcesCommitteeFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                    >
                                        {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                    </label>
                                    <label
                                        htmlFor="copyOfHumanResoourcesCommitteeFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : '' }}
                                    >
                                        <span>
                                            {this.state.copyOfHumanResoourcesCommittee}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            {
                                this.state.copyOfHumanResoourcesCommitteeErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfHumanResoourcesCommitteeErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOfHumanResoourcesCommitteeErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.copyOfHumanResourceComitteArray &&
                                    this.state.copyOfHumanResourceComitteArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.copyOfHumanResourceComitteArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                textAlign: this.props.isArabic ? "right" : "left",
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                                i18n[this.props.isArabic ? "ar" : "en"]
                                    .ExemptionFromAccompanyPatient.emiratedID
                            }
                            <span className="requireStar">*</span>
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="emiratedIDFile"
                                    type="file"
                                    name="emiratedID"
                                    onChange={event => this.emiratedIDOnChange(event)}
                                    required />

                                <label
                                    htmlFor="emiratedIDFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>

                                <label htmlFor="emiratedIDFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.emiratedID}</span>
                                </label>
                            </div>
                            {
                                this.state.emiratedIDErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emiratedIDErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emiratedIDErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                            {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                        </p>
                        {
                            (this.state.emiratesIdArray &&
                                this.state.emiratesIdArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.emiratesIdArray} />
                                : <span></span>
                        }
                    </MDBCol>

                </MDBRow>

                <MDBRow
                    className="formRow rowBottom"
                    style={{
                        display: "flex",
                        flexDirection: this.props.isArabic ? "row-reverse" : "row"
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: this.props.isArabic ? "row-reverse" : "row",
                                textAlign: this.props.isArabic ? 'right' : 'left'
                            }}
                            htmlFor="formGroupExampleInput">
                            {
                                i18n[this.props.isArabic ? "ar" : "en"]
                                    .ExemptionFromAccompanyPatient.copyOftheDigest
                            }
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="copyOftheDigestFile"
                                    type="file"
                                    name="copyOftheDigest"
                                    onChange={event => this.copyOftheDigestOnChange(event)}
                                />
                                <label
                                    htmlFor="copyOftheDigestFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="copyOftheDigestFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{this.state.copyOftheDigest}</span>
                                </label>
                            </div>
                            {
                                this.state.copyOftheDigestErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.copyOftheDigestErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.copyOfDigestArray && this.state.copyOfDigestArray.length > 0) ?
                                    <DownloadComponent filesObj={this.state.copyOfDigestArray} />
                                    :
                                    <span></span>
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: this.props.isArabic
                                        ? "row-reverse"
                                        : "row",
                                    textAlign: this.props.isArabic ? 'right' : 'left'
                                }}
                                htmlFor="formGroupExampleInput">
                                {
                                    i18n[this.props.isArabic ? "ar" : "en"]
                                        .ExemptionFromAccompanyPatient
                                        .documentationOfFinancialObligations
                                }
                                {/* <span className="requireStar">*</span> */}
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="documentationOfFinancialObligationsFile"
                                        type="file"
                                        name="documentationOfFinancialObligations"
                                        multiple
                                        onChange={event => this.documentationOfFinancialObligationsOnChange(event)}
                                    />
                                    <label
                                        htmlFor="documentationOfFinancialObligationsFile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                    </label>
                                    <label
                                        htmlFor="documentationOfFinancialObligationsFile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                        <span>
                                            {/* {this.state.documentationOfFinancialObligations} */}

                                            <span className="attachmentName">{this.state.documentationOfFinancialObligationsShortName}</span>
                                            <span className="attachmentShortName">
                                                {
                                                    this.state.documentationOfFinancialObligationsShortName ?
                                                        <MDBPopover
                                                            placement="bottom"
                                                            popover
                                                            clickable
                                                            id="popper12"
                                                            component="label"
                                                            popoverBody={i18n[this.getLocale()].viewMore}
                                                            size="sm"
                                                        >
                                                            <MDBPopoverBody
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "space-around"
                                                                }}>
                                                                <span>{this.state.documentationOfFinancialObligations}</span>
                                                            </MDBPopoverBody>
                                                        </MDBPopover>
                                                        :
                                                        ''
                                                }
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.documentationOfFinancialObligationsFileErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.documentationOfFinancialObligationsFileErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.documentationOfFinancialObligationsFileErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ color: '#000', display: 'flex', flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>

                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                                </p>
                                {
                                    (this.state.documentOfFinacialObligationArray &&
                                        this.state.documentOfFinacialObligationArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.documentOfFinacialObligationArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.OtherAttach}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="OtherAttachFile"
                                        type="file"
                                        name="OtherAttach"
                                        onChange={(event) => this.OtherAttachOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="OtherAttachFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="OtherAttachFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.OtherAttach}</span></label>
                                </div>

                                {
                                    this.state.OtherAttachErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.OtherAttachArray &&
                                    this.state.OtherAttachArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.OtherAttachArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>


                </MDBRow>

                <MDBRow style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row" }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type="submit" color="primary">{i18n[this.props.isArabic ? "ar" : "en"].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }
}
