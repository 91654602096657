import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import Config from '../../utils/Config';
import CKEditorComponent from '../CKEditorComponent';

const striptags = require('striptags');
var base64 = require('base-64');
var utf8 = require('utf8');

export default class MailTemplateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            title: '',
            mailTemplateTypeSelectedValue: '',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            mailTemplateError: "",
            mailTemplateTypeArray: [],
            isEdit: false,
            requestId: '',
        }
        this.updateContent = this.updateContent.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let tempMailTemplateTypeArray = Config.getMailTemplateTypeData();
        this.setState({
            mailTemplateTypeArray: tempMailTemplateTypeArray,
        });

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();        
        if (editFlag) {
            let tempContent = editObj.content;
            let decodedData = base64.decode(tempContent);
         
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                title: editObj.title,
                mailTemplateTypeSelectedValue: editObj.notificationtype,
                content: decodedData,
            }, () => {
                Config.setIsEditFlag(false);
            })
        }
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }

    onChangeEmailEditor = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                mailTemplateError: "",
                content: newContent
            })
        } else {
            this.setState({ mailTemplateError: 'ckeditorCountErr' });
        }
    }

    onBlurEmailEditor = (evt) => {
      
    }

    afterPasteEmailEditor = (evt) => {
      
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    convertHtmlToBase64 = (text) => {
        let bytes = utf8.encode(text);
        let encoded = base64.encode(bytes);
        
        return encoded;

        // var decodedData = base64.decode(encoded);
       
        // this.setState({
        //     content: decodedData;
        // });

    }

    submitHandler = event => {

        event.preventDefault();
        event.target.className += " was-validated";

        let tempmailTemplateError = "";
        if (this.state.content.trim() !== '') {

            let val = striptags(this.state.content.replace(/&nbsp;/g, "").trim());
            if (val.trim() === '') {

                tempmailTemplateError = 'thisFieldIsRequired';

            }
        } else {
            tempmailTemplateError = 'thisFieldIsRequired';
        }

        this.setState({ mailTemplateError: tempmailTemplateError }, () => {
            if (this.state.mailTemplateError === '') {
                if (this.state.title !== '' && this.state.mailTemplateTypeSelectedValue !== '' && striptags(this.state.content.toString('html')) !== '') {
                    let base64OfMailTemplateBody = this.convertHtmlToBase64(this.state.content);
                    Config.setBase64OfMailTemplateBody(base64OfMailTemplateBody);

                    this.props.onSubmitData(this.state.title, this.state.mailTemplateTypeSelectedValue, this.state.isEdit, this.state.requestId);
                }
            }
        })

    };

    handleTitleChange = (event) => {

        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ title: event.target.value }, () => {
                   
                });
            }
        } else {
            this.setState({ title: "" }, () => {
                
            });
        }

    };

    handleChangeForMailTemplateType = (event) => {
        this.setState({
            mailTemplateTypeSelectedValue: event.target.value
        });
    };

    render() {

        return (

            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                {i18n[this.getLocale()].title}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>

                            </label>

                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                value={this.state.title}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleTitleChange}
                                required
                            />

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.getLocale()].mailtemplatetype}<span className="requireStar">*</span></label>

                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select" id="sources-funding"
                                onChange={this.handleChangeForMailTemplateType}
                                value={this.state.mailTemplateTypeSelectedValue}
                                required >

                                <option value="">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                </option>
                                {
                                    this.state.mailTemplateTypeArray ? this.state.mailTemplateTypeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : ''
                                }

                            </select>

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>
                                {i18n[this.getLocale()].clientDescr}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>

                            </label>

                            <div style={{ border: this.state.mailTemplateError === 'thisFieldIsRequired' ? '1px solid red' : '' }} >

                                <CKEditorComponent
                                    isArabic={this.props.isArabic}
                                    content={this.state.content}
                                    onChange={this.onChangeEmailEditor}
                                    ckEditorHeight={"10vh"}
                                />
                            </div>
                        </div>
                        {
                            this.state.mailTemplateError === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }

                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].submit}</MDBBtn>
                </MDBRow>

            </form >
        );
    }
}
