import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from "mdbreact";
import i18n from "./../../i18n";
import StepsComponent from "./../StepsComponent";
import "./../../css/style.css";
import Config from '../../utils/Config';
import DownloadComponent from "./../DownloadComponent";

export default class StepTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            requestId: '',
            statusid: '',
            requestType: Config.getExceptionADHousingRequestTypeId(),
            entitityFileRequestFileObj: '',
            attachFileSupportFileObj: '',

            entityFormalRequest: this.props.sendData.entityFormalRequest,
            entityFormalRequestUrl: '',
            employeeContractUrl: '',
            employeeContract: this.props.sendData.employeeContract,
            employeeContractErr: '',
            employeeContractObj: '',
            flatContractUrl: '',
            flatContract: this.props.sendData.flatContract,
            flatContractErr: '',
            flatContractObj: '',
            
 PassportUrl: '',
 Passport: this.props.sendData.Passport,
PassportErr: '',
PassportObj: '',

EIDUrl: '',
 EID: this.props.sendData.EID,
 EIDErr: '',
 EIDObj: '',

FamilyBookUrl: '',
FamilyBook: this.props.sendData.FamilyBook,
FamilyBookErr: '',
FamilyBookObj: '',

            HRAEmployeeStudyMaker: this.props.sendData.HRAEmployeeStudyMaker,
            supportRequest: this.props.sendData.supportRequest,
            supportRequestShortName: this.props.sendData.supportRequestShortName,
            supportRequestUrl: '',

            entityFormalRequestErr: "",
            supportRequestErr: "",
            option: ['Test 1', 'Test 2', 'Test 3', 'Test 4', 'Test 5'],

            editAttachmentArray: [],
            entityFormalRequestArray: [],
            supportRequestArray: [],
            employeeContractArray: [],
            flatContractArray: [],    
            
             flatContractArray: [],
            PassportArray: [],
            EIDArray: [],
            FamilyBookArray: [],
            
            OtherAttachArray: [],
            OtherAttachErr: "",
            OtherAttach: this.props.sendData.OtherAttach,
            OtherAttachFileObj: ""
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                editAttachmentArray: editObj.attachments,
            }, () => {  let tempEntityFormalRequestArray = [],
                tempSupportRequestArray = [],
                tempemployeeContractArray = [],
                 tempflatContractArray = [],
              
                tempPassporArray= [],
tempEIDArray= [],
tempFamilyBookArray= [],tempOtherAttachArray = [];

                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    
                    let tempAttactmentArray = [...this.state.editAttachmentArray];
                    
                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getAdHousingEntityFormalRequestId()) {
                                let temp = {};
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEntityFormalRequestArray.push(temp);

                                this.setState({
                                    // entityFormalRequest: item.filename,
                                    entityFormalRequestUrl: item.aliasfilename
                                });

                            } else if (item.filetypeid === Config.getAdHousingAttachedFileForSupportRequestId()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempSupportRequestArray.push(temp);

                                this.setState({
                                    //supportRequest: item.filename,
                                    supportRequestUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAdHousingAttachedFileForEmployee()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempemployeeContractArray.push(temp);

                                this.setState({
                                    //employeeContract: item.filename,
                                    employeeContractUrl: item.aliasfilename
                                })

                            } else if (item.filetypeid === Config.getAdHousingAttachedFileForFlat()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempflatContractArray.push(temp);

                                this.setState({
                                    //flatContract: item.filename,
                                    flatContractUrl: item.aliasfilename
                                })
                                

                            }
                            else if (item.filetypeid && item.filetypeid === Config.getRETOtherAttachId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempOtherAttachArray.push(temp);

                                this.setState({
                                    //replacePlansForNonLocalsAttachementFileName: item.filename
                                })
                            }
                      

                            else if (item.filetypeid === Config.getAdHousingAttachedFileForPassport()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempPassporArray.push(temp);

                                this.setState({
                                  
                                    PassportUrl: item.aliasfilename
                                })

                            }
                            else if (item.filetypeid === Config.getAdHousingAttachedFileForEID()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempEIDArray.push(temp);

                                this.setState({
                                 
                                    EIDUrl: item.aliasfilename
                                })

                            }
                            else if (item.filetypeid === Config.getAdHousingAttachedFileForFamilyBook()) {

                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempFamilyBookArray.push(temp);

                                this.setState({
                                    
                                    FamilyBookUrl: item.aliasfilename
                                })

                            }
                        })

                        this.setState({
                            entityFormalRequestArray: tempEntityFormalRequestArray,
                            supportRequestArray: tempSupportRequestArray,
                            employeeContractArray: tempemployeeContractArray,
                            flatContractArray: tempflatContractArray,
                            PassportArray:tempPassporArray,
                            EIDArray:tempEIDArray,
                            FamilyBookArray:tempFamilyBookArray,
                             OtherAttachArray:tempOtherAttachArray
                        })
                    }
                }

            });

        }

    }

    entityFormalRequestFileChange = (e) => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    entityFormalRequest: '',
                    entityFormalRequestErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    
                    let filesObj = e.target.files[0];

                    this.setState({
                        entityFormalRequest: e.target.files[0].name, entityFormalRequestErr: ""
                    }, () => {
                        
                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                entitityFileRequestFileObj: reader.result,
                            }, () => {
                                Config.setBase64ForExemptionHousingPolicy_EntityFormalReq(this.state.entitityFileRequestFileObj);
                            });
                        }
                    });

                } else {
                    e.target.value = '';
                    this.setState({
                        entityFormalRequest: '',
                        entityFormalRequestErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                entityFormalRequest: '',
                entityFormalRequestErr: 'thisFieldIsRequired'
            });
        }
    }

    employeeContractFileChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    employeeContract: '',
                    employeeContractErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        employeeContract: e.target.files[0].name,
                        employeeContractErr: ""
                    }, () => {
                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                employeeContractObj: reader.result,
                            }, () => {
                                Config.setBase64ForExemptionHousingPolicy_employeeContract(this.state.employeeContractObj);
                            });
                        }

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        employeeContract: '',
                        employeeContractErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                employeeContract: '',
                employeeContractErr: 'thisFieldIsRequired'
            });
        }
    }

    flatContractFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    flatContract: '',
                    flatContractErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        flatContract: e.target.files[0].name,
                        flatContractErr: ""
                    }, () => {

                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                flatContractObj: reader.result,
                            }, () => {
                                Config.setBase64ForExemptionHousingPolicy_flatContract(this.state.flatContractObj);
                            });
                        }

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        flatContract: '',
                        flatContractErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                flatContract: '',
                flatContractErr: 'thisFieldIsRequired'
            });
        }
    }
    passportcopyFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    Passport: '',
                    PassportErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        Passport: e.target.files[0].name,
                        PassportErr: ""
                    }, () => {

                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                PassportObj: reader.result,
                            }, () => {
                                Config.setBase64ForExemptionHousingPolicy_Passport(this.state.PassportObj);
                            });
                        }

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        Passport: '',
                        PassportErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                Passport: '',
                PassportErr: ''
            });
        }
    }

    familybooknumberFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    FamilyBook: '',
                    FamilyBookErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        FamilyBook: e.target.files[0].name,
                        FamilyBookErr: ""
                    }, () => {

                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                FamilyBookObj: reader.result,
                            }, () => {
                                Config.setBase64ForExemptionHousingPolicy_familybooknumber(this.state.FamilyBookObj);
                            });
                        }

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        FamilyBook: '',
                        FamilyBookErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                FamilyBook: '',
                FamilyBookErr: ''
            });
        }
    }

    EIDFileChange = e => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size;
            let inpFileName = e.target.files[0].name;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    EID: '',
                    EIDErr: 'invalidFileType'
                });
            }
            else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    let filesObj = e.target.files[0];
                    this.setState({
                        EID: e.target.files[0].name,
                        EIDErr: ""
                    }, () => {

                        let tempFile = filesObj;
                        let reader = new FileReader();
                        reader.readAsDataURL(tempFile);
                        reader.onloadend = () => {
                            this.setState({
                                EIDObj: reader.result,
                            }, () => {
                                Config.setBase64ForExemptionHousingPolicy_EID(this.state.EIDObj);
                            });
                        }

                    });

                }
                else {
                    e.target.value = '';
                    this.setState({
                        EID: '',
                        EIDErr: 'invalidFileSize'
                    });
                }
            }
        } else {
            e.target.value = '';
            this.setState({
                EID: '',
                EIDErr: ''
            });
        }
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    attachFileSupportFileChange = e => {
        if (e.target.files.length > 0) {
            if (e.target.files.length <= Config.getMaxFileCount()) {
                let fileNames = "";
                let fileSupportArray = [];

                for (let m = 0; m < e.target.files.length; m++) {
                    let fileSize = e.target.files[m].size;
                    let inpFileName = e.target.files[m].name;
                    let fileExt = inpFileName.split('.').pop().toUpperCase();
                    let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

                    if (allowedExtensions.indexOf(fileExt) <= -1) {
                        e.target.value = '';
                        this.setState({ 
                            supportRequest: '',
                            supportRequestShortName: '',
                            supportRequestErr: 'invalidFileType' 
                        });
                        break;
                    } else {
                        if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                            let comma = "";
                            if (m > 0) {
                                comma = ", "
                            }
                            fileNames += comma + e.target.files[m].name;
                            let supportRequestShortName = fileNames.substring(0, 50);

                            let tempFile = e.target.files[m];
                            let reader = new FileReader();
                            reader.readAsDataURL(tempFile);

                            reader.onloadend = () => {
                                fileSupportArray.push(reader.result);
                                this.setState({
                                    attachFileSupportFileObj: fileSupportArray,
                                }, () => {
                                    Config.setBase64ForExemptionHousingPolicy_attachFileSupport(this.state.attachFileSupportFileObj);
                                });
                            }
                            this.setState({
                                supportRequest: fileNames,
                                supportRequestShortName: supportRequestShortName,
                                supportRequestErr: ''
                            });
                        }
                        else {
                            e.target.value = '';
                            this.setState({
                                supportRequest: '',
                                supportRequestShortName: '',
                                supportRequestErr: 'invalidFileSize'
                            });
                            break;
                        }
                    }
                }
            }
            else {
                e.target.value = '';
                this.setState({
                    supportRequest: "",
                    supportRequestShortName: "",
                    supportRequestErr: 'fileLimitErr'
                });
            }
        } else {
            e.target.value = '';
            this.setState({
                supportRequest: '',
                supportRequestShortName: '',
                supportRequestErr: 'thisFieldIsRequired'
            });
        }
    }

    checkValidations = () => {
    debugger;
        let
            entityFormalRequestErr = '',
            supportRequestErr = '',
            employeeContractErr = '',
            flatContractErr = '',
            passportErr='',
            eidErr='',
            familybookErr='';

        // if (this.state.entityFormalRequestArray && this.state.entityFormalRequestArray.length > 0) {
        //     entityFormalRequestErr = '';
        // } else {
        //     if (!this.state.entityFormalRequest) {
        //         entityFormalRequestErr = 'thisFieldIsRequired';
        //     }
        // }

        // if (this.state.supportRequestArray && this.state.supportRequestArray.length > 0) {
        //     supportRequestErr = '';
        // } else {
        //     if (!this.state.supportRequest) {
        //         supportRequestErr = 'thisFieldIsRequired';
        //     }
        // }

        if (this.state.employeeContractArray && this.state.employeeContractArray.length > 0) {
            employeeContractErr = '';
        } else {
            if (!this.state.employeeContract) {
                employeeContractErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.flatContractArray && this.state.flatContractArray.length > 0) {
            flatContractErr = '';
        } else {
            if (!this.state.flatContract) {
                flatContractErr = 'thisFieldIsRequired';
            }
        }
        if (this.state.PassportArray && this.state.PassportArray.length > 0) {
            passportErr = '';
        } else {
            if (!this.state.Passport) {
                passportErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.EIDArray && this.state.EIDArray.length > 0) {
            eidErr = '';
        } else {
            if (!this.state.EID) {
                eidErr = 'thisFieldIsRequired';
            }
        }

        if (this.state.FamilyBookArray && this.state.FamilyBookArray.length > 0) {
            familybookErr = '';
        } else {
            if (!this.state.employeeContract) {
                familybookErr = 'thisFieldIsRequired';
            }
        }

        this.setState({
            entityFormalRequestErr: entityFormalRequestErr,
            supportRequestErr: supportRequestErr,
            employeeContractErr: employeeContractErr,
            flatContractErr: flatContractErr,
          
            FamilyBookErr:familybookErr,
            EIDErr:eidErr,
            PassportErr:passportErr
        }, () => {
            if (
                
                !this.state.supportRequestErr &&
                
                !this.state.employeeContractErr && !this.state.passportErr && !this.state.passportErr && ! this.state.flatContractErr
            ) {
                this.props.getData.stepTwoData({
                    isEdit: this.state.isEdit,
                    requestId: this.state.requestId,
                    employeeContract: this.state.employeeContract,
                    flatContract: this.state.flatContract,
                    entityFormalRequest: this.state.entityFormalRequest,
                    supportRequest: this.state.supportRequest,
                    supportRequestShortName: this.state.supportRequestShortName,
                    OtherAttach:this.state.OtherAttach,
                    Passport:this.state.Passport,
                    EID:this.state.EID,
                    FamilyBook:this.state.FamilyBook
                });
            }
        });
    }

    OtherAttachOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ OtherAttach: e.target.files[0].name, OtherAttachErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                OtherAttachFileObj: reader.result
                            }, () => {
                                Config.setBase64ForOtherAttach(this.state.OtherAttachFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ OtherAttach: '', OtherAttachErr: 'invalidFileSize' });
                }
            }
        } 
            //else {
        //    e.target.value = '';
        //    this.setState({ OtherAttach: '', OtherAttachErr: 'thisFieldIsRequired' });
        //}
    };

    submitHandler = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        this.checkValidations();
    };

    clearForm = () => {
        this.setState({
            employeeContract: '',
            flatContract: '',
            entityFormalRequest: '',
            supportRequest: '',
            FamilyBook:'',
            Passport:'',
            EID:'', OtherAttach:''
        });
    }

    prev = () => {
        this.props.getPrev.stepTwoDataPrev({
            entityFormalRequest: this.state.entityFormalRequest,
            supportRequest: this.state.supportRequest,
            supportRequestShortName: this.state.supportRequestShortName,
            employeeContract: this.state.employeeContract,
            flatContract: this.state.flatContract,
            OtherAttach:this.state.OtherAttach,
            FamilyBook:this.state.FamilyBook,
            Passport:this.state.Passport,
            EID:this.state.EID
        });
    };

    handlePreViewNavigation = () => {
        this.props.previewPage({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            employeeContract: this.state.employeeContract,
            flatContract: this.state.flatContract,
            entityFormalRequest: this.state.entityFormalRequest,
            supportRequest: this.state.supportRequest,
            supportRequestShortName: this.state.supportRequestShortName,
            OtherAttach:this.state.OtherAttach,
            FamilyBook:this.state.FamilyBook,
            Passport:this.state.Passport,
            EID:this.state.EID
        });
    }

    saveAsDraft = () => {
        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            jobContract: this.state.jobContract,
            employeeContract: this.state.employeeContract,
            flatContract: this.state.flatContract,
            entityFormalRequest: this.state.entityFormalRequest,
            supportRequest: this.state.supportRequest,
            supportRequestShortName: this.state.supportRequestShortName,
            OtherAttach:this.state.OtherAttach,
            FamilyBook:this.state.FamilyBook,
            Passport:this.state.Passport,
            EID:this.state.EID
        });
    };

    render() {
        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate>
                <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse", marginTop: '50px' }}>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="supportRequest"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.support_request}
                                <span style={{ color: "red" }}> *</span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="file1"
                                        type="file"
                                        name="supportRequest"
                                        multiple
                                        onChange={event => this.attachFileSupportFileChange(event)}
                                    />
                                    <label
                                        htmlFor="file1"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic ? "row" : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="file1"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}>
                                        {/* <span>{this.state.supportRequest}</span> */}

                                       {/* <span className={`${this.props.isArabic ? "attachmentNameForArebic" : "attachmentName"}`}>{this.state.supportRequestShortName}</span>
                                        <span className="attachmentShortName">
                                            {
                                                this.state.supportRequestShortName ?
                                                    <MDBPopover
                                                        placement="bottom"
                                                        popover
                                                        clickable
                                                        id="popper12"
                                                        component="label"
                                                        popoverBody={i18n[this.getLocale()].viewMore}
                                                        size="sm"
                                                    >
                                                        <MDBPopoverBody
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "space-around"
                                                            }}>
                                                            <span>{this.state.supportRequest}</span>
                                                        </MDBPopoverBody>
                                                    </MDBPopover>
                                                    :
                                                    ''
                                            }
                                        </span>
                                    </label>
                                </div>
                                {
                                    this.state.supportRequestErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.supportRequestErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.supportRequestErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.supportRequestErr === 'fileLimitErr' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].fileLimitErr}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowedMultipleFile}
                            </p>

                        </div>
                        {
                            (this.state.supportRequestArray &&
                                this.state.supportRequestArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.supportRequestArray} />
                                : <span></span>
                        }
                    </MDBCol> */}

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="Entityformalrequest"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].viewDetails.ADHousingPassport}
                                <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="Passportfile"
                                        type="file"
                                        name="Passport"
                                        onChange={event => this.passportcopyFileChange(event)}
                                    />
                                    <label
                                        htmlFor="Passportfile"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                        style={{
                                            display: "flex",
                                            flexDirection: this.props.isArabic
                                                ? "row"
                                                : "row-reverse"
                                        }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="Passportfile"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        {this.state.Passport}
                                    </label>
                                </div>
                                {
                                    this.state.PassportErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.PassportErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.PassportErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                        </div>
                        {
                            (this.state.PassportArray &&
                                this.state.PassportArray.length > 0)
                                ? <DownloadComponent filesObj={this.state.PassportArray} />
                                : <span></span>
                        }
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="EID"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                }}>
                                {i18n[this.getLocale()].viewDetails.ADHousingEID}
                                <span style={{ color: "red" }}> *</span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="EID"
                                        id="EID"
                                        className="input-default-js"
                                        onChange={(event) => this.EIDFileChange(event)}
                                        required
                                    />
                                    <label
                                        htmlFor="EID"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                        {i18n[this.getLocale()].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="EID"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.EID}</span>
                                    </label>
                                </div>
                                {
                                    this.state.EIDErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.EIDErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.EIDErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.EIDArray &&
                                        this.state.EIDArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.EIDArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>


                </MDBRow>
  <MDBRow className="formRow">
                                 <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="FamilyBook"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                }}
                            >
                                {i18n[this.getLocale()].viewDetails.ADHousingFamilyBook}
                            </label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="FamilyBook"
                                        id="FamilyBook"
                                        className="input-default-js"
                                        onChange={(event) => this.familybooknumberFileChange(event)} required />

                                    <label htmlFor="FamilyBook" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                    <label
                                        htmlFor="FamilyBook"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.FamilyBook}</span>
                                    </label>
                                </div>
                                {
                                    this.state.FamilyBookErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.FamilyBookErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.FamilyBookArray &&
                                        this.state.FamilyBookArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.FamilyBookArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="formFinancialStatement"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                }}>
                                {i18n[this.getLocale()].employeeContract}
                                <span style={{ color: "red" }}> *</span>
                            </label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="employeeContract"
                                        id="employeeContract"
                                        className="input-default-js"
                                        onChange={(event) => this.employeeContractFileChange(event)}
                                        required
                                    />
                                    <label
                                        htmlFor="employeeContract"
                                        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                        {i18n[this.getLocale()].chooseFile}
                                    </label>
                                    <label
                                        htmlFor="employeeContract"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.employeeContract}</span>
                                    </label>
                                </div>
                                {
                                    this.state.employeeContractErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.employeeContractErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.employeeContractErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.employeeContractArray &&
                                        this.state.employeeContractArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.employeeContractArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>

           
                </MDBRow>


                <MDBRow className="formRow">
           
          
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].excSteer.OtherAttach}</label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="OtherAttachFile"
                                        type="file"
                                        name="OtherAttach"
                                        onChange={(event) => this.OtherAttachOnChange(event)}
                                        required
                                    />
                                    <label htmlFor="OtherAttachFile" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.props.isArabic ? 'ar' : 'en'].choose_file}</label>
                                    <label htmlFor="OtherAttachFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.OtherAttach}</span></label>
                                </div>

                                {
                                    this.state.OtherAttachErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.OtherAttachErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.OtherAttachArray &&
                                    this.state.OtherAttachArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.OtherAttachArray} />
                                    : <span></span>
                            }

                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="formFinancialStatement"
                                style={{
                                    display: "flex",
                                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                                }}
                            >
                                {i18n[this.getLocale()].flatContract}
      <span style={{ color: "red" }}> *</span>                       </label>
                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        type="file"
                                        name="flatContract"
                                        id="flatContract"
                                        className="input-default-js"
                                        onChange={(event) => this.flatContractFileChange(event)} required />

                                    <label htmlFor="flatContract" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].chooseFile}</label>
                                    <label
                                        htmlFor="flatContract"
                                        className="fileName"
                                        style={{ textAlign: this.props.isArabic ? "right" : "" }}
                                    >
                                        <span>{this.state.flatContract}</span>
                                    </label>
                                </div>
                                {
                                    this.state.flatContractErr == 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }{
                                    this.state.flatContractErr == 'invalidFileType' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.flatContractErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                </p>
                                {
                                    (this.state.flatContractArray &&
                                        this.state.flatContractArray.length > 0)
                                        ? <DownloadComponent filesObj={this.state.flatContractArray} />
                                        : <span></span>
                                }
                            </div>
                        </div>
                    </MDBCol>
               
                </MDBRow>

                <MDBRow className="paddingTopForm" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                 

                </MDBRow>

                <MDBRow className="formRow" style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse' }}>
                    <MDBBtn onClick={this.prev} color="gray" className="previousAndClearBtn">{i18n[this.props.isArabic ? 'ar' : 'en'].previous}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn type="submit" color="primary" className="submitBtn">
                        {i18n[this.props.isArabic ? 'ar' : 'en'].submit}
                    </MDBBtn>
                </MDBRow>
            </form>
        );
    }
}
