import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import LookupForm from '../../components/AdminPanel/LookupForm';
import i18n from '../../i18n';
import { saveLookupPayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';

export default class Lookup extends Component {

    constructor(props) {
        super(props);
        disableBrowserBackButton();
        this.state = {
            loginData: '',
            isLoading: false,
            theme: 'light',
            y: 0,
            isStatusDropdown: false,
            forString: '',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            selectedLookUpValue: "",
            selectedValueArray: [],
            subHeaderString_0: '',
            lookupArray: [
                {
                    'id': 1,
                    'EnglishTitle': 'Request Type',
                    'ArabicTitle': 'نوع الطلب'
                },
                {
                    'id': 2,
                    'EnglishTitle': 'Department',
                    'ArabicTitle': 'قسم، أقسام'
                },
                {
                    'id': 3,
                    'EnglishTitle': 'Employee',
                    'ArabicTitle': 'موظف'
                },
                {
                    'id': 5,
                    'EnglishTitle': 'Status',
                    'ArabicTitle': 'الحالة'
                },
                {
                    'id': 6,
                    'EnglishTitle': 'Reason for SCI',
                    'ArabicTitle': 'سبب اصابات النخاع الشوكي'
                },
                {
                    'id': 7,
                    'EnglishTitle': 'Attachment',
                    'ArabicTitle': 'المرفق'
                },
                {
                    'id': 8,
                    'EnglishTitle': 'Reason for Information',
                    'ArabicTitle': 'سبب المعلومات'
                },
                {
                    'id': 9,
                    'EnglishTitle': 'Reason',
                    'ArabicTitle': 'السبب'
                },
                {
                    'id': 10,
                    'EnglishTitle': 'Sector',
                    'ArabicTitle': 'قطاع'
                },
                {
                    'id': 11,
                    'EnglishTitle': 'Country List',
                    'ArabicTitle': 'قائمة الدول'
                },
                {
                    'id': 12,
                    'EnglishTitle': 'City List',
                    'ArabicTitle': 'قائمة المدينة'
                },
                {
                    'id': 13,
                    'EnglishTitle': 'Industry List',
                    'ArabicTitle': 'قائمة الصناعة'
                },
                {
                    'id': 14,
                    'EnglishTitle': 'Sector List',
                    'ArabicTitle': 'قائمة القطاعات'
                },
                {
                    'id': 15,
                    'EnglishTitle': 'Account Type List',
                    'ArabicTitle': 'قائمة نوع الحساب'
                },
                {
                    'id': 16,
                    'EnglishTitle': 'Reason To Deactive',
                    'ArabicTitle': 'سبب للنشاط'
                },
                {
                    'id': 17,
                    'EnglishTitle': 'Contact Status',
                    'ArabicTitle': 'حالة الاتصال'
                },
                {
                    'id': 18,
                    'EnglishTitle': 'Contact Reason',
                    'ArabicTitle': 'الاتصال السبب'
                },
                {
                    'id': 19,
                    'EnglishTitle': 'Mail template',
                    'ArabicTitle': 'قالب البريد'
                },
                {
                    'id': 20,
                    'EnglishTitle': 'Functional Block Type',
                    'ArabicTitle': 'نوع التسلسل الوظيفي'
                }
            ],
        }
    }

    componentDidMount = () => {
        let tempLoginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: tempLoginData
        })
    }

    componentWillMount = () => {

        if (localStorage.getItem("lookupDropdownValue")) {
            let temp = [...this.state.lookupArray];
            let tempId = localStorage.getItem("lookupDropdownValue");
            let selectedValue = temp.filter(output => {
                return output.id === parseInt(tempId);
            });
            
            // let selectedValue = this.state.lookupArray.filter(word => parseInt(localStorage.getItem("lookupDropdownValue")) === word.id);

            this.setState({
                selectedValueArray: selectedValue,
                selectedLookUpValue: this.state.isArabic ? selectedValue[0].ArabicTitle : selectedValue[0].EnglishTitle
            }, () => {
                if (this.state.selectedLookUpValue === "Reason for SCI" || this.state.selectedLookUpValue === "سبب اصابات النخاع الشوكي" || this.state.selectedLookUpValue === "Reason for Information" || this.state.selectedLookUpValue === "Reason" || this.state.selectedLookUpValue === "سبب المعلومات" || this.state.selectedLookUpValue === "السبب") {
                    this.setState({
                        isStatusDropdown: true
                    })
                }
            })
        } else {
            // TODO
        }

    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    onSubmitData = (objForLookup) => {
      
        let payload = saveLookupPayload(objForLookup);
        let endPoint = 'SaveLookup';
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {

            ApiRequest.sendRequestForPost(url, payload, res => {

                if (res.Status) {

                    if (objForLookup.isEdit) {
                        this.setState({
                            isLoading: false,
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].lookup_updated
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].lookup_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }

                } else {
                    this.setState({
                        isLoading: false,
                        titleAlert: i18n[this.getLocale()].error,
                        messageAlert: i18n[this.getLocale()].error_message
                    }, () => {
                        this.alertSubmit();
                    })
                }

            })

        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }


    render() {

        let sideBarData = [];
        sideBarData = Config.getServiceSideBar('lookUp', this.state.isArabic, this, 0);

        let subHeaderString_0 = i18n[this.getLocale()].adminPanelKeys.createLookup + " " + i18n[this.getLocale()].adminPanelKeys.for;
        let subHeaderTitle = this.state.selectedValueArray.length ? this.state.isArabic ? this.state.selectedValueArray[0].ArabicTitle : this.state.selectedValueArray[0].EnglishTitle : ' ';
 
        return (
            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} goToLandingPage={this.goToLandingPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className='formWhiteContainer'>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>

                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>

                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'lookUp' }) }} isArabic={this.state.isArabic} title={subHeaderString_0 + " " + subHeaderTitle} />

                                </MDBCol>
                            </MDBRow>

                            {/* <MDBCard style={{ marginTop: '1%', marginBottom: '1%' }}>

                                <MDBCardBody className="hratablerow"> */}
                            <LookupForm isArabic={this.state.isArabic} onSubmitData={this.onSubmitData} isStatusDropdown={this.state.isStatusDropdown} />
                            {/* </MDBCardBody></MDBCard> */}

                            <Footer isArabic={this.state.isArabic} />

                        </MDBCol>

                        <SideBar 
                        isSideBarMinimized={this.state.isSideBarMinimized} 
                        items={sideBarData} 
                        isArabic={this.state.isArabic} 
                        // height={'150vh'} 
                        handleNavigation={this.handleNavigation} 
                        />

                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}