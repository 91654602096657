import React from "react";
import { Carousel } from "react-responsive-carousel";

export default () => (
  <Carousel infiniteLoop autoPlay showThumbs={false}   showStatus={false}   >
    <div>
      <img alt="" src={require('./../assets/images/slider/banner.png')} />
    </div>
    {/* <div>
      <img alt="" src={require('./../assets/images/slider/HomePageBanner1.jpg')} />
    </div> */}
    {/* <div>
      <img alt="" src={require('./../assets/images/slider/HomePageBanner2.jpg')} />
    </div>
    <div>
      <img alt="" src={require('./../assets/images/slider/HomePageBanner3.jpg')} />
    </div>
    <div>
      <img alt="" src={require('./../assets/images/slider/HomePageBanner4.jpg')} />
    </div>
    <div>
      <img alt="" src={require('./../assets/images/slider/HomePageBanner5.jpg')} />
    </div> */}
  </Carousel>
);
