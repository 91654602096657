import React, { Component } from 'react';
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBContainer, MDBProgress, MDBBtn } from "mdbreact";
import i18n from './../../i18n';
// import PopOverComponent from './PopOverComponent';
import Config from './../../utils/Config';
import ApiRequest from './../../services/ApiRequest';
import { sr } from 'date-fns/locale';
let moment = require('moment');
export default class WorkShopList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isArabic: this.props.isArabic,
            data: [],
            pathName: '',
            dataRow: [],
            dataColumns: [
            ]
        }
    }
    componentWillUnmount(){
    }
    componentDidMount = () => {
        Config.setIsEditFlag(false);
        Config.setIsPreviewFlag(false);
        Config.setPreviewObj();
        Config.setPreviewAttachmentArray();
        Config.setPreviewckEditorArray();
        let pathName = '';
        if (this.props && this.props.location && this.props.location.state) {
            pathName = this.props.location.state.pathname
        } else {
            pathName = localStorage.getItem('currentPath');
        }
        let loginData = Config.getLocalStorageLoginData();

        if (loginData) {
            this.setState({
                pathName: pathName,
                loginData: loginData,
                entityNameEn: loginData.EntityNameEn,
                entityNameAr: loginData.EntityNameAr
            }, () => {
            }
    )
    }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    render() {
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        } else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }
        return (
            <MDBContainer fluid style={{ paddingTop: "20px", marginBottom: '150px', paddingRight: '0px', paddingLeft: '0px' }}>
               <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse' }}>
                            <MDBCol md="4"></MDBCol>
                            </MDBRow>
                </MDBContainer>
           );
    }
}
