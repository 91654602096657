import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBBtn } from "mdbreact";
import './../../css/style.css';
import i18n from './../../i18n';
import StepsComponent from "./../StepsComponent";
import Config from '../../utils/Config';
import CKEditor from "react-ckeditor-component";

const striptags = require('striptags');

export default class StepTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: false,
            statusid: '',
            enterTheBudget: this.props.sendData.enterTheBudget,
            budgetRelatedDocument: this.props.sendData.budgetRelatedDocument,
            reasonsToPayBonuses: this.props.sendData.reasonsToPayBonuses,
            entityAchivementsForYear: this.props.sendData.entityAchivementsForYear,
            budgetRelatedDocumentFileObj: null,
            totalNumberOfEmployees: this.props.sendData.totalNumberOfEmployees,
            totalNumberWithPrivateContract: this.props.sendData.totalNumberWithPrivateContract,
            totalNumberWithPermenantContract: this.props.sendData.totalNumberWithPermenantContract,
            totalNumberWithnonPrivateContract: this.props.sendData.totalNumberWithnonPrivateContract,
            totalNumberWithnonPermenantContract: this.props.sendData.totalNumberWithnonPermenantContract,
            haveBudget: this.props.sendData.haveBudget,
            haveBudget2: this.props.sendData.haveBudget2,
            explainTheEquation: this.props.sendData.explainTheEquation,
            requestAnnualBonus: this.props.sendData.requestAnnualBonus,
            excellentTotal: this.props.sendData.excellentTotal,
            veryGood: this.props.sendData.veryGood,
            showResultVeryGood: this.props.sendData.showResultVeryGood,
            excellent: this.props.sendData.excellent,
            showResultExcellent: this.props.sendData.showResultExcellent,
            good: this.props.sendData.good,
            showResultGood: this.props.sendData.showResultGood,
            acceptable: this.props.sendData.acceptable,
            showResultAcceptable: this.props.sendData.showResultAcceptable,
            weak: this.props.sendData.weak,
            showResultWeak: this.props.sendData.showResultWeak,
            totalCalculationErr: '',
            requestAnnualBonusErr: '',
            explainTheEquationErr: '',
            privateContractsEmployeeErr: ''
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                haveBudget2: parseInt(editObj.isfixedvaluebonus) === 1 ? "Yes" : "No",
                explainTheEquation: editObj.equation,
                totalNumberWithPrivateContract: editObj.localwithprivate,
                totalNumberWithPermenantContract: editObj.localwithpermanent,
                totalNumberWithnonPrivateContract: editObj.nonlocalwithprivate,
                totalNumberWithnonPermenantContract: editObj.nonlocalwithpermanent,
                totalNumberOfEmployees: editObj.totalemployees,
                excellentTotal: editObj.totalemployeedetails,
                veryGood: editObj.verygoodemployees,
                excellent: editObj.excellentemployees,
                good: editObj.goodemployees,
                acceptable: editObj.acceptableemployees,
                weak: editObj.weakemployees,
            }, () => {
                //Config.setIsEditFlag(false);
                this.calculateExcellentPercentage();
                this.calculateVeryGoodPercentage();
                this.calculateGoodPercentage();
                this.calculateAcceptablePercentage();
                this.calculateWeekPercentage();
            });
        }

        if (this.state.excellent != '') {
            this.calculateExcellentPercentage();
        }
        if (this.state.veryGood != '') {
            this.calculateVeryGoodPercentage();
        }
        if (this.state.good != '') {
            this.calculateGoodPercentage();
        }
        if (this.state.acceptable != '') {
            this.calculateAcceptablePercentage();
        }
        if (this.state.weak != '') {
            this.calculateWeekPercentage();
        }

    }

    onExplainTheEquationChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                explainTheEquationErr: "",
                explainTheEquation: newContent
            });
        }else{
            this.setState({
                explainTheEquationErr: "ckeditorCountErr"
            });
        }
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

        let tempexplainTheEquationErr = "", privateContractsEmployeeErr = '';
        if (this.state.explainTheEquation && this.state.explainTheEquation.trim()) {
            let val = striptags(this.state.explainTheEquation.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempexplainTheEquationErr = 'thisFieldisRequired';
            }
        } else {
            tempexplainTheEquationErr = 'thisFieldisRequired';
        }

        if (!this.state.haveBudget2) {
            privateContractsEmployeeErr = 'thisFieldIsRequired';
        } else {
            privateContractsEmployeeErr = '';
        }

        this.setState({
            explainTheEquationErr: tempexplainTheEquationErr,
            privateContractsEmployeeErr: privateContractsEmployeeErr
        }, () => {

            if (!this.state.explainTheEquationErr) {
                if (this.state.haveBudget2 === 'Yes') {
                    if (this.state.totalNumberOfEmployees && striptags(this.state.explainTheEquation.toString('html')) && this.state.totalNumberWithnonPermenantContract && this.state.totalNumberWithnonPrivateContract && this.state.totalNumberWithPermenantContract && this.state.totalNumberWithPrivateContract &&
                        this.state.haveBudget2 &&
                        this.state.veryGood &&
                        this.state.excellent &&
                        this.state.good &&
                        this.state.showResultAcceptable &&
                        this.state.weak &&
                        !privateContractsEmployeeErr &&
                        !this.state.totalCalculationErr) {
                        this.props.getData.stepTwoData({
                            isEdit: this.state.isEdit,
                            requestId: this.state.requestId,
                            requestAnnualBonus: this.state.requestAnnualBonus,
                            haveBudget: this.state.haveBudget,
                            haveBudget2: this.state.haveBudget2,
                            explainTheEquation: this.state.explainTheEquation,
                            totalNumberWithPrivateContract: this.state.totalNumberWithPrivateContract,
                            totalNumberWithPermenantContract: this.state.totalNumberWithPermenantContract,
                            totalNumberWithnonPrivateContract: this.state.totalNumberWithnonPrivateContract,
                            totalNumberWithnonPermenantContract: this.state.totalNumberWithnonPermenantContract,
                            totalNumberOfEmployees: this.state.totalNumberOfEmployees,
                            excellentTotal: this.state.excellentTotal,
                            veryGood: this.state.veryGood,
                            showResultVeryGood: this.state.showResultVeryGood,
                            excellent: this.state.excellent,
                            showResultExcellent: this.state.showResultExcellent,
                            good: this.state.good,
                            showResultGood: this.state.showResultGood,
                            acceptable: this.state.acceptable,
                            showResultAcceptable: this.state.showResultAcceptable,
                            weak: this.state.weak,
                            showResultWeak: this.state.showResultWeak
                        });
                    }
                }
                else {
                    if (this.state.totalNumberOfEmployees &&
                        striptags(this.state.explainTheEquation) && this.state.totalNumberWithnonPermenantContract && this.state.totalNumberWithnonPrivateContract && this.state.totalNumberWithPermenantContract && this.state.totalNumberWithPrivateContract &&
                        this.state.haveBudget2 &&
                        this.state.veryGood &&
                        this.state.excellent &&
                        this.state.good &&
                        this.state.showResultAcceptable &&
                        this.state.weak &&
                        !this.state.totalCalculationErr) {
                        this.props.getData.stepTwoData({
                            isEdit: this.state.isEdit,
                            requestId: this.state.requestId,
                            requestAnnualBonus: this.state.requestAnnualBonus,
                            haveBudget: this.state.haveBudget,
                            haveBudget2: this.state.haveBudget2,
                            explainTheEquation: this.state.explainTheEquation,
                            totalNumberWithPrivateContract: this.state.totalNumberWithPrivateContract,
                            totalNumberWithPermenantContract: this.state.totalNumberWithPermenantContract,
                            totalNumberWithnonPrivateContract: this.state.totalNumberWithnonPrivateContract,
                            totalNumberWithnonPermenantContract: this.state.totalNumberWithnonPermenantContract,
                            totalNumberOfEmployees: this.state.totalNumberOfEmployees,
                            excellentTotal: this.state.excellentTotal,
                            veryGood: this.state.veryGood,
                            showResultVeryGood: this.state.showResultVeryGood,
                            excellent: this.state.excellent,
                            showResultExcellent: this.state.showResultExcellent,
                            good: this.state.good,
                            showResultGood: this.state.showResultGood,
                            acceptable: this.state.acceptable,
                            showResultAcceptable: this.state.showResultAcceptable,
                            weak: this.state.weak,
                            showResultWeak: this.state.showResultWeak
                        });
                    }
                }
            }

        });

    };

    clearForm = () => {
        this.setState({
            requestAnnualBonus: '',
            haveBudget: '',
            haveBudget2: '',
            explainTheEquation: '',
            totalNumberWithPrivateContract: '',
            totalNumberWithPermenantContract: '',
            totalNumberWithnonPrivateContract: '',
            totalNumberWithnonPermenantContract: '',
            totalNumberOfEmployees: '',
            excellentTotal: '',
            excellentPercentage: '',
            veryGood: '',
            veryGoodPercentage: '',
            excellent: '',
            good: '',
            goodPercentage: '',
            acceptable: '',
            acceptablePercentage: '',
            weak: '',
            weekPercentag: '',
            showResultWeak: ''
        })
    }

    goToPrevious = () => {
        this.props.getPrev.stepTwoDataPrev({
            explainTheEquation: this.state.explainTheEquation.toString('html'),
            totalNumberWithPrivateContract: this.state.totalNumberWithPrivateContract,
            totalNumberWithPermenantContract: this.state.totalNumberWithPermenantContract,
            totalNumberWithnonPrivateContract: this.state.totalNumberWithnonPrivateContract,
            totalNumberWithnonPermenantContract: this.state.totalNumberWithnonPermenantContract,
            totalNumberOfEmployees: this.state.totalNumberOfEmployees,
            excellentTotal: this.state.excellentTotal,
            veryGood: this.state.veryGood,
            showResultVeryGood: this.state.showResultVeryGood,
            excellent: this.state.excellent,
            showResultExcellent: this.state.showResultExcellent,
            good: this.state.good,
            showResultGood: this.state.showResultGood,
            acceptable: this.state.acceptable,
            showResultAcceptable: this.state.showResultAcceptable,
            weak: this.state.weak,
            showResultWeak: this.state.showResultWeak,
            haveBudget: this.state.haveBudget,
            haveBudget2: this.state.haveBudget2,
        });
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    privateContractsEmployeeOptionChange = (e) => {
        if (e.target.value) {
            this.setState({
                haveBudget2: e.target.value, privateContractsEmployeeErr: ''
            });
        } else {
            this.setState({ privateContractsEmployeeErr: 'thisFieldIsRequired' });
        }
    };

    saveAsDraft = () => {
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            saveFrom: 'step2',
            requestAnnualBonus: this.state.requestAnnualBonus,
            haveBudget: this.state.haveBudget,
            haveBudget2: this.state.haveBudget2,
            explainTheEquation: this.state.explainTheEquation,
            totalNumberWithPrivateContract: this.state.totalNumberWithPrivateContract,
            totalNumberWithPermenantContract: this.state.totalNumberWithPermenantContract,
            totalNumberWithnonPrivateContract: this.state.totalNumberWithnonPrivateContract,
            totalNumberWithnonPermenantContract: this.state.totalNumberWithnonPermenantContract,
            totalNumberOfEmployees: this.state.totalNumberOfEmployees,
            excellentTotal: this.state.excellentTotal,
            veryGood: this.state.veryGood,
            showResultVeryGood: this.state.showResultVeryGood,
            excellent: this.state.excellent,
            showResultExcellent: this.state.showResultExcellent,
            good: this.state.good,
            showResultGood: this.state.showResultGood,
            acceptable: this.state.acceptable,
            showResultAcceptable: this.state.showResultAcceptable,
            weak: this.state.weak,
            showResultWeak: this.state.showResultWeak,
            isEdit: this.state.isEdit,
            requestId: this.state.requestId
        });
    };

    totalNumberWithPrivateContractChange = (event) => {
        let regex = /^[0-9]{0,25}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalNumberWithPrivateContract: event.target.value }, () => {
                this.checkTotalNumberOfEmp();
            });
        }
        else {
            return;
        }
    };

    totalNumberWithnonPrivateContractChange = (event) => {
        let regex = /^[0-9]{0,25}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalNumberWithnonPrivateContract: event.target.value }, () => {
                this.checkTotalNumberOfEmp();
            });
        } else {
            return;
        }
    };

    totalNumberWithPermenantContractChange = (event) => {
        let regex = /^[0-9]{0,25}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalNumberWithPermenantContract: event.target.value }, () => {
                this.checkTotalNumberOfEmp();
            });
        }
        else {
            return;
        }
    };

    totalNumberWithnonPermenantContractChange = (event) => {
        let regex = /^[0-9]{0,25}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalNumberWithnonPermenantContract: event.target.value }, () => {
                this.checkTotalNumberOfEmp();
            });
        }
        else {
            return;
        }
    };

    checkTotalNumberOfEmp = () => {

        //Check Total Number of Employees = (Enter Number of non-local with Private Contract) + (Enter Number of local with Private Contract) + (Enter Number of non local with Permenant Contract) + (Enter Number of local with Permenant Contract)
        let totalNoOfEmp = 0;
        let totalNoOfNonPrivate = 0;
        let totalNoOfWithPrivate = 0;
        let totalNoOfNonPermanent = 0;
        let totalNoOfWithPermanent = 0;

        if (parseFloat(this.state.totalNumberOfEmployees) > 0) {
            totalNoOfEmp = parseFloat(this.state.totalNumberOfEmployees);
        }

        if (parseFloat(this.state.totalNumberWithnonPrivateContract) > 0) {
            totalNoOfNonPrivate = parseFloat(this.state.totalNumberWithnonPrivateContract);
        }
        if (parseFloat(this.state.totalNumberWithPrivateContract) > 0) {
            totalNoOfWithPrivate = parseFloat(this.state.totalNumberWithPrivateContract);
        }
        if (parseFloat(this.state.totalNumberWithnonPermenantContract) > 0) {
            totalNoOfNonPermanent = parseFloat(this.state.totalNumberWithnonPermenantContract);
        }
        if (parseFloat(this.state.totalNumberWithPermenantContract) > 0) {
            totalNoOfWithPermanent = parseFloat(this.state.totalNumberWithPermenantContract);
        }

        let totalCalculation = 0;
        totalCalculation = totalNoOfNonPrivate + totalNoOfWithPrivate + totalNoOfNonPermanent + totalNoOfWithPermanent;

        if (totalCalculation === totalNoOfEmp) {
            this.setState({ totalCalculationErr: '' });
        } else {
            this.setState({ totalCalculationErr: 'totalCalculationErr' }, () => {
                return false;
            });
        }
    };

    onChangeExcellentTotal = (event) => {

        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ excellentTotal: event.target.value }, () => {
                if (!this.state.totalNumberOfEmployees) {
                    this.setState({ showResultExcellent: false })
                } else {
                    this.setState({ showResultExcellent: true })
                }
            });
        }
        else {
            return;
        }

    }

    onChangeExcellent = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ excellent: event.target.value }, () => {
                if (!this.state.totalNumberOfEmployees) {
                    this.setState({ showResultExcellent: false })
                } else {
                    this.setState({ showResultExcellent: true }, () => {
                        this.calculateExcellentPercentage();
                    });
                }
                this.checkGradeEmpAddition();
            });
        }
        else {
            return;
        }
    }

    onChangeVeryGood = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ veryGood: event.target.value }, () => {
                if (!this.state.totalNumberOfEmployees) {
                    this.setState({ showResultVeryGood: false })
                } else {
                    this.setState({ showResultVeryGood: true }, () => {
                        this.calculateVeryGoodPercentage();
                    });
                }
                this.checkGradeEmpAddition();
            });
        }
        else {
            return;
        }
    }

    onChangeGood = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ good: event.target.value }, () => {
                if (!this.state.totalNumberOfEmployees) {
                    this.setState({ showResultGood: false })
                } else {
                    this.setState({ showResultGood: true }, () => {
                        this.calculateGoodPercentage();
                    });
                }
                this.checkGradeEmpAddition();
            });
        }
        else {
            return;
        }
    }

    onChangeAcceptable = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ acceptable: event.target.value }, () => {
                if (!this.state.totalNumberOfEmployees) {
                    this.setState({ showResultAcceptable: false })
                } else {
                    this.setState({ showResultAcceptable: true }, () => {
                        this.calculateAcceptablePercentage();
                    });
                }
                this.checkGradeEmpAddition();
            });
        }
    }

    onChangeWeak = (event) => {
        let regex = /^[0-9]{0,4}$/;
        if (regex.test(event.target.value)) {
            this.setState({ weak: event.target.value }, () => {
                if (!this.state.totalNumberOfEmployees) {
                    this.setState({ showResultWeak: false })
                } else {
                    this.setState({ showResultWeak: true }, () => {
                        this.calculateWeekPercentage();
                    })
                }
                this.checkGradeEmpAddition();
            });
        }
    }

    // handleRequestAnnualBonusChange = (event) => {
    //     let regex = /^[0-9]{0,2}$/;
    //     let requestAnnualBonus = event.target.value;
    //     if (event.target.value !== '') {
    //         if (regex.test(event.target.value)) {
    //             this.setState({ requestAnnualBonus: event.target.value }, () => {
    //                 if (requestAnnualBonus > 50) {
    //                     this.setState({ requestAnnualBonusErr: "invalidBonusNumber" });
    //                 } else {
    //                     this.setState({ requestAnnualBonusErr: '' });
    //                 }
    //             });
    //         }
    //     } else {
    //         this.setState({ requestAnnualBonus: '', requestAnnualBonusErr: i18n[this.getLocale()].thisFieldIsRequired });
    //     }
    // };

    handleTotalNumberOfEmpChange = (event) => {
        let regex = /^[0-9\b]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ totalNumberOfEmployees: event.target.value }, () => {

                let totalNoOfNonPrivate = 0;
                let totalNoOfWithPrivate = 0;
                let totalNoOfNonPermanent = 0;
                let totalNoOfWithPermanent = 0;

                let veryGoodEmp = 0;
                let excellentEmp = 0;
                let goodEmp = 0;
                let acceptableEmp = 0;
                let weakEmp = 0;

                if (parseFloat(this.state.totalNumberWithnonPrivateContract) > 0) {
                    totalNoOfNonPrivate = parseFloat(this.state.totalNumberWithnonPrivateContract);
                }
                if (parseFloat(this.state.totalNumberWithPrivateContract) > 0) {
                    totalNoOfWithPrivate = parseFloat(this.state.totalNumberWithPrivateContract);
                }
                if (parseFloat(this.state.totalNumberWithnonPermenantContract) > 0) {
                    totalNoOfNonPermanent = parseFloat(this.state.totalNumberWithnonPermenantContract);
                }
                if (parseFloat(this.state.totalNumberWithPermenantContract) > 0) {
                    totalNoOfWithPermanent = parseFloat(this.state.totalNumberWithPermenantContract);
                }

                if (totalNoOfNonPrivate > 0 || totalNoOfWithPrivate > 0 || totalNoOfNonPermanent > 0 || totalNoOfWithPermanent > 0) {
                    this.checkTotalNumberOfEmp();
                }

                if (parseFloat(this.state.veryGood) > 0) {
                    veryGoodEmp = parseFloat(this.state.veryGood);
                }
                if (parseFloat(this.state.excellent) > 0) {
                    excellentEmp = parseFloat(this.state.excellent);
                }
                if (parseFloat(this.state.good) > 0) {
                    goodEmp = parseFloat(this.state.good);
                }
                if (parseFloat(this.state.acceptable) > 0) {
                    acceptableEmp = parseFloat(this.state.acceptable);
                }
                if (parseFloat(this.state.weak) > 0) {
                    weakEmp = parseFloat(this.state.weak);
                }

                if (veryGoodEmp > 0 || excellentEmp > 0 || goodEmp > 0 || acceptableEmp > 0 || weakEmp > 0) {
                    this.checkGradeEmpAddition();
                }
            });
        }

    };

    checkGradeEmpAddition = () => {
        //Check Total Number of Employees = (Enter Excelent employees) + (Enter Very Good employees) + (Enter Good employees) + (Enter Acceptable employees) + (Enter Weak employees)
        let totalNoOfEmp = 0;
        let veryGoodEmp = 0;
        let excellentEmp = 0;
        let goodEmp = 0;
        let acceptableEmp = 0;
        let weakEmp = 0;

        if (parseFloat(this.state.totalNumberOfEmployees) > 0) {
            totalNoOfEmp = parseFloat(this.state.totalNumberOfEmployees);
        }
        if (parseFloat(this.state.veryGood) > 0) {
            veryGoodEmp = parseFloat(this.state.veryGood);
        }
        if (parseFloat(this.state.excellent) > 0) {
            excellentEmp = parseFloat(this.state.excellent);
        }
        if (parseFloat(this.state.good) > 0) {
            goodEmp = parseFloat(this.state.good);
        }
        if (parseFloat(this.state.acceptable) > 0) {
            acceptableEmp = parseFloat(this.state.acceptable);
        }
        if (parseFloat(this.state.weak) > 0) {
            weakEmp = parseFloat(this.state.weak);
        }

        let totalEmp = 0;
        totalEmp = veryGoodEmp + excellentEmp + goodEmp + acceptableEmp + weakEmp;

        if (totalEmp === totalNoOfEmp) {
           
            this.setState({ totalCalculationErr: '' });
        } else {
           
            //throw error
            this.setState({ totalCalculationErr: 'totalEmpCulationErr' }, () => {
                return false;
            });

        }
    };

    calculateExcellentPercentage = () => {
        let tempExcellentPercentage = (this.state.excellent / this.state.totalNumberOfEmployees * 100).toFixed(2);
        this.setState({
            showResultExcellent: true,
            excellentPercentage: tempExcellentPercentage
        })
    }

    calculateVeryGoodPercentage = () => {
        let tempVeryGoodPercentage = (this.state.veryGood / this.state.totalNumberOfEmployees * 100).toFixed(2);
        this.setState({
            showResultVeryGood: true,
            veryGoodPercentage: tempVeryGoodPercentage
        })
    }

    calculateGoodPercentage = () => {
        let tempGoodPercentage = (this.state.good / this.state.totalNumberOfEmployees * 100).toFixed(2);
        this.setState({
            showResultGood: true,
            goodPercentage: tempGoodPercentage
        })
    }

    calculateAcceptablePercentage = () => {
        let tempAcceptablePercentage = (this.state.acceptable / this.state.totalNumberOfEmployees * 100).toFixed(2);
        this.setState({
            showResultAcceptable: true,
            acceptablePercentage: tempAcceptablePercentage
        })
    }

    calculateWeekPercentage = () => {
        let tempWeelPercentage = (this.state.weak / this.state.totalNumberOfEmployees * 100).toFixed(2);
        this.setState({
            showResultWeak: true,
            weekPercentage: tempWeelPercentage
        })
    }

    render() {

        return (

            <form
                style={{ marginBottom: '150px', paddingTop: '20px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={["Step1", "Step2", "Step3"]}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left', paddingTop: '20px' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <label htmlFor="formHavebudget">{i18n[this.getLocale()].totalEmployeesOfEntity}</label>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formTotalNumberOfEmployees" >{i18n[this.getLocale()].totalNumberOfEmployees}
                                <span className="requireStar">*</span>
                            </label>

                            <input
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                type="text"
                                className="form-control"
                                id="totalNumberOfEmployees"
                                name="totalNumberOfEmployees"
                                maxLength="50"
                                onChange={this.handleTotalNumberOfEmpChange}
                                value={this.state.totalNumberOfEmployees}
                                required
                            />
                            <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>

                            {
                                this.state.totalCalculationErr === 'totalCalculationErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].totalCalculationErr}
                                    </div>
                                    :
                                    null
                            }

                        </div>

                    </MDBCol>
                </MDBRow>

                {/* Private / Permenant table */}

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">

                        <MDBTable bordered style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}>
                            <MDBTableBody>
                                <tr>
                                    <th></th>
                                    <th>{i18n[this.getLocale()].permanentContract}</th>
                                    <th>{i18n[this.getLocale()].privateContract}</th>
                                </tr>
                                <tr>
                                    <td>{i18n[this.getLocale()].locals}<span className="requireStar">*</span></td>
                                    <td>
                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text"
                                            className="form-control" id="totalNumberWithPermenantContract" name="totalNumberWithPermenantContract"
                                            onChange={this.totalNumberWithPermenantContractChange} value={this.state.totalNumberWithPermenantContract}
                                            required
                                        />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="totalNumberWithPrivateContract" name="totalNumberWithPrivateContract" maxLength="50" onChange={this.totalNumberWithPrivateContractChange} value={this.state.totalNumberWithPrivateContract}
                                            required
                                        />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{i18n[this.getLocale()].nonLocals}<span className="requireStar">*</span></td>
                                    <td>
                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                            type="text"
                                            className="form-control"
                                            id="totalNumberWithnonPermenantContract" name="totalNumberWithnonPermenantContract"
                                            maxLength="50"
                                            onChange={this.totalNumberWithnonPermenantContractChange}
                                            value={this.state.totalNumberWithnonPermenantContract}
                                            required
                                        />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        <input
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                            type="text"
                                            className="form-control"
                                            id="totalNumberWithnonPrivateContract" name="totalNumberWithnonPrivateContract"
                                            maxLength="50"
                                            onChange={this.totalNumberWithnonPrivateContractChange}
                                            value={this.state.totalNumberWithnonPrivateContract}
                                            required
                                        />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        {i18n[this.getLocale()].bonusNote}
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        {
                            this.state.totalCalculationErr === 'totalEmpCulationErr' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].totalEmpCulationErr}
                                </div>
                                :
                                null
                        }
                    </MDBCol>
                </MDBRow>

                <br />

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">

                        <MDBTable bordered style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}>
                            <MDBTableBody>
                                <tr>
                                    <th>{i18n[this.getLocale()].annualEvaluationForAYearWhichRequestedBonus}</th>
                                    <th>{i18n[this.getLocale()].numberOfEmployeeWhichTheyNotEqual}</th>
                                    <th>{i18n[this.getLocale()].percentage}</th>
                                </tr>

                                <tr>
                                    <td>{i18n[this.getLocale()].excellent}</td>
                                    <td>
                                        <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="excellent" name="excellent" onChange={this.onChangeExcellent} required value={this.state.excellent} />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        {
                                            this.state.showResultExcellent ?
                                                <div className="textGrey" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <p className="textGrey">{this.state.excellentPercentage}%</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>{i18n[this.getLocale()].veryGood}</td>
                                    <td>
                                        <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="veryGood" name="veryGood" onChange={this.onChangeVeryGood} required value={this.state.veryGood} />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        {
                                            this.state.showResultVeryGood ?
                                                <div className="textGrey" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <p className="textGrey">{this.state.veryGoodPercentage}%</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>{i18n[this.getLocale()].good}</td>
                                    <td>
                                        <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="good" name="good" onChange={this.onChangeGood} required value={this.state.good} />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        {
                                            this.state.showResultGood ?
                                                <div className="textGrey" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <p className="textGrey">{this.state.goodPercentage}%</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>{i18n[this.getLocale()].acceptable}</td>
                                    <td>
                                        <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="acceptable" name="acceptable" onChange={this.onChangeAcceptable} required value={this.state.acceptable} />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        {
                                            this.state.showResultAcceptable ?
                                                <div className="textGrey" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <p className="textGrey">{this.state.acceptablePercentage}%</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td>{i18n[this.getLocale()].weak}</td>
                                    <td>
                                        <input style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} type="text" className="form-control" id="weak" name="weak" onChange={this.onChangeWeak} required value={this.state.weak} />
                                        <div className="invalid-feedback">{i18n[this.getLocale()].thisFieldIsRequired}</div>
                                    </td>
                                    <td>
                                        {
                                            this.state.showResultWeak ?
                                                <div className="textGrey" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <p className="textGrey">{this.state.weekPercentage}%</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </td>
                                </tr>

                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label htmlFor="formprivateContractsEmployee" style={{ direction: !this.props.isArabic ? 'ltr' : 'rtl' }}>{i18n[this.getLocale()].privateContractsEmployee}<span className="requireStar">*</span></label>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    name="privateContractsEmployee"
                                    type="radio"
                                    value="Yes"
                                    required
                                    id="privateContractsEmployeeYes"
                                    className="custom-control-input"
                                    checked={this.state.haveBudget2 === 'Yes'}
                                    onChange={this.privateContractsEmployeeOptionChange}
                                />
                                <label className="custom-control-label" htmlFor="privateContractsEmployeeYes">{i18n[this.getLocale()].yes}</label>
                            </div>
                            <div
                                className="custom-control custom-radio custom-control-inline">
                                <input
                                    required
                                    name="privateContractsEmployee"
                                    type="radio"
                                    value="No"
                                    id="privateContractsEmployeeNo"
                                    className="custom-control-input"
                                    checked={this.state.haveBudget2 === 'No'}
                                    onChange={this.privateContractsEmployeeOptionChange}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="privateContractsEmployeeNo">
                                    {i18n[this.getLocale()].no}
                                </label>
                            </div>

                            {
                                this.state.privateContractsEmployeeErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                {/* {this.state.haveBudget2 === 'Yes' ?

                                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                        <div className="form-group">
                                            <label htmlFor="form" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}> {i18n[this.getLocale()].requestAnnualBonus}<span className="requireStar">*</span></label>
                                            <input
                                                style={{ direction: this.props.isArabic ? "rtl" : "ltr", }}
                                                type="text" 
                                                className="form-control"
                                                id="requestAnnualBonus" 
                                                name="requestAnnualBonus"
                                                onChange={this.handleRequestAnnualBonusChange}
                                                required
                                                value={this.state.requestAnnualBonus} />

                                            {
                                                this.state.requestAnnualBonusErr == 'thisFieldIsRequired' ?
                                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                        {i18n[this.getLocale()].thisFieldIsRequired}                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                this.state.requestAnnualBonusErr == 'invalidBonusNumber' ?
                                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                        {i18n[this.getLocale()].invalidBonusNumber}
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>

                                    </MDBCol>

                                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                                    </MDBCol>
                                </MDBRow>

                                :

                                ''
                            } */}

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formDownloadThisExcelFile">{i18n[this.getLocale()].downloadThisExcelFile}</label>
                          <div style={{paddingTop:'30px',paddingBottom:'30px'}}>  <a style={{fontSize:'16px',fontWeight:'bold',color:'#ffffff',backgroundColor:'#40505f',margin:'30px',padding:'20px'}} href="نموذج كشف الموظفين.xlsx" target="_blank">{i18n[this.getLocale()].clickHereToDownload}</a></div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: !this.props.isArabic ? 'row' : 'row-reverse', alignItems: 'right', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="formExplainTheEquation" style={{ direction: this.props.isArabic ? 'ltr' : 'rtl' }}><span className="requireStar">*</span>{i18n[this.getLocale()].explainTheEquation}</label>

                                <div style={{ border: this.state.explainTheEquationErr === 'thisFieldisRequired' ? '1px solid red' : '' }}
                                    className="ckEditorStyle">
                                    {
                                        this.props.isArabic ?
                                            null :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.explainTheEquation}
                                                events={{
                                                    "change": this.onExplainTheEquationChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.explainTheEquation}
                                                events={{
                                                    "change": this.onExplainTheEquationChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            : null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.explainTheEquationErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">{i18n[this.getLocale()].previous}</MDBBtn>
                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].next}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>

            </form>

        );
    }
}