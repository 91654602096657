import React, { Component,useState} from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import {  uploadDocument } from '../../utils/Payload';
import LoadingOverlay from 'react-loading-overlay'
import DatePicker from "react-datepicker";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import TimeInput from 'material-ui-time-picker'
import DownloadComponent from  "../DownloadComponent";
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import moment from 'moment';
// import 'moment-timezone';
// import ReactDOM from 'react-dom';
// import 'rc-time-picker/assets/index.css';
const striptags = require('striptags');
const createDateFromTextValue = value => {
   if(value !=null)
   {
       debugger;
    const splitParts =new Date(value).toTimeString().split(':');
   var x= new Date(2020, 1, 1, splitParts[0], splitParts[1]);
   return x;
   }
   else
   {
       return null;
   }
  }
function SetElement ()
{
const [slectedDate,setSelectedDate]=React.useState(null)
}
export default class WorkShopCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RequireAddAttandanceDetails:false,
            WorkShopTitle:'',
            WorkShopPresArray: [],
            WorkShopPresErr: "",
            AttandanceNumber:"",
            AttandanceNumberErr:"",
            WorkShopPres:'',
            WorkShopPresFileObj: "",
            WorkShopPresUrl:'',
            administrativeProcedures:[],
            WorkShopDiscussPointsErr:'',
WorkShopDiscussPoints:'',
requestId:0,
minDate:'',
        }
this.handleDateOfDirectWork = this.handleDateOfDirectWork.bind(this);
this.fromonChange = this.fromonChange.bind(this);
this.toonChange = this.toonChange.bind(this);
    }
    addView = () => { 
        let obj = {};
        obj.WorkShopLocation='';
        obj.WorkShopDate='';
        obj.WorkShopfrom='';
        obj.WorkShopto='';

        if (this.state.administrativeProcedures.length < 40) {
            this.setState({
                administrativeProcedures: this.state.administrativeProcedures.concat(obj),
                view: this.state.view + 1
            });
        }
    }
    handleRequireAddAttandanceDetails=()=>
    {  this.setState({ RequireAddAttandanceDetails: !this.state.RequireAddAttandanceDetails });

    }
    handleWorkShopTitle = (event) => {
        if (event.target.value.trim() != '')
        
        {
            
           let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
           if (regex.test(event.target.value)) {
               this.setState({ WorkShopTitle: event.target.value });
           }
       } else {
           this.setState({ WorkShopTitle: '' });
       }
    }

    handleAttandanceNumber = (event) => {
        if (event.target.value.trim() != '')
        
        {
           let regex = /^[0-9]+$/;
           if (regex.test(event.target.value)) {
               this.setState({ AttandanceNumber: event.target.value });
           }
       } else {
           this.setState({ AttandanceNumber: '' });
       }
    }

    WorkShopDiscussPointsOnChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);
    
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                WorkShopDiscussPointsErr: "",
                WorkShopDiscussPoints: newContent
            })
        }
        else {
            this.setState({
                WorkShopDiscussPointsErr: "ckeditorCountErr"
            })
        }
    
    };

    WorkShopPresOnChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({ WorkShopPres: '', WorkShopPresErr: 'invalidFileType' });
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getWorkshopMaxFileSize()) {
                    this.setState({ WorkShopPres: e.target.files[0].name, WorkShopPresErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this.setState({
                                WorkShopPresFileObj: reader.result
                            }, () => {
                                Config.setBase64ForWorkShopPres(this.state.WorkShopPresFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    this.setState({ WorkShopPres: '', WorkShopPresErr: 'invalidFileSize' });
                }
            }
        }
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    };
    submitHandler= event =>
     {
        
        // this.setState({
        //     isLoading: true
        // }, () => {});
        event.preventDefault();
        event.target.className += " was-validated";
        let WorkShopTitleErr = "",AttandanceNumberErr="",WorkShopPresErr="",WorkShopDiscussPointsErr="";
        if (this.state.WorkShopPresArray && this.state.WorkShopPresArray.length > 0) {
            WorkShopPresErr = "";
        } else {
            if (!this.state.WorkShopPres) {
                WorkShopPresErr = 'thisFieldIsRequired';
            }
        }
        if (!this.state.WorkShopTitle) {
            WorkShopTitleErr = 'thisFieldIsRequired';
        }
        if (!this.state.AttandanceNumber) {
            AttandanceNumberErr = 'thisFieldIsRequired';
        }
        if (!this.state.WorkShopDiscussPoints) {
            WorkShopDiscussPointsErr = 'thisFieldIsRequired';
        }

        this.setState({
            WorkShopTitleErr: WorkShopTitleErr,
            AttandanceNumberErr: AttandanceNumberErr,
            WorkShopPresErr: WorkShopPresErr,
            WorkShopDiscussPointsErr: WorkShopDiscussPointsErr,
           
    
        }, () => 
        {
            if (!WorkShopTitleErr && !AttandanceNumberErr && !WorkShopPresErr && !WorkShopDiscussPointsErr ) {
                this.submitApiCall();

            }
        });

      }


     Cancel =()=>{
        this.props.history.replace('/WorkShopManage');
     }
     alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.push("/WorkShopManage")
                 
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
     uploadFile = (i, totalCount, resAcompanyPatient, multifileCount) => {
        debugger;
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let isLastFileInMultiUpload = false;
            let obj = {};
    
            if (i === 0) {
                fileObj = Config.getBase64ForWorkShopPres();
                obj.filename = this.state.WorkShopPres;
                obj.filetype = Config.getWorkShopPresId();
                tempBase64 = fileObj;
            }
              
            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 10949;
                if (resAcompanyPatient && resAcompanyPatient.data && resAcompanyPatient.data.id) {
                    reqId = resAcompanyPatient.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resAcompanyPatient.data.id;;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);
    
                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        // if (i === 1) {
                        //     if (isLastFileInMultiUpload) {
                        //         this.uploadFile(i + 1, totalCount, resAcompanyPatient, multifileCount + 1);
                        //     } else {
                        //         this.uploadFile(i, totalCount, resAcompanyPatient, multifileCount + 1);
                        //     }
                        // } else 
                        {
                            this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resAcompanyPatient, 0);
            }
        } else {
            this.setState({
                isLoading: false
            }, () => {
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            })
        }
    }
    SaveWorkShopDates = (id, tempTableData) => {
       
        debugger;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        let newTempTableData = [];
        tempTableData.forEach((item, index) => {

            let SaveWorkShopDatesObject = {};
            SaveWorkShopDatesObject.Date=item.strDate;
            SaveWorkShopDatesObject.WorkShopID=id;
            SaveWorkShopDatesObject.StartTime=item.StartTime;
            SaveWorkShopDatesObject.to=item.to;
            SaveWorkShopDatesObject.location=item.location;
            newTempTableData.push(SaveWorkShopDatesObject);
        })
        return newTempTableData;
    }
     submitApiCall = () => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            Config.setIsEditFlag(false);
            Config.setPreviewObj();
            Config.setPreviewAttachmentArray();
            Config.setPreviewckEditorArray();
        }
        let payload = {};
        let lan = localStorage.getItem('Lang');
        if (lan === 'ar')
            payload.lang = 1;
        else
            payload.lang = 0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (this.state.requestId > 0) {
            payload.id = this.state.requestId;
        } else {
            payload.id = 0;
        }
    
        if (getDataFromLocalStorage) {
    
            payload.CreatedUser = getDataFromLocalStorage.id;
        }
      payload.RequireAddAttandanceDetails=this.state.RequireAddAttandanceDetails;
        payload.Title = this.state.WorkShopTitle;
        payload.DicussionPoint = this.state.WorkShopDiscussPoints;
        payload.AttandeceNumber = this.state.AttandanceNumber;
        let endPoint = 'SaveWorkShop'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                if (resAcompanyPatient.Status) {
                    let tempTableData = this.state.administrativeProcedures;
                  
                    let payloadForPatientSalaryMatrix = this.SaveWorkShopDates(resAcompanyPatient.data.id, tempTableData);
debugger;
                    let endPoint = 'SaveWorkShopDates'
                    let urlForSalaryMatrix = Config.getUrl(endPoint);
                    ApiRequest.sendRequestForPost(urlForSalaryMatrix, payloadForPatientSalaryMatrix, resForSalaryMatrix => {
                        if (resForSalaryMatrix.Status) {
this.uploadFile(0, 1, resAcompanyPatient, 0);
                        }
})
                    
                }
                else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message
                        }, () => {
                            this.alertSubmit();
                        })
                    });
                }
    
            })
        })
     }
     alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    componentDidMount()
    {
    debugger;
        let temp = [];
        let obj = {};
      
        let date=moment()
            .utcOffset('+4')
            .format('YYYY-MM-DD');
        if(Config.getWorkShopItem()&&Config.getWorkShopItem().ID)
        {
            let item = Config.getWorkShopItem();
            //temp = item.workShopDates ? item.workShopDates:[];
            if(!item.workShopDates)
            {
            temp.push(obj);}
debugger;
            item.workShopDates.forEach(element => {
              
                debugger;
            
                // new Date(element.WorkShopDate); 
              //  alert(date);
                let obj = {};
                obj.Date=element.datetimeWorkShopDate;
                obj.strDate=element.WorkShopDate;
               
                obj.StartTime=item.acutalstarttime;
             
              obj.to=item.acutalendtime;
         //   obj.to="10:00";
                obj.location=element.WorkShopLocation;
                temp.push(obj);
           
                
            });
            this.setState(
                { 
                    RequireAddAttandanceDetails:item.RequireAddAttandanceDetails,
                     WorkShopTitle:item.Title,
                     WorkShopDiscussPoints:item.DiscussPoints,
                    requestId:item.ID,
                     administrativeProcedures:temp,
                     AttandanceNumber:item.AttadanceNumber,
                    minDate:date,
                    WorkShopPresArray:item.attachments
                }
                , ()=> {
                    if (this.state.WorkShopPresArray && this.state.WorkShopPresArray.length > 0) {
                        let tempAttactmentArray = [...this.state.WorkShopPresArray];
                        if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                            tempAttactmentArray.forEach((item, index) => {
                                
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;
                                this.setState({
                                    //passportCopy: item.filename,
                                    WorkShopPresUrl: item.aliasfilename,
                                 
                                });
                               
                            }  )
                        }
                    }
                })
          
        }
        else
        {
            temp.push(obj);
        this.setState({
            administrativeProcedures: temp,
            minDate:date
        }, () => {

        });
    }
    }
   
setdate=(date)=>{
    return date;

}

handleDateOfDirectWork(date,index) {
    debugger;
   
let tempobj = this.state.administrativeProcedures;
tempobj[index].strDate=date.toString();
tempobj[index].Date=date;

    this.setState({
   administrativeProcedures:tempobj
    });
}

fromonChange(date,index) {
    let tempobj = this.state.administrativeProcedures;

    tempobj[index].StartTime=date;
   
        this.setState({
       administrativeProcedures:tempobj
        });
    }

    toonChange(date,index) {
        debugger;
        let tempobj = this.state.administrativeProcedures;
        
        tempobj[index].to=date;
        
            this.setState({
           administrativeProcedures:tempobj
            });
        }

    render() {
        // const [startDate, setStartDate] = useState(new Date());
        let element = this.state.administrativeProcedures.map((item, index) =>
        {
            debugger;

            return (
                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', }}><MDBCol xs="1" sm="1" md="1" lg="1" xl="1">

  </MDBCol>
  <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
  <div style={{ width: '80%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} >
                               <DatePicker   
                                    className="form-control"
                                    todayButton={"Today"}
                                    selected={item.Date}
                                    minDate={this.state.minDate}
                                    onBlur={date => (item.Date)}
                                    onChange={(date)=>this.handleDateOfDirectWork(date, index)}
                                    // ref={el => this.onDatepickerRef1(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    //showTimeInput
                                    //required
                                   
                                />
                            </div>
  </MDBCol>
  <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
  <div style={{ width: '80%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} >
  <TimeInput 
          
           onChange={(date)=>this.fromonChange(date, index)}
            value={createDateFromTextValue(item.StartTime)}
        />
                            </div>
  </MDBCol>
  <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
  <div style={{ width: '80%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} >
 
  <TimeInput
       
           onChange={(date)=>this.toonChange(date, index)}
             value={createDateFromTextValue(item.to)}
        />
                            </div>
  
  </MDBCol>
  <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
  <input
                                value={item.location}
                                onChange={(e) => {item.location= e.target.value;}}
                                type="text"
                                id="WorkShopTitle"
                                className="form-control"
                                name="WorkShopTitle"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
  </MDBCol>
  <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
  <MDBBtn
                                    className="gridActionBtn AddNewDateButton"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.addView()}                       >
                                        {i18n[this.getLocale()].WorkShop.AddNewDate}
                                 
                                </MDBBtn>
  </MDBCol> </MDBRow>
                )
            })
        return(
            <LoadingOverlay
            active={this.state.isLoading}
            spinner={true}>
            <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].WorkShop.WorkShopInfo} </a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.Title}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.WorkShopTitle}
                                onChange={this.handleWorkShopTitle}
                                type="text"
                                id="WorkShopTitle"
                                className="form-control"
                                name="WorkShopTitle"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.WorkShopTitleErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                            </div>
                            </MDBCol>
                            
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.AttandanceNumber}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.AttandanceNumber}
                                onChange={this.handleAttandanceNumber}
                                type="text"
                                id="AttandanceNumber"
                                className="form-control"
                                name="AttandanceNumber"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.AttandanceNumberErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                            </div>
                            </MDBCol>
                            
                           </MDBRow>
                           <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                               <div class="form-group txtAr">

<label
style={{
    width: "100%",
    display: "flex",
  flexDirection:'row'
}}
htmlFor="formGroupExampleInput">
 {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.Presentation}

 <span className="requireStar">*</span> 
</label>

<div className="input-group">
<div className="custom-file-inp">
    <input
        id="WorkShopPresFile"
        type="file"
        name="WorkShopPres"
        onChange={event => this.WorkShopPresOnChange(event)}
    />
    <label
        htmlFor="WorkShopPresFile"
        className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
    >
        {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
    </label>
    <label htmlFor="WorkShopPresFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
        <span>{this.state.WorkShopPres}</span>
    </label>
</div>
{
    this.state.WorkShopPresErr === 'thisFieldIsRequired' ?
        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
            {i18n[this.getLocale()].thisFieldIsRequired}
        </div>
        :
        null
}
{
    this.state.WorkShopPresErr === 'invalidFileType' ?
        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
            {i18n[this.getLocale()].invalidFileType}
        </div>
        :
        null
}
{
    this.state.WorkShopPresErr === 'invalidFileSize' ?
        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
            {i18n[this.getLocale()].invalidFileSizeWorkshop}
        </div>
        :
        null
}
<p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
    {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAllWorkshop}
</p>
{
    (this.state.WorkShopPresArray &&
        this.state.WorkShopPresArray.length > 0)
        ? <DownloadComponent filesObj={this.state.WorkShopPresArray} />
        : <span></span>
}
</div>
</div>
</MDBCol>
                           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                               <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.RequireAddAttandanceDetails}</label>
                        <input
                                type="checkbox"
                                onChange={this.handleRequireAddAttandanceDetails}
                                style={{ cursor: 'pointer' }}
                                checked={this.state.RequireAddAttandanceDetails ? true : ''}
                            />
                               </div>
                           </MDBCol>
                           </MDBRow>
                           <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div  className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection:  'row' }} htmlFor="exampleFormControlTextarea3">{ i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.DiscussPoints}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.WorkShopDiscussPointsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                            {
                                        this.props.isArabic ?
                                            null :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.WorkShopDiscussPoints}
                                                events={{
                                                    "change": this.WorkShopDiscussPointsOnChange
                                                }}
                                                config={{ language: "en", height: '40vh'}}
                                            />
                                    }

                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.WorkShopDiscussPoints}
                                                events={{
                                                    "change": this.WorkShopDiscussPointsOnChange
                                                }}
                                                config={{ language: "ar", height: '40vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.WorkShopDiscussPointsErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                </div>
                                </div>
                            </MDBCol>
                       </MDBRow>
  </div></div>    
  <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].WorkShop.WorkShopTimes} </a>
  </li>
</ul>
<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
  <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
  </MDBCol>
  <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
      <h5>{i18n[this.getLocale()].WorkShop.WorkShopDate}</h5>
      </MDBCol> 
      <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
      <h5>{i18n[this.getLocale()].WorkShop.WorkShopTimefrom}</h5>
      </MDBCol>
      <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
      <h5>{i18n[this.getLocale()].WorkShop.WorkShopTimeTo}</h5>
      </MDBCol>
      <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
      <h5>{i18n[this.getLocale()].WorkShop.Location}</h5>
      </MDBCol>
      <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
      </MDBCol>
  </MDBRow>

  {element}
 
  </div> 

<div class="buttonsrow">
<MDBRow style={{ display: "flex", flexDirection: "row" }}>
                    <MDBBtn onClick={this.Cancel} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].WorkShop.Cancel}
                    </MDBBtn>
 <MDBBtn className="submitBtn" type="submit" color="primary">{i18n[this.props.isArabic ? "ar" : "en"].WorkShop.Submit}</MDBBtn>
                </MDBRow>
</div>
                </form>
       </LoadingOverlay>
        )
    }
}