/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBContainer, MDBProgress } from "mdbreact";
import i18n from './../i18n';
import PopOverComponent from './PopOverComponent';
import TableLabel from './TableLabel';
import { dashboardListPayload, getContactPayload, getEntitiesPayload, getExceptionAccompanyPatientForEntityPayload, getAllSciRequestForEntityPayload, getAllInformationRequestForEntityPayload, GetAllhrPoliciesForEntityPayload, GetAllAmendSalaryScaleForEntityPayload, GetAllBounsRequestForEntityPayload, GetAllAmendEmployeeSalaryForEntityPayload, getAllSecondmentForEntityPayload, getAllHousingPolicyForEntityPayload, getMailTemplatePayload, getRolePayload } from './../utils/Payload';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';
import OrganizationStructurePopOverComponent from './OrganizationStructure/OrganizationStructurePopOverComponent';

let moment = require('moment');

export default class DataTable extends Component {

    constructor(props) {
        super(props);
        this.handleLoginKeyUp = this.keyUpHandler.bind(this, 'SearchInput');
        this.state = {
            isAdmin: false,
            entityNameEn: '',
            entityNameAr: '',
            loginData: '',
            isArabic: this.props.isArabic,
            data: [],
            pathName: '',
            popUpRowArray: [],
            actualArray: [],
            dataRow: [],
            requestTypeSelectedValue: "",
            dataColumns: [
            ]
        }
    }

    componentDidMount = () => {
       

        // Set Preview flag/ Edit flag/ Attachment array/ Ck editorarray to false/empty in Config 
        Config.setIsEditFlag(false);
        Config.setIsPreviewFlag(false);
        Config.setPreviewObj();
        Config.setPreviewAttachmentArray();
        Config.setPreviewckEditorArray();

        let pathName = '';
        if (this.props && this.props.location && this.props.location.state) {
            pathName = this.props.location.state.pathname
        } else {
            pathName = localStorage.getItem('currentPath');
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                pathName: pathName,
                loginData: loginData,
                entityNameEn: loginData.EntityNameEn,
                entityNameAr: loginData.EntityNameAr
            }, () => {
                this.callAllApi();
            })
        }

    }

    callAllApi = (index) => {
        let temp = [
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.Requestnumber,
                field: 'Requestnumber',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.Subject,
                field: 'Subject',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].EntityName,
                field: 'entitynameen',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.createdby,
                field: 'createdby',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.status,
                field: 'status',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.step,
                field: 'step',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.createdon,
                field: 'createdon',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.action,
                field: 'action',
                sort: 'asc'
            },
        ];
        if (this.props.serviceType) {
            let status = '';
            if (this.props.statusType) {
                if (this.props.statusType === 'completed') {
                    status = 23;
                } else if (this.props.statusType === 'cancelled') {
                    status = 164;
                } else if (this.props.statusType === 'pending') {
                    status = 38;
                } else if (this.props.statusType === 'onHold') {
                    status = 37;
                } else if (this.props.statusType === 'inProgress') {
                    status = 31;
                }
            }
            if (this.props.serviceType === 'sci') {
                this.callDashboardList(status, Config.getSciRequestTypeId());
            } else if (this.props.serviceType === 'bonusRequest') {
                this.callDashboardList(status, Config.getBonusRequestTypeId());
            } else if (this.props.serviceType === 'amendSalariesScale') {
                this.callDashboardList(status, Config.getAmendSalaryScaleRequestTypeId());
            } else if (this.props.serviceType === 'amendEmployeeSalary') {
                this.callDashboardList(status, Config.getAmendEmployeeSalaryRequestTypeId());
            } else if (this.props.serviceType === 'infoRequest') {
                this.callDashboardList(status, Config.getInformationRequestTypeId());
            } else if (this.props.serviceType === 'exceptionForPatient') {
                this.callDashboardList(status, Config.getExceptionAcompanypatientRequestTypeId());
            } else if (this.props.serviceType === 'reviewHRPoliciesLable') {
                this.callDashboardList(status, Config.getHrReviewPolicyRequestTypeId());
            } else if (this.props.serviceType === 'exceptionADHousing') {
                this.callDashboardList(status, Config.getExceptionADHousingRequestTypeId());
            } else if (this.props.serviceType === 'secondmentForFederal') {
                this.callDashboardList(status, Config.getSecondmentForFederalAgencyRequestTypeId());
            } else if (this.props.serviceType === 'retirment') {
                this.callDashboardList(status, Config.getRetirmentRequestTypeId());
            } else if (this.props.serviceType === 'employeesPerformance') {
                this.callDashboardList(status, Config.getAddAndEditEmployeePerformanceEvaluationId());
            } else if (this.props.serviceType === 'extendAnnualPerformance') {
                this.callDashboardList(status, Config.getExtendAnnualPerformaceEvaluationId());
            } else if (this.props.serviceType === 'extendCreateObjectives') {
                this.callDashboardList(status, Config.getExtendCreateObjectiveId());
            } else if (this.props.serviceType === 'extendReviewObjectives') {
                this.callDashboardList(status, Config.getExtendReviewObjectiveId());
            } else if (this.props.serviceType === 'joinAndImplement') {
                this.callDashboardList(status, Config.getJoinAndImplementId());
            } else if (this.props.serviceType === 'amendsShared') {
                this.callDashboardList(status, Config.getAmendOnSharedGovermentId());
            } else if (this.props.serviceType === 'openComplainsStage') {
                this.callDashboardList(status, Config.getOpenComplainsStageId());
            } else if (this.props.serviceType === 'reportsAndStaticRequest') {
                this.callDashboardList(status, Config.getReportAndStaticId());
            } else if (this.props.serviceType === 'otherRequests') {
                this.callDashboardList(status, Config.getOtherRequest());
            }
        }

        this.setState({
            // pathName: pathName,
            dataColumns: temp,
        });

    }

    callDashboardList = (status, requestType) => {
        let payload = dashboardListPayload(status, requestType);
        let endPoint = 'dashboardlist'
        let url = Config.getUrl(endPoint);

        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                console.log(res.data)
                
                debugger;
                let resArray = res.data.items;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];
                        let rowsData = {
                            no: index + 1,
                            id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            subject: resArray[index].subject, // for SCI
                            requestNo: resArray[index].Requestnumber,
                            reason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    setTimeout(() => {
                        document.getElementById("hraSearch").focus();
                    }, 500);

                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                 
                    //debugger;
                    this.setState({
                        data: resArray,
                        popUpRowArray: resArray,
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    keyUpHandler = (refName, e) => {
        if (this.state.isAdmin) {
            let str = e.target.value.toLowerCase();
            if (str.length > 0) {
                if (this.state.popUpRowArray && this.state.popUpRowArray.length > 0) {
                    //let temp = this.state.data.rows.filter((item) => {
                    let temp = this.state.popUpRowArray.filter((item) => {
                        if ((item.englishName && item.englishName.toLowerCase().indexOf(str) > -1) || (item.arabicName && item.arabicName.toLowerCase().indexOf(str) > -1) || (item.username && item.username.toLowerCase().indexOf(str) > -1) || (item.emailAddress && item.emailAddress.toLowerCase().indexOf(str) > -1) || (item.mobilenumber && item.mobilenumber.toLowerCase().indexOf(str) > -1) || (item.phonenumber && item.phonenumber.toLowerCase().indexOf(str) > -1) || (item.designation && item.designation.toLowerCase().indexOf(str) > -1) || (item.accountType && item.accountType.toLowerCase().indexOf(str) > -1) || (item.sectorname && item.sectorname.toLowerCase().indexOf(str) > -1) || (item.employeenumber && item.employeenumber.toLowerCase().indexOf(str) > -1) || (item.industryname && item.industryname.toLowerCase().indexOf(str) > -1) || (item.cityname && item.cityname.toLowerCase().indexOf(str) > -1) || (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) || (item.EnglishTitle && item.EnglishTitle.toLowerCase().indexOf(str) > -1) || (item.ArabicTitle && item.ArabicTitle.toLowerCase().indexOf(str) > -1) || (item.title && item.title.toLowerCase().indexOf(str) > -1)) {
                            return item;
                        }
                    });

            
                    this.setState({
                        // dataRow: temp
                        popUpRowArray: temp
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    });
                }
            }
            else {
                this.setState({
                    // popUpRowArray: this.state.popUpRowArray
                    // popUpRowArray: this.state.data.rows
                    popUpRowArray: this.state.actualArray
                });
            }
        } else {
            let str = e.target.value.toLowerCase();
            if (str.length > 0) {
                // let temp = this.state.data.rows.filter((item) => {
                let temp = this.state.popUpRowArray.filter((item) => {
                    if ((item.createdby && item.createdby.toLowerCase().indexOf(str) > -1) || (item.Requestnumber && item.Requestnumber.toLowerCase().indexOf(str) > -1) || (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) || (item.status && item.status.toLowerCase().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getFullYear().toString().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getMonth().toString().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getDate().toString().indexOf(str) > -1) || (item.subject && item.subject.toLowerCase().indexOf(str) > -1)) {
                        return item;
                    }
                });

                this.setState({
                    // dataRow: temp
                    popUpRowArray: temp
                }, () => {
                    this.props.updateListData(this.state.data, this.state.popUpRowArray);
                });
            }
            else {
                this.setState({
                    // popUpRowArray: this.state.popUpRowArray
                    // popUpRowArray: this.state.data.rows
                    popUpRowArray: this.state.actualArray
                });
            }
        }
    }

    renderDataTableRows = (item, index) => {
        return (
            <tr style={{ textAlign: "center" }}>
                                <td>
                                <span>{item.Requestnumber}</span>
                                </td>
                                {
                    this.state.pathName === 'SCIRequest' ||
                        this.state.pathName === 'informationRequest' ||
                        this.state.pathName === 'bonusRequest' ||
                        this.state.pathName === 'amendSalaryScreen' ||
                        this.state.pathName === 'amendEmployeeSalary' ||
                        this.state.pathName === 'reviewHRPolicies' ||
                        this.state.pathName === 'exceptionADHousing' ||
                        this.state.pathName === 'secondmentForFederalAgency' ||
                        this.state.pathName === 'exemptionFromAccompanyPatient' ||
                        this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest' ||
                        this.state.pathName === 'extendCreateObjectives' ||
                        this.state.pathName === 'extendAnnualPerformance' ||
                        this.state.pathName === 'extendCreateObjectives' ||
                        this.state.pathName === 'extendReviewObjectives' ||
                        this.state.pathName === 'extendAnnualPerformance' ||
                        this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation' ||
                        this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity' ||
                        this.state.pathName === 'openComplainsStage' ||
                        this.state.pathName === 'amendsSharedGovernmentHRManagement' ||
                        this.state.pathName === 'reportsAndStaticRequest' ||
                        this.state.pathName === 'otherRequests' ||
                        this.state.pathName === 'inprogress' ||
                        this.state.pathName === 'pending' ||
                        this.state.pathName === 'completed' ||
                        this.state.pathName === 'cancelled' ||
                        this.state.pathName === 'onhold' ||
                        this.state.pathName === 'currentUserTasks' ?
                        null
                        :
                        <td>{item.subject}</td>
                }
                              {
                    this.state.pathName === 'informationRequest' ?
                        <td>{item.createdby}</td>
                        :
                        <td><span>{this.props.isArabic ? item.entityarabicname : item.entityenglishname}</span></td>
                }
                
                <td>
                    <span className={
                        item.isviolated === 1
                            ? "statusTxtForViolationGreen"
                            : item.isviolated === 2 ?
                                "statusTxtForViolationYellow"
                                :
                                "statusTxtForViolationRed"
                    }>
                        {item.status}
                    </span>
                    <spna>
                        {
                            item.progresspercent === 0 ?
                                null
                                :
                                <MDBProgress material value={item.progresspercent} height="15px" color="info">
                                    {item.progresspercent}%
                                </MDBProgress>
                        }

                    </spna>
                </td>
                {
                    this.props.isInternal === 0 ?
                        null :
                        <td>
                            <span>
                                {item.statusreason}
                            </span>
                            <br />
                            <span className="statusTxtForViolationYellow">
                                {
                                    item.statusreasonid === Config.getReasonForSectorAssignedId() ?
                                        item.assignedsectorname
                                        :
                                        (item.statusreasonid === Config.getReasonForDepartmentAssignedId() || item.statusreasonid === Config.getReasonForStudyProvidedId()) ?
                                            item.assigneddepartmentname
                                            :
                                            (item.statusreasonid === Config.getReasonForEmployeeAssignedId() || item.statusreasonid === Config.getReasonForStudyProvidedId()) ?
                                                item.assignedemployeename
                                                :
                                                null
                                }
                            </span>
                        </td>
                }
                <td>{item.createdon}</td>

                
                <td>
                    <span
                        onClick={(e) => {
                            this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                        }}
                        id={"checkbox" + index}>
                                      {/* data={this.state.popUpRowArray[index]} }
                                      />  */}
                           {localStorage.getItem("currentPath") === "OrganizationStructure"
                            ?
                            <OrganizationStructurePopOverComponent
                            changeState={this.props.changeState}
                            manageAction={this.props.manageAction}
                            y={this.props.y}
                            isArabic={this.state.isArabic}
                            index={index}
                            data={this.state.popUpRowArray[index]} />
                           :
                            <PopOverComponent
                            manageAction={this.props.manageAction}
                            y={this.props.y}
                            isArabic={this.state.isArabic}
                            index={index}
                            data={this.state.popUpRowArray[index]} />
                                    }
                    </span>
                </td>
                </tr>
       )
    }

    render() {

        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        } else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBContainer fluid style={{ paddingTop: "20px" }}>
                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse' }}>
                    {/* <MDBCol md="8"></MDBCol> */}
                    <MDBCol md="12">

                  
                        {/* <input class="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search} onChange={this.handleLoginKeyUp} ref="SearchInput" /> */}
                        <input className="form-control hraSearch" id="hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search} onChange={this.handleLoginKeyUp} ref="SearchInput" />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="padTop20">
                    <MDBCol md="12">
                        <MDBTable striped responsive hover style={{ marginBottom: '80px', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                            <MDBTableHead>
                                <tr className="tableHeader">
                                    {
                                        this.state.dataColumns ? this.state.dataColumns.map((item, index) => {
                                      
                                            if (this.props.isInternal === 0 && item.field === 'step') {
                                                return '';
                                            }

                                            if ((this.state.pathName === 'informationRequest' ||
                                                this.state.pathName === 'bonusRequest' ||
                                                this.state.pathName === 'SCIRequest' ||
                                                this.state.pathName === 'amendSalaryScreen' ||
                                                this.state.pathName === 'amendEmployeeSalary' ||
                                                this.state.pathName === 'reviewHRPolicies' ||
                                                this.state.pathName === 'exceptionADHousing' ||
                                                this.state.pathName === 'inprogress' ||
                                                this.state.pathName === 'pending' ||
                                                this.state.pathName === 'completed' ||
                                                this.state.pathName === 'cancelled' ||
                                                this.state.pathName === 'onhold' ||
                                                this.state.pathName === 'currentUserTasks' ||
                                                this.state.pathName === 'secondmentForFederalAgency' ||
                                                this.state.pathName === 'exemptionFromAccompanyPatient' ||
                                                this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest' ||
                                                //Data Services
                                                this.state.pathName === 'extendCreateObjectives' ||
                                                this.state.pathName === 'extendReviewObjectives' ||
                                                this.state.pathName === 'extendAnnualPerformance' ||
                                                this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation' ||
                                                this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity' ||
                                                this.state.pathName === 'openComplainsStage' ||
                                                this.state.pathName === 'amendsSharedGovernmentHRManagement' ||
                                                this.state.pathName === 'reportsAndStaticRequest' ||
                                                this.state.pathName === 'otherRequests')
                                                && item.field === 'Subject') {
                                                return '';
                                            }

                                            if ((
                                                this.state.pathName === 'SCIRequest' ||
                                                this.state.pathName === 'bonusRequest' ||
                                                this.state.pathName === 'amendSalaryScreen' ||
                                                this.state.pathName === 'amendEmployeeSalary' ||
                                                this.state.pathName === 'reviewHRPolicies' ||
                                                this.state.pathName === 'exceptionADHousing' ||
                                                this.state.pathName === 'secondmentForFederalAgency' ||
                                                this.state.pathName === 'exemptionFromAccompanyPatient' ||
                                                this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest' ||
                                                //Data Services
                                                this.state.pathName === 'extendCreateObjectives' ||
                                                this.state.pathName === 'extendReviewObjectives' ||
                                                this.state.pathName === 'extendAnnualPerformance' ||
                                                this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation' ||
                                                this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity' ||
                                                this.state.pathName === 'openComplainsStage' ||
                                                this.state.pathName === 'amendsSharedGovernmentHRManagement' ||
                                                this.state.pathName === 'reportsAndStaticRequest' ||
                                                this.state.pathName === 'otherRequests' ||
                                                // for inbox
                                                this.state.pathName === 'inprogress' ||
                                                this.state.pathName === 'pending' ||
                                                this.state.pathName === 'completed' ||
                                                this.state.pathName === 'cancelled' ||
                                                this.state.pathName === 'onhold' ||
                                                this.state.pathName === 'currentUserTasks'
                                            ) && item.field === 'createdby') {
                                                return '';
                                            }

                                            if (this.state.pathName === 'informationRequest' && item.field === 'entitynameen') {
                                                return '';
                                            }
                                            return (
                                                <th>
                                                    <TableLabel
                                                        key={index}
                                                        isArabic={this.props.isArabic}
                                                        field={item.field}
                                                        isAdmin={this.state.isAdmin}
                                                    />
                                                </th>
                                            )
                                        })
                                            :
                                            ''
                                    }
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {/* {
                                    this.state.dataRow ? this.state.dataRow.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                        ''
                                } */}
                                {
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ?
                                        null
                                        :
                                        <tr>
                                            <td align="center" colSpan="12">
                                                <div className="noRecordFound">
                                                    {i18n[this.props.isArabic ? 'ar' : 'en'].error_message_one}
                                                </div>
                                            </td>
                                        </tr>

                                }
                                {
                                    this.state.popUpRowArray ? this.state.popUpRowArray.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                        ''
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}