import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import i18n from '../../i18n';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import LoadingOverlay from 'react-loading-overlay';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import Header from '../../components/Header';
import Config from '../../utils/Config';
import RoleForm from '../../components/AdminPanel/RoleForm';
import { saveRolePayload } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';

export default class Role extends Component {

    constructor(props) {
        super(props);
        disableBrowserBackButton();
        this.state = {
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            isSideBarMinimized: false,
            isEdit: false,
            requestId: ''
        }
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    fileOnChange = (e) => {
      
    };

    onSubmitData = (objForRole) => {
      
        let payload = saveRolePayload(objForRole);
        let endPoint = 'SaveRole';
        let url = Config.getUrl(endPoint);
      
        
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                if (res.Status) {
                    if (objForRole.isEdit) {
                        this.setState({
                            isLoading: false,
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].role_updated
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].role_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                } else {
                    this.setState({
                        isLoading: false,
                        titleAlert: i18n[this.getLocale()].error,
                        messageAlert: i18n[this.getLocale()].error_message
                    }, () => {
                        this.alertSubmit();
                    })
                }
            })
        });
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    render() {

        const sideBarData = Config.getServiceSideBar('role', this.state.isArabic, this, 0);
        return (

            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} goToLandingPage={this.goToLandingPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className='formWhiteContainer'>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'role' }) }} isArabic={this.state.isArabic} title={i18n[this.getLocale()].adminPanelKeys.createRole} />
                                </MDBCol>
                            </MDBRow>

                            {/* <MDBCard style={{ marginTop: '1%', marginBottom: '1%' }}> */}

                            {/* <MDBCardBody className="hratablerow"> */}

                            <RoleForm isArabic={this.state.isArabic} onSubmitData={this.onSubmitData} />

                            {/* </MDBCardBody></MDBCard> */}

                            <Footer isArabic={this.state.isArabic} />

                        </MDBCol>

                        <SideBar 
                        isSideBarMinimized={this.state.isSideBarMinimized} 
                        items={sideBarData} 
                        isArabic={this.state.isArabic} 
                        // height={'450vh'} 
                        handleNavigation={this.handleNavigation} 
                        />

                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}