import React, { Component, Fragment } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CommentComponentWithTable from "./CommentComponentWithTable";
import ListAttachmentWithTable from "./../ListAttachmentWithTable";
import ViewStatusHistoryWithTable from "../ViewRecordComponents/ViewStatusHistoryWithTable";
import "react-tabs/style/react-tabs.css";
import i18n from "./../../i18n";
import Config from './../../utils/Config';
import CKEditor from "react-ckeditor-component";

class ViewRecordComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            pathName: '',
            listHeadings: [],
            listData: [],
            headerArebic: [],
            headerEnglish: [],
            data: [],
            activeIndex: 1,
            canViewComment: 1,
            loginData: '',
            isInternal: 0,
        };
        this.en_keys = [];
        this.enKeys = {};
        this.arKeys = {};
        this.respKeys = [];
    }

    componentDidMount = () => {

        let pathName = '';
        if (this.props && this.props.location && this.props.location.state) {
            pathName = this.props.location.state.pathname
        } else {
            pathName = localStorage.getItem('currentPath');
        }
        let loginData = Config.getLocalStorageLoginData();
        
        this.setState({
            loginData: loginData
        }, () => {
          
            if (pathName) {
                if (pathName === 'SCIRequest') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowscicomments,
                    })
                } else if (pathName === 'informationRequest') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowircomments,
                    })
                } else if (pathName === 'bonusRequest') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowbonuscomments,
                    })
                } else if (pathName === 'amendSalaryScreen') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowamendscalecomments,
                    })
                } else if (pathName === 'amendEmployeeSalary') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowamendsalarycomments,
                    })
                } else if (pathName === 'reviewHRPolicies') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowreviewhrcomments,
                    })
                } else if (pathName === 'secondmentForFederalAgency') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowsecondmentcomments,
                    })
                } else if (pathName === 'exceptionADHousing') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowadhousingcomments,
                        //canViewComment: 1
                    }, () => {
                    })
                } else if (pathName === 'exemptionFromAccompanyPatient') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshowpatientcomments
                    })
                
                }
                else if (pathName === 'OrganizationStructure') {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                        canViewComment: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canshoworgcomments
                    })
                }
                 else {
                    this.setState({
                        isInternal: this.state.loginData && this.state.loginData.internalcontact,
                    })
                }
            }
        })

        //let keys = Object.keys(this.props.data);
        let headerArrayar = [];
        let headerArrayen = [];
        this.enKeys = i18n["en"].viewComponent;
        this.arKeys = i18n["ar"].viewComponent;
        //let ar_keys = Object.keys(this.arKeys);
        this.en_keys = Object.keys(this.enKeys);

        let data = Object.values(this.props.data);
        this.respKeys = Object.keys(this.props.data);
      
        this.setState({
            pathName: pathName,
            headerArebic: headerArrayar,
            headerEnglish: headerArrayen,
            data: data
        });
    };

    toggle = () => {
        this.props.toggle(false);
    };

    render() {
        debugger;
        return (
            <MDBContainer
                style={{
                    display: "flex: ",
                    width: "100%",
                    flexDirection: this.props.isArabic ? "row-reverse" : "row"
                }}>

                <Tabs>
                    <TabList
                        style={{
                            flexDirection: this.props.isArabic ? "row-reverse" : "row",
                            direction: this.props.isArabic ? "rtl" : "ltr",
                            textAlign: this.props.isArabic ? "right" : "left"
                        }}>
                        
                        <Tab
                            onClick={() => {
                                this.setState({
                                    activeIndex: 1
                                });
                            }}
                            style={{
                                backgroundColor:this.state.activeIndex === 1 ? "#ececec" : "#ffffff",
                                border: "1px solid #c0c0c0",
                                marginLeft: 5,
                                marginRight: 5,
                                color: this.state.activeIndex === 1 ? "#444444" : "#b4b4b4",
                                borderRadius: "15px 15px 15px 15px",
                                textAlign: 'center'
                            }}
                        >
                            {
                                i18n[this.props.isArabic ? "ar" : "en"].viewComponent
                                    .attachments
                            }
                        </Tab>

                        {
                            this.state.canViewComment ?
                                <Tab
                                    onClick={() => {
                                        this.setState({
                                            activeIndex: 2
                                        });
                                    }}
                                    //disabled={this.state.canViewComment ? false : true}
                                    style={{
                                        backgroundColor:
                                            this.state.activeIndex === 2 ? "#ececec" : "#ffffff",
                                        border: "1px solid #c0c0c0",
                                        marginLeft: 5,
                                        marginRight: 5,
                                        color: this.state.activeIndex === 2 ? "#444444" : "#b4b4b4",
                                        borderRadius: "15px 15px 15px 15px",
                                        textAlign: 'center'
                                    }}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].viewComponent.comments}
                                </Tab>
                                :
                                null
                        }

                        {
                            this.state.isInternal === 1 ?
                                <Tab
                                    onClick={() => {
                                        this.setState({
                                            activeIndex: 3
                                        });
                                    }}
                                    style={{
                                        backgroundColor:
                                            this.state.activeIndex === 3 ? "#ececec" : "#ffffff",
                                        border: "1px solid #c0c0c0",
                                        marginLeft: 1,
                                        marginRight: 1,
                                        color: this.state.activeIndex === 3 ? "#444444" : "#b4b4b4",
                                        borderRadius: "15px 15px 15px 15px",
                                        // width: '13%', 
                                        textAlign: 'center'

                                    }}
                                >
                                    {
                                        i18n[this.props.isArabic ? "ar" : "en"].viewComponent
                                            .statusHistory
                                    }
                                </Tab>
                                :
                                null
                        }

                    </TabList>

                    <TabPanel>
                        <ListAttachmentWithTable
                            
                            isArabic={this.props.isArabic}
                            data={this.props.Attachments}
                            toggle={this.toggle}
                            id={this.props.data.id}
                            requesttypeid={this.props.data.requesttypeid}
                        />
                    </TabPanel>

                    {
                        this.state.canViewComment ?
                            <TabPanel>
                                <CommentComponentWithTable
                                    isArabic={this.props.isArabic}
                                    comments={this.props.comments}
                                    toggle={this.toggle}
                                    disabled={true}
                                    id={this.props.data.id}
                                    requesttypeid={this.props.data.requesttypeid}
                                    hideCloseBtn={true}
                                    location={this.props.location} />
                            </TabPanel>
                            :
                            null
                    }

                    {
                        this.state.isInternal === 1 ?
                            <TabPanel>
                                <ViewStatusHistoryWithTable
                                    isArabic={this.props.isArabic}
                                    value={this.props.statusHistory}
                                    toggle={this.toggle}
                                    id={this.props.data.id}
                                    requesttypeid={this.props.data.requesttypeid}
                                    targetdate={this.props.data.targetdate}
                                    createdon={this.props.data.createdon}
                                />
                                {/* <ViewStatusHistoryVerticalTimelineContainer /> */}
                            </TabPanel>
                            :
                            null
                    }

                </Tabs>

                <MDBRow
                    style={{
                        display: "flex",
                        justifyContent: this.props.isArabic ? "flex-start" : "flex-end",
                        width: "100%",
                        paddingRight: "1%",
                        paddingLeft: "1%"
                    }}
                >
                    <MDBBtn
                        size="md"
                        style={{ maxHeight: "5vw" }}
                        color="primary"
                        onClick={() => {
                            this.props.toggle(false);
                        }}
                    >
                        {i18n[this.props.isArabic ? "ar" : "en"].update_status.Close}
                    </MDBBtn>

                </MDBRow>

            </MDBContainer>
        );
    }
}

export default ViewRecordComponent;
