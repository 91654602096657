const Locali18n = {
    en:{
        dailyhours:" Daily Work Hours",
        monthlydays:" Monthly Work Days",
        yearlyvacations:"Yearly Vacation In Days",
        rolefrequency:"Role Frequency",
        daily:"Daily",
        monthly:"Monthly",
        yearly:"Yearly",
        orgStructure: {
            attachments : "Attachments",
            finalDesign : "Download Organization Structure Design",
                expandAll: 'Expand All',
                collapseAll: 'Collapse All',
                search: 'Search',
                previous: 'Previous',
                next: 'Next',
                info: 'Info',
                edit: 'Edit',
                addUnit: 'Add Unit',
                addEmployee: 'Add Employee',
                delete: 'Delete',
                addNode: 'Add Node',
                updateNode: 'Update Node',
                treeView: 'Tree Explaining View',
                parent: 'Parent',
                englishName: 'English Name',
                arabicName: 'Arabic Name',
                close: 'Close',
                type: 'Position Type',
                level: 'Level',
            },
            entityName: 'Entity Name',
            requestDetails: 'Request Details',
            requestDate: 'Request Date',
            employeeName: 'Employee Name',
            contactNumber: 'Contact Number',
            email: 'Email',
            organizationStructure: 'Organization Structure',
            chooseFile : 'choose File',
            thisFieldIsRequired :'This Field Is Required',
            invalidFileType :'Invalid File Type',
            invalidFileSize:'Invalid File Size',
            acceptedFormatsPDF : 'accepted Formats PDF',
            RequestReasonFile : 'Request Reason File',
            acceptedFormats_xlsx : 'accepted Formats xlsx',
            otherattachemnt:'other attachemnt',
            EntityEstablishmentLaw:'Entity Establishment Law',
            acceptedFormatsPDF : 'accepted Formats PDF',
            acceptedFormatsORG : 'Accepted Formates PDF,XSL,XSLX,PPT,PPTX,DOC,DOCX',
            acceptedFormatsORGTemplate : 'Accepted Formates PPTX',

            EntityFormalRequest:'Entity Formal Request',
            fileLimitErr:'file Limit Err',
            acceptedFormatsAll : 'accepted Formats All',
            allowedMultipleFile : 'allowed Multiple File',
            preview : 'preview',
            saveAsDraft:'save As Draft',
            submit:'submit',
            //-----------------------------------
            adminRoles:'admin Roles',
            functionalRoles:'Functional Roles',
            organizationalRoles:'Organizational Roles',
            experience:'Experience',
            educationLevel:'Education Level',
            knowledgeAndLevel:'Knowledge and Level',
            externalRelations:'External Relations',
            internalRelations:'Internal Relations',
            organizationalRelations:'Organizational Relations',
            positionGoals:'Position Goals',
            grade:'Grade',
            subgrade:'Sub Grade',
            numberOfPositions:'Positions Number',
            addPosition:'add Position',
            addPositionDetails:'add Position',

            close:'Close',
            addAdminRoles : 'Add Admin Roles',
            
            addOrganizationalRoles : 'Add Organizational Roles',
            addFuncationalRoles : 'Add Functional Roles',
            addFunctionalLoad :'Add Functional Load',
            addunit : 'Add Unit',
            removeNode :'Remove',
            unitdetails : 'Unit Details',
            goals:'Goals',
            responsibilities:'Responsibilities',
            basicinfo :'basic Information',
            goalsandrelations : 'Goals and Relations',
            educationandexperience:'education level and Experience',
            fileupload:'Files Upload',
            dutiesandresponsibilities:'Duties and Responsibilities',
            relations:'Relations',
            UnitesDetails:'Unites Details',
            editunit:'Edit Unit',
            editunitdetails:'Edit Unit Details',
            nodeadded :'Node Added Successfully',
            nodedeleted:'Node Removed Successfully',
            failed : 'Failed',
            processfailed : 'Process Failed',
            positionadded : 'Position Added',
            loadpositions:'Load Positions',
            editPosition : 'Edit Position',
            editPositionDetails : 'Position Details',

            JobEvaluationFile :'Job Evaluation File',
            nodata :'No Data',
            reportto:'Reporting To',
            total:'Grand Total',
            desc:'Description',
            serial:'SN',
            ftetype:' Role Type',
            ftevalue:' Value',
            editPosition : 'Edit Position',
            positiondetails:'Add/Edit Position Details',
            ftesubtotal:'Total',
            time:'Time In Hours',
            frequency:'Frequency',
            positionupdated:' position Updated',
            nodeupdated:'Node Updated',
            unitupdated:'Unit Updated',
            fteUpdated : 'Position FTE Updated ',
            allrolesrequired : 'Administration and functional and organizational role required ',
            jobEvaluationFileName:' Grade Evaluation File Name',
            functionalLoadFileName:'Functional Job Evaluation File Name',
            branch :"branch",

            TasksAndSpecificationsTemplate: "Tasks And Specifications Template",
            OrganizationStrucutreOperationalTemplate: "Organization Strucutre Operational Template",
            SuggestedOrganizationStructure: "Suggested Organization Structure",
            CurrentOrganizationStructure: "Current Organization Structure",
            OrganizationStructureCompleted : "Organization Structure Completed"
    },

    ar:{
        rolefrequency:"تكرار المهمة",
        dailyhours:" ساعات العمل اليومي",
        monthlydays:" عدد ايام العمل في الشهر",
        yearlyvacations:"عدد ايام الاجازة السنوية",
        daily:"يومى",
        monthly:"شهرى",
        yearly:"سنوى",
        orgStructure: {
            attachments : "المرفقات",
            finalDesign : " تحميل تصميم الهيكل",
                expandAll: 'اظهار الكل',
                collapseAll: 'تجمبع الكل',
                search: 'بحث',
                previous: 'سابق',
                next: 'التالى',
                info: 'معلومات',
                edit: 'تصحيح',
                add: 'إضافة',
                delete: 'حذف',
                addNode: 'إضافة العقدة',
                updateNode: 'تحديث العقدة',
                treeView: ' عرض توضيحي للشجرة',
                parent: 'الأبوين',
                englishName: 'الإسم باللغة الانجليزية',
                arabicName: 'الإسم باللغة العربية',
                close: 'غلق',
                type: 'تصنيف الوظيفة',
                level: 'المستوى',
            },
            entityName: 'اسم الجهة',
            requestDetails: 'تفاصيل الطلب',
            requestDate: 'تاريخ الطلب',
            employeeName: 'اسم الموظف',
            contactNumber: 'رقم التواصل',
            email: 'البريد الالكترونى',
            organizationStructure: 'الهيكل التنظيمي',
            chooseFile : 'اختار المرفق',
            thisFieldIsRequired :'هذا الحقل اجبارى',
            invalidFileType :'نوع المرفق غير صحيح',
            invalidFileSize:'حجم المرفق غير مسموح به',
            acceptedFormatsPDF : 'انوا ع المرفقات المسموح بها PDF',
            acceptedFormatsORG : 'انوا ع المرفقات المسموح بها PDF,XSL,XSLX,PPT,PPTX,DOC,DOCX',
            acceptedFormatsORGTemplate : 'انواع المرفقات المسموح بها PPTX',
            RequestReasonFile : ' مرفق سبب الطلب',
            acceptedFormats_xlsx : 'نوع المرفق الممسوح به xlsx',
            otherattachemnt:'مرفقات اخرى',
            EntityEstablishmentLaw:'قانون انشاء الجهه',
            EntityFormalRequest:'الطلب الرسمس للجهه',
            fileLimitErr:'خطاء بحجم المرفق',
            acceptedFormatsAll : 'جميع انواع المرفقات',
            allowedMultipleFile : 'يسمح بتحميل اكثرر من ملف',
            preview : 'اسنعراض',
            saveAsDraft:'حفظ كمسودة',
            submit:'حفظ',
              //-----------------------------------
              adminRoles:'الاوار الادارية',
              functionalRoles:'الادوار الوظيفية',
              organizationalRoles:'الادوار التنظيمية',
              experience:'الخبرة',
              educationLevel:'المستوى التعليمي',
              knowledgeAndLevel:'المستوى المعرفي',
              externalRelations:'العلاقات الخارجية',
              internalRelations:'العلاقات الداخلية',
              organizationalRelations:'العلاقات التنظيمية',
              positionGoals:'الاهداف الوظيفية',
              grade:'الدرجة',
              subgrade:'الدرجة الفرعية',
              numberOfPositions:'عدد الموظفين',
              addPosition:'اضافة وظيفة',
              addPositionDetails:'اضافة تفاصيل وظيفة',

              close:'اغلاق',
              addAdminRoles : 'اضافة ادوار ادارية',
              addOrganizationalRoles : 'اضافة ادوارر تنظيمية',
              addFuncationalRoles : 'اضافة ادوار وظيفية',
              addFunctionalLoad :'احتساب العبء الوظيفي',
              addunit : 'اضافة هيكل ',
              removeNode :'حذف',
              unitdetails : 'تفاصيل الهيكل',
              goals:'الاهداف',
              responsibilities:'المسوليات',
              basicinfo :'المعلومات الاساسية',
              goalsandrelations : 'الاهداف والعلاقات',
              educationandexperience:'المستوى التعليمي والخبرة',
              fileupload:'تحميل مرفق',
              dutiesandresponsibilities:'الواجبات والمسؤليات',
              relations:'العلاقات',
              UnitesDetails:'تفاصيل الهيكل',
              editunit:'تعديل الهيكل',
              editunitdetails:'تعديل تفاصيل الهيكل',
              nodeadded :'تم الاضافة بنجاح',
              nodedeleted:'تم الحذف بنجاح',
              failed : 'فشل',
              processfailed : 'الاجراء فشل',
              positionadded : 'تم اضافة الوظيفيه',
              loadpositions:'تحميل الوظائف',
              editPosition : 'تعديل وظيفة',
              editPositionDetails : 'تفاصيل وظيفة',

              adminrolesadded : 'تم اضافة الادوار الادارية',
              functionalrolesadded:'تم اضافة الادوار الوظيفية',
              organizationalrolesadded:'تم اضافة الاداور التنظيمية',
              JobEvaluationFile :'مرفق التقييم الوظيفى',
              nodata :'لا يوجد بيانات',
              reportto:'المدير المباشر',
              total:'المجموع',
              editPosition : 'تعديل وظيفة',
              positiondetails:'اضافة او تعديل وظيفة',
              ftesubtotal:'الاجمالي',
              desc : "الوصف",
              ftetype : "النوع",
              serial:"التسلسل",
              time:'الوقت المطلوب بالساعة',
              frequency:'عدد المرات',
              positionupdated:' تم تعديل الوظيفة',
              nodeupdated:'تم تعديل الهيكل',
              unitupdated:'تم تعديل الهيكل',
              fteUpdated : 'تم تعديل العبء الوظيفي ',
              allrolesrequired : 'جميع الادوار مطلوبة ',
              jobEvaluationFileName:'مرفق تقييم الوظيفة',
              functionalLoadFileName:'  مرفق العبء الوظيفي ',
              branch :"فرعى",
              TasksAndSpecificationsTemplate: "نموذج المهام والمسؤليات",
              OrganizationStrucutreOperationalTemplate: "النموذج التشغيلي",
              SuggestedOrganizationStructure: "الهيكل التنظيمي المقترح",
              CurrentOrganizationStructure: "الهيكل التنظيمي الحالي",
              OrganizationStructureCompleted :"تم اكتمال بناء الهيكل التنظيمي"

       
    }
}

export default Locali18n;