/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import i18n from './../i18n';
import { Router, useHistory } from "react-router-dom";
import Config from './../utils/Config';
// import covid19Ar  from './../assets/تعميم رقم 16 لسنة 2020 بشأن المخالفات والجزاءات التأديبية للإجراءات الاحترازية لمواجهة انتشار فيروس كوفيد 19.pdf';
// import covid19En  from './../assets/Covid19Violations.pdf';

export default class SideBar extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            shown: false,
            shown1: false,
            shown2: false,
            shown3: false,
            shown4: false,
            shown5: false,
            shown6: false,
            shown7: false,
            shown11: false,
            shown12: false,
            shown13: false,
            loginData: Config.getLocalStorageLoginData()
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleMenu1 = this.toggleMenu1.bind(this);
        this.toggleMenu2 = this.toggleMenu2.bind(this);
        this.toggleMenu3 = this.toggleMenu3.bind(this);
        this.toggleMenu4 = this.toggleMenu4.bind(this);
        this.toggleMenu5 = this.toggleMenu5.bind(this);
        this.handlereports = this.handlereports.bind(this);
        this.openlink = this.openlink.bind(this);
    }
    handlereports = () => {
        alert('x1');
        this.props.history.replace('/landing', { pathname: 'reports' });
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    getSidebarImage = (img) => {
        return require("./../assets/images/icons/sidebar_new_icon/" + img);
    }

    handleClick = (e) => {
        e.preventDefault();
    }

    toggleMenu() {
        this.setState({ shown: !this.state.shown }, () => {
        });
    }

    toggleMenu1() {
        this.setState({ shown1: !this.state.shown1 }, () => {
        });
    }

    toggleMenu2() {
        this.setState({ shown2: !this.state.shown2 }, () => {
        });
    }

    toggleMenu3() {
        this.setState({ shown3: !this.state.shown3 }, () => {
        });
    }

    toggleMenu4() {
        this.setState({ shown4: !this.state.shown4 }, () => {
        });
    }
    toggleMenu6() {
        this.setState({ shown6: !this.state.shown6 }, () => {
        });
    }
    toggleMenu7() {
        this.setState({ shown7: !this.state.shown7 }, () => {
        });
    }
    toggleMenu11() {
        this.setState({ shown11: !this.state.shown11 }, () => {
        });
    }
    toggleMenu12() {
        this.setState({ shown12: !this.state.shown12 }, () => {
        });
    }
    toggleMenu13() {
        this.setState({ shown13: !this.state.shown13 }, () => {
        });
    }

    toggleMenu5() {
        this.setState({ shown5: !this.state.shown5 }, () => {
        });
    }


    // policies library function
    openlink() {
        debugger;
        let a = document.createElement('a');
        a.target = '_blank';
       // a.href = "http://localhost:5002/authorization?user_token=51c6d2a1-a3b7-4c0d-84af-954f524ec0f2";
       a.href = "https://readinessmatrix.hra.gov.ae/authorization?user_token=51c6d2a1-a3b7-4c0d-84af-954f524ec0f2";
        a.click();
    }
    openlinkCalc() {
        debugger;
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = "https://www.dari.ae/ar/app/market-data-detail";
        a.click();
    }
    render() {

        return (

            <MDBCol md={this.props.isSideBarMinimized ? "1" : "2"} className="sideBarContainer" style={{ height: this.props.height }}>

                <div className="dashBoardBg">
                    {
                        this.props.isSideBarMinimized
                            ?
                            <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu1.bind(this)}>
                                <MDBCol md="12" className="menuTxt menuSpan fa-dashboard" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                            </MDBRow>
                            :
                            <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }}>
                                <MDBCol md="2" className="menuTxt menuSpan fa-dashboard" style={{ paddingRight: '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                <MDBCol md="8" className="11mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].dashboard}
                                </MDBCol>
                                <MDBCol md="2"></MDBCol>
                            </MDBRow>
                    }

                </div>


                {/* Police lib */}
                <div className="mainMenu"  style={{cursor:"pointer"}} onClick={this.openlink.bind(this)}>
                    <MDBRow
                        // onClick={() => this.props.handleNavigationPoliciesLibrary('PoliciesLibrary')}
                        onClick={() => this.openlink.bind(this)}
                        style={{ 'background-color': '#B8860B', 'Text-color': '#FFFFFF', 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse', 'margin-left': !this.props.isArabic ? '-15px' : '0px', 'margin-right': !this.props.isArabic ? '0px' : '-15px' }}>

                        {/*  href={{`"https://readinessmatrix.hra.gov.ae/authorization?email="${Config.getLocalStorageeservicesemail()}"`}} */}
                            <MDBCol
                                onClick={() => this.openlink.bind(this)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject}
                                md={!this.props.isSideBarMinimized ? '2' : '12'}
                                className="menuTxt menuSpan fa-report"
                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                            </MDBCol>
                        
                        {
                            !this.props.isSideBarMinimized ?
                                <MDBCol
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject}
                                    md="8" className="mainMenuTxt"
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                    {i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject}
                                </MDBCol>
                                :
                                ''
                        }
                    </MDBRow>
                </div>


                {/* dary link */}
                <div className="mainMenu" style={{cursor:"pointer"}} onClick={this.openlinkCalc.bind(this)}>
                    <MDBRow
                         onClick={() => this.openlinkCalc.bind(this)}
                        style={{ 'background-color': 'rgb(16, 112, 168)', 'Text-color': '#FFFFFF',
                         'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 
                         'row' : 'row-reverse', 'margin-left': !this.props.isArabic ? '-15px' : '0px', 'margin-right': !this.props.isArabic ? 
                         '0px' : '-15px' }}>

                             <MDBCol
                                onClick={() => this.openlinkCalc.bind(this)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject}
                                md={!this.props.isSideBarMinimized ? '2' : '12'}
                                className="menuTxt menuSpan fa-report"
                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                            </MDBCol>
                        
                        {
                            !this.props.isSideBarMinimized ?
                                <MDBCol
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject}
                                    md="8" className="mainMenuTxt"
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                    {i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject}
                                </MDBCol>
                                :
                                ''
                        }
                    </MDBRow>
                </div>
              
              
                {
                    this.props.canviewCircularization ?
                        <div className="mainMenu">
                            <MDBRow
                                onClick={() => this.props.handleNavigationCircularization('Circularization')}
                                style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                <MDBCol
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={i18n[this.props.isArabic ? 'ar' : 'en'].Circularization.subject}
                                    md={!this.props.isSideBarMinimized ? '2' : '12'}
                                    className="menuTxt menuSpan fa-report"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                </MDBCol>
                                {
                                    !this.props.isSideBarMinimized ?
                                        <MDBCol
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={i18n[this.props.isArabic ? 'ar' : 'en'].Circularization.subject}
                                            md="8" className="mainMenuTxt"
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                            {i18n[this.props.isArabic ? 'ar' : 'en'].Circularization.subject}
                                        </MDBCol>
                                        :
                                        ''
                                }
                            </MDBRow>
                        </div>
                        : null
                }
                {
                    this.props.canviewSystemreport ?
                        <div className="mainMenu">
                            <MDBRow
                                onClick={() => this.props.handleNavigationreport('reports')}
                                style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                <MDBCol
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={i18n[this.props.isArabic ? 'ar' : 'en'].report}
                                    md={!this.props.isSideBarMinimized ? '2' : '12'}
                                    className="menuTxt menuSpan fa-report"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                </MDBCol>
                                {
                                    !this.props.isSideBarMinimized ?
                                        <MDBCol
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={i18n[this.props.isArabic ? 'ar' : 'en'].report}
                                            md="8" className="mainMenuTxt"
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                            {i18n[this.props.isArabic ? 'ar' : 'en'].report}
                                        </MDBCol>
                                        :
                                        ''
                                }
                            </MDBRow>
                        </div>
                        :
                        null
                }

                {
                    this.props.canViewEmployeeReport ?
                        <div className="mainMenu">
                            <MDBRow
                                onClick={() => this.props.handleNavigationEmployeereport('EmployeesReports')}
                                style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                <MDBCol
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={i18n[this.props.isArabic ? 'ar' : 'en'].canViewEmployeeReport}
                                    md={!this.props.isSideBarMinimized ? '2' : '12'}
                                    className="menuTxt menuSpan fa-Employeereport"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                </MDBCol>
                                {
                                    !this.props.isSideBarMinimized ?
                                        <MDBCol
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={i18n[this.props.isArabic ? 'ar' : 'en'].canViewEmployeeReport}
                                            md="8" className="mainMenuTxt"
                                            style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                            {i18n[this.props.isArabic ? 'ar' : 'en'].canViewEmployeeReport}
                                        </MDBCol>
                                        :
                                        ''
                                }
                            </MDBRow>
                        </div>
                        :
                        null
                }


                {
                    this.props.isAdmin ?

                        <div className="mainMenu">


                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu3.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-admin_header"
                                            style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu3.bind(this)}>
                                        <MDBCol md="2"
                                            className="menuTxt menuSpan fa-admin_header"
                                            style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                        </MDBCol>


                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].adminPanel}
                                        </MDBCol>

                                        {
                                            !this.state.shown3
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }

                                    </MDBRow>
                            }

                            {
                                this.state.shown3 ?
                                    <div className="subMenuBox">
                                        {
                                            this.props.isAdmin ?
                                                <MDBRow onClick={() => this.props.handleNavigation('entity')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-entities"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].entities}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.isAdmin ?
                                                <MDBRow onClick={() => this.props.handleNavigation('role')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-roles"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].roles}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.isAdmin ?
                                                <MDBRow onClick={() => this.props.handleNavigation('contact')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-contact"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].contact}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.isAdmin ?
                                                <MDBRow onClick={() => this.props.handleNavigation('mailTemplate')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-mailTemplate"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].mailTemplate}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.isAdmin ?
                                                <MDBRow onClick={() => this.props.handleNavigation('lookUp')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-lookUp"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].lookUp}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        :
                        null
                }
              {/*   {
                    this.props.isInternal ?
                        <MDBRow onClick={() => this.props.handleNavigationsearch('search')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                className="menuTxt menuSpan fa-inporgress"
                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                            </MDBCol>

                            {
                                !this.props.isSideBarMinimized ?
                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].search}
                                    </MDBCol>
                                    :
                                    ''
                            }
                        </MDBRow>
                        : null
                } */}
                {
                    this.props.isInternal && this.props.isAdmin ?
                        <div className="mainMenu">

                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu5.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-inbox"
                                            style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu5.bind(this)}>
                                        <MDBCol md="2"
                                            className="menuTxt menuSpan fa-inbox"
                                            style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                        </MDBCol>

                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].inbox}
                                        </MDBCol>

                                        {
                                            !this.state.shown5
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }

                                    </MDBRow>
                            }


                            {
                                this.state.shown5 ?

                                    <div className="subMenuBox">
                                        <MDBRow onClick={() => this.props.handleNavigation('inprogress')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-inporgress"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].inprogress}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>
                                        <MDBRow onClick={() => this.props.handleNavigation('Late')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-pending"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].Late}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>


                                        <MDBRow onClick={() => this.props.handleNavigation('pending')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-pending"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].pending}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>

                                        <MDBRow onClick={() => this.props.handleNavigation('completed')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-completed"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].completed}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>

                                        <MDBRow onClick={() => this.props.handleNavigation('cancelled')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-cancelled"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].cancelled}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>

                                        <MDBRow onClick={() => this.props.handleNavigation('onhold')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-onhold"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].onhold}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>

                                        <MDBRow onClick={() => this.props.handleNavigation('Underapproval')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-pending"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].Underapproval}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>



                                           <MDBRow onClick={() => this.props.handleNavigationsearch('search')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                            <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                className="menuTxt menuSpan fa-inporgress"
                                                style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                            </MDBCol>

                                            {
                                                !this.props.isSideBarMinimized ?
                                                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].search}
                                                    </MDBCol>
                                                    :
                                                    ''
                                            }
                                        </MDBRow>

                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        :
                        null
                }

                <div className="mainMenu">
                    <div className="subMenuBox">
                        {
                            this.props.isInternal ?
                                <MDBRow
                                    onClick={() => this.props.handleNavigation('currentUserTasks')}
                                    style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].task}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-task"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>
                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].task}
                                                md="8" className="mainMenuTxt"
                                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                                {i18n[this.props.isArabic ? 'ar' : 'en'].task}
                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        }

                        {
                            this.props.canViewSCI ?
                                <MDBRow
                                    onClick={() => this.props.handleNavigation('SCIRequest')}
                                    style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].sci}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-sci"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>

                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].sci}
                                                md="8" className="mainMenuTxt"
                                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                {/* <span className="font12">{i18n[this.props.isArabic ? 'ar' : 'en'].sci} - {this.props.totalscirequest}</span> */}
                                                {/* <span className="font12">{i18n[this.props.isArabic ? 'ar' : 'en'].sci}</span> */}
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].sci}
                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        }

                        {
                            this.state.loginData.permissionType.canViewOHI ?
                                <MDBRow
                                    onClick={() => this.props.handleNavigationActionPlansList('ActionPlansListinternal')}
                                    style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.subject}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-sci"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>

                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.subject}
                                                md="8" className="mainMenuTxt"
                                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                                {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.subject}
                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        }
                        {
                            this.state.loginData.internalcontact && this.state.loginData.permissionType.canViewOHI ?
                                <MDBRow
                                    onClick={() => this.props.handleNavigationActionPlansKPIList('ActionPlansKPI')}
                                    style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.KPI}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-sci"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>

                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.KPI}
                                                md="8" className="mainMenuTxt"
                                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                                {i18n[this.props.isArabic ? 'ar' : 'en'].ActionPlans.KPI}
                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        }
                        {
                            this.state.loginData.permissionType.canViewDisciplinaryboard ?
                                <MDBRow
                                    onClick={() => this.props.handleNavigation('Disciplinaryboard')}
                                    style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.subject}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-sci"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>

                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.subject}
                                                md="8" className="mainMenuTxt"
                                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                                {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.subject}
                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        }

                        {
                            this.props.canViewInformation ?
                                <MDBRow onClick={() => this.props.handleNavigation('informationRequest')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].infoRequest}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-information_request"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>

                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].infoRequest}
                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].infoRequest}

                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        }






                        {/* {
                            this.props.canViewSecondMentForFederalAgency?
                                <MDBRow onClick={() => this.props.handleNavigation('SickLeave')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                    <MDBCol
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.subject}
                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                        className="menuTxt menuSpan fa-SickLeave"
                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                    </MDBCol>

                                    {
                                        !this.props.isSideBarMinimized ?
                                            <MDBCol
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.subject}
                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl':'ltr',textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest} - {this.props.totalfederalrequest} }
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].SickLeave.subject}
                                            </MDBCol>
                                            :
                                            ''
                                    }
                                </MDBRow>
                                :
                                null
                        } */}



                    </div>

                </div>
                {/* {StrategicPlanningforworkforce.Title} */}
                {/* {
                    this.state.loginData.permissionType.canviewSPWF ? 
    <div className="mainMenu">
        {
            this.props.isSideBarMinimized
                ?
                <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu7.bind(this)}>
                    <MDBCol md="12" className="menuTxt menuSpan fa-ExtendCreateObjectives" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                </MDBRow>
                :
                <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu7.bind(this)}>

                    <MDBCol md="2" className="menuTxt menuSpan fa-ExtendCreateObjectives" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                    <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                        {i18n[this.props.isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Title}
                    </MDBCol>

                    {
                        !this.state.shown7
                            ?
                            <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                            :
                            <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                    }
                </MDBRow>
        }

        {
            this.state.shown7 ?


                <div className="subMenuBox">  
               
                                  
                    
                    {
                                            this.state.loginData.permissionType.canviewSPWF ? 
                            <MDBRow onClick={() => this.props.handleNavigationStrategicPlanningforworkforce()} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                    className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                </MDBCol>
                                {
                                    !this.props.isSideBarMinimized ?
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Title}
                                        </MDBCol>
                                        :
                                        ''
                                }
                            </MDBRow>        
: null


                    }
                    {
                                            this.state.loginData.permissionType.canviewSPWF ? 
                            <MDBRow onClick={() => this.props.handleNavigation('StrategicPlanningforworkforceEnquire')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                    className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                </MDBCol>
                                {
                                    !this.props.isSideBarMinimized ?
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].StrategicPlanningforworkforce.Enquire}
                                        </MDBCol>
                                        :
                                        ''
                                }
                            </MDBRow>                       
: null


                    }

                </div>
                  
                                    
                  
                  
                                      :
                                      ''
                              }
    </div>
                        : null  
}  */}
                {/* WorkShop */}
                {
                    this.state.loginData.permissionType.canCreateWorkShop || this.state.loginData.permissionType.canBookWorkShop ?
                        <div className="mainMenu">
                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu6.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-ExtendCreateObjectives" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu6.bind(this)}>

                                        <MDBCol md="2" className="menuTxt menuSpan fa-ExtendCreateObjectives" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.subject}
                                        </MDBCol>

                                        {
                                            !this.state.shown6
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }
                                    </MDBRow>
                            }

                            {
                                this.state.shown6 ?


                                    <div className="subMenuBox">
                                        {
                                            this.props.isInternal && this.state.loginData.permissionType.canCreateWorkShop ?
                                                <MDBRow onClick={() => this.props.handleNavigationWorkShopManage()} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.WorkShopManage}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                : null


                                        }

                                        {
                                            this.props.isInternal && this.state.loginData.permissionType.canCreateWorkShop ?
                                                <MDBRow onClick={() => this.props.handleNavigationWorkShopAdd()} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.WorkShopAdd}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                : null


                                        }
                                        {
                                            this.props.isInternal && this.state.loginData.permissionType.canCreateWorkShop ?
                                                <MDBRow onClick={() => this.props.handleNavigationWorkShopBookedList()} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.WorkShopBookedList}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                : null


                                        }
                                        {
                                            !this.props.isInternal && this.state.loginData.permissionType.canBookWorkShop ?
                                                <MDBRow onClick={() => this.props.handleNavigationWorkShopBookinglist()} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.WorkShopBookinglist}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                : null


                                        }
                                        {
                                            !this.props.isInternal && this.state.loginData.permissionType.canBookWorkShop ?
                                                <MDBRow onClick={() => this.props.handleNavigationWorkShopMyBookedList()} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.WorkShopMyBookedList}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                : null


                                        }

                                    </div>




                                    :
                                    ''
                            }
                        </div>
                        : null
                }

                {
                    this.props.canViewExceptionAccompanyPatient ||
                        this.props.canViewExcemptionFromADHousingPolicies ||
                        this.props.canViewSecondMentForFederalAgency ?
                        <div className="mainMenu">
                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu11.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-exception_from_acccompany_patient" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu11.bind(this)}>

                                        <MDBCol md="2" className="menuTxt menuSpan fa-exception_from_acccompany_patient" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].policies}
                                        </MDBCol>

                                        {
                                            !this.state.shown11
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }
                                    </MDBRow>
                            }

                            {
                                this.state.shown11 ?

                                    <div className="subMenuBox">

                                        {
                                            this.props.canViewExceptionAccompanyPatient ?
                                                <MDBRow onClick={() => this.props.handleNavigation('exemptionFromAccompanyPatient')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].exceptionForPatient}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-exception_from_acccompany_patient"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].exceptionForPatient}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionForPatient} - {this.props.totalinforequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionForPatient}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canViewExcemptionFromADHousingPolicies ?
                                                <MDBRow onClick={() => this.props.handleNavigation('exceptionADHousing')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].exceptionADHousing}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-expection_housing_policy"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].exceptionADHousing}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionADHousing} - {this.props.totalhousingrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionADHousing}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canViewSecondMentForFederalAgency ?
                                                <MDBRow onClick={() => this.props.handleNavigation('secondmentForFederalAgency')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].secondmentForFederal}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-secondement_forfederalagency"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].secondmentForFederal}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].secondmentForFederal} - {this.props.totalfederalrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].secondmentForFederal}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canViewSecondMentForFederalAgency ?
                                                <MDBRow onClick={() => this.props.handleNavigation('exceptionOfSteerTheAgeOfRetirementRequest')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-exceptionOfSteerTheAgeOfRetirementRequest"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest} - {this.props.totalfederalrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canViewSecondMentForFederalAgency ?
                                                <MDBRow onClick={() => this.props.handleNavigation('Promotion')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.subject}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-Promotion"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.subject}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest} - {this.props.totalfederalrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.subject}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }


                                        {
                                            this.props.canViewSecondMentForFederalAgency ?
                                                <MDBRow onClick={() => this.props.handleNavigation('Substitutionplan')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.Subject}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.Subject}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>

                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].Substitutionplan.Subject}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        :
                        null
                }

                {
                    this.props.canViewHRPolicies ?
                        <div className="mainMenu">
                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu12.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-review_hr_policies" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu12.bind(this)}>

                                        <MDBCol md="2" className="menuTxt menuSpan fa-review_hr_policies" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].policiesServices}
                                        </MDBCol>

                                        {
                                            !this.state.shown12
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }
                                    </MDBRow>
                            }

                            {
                                this.state.shown12 ?

                                    <div className="subMenuBox">
                                        {
                                            this.props.canViewHRPolicies ?
                                                <MDBRow onClick={() => this.props.handleNavigation('reviewHRPolicies')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-review_hr_policies"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel}
                                                                md="8"
                                                                className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel} - {this.props.totalhrpolicyrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }
                                    </div>
                                    :

                                    ''
                            }
                        </div>
                        :
                        null
                }


                {
                    this.props.canViewBonusRequest
                        || this.props.canViewAmendSalariesScale || this.props.canViewAmendEmployeeSalary ?
                        <div className="mainMenu">
                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu13.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-bonus_request" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu13.bind(this)}>

                                        <MDBCol md="2" className="menuTxt menuSpan fa-bonus_request" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].salaries}
                                        </MDBCol>

                                        {
                                            !this.state.shown13
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }
                                    </MDBRow>
                            }

                            {
                                this.state.shown13 ?

                                    <div className="subMenuBox">






                                        {
                                            this.props.canViewBonusRequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('bonusRequest')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].bonus_Request}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-bonus_request"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].bonus_Request}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].bonus_Request} - {this.props.totalbonusrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].bonus_Request}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canViewAmendSalariesScale ?
                                                <MDBRow onClick={() => this.props.handleNavigation('amendSalaryScreen')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].amendSalariesScale}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-amend_salaries_scale"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].amendSalariesScale}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].amendSalariesScale} - {this.props.totalbonusrequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].amendSalariesScale}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canViewAmendEmployeeSalary ?
                                                <MDBRow onClick={() => this.props.handleNavigation('amendEmployeeSalary')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={i18n[this.props.isArabic ? 'ar' : 'en'].amendEmployeeSalary}
                                                        md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-Amend_employee_saleries"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>

                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={i18n[this.props.isArabic ? 'ar' : 'en'].amendEmployeeSalary}
                                                                md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {/* {i18n[this.props.isArabic ? 'ar' : 'en'].amendEmployeeSalary} - {this.props.totalammendsalaryemployeerequest} */}
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].amendEmployeeSalary}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }
                                    </div>
                                    :

                                    ''
                            }
                        </div>
                        :
                        null
                }



                {/* Data Services */}
                {
                    this.props.canviewextendcreaterequest || this.props.canviewextendreviewrequest || this.props.canviewextendannualrequest
                        || this.props.canviewemployeeperformancerequest || this.props.canviewjoinimplementrequest || this.props.canviewopencomplainsrequest
                        || this.props.canviewamendsystemsrequest || this.props.canviewreportsstaticsrequest || this.props.canviewotherrequestsrequest ?
                        <div className="mainMenu">
                            {
                                this.props.isSideBarMinimized
                                    ?
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu4.bind(this)}>
                                        <MDBCol md="12" className="menuTxt menuSpan fa-dataServices" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }} onClick={this.toggleMenu4.bind(this)}>

                                        <MDBCol md="2" className="menuTxt menuSpan fa-dataServices" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>
                                        <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].dataServices}
                                        </MDBCol>

                                        {
                                            !this.state.shown4
                                                ?
                                                <MDBCol md="1" className={!this.props.isArabic ? "arrowLft" : "arrowRht"} style={{ 'padding': '0' }}></MDBCol>
                                                :
                                                <MDBCol md="1" className="arrowDwn" style={{ 'padding': '0' }}></MDBCol>
                                        }
                                    </MDBRow>
                            }

                            {
                                this.state.shown4 ?

                                    <div className="subMenuBox">

                                        {
                                            this.props.canviewextendcreaterequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('extendCreateObjectives')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ExtendCreateObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].extendCreateObjectives}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewextendreviewrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('extendReviewObjectives')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-extendReviewObjectives"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].extendReviewObjectives}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewextendannualrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('extendAnnualPerformance')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-extendAnnualPerformanceEvaluationInterval"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationInterval}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewemployeeperformancerequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('addOrEditEmployeesPerformanceEvaluation')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-addOrEditEmployeesPerformanceEvaluation"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluation}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewjoinimplementrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('joinAndImplementGovResourcesSystemForNewEntity')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-joinImplementGovernmentResources"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntity}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewopencomplainsrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('openComplainsStage')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-openComplainsStage"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].openComplainsStage}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewamendsystemsrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('amendsSharedGovernmentHRManagement')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-amendOnSharedGovernmentHRManagementSystem"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystem}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewreportsstaticsrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('reportsAndStaticRequest')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-ReportsAndStaticRequest"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequest}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                        {
                                            this.props.canviewotherrequestsrequest ?
                                                <MDBRow onClick={() => this.props.handleNavigation('otherRequests')} style={{ 'padding': !this.props.isSideBarMinimized ? '10px 10px 10px 0px' : '10px', 'flex-direction': !this.props.isArabic ? 'row' : 'row-reverse' }}>
                                                    <MDBCol md={!this.props.isSideBarMinimized ? '2' : '12'}
                                                        className="menuTxt menuSpan fa-OtherRequests"
                                                        style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }}>
                                                    </MDBCol>
                                                    {
                                                        !this.props.isSideBarMinimized ?
                                                            <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                                                {i18n[this.props.isArabic ? 'ar' : 'en'].OtherRequests}
                                                            </MDBCol>
                                                            :
                                                            ''
                                                    }
                                                </MDBRow>
                                                :
                                                null
                                        }

                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        :
                        null
                }

                {/* { 
              this.props.canvieworgrequest ? 
              <div className="mainMenu">
                    {
                        // organization structure
                        this.props.isSideBarMinimized
                            ?
                            <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }}
                                onClick={() => this.props.handleNavigation('OrganizationStructure')}
                            >

                                <MDBCol md="12" className="menuTxt menuSpan fa-orgStructure" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>

                            </MDBRow>
                            :
                            <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }}
                                onClick={() => this.props.handleNavigation('OrganizationStructure')}
                            >
                                <MDBCol md="2" className="menuTxt menuSpan fa-orgStructure"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} >
                                        
                                    </MDBCol>
                               
                               
                                <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl':'ltr',textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].organizationStructure}
                                </MDBCol> 
                                <MDBCol md="2"></MDBCol>
                            </MDBRow>

                            
                    }
                   
                </div> : null} */}
                {/* { 
            Config.getLocalStorageLoginData().SSOToken ? 
              <div className="mainMenu">
                    {
                        // organization structure
                        this.props.isSideBarMinimized
                            ?
                            <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }}
                                onClick={() => this.props.handleNavigationTrasol()}
                            >

                                <MDBCol md="12" className="menuTxt menuSpan fa-Trasol" style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} ></MDBCol>

                            </MDBRow>
                            :
                            <MDBRow className="no-gutter" style={{ flexDirection: !this.props.isArabic ? "row" : "row-reverse" }}
                                onClick={() => this.props.handleNavigationTrasol()}
                            >
                                <MDBCol md="2" className="menuTxt menuSpan fa-Trasol"
                                    style={{ 'padding-right': '0', marginLeft: !this.props.isSideBarMinimized ? '8px' : '' }} >
                                        
                                    </MDBCol>
                               
                               
                                <MDBCol md="8" className="mainMenuTxt" style={{ direction: this.props.isArabic ? 'rtl':'ltr',textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Trasol}
                                </MDBCol>
                                <MDBCol md="2"></MDBCol>
                            </MDBRow>

                            
                    }
                   
                </div> : null}  */}
                {/* 
{
    <div style={{paddingRight:'15px', paddingLeft:'15px'}}>
                                   <a href={this.props.isArabic ? covid19Ar : covid19En} target="_blank" className="Covid19" >
                                   <div className="flagclass" style={{ display: 'flex', 
                                   flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', alignItems: 'center',
                                    justifyContent: 'center' }} caret gradient="var(--primaryColor)">
                                     <span  style={{ backgroundColor: '#09243e', borderRadius: 50,
                                         height: 25,  
                                          marginRight: 2, padding: '3px',color:'#ffffff'}}>
                                         {i18n[this.props.isArabic ? 'ar' : 'en'].Covid19}
                                         </span>
                                      
                                   </div>
                              </a>
                              </div>
                        } */}
            </MDBCol >

        );

    }

}