import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');



export default class PromotionStep extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            employeeName:'',
            EmployeeInfoEmployeeNumber:'',
            EmployeeInfoJobTitle:'',
            EmployeeInfoJobDegree:'',
            EmployeeInfoEmirateID:'',
            EmployeeInfoEmployeeUID:'',
            employeeNameErr:'',
            EmployeeInfoEmployeeNumberErr:'',
            EmployeeInfoJobTitleErr:'',
            EmployeeInfoJobDegreeErr:'',
            EmployeeInfoEmirateIDErr:'',
            EmployeeInfoEmployeeUIDErr: '',
            EmployeeInfoTotalSalary: "",
            EmployeeInfoTotalSalaryErr:'',
            EmployeeInfoBasicSalary: '',
            EmployeeInfoBasicSalaryErr:'',
            
            EmployeeInfoRetirementcontributions: "",
            EmployeeInfoRetirementcontributionsErr:'',
            RequestType:'',
            RequestTypeErr:'',
            isEdit:this.props.isEdit,
            requestId:this.props.requestId,
            EmployeeJobNumber:'',
            EmployeeJobNumberErr:'',
            salaryErr:'',
        }
    }

    saveAsDraft = () => {
       

        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            RequestType: this.state.RequestType,
            employeeName: this.state.employeeName,
            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
            EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
           
        });
    };

    handleEmployeeName = (event) => {
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeName: event.target.value });
            }
        } else {
            this.setState({ employeeName: '' });
        }
    };
    handleEmployeeInfoJobDegree = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoJobDegree: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoJobDegree: '' });
        }
    };
    handleEmployeeInfoBasicSalary = (value) => {
        if (value) {
            this.setState({ EmployeeInfoBasicSalary: value.formattedValue, EmployeeInfoBasicSalaryErr: "" });
        }
    };
    handleEmployeeInfoRetirementcontributions = (value) => {
        if (value) {
            this.setState({ EmployeeInfoRetirementcontributions: value.formattedValue, EmployeeInfoRetirementcontributionsErr: "" });
        }
    };
    handleEmployeeInfoTotalSalary = (value) => {
        if (value) {
            this.setState({ EmployeeInfoTotalSalary: value.formattedValue, EmployeeInfoTotalSalaryErr: "" });
        }
    };
    handleEmployeeInfoJobTitle = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoJobTitle: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoJobTitle: '' });
        }
    };
    handleEmployeeInfoEmployeeNumber = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ EmployeeInfoEmployeeNumber : event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoEmployeeNumber: '' });
        }
    };
    handleEmployeeInfoEmployeeUID = (event) => {
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ EmployeeInfoEmployeeUID: event.target.value });
            }
        } else {
            this.setState({ EmployeeInfoEmployeeUID: '' })
        }
    };

    
    componentDidMount = () => {
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
debugger;
        if (editFlag) {
            this.setState({
                employeeName:editObj.employeename,
                EmployeeInfoEmployeeNumber:editObj.employeenumber,
                EmployeeInfoJobTitle:editObj.jobtitle,
                EmployeeInfoJobDegree:editObj.grade,
                EmployeeInfoEmirateID:editObj.emiratesid,
                EmployeeInfoEmployeeUID:editObj.unifiednumber,
                EmployeeInfoBasicSalary:editObj.basicsalary,
                EmployeeInfoTotalSalary:editObj.totalsalary,
EmployeeInfoRetirementcontributions: editObj.retirementcontribution,
RequestType:editObj.requesttype,
requestId:editObj.requestId
            });
        }
    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handleSalayOptionChange = (e) => {
        this.setState({
            SalaryCointiunousOption: e.target.value,
            SalaryCointiunousOptionErr: ''
        });
    };
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    handleEmployeeInfoEmirateID = (event) => {
        let emiratesIDStepOne = event.target.value;
        if (event.target.value !== '') {
            this.setState({ EmployeeInfoEmirateID: event.target.value }, () => {
                if (Validator.validateEmiratesId(emiratesIDStepOne)) {
                    this.setState({ EmployeeInfoEmirateIDErr: '' });
                } else {
                    this.setState({ EmployeeInfoEmirateIDErr: 'invalidEmiratesId' });
                }
            });
        }
        else {
            this.setState({ EmployeeInfoEmirateID: '', EmployeeInfoEmirateIDErr: 'thisFieldisRequired' });
        }
    }

    

    submitHandler =(event) =>{
        event.preventDefault();
        event.target.className += " was-validated";
        let employeeNameErr='',
        EmployeeInfoEmployeeNumberErr='',
        EmployeeInfoJobTitleErr='',
        EmployeeInfoJobDegreeErr='',
        EmployeeInfoEmirateIDErr='',
            EmployeeInfoEmployeeUIDErr = '', EmployeeInfoBasicSalaryErr = '', EmployeeInfoTotalSalaryErr = '', EmployeeInfoRetirementcontributionsErr='',RequestTypeErr='',salaryErr='';
        if (!this.state.employeeName) {
            employeeNameErr = 'thisFieldIsRequired';
        }
        if (!this.state. EmployeeInfoJobTitle) {
            EmployeeInfoJobTitleErr = 'thisFieldIsRequired';
        }
        if (!this.state. EmployeeInfoEmployeeNumber) {
            EmployeeInfoEmployeeNumberErr = 'thisFieldIsRequired';
        }
        if (!this.state.EmployeeInfoJobDegree) {
            EmployeeInfoJobDegreeErr = 'thisFieldIsRequired';
        }
        if (!this.state.EmployeeInfoEmirateID) {
            EmployeeInfoEmirateIDErr = 'thisFieldIsRequired';
        }
        if (!this.state.EmployeeInfoEmployeeUID) {
            EmployeeInfoEmployeeUIDErr = 'thisFieldIsRequired';
        }
        if (!this.state.EmployeeInfoTotalSalary) {
            EmployeeInfoTotalSalaryErr = 'thisFieldIsRequired';
        }
        if (!this.state.EmployeeInfoRetirementcontributions) {
            EmployeeInfoRetirementcontributionsErr = 'thisFieldIsRequired';
        }
        if (!this.state.EmployeeInfoBasicSalary) {
            EmployeeInfoBasicSalaryErr = 'thisFieldIsRequired';
        }
        if (!this.state.RequestType) {
            RequestTypeErr = 'thisFieldIsRequired';
        }
        if (this.state.EmployeeInfoBasicSalary && this.state.EmployeeInfoTotalSalary) {
            let basicSalAfterReplace = this.state.EmployeeInfoBasicSalary.replace(/,/g, "");
            let totalSalAfterReplace = this.state.EmployeeInfoTotalSalary.replace(/,/g, "");
            if (parseFloat(basicSalAfterReplace) > parseFloat(totalSalAfterReplace)) {
                salaryErr = 'salaryErr';
            }
        }

this.setState({ salaryErr:salaryErr, employeeNameErr,
    EmployeeInfoEmployeeNumberErr,
    EmployeeInfoJobTitleErr,
    EmployeeInfoJobDegreeErr,
    EmployeeInfoEmirateIDErr,
    EmployeeInfoEmployeeUIDErr, EmployeeInfoTotalSalaryErr, EmployeeInfoRetirementcontributionsErr
    , EmployeeInfoBasicSalaryErr,RequestTypeErr}, () => {
            

        if (!employeeNameErr && !EmployeeInfoEmployeeNumberErr && !EmployeeInfoJobTitleErr  && !EmployeeInfoJobDegreeErr && !EmployeeInfoEmirateIDErr && !EmployeeInfoEmployeeUIDErr && !EmployeeInfoTotalSalaryErr && !EmployeeInfoRetirementcontributionsErr && !EmployeeInfoBasicSalaryErr && !RequestTypeErr && !salaryErr) {
            

            //If Budget Availability is Yes, then Enter the Budget's year compulsary
//            if (this.state.selectedOption2 === 'Yes')
             {
                // if (this.state.requestType &&
                //     this.state.empJob &&
                //     this.state.headlinesOfRequest &&
                //     this.state.employeeName &&
                //     this.state.grade &&
                //     this.state.basicSalary &&
                //     this.state.unifiedNumber &&
                //     this.state.totalSalary &&
                //     this.state.retirementContributions &&
                //     this.state.durationOfAccompanyingLeave &&
                //    // this.state.dateOfSalaryTermination &&
                //     this.state.selectedOption2 &&
                //     striptags(this.state.justificationsEmployee) &&
                //     this.state.emiratesIDStepOne && this.state.Degreeofrelationship
                // ) 
                {
                    this.setState({
                        isNextClick: true,
                        isfirstPageValidate: true
                    }, () => {
                        this.props.getData.stepOneData({
                            isEdit: this.state.isEdit,
                            requestId: this.state.requestId,
                            // formType: 'saveAsDraft',
                            // saveFrom: "Step1",
                            RequestType: this.state.RequestType,
                            employeeName: this.state.employeeName,
                            EmployeeInfoEmployeeNumber: this.state.EmployeeInfoEmployeeNumber,
                            EmployeeInfoJobTitle: this.state.EmployeeInfoJobTitle,
                            EmployeeInfoJobDegree: this.state.EmployeeInfoJobDegree,
                            EmployeeInfoEmirateID: this.state.EmployeeInfoEmirateID,
                            EmployeeInfoEmployeeUID: this.state.EmployeeInfoEmployeeUID,
                            EmployeeInfoTotalSalary: this.state.EmployeeInfoTotalSalary,
                            EmployeeInfoBasicSalary: this.state.EmployeeInfoBasicSalary,
                            durationOfAccompanyingLeave: this.state.durationOfAccompanyingLeave,
                            EmployeeInfoRetirementcontributions: this.state.EmployeeInfoRetirementcontributions,
                        });
                    });
                }
            } 
            
           
        }
    });

    }

    
    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };

    render() {
      
       
        return (
            // <React.Fragment>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={1}
                    isArabic={!this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
                <ul class="nav nav-tabs">
                    <li class="nav-item Customcontainer">
                        <a class="nav-link active" href="#">{i18n[this.getLocale()].Promotion.requestype} </a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            {/* <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.promotion}<span className="requireStar">*</span></label>*/}
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline"> 
                                <input
                                    type="radio"
                                    value="1"
                                    checked={this.state.RequestType === '1'}
                                    class="custom-control-input"
                                    id="customControlValidation4"
                                    onChange={this.handleRequestTypeChange}
                                    name="radio-stacked-2"
                                    required />
                                <label class="custom-control-label" for="customControlValidation4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.promotion}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="2"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.RequestType === '2'}
                                    onChange={this.handleRequestTypeChange}
                                    required />
                                <label class="custom-control-label" for="customControlValidation5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Promotion.hiring}
                                </label>
                            </div>
                            <br />
                            {
                                this.state.RequestTypeErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {/* {
                                this.state.selectedOption2Err === 'budgetAvailabilityError' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].budgetAvailabilityError}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>

                        </MDBRow>


                    </div>
                </div>

                <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].Common.EmployeeInfoSubject} </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  
  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmployeeName}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.employeeName}
                                onChange={this.handleEmployeeName}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.employeeNameErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmployeeNumber}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.EmployeeInfoEmployeeNumber}
                                onChange={this.handleEmployeeInfoEmployeeNumber}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                             {
                                this.state.EmployeeInfoEmployeeNumberErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

               
</MDBRow>

<MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoJobTitle}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.EmployeeInfoJobTitle}
                                onChange={this.handleEmployeeInfoJobTitle}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.EmployeeInfoJobTitleErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoJobDegree}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.EmployeeInfoJobDegree}
                                onChange={this.handleEmployeeInfoJobDegree}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.EmployeeInfoJobDegreeErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

               
</MDBRow>
<MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmirateID}<span className="requireStar">*</span></label>
                            <InputMask
                                   mask="999-9999-9999999-9"
                                   maskChar={null}
                                value={this.state.EmployeeInfoEmirateID}
                                onChange={this.handleEmployeeInfoEmirateID}
                                
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.EmployeeInfoEmirateIDErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EmployeeInfoEmirateIDErr === 'invalidEmiratesId' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidEmiratesID}
                                    </div>
                                    :
                                    null
                            }
                            
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                              <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmployeeUID}<span className="requireStar">*</span></label>
                            <input
                                value={this.state.EmployeeInfoEmployeeUID}
                                onChange={this.handleEmployeeInfoEmployeeUID}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                required
                                //maxlength="50"
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                              {
                                this.state.EmployeeInfoEmployeeUIDErr == 'thisFieldIsRequired' ?
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                        </div>
                    </MDBCol>

               
</MDBRow>
    

      </div>
      </div>

      <ul class="nav nav-tabs">
        <li class="nav-item Customcontainer">
          <a class="nav-link active" href="#">{i18n[this.getLocale()].Common.SalaryInfo} </a>
        </li>
      </ul>
      
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
                  <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoBasicSalary}<span className="requireStar">*</span></label>
                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.EmployeeInfoBasicSalaryErr !== '' || this.state.salaryErr ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                            value={this.state.EmployeeInfoBasicSalary}
                                            onValueChange={(value) => { this.handleEmployeeInfoBasicSalary(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                />
                            </div>
                                    {
                                        this.state.EmployeeInfoBasicSalaryErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}{
                                                this.state.salaryErr === 'salaryErr' ?
                                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                        {i18n[this.getLocale()].salaryErr}
                                                    </div>
                                                    :
                                                    null
                                            }
                              </div>
                          </MDBCol>
                          <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoRetirementcontributions}<span className="requireStar">*</span></label>
                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.EmployeeInfoRetirementcontributionsErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                        <CurrencyFormat
                                            thousandSeparator={true}
                                            value={this.state.EmployeeInfoRetirementcontributions}
                                            onValueChange={(value) => { this.handleEmployeeInfoRetirementcontributions(value) }}
                                            display={"text"}
                                            maxLength={10}
                                            style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        />
                                    </div>
                                    {
                                        this.state.EmployeeInfoRetirementcontributionsErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}
                              </div>
                          </MDBCol>
      
                     
      </MDBRow>
      
      <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                    <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                  <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoTotalSalary}<span className="requireStar">*</span></label>
                                    <div className=" currencyFormatInputCSS currencyFormat" style={{ border: this.state.EmployeeInfoTotalSalaryErr !== ''  ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                        <CurrencyFormat
                                            thousandSeparator={true}
                                            value={this.state.EmployeeInfoTotalSalary}
                                            onValueChange={(value) => { this.handleEmployeeInfoTotalSalary(value) }}
                                            display={"text"}
                                            maxLength={10}
                                            style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        />
                                    </div>
                                    {
                                        this.state.EmployeeInfoTotalSalaryErr == 'thisFieldIsRequired' ?
                                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            : ''}
                              </div>
                          </MDBCol>
                      
                     
      </MDBRow>
      
      
            </div></div>
          
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].next_btn}
                           </MDBBtn>
       
                           {
                            //    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            //        null
                            //        :
                                   <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                       {i18n[this.getLocale()].saveAsDraft}
                                   </MDBBtn>
                           }
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                       </MDBRow>
       
      
               
                       </form>    

    //   </React.Fragment>
              
        );
    }
}