import React, { Component } from 'react'
import i18n from "../i18n";
import CKEditor from "react-ckeditor-component";

export default class PreviewItem extends Component {

    constructor(props) {
        super(props);
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    render() {
        return (
            <div className="ckBoxContainer">
                <div
                    className="ckBox"
                    style={{
                        display: 'flex',
                        flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                    }}
                >
                    <div className="ckLabel"
                        style={{
                            marginLeft: this.props.isArabic ? 10 : 0, marginRight: this.props.isArabic ? 0 : 10,
                            direction: this.props.isArabic ? 'rtl' : 'ltr'
                        }}>
                        {i18n[this.getLocale()].viewDetails[this.props.item.label]} :
                    </div>
                </div>

                <div
                    className="ckBox"
                    style={{
                        display: 'flex',
                        flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                        borderBottom: '1px solid rgb(221, 221, 221)'
                    }}
                >
                    <div
                        className="ckValue"
                        style={{
                            display :'flex',
                            textAlign: this.props.isArabic ? 'right' : 'left',
                            direction: this.props.isArabic ? 'rtl' : 'ltr'
                        }}>
                        {
                            this.props.isArabic 
                            ? 
                            <CKEditor
                                    activeClass="viewCk"
                                    content={
                                        this.props.item.value
                                    }
                                    config={{
                                        language: "ar",
                                        height: "60vh",
                                        readOnly: true,
                                        width:'80vw'
                                    }}
                                />  
                            : 
                            null }

                            {
                            !this.props.isArabic 
                            ? 
                            <CKEditor
                                    activeClass="viewCk"
                                    content={
                                        this.props.item.value
                                    }
                                    config={{
                                        language: "en",
                                        height: "60vh",
                                        readOnly: true,
                                        width:'80vw'
                                    }}
                                />  
                            : 
                            null }
                    </div>
                </div>
            </div>
        )
    }
}