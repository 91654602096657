import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import InputMask from 'react-input-mask';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import Validator from "../../utils/Validator";
import { getContactPayload } from '../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';

export default class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            update: 0,
            radio: 0,
            isNextScreenActive: false,
            internalExternalOption: '',
            managerName: '',
            departmentID: '',
            sectorID: '',
            englishName: '',
            arabicName: '',
            userName: '',
            email: '',
            accountTypeSelectedValue: '',
            reasonSelectedValue: '1',
            roleSelectedValue: '',
            statusSelectedValue: '',
            entitySelectedValue: 0,
            designationValue: '',
            mobileValue: '',
            phoneNumberValue: '',
            emailErrorMessage: '',
            mobileNumberErr: '',
            phoneNumberNumberErr: '',
            inetnalExternalErr: '',
            requestId: '',
            isEdit: false,
            entityArray: [],
            statusArray: [],
            reasonArray: [],
            accountTypeArray: [],
            roleArray: [],
            managerArray: [],
            SectorsArray: [],
            DepartmentsArray: [],

            internalExternalArray: [
                {
                    'id': 1,
                    'EnglishTitle': 'Internal',
                    'ArabicTitle': 'داخلي'
                },
                {
                    'id': 0,
                    'EnglishTitle': 'External',
                    'ArabicTitle': 'خارجي'
                }
            ],


        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }


    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            let tempManager = '', tempEntityValue = '', tempDepartment = '', tempSector = '';
            if (editObj.internalcontact === 1) {
                tempEntityValue = 0;
                if (editObj.manager === 0) {
                    tempManager = '';
                } else {
                    tempManager = editObj.manager;
                }
                if (editObj.departmentID === 0) {
                    tempDepartment = '';
                }
                else {
                    tempDepartment = editObj.departmentID;
                }
                if (editObj.sectorID === 0) {
                    tempSector = '';
                }
                else {
                    tempSector = editObj.sectorID;
                }

            } else {
                tempManager = 0;
                tempDepartment = 0; tempSector = 0;
                if (editObj.entityName === 0) {
                    tempEntityValue = '';
                } else {
                    tempEntityValue = editObj.entityName;
                }

                tempManager = editObj.manager;
                tempSector = editObj.sectorID;
                tempDepartment = editObj.departmentID;
            }
            this.handlecontacts(editObj.internalcontact)

            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                englishName: editObj.englishName,
                arabicName: editObj.arabicName,
                userName: editObj.username,
                email: editObj.emailAddress,
                mobileValue: editObj.mobilenumber,
                phoneNumberValue: editObj.phonenumber,
                designationValue: editObj.designation,
                accountTypeSelectedValue: editObj.accountType,
                roleSelectedValue: editObj.roleID,
                statusSelectedValue: editObj.status,
                //entitySelectedValue: editObj.entityName,
                entitySelectedValue: tempEntityValue,
                reasonSelectedValue: editObj.statusreason,
                internalExternalOption: editObj.internalcontact,
                managerName: editObj.manager,
                departmentID: editObj.departmentID,
                sectorID: editObj.sectorID
                //managerName: tempManager
            }, () => {
                Config.setIsEditFlag(false);
            })
        }

        let tempStatusArray = [];
        if (Config.getContactStatusData()) {
            tempStatusArray = Config.getContactStatusData();
        }
        let tempReasonArray = [];
        if (Config.getContactReasonData()) {
            tempReasonArray = Config.getContactReasonData()
        }
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let tempAccountTypeArray = [];
        if (Config.getAccountTypeData()) {
            tempAccountTypeArray = Config.getAccountTypeData();
        }
        let tempRoleListArray = [];
        if (Config.getRoleListData()) {
            tempRoleListArray = Config.getRoleListData();
        }
        let tempContactArray = [];

        if (Config.getContactData()) {
            tempContactArray = Config.getContactData();
        }
        let tempDepartmentArray = [];
        if (Config.getDepartmentData()) {
            tempDepartmentArray = Config.getDepartmentData();
        }
        let tempSectorArray = [];
        if (Config.getSectorData()) {
            tempSectorArray = Config.getSectorData();
        }

        this.setState({
            roleArray: tempRoleListArray,
            reasonArray: tempReasonArray,
            statusArray: tempStatusArray,
            entityArray: tempEntityArray,
            accountTypeArray: tempAccountTypeArray,
            managerArray: tempContactArray,
            SectorsArray: tempSectorArray,
            DepartmentsArray: tempDepartmentArray
        }, () => {

        });
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        let objForContact = {};

        let
            tempMobileNumberErr = "",
            tempPhoneNumberErr = "",
            tempEmailErr = "",
            tempInternalExternalOptionErr = "";

        if (this.state.mobileValue !== '') {
            if (!Validator.validateContactNumber(this.state.mobileValue)) {
                tempMobileNumberErr = "invalidMobileNumber";
            } else {
                tempMobileNumberErr = "";
            }
        }
        else {
            tempMobileNumberErr = "thisFieldIsRequired";
        }

        if (this.state.phoneNumberValue !== '') {
            if (!Validator.validateContactNumber(this.state.phoneNumberValue)) {
                tempPhoneNumberErr = "invalidContactNumber";
            } else {
                tempPhoneNumberErr = "";
            }
        } else {
            tempPhoneNumberErr = "thisFieldIsRequired";
        }

        if (this.state.email !== '') {
            if (!Validator.validateEmail(this.state.email)) {
                tempEmailErr = "invalidEmail";
            }
        }
        else {
            tempEmailErr = "thisFieldIsRequired";
        }

        if (this.state.internalExternalOption === '') {
            tempInternalExternalOptionErr = "thisFieldIsRequired"
        }

        this.setState({
            mobileNumberErr: tempMobileNumberErr,
            phoneNumberNumberErr: tempPhoneNumberErr,
            emailErrorMessage: tempEmailErr,
            inetnalExternalErr: tempInternalExternalOptionErr
        }, () => {

            if (this.state.englishName !== '' &&
                this.state.arabicName !== "" &&
                this.state.userName !== '' &&
                this.state.mobileNumberErr === '' &&
                this.state.phoneNumberNumberErr === '' &&
                this.state.email !== "" &&
                this.state.emailErrorMessage === '' &&
                this.state.roleSelectedValue !== "" &&
                this.state.statusSelectedValue !== "" &&
                this.state.designationValue !== "" &&
                this.state.accountTypeSelectedValue !== "" &&
                this.state.entitySelectedValue !== "" &&
                this.state.reasonSelectedValue !== "" &&
                this.state.mobileValue !== "" &&
                this.state.phoneNumberValue !== "" &&
                this.state.inetnalExternalErr === ""
                //&&(this.state.internalExternalOption === 1 ? this.state.managerName !== "" : this.state.managerName !== "")
            ) {

                objForContact.englishName = this.state.englishName
                objForContact.arabicName = this.state.arabicName
                objForContact.userName = this.state.userName
                objForContact.email = this.state.email
                objForContact.mobile = this.state.mobileValue
                objForContact.phoneNumber = this.state.phoneNumberValue
                objForContact.roleSelectedValue = this.state.roleSelectedValue
                objForContact.designation = this.state.designationValue
                objForContact.accountTypeSelectedValue = this.state.accountTypeSelectedValue
                objForContact.statusSelectedValue = this.state.statusSelectedValue
                objForContact.entitySelectedValue = this.state.entitySelectedValue
                objForContact.reasonSelectedValue = this.state.reasonSelectedValue
                objForContact.internalExternalOption = this.state.internalExternalOption
                if (this.state.managerName !== "") {
                    objForContact.managerName = this.state.managerName
                }
                if (this.state.departmentID !== "") {
                    objForContact.departmentID = this.state.departmentID
                }
                if (this.state.sectorID !== "") {
                    objForContact.sectorID = this.state.sectorID
                }
                objForContact.isEdit = this.state.isEdit;
                objForContact.requestId = this.state.requestId;
                objForContact.update = this.state.update;

                this.props.onSubmitData(objForContact);
            } else {

            }
        });
    };

    handleEnglishName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ englishName: event.target.value });
            }
        } else {
            this.setState({ englishName: "" });
        }
    }

    handleChecked = (event) => {

        this.setState({ update: this.state.update === 0 ? 1 : 0 });
        this.submitHandler(event);

    }

    handleArabicName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ arabicName: event.target.value });
            }
        }
        else {
            this.setState({ arabicName: "" });
        }
    }

    handleUserNameChange = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z0-9_ \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ userName: event.target.value }, () => {

                });
            }
        } else {
            this.setState({ userName: "" });
        }
    }

    handleEmailChange = (event) => {
        if (event.target.value.trim()) {
            if (event.target.value.length <= 50) {
                this.setState({ email: event.target.value, emailErrorMessage: '' });
            }
            else {
                this.setState({ email: "", emailErrorMessage: "thisFieldIsRequired" });
            }
        } else {
            this.setState({ email: "", emailErrorMessage: "thisFieldIsRequired" });
        }
    };

    handleChangeForRole = (event) => {

        this.setState({ roleSelectedValue: event.target.value });
    };

    handleAccountTypeChange = (event) => {

        this.setState({ accountTypeSelectedValue: event.target.value });
    };

    handleChangeForStatus = (event) => {

        this.setState({ statusSelectedValue: event.target.value });
    };

    handleChangeForEntity = (event) => {
        this.setState({ entitySelectedValue: event.target.value });
    };

    handleChangeForReason = (event) => {
        this.setState({ reasonSelectedValue: event.target.value });
    };

    handleContactNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ phoneNumberValue: event.target.value }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ phoneNumberNumberErr: '' });
                } else {
                    this.setState({ phoneNumberNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ phoneNumberValue: '', phoneNumberNumberErr: 'thisFieldIsRequired' });
        }
    };

    handleMobileNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ mobileValue: event.target.value }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ mobileNumberErr: '' });
                } else {
                    this.setState({ mobileNumberErr: 'invalidMobileNumber' });
                }
            });
        } else {
            this.setState({ mobileValue: '', mobileNumberErr: 'thisFieldIsRequired' });
        }
    };

    handleDesignationChange = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ designationValue: event.target.value }, () => {

                });
            }
        } else {
            this.setState({
                designationValue: ""
            });
        }

    }
    handlecontacts = (value) => {
        let type = 0;
        let payload = getContactPayload(type);
        payload.selectedtype = value;

        let endPoint = 'GetEntityContact'
        let url = Config.getUrl(endPoint);
        ApiRequest.sendRequestForPost(url, payload, res => {
            let tempContactArray = [];
            if (res.Status) {
                let resEntityData = res.data;
                if (resEntityData && resEntityData.length > 0) {
                    for (let index = 0; index < resEntityData.length; index++) {
                        //if (resEntityData[index].internalcontact === 1) {
                        tempContactArray.push(resEntityData[index]);
                        // }
                    }

                    this.setState({
                        managerArray: tempContactArray
                    }
                        , () => {
                            Config.setContactData(tempContactArray);
                        })
                }
            } else {
                // TODO
                Config.setContactData(tempContactArray);
            }
        })

    };
    handleOptionForInternalExternal = (e) => {

        this.setState({
            internalExternalOption: parseInt(e.target.value),
            inetnalExternalErr: '',
            managerArray: this.props.arraycontacts,
            accountTypeSelectedValue: e.target.value === '0' ? '816' : '',
            roleSelectedValue: e.target.value === '0' ? '25' : '',
            statusSelectedValue: e.target.value === '0' ? '775' : ''
        },
            () => {
                this.handlecontacts(this.state.internalExternalOption);
            }
            , () => {

                if (this.state.internalExternalOption === 1) {
                    this.setState({
                        managerName: '',
                        entitySelectedValue: 0
                    }, () => {

                    })
                } else {

                    this.setState({
                        managerName: 0,
                        entitySelectedValue: '',

                    }, () => {
                        alert(this.state.roleSelectedValue);
                    })
                }
            })

    };



    handleChangeForManager = (event) => {
        this.setState({ managerName: event.target.value });
    }
    handleChangeForDepartment = (event) => {
        this.setState({ departmentID: event.target.value });
    }
    handleChangeForSector = (event) => {
        this.setState({ sectorID: event.target.value });
    }


    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }
    render() {

        return (
            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate >

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].internalorexternal}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select" id="sources-funding"
                                onChange={this.handleOptionForInternalExternal}
                                value={this.state.internalExternalOption}
                                required>
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.internalExternalArray ? this.state.internalExternalArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle} </option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    {
                        //this.state.internalExternalOption === 1 ?
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                    {i18n[this.getLocale()].manager}
                                    {/* <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span> */}
                                </label>
                                <select
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForManager}

                                    value={this.state.managerName}>
                                    <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                    {
                                        this.state.managerArray ? this.state.managerArray.map((item, index) => {
                                            return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}</option>
                                        }) : null
                                    }
                                </select>
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                            </div>
                        </MDBCol>
                        // :
                        // null
                    }
                </MDBRow>
                {this.state.internalExternalOption === 1 ?
                    <MDBRow>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                    {i18n[this.getLocale()].Department}

                                </label>
                                <select
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForDepartment}

                                    value={this.state.departmentID}>
                                    <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                    {
                                        this.state.DepartmentsArray ? this.state.DepartmentsArray.map((item, index) => {
                                            return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                        }) : null
                                    }
                                </select>
                            </div></MDBCol>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                    {i18n[this.getLocale()].Sector}

                                </label>
                                <select
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                    className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForSector}

                                    value={this.state.sectorID}>
                                    <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                    {
                                        this.state.SectorsArray ? this.state.SectorsArray.map((item, index) => {
                                            return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                        }) : null
                                    }
                                </select>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    : null}
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].englishName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="englishName"
                                name="englishName"
                                value={this.state.englishName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEnglishName}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].arabicName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="arabicName"
                                name="arabicName"
                                value={this.state.arabicName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleArabicName}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].userNameLabel}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="userName"
                                name="userName"
                                value={this.state.userName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleUserNameChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].adminPanelKeys.email}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={this.handleEmailChange}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.email}
                                required
                            />
                            {this.state.emailErrorMessage == "thisFieldIsRequired" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                            {this.state.emailErrorMessage == "invalidEmail" ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidEmail}
                                </div>
                                :
                                null}

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].mobile}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.mobileValue}
                                onChange={this.handleMobileNumberChange}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                className="form-control"
                                required
                            />
                            {
                                this.state.mobileNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.mobileNumberErr == 'invalidMobileNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidMobileNumber}
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].phoneNumber}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.phoneNumberValue}
                                onChange={this.handleContactNumberChange}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                className="form-control"
                                required
                            />
                            {
                                this.state.phoneNumberNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.phoneNumberNumberErr == 'invalidContactNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidPhoneNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].designation}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="designation"
                                name="designation"
                                value={this.state.designationValue}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleDesignationChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].accountType}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleAccountTypeChange}
                                value={this.state.accountTypeSelectedValue}
                                required >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.accountTypeArray ? this.state.accountTypeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }

                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].role}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForRole}
                                value={this.state.roleSelectedValue}
                                required >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.roleArray ? this.state.roleArray.map((item, index) => {
                                        return <option value={item.Id}>{this.props.isArabic ? item.EnglishTitle : item.ArabicTitle}</option>
                                    })
                                        :
                                        null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].status}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForStatus}
                                value={this.state.statusSelectedValue}
                                required >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.statusArray ? this.state.statusArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {
                        this.state.internalExternalOption === 1 ?
                            null
                            :
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                        {i18n[this.getLocale()].entity}
                                        <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                                    </label>
                                    <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForEntity}
                                        value={this.state.entitySelectedValue}
                                        required>
                                        <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}</option>
                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                </div>
                            </MDBCol>
                    }


                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
                        <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.getLocale()].submit}</MDBBtn>
                    </MDBCol>
                    <MDBCol xs="1" sm="1" md="1" lg="0" xl="0">
                        <MDBBtn className="submitBtn" color="primary" onClick={(event) => this.handleChecked(event)}>{i18n[this.getLocale()].update}</MDBBtn>

                    </MDBCol>                </MDBRow>
            </form>
        );
    }
}