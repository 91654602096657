import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import SideBar from '../../components/SideBar';
import Footer from '../../components/Footer';
import i18n from '../../i18n';
import EntityForm from './../../components/AdminPanel/EntityForm';
import { saveEntityPayload, uploadDocument } from './../../utils/Payload';
import ApiRequest from './../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';

export default class Entity extends Component {

    constructor(props) {
        super(props);
        disableBrowserBackButton();
        this.state = {
            logoFileName: '',
            loginData: '',
            isLoading: false,
            theme: 'light',
            y: 0,
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            requestId: '',
            isEdit: false,
        }
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    onSubmitData = (createObjectForEntity) => {
        debugger;
        this.setState({
            logoFileName: createObjectForEntity.logoFileName,
            isEdit: createObjectForEntity.isEdit,
            requestId: createObjectForEntity.requestId
        }, () => {
            let payload = saveEntityPayload(createObjectForEntity);
            let endPoint = 'SaveEntities';
            let url = Config.getUrl(endPoint);

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                    if (res.Status) {
                        let totalcount = 1;
                        this.uploadFile(0, totalcount, res);
                    } else {
                        let errorMessage = res.Message;
                        if (errorMessage === 'unabletoprocess') {
                            this.setState({
                                isLoading: false,
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: i18n[this.getLocale()].error_message
                            }, () => {
                                this.alertSubmit();
                            })
                        } else {
                            this.setState({
                                isLoading: false,
                                titleAlert: i18n[this.getLocale()].error,
                                messageAlert: errorMessage
                            }, () => {
                                this.alertSubmit();
                            })
                        } 
                    }
                })
            })
        })
    }

    uploadFile = (i, totalCount, res) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};

            if (i === 0) {
                fileObj = Config.getBase64EntityLogo();
                obj.filename = this.state.logoFileName;
                //obj.filetype = Config.getAmendEmployeeSalEmiratesId();
                obj.filetype = 3;
                tempBase64 = fileObj;
            }

            if (obj.filename !== '' && tempBase64 !== '') {
                obj.requestid = res.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForEntityFileRequestFile => {
                    this.setState({
                        isLoading: true
                    }, () => {
                        if (resForEntityFileRequestFile.Status) {
                            this.uploadFile(i + 1, totalCount, res);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.props.history.replace("/listPage", {
                                    pathname: localStorage.getItem('currentPath')
                                });
                            })
                        }
                    })
                })
            } else {
                this.uploadFile(i + 1, totalCount, res);
            }
        }
        else {
            {
                if (this.state.isEdit) {
                    this.setState({
                        isLoading: false,
                        titleAlert: i18n[this.getLocale()].success,
                        messageAlert: i18n[this.getLocale()].entity_updated
                    }, () => {
                        this.alertSubmitForSuccess();
                    })    
                } else {
                    this.setState({
                        isLoading: false,
                        titleAlert: i18n[this.getLocale()].success,
                        messageAlert: i18n[this.getLocale()].entity_created
                    }, () => {
                        this.alertSubmitForSuccess();
                    })
                }
            }
        }
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }


    render() {

        const sideBarData = Config.getServiceSideBar('entity', this.state.isArabic, this, 0);

        return (

            <MDBContainer fluid>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} goToLandingPage={this.goToLandingPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className='formWhiteContainer'>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip goBack={() => { this.props.history.replace('/listpage', { pathname: 'entity' }) }} isArabic={this.state.isArabic} title={i18n[this.getLocale()].adminPanelKeys.createEntity} />
                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCard style={{ marginTop: '1%', marginBottom: '1%' }}>
                                <MDBCardBody className="hratablerow"> */}
                            <EntityForm isArabic={this.state.isArabic} onSubmitData={this.onSubmitData} />
                            {/* </MDBCardBody></MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>

                        <SideBar
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic}
                            // height={'180vh'}
                            handleNavigation={this.handleNavigation}
                        />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }

}