import React, { Component } from "react";
import { MDBRow, MDBBtn, MDBCol } from "mdbreact";
import Config from './../utils/Config';
import i18n from "./../i18n";
import ReactExport from "react-data-export";
import {getAllOrganizationStructureRequestForEntityPayload, getCurrentTaskListPayload, getTaskListPayload, getContactPayload, getEntitiesPayload, getExceptionAccompanyPatientForEntityPayload, getAllSciRequestForEntityPayload, getAllInformationRequestForEntityPayload, GetAllhrPoliciesForEntityPayload, GetAllAmendSalaryScaleForEntityPayload, GetAllBounsRequestForEntityPayload, GetAllAmendEmployeeSalaryForEntityPayload, getAllSecondmentForEntityPayload, getAllHousingPolicyForEntityPayload, getMailTemplatePayload, getRolePayload } from './../utils/Payload';
import ApiRequest from './../services/ApiRequest';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class SubHeaderStrip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLate:false,
            loginData: '',
            isInternal: 0,
            isCreate: 1,
            circular:this.props.circular?this.props.circular:false,
            screen: localStorage.getItem("currentPath"),
            pathName: '',
            isAdminPath:false,
            tableData:[],
            data: [],
            pathName: '',
            popUpRowArray: [],
            temppopUpRowArray:[],
            actualArray: [],
            dataRow: [],
           statetableDataArray:this.props.export
        }
    };
 callCurrentTaskList = () => {
        let payload = getCurrentTaskListPayload();
        let endPoint = 'GetTaskList'
        let url = Config.getUrl(endPoint);
        this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                 //       this.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                  //      this.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            }
        })
    }
    componentDidMount = () => {
       
        let loginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: loginData
        }, () => {
            debugger;
            let pathName = '';
            if (this.props && this.props.location && this.props.location.state) {
                pathName = this.props.location.state.pathname;
                localStorage.setItem('currentPath', this.props.location.state.pathname);
            }
            else {
                pathName = localStorage.getItem('currentPath');
            }
           
            this.setState({
                isInternal: this.state.loginData && this.state.loginData.internalcontact,
                pathName: pathName
            }, () => {
              
                if(pathName==='StrategicPlanningforworkforce' || pathName==='StrategicPlanningforworkforceForm' ||pathName==='StrategicPlanningforworkforceEnquire' ||pathName==='StrategicPlanningforworkforceEnquireForm' )
{
  
this.state.isInternal ? this.setState({isCreate:0}):this.setState({isCreate:1});

}
                if (pathName) {
                 
                    if(pathName==='Substitutionplan')
                    {
                          
                        this.setState({isCreate:0})
                    }
else if(pathName==='Circularization')
{
   
    this.setState({isCreate:1})
}
else if ( pathName==='Late') {
    this.setState({
        isCreate: 0,
        isLate:true,
        
    },()=>{
    this.callGetAllTaskList(31);
    })
 }
else                    if (pathName === 'inprogress'  ) {
                        this.setState({
                            isCreate: 0
                        })
                        this.callGetAllTaskList(31);
                    } else if (pathName === 'pending') {
                        this.setState({
                            isCreate: 0
                        })
                        this.callGetAllTaskList(38);
                    } 
                    else if (pathName === 'Underapproval') {
                        this.setState({
                            isCreate: 0
                        })
                        //this.callGetAllTaskList(10968);
                        this.callGetAllTaskList(10982);
                    } 
                    else if (pathName === 'completed') {
                        this.setState({
                            isCreate: 0
                        })
                        this.callGetAllTaskList(23);
                    } else if (pathName === 'cancelled') {
                        this.setState({
                            isCreate: 0
                        })
                        this.callGetAllTaskList(164);
                    } else if (pathName === 'onhold') {
                        this.setState({
                            isCreate: 0
                        })
                        this.callGetAllTaskList(37);
                    } else if (pathName === 'currentUserTasks') {
                        this.setState({
                            isCreate: 0
                        })
                        this.callCurrentTaskList();
                    } else if (pathName === 'SCIRequest') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitscirequest
                        })
                    } else if (pathName === 'informationRequest') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitirrequest
                        })
                    } else if (pathName === 'reviewHRPolicies') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitreviewhrrequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'amendSalaryScreen') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitamendscalerequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'bonusRequest') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitbonusrequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'amendEmployeeSalary') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitamendsalaryrequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'secondmentForFederalAgency') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitsecondmentrequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'exceptionADHousing') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitadhousingrequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'exemptionFromAccompanyPatient') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitpatientrequest
                            // isCreate: 1
                        })
                    } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitretirementrequest
                        })
                    } else if (pathName === 'extendCreateObjectives') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitextendcreaterequest
                        })
                    } else if (pathName === 'extendReviewObjectives') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitextendreviewrequest
                        })
                    } else if (pathName === 'extendAnnualPerformance') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitextendannualrequest
                        })
                    } 

                    else if (pathName === 'ActionPlans') {
                        this.setState({
                            isCreate: true
                        })
                    } 
                    else if (pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitemployeeperformancerequest
                        })
                    } else if (pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitjoinimplementrequest
                        })
                    } else if (pathName === 'openComplainsStage') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitopencomplainsrequest
                        })
                    } else if (pathName === 'amendsSharedGovernmentHRManagement') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitamendsystemsrequest
                        })
                    } else if (pathName === 'reportsAndStaticRequest') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitreportsstaticsrequest
                        })
                    } else if (pathName === 'otherRequests') {
                        this.setState({
                            isCreate: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.cansubmitotherrequestsrequest
                        })
                    }
                }

                if (this.state.pathName ==='entity' || this.state.pathName ==='role'|| this.state.pathName ==='lookUp' || this.state.pathName ==='contact' || this.state.pathName ==='mailTemplate') {
                    this.setState({
                        isAdminPath : true
                    })
                }
            });
        })
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            tableData: props
        })
    }

    callGetAllTaskList = (statusid) => {
        let obj = {};
        obj.statusid = statusid;

        let payload = getTaskListPayload(obj);
        payload.sorttype=this.state.sorttype;
        payload.IsLate=this.state.isLate;
        let endPoint = 'GetTaskList'
        let url = Config.getUrl(endPoint);

        this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
        
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };

                    //MGM
                    if(this.state.isInternal == 1)
                    {
                        let tempdataArray = [];
                        let tempdataArray1 = [];
                       
                        tempdataArray1=resArray;
                      
            
                        for(let i = 0; i < tempdataArray1.length; i++){ 
                            let item = tempdataArray1[i];
                            let txtreason= '';
                            if(item.statusreasonid == Config.getReasonForSectorAssignedId() )
                                                      {
                                                     
                                                         txtreason=   item.assignedsectorname;
                                                      }
                                                      else if((item.statusreasonid == Config.getReasonForDepartmentAssignedId() || item.statusreasonid==Config.getReasonSCIDepartmentAssignedId() || item.statusreasonid == Config.getReasonForStudyProvidedId()) )
                                                      {
                                                     
                                                         txtreason=      item.assigneddepartmentname;
                                                      }
                                                      else if (item.statusreasonid == Config.getReasonForEmployeeAssignedId() || item.statusreasonid == Config.getReasonForStudyProvidedId())
                                                      {
                                                 
                                                         txtreason=  item.assignedemployeename;
                                                      }
                                                      else
                                                      {
                                                          debugger;
                                                        
                                                      }
                                                         
                                                  
                                         
                                                         
                                
                                                      item.statusreason=item.statusreason+" "+ txtreason;
                                                let tempobj={};
                                                tempobj.no=i+1;
                                                tempobj.createdby=item.createdby;
                                                tempobj.Requestnumber=item.Requestnumber;
                                                tempobj.subject=item.subject;
                                                                                               tempobj.statusreason=item.statusreason;

                                                tempobj.status=item.status;
                                                tempobj.createdon=item.createdon;
                                                tempobj.entityenglishname=item.entityenglishname;
                                                tempobj.entityarabicname=item.entityarabicname;
                                                    
                                                      tempdataArray.push(tempobj);
                           
            
                        }
                        this.setState({statetableDataArray:tempdataArray});
                    }
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        //this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                      //  this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }
    render() {
        let tableDataArray =this.state.statetableDataArray;
        let dataArray = [];
        
        if (this.props.exportVisible) {
           
        
        
            dataArray = tableDataArray.length ? Object.keys(tableDataArray[0]) : [];
       
    }

        return (
            <MDBRow
                style={{
                    height: "8vh",
                    alignItems: "center",
                    borderBottom: "1px solid #dddddd",
                    //backgroundColor: "#FFFFFF",
                    flexDirection: this.props.isArabic ? "row-reverse" : "row",
                    left: 0,
                    // boxShadow:
                    //   "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)"
                }}>

                <MDBCol
                    md="8" size="8" sm="8"
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: this.props.isArabic ? "flex-end" : "flex-start",
                        alignItems: "center"
                    }}>

                    {!this.props.isArabic && this.state.pathName !== 'organizationStructure' ? (
                        <img
                            alt=""
                            src={require("./../assets/images/back_arrow.png")}
                            onClick={this.props.goBack}
                            className={this.props.isArabic ? 'ar_back' : "far fa-arrow-alt-circle-left"}
                            style={{ padding: "0 10px", fontSize: "25px", cursor: "pointer" }}
                        />
                    ) : null}

                    <h4
                        style={{
                            padding: "0 15px",
                            textAlign: this.props.isArabic ? "right" : "left",
                            margin: "0 5px"
                        }}
                    >
                        <b>{this.props.title}</b>
                    </h4>
                    {this.props.isArabic && this.state.pathName !== 'organizationStructure' ? (
                        <img
                            alt=""
                            src={require("./../assets/images/back_arrow.png")}
                            onClick={this.props.goBack}
                            className={this.props.isArabic ? 'ar_back' : "far fa-arrow-alt-circle-left"}
                            style={{ padding: "0 10px", fontSize: "25px", cursor: "pointer" }}
                        />
                    ) : null}
                </MDBCol>


                <MDBCol md="4" size="4" sm="4"
                    style={{
                        // flexDirection: this.props.isArabic ? "row-reverse" : "row",
                        height: "100%", display: "flex", 
                        justifyContent: this.props.isArabic ? "flex-start" : "flex-end",
                        alignItems: "center"
                        // , paddingRight: '3.4%'
                    }}>
                    <MDBCol md="1" size="1" sm="1" />
                    {
                        this.state.isInternal === 1 && (!this.state.isAdminPath)
                            ?
                            <MDBCol md="3" size="3" sm="3"
                                style={{
                                    height: "100%", display: "flex", flexDirection: "row",
                                    justifyContent: this.props.isArabic ? "flex-start" : "flex-end",
                                    alignItems: "center",
                                    padding: 0,
                                    // , paddingRight: '3.4%'
                                }}>
                                {
                                    this.props.exportVisible ?
                                        <ExcelFile
                                            filename={localStorage.getItem('currentPath')}
                                            element={<MDBBtn className="fixcusotmbtn" style={{ borderRadius: '5px' }} color="primary" >
                                                {i18n[this.props.isArabic ? "ar" : "en"].exportToExcel}
                                            </MDBBtn>}>
                                            <ExcelSheet data={tableDataArray} name={localStorage.getItem('currentPath')}>
                                                {
                                                    dataArray.map((item, index) => {
                                                        return (
                                                            <ExcelColumn label={item} value={item} />
                                                        )
                                                    })
                                                }

                                            </ExcelSheet>
                                        </ExcelFile>
                                        : null
                                }
                            </MDBCol>
                            :
                            null
                    }

                    {
                        
                        this.state.isCreate||this.state.circular ?
                            <MDBCol md="4" size="4" sm="4"
                                style={{
                                    height: "100%", display: "flex", flexDirection: "row",
                                    justifyContent: this.props.isArabic ? "flex-start" : "flex-end",
                                    alignItems: "center",
                                    padding: 0
                                    // , paddingRight: '3.4%'
                                }}>
                               
                                {
                                    this.props.enableCreate ? (
                                        <MDBBtn className="fixcusotmbtn" style={{ borderRadius: '5px' }} color="primary" onClick={this.props.create}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].create}
                                        </MDBBtn>
                                    )
                                        :
                                        null
                                }
                            </MDBCol>
                            :
                            null
                    }
                    <MDBCol md="1" size="1" sm="1" />

                </MDBCol>
            </MDBRow>
        );
    }

};

export default SubHeaderStrip;
