import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol,MDBBtn } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import {  getDashboardPayload } from '../../utils/Payload';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../../components/Footer';
import { saveSecondmentRequestPayload, uploadDocument } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApexCharts from "react-apexcharts";

let moment = require('moment');
export default class ActionPlansKPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            WorkShopPres:'',
            WorkShopPresFileObj:null,
            KPIData:{},
            EntityID:0,
            popUpRowArray:[],
            popUpRowArrayTemp:[],
            entityArray:[],
            OverAll:true,
ProjectsKPI:false,
EmployeeInfo:false
        }
        
  
          
    }

    ProjectsKPIClick = () => {
        this.setState({
            OverAll: false,
            ProjectsKPI: true,
            EmployeeInfo: false
        });
    };
OverAllClick = () => {
        this.setState({
            OverAll: true,
            ProjectsKPI: false,
            EmployeeInfo: false
        });
    };
EmployeeInfolick = () => {
        this.setState({
            OverAll: false,
            ProjectsKPI: false,
            EmployeeInfo: true
        });
    };

    handleChangeForEntity = (event) => {
        let selectvalue =event.target.value;
        if(selectvalue=='')
    {
        selectvalue='0';
    }
  
      
       this.setState({EntityID:selectvalue}
        ,()=>{
this.callActionPlansKPI();
        
       })
  
    };

    callActionPlansKPI = () => {
        let payload = getDashboardPayload();
        let endPoint = 'ActionPlansKPI';
        payload.entityID=this.state.EntityID;
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
           
            ApiRequest.sendRequestForPost(url, payload, resDashobard => {
                if (resDashobard.Status) {
                    this.setState({
                        isLoading: false,
                        KPIData: resDashobard.data,
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
        })
    }

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }
    componentWillMount = () => {
        localStorage.setItem('currentPath', 'ActionPlansKPI');

        
    }

    componentDidMount=()=>
    {
        let loginData = Config.getLocalStorageLoginData();
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        
        if (loginData) {
            this.callActionPlansKPI();
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    entityArray:tempEntityArray,
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }

    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    create = () => {
        let pathname = localStorage.getItem('WorkShopCreate');
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    render() {
        const sideBarData = Config.getServiceSideBar('ActionPlans', this.state.isArabic, this, 0);

        const OverAll =
        {
            chart: {
                height: 350,
                type: "bar",
                background: '#ffffff',
                stacked: true,
                events: {
                    animations: {
                        enabled: true,
                        easing: 'easeout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    dataPointSelection: (event, chartContext, config) => {
                     
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    // columnWidth: "20%",
                }
            },
            series: [
                {
                    name: i18n[this.getLocale()].ActionPlans.OverAll
                    ,
                    data: [this.state.KPIData.NumberofApprovedInitiatives ? this.state.KPIData.NumberofApprovedInitiatives : 0,
        
                    this.state.KPIData.NumberofApprovedKeyProjects ? this.state.KPIData.NumberofApprovedKeyProjects : 0,
                    this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithBudget ? this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithBudget : 0,
                    this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithOutBudget ? this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithOutBudget : 0,
                    this.state.KPIData.NumberofCompletedProjectsTasksaccordingtotheplan ? this.state.KPIData.NumberofCompletedProjectsTasksaccordingtotheplan : 0,
                    this.state.KPIData.NumberofCompletedProjectTasksProjectsoutoftheplan ? this.state.KPIData.NumberofCompletedProjectTasksProjectsoutoftheplan : 0,
                    this.state.KPIData.NumberofCancelledProjectsTasksProjects ? this.state.KPIData.NumberofCancelledProjectsTasksProjects : 0,
                    this.state.KPIData.NumberofChangedProjectsTasksProjects ? this.state.KPIData.NumberofChangedProjectsTasksProjects : 0,
                    this.state.KPIData.NumberofNationalEmployeeswhohavePersonalDevelopmentPlan ? this.state.KPIData.NumberofNationalEmployeeswhohavePersonalDevelopmentPlan : 0,
                    this.state.KPIData.NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan ? this.state.KPIData.NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan : 0,
                    this.state.KPIData.NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans ? this.state.KPIData.NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans : 0,
                    this.state.KPIData.NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans ? this.state.KPIData.NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans : 0,
                    this.state.KPIData.NumberofNationalEmployeeswhohaveTrainingPlan ? this.state.KPIData.NumberofNationalEmployeeswhohaveTrainingPlan : 0,
                    this.state.KPIData.NumberofNonNationalEmployeeswhohaveTrainingPlan ? this.state.KPIData.NumberofNonNationalEmployeeswhohaveTrainingPlan : 0,
                    this.state.KPIData.NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan ? this.state.KPIData.NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan : 0,
                    this.state.KPIData.NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans ? this.state.KPIData.NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans : 0,
                    this.state.KPIData.NumberofNationalEmployeeswhohavenotTrainingPlan ? this.state.KPIData.NumberofNationalEmployeeswhohavenotTrainingPlan : 0,
                    this.state.KPIData.NumberofCompletedProjectsWithBudget ? this.state.KPIData.NumberofCompletedProjectsWithBudget : 0,
                    this.state.KPIData.NumberofCompletedProjectsWithoutBudget ? this.state.KPIData.NumberofCompletedProjectsWithoutBudget : 0
        
        
                    ]
        
                },
        
            ],
            colors: ["#6a96b0", "#676767", "#286385", "#1070a8", "#8e8f90"],
            title: {
                // text: i18n[this.getLocale()].eservicesStats
            },
            xaxis: {
                categories: [i18n[this.getLocale()].ActionPlans.NumberofApprovedInitiatives, i18n[this.getLocale()].ActionPlans.NumberofApprovedKeyProjects
                    , i18n[this.getLocale()].ActionPlans.NumberofKeyProjectsTasksundereachInitiativeswithBudget
                    , i18n[this.getLocale()].ActionPlans.NumberofKeyProjectsTasksundereachInitiativeswithOutBudget
                    , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectsTasksaccordingtotheplan
                    , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectTasksProjectsoutoftheplan
                    , i18n[this.getLocale()].ActionPlans.NumberofCancelledProjectsTasksProjects
                    , i18n[this.getLocale()].ActionPlans.NumberofChangedProjectsTasksProjects
                    , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohavePersonalDevelopmentPlan
                    , i18n[this.getLocale()].ActionPlans.NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan
                    , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans
                    , i18n[this.getLocale()].ActionPlans.NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans
                    , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohaveTrainingPlan
                    , i18n[this.getLocale()].ActionPlans.NumberofNonNationalEmployeeswhohaveTrainingPlan
                    , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan
                    , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans
                    , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohavenotTrainingPlan
                    , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectsWithBudget
                    , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectsWithoutBudget
                ],
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            yaxis: {
                title: {
                    text: undefined
                }
            },
            tooltip: {
                enabled: true,
                enabledOnSeries: true,
                shared: true,
                followCursor: true,
                intersect: false,
                inverseOrder: true,
                custom: undefined,
                fillSeriesColor: false,
                theme: 'light',
                style: {
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    backgroundColor: 'rgba(255, 255, 255, 0.96)'
                },
                onDatasetHover: {
                    highlightDataSeries: true,
                },
                x: {
                    show: true,
                    format: 'dd MMM',
                    formatter: (seriesName) => seriesName == i18n[this.getLocale()].shortNameForServices.bonusRequest ? i18n[this.getLocale()].bonusRequest : seriesName == i18n[this.getLocale()].shortNameForServices.amendSalary ? i18n[this.getLocale()].amendSalariesScale : seriesName == i18n[this.getLocale()].shortNameForServices.amendEmployee ? i18n[this.getLocale()].amendEmployeeSalary : seriesName == i18n[this.getLocale()].shortNameForServices.generalWizard ? i18n[this.getLocale()].sci : seriesName == i18n[this.getLocale()].shortNameForServices.informationRequest ? i18n[this.getLocale()].infoRequest : seriesName == i18n[this.getLocale()].shortNameForServices.exemptionPatient ? i18n[this.getLocale()].exceptionForPatient : seriesName == i18n[this.getLocale()].shortNameForServices.hrPolicy ? i18n[this.getLocale()].reviewHRPoliciesLable : seriesName == i18n[this.getLocale()].shortNameForServices.adHousing ? i18n[this.getLocale()].exceptionADHousing : seriesName == i18n[this.getLocale()].shortNameForServices.secondment ? i18n[this.getLocale()].secondmentForFederal : seriesName == i18n[this.getLocale()].shortNameForServices.retirment ? i18n[this.getLocale()].exceptionForRetirment : seriesName
                },
                y: {
                    formatter: undefined,
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                },
                z: {
                    formatter: undefined,
                    title: 'Size: '
                },
                marker: {
                    show: true,
                },
                items: {
                    display: 'flex',
                },
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: 40
            }
                
       };

       const ProjectsKPI =
{
    chart: {
        height: 350,
        type: "bar",
        background: '#ffffff',
        stacked: true,
        events: {
            animations: {
                enabled: true,
                easing: 'easeout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            dataPointSelection: (event, chartContext, config) => {
             
            }
        }
    },
    plotOptions: {
        bar: {
            horizontal: false,
            // columnWidth: "20%",
        }
    },
    series: [
        {
            name: i18n[this.getLocale()].ActionPlans.ProjectsKPI
            ,
            data: [this.state.KPIData.NumberofApprovedInitiatives ? this.state.KPIData.NumberofApprovedInitiatives : 0,

            this.state.KPIData.NumberofApprovedKeyProjects ? this.state.KPIData.NumberofApprovedKeyProjects : 0,
            this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithBudget ? this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithBudget : 0,
            this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithOutBudget ? this.state.KPIData.NumberofKeyProjectsTasksundereachInitiativeswithOutBudget : 0,
            this.state.KPIData.NumberofCompletedProjectsTasksaccordingtotheplan ? this.state.KPIData.NumberofCompletedProjectsTasksaccordingtotheplan : 0,
            this.state.KPIData.NumberofCompletedProjectTasksProjectsoutoftheplan ? this.state.KPIData.NumberofCompletedProjectTasksProjectsoutoftheplan : 0,
            this.state.KPIData.NumberofCancelledProjectsTasksProjects ? this.state.KPIData.NumberofCancelledProjectsTasksProjects : 0,
            this.state.KPIData.NumberofChangedProjectsTasksProjects ? this.state.KPIData.NumberofChangedProjectsTasksProjects : 0,
                        
                                    this.state.KPIData.NumberofCompletedProjectsWithBudget ? this.state.KPIData.NumberofCompletedProjectsWithBudget : 0,
            this.state.KPIData.NumberofCompletedProjectsWithoutBudget ? this.state.KPIData.NumberofCompletedProjectsWithoutBudget : 0


            ]

        },

    ],
    colors: ["#6a96b0", "#676767", "#286385", "#1070a8", "#8e8f90"],
    title: {
        // text: i18n[this.getLocale()].eservicesStats
    },
    xaxis: {
        categories: [i18n[this.getLocale()].ActionPlans.NumberofApprovedInitiatives, i18n[this.getLocale()].ActionPlans.NumberofApprovedKeyProjects
            , i18n[this.getLocale()].ActionPlans.NumberofKeyProjectsTasksundereachInitiativeswithBudget
            , i18n[this.getLocale()].ActionPlans.NumberofKeyProjectsTasksundereachInitiativeswithOutBudget
            , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectsTasksaccordingtotheplan
            , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectTasksProjectsoutoftheplan
            , i18n[this.getLocale()].ActionPlans.NumberofCancelledProjectsTasksProjects
            , i18n[this.getLocale()].ActionPlans.NumberofChangedProjectsTasksProjects
          
            , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectsWithBudget
            , i18n[this.getLocale()].ActionPlans.NumberofCompletedProjectsWithoutBudget
        ],
        labels: {
            formatter: function (val) {
                return val;
            }
        }
    },
    yaxis: {
        title: {
            text: undefined
        }
    },
    tooltip: {
        enabled: true,
        enabledOnSeries: true,
        shared: true,
        followCursor: true,
        intersect: false,
        inverseOrder: true,
        custom: undefined,
        fillSeriesColor: false,
        theme: 'light',
        style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            backgroundColor: 'rgba(255, 255, 255, 0.96)'
        },
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
            show: true,
            format: 'dd MMM',
            formatter: (seriesName) => seriesName == i18n[this.getLocale()].shortNameForServices.bonusRequest ? i18n[this.getLocale()].bonusRequest : seriesName == i18n[this.getLocale()].shortNameForServices.amendSalary ? i18n[this.getLocale()].amendSalariesScale : seriesName == i18n[this.getLocale()].shortNameForServices.amendEmployee ? i18n[this.getLocale()].amendEmployeeSalary : seriesName == i18n[this.getLocale()].shortNameForServices.generalWizard ? i18n[this.getLocale()].sci : seriesName == i18n[this.getLocale()].shortNameForServices.informationRequest ? i18n[this.getLocale()].infoRequest : seriesName == i18n[this.getLocale()].shortNameForServices.exemptionPatient ? i18n[this.getLocale()].exceptionForPatient : seriesName == i18n[this.getLocale()].shortNameForServices.hrPolicy ? i18n[this.getLocale()].reviewHRPoliciesLable : seriesName == i18n[this.getLocale()].shortNameForServices.adHousing ? i18n[this.getLocale()].exceptionADHousing : seriesName == i18n[this.getLocale()].shortNameForServices.secondment ? i18n[this.getLocale()].secondmentForFederal : seriesName == i18n[this.getLocale()].shortNameForServices.retirment ? i18n[this.getLocale()].exceptionForRetirment : seriesName
        },
        y: {
            formatter: undefined,
            title: {
                formatter: (seriesName) => seriesName,
            },
        },
        z: {
            formatter: undefined,
            title: 'Size: '
        },
        marker: {
            show: true,
        },
        items: {
            display: 'flex',
        },
        fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
        },
    },
    fill: {
        opacity: 1
    },
    legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
    }
};

const EmployeeInfo =
{
    chart: {
        height: 350,
        type: "bar",
        background: '#ffffff',
        stacked: true,
        events: {
            animations: {
                enabled: true,
                easing: 'easeout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            dataPointSelection: (event, chartContext, config) => {

            }
        }
    },
    plotOptions: {
        bar: {
            horizontal: false,
            // columnWidth: "20%",
        }
    },
    series: [
        {
            name: i18n[this.getLocale()].ActionPlans.EmployeeInfo
            ,
            data: [

                this.state.KPIData.NumberofNationalEmployeeswhohavePersonalDevelopmentPlan ? this.state.KPIData.NumberofNationalEmployeeswhohavePersonalDevelopmentPlan : 0,
                this.state.KPIData.NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan ? this.state.KPIData.NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan : 0,
                this.state.KPIData.NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans ? this.state.KPIData.NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans : 0,
                this.state.KPIData.NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans ? this.state.KPIData.NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans : 0,
                this.state.KPIData.NumberofNationalEmployeeswhohaveTrainingPlan ? this.state.KPIData.NumberofNationalEmployeeswhohaveTrainingPlan : 0,
                this.state.KPIData.NumberofNonNationalEmployeeswhohaveTrainingPlan ? this.state.KPIData.NumberofNonNationalEmployeeswhohaveTrainingPlan : 0,
                this.state.KPIData.NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan ? this.state.KPIData.NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan : 0,
                this.state.KPIData.NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans ? this.state.KPIData.NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans : 0,
                this.state.KPIData.NumberofNationalEmployeeswhohavenotTrainingPlan ? this.state.KPIData.NumberofNationalEmployeeswhohavenotTrainingPlan : 0,



            ]

        },

    ],
    colors: ["#6a96b0", "#676767", "#286385", "#1070a8", "#8e8f90"],
    title: {
        // text: i18n[this.getLocale()].eservicesStats
    },
    xaxis: {
        categories:

            [

                i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohavePersonalDevelopmentPlan
                , i18n[this.getLocale()].ActionPlans.NumberofNonNationalEmployeeswhohavePersonalDevelopmentPlan
                , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhoareincludedintheOrganizationalHealthsinitiativesPlans
                , i18n[this.getLocale()].ActionPlans.NumberofNonNationalEmployeeswhoareincludedintheinitiativesPlans
                , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohaveTrainingPlan
                , i18n[this.getLocale()].ActionPlans.NumberofNonNationalEmployeeswhohaveTrainingPlan
                , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohavenotPersonalDevelopmentPlan
                , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhoarenotincludedintheinitiativesPlans
                , i18n[this.getLocale()].ActionPlans.NumberofNationalEmployeeswhohavenotTrainingPlan

            ],
        labels: {
            formatter: function (val) {
                return val;
            }
        }
    },
    yaxis: {
        title: {
            text: undefined
        }
    },
    tooltip: {
        enabled: true,
        enabledOnSeries: true,
        shared: true,
        followCursor: true,
        intersect: false,
        inverseOrder: true,
        custom: undefined,
        fillSeriesColor: false,
        theme: 'light',
        style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            backgroundColor: 'rgba(255, 255, 255, 0.96)'
        },
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
            show: true,
            format: 'dd MMM',
            formatter: (seriesName) => seriesName == i18n[this.getLocale()].shortNameForServices.bonusRequest ? i18n[this.getLocale()].bonusRequest : seriesName == i18n[this.getLocale()].shortNameForServices.amendSalary ? i18n[this.getLocale()].amendSalariesScale : seriesName == i18n[this.getLocale()].shortNameForServices.amendEmployee ? i18n[this.getLocale()].amendEmployeeSalary : seriesName == i18n[this.getLocale()].shortNameForServices.generalWizard ? i18n[this.getLocale()].sci : seriesName == i18n[this.getLocale()].shortNameForServices.informationRequest ? i18n[this.getLocale()].infoRequest : seriesName == i18n[this.getLocale()].shortNameForServices.exemptionPatient ? i18n[this.getLocale()].exceptionForPatient : seriesName == i18n[this.getLocale()].shortNameForServices.hrPolicy ? i18n[this.getLocale()].reviewHRPoliciesLable : seriesName == i18n[this.getLocale()].shortNameForServices.adHousing ? i18n[this.getLocale()].exceptionADHousing : seriesName == i18n[this.getLocale()].shortNameForServices.secondment ? i18n[this.getLocale()].secondmentForFederal : seriesName == i18n[this.getLocale()].shortNameForServices.retirment ? i18n[this.getLocale()].exceptionForRetirment : seriesName
        },
        y: {
            formatter: undefined,
            title: {
                formatter: (seriesName) => seriesName,
            },
        },
        z: {
            formatter: undefined,
            title: 'Size: '
        },
        marker: {
            show: true,
        },
        items: {
            display: 'flex',
        },
        fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
        },
    },
    fill: {
        opacity: 1
    },
    legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
    }
};
        return(
        <MDBContainer fluid style={{ height: '100vh' }}>
        <LoadingOverlay
                active={this.state.isLoading}
                spinner={true}>
                <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                <MDBRow
                    className="formWhiteContainer"
                    style={{
                        //  width: '100vw',
                        flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                    }}>
                            <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                                <MDBRow className="paddingtop15px">
                                <MDBCol xs="12" sm="12" md="6" lg="12" xl="12">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.EntitName}<span className="requireStar">*</span>   
                            </label>
                      
                            <select style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForEntity}
                                        value={this.state.entitySelectedValue}
                                        required>
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                                return <option value={item.id}>{this.state.isArabic ? item.arabicName : item.englishName}</option>
                                            }) : null
                                        }
                                    </select>
                                   
                            
                        </div>
                    </MDBCol>

                                    </MDBRow>
                        <MDBRow>

                            <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                <SubHeaderStrip
                                    goBack={() => {
                                        Config.setIsEditFlag(false);
                                        this.props.history.replace('/ActionPlansListinternal', { pathname: 'ActionPlansListinternal' })
                                    }}
                                    enableCreate={false}
                                    create={this.create}
                                    isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.KPI}
                                />
                                <div style={{ paddingTop: "10px" }}>
                                <MDBRow
            style={{ justifyContent: 'center', flexDirection: this.state.isArabic ? "row-reverse" : "row", marginTop: "2%" }}>
            <MDBBtn
                style={{ borderRadius: '10px' }}
                type="submit"
                onClick={this.OverAllClick}
                color="primary"
                className="BtnDashBoard">
                {i18n[this.getLocale()].ActionPlans.OverAll}
            </MDBBtn>
            <MDBBtn
                style={{ borderRadius: '10px' }}
                type="submit"
                color="primary"
                onClick={this.ProjectsKPIClick}
                className="BtnDashBoard">
                {i18n[this.getLocale()].ActionPlans.ProjectsKPI}
            </MDBBtn>
            <MDBBtn
                style={{ borderRadius: '10px' }}
                type="submit"
                color="primary"
                onClick={this.EmployeeInfolick}
                className="BtnDashBoard">
                {i18n[this.getLocale()].ActionPlans.EmployeeInfo}
            </MDBBtn>
          
        </MDBRow>
                                <ApexCharts
                                                    options={
                                                        this.state.OverAll ? 
                                                        OverAll : this.state.ProjectsKPI
                                                        ?ProjectsKPI : EmployeeInfo
                                                    }
                                                    series={
                                                        this.state.OverAll ? 
                                                        OverAll.series : this.state.ProjectsKPI
                                                        ?ProjectsKPI.series : EmployeeInfo.series
                                                        
                                                    }
                                                    type="bar"
                                                    width="100%" height='560'
                                                />
                                </div>
                                <div style={{clear:"both",height:"100px"}}/>
                                  <Footer isArabic={this.state.isArabic} />

                            </MDBCol>
                             </MDBRow>
                        </MDBCol>
                        <SideBar
                            canViewSCI={true}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                      </MDBRow>
   </LoadingOverlay>
   </MDBContainer>);
    }
}