import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
// import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from './../components/Header';
import SideBar from './../components/SideBar';
import StepOne from './../components/BonusRequestSteps/StepOne';
import StepTwo from './../components/BonusRequestSteps/StepTwo';
import StepThree from './../components/BonusRequestSteps/StepThree';
import SubHeaderStrip from './../components/SubHeaderStrip';
import Footer from './../components/Footer';
import i18n from './../i18n';
import Config from './../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import ApiRequest from './../services/ApiRequest';
import { saveBonusRequestPayload, uploadDocument } from './../utils/Payload';
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';

export default class BonusRequest extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            step1: true,
            step2: false,
            step3: false,
            isEdit: false,
            requestId: '',
            statusid: '',
            formType: "",
            canviewbonusrequest: 0,
            canviewamendscalerequest: 0,
            canviewamendsalaryrequest: 0,
            isLoading: false,
            theme: 'light',
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,
            screenNumber: 1,
            //entityLogo: "",
            entityName: "",
            cordinatorName: "",
            jobName: "",
            contactNumber: "",
            contactNumberMobile: "",
            contactNumber2: "",
            contactNumberMobile2: "",
            email: "",
            email2: "",
            approvedGradesAndSalaries: "",
            haveBudget: "Yes",
            haveBudget2: '',
            requestAnnualBonus: '',
            enterTheBudget: "",
            budgetRelatedDocument: "",
            reasonsToPayBonuses: "",
            entityAchivementsForYear: "",
            enterTheYear1: "",
            balancedScoreCard: "",
            financialStatement: "",
            taxSummary: "",
            totalEmployeesOfEntityDetails: "",
            percentageOfEmariatisation: "",
            enterTheYear2: "",
            resultsOfEvaluationGeneralPerformance: "",
            resultsOfEvaluationGeneralPerformanceShortName: "",
            privateContractsEmployeeYes: "",
            uploadTheContracts: "",
            uploadTheContractsShortName: "",
            explainTheEquation: "",
            totalNumberOfEmployees: "",
            presentationforEntityAcheivementsDocument: '',
            numberGredeEmployees: "",
            uploadTheExcelAfterFill: "",
            HraStudy: "",
            isSideBarMinimized: false,
            percentageOne: '',
            percentageTwo: '',
            yearOne: '',
            yearTwo: '',
            totalNumberWithPrivateContract: '',
            totalNumberWithPermenantContract: '',
            totalNumberWithnonPrivateContract: '',
            totalNumberWithnonPermenantContract: '',
            excellentTotal: '',
            veryGood: '',
            showResultVeryGood: false,
            excellent: '',
            showResultExcellent: false,
            good: '',
            showResultGood: false,
            acceptable: '',
            showResultAcceptable: false,
            weak: '',
            showResultWeak: false
        }
    }

    componentDidMount = () => {
       
        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: false,
                step3: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewbonusrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewbonusrequest,
                    canviewamendscalerequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendscalerequest,
                    canviewamendsalaryrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewamendsalaryrequest
                }, () => {
                })
            })
        }
    }

    componentWillMount() {
        let previewFlag = Config.getIsPreviewFlag();
        let previewCKObj = Config.getPreviewckEditorArray();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();

        if (previewFlag) {

            let approvedGradesAndSalaries = '',
                balancedScoreCard = '',
                financialStatement = '',
                uploadTheExcelAfterFill = '',
                resultsOfEvaluationGeneralPerformance = '',
                uploadTheContracts = '',
                budgetRelatedDocument = '',
                presentationforEntityAcheivementsDocument = '';

            if (previewAttachmentArray) {
                for (let index = 0; index < previewAttachmentArray.length; index++) {
                    if (previewAttachmentArray[index].label === 'approvedGradesAndSalaries') {
                        approvedGradesAndSalaries = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'balancedScoreCard') {
                        balancedScoreCard = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'financialStatement') {
                        financialStatement = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'uploadTheExcelAfterFill') {
                        uploadTheExcelAfterFill = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'resultsOfEvaluationGeneralPerformance') {
                        resultsOfEvaluationGeneralPerformance = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'uploadTheContracts') {
                        uploadTheContracts = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'budgetRelatedDocument') {
                        budgetRelatedDocument = previewAttachmentArray[index].fileName;
                    }
                    else if (previewAttachmentArray[index].label === 'presentationforEntityAcheivementsDocument') {
                        presentationforEntityAcheivementsDocument = previewAttachmentArray[index].fileName;
                    }
                }
            }

            let
                isbudgeted = '',
                yearachievment = '',
                bonusreason = '',
                selectedOptionHaveBudget = '';

            if (previewCKObj) {
                for (let index = 0; index < previewCKObj.length; index++) {
                    if (previewCKObj[index].label === 'isfixedvaluebonus') {
                        isbudgeted = previewCKObj[index].value;

                        if (isbudgeted === i18n["ar"].yes || isbudgeted === i18n["en"].yes) {
                            selectedOptionHaveBudget = "Yes";
                        }
                        else if (isbudgeted === i18n["ar"].no || isbudgeted === i18n["en"].no) {
                            selectedOptionHaveBudget = "No";
                        }
                    } else if (previewCKObj[index].label === 'equation') {
                        yearachievment = previewCKObj[index].value;
                    } else if (previewCKObj[index].label === 'bonusreason') {
                        bonusreason = previewCKObj[index].value;
                    }
                }
            }

            this.setState({
                step1: false,
                step2: false,
                step3: true,
                isEdit: previewObj.isEdit,
                requestId: previewObj.requestId,
                entityName: previewObj.entityname,
                cordinatorName: previewObj.coordinatorname,
                jobName: previewObj.jobname,
                contactNumber: previewObj.contactnumber,
                contactNumberMobile: previewObj.phonenumber,
                email: previewObj.email,
                cordinatorName2: previewObj.subcoordinatorname,
                jobName2: previewObj.subjobname,
                contactNumber2: previewObj.subcontactnumber,
                contactNumberMobile2: previewObj.subphonenumber,
                email2: previewObj.subemail,
                enterTheBudget: previewObj.budget,
                enterTheYear1: previewObj.budgetyear,
                percentageOne: previewObj.emiratizationpercentage,
                percentageTwo: previewObj.emiratizationpercentage2,
                yearOne: previewObj.year1,
                yearTwo: previewObj.year2,
                entityAchivementsForYear: yearachievment,
                totalNumberWithPrivateContract: previewObj.table1.localwithprivate,
                totalNumberWithPermenantContract: previewObj.table1.localwithpermanent,
                totalNumberWithnonPrivateContract: previewObj.table1.nonlocalwithprivate,
                totalNumberWithnonPermenantContract: previewObj.table1.nonlocalwithpermanent,
                totalNumberOfEmployees: previewObj.totalemployees,
                veryGood: previewObj.table2.verygoodemployees,
                excellent: previewObj.table2.excellentemployees,
                good: previewObj.table2.goodemployees,
                acceptable: previewObj.table2.acceptableemployees,
                weak: previewObj.table2.weakemployees,
                haveBudget2: selectedOptionHaveBudget,
                explainTheEquation: yearachievment,
                reasonsToPayBonuses: bonusreason,
                approvedGradesAndSalaries: approvedGradesAndSalaries,
                balancedScoreCard: balancedScoreCard,
                financialStatement: financialStatement,
                uploadTheExcelAfterFill: uploadTheExcelAfterFill,
                resultsOfEvaluationGeneralPerformance: resultsOfEvaluationGeneralPerformance,
                resultsOfEvaluationGeneralPerformanceShortName: previewObj.resultsOfEvaluationGeneralPerformanceShortName,

                uploadTheContracts: uploadTheContracts,
                uploadTheContractsShortName: previewObj.uploadTheContractsShortName,
                budgetRelatedDocument: budgetRelatedDocument,
                presentationforEntityAcheivementsDocument: presentationforEntityAcheivementsDocument
            });
        }

    }

    toggleTheme = () => {
        const theme = this.state.theme === 'light' ? 'dark' : 'light';
        this.setState({ theme: theme });
        document.documentElement.classList.add('theme-transition');
        document.documentElement.setAttribute('data-theme', theme);
        setTimeout(() => {
            document.documentElement.classList.remove('theme-transition');
        }, 1000);
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    nextButtonPressed = (screenNumber) => {
        this.setState({ screenNumber: screenNumber });
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    stepOneData = (obj) => {
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            setConfigObj.entityname = obj.entityName;
            setConfigObj.coordinatorname = obj.cordinatorName;
            setConfigObj.jobname = obj.jobName;
            setConfigObj.contactnumber = obj.contactNumber;
            setConfigObj.phonenumber = obj.contactNumberMobile;
            setConfigObj.email = obj.email;
            setConfigObj.subcoordinatorname = obj.cordinatorName2;
            setConfigObj.subjobname = obj.jobName2;
            setConfigObj.subcontactnumber = obj.contactNumber2;
            setConfigObj.subphonenumber = obj.contactNumberMobile2;
            setConfigObj.subemail = obj.email2;
            setConfigObj.isbudgeted = obj.haveBudget;
            setConfigObj.bonusreason = obj.reasonsToPayBonuses;
            setConfigObj.yearachievment = obj.entityAchivementsForYear;
            setConfigObj.budget = obj.enterTheBudget;
            setConfigObj.budgetyear = obj.enterTheYear1;
            setConfigObj.emiratizationpercentage = obj.percentageOne;
            setConfigObj.emiratizationpercentage2 = obj.percentageTwo;
            setConfigObj.year1 = obj.yearOne;
            setConfigObj.year2 = obj.yearTwo;

            Config.setEditObj(setConfigObj);
        }

        this.setState({
            step1: false,
            step2: true,
            step3: false,
            entityName: obj.entityName,
            cordinatorName: obj.cordinatorName,
            jobName: obj.jobName,
            contactNumber: obj.contactNumber,
            contactNumberMobile: obj.contactNumberMobile,
            email: obj.email,
            cordinatorName2: obj.cordinatorName2,
            jobName2: obj.jobName2,
            contactNumber2: obj.contactNumber2,
            contactNumberMobile2: obj.contactNumberMobile2,
            email2: obj.email2,
            haveBudget: obj.haveBudget,
            reasonsToPayBonuses: obj.reasonsToPayBonuses,
            entityAchivementsForYear: obj.entityAchivementsForYear,
            enterTheBudget: obj.enterTheBudget,
            enterTheYear1: obj.enterTheYear1,
            percentageOne: obj.percentageOne,
            percentageTwo: obj.percentageTwo,
            yearOne: obj.yearOne,
            yearTwo: obj.yearTwo,
            step2: true,
            step1: false,
            step3: false,
        });
    };

    stepTwoData = (obj) => {
        let editObj = Config.getEditObj();

        if (editObj) {
            let setConfigObj = editObj;

            setConfigObj.equation = obj.explainTheEquation;
            setConfigObj.localwithprivate = obj.totalNumberWithPrivateContract;
            setConfigObj.localwithpermanent = obj.totalNumberWithPermenantContract;
            setConfigObj.nonlocalwithprivate = obj.totalNumberWithnonPrivateContract;
            setConfigObj.nonlocalwithpermanent = obj.totalNumberWithnonPermenantContract;
            setConfigObj.totalemployees = obj.totalNumberOfEmployees;
            setConfigObj.verygoodemployees = obj.veryGood;
            setConfigObj.excellentemployees = obj.excellent;
            setConfigObj.goodemployees = obj.good;
            setConfigObj.acceptableemployees = obj.acceptable;
            setConfigObj.weakemployees = obj.weak;
            setConfigObj.isfixedvaluebonus = obj.haveBudget2 === "Yes" ? 1 : 0;

            Config.setEditObj(setConfigObj);
        }

        this.setState({
            step1: false,
            step2: false,
            step3: true,
            explainTheEquation: obj.explainTheEquation,
            totalNumberWithPrivateContract: obj.totalNumberWithPrivateContract,
            totalNumberWithPermenantContract: obj.totalNumberWithPermenantContract,
            totalNumberWithnonPrivateContract: obj.totalNumberWithnonPrivateContract,
            totalNumberWithnonPermenantContract: obj.totalNumberWithnonPermenantContract,
            totalNumberOfEmployees: obj.totalNumberOfEmployees,
            // excellentTotal: obj.excellentTotal,
            veryGood: obj.veryGood,
            showResultVeryGood: obj.showResultVeryGood,
            excellent: obj.excellent,
            showResultExcellent: obj.showResultExcellent,
            good: obj.good,
            showResultGood: obj.showResultGood,
            acceptable: obj.acceptable,
            showResultAcceptable: obj.showResultAcceptable,
            weak: obj.weak,
            showResultWeak: obj.showResultWeak,
            // haveBudget: obj.haveBudget,
            haveBudget2: obj.haveBudget2,
            requestAnnualBonus: obj.requestAnnualBonus
        });
    };

    stepThreeData = (obj) => {
        if (obj.saveFrom === 'step1') {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                entityName: obj.entityName,
                cordinatorName: obj.cordinatorName,
                jobName: obj.jobName,
                contactNumber: obj.contactNumber,
                contactNumberMobile: obj.contactNumberMobile,
                email: obj.email,
                cordinatorName2: obj.cordinatorName2,
                jobName2: obj.jobName2,
                contactNumber2: obj.contactNumber2,
                contactNumberMobile2: obj.contactNumberMobile2,
                email2: obj.email2,
                haveBudget: obj.haveBudget,
                reasonsToPayBonuses: obj.reasonsToPayBonuses,
                entityAchivementsForYear: obj.entityAchivementsForYear,
                enterTheBudget: obj.enterTheBudget,
                enterTheYear1: obj.enterTheYear1,
                percentageOne: obj.percentageOne,
                percentageTwo: obj.percentageTwo,
                yearOne: obj.yearOne,
                yearTwo: obj.yearTwo,
            }, () => {
                this.submitAPICall();
            });
        } else if (obj.saveFrom === 'step2') {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                explainTheEquation: obj.explainTheEquation,
                totalNumberWithPrivateContract: obj.totalNumberWithPrivateContract,
                totalNumberWithPermenantContract: obj.totalNumberWithPermenantContract,
                totalNumberWithnonPrivateContract: obj.totalNumberWithnonPrivateContract,
                totalNumberWithnonPermenantContract: obj.totalNumberWithnonPermenantContract,
                totalNumberOfEmployees: obj.totalNumberOfEmployees,
                // excellentTotal: obj.excellentTotal,
                veryGood: obj.veryGood,
                showResultVeryGood: obj.showResultVeryGood,
                excellent: obj.excellent,
                showResultExcellent: obj.showResultExcellent,
                good: obj.good,
                showResultGood: obj.showResultGood,
                acceptable: obj.acceptable,
                showResultAcceptable: obj.showResultAcceptable,
                weak: obj.weak,
                showResultWeak: obj.showResultWeak,
                // haveBudget: obj.haveBudget,
                haveBudget2: obj.haveBudget2,
                requestAnnualBonus: obj.requestAnnualBonus
            }, () => {
                this.submitAPICall();
            });
        } else {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                //entityLogo: obj.entityLogo,
                approvedGradesAndSalaries: obj.approvedGradesAndSalaries,
                balancedScoreCard: obj.balancedScoreCard,
                financialStatement: obj.financialStatement,
                // taxSummary: obj.taxSummary,
                resultsOfEvaluationGeneralPerformance: obj.resultsOfEvaluationGeneralPerformance,
                uploadTheContracts: obj.uploadTheContracts,
                uploadTheContractsShortName: obj.uploadTheContractsShortName,
                uploadTheExcelAfterFill: obj.uploadTheExcelAfterFill,
                budgetRelatedDocument: obj.budgetRelatedDocument,
                presentationforEntityAcheivementsDocument: obj.presentationforEntityAcheivementsDocument,
                haveBudget: obj.haveBudget,
                haveBudget2: obj.haveBudget2,
            }, () => {
                this.submitAPICall();
            });
        }
    };

    submitAPICall = () => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let reqObj = {};
                reqObj.isEdit = this.state.isEdit;
                reqObj.requestId = this.state.requestId;
                reqObj.entityname = this.state.entityName;
                reqObj.jobname = this.state.jobName;
                reqObj.coordinatorname = this.state.cordinatorName;
                reqObj.email = this.state.email;
                reqObj.contactnumber = this.state.contactNumber;
                reqObj.phonenumber = this.state.contactNumberMobile;
                reqObj.subcoordinatorname = this.state.cordinatorName2;
                reqObj.subjobname = this.state.jobName2;
                reqObj.subcontactnumber = this.state.contactNumber2;
                reqObj.subphonenumber = this.state.contactNumberMobile2;
                reqObj.subemail = this.state.email2;

                if (this.state.haveBudget === 'Yes') {
                    reqObj.isbudgeted = "1";
                } else {
                    reqObj.isbudgeted = "0";
                }
                reqObj.budget = this.state.enterTheBudget;
                reqObj.budgetyear = this.state.enterTheYear1;
                reqObj.yearachievment = this.state.entityAchivementsForYear;
                reqObj.bonusreason = this.state.reasonsToPayBonuses;
                reqObj.year1 = this.state.yearOne;
                reqObj.emiratizationpercentage = this.state.percentageOne;
                // reqObj.totalemployeedetails = this.state.totalEmployeesOfEntityDetails;
                reqObj.year2 = this.state.yearTwo;
                reqObj.iscontracted = this.state.haveBudget2 === "Yes" ? "1" : "0";
                reqObj.totalemployees = this.state.totalNumberOfEmployees;
                reqObj.equation = this.state.explainTheEquation;
                reqObj.gradeemployees = "test";
                reqObj.emiratizationpercentage2 = this.state.percentageTwo;
                reqObj.nonlocalwithprivate = this.state.totalNumberWithnonPrivateContract;
                reqObj.localwithprivate = this.state.totalNumberWithPrivateContract;
                reqObj.nonlocalwithpermanent = this.state.totalNumberWithnonPermenantContract;
                reqObj.localwithpermanent = this.state.totalNumberWithPermenantContract;
                reqObj.excellentemployees = this.state.excellent;
                reqObj.verygoodemployees = this.state.veryGood;
                reqObj.goodemployees = this.state.good;
                reqObj.acceptableemployees = this.state.acceptable;
                reqObj.weakemployees = this.state.weak;
                reqObj.requestAnnualBonus = this.state.requestAnnualBonus;

                if (this.state.haveBudget2 === "Yes") {
                    reqObj.isfixedvaluebonus = "1";
                } else {
                    reqObj.isfixedvaluebonus = "0";
                }

                if (this.state.formType === "saveAsDraft") {
                    reqObj.draft = 1;
                } else {
                    reqObj.draft = 0;
                }

                let payload = saveBonusRequestPayload(reqObj);
                let endPoint = 'Savebounsrequest';
                let url = Config.getUrl(endPoint);

                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resBonusRequest => {
                        if (resBonusRequest.Status) {
                            // let isbudgeted = resBonusRequest.data.isbudgeted;
                            // let isfixedvaluebonus = resBonusRequest.data.isfixedvaluebonus;
                            let totalCount = 8;
                            this.uploadFile(0, totalCount, resBonusRequest, 0);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            })
                        }
                    })
                })
            })
        }
    }

    uploadFile = (i, totalCount, resBonusRequest, multifileCount) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};
            let isLastFileInMultiUpload = false;

            if (i === 0) {
                fileObj = Config.getBase64rBonusRequest_ForApprovedGrades();
                obj.filename = this.state.approvedGradesAndSalaries;
                obj.filetype = Config.getBonusApprovedGradesAndSalarieId();
                tempBase64 = fileObj;
            }
            else if (i === 1 && this.state.haveBudget === "Yes") {
                fileObj = Config.getBase64ForBonusRequest_BudgeDoc();
                obj.filename = this.state.budgetRelatedDocument;
                obj.filetype = Config.getBonusBudgetReletedDocumentId();
                tempBase64 = fileObj;
            }
            else if (i === 1 && this.state.haveBudget === "No") {
                this.uploadFile(i + 1, totalCount, resBonusRequest, 0);
                return;
            }
            else if (i === 2) {
                fileObj = Config.getBase64BonusRequest_FinancialStatement();
                obj.filename = this.state.financialStatement;
                obj.filetype = Config.getBonusFinacialStatementId();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                fileObj = Config.getBase64BonusRequest_BalanceScoreCard();
                obj.filename = this.state.balancedScoreCard;
                obj.filetype = Config.getBonusBalancedScoreCardId();
                tempBase64 = fileObj;
            }
            else if (i === 4 && this.state.haveBudget2 === "Yes") {
                let fileObjArray = Config.getBase64BonusRequest_UploadContrACT();
                if (fileObjArray && fileObjArray.length > 0) {
                    if (multifileCount === fileObjArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjArray[multifileCount];
                }
                if (this.state.uploadTheContracts !== null && this.state.uploadTheContracts !== undefined) {
                    let filenamesArray = this.state.uploadTheContracts.split(",");
                    obj.filename = filenamesArray[multifileCount];
                }
                obj.filetype = Config.getBonusForPrivateContractId();
                tempBase64 = fileObj;
            }
            else if (i === 4 && this.state.haveBudget2 === "No") {
                this.uploadFile(i + 1, totalCount, resBonusRequest, 0);
                return;
            }
            else if (i === 5) {
                let fileObjEvaluationGeneralPerformanceArray = Config.getBase64BonusRequest_resultsOfEvaluationGeneralPerformance();
                if (fileObjEvaluationGeneralPerformanceArray && fileObjEvaluationGeneralPerformanceArray.length > 0) {
                    if (multifileCount === fileObjEvaluationGeneralPerformanceArray.length - 1) {
                        isLastFileInMultiUpload = true;
                    }
                    fileObj = fileObjEvaluationGeneralPerformanceArray[multifileCount];
                }
                if (this.state.resultsOfEvaluationGeneralPerformance !== null && this.state.resultsOfEvaluationGeneralPerformance !== undefined) {
                    let fileNamesGenralPerformanceArray = this.state.resultsOfEvaluationGeneralPerformance.split(",");
                    obj.filename = fileNamesGenralPerformanceArray[multifileCount];
                }
                obj.filetype = Config.getBonusResultForEvalutionForGeneralPerformanceId();
                tempBase64 = fileObj;
            }
            else if (i === 6) {
                fileObj = Config.getBase64BonusRequest_uploadExcelAfterFill();
                obj.filename = this.state.uploadTheExcelAfterFill;
                obj.filetype = Config.getBonusUploadExcelAfterFillId();
                tempBase64 = fileObj;
            }
            else if (i === 7) {
                fileObj = Config.getBase64BonusRequest_PresentationforEntityAcheivements();
                obj.filename = this.state.presentationforEntityAcheivementsDocument;
                obj.filetype = Config.getPresentationforEntityAcheivementsId();
                tempBase64 = fileObj;
            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resBonusRequest && resBonusRequest.data && resBonusRequest.data.id) {
                    reqId = resBonusRequest.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resBonusRequest.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        if (i === 4) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, resBonusRequest, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, resBonusRequest, multifileCount + 1);
                            }
                        } else if (i === 6) {
                            if (isLastFileInMultiUpload) {
                                this.uploadFile(i + 1, totalCount, resBonusRequest, multifileCount + 1);
                            } else {
                                this.uploadFile(i, totalCount, resBonusRequest, multifileCount + 1);
                            }
                        } else {
                            this.uploadFile(i + 1, totalCount, resBonusRequest, 0);
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        })
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resBonusRequest, 0);
            }
        }
        else {
          
            this.setState({
                isLoading: false
            }, () => {
                // this.props.history.replace("/listPage", {
                //     pathname: localStorage.getItem('currentPath')
                // });
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            });
        }
    }

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData,
        stepThreeData: this.stepThreeData,
    };

    getTitle = () => {
        switch (this.props.location.state.pathname) {
            case 'bonusRequest':
                return 'Bonus Request';
            case 'amendSalaryScreen':
                return 'Amend Salaries Scale';
            case 'amendEmployeeSalary':
                return 'Amend Employee Salary';
            default:
                return '';
        }
    }

    stepTwoDataPrev = (obj) => {

        let editObj = Config.getEditObj();

        if (editObj) {
            let setConfigObj = editObj;
            setConfigObj.equation = obj.explainTheEquation;
            setConfigObj.localwithprivate = obj.totalNumberWithPrivateContract;
            setConfigObj.localwithpermanent = obj.totalNumberWithPermenantContract;
            setConfigObj.nonlocalwithprivate = obj.totalNumberWithnonPrivateContract;
            setConfigObj.nonlocalwithpermanent = obj.totalNumberWithnonPermenantContract;
            setConfigObj.totalemployees = obj.totalNumberOfEmployees;
            setConfigObj.verygoodemployees = obj.veryGood;
            setConfigObj.excellentemployees = obj.excellent;
            setConfigObj.goodemployees = obj.good;
            setConfigObj.acceptableemployees = obj.acceptable;
            setConfigObj.weakemployees = obj.weak;
            setConfigObj.isfixedvaluebonus = obj.haveBudget2 === "Yes" ? 1 : 0;

            Config.setEditObj(setConfigObj);
        }

        this.setState({
            step1: true, step2: false, step3: false,
            // haveBudget: obj.haveBudget,
            haveBudget2: obj.haveBudget2,
            // requestAnnualBonus: obj.requestAnnualBonus,
            explainTheEquation: obj.explainTheEquation,
            totalNumberWithPrivateContract: obj.totalNumberWithPrivateContract,
            totalNumberWithPermenantContract: obj.totalNumberWithPermenantContract,
            totalNumberWithnonPrivateContract: obj.totalNumberWithnonPrivateContract,
            totalNumberWithnonPermenantContract: obj.totalNumberWithnonPermenantContract,
            totalNumberOfEmployees: obj.totalNumberOfEmployees,
            // excellentTotal: obj.excellentTotal,
            veryGood: obj.veryGood,
            showResultVeryGood: obj.showResultVeryGood,
            excellent: obj.excellent,
            showResultExcellent: obj.showResultExcellent,
            good: obj.good,
            showResultGood: obj.showResultGood,
            acceptable: obj.acceptable,
            showResultAcceptable: obj.showResultAcceptable,
            weak: obj.weak,
            showResultWeak: obj.showResultWeak
        });
    }

    stepThreeDataPrev = (obj) => {
        this.setState({
            step1: false, step2: true, step3: false,
            approvedGradesAndSalaries: obj.approvedGradesAndSalaries,
            balancedScoreCard: obj.balancedScoreCard,
            financialStatement: obj.financialStatement,
            resultsOfEvaluationGeneralPerformance: obj.resultsOfEvaluationGeneralPerformance,
            resultsOfEvaluationGeneralPerformanceShortName: obj.resultsOfEvaluationGeneralPerformanceShortName,
            uploadTheContracts: obj.uploadTheContracts,
            uploadTheContractsShortName: obj.uploadTheContractsShortName,
            uploadTheExcelAfterFill: obj.uploadTheExcelAfterFill,
            budgetRelatedDocument: obj.budgetRelatedDocument,
            haveBudget: obj.haveBudget,
            haveBudget2: obj.haveBudget2,
            presentationforEntityAcheivementsDocument: obj.presentationforEntityAcheivementsDocument
        });

    }

    getPrev = {
        stepTwoDataPrev: this.stepTwoDataPrev,
        stepThreeDataPrev: this.stepThreeDataPrev,
    };

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    handlePreViewNavigation = (obj) => {
        let editObj = Config.getEditObj();

        let reqObj = {};
        reqObj.isEdit = editObj.isEdit;
        reqObj.requestId = editObj.requestId;
        reqObj.entityname = this.state.entityName;
        reqObj.jobname = this.state.jobName;
        reqObj.coordinatorname = this.state.cordinatorName;
        reqObj.email = this.state.email;
        reqObj.contactnumber = this.state.contactNumber;
        reqObj.phonenumber = this.state.contactNumberMobile;
        reqObj.subcoordinatorname = this.state.cordinatorName2;
        reqObj.subjobname = this.state.jobName2;
        reqObj.subcontactnumber = this.state.contactNumber2;
        reqObj.subphonenumber = this.state.contactNumberMobile2;
        reqObj.subemail = this.state.email2;
        // reqObj.isbudgeted = this.state.haveBudget;
        reqObj.budget = this.state.enterTheBudget;
        reqObj.budgetyear = this.state.enterTheYear1;
        // reqObj.yearachievment = this.state.entityAchivementsForYear;
        // reqObj.bonusreason = this.state.reasonsToPayBonuses;
        reqObj.year1 = this.state.yearOne;
        reqObj.emiratizationpercentage = this.state.percentageOne;
        // ============reqObj.totalemployeedetails = this.state.totalEmployeesOfEntityDetails;
        reqObj.year2 = this.state.yearTwo;
        // reqObj.iscontracted = this.state.haveBudget2 ;
        reqObj.totalemployees = this.state.totalNumberOfEmployees;
        // reqObj.equation = this.state.explainTheEquation;
        // ======reqObj.gradeemployees = "test";
        reqObj.emiratizationpercentage2 = this.state.percentageTwo;
        // reqObj.isfixedvaluebonus = this.state.haveBudget2;

        reqObj.resultsOfEvaluationGeneralPerformanceShortName = obj.resultsOfEvaluationGeneralPerformanceShortName;
        reqObj.uploadTheContractsShortName = obj.uploadTheContractsShortName;

        //Bonus table 1
        reqObj.table1 = {
            localwithpermanent: this.state.totalNumberWithPermenantContract,
            localwithprivate: this.state.totalNumberWithPrivateContract,
            nonlocalwithpermanent: this.state.totalNumberWithnonPermenantContract,
            nonlocalwithprivate: this.state.totalNumberWithnonPrivateContract
        }

        //Bonus table 2
        reqObj.table2 = {
            excellentemployees: this.state.excellent,
            verygoodemployees: this.state.veryGood,
            goodemployees: this.state.good,
            acceptableemployees: this.state.acceptable,
            weakemployees: this.state.weak,
            excellentemployeesPercentage: (this.state.excellent / this.state.totalNumberOfEmployees * 100).toFixed(2),
            verygoodemployeesPercentage: (this.state.veryGood / this.state.totalNumberOfEmployees * 100).toFixed(2),
            goodemployeesPercentage: (this.state.good / this.state.totalNumberOfEmployees * 100).toFixed(2),
            acceptableemployeesPercentage: (this.state.acceptable / this.state.totalNumberOfEmployees * 100).toFixed(2),
            weakemployeesPercentage: (this.state.weak / this.state.totalNumberOfEmployees * 100).toFixed(2)
        }

        let attachmentArr = [
            {
                label: 'approvedGradesAndSalaries',
                fileName: obj.approvedGradesAndSalaries,
                fileObj: Config.getBase64rBonusRequest_ForApprovedGrades()
            },
            {
                label: 'balancedScoreCard',
                fileName: obj.balancedScoreCard,
                fileObj: Config.getBase64BonusRequest_BalanceScoreCard()
            },
            {
                label: 'financialStatement',
                fileName: obj.financialStatement,
                fileObj: Config.getBase64BonusRequest_FinancialStatement()
            },
            {
                label: 'resultsOfEvaluationGeneralPerformance',
                fileName: obj.resultsOfEvaluationGeneralPerformance,
                fileObj: Config.getBase64BonusRequest_resultsOfEvaluationGeneralPerformance()
            },

            {
                label: 'uploadTheExcelAfterFill',
                fileName: obj.uploadTheExcelAfterFill,
                fileObj: Config.getBase64BonusRequest_uploadExcelAfterFill()
            },
            {
                label: 'budgetRelatedDocument',
                fileName: obj.budgetRelatedDocument,
                fileObj: Config.getBase64ForBonusRequest_BudgeDoc()
            },
            {
                label: 'presentationforEntityAcheivementsDocument',
                fileName: obj.presentationforEntityAcheivementsDocument,
                fileObj: Config.getBase64BonusRequest_PresentationforEntityAcheivements()
            }
        ];

        if (this.state.haveBudget2 === "Yes") {

            attachmentArr.push({
                label: 'uploadTheContracts',
                fileName: obj.uploadTheContracts,
                fileObj: Config.getBase64BonusRequest_UploadContrACT()
            })
        }

        let haveBudget2ValueForPreview = "";
        if (this.state.haveBudget2 === "Yes") {
            haveBudget2ValueForPreview = i18n[this.state.isArabic ? "ar" : "en"].yes
        }
        else {
            haveBudget2ValueForPreview = i18n[this.state.isArabic ? "ar" : "en"].no
        }

        let ckEditorArr = [
            {
                label: 'yearachievment',
                value: this.state.entityAchivementsForYear,
            },
            {
                label: 'bonusreason',
                value: this.state.reasonsToPayBonuses,
            },
            {
                label: 'isfixedvaluebonus',
                value: haveBudget2ValueForPreview,
                flag: 'radio'
            },
            {
                label: 'equation',
                value: this.state.explainTheEquation,
                flag: 'radio'
            }
        ];

        localStorage.setItem("currentPath", "bonusRequest");
        this.props.history.replace("/preview", { data: reqObj, attachmentArr: attachmentArr, ckEditorArr: ckEditorArr });
    };

    render() {
        const sideBarData = Config.getServiceSideBar('bonusRequest', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid >
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} />
                    <MDBRow className="formWhiteContainer" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'bonusRequest' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].bonusRequest}
                                    />
                                </MDBCol>
                            </MDBRow>

                            {
                                this.state.step1 ?
                                    <StepOne
                                        isArabic={this.state.isArabic}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        sendData={{
                                            entityName: this.state.entityName,
                                            cordinatorName: this.state.cordinatorName,
                                            jobName: this.state.jobName,
                                            contactNumber: this.state.contactNumber,
                                            contactNumberMobile: this.state.contactNumberMobile,
                                            email: this.state.email,
                                            cordinatorName2: this.state.cordinatorName2,
                                            jobName2: this.state.jobName2,
                                            contactNumber2: this.state.contactNumber2,
                                            contactNumberMobile2: this.state.contactNumberMobile2,
                                            email2: this.state.email2,
                                            haveBudget: this.state.haveBudget,
                                            reasonsToPayBonuses: this.state.reasonsToPayBonuses,
                                            entityAchivementsForYear: this.state.entityAchivementsForYear,
                                            enterTheBudget: this.state.enterTheBudget,
                                            enterTheYear1: this.state.enterTheYear1,
                                            percentageOne: this.state.percentageOne,
                                            percentageTwo: this.state.percentageTwo,
                                            yearOne: this.state.yearOne,
                                            yearTwo: this.state.yearTwo,
                                            totalNumberOfEmployees: this.state.totalNumberOfEmployees,
                                        }}
                                        saveAsDraft={this.stepThreeData}
                                    />
                                    :
                                    null
                            }

                            {
                                this.state.step2 ?
                                    <StepTwo
                                        isArabic={this.state.isArabic}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        onPreviousButtonPressed={this.nextButtonPressed}
                                        sendData={{
                                            haveBudget2: this.state.haveBudget2,
                                            requestAnnualBonus: this.state.requestAnnualBonus,
                                            explainTheEquation: this.state.explainTheEquation,
                                            totalNumberWithPrivateContract: this.state.totalNumberWithPrivateContract,
                                            totalNumberWithPermenantContract: this.state.totalNumberWithPermenantContract,
                                            totalNumberWithnonPrivateContract: this.state.totalNumberWithnonPrivateContract,
                                            totalNumberWithnonPermenantContract: this.state.totalNumberWithnonPermenantContract,
                                            totalNumberOfEmployees: this.state.totalNumberOfEmployees,
                                            // excellentTotal: this.state.excellentTotal,
                                            veryGood: this.state.veryGood,
                                            showResultVeryGood: this.state.showResultVeryGood,
                                            excellent: this.state.excellent,
                                            showResultExcellent: this.state.showResultExcellent,
                                            good: this.state.good,
                                            showResultGood: this.state.showResultGood,
                                            acceptable: this.state.acceptable,
                                            showResultAcceptable: this.state.showResultAcceptable,
                                            weak: this.state.weak,
                                            showResultWeak: this.state.showResultWeak,
                                            haveBudget: this.state.haveBudget,
                                        }}
                                        saveAsDraft={this.stepThreeData}
                                    />
                                    :
                                    null
                            }

                            {
                                this.state.step3 ?
                                    <StepThree
                                        isArabic={this.state.isArabic}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        onNextButtonPressed={this.nextButtonPressed}
                                        onPreviousButtonPressedThree={this.nextButtonPressed}
                                        sendData={{
                                            approvedGradesAndSalaries: this.state.approvedGradesAndSalaries,
                                            balancedScoreCard: this.state.balancedScoreCard,
                                            financialStatement: this.state.financialStatement,

                                            resultsOfEvaluationGeneralPerformance: this.state.resultsOfEvaluationGeneralPerformance,
                                            resultsOfEvaluationGeneralPerformanceShortName: this.state.resultsOfEvaluationGeneralPerformanceShortName,

                                            uploadTheContracts: this.state.uploadTheContracts,
                                            uploadTheContractsShortName: this.state.uploadTheContractsShortName,

                                            uploadTheExcelAfterFill: this.state.uploadTheExcelAfterFill,
                                            budgetRelatedDocument: this.state.budgetRelatedDocument,
                                            haveBudget: this.state.haveBudget,
                                            haveBudget2: this.state.haveBudget2,
                                            presentationforEntityAcheivementsDocument: this.state.presentationforEntityAcheivementsDocument
                                        }}
                                        previewPage={this.handlePreViewNavigation}
                                        saveAsDraft={this.stepThreeData}
                                    />
                                    :
                                    null
                            }
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewbonusrequest={this.state.canviewbonusrequest}
                            canviewamendscalerequest={this.state.canviewamendscalerequest}
                            canviewamendsalaryrequest={this.state.canviewamendsalaryrequest}
                            // height={'270vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            isArabic={this.state.isArabic}
                            items={sideBarData}
                            toggleTheme={this.toggleTheme.bind(this)} />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}