import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle, MDBDropdown } from "mdbreact";
import i18n from './../i18n';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';
import LoadingOverlay from 'react-loading-overlay';
const striptags = require('striptags');

export default class AboutEservices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isArabic: true,
            aboutUsData: ''
        }
    }

    componentDidMount = () => {
        if (localStorage.getItem('Lang') === 'ar') {
            this.setState({
                isArabic: true
            }, () => {
                this.callAboutUs();
            })
        } else {
            this.setState({
                isArabic: false
            }, () => {
                this.callAboutUs();
            })
        }
    }

    callAboutUs = () => {
        let payload = '';
        let endPoint = 'getaboutus';
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let data = res.data;
                     
                        this.setState({
                            aboutUsData: data
                        }, () => {
                     
                        });
                    }
                });
            });
        });
    }

    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    getLangName = () => {
        return this.state.isArabic ? 'العربية' : 'English'
    }

    getFlagImage = (lan) => {
        let lanFlag = 'ar';
        if (localStorage.getItem('Lang') !== '') {
            lanFlag = localStorage.getItem('Lang');
        }

        if (this.state.isArabic) {
            if (lan === lanFlag) {
                return <img src={require("./../assets/images/flags/ae.png")} alt="" />
            }
            else {
                return <img src={require("./../assets/images/flags/us.png")} alt="" />
            }
        } else if (this.state.isArabic) {
            return <img src={require("./../assets/images/flags/ae.png")} alt="" />
        } else {
            return <img src={require("./../assets/images/flags/us.png")} alt="" />
        }
    }

    onClickEServicesClick = () => {
        this.props.history.push('/faqs');
    }

    onClickHome = () => {
        this.props.history.push('/');
    }

    clickOnLogo = () => {
        return Config.getHraLink();
    }

    render() {

        const logo = require('../assets/images/logo_bg.png');
        const home = require('../assets/images/home_icon_black.png');

        let element = '';
        if (this.state.aboutUsData) {
            element = this.state.aboutUsData.map((item, index) => {
                return (
                    <div key={index}>
                        <h1 className="aboutHeadings" style={{ borderRight: this.state.isArabic ? '8px solid#329bd0' : '', borderLeft: this.state.isArabic ? '' : '8px solid #97b8c6', textAlign: this.state.isArabic ? 'right' : 'left' }}>
                            {this.state.isArabic ? item.HeaderAR : item.HeaderEN}
                        </h1>

                        <div className="middlespace">&nbsp;</div>

                        <div className="aboutDetail" style={{ textAlign: this.state.isArabic ? 'right' : 'left' }}>
                            {
                                this.state.isArabic ?
                                    striptags(item.DetailAR)
                                    :
                                    striptags(item.DetailEN)
                                // item.DetailAR.replace("<div>", "").replace("</div>", "")
                                //     :
                                //     item.DetailEN.replace("<div>", "").replace("</div>", "")
                            }
                        </div>

                        <div className="middlespace">&nbsp;</div>

                    </div>
                )
            })
        } else {

        }

        return (
            <MDBContainer fluid>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <MDBRow className={this.state.isArabic ? 'socialIconBar_ar' : 'socialIconBar_en'}>

                    </MDBRow>

                    <MDBRow className="header" style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }}>
                        <MDBCol md="8"></MDBCol>
                        <MDBCol md="4" style={{ textAlign: this.state.isArabic ? 'right' : 'left', paddingRight: this.state.isArabic ? '3%' : 0, paddingLeft: this.state.isArabic ? 0 : '3%', }}>
                            <a href={this.clickOnLogo()}>
                                <img
                                    src={logo} alt="" className="logo"></img>
                            </a>
                        </MDBCol>
                        {/* <MDBCol md="1"></MDBCol> */}
                    </MDBRow>

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse' }} className="navigation">

                        <MDBCol md="5" className={this.state.isArabic ? 'loginbtnCol' : 'loginbtnCol_en'}><button className="loginbtn" type="button" onClick={() => { this.props.history.push('/loginScreen'); }} title={i18n[this.getLocale()].login}>{i18n[this.getLocale()].login}</button>

                            <MDBDropdown style={{ float: this.state.isArabic ? 'left' : 'right', padding: 0, margin: 0, }} size="sm" className="mx-2">

                                <MDBDropdownToggle style={{ display: 'flex', flexDirection: this.state.isArabic ? 'row-reverse' : 'row', alignItems: 'center', justifyContent: 'center', border: '1px solid gray', padding: '3px 10px', borderRadius: '40px', margin: 0, }} caret gradient="#6a99ad">
                                    <span style={{ color: 'black', padding: '0 5px', fontSize: '12px' }}>{this.getFlagImage(localStorage.getItem('Lang'))}</span>
                                    <span style={{ color: 'black', padding: '0 5px', fontSize: '12px' }}>{this.getLangName(localStorage.getItem('Lang'))}</span>
                                </MDBDropdownToggle>

                                <MDBDropdownMenu basic>

                                    <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', padding: 5 }} onClick={() => { this.setState({ isArabic: true }, () => localStorage.setItem('Lang', 'ar')) }}>
                                        <span style={{ paddingLeft: '5px', marginRight: '5px' }}>
                                            <img src={require("./../assets/images/flags/ae.png")} alt="" />
                                        </span>
                                        <span style={{ textAlign: 'right', fontSize: '14px' }}>
                                            {i18n[this.getLocale()].arabic}
                                        </span>
                                    </MDBDropdownItem>

                                    <MDBDropdownItem divider />

                                    <MDBDropdownItem style={{ display: 'flex', flexDirection: this.state.lan === 'ar' ? 'row-reverse' : 'row', padding: 5 }} onClick={() => { this.setState({ isArabic: false }, () => localStorage.setItem('Lang', 'en')) }}>
                                        <span style={{ paddingLeft: 5, marginRight: '5px' }}>
                                            <img src={require("./../assets/images/flags/us.png")} alt="" />
                                        </span>
                                        <span style={{ textAlign: 'right', fontSize: '14px' }}>
                                            {i18n[this.getLocale()].english}
                                        </span>
                                    </MDBDropdownItem>

                                </MDBDropdownMenu>

                            </MDBDropdown>

                        </MDBCol>

                        <MDBCol md="7">
                            <ul style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', float: this.state.isArabic ? 'right' : 'left', marginRight: this.state.isArabic ? '5%' : '0', marginLeft: this.state.isArabic ? '0' : '5%' }} className="list-group list-group-horizontal">
                                <li
                                    onClick={this.onClickEServicesClick}
                                    style={{ paddingTop: '4px', paddingBottom: '4px' }} className="list-group-item borderNone" title={i18n[this.getLocale()].menu3}>{i18n[this.getLocale()].menu3}</li>
                                <li
                                    onClick={this.onClickAboutHra}
                                    style={{ paddingTop: '4px', paddingBottom: '4px' }} className="list-group-item active" title={i18n[this.getLocale()].menu2}>{i18n[this.getLocale()].menu2}</li>
                                <li
                                    onClick={this.onClickHome}
                                    style={{ paddingTop: '4px', paddingBottom: '4px' }} className="list-group-item" title={i18n[this.getLocale()].menu1}>
                                    <img
                                        style={{ height: '20px', width: '20px' }}
                                        src={home} alt="" className=""></img>
                                </li>
                            </ul>
                        </MDBCol>

                    </MDBRow>

                    <MDBRow className="aboutTopSpace">
                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ height: '30px' }}>
                            &nbsp;
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="aboutAndFaqcontent">

                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">

                            {element}

                            {/* <h1 className="aboutHeadings">
                                About HRA
                            </h1>

                            <div className="middlespace">&nbsp;</div>

                            <div className="aboutDetail">
                                The Authority aims to develop and develop human resources in the government sector based on modern concepts and international standards applied in the field of human resources management.
                            </div>
                            <div className="aboutDetail">
                                The Authority is enjoying the independent corporate body and necessary legal competence to resume all works and disposals which guarantee achieving its objectives as well as achieving Administrative and financial independence and subordinate to the Cabinet.
                            </div>
                            <div className="aboutDetail">
                                A Government Human Resources Authority was established in the United Arab Emirates in accordance with Federal Law issued by His Highness Sheikh Khalifa bin Zayed Al Nahyan.
                            </div>
                            <div className="aboutDetail">
                                Which is entrusted with the powers and responsibilities related to the human resources management of the ministries and federal bodies subject to this decree by law, to constitute a &quot;promising future start in the field of human resources development in ministries and federal government bodies.
                            </div>
                            <div className="aboutDetail">
                                This site is considered as a means of eliminating the services of the Human Resources Authority electronically to speed up and facilitate the procedures for making decisions regarding applications sent by different external bodies
                            </div> */}

                            {/* <div className="aboutDetail">
                                <div class="ExternalClass27018CB2F3B04281A8AC839151FD09B3\">
                                    <div>
                                        The Authority aims to develop and develop human resources in the government sector based on modern concepts and international standards applied in the field of human resources management.
                                    </div>
                                    <div>
                                        The Authority is enjoying the independent corporate body and necessary legal competence to resume all works and disposals which guarantee achieving its objectives as well as achieving Administrative and financial independence and subordinate to the Cabinet.
                                    </div>
                                    <div>
                                        A Government Human Resources Authority was established in the United Arab Emirates in accordance with Federal Law issued by His Highness Sheikh Khalifa bin Zayed Al Nahyan.
                                    </div>
                                    <div>
                                        Which is entrusted with the powers and responsibilities related to the human resources management of the ministries and federal bodies subject to this decree by law, to constitute a &quot;promising future start in the field of human resources development in ministries and federal government bodies.
                                    </div>
                                    <div>
                                        This site is considered as a means of eliminating the services of the Human Resources Authority electronically to speed up and facilitate the procedures for making decisions regarding applications sent by different external bodies​
                                    <br/>
                                    </div>
                                    <p><br/></p>
                                </div>
                            </div> */}

                        </MDBCol>

                    </MDBRow>

                </LoadingOverlay>

            </MDBContainer>
        );
    }
}