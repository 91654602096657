import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import Config from './../../utils/Config';
import DatePicker from "react-datepicker";
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import { Multiselect } from 'multiselect-react-dropdown';
// import { multiValueCSS } from 'react-select/src/components/multivalue';


let moment = require('moment');

const striptags = require('striptags');

export default class InformationRequestStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            MultipleSelectValue: [],
            requestId: '',
            isEdit: false,
            statusid: '',
            requestType: Config.getInformationRequestTypeId(),
            radio: 0,
            isNextScreenActive: false,
            isArabic: true,
            contactNumberErr: '',
            MultiselectErr: '',
            reqAttachmentErr: '',
            entityData: [],
            contactNumber: this.props.sendData.contactNumber,
            requestorName: this.props.sendData.requestorName,
            assignedEntitySelectedValue: this.props.sendData.assignedEntitySelectedValue,
            assignedEntitySelectedLabel: this.props.sendData.assignedEntitySelectedLabel,
            lastTimeToReply: this.props.sendData.lastTimeToReply,

            typeOfRequest: '',
            typeOfRequestLabel: '',
            ChangeLastTimeSelectedValue1: '',
            ChangeLastTimeSelectedValue2: '',
            ChangeLastTimeSelectedValue3: '',
            // whatInfoRequired: this.props.sendData.whatInfoRequired,
            assignedEntityId: '',
            attachFile1: this.props.sendData.attachFile1,
            isAttachmentDownloadShowsInEdit: true,
            editAttachmentArray: [],
            infoAttachmentArray: [],
            actualAttachFileOne: '',
            actualAttachFileUrl: '',
            showDateOnScreen: '',
            lastTimeToReplyErr: '',


            requestTypeArray: [
                {
                    id: 1,
                    value_english: 'Normal',
                    value_arebic: 'عادي'
                },
                {
                    id: 2,
                    value_english: 'Urgent',
                    value_arebic: 'مستعجل'
                },
                {
                    id: 3,
                    value_english: 'Confidential',
                    value_arebic: 'سري'
                }
            ],
            whatInfoRequired: "",
            whatInfoReqErr: '',
            multiseletedlabels: '',
        }

        this.handleLastTimeToReply = this.handleLastTimeToReply.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
     
       

        let loginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: loginData
        }, () => {
            if (loginData) {
                let temp = this.props.isArabic ? loginData.Username : loginData.Username
                this.setState({
                    requestorName: temp
                })
            }
        })
        let tempEntityData = [];
        if (Config.getEntityData()) {
            tempEntityData = Config.getEntityData();
        }
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        let previewFlag = Config.getIsPreviewFlag();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();
        let previewckEditorArr = Config.getPreviewckEditorArray();

        if (editFlag) {
            let tempDateOne = '';
            if (editObj.lastTimeToReply) {
                tempDateOne = new Date(editObj.lastTimeToReply.toString());
            } else {
                tempDateOne = null;
            }

            let tempvalue = '';
            for (let i = 0; i < editObj.assignedentity.length; i++) {

                this.props.isArabic ? tempvalue = tempvalue + "  " + editObj.assignedentity[i].arabicName :
                    tempvalue = tempvalue + "  " + editObj.assignedentity[i].englishName;

            }
            this.setState({ multiseletedlabels: tempvalue });

            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                contactNumber: editObj.contactnumber,
                requestorName: editObj.requestorname,
                whatInfoRequired: editObj.informationrequired,
                lastTimeToReply: tempDateOne,
                // assignedEntitySelectedValue: tempAssignedEntitySelectedValue,
                assignedEntitySelectedValue: editObj.assignedentity,
                typeOfRequest: editObj.requesttype,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempArray = [];
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];

                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getInformationRequestAttachmentId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempArray.push(temp);

                                // set base 64 in edit 
                                let obj = {}
                                obj.filename = item.aliasfilename;
                                obj.requesttype = this.state.requestType;
                                obj.requestid = this.state.requestId;
                                let payload = downloadAttachment(obj);
                                let endPoint = 'DownloadAttachment'
                                let url = Config.getUrl(endPoint);
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    ApiRequest.sendRequestForPost(url, payload, res => {

                                        this.setState({
                                            isLoading: false
                                        }, () => {
                                            if (res.Status) {
                                                let fileData = res.data;
                                                Config.setBase64ForInformatinRequestOne(fileData.filecontent);
                                            } else {

                                            }
                                        })
                                    })
                                })

                                this.setState({
                                    // attachFile1: item.filename,
                                    actualAttachFileUrl: item.aliasfilename
                                })
                            }
                        })
                        this.setState({
                            infoAttachmentArray: tempArray
                        })
                    } else {
                        this.setState({
                            isAttachmentDownloadShowsInEdit: false
                        })
                    }
                }
                // set flag
                // Config.setIsEditFlag(false);
            })
        }

        //For Preview Form
        if (previewFlag) {
            debugger;

            let tempvalue = '';
            for (let i = 0; i < previewObj.assignedEntitySelectedValue.length; i++) {

                this.props.isArabic ? tempvalue = tempvalue + "  " + previewObj.assignedEntitySelectedValue[i].arabicName :
                    tempvalue = tempvalue + "  " + previewObj.assignedEntitySelectedValue[i].englishName;

            }
            this.setState({ multiseletedlabels: tempvalue });

            this.setState({
                requestId: previewObj.requestId,
                isEdit: previewObj.isEdit,
                requestorName: previewObj.requestorName,
                contactNumber: previewObj.contactNumber,
                assignedEntitySelectedValue: previewObj.assignedEntitySelectedValue,
                assignedEntitySelectedLabel: previewObj.assignedEntitySelectedLabel,
                lastTimeToReply: previewObj.lastTimeToReply ? new Date(previewObj.lastTimeToReply) : null,
                typeOfRequest: previewObj.typeOfRequest,
                typeOfRequestLabel: previewObj.typeOfRequestLabel,
            }, () => {

                //For Attachments
                let attachFile1 = '';
                if (previewAttachmentArray.length > 0) {
                    for (let index = 0; index < previewAttachmentArray.length; index++) {
                        if (previewAttachmentArray[index].label === 'attachFile1') {
                            attachFile1 = previewAttachmentArray[index].fileName;
                        }
                    }
                }

                //For CK Editor
                let whatInfoRequired = '';
                if (previewckEditorArr) {
                    if (previewckEditorArr.length > 0) {
                        for (let j = 0; j < previewckEditorArr.length; j++) {
                            if (previewckEditorArr[j].label === 'whatInfoRequired') {
                                whatInfoRequired = previewckEditorArr[j].value;
                            }
                        }
                    }
                }

                this.setState({
                    attachFile1: attachFile1,
                    whatInfoRequired: whatInfoRequired
                });
            });
        }

        if (tempEntityData && tempEntityData.length > 0) {
            let entityId = tempEntityData[0].ID;
            this.setState({
                assignedEntityId: entityId,
                entityData: tempEntityData
            });
/* debugger;
            let all = {id: 0, englishName: "All Government Entities", 
            arabicName: " كل الجهات الحكومية ", shortName: "All"}
            let dt = tempEntityData;
            dt.concat(all);
            this.setState({entityData : dt}); */
        }
       
    }

    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    fileOnChange = (e) => {
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    onChangeWhatInfoReqEditor = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                whatInfoReqErr: "",
                whatInfoRequired: newContent
            });
        } else {
            this.setState({
                whatInfoReqErr: "ckeditorCountErr"
            });
        }
    }

    handlePreViewNavigation = () => {


        let dataObj = {
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            requestorName: this.state.requestorName,
            contactNumber: this.state.contactNumber,
            assignedEntitySelectedValue: this.state.assignedEntitySelectedValue,
            assignedEntitySelectedLabel: this.state.multiseletedlabels,
            lastTimeToReply: this.state.lastTimeToReply ? moment(this.state.lastTimeToReply).format('L').toString() : "",
            typeOfRequest: this.state.typeOfRequest,
            typeOfRequestLabel: this.state.typeOfRequestLabel
        }

        let attachmentArr = [
            {
                label: 'attachFile1',
                fileName: this.state.attachFile1,
                fileObj: Config.getBase64ForInformatinRequestOne()
            },
        ];

        let ckEditorArr = [
            {
                label: 'whatInfoRequired',
                value: this.state.whatInfoRequired,
            },
        ];
        debugger;
        Config.setPreviewObj(dataObj);
        this.props.previewPage(dataObj, attachmentArr, ckEditorArr);
    }

    saveAsDraft = () => {
        let tempvalues = [];
        if (this.state.MultipleSelectValue.length > 0) {
            for (let i = 0; i < this.state.MultipleSelectValue.length; i++) {
                tempvalues.push(this.state.MultipleSelectValue[i].id);

            }
        }
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            requestorName: this.state.requestorName,
            contactNumber: this.state.contactNumber,
            //assignedEntitySelectedValue: this.state.assignedEntityId,
            assignedEntitySelectedValue: tempvalues,
            whatInfoRequired: this.state.whatInfoRequired,
            lastTimeToReply: this.state.lastTimeToReply,
            typeOfRequest: this.state.typeOfRequest,
            attachFile1: this.state.attachFile1,
            actualAttachFileOne: this.state.actualAttachFileOne
        });
    };


    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

        let
            reqAttachmentErr = '',
            contactNumberErr = '',
            lastTimeToReplyErr = '',
            whatInfoReqErr = '', MultiselectErr = '';

        if (!this.state.attachFile1) {
            reqAttachmentErr = "";
        }
        debugger;
        if (!this.state.assignedEntitySelectedValue || this.state.assignedEntitySelectedValue.length == 0) {
            MultiselectErr = "thisFieldIsRequired";
        }
        if (!this.state.contactNumber) {
            contactNumberErr = "thisFieldIsRequired";
        } else {
            if (!Validator.validateContactNumber(this.state.contactNumber)) {
                contactNumberErr = 'invalidContactNumber';
            } else {
                contactNumberErr = '';
            }
        }

        if (!this.state.lastTimeToReply) {
            lastTimeToReplyErr = "thisFieldIsRequired";
        }

        if (this.state.whatInfoRequired && this.state.whatInfoRequired.trim()) {
            let val = striptags(this.state.whatInfoRequired.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                whatInfoReqErr = 'thisFieldisRequired';
            }
        } else {
            whatInfoReqErr = 'thisFieldisRequired';
        }

        this.setState({
            MultiselectErr: MultiselectErr,
            reqAttachmentErr: reqAttachmentErr,
            contactNumberErr: contactNumberErr,
            lastTimeToReplyErr: lastTimeToReplyErr,
            whatInfoReqErr: whatInfoReqErr
        }, () => {

        })
        let tempvalues = [];
        if (this.state.assignedEntitySelectedValue.length > 0) {
            for (let i = 0; i < this.state.assignedEntitySelectedValue.length; i++) {
                tempvalues.push(this.state.assignedEntitySelectedValue[i].id);

            }
        }
        if (
            !reqAttachmentErr &&
            !contactNumberErr &&
            !lastTimeToReplyErr &&
            !whatInfoReqErr && !MultiselectErr
        ) {
            if (
                this.state.requestorName &&
                this.state.contactNumber &&
                striptags(this.state.whatInfoRequired) &&

                this.state.lastTimeToReply &&
                this.state.typeOfRequest
            ) {
                this.props.getData.stepOneData({
                    requestId: this.state.requestId,
                    isEdit: this.state.isEdit,
                    requestorName: this.state.requestorName,
                    contactNumber: this.state.contactNumber,
                    assignedEntitySelectedValue: tempvalues,
                    //assignedEntitySelectedValue: this.state.assignedEntitySelectedValue,
                    whatInfoRequired: this.state.whatInfoRequired,
                    lastTimeToReply: this.state.lastTimeToReply,
                    typeOfRequest: this.state.typeOfRequest,
                    attachFile1: this.state.attachFile1,
                    actualAttachFileOne: this.state.actualAttachFileOne
                });
            }
        }
    };

    clearForm = () => {
        this.setState({
            contactNumber: '',
            //assignedEntitySelectedValue: this.state.assignedEntityId,
            assignedEntitySelectedValue: '',
            whatInfoRequired: '',
            lastTimeToReply: '',
            typeOfRequest: '',
            attachFile1: '',
            actualAttachFileOne: null
        });
    }

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleRequesterNameChange = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value && regex.test(event.target.value)) {
            this.setState({ requestorName: event.target.value });
        } else {
            this.setState({ requestorName: "" });
        }
    };

    handleContactNumberChange = (event) => {
        let contactNumber = event.target.value;
        if (event.target.value) {
            this.setState({ contactNumber: event.target.value }, () => {
                if (contactNumber.length === 11 || contactNumber.length === 12) {
                    this.setState({ contactNumberErr: '' });
                } else {
                    this.setState({ contactNumberErr: "invalidContactNumber" });
                }
            });
        } else {
            this.setState({ contactNumber: '', contactNumberErr: "thisFieldIsRequired" });
        }
    };
    handleMultipleselect = (event) => {
        debugger;
        this.setState({ assignedEntitySelectedValue: event });
        let arr = event;
        let tempvalue = '';
        for (let i = 0; i < arr.length; i++) {

            this.props.isArabic ? tempvalue = tempvalue + "  " + arr[i].arabicName :
                tempvalue = tempvalue + "  " + arr[i].englishName;

        }
        this.setState({ multiseletedlabels: tempvalue });
    }
    handleChangeForAssignedEntities = (event) => {
        let dropDownValue = event.target.value;
        let dropDownId = '', dropDownLabel = '';
        let tempArray = [...this.state.entityData];

        if (tempArray.length > 0) {
            for (let index = 0; index < tempArray.length; index++) {
                if (parseInt(dropDownValue) === tempArray[index].id) {
                    dropDownId = tempArray[index].id;
                    dropDownLabel = this.props.isArabic ? tempArray[index].arabicName : tempArray[index].englishName;
                }
            }
        }

        this.setState({
            assignedEntityId: dropDownId,
            assignedEntitySelectedLabel: dropDownLabel,
            assignedEntitySelectedValue: event.target.value
        });

    };

    handleTypeRequestChange = (event) => {
        let dropDownValue = event.target.value;
        let tempArray = this.state.requestTypeArray;
        let dropDownLabel = '';

        if (tempArray.length > 0) {
            for (let index = 0; index < tempArray.length; index++) {
                if (parseInt(dropDownValue) === tempArray[index].id) {
                    dropDownLabel = this.props.isArabic ? tempArray[index].value_arebic : tempArray[index].value_english;
                }
            }
        }

        this.setState({
            typeOfRequest: parseInt(event.target.value),
            typeOfRequestLabel: dropDownLabel
        });

    };

    handleLastTimeToReply = (date) => {
        this.setState({ lastTimeToReply: date, lastTimeToReplyErr: '' });
    }

    attachFileOnChange1 = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    attachFile1: '',
                    reqAttachmentErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ attachFile1: e.target.files[0].name, reqAttachmentErr: '' });
                    let tempFile = e.target.files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(tempFile);
                    reader.onloadend = () => {
                        this.setState({
                            actualAttachFileOne: reader.result,
                        }, () => {
                            Config.setBase64ForInformatinRequestOne(this.state.actualAttachFileOne);
                        });
                    }
                }
                else {
                    e.target.value = '';
                    this.setState({ attachFile1: '', reqAttachmentErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ attachFile1: '' })
        }
    };

    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    render() {

        return (

            <form
                style={{ paddingTop: '20px', marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate >

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].requestor_name}
                                <span style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>

                            <input
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="requestorName"
                                name="requestorName"
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                value={this.state.requestorName}
                                onChange={this.handleRequesterNameChange}
                                required
                            />

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                {i18n[this.getLocale()].contact_number}

                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                className="form-control"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            {
                                this.state.contactNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div> : null
                            }

                            {this.state.contactNumberErr == 'invalidContactNumber' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].invalidContactNumber}
                                </div>
                                :
                                null}

                        </div>

                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">

                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>

                                {i18n[this.getLocale()].who_are_assign_entities}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>

                            </label>
                            <Multiselect
                                options={this.state.entityData}
                                selectedValues={this.state.assignedEntitySelectedValue}
                                // value = {this.state.MultipleSelectValue}
                                onSelect={this.onSelect}
                                onRemove={this.onRemove}
                                displayValue={this.props.isArabic ? "arabicName" : "englishName"}
                                onSelect={this.handleMultipleselect}
                            />
                            {this.state.MultiselectErr == 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', 
                                flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                            {/* <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select"
                                id="sources-funding"
                                onChange={this.handleChangeForAssignedEntities}
                                value={this.state.assignedEntitySelectedValue}
                                name='assignedEntitySelectedValue'
                                required>

                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.entityData.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}</option>
                                    })
                                }

                            </select>  */}

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>

                        </div>

                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group ">

                            <label htmlFor="exampleFormControlTextarea2" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>

                                {i18n[this.getLocale()].type_of_request}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: "red" }}>*</span>

                            </label>

                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select"
                                id="sources-funding"
                                onChange={this.handleTypeRequestChange}
                                required
                                name="typeOfRequest"
                                value={this.state.typeOfRequest}
                            >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>

                                {
                                    this.state.requestTypeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.value_arebic : item.value_english}</option>
                                    })
                                }
                            </select>

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow">
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} 
                            htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].datefrominfo}
                                {/*                             <span className="requireStar">*</span>
 */}                            </label>
                            <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                className={`${ "react-datepicker-popper datePickerBgClass"} ${this.state.lastTimeToReplyErr ? "datePickerBorderColorErr" : ""}`}>
                                <DatePicker
                                    className="form-control"
                                    todayButton={"Today"}
                                    minDate={new Date()}
                                    style={{ width: '100%' }}
                                    selected={this.state.lastTimeToReply}
                                    onChange={this.handleLastTimeToReply}
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={<input />}
                                    dateFormat="MM/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                //showTimeInput
                                //required
                                />

                            </div>
                            {/*  {
                                this.state.lastTimeToReplyErr === "thisFieldIsRequired" ?

                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput"
                            style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}
                        >
                            {i18n[this.getLocale()].attached_files}
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="attachFile1File"
                                    type="file"
                                    name="attachFile1"
                                    onChange={(event) => this.attachFileOnChange1(event)}
                                />
                                <label htmlFor="attachFile1File" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].choose_file}</label>
                                <label htmlFor="attachFile1File" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.attachFile1}</span></label>

                            </div>
                            {
                                this.state.reqAttachmentErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.reqAttachmentErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.isAttachmentDownloadShowsInEdit &&
                                    this.state.isAttachmentDownloadShowsInEdit.length > 0)
                                    ? <DownloadComponent filesObj={this.state.isAttachmentDownloadShowsInEdit} />
                                    : <span></span>

                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>
                                {i18n[this.getLocale()].what_are_the_info_req}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <div style={{ border: this.state.whatInfoReqErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">

                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.whatInfoRequired}
                                            events={{
                                                "change": this.onChangeWhatInfoReqEditor
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }

                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.whatInfoRequired}
                                            events={{
                                                "change": this.onChangeWhatInfoReqEditor
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.whatInfoReqErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', marginTop: '2%' }}>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {/* {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    } */}
                    <MDBBtn type='submit' color="primary" className="submitBtn">
                        {i18n[this.getLocale()].submit}
                    </MDBBtn>
                </MDBRow>

            </form>
        );
    }
}