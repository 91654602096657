/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBRow, MDBContainer, MDBProgress, MDBBtn } from "mdbreact";
import i18n from './../i18n';
import PopOverComponent from './PopOverComponent';
import OrganizationStructurePopOverComponent from './OrganizationStructure/OrganizationStructurePopOverComponent';
import TableLabel from './TableLabel';
import {getAllOrganizationStructureRequestForEntityPayload, getCurrentTaskListPayload, getTaskListPayload, getContactPayload, getEntitiesPayload, getExceptionAccompanyPatientForEntityPayload, getAllSciRequestForEntityPayload, getAllInformationRequestForEntityPayload, GetAllhrPoliciesForEntityPayload, GetAllAmendSalaryScaleForEntityPayload, GetAllBounsRequestForEntityPayload, GetAllAmendEmployeeSalaryForEntityPayload, getAllSecondmentForEntityPayload, getAllHousingPolicyForEntityPayload, getMailTemplatePayload, getRolePayload } from './../utils/Payload';
import Config from './../utils/Config';
import ApiRequest from './../services/ApiRequest';
import { sr } from 'date-fns/locale';
let moment = require('moment');

export default class DataTable extends Component {

    constructor(props) {
        super(props);
        this.handleLoginKeyUp = this.handleLoginKeyUp.bind(this);
        this.validatekey=this.validatekey.bind(this);
        this.state = {
            sorttype:'',
            changeStateForSearch: false,
            isViolation: '',
            isAdmin: false,
            entityNameEn: '',
            entityNameAr: '',
            loginData: '',
            isArabic: this.props.isArabic,
            data: [],
            pathName: '',
            popUpRowArray: [],
            temppopUpRowArray:[],
            actualArray: [],
            dataRow: [],
            requestTypeSelectedValue: "",
            lookupArray: [
                {
                    'id': 1,
                    'EnglishTitle': 'Request Type',
                    'ArabicTitle': 'نوع الطلب'
                },
                {
                    'id': 2,
                    'EnglishTitle': 'Department',
                    'ArabicTitle': 'قسم، أقسام'
                },
                {
                    'id': 3,
                    'EnglishTitle': 'Employee',
                    'ArabicTitle': 'موظف'
                },
                {
                    'id': 5,
                    'EnglishTitle': 'Status',
                    'ArabicTitle': 'الحالة'
                },
                {
                    'id': 6,
                    'EnglishTitle': 'Reason for SCI',
                    'ArabicTitle': 'سبب اصابات النخاع الشوكي'
                },
                {
                    'id': 7,
                    'EnglishTitle': 'Attachment',
                    'ArabicTitle': 'المرفق'
                },
                {
                    'id': 8,
                    'EnglishTitle': 'Reason for Information',
                    'ArabicTitle': 'سبب المعلومات'
                },
                {
                    'id': 9,
                    'EnglishTitle': 'Reason',
                    'ArabicTitle': 'السبب'
                },
                {
                    'id': 10,
                    'EnglishTitle': 'Sector',
                    'ArabicTitle': 'قطاع'
                },
                {
                    'id': 11,
                    'EnglishTitle': 'Country List',
                    'ArabicTitle': 'قائمة الدول'
                },
                {
                    'id': 12,
                    'EnglishTitle': 'City List',
                    'ArabicTitle': 'قائمة المدينة'
                },
                {
                    'id': 13,
                    'EnglishTitle': 'Industry List',
                    'ArabicTitle': 'قائمة الصناعة'
                },
                {
                    'id': 14,
                    'EnglishTitle': 'Sector List',
                    'ArabicTitle': 'قائمة القطاعات'
                },
                {
                    'id': 15,
                    'EnglishTitle': 'Account Type List',
                    'ArabicTitle': 'قائمة نوع الحساب'
                },
                {
                    'id': 16,
                    'EnglishTitle': 'Reason To Deactive',
                    'ArabicTitle': 'سبب للنشاط'
                },
                {
                    'id': 17,
                    'EnglishTitle': 'Contact Status',
                    'ArabicTitle': 'حالة الاتصال'
                },
                {
                    'id': 18,
                    'EnglishTitle': 'Contact Reason',
                    'ArabicTitle': 'الاتصال السبب'
                },
                {
                    'id': 19,
                    'EnglishTitle': 'Mail template',
                    'ArabicTitle': 'قالب البريد'
                },
                {
                    'id': 20,
                    'EnglishTitle': 'Functional Block Type',
                    'ArabicTitle': 'نوع التسلسل الوظيفي'
                }
            ],
            dataColumns: [
            ],
            searchtext:''
        }
    }
    handleSeach=() => {
     
        this.handleLoginKeyUp(this.state.searchtext);
    }

    handleSearchTextbox=(event) => {
    
        this.setState({searchtext:event.target.value.trim()});
            }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
      }

      callGetAllSearch=()    => 
    {
        let obj = {};
        obj.statusid = -1;

        let payload = getTaskListPayload(obj);
        payload.sorttype=this.state.sorttype;
        let endPoint = 'GetTaskList'
        let url = Config.getUrl(endPoint);

        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        temppopUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                     
                      //  this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    componentDidMount = () => {
        // Set Preview flag/ Edit flag/ Attachment array/ Ck editorarray to false/empty in Config 
        document.addEventListener("keydown", this.escFunction, false);
        Config.setIsEditFlag(false);
        Config.setIsPreviewFlag(false);
        Config.setPreviewObj();
        Config.setPreviewAttachmentArray();
        Config.setPreviewckEditorArray();
      
        
        let pathName = '';
        if (this.props && this.props.location && this.props.location.state) {
            pathName = this.props.location.state.pathname
        } else {
            pathName = localStorage.getItem('currentPath');
        }
        
        
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                pathName: pathName,
                loginData: loginData,
                entityNameEn: loginData.EntityNameEn,
                entityNameAr: loginData.EntityNameAr
            }, () => {
                if (this.state.pathName === 'lookUp') {
                    let temp = localStorage.getItem("lookupDropdownValue");
                    if (temp !== null) {
                        this.setState({
                            requestTypeSelectedValue: localStorage.getItem("lookupDropdownValue")
                        }, () => {
                            // call look up data according to filter 
                            this.callLookUpdataAccordingToFilter();
                        })
                    }
                }
                this.callAllApi();
            })
        }
    }
    sortascRequestnumber = () => {
   
        this.setState({
            sorttype: 'ascRequestnumber'
        }, () => {
            this.callAllApi();
        })
       
    }
    sortdescRequestnumber = () => {
        this.setState({
            sorttype: 'descRequestnumber'
        }, () => {
            this.callAllApi();
        })
    }
    sortascSubject = () => {
        this.setState({
            sorttype: 'ascSubject'
        }, () => {
            this.callAllApi();
        })}
    sortdescSubject = () => {
        this.setState({
            sorttype: 'descSubject'
        }, () => {
            this.callAllApi();
        })
    }
    sortascentitynameen = () => {
    
        this.setState({
            sorttype: 'ascentitynameen'
        }, () => {
            this.callAllApi();
        })}
    sortdescentitynameen = () => {
        this.setState({
            sorttype: 'descentitynameen'
        }, () => {
            this.callAllApi();
        })
    }
    sortascstatus = () => {
        this.setState({
            sorttype: 'ascstatus'
        }, () => {
            this.callAllApi();
        })
    }
    sortdescstatus = () => {
        this.setState({
            sorttype: 'descstatus'
        }, () => {
            this.callAllApi();
        })
    }
    sortascstep = () => {
        this.setState({
            sorttype: 'ascstep'
        }, () => {
            this.callAllApi();
        })
    }
    sortdescstep = () => {
        this.setState({
            sorttype: 'descstep'
        }, () => {
            this.callAllApi();
        })
    
    }
    sortasccreatedon = () => {
        this.setState({
            sorttype: 'asccreatedon'
        }, () => {
            this.callAllApi();
        })
    }
    sortdesccreatedon = () => {
        this.setState({
            sorttype: 'desccreatedon'
        }, () => {
            this.callAllApi();
        })
    }
    
    callAllApi = (index) => {
       
        let temp = [
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.Requestnumber,
                field: 'Requestnumber',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.Subject,
                field: 'Subject',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].EntityName,
                field: 'entitynameen',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.createdby,
                field: 'createdby',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.status,
                field: 'status',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.step,
                field: 'step',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.createdon,
                field: 'createdon',
                sort: 'asc'
            },
            {
                label: i18n[this.state.isArabic ? 'ar' : 'en'].viewComponent.action,
                field: 'action',
                sort: 'asc'
            },
        ];

        if (this.state.pathName) {
            if (this.state.pathName === 'search') {
                
                this.callGetAllSearch();
            }
            if (this.state.pathName === 'StrategicPlanningforworkforceEnquire') {
                this.callGetAllStrategicPlanningforworkforceEnquire();
                // organization structure
            }
          else  if (this.state.pathName === 'SCIRequest') {
                this.callGetAllSCIRequest();
                // organization structure
            }  else  if (this.state.pathName === 'OrganizationStructure') {
                this.callGetAllOrganizationStructureRequest();
            } else if (this.state.pathName === 'informationRequest') {
                this.callGetAllInformationRequest()
            } else if (this.state.pathName === 'bonusRequest') {
                this.callGetAllBounsRequestForEntity();
            } else if (this.state.pathName === 'amendSalaryScreen') {
                this.callGetAllAmendSalaryScaleForEntity();
            } else if (this.state.pathName === 'amendEmployeeSalary') {
                this.callGetAllAmendEmployeeSalaryForEntity();
            } else if (this.state.pathName === 'reviewHRPolicies') {
                this.callGetAllHrPoliciesForEntity();
            } else if (this.state.pathName === 'exceptionADHousing') {
                this.callGetAllHousingPolicyForEntity();
            } else if (this.state.pathName === 'secondmentForFederalAgency') {
                this.callSecondmentForFederalAgency();
            } else if (this.state.pathName === 'exemptionFromAccompanyPatient') {
                this.callGetAllExcemptionFromAcompanyPatient();
            } 
            else if (this.state.pathName === 'Promotion') {
                this.callGetAllPromotion();
            }
            else if (this.state.pathName === 'Disciplinaryboard') {
                this.callGetAllDisciplinaryboard();
            }
            
            else if (this.state.pathName==='SickLeave')
            {
                this.callGetAllSickLeave();
            }
            else if (this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
                this.callGetAllRetirmentRequest();
            } else if (this.state.pathName === 'inprogress') {
                this.callGetAllTaskList(31);
            }else if (this.state.pathName === 'Late') {
                this.callGetAllTaskListLate(31);
            } else if (this.state.pathName === 'pending') {
                this.callGetAllTaskList(38);
            }
            else if (this.state.pathName === 'Underapproval') {
                //this.callGetAllTaskList(10968);
                this.callGetAllTaskList(10982);
            }
            
            else if (this.state.pathName === 'completed') {
                this.callGetAllTaskList(23);
            } else if (this.state.pathName === 'cancelled') {
                this.callGetAllTaskList(164);
            } else if (this.state.pathName === 'onhold') {
                this.callGetAllTaskList(37);
            } else if (this.state.pathName === 'currentUserTasks') {
                this.callCurrentTaskList();
            } else if (this.state.pathName === 'extendCreateObjectives') {
                this.callExtendCreateObjectiveList();
            } else if (this.state.pathName === 'extendReviewObjectives') {
                this.callExtendReviewObjectivesList();
            } else if (this.state.pathName === 'extendAnnualPerformance') {
                this.callExtendAnnualPerformanceList();
            } else if (this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation') {
                this.callAddEditEmployeePerformanceList();
            } else if (this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
                this.callJoinAndImplementResourceSystemForNewEntityList();
            } else if (this.state.pathName === 'openComplainsStage') {
                this.callOpenComplainStageList();
            } else if (this.state.pathName === 'amendsSharedGovernmentHRManagement') {
                this.callAmendSharedGovHrManagementList();
            } else if (this.state.pathName === 'reportsAndStaticRequest') {
                this.callReportAndStaticRequestList();
            } else if (this.state.pathName === 'otherRequests') {
                this.callOtherRequestsList();
            } else if (this.state.pathName === 'entity') {
                this.setState({
                    isAdmin: true
                });
                temp = [
                    {
                        label: "English Name",
                        field: 'englishName',
                        sort: 'asc'
                    },
                    {
                        label: "Arabic Name",
                        field: 'arabicName',
                        sort: 'asc'
                    },
                    {
                        label: "Short Name",
                        field: 'shortName',
                        sort: 'asc'
                    },
                    {
                        label: "Employee Number",
                        field: 'employeeNumber',
                        sort: 'asc'
                    },
                    {
                        label: "Sector",
                        field: 'sector',
                        sort: 'asc'
                    },
                    {
                        label: "Industry",
                        field: 'industry',
                        sort: 'asc'
                    },
                    {
                        label: "Client Description",
                        field: 'clientDesc',
                        sort: 'asc'
                    },
                    {
                        label: "City",
                        field: 'city',
                        sort: 'asc'
                    },
                    {
                        label: "Phone Number",
                        field: 'phnNumber',
                        sort: 'asc'
                    },
                    {
                        label: "Action",
                        field: 'action',
                        sort: 'asc'
                    }
                ];
                this.getEntityData();
            } else if (this.state.pathName === 'contact') {
                this.setState({
                    isAdmin: true
                });
                temp = [
                    {
                        label: "English Name",
                        field: 'englishName',
                        sort: 'asc'
                    },
                    {
                        label: "Arabic Name",
                        field: 'arabicName',
                        sort: 'asc'
                    },
                    {
                        label: "User Name",
                        field: 'userName',
                        sort: 'asc'
                    },
                    {
                        label: "Email",
                        field: 'email',
                        sort: 'asc'
                    },
                    {
                        label: "Role",
                        field: 'role',
                        sort: 'asc'
                    },
                    // {
                    //     label: "Mobile",
                    //     field: 'mobile',
                    //     sort: 'asc'
                    // },
                    // {
                    //     label: "Phone Number",
                    //     field: 'phnNumber',
                    //     sort: 'asc'
                    // },
                    // {
                    //     label: "Designation",
                    //     field: 'designation',
                    //     sort: 'asc'
                    // },
                    // {
                    //     label: "Account Type",
                    //     field: 'accType',
                    //     sort: 'asc'
                    // },

                    // 
                    // {
                    //     label: "Entity",
                    //     field: 'entity',
                    //     sort: 'asc'
                    // },
                    {
                        label: "Action",
                        field: 'action',
                        sort: 'asc'
                    }
                ];
                this.getAllContacts();
            } else if (this.state.pathName === 'lookUp') {
                this.setState({ isAdmin: true });
                temp = [
                    {
                        label: "English Name",
                        field: 'englishName',
                        sort: 'asc'
                    },
                    {
                        label: "Arabic Name",
                        field: 'arabicName',
                        sort: 'asc'
                    },
                    // {
                    //     label: "Is Active",
                    //     field: 'isActive',
                    //     sort: 'asc'
                    // },
                    {
                        label: "Action",
                        field: 'action',
                        sort: 'asc'
                    }
                ];
            } else if (this.state.pathName === 'mailTemplate') {
                this.setState({ isAdmin: true });
                temp = [
                    {
                        label: "Title",
                        field: 'title',
                        sort: 'asc'
                    },
                    {
                        label: "Created On",
                        field: 'createdOn',
                        sort: 'asc'
                    },
                    {
                        label: "ModifiedOn",
                        field: 'modifiedOn',
                        sort: 'asc'
                    },
                    {
                        label: "Action",
                        field: 'action',
                        sort: 'asc'
                    }
                ];
                this.getAllMailTemplate();
            } else if (this.state.pathName === 'role') {
                this.setState({ isAdmin: true });
                temp = [
                    {
                        label: "EnglishTitle",
                        field: 'EnglishTitle',
                        sort: 'asc'
                    },
                    {
                        label: "ArabicTitle",
                        field: 'ArabicTitle',
                        sort: 'asc'
                    },
                    {
                        label: "Action",
                        field: 'action',
                        sort: 'asc'
                    }
                ];
                this.getAllRoles();
            }
        }

        this.setState({
            // pathName: pathName,
            dataColumns: temp,
        });

    }

    calculateDays = (firstDate, secondDate) => {
        let finalFirstDate = moment(firstDate, 'YYYY/MM/DD HH:MM:SS');
        let finalSecondDate = moment(secondDate, 'YYYY/MM/DD HH:MM:SS');
        let days = finalSecondDate.diff(finalFirstDate, 'days');
       
        return days;
    }

    handleChangeForSelectRequestType = (event) => {
        this.setState({
            requestTypeSelectedValue: event.target.value
        }, () => {
       
            localStorage.setItem('lookupDropdownValue', this.state.requestTypeSelectedValue);
            // call look up data according to filter 
            this.callLookUpdataAccordingToFilter();
        });
    };

    callLookUpdataAccordingToFilter = () => {
        let payload = {};
        payload.filter = this.state.requestTypeSelectedValue;
        let endPoint = 'Getmaster'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                this.setState({
                    isLoading: false
                }, () => {
                    let resData = res.data;
                    if (resData && resData.length > 0) {
                        for (let index = 0; index < resData.length; index++) {
                            let obj = {
                                id: resData[index].id,
                                englishName: resData[index].EnglishTitle,
                                arabicName: resData[index].ArabicTitle,
                            };
                            actualRowArray.push(obj);
                        }
                        mainArray = {
                            'columns': this.state.dataColumns,
                            'rows': actualRowArray
                        };
                        this.setState({
                            data: mainArray,
                            popUpRowArray: resArray,
                            actualArray: resArray,
                            dataRow: actualRowArray
                        }, () => {
                            this.props.updateListData(this.state.data, this.state.popUpRowArray);
                        })
                    } else {
                        // send empty array to list page
                        this.setState({
                            data: [],
                            popUpRowArray: [],
                        }, () => {
                            this.props.updateListData(this.state.data, this.state.popUpRowArray);
                        })
                    }
                })
            } else {
                // TODO
            }
        })
    };

    getAllMailTemplate = () => {
        let payload = getMailTemplatePayload();
        let endPoint = 'GetMailTemplates'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let resEntityData = res.data;
                if (resEntityData && resEntityData.length > 0) {
                    for (let index = 0; index < resEntityData.length; index++) {
                        let obj = {
                            id: resEntityData[index].id,
                            createdon: resEntityData[index].createdon,
                            modifiedon: resEntityData[index].modifiedon,
                            title: resEntityData[index].title,
                        };
                        actualRowArray.push(obj);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            }
        })
    }

    getAllRoles = () => {
        let payload = getRolePayload();
        let endPoint = 'GetRoles';
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let resEntityData = res.data;
                if (resEntityData && resEntityData.length > 0) {
                    for (let index = 0; index < resEntityData.length; index++) {
                        let obj = {
                            id: resEntityData[index].id,
                            EnglishTitle: resEntityData[index].EnglishTitle,
                            ArabicTitle: resEntityData[index].ArabicTitle,
                            cansubmitscirequest: resEntityData[index].cansubmitscirequest,
                        };
                        actualRowArray.push(obj);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        });
    }

    getAllContacts = () => {
        let type = 3;
        let payload = getContactPayload(type);
        let endPoint = 'GetEntityContact'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let resEntityData = res.data;
                if (resEntityData && resEntityData.length > 0) {
                    for (let index = 0; index < resEntityData.length; index++) {
                        let obj = {
                            id: resEntityData[index].id,
                            englishName: resEntityData[index].englishName,
                            arabicName: resEntityData[index].arabicName,
                            username: resEntityData[index].username,
                            emailAddress: resEntityData[index].emailAddress,
                            mobilenumber: resEntityData[index].mobilenumber,
                            phonenumber: resEntityData[index].phonenumber,
                            designation: resEntityData[index].designation,
                            entityName: resEntityData[index].entityName,
                            accountType: resEntityData[index].accountType,
                            role: resEntityData[index].role,
                        };
                        actualRowArray.push(obj);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    getEntityData = () => {
        let payload = getEntitiesPayload();
        let endPoint = 'GetEntities'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                let resEntityData = res.data;
                if (resEntityData && resEntityData.length > 0) {
                    for (let index = 0; index < resEntityData.length; index++) {
                        let obj = {
                            id: resEntityData[index].id,
                            englishName: resEntityData[index].englishName,
                            arabicName: resEntityData[index].arabicName,
                            shortName: resEntityData[index].shortName,
                            employeenumber: resEntityData[index].employeenumber,
                            sectorname: resEntityData[index].sectorname,
                            industryname: resEntityData[index].industryname,
                            description: resEntityData[index].description,
                            cityname: resEntityData[index].cityname,
                            phonenumber: resEntityData[index].phonenumber,
                        };
                        actualRowArray.push(obj);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callCurrentTaskList = () => {
        let payload = getCurrentTaskListPayload();
        let endPoint = 'GetTaskList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            }
        })
    }
    callGetAllTaskListLate = (statusid) => {
        let obj = {};
        obj.statusid = statusid;

        let payload = getTaskListPayload(obj);
        payload.sorttype=this.state.sorttype;
        payload.IsLate=true;
        let endPoint = 'GetTaskList'
        let url = Config.getUrl(endPoint);

        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    callGetAllTaskList = (statusid) => {
        let obj = {};
        obj.statusid = statusid;

        let payload = getTaskListPayload(obj);
        payload.sorttype=this.state.sorttype;
        let endPoint = 'GetTaskList'
        let url = Config.getUrl(endPoint);

        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    callGetAllStrategicPlanningforworkforceEnquire =()=>
    {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetallStrategicPlanningforworkforceEnquire'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    callGetAllSCIRequest = () => {
      
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'Getallscirequestforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                console.log('res : ' ,res.data);
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
// organization structure
    callGetAllOrganizationStructureRequest = () => {
       
        let payload = getAllOrganizationStructureRequestForEntityPayload();
        let endPoint = 'GetOrganizationStructureList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
           
                if (resArray && resArray.length > 0) {

                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];
                       
                        let rowsData = {
                          

                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            subject: resArray[index].subject,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    callGetAllInformationRequest = () => {
        let payload = getAllInformationRequestForEntityPayload();
        let endPoint = 'Getallinformationrequestforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                console.log('popup array : ' , res.data);
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        // calculate the SLA
                        let color = 'green';
                        // let status = resArray[index].status;
                        let statusId = resArray[index].statusid;
                        // let statusreason = resArray[index].statusreason;
                        let statusreasonId = resArray[index].statusreasonid;
                        //let createdon = resArray[index].createdon.split(' ')[0];
                        // let targetdate = resArray[index].targetdate;
                        //let laststatusdate = resArray[index].laststatusdate.split(' ')[0];

                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let tempLastStatusDate = '';
                        if (resArray[index].laststatusdate)
                            tempLastStatusDate = resArray[index].laststatusdate.split(' ')[0]


                        if (statusId === Config.getStatusPendingId()) {
                            if (this.calculateDays(tempCreatedDate, new Date()) >= 1) {
                                color = 'red'
                            }
                        } else if (statusId === Config.getStatusInProgressId()) {
                            if (statusreasonId === Config.getReasonInformationSectorAssignedId()) {
                                if (this.calculateDays(tempLastStatusDate, new Date()) >= 1) {
                                    color = 'red'
                                }
                            } else if (statusreasonId === Config.getReasonInformationDepartmentAssignedId()) {
                                if (this.calculateDays(tempLastStatusDate, new Date()) >= 1) {
                                    color = 'red'
                                }
                            }
                        }

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            createdon: tempCreatedDate,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllBounsRequestForEntity = () => {
        let payload = GetAllBounsRequestForEntityPayload();
        let endPoint = 'Getallbounsrequestforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllAmendSalaryScaleForEntity = () => {
        let payload = GetAllAmendSalaryScaleForEntityPayload();
        let endPoint = 'GetallAmmendSalaryScaleforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllAmendEmployeeSalaryForEntity = () => {
        let payload = GetAllAmendEmployeeSalaryForEntityPayload();
        let endPoint = 'GetallAmmendEmployeeSalaryforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            //id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            //isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllHrPoliciesForEntity = () => {
        let payload = GetAllhrPoliciesForEntityPayload();
        let endPoint = 'GetallHRPolicyReviewforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callSecondmentForFederalAgency = () => {
        let payload = getAllSecondmentForEntityPayload();
        let endPoint = 'GetallFederalSecondmentforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    });
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllHousingPolicyForEntity = () => {
        let payload = getAllHousingPolicyForEntityPayload();
        let endPoint = 'GetallHousingPolicyforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];
                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllExcemptionFromAcompanyPatient = () => {
        let payload = getExceptionAccompanyPatientForEntityPayload();
        let endPoint = 'GetallPoliciesPatientforentity '
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllPromotion = () => {
        let payload = getExceptionAccompanyPatientForEntityPayload();
        let endPoint = 'GetallPromotion '
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllDisciplinaryboard = () => {
        let payload = getExceptionAccompanyPatientForEntityPayload();
        let endPoint = 'GetallDisciplinaryboard'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllSickLeave= () => {
        let payload = getExceptionAccompanyPatientForEntityPayload();
        let endPoint = 'GetallSickLeave'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callGetAllRetirmentRequest = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetallRetirementsforentity'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    // data services get list apis
    callExtendCreateObjectiveList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetExtendCreateObjectivesIntervalList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
   
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            
            if (res.Status) {
               
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            // no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    uploaddocuments()
    {
       
    }
    callExtendReviewObjectivesList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetExtendReviewObjectivesList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callExtendAnnualPerformanceList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetExtendAnnualPerformanceList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callAddEditEmployeePerformanceList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetEmpPerformanceList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callJoinAndImplementResourceSystemForNewEntityList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetJoinAndImplementSystemsList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callOpenComplainStageList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetOpenGrievanceProcessList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callAmendSharedGovHrManagementList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetAmendSystemsList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);

        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callReportAndStaticRequestList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetReportsRequestsList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);

        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    callOtherRequestsList = () => {
        let payload = getAllSciRequestForEntityPayload();
        let endPoint = 'GetOtherRequestsList'
        let url = Config.getUrl(endPoint);
        this.props.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.props.toggleLoadingSpinner(false);
            if (res.Status) {
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            // id_id: resArray[index].id,
                            createdby: resArray[index].createdby,
                            createdon: tempCreatedDate,
                            Requestnumber: resArray[index].Requestnumber,
                            statusreason: resArray[index].statusreason,
                            status: resArray[index].status,
                            entityenglishname: resArray[index].entityenglishname,
                            entityarabicname: resArray[index].entityarabicname,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.dataColumns,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
validatekey(event)

{
    debugger;
    let str = '';
    if(event.keyCode>48)
    {
    if(event.target.value.length>0)
    {
        str=event.target.value+event.key;
    }
    else
    {
        str=event.key;
    }
}

   if
   (event.keyCode == 8 || event.keyCode == 46)
   {
    str=event.target.value;
       if(event.keyCode==8 && event.target.value.length==1)
       {
           str='';
       }
       else if (event.keyCode==46)
       {
        str='';
       }
   
    if(this.state.popUpRowArray.length<1 || str=='')
    {
        if(str.length>0)
        {     
        str=str.substring(0,str.length-1);
        }
     
        this.setState({popUpRowArray:this.state.temppopUpRowArray}, () =>{this.handleLoginKeyUp(str);});
    }
    else
    {
        //alert(str);
       // str=str;
       

     this.setState({popUpRowArray:this.state.temppopUpRowArray}, () =>{this.handleLoginKeyUp(str);});
    }
   }
   else
   {
if(this.state.temppopUpRowArray.length<1)
{
    this.setState({temppopUpRowArray:this.state.popUpRowArray});
}
if(event.target.value=='')
{
    str='';
}
    this.handleLoginKeyUp(str);
    //this.setState({temppopUpRowArray:this.state.popUpRowArray}, () =>{this.handleLoginKeyUp(str);});

   }
   

}
handleLoginKeyUp = (str) => {
        //debugger;
        // alert(this.state.popUpRowArray.length);
        // alert(this.state.temppopUpRowArray.length);
        debugger;
        if (this.state.isAdmin) {
            //let str = e.target.value.toLowerCase();
           
            if (str.length > 0) {
                if (this.state.data.rows && this.state.data.rows.length > 0) {
                    let temp =[];
               if(this.state.pathName==='search')    
               {
                 
                temp = this.state.temppopUpRowArray.filter((item) => {
                    if ((item.englishName && item.englishName.toLowerCase().indexOf(str) > -1) || (item.arabicName && item.arabicName.toLowerCase().indexOf(str) > -1) || (item.username && item.username.toLowerCase().indexOf(str) > -1) || (item.emailAddress && item.emailAddress.toLowerCase().indexOf(str) > -1) || (item.mobilenumber && item.mobilenumber.toLowerCase().indexOf(str) > -1) || (item.phonenumber && item.phonenumber.toLowerCase().indexOf(str) > -1) || (item.designation && item.designation.toLowerCase().indexOf(str) > -1) || (item.accountType && item.accountType.toLowerCase().indexOf(str) > -1) || (item.sectorname && item.sectorname.toLowerCase().indexOf(str) > -1) || (item.employeenumber && item.employeenumber.toLowerCase().indexOf(str) > -1) || (item.industryname && item.industryname.toLowerCase().indexOf(str) > -1) || (item.cityname && item.cityname.toLowerCase().indexOf(str) > -1) || (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) || (item.EnglishTitle && item.EnglishTitle.toLowerCase().indexOf(str) > -1) || (item.ArabicTitle && item.ArabicTitle.toLowerCase().indexOf(str) > -1) || (item.title && item.title.toLowerCase().indexOf(str) > -1)) {
                        return item;
                    }
                });

               }
               else
                 {    temp = this.state.popUpRowArray.filter((item) => {
                        if ((item.englishName && item.englishName.toLowerCase().indexOf(str) > -1) || (item.arabicName && item.arabicName.toLowerCase().indexOf(str) > -1) || (item.username && item.username.toLowerCase().indexOf(str) > -1) || (item.emailAddress && item.emailAddress.toLowerCase().indexOf(str) > -1) || (item.mobilenumber && item.mobilenumber.toLowerCase().indexOf(str) > -1) || (item.phonenumber && item.phonenumber.toLowerCase().indexOf(str) > -1) || (item.designation && item.designation.toLowerCase().indexOf(str) > -1) || (item.accountType && item.accountType.toLowerCase().indexOf(str) > -1) || (item.sectorname && item.sectorname.toLowerCase().indexOf(str) > -1) || (item.employeenumber && item.employeenumber.toLowerCase().indexOf(str) > -1) || (item.industryname && item.industryname.toLowerCase().indexOf(str) > -1) || (item.cityname && item.cityname.toLowerCase().indexOf(str) > -1) || (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) || (item.EnglishTitle && item.EnglishTitle.toLowerCase().indexOf(str) > -1) || (item.ArabicTitle && item.ArabicTitle.toLowerCase().indexOf(str) > -1) || (item.title && item.title.toLowerCase().indexOf(str) > -1)) {
                            return item;
                        }
                    });
                }

                    this.setState({

                        popUpRowArray: temp
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    });
                }
            }
            else {
                this.setState({
                 
                    popUpRowArray: this.state.actualArray
                });
            }
        } else {
           
           // let str = e.target.value.toLowerCase();
           
           debugger;
           if(this.state.pathName==='search')    
           {
               
            
                if (str.length > 0) {
                 let temparr = this.state.temppopUpRowArray;
                 str=str.toLowerCase();
                    let temp = temparr.filter((item) => {
                        debugger;
                        if ((item.createdby && item.createdby.toLowerCase().indexOf(str) > -1) ||
                        (item.entityarabicname && item.entityarabicname.toLowerCase().indexOf(str) > -1) ||
                        (item.entityenglishname && item.entityenglishname.toLowerCase().indexOf(str) > -1) ||
                        (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) ||
                        (item.assigneddepartmentname && item.assigneddepartmentname.toLowerCase().indexOf(str) > -1) ||
                        (item.assignedemployeename && item.assignedemployeename.toLowerCase().indexOf(str) > -1) ||
                        (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) ||
                        (item.subject && item.subject.toLowerCase().indexOf(str) > -1) ||
                        (item.Requestnumber && item.Requestnumber.toLowerCase().indexOf(str) > -1) ||
                        (item.createdon && item.createdon.toLowerCase().indexOf(str) > -1)|| (item.status && item.status.toLowerCase().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getFullYear().toString().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getMonth().toString().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getDate().toString().indexOf(str) > -1)) {
                           
                            return item;
                        }
                    });
            
                    this.setState({
                        // dataRow: temp
                        popUpRowArray: temp
                    }, () => {
                        this.props.updateListData(this.state.data, this.state.popUpRowArray);
                    });
              
                }
                else {
                    this.setState({
                     
                        popUpRowArray: this.state.actualArray
                    });
                }
            }
            else
            {   
                if (str.length > 0)
                {
                

                let temp = this.state.popUpRowArray.filter((item) => {
                    debugger;
                    if ((item.createdby && item.createdby.toLowerCase().indexOf(str) > -1) ||
                    (item.entityarabicname && item.entityarabicname.toLowerCase().indexOf(str) > -1) ||
                    (item.entityenglishname && item.entityenglishname.toLowerCase().indexOf(str) > -1) ||
                    (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) ||
                    (item.assigneddepartmentname && item.assigneddepartmentname.toLowerCase().indexOf(str) > -1) ||
                    (item.assignedemployeename && item.assignedemployeename.toLowerCase().indexOf(str) > -1) ||
                    (item.statusreason && item.statusreason.toLowerCase().indexOf(str) > -1) ||
                    (item.subject && item.subject.toLowerCase().indexOf(str) > -1) ||
                    (item.Requestnumber && item.Requestnumber.toLowerCase().indexOf(str) > -1) ||
                    (item.createdon && item.createdon.toLowerCase().indexOf(str) > -1)|| (item.status && item.status.toLowerCase().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getFullYear().toString().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getMonth().toString().indexOf(str) > -1) || (item.createdon && new Date(item.createdon).getDate().toString().indexOf(str) > -1)) {
                       
                        return item;
                    }
                });
        
                this.setState({
                    // dataRow: temp
                    popUpRowArray: temp
                }, () => {
                    this.props.updateListData(this.state.data, this.state.popUpRowArray);
                });
                      }
                      else {
                        this.setState({
                         
                            popUpRowArray: this.state.actualArray
                        });
                    }
                       
           }
            
        }
    }

    renderDataTableRows = (item, index) => {
     console.log('table item : ')
        if (this.state.isAdmin) {
            if (this.state.pathName === 'entity') {
                return (
                    <tr style={{ textAlign: "center", padding: '10%' }}>
                        <td>{item.englishName}</td>
                        <td>{item.arabicName}</td>
                        <td>{item.shortName}</td>
                        <td>{item.employeenumber}</td>
                        <td>{item.sectorname}</td>
                        <td>{item.industryname}</td>
                        <td>{item.description}</td>
                        <td>{item.cityname}</td>
                        <td>{item.phonenumber}</td>
                        <td>
                            <span
                                onClick={(e) => {
                                 
                                    this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                                }}
                                id={"checkbox" + index}>
                                <PopOverComponent
                                    manageAction={this.props.manageAction}
                                    y={this.props.y}
                                    isArabic={this.state.isArabic}
                                    index={index}
                                    data={this.state.popUpRowArray[index]} />
                                     </span>
                                  
                           
                        </td>
                    </tr>
                )
            }
            else if (this.state.pathName === 'contact') {
                return (
                    <tr style={{ textAlign: "center", padding: '10%' }}>
                        <td>{item.englishName}</td>
                        <td>{item.arabicName}</td>
                        <td>{item.username}</td>
                        <td>{item.emailAddress}</td>
                        <td>{item.role}</td>
                     
                        <td>
                            <span
                                onClick={(e) => {
                                
                                    this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                                }}
                                id={"checkbox" + index}>
                                <PopOverComponent
                                    manageAction={this.props.manageAction}
                                    y={this.props.y}
                                    isArabic={this.state.isArabic}
                                    index={index}
                                    data={this.state.popUpRowArray[index]} />
                            </span>
                        </td>
                    </tr>
                )
            }
            else if (this.state.pathName === 'lookUp') {
                return (
                    <tr style={{ textAlign: "center", padding: '10%' }}>
                        <td>{item.EnglishTitle}</td>
                        <td>{item.ArabicTitle}</td>
                        <td>
                            <span
                                onClick={(e) => {
                            
                                    this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                                }}
                                id={"checkbox" + index}>
                                <PopOverComponent
                                    manageAction={this.props.manageAction}
                                    y={this.props.y}
                                    isArabic={this.state.isArabic}
                                    index={index}
                                    data={this.state.popUpRowArray[index]} />
                            </span>
                        </td>
                    </tr>
                )
            }
            else if (this.state.pathName === 'mailTemplate') {
                return (
                    <tr style={{ textAlign: "center", padding: '10%' }}>
                        <td>{item.title}</td>
                        <td>{item.createdon}</td>
                        <td>{item.modifiedon}</td>
                        <td>
                            <span
                                onClick={(e) => {
                                 
                                    this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                                }}
                                id={"checkbox" + index}>
                                <PopOverComponent
                                    manageAction={this.props.manageAction}
                                    y={this.props.y}
                                    isArabic={this.state.isArabic}
                                    index={index}
                                    data={this.state.popUpRowArray[index]} />
                            </span>
                        </td>
                    </tr>
                )
            }
            else if (this.state.pathName === 'role') {
                return (
                    <tr style={{ textAlign: "center", padding: '10%' }}>
                        <td>{item.EnglishTitle}</td>
                        <td>{item.ArabicTitle}</td>
                        <td>
                            <span
                                onClick={(e) => {
                                 
                                    this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                                }}
                                id={"checkbox" + index}>
                                <PopOverComponent
                                    manageAction={this.props.manageAction}
                                    y={this.props.y}
                                    isArabic={this.state.isArabic}
                                    index={index}
                                    data={this.state.popUpRowArray[index]} />
                            </span>
                        </td>
                    </tr>
                )
            }
        } else {
            
            return (
                <tr style={{ textAlign: "center" }}>
                    <td>
                        <span>{item.Requestnumber}</span><br/>
                        {
                           this.state.pathName === 'SCIRequest'|| this.state.pathName === 'StrategicPlanningforworkforceEnquire' ?
                             <span>{item.createdon}  </span>:null
                       
                        }
                    </td>
                    {
                        
                            this.state.pathName === 'informationRequest' ||
                            this.state.pathName === 'bonusRequest' ||
                            this.state.pathName === 'amendSalaryScreen' ||
                            this.state.pathName === 'amendEmployeeSalary' ||
                            this.state.pathName === 'reviewHRPolicies' ||
                            
                            this.state.pathName === 'secondmentForFederalAgency' ||
                            this.state.pathName === 'exemptionFromAccompanyPatient' ||
                            this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest' ||
                            this.state.pathName === 'extendCreateObjectives' ||
                            this.state.pathName === 'extendAnnualPerformance' ||
                            this.state.pathName === 'extendCreateObjectives' ||
                            this.state.pathName === 'extendReviewObjectives' ||
                            this.state.pathName === 'extendAnnualPerformance' ||
                            this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation' ||
                            this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity' ||
                            this.state.pathName === 'openComplainsStage' ||
                            this.state.pathName === 'amendsSharedGovernmentHRManagement' ||
                            this.state.pathName === 'reportsAndStaticRequest' ||
                                // organization structure
                            this.state.pathName === 'OrganizationStructure' ||
                            this.state.pathName === 'otherRequests' ||
                            this.state.pathName === 'Promotion'||this.state.pathName === 'SickLeave' ||this.state.pathName === 'Disciplinaryboard'
                           
                             ?
                            null
                            :
                            <td>
                                <span>{item.subject}</span>
                            
                            </td>
                    }
                    {
                        this.state.pathName === 'informationRequest' ?
                        Config.getLocalStorageLoginData().internalcontact == 1 ?  <td><span>{item.createdby}</span></td>
                        :<td><span>{this.props.isArabic ? item.entityarabicname : item.entityenglishname}</span></td>

                            :
                            Config.getLocalStorageLoginData().internalcontact==0 ? 
                             
                             '':<td><span>{this.props.isArabic ? item.entityarabicname : item.entityenglishname}</span></td>
                           
                    }
                    <td>
                        {item.statusreasonid === 10970 ||item.statusreasonid ===10987?

                            <div className={


                                "statusTxtForReopen"
                            }>
                                {item.status + "[" + item.statusreason + "]"}

                            </div>
                            :
                            <span className={

                                item.isviolated === 1
                                    ? "statusTxtForViolationGreen"
                                    : item.isviolated === 2 ?
                                        "statusTxtForViolationYellow"
                                        :
                                        "statusTxtForViolationRed"
                            }>
                                {item.status}

                            </span>
                        }
                        <br />
                        {
                            item.progresspercent === 0 ?
                                null
                                :
                                <span>
                                    <MDBProgress material value={item.progresspercent} height="15px" color="info">
                                        {item.progresspercent}%
                                    </MDBProgress>
                                </span>
                        }
                    </td>
                    
                    {
                      
                        this.props.isInternal === 0 ?
                            null :
                            <td>
                              
                              {item.statusreasonid === 10970 ||item.statusreasonid ===10987?

                                    <div className={


                                        "statusTxtForReopen"
                                    }>
                                        {item.statusreason}

                                    </div>
                                    :
                                    <span>
                                        {item.statusreason}
                                    </span>
                                  
                                }
                                <br />
                                    <span className="statusTxtForViolationYellow">
                                        {
                                            item.statusreasonid == Config.getReasonForSectorAssignedId() ?
                                                item.assignedsectorname
                                                :
                                                item.statusreasonid == Config.getReasonForDepartmentAssignedId() ||
                                                    item.statusreasonid == Config.getReasonSCIDepartmentAssignedId() ||
                                                    item.statusreasonid == Config.getReasonForStudyProvidedId() ?
                                                    item.assigneddepartmentname
                                                    :
                                                item.statusreasonid == Config.getReasonForEmployeeAssignedId() ||
                                                        item.statusreasonid == Config.getReasonForStudyProvidedId() ?
                                                        item.assignedemployeename
                                                        :
                                                        null
                                                        
                                        }
                                    </span>
                            </td>
                    }
                    
                    {
                         this.state.pathName === 'SCIRequest'|| this.state.pathName === 'StrategicPlanningforworkforceEnquire' ?
                    <td><span> {item.createdon}</span></td> :<td><span> {item.createdon}</span></td> 
                    }
                    <td>
                    <span class="Spnaction"
                            onClick={(e) => {
                                this.props.updateYClickCoOrdinate(e.nativeEvent.clientY);
                            }}

                            MGM
                            id={"checkbox" + index}>
                          
                           {localStorage.getItem("currentPath") === "OrganizationStructure" 
                           || item.Requestnumber.indexOf('OS')>-1
                            ?
                            <OrganizationStructurePopOverComponent
                            changeState={this.props.changeState}
                            manageAction={this.props.manageAction}
                            y={this.props.y}
                            isArabic={this.state.isArabic}
                            index={index}
                            // tempindex={item.id}
                            data={this.state.popUpRowArray[index]} />
                           :
                            <PopOverComponent
                                changeState={this.props.changeState}
                                manageAction={this.props.manageAction}
                                y={this.props.y}
                                isArabic={this.state.isArabic}
                                index={index}
                                // tempindex={item.id}
                                data={this.state.popUpRowArray[index]} />
                            }
                      
                    </span>
                      
                    </td>
               
                </tr>
            )
        }
    }

    render() {
        
        
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        } else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBContainer fluid style={{ paddingTop: "20px", marginBottom: '150px', paddingRight: '0px', paddingLeft: '0px' }}>
                {
                    this.state.pathName === 'lookUp' ?
                        <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse' }}>
                            <MDBCol md="4">
                   
                                <input class="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search} onKeyDown={this.validatekey} ref="SearchInput" />
                   
                                </MDBCol>
                            <MDBCol md="4"></MDBCol>
                            <MDBCol md="4">
                                <div className="form-group">
                                    <select
                                        style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                        className="browser-default custom-select" id="sources-funding"
                                        onChange={this.handleChangeForSelectRequestType}
                                        value={this.state.requestTypeSelectedValue}
                                        required >
                                        <option value="">
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                        </option>
                                        {
                                            this.state.lookupArray ? this.state.lookupArray.map((item, index) => {
                                                return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow> :

                        <MDBRow style={{ flexDirection: this.props.isArabic ? 'row' : 'row-reverse' }}>
                            {/* <MDBCol md="8"></MDBCol> */}
                            <MDBCol md="12">
                                {/* <input class="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search} onChange={this.handleLoginKeyUp} ref="SearchInput" /> */}
                                {/*MGM*/}
                                {this.state.pathName=='search'?
                                <div>  <MDBRow>
                                <MDBCol md="12" sm="12" size="12" style={{ paddingLeft: '10px', paddingRight: '10px',paddingTop:"10px" }}>
                            <input class="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search} ref="SearchInput"     onChange={(value) => { this.handleSearchTextbox(value) }}/>
                            </MDBCol>
                            </MDBRow>
                            <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
             <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                          <div tyle={{ paddingLeft: '10px', paddingRight: '10px',paddingTop:"10px" }}>
                            <MDBBtn className="submitBtn" color="primary" 
                             onClick={this.handleSeach}
                            >
                               {i18n[this.getLocale()].search}
                        
                           </MDBBtn>       </div>
                            </MDBCol>
                            </MDBRow>
</div> :
                                <input className="form-control hraSearch" style={{ textAlign: this.props.isArabic ? 'right' : '' }} type="search" placeholder={i18n[this.getLocale()].search}   onKeyDown={this.validatekey} ref="SearchInput" />
                                    }
                                </MDBCol>
                        </MDBRow>
                }
                <MDBRow className="padTop20">
                    <MDBCol md="12">
                        <MDBTable striped responsive hover style={{ marginBottom: '80px', direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                        <MDBTableHead>
                        <tr className="tableHeader"> 
                                    
                              
                        {
                                        this.state.dataColumns ? this.state.dataColumns.map((item, index) => {
                                       
                                            if (this.props.isInternal === 0 && item.field === 'step') {
                                                return '';
                                            }
                                            if( (this.state.pathName === 'SCIRequest'|| this.state.pathName === 'StrategicPlanningforworkforceEnquire'||this.state.pathName === 'exceptionADHousing' ) && item.field === 'Subject')
                                            {
                                                    //return  i18n[this.props.isArabic === 'ar' ? 'ar' : 'en'].subject;
                                                   
                                                    return(   <th><span >{i18n[this.getLocale()].SCI.requestSubject}
                                                    </span>
                                                        </th>);
                                            }
                                            if( this.state.pathName === 'SCIRequest' && item.field === 'Requestnumber')
                                            {
                                                return(   <th><span>{i18n[this.getLocale()].viewComponent.Requestnumber} - {i18n[this.getLocale()].viewComponent.createdon}
                                                </span>
                                                    </th>);
                                            }
                                            if ((this.state.pathName === 'informationRequest' ||
                                                this.state.pathName === 'bonusRequest' ||
                                              this.state.pathName === 'SCIRequest'|| this.state.pathName === 'StrategicPlanningforworkforceEnquire' ||
                                                this.state.pathName === 'amendSalaryScreen' ||
                                                this.state.pathName === 'amendEmployeeSalary' ||
                                                this.state.pathName === 'reviewHRPolicies' ||
                                                
                                               
                                                this.state.pathName === 'secondmentForFederalAgency' ||
                                                this.state.pathName === 'exemptionFromAccompanyPatient' ||
                                                this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest' ||
                                                //Data Services
                                                this.state.pathName === 'extendCreateObjectives' ||
                                                this.state.pathName === 'extendReviewObjectives' ||
                                                this.state.pathName === 'extendAnnualPerformance' ||
                                                this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation' ||
                                                this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity' ||
                                                this.state.pathName === 'openComplainsStage' ||
                                                this.state.pathName === 'amendsSharedGovernmentHRManagement' ||
                                                this.state.pathName === 'reportsAndStaticRequest' ||
                                                this.state.pathName === 'otherRequests'
                                                
                                                // organization structure
                                                ||this.state.pathName === 'OrganizationStructure' 
                                                ||this.state.pathName === 'Promotion' ||this.state.pathName === 'SickLeave' || this.state.pathName === 'Disciplinaryboard'
                                                )
                                                && item.field === 'Subject'
                                            ) {
                                                return '';
                                            }
                                            if ((
                                              this.state.pathName === 'SCIRequest'|| this.state.pathName === 'StrategicPlanningforworkforceEnquire' ||
                                                this.state.pathName === 'bonusRequest' ||
                                                this.state.pathName === 'amendSalaryScreen' ||
                                                this.state.pathName === 'amendEmployeeSalary' ||
                                                this.state.pathName === 'reviewHRPolicies' ||
                                                this.state.pathName === 'exceptionADHousing' ||
                                                this.state.pathName === 'secondmentForFederalAgency' ||
                                                this.state.pathName === 'exemptionFromAccompanyPatient' ||
                                                this.state.pathName === 'exceptionOfSteerTheAgeOfRetirementRequest' ||
                                                //Data Services
                                                this.state.pathName === 'extendCreateObjectives' ||
                                                this.state.pathName === 'extendReviewObjectives' ||
                                                this.state.pathName === 'extendAnnualPerformance' ||
                                                this.state.pathName === 'addOrEditEmployeesPerformanceEvaluation' ||
                                                this.state.pathName === 'joinAndImplementGovResourcesSystemForNewEntity' ||
                                                this.state.pathName === 'openComplainsStage' ||
                                                this.state.pathName === 'amendsSharedGovernmentHRManagement' ||
                                                this.state.pathName === 'reportsAndStaticRequest' ||
                                                this.state.pathName === 'otherRequests' || 
                                               // organization structure
                                               this.state.pathName === 'OrganizationStructure' ||this.state.pathName === 'Promotion' ||this.state.pathName === 'SickLeave' || 
                                               this.state.pathName === 'Disciplinaryboard'
                                                // for inbox
                                                || this.state.pathName === 'inprogress' ||
                                                this.state.pathName === 'pending' ||       this.state.pathName === 'Late' ||
                                                this.state.pathName === 'Underapproval'||
                                                this.state.pathName === 'completed' ||
                                                this.state.pathName === 'cancelled' ||
                                                this.state.pathName === 'onhold' ||
                                                this.state.pathName === 'currentUserTasks' ||this.state.pathName === 'search' 
                                            ) && item.field === 'createdby' ) {
                                                return '';
                                            }
                                            if (
                                                (this.state.pathName === 'informationRequest'|| Config.getLocalStorageLoginData().internalcontact==0) &&
                                                item.field === 'entitynameen'  ) {
                                                return '';
                                            }
                                            return (
                                                <th> 
                                                    
                                                    <TableLabel
                                                        key={index}
                                                        isArabic={this.props.isArabic}
                                                        field={item.field}
                                                        isAdmin={this.state.isAdmin}
                                                    />
<br/>

{Config.getLocalStorageLoginData().internalcontact && item.field==='Requestnumber'? 
this.state.pathName === 'inprogress' || this.state.pathName === 'Late'   ||  this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' ||  this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold'  ?
<div style={{paddingTop:5,paddingBottom:5,minWidth:65}}>
 <MDBBtn onClick={this.sortascRequestnumber} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdescRequestnumber} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn>
</div>:'':''}
{Config.getLocalStorageLoginData().internalcontact && item.field==='Subject'? 
this.state.pathName === 'inprogress' || this.state.pathName === 'Late' || this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' || this.state.pathName === 'Underapproval' || this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold' ?<div style={{paddingTop:5,paddingBottom:5,minWidth:65}}>
 <MDBBtn onClick={this.sortascSubject} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdescSubject} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn>
</div>:'':''}
{Config.getLocalStorageLoginData().internalcontact && item.field==='entitynameen'? 
this.state.pathName === 'inprogress' || this.state.pathName === 'Late'|| this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' || this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold' ?<div style={{paddingTop:5,paddingBottom:5,minWidth:65}}>
 <MDBBtn onClick={this.sortascentitynameen} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdescentitynameen} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn>
</div>:'':''}
{Config.getLocalStorageLoginData().internalcontact && item.field==='status'? 
this.state.pathName === 'inprogress'|| this.state.pathName === 'Late' || this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' || this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold' ?<div style={{paddingTop:5,paddingBottom:5,minWidth:65}}>
 <MDBBtn onClick={this.sortascstatus} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdescstatus} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn>
</div>:'':''}
{Config.getLocalStorageLoginData().internalcontact && item.field==='step'? 
this.state.pathName === 'inprogress' || this.state.pathName === 'Late'|| this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' || this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold' ?<div style={{paddingTop:5,paddingBottom:5,minWidth:65}}>
 <MDBBtn onClick={this.sortascstep} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdescstep} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn>
</div>:'':''}

{Config.getLocalStorageLoginData().internalcontact && item.field==='createdon'? 
this.state.pathName === 'inprogress' || this.state.pathName === 'Late'|| this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' || this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold' ?
<div style={{paddingTop:5,paddingBottom:5,minWidth:65}}>
 <MDBBtn onClick={this.sortasccreatedon} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdesccreatedon} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn>
</div>:'':''}

{Config.getLocalStorageLoginData().internalcontact && item.field==='action'? 
this.state.pathName === 'inprogress' || this.state.pathName === 'Late'|| this.state.pathName === 'pending' || this.state.pathName === 'Underapproval' || this.state.pathName === 'completed' || this.state.pathName === 'cancelled' ||
this.state.pathName === 'onhold' ?
<div style={{paddingTop:5,paddingBottom:5,minWidth:65,height:58}}>
 {/* <MDBBtn onClick={this.sortasc} color="primary" style={{ width: '20px',  padding: 5, borderRadius: 5 }}> ↑ </MDBBtn><MDBBtn onClick={this.sortdesc} color="primary" style={{ width: '20px', padding: 5, borderRadius: 5 }}> ↓ </MDBBtn> */}
</div>:'':''}


                                                </th>
                                            )
                                        })
                                            :
                                            ''
                                    }
                             
                          
                           
                        </tr> </MDBTableHead>
                <MDBTableBody>
                               
                                {
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ?
                                        null
                                        :
                                        <tr>
                                            <td align="center" colSpan="12">
                                                <div className="noRecordFound">
                                                    {i18n[this.props.isArabic ? 'ar' : 'en'].error_message_one}
                                                </div>
                                            </td>
                                        </tr>

                                }
                                {
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ? this.state.popUpRowArray.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                                
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                        null
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}