import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// import Login from './LoginScreen';
import Login from './Login';
import ListPage from './ListPage';
import DashboardScreen from './DashboardScreen';
import AmendSalaryScreen from './AmendSalaryScreen';
import Reports from './Reports'
import SCIRequest from './SCIRequest'
import ExemptionFromAccompanyPatient from './ExemptionFromAccompanyPatient';
import ReviewHRPolicies from './ReviewHRPolicies';
import ExceptionADHousing from './ExceptionADHousing';
import BonusRequest from './BonusRequest';
import InformationRequest from './InformationRequest';
import ProtectedRoute from "../components/ProtectedRoutes";
import Landing from './Landing';
import SecondmentForFederalAgency from './SecondmentForFederalAgency';
import AmendEmployeeSalary from './AmendEmployeeSalary';
// import ForgotPasword from './ForgotPassword';
import ConfirmPassword from './ConfirmPassword';
import ExceptionOfSteerTheAgeOfRetirementRequest from './ExceptionOfSteerTheAgeOfRetirementRequest';
import AboutEservices from './AboutEservices';
import Faqs from './Faqs';
import Role from './AdminPanel/Role';
import Entity from './AdminPanel/Entity';
import Contact from './AdminPanel/Contact';
import LookUp from './AdminPanel/LookUp';
import MailTemplate from './AdminPanel/MailTemplate';
// organization structure
import OrganizationStructure from './../components/OrganizationStructure/OrganizationStructureRequest';
import OrganizationStructureTree from './../components/OrganizationStructure/OrganizationStructureTree';
import OrganizationStructureTreePreview from './../components/OrganizationStructure/OrganizationStructureTreePreview';


import HierarchyContainer from './../components/OrganizationStructure/HierarchyContainer';

import ExtendCreateObjectives from './ExtendCreateObjectives';
import ExtendReviewObjectives from './ExtendReviewObjectives';
import ExtendAnnualPerformance from './ExtendAnnualPerformance';
import AddOrEditEmployeePerformanceEvaluation from './AddOrEditEmployeesPerformanceEvaluation';
import JoinAndImplementGovResourcesSystemForNewEntity from './JoinAndImplementGovResourcesSystemForNewEntity';
import OpenComplainsStage from './OpenComplainsStage';
import AmendsSharedGovernmentHRManagement from './AmendsSharedGovernmentHRManagement';
import ReportsAndStaticRequest from './ReportsAndStaticRequest';
import OtherRequests from './OtherRequests';
import HelpScreen from './HelpScreen';
import ViewContainer from './ViewContainer';
import ViewDetailsDisciplinaryboard from './ViewDetailsDisciplinaryboard';

import EmployeesReports from './EmployeesReports';
// import TempViewContainer from './ViewContainerner';
import Preview from './Preview';
import SickLeave from './SickLeave';
import Promotion from './Promotion';
import Disciplinaryboard from './Disciplinaryboard';
import WorkShopManage from './WorkShop/WorkShopManage';
import WorkShopBooking from './WorkShop/WorkShopBooking';
import WorkShopBookinglist from './WorkShop/WorkShopBookinglist';
import WorkShopBookedList from './WorkShop/WorkShopBookedList';
import WorkShopMyBookedList from './WorkShop/WorkShopMyBookedList';
import WorkShopAdd from './WorkShop/WorkShopAdd';
import ActionPlansEntity from './ActionPlans/ActionPlansEntity';
 import ActionPlansInitiatives from './ActionPlans/ActionPlansInitiatives';
import ActionPlansIntro from './ActionPlans/ActionPlansIntro';
import ActionPlansList from './ActionPlans/ActionPlansList';
import ViewDetailsActionPlans from './ViewDetailsActionPlans';
import ViewDetailsCircular from './ViewDetailsCircular';

import ActionPlansListInternal from './ActionPlans/ActionPlansListInternal';
import ActionPlansKPI from './ActionPlans/ActionPlansKPI';
import Circularization from './Circularization/Circularization';
import CircularizationCreate from './Circularization/CircularizationCreate'
import Substitutionplan from './SubstitutionPlan/Substitutionplan'
import SubstitutionplanForm from './SubstitutionPlan/SubstitutionplanForm'
import Survey from './Survey/Survey'
import Search from './Search';
import StrategicPlanningforworkforce from  './StrategicPlanningforworkforce/StrategicPlanningforworkforce';
import StrategicPlanningforworkforceForm from  './StrategicPlanningforworkforce/StrategicPlanningforworkforceForm';
import StrategicPlanningforworkforceEnquire from  './StrategicPlanningforworkforce/StrategicPlanningforworkforceEnquire';
import StrategicPlanningEnquireforworkforceForm from  './StrategicPlanningforworkforce/StrategicPlanningEnquireforworkforceForm';
 export default () => (
	<BrowserRouter>
		
		
		<ProtectedRoute path="/StrategicPlanningforworkforceEnquire" exact component={StrategicPlanningforworkforceEnquire} />
		<ProtectedRoute path="/StrategicPlanningEnquireforworkforceForm" exact component={StrategicPlanningEnquireforworkforceForm} /><Switch>
		<ProtectedRoute path="/StrategicPlanningforworkforce" exact component={StrategicPlanningforworkforce} />
		<ProtectedRoute path="/StrategicPlanningforworkforceForm" exact component={StrategicPlanningforworkforceForm} />
		<ProtectedRoute path="/Survey" exact component={Survey} />
		<ProtectedRoute path="/ViewDetailsCircular" exact component={ViewDetailsCircular} />
		<ProtectedRoute path="/Substitutionplan" exacet component={Substitutionplan}/>
		<ProtectedRoute path="/SubstitutionplanForm" exacet component={SubstitutionplanForm}/>
			<ProtectedRoute path="/CircularizationCreate" exacet component={CircularizationCreate}/>
<ProtectedRoute path="/Circularization" exacet component={Circularization}/>

		<ProtectedRoute path="/Search" exact component={Search}/>
		<ProtectedRoute path="/ActionPlansKPI" exact component={ActionPlansKPI} />
		<ProtectedRoute path="/ActionPlansListInternal" exact component={ActionPlansListInternal} />
		<ProtectedRoute path="/ActionPlansInitiatives" exact component={ActionPlansInitiatives} />
		<ProtectedRoute path="/ActionPlansEntity" exact component={ActionPlansEntity} />
		<ProtectedRoute path="/ActionPlansIntro" exact component={ActionPlansIntro} />
		<ProtectedRoute path="/ActionPlansList" exact component={ActionPlansList} />
		<ProtectedRoute path="/ViewDetailsActionPlans" exact component={ViewDetailsActionPlans} />
		
		<ProtectedRoute path="/SickLeave" exact component={SickLeave} />
		<ProtectedRoute path="/Promotion" exact component={Promotion} />
		<ProtectedRoute path="/Disciplinaryboard" exact component={Disciplinaryboard} />
		<ProtectedRoute path="/WorkShopManage" exact component={WorkShopManage} />
		<ProtectedRoute path="/WorkShopBooking" exact component={WorkShopBooking} />
		<ProtectedRoute path="/WorkShopBookinglist" exact component={WorkShopBookinglist} />
		<ProtectedRoute path="/WorkShopBookedList" exact component={WorkShopBookedList} />
		<ProtectedRoute path="/WorkShopMyBookedList" exact component={WorkShopMyBookedList} />
		<ProtectedRoute path="/WorkShopAdd" exact component={WorkShopAdd} />
		
			{/* organization structure */}
		<ProtectedRoute path="/OrganizationStructure" exact component={OrganizationStructure} />
		<ProtectedRoute path="/OrganizationStructureTree" exact component={OrganizationStructureTree} />
		<ProtectedRoute path="/OrganizationStructureTreePreview" exact component={OrganizationStructureTreePreview} />

		<ProtectedRoute path="/hierarchyContainer" exact component={HierarchyContainer} />

			{/* <Route exact path='/' component={DashboardScreen} /> */}
			<Route exact path='/' component={Login} />
			<Route path='/loginScreen' exact component={Login} />
			{/* <Route path='/loginScreen' exact component={LoginScreen} /> */}
			<ProtectedRoute path="/amendSalaryScreen" exact component={AmendSalaryScreen} />
			<ProtectedRoute path="/SCIRequest" exact component={SCIRequest} />
			<ProtectedRoute path="/Reports" exact component={Reports} />
			<ProtectedRoute path="/EmployeesReports" exact component={EmployeesReports} />
			<ProtectedRoute path="/exemptionFromAccompanyPatient" exact component={ExemptionFromAccompanyPatient} />
			<ProtectedRoute path="/bonusRequest" exact component={BonusRequest} />
			<ProtectedRoute path="/informationRequest" exact component={InformationRequest} />
			<ProtectedRoute path="/secondmentForFederalAgency" exact component={SecondmentForFederalAgency} />
			<ProtectedRoute path="/listPage" exact component={ListPage} />
			<ProtectedRoute path="/landing" exact component={Landing} />
			<ProtectedRoute path="/reviewHRPolicies" exact component={ReviewHRPolicies} />
			<ProtectedRoute path="/exceptionOfSteerTheAgeOfRetirementRequest" exact component={ExceptionOfSteerTheAgeOfRetirementRequest} />
      		<ProtectedRoute path="/exceptionADHousing" exact component={ExceptionADHousing} />
			<ProtectedRoute path="/amendEmployeeSalary" exact component={AmendEmployeeSalary} />
			<ProtectedRoute path="/role" exact component={Role} />
			<ProtectedRoute path="/entity" exact component={Entity} />
			<ProtectedRoute path="/lookUp" exact component={LookUp} />
			<ProtectedRoute path="/contact" exact component={Contact} />
			<ProtectedRoute path="/mailTemplate" exact component={MailTemplate} />
			<ProtectedRoute path="/hierarchyContainer" exact component={HierarchyContainer} />

			<ProtectedRoute path="/extendCreateObjectives" exact component={ExtendCreateObjectives} />
			<ProtectedRoute path="/extendReviewObjectives" exact component={ExtendReviewObjectives} />
			<ProtectedRoute path="/extendAnnualPerformance" exact component={ExtendAnnualPerformance} />
			<ProtectedRoute path="/addOrEditEmployeesPerformanceEvaluation" exact component={AddOrEditEmployeePerformanceEvaluation} />
			<ProtectedRoute path="/joinAndImplementGovResourcesSystemForNewEntity" exact component={JoinAndImplementGovResourcesSystemForNewEntity} />
			<ProtectedRoute path="/openComplainsStage" exact component={OpenComplainsStage} />
			<ProtectedRoute path="/amendsSharedGovernmentHRManagement" exact component={AmendsSharedGovernmentHRManagement} />
			<ProtectedRoute path="/reportsAndStaticRequest" exact component={ReportsAndStaticRequest} />
			<ProtectedRoute path="/otherRequests" exact component={OtherRequests} />
			
			<ProtectedRoute path="/viewContainer" exact component={ViewContainer} />
			
			<ProtectedRoute path="/ViewDetailsDisciplinaryboard" exact component={ViewDetailsDisciplinaryboard} />
			{/* <ProtectedRoute path="/tempViewContainer" exact component={TempViewContainer} /> */}
			<ProtectedRoute path="/preview" exact component={Preview} />

			{/* <Route path="/forgotPasword" exact component={ForgotPasword} /> */}
			<Route path="/confirmPassword" exact component={ConfirmPassword} />
			<Route path="/aboutEservices" exact component={AboutEservices} />
			<Route path="/help" exact component={HelpScreen} />
			<Route path="/faqs" exact component={Faqs} />
		</Switch>
	</BrowserRouter>
);