import React from 'react';
import { MDBCol, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import i18n from './../i18n';
import Collapsible from 'react-collapsible';
import { right, left } from 'glamor';
import { red } from 'ansi-colors';

const DashboardTable = (props) => {
    return (
        <MDBCol className={props.isArabic ? "dashBoardStyle dashboardTable_ar collapseStyle" : "dashBoardStyle dashboardTable_en collapseStyle"} >
            <Collapsible trigger={i18n[props.isArabic ? 'ar' : 'en'].general_manger_service} open={false}>
                <MDBTable bordered style={{ direction: props.isArabic ? "rtl" : 'ltr' }} className="dashboardTable">

                    <MDBTableBody>
                        <tr >
                            <th style={{ width: "10%" }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceName}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].serviceDescription}</th>
                            <th style={{ width: "20%" }}>{i18n[props.isArabic ? 'ar' : 'en'].requiredDocuments}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].specialConditions}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].durationOfTheService}</th>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].sci}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].sciDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].EmptySLA}</td>
                        </tr>
                        {/* <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].infoRequest}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].infoReqDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].EmptySLA}</td>
                        </tr> */}
                    </MDBTableBody>
                </MDBTable>
            </Collapsible>

            <Collapsible trigger={i18n[props.isArabic ? 'ar' : 'en'].policies} open={false} >
                <MDBTable bordered style={{ direction: props.isArabic ? "rtl" : 'ltr' }} className="dashboardTable">

                    <MDBTableBody>
                        <tr >

                            <th style={{ width: '10%' }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceName}</th>
                            <th style={{ width: '20%' }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceDescription}</th>
                            <th style={{ width: '50%' }}>{i18n[props.isArabic ? 'ar' : 'en'].requiredDocuments}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].specialConditions}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].durationOfTheService}</th>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].exemptionFromAccompanyPatient}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].exempAccompanyPatientDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.passportCopy}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.medicalReport}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.letterFromEmbassyFile}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.copyOftheDigest}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.copyOfHumanResoourcesCommittee}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.documentationOfFinancialObligations}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.officialLetter}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.emiratedID}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].exempAccompanyPatientCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].exempAccompanyPatientDurationOfService}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].Promotion.subject}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].Promotion.Describtion}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.EmployeeCV}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.EmployeePhoto}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.positionofthenominatedposition}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.jobdescription}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.HumanResourcesCommittee}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.EmployeeNominationForm}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.EducationalQualifications}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.ExperienceCertificates}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.competencyreports}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.passport}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.Summaryofregistrationforcitizens}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.IDcard}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.CID}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.Medicalfitnesscheckup}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].Promotion.Anotheremployeesselfstatement}
                                            <span className="asterisk_input" ></span></li>

                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].Promotion.Condtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].Promotion.DurationOfService}</td>
                        </tr>


                        {/* <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].reveiew_hr_policies}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}>{i18n[props.isArabic ? 'ar' : 'en'].reviewHRPoliciesDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.standard_comparisons}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.studies}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.Entity_formal_request}
                                            <span class="asterisk_input">  </span></li>

                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].reviewHRPoliciesCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].reviewHRPoliciesDurationOfService}</td>
                        </tr>*/}
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].exceptionADHousing}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].exceptionADHousingDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].HRAPolicies.support_request}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].HRAPolicies.Entity_formal_request}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].employeeContract}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].flatContract}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].exceptionADHousingDurationOfService}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].secndmentForFedralAgency}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].secondmentOfFedralAgenDesc}</td>
                            <td >
                                <div >
                                    <ul>

                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Request_By_The_Competent_Entity}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Request_of_The_Borrowing_Entity}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Job_Description}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Copy_of_last_year_eff_report}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Emairates_Id}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Passport_copy}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Copy_Of_HR_Committe}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].secondement_For_Federal_Agency_Step.Approval_Of_Employee}
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].secondmentOfFedralAgenCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].secondmentOfFedralAgenDurationOfService}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].exceptionOfSteerRetirementRequest}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].excepOfSteerTheAgeOfRetirREQDesc}</td>
                            <td >
                                <div >
                                    <ul>

                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.jobContract}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.medicalApprovalsFromTheCometentMedicalCommittee}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.passport}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.annualPerformanceEvaluationattach}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.emaritesID}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.employeeCV}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].replacePlansForNonLocals}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.personalPhoto}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.Summaryofregistrationforcitizens}<span class="asterisk_input">  </span>
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.Previousapprovalsfortheextension}
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.Careersuccessionplan}  <span class="asterisk_input">  </span>
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.HRCom}<span class="asterisk_input">  </span>
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.PoistioninStructure}<span class="asterisk_input">  </span>
                                        </li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].excSteer.OtherAttach}
                                        </li>


                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].excepOfSteerTheAgeOfRetirREQCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].excepOfSteerTheAgeOfRetirREQDurationOfService}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </Collapsible>


            <Collapsible trigger={i18n[props.isArabic ? 'ar' : 'en'].policiesServices} open={false} >
                <MDBTable bordered style={{ direction: props.isArabic ? "rtl" : 'ltr' }} className="dashboardTable">

                    <MDBTableBody>
                        <tr >

                            <th style={{ width: '10%' }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceName}</th>
                            <th style={{ width: '20%' }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceDescription}</th>
                            <th style={{ width: '50%' }}>{i18n[props.isArabic ? 'ar' : 'en'].requiredDocuments}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].specialConditions}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].durationOfTheService}</th>
                        </tr>

                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].reveiew_hr_policies}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}>{i18n[props.isArabic ? 'ar' : 'en'].reviewHRPoliciesDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.standard_comparisons}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.studies}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].ReviewHRPolicies.Entity_formal_request}
                                            <span class="asterisk_input">  </span></li>

                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].reviewHRPoliciesCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].reviewHRPoliciesDurationOfService}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </Collapsible>


            <Collapsible trigger={i18n[props.isArabic ? 'ar' : 'en'].salaries} open={false} >
                <MDBTable bordered style={{ direction: props.isArabic ? "rtl" : 'ltr' }} className="dashboardTable">

                    <MDBTableBody>
                        <tr >

                            <th style={{ width: "10%" }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceName}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].serviceDescription}</th>
                            <th style={{ width: "42%" }}>{i18n[props.isArabic ? 'ar' : 'en'].requiredDocuments}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].specialConditions}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].durationOfTheService}</th>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendEmployeeSalary}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].amendEmpSalDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].employeeEmaritesId}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].employeePhoto}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].copyOfLastProcedure}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].previousAndCurrentApprovedCompetencyReport}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].approvedScaleOfSalaries}
                                            <span class="asterisk_input">  </span></li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendEmpSalSpecialCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendEmpSalDurationOfService}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendSalaryScale}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].amendSalScaleDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].approved_scale_of_grades_salaries}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].salaries_compensation_policy}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].proposed_scale}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].table_of_the_alignment_of_the_grades}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].comparative_study_with_similar_entities}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].illustrations_current_and_proposed_salary_lines}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].entity_chairman_approve}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].details_of_annual_cost}
                                            <span class="asterisk_input">  </span></li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendSalScaleSpecialCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendSalScaleDurationOfService}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].bonusRequest}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].bonusReqDesc}</td>
                            <td >
                                <div >
                                    <ul>

                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].approvedGradesAndSalaries}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].financialStatement}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].balancedScoreCard}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].uploadTheExcelAfterFill}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].resultsOfEvaluationGeneralPerformance}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].presentationforEntityAcheivements}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].budgetRelatedDocument}
                                            <span class="asterisk_input">  </span></li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].bonusReqSpecialCondtion}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].bonusReqDurationOfService}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </Collapsible>


            <Collapsible trigger={i18n[props.isArabic ? 'ar' : 'en'].dataServices} open={false} >

                <MDBTable bordered style={{ direction: props.isArabic ? "rtl" : 'ltr' }} className="dashboardTable">

                    <MDBTableBody>
                        <tr >

                            <th style={{ width: '10%' }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceName}</th>
                            <th style={{ width: '20%' }}>{i18n[props.isArabic ? 'ar' : 'en'].serviceDescription}</th>
                            <th style={{ width: '50%' }}>{i18n[props.isArabic ? 'ar' : 'en'].requiredDocuments}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].specialConditions}</th>
                            <th >{i18n[props.isArabic ? 'ar' : 'en'].durationOfTheService}</th>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendCreateObjectives}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].extendCreateObjectivesDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}

                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendCreateObjectivesSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendCreateObjectivesDuration}</td>
                        </tr>

                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendReviewObjectives}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].extendReviewObjectivesDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}

                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendReviewObjectivesSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendReviewObjectivesDuration}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationInterval}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationIntervalDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}

                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationIntervalSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationIntervalDuration}</td>
                        </tr>

                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluation}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluationDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.formalReqFromEntityEmp_for_AddOrEditPerEva}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.employeesFinalPerformanceEvaluationInExcelSheet_for_AddOrEditPerEva}
                                            <span class="asterisk_input">  </span></li>

                                    </ul>
                                </div>
                            </td>
                            <td>{i18n[props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluationSpecialCondition}</td>
                            <td>{i18n[props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluationDuration}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntity}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntityDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.attachEntityAttestedPolicyPDFFile}
                                            <span className="asterisk_input" ></span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.attachEntityAttestedOrgStructureFileInPDF}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.attachEntityCreationLawFileInPDF}
                                            <span class="asterisk_input">  </span></li>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.attachAgreementFromEntityTopManagementPDFFIle}
                                        </li>

                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntitySpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntityDuration}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].openComplainsStage}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].openComplainsStageDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}

                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].openComplainsStageSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].openComplainsStageDuration}</td>
                        </tr>

                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystem}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystemDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}

                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystemSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystemDuration}</td>
                        </tr>

                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequest}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequestDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].dataServicesObj.attachementForRequiredReqortFieldsAndDetails}
                                            <span className="asterisk_input" ></span></li>

                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequestSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequestDuration}</td>
                        </tr>
                        <tr>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].OtherRequests}</td>
                            <td style={{ textAlign: props.isArabic ? "right" : "left" }}> {i18n[props.isArabic ? 'ar' : 'en'].OtherRequestsDesc}</td>
                            <td >
                                <div >
                                    <ul>
                                        <li>{i18n[props.isArabic ? 'ar' : 'en'].NoAttachmentrequired}

                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].OtherRequestsSpecialCondition}</td>
                            <td >{i18n[props.isArabic ? 'ar' : 'en'].EmptySLA}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </Collapsible>
        </MDBCol >
    )
}
export default DashboardTable;