import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import Config from '../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import InputMask from 'react-input-mask';
// import CKEditorComponent from '../CKEditorComponent';
import Validator from "../../utils/Validator"
const striptags = require('striptags');

export default class EntityForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radio: 0,
            isNextScreenActive: false,
            englisName: "",
            englishName: '',
            arabicName: '',
            shortName: '',
            employeeNumber: '',
            selectedOptionMotherComp: '',
            sectorSelectedValue: '',
            industrySelectedValue: '',
            citySeletedValue: '',
            reasonDeativeSelectedValue: '1',
            selectedOptionForIsActive: '',
            isArabic: true,
            sector: '',
            industry: '',
            logoFile: null,
            clientDescriptionValue: '',
            city: [],
            isActive: false,
            reasonToDeative: '',
            phoneNumber: '',
            logoFileName: "",
            logoFileObj: null,
            logoFileError: '',
            showEntityDropdown: false,
            entitySelectedValue: '',
            cityArray: [],
            reasonToDeativeArray: [],
            industryArray: [],
            entityArray: [],
            sectorArray: [],
            editAttachmentArray: [],
            entityLogoArray: [],
            phoneNumberErr: '',
            isEdit: false,
            requestId: '',
            clientDescrError: '',
            selectedOptionForIsActiveErr: "",
            selectedOptionMotherCompErr: ""
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
       
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData();
        }
        let tempCityArray = [];
        if (Config.getCityData()) {
            tempCityArray = Config.getCityData();
        }
        let tempIndustryArray = [];
        if (Config.getIndustryData()) {
            tempIndustryArray = Config.getIndustryData();
        }
        let tempReasonToDeactivateArray = [];
        if (Config.getReasonToDeactivateData()) {
            tempReasonToDeactivateArray = Config.getReasonToDeactivateData();
        }
        let tempSectorArray = [];
        if (Config.getAdminSectorData()) {
            tempSectorArray = Config.getAdminSectorData();
        }

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                englisName: editObj.englishName,
                arabicName: editObj.arabicName,
                shortName: editObj.shortName,
                employeeNumber: editObj.employeenumber,
                selectedOptionMotherComp: editObj.ismothercompany === 1 ? 'Yes' : 'No',
                entitySelectedValue: editObj.mothercompanyid,
                industrySelectedValue: editObj.industryid,
                citySeletedValue: editObj.cityid,
                clientDescriptionValue: editObj.description,
                selectedOptionForIsActive: editObj.statusid === 0 ? 'No' : 'Yes',
                reasonDeativeSelectedValue: editObj.statusreasonid,
                sectorSelectedValue: editObj.sectorid,
                phoneNumber: editObj.phonenumber,
                editAttachmentArray: editObj.attachments,
            }, () => {
                let tempEntityLogoArray = [];
                let tempAttactmentArray = [...this.state.editAttachmentArray];
                if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                    tempAttactmentArray.forEach((item, index) => {
                        if (item.filetypeid === 3) {
                            let temp = {}
                            temp.url = item.aliasfilename;
                            temp.fileName = item.filename;
                            temp.requestId = this.state.requestId;

                            tempEntityLogoArray.push(temp);
                            this.setState({
                                // logoFileName: item.filename,
                            })
                        }
                    })

                    this.setState({
                        entityLogoArray: tempEntityLogoArray,
                    })
                }
                Config.setIsEditFlag(false);
            })
        }

        this.setState({
            entityArray: tempEntityArray,
            cityArray: tempCityArray,
            industryArray: tempIndustryArray,
            sectorArray: tempSectorArray,
            reasonToDeativeArray: tempReasonToDeactivateArray
        }, () => {
           
        });
    }

    handleOptionChangeMotherComp = (e) => {
        this.setState({
            selectedOptionMotherComp: e.target.value,
            selectedOptionMotherCompErr: ""
        }, () => {
            if (this.state.selectedOptionMotherComp === 'Yes') {
                // this.setState({ showEntityDropdown: true });
            }
            else {
                this.setState({ entitySelectedValue: "" })
            }
        });
    };

    handleOptionChangeIsActive = (e) => {
        this.setState({
            selectedOptionForIsActive: e.target.value,
            selectedOptionForIsActiveErr: ''
        });
    };

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    submitHandler = event => {
        debugger;

        event.preventDefault();
        event.target.className += " was-validated";
       
        // if (this.state.isEdit) {
        //     if (this.state.selectedOptionMotherComp === 'Yes') {
        //         this.sendEntityData();
        //     } else {
        //         this.setState({
        //             entitySelectedValue: '0'
        //         }, () => {
        //             this.sendEntityData();
        //         })
        //     }
        // } else {
        // if (this.state.logoFileObj == null) {
            this.setState({ logoFileError: "" })
        // if (this.state.entityLogoArray && this.state.entityLogoArray.length > 0) {
        //     this.setState({ logoFileError: "" })
        // } else {
        //     if (!this.state.logoFileObj)
        //         this.setState({ logoFileError: "thisFieldIsRequired" })
        // }

        let tempPhoneNumberErr = "";
       
        if (this.state.phoneNumber) {
            if (!Validator.validateContactNumber(this.state.phoneNumber)) {
                tempPhoneNumberErr = 'invalidContactNumber';
            }
        } else {
            tempPhoneNumberErr = 'thisFieldIsRequired';
        }

        let tempClientDescrErr = "";
        if ( this.state.clientDescriptionValue.trim() === '') {

                tempClientDescrErr = 'thisFieldIsRequired';
            
        }


        let tempSelectedOptionForIsActiveErr = "";
        if (!this.state.selectedOptionForIsActive) {
            tempSelectedOptionForIsActiveErr = "thisFieldIsRequired";
        }

        let tempSelectedOptionMotherCompErr = "";
        if (!this.state.selectedOptionMotherComp) {
            tempSelectedOptionMotherCompErr = "thisFieldIsRequired";
        }
       
        this.setState({
            phoneNumberErr: tempPhoneNumberErr,
            clientDescrError: tempClientDescrErr,
            selectedOptionForIsActiveErr: tempSelectedOptionForIsActiveErr,
            selectedOptionMotherCompErr: tempSelectedOptionMotherCompErr
        }, () => {
            debugger;
            if (this.state.englisName !== '' && this.state.arabicName !== '' && this.state.shortName !== '' && this.state.selectedOptionForIsActive !== '' && this.state.selectedOptionMotherComp !== '' && this.state.sectorSelectedValue !== '' && this.state.industrySelectedValue !== '' &&  this.state.citySeletedValue !== '' && this.state.reasonDeativeSelectedValue !== '' && this.state.phoneNumber !== '' && striptags(this.state.clientDescriptionValue.toString()) !== ''  ) {
                debugger;
                if (this.state.selectedOptionMotherComp === 'Yes') {
                    this.sendEntityData();
                } else {
                    this.setState({
                        entitySelectedValue: '0'
                    }, () => {
                        this.sendEntityData();
                    })
                }
            }
        });
        //}
    };

    sendEntityData = () => {
        let objForCreateEntity = {};
        objForCreateEntity.englisName = this.state.englisName;
        objForCreateEntity.arabicName = this.state.arabicName;
        objForCreateEntity.shortName = this.state.shortName;
        objForCreateEntity.employeeNumber = this.state.employeeNumber;
        objForCreateEntity.selectedOptionForIsActive = this.state.selectedOptionForIsActive;
        objForCreateEntity.selectedOptionMotherComp = this.state.selectedOptionMotherComp;
        objForCreateEntity.sectorSelectedValue = this.state.sectorSelectedValue;
        objForCreateEntity.industrySelectedValue = this.state.industrySelectedValue;
        objForCreateEntity.logoFileName = this.state.logoFileName;
        objForCreateEntity.clientDescriptionValue = this.state.clientDescriptionValue;
        objForCreateEntity.entitySelectedValue = this.state.entitySelectedValue;
        objForCreateEntity.logoFileObj = this.state.logoFileObj;
        objForCreateEntity.citySeletedValue = this.state.citySeletedValue;
        objForCreateEntity.reasonDeativeSelectedValue = this.state.reasonDeativeSelectedValue;
        objForCreateEntity.phoneNumber = this.state.phoneNumber;
        objForCreateEntity.isEdit = this.state.isEdit;
        objForCreateEntity.requestId = this.state.requestId;

        this.props.onSubmitData(objForCreateEntity);
    }

    // handleChangeForClientDesc = event => {
    //     let regex = /^[\u0621-\u064AA-Za-z0-9 \n]{0,250}$/;
    //     if (regex.test(event.target.value)) {
    //         this.setState({ clientDescriptionValue: event.target.value }, () => {
    //         });
    //     }
    // };

    handleChangeForClientDesc = (evt) => {
        let regex = /^[\u0621-\u064AA-Za-z0-9 \n]{0,250}$/;
        if (regex.test(evt.target.value)) {
            this.setState({
                clientDescrError: "",
                clientDescriptionValue: evt.target.value
            });
        } else {
            this.setState({
                clientDescrError: "thisFieldIsRequired",
                clientDescriptionValue: ""
            })
        }
    }

    handleEnglishName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ englisName: event.target.value }, () => {
                });
            }
        } else {
            this.setState({ englisName: "" });
        }
    }

    handleArabicName = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ arabicName: event.target.value });
            }
        } else {
            this.setState({ arabicName: "" });
        }
    }

    handleShortNameChange = (event) => {
        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
        if (event.target.value.trim() !== '') {
            if (regex.test(event.target.value)) {
                this.setState({ shortName: event.target.value });
            }
        } else {
            this.setState({ shortName: "" });
        }
    };

    handleEmployeeNumberChange = (event) => {
        if (event.target.value.trim() !== '') {
            let regex = /^[0-9]*$/;
            if (regex.test(event.target.value)) {
                this.setState({ employeeNumber: event.target.value });
            }
        }
        else {
            this.setState({ employeeNumber: "" });
        }
    };

    handlePhoneNumberChange = (event) => {
        let phoneNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ phoneNumber: event.target.value }, () => {
                if (phoneNumber.length === 11 || phoneNumber.length === 12) {
                    this.setState({ phoneNumberErr: '' });
                } else {
                    this.setState({ phoneNumberErr: "invalidPhoneNumber" });
                }
            });
        } else {
            this.setState({ phoneNumber: '', phoneNumberErr: "thisFieldIsRequired" });
        }
    };

    handleChangeForSector = (event) => {
        this.setState({ sectorSelectedValue: event.target.value });
    };

    handleChangeForEntity = (event) => {
        this.setState({ entitySelectedValue: event.target.value });
    };

    handleChangeForIndustry = (event) => {
        this.setState({ industrySelectedValue: event.target.value });
    };

    handleChangeForCity = (event) => {
        this.setState({ citySeletedValue: event.target.value });
    };

    handleChangeForReasonDeative = (event) => {
        this.setState({ reasonDeativeSelectedValue: event.target.value });
    };

    logoFileChange = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;

            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['JPEG', 'PNG', 'JPG', "BMP"];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                this.setState({ logoFileName: '', logoFileError: "invalidFileType" });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ logoFileName: e.target.files[0].name, logoFileObj: e.target.files[0], logoFileError: '' });
                    let tempFile = e.target.files[0];
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        this.setState({
                            logoFileObj: reader.result,
                        }, () => {
                            Config.setBase64OfEntityLogo(this.state.logoFileObj);
                        });
                    }
                    reader.readAsDataURL(tempFile);
                }
                else {
                    this.setState({ logoFileName: '', logoFileError: 'invalidFileSize' })
                }
            }
        }
        else {
            this.setState({ logoFileName: "", logoFileError: "thisFieldIsRequired" })
        }
    };

    render() {

        return (

            <form
                className="needs-validation formWeight formSpace"
                onSubmit={this.submitHandler}
                noValidate
            >
                <MDBRow className="formRow">
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">

                                {i18n[this.getLocale()].englishName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>

                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="englishName"
                                name="englishName"
                                value={this.state.englisName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEnglishName}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>

                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].arabicName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="arabicName"
                                name="arabicName"
                                value={this.state.arabicName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleArabicName}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].shortName}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="shortName"
                                name="shortName"
                                value={this.state.shortName}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleShortNameChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].secondement_For_Federal_Agency_Step.Employee_Number}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <input
                                disabled={true}
                                type="text"
                                minLength={0}
                                className="form-control"
                                id="employeeNumber"
                                name="employeeNumber"
                                value={this.state.employeeNumber}
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                onChange={this.handleEmployeeNumberChange}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].isMotherComp}<span className="requireStar">*</span></label>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOptionMotherComp === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidationMotherComp4"
                                    onChange={this.handleOptionChangeMotherComp}
                                    name="radio-stacked-2_1"
                                    required />
                                <label class="custom-control-label" for="customControlValidationMotherComp4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidationMotherComp5"
                                    name="radio-stacked-2_1"
                                    checked={this.state.selectedOptionMotherComp === 'No'}
                                    onChange={this.handleOptionChangeMotherComp}
                                    required />

                                <label class="custom-control-label" for="customControlValidationMotherComp5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>
                            </div>

                            <br />

                            {this.state.selectedOptionMotherCompErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                    {
                        this.state.selectedOptionMotherComp === 'Yes' ? <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                    {i18n[this.getLocale()].entity}
                                    <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                                </label>
                                <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange=
                                    {this.handleChangeForEntity}
                                    value={this.state.entitySelectedValue}
                                    required >
                                    <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                    {
                                        this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                            return <option value={item.id}>{this.props.isArabic ? item.arabicName : item.englishName}</option>
                                        }) : null
                                    }
                                </select>
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                            </div>
                        </MDBCol> :
                            null
                    }
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].industry}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange=
                                {this.handleChangeForIndustry}
                                value={this.state.industrySelectedValue}
                                required >

                                <option value="">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}
                                </option>
                                {
                                    this.state.industryArray ?
                                        this.state.industryArray.map((item, index) => {
                                            return <option key={index} value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                        })
                                        :
                                        null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].city}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForCity}
                                value={this.state.citySeletedValue}
                                required >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.cityArray ? this.state.cityArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].sector}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange=
                                {this.handleChangeForSector}
                                value={this.state.sectorSelectedValue}
                                required >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.sectorArray ? this.state.sectorArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].isActive}<span className="requireStar">*</span></label>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOptionForIsActive === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidation4"
                                    onChange={this.handleOptionChangeIsActive}
                                    name="radio-stacked-2"
                                    required />
                                <label class="custom-control-label" for="customControlValidation4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.selectedOptionForIsActive === 'No'}
                                    onChange={this.handleOptionChangeIsActive}
                                    required />

                                <label class="custom-control-label" for="customControlValidation5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>

                            </div>
                            <br />
                            {this.state.selectedOptionForIsActiveErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                                {i18n[this.getLocale()].reasonToDeative}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <select style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding"
                                onChange={this.handleChangeForReasonDeative}
                                value={this.state.reasonDeativeSelectedValue}
                                required >

                                <option value="">
                                    {i18n[this.getLocale()].selectDropdownDefaultValue}
                                </option>
                                {
                                    this.state.reasonToDeativeArray ? this.state.reasonToDeativeArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    }) : null
                                }
                            </select>
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol> */}
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].phnNumber}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: 'red' }}>*</span>
                            </label>
                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={this.state.phoneNumber}
                                onChange={this.handlePhoneNumberChange}
                                className="form-control"
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            {
                                this.state.phoneNumberErr === "thisFieldIsRequired" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.phoneNumberErr === "invalidContactNumber" ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidPhoneNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}>
                            {i18n[this.getLocale()].logo}
                            {
                                this.state.isEdit ?
                                    null :
                                    <span className="requireStar"
                                        style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                                        *
                                                </span>
                            }
                        </label>
                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="logoFileId"
                                    type="file"
                                    name="logoFileName"
                                    onChange={(event) => this.logoFileChange(event)}
                                    required
                                    accept="image/*"
                                />
                                <label htmlFor="logoFileId" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].chooseFile}
                                </label>
                                <label htmlFor="logoFileId" className="fileName" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}><span>{this.state.logoFileName}</span></label>
                            </div>
                            {
                                this.state.logoFileError === 'thisFieldIsRequired' ?
                                    <div style={{ color: '#dc3545', fontSize: '80%', textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.logoFileError === 'invalidFileType' ?
                                    <div style={{ color: '#dc3545', fontSize: '80%', textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.logoFileError === 'invalidFileSize' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsImageOnly}
                            </p>
                        </div>
                        {
                            this.state.isEdit ?
                                // <DownloadComponent filesObj={[{ url: this.state.employeeEmaritesIdUrl, fileName: this.state.employeeEmaritesId, requestId: this.state.requestId }]} />
                                <DownloadComponent filesObj={this.state.entityLogoArray} />
                                :
                                ''
                        }
                    </MDBCol>
                </MDBRow>

                {/* <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].isActive}<span className="requireStar">*</span></label>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.selectedOptionForIsActive === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidation4"
                                    onChange={this.handleOptionChangeIsActive}
                                    name="radio-stacked-2"
                                    required />
                                <label class="custom-control-label" for="customControlValidation4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.selectedOptionForIsActive === 'No'}
                                    onChange={this.handleOptionChangeIsActive}
                                    required />

                                <label class="custom-control-label" for="customControlValidation5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>
                            </div>
                            <br />
                            {this.state.selectedOptionForIsActiveErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>
                </MDBRow> */}

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label htmlFor="formGroupExampleInput" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>
                                {i18n[this.getLocale()].clientDescr}
                                <span className="required-mark" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', color: 'red' }}>*</span>
                            </label>
                            <div style={{ border: this.state.clientDescrError === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                                {/* <CKEditorComponent
                                    isArabic={this.props.isArabic}
                                    content={this.state.clientDescriptionValue}
                                    onChange={this.handleChangeForClientDesc}
                                    // blur={this.onBlurClientDescrEditor}
                                    // afterPaste={this.afterPasteClientDescrEditor}
                                    ckEditorHeight={"10vh"}
                                /> */}
                                <textarea
                                    className="form-control"
                                    id="reasonToModifySalary"
                                    name="reasonToModifySalary"
                                    rows="4"
                                    maxLength="250"
                                    onChange={this.handleChangeForClientDesc}
                                    value={this.state.clientDescriptionValue}
                                    style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                    required
                                />
                            </div>
                            {
                                this.state.clientDescrError === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                :
                                null
                            }
                        </div>
                       
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn className="submitBtn" type='submit' color="primary" style={{ borderRadius: 5 }} >{i18n[this.getLocale()].submit}</MDBBtn>
                </MDBRow>
            </form>
        );
    }
}