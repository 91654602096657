import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import { components } from 'react-select';
import { Multiselect } from 'multiselect-react-dropdown';
import { confirmAlert } from 'react-confirm-alert';
import { saveCircularRequestPayload, uploadDocument } from '../../utils/Payload';
const striptags = require('striptags');

export default class CircularizationForm extends Component 

{
    constructor (props)
    {
        super(props);
        this.state=
        {
            MultiselectErr:'',
            entityData: [],
            MultipleSelectValue:[],
           
            MessageSubjecterror:'',
            reqDetailsErr:'',
            reqDetails:'',
            MessageSubject:'',
            officalLetterErr: '',
            actualOfficialLetter: '',
            officialLetter:'',

            Files:[]
        }

    }
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }
 componentDidMount = () => {
    let tempEntityData = [];
    if (Config.getEntityData()) {
        tempEntityData = Config.getEntityData();
    }

    if (tempEntityData && tempEntityData.length > 0) {
        let entityId = tempEntityData[0].ID;
        this.setState({
            assignedEntityId: entityId,
            entityData: tempEntityData
        });
    }

 }

 handleMultipleselect = (event) =>
    {
        this.setState({MultipleSelectValue:event});
    }


    onReqDetailsChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                reqDetailsErr: "",
                reqDetails: newContent
            });
        }else{
            this.setState({
                reqDetailsErr: "ckeditorCountErr"
            });
        }
    }


 submitHandler = event => {
 debugger;
    event.preventDefault();
    event.target.className += " was-validated";
     let MessageSubjecterror='',entitynamerror='',reqDetailsErr='',officalLetterErr='';
     
     if(!this.state.MessageSubject||this.state.MessageSubject=='')
     {
        MessageSubjecterror ="thisFieldIsRequired";
     }
     if(!this.state.MultipleSelectValue || this.state.MultipleSelectValue.length==0)
{
    entitynamerror="thisFieldIsRequired";
}

if (this.state.reqDetails && this.state.reqDetails.trim()) {
    let val = striptags(this.state.reqDetails.replace(/&nbsp;/g, "").trim());
    if (!val.trim()) {
        reqDetailsErr = 'thisFieldisRequired';
    }
} else {
    reqDetailsErr = 'thisFieldisRequired';
}



this.setState({
    MultiselectErr:entitynamerror,
    officalLetterErr:officalLetterErr,
    MessageSubjecterror,
    reqDetailsErr:reqDetailsErr
    
},()=>{})

   

let tempvalues=[];
if(this.state.MultipleSelectValue.length>0)
{
    for(let i=0;i<this.state.MultipleSelectValue.length;i++)
    {
        tempvalues.push(this.state.MultipleSelectValue[i].id);

    }
}

if (
    !MessageSubjecterror &&
    !entitynamerror &&
    !officalLetterErr &&
    !reqDetailsErr 
)
{
    let dataObject = {}
    dataObject.Subject=this.state.MessageSubject;
    dataObject.Description = this.state.reqDetails;
    
    dataObject.Entites = tempvalues;
    dataObject.Files=this.state.Files;
    // dataObject.filecontent=this.state.actualOfficialLetter;
    let payload = saveCircularRequestPayload(dataObject);
    
    debugger;
    let endPoint = 'saveCirculars'
    let url = Config.getUrl(endPoint);
    this.setState({
        isLoading: true
    }, () => {
        ApiRequest.sendRequestForPost(url, payload, res => {
            if (res.Status) {
              

                this.setState({
                    isLoading: false,
                    titleAlert: i18n[this.getLocale()].success,
                    messageAlert: i18n[this.getLocale()].Circularization.Circularsendsucess
                }, () => {
                    this.alertSubmitForSuccess();
                })
            }
        }
        )

 }
);
}
 }



 officialLetterOnChange = e => {
     let tempfiles = [];    debugger;
    if (e.target.files.length > 0) {
        for(let i=0; i<e.target.files.length;i++)
        {
            let element=e.target.files[i];
        
        
            let inpFileName = element.name;
            let fileSize = element.size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    officialLetter: '',
                    officalLetterErr: 'invalidFileType'
                });
                return;
            }
            else {
                debugger;
                if (fileSize > 0 && fileSize < Config.getWorkshopMaxFileSize()) {
                    let tempobj = {};
                    let fileObj = element;
                    tempobj.filename=element.name;
                    let reader = new FileReader();
                    let file = fileObj;
                    reader.onloadend = () => {
                        tempobj.filecontent=reader.result;
                    };
                    reader.readAsDataURL(file);
                    tempfiles.push(tempobj);
                }
                else {
                    e.target.value = '';
                    this.setState({
                        officialLetter: '',
                        officalLetterErr: 'invalidFileSize'
                    });
                    return;
                }  
            }
            

        }
      
this.setState({
    Files:tempfiles
});
        // else {
        //     if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
        //         let fileObj = e.target.files[0];
        //         this.setState({
        //             officialLetter: e.target.files[0].name,
        //             officalLetterErr: ''
        //         }, () => {
        //             let reader = new FileReader();
        //             let file = fileObj;
        //             reader.onloadend = () => {
        //                 this.setState({
        //                     actualOfficialLetter: reader.result,
        //                 }, () => {
        //                     Config.setBase64ForSciOfficalLetter(this.state.actualOfficialLetter);
        //                 });
        //             };
        //             reader.readAsDataURL(file);
        //         });

        //     } else {
        //         e.target.value = '';
        //         this.setState({
        //             officialLetter: '',
        //             officalLetterErr: 'invalidFileSize'
        //         });
        //     }
        // }

    } else {
        e.target.value = '';
        this.setState({ officialLetter: '' });
    }

};
alertSubmitForSuccess = () => {
    debugger;
    //return (<RequestSurvey isArabic={this.state.isArabic} y={this.props.y} />);
     confirmAlert({
         title: this.state.titleAlert,
         message: this.state.messageAlert,
         buttons: [
             {
                 label: i18n[this.getLocale()].ok,
                 onClick: () =>   this.props.history.replace('/Circularization', {
                    pathname: '/Circularization'
                })
             }
         ],
         closeOnEscape: false,
         closeOnClickOutside: false,
         childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
     });
     setTimeout(() => {
         document.getElementById('alertOkButton').focus()
     }, 100);
};
 render() {

    return (

        <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate>
  
            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].Circularization.MessageSubject}
                            <span className="requireStar">*</span>
                        </label>
                        <input style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                            type="text"
                            className="form-control"
                            id="req_subject"
                            maxLength={100}
                            name="req_subject"
                            value={this.state.MessageSubject}
                            onChange={(event) => {
                                if (event.target.value && event.target.value.trim()) {
                                    let regex = /^[\u0621-\u064AA-Za-z-0-9 ]*$/;
                                    if (regex.test(event.target.value)) {
                                        this.setState({ MessageSubject: event.target.value });
                                    }
                                }
                                else {
                                    this.setState({ requestSubject: '' });
                                }
                            }}
                            required
                        />

                        <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                    </div>
                </MDBCol>

         
            </MDBRow>
         


            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>

               <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].entityName}
                            <span className="requireStar">*</span>
                        </label>
                        <Multiselect
options={this.state.entityData}
selectedValues={this.state.selectedValue} 
onSelect={this.onSelect}
onRemove={this.onRemove}
displayValue={this.props.isArabic ? "arabicName" : "englishName"}
onSelect={this.handleMultipleselect} 
/>
{this.state.MultiselectErr == 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                            {i18n[this.getLocale()].thisFieldIsRequired}
                        </div>
                    </div>
                </MDBCol>

        
            </MDBRow>

           
            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
                <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                    <div className="form-group">
                        <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                            {i18n[this.getLocale()].SCI.requestDetails}
                            <span className="requireStar">*</span>
                        </label>

                        <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                            {
                                this.props.isArabic ?
                                    null
                                    :
                                    <CKEditor
                                        activeClass="p10"
                                        content={this.state.reqDetails}
                                        events={{
                                            "change": this.onReqDetailsChange
                                        }}
                                        config={{ language: "en", height: '10vh' }}
                                    />
                            }

                            {
                                this.props.isArabic ?
                                    <CKEditor
                                        activeClass="p10"
                                        content={this.state.reqDetails}
                                        events={{
                                            "change": this.onReqDetailsChange
                                        }}
                                        config={{ language: "ar", height: '10vh' }}
                                    />
                                    :
                                    null
                            }
                        </div>
                        <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                            {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                        </p>
                        {
                            this.state.reqDetailsErr === 'thisFieldisRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                                :
                                null
                        }
                        {
                            this.state.reqDetailsErr === 'ckeditorCountErr' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.getLocale()].ckeditorCountErr}
                                </div>
                                :
                                null
                        }
                        

                    </div>
                </MDBCol>
            </MDBRow>



            <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
          
         <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
              <div className="form-group">
                  <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="Attachedfilesforsupportrequest">{i18n[this.getLocale()].SCI.attachedFiles}</label>

                  <div className="input-group">
                      <div className="custom-file-inp">
                          <input
                              id="file"
                              multiple
                              type="file"
                              name="officialLetter"
                              onChange={(event) => this.officialLetterOnChange(event)}
                          />
                          <label htmlFor="file" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].SCI.chooseFile}</label>
                          <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="file" className="fileName">
                       
                              {this.state.Files.length>0
                              ?this.state.Files.map((item, index) => {
                                return (
                                    item.filename
                                )
                              }
                              )
                              :''
                              }
                              {/* <span>{this.state.officialLetter}</span> */}
                          </label>
                      </div>
                      {
                          this.state.officalLetterErr === 'invalidFileType' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                              </div>
                              :
                              null
                      }
                      {
                          this.state.officalLetterErr === 'invalidFileSize' ?
                              <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                  {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSizeWorkshop}
                              </div>
                              :
                              null
                      }

                  </div>
                  <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                      {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAllWorkshop}
                  </p>
                  {
                      (this.state.sciAttachmentArray &&
                          this.state.sciAttachmentArray.length > 0)
                          ? <DownloadComponent filesObj={this.state.sciAttachmentArray} />
                          : <span></span>

                  }
              </div>
          </MDBCol>

      </MDBRow>

            <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                {/* {
                    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        null
                        :
                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                            {i18n[this.getLocale()].preview}
                        </MDBBtn>
                } */}
                {/* {
                    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                        null
                        :
                        <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                            {i18n[this.getLocale()].saveAsDraft}
                        </MDBBtn>
                } */}
                <MDBBtn className="submitBtn" type='submit' color="primary">
                    {i18n[this.getLocale()].submit}
                </MDBBtn>
            </MDBRow>
        </form>
    );
}
}

