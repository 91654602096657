import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import moment from 'moment';
import StepsComponent from "../StepsComponent";
import i18n from './../../i18n';
import './../../css/style.css';
import Config from './../../utils/Config';
import DownloadComponent from "./../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";
import './../../css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab ,Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';
import { confirmAlert } from 'react-confirm-alert';

const striptags = require('striptags');



export default class Step2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            EmployeesArray:this.props.sendData.EmployeesArray,
                        
            RequestType:this.props.sendData.RequestType,
            

            RequestTypeErr:'',
            EmployeeNameErr:'',
            EmployeeJobTitleErr:'',
            EmpolyeeJobDegreeErr:'',
            EmployeePhoneNumberErr:'',
            EmployeeReasonErr:'',
            EmployeeViloationErr:'',
            EmployeeinvestigationErr:'',
            isEdit:this.props.isEdit,
            requestId:this.props.sendData.requestId,
            currentIndex:0
           
        }
    }
    addemployee = () => { 
        let loginData = Config.getLocalStorageLoginData();
        if(this.state.EmployeeNameErr!='1' && this.state.EmployeeJobTitleErr!='1' && this.state.EmpolyeeJobDegreeErr!='1' &&
        this.state.EmployeePhoneNumberErr!='1' &&
        this.state.EmployeeReasonErr!='1'&&
        this.state.EmployeeViloationErr!='1' &&
        this.state.EmployeeinvestigationErr!='1' && this.state.isinverstagiondoneErr !='1')
        {
        let obj = {};
        obj.EmployeeName='';
        obj.EmployeeJobTitle='';
        obj.EmployeeJobDegree='';
        obj.EmployeePhoneNumber='';
        obj.Reason='';
        obj.Violations='';
        obj.investigation='';
        obj.userid=loginData.EntityID;
        obj.entityid=loginData.id;
        let currentIndex=this.state.currentIndex;
        if (this.state.EmployeesArray.length < 40) {
            this.setState({
                EmployeesArray: this.state.EmployeesArray.concat(obj),
                view: this.state.view + 1,
                EmployeeNameErr:'1' , EmployeeJobTitleErr:'1' , EmpolyeeJobDegreeErr:'1' ,
        EmployeePhoneNumberErr:'1' ,
        EmployeeReasonErr:'1',
        EmployeeViloationErr:'1' ,
        EmployeeinvestigationErr:'1',
        currentIndex:currentIndex+1
            });
        }
    }
    else

    {
        let errormessage = '';
        if(this.state.EmployeeNameErr=='1')
        {
            errormessage  = errormessage+i18n[this.getLocale()].Disciplinaryboard.EmployeeNameErr;
        }
        if(this.state.isinverstagiondoneErr=='1')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.isinverstagiondoneErr;
        }
         if (this.state.EmployeeJobTitleErr=='1')
        {
            errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.EmployeeJobTitleErr;
        }
        
         if (this.state.EmpolyeeJobDegreeErr=='1')
        {
            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmpolyeeJobDegreeErr;
        }
             if (this.state.EmployeePhoneNumberErr=='1')
        {
            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeePhoneNumberErr;

        }
        if (this.state.EmployeeReasonErr=='1')
        {
            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeeReasonErr;

        }
             if (this.state.EmployeeViloationErr=='1')
        {
            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeeViloationErr;

        
        }     if (this.state.EmployeeinvestigationErr=='1')
        {

            errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeeinvestigationErr;
        
        }

        

        this.setState({
            titleAlert: i18n[this.getLocale()].error,
            messageAlert: errormessage
        }, () => {
            this.alertSubmit();
        })
    }
    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    delteemployee = (indexVal) => {
        var array = this.state.EmployeesArray;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        EmployeesArray: array
                    });

                    //ReCalculate total no of days & total salary to be payed
                 
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            EmployeesArray: array
                        });
                     
                    }
                }
            }
        }

    }
    saveAsDraft = () => {
       
     
        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step2",
            RequestType: this.state.RequestType,
            EmployeesArray:this.state.EmployeesArray
           
        });
    };
    EmployeeViloationOnChange = (e,index) => {
        debugger;
index = this.state.currentIndex;

        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let tempobj = this.state.EmployeesArray;
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                tempobj[index].EmployeeViloation = '';
                tempobj[index].EmployeeViloationErr = 'invalidFileType';
    
            
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ 
                        // EmployeeViloation:inpFileName,
                         EmployeeViloationErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            tempobj[index].EmployeeViloation=inpFileName;
                            tempobj[index].EmployeeViloationFileObj = reader.result;
                            tempobj[index].EmployeeViloationErr = '';
    
                            this.setState({
                                EmployeeViloationFileObj: reader.result
                            }, () => {
                                // Config.setBase64ForDisciplinaryboardEmployeeViloation(this.state.EmployeeViloationFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    tempobj[index].EmployeeViloationFile = '';
                        tempobj[index].EmployeeViloationFileErr = 'invalidFileSize';
                    this.setState({ EmployeeViloationFile: '', EmployeeViloationFileErr: 'invalidFileSize' });
                }
            }
    
            this.setState({
                EmployeesArray: tempobj,
                EmployeeViloationErr: tempobj[index].EmployeeViloationFileErr
            });
        }
        //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };

    EmployeeinvestigationOnChange = (e,index) => {
        index = this.state.currentIndex;
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let tempobj = this.state.EmployeesArray;
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                tempobj[index].Employeeinvestigation = '';
                tempobj[index].EmployeeinvestigationErr = 'invalidFileType';
    
            
            } else {
                let fileTargetVarible = e.target.files[0];
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ 
                        // EmployeeViloation:inpFileName,
                         EmployeeinvestigationErr: '' }, () => {
                        let tempFile = fileTargetVarible;
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            tempobj[index].Employeeinvestigation=inpFileName;
                            tempobj[index].EmployeeinvestigationFileObj = reader.result;
                            tempobj[index].EmployeeinvestigationErr = '';
    
                            this.setState({
                                EmployeeinvestigationFileObj: reader.result
                            }, () => {
                                // Config.setBase64ForDisciplinaryboardEmployeeinvestigation(this.state.EmployeeinvestigationFileObj);
                            })
                        };
                        reader.readAsDataURL(tempFile);
                    });
                }
                else {
                    e.target.value = '';
                    tempobj[index].EmployeeinvestigationFile = '';
                        tempobj[index].EmployeeinvestigationFileErr = 'invalidFileSize';
                    this.setState({ EmployeeinvestigationFile: '', EmployeeinvestigationFileErr: 'invalidFileSize' });
                }
            }
    
            this.setState({
                EmployeesArray: tempobj,
                EmployeeinvestigationErr: tempobj[index].EmployeeinvestigationFileErr
            });
        }
        //else {
        //    e.target.value = '';
        //    this.setState({ EmployeeCV: '', EmployeeCVErr: 'thisFieldIsRequired' });
        //}
    };
    handleEmployeeName = (event,index) => {
        // event.currentTarget.className += " was-validated";
        event.target.parentNode.className += " was-validated";
        if (event.target.value.trim() != '')
        
         {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                let tempobj = this.state.EmployeesArray;
        
        tempobj[index].employeeName=event.target.value;
        tempobj[index].EmployeeNameErr='';
        
            this.setState({
                EmployeesArray:tempobj,
                EmployeeNameErr:'',
            });
            }
        } else {
            let tempobj = this.state.EmployeesArray;
        
            tempobj[index].employeeName=event.target.value;
            tempobj[index].EmployeeNameErr='thisFieldIsRequired';
             this.setState({ EmployeeNameErr: '1',EmployeesArray:tempobj, });
        }
    };
    handleEmployeeInfoJobDegree = (event,index) => {
        event.target.parentNode.className += " was-validated";
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;
            if (regex.test(event.target.value)) {
                let tempobj = this.state.EmployeesArray;
        
        tempobj[index].EmployeeJobDegree=event.target.value;
        tempobj[index].EmpolyeeJobDegreeErr='';
        
            this.setState({
                EmployeesArray:tempobj,
                EmpolyeeJobDegreeErr:'',
            });
            }
            
        } else {
            let tempobj = this.state.EmployeesArray;
        
            tempobj[index].EmployeeJobDegree=event.target.value;
            tempobj[index].EmpolyeeJobDegreeErr='thisFieldIsRequired';
             this.setState({ EmpolyeeJobDegreeErr: '1',EmployeesArray:tempobj });
        }
    };
    
    
    handleEmployeeInfoJobTitle = (event,index) => {
        event.target.parentNode.className += " was-validated";
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                let tempobj = this.state.EmployeesArray;
        
                tempobj[index].EmployeeJobTitle=event.target.value;
                tempobj[index].EmployeeJobTitleErr='';
                
                    this.setState({
                        EmployeesArray:tempobj,
                        EmployeeJobTitleErr:''
                    });
            }
        } else {
            let tempobj = this.state.EmployeesArray;
        
            tempobj[index].EmployeeJobTitle=event.target.value;
            tempobj[index].EmployeeJobTitleErr='thisFieldIsRequired';
             this.setState({ EmployeeJobTitleErr: '1',EmployeesArray:tempobj });
        }
    };
    
 
    
    componentDidMount = () => {

        let temp = [];
        let obj = {};
        let loginData = Config.getLocalStorageLoginData();
        obj.userid=loginData.EntityID;
        obj.entityid=loginData.id;
        temp.push(obj);
        this.setState({EmployeesArray:temp,EmployeeNameErr:'1',
        EmployeeJobTitleErr:'1',
        EmpolyeeJobDegreeErr:'1',
        EmployeePhoneNumberErr:'1',
        EmployeeViloationErr:'1',
        EmployeeinvestigationErr:'1',EmployeeReasonErr:'1'});
        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();
        
debugger;
let tempobj={};
let tempArray=[];
if(!editObj.EmployeesArray)
{
  
    tempArray.push(tempobj);
  
}
else
{

    for(let i=0;i<editObj.EmployeesArray.length;i++)
    {
        let tempitem = {};
        let item = editObj.EmployeesArray[i];
        tempitem.EmployeePhoneNumber=item.EmployeePhoneNumber;
        tempitem.EmployeeJobTitle=item.EmployeeJobTitle;
        tempitem.EmployeeJobDegree=item.EmployeeJobDegree;
        tempitem.EmployeeName=item.EmployeeName;
        tempitem.employeeName=item.EmployeeName;
        tempitem.EmployeeReason=item.Reason;
       
        tempitem.EmployeeViloationArray=item.ViolationsFiles;
        // tempitem.EmployeeViloationFileObj=item.EmployeeViloationFileObj;
        tempitem.EmployeeinvestigationArray=item.InvestegationFiles;
        // tempitem.EmployeeinvestigationFileObj=item.EmployeeinvestigationFileObj;
        if(item.userid>0)
        {
        tempitem.userid=item.userid;
        }
        else
        {
            tempitem.userid  =obj.userid
        }
        if(item.entityid>0)
    {
        tempitem.entityid=item.entityid;
    }
    else
    {
tempitem.entityid=obj.entityid;
    }
        
        tempitem.isinverstagiondone=item.isinverstagiondone;
        tempArray.push(tempitem);
      
    }
}
        if (editFlag) {
            this.setState({
                EmployeesArray:tempArray,
RequestType:editObj.RequestType,
requestId:editObj.requestId
            });
        }


    }

    onReqDetailsChange =(e,index)=>
    {
        var newContent = e.editor.getData();
        let newContent1 = striptags(newContent);
        let tempobj = this.state.EmployeesArray;
        if (newContent1.length <= Config.getMaxCKEditorLimit()) {

            tempobj[index].EmployeeReason=newContent;
            this.setState({
                EmployeeReasonErr: "",
              
                EmployeesArray:tempobj
            });
        }else{
            tempobj[index].EmployeeReason=newContent;
            tempobj[index].EmployeeReasonErr="ckeditorCountErr";
            this.setState({
                EmployeeReasonErr: "1",
              
                EmployeesArray:tempobj
            });

         
                    }

    }
    handleOptionChange = (e) => {
        this.setState({
            requiredToPayBudget: e.target.value,
            selectedOptionErr: ''
        });
    };

    handlePhoneNumber= (event,index) => {
        event.target.parentNode.className += " was-validated";
        let contactNumber = event.target.value;
        let tempobj = this.state.EmployeesArray;
        if (event.target.value !== '') {
      
            
                if (Validator.validateContactNumber(contactNumber))
                {
                    
        
                    tempobj[index].EmployeePhoneNumber=contactNumber;
                    tempobj[index].PhoneNumberErr='';
                        this.setState({
                            EmployeesArray:tempobj
                            ,EmployeePhoneNumberErr:''
                        });
                } else {
                    tempobj[index].EmployeePhoneNumber=contactNumber;
                 
                    tempobj[index].PhoneNumberErr='invalidContactNumber';
                    this.setState({ EmployeesArray:tempobj,EmployeePhoneNumberErr:'1' }
                    );
                }
           
        } else {

            tempobj[index].EmployeePhoneNumber=contactNumber;
           
            tempobj[index].PhoneNumberErr='thisFieldIsRequired';
            
                this.setState({ EmployeesArray:tempobj ,EmployeePhoneNumberErr:'1' }
                    );
        }
    }

    
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    
    

    submitHandler =(event) =>{
        event.preventDefault();
        event.target.className += " was-validated";
      if(this.state.RequestType==2||this.state.RequestType=='2')
      {
        
        if(this.state.EmployeeNameErr!='1' && this.state.EmployeeJobTitleErr!='1' && this.state.EmpolyeeJobDegreeErr!='1' &&
        this.state.EmployeePhoneNumberErr!='1' &&
        this.state.EmployeeReasonErr!='1'&&
        this.state.EmployeeViloationErr!='1' &&
        this.state.EmployeeinvestigationErr!='1' && this.state.isinverstagiondoneErr !='1')
        {
            this.setState({
                isNextClick: true,
                isfirstPageValidate: true
            }, () => {
                this.props.getData.stepTwoData({
                    isEdit: this.state.isEdit,
                    RequestType:this.state.RequestType,
                    requestId: this.state.requestId,
                    EmployeesArray:this.state.EmployeesArray,
                    saveFrom: "Step2",
                });
            });
        }
        else

        {
            let errormessage = '';
            if(this.state.EmployeeNameErr=='1')
            {
                errormessage  = errormessage+i18n[this.getLocale()].Disciplinaryboard.EmployeeNameErr;
            }
            if(this.state.isinverstagiondoneErr=='1')
            {
                errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.isinverstagiondoneErr;
            }
             if (this.state.EmployeeJobTitleErr=='1')
            {
                errormessage  = errormessage+' \n -  '+i18n[this.getLocale()].Disciplinaryboard.EmployeeJobTitleErr;
            }
            
             if (this.state.EmpolyeeJobDegreeErr=='1')
            {
                errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmpolyeeJobDegreeErr;
            }
                 if (this.state.EmployeePhoneNumberErr=='1')
            {
                errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeePhoneNumberErr;
    
            }
            if (this.state.EmployeeReasonErr=='1')
            {
                errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeeReasonErr;
    
            }
                 if (this.state.EmployeeViloationErr=='1')
            {
                errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeeViloationErr;
    
            
            }     if (this.state.EmployeeinvestigationErr=='1')
            {
    
                errormessage  = errormessage+' \n - '+i18n[this.getLocale()].Disciplinaryboard.EmployeeinvestigationErr;
            
            }
    
            
    
            this.setState({
                titleAlert: i18n[this.getLocale()].error,
                messageAlert: errormessage
            }, () => {
                this.alertSubmit();
            })
        }
    }
    else
    {
        this.setState({
            isNextClick: true,
            isfirstPageValidate: true
        }, () => {
            this.props.getData.stepTwoData({
                isEdit: this.state.isEdit,
                RequestType:this.state.RequestType,
                requestId: this.state.requestId,
                EmployeesArray:this.state.EmployeesArray,
                saveFrom: "Step2",
            });
        });
    }

    }

    
    goToPrevious = () => {
        this.props.getData.stepTwoPrev({
            RequestType:this.state.RequestType,
            requestId: this.state.requestId,
            EmployeesArray:this.state.EmployeesArray,
        });
    };

    handleRequestTypeChange = (e) => {
        this.setState({
            RequestType: e.target.value,
            RequestTypeErr: ''
        });
    };


    handleisinverstagiondoneChange = (e,index) => {


  
   let tempobj = this.state.EmployeesArray;

 tempobj[index].isinverstagiondone=e.target.value;
                
                 let errorcpde ='';
                 if (e.target.value==2 || e.target.value=='2')
                 {
errorcpde= '1';
                 }
                 tempobj[index].isinverstagiondoneErr=errorcpde;
                 
                 
                        this.setState({
                            
                            EmployeesArray:tempobj , isinverstagiondoneErr:errorcpde
                        });
    };

    render() {
      
       let element = this.state.EmployeesArray.map((item, index) =>
       {
        return (
       <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
         
         <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                   <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                               <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                   <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoEmployeeName}<span className="requireStar">*</span></label>
                                   <input
                                       value={item.employeeName}
                                       onChange={(e)=>this.handleEmployeeName(e, index)}
                                       type="text"
                                       id="employeeName"
                                       className="form-control"
                                       name="empJob"
                                       required
                                       //maxlength="50"
                                       style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                   />
                                   
                                     {
                                       item.EmployeeNameErr == 'thisFieldIsRequired' ?
                                   <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                       {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                   </div>
                                    :'' }
                               </div>
                           </MDBCol>
                           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                     <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                   <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoJobTitle}<span className="requireStar">*</span></label>
                                   <input
                                       value={item.EmployeeJobTitle}
                                       onChange={e=>this.handleEmployeeInfoJobTitle(e,index)}
                                       type="text"
                                       id="employeeName"
                                       className="form-control"
                                       name="empJob"
                                       required
                                       
                                       //maxlength="50"
                                       style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                   />
                                     {
                                       item.EmployeeJobTitleErr == 'thisFieldIsRequired' ?
                                   <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                       {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                   </div>
                                    :'' }
                               </div>
                           </MDBCol>
                   
                      
       </MDBRow>
       
       <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                 
                           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                     <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                   <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Common.EmployeeInfoJobDegree}<span className="requireStar">*</span></label>
                                   <input
                                       value={item.EmployeeJobDegree}
                                       onChange={e=>this.handleEmployeeInfoJobDegree(e,index)}
                                       type="text"
                                       id="employeeName"
                                       className="form-control"
                                       name="empJob"
                                       required
                                       
                                       //maxlength="50"
                                       style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                   />
                                     {
                                       item.EmpolyeeJobDegreeErr == 'thisFieldIsRequired' ?
                                   <div className="invalid-feedback textRed" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                       {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                   </div>
                                    :'' }
                               </div>
                           </MDBCol>
       
       
                           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                                     <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                   <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].WorkShop.PhoneNumber}<span className="requireStar">*</span></label>
                                   <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                       value={item.EmployeePhoneNumber}
                                       onChange={e=>this.handlePhoneNumber(e,index)}
                                       type="text"
                                       id="PhoneNumber"
                                       className="form-control"
                                       name="PhoneNumber"
                                       required
                                       //maxlength="50"
                                       style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                   />
       
       {
                                       item.PhoneNumberErr === 'thisFieldisRequired' ?
                                           <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                               {i18n[this.getLocale()].thisFieldIsRequired}
                                           </div>
                                           :
                                           null
                                   }
                                    {
                                       item.PhoneNumberErr === "invalidContactNumber" ?
                                           <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                               {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.invalidPhoneNumber}
                                           </div>
                                           :
                                           null
                                   }
                                   
                               </div>
                           </MDBCol>
                  
                      
       </MDBRow>
       <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
                
                  
                           <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                                     <div className={this.props.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                   <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.reason}<span className="requireStar">*</span></label>
                                   
                                   <div style={{ border: this.state.EmployeeReasonErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                       {
                                           this.props.isArabic ?
                                               null
                                               :
                                               <CKEditor
                                                   activeClass="p10"
                                                   content={item.EmployeeReason}
                                                   events={{
                                                       "change": e=> this.onReqDetailsChange(e,index)
                                                   }}
                                                   config={{ language: "en", height: '10vh' }}
                                               />
                                       }
       
                                       {
                                           this.props.isArabic ?
                                               <CKEditor
                                                   activeClass="p10"
                                                   content={item.EmployeeReason}
                                                   events={{
                                                       "change":e=> this.onReqDetailsChange(e,index)
                                                   }}
                                                   config={{ language: "ar", height: '10vh' }}
                                               />
                                               :
                                               null
                                       }
                                   </div>
                                  
                                     {
                                       item.EmployeeReasonErr == 'thisFieldIsRequired' ?
                                   <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                       {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                   </div>
                                    :'' }
                               </div>
                           </MDBCol>
       
                      
       </MDBRow>
           
       <MDBRow
                    className="formRow"
                    style={{
                        display: "flex",
                      flexDirection:'row',
                        marginTop: '20px'
                    }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.Violations}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeeViloationFile"
                                    type="file"
                                    name="EmployeeViloationform"
                                    onChange={event => this.EmployeeViloationOnChange(event,index)}
                                   
                                />
                                <label
                                    htmlFor="EmployeeViloationFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeeViloationFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{item.EmployeeViloation}</span>
                                </label>
                            </div>
                            {
                                item.EmployeeViloationErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                               item.EmployeeViloationErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                  item.EmployeeViloationErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                          
                            {
                                (item.EmployeeViloationArray &&
                                    item.EmployeeViloationArray.length > 0)
                                    ? <DownloadComponent filesObj={item.EmployeeViloationArray} />
                                    : <span></span>
                            }
                        </div>
                        <br />
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <label
                            style={{
                                width: "100%",
                                display: "flex",
                              flexDirection:'row'
                            }}
                            htmlFor="formGroupExampleInput">
                             {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.investigation}
                            <span className="requireStar">*</span>
                        </label>

                        <div className="input-group">
                            <div className="custom-file-inp">
                                <input
                                    id="EmployeeinvestigationFile"
                                    type="file"
                                    name="Employeeinvestigation"
                                    onChange={event => this.EmployeeinvestigationOnChange(event,index)}
                                />
                                <label
                                    htmlFor="EmployeeinvestigationFile"
                                    className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}
                                >
                                    {i18n[this.props.isArabic ? "ar" : "en"].choose_file}
                                </label>
                                <label htmlFor="EmployeeinvestigationFile" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}>
                                    <span>{item.Employeeinvestigation}</span>
                                </label>
                            </div>
                            {
                                item.EmployeeinvestigationErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                item.EmployeeinvestigationErr === 'invalidFileType' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileType}
                                    </div>
                                    :
                                    null
                            }
                            {
                                item.EmployeeinvestigationErr === 'invalidFileSize' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidFileSize}
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ color: '#000', display: "flex", flexDirection: !this.props.isArabic ? "row-reverse" : "row", width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (item.EmployeeinvestigationArray &&
                                    item.EmployeeinvestigationArray.length > 0)
                                    ? <DownloadComponent filesObj={item.EmployeeinvestigationArray} />
                                    : <span></span>
                            }
                        </div>
                    </MDBCol>
                    <br />
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                        <label style={{ display: 'flex', flexDirection:  'row' }} htmlFor="formGroupExampleInput">
                                    {i18n[this.getLocale()].Disciplinaryboard.isinverstagiondone}
                                    <span className="requireStar">*</span>
                                </label>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline"> 
                               <input
                                    type="radio"
                                    value="1"
                                    checked={item.isinverstagiondone === '1'  }
                                    class="custom-control-input"
                                     id={index+"customControlValidation4isinverstagiondone"}
                                    onChange={e=>this.handleisinverstagiondoneChange(e,index)}
                                    name={index+"radio-stacked-isinverstagiondone"}
                                    required />
                                <label class="custom-control-label" for={index+"customControlValidation4isinverstagiondone"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.Yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="2"
                                    class="custom-control-input"
                                     id={index+"customControlValidation5isinverstagiondone"}
                                    name={index+"radio-stacked-isinverstagiondone"}
                                    checked={item.isinverstagiondone === '2'}
                                    onChange={e=>this.handleisinverstagiondoneChange(e,index)}
                                     />
                                <label class="custom-control-label" for={index+"customControlValidation5isinverstagiondone"}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.No
}
                                </label>
                            </div>
                            <br />
                            {
                                item.isinverstagiondoneErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                          
                        </div>
                    </MDBCol>

                        </MDBRow>

  
     
       <MDBRow  style={{ margin:'5px 34%' }}>
             <MDBBtn onClick={() => this.addemployee()} className="gridActionBtAttch whitetext">
                               {i18n[this.props.isArabic ? "ar" : "en"].Disciplinaryboard.addemployee}
                           </MDBBtn> 
                           <MDBBtn onClick={() => this.delteemployee
(index)}  className="gridUploadAttch whitetext">
                               {i18n[this.props.isArabic ? "ar" : "en"].Disciplinaryboard.removeEmployee}
                           </MDBBtn>
             </MDBRow>
         
             </div>  
             
             )
            })
            
        return (
            // <React.Fragment>
                    <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.props.isArabic? 'rtl':'ltr',textAlign:this.props.isArabic?'right':'left'}}>
                 <StepsComponent
                    steps={["Step1", "Step2","Step3"]}
                    currentIndex={2}
                    isArabic={!this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />
                <ul class="nav nav-tabs">
                    <li class="nav-item Customcontainer">
                        <a class="nav-link active" href="#">{i18n[this.getLocale()].Disciplinaryboard.requestype} </a>
                    </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                         
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline"> 
                                <input
                                    type="radio"
                                    value="1"
                                    checked={this.state.RequestType === '1'}
                                    class="custom-control-input"
                                    id="customControlValidation4"
                                    onChange={this.handleRequestTypeChange}
                                    name="radio-stacked-2"
                                    required />
                                <label class="custom-control-label" for="customControlValidation4">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.permanent}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="2"
                                    class="custom-control-input"
                                    id="customControlValidation5"
                                    name="radio-stacked-2"
                                    checked={this.state.RequestType === '2'}
                                    onChange={this.handleRequestTypeChange}
                                     />
                                <label class="custom-control-label" for="customControlValidation5">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].Disciplinaryboard.temporary}
                                </label>
                            </div>
                            <br />
                            {
                                this.state.RequestTypeErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                          
                        </div>
                    </MDBCol>

                        </MDBRow>


                    </div>
                </div>
{
    this.state.RequestType==2?
<div>
                <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].Disciplinaryboard.EmployeeInfoSubject} </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent">
  {element}
   </div>
   </div>
      :null  }
      <div className="clearfix"></div>
             
             <MDBRow className="SaveButtons" style={{ display: 'flex', flexDirection:  'row' }}>
             <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">
                        {i18n[this.props.isArabic ? "ar" : "en"].previous}
                    </MDBBtn>
                           <MDBBtn className="submitBtn" type='submit' color="primary">
                               {i18n[this.getLocale()].next_btn}
                           </MDBBtn>
       
                           {
                            //    (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            //        null
                            //        :
                                   <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                       {i18n[this.getLocale()].saveAsDraft}
                                   </MDBBtn>
                           }
       
                           {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                       </MDBRow>
       
      
               
                       </form>    

    //   </React.Fragment>
              
        );
    }
}