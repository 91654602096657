import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import "./../css/style.css";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import SideBar from "./../components/SideBar";
import Stepone from "./../components/ExceptionADHouseingSteps/StepOne";
import StepTwo from "./../components/ExceptionADHouseingSteps/StepTwo";
import i18n from "./../i18n";
import SubHeaderStrip from "./../components/SubHeaderStrip";
import Config from "./../utils/Config";
import ApiRequest from './../services/ApiRequest';
import { saveHousingPolicyPayload, uploadDocument } from './../utils/Payload';
import LoadingOverlay from 'react-loading-overlay'
import { confirmAlert } from 'react-confirm-alert';
import './../css/react-confirm-alert.css';

export default class ExceptionADHousing extends Component {

    constructor(props) {
        super(props);
        // disableBrowserBackButton();
        this.state = {
            isEdit: false,
            step1: true,
            step2: false,
            requestId: '',
            formType: "",
            statusid: '',
            canviewreviewhrrequest: 0,
            canviewsecondmentrequest: 0,
            canviewadhousingrequest: 0,
            canviewpatientrequest: 0,
            canviewretirementrequest: 0,
            isArabic: localStorage.getItem("Lang") === "ar" ? true : false,
            requiredRecomandation: '',
            typeOfContract: '',
            typeOfContractLabel: '',
            EmployeeName: '',
            EntityName: '',
            BasicSalary: '',
            FinancialCost: '',
            TotalSalary: '',
            EmployeeNumber: '',
            HousingAllowance: '',
            contractPeriod: '',
            jobTitle: '',
            retirementSubscriptions: '',
            RetirementContributions: '',
            supportRequest: '',
            supportRequestShortName: '',
            ReasonsOfRequest: '',
            entityFormalRequest: '',
            HRAEmployeeStudyMaker: '',
            fileNasupportRequestme1: '',

            isSideBarMinimized: false,
            isLoading: false,
            unifiedNumber: "",
            emiratesID: "",
            flatContract: '',
            employeeContract: '',

            employeeContractObj: '',
            flatContractObj: "",
            entitityFileRequestFileObj: "",
            attachFileSupportFileObj: "",
            OtherAttach: '',
            OtherAttachFileObj:"",
 Passport:'',
 PassportObj: '',
 EID:'',
 EIDObj:'',
 FamilyBook:'',
 FamilyBookObj:'',
        }
    }

    componentWillMount = () => {

        let previewFlag = Config.getIsPreviewFlag();
        let previewObj = Config.getPreviewObj();
        let previewArray = Config.getPreviewAttachmentArray();
        let previewckEditorArr = Config.getPreviewckEditorArray();

        if (previewFlag) {

            let
                employeeContract = '',
                flatContract = '',
                entityFormalRequest = '',
                supportRequest = '',
                ReasonsOfRequest = '',OtherAttach='',Passport='',EID='',FamilyBook='';

            if (previewArray && previewArray.length) {
                for (let index = 0; index < previewArray.length; index++) {
                    if (previewArray[index].label === 'employeeContract') {
                        employeeContract = previewArray[index].fileName;
                    } else if (previewArray[index].label === 'flatContract') {
                        flatContract = previewArray[index].fileName;
                    } 
                    // else if (previewArray[index].label === 'entityFormalRequest') {
                    //     entityFormalRequest = previewArray[index].fileName;
                    // } 
                    else if (previewArray[index].label === 'supportRequest') {
                        supportRequest = previewArray[index].fileName;
                    }
                    else if (previewArray[index].label==='OtherAttach')
                    {
                        OtherAttach=previewArray[index].fileName;
                    }
                    else if (previewArray[index].label==='ADHousingPassport')
                    {
                        Passport=previewArray[index].fileName;
                    }
                    else if (previewArray[index].label==='ADHousingEID')
                    {
                        EID=previewArray[index].fileName;
                    }
                    else if (previewArray[index].label==='ADHousingFamilyBook')
                    {
                        FamilyBook=previewArray[index].fileName;
                    }
                }
            }

            if (previewckEditorArr && previewckEditorArr.length > 0) {
                for (let j = 0; j < previewckEditorArr.length; j++) {
                    if (previewckEditorArr[j].label === 'ReasonsOfRequest') {
                        ReasonsOfRequest = previewckEditorArr[j].value;
                    }
                }
            }

            this.setState({
                requestId: previewObj.requestId,
                isEdit: previewObj.isEdit,
                typeOfContract: previewObj.typeOfContract,
                typeOfContractLabel: previewObj.typeOfContractLabel,
                EmployeeName: previewObj.EmployeeName,
                EntityName: previewObj.EntityName,
                BasicSalary: previewObj.BasicSalary,
                unifiedNumber: previewObj.unifiedNumber,
                jobTitle: previewObj.jobTitle,
                contractPeriod: previewObj.contractPeriod,
                // FinancialCost: previewObj.FinancialCost,
                TotalSalary: previewObj.TotalSalary,
                EmployeeNumber: previewObj.EmployeeNumber,
                HousingAllowance: previewObj.HousingAllowance,
                RetirementContributions: previewObj.RetirementContributions,
                emiratesID: previewObj.emiratesID,
                ReasonsOfRequest: ReasonsOfRequest,
                employeeContract: employeeContract,
                flatContract: flatContract,
                entityFormalRequest: entityFormalRequest,
                supportRequest: supportRequest,
                supportRequestShortName: previewObj.supportRequestShortName,
                OtherAttach,Passport,EID,FamilyBook
            }, () => {
           
            });
        }
    }

    componentDidMount = () => {

        if (Config.getIsPreviewFlag()) {
            this.setState({
                step1: false,
                step2: true,
            })
        }

        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })

        }

    }

    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.replace('/listpage', {
                        pathname: localStorage.getItem('currentPath')
                    })
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace("/");
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }

    toggleTheme = () => {
        const theme = this.state.theme === "light" ? "dark" : "light";
        this.setState({ theme: theme });
        document.documentElement.classList.add("theme-transition");
        document.documentElement.setAttribute("data-theme", theme);
        setTimeout(() => {
            document.documentElement.classList.remove("theme-transition");
        }, 1000);
    };

    toggleLanguage = lan => {
        this.setState({ isArabic: lan === "ar" ? true : false });
    };

    getLocale = () => {
        if (this.state.isArabic) {
            return "ar";
        } else {
            return "en";
        }
    };

    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    navigate = () => {
        this.setState({
            step2: true,
            step1: false,
            step3: false
        });
    };

    stepTwoDataPrev = (obj) => {
        this.setState({
            step1: true,
            step2: false,
            entitityFileRequestFileObj: obj.entitityFileRequestFileObj,
            attachFileSupportFileObj: obj.attachFileSupportFileObj,
            entityFormalRequest: obj.entityFormalRequest,
            supportRequest: obj.supportRequest,
            supportRequestShortName: obj.supportRequestShortName,
            employeeContract: obj.employeeContract,
            flatContract: obj.flatContract,
            OtherAttach:obj.OtherAttach,
            OtherAttachFileObj:obj.OtherAttachFileObj,
            Passport:obj.Passport,
 EID:obj.EID,
 FamilyBook:obj.FamilyBook,
            PassportObj:obj.PassportObj,
            EIDObj:obj.EIDObj,
            FamilyBookObj:obj.FamilyBookObj,
        });
    };

    stepOneData = (obj) => {

        debugger;
        let editObj = Config.getEditObj();
        if (editObj) {
            let setConfigObj = editObj;
            setConfigObj.requestreason = obj.ReasonsOfRequest;
            setConfigObj.contracttype = obj.typeOfContract;
            setConfigObj.typeOfContractLabel = obj.typeOfContractLabel;
            setConfigObj.employeename = obj.EmployeeName;
            setConfigObj.entityname = obj.EntityName;
            setConfigObj.basicsalary = obj.BasicSalary;
            // setConfigObj.financialcost = obj.FinancialCost;
            setConfigObj.totalsalary = obj.TotalSalary;
            setConfigObj.employeenumber = obj.EmployeeJobNumber;
            setConfigObj.housingallowance = obj.HousingAllowance;
            setConfigObj.jobname = obj.jobTitle;
            setConfigObj.contractperiod = obj.contractPeriod;
            setConfigObj.retirementcontribution = obj.RetirementContributions;
            setConfigObj.emiratesid = obj.emiratesID;
            setConfigObj.unifiednumber = obj.unifiedNumber;

            Config.setEditObj(setConfigObj);
        }

        this.setState({
            step2: true,
            step1: false,
            BasicSalary: obj.BasicSalary,
            TotalSalary: obj.TotalSalary,
            // requiredRecomandation: obj.requiredRecomandation,
            typeOfContract: obj.typeOfContract,
            typeOfContractLabel: obj.typeOfContractLabel,
            EmployeeName: obj.EmployeeName,
            EntityName: obj.EntityName,
            RetirementContributions: obj.RetirementContributions,
            // FinancialCost: obj.FinancialCost,
            EmployeeNumber: obj.EmployeeNumber,
            HousingAllowance: obj.HousingAllowance,
            jobTitle: obj.jobTitle,
            contractPeriod: obj.contractPeriod,
            retirementSubscriptions: obj.retirementSubscriptions,
            ReasonsOfRequest: obj.ReasonsOfRequest,
            unifiedNumber: obj.unifiedNumber,
            emiratesID: obj.emiratesID,
        });
    };

    stepTwoData = (obj) => {
        if (obj.saveFrom === "Step1") {
            this.setState({
                saveFrom: "Step1",
                isEdit: obj.isEdit,
                formType: obj.formType,
                requestId: obj.requestId,
                typeOfContract: obj.typeOfContract,
                typeOfContractLabel: obj.typeOfContractLabel,
                EmployeeName: obj.EmployeeName,
                EntityName: obj.EntityName,
                BasicSalary: obj.BasicSalary,
                unifiedNumber: obj.unifiedNumber,
                jobTitle: obj.jobTitle,
                contractPeriod: obj.contractPeriod,
                // FinancialCost: obj.FinancialCost,
                TotalSalary: obj.TotalSalary,
                EmployeeNumber: obj.EmployeeNumber,
                HousingAllowance: obj.HousingAllowance,
                RetirementContributions: obj.RetirementContributions,
                ReasonsOfRequest: obj.ReasonsOfRequest,
                emiratesID: obj.emiratesID
            }, () => {
                this.submitApiCall();
            });
        }
        else {
            this.setState({
                isEdit: obj.isEdit,
                requestId: obj.requestId,
                formType: obj.formType,
                entityFormalRequest: obj.entityFormalRequest,
                supportRequest: obj.supportRequest,
                supportRequestShortName: obj.supportRequestShortName,
                flatContract: obj.flatContract,
                employeeContract: obj.employeeContract,
                OtherAttach:obj.OtherAttach,
                Passport:obj.Passport,
                EID:obj.EID,
                FamilyBook:obj.FamilyBook
            }, () => {
                this.submitApiCall();
            });
        }
    }

    submitApiCall = () => {
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            tempStatusId = tempEditObj.statusid;
            this.setState({
                statusid: tempStatusId
            }, () => {
                Config.setIsEditFlag(false);
                Config.setIsPreviewFlag(false);
                Config.setPreviewObj();
                Config.setPreviewAttachmentArray();
                Config.setPreviewckEditorArray();

                let reqObj = {};
                reqObj.requiredRecomandation = this.state.requiredRecomandation;
                reqObj.typeOfContract = this.state.typeOfContract;
                reqObj.typeOfContractLabel = this.state.typeOfContractLabel;
                reqObj.EmployeeName = this.state.EmployeeName;
                reqObj.EntityName = this.state.EntityName;
                reqObj.BasicSalary = this.state.BasicSalary;
                // reqObj.JobName = this.state.JobName;
                // reqObj.FinancialCost = this.state.FinancialCost;
                reqObj.TotalSalary = this.state.TotalSalary;
                reqObj.EmployeeNumber = this.state.EmployeeNumber;
                reqObj.HousingAllowance = this.state.HousingAllowance;
                reqObj.contractPeriod = this.state.contractPeriod;
                reqObj.JobName = this.state.jobTitle;
                reqObj.RetirementContributions = this.state.RetirementContributions;
                reqObj.ReasonsOfRequest = this.state.ReasonsOfRequest;
                reqObj.isEdit = this.state.isEdit;
                reqObj.requestId = this.state.requestId;
                reqObj.unifiednumber = this.state.unifiedNumber;
                reqObj.emiratesid = this.state.emiratesID;

                if (this.state.formType === "saveAsDraft") {
                    reqObj.draft = 1;
                } else {
                    reqObj.draft = 0;
                }

                // reqObj.retirementSubscriptions= this.state.retirementSubscriptions;
                let payload = saveHousingPolicyPayload(reqObj);
                let endPoint = 'SaveHousingPolicy';
                let url = Config.getUrl(endPoint);

                
                this.setState({
                    isLoading: true
                }, () => {
                    ApiRequest.sendRequestForPost(url, payload, resHousingPolicy => {
                        if (resHousingPolicy.Status) {
                            let totalCount = 6;
                            this.uploadFile(0, totalCount, resHousingPolicy, 0);
                        } else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].error_message
                                }, () => {
                                    this.alertSubmit();
                                })
                            })
                        }
                    })
                })
            })
        }
    }

    uploadFile = (i, totalCount, resHousingPolicy, multifileCount) => {
        if (i < totalCount) {
            let fileObj = null;
            let tempBase64 = {};
            let obj = {};
            let isLastFileInMultiUpload = false;
debugger;
            if (i === 0) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForExemptionHousingPolicy_Passport();
                obj.filename = this.state.Passport;
                obj.filetype = Config.getAdHousingAttachedFileForPassport();
                tempBase64 = fileObj;
            }
            // else if (i === 1) {
            //     // get base 64 for attachement
            //     let fileObjArray = Config.getBase64ForExemptionHousingPolicy_attachFileSupport();
            //     if (fileObjArray && fileObjArray.length > 0) {
            //         if (multifileCount === fileObjArray.length - 1) {
            //             isLastFileInMultiUpload = true;
            //         }
            //         fileObj = fileObjArray[multifileCount];
            //     }
            //     if (this.state.supportRequest !== null) {
            //         let filenamesArray = this.state.supportRequest.split(",");
            //         obj.filename = filenamesArray[multifileCount];
            //     }
            //     obj.filetype = Config.getAdHousingAttachedFileForSupportRequestId();
            //     tempBase64 = fileObj;
            // }
             else if (i === 1) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForExemptionHousingPolicy_employeeContract();
                obj.filename = this.state.employeeContract;
                obj.filetype = Config.getAdHousingAttachedFileForEmployee();
                tempBase64 = fileObj;
            } else if (i === 2) {
                // get base 64 for attachement
                fileObj = Config.getBase64ForExemptionHousingPolicy_flatContract();
                obj.filename = this.state.flatContract;
                obj.filetype = Config.getAdHousingAttachedFileForFlat();
                tempBase64 = fileObj;
            }
            else if (i === 3) {
                fileObj = Config.getBase64ForOtherAttach();
                obj.filename = this.state.OtherAttach;
                obj.filetype = Config.getRETOtherAttachId();
                tempBase64 = fileObj;
            }
            else if (i === 4) {
                fileObj = Config.getBase64ForExemptionHousingPolicy_EID();
                obj.filename = this.state.EID;
                obj.filetype = Config.getAdHousingAttachedFileForEID();
                tempBase64 = fileObj;
            }
            else if (i === 5) {
                fileObj = Config.getBase64ForExemptionHousingPolicy_familybooknumber();
                obj.filename = this.state.FamilyBook;
                obj.filetype = Config.getAdHousingAttachedFileForFamilyBook();
                tempBase64 = fileObj;
            }

            if (obj.filename && obj.filename !== '' && obj.filename !== undefined && tempBase64 && tempBase64 !== undefined && tempBase64 !== '') {
                let reqId = 0;
                if (resHousingPolicy && resHousingPolicy.data && resHousingPolicy.data.id) {
                    reqId = resHousingPolicy.data.id;
                    obj.requestid = reqId;
                } else {
                    obj.requestid = reqId;
                }
                // obj.requestid = resHousingPolicy.data.id;
                // obj.fileContent = tempBase64.split(',')[1];
                // obj.fileContent = tempBase64.split(',')[1];
                // let posIfValueIsPresentInStringOrNot = tempBase64.indexOf(",");
                // include method is not supported by IE
                // if (posIfValueIsPresentInStringOrNot >= 0) {
                if (tempBase64.includes(",")) {
                    obj.fileContent = tempBase64.split(',')[1];
                } else {
                    obj.fileContent = tempBase64;
                }
                let payload = uploadDocument(obj);
                let endPoint = 'UploadAttachment';
                let url = Config.getUrl(endPoint);

                ApiRequest.sendRequestForPost(url, payload, resForEntityFIleRequestFile => {
                    if (resForEntityFIleRequestFile.Status) {
                        // if (i === 1) {
                        //     if (isLastFileInMultiUpload) {
                        //         this.uploadFile(i + 1, totalCount, resHousingPolicy, multifileCount + 1);
                        //     } else {
                        //         this.uploadFile(i, totalCount, resHousingPolicy, multifileCount + 1);
                        //     }
                        // } else {
                            this.uploadFile(i + 1, totalCount, resHousingPolicy, 0);
                        // }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //   pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                            if (this.state.formType === "saveAsDraft") {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].save_as_Draft
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                            } else {
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].error,
                                    messageAlert: i18n[this.getLocale()].attachment_error
                                }, () => {
                                    this.alertSubmit();
                                })
                            }
                        });
                    }
                })
            } else {
                this.uploadFile(i + 1, totalCount, resHousingPolicy, 0);
            }
        }
        else {
           
            this.setState({
                isLoading: false
            }, () => {
                // this.props.history.replace("/listPage", {
                //   pathname: localStorage.getItem('currentPath')
                // });
                if (this.state.isEdit) {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        if (this.state.statusid === Config.getDraftId()) {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_created
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        } else {
                            this.setState({
                                titleAlert: i18n[this.getLocale()].success,
                                messageAlert: i18n[this.getLocale()].request_updated
                            }, () => {
                                this.alertSubmitForSuccess();
                            })
                        }
                        // if (this.state.formType) {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_updated
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // } else {
                        //     this.setState({
                        //         titleAlert: i18n[this.getLocale()].success,
                        //         messageAlert: i18n[this.getLocale()].request_created
                        //     }, () => {
                        //         this.alertSubmitForSuccess();
                        //     })
                        // }
                    }
                } else {
                    if (this.state.formType === "saveAsDraft") {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].save_as_Draft
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    } else {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].success,
                            messageAlert: i18n[this.getLocale()].request_created
                        }, () => {
                            this.alertSubmitForSuccess();
                        })
                    }
                }
            });
        }
    }

    getPrev = {
       
        stepOneDataPrev: this.stepOneDataPrev,
        stepTwoDataPrev: this.stepTwoDataPrev
    };

    getData = {
        stepOneData: this.stepOneData,
        stepTwoData: this.stepTwoData
    };

    handlePreViewNavigation = (obj) => {
        localStorage.setItem('currentPath', 'exceptionADHousing');

        let previewObj = {};
        previewObj.requestId = this.state.requestId;
        previewObj.isEdit = this.state.isEdit;
        previewObj.typeOfContract = this.state.typeOfContract;
        previewObj.typeOfContractLabel = this.state.typeOfContractLabel;
        previewObj.EmployeeName = this.state.EmployeeName;
        previewObj.EntityName = this.state.EntityName;
        previewObj.BasicSalary = this.state.BasicSalary;
        previewObj.unifiedNumber = this.state.unifiedNumber;
        previewObj.jobTitle = this.state.jobTitle;
        previewObj.contractPeriod = this.state.contractPeriod;
        // previewObj.FinancialCost = this.state.FinancialCost;
        previewObj.TotalSalary = this.state.TotalSalary;
        previewObj.EmployeeNumber = this.state.EmployeeNumber;
        previewObj.HousingAllowance = this.state.HousingAllowance;
        previewObj.RetirementContributions = this.state.RetirementContributions;
        previewObj.emiratesID = this.state.emiratesID;
        previewObj.supportRequestShortName = obj.supportRequestShortName;

        let previewAttachMentArray = [
            {
                "label": "employeeContract",
                "fileName": obj.employeeContract,
                "fileObj": Config.getBase64ForExemptionHousingPolicy_employeeContract()
            },
            {
                "label": "flatContract",
                "fileName": obj.flatContract,
                "fileObj": Config.getBase64ForExemptionHousingPolicy_flatContract()
            },
            // {
            //     "label": "entityFormalRequest",
            //     "fileName": obj.entityFormalRequest,
            //     "fileObj": Config.getBase64ForExemptionHousingPolicy_EntityFormalReq()
            // },
            // {
            //     "label": "supportRequest",
            //     "fileName": obj.supportRequest,
            //     "fileObj": Config.getBase64ForExemptionHousingPolicy_attachFileSupport()
            // },
            {
                "label": 'OtherAttach',
                "fileName": obj.OtherAttach,
                "fileObj": Config.getBase64ForOtherAttach(),
            },
            {
                "label": 'ADHousingPassport',
                "fileName": obj.Passport,
                "fileObj": Config.getBase64ForExemptionHousingPolicy_Passport(),
            },
            {
                "label": 'ADHousingEID',
                "fileName": obj.EID,
                "fileObj": Config.getBase64ForExemptionHousingPolicy_EID(),
            },
            {
                "label": 'ADHousingFamilyBook',
                "fileName": obj.FamilyBook,
                "fileObj": Config.getBase64ForExemptionHousingPolicy_familybooknumber(),
            },
            
        ];

        let ckEditorArr = [
            {
                label: 'ReasonsOfRequest',
                value: this.state.ReasonsOfRequest,
            },
        ];

        this.props.history.replace('/preview',{ data: previewObj, attachmentArr: previewAttachMentArray, ckEditorArr: ckEditorArr });

    }
    render() {
      
        const sideBarData = Config.getServiceSideBar('exceptionADHousing', this.state.isArabic, this, 0);
        return (
            <MDBContainer fluid style={{ height: "100vh" }}>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                    <MDBRow
                        className="formWhiteContainer"
                        style={{
                            flexDirection: this.state.isArabic ? "row" : "row-reverse"
                        }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: "0", paddingRight: "0" }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace("/listpage", {
                                                pathname: 'exceptionADHousing'
                                            });
                                        }}
                                        isArabic={this.state.isArabic}
                                        title={i18n[this.state.isArabic ? "ar" : "en"].exceptionADHousing}
                                    />
                                </MDBCol>
                            </MDBRow>
                          
                            {
                                this.state.step1 ?
                                    <Stepone
                                        sendData={{
                                            requiredRecomandation: this.state.requiredRecomandation,
                                            typeOfContract: this.state.typeOfContract,
                                            typeOfContractLabel: this.state.typeOfContractLabel,
                                            EmployeeName: this.state.EmployeeName,
                                            EntityName: this.state.EntityName,
                                            BasicSalary: this.state.BasicSalary,
                                            retirementSubscriptions: this.state.retirementSubscriptions,
                                            // FinancialCost: this.state.FinancialCost,
                                            TotalSalary: this.state.TotalSalary,
                                            unifiedNumber: this.state.unifiedNumber,
                                            emiratesID: this.state.emiratesID,
                                            EmployeeNumber: this.state.EmployeeNumber,
                                            HousingAllowance: this.state.HousingAllowance,
                                            contractPeriod: this.state.contractPeriod,
                                            jobTitle: this.state.jobTitle,
                                            RetirementContributions: this.state.RetirementContributions,
                                            ReasonsOfRequest: this.state.ReasonsOfRequest,
                                        }}
                                        getData={this.getData}
                                        getPrev={this.getPrev}
                                        isArabic={this.state.isArabic}
                                        saveAsDraft={this.stepTwoData}
                                    />
                                    :
                                    null
                            }
                            {
                                this.state.step2 ?
                                    <StepTwo
                                        sendData={{
                                            entityFormalRequest: this.state.entityFormalRequest,
                                            supportRequest: this.state.supportRequest,
                                            supportRequestShortName: this.state.supportRequestShortName,
                                            entitityFileRequestFileObj: this.state.entitityFileRequestFileObj,
                                            attachFileSupportFileObj: this.state.attachFileSupportFileObj,
                                            employeeContract: this.state.employeeContract,
                                            flatContract: this.state.flatContract,
                                            OtherAttach:this.state.OtherAttach,
                                            OtherAttachFileObj:this.state.OtherAttachFileObj,
                                            Passport:this.state.Passport,
                                            EID:this.state.EID,
                                            FamilyBook:this.state.FamilyBook,
                                            PassportObj:this.state.PassportObj,
                                            EIDObj:this.state.EIDObj,
                                            FamilyBookObj:this.state.FamilyBookObj,
                                        }}
                                        getPrev={this.getPrev}
                                        getData={this.getData}
                                        isArabic={this.state.isArabic}
                                        saveAsDraft={this.stepTwoData}
                                        previewPage={this.handlePreViewNavigation}
                                    />
                                    :
                                    null
                            }
                            {/* </MDBCardBody> */}
                            {/* </MDBCard> */}
                            <Footer isArabic={this.state.isArabic} />
                        </MDBCol>
                        <SideBar
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            isArabic={this.state.isArabic}
                            items={sideBarData}
                            toggleTheme={this.toggleTheme.bind(this)}
                        />
                    </MDBRow>
                </LoadingOverlay>
            </MDBContainer>
        );
    }
}
