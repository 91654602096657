import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import './../../css/style.css';
import Config from '../../utils/Config';
import DownloadComponent from "../DownloadComponent";
import { downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from "../../utils/Validator";

import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

const striptags = require('striptags');

export default class StrategicPlanningforworkforceEnquireForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Files:[],
            SCIPolicy:'',
            SCIPOlicyOptions:'',
            requestId: '',
            isEdit: false,
            statusid: '',
            contactNumberErr: '',
            requestType: Config.getSciRequestTypeId(),
            radio: 0,
            officalLetterErr: '',
            actualOfficialLetter: '',
            officialLetter: this.props.dataObject.officialLetter,
            officialLetterUrl: '',
            isNextScreenActive: false,
            isfirstPageValidate: false,
            applicantsJob: this.props.dataObject.applicantsJob,
            contactNumber: this.props.dataObject.contactNumber,
            reqDetails: this.props.dataObject.reqDetails,
            EntityLegalOpinon:this.props.dataObject.EntityLegalOpinon,
            applicantsName: this.props.dataObject.applicantsName,
            requestSubject: this.props.dataObject.requestSubject,
            hraConcernedDept: '',
            hraFeedback: '',
            enquiry: false,
            complain: false,
            isNextClick: false,
            isAddtachmentDownloadShowsInEdit: true,
            selectedOption: '',
            requestTypeLabelInSCI: this.props.dataObject.requestTypeLabelInSCI,
            selectedOptionErr: '',
            editAttachmentArray: [],
            sciAttachmentArray: [],
            reqDetails: '',
            reqDetailsErr: '',
            EntityLegalOpinon:'',
            EntityLegalOpinonErr:'',
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        let previewFlag = Config.getIsPreviewFlag();
        let previewObj = Config.getPreviewObj();
        let previewAttachmentArray = Config.getPreviewAttachmentArray();
        let previewckEditorArr = Config.getPreviewckEditorArray();
let scioption=0;
let scipolicyoption='';
        //For Preview Form
        if (previewFlag) {
           
            let sciRadioValueFromPreview = "";
            if (previewObj.requestTypeLabelInSCI) {
                if (previewObj.requestTypeLabelInSCI === i18n["en"].SCI.complain || previewObj.requestTypeLabelInSCI === i18n["ar"].SCI.complain) {
                    sciRadioValueFromPreview = "Complain";
                } else if (previewObj.requestTypeLabelInSCI === i18n["en"].SCI.inquiry || previewObj.requestTypeLabelInSCI === i18n["ar"].SCI.inquiry) {
                    sciRadioValueFromPreview = "Inquiry";
                    scipolicyoption="General";
                    scioption='1';
                }
                else if (previewObj.requestTypeLabelInSCI === i18n["en"].SCI.suggestion || previewObj.requestTypeLabelInSCI === i18n["ar"].SCI.suggestion) {
                    sciRadioValueFromPreview = "Suggestion";
                }
                else if(previewObj.requestTypeLabelInSCI ===i18n[this.props.isArabic ? "ar" : "en"].SCI.inquiry+" "+ i18n[this.props.isArabic ? "ar" : "en"].SCI.SuggestionPolicy )
                {
                    sciRadioValueFromPreview = "Inquiry";
                    scipolicyoption="Legal";
                    scioption='1';

                }
            }
            this.setState({
                requestId: previewObj.requestId,
                isEdit: previewObj.isEdit,
                selectedOption: sciRadioValueFromPreview,
                requestTypeLabelInSCI: previewObj.requestTypeLabelInSCI,
                applicantsJob: previewObj.applicantsJob,
                contactNumber: previewObj.contactNumber,
                applicantsName: previewObj.applicantsName,
                requestSubject: previewObj.requestSubject,
                SCIPolicy:scioption,SCIPOlicyOptions:scipolicyoption
            }, () => {

                //For Attachments
                let officialLetter = '';
                if (previewAttachmentArray.length > 0) {
                    for (let index = 0; index < previewAttachmentArray.length; index++) {
                        if (previewAttachmentArray[index].label === 'officialLetter') {
                           
                            officialLetter = previewAttachmentArray[index].fileName;
                        }
                    }
                }

                //For CK Editor
                let reqDetails = '';
                let EntityLegalOpinon='';
                if (previewckEditorArr) {
                    if (previewckEditorArr.length > 0) {
                        for (let j = 0; j < previewckEditorArr.length; j++) {
                            if (previewckEditorArr[j].label === 'reqDetails') {
                                reqDetails = previewckEditorArr[j].value;
                            }
                            else if (previewckEditorArr[j].label=='EntityLegalOpinon')
                            {
                                EntityLegalOpinon=previewckEditorArr[j].value;
                            }
                        }
                    }
                }

                this.setState({
                    officialLetter: officialLetter,
                    reqDetails: reqDetails,
                    EntityLegalOpinon:EntityLegalOpinon
                }, () => {
                   
                });
            });
        }

        // For Edit Form
        if (editFlag) {
         
            debugger;
            //selectedOption: editObj.requesttypeForSuggesationComplainEnquiry,
            this.setState({
           
                requestId: editObj.requestId,
                statusid: editObj.statusid,
                applicantsJob: editObj.applicantsJob,
                contactNumber: editObj.contactnumber,
                reqDetails: editObj.reqDetails,
                EntityLegalOpinon:editObj.EntityLegalOpinon,
                selectedOption: editObj.requesttypeid,
           
                applicantsName: editObj.applicantsName,
                requestSubject: editObj.requestSubject,
                isEdit: editFlag,
                editAttachmentArray: editObj.attachments
            }, () => {    
                let tempArray = [];    
               
                switch (this.state.selectedOption)
                {
                   
                    case 401:
                {
                    this.setState({
                        SCIPOlicyOptions:'Legal',
                        selectedOption:'Inquiry',SCIPolicy:'1'});
                        break;}
                        case 10960:
                            {
                                this.setState({
                                    SCIPOlicyOptions:'workforce',
                                    selectedOption:'Inquiry',SCIPolicy:'2'});
                                    break;}
                        case 15:
                            
                                {
                                    this.setState({
                                        SCIPOlicyOptions:'',
                                        selectedOption:'Suggestion'});
                                        break;}
                        case 16:
                                {
                                    this.setState({
                                        SCIPOlicyOptions:'',
                                        selectedOption:'Complain'});
                                        break;}
                                        case 17:
                                                {
                                                    this.setState({
                                                        SCIPOlicyOptions:'General',
                                                        selectedOption:'Inquiry',SCIPolicy:'1'});
                                                        break;}
                        default:
                            break;
                }
//                 if(this.state.selectedOption==401)
//                 {
// debugger;

//                 }
                if (this.state.editAttachmentArray && this.state.editAttachmentArray.length > 0) {
                    let tempAttactmentArray = [...this.state.editAttachmentArray];

                    if (tempAttactmentArray && tempAttactmentArray.length > 0) {
                        tempAttactmentArray.forEach((item, index) => {
                            if (item.filetypeid === Config.getSciAttachmentId()) {
                                let temp = {}
                                temp.url = item.aliasfilename;
                                temp.fileName = item.filename;
                                temp.requestId = this.state.requestId;

                                tempArray.push(temp);

                                // set base 64 in edit 
                                let obj = {}
                                obj.filename = item.aliasfilename;
                                obj.requesttype = this.state.requestType;
                                obj.requestid = this.state.requestId;
                                let payload = downloadAttachment(obj);
                                let endPoint = 'DownloadAttachment'
                                let url = Config.getUrl(endPoint);
                                this.setState({
                                    isLoading: false
                                }, () => {
                                    ApiRequest.sendRequestForPost(url, payload, res => {
                                       
                                        this.setState({
                                            isLoading: false
                                        }, () => {
                                            if (res.Status) {
                                                let fileData = res.data;
                                                Config.setBase64ForSciOfficalLetter(fileData.filecontent);
                                            } else {
                                              
                                            }
                                        })
                                    })
                                })
                                this.setState({
                                   
                                    officialLetterUrl: item.aliasfilename
                                })
                            }
                        })
                        this.setState({
                            sciAttachmentArray: tempArray
                        })
                    } else {
                        this.setState({
                            isAddtachmentDownloadShowsInEdit: false
                        })
                    }
                }
             
            })
        } else {
            let loginData = Config.getLocalStorageLoginData();
            this.setState({
                loginData: loginData
            }, () => {
                if (loginData) {
                    let tempEntityName = this.props.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn
                    this.setState({
                        applicantsName: tempEntityName
                    })
                }
            })
        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    onClick = nr => () => {
        this.setState({
            radio: nr
        });
    }

    nextButtonPressed = () => {
        this.setState({ isNextClick: true })
    }

    goToPrevious = () => {
        this.setState({ isNextClick: false })
    }

    handleContactNumberChange = (event) => {
        
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ contactNumber: event.target.value }, () => {
                if (Validator.validateContactNumber(contactNumber))
                {
                    this.setState({ contactNumberErr: '' });
                } else {
                    this.setState({ contactNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ contactNumber: '', contactNumberErr: 'thisFieldIsRequired' });
        }
    };

    submitHandler = event => {
       

        event.preventDefault();
        event.target.className += " was-validated";

        let
            contactNumberErr = '',
            reqDetailsErr = '',
            EntityLegalOpinonErr='',
            selectedOptionErr = '',
            officalLetterErr = '';

        if (this.state.contactNumber) {
            if (!Validator.validateContactNumber(this.state.contactNumber)) {
                contactNumberErr = 'invalidContactNumber';
            }
        } else {
            contactNumberErr = 'thisFieldIsRequired';
        }

        let val = '';
        if (this.state.reqDetails && this.state.reqDetails.trim()) {
            val = striptags(this.state.reqDetails.trim().replace(/&nbsp;/g, ""));
            if (!val.trim()) {
                reqDetailsErr = 'thisFieldisRequired';
            }
        } else {
            reqDetailsErr = 'thisFieldisRequired';
        }

       

        // if (!this.state.selectedOption) {
        //     selectedOptionErr = 'thisFieldisRequired';
        // }

        //if (!this.state.officialLetter) {
        //    officalLetterErr = '';
        //}

        this.setState({
            EntityLegalOpinonErr:EntityLegalOpinonErr,
            reqDetailsErr: reqDetailsErr,
         //   selectedOptionErr: selectedOptionErr,
            contactNumberErr: contactNumberErr,
            officalLetterErr: officalLetterErr
        }, () => {
          
            if (
                !EntityLegalOpinonErr&&
                !reqDetailsErr &&
                
                !contactNumberErr
            ) {
                if (
                    this.state.requestSubject &&
                    this.state.applicantsName &&
                    striptags(this.state.reqDetails)  &&
                    this.state.contactNumber &&
                    this.state.applicantsJob ) {

                    this.setState({
                        isNextClick: true, isfirstPageValidate: true
                    }, () => {
debugger;
                        let requestType = 10969;
                    
                        
                       
debugger;
                        var obj = {
                            requestId: this.state.requestId,
                            isEdit: this.state.isEdit,
                            actualOfficialLetter: this.state.Files,
                            requestType: requestType,
                            requestSubject: this.state.requestSubject,
                            applicantsName: this.state.applicantsName,
                            reqDetails: this.state.reqDetails,
                             EntityLegalOpinon:this.state.EntityLegalOpinon,
                            contactNumber: this.state.contactNumber,
                            applicantsJob: this.state.applicantsJob,
                            officialLetter: this.state.officialLetter,
                            Files: this.state.Files
                        }
                        
                        this.props.setDataObject(obj);
                        this.props.onNextButtonPressed(true);
                    })
                }
            }

        });

    };

    onReqDetailsChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                reqDetailsErr: "",
                reqDetails: newContent
            });
        }else{
            this.setState({
                reqDetailsErr: "ckeditorCountErr"
            });
        }
    }

    onEntityLegalOpinonChange = (evt) => {
        
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                EntityLegalOpinonErr: "",
                EntityLegalOpinon: newContent
            });
        }else{
            this.setState({
                EntityLegalOpinonErr: "ckeditorCountErr"
            });
        }
    }


    // officialLetterOnChange = e => {
    //     if (e.target.files.length > 0) {
    //         let inpFileName = e.target.files[0].name;
    //         let fileSize = e.target.files[0].size;
    //         let fileExt = inpFileName.split('.').pop().toUpperCase();
    //         let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];

    //         if (allowedExtensions.indexOf(fileExt) <= -1) {
    //             e.target.value = '';
    //             this.setState({
    //                 officialLetter: '',
    //                 officalLetterErr: 'invalidFileType'
    //             });
    //         } else {
    //             if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
    //                 let fileObj = e.target.files[0];
    //                 this.setState({
    //                     officialLetter: e.target.files[0].name,
    //                     officalLetterErr: ''
    //                 }, () => {
    //                     let reader = new FileReader();
    //                     let file = fileObj;
    //                     reader.onloadend = () => {
    //                         this.setState({
    //                             actualOfficialLetter: reader.result,
    //                         }, () => {
    //                             Config.setBase64ForSciOfficalLetter(this.state.actualOfficialLetter);
    //                         });
    //                     };
    //                     reader.readAsDataURL(file);
    //                 });

    //             } else {
    //                 e.target.value = '';
    //                 this.setState({
    //                     officialLetter: '',
    //                     officalLetterErr: 'invalidFileSize'
    //                 });
    //             }
    //         }

    //     } else {
    //         e.target.value = '';
    //         this.setState({ officialLetter: '' });
    //     }

    // };

    officialLetterOnChange = e => {
        let tempfiles = [];    debugger;
       if (e.target.files.length > 0) {
           for(let i=0; i<e.target.files.length;i++)
           {
               let element=e.target.files[i];
           
           
               let inpFileName = element.name;
               let fileSize = element.size;
               let fileExt = inpFileName.split('.').pop().toUpperCase();
               let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
               if (allowedExtensions.indexOf(fileExt) <= -1) {
                   e.target.value = '';
                   this.setState({
                       officialLetter: '',
                       officalLetterErr: 'invalidFileType'
                   });
                   return;
               }
               else {
                   debugger;
                   if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                       let tempobj = {};
                       let fileObj = element;
                       tempobj.filename=element.name;
                       let reader = new FileReader();
                       let file = fileObj;
                       reader.onloadend = () => {
                           tempobj.filecontent=reader.result;
                       };
                       reader.readAsDataURL(file);
                       tempfiles.push(tempobj);
                   }
                   else {
                       e.target.value = '';
                       this.setState({
                           officialLetter: '',
                           officalLetterErr: 'invalidFileSize'
                       });
                       return;
                   }  
               }
               
   
           }
         
   this.setState({
       Files:tempfiles
   });
           // else {
           //     if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
           //         let fileObj = e.target.files[0];
           //         this.setState({
           //             officialLetter: e.target.files[0].name,
           //             officalLetterErr: ''
           //         }, () => {
           //             let reader = new FileReader();
           //             let file = fileObj;
           //             reader.onloadend = () => {
           //                 this.setState({
           //                     actualOfficialLetter: reader.result,
           //                 }, () => {
           //                     Config.setBase64ForSciOfficalLetter(this.state.actualOfficialLetter);
           //                 });
           //             };
           //             reader.readAsDataURL(file);
           //         });
   
           //     } else {
           //         e.target.value = '';
           //         this.setState({
           //             officialLetter: '',
           //             officalLetterErr: 'invalidFileSize'
           //         });
           //     }
           // }
   
       } else {
           e.target.value = '';
           this.setState({ officialLetter: '' });
       }
   
   };
    attachedTwoOnChange = e => {
        if (e.target.files.length > 0) {
            this.setState({ attachedTwo: e.target.files[0].name });
        }
    };

    handleOptionChange = (e) => {
       


        let requestTypeLabelInSCI = '';
        if (e.target.value === 'Complain') {
            requestTypeLabelInSCI = i18n[this.getLocale()].SCI.complain;
        }
        else if (e.target.value === 'Suggestion') {
            requestTypeLabelInSCI = i18n[this.getLocale()].SCI.suggestion;
            

        }
      
        else if (e.target.value==='General')
        {
            requestTypeLabelInSCI = i18n[this.getLocale()].SCI.SuggestionGeneral;
        }

        else if (e.target.value==='Legal')
        {
            
            requestTypeLabelInSCI = i18n[this.getLocale()].SCI.SuggestionPolicy;
        }
        else if (e.target.value==='workforce')
        {
            
            requestTypeLabelInSCI = i18n[this.getLocale()].SCI.workforce;
        }
        if (e.target.value === 'Inquiry' )

        {
            
            requestTypeLabelInSCI = i18n[this.getLocale()].SCI.inquiry;
            
            this.setState({
                
                selectedOption:'Inquiry' });
                this.setState({SCIPOlicyOptions:'General' });
                this.setState({SCIPolicy: '1' });
                this.setState({requestTypeLabelInSCI: requestTypeLabelInSCI });
                this.setState({selectedOptionErr: '' });
           
        }
        else if (e.target.value === 'General'  || e.target.value === 'Legal'||  e.target.value === 'workforce')
        {
            
            let SCIPolicyval = '1';
            if(e.target.value ==='workforce')
            {
                SCIPolicyval='2';
            }

            this.setState({selectedOption:'Inquiry' });
            this.setState({SCIPOlicyOptions:e.target.value });
            this.setState({SCIPolicy: SCIPolicyval });
            this.setState({requestTypeLabelInSCI: requestTypeLabelInSCI });
            this.setState({selectedOptionErr: '' });
            
        }
     else    {
        this.setState({
            SCIPolicy: '',
            selectedOption: e.target.value,
            requestTypeLabelInSCI: requestTypeLabelInSCI,
            selectedOptionErr: '',
            SCIPOlicyOptions:''
        });
    }
        

    };

    clearForm = () => {
        this.setState({
            requestSubject: '',
            applicantsName: '',
            reqDetails: '',
            contactNumber: '',
            applicantsJob: '',
            officialLetter: ''
        });
    }

    

    saveAsDraft = () => {
debugger;
        let requestType = '';
      

        if(this.state.SCIPOlicyOptions === 'General')
        {
            requestType = 17;
        }
        else if (this.state.SCIPOlicyOptions === 'Legal')
            {
                requestType = 401;
            }
            else if (this.state.SCIPOlicyOptions === 'workforce')
            {
                requestType = 10960;
            }
        
       
         else if (this.state.selectedOption === 'Complain') {
            requestType = 16;
        } else if (this.state.selectedOption === 'Suggestion') {
            requestType = 15;
        }
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            actualOfficialLetter: this.state.Files,
            requestType: requestType,
            requestSubject: this.state.requestSubject,
            applicantsName: this.state.applicantsName,
            reqDetails: this.state.reqDetails,
            contactNumber: this.state.contactNumber,
            applicantsJob: this.state.applicantsJob,
            officialLetter: this.state.officialLetter,
            EntityLegalOpinon: this.state.EntityLegalOpinon,
                officialLetter: this.state.officialLetter,
            Files: this.state.Files
        });
    };

    handlePreViewNavigation = () => {
        let langValueForRequestTypeLabelInSCI = "";
        if (this.state.selectedOption === "Complain") {
            langValueForRequestTypeLabelInSCI = i18n[this.props.isArabic ? "ar" : "en"].SCI.complain;
        }
         else if (this.state.selectedOption === "Inquiry") {
             if(this.state.SCIPOlicyOptions==="Legal")
             {
                langValueForRequestTypeLabelInSCI = i18n[this.props.isArabic ? "ar" : "en"].SCI.inquiry+" "+ i18n[this.props.isArabic ? "ar" : "en"].SCI.SuggestionPolicy;
             }
             else if(this.state.SCIPOlicyOptions==="workforce")
             {
                langValueForRequestTypeLabelInSCI = i18n[this.props.isArabic ? "ar" : "en"].SCI.inquiry+" "+ i18n[this.props.isArabic ? "ar" : "en"].SCI.workforce;
             }
             else
             {
            langValueForRequestTypeLabelInSCI = i18n[this.props.isArabic ? "ar" : "en"].SCI.inquiry ;
             }
        } 
        else if (this.state.selectedOption === "Suggestion") {
            langValueForRequestTypeLabelInSCI = i18n[this.props.isArabic ? "ar" : "en"].SCI.suggestion;

        }

        let dataObj = {
            requestId: this.state.requestId,
            isEdit: this.state.isEdit,
            requestSubject: this.state.requestSubject,
            applicantsName: this.state.applicantsName,
            contactNumber: this.state.contactNumber,
            applicantsJob: this.state.applicantsJob,
            selectedOption: this.state.selectedOption,
            SCIPOlicyOptions:this.state.SCIPOlicyOptions,
            SCIPolicy:this.state.SCIPolicy,
            requestTypeLabelInSCI: langValueForRequestTypeLabelInSCI,
            
        }

        let attachmentArr = [
            {
                label: 'officialLetter',
                fileName: this.state.officialLetter,
                fileObj: Config.getBase64ForSciOfficalLetter()
            },
        ];

        let ckEditorArr = [
          
            {
                label: 'reqDetails',
                value: this.state.reqDetails,
            },
            
        ];

        this.props.previewPage(dataObj, attachmentArr, ckEditorArr);
    }

    render() {

        return (

            <form
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].SCI.requestSubject}
                                <span className="requireStar">*</span>
                            </label>
                            <input style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                type="text"
                                className="form-control"
                                id="req_subject"
                                maxLength={50}
                                name="req_subject"
                                value={this.state.requestSubject}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064AA-Za-z ]*$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ requestSubject: event.target.value });
                                        }
                                    }
                                    else {
                                        this.setState({ requestSubject: '' });
                                    }
                                }}
                                required
                            />

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <div className="form-group">
                                <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                    {i18n[this.getLocale()].viewComponent.requesttype}
                                    <span className="requireStar">*</span>
                                </label>
                                <div style={{ float: this.props.isArabic ? '' : 'left', width: '100%', paddingLeft: "10px" }} className="custom-control custom-radio custom-control-inline">
                                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                                        <input
                                            name="requestType"
                                            type="radio"
                                            value="Complain"
                                            id="defaultInline1"
                                            className="custom-control-input"
                                            checked={this.state.selectedOption === 'Complain' || this.state.selectedOption === 'شكوى'}
                                            onChange={this.handleOptionChange}
                                            required
                                        />
                                        <label className="custom-control-label" htmlFor="defaultInline1" style={{ float: this.props.isArabic ? 'right' : '', width: this.props.isArabic ? '20%' : '' }}>{i18n[this.getLocale()].SCI.complain}</label>
                                    </MDBCol>
                                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                                        <input
                                            name="requestType"
                                            type="radio"
                                            value="Inquiry"
                                            id="defaultInline2"
                                            className="custom-control-input"
                                            checked={this.state.selectedOption === 'Inquiry' || this.state.selectedOption === 'استفسار'}
                                            onChange={this.handleOptionChange}
                                            required
                                        />
                                        <label className="custom-control-label" htmlFor="defaultInline2" style={{ float: this.props.isArabic ? 'right' : '', width: this.props.isArabic ? '20%' : '' }}>{i18n[this.getLocale()].SCI.inquiry}</label>
                                    </MDBCol>
                                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                                        <input
                                            name="requestType"
                                            type="radio"
                                            value="Suggestion"
                                            id="defaultInline3"
                                            className="custom-control-input"
                                            checked={this.state.selectedOption === 'Suggestion' || this.state.selectedOption === 'اقتراح'}
                                            onChange={this.handleOptionChange}
                                            required
                                        />
                                        <label className="custom-control-label" htmlFor="defaultInline3" style={{ float: this.props.isArabic ? 'right' : '', width: this.props.isArabic ? '20%' : '' }}>{i18n[this.getLocale()].SCI.suggestion}</label>
                                    </MDBCol>


                                </div>
                                {this.state.selectedOptionErr == 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null}
                            </div>

                        </div>
                    </MDBCol> */}

                </MDBRow>
                {
                    this.state.SCIPolicy==='1'  ||    this.state.SCIPolicy==='2'? 
                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
                     <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"></MDBCol>
                     <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                     <div className="form-group">
                            <div className="form-group">
                            <div style={{ float: this.props.isArabic ? '' : 'left', width: '100%', paddingLeft: "10px" }} className="custom-control custom-radio custom-control-inline">
                   
                            <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                              
                         <input
                                            name="requestType1"
                                            type="radio"
                                            value="Legal"
                                            id="defaultInline4"
                                            className="custom-control-input"
                                            checked={this.state.SCIPOlicyOptions === 'Legal' || this.state.SCIPOlicyOptions === 'قانونى'}
                                            onChange={this.handleOptionChange}
                                            required
                                        />
                                        <label className="custom-control-label" htmlFor="defaultInline4" style={{ float: this.props.isArabic ? 'right' : '', width: this.props.isArabic ? '20%' : '' }}>{i18n[this.getLocale()].SCI.SuggestionPolicy}</label>
                         </MDBCol>
                         <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                    <input
                                            name="requestType1"
                                            type="radio"
                                            value="General"
                                            id="defaultInline5"
                                            className="custom-control-input"
                                            checked={this.state.SCIPOlicyOptions === 'General' || this.state.SCIPOlicyOptions === 'عام'}
                                            onChange={this.handleOptionChange}
                                            required
                                        />
                                        <label className="custom-control-label" htmlFor="defaultInline5" style={{ float: this.props.isArabic ? 'right' : '', width: this.props.isArabic ? '20%' : '' }}>{i18n[this.getLocale()].SCI.SuggestionGeneral}</label>
                         </MDBCol>

                         <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                    <input
                                            name="requestType1"
                                            type="radio"
                                            value="workforce"
                                            id="defaultInline6"
                                            className="custom-control-input"
                                            checked={this.state.SCIPOlicyOptions === 'workforce' || this.state.SCIPOlicyOptions === 'القوى العاملة'}
                                            onChange={this.handleOptionChange}
                                            required
                                        />
                                        <label className="custom-control-label" htmlFor="defaultInline6" style={{ float: this.props.isArabic ? 'right' : '', width: this.props.isArabic ? '100%' : '' }}>{i18n[this.getLocale()].SCI.workforce}</label>
                         </MDBCol>
                       
                         </div>                         </div></div>
                         </MDBCol>
                </MDBRow>
                :
                null
                }


                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].entityName}
                                <span className="requireStar">*</span>
                            </label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                disabled={true}
                                type="text"
                                className="form-control"
                                id="applicantsName"
                                name="applicantsName"
                                maxLength={50}
                                value={this.state.applicantsName}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064AA-Za-z ]*$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ applicantsName: event.target.value });
                                        }
                                    }
                                    else {
                                        this.setState({ applicantsName: '' });
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].SCI.applicatorsJob}
                                <span className="requireStar">*</span>
                            </label>
                            <input
                                style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', direction: this.props.isArabic ? "rtl" : 'ltr', borderRadius: '5px' }}
                                type="text"
                                className="form-control"
                                id="applicantsJob"
                                name="applicantsJob"
                                value={this.state.applicantsJob}
                                onChange={(event) => {
                                    if (event.target.value && event.target.value.trim()) {
                                        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ applicantsJob: event.target.value });
                                        }
                                    }
                                    else {
                                        this.setState({ applicantsJob: '' });
                                    }
                                }}
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>

                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].SCI.contactNumber}
                                <span className="requireStar">*</span>
                            </label>

                            <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                value={this.state.contactNumber}
                                onChange={this.handleContactNumberChange}
                                className="form-control"
                                required
                            />
                            {
                                this.state.contactNumberErr == 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.contactNumberErr == 'invalidContactNumber' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].invalidContactNumber}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="Attachedfilesforsupportrequest">{i18n[this.getLocale()].SCI.attachedFiles}</label>

                            <div className="input-group">
                                <div className="custom-file-inp">
                                    <input
                                        id="file"
                                        type="file"
                                        name="officialLetter"
                                        multiple
                                        onChange={(event) => this.officialLetterOnChange(event)}
                                    />
                                    <label htmlFor="file" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].SCI.chooseFile}</label>
                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="file" className="fileName">
                                        <span>
                                        <span>  {this.state.Files.length>0
                              ?this.state.Files.map((item, index) => {
                                return (
                                    "( "+item.filename+" ) "
                                )
                              }
                              )
                              :''
                              }</span>
                                            </span>
                                    </label>
                                </div>
                                {
                                    this.state.officalLetterErr === 'invalidFileType' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.officalLetterErr === 'invalidFileSize' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse" }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                            </p>
                            {
                                (this.state.sciAttachmentArray &&
                                    this.state.sciAttachmentArray.length > 0)
                                    ? <DownloadComponent filesObj={this.state.sciAttachmentArray} />
                                    : <span></span>

                            }
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].SCI.requestDetails}
                                <span className="requireStar">*</span>
                            </label>

                            <div style={{ border: this.state.reqDetailsErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.reqDetails}
                                            events={{
                                                "change": this.onReqDetailsChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }

                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.reqDetails}
                                            events={{
                                                "change": this.onReqDetailsChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.reqDetailsErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.reqDetailsErr === 'ckeditorCountErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].ckeditorCountErr}
                                    </div>
                                    :
                                    null
                            }
                            

                        </div>
                    </MDBCol>
                </MDBRow>


{
this.state.SCIPOlicyOptions === 'Legal'  ? 
<MDBRow style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', alignItems: 'right' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', borderRadius: '5px' }} htmlFor="formGroupExampleInput">
                                {i18n[this.getLocale()].SCI.            EntityLegalOpinon} 

                                <span className={this.state.isArabic ? "requireStar ArabicLegalOpinon":"requireStar LegalOpinon"}>{i18n[this.getLocale()].SCI.EntityLegalOpinonDetials}*</span>
                            </label>

                            <div style={{ border: this.state.EntityLegalOpinonErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                {
                                    this.props.isArabic ?
                                        null
                                        :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.EntityLegalOpinon}
                                            events={{
                                                "change": this.onEntityLegalOpinonChange
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }

                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.EntityLegalOpinon}
                                            events={{
                                                "change": this.onEntityLegalOpinonChange
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.EntityLegalOpinonErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.EntityLegalOpinonErr === 'ckeditorCountErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].ckeditorCountErr}
                                    </div>
                                    :
                                    null
                            }
                            

                        </div>
                    </MDBCol>
                </MDBRow>
: null

}
                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.handlePreViewNavigation}>
                                {i18n[this.getLocale()].preview}
                            </MDBBtn>
                    }
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    <MDBBtn className="submitBtn" type='submit' color="primary">
                        {i18n[this.getLocale()].submit}
                    </MDBBtn>
                </MDBRow>
            </form>
        );
    }
}