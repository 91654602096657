import Config from './../utils/Config';

export const getNotificationPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let notificationPayload = {};
    if (getDataFromLocalStorage) {
        notificationPayload.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        notificationPayload.lang = 1;
    else
        notificationPayload.lang = 0;

    return notificationPayload;
}

export const getCurrentTaskListPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let taskListObject = {};
    if (getDataFromLocalStorage) {
        taskListObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        taskListObject.lang = 1;
    else
        taskListObject.lang = 0;

    return taskListObject;
}

export const getTaskListPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let allTaskListObject = {};
    allTaskListObject.statusid = obj.statusid;
    if (getDataFromLocalStorage) {
        allTaskListObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        allTaskListObject.lang = 1;
    else
        allTaskListObject.lang = 0;

    return allTaskListObject;
}

export const getAllSciRequestForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let allSciRequestForEntityObject = {};
    if (getDataFromLocalStorage) {
        allSciRequestForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
        allSciRequestForEntityObject.Userid = getDataFromLocalStorage.id;
        allSciRequestForEntityObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        allSciRequestForEntityObject.lang = 1;
    else
        allSciRequestForEntityObject.lang = 0;

    return allSciRequestForEntityObject;
}



export const getAllInformationRequestForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getInformationRequestForEntityObject = {};
    if (getDataFromLocalStorage) {
        getInformationRequestForEntityObject.entityid = getDataFromLocalStorage.EntityID;
        getInformationRequestForEntityObject.userid = getDataFromLocalStorage.id;
        getInformationRequestForEntityObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getInformationRequestForEntityObject.lang = 1;
    else
        getInformationRequestForEntityObject.lang = 0;

    return getInformationRequestForEntityObject;
}

export const GetAllhrPoliciesForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getAllhrPoliciesForEntityPayloadObject = {};
    if (getDataFromLocalStorage) {
        getAllhrPoliciesForEntityPayloadObject.entityid = getDataFromLocalStorage.EntityID;
        getAllhrPoliciesForEntityPayloadObject.userid = getDataFromLocalStorage.id;
        getAllhrPoliciesForEntityPayloadObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getAllhrPoliciesForEntityPayloadObject.lang = 1;
    else
        getAllhrPoliciesForEntityPayloadObject.lang = 0;

    return getAllhrPoliciesForEntityPayloadObject;
}

export const GetAllAmendSalaryScaleForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getAllAmendSalaryScaleForEntityObject = {};
    if (getDataFromLocalStorage) {
        getAllAmendSalaryScaleForEntityObject.entityid = getDataFromLocalStorage.EntityID;
        getAllAmendSalaryScaleForEntityObject.userid = getDataFromLocalStorage.id;
        getAllAmendSalaryScaleForEntityObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getAllAmendSalaryScaleForEntityObject.lang = 1;
    else
        getAllAmendSalaryScaleForEntityObject.lang = 0;

    return getAllAmendSalaryScaleForEntityObject;
}

export const GetAllBounsRequestForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getAllBounsRequestForEntityPayloadObject = {};
    if (getDataFromLocalStorage) {
        getAllBounsRequestForEntityPayloadObject.entityid = getDataFromLocalStorage.EntityID;
        getAllBounsRequestForEntityPayloadObject.userid = getDataFromLocalStorage.id;
        getAllBounsRequestForEntityPayloadObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getAllBounsRequestForEntityPayloadObject.lang = 1;
    else
        getAllBounsRequestForEntityPayloadObject.lang = 0;

    return getAllBounsRequestForEntityPayloadObject;
}

export const GetAllAmendEmployeeSalaryForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getAllAmendEmployeeSalaryForEntityPayloadObject = {};
    if (getDataFromLocalStorage) {
        getAllAmendEmployeeSalaryForEntityPayloadObject.entityid = getDataFromLocalStorage.EntityID;
        getAllAmendEmployeeSalaryForEntityPayloadObject.userid = getDataFromLocalStorage.id;
        getAllAmendEmployeeSalaryForEntityPayloadObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getAllAmendEmployeeSalaryForEntityPayloadObject.lang = 1;
    else
        getAllAmendEmployeeSalaryForEntityPayloadObject.lang = 0;

    return getAllAmendEmployeeSalaryForEntityPayloadObject;
}

export const getAllSecondmentForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let allSecondmentForEntityObject = {};
    if (getDataFromLocalStorage) {
        allSecondmentForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
        allSecondmentForEntityObject.Userid = getDataFromLocalStorage.id;
        allSecondmentForEntityObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        allSecondmentForEntityObject.lang = 1;
    else
        allSecondmentForEntityObject.lang = 0;

    return allSecondmentForEntityObject;
}

export const getAllHousingPolicyForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getAllHousingPolicyForEntityObject = {};
    if (getDataFromLocalStorage) {
        getAllHousingPolicyForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
        getAllHousingPolicyForEntityObject.Userid = getDataFromLocalStorage.id;
        getAllHousingPolicyForEntityObject.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getAllHousingPolicyForEntityObject.lang = 1;
    else
        getAllHousingPolicyForEntityObject.lang = 0;

    return getAllHousingPolicyForEntityObject;
}

export const getExceptionAccompanyPatientForEntityPayload = () => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let getExceptionAccompanyPatientForEntityObj = {};
    if (getDataFromLocalStorage) {
        getExceptionAccompanyPatientForEntityObj.EntityID = getDataFromLocalStorage.EntityID;
        getExceptionAccompanyPatientForEntityObj.Userid = getDataFromLocalStorage.id;
        getExceptionAccompanyPatientForEntityObj.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
    }
    if (lan === 'ar')
        getExceptionAccompanyPatientForEntityObj.lang = 1;
    else
        getExceptionAccompanyPatientForEntityObj.lang = 0;

    return getExceptionAccompanyPatientForEntityObj;
}

export const getRequestByPWFIdPayload = (id) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let sciRequestForEntityObject = {};
    sciRequestForEntityObject.RequestID = id;
    if (getDataFromLocalStorage) {
         sciRequestForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
        sciRequestForEntityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        sciRequestForEntityObject.lang = 1;
    else
        sciRequestForEntityObject.lang = 0;

    return sciRequestForEntityObject;
}
export const getRequestByIdPayload = (id) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let sciRequestForEntityObject = {};
    sciRequestForEntityObject.id = id;
    if (getDataFromLocalStorage) {
        // sciRequestForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
        sciRequestForEntityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        sciRequestForEntityObject.lang = 1;
    else
        sciRequestForEntityObject.lang = 0;

    return sciRequestForEntityObject;
}

export const getUnderApprovalPayLoadById = (id,Typeid) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let sciRequestForEntityObject = {};
    sciRequestForEntityObject.id = id;
    sciRequestForEntityObject.Typeid=Typeid;
    if (getDataFromLocalStorage) {
        // sciRequestForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
        sciRequestForEntityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        sciRequestForEntityObject.lang = 1;
    else
        sciRequestForEntityObject.lang = 0;

    return sciRequestForEntityObject;
}

export const getLookupRequestByIdPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let lookupObject = {};
    lookupObject.id = obj.id;
    lookupObject.filtertype = obj.filtertype;
    if (getDataFromLocalStorage) {
        // lookupObject.EntityID = getDataFromLocalStorage.EntityID;
        lookupObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        lookupObject.lang = 1;
    else
        lookupObject.lang = 0;

    return lookupObject;
}
export const saveStrategicPlanningforworkforce = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveSciRequestObject = {};
   
    saveSciRequestObject.RequestID=obj.RequestID;
   saveSciRequestObject.RequesterName = obj.RequesterName;
    saveSciRequestObject.RequesterJobTitle=obj.RequesterJobTitle;
    saveSciRequestObject.RequesterContactNumber=obj.RequesterContactNumber;
    saveSciRequestObject.VacancyAppointmentFormFilesname = obj.VacancyAppointmentFormFilesname;
     saveSciRequestObject.JobangelFormFilesname=obj.JobangelFormFilesname;

     saveSciRequestObject.jobdescriptiontotherequiredvacanciesFilesname=obj.jobdescriptiontotherequiredvacanciesFilesname;
     saveSciRequestObject.VacancyAppointmentFormFilesContent=obj.VacancyAppointmentFormFilesContent;
     saveSciRequestObject.JobangelFormFilesConetent=obj.JobangelFormFilesConetent;
     saveSciRequestObject.jobdescriptiontotherequiredvacanciesFilesContent=obj.jobdescriptiontotherequiredvacanciesFilesContent;
 
    if (getDataFromLocalStorage) {
       
        saveSciRequestObject.CreatedUser = getDataFromLocalStorage.id;
        saveSciRequestObject.EntityID=getDataFromLocalStorage.EntityID;
    }
    if (lan === 'ar')
        saveSciRequestObject.lang = 1;
    else
        saveSciRequestObject.lang = 0;

    return saveSciRequestObject;
}
export const saveSubstitutionPlanPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveSciRequestObject = {};
    //if (obj.isEdit) {
    // if (obj.requestId > 0) {
    //     saveSciRequestObject.id = obj.requestId;
    // }
    // else {
    //     saveSciRequestObject.id = 0;
    // }
    saveSciRequestObject.Quarter=obj.Quarter;
   saveSciRequestObject.filename = obj.filename;
    saveSciRequestObject.filecontent=obj.filecontent;
 
    if (getDataFromLocalStorage) {
       
        saveSciRequestObject.CreatedUser = getDataFromLocalStorage.id;
        saveSciRequestObject.EntityID=getDataFromLocalStorage.EntityID;
    }
    if (lan === 'ar')
        saveSciRequestObject.lang = 1;
    else
        saveSciRequestObject.lang = 0;

    return saveSciRequestObject;
}


export const saveCircularRequestPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveSciRequestObject = {};
    //if (obj.isEdit) {
    // if (obj.requestId > 0) {
    //     saveSciRequestObject.id = obj.requestId;
    // }
    // else {
    //     saveSciRequestObject.id = 0;
    // }
    saveSciRequestObject.Subject=obj.Subject;
    saveSciRequestObject.Description = obj.Description;
    
    saveSciRequestObject.Entites = obj.Entites;

    // saveSciRequestObject.filename = obj.filename;
    // saveSciRequestObject.filecontent = obj.filecontent;
    
    saveSciRequestObject.CircularFiles=obj.Files;
    saveSciRequestObject.draft = obj.draft;
    if (getDataFromLocalStorage) {
        //saveSciRequestObject.EntityID = getDataFromLocalStorage.EntityID;
        saveSciRequestObject.CreatedUser = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveSciRequestObject.lang = 1;
    else
        saveSciRequestObject.lang = 0;

    return saveSciRequestObject;
}


export const saveSciRequestPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveSciRequestObject = {};
    //if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveSciRequestObject.id = obj.requestId;
    }
    else {
        saveSciRequestObject.id = 0;
    }
    saveSciRequestObject.EntityLegalOpinon=obj.EntityLegalOpinon;
    saveSciRequestObject.requesttypeid = obj.requestType;
    saveSciRequestObject.requestType = 4;
    saveSciRequestObject.subject = obj.requestSubject;
    saveSciRequestObject.details = obj.reqDetails;
    saveSciRequestObject.applicantname = obj.applicantsName;
    saveSciRequestObject.applicantjob = obj.applicantsJob;
    saveSciRequestObject.applicantcontactnumber = obj.contactNumber;
    saveSciRequestObject.HRAdepartment = 0;
    saveSciRequestObject.draft = obj.draft;
    if (getDataFromLocalStorage) {
        saveSciRequestObject.EntityID = getDataFromLocalStorage.EntityID;
        saveSciRequestObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveSciRequestObject.lang = 1;
    else
        saveSciRequestObject.lang = 0;

    return saveSciRequestObject;
}

export const saveInformationRequestPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveInformationRequestObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveInformationRequestObject.id = obj.requestId;
    } else {
        saveInformationRequestObject.id = 0;
    }
    if (getDataFromLocalStorage) {
        saveInformationRequestObject.EntityID = getDataFromLocalStorage.EntityID;
        saveInformationRequestObject.Userid = getDataFromLocalStorage.id;
    }
    saveInformationRequestObject.requestorname = obj.requestorname;
    saveInformationRequestObject.contactnumber = obj.contactnumber;
    saveInformationRequestObject.informationrequired = obj.informationrequired;
    saveInformationRequestObject.assignedentity = obj.assignedentity;
    saveInformationRequestObject.requesttype = obj.requesttype;
    //saveInformationRequestObject.requestType = 7;
    saveInformationRequestObject.datettoreply = obj.datettoreply;
    saveInformationRequestObject.statement = obj.statement;
    saveInformationRequestObject.draft = obj.draft;
    if (lan === 'ar')
        saveInformationRequestObject.lang = 1;
    else
        saveInformationRequestObject.lang = 0;

    return saveInformationRequestObject;
}

export const saveHousingPolicyPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveHousingPolicyObj = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveHousingPolicyObj.id = obj.requestId;
    } else {
        saveHousingPolicyObj.id = 0;
    }

    if (getDataFromLocalStorage) {
        saveHousingPolicyObj.EntityID = getDataFromLocalStorage.EntityID;
        saveHousingPolicyObj.Userid = getDataFromLocalStorage.id;
    }
    saveHousingPolicyObj.employeename = obj.EmployeeName;
    saveHousingPolicyObj.entityname = obj.EntityName;
    saveHousingPolicyObj.basicsalary = obj.BasicSalary;
    saveHousingPolicyObj.jobname = obj.JobName;
    saveHousingPolicyObj.financialcost = obj.FinancialCost;
    saveHousingPolicyObj.totalsalary = obj.TotalSalary;
    saveHousingPolicyObj.employeenumber = obj.EmployeeNumber;
    saveHousingPolicyObj.housingallowance = obj.HousingAllowance;
    saveHousingPolicyObj.contractperiod = obj.contractPeriod;
    //saveHousingPolicyObj.jobTitle = obj.jobTitle;
    saveHousingPolicyObj.retirementcontribution = obj.RetirementContributions;
    saveHousingPolicyObj.requestreason = obj.ReasonsOfRequest;
    saveHousingPolicyObj.contracttypeid = obj.typeOfContract;
    saveHousingPolicyObj.recommendation = obj.requiredRecomandation;
    saveHousingPolicyObj.emiratesid = obj.emiratesid;
    saveHousingPolicyObj.unifiednumber = obj.unifiednumber;
    saveHousingPolicyObj.draft = obj.draft;
    if (lan === 'ar')
        saveHousingPolicyObj.lang = 1;
    else
        saveHousingPolicyObj.lang = 0;

    return saveHousingPolicyObj;
}

export const saveBonusRequestPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveBonusRequestObj = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveBonusRequestObj.id = obj.requestId;
    } else {
        saveBonusRequestObj.id = 0;
    }
    saveBonusRequestObj.entityname = obj.entityname;
    saveBonusRequestObj.jobname = obj.jobname;
    saveBonusRequestObj.coordinatorname = obj.coordinatorname;
    saveBonusRequestObj.email = obj.email;
    saveBonusRequestObj.contactnumber = obj.contactnumber;
    saveBonusRequestObj.contactNumberMobile = obj.contactNumberMobile;
    saveBonusRequestObj.isbudgeted = obj.isbudgeted;
    saveBonusRequestObj.budget = obj.budget;
    saveBonusRequestObj.yearachievment = obj.yearachievment;
    saveBonusRequestObj.bonusreason = obj.bonusreason;
    saveBonusRequestObj.year1 = obj.year1;
    saveBonusRequestObj.emiratizationpercentage = obj.emiratizationpercentage;
    saveBonusRequestObj.totalemployeedetails = obj.totalemployeedetails;
    saveBonusRequestObj.year2 = obj.year2;
    saveBonusRequestObj.iscontracted = obj.iscontracted;
    saveBonusRequestObj.totalemployees = obj.totalemployees;
    saveBonusRequestObj.equation = obj.equation;
    saveBonusRequestObj.gradeemployees = obj.gradeemployees;
    saveBonusRequestObj.emiratizationpercentage2 = obj.emiratizationpercentage2;
    saveBonusRequestObj.nonlocalwithprivate = obj.nonlocalwithprivate;
    saveBonusRequestObj.localwithprivate = obj.localwithprivate;
    saveBonusRequestObj.nonlocalwithpermanent = obj.nonlocalwithpermanent;
    saveBonusRequestObj.localwithpermanent = obj.localwithpermanent;
    saveBonusRequestObj.excellentemployees = obj.excellentemployees;
    saveBonusRequestObj.verygoodemployees = obj.verygoodemployees;
    saveBonusRequestObj.goodemployees = obj.goodemployees;
    saveBonusRequestObj.acceptableemployees = obj.acceptableemployees;
    saveBonusRequestObj.weakemployees = obj.weakemployees;
    saveBonusRequestObj.isfixedvaluebonus = obj.isfixedvaluebonus;
    saveBonusRequestObj.budgetyear = obj.budgetyear;
    saveBonusRequestObj.phonenumber = obj.phonenumber;
    saveBonusRequestObj.subcoordinatorname = obj.subcoordinatorname;
    saveBonusRequestObj.subjobname = obj.subjobname;
    saveBonusRequestObj.subcontactnumber = obj.subcontactnumber;
    saveBonusRequestObj.subphonenumber = obj.subphonenumber;
    saveBonusRequestObj.subemail = obj.subemail;
    saveBonusRequestObj.draft = obj.draft;
    if (getDataFromLocalStorage) {
        saveBonusRequestObj.EntityID = getDataFromLocalStorage.EntityID;
        saveBonusRequestObj.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveBonusRequestObj.lang = 1;
    else
        saveBonusRequestObj.lang = 0;

    return saveBonusRequestObj;
}

export const saveSecondmentRequestPayload = (obj) => {
    // get language
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveSecondmentObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveSecondmentObject.id = obj.requestId;
    } else {
        saveSecondmentObject.id = 0;
    }
    saveSecondmentObject.secondmententityname = obj.secondmententityname;
    saveSecondmentObject.employeenumber = obj.employeenumber;
    saveSecondmentObject.jobname = obj.jobname;
    saveSecondmentObject.employeename = obj.employeename;
    saveSecondmentObject.basicsalary = obj.basicsalary;
    saveSecondmentObject.totalsalary = obj.totalsalary;
    saveSecondmentObject.retirementcontribution = obj.retirementcontribution;
    saveSecondmentObject.secondmentfrom = obj.secondmentfrom;
    saveSecondmentObject.secondmentto = obj.secondmentto;
    saveSecondmentObject.salaryownerid = obj.salaryownerid;
    saveSecondmentObject.unifiednumber = obj.unifiedNumber;
    saveSecondmentObject.familynumber = obj.familyNumber;
    saveSecondmentObject.emiratesid = obj.emiratesid;
    saveSecondmentObject.passtestperiod = obj.empPassNoticePeriod;
    saveSecondmentObject.performancedegree = obj.lastYearPerformanceEfficiency;
    saveSecondmentObject.employeeagreement = obj.employeeAgreement;
    saveSecondmentObject.draft = obj.draft;
    saveSecondmentObject.reasonForGovPaySalary=obj.reasonForGovPaySalary;
    saveSecondmentObject.BorrowEntityID=obj.BorrowEntityID;
    if (getDataFromLocalStorage) {
        saveSecondmentObject.EntityID = getDataFromLocalStorage.EntityID;
        saveSecondmentObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveSecondmentObject.lang = 1;
    else
        saveSecondmentObject.lang = 0;

    return saveSecondmentObject;
}

export const saveAmendSalaryScalePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveAmendSalaryScaleObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveAmendSalaryScaleObject.id = obj.requestId;
    } else {
        saveAmendSalaryScaleObject.id = 0;
    }
    saveAmendSalaryScaleObject.justification = obj.justification;
    saveAmendSalaryScaleObject.principle = obj.principle;
    saveAmendSalaryScaleObject.fundingid = obj.fundingid;
    saveAmendSalaryScaleObject.isallocated = obj.isallocated;
    saveAmendSalaryScaleObject.budgetapprovalamount = obj.budgetapprovalamount;
    // saveAmendSalaryScaleObject.currentbasicsalary = obj.currentbasicsalary;
    // saveAmendSalaryScaleObject.proposedbasicsalary = obj.proposedbasicsalary;
    // saveAmendSalaryScaleObject.currentannualbenefits = obj.currentannualbenefits;
    // saveAmendSalaryScaleObject.proposedannualbenefits = obj.proposedannualbenefits;
    saveAmendSalaryScaleObject.currentbasicsalary = "0";
    saveAmendSalaryScaleObject.proposedbasicsalary = "0";
    saveAmendSalaryScaleObject.currentannualbenefits = "0";
    saveAmendSalaryScaleObject.proposedannualbenefits = "0";
    saveAmendSalaryScaleObject.currentfinancialeffect = obj.currentfinancialeffect;
    saveAmendSalaryScaleObject.proposedfinancialeffect = obj.proposedfinancialeffect;
    saveAmendSalaryScaleObject.currentscalechallenge = obj.currentscalechallenge;
    saveAmendSalaryScaleObject.proposedscalechallenge = obj.proposedscalechallenge;
    saveAmendSalaryScaleObject.currentincreaeaverage = obj.currentincreaeaverage;
    saveAmendSalaryScaleObject.currentdecreaseaverage = obj.currentdecreaseaverage;
    saveAmendSalaryScaleObject.currentapplyeffect = obj.currentapplyeffect;
    saveAmendSalaryScaleObject.proposedapplyeffect = obj.proposedappleffect;
    saveAmendSalaryScaleObject.draft = obj.draft;
    if (getDataFromLocalStorage) {
        saveAmendSalaryScaleObject.EntityID = getDataFromLocalStorage.EntityID;
        saveAmendSalaryScaleObject.Userid = getDataFromLocalStorage.id;
    }

    if (lan === 'ar')
        saveAmendSalaryScaleObject.lang = 1;
    else
        saveAmendSalaryScaleObject.lang = 0;

    return saveAmendSalaryScaleObject;
}

export const saveAmendSalaryScaleMatrixPayload = (obj) => {
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveAmendSalaryScaleMatrixObject = {};
    saveAmendSalaryScaleMatrixObject.id = 0;
    saveAmendSalaryScaleMatrixObject.refid = obj.refid;
    saveAmendSalaryScaleMatrixObject.currentmonthlybonus = obj.currentmonthlybonus;
    saveAmendSalaryScaleMatrixObject.proposedmonthlybonus = obj.proposedmonthlybonus;
    saveAmendSalaryScaleMatrixObject.currentannualbenefits = obj.currentannualbenefits;
    saveAmendSalaryScaleMatrixObject.proposedannualbenefits = obj.proposedannualbenefits;
    saveAmendSalaryScaleMatrixObject.otherbonus = obj.otherbonus;
    saveAmendSalaryScaleMatrixObject.otherbenefits = obj.otherbenefits;
    if (getDataFromLocalStorage) {
        saveAmendSalaryScaleMatrixObject.Userid = getDataFromLocalStorage.id;
    }

    return saveAmendSalaryScaleMatrixObject;
}

export const saveHrPoliciesPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveHrPoliciesObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveHrPoliciesObject.id = obj.requestId;
    } else {
        saveHrPoliciesObject.id = 0;
    }
    saveHrPoliciesObject.requestreason = obj.requestreason;
    saveHrPoliciesObject.draft = obj.draft;
    if (getDataFromLocalStorage) {
        saveHrPoliciesObject.EntityID = getDataFromLocalStorage.EntityID;
        saveHrPoliciesObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveHrPoliciesObject.lang = 1;
    else
        saveHrPoliciesObject.lang = 0;

    return saveHrPoliciesObject;
}

export const saveAmendEmployeeSalaryPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let SaveamendemployeesalaryObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        SaveamendemployeesalaryObject.id = obj.requestId;
    } else {
        SaveamendemployeesalaryObject.id = 0;
    }
    SaveamendemployeesalaryObject.draft = obj.draft;
    SaveamendemployeesalaryObject.employeename = obj.employeename;
    SaveamendemployeesalaryObject.nationality = obj.nationality;
    SaveamendemployeesalaryObject.age = obj.age;
    SaveamendemployeesalaryObject.currentbasicsalary = obj.currentbasicsalary;
    SaveamendemployeesalaryObject.proposedbasicsalary = obj.proposedbasicsalary;
    SaveamendemployeesalaryObject.percentageincrease = obj.percentageincrease;
    SaveamendemployeesalaryObject.designationdate = obj.designationdate;
    SaveamendemployeesalaryObject.qualification = obj.qualification;
    SaveamendemployeesalaryObject.exprienceafterqualification = obj.exprienceafterqualification;
    SaveamendemployeesalaryObject.totalexpeirence = obj.totalexpeirence;
    SaveamendemployeesalaryObject.annualprevevaluation = obj.annualprevevaluation;
    SaveamendemployeesalaryObject.annualcurrevaluation = obj.annualcurrevaluation;
    SaveamendemployeesalaryObject.jobtitle = obj.jobtitle;
    SaveamendemployeesalaryObject.grade = obj.grade;
    SaveamendemployeesalaryObject.jobrank = obj.jobrank;
    SaveamendemployeesalaryObject.proceduretype = obj.proceduretype;
    SaveamendemployeesalaryObject.financialratio = obj.financialratio;
    SaveamendemployeesalaryObject.procedureDate = obj.procedureDate;
    SaveamendemployeesalaryObject.reasontomodify = obj.reasontomodify;
    SaveamendemployeesalaryObject.baseofincrease = obj.baseofincrease;
    if (getDataFromLocalStorage) {
        SaveamendemployeesalaryObject.entityID = getDataFromLocalStorage.EntityID;
        SaveamendemployeesalaryObject.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        SaveamendemployeesalaryObject.lang = 1;
    else
        SaveamendemployeesalaryObject.lang = 0;

    return SaveamendemployeesalaryObject;
}

// data services payload
// extend create objective
export const saveExtendCreateObjectivePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveExtendCreateObjectiveObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveExtendCreateObjectiveObject.id = obj.requestId;
    } else {
        saveExtendCreateObjectiveObject.id = 0;
    }
    saveExtendCreateObjectiveObject.draft = obj.draft;
    // saveExtendCreateObjectiveObject.draft = 0;
    saveExtendCreateObjectiveObject.requesttitle = obj.requesttitle;
    saveExtendCreateObjectiveObject.email = obj.email;
    saveExtendCreateObjectiveObject.requestdate = obj.requestdate;
    saveExtendCreateObjectiveObject.fromdate = obj.fromdate;
    saveExtendCreateObjectiveObject.todate = obj.todate;
    saveExtendCreateObjectiveObject.employeename = obj.employeename;
    saveExtendCreateObjectiveObject.contactnumber = obj.contactnumber;
    saveExtendCreateObjectiveObject.requestdetails = obj.requestdetails;
    saveExtendCreateObjectiveObject.jobtitle = obj.jobdescription;
    if (getDataFromLocalStorage) {
        saveExtendCreateObjectiveObject.entityid = getDataFromLocalStorage.EntityID;
        saveExtendCreateObjectiveObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveExtendCreateObjectiveObject.lang = 1;
    else
        saveExtendCreateObjectiveObject.lang = 0;

    return saveExtendCreateObjectiveObject;
}

// extend review objective
export const saveExtendReviewObjectivePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveExtendReviewObjectiveObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveExtendReviewObjectiveObject.id = obj.requestId;
    } else {
        saveExtendReviewObjectiveObject.id = 0;
    }
    saveExtendReviewObjectiveObject.draft = obj.draft;
    // saveExtendReviewObjectiveObject.draft = 0;
    saveExtendReviewObjectiveObject.requesttitle = obj.requesttitle;
    saveExtendReviewObjectiveObject.email = obj.email;
    saveExtendReviewObjectiveObject.requestdate = obj.requestdate;
    saveExtendReviewObjectiveObject.fromdate = obj.fromdate;
    saveExtendReviewObjectiveObject.todate = obj.todate;
    saveExtendReviewObjectiveObject.employeename = obj.employeename;
    saveExtendReviewObjectiveObject.contactnumber = obj.contactnumber;
    saveExtendReviewObjectiveObject.requestdetails = obj.requestdetails;
    saveExtendReviewObjectiveObject.jobtitle = obj.jobdescription;
    if (getDataFromLocalStorage) {
        saveExtendReviewObjectiveObject.entityid = getDataFromLocalStorage.EntityID;
        saveExtendReviewObjectiveObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveExtendReviewObjectiveObject.lang = 1;
    else
        saveExtendReviewObjectiveObject.lang = 0;

    return saveExtendReviewObjectiveObject;
}

// extend annual performance evaluation
export const saveExtendAnnualPerformanceEvaluationPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveExtendAnnualPerformanceEvaluationObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveExtendAnnualPerformanceEvaluationObject.id = obj.requestId;
    } else {
        saveExtendAnnualPerformanceEvaluationObject.id = 0;
    }
    saveExtendAnnualPerformanceEvaluationObject.draft = obj.draft;
    // saveExtendAnnualPerformanceEvaluationObject.draft = 0;
    // saveExtendAnnualPerformanceEvaluationObject.entityname = obj.entityname;
    saveExtendAnnualPerformanceEvaluationObject.requesttitle = obj.requesttitle;
    saveExtendAnnualPerformanceEvaluationObject.email = obj.email;
    saveExtendAnnualPerformanceEvaluationObject.requestdate = obj.requestdate;
    saveExtendAnnualPerformanceEvaluationObject.fromdate = obj.fromdate;
    saveExtendAnnualPerformanceEvaluationObject.todate = obj.todate;
    saveExtendAnnualPerformanceEvaluationObject.employeename = obj.employeename;
    saveExtendAnnualPerformanceEvaluationObject.contactnumber = obj.contactnumber;
    saveExtendAnnualPerformanceEvaluationObject.requestdetails = obj.requestdetails;
    saveExtendAnnualPerformanceEvaluationObject.jobtitle = obj.jobdescription;
    if (getDataFromLocalStorage) {
        saveExtendAnnualPerformanceEvaluationObject.entityid = getDataFromLocalStorage.EntityID;
        saveExtendAnnualPerformanceEvaluationObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveExtendAnnualPerformanceEvaluationObject.lang = 1;
    else
        saveExtendAnnualPerformanceEvaluationObject.lang = 0;

    return saveExtendAnnualPerformanceEvaluationObject;
}

// extend add and edit employee performance evaluation
export const saveAddEditEmployeePerformanceEvaluationPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveAddAndEditEmployeePerformanceEvaluationObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveAddAndEditEmployeePerformanceEvaluationObject.id = obj.requestId;
    } else {
        saveAddAndEditEmployeePerformanceEvaluationObject.id = 0;
    }
    saveAddAndEditEmployeePerformanceEvaluationObject.draft = obj.draft;
    // saveAddAndEditEmployeePerformanceEvaluationObject.draft = 0;
    //saveAddAndEditEmployeePerformanceEvaluationObject.entityname = obj.entityname;
    saveAddAndEditEmployeePerformanceEvaluationObject.requesttitle = obj.requesttitle;
    saveAddAndEditEmployeePerformanceEvaluationObject.email = obj.email;
    saveAddAndEditEmployeePerformanceEvaluationObject.requestdate = obj.requestdate;
    saveAddAndEditEmployeePerformanceEvaluationObject.employeename = obj.employeename;
    saveAddAndEditEmployeePerformanceEvaluationObject.contactnumber = obj.contactnumber;
    saveAddAndEditEmployeePerformanceEvaluationObject.requestdetails = obj.requestdetails;
    saveAddAndEditEmployeePerformanceEvaluationObject.jobtitle = obj.jobdescription;
    if (getDataFromLocalStorage) {
        saveAddAndEditEmployeePerformanceEvaluationObject.entityid = getDataFromLocalStorage.EntityID;
        saveAddAndEditEmployeePerformanceEvaluationObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveAddAndEditEmployeePerformanceEvaluationObject.lang = 1;
    else
        saveAddAndEditEmployeePerformanceEvaluationObject.lang = 0;

    return saveAddAndEditEmployeePerformanceEvaluationObject;
}

// extend join and implement goverment resources system for new entity
export const saveJoinAndImplementGovermentResourcesSystemForNewEntityPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveJoinAndImplementGovermentResourcesSystemForNewEntityObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.id = obj.requestId;
    } else {
        saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.id = 0;
    }
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.draft = obj.draft;
    // saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.draft = 0;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.requesttitle = obj.requesttitle;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.email = obj.email;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.systemtype = obj.systemtype;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.requestdate = obj.requestdate;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.employeename = obj.employeename;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.contactnumber = obj.contactnumber;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.requestdetails = obj.requestdetails;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.jobtitle = obj.jobdescription;
    saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.systemlist = obj.systemlist;
    if (getDataFromLocalStorage) {
        saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.entityid = getDataFromLocalStorage.EntityID;
        saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.lang = 1;
    else
        saveJoinAndImplementGovermentResourcesSystemForNewEntityObject.lang = 0;

    return saveJoinAndImplementGovermentResourcesSystemForNewEntityObject;
}

// extend open complains stage
export const saveOpenComplainsStagePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveOpenComplainsStageObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveOpenComplainsStageObject.id = obj.requestId;
    } else {
        saveOpenComplainsStageObject.id = 0;
    }
    saveOpenComplainsStageObject.draft = obj.draft;
    // saveOpenComplainsStageObject.draft = 0;
    saveOpenComplainsStageObject.requesttitle = obj.requesttitle;
    saveOpenComplainsStageObject.email = obj.email;
    saveOpenComplainsStageObject.requestdate = obj.requestdate;
    saveOpenComplainsStageObject.employeename = obj.employeename;
    saveOpenComplainsStageObject.contactnumber = obj.contactnumber;
    saveOpenComplainsStageObject.requestdetails = obj.requestdetails;
    saveOpenComplainsStageObject.jobtitle = obj.jobdescription;
    saveOpenComplainsStageObject.fromdate = obj.fromdate;
    saveOpenComplainsStageObject.todate = obj.todate;
    if (getDataFromLocalStorage) {
        saveOpenComplainsStageObject.entityid = getDataFromLocalStorage.EntityID;
        saveOpenComplainsStageObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveOpenComplainsStageObject.lang = 1;
    else
        saveOpenComplainsStageObject.lang = 0;

    return saveOpenComplainsStageObject;
}

// extend amend shared goverment hr management system
export const saveAmendSharedGovermentHrManagementPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveAmendSharedGovermentHrManagementSystemObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveAmendSharedGovermentHrManagementSystemObject.id = obj.requestId;
    } else {
        saveAmendSharedGovermentHrManagementSystemObject.id = 0;
    }
    saveAmendSharedGovermentHrManagementSystemObject.draft = obj.draft;
    // saveAmendSharedGovermentHrManagementSystemObject.draft = 0;
    saveAmendSharedGovermentHrManagementSystemObject.requesttitle = obj.requesttitle;
    saveAmendSharedGovermentHrManagementSystemObject.email = obj.email;
    saveAmendSharedGovermentHrManagementSystemObject.requestdate = obj.requestdate;
    saveAmendSharedGovermentHrManagementSystemObject.systemtype = obj.systemtype;
    saveAmendSharedGovermentHrManagementSystemObject.employeename = obj.employeename;
    saveAmendSharedGovermentHrManagementSystemObject.contactnumber = obj.contactnumber;
    saveAmendSharedGovermentHrManagementSystemObject.requestdetails = obj.requestdetails;
    saveAmendSharedGovermentHrManagementSystemObject.jobtitle = obj.jobdescription;
    saveAmendSharedGovermentHrManagementSystemObject.systemlist = obj.systemlist;
    if (getDataFromLocalStorage) {
        saveAmendSharedGovermentHrManagementSystemObject.entityid = getDataFromLocalStorage.EntityID;
        saveAmendSharedGovermentHrManagementSystemObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveAmendSharedGovermentHrManagementSystemObject.lang = 1;
    else
        saveAmendSharedGovermentHrManagementSystemObject.lang = 0;

    return saveAmendSharedGovermentHrManagementSystemObject;
}

// extend report and static request
export const saveReportAndStaticPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveReportAndStaticObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveReportAndStaticObject.id = obj.requestId;
    } else {
        saveReportAndStaticObject.id = 0;
    }
    saveReportAndStaticObject.draft = obj.draft;
    // saveReportAndStaticObject.draft = 0;
    saveReportAndStaticObject.requesttitle = obj.requesttitle;
    saveReportAndStaticObject.email = obj.email;
    saveReportAndStaticObject.requestdate = obj.requestdate;
    saveReportAndStaticObject.employeename = obj.employeename;
    saveReportAndStaticObject.contactnumber = obj.contactnumber;
    saveReportAndStaticObject.requestdetails = obj.requestdetails;
    saveReportAndStaticObject.jobtitle = obj.jobdescription;
    if (getDataFromLocalStorage) {
        saveReportAndStaticObject.entityid = getDataFromLocalStorage.EntityID;
        saveReportAndStaticObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveReportAndStaticObject.lang = 1;
    else
        saveReportAndStaticObject.lang = 0;

    return saveReportAndStaticObject;
}

// extend other request
export const saveOtherRequestPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveOtherRequestObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveOtherRequestObject.id = obj.requestId;
    } else {
        saveOtherRequestObject.id = 0;
    }
    saveOtherRequestObject.draft = obj.draft;
    // saveOtherRequestObject.draft = 0;
    saveOtherRequestObject.requesttitle = obj.requesttitle;
    saveOtherRequestObject.email = obj.email;
    saveOtherRequestObject.requestdate = obj.requestdate;
    saveOtherRequestObject.employeename = obj.employeename;
    saveOtherRequestObject.contactnumber = obj.contactnumber;
    saveOtherRequestObject.requestdetails = obj.requestdetails;
    saveOtherRequestObject.jobtitle = obj.jobdescription;
    if (getDataFromLocalStorage) {
        saveOtherRequestObject.entityid = getDataFromLocalStorage.EntityID;
        saveOtherRequestObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveOtherRequestObject.lang = 1;
    else
        saveOtherRequestObject.lang = 0;

    return saveOtherRequestObject;
}

export const saveStatusPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveStatusPayload = {};
    // let pathName = localStorage.getItem('currentPath');
    // if (pathName) {
    //     if (pathName === 'SCIRequest') {
    //         saveStatusPayload.requesttype = Config.getSciRequestTypeId()
    //     } else if (pathName === 'informationRequest') {
    //         saveStatusPayload.requesttype = Config.getInformationRequestTypeId()
    //     } else if (pathName === 'bonusRequest') {
    //         saveStatusPayload.requesttype = Config.getBonusRequestTypeId()
    //     } else if (pathName === 'amendSalaryScreen') {
    //         saveStatusPayload.requesttype = Config.getAmendSalaryScaleRequestTypeId()
    //     } else if (pathName === 'amendEmployeeSalary') {
    //         saveStatusPayload.requesttype = Config.getAmendEmployeeSalaryRequestTypeId()
    //     } else if (pathName === 'reviewHRPolicies') {
    //         saveStatusPayload.requesttype = Config.getHrReviewPolicyRequestTypeId()
    //     } else if (pathName === 'exceptionADHousing') {
    //         saveStatusPayload.requesttype = Config.getExceptionADHousingRequestTypeId()
    //     } else if (pathName === 'secondmentForFederalAgency') {
    //         saveStatusPayload.requesttype = Config.getSecondmentForFederalAgencyRequestTypeId()
    //     } else if (pathName === 'exemptionFromAccompanyPatient') {
    //         saveStatusPayload.requesttype = Config.getExceptionAcompanypatientRequestTypeId()
    //     } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
    //         saveStatusPayload.requesttype = Config.getRetirmentRequestTypeId()
    //     } 
    // }
    saveStatusPayload.requestid = obj.requestid;
    saveStatusPayload.requestnumber = obj.requestnumber;
    saveStatusPayload.requesttype = obj.requesttype;
    saveStatusPayload.statusid = obj.statusid;
    saveStatusPayload.reasonid = obj.reasonid;
    saveStatusPayload.comment = obj.comment;
    if (getDataFromLocalStorage) {
        saveStatusPayload.entityID = getDataFromLocalStorage.EntityID;
        saveStatusPayload.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveStatusPayload.lang = 1;
    else
        saveStatusPayload.lang = 0;

    return saveStatusPayload;
}

export const saveCommentPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveCommentPayload = {};
    saveCommentPayload.requestid = obj.id;
    saveCommentPayload.requesttype = obj.requesttype;
    saveCommentPayload.isEditComment=obj.isEditComment;
    if (getDataFromLocalStorage) {
        saveCommentPayload.entityID = getDataFromLocalStorage.EntityID;
        saveCommentPayload.userid = getDataFromLocalStorage.id;
    }
    saveCommentPayload.notes = obj.notes;
    if (lan === 'ar')
        saveCommentPayload.lang = 1;
    else
        saveCommentPayload.lang = 0;

    return saveCommentPayload;
}

export const saveExemptionFromAccompanyPatient = (obj) => {
   
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveExemptionFromAccompanyPatientObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveExemptionFromAccompanyPatientObject.id = obj.requestId;
    } else {
        saveExemptionFromAccompanyPatientObject.id = 0;
    }
    saveExemptionFromAccompanyPatientObject.draft = obj.draft;
    //saveExemptionFromAccompanyPatientObject.requesttype = obj.requesttype;
    saveExemptionFromAccompanyPatientObject.requesttype = obj.requesttype;
    saveExemptionFromAccompanyPatientObject.employeename = obj.employeeName;
    saveExemptionFromAccompanyPatientObject.jobtitle = obj.jobtitle;
    saveExemptionFromAccompanyPatientObject.grade = obj.grade;
    saveExemptionFromAccompanyPatientObject.basicsalary = obj.basicsalary;
    saveExemptionFromAccompanyPatientObject.totalsalary = obj.totalsalary;
    saveExemptionFromAccompanyPatientObject.unifiednumber = obj.unifiedNumber;
    saveExemptionFromAccompanyPatientObject.requestheadline = obj.headlinesOfRequest;
    saveExemptionFromAccompanyPatientObject.retirementcontribution = obj.retirementcontribution;
    saveExemptionFromAccompanyPatientObject.durationofleave = obj.durationofleave;
    saveExemptionFromAccompanyPatientObject.dateofsalarytermination = obj.dateofsalarytermination;
    saveExemptionFromAccompanyPatientObject.isdocumentofobligatory = obj.isdocumentofobligatory;
    saveExemptionFromAccompanyPatientObject.budgetyear = obj.budgetyear;
    saveExemptionFromAccompanyPatientObject.budgetvalue = obj.budgetvalue;
    saveExemptionFromAccompanyPatientObject.dateofwork = obj.dateofwork;
    saveExemptionFromAccompanyPatientObject.budgetavailableyear = obj.budgetavailableyear;
    saveExemptionFromAccompanyPatientObject.isrequriedtopaybybudget = obj.isrequriedtopaybybudget;
    saveExemptionFromAccompanyPatientObject.dateofstarting = obj.dateofstarting;
    saveExemptionFromAccompanyPatientObject.annualleavebalance = obj.annualleavebalance;
    saveExemptionFromAccompanyPatientObject.justification = obj.justification;
    saveExemptionFromAccompanyPatientObject.husbandwifework = obj.husbandwifework;
    saveExemptionFromAccompanyPatientObject.numberofchidren = obj.numberofchidren;
    saveExemptionFromAccompanyPatientObject.placeofchildren = obj.placeofchildren;
    saveExemptionFromAccompanyPatientObject.emiratesid = obj.emiratesid;
    saveExemptionFromAccompanyPatientObject.Degreeofrelationship=obj.Degreeofrelationship;
    if(obj.selectedOption2=="Yes")
    {
    saveExemptionFromAccompanyPatientObject.selectedOption2=1;
    }
    else

    {
        saveExemptionFromAccompanyPatientObject.selectedOption2=0;
    }
    saveExemptionFromAccompanyPatientObject.workyear = "test";
    saveExemptionFromAccompanyPatientObject.worksalarystatus = "test";
    saveExemptionFromAccompanyPatientObject.workmonth = "test";
    saveExemptionFromAccompanyPatientObject.worktotalsalary = "test";
    saveExemptionFromAccompanyPatientObject.worknoofdays = "test";
    saveExemptionFromAccompanyPatientObject.workbasicsalary = "test";

    saveExemptionFromAccompanyPatientObject.worktotaldays = obj.worktotaldays;
    saveExemptionFromAccompanyPatientObject.worktotalsalarytobepaid = obj.worktotalsalarytobepaid;

    saveExemptionFromAccompanyPatientObject.worktobeexempted = obj.worktobeexempted;
    saveExemptionFromAccompanyPatientObject.howtocalculateid = '1';
    saveExemptionFromAccompanyPatientObject.howtocalculaterule = obj.howtocalculaterule;
    saveExemptionFromAccompanyPatientObject.isviolation = obj.violation;
    saveExemptionFromAccompanyPatientObject.violationdetail = obj.explain;

    saveExemptionFromAccompanyPatientObject.basicsalary2 = obj.basicsalary2;
    saveExemptionFromAccompanyPatientObject.totalsalarywithsubscription = obj.totalsalarywithsubscription;
    saveExemptionFromAccompanyPatientObject.retirement = obj.retirement;
    saveExemptionFromAccompanyPatientObject.totalsalary2 = obj.totalsalary2;
    saveExemptionFromAccompanyPatientObject.totalamount = obj.totalamount;
    saveExemptionFromAccompanyPatientObject.totaldays = obj.totaldays;

    if (getDataFromLocalStorage) {
        saveExemptionFromAccompanyPatientObject.entityID = getDataFromLocalStorage.EntityID;
        saveExemptionFromAccompanyPatientObject.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveExemptionFromAccompanyPatientObject.lang = 1;
    else
        saveExemptionFromAccompanyPatientObject.lang = 0;

    return saveExemptionFromAccompanyPatientObject;
}


export const saveSickLeave = (obj) => {

    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveExemptionFromAccompanyPatientObject = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveExemptionFromAccompanyPatientObject.id = obj.requestId;
    } else {
        saveExemptionFromAccompanyPatientObject.id = 0;
    }
    saveExemptionFromAccompanyPatientObject.draft = obj.draft;
    //saveExemptionFromAccompanyPatientObject.requesttype = obj.requesttype;
    //saveExemptionFromAccompanyPatientObject.requesttype = obj.requesttype;
    saveExemptionFromAccompanyPatientObject.employeename = obj.employeeName;
    saveExemptionFromAccompanyPatientObject.jobtitle = obj.jobtitle;
    saveExemptionFromAccompanyPatientObject.grade = obj.grade;
    saveExemptionFromAccompanyPatientObject.basicsalary = obj.basicsalary;
    saveExemptionFromAccompanyPatientObject.totalsalary = obj.totalsalary;
    saveExemptionFromAccompanyPatientObject.unifiednumber = obj.unifiedNumber;
    saveExemptionFromAccompanyPatientObject.requestheadline = obj.headlinesOfRequest;
    saveExemptionFromAccompanyPatientObject.retirementcontribution = obj.retirementcontribution;
    saveExemptionFromAccompanyPatientObject.durationofleave = obj.durationofleave;
    saveExemptionFromAccompanyPatientObject.dateofsalarytermination = obj.dateofsalarytermination;
    saveExemptionFromAccompanyPatientObject.isdocumentofobligatory = obj.isdocumentofobligatory;
    saveExemptionFromAccompanyPatientObject.budgetyear = obj.budgetyear;
    saveExemptionFromAccompanyPatientObject.budgetvalue = obj.budgetvalue;
    saveExemptionFromAccompanyPatientObject.dateofwork = obj.dateofwork;
    saveExemptionFromAccompanyPatientObject.budgetavailableyear = obj.budgetavailableyear;
    saveExemptionFromAccompanyPatientObject.isrequriedtopaybybudget = obj.isrequriedtopaybybudget;
    saveExemptionFromAccompanyPatientObject.dateofstarting = obj.dateofstarting;
    saveExemptionFromAccompanyPatientObject.annualleavebalance = obj.annualleavebalance;
    saveExemptionFromAccompanyPatientObject.justification = obj.justification;
    //saveExemptionFromAccompanyPatientObject.husbandwifework = obj.husbandwifework;
    //saveExemptionFromAccompanyPatientObject.numberofchidren = obj.numberofchidren;
    //saveExemptionFromAccompanyPatientObject.placeofchildren = obj.placeofchildren;
    saveExemptionFromAccompanyPatientObject.emiratesid = obj.emiratesid;
    //saveExemptionFromAccompanyPatientObject.Degreeofrelationship = obj.Degreeofrelationship;
    if (obj.selectedOption2 == "Yes") {
        saveExemptionFromAccompanyPatientObject.selectedOption2 = 1;
    }
    else {
        saveExemptionFromAccompanyPatientObject.selectedOption2 = 0;
    }
    

    saveExemptionFromAccompanyPatientObject.worktotaldays = obj.worktotaldays;
    saveExemptionFromAccompanyPatientObject.worktotalsalarytobepaid = obj.worktotalsalarytobepaid;

    saveExemptionFromAccompanyPatientObject.worktobeexempted = obj.worktobeexempted;
    saveExemptionFromAccompanyPatientObject.howtocalculateid = '1';
    saveExemptionFromAccompanyPatientObject.howtocalculaterule = obj.howtocalculaterule;
    saveExemptionFromAccompanyPatientObject.isviolation = obj.violation;
    saveExemptionFromAccompanyPatientObject.violationdetail = obj.explain;

    saveExemptionFromAccompanyPatientObject.basicsalary2 = obj.basicsalary2;
    saveExemptionFromAccompanyPatientObject.totalsalarywithsubscription = obj.totalsalarywithsubscription;
    saveExemptionFromAccompanyPatientObject.retirement = obj.retirement;
    saveExemptionFromAccompanyPatientObject.totalsalary2 = obj.totalsalary2;
    saveExemptionFromAccompanyPatientObject.totalamount = obj.totalamount;
    saveExemptionFromAccompanyPatientObject.totaldays = obj.totaldays;

    if (getDataFromLocalStorage) {
        saveExemptionFromAccompanyPatientObject.entityID = getDataFromLocalStorage.EntityID;
        saveExemptionFromAccompanyPatientObject.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveExemptionFromAccompanyPatientObject.lang = 1;
    else
        saveExemptionFromAccompanyPatientObject.lang = 0;

    return saveExemptionFromAccompanyPatientObject;
}


export const saveDisciplinaryboard = (obj) => {
   
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveDisciplinaryboard = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveDisciplinaryboard.RequestID = obj.requestId;
    } else {
        saveDisciplinaryboard.RequestID = 0;
    }
   
    if (getDataFromLocalStorage) {
        saveDisciplinaryboard.EntityID = getDataFromLocalStorage.EntityID;
        saveDisciplinaryboard.RequesterID = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
    {
    saveDisciplinaryboard.lang = 1;
    }
    else
    {
    saveDisciplinaryboard.lang = 0;
    }
    saveDisciplinaryboard.RequestType= obj.RequestType;
    saveDisciplinaryboard.ContactName= obj.requesterName;
    saveDisciplinaryboard.ContactPhoneNumber= obj.requesterphoneNumber;
    
    saveDisciplinaryboard.ContactEmail=obj.rquesterEmail;
    
    
    saveDisciplinaryboard.isDraft=obj.draft;

    return saveDisciplinaryboard;
}

export const saveEntityInfo = (obj) => {
   
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveDisciplinaryboard = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveDisciplinaryboard.RequestID = obj.requestId;
    } else {
        saveDisciplinaryboard.RequestID = 0;
    }
   
    if (getDataFromLocalStorage) {
        saveDisciplinaryboard.EntityID = getDataFromLocalStorage.EntityID;
        saveDisciplinaryboard.RequesterID = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
    {
    saveDisciplinaryboard.lang = 1;
    }
    else
    {
    saveDisciplinaryboard.lang = 0;
    }
    saveDisciplinaryboard.requesterName = obj.requesterName;
saveDisciplinaryboard.rquesterEmail = obj.rquesterEmail;
saveDisciplinaryboard.requesterphoneNumber = obj.requesterphoneNumber;
saveDisciplinaryboard.UAEEmployeesTotalNumber = obj.UAEEmployeesTotalNumber;
saveDisciplinaryboard.NonEmployeesTotalNumber = obj.NonEmployeesTotalNumber;
saveDisciplinaryboard.NNUAEHAvePDP = obj.NNUAEHAvePDP;
saveDisciplinaryboard.NNNonUAEHAvePDP = obj.NNNonUAEHAvePDP;
saveDisciplinaryboard.NNUAEIncluded = obj.NNUAEIncluded;
saveDisciplinaryboard.NNNONUAEIncluded = obj.NNNONUAEIncluded;
saveDisciplinaryboard.NNEHave = obj.NNEHave;
saveDisciplinaryboard.NNonUAEHave = obj.NNonUAEHave;
saveDisciplinaryboard.EmployeesTotalNumber = obj.EmployeesTotalNumber;

    
    
    saveDisciplinaryboard.isDraft=obj.draft;

    return saveDisciplinaryboard;
}

export const saveActionPlansInitiatives = (obj) => {
   
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveDisciplinaryboard = {};
    // if (obj.isEdit) {
    // if (obj.requestId > 0) {
    //     saveDisciplinaryboard.RequestID = obj.requestId;
    // } else {
    //     saveDisciplinaryboard.RequestID = 0;
    // }
   
    if (getDataFromLocalStorage) {
        saveDisciplinaryboard.EntityID = getDataFromLocalStorage.EntityID;
        saveDisciplinaryboard.RequesterID = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
    {
    saveDisciplinaryboard.lang = 1;
    }
    else
    {
    saveDisciplinaryboard.lang = 0;
    }

saveDisciplinaryboard.Nameofresponsible = obj.Nameofresponsible;
saveDisciplinaryboard.RoleofResponsible = obj.Roleofresponsible;
saveDisciplinaryboard.Title = obj.InitiativeName;
saveDisciplinaryboard.Budget = obj.InitiativeBudget;
saveDisciplinaryboard.ID=obj.ID;
saveDisciplinaryboard.ExpectedOutcomes=obj.ExpectedOutcomes;
saveDisciplinaryboard.ActualOutcomes=obj.ActualOutcomes;
saveDisciplinaryboard.InitiativesStatus=obj.InitiativesStatus;
saveDisciplinaryboard.InitiativeOtherDescrption=obj.InitiativeOtherDescrption;
saveDisciplinaryboard.InitiativeDelayJustificationTxt=obj.InitiativeDelayJustificationTxt;
saveDisciplinaryboard.InitiativeDelayJustification=obj.InitiativeDelayJustification;
 
    saveDisciplinaryboard.isDraft=obj.draft;

    return saveDisciplinaryboard;
}





export const savePromotion = (obj) => {
   
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let savePromotion = {};
    // if (obj.isEdit) {
    if (obj.requestId > 0) {
        savePromotion.RequestID = obj.requestId;
    } else {
        savePromotion.RequestID = 0;
    }
   
    if (getDataFromLocalStorage) {
        savePromotion.EntityID = getDataFromLocalStorage.EntityID;
        savePromotion.RequesterID = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
    savePromotion.lang = 1;
    else
    savePromotion.lang = 0;
    savePromotion.RequestType= obj.RequestType;
    savePromotion.EmployeeName= obj.employeeName;
    savePromotion.EmployeeJobNumber= obj.EmployeeInfoEmployeeNumber;
    
    savePromotion.EmployeeJobTitle=obj.EmployeeInfoJobTitle;
    
    savePromotion.EmployeeDegree= obj.EmployeeInfoJobDegree;
    savePromotion.EmployeeEID= obj.EmployeeInfoEmirateID;
    savePromotion.EmployeeUID= obj.EmployeeInfoEmployeeUID;
    savePromotion.TotalSalary= obj.EmployeeInfoTotalSalary;
    savePromotion.BasicSalary= obj.EmployeeInfoBasicSalary;
    //savePromotion.durationOfAccompanyingLeave= obj.durationOfAccompanyingLeave;
    savePromotion.RetirementContributions= obj.EmployeeInfoRetirementcontributions;
    savePromotion.isDraft=obj.draft;

    return savePromotion;
}

export const savePatientSalaryMatrix = (id, tempTableData) => {
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let newTempTableData = [];
    
    tempTableData.forEach((item, index) => {
        let savePatientSalaryMatrixObject = {};

        //let tempDate = new Date(item.fromDate1);
        let tempDate = new Date();
        let day = tempDate.getDate();
        let month = tempDate.getMonth();
        let year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.fromdate = year + '-' + month + '-' + day;

        // tempDate = new Date(item.toDate1);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.todate = year + '-' + month + '-' + day;

        // tempDate = new Date(item.fromDate2);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.fromdate2 = year + '-' + month + '-' + day;

        //tempDate = new Date(item.toDate2);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.todate2 = year + '-' + month + '-' + day;

        // tempDate = new Date(item.fromDate3);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.fromdate3 = year + '-' + month + '-' + day;

        // tempDate = new Date(item.toDate3);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.todate3 = year + '-' + month + '-' + day;

        savePatientSalaryMatrixObject.year = item.year;
        savePatientSalaryMatrixObject.noOfDays = item.noOfDays;
        savePatientSalaryMatrixObject.salaryStatus = item.salaryStatus;
        savePatientSalaryMatrixObject.totalSalary = item.totalSalary;
        savePatientSalaryMatrixObject.from = item.fromDay;
        savePatientSalaryMatrixObject.to = item.toDay;
        savePatientSalaryMatrixObject.month = item.month;
        savePatientSalaryMatrixObject.subtotal = item.subTotal;
        savePatientSalaryMatrixObject.basicSalary = "123";

        savePatientSalaryMatrixObject.refid = id;
        savePatientSalaryMatrixObject.id = 0;
        savePatientSalaryMatrixObject.userid = getDataFromLocalStorage.id;

        newTempTableData.push(savePatientSalaryMatrixObject);
    })

    return newTempTableData;
}

export const SaveSickLeaveSalaryMatrix = (id, tempTableData) => {
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let newTempTableData = [];
    
    tempTableData.forEach((item, index) => {
        let savePatientSalaryMatrixObject = {};

        //let tempDate = new Date(item.fromDate1);
        let tempDate = new Date();
        let day = tempDate.getDate();
        let month = tempDate.getMonth();
        let year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.fromdate = year + '-' + month + '-' + day;

        // tempDate = new Date(item.toDate1);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.todate = year + '-' + month + '-' + day;

        // tempDate = new Date(item.fromDate2);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.fromdate2 = year + '-' + month + '-' + day;

        //tempDate = new Date(item.toDate2);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.todate2 = year + '-' + month + '-' + day;

        // tempDate = new Date(item.fromDate3);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.fromdate3 = year + '-' + month + '-' + day;

        // tempDate = new Date(item.toDate3);
        tempDate = new Date();
        day = tempDate.getDate();
        month = tempDate.getMonth();
        year = tempDate.getFullYear();
        savePatientSalaryMatrixObject.todate3 = year + '-' + month + '-' + day;

        savePatientSalaryMatrixObject.year = item.year;
        savePatientSalaryMatrixObject.noOfDays = item.noOfDays;
        savePatientSalaryMatrixObject.salaryStatus = item.salaryStatus;
        savePatientSalaryMatrixObject.totalSalary = item.totalSalary;
        savePatientSalaryMatrixObject.from = item.fromDay;
        savePatientSalaryMatrixObject.to = item.toDay;
        savePatientSalaryMatrixObject.month = item.month;
        savePatientSalaryMatrixObject.subtotal = item.subTotal;
        savePatientSalaryMatrixObject.basicSalary =item.basicSalary;

        savePatientSalaryMatrixObject.refid = id;
        savePatientSalaryMatrixObject.id = 0;
        savePatientSalaryMatrixObject.userid = getDataFromLocalStorage.id;

        newTempTableData.push(savePatientSalaryMatrixObject);
    })

    return newTempTableData;
}

export const saveExceptionRetirement = (obj) => {
    debugger;
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let saveExceptionRetirement = {};
    //if (obj.isEdit) {
    if (obj.requestId > 0) {
        saveExceptionRetirement.id = obj.requestId;
    } else {
        saveExceptionRetirement.id = 0;
    }
    saveExceptionRetirement.draft = obj.draft;
    saveExceptionRetirement.subject = obj.employeeName;
    saveExceptionRetirement.employeenumber = obj.employeeNumber;
    saveExceptionRetirement.nationalityid = obj.nationality;
    saveExceptionRetirement.dob = obj.dateOfBirth;
    saveExceptionRetirement.jobtitle = obj.jobTitle;
    saveExceptionRetirement.unifiednumber = obj.unifiedNumber;
    saveExceptionRetirement.steerfrom = obj.fromDate;
    saveExceptionRetirement.steerto = obj.toDate;
    saveExceptionRetirement.isjurisdiction = obj.copyButton;
    saveExceptionRetirement.reasonforextending = obj.reasonForExtendingTheService;
    saveExceptionRetirement.annualperformanceevaluation = obj.annualPerformanceEvaluation;
    saveExceptionRetirement.emiratesid = obj.emiratesid;


    saveExceptionRetirement.HiringDate = obj.HiringDate;
    saveExceptionRetirement.CurrentGrade = obj.CurrentGrade;
    saveExceptionRetirement.Efficiency = obj.Efficiency;
    saveExceptionRetirement.extendedservice = obj.extendedservice;
    saveExceptionRetirement.Salary = obj.Salary;
    saveExceptionRetirement.Qualification = obj.Qualification;
    saveExceptionRetirement.GraduationYear = obj.GraduationYear;
    saveExceptionRetirement.DateofretirementAge = obj.DateofretirementAge;
    saveExceptionRetirement.TypeofContract = obj.TypeofContract;
    saveExceptionRetirement.prviousextensionwithoutapprovalfrom = obj.prviousextensionwithoutapprovalfrom;
    saveExceptionRetirement.prviousextensionwithoutapprovalto = obj.prviousextensionwithoutapprovalto;
    saveExceptionRetirement.Previousextionsfrom = obj.Previousextionsfrom;
    saveExceptionRetirement.Previousextionsto = obj.Previousextionsto;
    saveExceptionRetirement.Replacementplanfrom = obj.Replacementplanfrom;
    saveExceptionRetirement.Replacementplanto = obj.Replacementplanto

    if (getDataFromLocalStorage) {
        saveExceptionRetirement.EntityID = getDataFromLocalStorage.EntityID;
        saveExceptionRetirement.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        saveExceptionRetirement.lang = 1;
    else
        saveExceptionRetirement.lang = 0;

    return saveExceptionRetirement;
}

export const saveEntityPayload = (obj) => {
    // get language
    let lan = localStorage.getItem('Lang');

    let entityObject = {};
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    if (obj.isEdit) {
        entityObject.id = obj.requestId;
    } else {
        entityObject.id = 0;
    }

    entityObject.englishName = obj.englisName;
    entityObject.arabicName = obj.arabicName;
    entityObject.shortName = obj.shortName;
    entityObject.ismothercompany = obj.selectedOptionMotherComp === 'Yes' ? 1 : 0;
    entityObject.mothercompanyid = obj.entitySelectedValue;
    entityObject.industryid = obj.industrySelectedValue;
    entityObject.cityid = obj.citySeletedValue;
    entityObject.description = obj.clientDescriptionValue;
    //entityObject.employeeNumber = obj.employeeNumber;
    entityObject.employeeNumber = '1';
    entityObject.status = obj.selectedOptionForIsActive === 'Yes' ? 1 : 0;
    entityObject.reason = obj.reasonDeativeSelectedValue;
    entityObject.sectorid = obj.sectorSelectedValue;
    entityObject.phonenumber = obj.phoneNumber;

    if (getDataFromLocalStorage) {
        entityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        entityObject.lang = 1;
    else
        entityObject.lang = 0;

    return entityObject;
}

export const saveContactPayload = (obj) => {
    // get language
    let lan = localStorage.getItem('Lang');

    let contactObject = {};
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    if (obj.isEdit) {
        contactObject.id = obj.requestId;
    } else {
        contactObject.id = 0;
    }
    contactObject.englishName = obj.englishName;
    contactObject.arabicName = obj.arabicName;
    contactObject.username = obj.userName;
    contactObject.emailAddress = obj.email;
    contactObject.mobilenumber = obj.mobile;
    contactObject.phonenumber = obj.phoneNumber;
    contactObject.designation = obj.designation;
    contactObject.entityID = obj.entitySelectedValue;
    contactObject.status = obj.statusSelectedValue;
    contactObject.reason = obj.reasonSelectedValue;
    contactObject.accountTypeID = obj.accountTypeSelectedValue;
    contactObject.roleID = obj.roleSelectedValue;
    contactObject.internalcontact = obj.internalExternalOption;
    contactObject.manager = obj.managerName;
    contactObject.update = obj.update;
    contactObject.update = obj.update;
    contactObject.sectorID=obj.sectorID;
    contactObject.departmentID=obj.departmentID;
    if (getDataFromLocalStorage) {
        contactObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        contactObject.lang = 1;
    else
        contactObject.lang = 0;

    return contactObject;
}

export const saveLookupPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let lookupObject = {};
    lookupObject.id = 0;
    lookupObject.filtertype = obj.filtertype;
    lookupObject.englishtitle = obj.englishtitle;
    lookupObject.arabictitle = obj.arabictitle;
    lookupObject.status = obj.status === 'yes' ? 0 : 1;
    lookupObject.parentid = obj.parentid === '' ? 0 : obj.parentid;
    lookupObject.isdefault = obj.isdefault === 'yes' ? 1 : 0;
    if (getDataFromLocalStorage) {
        lookupObject.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        lookupObject.lang = 1;
    else
        lookupObject.lang = 0;

    return lookupObject;
}

export const saveMailTemplatePayload = (title, mailTemplateTypeSelectedValue, isEdit, requestId) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let mailTemplateObject = {};
    if (isEdit) {
        mailTemplateObject.id = requestId;
    } else {
        mailTemplateObject.id = 0;
    }
    mailTemplateObject.title = title;
    mailTemplateObject.content = Config.getBase64MailTemplateBody();
    mailTemplateObject.notificationtype = mailTemplateTypeSelectedValue;

    if (getDataFromLocalStorage) {
        mailTemplateObject.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        mailTemplateObject.lang = 1;
    else
        mailTemplateObject.lang = 0;

    return mailTemplateObject;
}

export const saveRolePayload = (objForRole) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let roleObject = {};
    if (objForRole.isEdit) {
        roleObject.id = objForRole.requestId;
    } else {
        roleObject.id = 0;
    }

    roleObject.EnglishTitle = objForRole.titleSelectedValue;
    roleObject.ArabicTitle = objForRole.arabicTitle;
    // admin 
    roleObject.isadmin = objForRole.adminPermissionData.isadmin;
      roleObject.canviewreport=objForRole.reportPermissionData.canviewreport;
 
      roleObject.canCreateWorkShop=objForRole.WorkShopData.canCreateWorkShop;
      roleObject.canBookWorkShop=objForRole.WorkShopData.canBookWorkShop;
      roleObject.canViewDisciplinaryboard=objForRole.DisciplinaryboardData.canViewDisciplinaryboard;
      roleObject.canCreateDisciplinaryboard=objForRole.DisciplinaryboardData.canCreateDisciplinaryboard;
      roleObject.canEditDisciplinaryboard=objForRole.DisciplinaryboardData.canEditDisciplinaryboard;
      roleObject.canAssignDisciplinaryboardToDGoffice=objForRole.DisciplinaryboardData.canAssignDisciplinaryboardToDGoffice;
      roleObject.canAssignDisciplinaryboardtoEmployee=objForRole.DisciplinaryboardData.canAssignDisciplinaryboardtoEmployee;
      roleObject.canAddReplayDisciplinaryboard=objForRole.DisciplinaryboardData.canAddReplayDisciplinaryboard;
      roleObject.canViewOHI=objForRole.OHIData.canViewOHI;
      // organization structure 
 
 

   roleObject.cansubmitorgrequest = objForRole.orgRequestPermissionData.cansubmitorgrequest;
   roleObject.canvieworgrequest=objForRole.orgRequestPermissionData.canvieworgrequest;
   roleObject.caneditorgrequest=objForRole.orgRequestPermissionData.caneditorgrequest;
   roleObject.canupdateorgstatus=objForRole.orgRequestPermissionData.canupdateorgstatus;
   roleObject.canaddorgcomment=objForRole.orgRequestPermissionData.canaddorgcomment;
   roleObject.canassignorgdepartment=objForRole.orgRequestPermissionData.canassignorgdepartment;
   roleObject.canasignorgemployee=objForRole.orgRequestPermissionData.canasignorgemployee;
   roleObject.canprovideorgfeedback=objForRole.orgRequestPermissionData.canprovideorgfeedback;
   roleObject.canvieworgfeedback=objForRole.orgRequestPermissionData.canvieworgfeedback;
   roleObject.canassignorgsector=objForRole.orgRequestPermissionData.canassignorgsector;
   roleObject.canshoworgcomments=objForRole.orgRequestPermissionData.canshoworgcomments;
   roleObject.canassignorggm=objForRole.orgRequestPermissionData.canassignorggm;
   roleObject.canassignorgbacktoentity=objForRole.orgRequestPermissionData.canassignorgbacktoentity;
   roleObject.canvieworgrequesttree=objForRole.orgRequestPermissionData.CanCreateStructure;
   roleObject.canviewtree=objForRole.orgRequestPermissionData.canviewtree;


    //Ammend Employee salary
    roleObject.canprovideamendsalarystudy = objForRole.amendEmpSalaryPermissionData.canprovideamendsalarystudy;
    roleObject.canreceiveamendsalarystudy = objForRole.amendEmpSalaryPermissionData.canreceiveamendsalarystudy;
    roleObject.canshowamendsalarycomments = objForRole.amendEmpSalaryPermissionData.canshowamendsalarycomments;
    roleObject.cansubmitamendsalaryrequest = objForRole.amendEmpSalaryPermissionData.cansubmitamendsalaryrequest;
    roleObject.canviewamendsalaryrequest = objForRole.amendEmpSalaryPermissionData.canviewamendsalaryrequest;
    roleObject.canaddamendsalarycomments = objForRole.amendEmpSalaryPermissionData.canaddamendsalarycomments;
    roleObject.canassignamendsalarydepartment = objForRole.amendEmpSalaryPermissionData.canassignamendsalarydepartment;
    roleObject.canassignamendsalaryemployee = objForRole.amendEmpSalaryPermissionData.canassignamendsalaryemployee;
    roleObject.canassignamendsalarysector = objForRole.amendEmpSalaryPermissionData.canassignamendsalarysector;
    roleObject.caneditamendsalaryrequest = objForRole.amendEmpSalaryPermissionData.caneditamendsalaryrequest;
    roleObject.canupdateamendsalarystatus = objForRole.amendEmpSalaryPermissionData.canupdateamendsalarystatus;
    roleObject.canassignamendsalarygm = objForRole.amendEmpSalaryPermissionData.canassignamendsalarygm;
    roleObject.canassignamendsalarybacktoentity = objForRole.amendEmpSalaryPermissionData.canassignamendsalarybacktoentity;

    //Ammend salary scale
    roleObject.canprovideamendscalestudy = objForRole.amendSalaryScalePermissionData.canprovideamendscalestudy;
    roleObject.canreceiveamendscalestudy = objForRole.amendSalaryScalePermissionData.canreceiveamendscalestudy;
    roleObject.canshowamendscalecomments = objForRole.amendSalaryScalePermissionData.canshowamendscalecomments;
    roleObject.cansubmitamendscalerequest = objForRole.amendSalaryScalePermissionData.cansubmitamendscalerequest;
    roleObject.canviewamendscalerequest = objForRole.amendSalaryScalePermissionData.canviewamendscalerequest;
    roleObject.canaddamendscalecomments = objForRole.amendSalaryScalePermissionData.canaddamendscalecomments;
    roleObject.canassignamendscaledepartment = objForRole.amendSalaryScalePermissionData.canassignamendscaledepartment;
    roleObject.canassignamendscaleemployee = objForRole.amendSalaryScalePermissionData.canassignamendscaleemployee;
    roleObject.canassignamendscalesector = objForRole.amendSalaryScalePermissionData.canassignamendscalesector;
    roleObject.caneditamendscalerequest = objForRole.amendSalaryScalePermissionData.caneditamendscalerequest;
    roleObject.canupdateamendscalestatus = objForRole.amendSalaryScalePermissionData.canupdateamendscalestatus;
    roleObject.canassignamendscalegm = objForRole.amendSalaryScalePermissionData.canassignamendscalegm;
    roleObject.canassignamendscalebacktoentity = objForRole.amendSalaryScalePermissionData.canassignamendscalebacktoentity;

    //Bonus
    roleObject.canprovidebonusstudy = objForRole.bonusRequestPermissionData.canprovidebonusstudy;
    roleObject.canreceivebonusstudy = objForRole.bonusRequestPermissionData.canreceivebonusstudy;
    roleObject.canshowbonuscomments = objForRole.bonusRequestPermissionData.canshowbonuscomments;
    roleObject.cansubmitbonusrequest = objForRole.bonusRequestPermissionData.cansubmitbonusrequest;
    roleObject.canaddbonuscomments = objForRole.bonusRequestPermissionData.canaddbonuscomments;
    roleObject.canassignbounsdepartment = objForRole.bonusRequestPermissionData.canassignbounsdepartment;
    roleObject.canassignbounsemployee = objForRole.bonusRequestPermissionData.canassignbounsemployee;
    roleObject.canassignbonussector = objForRole.bonusRequestPermissionData.canassignbonussector;
    roleObject.caneditbonusrequest = objForRole.bonusRequestPermissionData.caneditbonusrequest;
    roleObject.canupdatebonusstatus = objForRole.bonusRequestPermissionData.canupdatebonusstatus;
    roleObject.canviewbonusrequest = objForRole.bonusRequestPermissionData.canviewbonusrequest;
    roleObject.canassignbonusgm = objForRole.bonusRequestPermissionData.canassignbonusgm;
    roleObject.canassignbonusbacktoentity = objForRole.bonusRequestPermissionData.canassignbonusbacktoentity;

    //Exception AD Housing
    roleObject.canprovidehousingstudy = objForRole.exmADHousingPermissionData.canprovidehousingstudy;
    roleObject.canreceivehousingstudy = objForRole.exmADHousingPermissionData.canreceivehousingstudy;
    roleObject.canshowadhousingcomments = objForRole.exmADHousingPermissionData.canshowadhousingcomments;
    roleObject.cansubmitadhousingrequest = objForRole.exmADHousingPermissionData.cansubmitadhousingrequest;
    roleObject.canaddadhousingcomments = objForRole.exmADHousingPermissionData.canaddadhousingcomments;
    roleObject.canassignhousingdepartment = objForRole.exmADHousingPermissionData.canassignhousingdepartment;
    roleObject.canassignhousingemployee = objForRole.exmADHousingPermissionData.canassignhousingemployee;
    roleObject.canassignhousingsector = objForRole.exmADHousingPermissionData.canassignhousingsector;
    roleObject.caneditadhousingrequest = objForRole.exmADHousingPermissionData.caneditadhousingrequest;
    roleObject.canupdateadhousingstatus = objForRole.exmADHousingPermissionData.canupdateadhousingstatus;
    roleObject.canviewadhousingrequest = objForRole.exmADHousingPermissionData.canviewadhousingrequest;
    roleObject.canassignhousinggm = objForRole.exmADHousingPermissionData.canassignhousinggm;
    roleObject.canassignhousingbacktoentity = objForRole.exmADHousingPermissionData.canassignhousingbacktoentity;

    //Exemption Accompany Patient
    roleObject.canprovidepatientstudy = objForRole.exmAccompanyPatientPermissionData.canprovidepatientstudy;
    roleObject.canreceivepatientstudy = objForRole.exmAccompanyPatientPermissionData.canreceivepatientstudy;
    roleObject.canshowpatientcomments = objForRole.exmAccompanyPatientPermissionData.canshowpatientcomments;
    roleObject.cansubmitpatientrequest = objForRole.exmAccompanyPatientPermissionData.cansubmitpatientrequest;
    roleObject.canaddpatientcomments = objForRole.exmAccompanyPatientPermissionData.canaddpatientcomments;
    roleObject.canassignpatientdepartment = objForRole.exmAccompanyPatientPermissionData.canassignpatientdepartment;
    roleObject.canassignpatientemployee = objForRole.exmAccompanyPatientPermissionData.canassignpatientemployee;
    roleObject.canassignpatientsector = objForRole.exmAccompanyPatientPermissionData.canassignpatientsector;
    roleObject.caneditpatientrequest = objForRole.exmAccompanyPatientPermissionData.caneditpatientrequest;
    roleObject.canupdatepatientstatus = objForRole.exmAccompanyPatientPermissionData.canupdatepatientstatus;
    roleObject.canviewpatientrequest = objForRole.exmAccompanyPatientPermissionData.canviewpatientrequest;
    roleObject.canassignpatientgm = objForRole.exmAccompanyPatientPermissionData.canassignpatientgm;
    roleObject.canassignpatientbacktoentity = objForRole.exmAccompanyPatientPermissionData.canassignpatientbacktoentity;

    //Information Request
    // roleObject.canAddInfoReqFeedback = objForRole.infoRequestPermissionData.canAddInfoReqFeedback;
    roleObject.canassignirsector = objForRole.infoRequestPermissionData.canassignirsector;
    roleObject.canshowircomments = objForRole.infoRequestPermissionData.canshowircomments;
    roleObject.cansubmitirrequest = objForRole.infoRequestPermissionData.cansubmitirrequest;
    roleObject.canaddircomments = objForRole.infoRequestPermissionData.canaddircomments;
    roleObject.canassignirdepartment = objForRole.infoRequestPermissionData.canassignirdepartment;
    roleObject.caneditirrequest = objForRole.infoRequestPermissionData.caneditirrequest;
    roleObject.canreceiveirfeedback = objForRole.infoRequestPermissionData.canreceiveirfeedback;
    roleObject.canupdateirstatus = objForRole.infoRequestPermissionData.canupdateirstatus;
    roleObject.canviewirrequest = objForRole.infoRequestPermissionData.canviewirrequest;
    roleObject.canprovideirfeedback = objForRole.infoRequestPermissionData.canprovideirfeedback;
    roleObject.canassignirgm = objForRole.infoRequestPermissionData.canassignirgm;
    roleObject.canassignirbacktoentity = objForRole.infoRequestPermissionData.canassignirbacktoentity;

    //Retirement
    roleObject.canprovideretirementstudy = objForRole.retirementPermissionData.canprovideretirementstudy;
    roleObject.canreceiveretirementstudy = objForRole.retirementPermissionData.canreceiveretirementstudy;
    roleObject.canshowretirementcomments = objForRole.retirementPermissionData.canshowretirementcomments;
    roleObject.cansubmitretirementrequest = objForRole.retirementPermissionData.cansubmitretirementrequest;
    roleObject.canviewretirementrequest = objForRole.retirementPermissionData.canviewretirementrequest;
    roleObject.canaddretirementcomments = objForRole.retirementPermissionData.canaddretirementcomments;
    roleObject.canassignretirementdepartment = objForRole.retirementPermissionData.canassignretirementdepartment;
    roleObject.canassignretirementemployees = objForRole.retirementPermissionData.canassignretirementemployees;
    roleObject.canassignretirementsector = objForRole.retirementPermissionData.canassignretirementsector;
    roleObject.caneditretirementrequest = objForRole.retirementPermissionData.caneditretirementrequest;
    roleObject.canupdateretirementstatus = objForRole.retirementPermissionData.canupdateretirementstatus;
    roleObject.canassignretirementgm = objForRole.retirementPermissionData.canassignretirementgm;
    roleObject.canassignretirementbacktoentity = objForRole.retirementPermissionData.canassignretirementbacktoentity;

    //Review HR Policy
    roleObject.canreceivehrstudy = objForRole.reviewHrPolicyPermissionData.canreceivehrstudy;
    roleObject.canassignhremployee = objForRole.reviewHrPolicyPermissionData.canassignhremployee;
    roleObject.canprovidehrstudy = objForRole.reviewHrPolicyPermissionData.canprovidehrstudy;
    roleObject.canshowreviewhrcomments = objForRole.reviewHrPolicyPermissionData.canshowreviewhrcomments;
    roleObject.cansubmitreviewhrrequest = objForRole.reviewHrPolicyPermissionData.cansubmitreviewhrrequest;
    roleObject.canviewreviewhrrequest = objForRole.reviewHrPolicyPermissionData.canviewreviewhrrequest;
    roleObject.canaddreviewhrcomments = objForRole.reviewHrPolicyPermissionData.canaddreviewhrcomments;
    roleObject.canassignhrdepartment = objForRole.reviewHrPolicyPermissionData.canassignhrdepartment;
    roleObject.canassignhrsector = objForRole.reviewHrPolicyPermissionData.canassignhrsector;
    roleObject.caneditreviewhrrequest = objForRole.reviewHrPolicyPermissionData.caneditreviewhrrequest;
    roleObject.canupdatereviewhrstatus = objForRole.reviewHrPolicyPermissionData.canupdatereviewhrstatus;
    roleObject.canassignhrgm = objForRole.reviewHrPolicyPermissionData.canassignhrgm;
    roleObject.canassignhrbacktoentity = objForRole.reviewHrPolicyPermissionData.canassignhrbacktoentity;

    //SCI
    roleObject.cansubmitscirequest = objForRole.sciRequestPermissionData.cansubmitscirequest;
    roleObject.canaddscicomment = objForRole.sciRequestPermissionData.canaddscicomment;
    roleObject.canasignsciemployee = objForRole.sciRequestPermissionData.canasignsciemployee;
    roleObject.canassignscidepartment = objForRole.sciRequestPermissionData.canassignscidepartment;
    roleObject.canassignscisector = objForRole.sciRequestPermissionData.canassignscisector;
    roleObject.caneditscirequest = objForRole.sciRequestPermissionData.caneditscirequest;
    roleObject.canprovidescifeedback = objForRole.sciRequestPermissionData.canprovidescifeedback;
    roleObject.canreviewscirequest = objForRole.sciRequestPermissionData.canreviewscirequest;
    roleObject.canupdatescistatus = objForRole.sciRequestPermissionData.canupdatescistatus;
    roleObject.canviewscifeedback = objForRole.sciRequestPermissionData.canviewscifeedback;
    roleObject.canshowscicomments = objForRole.sciRequestPermissionData.canshowscicomments;
    roleObject.canassignscigm = objForRole.sciRequestPermissionData.canassignscigm;
    roleObject.canassignscibacktoentity = objForRole.sciRequestPermissionData.canassignscibacktoentity;

    // Secondment
    roleObject.canprovidesecondmentstudy = objForRole.secndmentForFedralAgencyPermissionData.canprovidesecondmentstudy;
    roleObject.canreceivesecondmentstudy = objForRole.secndmentForFedralAgencyPermissionData.canreceivesecondmentstudy;
    roleObject.canshowsecondmentcomments = objForRole.secndmentForFedralAgencyPermissionData.canshowsecondmentcomments;
    roleObject.cansubmitsecondmentrequest = objForRole.secndmentForFedralAgencyPermissionData.cansubmitsecondmentrequest;
    roleObject.canviewsecondmentrequest = objForRole.secndmentForFedralAgencyPermissionData.canviewsecondmentrequest;
    roleObject.canaddsecondmentcomments = objForRole.secndmentForFedralAgencyPermissionData.canaddsecondmentcomments;
    roleObject.canassignsecondmentdepartment = objForRole.secndmentForFedralAgencyPermissionData.canassignsecondmentdepartment;
    roleObject.canassignsecondmetnemployee = objForRole.secndmentForFedralAgencyPermissionData.canassignsecondmetnemployee;
    roleObject.canassignsecondmentsector = objForRole.secndmentForFedralAgencyPermissionData.canassignsecondmentsector;
    roleObject.caneditsecondmentrequest = objForRole.secndmentForFedralAgencyPermissionData.caneditsecondmentrequest;
    roleObject.canupdatesecondmentstatus = objForRole.secndmentForFedralAgencyPermissionData.canupdatesecondmentstatus;
    roleObject.canassignsecondmentgm = objForRole.secndmentForFedralAgencyPermissionData.canassignsecondmentgm;
    roleObject.canassignsecondmentbacktoentity = objForRole.secndmentForFedralAgencyPermissionData.canassignsecondmentbacktoentity;

    // extend create objective
    roleObject.cansubmitextendcreaterequest = objForRole.extendCreateObjectivesData.cansubmitextendcreaterequest;
    roleObject.canviewextendcreaterequest = objForRole.extendCreateObjectivesData.canviewextendcreaterequest;
    roleObject.caneditextendcreaterequest = objForRole.extendCreateObjectivesData.caneditextendcreaterequest;
    roleObject.canupdateextendcreatestatus = objForRole.extendCreateObjectivesData.canupdateextendcreatestatus;
    roleObject.canshowextendcreatecomments = objForRole.extendCreateObjectivesData.canshowextendcreatecomments;
    roleObject.canaddextendcreatecomments = objForRole.extendCreateObjectivesData.canaddextendcreatecomments;
    roleObject.canprovideextendcreatestudy = objForRole.extendCreateObjectivesData.canprovideextendcreatestudy;
    roleObject.canreceiveextendcreatestudy = objForRole.extendCreateObjectivesData.canreceiveextendcreatestudy;
    roleObject.canassignextendcreatesector = objForRole.extendCreateObjectivesData.canassignextendcreatesector;
    roleObject.canassignextendcreatedepartment = objForRole.extendCreateObjectivesData.canassignextendcreatedepartment;
    roleObject.canassignextendcreateemployee = objForRole.extendCreateObjectivesData.canassignextendcreateemployee;
    roleObject.canassignextendcreatebacktodepartment = objForRole.extendCreateObjectivesData.canassignextendcreatebacktodepartment;
    roleObject.canassignextendcreatebacktoentity = objForRole.extendCreateObjectivesData.canassignextendcreatebacktoentity;
    roleObject.canassignextendcreaterequesttogm = objForRole.extendCreateObjectivesData.canassignextendcreaterequesttogm;

    // exten review objective
    roleObject.cansubmitextendreviewrequest = objForRole.extendReviewObjectivesData.cansubmitextendreviewrequest;
    roleObject.canviewextendreviewrequest = objForRole.extendReviewObjectivesData.canviewextendreviewrequest;
    roleObject.caneditextendreviewrequest = objForRole.extendReviewObjectivesData.caneditextendreviewrequest;
    roleObject.canupdateextendreviewstatus = objForRole.extendReviewObjectivesData.canupdateextendreviewstatus;
    roleObject.canshowextendreviewcomments = objForRole.extendReviewObjectivesData.canshowextendreviewcomments;
    roleObject.canaddextendreviewcomments = objForRole.extendReviewObjectivesData.canaddextendreviewcomments;
    roleObject.canprovideextendreviewstudy = objForRole.extendReviewObjectivesData.canprovideextendreviewstudy;
    roleObject.canreceiveextendreviewstudy = objForRole.extendReviewObjectivesData.canreceiveextendreviewstudy;
    roleObject.canassignextendreviewsector = objForRole.extendReviewObjectivesData.canassignextendreviewsector;
    roleObject.canassignextendreviewdepartment = objForRole.extendReviewObjectivesData.canassignextendreviewdepartment;
    roleObject.canassignextendreviewemployee = objForRole.extendReviewObjectivesData.canassignextendreviewemployee;
    roleObject.canassignextendreviewbacktodepartment = objForRole.extendReviewObjectivesData.canassignextendreviewbacktodepartment;
    roleObject.canassignextendreviewbacktoentity = objForRole.extendReviewObjectivesData.canassignextendreviewbacktoentity;
    roleObject.canassignextendreviewrequesttogm = objForRole.extendReviewObjectivesData.canassignextendreviewrequesttogm;

    // extend annual
    roleObject.cansubmitextendannualrequest = objForRole.extendAnnualObjectivesData.cansubmitextendannualrequest;
    roleObject.canviewextendannualrequest = objForRole.extendAnnualObjectivesData.canviewextendannualrequest;
    roleObject.caneditextendannualrequest = objForRole.extendAnnualObjectivesData.caneditextendannualrequest;
    roleObject.canupdateextendannualstatus = objForRole.extendAnnualObjectivesData.canupdateextendannualstatus;
    roleObject.canshowextendannualcomments = objForRole.extendAnnualObjectivesData.canshowextendannualcomments;
    roleObject.canaddextendannualcomments = objForRole.extendAnnualObjectivesData.canaddextendannualcomments;
    roleObject.canprovideextendannualstudy = objForRole.extendAnnualObjectivesData.canprovideextendannualstudy;
    roleObject.canreceiveextendannualstudy = objForRole.extendAnnualObjectivesData.canreceiveextendannualstudy;
    roleObject.canassignextendannualsector = objForRole.extendAnnualObjectivesData.canassignextendannualsector;
    roleObject.canassignextendannualdepartment = objForRole.extendAnnualObjectivesData.canassignextendannualdepartment;
    roleObject.canassignextendannualemployee = objForRole.extendAnnualObjectivesData.canassignextendannualemployee;
    roleObject.canassignextendannualbacktodepartment = objForRole.extendAnnualObjectivesData.canassignextendannualbacktodepartment;
    roleObject.canassignextendannualbacktoentity = objForRole.extendAnnualObjectivesData.canassignextendannualbacktoentity;
    roleObject.canassignextendannualrequesttogm = objForRole.extendAnnualObjectivesData.canassignextendannualrequesttogm;

    // add and edit employee performance
    roleObject.cansubmitemployeeperformancerequest = objForRole.addOrEditEmpPerfData.cansubmitemployeeperformancerequest;
    roleObject.canviewemployeeperformancerequest = objForRole.addOrEditEmpPerfData.canviewemployeeperformancerequest;
    roleObject.caneditemployeeperformancerequest = objForRole.addOrEditEmpPerfData.caneditemployeeperformancerequest;
    roleObject.canupdateemployeeperformancestatus = objForRole.addOrEditEmpPerfData.canupdateemployeeperformancestatus;
    roleObject.canshowemployeeperformancecomments = objForRole.addOrEditEmpPerfData.canshowemployeeperformancecomments;
    roleObject.canaddemployeeperformancecomments = objForRole.addOrEditEmpPerfData.canaddemployeeperformancecomments;
    roleObject.canprovideemployeeperformancestudy = objForRole.addOrEditEmpPerfData.canprovideemployeeperformancestudy;
    roleObject.canreceiveemployeeperformancestudy = objForRole.addOrEditEmpPerfData.canreceiveemployeeperformancestudy;
    roleObject.canassignemployeeperformancesector = objForRole.addOrEditEmpPerfData.canassignemployeeperformancesector;
    roleObject.canassignemployeeperformancedepartment = objForRole.addOrEditEmpPerfData.canassignemployeeperformancedepartment;
    roleObject.canassignemployeeperformanceemployee = objForRole.addOrEditEmpPerfData.canassignemployeeperformanceemployee;
    roleObject.canassignemployeeperformancebacktodepartment = objForRole.addOrEditEmpPerfData.canassignemployeeperformancebacktodepartment;
    roleObject.canassignemployeeperformancebacktoentity = objForRole.addOrEditEmpPerfData.canassignemployeeperformancebacktoentity;
    roleObject.canassignemployeeperformancerequesttogm = objForRole.addOrEditEmpPerfData.canassignemployeeperformancerequesttogm;

    // join and implement
    roleObject.cansubmitjoinimplementrequest = objForRole.joinAndImplementData.cansubmitjoinimplementrequest;
    roleObject.canviewjoinimplementrequest = objForRole.joinAndImplementData.canviewjoinimplementrequest;
    roleObject.caneditjoinimplementrequest = objForRole.joinAndImplementData.caneditjoinimplementrequest;
    roleObject.canupdatejoinimplementstatus = objForRole.joinAndImplementData.canupdatejoinimplementstatus;
    roleObject.canshowjoinimplementcomments = objForRole.joinAndImplementData.canshowjoinimplementcomments;
    roleObject.canaddjoinimplementcomments = objForRole.joinAndImplementData.canaddjoinimplementcomments;
    roleObject.canprovidejoinimplementstudy = objForRole.joinAndImplementData.canprovidejoinimplementstudy;
    roleObject.canreceivejoinimplementstudy = objForRole.joinAndImplementData.canreceivejoinimplementstudy;
    roleObject.canassignjoinimplementsector = objForRole.joinAndImplementData.canassignjoinimplementsector;
    roleObject.canassignjoinimplementdepartment = objForRole.joinAndImplementData.canassignjoinimplementdepartment;
    roleObject.canassignjoinimplementemployee = objForRole.joinAndImplementData.canassignjoinimplementemployee;
    roleObject.canassignjoinimplementbacktodepartment = objForRole.joinAndImplementData.canassignjoinimplementbacktodepartment;
    roleObject.canassignjoinimplementbacktoentity = objForRole.joinAndImplementData.canassignjoinimplementbacktoentity;
    roleObject.canassignjoinimplementrequesttogm = objForRole.joinAndImplementData.canassignjoinimplementrequesttogm;

    // open 
    roleObject.cansubmitopencomplainsrequest = objForRole.openComplainsData.cansubmitopencomplainsrequest;
    roleObject.canviewopencomplainsrequest = objForRole.openComplainsData.canviewopencomplainsrequest;
    roleObject.caneditopencomplainsrequest = objForRole.openComplainsData.caneditopencomplainsrequest;
    roleObject.canupdateopencomplainsstatus = objForRole.openComplainsData.canupdateopencomplainsstatus;
    roleObject.canshowopencomplainscomments = objForRole.openComplainsData.canshowopencomplainscomments;
    roleObject.canaddopencomplainscomments = objForRole.openComplainsData.canaddopencomplainscomments;
    roleObject.canprovideopencomplainsstudy = objForRole.openComplainsData.canprovideopencomplainsstudy;
    roleObject.canreceiveopencomplainsstudy = objForRole.openComplainsData.canreceiveopencomplainsstudy;
    roleObject.canassignopencomplainssector = objForRole.openComplainsData.canassignopencomplainssector;
    roleObject.canassignopencomplainsdepartment = objForRole.openComplainsData.canassignopencomplainsdepartment;
    roleObject.canassignopencomplainsemployee = objForRole.openComplainsData.canassignopencomplainsemployee;
    roleObject.canassignopencomplainsbacktodepartment = objForRole.openComplainsData.canassignopencomplainsbacktodepartment;
    roleObject.canassignopencomplainsbacktoentity = objForRole.openComplainsData.canassignopencomplainsbacktoentity;
    roleObject.canassignopencomplainsrequesttogm = objForRole.openComplainsData.canassignopencomplainsrequesttogm;

    // amend systemcansubmitamendsystemsrequest
    roleObject.cansubmitamendsystemsrequest = objForRole.amendSystemData.cansubmitamendsystemsrequest;
    roleObject.canviewamendsystemsrequest = objForRole.amendSystemData.canviewamendsystemsrequest;
    roleObject.caneditamendsystemsrequest = objForRole.amendSystemData.caneditamendsystemsrequest;
    roleObject.canupdateamendsystemsstatus = objForRole.amendSystemData.canupdateamendsystemsstatus;
    roleObject.canshowamendsystemscomments = objForRole.amendSystemData.canshowamendsystemscomments;
    roleObject.canaddamendsystemscomments = objForRole.amendSystemData.canaddamendsystemscomments;
    roleObject.canprovideamendsystemsstudy = objForRole.amendSystemData.canprovideamendsystemsstudy;
    roleObject.canreceiveamendsystemsstudy = objForRole.amendSystemData.canreceiveamendsystemsstudy;
    roleObject.canassignamendsystemssector = objForRole.amendSystemData.canassignamendsystemssector;
    roleObject.canassignamendsystemsdepartment = objForRole.amendSystemData.canassignamendsystemsdepartment;
    roleObject.canassignamendsystemsemployee = objForRole.amendSystemData.canassignamendsystemsemployee;
    roleObject.canassignamendsystemsbacktodepartment = objForRole.amendSystemData.canassignamendsystemsbacktodepartment;
    roleObject.canassignamendsystemsbacktoentity = objForRole.amendSystemData.canassignamendsystemsbacktoentity;
    roleObject.canassignamendsystemsrequesttogm = objForRole.amendSystemData.canassignamendsystemsrequesttogm;

    // report and static
    roleObject.cansubmitreportsstaticsrequest = objForRole.reportStatisticData.cansubmitreportsstaticsrequest;
    roleObject.canviewreportsstaticsrequest = objForRole.reportStatisticData.canviewreportsstaticsrequest;
    roleObject.caneditreportsstaticsrequest = objForRole.reportStatisticData.caneditreportsstaticsrequest;
    roleObject.canupdatereportsstaticsstatus = objForRole.reportStatisticData.canupdatereportsstaticsstatus;
    roleObject.canshowreportsstaticscomments = objForRole.reportStatisticData.canshowreportsstaticscomments;
    roleObject.canaddreportsstaticscomments = objForRole.reportStatisticData.canaddreportsstaticscomments;
    roleObject.canprovidereportsstaticsstudy = objForRole.reportStatisticData.canprovidereportsstaticsstudy;
    roleObject.canreceivereportsstaticsstudy = objForRole.reportStatisticData.canreceivereportsstaticsstudy;
    roleObject.canassignreportsstaticssector = objForRole.reportStatisticData.canassignreportsstaticssector;
    roleObject.canassignreportsstaticsdepartment = objForRole.reportStatisticData.canassignreportsstaticsdepartment;
    roleObject.canassignreportsstaticsemployee = objForRole.reportStatisticData.canassignreportsstaticsemployee;
    roleObject.canassignreportsstaticsbacktodepartment = objForRole.reportStatisticData.canassignreportsstaticsbacktodepartment;
    roleObject.canassignreportsstaticsbacktoentity = objForRole.reportStatisticData.canassignreportsstaticsbacktoentity;
    roleObject.canassignreportsstaticsrequesttogm = objForRole.reportStatisticData.canassignreportsstaticsrequesttogm;

    // other request 
    roleObject.cansubmitotherrequestsrequest = objForRole.otherRequestData.cansubmitotherrequestsrequest;
    roleObject.canviewotherrequestsrequest = objForRole.otherRequestData.canviewotherrequestsrequest;
    roleObject.caneditotherrequestsrequest = objForRole.otherRequestData.caneditotherrequestsrequest;
    roleObject.canupdateotherrequestsstatus = objForRole.otherRequestData.canupdateotherrequestsstatus;
    roleObject.canshowotherrequestscomments = objForRole.otherRequestData.canshowotherrequestscomments;
    roleObject.canaddotherrequestscomments = objForRole.otherRequestData.canaddotherrequestscomments;
    roleObject.canprovideotherrequestsstudy = objForRole.otherRequestData.canprovideotherrequestsstudy;
    roleObject.canreceiveotherrequestsstudy = objForRole.otherRequestData.canreceiveotherrequestsstudy;
    roleObject.canassignotherrequestssector = objForRole.otherRequestData.canassignotherrequestssector;
    roleObject.canassignotherrequestsdepartment = objForRole.otherRequestData.canassignotherrequestsdepartment;
    roleObject.canassignotherrequestsemployee = objForRole.otherRequestData.canassignotherrequestsemployee;
    roleObject.canassignotherrequestsbacktodepartment = objForRole.otherRequestData.canassignotherrequestsbacktodepartment;
    roleObject.canassignotherrequestsbacktoentity = objForRole.otherRequestData.canassignotherrequestsbacktoentity;
    roleObject.canassignotherrequesttogmsrequesttogm = objForRole.otherRequestData.canassignotherrequesttogmsrequesttogm;
    
    roleObject.canCloseRequest=objForRole.closerequestData.canCloseRequest;
    roleObject.canViewEmployeeReport=objForRole.canViewEmployeeReportData.canViewEmployeeReport;
    roleObject.canGenerateAllreports=objForRole.canGenerateAllreportsData.canGenerateAllreports;
    
    if (getDataFromLocalStorage) {
        roleObject.userid = getDataFromLocalStorage.id;
    }

    if (lan === 'ar')
        roleObject.lang = 1;
    else
        roleObject.lang = 0;

    return roleObject;
}

// organization structure

export const saveFunctionalDataPayload = (obj) => {
    // get language
    // let lan = localStorage.getItem('Lang');

    let functionalBlockObject = {};
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    if (getDataFromLocalStorage) {
        functionalBlockObject.entityId = getDataFromLocalStorage.EntityID;
        // functionalBlockObject.Userid = getDataFromLocalStorage.Id;
    }

    functionalBlockObject.nameEN = obj.nameEN;
    functionalBlockObject.nameAR = obj.nameAR;
    functionalBlockObject.typeId = obj.typeId;
    functionalBlockObject.parentId = obj.parentId;
    functionalBlockObject.Id = obj.Id;
    functionalBlockObject.unitType = obj.unittype;
    functionalBlockObject.branch = obj.branch;
    functionalBlockObject.level = obj.level;


    if (getDataFromLocalStorage) {
        functionalBlockObject.entityId = getDataFromLocalStorage.EntityID;
        // functionalBlockObject.Userid = getDataFromLocalStorage.Id;
    }

    // if (lan == 'ar')
    //     functionalBlockObject.lang = 1;
    // else
    //     functionalBlockObject.lang = 0;

    return functionalBlockObject;
}

export const getMasterPayload = (obj) => {
    let lan = localStorage.getItem('Lang');

    let masterObj = {};
    masterObj.filter = obj.filter;
    if (lan === 'ar')
        masterObj.lang = 1;
    else
        masterObj.lang = 0;

    return masterObj;
}

export const getDashboardPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let dashobardPayload = {};
    if (getDataFromLocalStorage) {
        dashobardPayload.entityID = getDataFromLocalStorage.EntityID;
        dashobardPayload.userid = getDataFromLocalStorage.id;
        // dashobardPayload.admin = getDataFromLocalStorage.permissionType && getDataFromLocalStorage.permissionType.isadmin;
        dashobardPayload.internalcontact = getDataFromLocalStorage && getDataFromLocalStorage.internalcontact;
    }
    if (lan === 'ar')
        dashobardPayload.lang = 1;
    else
        dashobardPayload.lang = 0;
        try{
        dashobardPayload.startdate=obj.startdate;
        dashobardPayload.enddate=obj.enddate;
        }
        catch{}
    return dashobardPayload;
}

export const dashboardListPayload = (status, requestType) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let dashobardPayloadObj = {};

    dashobardPayloadObj.requestType = requestType;
    dashobardPayloadObj.status = status;
    if (getDataFromLocalStorage) {
        dashobardPayloadObj.entityID = getDataFromLocalStorage.EntityID;
        dashobardPayloadObj.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        dashobardPayloadObj.lang = 1;
    else
        dashobardPayloadObj.lang = 0;

    return dashobardPayloadObj;
}

export const uploadDocument = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let uploadDocumentPayload = {};
    let pathName = localStorage.getItem('currentPath');
    if (pathName) {
        if(pathName === 'ActionPlansInitiatives') {
            uploadDocumentPayload.requestType = Config.getActionPlanId()
        }
     
if(pathName === 'WorkShopAdd') {
    uploadDocumentPayload.requestType = Config.getWorkShopTypeId()
}
if(pathName === 'Disciplinaryboard')
    {
        uploadDocumentPayload.requestType=Config.getDisciplinaryboardTypeId();
    }
        else if (pathName === 'SCIRequest') {
            uploadDocumentPayload.requestType = Config.getSciRequestTypeId()
     }
         else if (pathName === 'OrganizationStructure') {
             if(obj.position===1)
             uploadDocumentPayload.requestType = Config.getORGRequestPosition()
              else
            uploadDocumentPayload.requestType = Config.getORGRequestTypeId()
        } 
      
        else if (pathName === 'informationRequest') {
            uploadDocumentPayload.requestType = Config.getInformationRequestTypeId()
        } else if (pathName === 'bonusRequest') {
            uploadDocumentPayload.requestType = Config.getBonusRequestTypeId()
        } else if (pathName === 'amendSalaryScreen') {
            uploadDocumentPayload.requestType = Config.getAmendSalaryScaleRequestTypeId()
        } else if (pathName === 'amendEmployeeSalary') {
            uploadDocumentPayload.requestType = Config.getAmendEmployeeSalaryRequestTypeId()
        } else if (pathName === 'reviewHRPolicies') {
            uploadDocumentPayload.requestType = Config.getHrReviewPolicyRequestTypeId()
        } else if (pathName === 'exceptionADHousing') {
            uploadDocumentPayload.requestType = Config.getExceptionADHousingRequestTypeId()
        } else if (pathName === 'secondmentForFederalAgency') {
            uploadDocumentPayload.requestType = Config.getSecondmentForFederalAgencyRequestTypeId()
        } else if (pathName === 'exemptionFromAccompanyPatient') {
            uploadDocumentPayload.requestType = Config.getExceptionAcompanypatientRequestTypeId()
        } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
            uploadDocumentPayload.requestType = Config.getRetirmentRequestTypeId()
        } else if (pathName === 'entity') {
            uploadDocumentPayload.requestType = Config.getEntityRequestType()
        } else if (pathName === 'extendCreateObjectives') {
            uploadDocumentPayload.requestType = Config.getExtendCreateObjectiveId()
        } else if (pathName === 'extendReviewObjectives') {
            uploadDocumentPayload.requestType = Config.getExtendReviewObjectiveId()
        } else if (pathName === 'extendAnnualPerformance') {
            uploadDocumentPayload.requestType = Config.getExtendAnnualPerformaceEvaluationId()
        } else if (pathName === 'addOrEditEmployeesPerformanceEvaluation') {
            uploadDocumentPayload.requestType = Config.getAddAndEditEmployeePerformanceEvaluationId()
        } else if (pathName === 'joinAndImplementGovResourcesSystemForNewEntity') {
            uploadDocumentPayload.requestType = Config.getJoinAndImplementId()
        } else if (pathName === 'openComplainsStage') {
            uploadDocumentPayload.requestType = Config.getOpenComplainsStageId()
        } else if (pathName === 'amendsSharedGovernmentHRManagement') {
            uploadDocumentPayload.requestType = Config.getAmendOnSharedGovermentId()
        } else if (pathName === 'reportsAndStaticRequest') {
            uploadDocumentPayload.requestType = Config.getReportAndStaticId()
        } else if (pathName === 'otherRequests') {
            uploadDocumentPayload.requestType = Config.getOtherRequest()
        }
        else if (pathName === 'Promotion') {
            uploadDocumentPayload.requestType = Config.getPromotionTypeId()
        }
        else if (pathName === 'SickLeave') {
            uploadDocumentPayload.requestType = Config.getSickLeaveTypeId()
        }
        else
        {
            uploadDocumentPayload.requesttype=obj.requesttype;
        }
    }

    if (getDataFromLocalStorage) {
        uploadDocumentPayload.entityID = getDataFromLocalStorage.EntityID;
        uploadDocumentPayload.userid = getDataFromLocalStorage.id;
    }
    uploadDocumentPayload.requestid = obj.requestid;
    uploadDocumentPayload.filename = obj.filename;
    uploadDocumentPayload.fileContent = obj.fileContent;
    uploadDocumentPayload.filetype = obj.filetype;

    if (lan === 'ar')
        uploadDocumentPayload.lang = 1;
    else
        uploadDocumentPayload.lang = 0;

    return uploadDocumentPayload;
}

export const downloadAttachment = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let downloadDocumentPayload = {};
    downloadDocumentPayload.filename = obj.filename;
    downloadDocumentPayload.requestid = obj.requestid;
    downloadDocumentPayload.requesttype = obj.requesttype;
    if (getDataFromLocalStorage) {
        downloadDocumentPayload.entityID = getDataFromLocalStorage.EntityID;
        downloadDocumentPayload.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        downloadDocumentPayload.lang = 1;
    else
        downloadDocumentPayload.lang = 0;

    return downloadDocumentPayload;
}

export const assginGmPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    let pathName = localStorage.getItem('currentPath');

    let assignGmObject = {};
    // if (pathName) {
    //     if (pathName === 'SCIRequest') {
    //         assignGmObject.requestType = Config.getSciRequestTypeId()
    //     } else if (pathName === 'informationRequest') {
    //         assignGmObject.requestType = Config.getInformationRequestTypeId()
    //     } else if (pathName === 'bonusRequest') {
    //         assignGmObject.requestType = Config.getBonusRequestTypeId()
    //     } else if (pathName === 'amendSalaryScreen') {
    //         assignGmObject.requestType = Config.getAmendSalaryScaleRequestTypeId()
    //     } else if (pathName === 'amendEmployeeSalary') {
    //         assignGmObject.requestType = Config.getAmendEmployeeSalaryRequestTypeId()
    //     } else if (pathName === 'reviewHRPolicies') {
    //         assignGmObject.requestType = Config.getHrReviewPolicyRequestTypeId()
    //     } else if (pathName === 'exceptionADHousing') {
    //         assignGmObject.requestType = Config.getExceptionADHousingRequestTypeId()
    //     } else if (pathName === 'secondmentForFederalAgency') {
    //         assignGmObject.requestType = Config.getSecondmentForFederalAgencyRequestTypeId()
    //     } else if (pathName === 'exemptionFromAccompanyPatient') {
    //         assignGmObject.requestType = Config.getExceptionAcompanypatientRequestTypeId()
    //     } else if (pathName === 'exceptionOfSteerTheAgeOfRetirementRequest') {
    //         assignGmObject.requestType = Config.getRetirmentRequestTypeId()
    //     } 
    // }

    if (getDataFromLocalStorage) {
        assignGmObject.entityId = getDataFromLocalStorage.EntityID;
        assignGmObject.Userid = getDataFromLocalStorage.id;
    }
    assignGmObject.requestType = obj.requesttype;
    assignGmObject.requestid = obj.requestid;
    assignGmObject.statusid = obj.statusid;
    assignGmObject.reasonid = obj.reasonid;
    assignGmObject.requestnumber = obj.requestnumber;
    assignGmObject.notes = obj.notes;


    assignGmObject.gmofficeid = Config.getReasonForGmAssignedId();
    if (lan === 'ar')
        assignGmObject.lang = 1;
    else
        assignGmObject.lang = 0;

    return assignGmObject;
}

export const assginBackToEntityPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let assignBackToEntityObject = {};

    if (getDataFromLocalStorage) {
        assignBackToEntityObject.entityId = getDataFromLocalStorage.EntityID;
        assignBackToEntityObject.Userid = getDataFromLocalStorage.id;
    }
    assignBackToEntityObject.requestType = obj.requesttype;
    assignBackToEntityObject.notes = obj.notes;
    assignBackToEntityObject.requestid = obj.requestid;
    assignBackToEntityObject.statusid = obj.statusid;
    assignBackToEntityObject.reasonid = obj.reasonid;
    assignBackToEntityObject.requestnumber = obj.requestnumber;
    if (lan === 'ar')
        assignBackToEntityObject.lang = 1;
    else
        assignBackToEntityObject.lang = 0;

    return assignBackToEntityObject;
}

export const assginSectorPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let assignSectorObject = {};
    if (getDataFromLocalStorage) {
        assignSectorObject.entityId = getDataFromLocalStorage.EntityID;
        assignSectorObject.Userid = getDataFromLocalStorage.id;
    }
    assignSectorObject.requestid = obj.requestid;
    assignSectorObject.requesttype = obj.requesttype;
    assignSectorObject.notes = obj.notes;
    assignSectorObject.targetdate = obj.targetdate;
    assignSectorObject.sectorid = obj.sectorid;
    assignSectorObject.statusid = obj.statusid;
    assignSectorObject.reasonid = obj.reasonid;
    assignSectorObject.requestnumber = obj.requestnumber;

    if (lan === 'ar')
        assignSectorObject.lang = 1;
    else
        assignSectorObject.lang = 0;

    return assignSectorObject;
}

export const assignDepartmentPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let assignDepartmentObject = {};
    if (getDataFromLocalStorage) {
        assignDepartmentObject.entityId = getDataFromLocalStorage.EntityID;
        assignDepartmentObject.Userid = getDataFromLocalStorage.id;
    }
    assignDepartmentObject.requestId = obj.requestid;
    assignDepartmentObject.requestType = obj.requesttype;
    assignDepartmentObject.departmentid = obj.departmentid;
    assignDepartmentObject.notes = obj.notes;
    assignDepartmentObject.statusid = obj.statusid;
    assignDepartmentObject.reasonid = obj.reasonid;
    assignDepartmentObject.requestnumber = obj.requestnumber;
    if (lan === 'ar')
        assignDepartmentObject.lang = 1;
    else
        assignDepartmentObject.lang = 0;

    return assignDepartmentObject;
}

export const assignEmployeePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let assignEmployeeObject = {};
    if (getDataFromLocalStorage) {
        assignEmployeeObject.entityId = getDataFromLocalStorage.EntityID;
        assignEmployeeObject.Userid = getDataFromLocalStorage.id;
    }
    assignEmployeeObject.requestId = obj.requestid;
    assignEmployeeObject.requestType = obj.requesttype;
    assignEmployeeObject.notes = obj.notes;
    assignEmployeeObject.employeeid = obj.employeeid;
    assignEmployeeObject.statusid = obj.statusid;
    assignEmployeeObject.reasonid = obj.reasonid;
    assignEmployeeObject.requestnumber = obj.requestnumber;
    if (lan === 'ar')
        assignEmployeeObject.lang = 1;
    else
        assignEmployeeObject.lang = 0;

    return assignEmployeeObject;
}

export const passwordResetPayload = (obj) => {
    let lan = localStorage.getItem('Lang');

    let passwordResetObject = {};
    passwordResetObject.email = obj.email;
    if (lan === 'ar')
        passwordResetObject.lang = 1;
    else
        passwordResetObject.lang = 0;

    return passwordResetObject;
}

export const updateStudyorFeedbackPayload = (obj) => {
   
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let updateStudyorFeedbackObject = {};
    updateStudyorFeedbackObject.requestid = obj.requestid;
    updateStudyorFeedbackObject.requesttype = obj.requesttype;
    updateStudyorFeedbackObject.study = obj.study;
    updateStudyorFeedbackObject.CheckedComments=obj.CheckedComments;
    
    //updateStudyorFeedbackObject.CheckedComments = obj.CheckedComments
    
    if (getDataFromLocalStorage) {
       
        updateStudyorFeedbackObject.entityID = getDataFromLocalStorage.EntityID;
        updateStudyorFeedbackObject.userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        updateStudyorFeedbackObject.lang = 1;
    else
        updateStudyorFeedbackObject.lang = 0;

       
    return updateStudyorFeedbackObject;
}

export const changePasswordPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let changePasswordObject = {};
    changePasswordObject.newpassword = obj.newpassword;
    changePasswordObject.confirmpassword = obj.confirmpassword;
    if (getDataFromLocalStorage) {
        // changePasswordObject.entityID = getDataFromLocalStorage.EntityID;
        changePasswordObject.userid = getDataFromLocalStorage.id;
    }

    return changePasswordObject;
}

export const getDependentResonPayload = (obj) => {
    let lan = localStorage.getItem('Lang');

    let denpendentReasonFilterObject = {};
    denpendentReasonFilterObject.filter = obj.filter;
    denpendentReasonFilterObject.parentid = obj.parentid;
    if (lan === 'ar')
        denpendentReasonFilterObject.lang = 1;
    else
        denpendentReasonFilterObject.lang = 0;

    return denpendentReasonFilterObject;
}

export const getContactPayload = (type) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let contactObject = {};
    contactObject.id = 0;
    contactObject.type = type;
    if (getDataFromLocalStorage) {
        contactObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        contactObject.lang = 1;
    else
        contactObject.lang = 0;

    return contactObject;
}

export const getEntitiesPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let entityObject = {};
    entityObject.id = 0;
    if (getDataFromLocalStorage) {
        entityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        entityObject.lang = 1;
    else
        entityObject.lang = 0;

    return entityObject;
}

export const getRoleListPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let entityObject = {};
    entityObject.id = 0;
    if (getDataFromLocalStorage) {
        entityObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        entityObject.lang = 1;
    else
        entityObject.lang = 0;

    return entityObject;
}

export const getMailTemplatePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let contactObject = {};
    contactObject.id = 0;
    if (getDataFromLocalStorage) {
        contactObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        contactObject.lang = 1;
    else
        contactObject.lang = 0;

    return contactObject;
}

export const getRolePayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let contactObject = {};

    contactObject.id = 0;
    if (getDataFromLocalStorage) {
        contactObject.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
        contactObject.lang = 1;
    else
        contactObject.lang = 0;

    return contactObject;
}

export const getFunctionBlockPayload = () => {
    // get language
     let lan = localStorage.getItem('Lang');

    let functionalBlockObject = {};
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    if (getDataFromLocalStorage) {
        functionalBlockObject.EntityID = getDataFromLocalStorage.EntityID;
         functionalBlockObject.Userid = getDataFromLocalStorage.id;
    }
     if (lan === 'ar')
         functionalBlockObject.lang = 1;
     else
         functionalBlockObject.lang = 0;

    return functionalBlockObject;
}

export const deleteFunctionalDataPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let functionalBlockObject = {};
    functionalBlockObject.id = obj.id;
    if (getDataFromLocalStorage) {
        // functionalBlockObject.entityId = getDataFromLocalStorage.EntityID;
        // functionalBlockObject.Userid = getDataFromLocalStorage.Id;
    }
    // if (lan == 'ar')
    //     functionalBlockObject.lang = 1;
    // else
    //     functionalBlockObject.lang = 0;

    return functionalBlockObject;
}

export const editFunctionalDataPayload = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let functionalBlockObject = {};
    functionalBlockObject.nameEN = obj.nameEN;
    functionalBlockObject.nameAR = obj.nameAR;
    functionalBlockObject.typeId = obj.typeId;
    functionalBlockObject.parentId = obj.parentId;
    functionalBlockObject.id = obj.id;

    if (getDataFromLocalStorage) {
        functionalBlockObject.entityId = getDataFromLocalStorage.EntityID;
        // functionalBlockObject.Userid = getDataFromLocalStorage.Id;
    }
    // if (lan == 'ar')
    //     functionalBlockObject.lang = 1;
    // else
    //     functionalBlockObject.lang = 0;

    return functionalBlockObject;
}
    // Organiztion structure


export const getAllCommentsForRequest = (obj) => {
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let allComments = {};
    allComments.requestid=obj.requestid;
    allComments.requesttype=obj.requesttype;
    

   
    if (getDataFromLocalStorage) {
        allComments.Userid = getDataFromLocalStorage.id;
    }
    if (lan === 'ar')
    allComments.lang = 1;
    else
    allComments.lang = 0;

    return allComments;
}


    export const OrganizationStrucutreRequestPayload = (obj) => {
        let lan = localStorage.getItem('Lang');
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        let OrganizationStrucutreRequestObject = {};
        // if (obj.isEdit) {
        if (obj.requestId > 0) {
            OrganizationStrucutreRequestObject.id = obj.requestId;
        } else {
            OrganizationStrucutreRequestObject.id = 0;
        }
        OrganizationStrucutreRequestObject.draft = obj.draft;
        // OrganizationStrucutreRequestObject.draft = 0;
        OrganizationStrucutreRequestObject.email = obj.email;
        OrganizationStrucutreRequestObject.requestdate = obj.requestdate;
        OrganizationStrucutreRequestObject.employeename = obj.employeename;
        OrganizationStrucutreRequestObject.contactnumber = obj.contactnumber;
        OrganizationStrucutreRequestObject.requestdetails = obj.requestdetails;
        if (getDataFromLocalStorage) {
            OrganizationStrucutreRequestObject.entityid = getDataFromLocalStorage.EntityID;
            OrganizationStrucutreRequestObject.Userid = getDataFromLocalStorage.id;
        }
        if (lan === 'ar')
            OrganizationStrucutreRequestObject.lang = 1;
        else
            OrganizationStrucutreRequestObject.lang = 0;
    
        return OrganizationStrucutreRequestObject;
    }
    
    export const getAllOrganizationStructureRequestForEntityPayload = () => {
        let lan = localStorage.getItem('Lang');
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        let allSciRequestForEntityObject = {};
        if (getDataFromLocalStorage) {
            allSciRequestForEntityObject.EntityID = getDataFromLocalStorage.EntityID;
            allSciRequestForEntityObject.Userid = getDataFromLocalStorage.id;
            allSciRequestForEntityObject.admin = getDataFromLocalStorage.permissionType && 
            getDataFromLocalStorage.permissionType.isadmin;
        }
        if (lan === 'ar')
            allSciRequestForEntityObject.lang = 1;
        else
            allSciRequestForEntityObject.lang = 0;
    
        return allSciRequestForEntityObject;
    }

    export const GetLastActionIdPayload = (obj) => 
{
  
    let lan = localStorage.getItem('Lang');
    let getDataFromLocalStorage = Config.getLocalStorageLoginData();

    let GETLastActionByIDObject = {};

    if(lan=='ar')
    GETLastActionByIDObject.lang=1;
    else
    GETLastActionByIDObject.lang=0;

    GETLastActionByIDObject.userID = getDataFromLocalStorage.id;
    GETLastActionByIDObject.RequestID = obj.requestid;
    GETLastActionByIDObject.RequestType=obj.requestType;
    return GETLastActionByIDObject;
}