import React, { Component } from 'react';
import { MDBBtn, MDBModalBody,MDBModalHeader, MDBRow, MDBCol,MDBModal } from "mdbreact";
import i18n from '../../i18n';
import { saveStatusPayload} from '../../utils/Payload';
import LoadingOverlay from 'react-loading-overlay';

import ApiRequest from '../../services/ApiRequest';
import Config from '../../utils/Config';

export default class OrganizationStructureCompleted extends Component {

    constructor(props) {
        super(props)
        this.state = {
            requesttypeid: '',
            requestType: '',
            isLoading: false,
            employeeId: 0,
            departmentId: 0,
            addedComent: '',
            value: '',
            title: '',
            pathname: '',
            employeeArray: [],
            departmentArray: [],
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,

        }
    }

    componentDidMount = () => {
      
      
        if (this.props.requesttypeid) {
            this.setState({
                requesttypeid: this.props.requesttypeid
            });        }
       
    
         
    }

  

    CallOrganizationStructureCompleted = () => {
        let obj = {};
        obj.requestid = this.props.id;
        obj.requestnumber = this.props.currentRequestNumber;
        // obj.requesttype = this.state.requestType;
        obj.requesttype = this.props.requesttypeid;
       

        obj.reasonid = 897;
        obj.statusid = 31;
        // obj.reasonid = Config.getReasonAcceptedId();
        obj.comment = this.state.addedComent;

        let payload = saveStatusPayload(obj);
        let endPoint = 'SaveStatus'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        this.props.toggle(true);
                        Config.setCanViewTree(0);
                    } else {
                        this.props.toggle(false);
                        console.log(res.Message);
                    }
                })
            })
        })
            }

  
    submitHandler = (event) => {
       
        event.preventDefault();
        event.target.className += " was-validated";
      
            this.CallOrganizationStructureCompleted();
        
    }
    

    render() {
     

        return (
               <React.Fragment>

                 <MDBModalHeader  style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>
                        
                      {i18n[this.state.isArabic?'ar':'en'].OrganizationStructureCompleted}
                      </MDBModalHeader >
                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate>
                   <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>
                       
                       < MDBModalBody  style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>

                        <MDBRow md="12" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', }}>
                            <MDBCol md={12} style={{ display: 'flex', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }} className="form-group">
                                    <label style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }} htmlFor="formGroupExampleInput">
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                    </label>
                                    <textarea
                                        className="form-control" id="exampleFormControlTextarea2" rows="2" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        // required
                                        onChange={(event) => {
                                            this.setState({
                                                addedComent: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }} >
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].SCI.submit}
                            </MDBBtn>
                            <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
                        </MDBRow>
                        </MDBModalBody>
                        </LoadingOverlay>
                </form>
                </React.Fragment>
        );
    }
}