import React, { Component } from 'react';
import DownloadComponent from  "../../components/DownloadComponent";
import CKEditor from "react-ckeditor-component";
import InputMask from 'react-input-mask';
import Validator from "../../utils/Validator";

import { MDBContainer, MDBRow, MDBCol,MDBBtn,MDBTableBody,MDBTable,MDBTableHead,MDBModalHeader,MDBModalBody,MDBModal } from "mdbreact";
import TableLabel from '../../components/TableLabel';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../../components/Footer';
import { saveSecondmentRequestPayload, uploadDocument,downloadAttachment } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import WorkShopList from '../../components/WorkShop/WorkShopList'  ;
import { Card,Accordion,Button  } from 'react-bootstrap';
import './../../css/bootstrap.min.css';
import './../../css/style.css';

let moment = require('moment');
// const decoratedOnClick = useAccordionToggle(eventKey, onClick);

export default class WorkShopBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AttendancenameErr:'1',
            AttendanceJobTitleErr:'1',
            AttendanceJobDegreeErr:'1',
            AttendanceEmailErr:'1',
            AttendancePhoneErr:'1',
            AttendanceArray:[],
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            ShowDetails:false,EnityName:'',
            PersonErr:'',
            Person:'',
            PhoneNumber:'',
            Email:'',
PhoneNumberErr:'',
EmailErr:'',

AttandanceNumberErr:'',
AttandanceNumber:'',
WorkShopDate:'',
WorkShopDateErr:'',
EntityID:'',
WorkShopID:'',
MaxAllowedAtt:'',
RequireAddAttandanceDetails:'0'

        }
    }
    
    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }
    EditWorkShop = (item) =>{
       
        Config.setWorkShopItem(item);
        
        this.create();
        
    }
    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }

    alertSubmitForSuccess = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok,
                    onClick: () => this.props.history.push("/WorkShopManage")
                 
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };
    alertSubmit = () => {
        confirmAlert({
            title: this.state.titleAlert,
            message: this.state.messageAlert,
            buttons: [
                {
                    label: i18n[this.getLocale()].ok
                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
        });
        setTimeout(() => {
            document.getElementById('alertOkButton').focus()
        }, 100);
    };

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    callGetAllWorkShops = () => {
        let payload = {};
        payload.Upcoming=1;
        let endPoint = 'GetallWorkShops '
        let url = Config.getUrl(endPoint);
       this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            Title:resArray[index].Title,
                            WorkShopList:resArray[index].ID,
                            WorkShopDates:resArray[index].workShopDates,
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    this.setState({
                        data: mainArray,
                        popUpRowArray: resArray,
                        actualArray: resArray,
                        dataRow: actualRowArray
                    }, () => {
                        this.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArray: [],
                    }, () => {
                        this.updateListData(this.state.data, this.state.popUpRowArray);
                    })
                }
            } else {
                // TODO
            }
        })
    }
    toggle = (reloadFlag) => {
        debugger;
        this.setState({
            modal: !this.state.modal,
            ShowDetails :!this.state.ShowDetails
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState
                }, () => {
                    this.props.history.goBack();
                    //this.componentDidMount();
                });
            }
        });
    }
    ViewDates =(index)=>
    {
      
        this.setState({selectedIndex:index ,ShowDetails: true});
        this.toggle();
    }
    renderDates ()
    {
      
        let index = this.state.selectedIndex ? this.state.selectedIndex: null;
        if(index !=null)
        
        {
                  
        let selecteddates = this.state.popUpRowArray[index].workShopDates;

        return (
            <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                {
                    <MDBContainer style={{ display: 'flex: ', flexDirection: !this.state.isArabic ? 'row-reverse' : 'row', height: '100%', }}>
                        <MDBRow style={{ display: 'flex', flexDirection: !this.state.isArabic ? 'row-reverse' : 'row', height: '100%', margin: '20px' }} >
                            <MDBCol md="12">
                                <MDBTable striped responsive hover style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                                    <MDBTableHead>
                                        <tr style={{ textAlign: "center" }}>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.WorkShopDate}
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.WorkShopTimefrom}   
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.WorkShopTimeTo} 
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.Location} 
                                           </th>
                                           <th>
                                           {i18n[this.getLocale()].WorkShop.AttandanceNumber} 
                                           </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {
                                           selecteddates ? selecteddates.map((item, index) => {
                                                return (<tr style={{ textAlign: "center" }}>
                                                    <td>
                                                        {item.WorkShopDate}
                                                    </td>
                                                   
                                                    <td>
                                                        {item.StartTime}
                                                    </td>
                                                    <td>
                                                        {item.EndTime}
                                                    </td>
                                                    <td>
                                                        {item.WorkShopLocation}
                                                    </td>
                                                    <td>
                                                        {item.ReaminAttandance}
                                                    </td>
                                                </tr>)
                                            }) : ''
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                }
            </div>
        );
       
        }
    }
    renderDataTableRows = (item, index) => {
        return (
            <tr style={{ textAlign: "center", padding: '10%' }}>
            <td>{item.Title}</td>
            <td dangerouslySetInnerHTML ={{__html: item.DiscussPoints}} />
            <td>{   item.attachments.map((item, index) => {

return (
    
        <div style={{display:"inline",flex:"auto",float:this.state.isArabic ?"right":"left",padding:"5px",marginLeft:"10px",marginRight:"10px"}}>
      

            {this.state.isArabic ?
                                                    <img onClick={() => this.saveFile(item)} style={{
                                                        height: "auto", display: "inline-block", valign: "middle",
                                                        verticalAlign: "middle", width: "auto", maxWidth: "40px", maxHeight: "40px", marginLeft: '10px', cursor: "pointer"
                                                    }} alt="" src={require('../../assets/images/BlueDownloads.png')}
                                                        className="img-fluid" />
                                                    : ''}
                                                  
        {'   '+ item.filename}
        
        
      
                                              
                                                    

</div>
        
        )
    })    
}</td>
            <td>
            <MDBBtn
                                    className="gridActionBtn "
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.ViewDates(index)}                       >
                                        {i18n[this.getLocale()].WorkShop.ViewDates}
                                 
                                </MDBBtn>
                 <MDBBtn
                                    className="gridActionBtn GreenBG"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.EditWorkShop(item)}                       >
                                        {i18n[this.getLocale()].WorkShop.Edit}
                                 
                                </MDBBtn>
  </td>
            </tr>
        )
    }
    updateListData = (data, popUpRowArray) => {
        
      
        
        let tempIndex = 0;
        if (popUpRowArray && popUpRowArray.length === 1) {
      
            this.setState({
                data: data,
                popUpRowArray: popUpRowArray,
                selectedIndex: tempIndex
            });
        } else {
      
            
            this.setState({
                data: data,
                popUpRowArray: popUpRowArray,
            });
        }
    }

    componentWillMount = () => {
    }

    submitHandler= event =>
    {
        debugger;
       event.preventDefault();
       event.target.className += " was-validated";
       let AttandanceNumberErr = "",WorkShopDateErr="",PhoneNumberErr="",EmailErr="",PersonErr="";
  
       if (!this.state.AttandanceNumber) {
        AttandanceNumberErr = 'thisFieldIsRequired';
       }
       
       {
           let AttandanceNumber =parseInt(this.state.AttandanceNumber,0);
           if(AttandanceNumber>this.state.MaxAllowedAtt)
           {
            AttandanceNumberErr= 'invalidAttandanceNumber';
         
           }
           if(AttandanceNumber<1)
           {
            AttandanceNumberErr = 'cantbookedzero'; 
           }
       }
       
       if (!this.state.WorkShopDate) {
        WorkShopDateErr = 'thisFieldIsRequired';
       }
       if (!this.state.Email) {
        EmailErr = 'thisFieldIsRequired';
       }
       if (!this.state.PhoneNumber) {
        PhoneNumberErr = 'thisFieldIsRequired';
       }
       if (!this.state.Person) {
        PersonErr = 'thisFieldIsRequired';
       }

    


       this.setState({
        AttandanceNumberErr: AttandanceNumberErr,
        WorkShopDateErr: WorkShopDateErr,
        EmailErr: EmailErr,
        PhoneNumberErr: PhoneNumberErr,
        PersonErr: PersonErr,
          
   
       }, () => 
       {
           if (!AttandanceNumberErr && !WorkShopDateErr && !EmailErr && !PhoneNumberErr&& !PersonErr  ) {
               this.submitApiCall();

           }
       });

    }
    submitApiCall = () => {
      
        let tempEditObj = Config.getEditObj();
        let tempStatusId = '';
        if (tempEditObj) {
            Config.setIsEditFlag(false);
            Config.setPreviewObj();
            Config.setPreviewAttachmentArray();
            Config.setPreviewckEditorArray();
        }
        let payload = {};
        let lan = localStorage.getItem('Lang');
        if (lan === 'ar')
            payload.lang = 1;
        else
            payload.lang = 0;
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (this.state.requestId > 0) {
            payload.id = this.state.requestId;
        } else {
            payload.id = 0;
        }
    
        if (getDataFromLocalStorage) {
    
            payload.Requesterid = getDataFromLocalStorage.id;
        }
      
        payload.WorkShopTimeID = this.state.WorkShopID;
        payload.AttadeceNumbrer = this.state.AttandanceNumber;
        payload.EntityID = this.state.EntityID;
        payload.Name = this.state.Person;
        payload.Phone = this.state.PhoneNumber;
        payload.Email = this.state.Email;
        let endPoint = 'BookWorkShop'
        let url = Config.getUrl(endPoint);
        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, resAcompanyPatient => {
                if (resAcompanyPatient.Status) {
                    if(this.state.RequireAddAttandanceDetails ==='1' || this.state.RequireAddAttandanceDetails==='True' || this.state.RequireAddAttandanceDetails  )
                    {
                        debugger;
                        let payloadWoSAt={};

                        let tempArray = [];
        // let tempobj ={};
        let focalpoint=this.state.AttendanceArray;
   for(let i=0;i<focalpoint.length;i++)
                        {
                            debugger;

 let   item=focalpoint[i];
        let tempobj ={};
                      tempobj.Attendancename=item.Attendancename;
                             tempobj.AttendanceJobDegree=item.AttendanceJobDegree;
                             tempobj.AttendanceEmail=item.AttendanceEmail;
                             tempobj.AttendancePhone=item.AttendancePhone;
                             tempobj.AttendanceJobTitle=item.AttendanceJobTitle;
                         
           
            tempArray.push(tempobj);

                          
                  

                        }
                        payloadWoSAt.WorkShopID = resAcompanyPatient.data.id;
                      
                        payloadWoSAt.AttandeceMembres=focalpoint;
                        let endPointWoSAt = 'AddWorkShopAttandenace'
                        let urlWoSAt = Config.getUrl(endPointWoSAt);
                        this.setState({
                            isLoading: true
                        }, () => {
                            ApiRequest.sendRequestForPost(urlWoSAt, payloadWoSAt, resAcompanyPatientWoSAt => {
                                if (resAcompanyPatientWoSAt.Status) {
                                    this.setState({
                                        isLoading: false
                                    }, () => {
                                        // this.props.history.replace("/listPage", {
                                        //     pathname: localStorage.getItem('currentPath')
                                        // });
                                        // unable to upload attachment
                                       
                                            this.setState({
                                                titleAlert: i18n[this.getLocale()].success,
                                                messageAlert: i18n[this.getLocale()].bookingSucess
                                            }, () => {
                                                this.alertSubmitForSuccess();
                                            })
                                       
                                    })
                                }
                            
                        })
                    
                    })
                    }
                    else
                    {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // this.props.history.replace("/listPage", {
                            //     pathname: localStorage.getItem('currentPath')
                            // });
                            // unable to upload attachment
                           
                                this.setState({
                                    titleAlert: i18n[this.getLocale()].success,
                                    messageAlert: i18n[this.getLocale()].bookingSucess
                                }, () => {
                                    this.alertSubmitForSuccess();
                                })
                           
                        })
                    }
                 
                    
                }
                else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.setState({
                            titleAlert: i18n[this.getLocale()].error,
                            messageAlert: i18n[this.getLocale()].error_message
                        }, () => {
                            this.alertSubmit();
                        })
                    });
                }
    
            })
        })
     }
     handleEmail= (event) => {
        let Email = event.target.value;
        if (event.target.value !== '') {
            this.setState({ Email: event.target.value }, () => {
                if (Validator.validateEmail(Email))
                {
                    this.setState({ EmailErr: '' });
                } else {
                    this.setState({ EmailErr: 'invalidEmail' });
                }
            });
        } else {
            this.setState({ Email: '',EmailErr: 'thisFieldIsRequired' });
        }

     }
     handlePhoneNumber= (event) => {
        let contactNumber = event.target.value;
        if (event.target.value !== '') {
            this.setState({ PhoneNumber: event.target.value }, () => {
                if (Validator.validateContactNumber(contactNumber))
                {
                    this.setState({ PhoneNumberErr: '' });
                } else {
                    this.setState({ PhoneNumberErr: 'invalidContactNumber' });
                }
            });
        } else {
            this.setState({ PhoneNumber: '',PhoneNumberErr: 'thisFieldIsRequired' });
        }
    }
    handlePerson= (event) => {
        if (event.target.value.trim() != '')
        
        {
           let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
           if (regex.test(event.target.value)) {
               this.setState({ Person: event.target.value });
           }
       } else {
           this.setState({ Person: '' });
       }
    }
    handleAttandanceNumber= (event) => {
        if (event.target.value.trim() != '')
        
        {
           let regex = /^[0-9]+$/;
           if (regex.test(event.target.value)) {
               this.setState({ AttandanceNumber: event.target.value });
           }
       } else {
           this.setState({ AttandanceNumber: '' });
       }
    }
   
    Cancel =()=>{
       this.props.history.replace('/WorkShopBookinglist');
    }
    alertSubmitForSuccess = () => {
       confirmAlert({
           title: this.state.titleAlert,
           message: this.state.messageAlert,
           buttons: [
               {
                   label: i18n[this.getLocale()].ok,
                   onClick: () => this.props.history.push("/WorkShopBookinglist")
                
               }
           ],
           closeOnEscape: false,
           closeOnClickOutside: false,
           childrenElement: () => <button id='alertOkButton'  name={i18n[this.getLocale()].ok}/>,
       });
       setTimeout(() => {
           document.getElementById('alertOkButton').focus()
       }, 100);
   };

   handleAttendancename = (event, index) => {
    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.AttendanceArray;
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;

        if (regex.test(event.target.value)) {


            tempobj[index].Attendancename = event.target.value;
            tempobj[index].AttendancenameErr = '';

            this.setState({
                AttendanceArray: tempobj,
                AttendancenameErr: '',
            });
        }
    } else {


        tempobj[index].Attendancename = event.target.value;
        tempobj[index].AttendancenameErr = 'thisFieldIsRequired';
        this.setState({ AttendancenameErr: '1', AttendanceArray: tempobj });
    }
}
handleAttendanceJobTitle = (event, index) => {
    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.AttendanceArray;
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;

        if (regex.test(event.target.value)) {


            tempobj[index].AttendanceJobTitle = event.target.value;
            tempobj[index].AttendanceJobTitleErr = '';

            this.setState({
                AttendanceArray: tempobj,
                AttendanceJobTitleErr: '',
            });
        }
    } else {


        tempobj[index].AttendanceJobTitle = event.target.value;
        tempobj[index].AttendanceJobTitleErr = 'thisFieldIsRequired';
        this.setState({ AttendanceJobTitleErr: '1', AttendanceArray: tempobj });
    }
}
handleAttendanceJobDegree = (event, index) => {
    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.AttendanceArray;
    if (event.target.value.trim() != '') {
        let regex = /^[\u0621-\u064AA-Za-z0-9 ]{0,50}$/;

        if (regex.test(event.target.value)) {


            tempobj[index].AttendanceJobDegree = event.target.value;
            tempobj[index].AttendanceJobDegreeErr = '';

            this.setState({
                AttendanceArray: tempobj,
                AttendanceJobDegreeErr: '',
            });
        }
    } else {


        tempobj[index].AttendanceJobDegree = event.target.value;
        tempobj[index].AttendanceJobDegreeErr = 'thisFieldIsRequired';
        this.setState({ AttendanceJobDegreeErr: '1', AttendanceArray: tempobj });
    }
}

handleAttendanceEmail = (event, index) => {

    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.AttendanceArray;
    if (event.target.value.trim() != '') {
        if (event.target.value.length <= 50) {
let validation = Validator.validateEmail
(event.target.value);

            if (validation) {
              
                tempobj[index].AttendanceEmail = event.target.value;
                tempobj[index].AttendanceEmailErr = '';

                this.setState({
                    AttendanceArray: tempobj,
                    AttendanceEmailErr: '',
                });
            }
            else {
                tempobj[index].AttendanceEmail =event.target.value;
                tempobj[index].AttendanceEmailErr = '2';
    
                this.setState({
                    AttendanceArray: tempobj,
                    AttendanceEmailErr: '2',
                });
            }
        }
        else {
            tempobj[index].AttendanceEmail = event.target.value;
            tempobj[index].AttendanceEmailErr = '2';

            this.setState({
                AttendanceArray: tempobj,
                AttendanceEmailErr: '2',
            });

        }
    }
    else {


        tempobj[index].AttendanceEmail = '';
        tempobj[index].AttendanceEmailErr = 'thisFieldIsRequired';
        this.setState({ AttendanceEmailErr: '1', AttendanceArray: tempobj });
    }
}
handleAttendancePhone = (event, index) => {
    debugger;
    event.target.parentNode.className += " was-validated";
    let tempobj = this.state.AttendanceArray;
    if (event.target.value.trim() != '') {
        // let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
let validation = Validator.validateContactNumber(event.target.value);

        if (validation) {

          
            tempobj[index].AttendancePhone = event.target.value;
            tempobj[index].AttendancePhoneErr = '';



            this.setState({
                AttendanceArray: tempobj,
                AttendancePhoneErr: '',
            });
        }
        else {
            tempobj[index].AttendancePhone = event.target.value;
            tempobj[index].AttendancePhoneErr = '';
            this.setState({ AttendancePhoneErr: '2', AttendanceArray: tempobj });
        }
    }

    else {


        tempobj[index].AttendancePhone = event.target.value;
        tempobj[index].AttendancePhoneErr = 'thisFieldIsRequired';
        this.setState({ AttendancePhoneErr: '1', AttendanceArray: tempobj });
    }
}
    componentDidMount=()=>
    {
        let loginData = Config.getLocalStorageLoginData();
        let workshopitem = Config.getWorkShopTimeID();
        debugger;
        if (loginData) {
            this.setState({
                loginData: loginData
               
            }, () => {
                let tempEntityName = this.state.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn
                let tempEmployeeName = this.state.isArabic ? loginData.NameAr : loginData.NameEn
                let obj={};
                let tempAttendanceArray=[];
                tempAttendanceArray.push(obj);
               
                this.setState({
                    RequireAddAttandanceDetails:workshopitem.RequireAddAttandanceDetails,
                    AttendanceArray:tempAttendanceArray,
                    EnityName:tempEntityName,
                    Person:tempEmployeeName,
                    PhoneNumber:loginData.ContactNumber,
                    Email:loginData.Email,
                    EntityID:loginData.EntityID,
                    WorkShopDate:workshopitem.WorkShopDate,
                    WorkShopID:workshopitem.WorkShopDatesID,
                    MaxAllowedAtt:workshopitem.ReaminAttandance,
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
        this.callGetAllWorkShops();
    }

    checkValidationAddNewMember()
    {
    
        debugger;
        let errormessage = '';
        let isvalida = true;
       
        if (this.state.AttendancenameErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendancenameErr;
            isvalida = false;
        }
        if (this.state.AttendanceJobDegreeErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendanceJobDegreeErr;
            isvalida = false;
        }
        if (this.state.AttendanceJobTitleErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendanceJobTitleErr;
            isvalida = false;
        }
        if (this.state.AttendancePhoneErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendancePhoneErr;
            isvalida = false;
        }
        if (this.state.AttendancePhoneErr == '2') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendanceinvalidPhoneNumber;
            isvalida = false;
        }
    
        if (this.state.AttendanceEmailErr == '1') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendanceEmailErr;
            isvalida = false;
        }
     
        if (this.state.AttendanceEmailErr == '2') {
            errormessage = errormessage + ' \n -  ' + i18n[this.getLocale()].WorkShopErrorMessage.AttendanceEmailinvalidEmail;
            isvalida = false;
        }
        
    
     
    
        this.setState({
            errormessage: errormessage,
            isValid: isvalida,
        });
        return errormessage;
    }
    addnewmember = () => { 
        
        let isvalida = this.checkValidationAddNewMember();
     
        if(isvalida==='')
        {
         
        let obj = {};
        obj.Attendancename='';
        obj.AttendanceJobTitle='';
        obj.AttendanceJobDegree='';
        obj.AttendanceEmail='';
        obj.AttendancePhone='';

        if (this.state.AttendanceArray.length < 40) {
            this.setState({
                AttendanceArray: this.state.AttendanceArray.concat(obj),
                view: this.state.view + 1,AttendancenameErr:'1',
                AttendanceJobTitleErr:'1',
                AttendanceJobDegreeErr:'1',
                AttendanceEmailErr:'1',
                AttendancePhoneErr:'1',
            });
        }
    }
    else{
        this.setState({
            titleAlert: i18n[this.getLocale()].error,
            messageAlert: isvalida
        }, () => {
            this.alertSubmit();
        })

    }

    }
    toggle = (reloadFlag) => {
        
        debugger;
        this.setState({
            modal: !this.state.modal,
            showDefneationsState:!this.state.showDefneationsState
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState,
                  
                }, () => {
                    alert(this.state.showDefneationsState);
                    this.current.componentDidMount();
                });
            }
        });
    }
    removemember = (indexVal) => {
        var array = this.state.AttendanceArray;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        AttendanceArray: array,
                        FocalPointNameErr:'',
FoaclPointTitleErr:'',
FocalPointPhoneErr:'',
FocalPointEmailErr:'',
FocalPointMobileErr:'',
                    });

                    //ReCalculate total no of days & total salary to be payed
                 
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            AttendanceArray: array
                        });
                     
                    }
                }
            }
        }

    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    create = () => {
        let pathname ='WorkShopAdd';
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    render() {
        const sideBarData = Config.getServiceSideBar('WorkShopBooking', this.state.isArabic, this, 0);
        let element = this.state.AttendanceArray.map((item, index) => {
            return (
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        
                    <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.Attendancename}<span className="requireStar">*</span></label>
        
                                <input
                                    type="text" className="form-control"
        
                                    value={item.Attendancename}
                                    onChange={(value) => { this.handleAttendancename(value, index) }}
                                    required
                                    style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                />
        
                                {
                                    item.AttendancenameErr == 'thisFieldIsRequired' ?
                                        <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                            {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        : ''}
                            </div>
                        </MDBCol>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.AttendanceJobTitle}<span className="requireStar">*</span></label>
                                <input
                                    type="text" className="form-control"
        
                                    value={item.AttendanceJobTitle}
                                    onChange={(value) => { this.handleAttendanceJobTitle(value, index) }}
                                    required
                                    style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                />
        
                                {
                                    item.AttendanceJobTitleErr == 'thisFieldIsRequired' ?
                                        <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                            {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        : ''}
        
                               
                            </div>
                        </MDBCol>
        
        
                    </MDBRow>
        
                    <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.AttendanceJobDegree}<span className="requireStar">*</span></label>
        
                                <input
                                    type="text" className="form-control"
                                    value={item.AttendanceJobDegree}
                                    onChange={(value) => { this.handleAttendanceJobDegree(value, index) }}
        
                                    style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                />
        
                                {
                                    item.AttendanceJobDegreeErr == 'thisFieldIsRequired' ?
                                        <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                            {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        : ''}
                            </div>
                        </MDBCol>
        
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.AttendanceEmail}<span className="requireStar">*</span></label>
        
                                <input
                                    type="text" className="form-control"
        
                                    value={item.AttendanceEmail}
                                    onChange={(value) => { this.handleAttendanceEmail(value, index) }}
                                    required
                                    style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                                />
        
                                {
                                    item.AttendanceEmailErr == 'thisFieldIsRequired' ?
                                        <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                            {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        : ''}
                            </div>
                        </MDBCol>
        
                    </MDBRow>
                    <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                            <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                                <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.AttendancePhone}<span className="requireStar">*</span></label>
                                <InputMask
                                mask="999-999-9999"
                                maskChar={null}
                                value={item.AttendancePhone}
                             
                                onChange={(value) => { this.handleAttendancePhone(value,index) }}
                                type="text"
                                id="employeeName"
                                className="form-control"
                                name="empJob"
                                // required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />
                             
        
                                {
                                    item.AttendancePhoneErr == 'thisFieldIsRequired' ?
                                        <div className="invalid-feedback textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                            {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        : ''}
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ margin: '5px 34%' }}>
                        <MDBBtn onClick={() => this.addnewmember()} className="gridActionBtAttch whitetext">
                            {i18n[this.state.isArabic ? "ar" : "en"].Disciplinaryboard.addnewmember}
                        </MDBBtn>
                        <MDBBtn onClick={() => this.removemember
                            (index)} className="gridUploadAttch whitetext">
                            {i18n[this.state.isArabic ? "ar" : "en"].Disciplinaryboard.removemember}
                        </MDBBtn>
                    </MDBRow>
        
        
        
                </div>)
        })
        return(
            <MDBContainer fluid style={{ height: '100vh' }}>
            <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>
                    <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                    <MDBRow
                        className="formWhiteContainer"
                        style={{
                            //  width: '100vw',
                            flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                        }}>
                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'WorkShopManage' })
                                        }}
                                        enableCreate={false}
                                        create={this.create}
                                        isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.subject}
                                    />
                                    <div style={{ paddingTop: "10px" }}>
            <form
            className="needs-validation formWeight"
            onSubmit={this.submitHandler}
            noValidate style={{direction:this.state.isArabic? 'rtl':'ltr',textAlign:this.state.isArabic?'right':'left'}}>
                 <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].WorkShop.userinformation} </a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                        <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.EntitName}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.EnityName}
                                disabled={true}
                                onChange={this.handleWorkShopTitle}
                                type="text"
                                id="WorkShopTitle"
                                className="form-control"
                                name="WorkShopTitle"
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.WorkShopTitleErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                            </div>
                            </MDBCol>
                            
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.Person}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.Person}
                               
                                onChange={this.handlePerson}
                                type="text"
                                id="WorkShopTitle"
                                className="form-control"
                                name="WorkShopTitle"
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.PersonErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                            </div>
                            </MDBCol>
                            
                        
                           </MDBRow>
                           <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                           <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.PhoneNumber}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.PhoneNumber}
                                onChange={this.handlePhoneNumber}
                                type="text"
                                id="PhoneNumber"
                                className="form-control"
                                name="PhoneNumber"
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.PhoneNumberErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                            </div>
                            </MDBCol>
                            

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.Email}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.Email}
                               
                                onChange={this.handleEmail}
                                type="text"
                                id="WorkShopTitle"
                                className="form-control"
                                name="WorkShopTitle"
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.EmailErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                              {this.state.EmailErr === 'invalidEmail' ?
                                <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                    {i18n[this.state.isArabic ? 'ar' : 'en'].invalidEmail}
                                </div>
                                :
                                null}
                            </div>
                            </MDBCol>
                            
                           </MDBRow>
                        </div></div>    
  <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].WorkShop.WorkShopInfo} </a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  <MDBRow style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
  <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.AttandanceNumber}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.AttandanceNumber}
                                onChange={this.handleAttandanceNumber}
                                type="text"
                                id="AttandanceNumber"
                                className="form-control"
                                name="AttandanceNumber"
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}      
                                //maxlength="50"
                               
                                
                            />

{
                                this.state.AttandanceNumberErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                          
                            {this.state.AttandanceNumberErr === 'invalidAttandanceNumber'? <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%',display: 'inline-block'}}>
                                

                                {i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.invalidAttandanceNumberErr+' '+this.state.MaxAllowedAtt}
                            </div>:null}

                            {this.state.AttandanceNumberErr === 'cantbookedzero'? <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%',display: 'inline-block'}}>
                                

                                {i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.cantbookedzeroErr}
                            </div>:null}

                            {this.state.AttandanceNumberErr != 'invalidAttandanceNumber'? <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%',display: 'inline-block'}}>
                                

                                {i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.MaxAllowedAttMessage+' '+this.state.MaxAllowedAtt}
                            </div>:null}

                            </div>
                            </MDBCol>
                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                        <label style={{ width: '100%', display: 'flex', flexDirection:'row'}} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].WorkShop.WorkShopDate}<span className="requireStar">*</span></label>
                        <input
                                value={this.state.WorkShopDate}
                              disabled={true}
                                onChange={this.handleWorkShopDate}
                                type="text"
                                id="WorkShopWorkShopDate"
                                className="form-control"
                                name="WorkShopWorkShopDate"
                                required
                                //maxlength="50"
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />

{
                                this.state.WorkShopDateErr == 'thisFieldIsRequired' ?
                            <div className="textRed" style={this.state.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                             :'' }
                            </div>
                            </MDBCol>
                            </MDBRow>
 
  </div> </div> 
  
{ this.state.RequireAddAttandanceDetails ==='1' || this.state.RequireAddAttandanceDetails==='True' || this.state.RequireAddAttandanceDetails ?
  <ul class="nav nav-tabs">
  <li class="nav-item Customcontainer">
    <a class="nav-link active" href="#">{i18n[this.getLocale()].WorkShop.AtteandanceDetails} </a>
  </li>
</ul>:null}{ this.state.RequireAddAttandanceDetails ==='1' || this.state.RequireAddAttandanceDetails || this.state.RequireAddAttandanceDetails==='True' ?
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
      
      {element}
    
      </div>
      </div>
      :null}

<div class="buttonsrow">
<MDBRow style={{ display: "flex", flexDirection: "row" }}>
                    <MDBBtn onClick={this.Cancel} color="gray" className="previousAndClearBtn">
                        {i18n[this.state.isArabic ? "ar" : "en"].WorkShop.Cancel}
                    </MDBBtn>
 <MDBBtn className="submitBtn" type="submit" color="primary">{i18n[this.state.isArabic ? "ar" : "en"].WorkShop.BookWorkShop}</MDBBtn>
                </MDBRow>
</div>
                </form>
                </div>
                                <div style={{clear:"both",height:"100px"}}/>
                                  <Footer isArabic={this.state.isArabic} />

                            </MDBCol>
                             </MDBRow>
                        </MDBCol>
                        <SideBar
                            canViewSCI={true}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                      </MDBRow>
   </LoadingOverlay>
   </MDBContainer>
     )
    }
}