import React, { Component } from 'react';
import Config from '../../utils/Config';

    
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalBody, MDBModalHeader,
     MDBModalFooter, MDBBtn, MDBPopover, MDBPopoverBody, MDBPopoverHeader } from "mdbreact";
import Footer from '../Footer';
import Header from '../Header';
import SideBar from '../SideBar';
import i18n from '../../i18n';
import Locali18n from './Locali18n';
import SubHeaderStrip from '../SubHeaderStrip';

import LoadingOverlay from 'react-loading-overlay';

import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';

import ApiRequest from '../../services/ApiRequest';
import ToastComponent from './../ToastComponent';
import { getFunctionBlockPayload, saveFunctionalDataPayload,
    deleteFunctionalDataPayload, downloadAttachment } from '../../utils/Payload';

import TreeNode from './TreeNode';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import './../../css/react-confirm-alert.css';
import SortableTree, { toggleExpandedForAll, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import Positions from './Positions';
import FTE from './FTE';
import Preview from './Preview';


import OrganizationStructureFunctions from './OrganizationStructureFunctions';
import UnitsDetails from './UnitesDetails';

let moment = require('moment');
var _ = require('lodash');
const striptags = require('striptags');

const maxDepth = 5;
// let sector = 'fa-sector div'; // type --> sector
// let dept = 'fa-dept'; // type --> department
// let section = 'fa-section'; // type --> section
// let position = 'fa-position'; // type --> position



export  class OrganizationStructureTreePreview extends OrganizationStructureFunctions {

    constructor(props){
        super(props);
         this.state={
           
            entityArray:[],
            isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,

         }
    }
  
    
    componentDidMount = () => {
       
        let tempLoginData = Config.getLocalStorageLoginData();
        this.setState({
            loginData: tempLoginData
        },()=>{
            this.setState({
            canvieworgrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canvieworgrequest,
            canvieworgrequesttree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.CanCreateStructure,
            canviewtree: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewtree,
            entitySelectedValue : this.state.loginData && this.state.loginData.permissionType && this.state.loginData.EntityID,

        });

        });


        window.scrollTo(0, 0);
    
        // call get function block API to get the orginasation structure data
        
       
        if (Config.getEntityData()) {
               
                  this.setState({entityArray : Config.getEntityData()}) ;
       
        }
        this.getAllFunctionDataPreview(0);
   
        let tempTypeArray = [];
        if (Config.getFunctionalBlockTypeData()) {
            tempTypeArray = Config.getFunctionalBlockTypeData();
        }
        this.setState({
            typeArray: tempTypeArray
        })

    }
    
   
    

    render() {

        const sideBarData = Config.getSidebarForOrgStructure(this.state.isArabic, this, 0);

        const {
            treeData,
            searchString,
            searchFocusIndex,
            //searchFoundCount,
        } = this.state;
        let rowDirection = 'ltr';

        if (this.state.isArabic) {
            rowDirection = 'rtl';
        }

        
        // Case insensitive search of `node.title`
        const customSearchMethod = ({ node, searchQuery }) =>
            searchQuery &&
            node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

        let  tree = this.state.treeData;
        
        let titleArray = [];
        let t = this.ttype;

        var buildTree = function (tree) {
            _.each(tree, function (item) {
                let titleObj = {};

             
               
                // titleObj.id = item.id;
                titleObj.title = item.title;
                titleObj.Id = item.Id;
                titleObj.unitType = item.unittype;
                titleObj.nodeType = item.typeid;
                titleObj.branch = item.branch;

               
                    titleArray.push(titleObj);

            
             if (item.children) {
                    buildTree(item.children);
                }
            
            });
        }

        buildTree(tree);

       
        return (
            <MDBContainer fluid style={{ height: '100vh' }}>

                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner={true}>

                    <Header goToHelpPage={this.onClickHelpPage} toggleSideBarSize={this.toggleSideBarSize} logout={this.logout} toggleLanguage={this.toggleLanguage} />

                    <MDBRow style={{ flexDirection: this.state.isArabic ? 'row' : 'row-reverse', 'backgroundColor': '#dbd9be' }}>

                        <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                            <MDBRow>
                                <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                    <SubHeaderStrip
                                        goBack={() => {
                                            Config.setIsEditFlag(false);
                                            this.props.history.replace('/listpage', { pathname: 'OrganizationStructure' })
                                        }}
                                        isArabic={this.state.isArabic} title={i18n[this.getLocale()].organizationStructure}
                                    />
                                </MDBCol>
                            </MDBRow>

                           
                            <div className="wrapper">
                             
                                <div className="bar-wrapper">

                                   
                                <MDBRow
                                        style={{
                                            display: 'flex',
                                            flexDirection: this.state.isArabic ? 'row-reverse' : 'row'
                                        }}
                                    >

                                        <MDBCol xs="1" sm="1" md="1" lg="1" xl="1" style={{ maxWidth: '5%' }}>
                                            <label
                                                style={{
                                                    width: '100%',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                {Locali18n[this.getLocale()].orgStructure.search}
                                            </label>
                                        </MDBCol>
                                      {/* search*/}
                                        <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                                            <input type="search" onChange={this.handleSearchOnChange} className="form-control" />
                                        </MDBCol>

                                        <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '14%' }}>
                                            <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.handleViewTreeNavigation}>
                                            
                                                {Locali18n[this.getLocale()].orgStructure.treeView}
                                            </button>
                                        </MDBCol>
                                 {/* expand all*/}
                                        <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '11%' }}>
                                            <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.toggleNodeExpansion.bind(this, true)}>
                                                {Locali18n[this.getLocale()].orgStructure.expandAll}
                                            </button>
                                        </MDBCol>
                                 {/* collapse all*/}
                                        <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '11%' }}>
                                            <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.toggleNodeExpansion.bind(this, false)}
                                            >
                                                {Locali18n[this.getLocale()].orgStructure.collapseAll}
                                            </button>
                                        </MDBCol>
 {/* desgin template*/}
 <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '14%' }}>
                                          <button
                                                style={{ margin: 0 }}
                                                className="btn btn-primary btn-md Ripple-parent"
                                                onClick={this.createDesign}>
                                                {Locali18n[this.getLocale()].orgStructure.finalDesign}
                                            </button> 


                                        </MDBCol>
                            {/* tree type*/}
                              <MDBCol xs="2" sm="2" md="2" lg="2" xl="2" style={{ maxWidth: '10%' }}>
                              <select
                                    className="browser-default custom-select"
                                    id="sources-funding"
                                    onChange={(e) =>this.ReloadTree(e) }
                                       
                                    name='nodeType'
                                    required>

                                    <option value='0'> {this.getLocale() === 'ar' ? 'الكل' : 'All'} </option>
                                    <option value='1'> {this.getLocale() === 'ar' ? 'الهياكل' : 'Unites'}</option>
                                    <option value='2'>{this.getLocale() === 'ar' ? 'الوظائف' : 'Positions'}</option>
                                   
                                </select>

                                        </MDBCol>

                                    </MDBRow>
                                
                                    <br></br>
                                    <MDBRow
                                        style={{
                                            display: 'flex',
                                            flexDirection: this.state.isArabic ? 'row-reverse' : 'row'
                                        }}
                                    >
                                          <MDBCol xs="1" sm="1" md="1" lg="1" xl="1" style={{ maxWidth: '5%' }}>
                                            <label
                                                style={{
                                                    width: '100%',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                {i18n[this.getLocale()].entity}
                                            </label>
                                        </MDBCol>
                                     <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                      <div className="form-group">
                                    
                                    <select style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} 
                                    className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForEntity}
                                        value={this.state.entitySelectedValue}
                                        required>
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                                return <option value={item.id}>{this.state.isArabic ? item.arabicName : item.englishName}</option>
                                            }) : null
                                        }
                                    </select>
                                    <div className="invalid-feedback" style={{ textAlign: this.state.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.state.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                </div>
                            </MDBCol>
              
                                    </MDBRow>
                                </div>
                                {/*tree div */}
                                <div className="tree-wrapper">
                                    <SortableTree
                                        treeData={tree}
                                        
                                        onChange={this.handleTreeOnChange}
                                        onMoveNode={({ node, treeIndex, path }) =>
                                            global.console.debug(
                                                'node:',
                                                node,
                                                'treeIndex:',
                                                treeIndex,
                                                'path:',
                                                path
                                            )
                                          
                                        }
                                        
                                        maxDepth={maxDepth}
                                        rowDirection={rowDirection}
                                        searchMethod={customSearchMethod  }//added custom search
                                             
                                        searchQuery={searchString}

                                        searchFocusOffset={searchFocusIndex}
                                        canDrag={false}
                                        canDrop={false}
                                        // canDrag={({ node }) => !node.noDragging}
                                        // canDrop={({ nextParent }) => !nextParent || !nextParent.noChildren}
                                        searchFinishCallback={matches =>
                                            this.setState({
                                                searchFoundCount: matches.length,
                                                searchFocusIndex:
                                                    matches.length > 0 ? 
                                                    searchFocusIndex % matches.length : 0,
                                            })
                                        }
                                        isVirtualized={true}
                                        generateNodeProps={rowInfo => {
                                       
                                            let nodeProps = {
                                                nodeInfo : rowInfo,
                                                className: `${rowInfo.node.className}`,
                                                buttons: [

                                                // pop over div
                                                rowInfo.node.typeid !== 2  ?  
                                               <div style={{ display: "flex" }}>
                                               
                              <MDBPopover component="button" size="sm" placement={this.props.y > 555 ? "top" : "bottom"}
                popoverBody={i18n[this.getLocale()].actions} 
                className= { rowInfo.node.typeid === 0 ?  "btn btn-primary btn-sm Ripple-parent gridActionBtn"
                            :  "btn btn-warning btn-sm"}
               
                 >
                
                <MDBPopoverHeader>
                    {" "}
                    {i18n[this.getLocale()].select_action}
                </MDBPopoverHeader>
                <MDBPopoverBody
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around"
                        
                    }}>
                          {/* node buttons */} 

                                                               
         {/*   <MDBRow>
           <MDBBtn className="gridActionBtn"
          color="primary" style={{ color: "var(--btnColor)" }}size="sm"
          onClick={(event) => this.Preview(rowInfo)}>
           {Locali18n[this.getLocale()].preview}
          </MDBBtn>
      </MDBRow> */}

                                                               
                                                            </MDBPopoverBody>

                                                        </MDBPopover>

                                                 </div> : null,

                                               // pop over div end

                                                ],
                                            };
                                            if (this.state.isArabic) {
                                                nodeProps.title = rowInfo.node.arabicTitle;
                                            }
                                            return nodeProps;
                                        }}

                                    />
                                </div>

                                 {/*tree div end */}
                            </div>

                            <Footer isArabic={this.state.isArabic} />

                        </MDBCol>

                        <SideBar
                            height={'300vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />

                    </MDBRow>

                </LoadingOverlay>

            

              {/*Preview */}

             
                <MDBModal 
                size="lg" 
                    isOpen={this.state.modalPeview}
                    onHide={this.closePreview}
                    
                    backdrop={false}
                    tabIndex="0"
                    keyboard={true}>

                  <Preview 
                  
                   info={this.state.rowInfo} 
                   hide = {this.closePreview}
                  open={this.state.modalPeview}></Preview>

                </MDBModal >
               
            </MDBContainer >


        );
    }
}

export default OrganizationStructureTreePreview;