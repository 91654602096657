import React from "react";
import { Route, Redirect } from 'react-router-dom';
import Config from "../utils/Config";

const ProtectedRoute = ({ component: Component, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      Config.isAuthenticate()
        ? <Component  {...props} />
        : <Redirect
          to={{
            pathname: '/loginScreen',
            state: { from: props.location }
          }}
        />}
  />;

export default ProtectedRoute;