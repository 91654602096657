import React, { Component } from "react"
import {MDBModalBody,MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody ,
    MDBModal,MDBModalHeader, MDBModalFooter} from "mdbreact";

    import ExpansionPanel from '@material-ui/core/ExpansionPanel';
    import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
    import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
    import Typography from '@material-ui/core/Typography';
    import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import i18n from '../../i18n';
import Locali18n from './Locali18n';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';

import PostionsFunctions from './PostionsFunctions';
import PositionAdminRoles from "./PositionAdminRoles";
import PositionOrganizationalRoles from './PositionOrganizationalRoles';
import PositionFunctionalRoles from './PositionFunctionalRoles';

export default class PositionsBasic extends PostionsFunctions{

  

    constructor(props)
    {
        super(props);
      
 
    }

    componentWillMount()
  {

   
    this.getpositionscategories(); 
    this.geteducationlevels();
    this.getpositionsTypes();
    this.getgrades();
   


  if(this.props.info.node.edit===1)
  {
     
    let obj={};

          obj.id=this.props.info.node.id;
        
    
          obj.lang = this.props.info.node.lang;
          obj.entityid = this.props.info.node.entityid;
          obj.userid = this.props.info.node.UserId;
    
    
    
          let payload = obj;
          let endpoint = 'GetPosition';
          let url = Config.getUrl(endpoint);
    
           ApiRequest.sendRequestForPost(url, payload, res => {
              
           if (res.Status) { 
               
            this.setState({
               
                parentId : res.data.id,
               nameen : res.data.nameen,
               namear :  res.data.namear,
               reportto :  res.data.reportto,
               categoryid :  res.data.categoryid,  
               educationLevel : res.data.qualifications,

               typeid : 1,
               unittype  : res.data.unittype,
               positiontypeid : res.data.positiontypeid,
               grade : res.data.grade,
               numberOfPositions : res.data.number,
               positiongoals : res.data.objectives,
               organizationalRelations : res.data.organizationalrelations,
               internalRelations : res.data.internalrelations,
               externalRelations : res.data.externalrelations,
               knowledgeAndLevel : res.data.knowledgeandlevel,
               experience : res.data.experience,
               adminrRoles: res.data.adminroles,
               organizationalRoles : res.data.organizationalroles,
               functionalRoles : res.data.functionalroles,
            
                dailyhours:res.data.dailyhours,
                yearlyvacation : res.data.yearlyvacationdays,
                monthlydays : res.data.monthlydays,
                dmy : res.data.dmy,
                subgrade : res.data.subgrade
            
        
            }); 

            if(res.data.attachments.length>0)
            {
            this.setState({functionalLoadFileName: res.data.attachments[0].filename,
            functionalLoadFileref: res.data.attachments[0].aliasfilename
        });
           }
            
             if(res.data.attachments.length>1)
             this.setState({jobEvaluationFileName: res.data.attachments[1].filename,
                jobEvaluationFileref: res.data.attachments[1].aliasfilename
            });
             
         
          Config.setOrganizationStructurAdminRoles(res.data.adminroles);
          Config.setOrganizationStructurFunctionalRoles(res.data.functionalroles);
          Config.setOrganizationStructurOrganizationalRoles(res.data.organizationalroles);
    
              this.getsubgrades(res.data.grade);
              this.setState({subgrade:res.data.subgrade});
          }
    
     
        });
        
  
   }
  
 
  }
  
  getPositionInfo=(positionid)=>{
    
        
          let obj={};
          obj.id=positionid;
        
    
          obj.lang = this.props.info.node.lang;
          obj.entityid = this.props.info.node.entityid;
          obj.userid = this.props.info.node.UserId;
    
    
    
          let payload = obj;
          let endpoint = 'GetPosition';
          let url = Config.getUrl(endpoint);
    
           ApiRequest.sendRequestForPost(url, payload, res => {
                
           if (res.Status) {
            this.setState({
                parentId : res.data.id,
               nameen : res.data.nameen,
               namear :  res.data.namear,
               typeid : 1,
               unittype  : res.data.unittype,
               positiontypeid : res.data.positiontypeid,
               grade : res.data.grade,
               numberOfPositions : res.data.number,
               positiongoals : res.data.objectives,
               organizationalRelations : res.data.organizationalrelations,
               internalRelations : res.data.internalrelations,
               externalRelations : res.data.externalrelations,
               knowledgeAndLevel : res.data.knowledgeandlevel,
               educationLevel : res.data.qualifications,
               experience : res.data.experience,
               adminrRoles: res.data.adminroles,
               organizationalRoles : res.data.organizationalroles,
               functionalRoles : res.data.functionalroles,
               reportto :  res.data.reportto,
               categoryid :  res.data.categoryid,
        
                
              functionalLoadFileName: res.data.attachments[0].filename,
               jobEvaluationFileName: res.data.attachments[1].filename,
        
               functionalLoadFileref: res.data.attachments[0].aliasfilename,
               jobEvaluationFileref: res.data.attachments[1].aliasfilename
              
        
            }); 
          
          Config.setOrganizationStructurAdminRoles(res.data.adminroles);
          Config.setOrganizationStructurFunctionalRoles(res.data.functionalroles);
          Config.setOrganizationStructurOrganizationalRoles(res.data.organizationalroles);
    
    
          }
    
     
        });
    
      };
    
    render() 
    {
        

            return(


                <form  className="needs-validation formWeight formSpace"
                 // onSubmit={this.addPosition} 
                   noValidate>

            <MDBContainer >
            <MDBModalHeader  style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>
            {  ( this.props.edit === 1)? 
              Locali18n[this.getLocale()].editPosition
             : Locali18n[this.getLocale()].addPosition }</MDBModalHeader>
              
            <MDBModalBody  style={{overflow: 'auto'}}>
     
      <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>
       <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
      <label 
            style={{
                width: '100%', display: 'flex', fontWeight: '250',
                flexDirection: this.getLocale() ? 'row-reverse' : 'row'
            }} htmlFor="formGroupExampleInput">
            <span className="requireStar">  {i18n[this.getLocale()].positionnotfound}</span>
            </label>
      </MDBCol>
     
          </MDBRow> 
         
    

            <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}> 

          

            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

         <div className="form-group">

        <label
            style={{
                width: '100%', display: 'flex', fontWeight: '200',
                flexDirection: this.getLocale() ? 'row-reverse' : 'row'
            }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].orgStructure.type}<span className="requireStar">*</span>
            </label>

            <div style={{ border: this.state.categoryidErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
            <select
            className="browser-default custom-select"
            id="sources-funding"
            onChange={(e) =>this.updatePositioncategory(e) }
            value={this.state.categoryid}   
            name='nodeType'
            required>
                
                <option value=''>-Select-</option>
            {
               
                this.state.positionsCategoriesArray ? this.state.positionsCategoriesArray.map((item, index) => {
                    return <option value={item.id} key={item.id} 
                     selected={this.props.edit===1 &&  
                        this.props.info.node.categoryid === item.id ? 'selected' : null}> 
                   
                    {this.getLocale() ? item.namear : item.nameen}
                    </option>
                })
                    :
                    null
            }
        </select>
      
        </div>
    </div>


         </MDBCol>
             <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

          <div className="form-group">

<label
  style={{
      width: '100%', display: 'flex', fontWeight: '400',
      flexDirection: this.getLocale() ? 'row-reverse' : 'row'
  }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].reportto}<span className="requireStar">*</span>
  </label>
  <div style={{ border: this.state.reporttoErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
    <select
  className="browser-default custom-select"
  id="sources-funding"
  onChange={(e) =>this.updatePositionreportto(e) }
   value={this.state.reportto}  
  name='nodeType'
  required>
  <option value=''>-Select-</option>
  {

this.state.positionsTypesArray ? this.state.positionsTypesArray.map((item, index) => {
          return <option value={item.id} key={item.id} 
           selected={this.props.edit===1 && this.props.info.node.reportto === item.id ? 'selected' : null}> 
         
          {this.getLocale() ? item.namear : item.nameen}
          </option>
      })
          :
          null
  }
</select>
</div>

</div>


</MDBCol>


           </MDBRow>


            <MDBRow style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>

                        <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                     <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 
                            'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {Locali18n[this.getLocale()].grade}</label>
                            <div style={{ border: this.state.gradeErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                            <select
            className="browser-default custom-select"
            id="sources-funding"
            onChange={(e) =>this.handleGrade(e) }
            value={this.state.grade}   
            name='grade'
            style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}

            required>
                
                <option value=''>-Select-</option>
            {
               
               this.state.gradesArray ? this.state.gradesArray.map((item, index) => {
                    return <option value={item.id} key={item.id} 
                     selected={this.props.edit===1 &&  
                        this.state.grade === item.id ? 'selected' : null}> 
                   
                    {this.getLocale()==='ar' ? item.namear : item.nameen}
                    </option>
                })
                    :
                    null
            }
        </select>
       
        </div>

                      
                        </MDBCol>

                        
                        <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                     <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 
                            'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {Locali18n[this.getLocale()].subgrade}</label>
                            <div style={{ border: this.state.subgradeErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                            <select
            className="browser-default custom-select"
            id="sources-funding"
            onChange={(e) =>this.handleSubGrade(e) }
            value={this.state.subgrade}   
            name='grade'
            style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}

            required>
                  <option value=''>-Select-</option>
              
            {
               
               this.state.subGradesArray ? this.state.subGradesArray.map((item, index) => {
                    return <option value={item.id} key={item.id} 
                     selected={this.props.edit===1 &&  
                        this.state.subgrade === item.id ? 'selected' : null}> 
                   
                    { this.getLocale() === 'ar' ? item.namear : item.nameen}
                    </option>
                })
                    :
                    null
            }
        </select>
      
                      </div>
                        </MDBCol>


                        <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                        <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 
                            'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">
                            {Locali18n[this.getLocale()].numberOfPositions}</label>
                           
                        <div className="form-group">
                        <div style={{ border: this.state.numberOfPositionsErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >

                            <input
                                type="text"
                                
                                className="form-control"
                                value={this.state.numberOfPositions}
                                onChange={(e)=>this.handleNumberOfPositions(e)}
                                style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}
                            />
                        </div>
                       
                        </div>
                        </MDBCol>

               </MDBRow>
               
             <MDBRow style={{ display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}>
            
             <MDBCol xs="8" sm="8" md="8" lg="8" xl="8">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale()? 'row-reverse' : 'row' }}
                             htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].educationLevel}<span className="requireStar">*</span></label>
                            <div style={{ border: this.state.educationLevelErr === 'thisFieldIsRequired' ? '1px solid red' : '' }} >
                               
                            <select
                                    className="browser-default custom-select"
                                    id="sources-funding"
                                    onChange={(e) =>this.updatEducationLevel(e) }
                                    value = {this.state.educationLevel}   
                                    name='nodeType'
                                    required>

                                    <option value=''>-Select-</option>
                                    {
                                        

                                        this.state.EducationLevelArray ? this.state.EducationLevelArray.map((item, index) => {
                                            return <option value={item.id} key={item.id} 
                                             selected={this.props.info.edit===1 &&this.props.info.node.educationLevel === item.id ? 'selected' : null}> 
                                           
                                            {this.getLocale() ? item.namear : item.nameen}
                                            </option>
                                        })
                                            :
                                            null
                                    }
                                </select>
                               
                            </div>
                            <p style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {Locali18n[this.getLocale()].allowCkEditorLimit}
                            </p>
                           
                        </div>
                    </MDBCol>
              <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                    <div className="form-group">

<label
  style={{
      width: '100%', display: 'flex', fontWeight: '400',
      flexDirection: this.getLocale() ? 'row-reverse' : 'row'
  }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].rolefrequency}
  <span className="requireStar">*</span>
  </label>

<select
  className="browser-default custom-select"
  id="sources-funding"
  onChange={this.handledmy}
   value={this.state.dmy}  
  name='nodeType'
  required>

 

     <option selected='true' value='1'>
          {Locali18n[this.getLocale()].daily}
     </option>
     <option  value='2'>
          {Locali18n[this.getLocale()].monthly}
     </option>
     <option value='3'>
          {Locali18n[this.getLocale()].yearly}
     </option>
         
  }
</select>

<div className="invalid-feedback" style={this.getLocale() ? 
  { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
  {i18n[this.getLocale()].thisFieldIsRequired}
</div>
</div>


                    </MDBCol> 
                

             </MDBRow>
             <br></br>
                <MDBRow style={{ display: 'flex', flexDirection: this.isArabic ? 'row-reverse' : 'row' }}>
                   
                  
                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                    <div className="form-group">

<label
  style={{
      width: '100%', display: 'flex', fontWeight: '400',
      flexDirection: this.getLocale() ? 'row-reverse' : 'row'
  }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].dailyhours}
  <span className="requireStar">*</span>
  </label>
  <input
         value={this.state.dailyhours}
         onChange={this.handledailyhours}
         type="text"
         id="arabicName"
         className="form-control"
         name="arabicName"
         required
         style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}
     />

<div className="invalid-feedback" style={this.getLocale() ? 
  { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } :
   { justifyContent: 'flex-start', width: '100%' }}>
  {i18n[this.getLocale()].thisFieldIsRequired}
</div>
</div>


                    </MDBCol> 
                  
                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                    <div className="form-group">

<label
  style={{
      width: '100%', display: 'flex', fontWeight: '400',
      flexDirection: this.getLocale() ? 'row-reverse' : 'row'
  }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].monthlydays}
  <span className="requireStar">*</span>
  </label>
  <input
         value={this.state.monthlydays}
         onChange={this.handlemonthlydays}
         type="text"
         id="arabicName"
         className="form-control"
         name="arabicName"
         required
         style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}
     />

<div className="invalid-feedback" style={this.getLocale() ? 
  { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } :
   { justifyContent: 'flex-start', width: '100%' }}>
  {i18n[this.getLocale()].thisFieldIsRequired}
</div>
</div>


                    </MDBCol> 
                  
                    <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
                    <div className="form-group">

<label
  style={{
      width: '100%', display: 'flex', fontWeight: '400',
      flexDirection: this.getLocale() ? 'row-reverse' : 'row'
  }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].yearlyvacations}
  <span className="requireStar">*</span>
  </label>
  <input
         value={this.state.yearlyvacation}
         onChange={this.handleyearlyvacation}
         type="text"
         id="arabicName"
         className="form-control"
         name="arabicName"
         required
         style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}
     />

<div className="invalid-feedback" style={this.getLocale() ? 
  { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } :
   { justifyContent: 'flex-start', width: '100%' }}>
  {i18n[this.getLocale()].thisFieldIsRequired}
</div>
</div>


                    </MDBCol> 
                  
                  
                    </MDBRow>

        
           <MDBRow style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row" }}>
               
             
                <MDBBtn     color="primary" 
                    onClick={()=>this.showAdminModal()}>
                        {Locali18n[this.getLocale()].adminRoles}
                    </MDBBtn >
                  
                    <MDBBtn    color="primary" onClick={()=>this.showFunctionalModal()}>
                        {Locali18n[this.getLocale()].functionalRoles}
                    </MDBBtn>
                   
                    <MDBBtn     color="primary" onClick={()=>this.showOrganizationalModal()}>
                        {Locali18n[this.getLocale()].organizationalRoles}
                    </MDBBtn >
                   {/*  <div>
                  
                      {
                                this.state.allroles === '1' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()? 
                                    'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].allrolesrequired}
                                    </div>
                                    :
                                    null
                            }
                    </div> */}
                    <br></br>
                    <div>   {
                                this.state.adminRolesErr !== '' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()?
                                     'right' : 'left', width: '100%' }}>
                                        {Locali18n[this.getLocale()].allrolesrequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                     {/*    <div>   {
                                this.state.functionalRolesErr !== '' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()?
                                     'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].functionalrolesrequird}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div>   {
                                this.state.organizationalRolesErr !== '' ?
                                    <div className="textRed" style={{ textAlign: this.getLocale()?
                                     'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].organizationalrolesrequird}
                                    </div>
                                    :
                                    null
                            }
                        </div> */}
                    </MDBRow>
           

           <MDBRow style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row" }}>
                  

                  <MDBModal size="lg" 
                      isOpen={this.state.organizationalRolesModal}
                      toggle={this.organizationalRolesModal}
                      backdrop={false}
                      tabIndex="0"
                      keyboard={true}>
  
                       <PositionOrganizationalRoles 
                        hide = {this.showOrganizationalModal}
                        parent={this.state.parentNodeValue} 
                        open={this.state.organizationalRolesModal}
                        data = {this.state.organizationalRoles}
                        >

                        </PositionOrganizationalRoles>
                       </MDBModal >
                
                   <MDBModal size="lg" 
                      isOpen={this.state.adminRolesModal}
                      toggle={this.adminRolesModal}
                      backdrop={false}
                      tabIndex="0"
                      keyboard={true}>
  
                       <PositionAdminRoles  hide = {this.showAdminModal} 
                        data = {this.state.adminRoles}
                       parent={this.state.parentNodeValue} open={this.state.adminRolesModal}>

                       </PositionAdminRoles>
                       </MDBModal >
  
                  <MDBModal size="lg" 
                      isOpen={this.state.functionalRolesModal}
                      toggle={this.functionalRolesModal}
                      backdrop={false}
                      tabIndex="0"
                      keyboard={true}>
                  <PositionFunctionalRoles hide = {this.showFunctionalModal}
                   data = {this.state.functionalRoles}
                   parent={this.state.parentNodeValue} open={this.state.functionalRolesModal}></PositionFunctionalRoles>
                  </MDBModal >
               
                  </MDBRow>
               
               
              </MDBModalBody>
 
                <MDBModalFooter>
                <MDBRow style={{ display: "flex", flexDirection: this.getLocale()? "row-reverse" : "row" }}>
                
                
                <MDBCol xs="4" sm="41" md="4" lg="4" xl="4"> </MDBCol>
               
                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
             
                   
                   <MDBBtn  type='button' id='2' color="primary" type="submit"
                    onClick={(event)=>this.addPosition(event)}>
                       {Locali18n[this.getLocale()].submit}
                   </MDBBtn>
          

                  </MDBCol>
                
                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                <MDBBtn   color="primary" onClick={this.props.hide}>
               {Locali18n[this.getLocale()].close}
           </MDBBtn>

</MDBCol>
        
                <MDBCol xs="2" sm="2" md="2" lg="2" xl="2"></MDBCol>

                
            </MDBRow>
           
           
                </MDBModalFooter> 
             </MDBContainer>
             
                </form>
)};
}
