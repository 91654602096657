
    import React, { Component } from 'react';

    import {MDBModalFooter,MDBModalBody,MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody,MDBModal,MDBModalHeader } from "mdbreact";
    
   import ToastComponent from './../ToastComponent';

    import i18n from '../../i18n';
    import Locali18n from './Locali18n';
    import  RequestFunctions from './RequestFunctions';
    import './../../css/style.css';
    import "react-datepicker/dist/react-datepicker.css";
    import Config from '../../utils/Config';
    import LoadingOverlay from 'react-loading-overlay';
    import { confirmAlert } from 'react-confirm-alert';
    import './../../css/react-confirm-alert.css';
    import ApiRequest from '../../services/ApiRequest';
    import { uploadDocument ,OrganizationStrucutreRequestPayload} from '../../utils/Payload';
    import DatePicker from "react-datepicker";
    import InputMask from 'react-input-mask';
    import CKEditorComponent from '../CKEditorComponent';
    import DownloadComponent from "../DownloadComponent";
    import PostionsFunctions from './PostionsFunctions';
    
   import OrganizationStructureFunctions from './OrganizationStructureFunctions';


export default class TreeNode  extends OrganizationStructureFunctions {

    constructor(props)
    {
        super(props);
       this.state={
           info : this.props.info,
           isArabic: localStorage.getItem('Lang') === 'ar' ? true : false,

       }
       
       
    }

  
    componentWillMount()
    {
        this.getnodetypes();
    
       if(this.props.info.edit===1)
       {
        this.setState({
            nodeType :this.props.info.node.unittype,
            arabicName :this.props.info.node.namear,
            englishName :this.props.info.node.nameen,
            level : this.props.info.node.level,
            branch : this.props.info.node.branch

        });
   }
     
    }
    
   

       render()
       {
           return (
          
            <form
                style={{ paddingTop: 20 }}
                className="needs-validation"
                onSubmit={this.addNode}
                noValidate>
              <MDBContainer >

            <MDBModalHeader  style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>
            {
               this.props.info.edit===1 ?
                Locali18n[this.getLocale()].editunit :
                Locali18n[this.getLocale()].addunit
                }
            </MDBModalHeader>



                <MDBModalBody >

      
                    <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>

                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">

                            <div className="form-group">

                                <label
                                    style={{
                                        width: '100%', display: 'flex', fontWeight: '400',
                                        flexDirection: this.getLocale() ? 'row-reverse' : 'row'
                                    }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].orgStructure.type}<span className="requireStar">*</span>
                                    </label>

                                <select
                                    className="browser-default custom-select"
                                    id="sources-funding"
                                    onChange={(e) =>this.updateUnittype(e) }
                                       
                                    name='nodeType'
                                    required>

                                    <option value='0'>-Select-</option>
                                    {
                                        
                                        this.state.typeArray ? this.state.typeArray.map((item, index) => {
                                            return <option value={item.id} key={item.id} 
                                             selected={this.props.info.edit===1 &&this.props.info.node.unittype === item.id ? 'selected' : null}> 
                                           
                                            {this.getLocale() ? item.namear : item.nameen}
                                            </option>
                                        })
                                            :
                                            null
                                    }
                                </select>

                                <div className="invalid-feedback" style={this.getLocale() ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                            </div>

                        </MDBCol>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>

                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row', fontWeight: '400' }}
                                 htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].orgStructure.englishName}<span className="requireStar">*</span></label>

                                <input
                                    value={this.state.englishName}
                                    onChange={this.handleEnglishName}
                                    type="text"
                                    id="englishName"
                                    className="form-control"
                                    name="englishName"
                                    required
                                    style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}
                                />
                                <div className="invalid-feedback" style={this.getLocale() ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                            </div>
                        </MDBCol>

                    </MDBRow>

                    <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>

                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className="form-group">
                                <label style={{ width: '100%', display: 'flex', flexDirection: this.getLocale() ? 
                                'row-reverse' : 'row', fontWeight: '400' }} htmlFor="formGroupExampleInput">
                                {Locali18n[this.getLocale()].orgStructure.arabicName}<span className="requireStar">*</span></label>

                                <input
                                    value={this.state.arabicName}
                                    onChange={this.handleArabicName}
                                    type="text"
                                    id="arabicName"
                                    className="form-control"
                                    name="arabicName"
                                    required
                                    style={{ direction: this.getLocale() ? "rtl" : 'ltr' }}
                                />
                                <div className="invalid-feedback" style={this.getLocale() ?
                                     { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                    {i18n[this.getLocale()].thisFieldIsRequired}
                                </div>
                            </div>
                        </MDBCol>

                    </MDBRow>
                  
                    <MDBRow style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">

<div className="form-group">

    <label
        style={{
            width: '100%', display: 'flex', fontWeight: '400',
            flexDirection: this.getLocale() ? 'row-reverse' : 'row'
        }} htmlFor="formGroupExampleInput">{Locali18n[this.getLocale()].orgStructure.level}<span className="requireStar">*</span>
        </label>

    <select
        className="browser-default custom-select"
        id="sources-funding"
        onChange={(e) =>this.updateLevel(e) }
        value = {this.state.level}
        name='nodeType'
        required>

        <option value='1'>1</option>
        <option value='2'>2</option>
        <option value='3'>3</option>
        <option value='4'>4</option>
        <option value='5'>5</option>
        <option value='6'>6</option>
      

        
    </select>

    <div className="invalid-feedback" style={this.getLocale() ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
        {i18n[this.getLocale()].thisFieldIsRequired}
    </div>
</div>

</MDBCol>


                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                              <div className="form-group">
                <div style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row' }}>
                    <div><input type="checkbox"
                        onChange={this.HandleCheckBoxBranch}
                        style={{ cursor: 'pointer' }}
                        checked={this.state.branch ? true : ''}
                    /></div>
                    <label className={this.getLocale() ? "roleLabel_ar" : "roleLabel_en"} htmlFor="formGroupExampleInput" style={{ display: 'flex', flexDirection: this.getLocale() ? 'row-reverse' : 'row', width: '100%', textAlign: this.getLocale() ? 'right' : '' }}>
                        {Locali18n[this.getLocale()].branch}
                    </label>
                </div>
                 </div>
                        </MDBCol> 
                        </MDBRow>
                  

                </MDBModalBody >

                <MDBModalFooter>
                    <MDBBtn style={{ borderRadius: '5px' }} type='submit' color="btn btn-primary btn-md" 
                    onClick={this.props.hide }>
                    {i18n[this.getLocale()].submit}</MDBBtn>

                    <MDBBtn style={{ borderRadius: '5px' }} className="btn btn-primary btn-md" onClick={this.props.hide} >{Locali18n[this.getLocale()].orgStructure.close}</MDBBtn>
                </MDBModalFooter>
                </MDBContainer>
            </form>

     
          )};
        }
                           

