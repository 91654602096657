import React, { Component } from "react";
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import "./../../css/style.css";
import i18n from "../../i18n";
import StepsComponent from "../StepsComponent";
import Config from '../../utils/Config';
import validator from '../../utils/Validator';
import InputMask from 'react-input-mask';
import CKEditor from "react-ckeditor-component";
import Validator from '../../utils/Validator';
import CurrencyFormat from 'react-currency-format';
const striptags = require('striptags');

export default class Stepone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: false,
            statusid: '',
            requiredRecomandation: this.props.sendData.requiredRecomandation,
            typeOfContract: this.props.sendData.typeOfContract,
            EmployeeName: this.props.sendData.EmployeeName,
            EntityName: this.props.sendData.EntityName,
            BasicSalary: this.props.sendData.BasicSalary,
            FinancialCost: this.props.sendData.FinancialCost,
            TotalSalary: this.props.sendData.TotalSalary,
            EmployeeNumber: this.props.sendData.EmployeeNumber,
            HousingAllowance: this.props.sendData.HousingAllowance,
            contractPeriod: this.props.sendData.contractPeriod,
            jobTitle: this.props.sendData.jobTitle,
            entitityFileRequestFileObj: null,
            attachFileSupportFileObj: null,
            RetirementContributions: this.props.sendData.RetirementContributions,
            ReasonsOfRequest: this.props.sendData.ReasonsOfRequest,
            requiredRecomandationErr: '',
            ReasonsOfRequestErr: '',
            unifiedNumber: this.props.sendData.unifiedNumber,
            // isEdit: false,
            emiratesID: this.props.sendData.emiratesID,
            emiratesIDErr: '',
            basicSalaryErr: '',
            TotalSalaryErr: '',
            FinancialCostErr: '',
            HousingAllowanceErr: '',
            RetirementContributionsErr: '',
            contractperiodrequired:'',
            // requestId: '',
            loginData: '',
            salaryErr: '',
            typeOfContractLabel: this.props.sendData.typeOfContractLabel,
            contractArray: [
                {
                    id: 1,
                    EnglishTitle: 'Permanent',
                    ArabicTitle: 'دائم'
                },
                {
                    id: 2,
                    EnglishTitle: 'Private',
                    ArabicTitle: 'خاص'
                }
            ]
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        //For Edit
        if (editFlag) {

            let tempArray = this.state.contractArray;
            let typeOfContractLabel = '';
            if (tempArray.length > 0) {
                for (let index = 0; index < tempArray.length; index++) {
                    if (parseInt(editObj.contracttype) === tempArray[index].id) {
                        typeOfContractLabel = this.props.isArabic ? tempArray[index].ArabicTitle : tempArray[index].EnglishTitle;
                    }
                }
            }
            
debugger;
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                // requiredRecomandation: editObj.recommendation,
                typeOfContract: editObj.contracttype,
                typeOfContractLabel: typeOfContractLabel,
                EmployeeName: editObj.employeeName,
                EntityName: editObj.entityname,
                BasicSalary: editObj.basicsalary,
                FinancialCost: editObj.financialcost,
                TotalSalary: editObj.totalsalary,
                EmployeeNumber: editObj.EmployeeJobNumber,
                HousingAllowance: editObj.housingallowance,
                jobTitle: editObj.jobname,
                contractPeriod: editObj.contractperiod,
                RetirementContributions: editObj.retirementcontribution,
                emiratesID: editObj.emiratesid,
                ReasonsOfRequest: editObj.requestreason,
                unifiedNumber: editObj.unifiednumber,
                // retirementSubscriptions:editObj.retirementSubscriptions
            })
        } else {
            let loginData = Config.getLocalStorageLoginData();
            this.setState({
                loginData: loginData
            }, () => {
                if (loginData) {
                    let tempEntityName = this.props.isArabic ? loginData.EntityNameAr : loginData.EntityNameEn
                    this.setState({
                        EntityName: tempEntityName
                    })
                }
            })
        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    submitHandler = event => {
debugger;
        event.preventDefault();
        event.target.className += " was-validated";

        let emiratesIDErr = '',
            basicSalaryErr = '',
            TotalSalaryErr = '',
            // FinancialCostErr = '',
            HousingAllowanceErr = '',
            tempReasonOfRequestErr = "",
            RetirementContributionsErr = '';

        if (this.state.ReasonsOfRequest && this.state.ReasonsOfRequest.trim()) {
            let val = striptags(this.state.ReasonsOfRequest.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempReasonOfRequestErr = "thisFieldisRequired";
            }
        } else {
            tempReasonOfRequestErr = "thisFieldisRequired";
        }

        if (this.state.emiratesID) {
            if (!Validator.validateEmiratesId(this.state.emiratesID)) {
                emiratesIDErr = "invalidEmiratesId";
            }
        }
        else {
            emiratesIDErr = "thisFieldisRequired"
        }

        if (!this.state.BasicSalary) {
            basicSalaryErr = 'thisFieldIsRequired'
        }
        if (!this.state.TotalSalary) {
            TotalSalaryErr = 'thisFieldIsRequired'
        }
        // if (!this.state.FinancialCost) {
        //     FinancialCostErr = 'thisFieldIsRequired'
        // }
        if (!this.state.HousingAllowance) {
            HousingAllowanceErr = 'thisFieldIsRequired'
        }

        if (this.state.BasicSalary && this.state.TotalSalary) {
            let basicSalAfterReplace = this.state.BasicSalary.replace(/,/g, "");
            let totalSalAfterReplace = this.state.TotalSalary.replace(/,/g, "");
            if (parseFloat(basicSalAfterReplace) > parseFloat(totalSalAfterReplace)) {
                basicSalaryErr = 'salaryErr';
            } else {
                basicSalaryErr = '';
            }
        }

        if (this.state.HousingAllowance && this.state.TotalSalary) {
            let HousingAllowanceAfterReplace = this.state.HousingAllowance.replace(/,/g, "");
            let totalSalAfterReplace = this.state.TotalSalary.replace(/,/g, "");
            if (parseFloat(HousingAllowanceAfterReplace) > parseFloat(totalSalAfterReplace)) {
                HousingAllowanceErr = 'housingAllowanceErr';
            } else {
                HousingAllowanceErr = '';
            }
        }

        if (this.state.RetirementContributions && this.state.TotalSalary) {
            let RetirementContributionsAfterReplace = this.state.RetirementContributions.replace(/,/g, "");
            let totalSalAfterReplace = this.state.TotalSalary.replace(/,/g, "");
            if (parseFloat(RetirementContributionsAfterReplace) > parseFloat(totalSalAfterReplace)) {
                RetirementContributionsErr = 'RetirementContributionsErr';
            } else {
                RetirementContributionsErr = '';
            }
        }
        
        this.setState({
            ReasonsOfRequestErr: tempReasonOfRequestErr,
            emiratesIDErr: emiratesIDErr,
            basicSalaryErr: basicSalaryErr,
            TotalSalaryErr: TotalSalaryErr,
            // FinancialCostErr: FinancialCostErr,
            HousingAllowanceErr: HousingAllowanceErr,
            RetirementContributionsErr: RetirementContributionsErr
        }, () => {

            if (
                !tempReasonOfRequestErr &&
                !emiratesIDErr &&
                !basicSalaryErr &&
                !TotalSalaryErr &&
               
                !HousingAllowanceErr &&
                !RetirementContributionsErr
            ) {
                if (
                    this.state.EmployeeNumber &&
                   
                    this.state.HousingAllowance &&
                    this.state.BasicSalary &&
                    this.state.TotalSalary &&
                    this.state.EmployeeName &&
                    this.state.typeOfContract &&
                    this.state.EntityName &&
                    this.state.contractPeriod &&
                    this.state.jobTitle &&
                    this.state.unifiedNumber &&
                    this.state.emiratesID &&
                    striptags(this.state.ReasonsOfRequest)
                    // this.state.RetirementContributions !== "" &&
                    // striptags(this.state.requiredRecomandation.toString('html')) !== '' &&
                ) {

                    this.props.getData.stepOneData({
                        typeOfContract: this.state.typeOfContract,
                        typeOfContractLabel: this.state.typeOfContractLabel,
                        EmployeeName: this.state.EmployeeName,
                        EntityName: this.state.EntityName,
                        BasicSalary: this.state.BasicSalary,
                        unifiedNumber: this.state.unifiedNumber,
                        jobTitle: this.state.jobTitle,
                        contractPeriod: this.state.contractPeriod,
                      
                        TotalSalary: this.state.TotalSalary,
                        EmployeeNumber: this.state.EmployeeNumber,
                        HousingAllowance: this.state.HousingAllowance,
                        RetirementContributions: this.state.RetirementContributions,
                        ReasonsOfRequest: this.state.ReasonsOfRequest,
                        emiratesID: this.state.emiratesID,
                        // requiredRecomandation: this.state.requiredRecomandation,
                        // retirementSubscriptions:this.state.retirementSubscriptions
                    });
                }
            }
        });
    };

    clearForm = () => {
        this.setState({
            typeOfContract: '',
            typeOfContractLabel: '',
            EmployeeName: '',
            EntityName: '',
            BasicSalary: '',
            unifiedNumber: '',
            jobTitle: '',
            contractPeriod: '',
            FinancialCost: '',
            TotalSalary: '',
            EmployeeNumber: '',
            HousingAllowance: '',
            RetirementContributions: '',
            ReasonsOfRequest: '',
            emiratesID: ''
        });
    }

    handleBasicSalaryTable = (value) => {
        if (value) {
            this.setState({ BasicSalary: value.formattedValue, basicSalaryErr: "" });
        }
    }

    handleTotalSalaryTable = (value) => {
        if (value) {
            this.setState({ TotalSalary: value.formattedValue, TotalSalaryErr: "" });
        }
    }

    handleFinancialCostTable = (value) => {
        if (value) {
            this.setState({ FinancialCost: value.formattedValue, FinancialCostErr: "" });
        }
    }

    handleHousingAllowance = (value) => {
        if (value) {
            this.setState({ HousingAllowance: value.formattedValue, HousingAllowanceErr: "" });
        }
    }
    handleRetirementContributions = (value) => {
        if (value) {
            this.setState({ RetirementContributions: value.formattedValue, RetirementContributionsErr: "" });
        }
    }

    handleUnifiedNumber = (event) => {
        if (event.target.value) {
            if (Validator.validateUnifiedNumber(event.target.value)) {
                this.setState({ unifiedNumber: event.target.value });
            }
        } else {
            this.setState({ unifiedNumber: "" })
        }
    };

    handleretirementSubscriptions = (event) => {
        let regex = /^[0-9]{0,15}$/;
        if (regex.test(event.target.value)) {
            this.setState({ retirementSubscriptions: event.target.value });
        }
    };

    onrequiredRecomandationChange = (event) => {
        var newContent = event.editor.getData();
        if (event) {
            this.setState({ requiredRecomandation: newContent, requiredRecomandationErr: '' });
        } else {
            this.setState({ requiredRecomandation: '', requiredRecomandationErr: 'thisFieldisRequired' });
        }
    }

    onReasonsOfRequestChange = (event) => {
        var newContent = event.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({ ReasonsOfRequest: newContent, ReasonsOfRequestErr: '' });
        } else {
            this.setState({ ReasonsOfRequestErr: 'ckeditorCountErr' });
        }
    }

    handleEmiratesIDChange = (event) => {
        let EmiratesID = event.target.value;
        if (event.target.value !== '') {
            this.setState({ emiratesID: event.target.value }, () => {
                if (validator.validateEmiratesId(EmiratesID)) {
                    this.setState({ emiratesIDErr: '' });
                } else {
                    this.setState({ emiratesIDErr: 'invalidEmiratesId' });
                }
            });
        } else {
            this.setState({ emiratesID: '', emiratesIDErr: 'thisFieldisRequired' });
        }
    }

    handleTypeContractChange = (event) => {
        // this.setState({ typeOfContract: parseInt(event.target.value) });
        let dropDownValue = event.target.value;
        
        let tempArray = this.state.contractArray;
        let dropDownLabel = '';
        if (tempArray.length > 0) {
            for (let index = 0; index < tempArray.length; index++) {
                if (parseInt(dropDownValue) === tempArray[index].id) {
                    dropDownLabel = this.props.isArabic ? tempArray[index].ArabicTitle : tempArray[index].EnglishTitle;
                    if(dropDownValue=="1")
                    {
this.state.contractperiodrequired="1";
debugger;
//this.refs.contractperiodrequiredspan.style('display:none');
document.getElementById('contractperiodrequiredspan').style.display = "none";
document.getElementById('exampleFormControlTextarea2').required=false;
                    }
                    else
                    {
                        document.getElementById('contractperiodrequiredspan').style.display = "flex";
                        document.getElementById('exampleFormControlTextarea2').required=true;
                    }
                }
            }
        }

        this.setState({
            typeOfContract: parseInt(event.target.value),
            typeOfContractLabel: dropDownLabel
        });
    };

    saveAsDraft = () => {
        
        this.props.saveAsDraft({
            formType: 'saveAsDraft',
            saveFrom: "Step1",
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            typeOfContract: this.state.typeOfContract,
            typeOfContractLabel: this.state.typeOfContractLabel,
            EmployeeName: this.state.EmployeeName,
            EntityName: this.state.EntityName,
            BasicSalary: this.state.BasicSalary,
            unifiedNumber: this.state.unifiedNumber,
            jobTitle: this.state.jobTitle,
            contractPeriod: this.state.contractPeriod,
            FinancialCost: this.state.FinancialCost,
            TotalSalary: this.state.TotalSalary,
            EmployeeNumber: this.state.EmployeeNumber,
            HousingAllowance: this.state.HousingAllowance,
            RetirementContributions: this.state.RetirementContributions,
            ReasonsOfRequest: this.state.ReasonsOfRequest,
            emiratesID: this.state.emiratesID
        });
    };

    render() {

        return (
            <form
                style={{ marginBottom: '150px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate
            >
                <StepsComponent
                    steps={["Step1", "Step2"]}
                    currentIndex={1}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow className="formRow" style={{ marginTop: '20px' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group">
                            <label
                                htmlFor="retirementContributions"
                                style={{
                                    display: "flex",
                                    flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Employee_number}
                                <span style={{ color: "red" }}> *</span>
                            </label>

                            <input
                                className="form-control"
                                style={{
                                    display: "flex",
                                  flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                    direction: this.props.isArabic ? "rtl" : "ltr",
                                    borderRadius: "5px"
                                }}
                                type="text"
                                value={
                                    this.state.EmployeeNumber === 0
                                        ? null
                                        : this.state.EmployeeNumber
                                }
                                onChange={event => {
                                    let regex = /^[0-9]{0,15}$/;
                                    if (regex.test(event.target.value)) {
                                        this.setState({ EmployeeNumber: event.target.value });
                                    }
                                    else {
                                        return;
                                    }
                                }}
                                id="exampleFormControlTextarea2"
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>

                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="Entityformalrequest"
                                style={{
                                    display: "flex",
                                  flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}
                            >
                                {
                                    i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies
                                        .Entity_name
                                }
                                <span style={{ color: "red" }}> *</span>
                            </label>
                            <div class="form-group">
                                <input
                                    disabled={true}
                                    class="form-control "
                                    style={{
                                        display: "flex",
                                        flexDirection: this.props.isArabic
                                            ? "row-reverse"
                                            : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        borderRadius: "5px"
                                    }}
                                    value={
                                        this.state.EntityName
                                    }
                                    onChange={event => {
                                        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ EntityName: event.target.value });
                                        }
                                        else {
                                            return;
                                        }

                                    }}
                                    required
                                    type="text"
                                    id="exampleFormControlTextarea2"
                                    name="exampleFormControlTextarea2"
                                />
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', textAlign: this.props.isArabic ? 'right' : 'left', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].emiratesID}<span className="requireStar">*</span></label>
                            <div style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                <InputMask
                                    mask="999-9999-9999999-9"
                                    maskChar={null}
                                    value={this.state.emiratesID}
                                    onChange={this.handleEmiratesIDChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            {
                                this.state.emiratesIDErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                            {
                                this.state.emiratesIDErr === 'invalidEmiratesId' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].invalidEmiratesID}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.unifiedNumber}<span className="requireStar">*</span></label>

                            <input
                                type="text"
                                className="form-control"
                                id="unifiedNumber"
                                name="unifiedNumber"
                                onChange={this.handleUnifiedNumber}
                                value={this.state.unifiedNumber}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>

                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">

                        <div className="form-group ">
                            <label
                                htmlFor="htmlForEmpName"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Employee_name}
                                <span style={{ color: "red" }}> *</span>
                            </label>
                            <div class="form-group">
                                <input
                                    class="form-control"
                                    style={{
                                        display: "flex",
                                       flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        borderRadius: "5px"
                                    }}
                                    value={this.state.EmployeeName}
                                    onChange={event => {
                                        if (event.target.value && event.target.value.trim()) {
                                            let regex = /^[\u0621-\u064AA-Za-z ]*$/;
                                            if (regex.test(event.target.value) && event.target.value.length < 51) {
                                                this.setState({ EmployeeName: event.target.value });
                                            }
                                        } else {
                                            this.setState({ EmployeeName: '' });
                                        }
                                    }}
                                    required
                                    id="htmlForEmpName1"
                                    name="htmlForEmpName1"
                                    type="text"
                                />
                                <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="formGroupExampleInput"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].jobTitle}
                                <span style={{ color: "red" }}> *</span>
                            </label>

                            <input
                                className="form-control "
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                    direction: this.props.isArabic ? "rtl" : "ltr",
                                    borderRadius: "5px"
                                }}
                                type="text"
                                value={this.state.jobTitle}
                                onChange={event => {
                                    if (event.target.value.trim() != '') {
                                        let regex = /^[\u0621-\u064AA-Za-z \n]{0,50}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ jobTitle: event.target.value });
                                        }
                                    } else {
                                        this.setState({ jobTitle: '' });
                                    }

                                }}
                                id="exampleFormControlTextarea2"
                                required
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.file_required}
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="formGroupExampleInput"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Basic_salary}
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <div class="form-group">
                                {/* <input
                                    class="form-control "
                                    style={{
                                        display: "flex",
                                      flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        borderRadius: "5px"
                                    }}
                                    value={
                                        this.state.BasicSalary === 0
                                        ? null
                                        : this.state.BasicSalary
                                    }
                                    onChange={event => {
                                        let regex = /^[0-9\b.]{0,15}$/;
                                        if (regex.test(event.target.value)) {
                                        if (event.target.value.split('.').length <= 2) {
                                            this.setState({ BasicSalary: event.target.value });
                                        }
                                        }
                                    }}
                                    required
                                    id="exampleFormControlTextarea2"
                                    type="text"
                                    /> */}

                                <div className=" currencyFormatInputCSS currencyFormat"
                                    style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', border: this.state.basicSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                    <CurrencyFormat
                                        thousandSeparator={true}
                                        value={this.state.BasicSalary}
                                        onValueChange={(value) => { this.handleBasicSalaryTable(value) }}
                                        display={"text"}
                                        maxLength={10}
                                        required
                                    />

                                </div>
                                {
                                    this.state.basicSalaryErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.basicSalaryErr === 'salaryErr' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].salaryErr}
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>

                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="formGroupExampleInput"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Total_salary}
                                <span style={{ color: "red" }}> *</span>
                            </label>
                            <div class="form-group">
                                {/* <input
                                    class="form-control "
                                    style={{
                                        display: "flex",
                                      flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        borderRadius: "5px"
                                    }}
                                    value={
                                        this.state.TotalSalary === 0
                                        ? null
                                        : this.state.TotalSalary
                                    }
                                    onChange={event => {
                                        let regex = /^[0-9\b.]{0,15}$/;
                                        if (regex.test(event.target.value)) {
                                        if (event.target.value.split('.').length <= 2) {
                                            this.setState({ TotalSalary: event.target.value });
                                        }
                                        }
                                        else {
                                        return;
                                        }
                                    }}
                                    required
                                    id="exampleFormControlTextarea2"
                                    type="text"

                                    /> */}

                                <div className="currencyFormatInputCSS currencyFormat"
                                    style={{
                                        direction: this.props.isArabic ? 'rtl' : 'ltr',
                                        border: this.state.TotalSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                    }}>
                                    <CurrencyFormat
                                        thousandSeparator={true}
                                        value={this.state.TotalSalary}
                                        onValueChange={(value) => { this.handleTotalSalaryTable(value) }}
                                        display={"text"}
                                        maxLength={10}
                                        required
                                    />

                                </div>
                                {
                                    this.state.TotalSalaryErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="Financialcost"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Financial_cost}
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend" />
                                {/* <input
                                    className="form-control "
                                    style={{
                                        display: "flex",
                                      flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                        direction: this.props.isArabic ? "rtl" : "ltr",
                                        borderRadius: "5px"
                                    }}
                                    type="text"
                                    value={
                                        this.state.FinancialCost === 0
                                        ? null
                                        : this.state.FinancialCost
                                    }
                                    onChange={event => {
                                        let regex = /^[0-9\b.]{0,15}$/;
                                        if (regex.test(event.target.value)) {
                                        if (event.target.value.split('.').length <= 2) {
                                            this.setState({ FinancialCost: event.target.value });
                                        }
                                        }
                                        else {
                                        return;
                                        }
                                    }}
                                    id="exampleFormControlTextarea2"

                                    required
                                    /> */}

                                {/* <div className=" currencyFormatInputCSS currencyFormat"
                                    style={{
                                        direction: this.props.isArabic ? 'rtl' : 'ltr',
                                        border: this.state.FinancialCostErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                    }}>
                                    <CurrencyFormat
                                        thousandSeparator={true}
                                        value={this.state.FinancialCost}
                                        onValueChange={(value) => { this.handleFinancialCostTable(value) }}
                                        display={"text"}
                                        maxLength={10}
                                        required
                                    />
                                </div>

                                {
                                    this.state.FinancialCostErr === 'thisFieldIsRequired' ?
                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol> */} 

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="formGroupExampleInput"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Housing_allowance}
                                <span style={{ color: "red" }}>*</span>
                            </label>

                            {/* <input
                                className="form-control"
                                style={{
                                display: "flex",
                                flexDirection: this.props.isArabic
                                    ? "row-reverse"
                                    : "row",
                                direction: this.props.isArabic ? "rtl" : "ltr",
                                borderRadius: "5px"
                                }}
                                type="text"
                                value={
                                this.state.HousingAllowance === 0
                                    ? null
                                    : this.state.HousingAllowance
                                }
                                onChange={event => {
                                let regex = /^[0-9\b.]{0,15}$/;
                                if (regex.test(event.target.value)) {
                                    if (event.target.value.split('.').length <= 2) {
                                    this.setState({ HousingAllowance: event.target.value });
                                    }
                                }
                                else {
                                    return;
                                }
                                }}
                                id="exampleFormControlTextarea2"
                                required
                            /> */}

                            <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', border: this.state.HousingAllowanceErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.HousingAllowance}
                                    onValueChange={(value) => { this.handleHousingAllowance(value) }}
                                    display={"text"}
                                    maxLength={10}
                                    required
                                />

                            </div>
                            {
                                this.state.HousingAllowanceErr === 'thisFieldIsRequired' ?
                                    <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                        {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }

                            {
                                this.state.HousingAllowanceErr === 'housingAllowanceErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].housingAllowanceErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                {/* </MDBRow>
                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}> */}
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group ">
                            <label htmlFor="exampleFormControlTextarea2" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row', display: 'flex', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].type_of_contract}
                                <span className="required-mark" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', color: "red" }}>*</span>
                            </label>

                            <select
                                style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                                className="browser-default custom-select"
                                id="sources-funding"
                                onChange={this.handleTypeContractChange}
                                required
                                name="typeOfRequest"
                                value={this.state.typeOfContract}
                            >
                                <option value="">{i18n[this.props.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                {
                                    this.state.contractArray.map((item, index) => {
                                        return <option value={item.id}>{this.props.isArabic ? item.ArabicTitle : item.EnglishTitle}</option>
                                    })
                                }
                            </select>

                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left' }}>
                                {i18n[this.getLocale()].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        {/* <div className="form-group">
                        <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].retirementSubscriptions}<span className="requireStar">*</span></label>

                        <input
                            type="text"
                            className="form-control"
                            id="retirementSubscriptions"
                            name="retirementSubscriptions"
                            onChange={this.handleretirementSubscriptions}
                            value={this.state.retirementSubscriptions}
                            required
                            style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                        />

                        <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                        </div>

                    </div> */}

                    </MDBCol>

                </MDBRow>
                <MDBRow className="formRow" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label
                                htmlFor="contractPeriod"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}
                            >
                                {i18n[this.props.isArabic ? "ar" : "en"].contractPeriod}
                                <span id="contractperiodrequiredspan" style={{ color: "red",visibility:this.state.contractperiodrequired='1'? true:false }}>*</span>
                            </label>

                            <input
                           // id="contractperiodrequiredText"
                                className="form-control "
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row",
                                    direction: this.props.isArabic ? "rtl" : "ltr",
                                    borderRadius: "5px"
                                }}
                                type="text"
                                value={this.state.contractPeriod}
                                onChange={event => {
                                    if (event.target.value.trim() != '') {
                                        let regex = /^[0-9]{0,2}$/;
                                        if (regex.test(event.target.value)) {
                                            this.setState({ contractPeriod: event.target.value });
                                        }
                                    }
                                    else {
                                        this.setState({ contractPeriod: '' });
                                    }
                                }}
                                id="exampleFormControlTextarea2"
                                required
                              //s{this.state.contractperiodrequired='1' ? required:'' }
                            />
                            <div className="invalid-feedback" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div>
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group ">
                            <label
                                htmlFor="htmlForRetirementCont"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.Retirement_Contributions}
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            {/* <input
                                class="form-control "
                                style={{
                                display: "flex",
                                flexDirection: this.props.isArabic
                                    ? "row-reverse"
                                    : "row",
                                direction: this.props.isArabic ? "rtl" : "ltr",
                                borderRadius: "5px"
                                }}
                                value={
                                this.state.RetirementContributions
                                }
                                onChange={event => {
                                let regex = /^[0-9\b.]{0,15}$/;
                                if (regex.test(event.target.value)) {
                                    if (event.target.value.split('.').length <= 2) {
                                    this.setState({ RetirementContributions: event.target.value });
                                    }
                                }
                                }}
                                id="exampleFormControlTextarea2"
                                name="exampleFormControlTextarea2"
                                type="text"
                            /> */}

                            <div className=" currencyFormatInputCSS currencyFormat"
                                style={{
                                    direction: this.props.isArabic ? 'rtl' : 'ltr',
                                    border: this.state.RetirementContributionsErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                }}
                            >
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.RetirementContributions}
                                    onValueChange={(value) => { this.handleRetirementContributions(value) }}
                                    display={"text"}
                                    maxLength={10}
                                />

                            </div>
                            {/* {this.state.RetirementContributionsErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null} */}

                            {
                                this.state.RetirementContributionsErr === 'RetirementContributionsErr' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].RetirementContributionsErr}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

               

                <MDBRow className="" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group ">
                            <label
                                htmlFor="htmlForReasonRequest"
                                style={{
                                    display: "flex",
                                   flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row"
                                }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].HRAPolicies.ReasonsOfRequestHousing}
                                <span style={{ color: "red" }}> *</span>
                            </label>

                            <div class="form-group">
                                <div style={{
                                    border: this.state.ReasonsOfRequestErr === 'thisFieldisRequired' ? '1px solid red' : ''
                                }} className="ckEditorStyle">
                                    {
                                        this.props.isArabic ?
                                            null
                                            :
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.ReasonsOfRequest}
                                                events={{
                                                    "change": this.onReasonsOfRequestChange
                                                }}
                                                config={{ language: "en", height: '10vh' }}
                                            />
                                    }
                                    {
                                        this.props.isArabic ?
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.ReasonsOfRequest}
                                                events={{
                                                    "change": this.onReasonsOfRequestChange
                                                }}
                                                config={{ language: "ar", height: '10vh' }}
                                            />
                                            :
                                            null
                                    }
                                </div>
                                <p style={{ display: "flex",flexDirection: this.getLocale()=='ar' ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                    {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                </p>
                                {
                                    this.state.ReasonsOfRequestErr === 'thisFieldisRequired' ?
                                        <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                            {i18n[this.getLocale()].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="formRow" style={{ flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn type="submit" color="primary" className="submitBtn">{i18n[this.props.isArabic ? 'ar' : 'en'].next}</MDBBtn>
                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }
                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>
            </form>
        );
    }
}
