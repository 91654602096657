import React, { Component } from 'react';

import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody,MDBModal,MDBModalHeader,MDBModalBody } from "mdbreact";

import Footer from '../Footer';
import Header from '../Header';
import SideBar from '../SideBar';
import i18n from '../../i18n';
import Locali18n from './Locali18n';
import  RequestFunctions from './RequestFunctions';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';
import { uploadDocument ,OrganizationStrucutreRequestPayload} from '../../utils/Payload';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import CKEditorComponent from '../CKEditorComponent';
import DownloadComponent from "../DownloadComponent";
import PostionsFunctions from './PostionsFunctions';



export default class PositionFunctionalRoles  extends PostionsFunctions   {

    constructor(props)
    {
        super(props);
       
    }


    render()
    {
        let  FunctionalObj = Config.getOrganizationStructurFunctionalRoles();

        return (
         <MDBModal 
         size="lg"
         isOpen={this.props.open}
         toggle={this.props.open}
         backdrop={false}
         tabIndex="0"
         keyboard={true}>

            <MDBModalHeader >{Locali18n[this.getLocale()].addFuncationalRoles}</MDBModalHeader>

             <form  className="needs-validation formWeight formSpace"
             //onSubmit={  this.props.hide}
              noValidate>
                   <MDBModalBody   style={{overflow: 'auto'}}>     

             
                
              <MDBRow>
<MDBCol xs="11" sm="11" md="11" lg="11" xl="11">
</MDBCol>
 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">             
<MDBBtn className="primary plusBtn" onClick={() => this.addFunctionalRole()}>+</MDBBtn>

 </MDBCol>
  </MDBRow>
        <br></br>                 
          
         {  FunctionalObj ?
               FunctionalObj.map((item, index) => {
         return(
             <MDBRow style={{ display: 'flex', flexDirection: this.isArabic ? 'row-reverse' : 'row' }}>
                 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1"></MDBCol>
              <MDBCol xs="9" sm="9" md="9" lg="9" xl="9">   
                     <div className="form-group">
                         <input
                             type="text"
                             className="form-control"
                                key={index}
                                tabIndex={index}
                                value={item.desc}
                             onChange={(e) =>this.handleFunctionalRoleOnchange(e,index)}
                             style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                         />
                     </div>
               
 </MDBCol>
 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">   
                    <span>{index+1}</span>
                
            </MDBCol>
 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
       <MDBBtn className="primary deleteBtn" key={index} onClick={() => this.deleteFunctionalRole(index)}>-</MDBBtn>

 </MDBCol>
</MDBRow> 
      
           
             )
         }) : null
         }
           <MDBRow style={{ display: 'flex', flexDirection: this.isArabic ? 'row-reverse' : 'row' }}>
             <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
             </MDBCol> 
                  <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
             <MDBBtn className="submitBtn" color="primary"  onClick={this.props.hide}>
                     {Locali18n[this.getLocale()].submit }
                 </MDBBtn>
                 </MDBCol>
                 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
             <MDBBtn className="submitBtn"  color="primary"
              onClick={Config.OrganizationStructurFunctionalRoles=[],this.props.hide}>
                     {Locali18n[this.getLocale()].close} 
                 </MDBBtn>
                 </MDBCol>
                 <MDBCol xs="4" sm="4" md="4" lg="4" xl="4">
             </MDBCol> 
                 </MDBRow> 
                 </MDBModalBody>
         </form>
     </MDBModal>
       
       
       )};
       

        }
                           

