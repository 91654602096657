import React, { Component } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import i18n from '../../i18n';
import { assginGmPayload } from '../../utils/Payload';
import ApiRequest from '../../services/ApiRequest';
import ToastComponent from '../ToastComponent';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import DownloadComponent from "./../DownloadComponent";
import { uploadDocument } from "./../../utils/Payload";

import DatePicker from "react-datepicker";

export default class ExtendDateComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            attachFile1: '',
            isAttachmentDownloadShowsInEdit: true,
            editAttachmentArray: [],
            infoAttachmentArray: [],
            actualAttachFileOne: '',
            actualAttachFileUrl: '',
            isLoading: false,
            comment: '',
            requestType: '',
            datefrom: '',
            dateto: '',
            pathName: '',
            id: '',
            lastTimeToReplyErr: '',
            notes: '',
            mindate: '',
        }
    }
    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }
    attachFileOnChange1 = e => {
        if (e.target.files.length > 0) {
            let inpFileName = e.target.files[0].name;
            let fileSize = e.target.files[0].size;
            let fileExt = inpFileName.split('.').pop().toUpperCase();
            let allowedExtensions = ['PDF', 'DOC', 'DOCX', 'PPT', 'PPTX', 'XLS', 'XLSX', 'TXT', 'JPEG', 'PNG', 'JPG'];
            if (allowedExtensions.indexOf(fileExt) <= -1) {
                e.target.value = '';
                this.setState({
                    attachFile1: '',
                    reqAttachmentErr: 'invalidFileType'
                });
            } else {
                if (fileSize > 0 && fileSize < Config.getMaxFileSize()) {
                    this.setState({ attachFile1: e.target.files[0].name, reqAttachmentErr: '' });
                    let tempFile = e.target.files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(tempFile);
                    reader.onloadend = () => {
                        this.setState({
                            actualAttachFileOne: reader.result,
                        }, () => {
                            Config.setBase64ForInformatinRequestOne(this.state.actualAttachFileOne);
                        });
                    }
                }
                else {
                    e.target.value = '';
                    this.setState({ attachFile1: '', reqAttachmentErr: 'invalidFileSize' });
                }
            }
        } else {
            e.target.value = '';
            this.setState({ attachFile1: '' })
        }
    };

    componentDidMount = () => {

        //    console.log("data : ", this.props.data);

        this.Load();

    }


    onDatepickerRef = (el) => {
        if (el && el.input) {
            el.input.readOnly = true;
            el.input.classList.add('white_bg');
        }
    }

    Load = () => {
        let obj = {};
        let endPoint = 'GetinformationrequestbyID';
        let payload = assginGmPayload(obj);
        payload.id = this.props.id;
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                debugger;
                this.setState({
                    isLoading: false
                }, () => {

                    if (res.Status) {
                        // this.props.toggle(true);
                        console.log("res : ", res);
                        debugger;
                        this.setState({ datefrom: res.data.datettoreply })
                        this.setState({ mindate: res.data.minextdate })

                    } else {

                        // this.props.toggle(false);
                        ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoloadextend);
                    }
                })
            })
        })

    }

    uploadDocument = (obj) => {
        let uploadDocumentPayload = {};
        let lan = localStorage.getItem('Lang');
        let getDataFromLocalStorage = Config.getLocalStorageLoginData();
        if (getDataFromLocalStorage) {
            uploadDocumentPayload.entityID = getDataFromLocalStorage.EntityID;
            uploadDocumentPayload.userid = getDataFromLocalStorage.id;
        }
        uploadDocumentPayload.requestType = Config.getInformationRequestTypeId();
        uploadDocumentPayload.requestid = obj.requestid;
        uploadDocumentPayload.filename = obj.filename;
        uploadDocumentPayload.fileContent = obj.fileContent;
        uploadDocumentPayload.filetype = obj.filetype;

        if (lan === 'ar')
            uploadDocumentPayload.lang = 1;
        else
            uploadDocumentPayload.lang = 0;

        return uploadDocumentPayload;
    }

    uploadAttachment = () => {

        let attachment = Config.getBase64ForInformatinRequestForReply();
        if (attachment !== '') {
            let objAttachment = {};
            objAttachment.requestid = this.props.id;
            objAttachment.requesttype = this.props.requesttypeid;

            if (objAttachment.requesttype == 10953) {
                objAttachment.filetype = 10959;
            }
            else {
                objAttachment.filetype = this.state.fileType;
            }
            objAttachment.filename = this.state.fileName;

            let tempBase64 = attachment;
            objAttachment.fileContent = tempBase64.split(',')[1];
            let payloadForAttachment = uploadDocument(objAttachment);
            let endPointForAttachment = 'UploadAttachment'
            let urlForAttachment = Config.getUrl(endPointForAttachment);

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(urlForAttachment, payloadForAttachment, resForAttachment => {
                    if (resForAttachment.Status) {
                        if (objAttachment.requesttype != 4) {
                            this.callSaveStatus();
                        }
                        else {
                            this.setState({
                                isLoading: false
                            }, () => {
                                this.props.toggle(true);
                            })
                        }
                    } else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            this.props.toggle(false);
                        })
                    }
                })
            })
        } else {
            // TODO
        }
    }

    submitHandler = (event) => {
        event.preventDefault();


        if (this.state.comment === '') {
            this.setState({ notes: "thisFieldIsRequired" });
        }
        if (this.state.dateto === '') {
            this.setState({ lastTimeToReplyErr: "thisFieldIsRequired" });
        }

        if (this.state.comment !== '' && this.state.dateto !== '') {
            event.target.className += " was-validated";
            let payload = {};
            payload.requestid = this.props.id;
            // obj.requesttype = this.state.requestType;
            // obj.requesttype = this.props.requesttypeid;
            payload.comment = this.state.comment;
            payload.todate = this.state.dateto;

            let endPoint = 'ExtendInfoDate';

            let userid = Config.getLocalStorageLoginData().id;
            payload.userid = userid;
            debugger;
            let url = Config.getUrl(endPoint);
            let attachment = Config.getBase64ForInformatinRequestForReply();

            this.setState({
                isLoading: true
            }, () => {
                ApiRequest.sendRequestForPost(url, payload, res => {
                    debugger;
                    this.setState({
                        isLoading: false
                    }, () => {

                        if (res) {
                            if (attachment !== '') {
                                this.uploadAttachment();
                            }
                            ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].savedsuccessfully);

                            this.props.toggle(true);
                        } else {

                            this.props.toggle(false);
                            ToastComponent.notifyInfo(i18n[this.props.isArabic ? 'ar' : 'en'].failedtoextend);
                        }
                    })
                })
            })
        }
        else
            return;

    };

    getmindate = () => {
        debugger;
        let v = Date(this.state.datefrom) + 1;
        return v;
    }

    changecomment = (value) => {

        this.setState({ comment: value });
        this.setState({ notes: '' });

    }

    handleLastTimeToReply = (date) => {
        this.setState({ dateto: date, lastTimeToReplyErr: '' });
    }


    render() {
        // let pathName = '';
        if (!this.props && !this.props.location && !this.props.location.state) {
            // pathName = localStorage.getItem('currentPath');
        }
        else if (this.props && this.props.location && this.props.location.state) {
            // pathName = this.props.location.state.pathname
            localStorage.setItem('currentPath', this.props.location.state.pathname);
        }

        return (
            <MDBContainer style={{ justifyContent: this.props.isArabic ? 'row-reverse' : 'row' }}>

                <form
                    style={{ marginTop: 20 }}
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                //noValidate
                >

                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner={true}>

                        <MDBRow className="formRow">




                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].toDate}
                                        <span className="requireStar">*</span>
                                    </label>

                                    <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }}
                                        className={`${"react-datepicker-popper datePickerBgClass"} ${this.state.lastTimeToReplyErr ? "datePickerBorderColorErr" : ""}`}>

                                        <DatePicker
                                            className="form-control"
                                            todayButton={"Today"}
                                            minDate={new Date(this.state.mindate)}
                                            style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}
                                            selected={this.state.dateto}
                                            onChange={this.handleLastTimeToReply}
                                            ref={el => this.onDatepickerRef(el)}
                                            customInput={<input />}
                                            dateFormat="MM/dd/yyyy"
                                            showMonthDropdown
                                            showYearDropdown
                                        //showTimeInput
                                        //required
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.lastTimeToReplyErr === "thisFieldIsRequired" ?

                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                            </MDBCol>

                            <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].datefrominfo}
                                    </label>

                                    <div style={{ width: '100%' }}
                                        className={`${this.props.isArabic ? "react-datepicker-popper datePicker_ar datePickerBgClass" : "react-datepicker-popper datePickerBgClass"} ${this.state.lastTimeToReplyErr ? "datePickerBorderColorErr" : ""}`}>

                                        <label htmlFor="formGroupExampleInput"
                                            style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}
                                        >
                                            {this.state.datefrom}
                                        </label>
                                    </div>

                                </div>
                            </MDBCol>



                        </MDBRow>
                        <MDBRow className="formRow">

                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput"
                                    style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', width: '100%' }}
                                >
                                    {i18n[this.getLocale()].attached_files}
                                </label>

                                <div className="input-group">
                                    <div className="custom-file-inp">
                                        <input
                                            id="attachFile1File"
                                            type="file"
                                            name="attachFile1"
                                            onChange={(event) => this.attachFileOnChange1(event)}
                                        />
                                        <label htmlFor="attachFile1File" className={this.props.isArabic ? "chooseFileLabel_ar" : "chooseFileLabel"}>{i18n[this.getLocale()].choose_file}</label>
                                        <label htmlFor="attachFile1File" className="fileName" style={{ textAlign: this.props.isArabic ? "right" : '' }}><span>{this.state.attachFile1}</span></label>

                                    </div>
                                    {
                                        this.state.reqAttachmentErr === 'invalidFileType' ?
                                            <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                {i18n[this.props.isArabic ? "ar" : "en"].invalidFileType}
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.reqAttachmentErr === 'invalidFileSize' ?
                                            <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                {i18n[this.props.isArabic ? "ar" : "en"].invalidFileSize}
                                            </div>
                                            :
                                            null
                                    }
                                    <p style={{ color: '#000', display: "flex", flexDirection: this.props.isArabic ? "row" : "row-reverse", width: '100%' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].acceptedFormatsAll}
                                    </p>
                                    {
                                        (this.state.isAttachmentDownloadShowsInEdit &&
                                            this.state.isAttachmentDownloadShowsInEdit.length > 0)
                                            ? <DownloadComponent filesObj={this.state.isAttachmentDownloadShowsInEdit} />
                                            : <span></span>

                                    }
                                     </div>
                                </div>
                            </MDBCol>

                        </MDBRow>
                        <MDBRow className="formRow">
                            <MDBCol md="12"  >
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}
                                        htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        <span className="requireStar">*</span>
                                    </label>


                                    <textarea
                                        className="form-control" style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                        placeholder={i18n[this.props.isArabic ? 'ar' : 'en'].add_comment}
                                        //required
                                        onChange={(event) => this.changecomment(event.target.value)}
                                    />



                                </div>
                                {
                                    this.state.notes === "thisFieldIsRequired" ?

                                        <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                            {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                        </div>
                                        :
                                        null
                                }

                            </MDBCol>

                        </MDBRow>

                        <MDBRow
                            style={{ float: this.props.isArabic ? 'right' : 'left', marginTop: '1%', paddingLeft: this.props.isArabic ? 0 : '5%', paddingRight: this.props.isArabic ? '5%' : 0, justifyContent: 'center', width: '100%' }} >
                            <MDBBtn type='submit' className="gridActionBtn" color="primary" style={{ borderWidth: 1, color: 'white', borderColor: '#d3d3d3', borderRadius: 5, height: '40px', width: '30%', padding: '5px' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].submit}
                            </MDBBtn>
                            <MDBBtn onClick={this.props.toggle} color="primary" style={{ width: '30%', height: '40px', padding: 5, borderRadius: 5 }}> {i18n[this.props.isArabic ? 'ar' : 'en'].cancle} </MDBBtn>
                        </MDBRow>

                    </LoadingOverlay>

                </form>

            </MDBContainer>
        );
    }
}

