import React, { Component } from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBTable, MDBTableBody } from "mdbreact";
import StepsComponent from '../../components/StepsComponent';
import './../../css/style.css';
import Config from './../../utils/Config';
import i18n from './../../i18n';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import moment from 'moment';
import CKEditor from "react-ckeditor-component";
import Validator from './../../utils/Validator';
import CurrencyFormat from 'react-currency-format';

const striptags = require('striptags');
var temp = 0;

export default class StepTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requestId: '',
            isEdit: '',
            statusid: '',
            isNextScreenActive: false,
            isfirstPageValidate: false,
            isNextClick: false,
            isArabic: true,
            isSubmit: false,
            totalDays: this.props.sendData.totalDays,
            myTemp: this.props.sendData.myTemp,
            dateOfStartingWork: this.props.sendData.dateOfStartingWork,
            husbandWifeWork: this.props.sendData.husbandWifeWork,
            noOfChildren: this.props.sendData.noOfChildren,
            placeOfChildren: this.props.sendData.placeOfChildren,
            totalAmountExempted: this.props.sendData.totalAmountExempted,
            HowToCalculate: this.props.sendData.HowToCalculate,
            dateOfSalaryTermination: this.props.sendData.dateOfSalaryTermination,
            violation: this.props.sendData.violation,
            violationErr: '',
            dateOfStartingWorkErr: '',
            explain: this.props.sendData.explain,
            administrativeProcedures: this.props.sendData.tableData,
            view: this.props.sendData.view,
            status: [],
            tempTableData: [],
            isStatusTerminated: [],
            noOfChildrenErr: '',
            explainErr: '',
            howToCalculateErr: '',
            totalAmtExmErr: "",
            intervalsErr: '',
            salaryStatusArr: [
                {
                    id: 1,
                    EnglishName: 'Continuous',
                    ArabicName: 'مستمر'
                },
                {
                    id: 2,
                    EnglishName: 'Stop',
                    ArabicName: 'متوقف'
                }
            ],
            statusArr: [
                {
                    id: 2,
                    EnglishName: 'Paid',
                    ArabicName: 'مدفوع'
                },
                {
                    id: 1,
                    EnglishName: 'Terminated',
                    ArabicName: 'منتهي'
                }
            ],
            monthArray: [
                {
                    id: 1,
                    EnglishName: 'January',
                    ArabicName: 'يناير'
                },
                {
                    id: 2,
                    EnglishName: 'February',
                    ArabicName: 'فبراير'
                },
                {
                    id: 3,
                    EnglishName: 'March',
                    ArabicName: 'مارس'
                },
                {
                    id: 4,
                    EnglishName: 'April',
                    ArabicName: 'ابريل'
                },
                {
                    id: 5,
                    EnglishName: 'May',
                    ArabicName: 'مايو'
                },
                {
                    id: 6,
                    EnglishName: 'June',
                    ArabicName: 'يونيو'
                },
                {
                    id: 7,
                    EnglishName: 'July',
                    ArabicName: 'يوليو'
                },
                {
                    id: 8,
                    EnglishName: 'August',
                    ArabicName: 'اغسطس'
                },
                {
                    id: 9,
                    EnglishName: 'September',
                    ArabicName: 'سبتمبر'
                },
                {
                    id: 10,
                    EnglishName: 'October',
                    ArabicName: 'اكتوبر'
                },
                {
                    id: 11,
                    EnglishName: 'November',
                    ArabicName: 'نوفمبر'
                },
                {
                    id: 12,
                    EnglishName: 'December',
                    ArabicName: 'ديسمبر'
                }
            ],
            basicSalaryTable: this.props.sendData.basicSalaryTable,
            year: "",
            month: "1",
            fromDay: "",
            toDay: "",
            salaryStatus: "Continuous",
            subTotal: "",
            noOfDays: "",
            subTotalTotal: this.props.sendData.subTotalTotal,
            totalNoOfDays: this.props.sendData.totalNoOfDays,
            totalSalaryWithSubscription: this.props.sendData.totalSalaryWithSubscription,
            retirementSalary: this.props.sendData.retirementSalary,
            tswsrsSalary: this.props.sendData.tswsrsSalary,
            yearErr: "",
            monthErr: "",
            fromDayErr: "",
            toDayErr: "",
            subTotalErr: "",
            noOfDaysErr: "",
            basicSalErr: "",
            retirementSalaryErr: "",
        }

        this.handleDateFrom1 = this.handleDateFrom1.bind(this);
        this.handleDateTo1 = this.handleDateTo1.bind(this);

        this.handleDateFrom2 = this.handleDateFrom2.bind(this);
        this.handleDateTo2 = this.handleDateTo2.bind(this);

        this.handleDateFrom3 = this.handleDateFrom3.bind(this);
        this.handleDateTo3 = this.handleDateTo3.bind(this);
    }

    componentDidMount = () => {

        window.scrollTo(0, 0);

        let editFlag = Config.getIsEditFlag();
        let editObj = Config.getEditObj();

        if (editFlag) {
            this.setState({
                requestId: editObj.requestId,
                isEdit: editFlag,
                statusid: editObj.statusid,
                totalDays: editObj.worktotaldays,
                myTemp: editObj.worktotalsalarytobepaid,
                dateOfStartingWork: new Date(`${editObj.dateofstarting}`),
                husbandWifeWork: editObj.husbandwifework,
                noOfChildren: editObj.numberofchidren,
                placeOfChildren: editObj.placeofchildren,
                totalAmountExempted: editObj.worktobeexempted,
                HowToCalculate: editObj.howtocalculaterule,
                violation: editObj.isviolation === 1 ? 'Yes' : 'No',
                explain: editObj.violationdetail,
                basicSalaryTable: editObj.basicsalary2 !='' ? editObj.basicsalary2:this.state.basicSalaryTable,
                totalSalaryWithSubscription: editObj.totalsalarywithsubscription !='' ? editObj.totalsalarywithsubscription : this.state.totalSalaryWithSubscription,
                retirementSalary: editObj.retirement !='' ? editObj.retirement: this.state.retirementSalary,
                tswsrsSalary: editObj.totalsalary2  !=''?editObj.totalsalary2:this.state.tswsrsSalary, 
                tempTableData: editObj.salarymatrix
            }, () => {
                let temp = [];
                if (this.state.tempTableData && this.state.tempTableData.length > 0) {
                    this.state.tempTableData.forEach((item, index) => {
                        let obj = {};
                        obj.year = item.year;
                        obj.salaryStatus = item.salaryStatus;
                        obj.noOfDays = item.noOfDays;
                        obj.month = item.month;
                        // obj.subTotalTotal = item.totalSalary;

                        if (item.subtotal) {
                            obj.subTotal = item.subtotal;
                        } else if (item.subTotal) {
                            obj.subTotal = item.subTotal;
                        }

                        if (item.from) {
                            obj.fromDay = item.from;
                        } else if (item.fromDay) {
                            obj.fromDay = item.fromDay;
                        }

                        if (item.to) {
                            obj.toDay = item.to;
                        } else if (item.toDay) {
                            obj.toDay = item.toDay;
                        }

                        temp.push(obj);
                        this.recalculateSubTotalAndNoOfDaysWhenEdit(item);
                    });

                    this.setState({
                        administrativeProcedures: temp
                    }, () => {
                      
                    });
                }
                // set flag
                //Config.setIsEditFlag(false);
            })
        }

    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    };

    // handleAmountExempted = (event) => {
    //     let regex = /^[0-9\b.]{0,15}$/;
    //     if (regex.test(event.target.value)) {
    //         if (event.target.value.split('.').length <= 2) {
    //             this.setState({ totalAmountExempted: event.target.value });
    //         }
    //     }
    // };

    handleAmountExempted = (value) => {
        if (value) {
          
            this.setState({ totalAmountExempted: value.formattedValue, totalAmtExmErr: "" });
        }
    };

    handletotalSalaryWithSubscriptionTable = (value) => {
        if (value) {
            this.setState({
                totalSalaryWithSubscription: value.value,
                totalSalaryWithSubscriptionErr: ""
            }, () => {
                this.calculateTswsRsSalary();
            });

        }
    };

    handleretirementSalaryTable = (value) => {
        if (value) {
            this.setState({
                retirementSalary: value.value,
                retirementSalaryErr: ""
            }, () => this.calculateTswsRsSalary());
        }
    };

    handletswsrsSalaryTable = (value) => {
        if (value) {
         
            this.setState({ tswsrsSalary: value.value, tswsrsSalaryErr: "" });
        }
    };

    handleHowToCalculate = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                howToCalculateErr: '',
                HowToCalculate: newContent
            })
        } else{
            this.setState({
                howToCalculateErr: "ckeditorCountErr"
            });
        }
    }

    handleExplainOnChange = (evt) => {
        var newContent = evt.editor.getData();
        let newContent1 = striptags(newContent);

        if (newContent1.length <= Config.getMaxCKEditorLimit()) {
            this.setState({
                explainErr: "",
                explain: newContent
            })
        }
        else {
            this.setState({
                explainErr: "ckeditorCountErr"
            })
        }
    }

    handleNoOfChildren = (event) => {
        let regex = /^[0-9\b]{0,2}$/;
        if (regex.test(event.target.value)) {
            if (parseInt(event.target.value) < 20) {
                this.setState({ noOfChildren: event.target.value, noOfChildrenErr: "" });
            }
            else {
                this.setState({
                    noOfChildren: "",
                    noOfChildrenErr: "noOfChildShouldBeLesThan20"
                });
            }
        }
        else {
            this.setState({ noOfChildren: "" });
        }
    }

    handleBasicSalaryTable = (value) => {
        if (value) {
          
            this.setState({ basicSalaryTable: value.value, basicSalErr: "" });
        }

    }

    goToPrevious = () => {
        this.props.getPrev.stepTwoDataPrev();
        this.props.getData.stepTwoPrev({
            myTemp: this.state.myTemp,
            dateOfStartingWork: this.state.dateOfStartingWork,
            husbandWifeWork: this.state.husbandWifeWork,
            noOfChildren: this.state.noOfChildren,
            placeOfChildren: this.state.placeOfChildren,
            totalAmountExempted: this.state.totalAmountExempted,
            HowToCalculate: this.state.HowToCalculate,
            violation: this.state.violation,
            explain: this.state.explain,
            tableData: this.state.administrativeProcedures,
            basicSalaryTable: this.state.basicSalaryTable,
            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
            retirementSalary: this.state.retirementSalary,
            tswsrsSalary: this.state.tswsrsSalary,
            subTotalTotal: this.state.subTotalTotal,
            totalNoOfDays: this.state.totalNoOfDays
        });
    }

    handleHusbandWifeWork = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ husbandWifeWork: event.target.value });
            }
        } else {
            this.setState({ husbandWifeWork: '' });
        }
    };

    saveAsDraft = () => {
        this.props.saveAsDraft({
            isEdit: this.state.isEdit,
            requestId: this.state.requestId,
            formType: 'saveAsDraft',
            saveFrom: "Step2",
            totalDays: this.state.totalDays,
            myTemp: this.state.myTemp,
            //  dateOfStartingWork: this.state.dateOfStartingWork,
            husbandWifeWork: this.state.husbandWifeWork,
            noOfChildren: this.state.noOfChildren,
            placeOfChildren: this.state.placeOfChildren,
            totalAmountExempted: this.state.totalAmountExempted,
            HowToCalculate: this.state.HowToCalculate,
            violation: this.state.violation,
            explain: this.state.explain,
            tableData: this.state.administrativeProcedures,
            basicSalaryTable: this.state.basicSalaryTable,
            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
            retirementSalary: this.state.retirementSalary,
            tswsrsSalary: this.state.tswsrsSalary,
            subTotalTotal: this.state.subTotalTotal,
            totalNoOfDays: this.state.totalNoOfDays
        });
    };

    submitHandler = (event) => {

        event.preventDefault();
        event.target.className += " was-validated";

        if (!this.state.violation) {
            this.setState({
                violationErr: 'thisFieldIsRequired'
            })
        }

        let totalAmtExmErr = "",
            intervalsErr = false,
            tempExplainErr = "",
            tempHowToCalErr = "",
            basicSalErr = "",
            retirementSalaryErr = "";

        if (!this.state.totalAmountExempted) {
            totalAmtExmErr = "thisFieldIsRequired";
        }

        this.setState({
            administrativeProcedures: this.state.administrativeProcedures
        });

        for (let i = 0; i < this.state.administrativeProcedures.length; i++) {
            if (!this.state.administrativeProcedures[i].year) {
                intervalsErr = true;
            }
            if (!this.state.administrativeProcedures[i].fromDay) {
                intervalsErr = true;
            }
            if (!this.state.administrativeProcedures[i].toDay) {
                intervalsErr = true;
            }
            if (!this.state.administrativeProcedures[i].subTotal) {
                intervalsErr = true;
            } else {
                if (parseInt(this.state.administrativeProcedures[i].subTotal) <= 0) {
                    intervalsErr = true;
                }
            }
        }

        if (this.state.explain && this.state.explain.trim()) {
            let val = striptags(this.state.explain.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempExplainErr = "thisFieldisRequired";
            }

        } else {
            tempExplainErr = "thisFieldisRequired";
        }

        if (this.state.HowToCalculate && this.state.HowToCalculate.trim()) {
            let val = striptags(this.state.HowToCalculate.replace(/&nbsp;/g, "").trim());
            if (!val.trim()) {
                tempHowToCalErr = "thisFieldisRequired";
            }
        } else {
            tempHowToCalErr = "thisFieldisRequired";
        }

        if (
            this.state.basicSalaryTable
            && this.state.totalSalaryWithSubscription
        ) {
            let basicSalAfterReplace = this.state.basicSalaryTable.replace(/,/g, "");
            let totalSalAfterReplace = this.state.totalSalaryWithSubscription.replace(/,/g, "");
            if (parseFloat(basicSalAfterReplace) > parseFloat(totalSalAfterReplace)) {
                basicSalErr = 'basicSalErr';
            }
        }

        if (
            this.state.retirementSalary &&
            this.state.totalSalaryWithSubscription
        ) {
            let retirementSalaryAfterReplace = this.state.retirementSalary.replace(/,/g, "");
            let totalSalAfterReplace = this.state.totalSalaryWithSubscription.replace(/,/g, "");
            if (parseFloat(retirementSalaryAfterReplace) > parseFloat(totalSalAfterReplace)) {
                retirementSalaryErr = 'retirementSalaryErr';
            } else {
                retirementSalaryErr = '';
            }
        }

        this.setState({
            explainErr: tempExplainErr,
            intervalsErr: intervalsErr,
            howToCalculateErr: tempHowToCalErr,
            totalAmtExmErr: totalAmtExmErr,
            basicSalErr: basicSalErr,
            retirementSalaryErr: retirementSalaryErr
        }, () => {

            if (this.state.violation === 'Yes') {

                if (
                    !this.state.explainErr &&
                    !this.state.howToCalculateErr &&
                    !totalAmtExmErr &&
                    !this.state.intervalsErr &&
                    !this.state.basicSalErr &&
                    !this.state.retirementSalaryErr) {

                    if (
                        this.state.totalAmountExempted &&
                        striptags(this.state.HowToCalculate) &&
                        striptags(this.state.explain)) {

                        this.props.getData.stepTwoData({
                            totalDays: this.state.totalDays,
                            myTemp: this.state.myTemp,
                            //  dateOfStartingWork: this.state.dateOfStartingWork,
                            husbandWifeWork: this.state.husbandWifeWork,
                            noOfChildren: this.state.noOfChildren,
                            placeOfChildren: this.state.placeOfChildren,
                            totalAmountExempted: this.state.totalAmountExempted,
                            HowToCalculate: this.state.HowToCalculate,
                            violation: this.state.violation,
                            explain: this.state.explain,
                            tableData: this.state.administrativeProcedures,
                            basicSalaryTable: this.state.basicSalaryTable,
                            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
                            retirementSalary: this.state.retirementSalary,
                            tswsrsSalary: this.state.tswsrsSalary,
                            subTotalTotal: this.state.subTotalTotal,
                            totalNoOfDays: this.state.totalNoOfDays
                        });

                    }

                }

            } else {
                if (
                    !this.state.howToCalculateErr &&
                    !totalAmtExmErr &&
                    !this.state.intervalsErr &&
                    !this.state.basicSalErr &&
                    !this.state.retirementSalaryErr
                ) {

                    if (
                        this.state.totalAmountExempted &&
                        striptags(this.state.HowToCalculate) &&
                        this.state.violation) {

                        this.props.getData.stepTwoData({
                            totalDays: this.state.totalDays,
                            myTemp: this.state.myTemp,
                            //  dateOfStartingWork: this.state.dateOfStartingWork,
                            husbandWifeWork: this.state.husbandWifeWork,
                            noOfChildren: this.state.noOfChildren,
                            placeOfChildren: this.state.placeOfChildren,
                            totalAmountExempted: this.state.totalAmountExempted,
                            HowToCalculate: this.state.HowToCalculate,
                            violation: this.state.violation,
                            explain: this.state.explain,
                            tableData: this.state.administrativeProcedures,
                            basicSalaryTable: this.state.basicSalaryTable,
                            totalSalaryWithSubscription: this.state.totalSalaryWithSubscription,
                            retirementSalary: this.state.retirementSalary,
                            tswsrsSalary: this.state.tswsrsSalary,
                            subTotalTotal: this.state.subTotalTotal,
                            totalNoOfDays: this.state.totalNoOfDays
                        });
                    }
                }
            }
        });
    };

    clearForm = () => {
        this.setState({
            husbandWifeWork: '',
            noOfChildren: '',
            placeOfChildren: '',
            totalAmountExempted: '',
            HowToCalculate: '',
            violation: '',
            explain: '',
            tableData: '',
            basicSalaryTable: '',
            totalSalaryWithSubscription: '',
            retirementSalary: '',
            tswsrsSalary: '',
            subTotalTotal: '',
            totalNoOfDays: '',
        });
    }

    handlePlaceOfChildren = (event) => {
        if (event.target.value.trim() != '') {
            let regex = /^[\u0621-\u064AA-Za-z ]{0,50}$/;
            if (regex.test(event.target.value)) {
                this.setState({ placeOfChildren: event.target.value });
            }
        } else {
            this.setState({ placeOfChildren: '' });
        }
    };

    handleDateFrom1 = (item, date) => {
        if (date) {
            item.fromDate1 = date;
            item.toDate1IsDisabled = false;
            this.calculateNoOfDays(item);
        } else {
            item.toDate1IsDisabled = true;
        }
     
    };

    handleDateTo1 = (item, date) => {
        if (item.fromDate1) {
            item.toDate1 = date;
            this.calculateNoOfDays(item);
        }
    }

    handleDateFrom2 = (item, date) => {
        this.setState({ toDate2IsDisabled: false }, () => {
            item.fromDate2 = date;
            this.calculateNoOfDays(item);
        })
    };

    handleDateTo2 = (item, date) => {
        if (item.fromDate2) {
            item.toDate2 = date;
            this.calculateNoOfDays(item);
        }
        else {
            this.setState({ toDate2IsDisabled: true })
        }

    }

    handleDateFrom3 = (item, date) => {
        this.setState({ toDate3IsDisabled: false }, () => {
            item.fromDate3 = date;
            this.calculateNoOfDays(item);
        })

    };

    handleDateTo3 = (item, date) => {
        if (item.fromDate3) {
            item.toDate3 = date;
            this.calculateNoOfDays(item);
        }
        else {
            this.setState({ toDate3IsDisabled: true })
        }
    }

    calculateNoOfDays = (item) => {

        let dateCal1 = 0, dateCal2 = 0, dateCal3 = 0;
        //Calculate from date1-3 & todate 1-3, then assign to numOfDays
        if (item.fromDate1 !== null && item.toDate1 !== null) {
            dateCal1 = Math.abs((item.fromDate1 - item.toDate1) / (1000 * 60 * 60 * 24));
        }
        if (item.fromDate2 !== null && item.toDate2 !== null) {
            dateCal2 = Math.abs((item.fromDate2 - item.toDate2) / (1000 * 60 * 60 * 24));
        }
        if (item.fromDate3 !== null && item.toDate3 !== null) {
            dateCal3 = Math.abs((item.fromDate3 - item.toDate3) / (1000 * 60 * 60 * 24));
        }

        item.numOfDays = parseInt(dateCal1) + parseInt(dateCal2) + parseInt(dateCal3);

        //ReCalculate total no of days & total salary to be payed
        let array = this.state.administrativeProcedures;
        let tempTotalNoOfDays = 0;

        for (let i = 0; i < array.length; i++) {
            if (array[i].numOfDays > 0) {
                tempTotalNoOfDays = parseFloat(array[i].numOfDays + tempTotalNoOfDays);
            }

        }

        this.setState({
            totalDays: tempTotalNoOfDays,
            administrativeProcedures: this.state.administrativeProcedures
        });

    };

    addView = () => {

        let obj = {};
        obj.year = "";
        obj.month = "1";
        obj.fromDay = "";
        obj.toDay = "";
        obj.salaryStatus = "Continuous";
        obj.subTotal = "";
        obj.noOfDays = "";
        obj.subTotalTotal = "";
        obj.totalNoOfDays = "";
        obj.ref = this.state.view;
        obj.yearErr = "";
        obj.monthErr = "";
        obj.fromDayErr = "";
        obj.toDayErr = "";
        obj.subTotalErr = "";
        obj.noOfDaysErr = "";

        if (this.state.administrativeProcedures.length < 40) {
            this.setState({
                administrativeProcedures: this.state.administrativeProcedures.concat(obj),
                view: this.state.view + 1
            });
        }

    }

    deleteView = (indexVal) => {
        var array = this.state.administrativeProcedures;
        var index = array.indexOf(indexVal);
        if (array && array.length > 1) {
            if (index === -1) {
                if (array && array.length > 1) {
                    array.splice(indexVal, 1);
                    this.setState({
                        administrativeProcedures: array
                    });

                    //ReCalculate total no of days & total salary to be payed
                    let tempTotalNoOfDays = 0;
                    let tempSubTotalTotal = 0;

                    for (let i = 0; i < array.length; i++) {
                   
                        if (array[i].noOfDays > 0) {
                            tempTotalNoOfDays = parseInt(array[i].noOfDays) + tempTotalNoOfDays;
                        }
                        if (array[i].subTotal > 0) {
                            tempSubTotalTotal = parseFloat(parseFloat(array[i].subTotal) + tempSubTotalTotal);
                        }
                    }
                    this.setState({
                        totalNoOfDays: tempTotalNoOfDays,
                        subTotalTotal: tempSubTotalTotal
                    });
                    // this.setState({
                    //     totalDays: tempTotalNoOfDays,
                    //     myTemp: tempSubTotalTotal
                    // });
                } else {
                    array.splice(indexVal, 1);
                    if (indexVal > 0) {
                        this.setState({
                            administrativeProcedures: array
                        });
                        //ReCalculate total no of days & total salary to be payed
                        let tempTotalNoOfDays = 0;
                        let tempSubTotalTotal = 0;

                        for (let i = 0; i < array.length; i++) {
                           
                            if (array[i].numOfDays > 0) {
                                tempTotalNoOfDays = parseFloat(array[i].numOfDays + tempTotalNoOfDays);
                            }
                            if (array[i].subTotal > 0) {
                                tempSubTotalTotal = parseFloat(parseFloat(array[i].subTotal) + tempSubTotalTotal);
                            }
                        }
                        this.setState({
                            totalNoOfDays: tempTotalNoOfDays,
                            subTotalTotal: tempSubTotalTotal
                        });
                    }
                }
            }
        }

    }

    handleViolationChange = (e) => {
        this.setState({
            violation: e.target.value,
            violationErr: ''
        });
    };

    handleOnCHangeYear = (event) => {
        if (event.target.value) {
            let regex = /^[0-9]{0,4}$/;
            if (regex.test(event.target.value)) {
                this.setState({ year: event.target.value, yearOneErr: '' });
            }
        } else {
            this.setState({ year: '', yearOneErr: 'thisFieldIsRequired' });
        }
    };

    daysInMonth = (month, year) => {
      
        //return new Date(year, month, 0).getDate();
        return moment(year + "-" + month, "YYYY-MM").daysInMonth();
    }

    calculateTswsRsSalary = () => {
        debugger;

        let temptotalSalaryWithSubscription = 0;
        if (this.state.totalSalaryWithSubscription) {
            temptotalSalaryWithSubscription = this.state.totalSalaryWithSubscription;
        } else {
            temptotalSalaryWithSubscription = 0;
        }

        let tempretirementSalary = 0;
        if (this.state.retirementSalary) {
            tempretirementSalary = this.state.retirementSalary;
        } else {
            tempretirementSalary = 0;
        }

        let temp = parseFloat(temptotalSalaryWithSubscription.replace(',','')) + parseFloat(tempretirementSalary.replace(',',''));
      

        if (temp === 0) {
            temp = '';
        }

        this.setState({
            tswsrsSalary: temp
        }, () => {
            // this.recalculateSubTotalAndNoOfDaysAfterChangeTotalSalary();
        });
    }

    changeNoOfDays = (fromDay, toDay, item) => {
        if (fromDay && toDay) {
            if (fromDay <= toDay) {
                let temp = toDay - fromDay;
                item.noOfDays = temp;
                this.setState({ noOfDays: temp });
            }
        }
    }

    //When edit Recalculate Subtotal & NoOfDays
    recalculateSubTotalAndNoOfDaysWhenEdit = () => {
        //Here we have to calculate total no of days & Total of sub-total
        let tempNoOfDays = 0;
        let tempsubTotal = 0;
        let tempTableData = this.state.tempTableData;

        if (tempTableData) {
            for (let i = 0; i < tempTableData.length; i++) {
             

                if (tempTableData[i].noOfDays) {
                    tempNoOfDays = tempNoOfDays + parseInt(tempTableData[i].noOfDays);
                }
                if (tempTableData[i].subtotal) {
                    tempsubTotal = tempsubTotal + parseFloat(tempTableData[i].subtotal);
                } else {
                    if (tempTableData[i].subTotal) {
                        tempsubTotal = tempsubTotal + parseFloat(tempTableData[i].subTotal);
                    }
                }
                if (i == tempTableData.length - 1) {
                    this.setState({
                        totalNoOfDays: tempNoOfDays,
                        subTotalTotal: tempsubTotal.toFixed(2)
                    });
                }
            }
        }
    }

    recalculateSubTotalAndNoOfDays = (item, daysInMonth) => {
        let temp = item.toDay - item.fromDay;
        if (temp > 0) {
            item.noOfDays = temp + 1;
        } else {
            item.noOfDays = temp;
        }

        this.setState({
            noOfDays: temp
        }, () => {

            //Here we have to calculate total no of days
            let tempNoOfDays = 0;
            let tempsubTotal = 0;
            for (let i = 0; i < this.state.administrativeProcedures.length; i++) {
                if (this.state.administrativeProcedures[i].noOfDays != "") {
                    tempNoOfDays = tempNoOfDays + parseInt(this.state.administrativeProcedures[i].noOfDays);
                }
                if (this.state.administrativeProcedures[i].subTotal != "") {
                    tempsubTotal = tempsubTotal + parseFloat(this.state.administrativeProcedures[i].subTotal);
                }
                if (i == this.state.administrativeProcedures.length - 1) {
                    this.setState({
                        totalNoOfDays: tempNoOfDays,
                        subTotalTotal: tempsubTotal.toFixed(2)
                    });
                }
            }

        })

    }

    recalculateSubTotalAndNoOfDaysAfterChangeTotalSalary = () => {
        //Here we have to calculate total no of days
        let tempNoOfDays = 0;
        let temp = [];

        this.state.administrativeProcedures.forEach((item, index) => {
            let obj = {};
            obj.year = item.year;
            obj.month = item.month;
            obj.fromDay = item.fromDay;
            obj.toDay = item.toDay;
            obj.salaryStatus = item.salaryStatus;
            obj.subTotal = item.subTotal;

            obj.noOfDays = item.noOfDays;
            if (item.noOfDays != "") {
                tempNoOfDays = tempNoOfDays + parseInt(item.noOfDays);
            }
            // if (item.subTotal != "") {
            //     tempsubTotal = tempsubTotal + parseFloat(obj.subTotal);
            // }
            if (index == this.state.administrativeProcedures.length - 1) {
                this.setState({
                    totalNoOfDays: tempNoOfDays,
                    // subTotalTotal: tempsubTotal.toFixed(2)
                });
            }
            temp.push(obj);

        });

        this.setState({
            administrativeProcedures: temp
        }, () => {
            
        });

    }

    render() {

        let element = this.state.administrativeProcedures.map((item, index) => {
            return (
                <tr>
                    <td>
                        {
                            index > 0 ?
                                <div className="deleteBox">
                                    <MDBBtn className="primary deleteBtn" onClick={() => this.deleteView(index)}>-</MDBBtn>
                                </div>
                                : ''
                        }
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "year"}
                                name="year"
                                value={item.year}
                                required
                                onChange={(e) => {
                                    let regex = /^[0-9\b]{0,4}$/;
                                    if (e.target.value) {
                                        if (regex.test(e.target.value)) {
                                            item.year = e.target.value;
                                            this.setState({ year: e.target.value });
                                        }
                                    } else {
                                        item.year = '';
                                        this.setState({ year: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    let year = e.target.value;
                                    if (year !== "" && parseInt(year) > 0) {
                                        if (!Validator.validateYear(year)) {
                                            item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].yearValidation;
                                            this.setState({
                                                yearErr: i18n[this.props.isArabic ? 'ar' : 'en'].yearValidation
                                            });
                                        } else {
                                            item.year = e.target.value;
                                            item.yearErr = "";
                                            item.fromDayErr = "";

                                            item.fromDay = '';
                                            item.toDay = '';

                                            this.setState({
                                                year: e.target.value,
                                                yearErr: "",

                                                fromDay: '',
                                                toDay: '',
                                                fromDayErr: ""
                                            });
                                        }
                                    } else {
                                        item.yearErr = "";
                                        item.year = '';
                                        this.setState({
                                            year: "",
                                            yearErr: ''
                                        });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: 100 }}
                            />
                            <div className='textRed'>{item.yearErr}</div>
                        </div>
                    </td>
                    <td style={{ width: '15%' }}>
                        <div className="form-group">
                            <select
                                className="browser-default custom-select"
                                id={item.ref + "month"}
                                style={{ width: '125px', fontSize: '13px' }}
                                value={item.month}
                                onChange={(e) => {
                                    item.month = e.target.value;
                                    item.fromDay = '';
                                    item.toDay = '';
                                    item.noOfDays = '';
                                    this.setState({
                                        month: e.target.value,
                                        fromDay: '',
                                        toDay: '',
                                        noOfDays: '',
                                        fromDayErr: ""
                                    }, () => {
                                        this.recalculateSubTotalAndNoOfDays(item);
                                    });
                                }}
                                name='month'>
                                {
                                    this.state.monthArray.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "fromDay"}
                                name="fromDay"
                                value={item.fromDay}
                                required
                                onChange={(e) => {
                                    if (!item.year) {
                                        item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst;
                                        this.setState({ yearErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst });
                                        return;
                                    }

                                    if (e.target.value != "") {
                                        let regex = /^[0-9]{0,2}$/;
                                        let fromDay = parseInt(e.target.value);
                                        let toDay = item.toDay;
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();

                                        if (fromDay > 0 && fromDay <= daysInMonth) {
                                            if (toDay > 0) {
                                                if (fromDay <= toDay ) {
                                                    item.fromDay = fromDay;
                                                    this.recalculateSubTotalAndNoOfDays(item, daysInMonth);
                                                    item.fromDayErr = '';
                                                    this.setState({ fromDay: fromDay, fromDayErr: '' });
                                                } else {
                                                    //show error fromDay value should be less than ToDay
                                                    item.fromDay = '';
                                                    item.fromDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                                    this.setState({
                                                        fromDay: '',
                                                        fromDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                                    });
                                                }
                                            } else {
                                                if (regex.test(e.target.value)) {
                                                    item.fromDay = fromDay;
                                                    item.fromDayErr = '';
                                                    this.setState({ fromDay: fromDay, fromDayErr: '' });
                                                }
                                            }
                                        } else {
                                            item.fromDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                            this.setState({
                                                fromDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                            });
                                        }
                                    } else {
                                        this.recalculateSubTotalAndNoOfDays(item, 0);
                                        item.fromDay = '';
                                        this.setState({ fromDay: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (e.target.value != "") {
                                        let fromDay = parseInt(e.target.value);
                                        let toDay = item.toDay;
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();

                                        if (fromDay > 0 && fromDay <= daysInMonth) {
                                            if (toDay > 0 && fromDay < toDay) {
                                                item.fromDayErr = '';
                                                this.setState({ fromDayErr: '' });
                                            }
                                        }
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: '70px' }}
                            />
                            <div className='textRed'>{item.fromDayErr}</div>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "toDay"}
                                name="toDay"
                                required
                                value={item.toDay}
                                onChange={(e) => {
                                    if (!item.year) {
                                        item.yearErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.pleaseEnterYearFirst;
                                        item.toDay = '';
                                        this.setState({ toDay: '' });
                                        return;
                                    }

                                    if (e.target.value !== "") {
                                        let regex = /^[0-9]{0,2}$/;
                                        let toDay = parseInt(e.target.value);
                                        let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                        if (toDay > 0 && toDay <= daysInMonth) {
                                            if (regex.test(e.target.value)) {
                                                item.toDay = toDay;
                                                item.toDayErr = '';
                                                this.setState({ toDay: toDay, toDayErr: '' });
                                            }
                                        } else {
                                            item.toDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                            this.setState({
                                                toDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                            });
                                        }
                                    } else {
                                        item.toDay = '';
                                        this.setState({ toDay: '' });
                                    }
                                }}
                                onBlur={(e) => {
                                    if (item.yearErr === '') {
                                        if (item.toDay != "") {
                                            let toDay = item.toDay;
                                            item.toDay = toDay;
                                            let fromDay = item.fromDay;
                                            if (fromDay > 0) {
                                                if (fromDay <= toDay) {
                                                    let temp = toDay - fromDay;
                                                    item.noOfDays = temp;
                                                    this.setState({ noOfDays: temp });
                                                    let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                                    this.recalculateSubTotalAndNoOfDays(item, daysInMonth);

                                                    item.toDayErr = '';
                                                    this.setState({ toDayErr: '' });

                                                } else {
                                                    //show error formDay value should be less than ToDay
                                                    item.toDayErr = i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay;
                                                    this.setState({
                                                        toDayErr: i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.invalidDay
                                                    });
                                                }

                                            } else {
                                                item.toDay = e.target.value;
                                                item.toDayErr = '';
                                                this.setState({ toDay: e.target.value, toDayErr: '' });
                                            }
                                        }
                                    } else {
                                        item.toDay = e.target.value;
                                        this.setState({ toDay: e.target.value });
                                    }
                                }}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', width: '70px' }}
                            />
                            <div className='textRed'>{item.toDayErr}</div>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <select
                                className="browser-default custom-select"
                                id={item.ref + "salaryStatus"}
                                name="salaryStatus"
                                value={item.salaryStatus}
                                onChange={(e) => {
                                    item.salaryStatus = e.target.value;
                                    this.setState({ salaryStatus: e.target.value });
                                }}
                                style={{ width: 'auto', fontSize: '13px' }}>
                                {
                                    this.state.salaryStatusArr ? this.state.salaryStatusArr.map((itemStatus, index) => {
                                        return <option value={itemStatus.id}>{this.props.isArabic ? itemStatus.ArabicName : itemStatus.EnglishName}</option>
                                    }) : null
                                }
                            </select>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                pattern="^[1-9]\d*$"
                                required
                                id={item.ref + "subTotal"}
                                name="subTotal"
                                onChange={(e) => {
                                    let daysInMonth = moment(item.year + "-" + item.month, "YYYY-MM").daysInMonth();
                                    let regex = /^[0-9\b.]{0,15}$/;
                                    let splitArr = e.target.value.split('.');
                                   
                                    if (regex.test(e.target.value) && splitArr.length <= 2) {
                                        item.subTotal = e.target.value;
                                        this.setState({ subTotal: e.target.value }, () => {
                                            this.recalculateSubTotalAndNoOfDays(item, daysInMonth);
                                        });
                                    }
                                }}
                                value={item.subTotal}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr', overflowX: 'scroll' }}
                            />
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id={item.ref + "noOfDays"}
                                name="noOfDays"
                                readOnly
                                value={item.noOfDays}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </td>
                </tr>
            )
        })

        return (

            <form
                style={{ paddingTop: '20', paddingBottom: '200px' }}
                className="needs-validation formWeight"
                onSubmit={this.submitHandler}
                noValidate>

                <StepsComponent
                    steps={['Step1', 'Step2', 'Step3']}
                    currentIndex={2}
                    isArabic={this.props.isArabic}
                    activeColor="#00BCD4"
                    nonActiveColor="#eeeeee"
                />

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', paddingTop: '50px' }}>

                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                                    <div className="form-group">

                                        <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.dateOfStartingWork}<span className="requireStar">*</span></label>

                                        <div style={{ width: '100%', direction: this.props.isArabic ? 'rtl' : '' }} className={this.props.isArabic ? "react-datepicker-popper datePicker_ar" : "react-datepicker-popper"} >

                                            <DatePicker
                                                className="form-control"
                                                todayButton={"Today"}
                                                style={{ width: '100%' }}
                                                minDate={this.state.dateOfSalaryTermination}
                                                selected={this.state.dateOfStartingWork}
                                                onChange={this.handleDateOfStartingWork}
                                                required
                                            />

                                        </div>

                                        {this.state.dateOfStartingWorkErr == 'thisFieldIsRequired' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                        }

                                    </div>
                                </MDBCol> */}

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.husbandWifeWork}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="husbandWifeWork"
                                name="husbandWifeWork"
                                value={this.state.husbandWifeWork}
                                onChange={this.handleHusbandWifeWork}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalAmountExempted}<span className="requireStar">*</span></label>
                            {/* <input
                                type="text"
                                id="totalAmountExempted"
                                name="totalAmountExempted"
                                className="form-control"
                                aria-label="Amount (to the nearest dollar)"
                                value={this.state.totalAmountExempted}
                                onChange={this.handleAmountExempted}
                                required
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                            <div className="invalid-feedback" style={this.props.isArabic ? { justifyContent: 'flex-end', width: '100%', textAlign: 'right' } : { justifyContent: 'flex-start', width: '100%' }}>
                                {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                            </div> */}
                            <div className=" currencyFormatInputCSS currencyFormat"
                                style={{ border: this.state.totalAmtExmErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da', direction: this.props.isArabic ? 'rtl' : 'ltr' }}
                            >
                                <CurrencyFormat
                                    thousandSeparator={true}
                                    value={this.state.totalAmountExempted}
                                    onValueChange={(value) => { this.handleAmountExempted(value) }}
                                    display={"text"}
                                    maxLength={10}
                                />

                            </div>

                            {this.state.totalAmtExmErr === 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>


                </MDBRow>

                <MDBRow className="formRow rowBottom" style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.noOfChildren}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="noOfChildren"
                                name="noOfChildren"
                                value={this.state.noOfChildren}
                                onChange={this.handleNoOfChildren}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />

                            {this.state.noOfChildrenErr == 'noOfChildShouldBeLesThan20' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.noOfChildShouldBeLesThan20}
                                </div>
                                :
                                null}

                        </div>
                    </MDBCol>

                    <MDBCol xs="6" sm="6" md="6" lg="6" xl="6">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.placeOfChildren}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="placeOfChildren"
                                name="placeOfChildren"
                                maxLength="50"
                                value={this.state.placeOfChildren}
                                onChange={this.handlePlaceOfChildren}
                                style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                    </MDBCol>

                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row', justifyContent: this.props.isArabic ? 'flex-start' : 'flex-end', }}>

                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            {/* <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="formGroupExampleInput">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.violationLabel}<span className="requireStar">*</span></label> */}

                            <label htmlFor="formGroupExampleInput" style={{ direction: !this.props.isArabic ? 'ltr' : 'rtl', textAlign: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.getLocale()].ExemptionFromAccompanyPatient.violationLabel}<span className="requireStar">*</span></label>

                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={this.state.violation === 'Yes'}
                                    class="custom-control-input"
                                    id="customControlValidation2"
                                    onChange={this.handleViolationChange}
                                    name="radio-stacked"
                                    required />
                                <label class="custom-control-label" for="customControlValidation2">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].yes}
                                </label>
                            </div>
                            <div style={{ float: this.props.isArabic ? 'right' : 'left' }} class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    value="No"
                                    class="custom-control-input"
                                    id="customControlValidation3"
                                    name="radio-stacked"
                                    checked={this.state.violation === 'No'}
                                    onChange={this.handleViolationChange}
                                    required />
                                <label class="custom-control-label" for="customControlValidation3">
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].no}
                                </label>


                            </div> <br />
                            {this.state.violationErr == 'thisFieldIsRequired' ?
                                <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                    {i18n[this.props.isArabic ? 'ar' : 'en'].thisFieldIsRequired}
                                </div>
                                :
                                null}
                        </div>
                    </MDBCol>

                    {/* <MDBCol xs="6" sm="6" md="6" lg="6" xl="6"></MDBCol> */}

                </MDBRow>

                {
                    this.state.violation === 'Yes' ?
                        <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                                <div className="form-group">
                                    <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.explain}<span className="requireStar">*</span></label>
                                    <div style={{ border: this.state.explainErr === 'thisFieldisRequired' ? '1px solid red' : '' }} className="ckEditorStyle">
                                        {
                                            this.props.isArabic ?
                                                null
                                                :
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={this.state.explain}
                                                    events={{
                                                        "change": this.handleExplainOnChange
                                                    }}
                                                    config={{ language: "en", height: '10vh' }}
                                                />
                                        }
                                        {
                                            this.props.isArabic ?
                                                <CKEditor
                                                    activeClass="p10"
                                                    content={this.state.explain}
                                                    events={{
                                                        "change": this.handleExplainOnChange
                                                    }}
                                                    config={{ language: "ar", height: '10vh' }}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                    <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                        {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                                    </p>
                                    {
                                        this.state.explainErr === 'thisFieldisRequired' ?
                                            <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                {i18n[this.getLocale()].thisFieldIsRequired}
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                            </MDBCol>
                        </MDBRow>
                        :
                        ''
                }

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <label htmlFor="formGroupExampleInput" style={{ float: this.props.isArabic ? 'right' : 'left' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.irregularitiesTable}</label>
                        <MDBTable bordered style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}>
                            <MDBTableBody>
                                <div>
                                    <tr>
                                        <td width="15%"><div className="tableHeading" style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.employeeSalaryInformation}</div></td>
                                        <td>
                                            <div>
                                                <tr>
                                                    <td width="20%">
                                                        <lable style={{ display: 'flex' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.basicSalaryTb}</lable>

                                                        <div
                                                            className=" currencyFormatInputCSS currencyFormat"
                                                            style={{
                                                                direction: this.props.isArabic ? 'rtl' : 'ltr',
                                                                border: this.state.basicSalErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da'
                                                            }}>
                                                            <CurrencyFormat
                                                                thousandSeparator={true}
                                                                value={this.state.basicSalaryTable}
                                                                onValueChange={(value) => { this.handleBasicSalaryTable(value) }}
                                                                display={"text"}
                                                                maxLength={10}
                                                            />
                                                        </div>

                                                        {
                                                            this.state.basicSalErr === 'basicSalErr' ?
                                                                <div className="textRed" style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                                                                    {i18n[this.props.isArabic ? 'ar' : 'en'].salaryErr}
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </td>
                                                    <td width="30%">
                                                        <lable style={{ display: 'flex' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.totalSalaryWithoutSubscription}</lable>
                                                        <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                                            <CurrencyFormat
                                                                thousandSeparator={true}
                                                                value={this.state.totalSalaryWithSubscription}
                                                                onValueChange={(value) => { this.handletotalSalaryWithSubscriptionTable(value) }}
                                                                display={"text"}
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td width="20%">
                                                        <lable style={{ display: 'flex' }}>
                                                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.retirementSalary}</lable>
                                                        <div className="form-group">

                                                            <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr', border: this.state.retirementSalaryErr !== '' ? '1px solid #dc3545' : '1px solid #ced4da' }}>
                                                                <CurrencyFormat
                                                                    thousandSeparator={true}
                                                                    value={this.state.retirementSalary}
                                                                    onValueChange={(value) => { this.handleretirementSalaryTable(value) }}
                                                                    display={"text"}
                                                                    maxLength={10}
                                                                />

                                                            </div>

                                                            {
                                                                this.state.retirementSalaryErr === 'retirementSalaryErr' ?
                                                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                                                        {i18n[this.getLocale()].monthlyContributionsErr}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                        </div>
                                                    </td>
                                                    <td width="20%">
                                                        <lable style={{ display: 'flex' }}>
                                                            {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.total}</lable>
                                                        <div className="form-group">
                                                            <div className=" currencyFormatInputCSS currencyFormat" style={{ direction: this.props.isArabic ? 'rtl' : 'ltr' }}>
                                                                <CurrencyFormat
                                                                    thousandSeparator={true}
                                                                    value={this.state.tswsrsSalary}
                                                                    onValueChange={(value) => { this.handletswsrsSalaryTable(value) }}
                                                                    display={"text"}
                                                                    maxLength={10}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td >
                                            <div className="tableHeading"
                                                style={{
                                                    textAlign: this.props.isArabic ? "right" : '',
                                                    width: 'auto'
                                                }}
                                            >
                                                {i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.intervals}
                                            </div>

                                            <div className="deleteBox">
                                                <MDBBtn className="primary deleteBtn" onClick={() => this.addView()} >+</MDBBtn>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <th></th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.year} <span class="requireStar">*</span> </th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.month} <span class="requireStar">*</span> </th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.from} <span class="requireStar">*</span> </th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.to} <span class="requireStar">*</span></th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.salaryStatus} <span class="requireStar">*</span></th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.subTotal} <span class="requireStar">*</span></th>
                                                        <th style={{ textAlign: this.props.isArabic ? "right" : '' }}>{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.noOfDays} </th>
                                                    </tr>

                                                    {element}

                                                    <tr>
                                                        <td colSpan={6} style={{ border: 'none' }}>
                                                            {
                                                                this.state.intervalsErr ?
                                                                    <div className="textRed"
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            textAlign: this.props.isArabic ? 'right' : 'left',
                                                                            flexDirection: this.props.isArabic ? 'row-reverse' : 'row',
                                                                            direction: 'ltr'
                                                                        }}
                                                                    >
                                                                        {i18n[this.props.isArabic ? 'ar' : 'en'].allFieldsAreMandatory}
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </td>

                                                        <td>
                                                            {/* <lable>Amount Should be Paid</lable> */}
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="subTotalTotal"
                                                                    name="subTotalTotal"
                                                                    readOnly
                                                                    value={this.state.subTotalTotal}
                                                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr', overflowX: 'auto' }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {/* <lable>Total Days</lable> */}
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="daysTotal"
                                                                    name="daysTotal"
                                                                    disabled
                                                                    value={this.state.totalNoOfDays}
                                                                    style={{ direction: this.props.isArabic ? "rtl" : 'ltr' }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </div>

                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="form-group">
                            <label style={{ width: '100%', display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }} htmlFor="exampleFormControlTextarea3">{i18n[this.props.isArabic ? 'ar' : 'en'].ExemptionFromAccompanyPatient.HowToCalculate}<span className="requireStar">*</span></label>

                            <div style={{ border: this.state.howToCalculateErr === 'thisFieldisRequired' ? '1px solid red' : '' }}>
                                {
                                    this.props.isArabic ?
                                        null :
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.HowToCalculate}
                                            events={{
                                                "change": this.handleHowToCalculate
                                            }}
                                            config={{ language: "en", height: '10vh' }}
                                        />
                                }
                                {
                                    this.props.isArabic ?
                                        <CKEditor
                                            activeClass="p10"
                                            content={this.state.HowToCalculate}
                                            events={{
                                                "change": this.handleHowToCalculate
                                            }}
                                            config={{ language: "ar", height: '10vh' }}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <p style={{ display: "flex", flexDirection: this.props.isArabic ? "row-reverse" : "row", color: '#000', width: '100%' }}>
                                {i18n[this.props.isArabic ? "ar" : "en"].allowCkEditorLimit}
                            </p>
                            {
                                this.state.howToCalculateErr === 'thisFieldisRequired' ?
                                    <div className="textRed" style={{ textAlign: this.props.isArabic ? 'right' : 'left', width: '100%' }}>
                                        {i18n[this.getLocale()].thisFieldIsRequired}
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </MDBCol>
                </MDBRow>

                <MDBRow style={{ display: 'flex', flexDirection: this.props.isArabic ? 'row-reverse' : 'row' }}>
                    <MDBBtn onClick={this.goToPrevious} color="gray" className="previousAndClearBtn">{i18n[this.props.isArabic ? 'ar' : 'en'].previous}</MDBBtn>

                    <MDBBtn className="submitBtn" type='submit' color="primary">{i18n[this.props.isArabic ? 'ar' : 'en'].next}</MDBBtn>

                    {
                        (!(this.state.statusid === Config.getDraftId()) && (this.state.requestId > 0)) ?
                            null
                            :
                            <MDBBtn className="submitBtn" type='button' color="primary" onClick={this.saveAsDraft}>
                                {i18n[this.getLocale()].saveAsDraft}
                            </MDBBtn>
                    }

                    {/* <MDBBtn color="gray" className="previousAndClearBtn" onClick={this.clearForm}>{i18n[this.getLocale()].SCI.clear}</MDBBtn> */}
                </MDBRow>

            </form>
        );
    }
}