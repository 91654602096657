/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import i18n from './../i18n';

export default class SideBar extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isArabic: true,
        }
    }

    componentDidMount = () => {
    }

    getLocale = () => {
        if (this.props.isArabic) {
            return 'ar';
        }
        else {
            return 'en';
        }
    }

    getSidebarImage = (img) => {
        return require("./../assets/images/sidebar/" + img);
    }
    openlink() {
        debugger;
        let a = document.createElement('a');
        a.target = '_blank';
       // a.href = "http://localhost:5002/authorization?user_token=51c6d2a1-a3b7-4c0d-84af-954f524ec0f2";
       a.href = "https://readinessmatrix.hra.gov.ae/authorization?user_token=51c6d2a1-a3b7-4c0d-84af-954f524ec0f2";


        a.click();
    }
    openlinkCalc() {
        debugger;
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = "https://www.dari.ae/ar/app/market-data-detail";
        a.click();
    }
    render() {


        return (
            <>

                <MDBCol md={this.props.isSideBarMinimized ? "1" : "2"} className="sideBarContainer" style={{ height: this.props.height }}>


 
                    <div  style={{cursor:"pointer"}} onClick={() => this.openlink.bind(this)}
                        className={this.props.isSideBarMinimized ? "sibebarLinkMin" : "sibebarLink"}

                        style={{ width: '100%', display: 'flex', padding: '5%', flexDirection: 'row', justifyContent: this.props.isSideBarMinimized ? 'center' : 'space-between', alignItems: 'center', backgroundColor: '#B8860B', color: '#ffffff' }}>
                        {!this.props.isArabic ?
                            <img /*onClick={item.fn}*/
                                title={i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject} alt={i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject}

                                src={this.getSidebarImage('sci.png')}
                                style={{
                                    marginTop: this.props.isSideBarMinimized ? '1%' : '',
                                    marginBottom: this.props.isSideBarMinimized ? '' : '1%',

                                    alignSelf: 'center'
                                }}
                            /> : null}

                        {!this.props.isSideBarMinimized ?

                            <p className="loginBtn"
                                                /*onClick={item.fn}*/ style={{ width: '85%', padding: '0 5%', textAlign: this.props.isArabic ? 'right' : 'left', fontFamily: 'ge_ss_text_Regular', fontSize: 14 }} title={i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject}>{i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject}</p> : null}
                        {this.props.isArabic ?
                            <img
                                                /*onClick={item.fn}*/ title={''} alt={i18n[this.props.isArabic ? 'ar' : 'en'].PoliceLib.subject} src={this.getSidebarImage('sci.png')}
                                style={{
                                    marginTop: this.props.isSideBarMinimized ? '1%' : '',
                                    marginBottom: this.props.isSideBarMinimized ? '' : '1%',
                                    // height: this.props.isSideBarMinimized ? '35px' : '',
                                    // height: this.props.isSideBarMinimized ? '' : '24px',
                                    alignSelf: 'center'
                                }}
                            />
                            : null}

                    </div>
 

                    <div   style={{cursor:"pointer"}}  onClick={() => this.openlinkCalc.bind(this)}
                        className={this.props.isSideBarMinimized ? "sibebarLinkMin" : "sibebarLink"}

                        style={{ width: '100%', display: 'flex', padding: '5%', flexDirection: 'row', justifyContent: this.props.isSideBarMinimized ? 'center' : 'space-between', alignItems: 'center', backgroundColor: 'rgb(16, 112, 168)', color: '#ffffff' }}>
                        {!this.props.isArabic ?
                            <img /*onClick={item.fn}*/
                                title={i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject} alt={i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject}

                                src={this.getSidebarImage('sci.png')}
                                style={{
                                    marginTop: this.props.isSideBarMinimized ? '1%' : '',
                                    marginBottom: this.props.isSideBarMinimized ? '' : '1%',

                                    alignSelf: 'center'
                                }}
                            /> : null}

                        {!this.props.isSideBarMinimized ?

                            <p className="loginBtn"
                                                /*onClick={item.fn}*/ style={{ width: '85%', padding: '0 5%', textAlign: this.props.isArabic ? 'right' : 'left', fontFamily: 'ge_ss_text_Regular', fontSize: 14 }} title={i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject}>{i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject}</p> : null}
                        {this.props.isArabic ?
                            <img
                                                /*onClick={item.fn}*/ title={''} alt={i18n[this.props.isArabic ? 'ar' : 'en'].dary.subject} src={this.getSidebarImage('sci.png')}
                                style={{
                                    marginTop: this.props.isSideBarMinimized ? '1%' : '',
                                    marginBottom: this.props.isSideBarMinimized ? '' : '1%',
                                    // height: this.props.isSideBarMinimized ? '35px' : '',
                                    // height: this.props.isSideBarMinimized ? '' : '24px',
                                    alignSelf: 'center'
                                }}
                            />
                            : null}

                    </div>

                    
                    {
                        this.props.items ?

                            this.props.items.map((item, index) => {

                                // if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].mailTemplate) {
                                //         return;
                                // }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].sci) {
                                    if (!this.props.canViewSCI) {
                                        return;
                                    }
                                }


                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].infoRequest) {
                                    if (!this.props.canViewInformation) {
                                        return;
                                    }
                                }

                                // salaries and bonuses
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].bonus_Request) {
                                    if (!this.props.canviewbonusrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].amendSalariesScale) {
                                    if (!this.props.canviewamendscalerequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].amendEmployeeSalary) {
                                    if (!this.props.canviewamendsalaryrequest) {
                                        return;
                                    }
                                }

                                // policies
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].exceptionForPatient) {
                                    if (!this.props.canviewpatientrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].reviewHRPoliciesLabel) {
                                    if (!this.props.canviewreviewhrrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].secondmentForFederal) {
                                    if (!this.props.canviewsecondmentrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].exceptionForRetirment) {
                                    if (!this.props.canviewadhousingrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].exceptionADHousing) {
                                    if (!this.props.canviewretirementrequest) {
                                        return;
                                    }
                                }

                                // data services
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].extendCreateObjectives) {
                                    if (!this.props.canviewextendcreaterequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].extendReviewObjectives) {
                                    if (!this.props.canviewextendreviewrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].extendAnnualPerformanceEvaluationInterval) {
                                    if (!this.props.canviewextendannualrequest) {
                                        return;
                                    }
                                }

                                ////
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].addOrEditEmployeesPerformanceEvaluation) {
                                    if (!this.props.canviewemployeeperformancerequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].joinAndImplementGovernmentResourcesSystemForNewEntity) {
                                    if (!this.props.canviewjoinimplementrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].openComplainsStage) {
                                    if (!this.props.canviewopencomplainsrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].amendsOnSharedGovernmentHRManagementystem) {
                                    if (!this.props.canviewamendsystemsrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].ReportsAndStaticRequest) {
                                    if (!this.props.canviewreportsstaticsrequest) {
                                        return;
                                    }
                                }

                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].OtherRequests) {
                                    if (!this.props.canviewotherrequestsrequest) {
                                        return;
                                    }
                                }
                                // organization structure
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].organizationStructure) {
                                    if (!this.props.canvieworgrequest) {
                                        return;
                                    }
                                }
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].organizationStructureTree) {
                                    if (!this.props.canvieworgrequesttree) {
                                        return;
                                    }

                                }
                                if (item.name === i18n[this.props.isArabic ? 'ar' : 'en'].organizationStructureTreePreview) {
                                    if (!this.props.canviewtree) {
                                        return;
                                    }

                                }

                                return (


                                    item.header ?
                                        !this.props.isSideBarMinimized ? <div key={index} style={{ marginBottom: '1px', width: this.props.isSideBarMinimized ? '10%' : '100%', display: 'flex', padding: '5%', flexDirection: 'row', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start', alignItems: 'center', backgroundColor: item.active ? '#5F899B' : 'transparent', borderTop: '1px solid #5F899B', boxShadow: "1px 2px 3px #008080" }}>
                                            <p
                                                key={index}
                                                style={{ width: '100%', margin: '2px', display: 'flex', flexDirection: 'row', justifyContent: this.props.isArabic ? 'flex-end' : 'flex-start', alignItems: 'center', height: '4vh', color: '#ffffff', fontFamily: 'ge_ss_text_Regular', fontSize: 16, textAlign: this.props.isArabic ? 'right' : 'left', textDecoration: 'underline' }}
                                                onClick={item.fn}>
                                                <b>
                                                    {item.name}
                                                </b>
                                            </p>

                                        </div> : null
                                        :




                                        <div
                                            className={this.props.isSideBarMinimized ? "sibebarLinkMin" : "sibebarLink"}
                                            key={index}
                                            style={{ width: '100%', display: 'flex', padding: '5%', flexDirection: 'row', justifyContent: this.props.isSideBarMinimized ? 'center' : 'space-between', alignItems: 'center', backgroundColor: item.active ? '#5F899B' : 'transparent' }}>
                                            {!this.props.isArabic ?
                                                <img onClick={item.fn}
                                                    title={item.name} alt={item.name}
                                                    src={this.getSidebarImage(item.img)}
                                                    style={{
                                                        marginTop: this.props.isSideBarMinimized ? '1%' : '',
                                                        marginBottom: this.props.isSideBarMinimized ? '' : '1%',
                                                        // height: this.props.isSideBarMinimized ? '35px' : '',
                                                        // height: this.props.isSideBarMinimized ? '' : '24px',
                                                        alignSelf: 'center'
                                                    }}
                                                /> : null}

                                            {!this.props.isSideBarMinimized ?

                                                <p className="loginBtn"
                                                    onClick={item.fn} style={{ width: '85%', padding: '0 5%', textAlign: this.props.isArabic ? 'right' : 'left', fontFamily: 'ge_ss_text_Regular', fontSize: 14 }} title={item.name}>{item.name}</p> : null}
                                            {this.props.isArabic ?
                                                <img
                                                    onClick={item.fn} title={item.name} alt={item.name} src={this.getSidebarImage(item.img)}
                                                    style={{
                                                        marginTop: this.props.isSideBarMinimized ? '1%' : '',
                                                        marginBottom: this.props.isSideBarMinimized ? '' : '1%',
                                                        // height: this.props.isSideBarMinimized ? '35px' : '',
                                                        // height: this.props.isSideBarMinimized ? '' : '24px',
                                                        alignSelf: 'center'
                                                    }}
                                                />
                                                : null}

                                        </div>

                                )

                            }) : ''}


                </MDBCol>
            </>
        );

    }

}