import React, { Component } from 'react';

import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBPopover, MDBPopoverBody,MDBModal,MDBModalHeader, MDBFooter } from "mdbreact";

import Footer from '../Footer';
import Header from '../Header';
import SideBar from '../SideBar';
import i18n from '../../i18n';
import Locali18n from './Locali18n';
import  RequestFunctions from './RequestFunctions';
import './../../css/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import ApiRequest from '../../services/ApiRequest';
import { uploadDocument ,OrganizationStrucutreRequestPayload} from '../../utils/Payload';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import CKEditorComponent from '../CKEditorComponent';
import DownloadComponent from "../DownloadComponent";
import PostionsFunctions from './PostionsFunctions';

let moment = require('moment');



export default class PositionAdminRoles  extends PostionsFunctions {

    constructor(props)
    {
        super(props);
        

       this.getFTE(this.props.info.node.id);
       
    }



    
       render()
       {
       
        

           return ( 
                
             <MDBContainer fluid   style={{ overflow: 'auto' ,paddingTop: "20px", marginBottom: '150px', paddingRight: '0px', paddingLeft: '0px' }}>

            <MDBModal 
            size="xl"
            isOpen={this.props.open}
            toggle={this.props.open}
            backdrop={false}
            tabIndex="0"
            keyboard={true}>

             

                <form  className="needs-validation formWeight formSpace"
                onSubmit={this.saveFTE}
                noValidate>

               
               <MDBModalHeader className='tableheader' >

               <MDBRow >
              
               <MDBCol xs="1" sm="1" md="1" lg="1" xl="1"> 
               <span>{Locali18n[this.state.isArabic ? 'ar' : 'en'].ftesubtotal}</span></MDBCol>
               <MDBCol xs="1" sm="1" md="1" lg="1" xl="1"> 
               <span>{Locali18n[this.state.isArabic ? 'ar' : 'en'].frequency}</span></MDBCol>
               
               <MDBCol xs="2" sm="2" md="2" lg="2" xl="2"> 
               <span>{Locali18n[this.state.isArabic ? 'ar' : 'en'].time}</span></MDBCol>
               <MDBCol xs="5" sm="5" md="5" lg="5" xl="5">
                    <span>{Locali18n[this.state.isArabic ? 'ar' : 'en'].desc}</span></MDBCol>
               <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
                    <span>{Locali18n[this.state.isArabic ? 'ar' : 'en'].ftetype}</span></MDBCol>
               <MDBCol xs="1" sm="1" md="1" lg="1" xl="1"> 
               <span>{Locali18n[this.state.isArabic ? 'ar' : 'en'].serial}</span></MDBCol>
                </MDBRow>
             
               </MDBModalHeader>


 
              <MDBPopoverBody className='tablebody'>
              { 
               
               this.state.FTE ?  this.state.FTE.map((item, index) => (

              
               

                <MDBRow className='tablerow'>

                 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1"> 
                  <span>{item.subtotal}</span></MDBCol>
               
                 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">   
                        <div className="form-group">
                            <input
                                type="label"
                                className="form-control"
                                key={index}
                               // tabIndex={index}
                                value={item.frequency}
                                onChange={(e) =>this.handleFTEfrequencyOnchange(e,item,index)}
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                        
                  
             </MDBCol>
               
                 <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">   
                        <div className="form-group">
                            <input
                                type="label"
                                className="form-control"
                                key={index}
                               // tabIndex={index}
                                value={item.grade}
                                onChange={(e) =>this.handleFTEgradeOnchange(e,item,index)}
                                style={{ direction: this.state.isArabic ? "rtl" : 'ltr' }}
                            />
                        </div>
                        
                  
             </MDBCol>
               
                 <MDBCol xs="5" sm="5" md="5" lg="5" xl="5">   
                        <div className="form-group">
                           <span>{item.desc}</span>
                              
                        </div>
                        
                  
    </MDBCol>
               
                 <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">   
                       <div
                           backgroundColor={item.type === 1 ? 'yellow' : item.type === 2 ? 'green' : 'gray'} >
                       
                       {
                           item.type === 1 ? 'Administration' :item.type === 2 ?
                            'Functional' : 'Organizational'
                       
                            }
                        </div>
                   
               </MDBCol>
                
                 <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">   
                       <span>{index+1}</span>
                   
               </MDBCol>
             
               </MDBRow>
            
               ) ) : null}
                              
               {/* footer*/} 
                <MDBRow className='tablerow' style={{ display: 'flex', flexDirection: this.isArabic ? 'row-reverse' : 'row' }}>
          <MDBCol xs="2" sm="2" md="2" lg="2" xl="2">
                    <div className="form-group">
                           <span> {Locali18n[this.isArabic ? "ar" : "en"].total} </span>
                              
                        </div>
                    </MDBCol> 
           <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                    <div className="grantotal">
                           <span>{this.state.FTETotal}</span>
                              
                        </div>
                </MDBCol> 
          </MDBRow>
          
          <br></br>
               <MDBRow style={{ display: 'flex', flexDirection: this.isArabic ? 'row-reverse' : 'row' }}>
                
                <MDBCol xs="3" sm="3" md="3" lg="3" xl="3">
                </MDBCol> 

                     <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
                     <MDBBtn className="submitBtn" type='submit' color="primary"  onClick={this.props.hide}>
                        {Locali18n[this.isArabic ? "ar" : "en"].submit }
                    </MDBBtn>
                    </MDBCol>
                    
                     <MDBCol xs="1" sm="1" md="1" lg="1" xl="1">
                <MDBBtn className="submitBtn"  color="primary"
                 onClick={this.props.hide}>
                        {Locali18n[this.isArabic ? "ar" : "en"].close} 
                    </MDBBtn>
                    </MDBCol>
                     <MDBCol xs="5" sm="5" md="5" lg="5" xl="5"></MDBCol>
                    </MDBRow> 
         
              
                   
              </MDBPopoverBody>
         
            </form>
           </MDBModal>
                </MDBContainer>     

          )};
        }
                           

