import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol,MDBBtn,MDBModalBody,MDBModalHeader,MDBTableBody,MDBTableHead,MDBModal,MDBTable } from "mdbreact";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';

import Step1 from '../../components/ActionPlans/Step1';
import Step2 from '../../components/ActionPlans/Step3';
import Step3 from '../../components/ActionPlans/Step4';
import { saveDisciplinaryboard, uploadDocument,downloadAttachment } from '../../utils/Payload';
import Upload from '../../components/Common/Upload';
import StepTwo from '../../components/SecondmentForAFederalAgencySteps/StepTwo';
import SubHeaderStrip from '../../components/SubHeaderStrip';
import i18n from '../../i18n';
import Config from '../../utils/Config';
import LoadingOverlay from 'react-loading-overlay'
import Footer from '../../components/Footer';



import ApiRequest from '../../services/ApiRequest';
import { confirmAlert } from 'react-confirm-alert';
import './../../css/react-confirm-alert.css';
import color from '@material-ui/core/colors/amber';
let moment = require('moment');

export default class ActionPlansListInternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:false,isArabic:localStorage.getItem("Lang") === "ar" ? true : false,
            ShowDetails:false,
            popUpRowArray:[],
            popUpRowArrayTemp:[],
            entityArray:[],
            entitySelectedValue:'',
            isinternal:false,
        }
    }
    
    onClickHelpPage = () => {
        this.props.history.replace('/help');
    }
    EditWorkShop = (item) =>{
       
        Config.setWorkShopItem(item);
        
        this.create();
        
    }
    toggleSideBarSize = () => {
        this.setState({
            isSideBarMinimized: !this.state.isSideBarMinimized
        });
    }

    toggleLanguage = (lan) => {
        this.setState({ isArabic: lan === 'ar' ? true : false });
    }
    handleChangeForEntity = (event) => {
        let selectvalue =event.target.value;
      
        let tempmainprac = []
        
        for(let i=0;i<this.state.popUpRowArraytemp.length;i++)
        {
        let tempitem = this.state.popUpRowArraytemp[i];
        
        if(tempitem.EntityID==selectvalue)
        {
            tempmainprac.push(tempitem);
        }
        }
        this.setState({ entitySelectedValue:selectvalue,popUpRowArray:tempmainprac });
        Config.SetActionPlanListEntity(selectvalue);
    };


    handleLoadForEntity =() =>{
        
        let selectvalue = Config.GetActionPlanListEntity();
      
        let tempmainprac = []
        for(let i=0;i<this.state.popUpRowArraytemp.length;i++)
        {
        let tempitem = this.state.popUpRowArraytemp[i];
        
        if(tempitem.EntityID==selectvalue)
        {
            tempmainprac.push(tempitem);
        }
        }
        this.setState({ entitySelectedValue:selectvalue,popUpRowArray:tempmainprac });
       
    };
    saveFile = (item) => {
        let obj = {}

        obj.filename = item.aliasfilename;
        obj.requesttype = this.state.requesttypeid;
        obj.requestid = this.props.location.state.id;

        let payload = downloadAttachment(obj);
        let endPoint = 'DownloadAttachment'
        let url = Config.getUrl(endPoint);

        this.setState({
            isLoading: true
        }, () => {
            ApiRequest.sendRequestForPost(url, payload, res => {
                this.setState({
                    isLoading: false
                }, () => {
                    if (res.Status) {
                        let fileData = res.data;
                        let filecontent = fileData.filecontent;
                        let contenttype = fileData.contenttype
                        if (navigator.msSaveBlob) {
                            let blob = this.b64toBlob(filecontent, contenttype);
                            window.navigator.msSaveBlob(blob, item.filename);
                        } else {
                            let a = window.document.createElement('a');
                            a.href = `data:${contenttype};base64,${filecontent}`;
                            a.download = item.filename;
                            // Append anchor to body.
                            document.body.appendChild(a);
                            a.click();
                            // Remove anchor from body
                            document.body.removeChild(a);
                        }
                    } else {
                       
                    }
                })
            })
        })
    }

    logout = () => {
        Config.clearLocalStorageByKey("LoginInformation");
        this.props.history.replace('/');
    }

    toggleLoadingSpinner = (flag) => {
        this.setState({
            isLoading: flag
        })
    }

    
    toggle = (reloadFlag) => {
        debugger;
        this.setState({
            modal: !this.state.modal,
            WorkShopDates :!this.state.WorkShopDates
        }, () => {
            if (reloadFlag && typeof reloadFlag == "boolean") {
                // window.location.reload(true);
                // this.props.history.go(0);
                // let location = Config.getDomainUrl('');
                // window.location.assign(location + "listpage");
                // document.location.assign(location + "listpage");
                this.setState({
                    changeState: !this.state.changeState
                }, () => {
                    this.props.history.goBack();
                    //this.componentDidMount();
                });
            }
        });
    }

    EditIntivate =(index,id)=>
    {
       
      Config.setActionPlanID(id);
      let pathname ='ActionPlansEntity';
      this.props.history.replace(pathname, {
                    pathname: pathname
                });
        // this.setState({selectedIndex:index ,ShowDetails: true});
        // this.toggle();
    }

    ViewDates =(index,id)=>
    {
      
        Config.setActionPlanID(id);
        let pathname ='ViewDetailsActionPlans';
        this.props.history.replace(pathname, {
                      pathname: pathname
                  });
    }

   
   
    renderDataTableRows = (item, index) => {
       let StartDate = '',EndDate='';
       if(item.StartDate)
       {
        
StartDate=item.StartDate.split("12:00:00 AM");
       }
       if(item.EndDate)
       {
        EndDate=item.EndDate.split("12:00:00 AM");
       }
        return (
            <tr style={{ textAlign: "center", padding: '10%' }}>
          {item.InitiativStatus==7?
          <td className='TDtextLeft'>
              <span className='IntivateNeedUpdate'>  {i18n[this.getLocale()].ActionPlans.InitiativeUpdate}</span>
            {item.Title}</td>
          :
          <td  className='TDtextLeft'>{item.Title}</td>
          }
        {/* <td>{item.InitiativStatus==7 ? :item.Title}</td> */}
            <td>{this.state.isArabic ? item.EntitNameAr:item.EntitNameEn}</td>
            <td>{StartDate}</td>
            <td>{EndDate}</td>
            <td>
                {
                    !this.state.isinternal && item.InitiativStatus !=1 && item.InitiativStatus !=5
                    ?
                    <MDBBtn
                                    className="gridActionBtn "
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.EditIntivate(index,item.ID)}                       >
                                        {i18n[this.getLocale()].ActionPlans.Edit}
                                 
                                </MDBBtn>
                    :null
                }
                </td><td>
                <MDBBtn
                                    className="gridActionBtn "
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.ViewDates(index,item.ID)}                       >
                                        {i18n[this.getLocale()].ActionPlans.ViewDetails}
                                 
                                </MDBBtn></td>
           
           {/* <td> 
            <MDBBtn
                                    className="gridActionBtn "
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.ViewDates(index)}                       >
                                        {i18n[this.getLocale()].WorkShop.ViewDates}
                                 
                                </MDBBtn>
                 <MDBBtn
                                    className="gridActionBtn GreenBG"
                                    color="primary"
                                    style={{ color: "var(--btnColor)" }}
                                    size="sm"
                                    // onClick={() => {
                                    //     this.props.manageAction(item.action, this.props.index);
                                    // }}
                                    onClick={() => this.EditWorkShop(item)}                       >
                                        {i18n[this.getLocale()].WorkShop.Edit}
                                 
                                </MDBBtn>
  </td> */}
            </tr>
        )
    }
    updateListData = (data, popUpRowArray) => {
        
     
        
        let tempIndex = 0;
        if (popUpRowArray && popUpRowArray.length === 1) {
      
            this.setState({
                data: data,
                popUpRowArraytemp: popUpRowArray,
                selectedIndex: tempIndex
            });
        } else {
      
            
            this.setState({
                data: data,
                popUpRowArraytemp: popUpRowArray,
            });
        }
    }

    componentWillMount = () => {
    }

    componentDidMount=()=>
    {
        let tempEntityArray = [];
        if (Config.getEntityData()) {
            tempEntityArray = Config.getEntityData()
        }
        let loginData = Config.getLocalStorageLoginData();
        if (loginData) {
            this.setState({
                loginData: loginData
            }, () => {
                this.setState({
                    isinternal:this.state.loginData.internalcontact,
                    entityArray:tempEntityArray,
                    canviewreviewhrrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewreviewhrrequest,
                    canviewsecondmentrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewsecondmentrequest,
                    canviewadhousingrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewadhousingrequest,
                    canviewpatientrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewpatientrequest,
                    canviewretirementrequest: this.state.loginData && this.state.loginData.permissionType && this.state.loginData.permissionType.canviewretirementrequest
                }, () => {
                })
            })
        }
        this.callGetActionPlanInitiativesList();
    }
    getLocale = () => {
        if (this.state.isArabic) {
            return 'ar';
        } else {
            return 'en';
        }
    }

    callGetActionPlanInitiativesList= () => {
        let payload = {};
        payload.Upcoming=1;
 
        payload.lang=this.state.isArabic ? 1:0;
  let getDataFromLocalStorage = Config.getLocalStorageLoginData();
    
        payload.entityID = getDataFromLocalStorage.EntityID;
        let endPoint = 'GetActionPlanInitiativesList'
        let url = Config.getUrl(endPoint);
       this.toggleLoadingSpinner(true);
        ApiRequest.sendRequestForPost(url, payload, res => {
            this.toggleLoadingSpinner(false);
            if (res.Status) {
                
                let mainArray = {};
                let actualRowArray = [];
                let resArray = res.data;
                if (resArray && resArray.length > 0) {
                    for (let index = 0; index < resArray.length; index++) {
                        let tempCreatedDate = '';
                        if (resArray[index].createdon)
                            tempCreatedDate = resArray[index].createdon.split(' ')[0];

                        let rowsData = {
                            no: index + 1,
                            Title:resArray[index].Title,
                          ID:resArray[index].ID,
                            EntitName:resArray[index].EntitName,
                            StartDate:resArray[index].StartDate,
                            EndDate:resArray[index].EndDate,
                          
                            // isviolated: resArray[index].isviolated
                        };
                        actualRowArray.push(rowsData);
                    }
                    mainArray = {
                        'columns': this.state.columnData,
                        'rows': actualRowArray
                    };
                    if(this.state.isinternal)
                    {
                        this.setState({
                            data: mainArray,
                            popUpRowArraytemp: resArray,
                            actualArray: resArray,
                            dataRow: actualRowArray
                        }, () => {
                            this.handleLoadForEntity();
                           this.updateListData(this.state.data, this.state.popUpRowArraytemp);
                        })
                    }
                    else
                    {
                        this.setState({
                            data: mainArray,
                            popUpRowArray: resArray,
                            actualArray: resArray,
                            dataRow: actualRowArray
                        }, () => {
                  
                           this.updateListData(this.state.data, this.state.popUpRowArray);
                        })
                    }
               
                } else {
                    // send empty array to list page
                    this.setState({
                        data: [],
                        popUpRowArraytemp: [],
                    }, () => {
                         this.updateListData(this.state.data, this.state.popUpRowArraytemp);
                    })
                }
            } else {
                // TODO
            }
        })

      
    }
    create = () => {
        Config.setActionPlanID(0);
        let pathname ='ActionPlansEntity';
        this.setState({
            isexportVisible: false
        }, () => {
            this.props.history.replace(pathname, {
                pathname: pathname
            });
        })
    }
    render() {
        const sideBarData = Config.getServiceSideBar('ActionPlans', this.state.isArabic, this, 0);
        return(
        <MDBContainer fluid style={{ height: '100vh' }}>
        <LoadingOverlay
                active={this.state.isLoading}
                spinner={true}>
                <Header goToHelpPage={this.onClickHelpPage} logout={this.logout} toggleLanguage={this.toggleLanguage} isSideBarMinimized={this.state.isSideBarMinimized} isArabic={this.state.isArabic} toggleSideBarSize={this.toggleSideBarSize} />
                <MDBRow
                    className="formWhiteContainer"
                    style={{
                        //  width: '100vw',
                        flexDirection: this.state.isArabic ? 'row' : 'row-reverse'
                    }}>
                    <MDBCol md={this.state.isSideBarMinimized ? "11" : "10"}>
                        <MDBRow>
                            <MDBCol md="12" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                <SubHeaderStrip
                                    goBack={() => {
                                        Config.setIsEditFlag(false);
                                        this.props.history.replace('/landing') ;
                                    }}
                                    enableCreate={!this.state.isinternal}
                                    create={this.create}
                                    isArabic={this.state.isArabic} title={i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.subject}
                                />
                              {this.state.isinternal ? 
                                   <div style={{ paddingTop: "10px",paddingLeft:"30px",paddingRight:"30px" }}>
                                   <MDBRow style={{ display: 'flex', flexDirection:'row', paddingTop: '20px' }}>
  <MDBCol xs="12" sm="12" md="6" lg="12" xl="12">
                        <div className={this.state.isArabic ? "form-group txtAr" : "form-group txtEng"}>
                            <label style={{ width: '100%', display: 'flex', flexDirection: 'row' }} htmlFor="formGroupExampleInput">{i18n[this.state.isArabic ? 'ar' : 'en'].ActionPlans.EntitName}<span className="requireStar">*</span>   
                            </label>
                      
                            <select style={{ direction: this.state.isArabic ? 'rtl' : 'ltr' }} className="browser-default custom-select" id="sources-funding" onChange={this.handleChangeForEntity}
                                        value={this.state.entitySelectedValue}
                                        required>
                                        <option value="">{i18n[this.state.isArabic ? 'ar' : 'en'].selectDropdownDefaultValue}</option>
                                        {
                                            this.state.entityArray ? this.state.entityArray.map((item, index) => {
                                                return <option value={item.id}>{this.state.isArabic ? item.arabicName : item.englishName}</option>
                                            }) : null
                                        }
                                    </select>
                                   
                            
                        </div>
                    </MDBCol>

                                       </MDBRow>
                                   </div>:null}
                                <div style={{ paddingTop: "10px" }}>
                                <MDBTable striped responsive hover style={{ marginBottom: '80px', direction: this.state.isArabic ? 'rtl' : 'ltr' }}>
                        <MDBTableHead>
                        <tr className="tableHeader"> 
                     
<th><span >{i18n[this.getLocale()].ActionPlans.InitiativeName}</span>  </th>
<th><span >{i18n[this.getLocale()].ActionPlans.EntitName}</span></th>
<th><span >{i18n[this.getLocale()].ActionPlans.StartDate}</span></th>
<th><span >{i18n[this.getLocale()].ActionPlans.EndDate}</span></th>

                                <th><span ></span></th>
                                <th><span ></span></th>

                        </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                        {
                             
                                    this.state.popUpRowArray && this.state.popUpRowArray.length > 0 ? this.state.popUpRowArray.map((item, index) => {
                                        return (
                                            <React.Fragment>
                                              
                                                {this.renderDataTableRows(item, index)}
                                            </React.Fragment>
                                        )
                                    })
                                        :
                                     null
                                }
                        </MDBTableBody>
                        </MDBTable>
                                </div>
                                <div style={{clear:"both",height:"100px"}}/>
                                  <Footer isArabic={this.state.isArabic} />

                            </MDBCol>
                            </MDBRow>
                          
                        
   
                        </MDBCol>
                            <SideBar
                            canViewSCI={'1'}
                            canviewreviewhrrequest={this.state.canviewreviewhrrequest}
                            canviewsecondmentrequest={this.state.canviewsecondmentrequest}
                            canviewadhousingrequest={this.state.canviewadhousingrequest}
                            canviewpatientrequest={this.state.canviewpatientrequest}
                            canviewretirementrequest={this.state.canviewretirementrequest}
                            // height={'150vh'}
                            isSideBarMinimized={this.state.isSideBarMinimized}
                            items={sideBarData}
                            isArabic={this.state.isArabic} />
                       
                        </MDBRow>

                        <MDBModal
                        className={this.state.isArabic ? 'comment_ar viewDetailModal70' : 'viewDetailModal70'}
                        isOpen={this.state.ShowDetails === true}  toggle={this.toggle}
                        size={
                            
                                 "fluid"
                                
                        } position="bottom">
 <MDBModalHeader
                            style={{
                                padding: '0.5rem 1rem',
                                flexDirection: this.state.isArabic ? 'row-reverse' : 'row',
                                textAlign: this.state.isArabic ? "right" : "left"
                            }}
                            toggle={this.toggle}>{i18n[this.getLocale()].WorkShop.WorkShopTimes}
                        </MDBModalHeader>
                        <MDBModalBody>
                        {/* {this.renderDates()} */}
                        </MDBModalBody>
                        </MDBModal>
   </LoadingOverlay>
   
  
   </MDBContainer>);
    }
}